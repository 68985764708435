import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TCurrencyDM from './TCurrencyDM'
import TCustomerCreditItemDM from './TCustomerCreditItemDM'
import TCustomerCreditViolationDM from './TCustomerCreditViolationDM'

/**
 * Kredity zákazníka
 */
export default class TCustomerCreditDM extends Data {
	public static className = 'TCustomerCreditDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = false

	/**
	 * Neaktuální
	 */
	IsOutdated?: string

	/**
	 * Časové razítko
	 */
	TimeStamp?: string

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * Neaktivní
	 */
	IsInactive?: string

	/**
	 * Nekontrolovat hotovostní platby
	 */
	NotCheckCashPayments?: string

	/**
	 * Procento
	 */
	Percentage?: number

	/**
	 * Limit
	 */
	Limit?: number

	/**
	 * Firma
	 */
	CompanyId?: number

	/**
	 * Credit
	 */
	CreditRID?: number

	/**
	 * Zákazník
	 */
	TradingPartnerId?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Hodnota
	 */
	AmountCalc?: number

	/**
	 * Měna
	 */
	@Type(() => TCurrencyDM)
	Currency?: TCurrencyDM

	/**
	 * undefined
	 */
	@Type(() => TCustomerCreditItemDM)
	CreditItemChild?: TCustomerCreditItemDM[]

	/**
	 * undefined
	 */
	@Type(() => TCustomerCreditViolationDM)
	CreditTradingPartnerViolationChild?: TCustomerCreditViolationDM[]
}