import Data from 'react-k2-api/Api/Data'
import { Type } from 'class-transformer'
import TEshopCategoryInfoDM from './TEshopCategoryInfoDM'
import TEshopDM from './TEshopDM'

/**
 * Kategorie zboží
 */
export default class TEshopCategoryListDM extends Data {
	public static className = 'TEshopCategoryListDM'
	public static systemFields = [
		'RecordID',
	]

	public static isHeader = false

	/**
	 * Zboží
	 */
	ArticleId?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * Kategorie
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * undefined
	 */
	IsArticleAssignedCalc?: string

	/**
	 * undefined
	 */
	IsArticleAssignedImgCalc?: string

	/**
	 * Kategorie
	 */
	@Type(() => TEshopCategoryInfoDM)
	CategoryRID?: TEshopCategoryInfoDM

	/**
	 * E-shop
	 */
	@Type(() => TEshopDM)
	EshopRIDCalc?: TEshopDM
}