import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TElectronicAddressTypeDM from './TElectronicAddressTypeDM'
import TTextDM from './TTextDM'
import TEshopDM from './TEshopDM'
import TContactPersonDM from './TContactPersonDM'
import TPartnerDM from './TPartnerDM'
import TMarketingActivityTypeDM from './TMarketingActivityTypeDM'
import TLinkDM from './TLinkDM'
import TCommentDM from './TCommentDM'
import TMarketingInformationKindDM from './TMarketingInformationKindDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TAttachedDocumentDM from './TAttachedDocumentDM'
import TOldNoteDM from './TOldNoteDM'

/**
 * Registrované emaily
 */
export default class TRegisteredEmailDM extends Data {
	public static className = 'TRegisteredEmailDM'
	public static primaryKey = 'RID'
	public static systemFields = [
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = true

	/**
	 * Přezdívka
	 */
	Username?: string

	/**
	 * Zasílání notifikací
	 */
	SendNotifications?: string

	/**
	 * Zasílání informací
	 */
	SendInformation?: string

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * Email
	 */
	FullElectronicAddress?: string

	/**
	 * Stát
	 */
	CountryId?: number

	/**
	 * Elektronická adresa
	 */
	ElectronicAddress?: string

	/**
	 * Department
	 */
	Department?: number

	/**
	 * HeaderId
	 */
	HeaderRID?: number

	/**
	 * TypeOfReference
	 */
	ReferenceTypeId?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Odkaz
	 */
	PictureCalc?: string

	/**
	 * undefined
	 */
	NewsLetterURLCalc?: string

	/**
	 * undefined
	 */
	UrlCalc?: string

	/**
	 * undefined
	 */
	TextHeaderMemoCalc?: string

	/**
	 * undefined
	 */
	TextFooterMemoCalc?: string

	/**
	 * Poslední komentář
	 */
	LastCommentParamCalc?: string

	/**
	 * Typ
	 */
	@Type(() => TElectronicAddressTypeDM)
	ElectronicAddressTypeId?: TElectronicAddressTypeDM

	/**
	 * Z
	 */
	@Type(() => TTextDM)
	InvalidRecordImgCalc?: TTextDM

	/**
	 * undefined
	 */
	@Type(() => TEshopDM)
	EshopRIDCalc?: TEshopDM

	/**
	 * undefined
	 */
	@Type(() => TContactPersonDM)
	ContactPersonRIDCalc?: TContactPersonDM

	/**
	 * undefined
	 */
	@Type(() => TPartnerDM)
	PartnerIdCalc?: TPartnerDM

	/**
	 * Web pro zobrazení a editaci dat
	 */
	@Type(() => TEshopDM)
	ShownWebRIDCalc?: TEshopDM

	/**
	 * D
	 */
	@Type(() => TTextDM)
	DocumentExistsCalc?: TTextDM

	/**
	 * undefined
	 */
	@Type(() => TMarketingActivityTypeDM)
	ActivityTypeRIDCalc?: TMarketingActivityTypeDM

	/**
	 * Odkazy
	 */
	@Type(() => TLinkDM)
	LinkChild?: TLinkDM[]

	/**
	 * Komentáře
	 */
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]

	/**
	 * Specifikace
	 */
	@Type(() => TMarketingInformationKindDM)
	InformationKindChild?: TMarketingInformationKindDM[]

	/**
	 * Text hlavičky
	 */
	@Type(() => TLanguageMemoTranslationDM)
	TextHeaderChild?: TLanguageMemoTranslationDM[]

	/**
	 * Text patičky
	 */
	@Type(() => TLanguageMemoTranslationDM)
	TextFooterChild?: TLanguageMemoTranslationDM[]

	/**
	 * Svázané doklady
	 */
	@Type(() => TAttachedDocumentDM)
	AttachedDocumentOldChild?: TAttachedDocumentDM[]

	/**
	 * Poznámky
	 */
	@Type(() => TOldNoteDM)
	DeprecatedNoteOldChild?: TOldNoteDM[]

	/**
	 * Zneplatnění
	 */
	public BulkInvalidationCOMMAND() {
		return this.executeCommand('BulkInvalidationCOMMAND')
	}

	/**
	 * Zplatnění
	 */
	public BulkUnInvalidationCOMMAND() {
		return this.executeCommand('BulkUnInvalidationCOMMAND')
	}

	/**
	 * Zneplatnění/Zplatnění
	 */
	public InvalidationCOMMAND() {
		return this.executeCommand('InvalidationCOMMAND')
	}

	/**
	 * Potvrzení
	 */
	public BulkConfirmCOMMAND() {
		return this.executeCommand('BulkConfirmCOMMAND')
	}

	/**
	 * Odpotvrzení
	 */
	public BulkUnconfirmCOMMAND() {
		return this.executeCommand('BulkUnconfirmCOMMAND')
	}

	/**
	 * Změna data potvrzení
	 */
	public BulkChangeConfirmationDateCOMMAND(parameters: TBulkChangeConfirmationDateCOMMANDParams) {
		return this.executeCommand('BulkChangeConfirmationDateCOMMAND', parameters)
	}

	/**
	 * Storno
	 */
	public BulkCancelCOMMAND() {
		return this.executeCommand('BulkCancelCOMMAND')
	}

	/**
	 * Odstorno
	 */
	public BulkUnCancelCOMMAND() {
		return this.executeCommand('BulkUnCancelCOMMAND')
	}

	/**
	 * Potvrzení
	 */
	public ConfirmCOMMAND() {
		return this.executeCommand('ConfirmCOMMAND')
	}

	/**
	 * Odpotvrzení
	 */
	public UnconfirmCOMMAND() {
		return this.executeCommand('UnconfirmCOMMAND')
	}

	/**
	 * Změna data potvrzení
	 */
	public ChangeConfirmationDateCOMMAND(parameters: TChangeConfirmationDateCOMMANDParams) {
		return this.executeCommand('ChangeConfirmationDateCOMMAND', parameters)
	}

	/**
	 * Storno/Odstorno
	 */
	public CancelCOMMAND() {
		return this.executeCommand('CancelCOMMAND')
	}

	/**
	 * Zrušení stavu
	 */
	public ClearRecordStatusCOMMAND() {
		return this.executeCommand('ClearRecordStatusCOMMAND')
	}

	/**
	 * Změna stavu
	 */
	public ChangeRecordStatusCOMMAND() {
		return this.executeCommand('ChangeRecordStatusCOMMAND')
	}

	/**
	 * 
	 */
	public ChangeRecordStatusSWSCOMMAND(parameters: TChangeRecordStatusSWSCOMMANDParams) {
		return this.executeCommand('ChangeRecordStatusSWSCOMMAND', parameters)
	}

	/**
	 * Zrušení stavu
	 */
	public BulkClearRecordStatusCOMMAND() {
		return this.executeCommand('BulkClearRecordStatusCOMMAND')
	}

	/**
	 * Změna stavu
	 */
	public BulkChangeRecordStatusCOMMAND() {
		return this.executeCommand('BulkChangeRecordStatusCOMMAND')
	}

	/**
	 * Změna atributů
	 */
	public BulkChangeAttributesCOMMAND(parameters: TBulkChangeAttributesCOMMANDParams) {
		return this.executeCommand('BulkChangeAttributesCOMMAND', parameters)
	}

	/**
	 * Aktualizace sady atributů
	 */
	public BulkActualizeAttributesCOMMAND(parameters: TBulkActualizeAttributesCOMMANDParams) {
		return this.executeCommand('BulkActualizeAttributesCOMMAND', parameters)
	}

	/**
	 * Aktualizace sady atributů
	 */
	public ActualizeAttributesCOMMAND() {
		return this.executeCommand('ActualizeAttributesCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public ChangeGroupRightCOMMAND() {
		return this.executeCommand('ChangeGroupRightCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public BulkChangeGroupRightCOMMAND() {
		return this.executeCommand('BulkChangeGroupRightCOMMAND')
	}

	/**
	 * Aktualizace počítaných polí
	 */
	public BulkUpdateCalcFieldsCOMMAND() {
		return this.executeCommand('BulkUpdateCalcFieldsCOMMAND')
	}

	/**
	 * Kontrola legislativy
	 */
	public SwitchOffLegislationValidationCOMMAND() {
		return this.executeCommand('SwitchOffLegislationValidationCOMMAND')
	}
}

export type TBulkChangeConfirmationDateCOMMANDParams = {
	/**
	 * Datum potvrzení
	 */
	Confirmation1Date?: Date
	/**
	 * Hodina potvrzení
	 */
	Confirmation1Time?: Date
	/**
	 * Datum 2. potvrzení
	 */
	Confirmation2Date?: Date
	/**
	 * Hodina 2. potvrzení
	 */
	Confirmation2Time?: Date
	/**
	 * Kontrolovat záporné stavy
	 */
	CheckNegativeState?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TChangeConfirmationDateCOMMANDParams = {
	/**
	 * Datum potvrzení
	 */
	Confirmation1Date?: Date
	/**
	 * Hodina potvrzení
	 */
	Confirmation1Time?: Date
	/**
	 * Datum 2. potvrzení
	 */
	Confirmation2Date?: Date
	/**
	 * Hodina 2. potvrzení
	 */
	Confirmation2Time?: Date
	/**
	 * Kontrolovat záporné stavy
	 */
	CheckNegativeState?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TChangeRecordStatusSWSCOMMANDParams = {
	/**
	 * StatusId
	 */
	StatusId?: number
}

export type TBulkChangeAttributesCOMMANDParams = {
	/**
	 * Smazat atributy
	 */
	DeleteAttribute?: boolean
	/**
	 * Smazat všechny volné atributy
	 */
	DeleteFreeAttribute?: boolean
	/**
	 * Nastavit hodnoty atributů
	 */
	SetValues?: boolean
	/**
	 * Přidat nepřipojené atributy
	 */
	AddFreeAttributes?: boolean
	/**
	 * FreeAttributeEnabled
	 */
	FreeAttributeEnabled?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TBulkActualizeAttributesCOMMANDParams = {
	/**
	 * Aktualizovat včetně samotné sady atributů
	 */
	ActualizeAttributeSet?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}