import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TWorkplaceDM from './TWorkplaceDM'
import TResourceDM from './TResourceDM'
import TTextDM from './TTextDM'
import TWorkshiftDM from './TWorkshiftDM'
import TJobCardDM from './TJobCardDM'

/**
 * Production calendars items
 */
export default class TJobCardScheduleDM extends Data {
	public static className = 'TJobCardScheduleDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = false

	/**
	 * Průvodka
	 */
	JobCardBindRID?: number

	/**
	 * Člen
	 */
	Member?: string

	/**
	 * Pořadí
	 */
	SequenceNumber?: number

	/**
	 * Rozvrženo
	 */
	IsManuallyAdjustedSchedule?: string

	/**
	 * Trvání
	 */
	Duration?: number

	/**
	 * Množství
	 */
	Quantity?: number

	/**
	 * Kalendář
	 */
	CalendarId?: number

	/**
	 * Archivováno
	 */
	IsArchived?: string

	/**
	 * Popis
	 */
	Description?: string

	/**
	 * Datum směny
	 */
	WorkshiftDate?: Date

	/**
	 * Čas od
	 */
	TimeFrom?: Date

	/**
	 * Datum
	 */
	Date?: Date

	/**
	 * Zdroj průvodky
	 */
	JobCardResourceRID?: number

	/**
	 * Operace průvodky
	 */
	JobCardOperationRID?: number

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Zpráva
	 */
	PlannerMessageCalc?: string

	/**
	 * Doklad
	 */
	DocumentCalc?: string

	/**
	 * Datum od
	 */
	DateFromCalc?: Date

	/**
	 * Datum do
	 */
	DateToCalc?: Date

	/**
	 * Trvání
	 */
	DurationCalc?: number

	/**
	 * Čas do
	 */
	PlannedTimeToCalc?: Date

	/**
	 * undefined
	 */
	ChangeTimeQuantityCalc?: number

	/**
	 * Pracoviště
	 */
	@Type(() => TWorkplaceDM)
	WorkPlaceRID?: TWorkplaceDM

	/**
	 * Zdroj
	 */
	@Type(() => TResourceDM)
	ResourceId?: TResourceDM

	/**
	 * Čas operace
	 */
	@Type(() => TTextDM)
	OperationTime?: TTextDM

	/**
	 * Směna
	 */
	@Type(() => TWorkshiftDM)
	WorkshiftRID?: TWorkshiftDM

	/**
	 * Průvodka
	 */
	@Type(() => TJobCardDM)
	JobCardRID?: TJobCardDM
}