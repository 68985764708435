import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TK2UserLookupDM from './TK2UserLookupDM'
import ZoneType from './ZoneType'
import TWarehouseDM from './TWarehouseDM'
import TLocationDM from './TLocationDM'
import TWarehouseZoneListItemDM from './TWarehouseZoneListItemDM'
import TWarehouseZoneSummationItemDM from './TWarehouseZoneSummationItemDM'

/**
 * Skladové zóny
 */
export default class TWarehouseZoneDM extends Data {
	public static className = 'TWarehouseZoneDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = true

	/**
	 * Sumační zóna
	 */
	IsSumZone?: string

	/**
	 * Časové razítko
	 */
	TimeStamp?: string

	/**
	 * Změněno
	 */
	ChangedOn?: Date

	/**
	 * Popis
	 */
	Description?: string

	/**
	 * Zkratka
	 */
	Abbr?: string

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * undefined
	 */
	ZoneTypeImgCalc?: string

	/**
	 * undefined
	 */
	SumZoneArticleCountCalc?: number

	/**
	 * undefined
	 */
	ListZoneArticleCountCalc?: number

	/**
	 * Změnil
	 */
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM

	/**
	 * Typ zóny
	 */
	@Type(() => ZoneType)
	ZoneTypeId?: ZoneType

	/**
	 * Sklad
	 */
	@Type(() => TWarehouseDM)
	WarehouseId?: TWarehouseDM

	/**
	 * undefined
	 */
	@Type(() => TWarehouseDM)
	WarehouseIdCalc?: TWarehouseDM

	/**
	 * undefined
	 */
	@Type(() => TLocationDM)
	LocationChild?: TLocationDM[]

	/**
	 * undefined
	 */
	@Type(() => TWarehouseZoneListItemDM)
	ListItemChild?: TWarehouseZoneListItemDM[]

	/**
	 * undefined
	 */
	@Type(() => TWarehouseZoneSummationItemDM)
	SummationItemChild?: TWarehouseZoneSummationItemDM[]

	/**
	 * undefined
	 */
	@Type(() => TLocationDM)
	SumZoneLocationChild?: TLocationDM[]

	/**
	 * Změna skupiny práv
	 */
	public ChangeGroupRightCOMMAND() {
		return this.executeCommand('ChangeGroupRightCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public BulkChangeGroupRightCOMMAND() {
		return this.executeCommand('BulkChangeGroupRightCOMMAND')
	}

	/**
	 * Aktualizace počítaných polí
	 */
	public BulkUpdateCalcFieldsCOMMAND() {
		return this.executeCommand('BulkUpdateCalcFieldsCOMMAND')
	}

	/**
	 * Kontrola legislativy
	 */
	public SwitchOffLegislationValidationCOMMAND() {
		return this.executeCommand('SwitchOffLegislationValidationCOMMAND')
	}
}