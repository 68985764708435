import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'

/**
 * Informace
 */
export default class TCustomTReceiptCardDMInformace_P extends Data {
	public static className = 'TCustomTReceiptCardDMInformace_P'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = false

	/**
	 * Master
	 */
	Master?: number

	/**
	 * Pořadí
	 */
	Por?: number

	/**
	 * Typ
	 */
	Typ?: string

	/**
	 * Informace
	 */
	Informace?: string

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * RecordMod
	 */
	RecordMod?: number

	/**
	 * RecordES
	 */
	RecordES?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * RecordState
	 */
	RecordState?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date
}