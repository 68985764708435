import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import PlanType from './PlanType'

/**
 * Podřízený typ plánu
 */
export default class TPlanTypeSubordinateItemDM extends Data {
	public static className = 'TPlanTypeSubordinateItemDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = false

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * Dodatek
	 */
	SupplementId?: number

	/**
	 * C_Zbo
	 */
	ArticleId?: number

	/**
	 * Proc
	 */
	Percentage?: number

	/**
	 * Kniha2
	 */
	Book2?: string

	/**
	 * Ci1
	 */
	DocumentRID1?: number

	/**
	 * Kniha1
	 */
	Book1?: string

	/**
	 * Typ
	 */
	RecordTypeId?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Ci2
	 */
	@Type(() => PlanType)
	DocumentRID2?: PlanType
}