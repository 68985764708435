import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TK2UserLookupDM from './TK2UserLookupDM'
import TAttendanceWorkShiftTypeDM from './TAttendanceWorkShiftTypeDM'
import TTextDM from './TTextDM'
import PrCalTypDay from './PrCalTypDay'
import PrCalTypHours from './PrCalTypHours'

/**
 * Definiční položky
 */
export default class TPrCalendarDefinitionDM extends Data {
	public static className = 'TPrCalendarDefinitionDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = false

	/**
	 * Skupina práv
	 */
	RightGroupId?: number

	/**
	 * Časové razítko
	 */
	TimeStamp?: string

	/**
	 * Změněno
	 */
	ChangedOn?: Date

	/**
	 * Vytvořeno
	 */
	CreatedOn?: Date

	/**
	 * Deaktivovaný záznam
	 */
	IsDeactivatedRecord?: string

	/**
	 * Směna
	 */
	WorkshiftRID?: number

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * Posun datumu
	 */
	DateShift?: number

	/**
	 * Čas přerušení
	 */
	BreakTime?: number

	/**
	 * Čas celkem
	 */
	TotalTime?: number

	/**
	 * Datum do
	 */
	ValidTo?: Date

	/**
	 * Datum od
	 */
	ValidFrom?: Date

	/**
	 * S.hodn.2
	 */
	StringValue2?: string

	/**
	 * S.hodn.3
	 */
	StringValue3?: string

	/**
	 * Č.hodn.N
	 */
	ShortValue?: number

	/**
	 * Počet dnů v sekvenci
	 */
	RepetitionDayCount?: number

	/**
	 * Č.hodn.L
	 */
	LongValue?: number

	/**
	 * Měsíc
	 */
	RepetitionMonthId?: number

	/**
	 * Den
	 */
	RepetitionDayId?: number

	/**
	 * Opak.každ.B
	 */
	RepetitionEveryB?: number

	/**
	 * Opak.každ.A
	 */
	RepetitionEveryA?: number

	/**
	 * Dny č.ús.
	 */
	DayCount?: number

	/**
	 * Čas do
	 */
	ValidToTime?: number

	/**
	 * Čas od
	 */
	ValidFromTime?: number

	/**
	 * Datum do
	 */
	ValidToDate?: Date

	/**
	 * Datum od
	 */
	ValidFromDate?: Date

	/**
	 * Popis
	 */
	Description?: string

	/**
	 * Kalendář
	 */
	CodeCalendarId?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Hodiny
	 */
	TimeHoursCalc?: number

	/**
	 * Minuty
	 */
	TimeMinutesCalc?: number

	/**
	 * Přesah dnů
	 */
	OverdaysCalc?: number

	/**
	 * Trvání
	 */
	DurationTimeCalc?: number

	/**
	 * Přestávka
	 */
	BreakInHoursCalc?: number

	/**
	 * L
	 */
	StateLockedCalc?: number

	/**
	 * Všechny dny
	 */
	AllDayIdCalc?: number

	/**
	 * Jen pondělí až pátek
	 */
	WorkDayIdCalc?: number

	/**
	 * Pondělí až pátek
	 */
	AccountWorkDayIdCalc?: number

	/**
	 * Soboty
	 */
	SaturdayIdCalc?: number

	/**
	 * Neděle
	 */
	SundayIdCalc?: number

	/**
	 * Pouze dny typu
	 */
	DayTypeIdCalcCalc?: number

	/**
	 * Po
	 */
	DaySequence1BitCalc?: number

	/**
	 * Út
	 */
	DaySequence2BitCalc?: number

	/**
	 * St
	 */
	DaySequence3BitCalc?: number

	/**
	 * Čt
	 */
	DaySequence4BitCalc?: number

	/**
	 * Pá
	 */
	DaySequence5BitCalc?: number

	/**
	 * So
	 */
	DaySequence6BitCalc?: number

	/**
	 * Ne
	 */
	DaySequence7BitCalc?: number

	/**
	 * undefined
	 */
	DaySequence8BitCalc?: number

	/**
	 * undefined
	 */
	DaySequence9BitCalc?: number

	/**
	 * undefined
	 */
	DaySequence10BitCalc?: number

	/**
	 * undefined
	 */
	DaySequence11BitCalc?: number

	/**
	 * undefined
	 */
	DaySequence12BitCalc?: number

	/**
	 * undefined
	 */
	DaySequence13BitCalc?: number

	/**
	 * undefined
	 */
	DaySequence14BitCalc?: number

	/**
	 * undefined
	 */
	DaySequence15BitCalc?: number

	/**
	 * undefined
	 */
	DaySequence16BitCalc?: number

	/**
	 * undefined
	 */
	DaySequence17BitCalc?: number

	/**
	 * undefined
	 */
	DaySequence18BitCalc?: number

	/**
	 * undefined
	 */
	DaySequence19BitCalc?: number

	/**
	 * undefined
	 */
	DaySequence20BitCalc?: number

	/**
	 * undefined
	 */
	DaySequence21BitCalc?: number

	/**
	 * undefined
	 */
	DaySequence22BitCalc?: number

	/**
	 * undefined
	 */
	DaySequence23BitCalc?: number

	/**
	 * undefined
	 */
	DaySequence24BitCalc?: number

	/**
	 * undefined
	 */
	DaySequence25BitCalc?: number

	/**
	 * undefined
	 */
	DaySequence26BitCalc?: number

	/**
	 * undefined
	 */
	DaySequence27BitCalc?: number

	/**
	 * undefined
	 */
	DaySequence28BitCalc?: number

	/**
	 * undefined
	 */
	DaySequence29BitCalc?: number

	/**
	 * undefined
	 */
	DaySequence30BitCalc?: number

	/**
	 * undefined
	 */
	DaySequence31BitCalc?: number

	/**
	 * Zobrazit zkratky dnů v týdnu
	 */
	ShowDayAbbrCalc?: string

	/**
	 * Zobrazit zatržítka dnů
	 */
	ShowDayFlagCalc?: string

	/**
	 * Čas od
	 */
	TimeFromCalc?: Date

	/**
	 * Čas do
	 */
	TimeToCalc?: Date

	/**
	 * Skupina práv
	 */
	RightGroupIdCalc?: number

	/**
	 * Změnil
	 */
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM

	/**
	 * Vytvořil
	 */
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM

	/**
	 * Typ směny modelu pracovní doby
	 */
	@Type(() => TAttendanceWorkShiftTypeDM)
	AttendanceWorkShiftTypeRID?: TAttendanceWorkShiftTypeDM

	/**
	 * Typ směny
	 */
	@Type(() => TTextDM)
	WorkShiftTypeId?: TTextDM

	/**
	 * Typ položky
	 */
	@Type(() => TTextDM)
	ItemTypeId?: TTextDM

	/**
	 * Priorita
	 */
	@Type(() => TTextDM)
	PriorityId?: TTextDM

	/**
	 * Opakování
	 */
	@Type(() => TTextDM)
	RepetitionTypeId?: TTextDM

	/**
	 * Typ dne opak.
	 */
	@Type(() => PrCalTypDay)
	RepetitionDayTypeId?: PrCalTypDay

	/**
	 * Typ čas. úseku
	 */
	@Type(() => PrCalTypHours)
	CalendarRecordTypeId?: PrCalTypHours

	/**
	 * Typ dne
	 */
	@Type(() => PrCalTypDay)
	DayTypeId?: PrCalTypDay

	/**
	 * Č.opak.
	 */
	@Type(() => TTextDM)
	RepetitionEveryId?: TTextDM
}