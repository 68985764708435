import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TBookDM from './TBookDM'
import TK2UserLookupDM from './TK2UserLookupDM'
import TCompanyDM from './TCompanyDM'

/**
 * Firmy
 */
export default class TCompanyLinkPartnerDM extends Data {
	public static className = 'TCompanyLinkPartnerDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = false

	/**
	 * Měna
	 */
	Currency?: string

	/**
	 * Valid
	 */
	IsValid?: string

	/**
	 * Změněno
	 */
	ChangedOn?: Date

	/**
	 * RecID
	 */
	RecordNumber?: number

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * undefined
	 */
	IconCalc?: number

	/**
	 * Bank. účet
	 */
	@Type(() => TBookDM)
	BankBookId?: TBookDM

	/**
	 * Změnil
	 */
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM

	/**
	 * CompanyId
	 */
	@Type(() => TCompanyDM)
	CompanyId?: TCompanyDM
}