import { useState } from "react"
import { Button, Modal } from "react-bootstrap"
import Data from "react-k2-api/Api/Data"
import { useGlobalModalContext } from "./GlobalModal"

export type LookupModalProps = {
    title: string
    dataList: Data[]
    handleConfirmation: (dataList: Data[]) => void
    handleDataRender: (data: Data, key: number) => JSX.Element
    handleClose?: () => void
}

export const LookupModal = () => {
    const { hideModal, store } = useGlobalModalContext()
    const { title, dataList, handleConfirmation, handleDataRender, handleClose } = store.modalProps as LookupModalProps
    const [selectedItems, setSelectedItems] = useState<Data[]>([])

    return (
        <Modal show={true}
            backdrop="static"
            keyboard={false}
            onHide={() => {
                hideModal()
                handleClose && handleClose()
            }}
        >
            <Modal.Header>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ul className="list-group">
                    {dataList.map((item, index) => {
                        return <li className={`list-group-item${selectedItems.includes(item) ? ' active' : ''}`} onClick={() => {
                            setSelectedItems([item])
                        }}>{handleDataRender(item, index)}</li>
                    })}
                </ul>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" className="col-3 me-auto" onClick={() => {
                    hideModal()
                    handleClose && handleClose()
                }}>
                    <i className="bi bi-x-lg" />
                </Button>
                <Button
                    variant="success"
                    className="col-3"
                    onClick={() => {
                        hideModal()
                        handleConfirmation(selectedItems)
                    }}
                    disabled={selectedItems.length === 0}
                >
                    <i className="bi bi-check-lg" />
                </Button>
            </Modal.Footer>
        </Modal>
    )
}