import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TElectronicSubmissionDM from './TElectronicSubmissionDM'
import TTextDM from './TTextDM'
import TK2UserLookupDM from './TK2UserLookupDM'

/**
 * Zprávy neschopenky
 */
export default class TPrEmployeeSickNoteMessageDM extends Data {
	public static className = 'TPrEmployeeSickNoteMessageDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = false

	/**
	 * Verze
	 */
	XmlVersion?: string

	/**
	 * Datum vzniku notifikace
	 */
	NotificationDate?: Date

	/**
	 * eNeschopenka
	 */
	PrEmployeeSickNoteRID?: number

	/**
	 * XMLField
	 */
	XMLField?: string

	/**
	 * Číslo notifikace
	 */
	NotificationNumber?: string

	/**
	 * Právo na záznam
	 */
	RightGroupId?: number

	/**
	 * Časové razítko
	 */
	TimeStamp?: string

	/**
	 * Změněno
	 */
	ChangedOn?: Date

	/**
	 * Vytvořeno
	 */
	CreatedOn?: Date

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Právo na záznam
	 */
	RightGroupIdCalc?: number

	/**
	 * Elektronické podání
	 */
	@Type(() => TElectronicSubmissionDM)
	ElectronicSubmissionRID?: TElectronicSubmissionDM

	/**
	 * Typ notifikace
	 */
	@Type(() => TTextDM)
	NotificationTypeId?: TTextDM

	/**
	 * Změnil
	 */
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM

	/**
	 * Vytvořil
	 */
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM
}