import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TCountryDM from './TCountryDM'
import TElectronicAddressTypeDM from './TElectronicAddressTypeDM'
import DepartmentName from './DepartmentName'

/**
 * Elektronické adresy - položky
 */
export default class TElectronicAddressDM extends Data {
	public static className = 'TElectronicAddressDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = false

	/**
	 * Přezdívka
	 */
	Username?: string

	/**
	 * Zasílání notifikací
	 */
	SendNotifications?: string

	/**
	 * Zasílání informací
	 */
	SendInformation?: string

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * Email
	 */
	FullElectronicAddress?: string

	/**
	 * Elektronická adresa
	 */
	ElectronicAddress?: string

	/**
	 * HeaderId
	 */
	HeaderRID?: number

	/**
	 * TypeOfReference
	 */
	ReferenceTypeId?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * undefined
	 */
	AddressFormattedCalc?: string

	/**
	 * undefined
	 */
	AddressTypeCalc?: string

	/**
	 * Stát
	 */
	@Type(() => TCountryDM)
	CountryId?: TCountryDM

	/**
	 * Typ
	 */
	@Type(() => TElectronicAddressTypeDM)
	ElectronicAddressTypeId?: TElectronicAddressTypeDM

	/**
	 * Department
	 */
	@Type(() => DepartmentName)
	Department?: DepartmentName
}