import Data from 'react-k2-api/Api/Data'
import { Type } from 'class-transformer'
import THandlingUnitArticleDM from './THandlingUnitArticleDM'
import TWarehouseDM from './TWarehouseDM'
import TTextDM from './TTextDM'
import TLocationArticleDM from './TLocationArticleDM'
import TBatchDM from './TBatchDM'
import TContractCodeDocumentDM from './TContractCodeDocumentDM'
import TArticleDM from './TArticleDM'
import TTradingPartnerDM from './TTradingPartnerDM'
import TJobCardDM from './TJobCardDM'
import TSalesOrderDM from './TSalesOrderDM'
import TPurchaseOrderDM from './TPurchaseOrderDM'
import TReceiptCardDM from './TReceiptCardDM'
import TReleaseNoteDM from './TReleaseNoteDM'
import TTransferNoteDM from './TTransferNoteDM'

/**
 * Položky skladové karty
 */
export default class TStockCardOldDM extends Data {
	public static className = 'TStockCardOldDM'
	public static systemFields = [
		'ItemRIDCalc',
	]

	public static isHeader = false

	/**
	 * undefined
	 */
	RID?: number

	/**
	 * undefined
	 */
	UMCoeficient?: number

	/**
	 * Vedl. nákl.
	 */
	IncidentalCost?: number

	/**
	 * Unikát
	 */
	SequenceNumber?: number

	/**
	 * Nákl. cena / MJ
	 */
	UnitCostPrice?: number

	/**
	 * Varianta
	 */
	RoutingVariantId?: number

	/**
	 * Osoba
	 */
	OfficerId?: number

	/**
	 * Kód 2
	 */
	Code2Id?: number

	/**
	 * Kód 1
	 */
	Code1Id?: number

	/**
	 * Č.obj./zak.
	 */
	OrderDocumentRID?: number

	/**
	 * Číslo podpol.
	 */
	SequenceNumber1?: number

	/**
	 * Číslo pol.
	 */
	ItemId?: number

	/**
	 * K.obj./zak.
	 */
	PurchaseBook?: string

	/**
	 * Datum od
	 */
	ProductionDateFrom?: Date

	/**
	 * Zadáno
	 */
	Assigned?: number

	/**
	 * Dispozice
	 */
	Available?: number

	/**
	 * Inf.sklad
	 */
	InformationWarehouseId?: number

	/**
	 * Stav-Kč
	 */
	LevelAmount?: number

	/**
	 * Stav-množ.
	 */
	LevelQuantity?: number

	/**
	 * Výdej-Kč
	 */
	ReleaseAmount?: number

	/**
	 * Výdej-množ.
	 */
	ReleaseQuantity?: number

	/**
	 * Příjem-Kč
	 */
	ReceiptAmount?: number

	/**
	 * Příjem-množ.
	 */
	ReceiptQuantity?: number

	/**
	 * Cena / MJ
	 */
	StockPrice?: number

	/**
	 * Číslo
	 */
	DocumentRID?: number

	/**
	 * Datum
	 */
	ConfirmedOn?: Date

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * undefined
	 */
	RecordStateImgCalc?: string

	/**
	 * Příjem
	 */
	ReceiptCalc?: number

	/**
	 * Výdej
	 */
	ReleaseCalc?: number

	/**
	 * Stav
	 */
	LevelStateCalc?: number

	/**
	 * Cena / MJ
	 */
	StockPriceCalc?: number

	/**
	 * Typ
	 */
	DocumentTypeCalc?: string

	/**
	 * Nákl. cena / MJ
	 */
	CostPriceUMCalc?: number

	/**
	 * Nadř. doklad
	 */
	SuperiorDocumentCalc?: string

	/**
	 * Datum 2
	 */
	Confirmation2DateCalc?: Date

	/**
	 * Doklad
	 */
	DocumentIdentificationCalc?: string

	/**
	 * Popis dokladu
	 */
	DescriptionCalc?: string

	/**
	 * Kniha
	 */
	BookAbbrCalc?: string

	/**
	 * ItemRIDCalc
	 */
	ItemRIDCalc?: number

	/**
	 * PS množství
	 */
	InitialStateQuantityCalc?: number

	/**
	 * PS částka
	 */
	InitialStateAmountCalc?: number

	/**
	 * Obrat - příjmy
	 */
	ReceiptTurnoverCalc?: number

	/**
	 * Obrat - výdeje
	 */
	ReleaseTurnoverCalc?: number

	/**
	 * Kladné saldo
	 */
	PositiveBalanceCalc?: number

	/**
	 * Záporné saldo
	 */
	NegativeBalanceCalc?: number

	/**
	 * KS množství
	 */
	EndStateLevelQuantityCalc?: number

	/**
	 * KS částka
	 */
	EndStateLevelAmountCalc?: number

	/**
	 * RecordModeCalc
	 */
	RecordModeCalc?: number

	/**
	 * undefined
	 */
	@Type(() => THandlingUnitArticleDM)
	HandlingUnitRID?: THandlingUnitArticleDM

	/**
	 * Sklad 2
	 */
	@Type(() => TWarehouseDM)
	TransferToWarehouseId?: TWarehouseDM

	/**
	 * Typ dokladu
	 */
	@Type(() => TTextDM)
	DocumentTypeId?: TTextDM

	/**
	 * Č. umístění
	 */
	@Type(() => TLocationArticleDM)
	LocationId?: TLocationArticleDM

	/**
	 * Č. šarže
	 */
	@Type(() => TBatchDM)
	BatchId?: TBatchDM

	/**
	 * Kód zak. pol.
	 */
	@Type(() => TContractCodeDocumentDM)
	ContractCodeRID?: TContractCodeDocumentDM

	/**
	 * Č. skladu
	 */
	@Type(() => TWarehouseDM)
	WarehouseId?: TWarehouseDM

	/**
	 * Č. zboží
	 */
	@Type(() => TArticleDM)
	ArticleId?: TArticleDM

	/**
	 * Kód zak. hl.
	 */
	@Type(() => TContractCodeDocumentDM)
	ContractCodeFromHeaderRIDCalc?: TContractCodeDocumentDM

	/**
	 * Firma
	 */
	@Type(() => TTradingPartnerDM)
	TradingPartnerIdCalc?: TTradingPartnerDM

	/**
	 * Adresát
	 */
	@Type(() => TTradingPartnerDM)
	TradingPartner1IdCalc?: TTradingPartnerDM

	/**
	 * Číslo průvodky
	 */
	@Type(() => TJobCardDM)
	JCRIDCalc?: TJobCardDM

	/**
	 * Číslo zakázky
	 */
	@Type(() => TSalesOrderDM)
	SalesOrderRIDCalc?: TSalesOrderDM

	/**
	 * Číslo objednávky
	 */
	@Type(() => TPurchaseOrderDM)
	PurchaseOrderRIDCalc?: TPurchaseOrderDM

	/**
	 * Číslo příjemky
	 */
	@Type(() => TReceiptCardDM)
	ReceiptCardRIDCalc?: TReceiptCardDM

	/**
	 * Číslo výdejky
	 */
	@Type(() => TReleaseNoteDM)
	ReleaseNoteRIDCalc?: TReleaseNoteDM

	/**
	 * Převodka
	 */
	@Type(() => TTransferNoteDM)
	TransferNoteRIDCalc?: TTransferNoteDM

	/**
	 * undefined
	 */
	@Type(() => TJobCardDM)
	JobCardRIDCalc?: TJobCardDM

	/**
	 * Číslo průvodky
	 */
	@Type(() => TJobCardDM)
	JobCardCalc?: TJobCardDM
}