import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TK2UserLookupDM from './TK2UserLookupDM'
import TCostCentreCollectionDocumentDM from './TCostCentreCollectionDocumentDM'
import EmployeeFunction from './EmployeeFunction'
import EmploymentName from './EmploymentName'
import ProfessionName from './ProfessionName'
import FullfilRequirements from './FullfilRequirements'
import DegreeName from './DegreeName'
import EducationFieldName from './EducationFieldName'
import TEmployedPersonDM from './TEmployedPersonDM'
import TCompanyLinkDM from './TCompanyLinkDM'

/**
 * Historie zařazení
 */
export default class TEmployeeClassificationHistoryDM extends Data {
	public static className = 'TEmployeeClassificationHistoryDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = true

	/**
	 * Skupina práv
	 */
	RightGroupId?: number

	/**
	 * Časové razítko
	 */
	TimeStamp?: string

	/**
	 * Změněno
	 */
	ChangedOn?: Date

	/**
	 * Vytvořeno
	 */
	CreatedOn?: Date

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * ČísPP
	 */
	PrEploymentId?: number

	/**
	 * Datum
	 */
	DateFrom?: Date

	/**
	 * Praxe D.
	 */
	ExperienceDayCount?: number

	/**
	 * Praxe R.
	 */
	ExperienceYearCount?: number

	/**
	 * Praxe P.
	 */
	RequiredExperienceId?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Skupina práv
	 */
	RightGroupIdCalc?: number

	/**
	 * Změnil
	 */
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM

	/**
	 * Vytvořil
	 */
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM

	/**
	 * Středisko kmenové
	 */
	@Type(() => TCostCentreCollectionDocumentDM)
	CostCentreId?: TCostCentreCollectionDocumentDM

	/**
	 * Funkce
	 */
	@Type(() => EmployeeFunction)
	EmployeePosition?: EmployeeFunction

	/**
	 * Zaměstnání
	 */
	@Type(() => EmploymentName)
	EmployeeOccupation?: EmploymentName

	/**
	 * Povolání
	 */
	@Type(() => ProfessionName)
	EmployeeProfession?: ProfessionName

	/**
	 * Kvalifikace
	 */
	@Type(() => FullfilRequirements)
	Qualification?: FullfilRequirements

	/**
	 * Stup.nej.vzděl.
	 */
	@Type(() => DegreeName)
	EducationDegree?: DegreeName

	/**
	 * Stup.pož.vzděl.
	 */
	@Type(() => DegreeName)
	RequiredEducationDegree?: DegreeName

	/**
	 * Dos.obor
	 */
	@Type(() => EducationFieldName)
	AccomplishedEducationField?: EducationFieldName

	/**
	 * Pož. obor
	 */
	@Type(() => EducationFieldName)
	RequiredEducationField?: EducationFieldName

	/**
	 * ČísP.
	 */
	@Type(() => TEmployedPersonDM)
	EmployedPersonId?: TEmployedPersonDM

	/**
	 * undefined
	 */
	@Type(() => TCompanyLinkDM)
	CompanyChild?: TCompanyLinkDM[]

	/**
	 * Změna skupiny práv
	 */
	public ChangeGroupRightCOMMAND() {
		return this.executeCommand('ChangeGroupRightCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public BulkChangeGroupRightCOMMAND() {
		return this.executeCommand('BulkChangeGroupRightCOMMAND')
	}

	/**
	 * Aktualizace počítaných polí
	 */
	public BulkUpdateCalcFieldsCOMMAND() {
		return this.executeCommand('BulkUpdateCalcFieldsCOMMAND')
	}

	/**
	 * Kontrola legislativy
	 */
	public SwitchOffLegislationValidationCOMMAND() {
		return this.executeCommand('SwitchOffLegislationValidationCOMMAND')
	}
}