import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TTownPartDM from './TTownPartDM'
import TK2UserLookupDM from './TK2UserLookupDM'

/**
 * Adresy
 */
export default class TAddressDM extends Data {
	public static className = 'TAddressDM'
	public static primaryKey = 'Id'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.Id!
	}

	public static isHeader = true

	/**
	 * Časové razítko
	 */
	TimeStamp?: string

	/**
	 * Longitude
	 */
	Longitude?: number

	/**
	 * Latitude
	 */
	Latitude?: number

	/**
	 * Ulice
	 */
	Street?: string

	/**
	 * Číslo orientační
	 */
	OrientationNumber?: string

	/**
	 * Název
	 */
	Name?: string

	/**
	 * Změněno
	 */
	ChangedOn?: Date

	/**
	 * Číslo popisné
	 */
	HouseNumber?: string

	/**
	 * Adresy
	 */
	Id?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Adresa
	 */
	OneLineCalc?: string

	/**
	 * Číslo
	 */
	CompleteNumberCalc?: string

	/**
	 * Ulice s číslem
	 */
	CompleteStreetCalc?: string

	/**
	 * Souřadnice
	 */
	CoordinatesCalc?: string

	/**
	 * Část obce
	 */
	@Type(() => TTownPartDM)
	TownPartId?: TTownPartDM

	/**
	 * Změnil
	 */
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM

	/**
	 * Rozdělit ulici
	 */
	public BulkSplitStreetCOMMAND(parameters: TBulkSplitStreetCOMMANDParams) {
		return this.executeCommand('BulkSplitStreetCOMMAND', parameters)
	}

	/**
	 * Rozdělit číslo orientační
	 */
	public BulkSplitOrientNumberCOMMAND(parameters: TBulkSplitOrientNumberCOMMANDParams) {
		return this.executeCommand('BulkSplitOrientNumberCOMMAND', parameters)
	}

	/**
	 * Změna skupiny práv
	 */
	public ChangeGroupRightCOMMAND() {
		return this.executeCommand('ChangeGroupRightCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public BulkChangeGroupRightCOMMAND() {
		return this.executeCommand('BulkChangeGroupRightCOMMAND')
	}

	/**
	 * Aktualizace počítaných polí
	 */
	public BulkUpdateCalcFieldsCOMMAND() {
		return this.executeCommand('BulkUpdateCalcFieldsCOMMAND')
	}

	/**
	 * Kontrola legislativy
	 */
	public SwitchOffLegislationValidationCOMMAND() {
		return this.executeCommand('SwitchOffLegislationValidationCOMMAND')
	}
}

export type TBulkSplitStreetCOMMANDParams = {
	/**
	 * Preferovat číslo popisné
	 */
	PreferHouseNumber?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TBulkSplitOrientNumberCOMMANDParams = {
	/**
	 * Preferovat číslo popisné
	 */
	PreferHouseNumber?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}