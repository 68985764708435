import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TTextDM from './TTextDM'
import TEshopDM from './TEshopDM'
import TK2UserLookupDM from './TK2UserLookupDM'

/**
 * Url adresy pro eshop a web - položky
 */
export default class TWebURLAddressItemDM extends Data {
	public static className = 'TWebURLAddressItemDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = false

	/**
	 * Záznam
	 */
	ModuleItemRID?: number

	/**
	 * Datový modul
	 */
	ModuleId?: number

	/**
	 * Přesměrováno
	 */
	IsRedirected?: string

	/**
	 * Výchozí
	 */
	IsDefault?: string

	/**
	 * URL
	 */
	URL?: string

	/**
	 * Právo na záznam
	 */
	RightGroupId?: number

	/**
	 * Časové razítko
	 */
	TimeStamp?: string

	/**
	 * Změněno
	 */
	ChangedOn?: Date

	/**
	 * Vytvořeno
	 */
	CreatedOn?: Date

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Právo na záznam
	 */
	RightGroupIdCalc?: number

	/**
	 * Jazyk
	 */
	@Type(() => TTextDM)
	LanguageId?: TTextDM

	/**
	 * Web
	 */
	@Type(() => TEshopDM)
	ShopRID?: TEshopDM

	/**
	 * Změnil
	 */
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM

	/**
	 * Vytvořil
	 */
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM
}