import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TK2UserLookupDM from './TK2UserLookupDM'
import TEshopDM from './TEshopDM'
import TEshopCategoryInfoDM from './TEshopCategoryInfoDM'

/**
 * Kategorie
 */
export default class TEshopCategoryAsItemDM extends Data {
	public static className = 'TEshopCategoryAsItemDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = false

	/**
	 * Nezobrazovat mezi kategoriemi
	 */
	IsInvisible?: string

	/**
	 * Vzorová kategorie
	 */
	PatternCategoryRID?: number

	/**
	 * Virtuální kategorie
	 */
	IsVirtual?: string

	/**
	 * OgImage
	 */
	OpenGraphImage?: number

	/**
	 * OG typ
	 */
	OpenGraphTypeId?: number

	/**
	 * Změněno
	 */
	ChangedOn?: Date

	/**
	 * Vytvořeno
	 */
	CreatedOn?: Date

	/**
	 * Právo na záznam
	 */
	RightGroupId?: number

	/**
	 * Cesta
	 */
	CategoryPath?: string

	/**
	 * Časové razítko
	 */
	TimeStamp?: string

	/**
	 * Pořadí
	 */
	SequenceNumber?: number

	/**
	 * Obrázek
	 */
	ImageFileName?: string

	/**
	 * Ikona
	 */
	IconFileName?: string

	/**
	 * Parametry
	 */
	ParameterSetId?: number

	/**
	 * Analytika
	 */
	PageAnalytics?: string

	/**
	 * Kanonická URL
	 */
	PageUrlCanonical?: string

	/**
	 * URL
	 */
	PageUrl?: string

	/**
	 * Klíčová slova
	 */
	PageKeyWord?: string

	/**
	 * Meta popis
	 */
	PageDescription?: string

	/**
	 * Meta titulek
	 */
	PageTitle?: string

	/**
	 * Popis
	 */
	Description?: string

	/**
	 * Hlavní nadpis
	 */
	Title?: string

	/**
	 * Název
	 */
	Name?: string

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * Pořadí
	 */
	RecordNo?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * EX_kod_prech_syst
	 */
	EX_kod_prech_syst?: string

	/**
	 * EX_id_prech_syst
	 */
	EX_id_prech_syst?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * undefined
	 */
	NotShowBitCalc?: number

	/**
	 * Právo na záznam
	 */
	RightGroupIdCalc?: number

	/**
	 * Změnil
	 */
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM

	/**
	 * Vytvořil
	 */
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM

	/**
	 * E-shop
	 */
	@Type(() => TEshopDM)
	ShopRID?: TEshopDM

	/**
	 * Nadřazená kategorie
	 */
	@Type(() => TEshopCategoryInfoDM)
	ParentRID?: TEshopCategoryInfoDM
}