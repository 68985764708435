import Data from 'react-k2-api/Api/Data'

/**
 * Doklady
 */
export default class TDocumentItemDM extends Data {
	public static className = 'TDocumentItemDM'
	public static systemFields = [
		'RecordID',
	]

	public static isHeader = false

	/**
	 * Node
	 */
	NodeId?: number

	/**
	 * Unik
	 */
	Id?: number

	/**
	 * Č. E_
	 */
	FileModuleNumber?: number

	/**
	 * Č. Dm
	 */
	DataModuleNumber?: number

	/**
	 * Č. doku.
	 */
	DocumentId?: number

	/**
	 * Č. dokl.
	 */
	DocumentNumber?: number

	/**
	 * Kniha
	 */
	Book?: string

	/**
	 * Typ dokl.
	 */
	TypeId?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * undefined
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * EX_ES_File_Dalsi_E3
	 */
	EX_ES_File_Dalsi_E3?: number

	/**
	 * EX_ES_File_Zmena_E3
	 */
	EX_ES_File_Zmena_E3?: Date

	/**
	 * EX_ES_File_Size_E3
	 */
	EX_ES_File_Size_E3?: number

	/**
	 * EX_ES_File_Dalsi
	 */
	EX_ES_File_Dalsi?: number

	/**
	 * EX_ES_File_Zmena
	 */
	EX_ES_File_Zmena?: Date

	/**
	 * EX_ES_File_Size
	 */
	EX_ES_File_Size?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * undefined
	 */
	DataModuleNameCalc?: string

	/**
	 * undefined
	 */
	DocumentIdentifierCalc?: string

	/**
	 * undefined
	 */
	DocumentDescriptionCalc?: string

	/**
	 * undefined
	 */
	K2PictureAbbrCalc?: string
}