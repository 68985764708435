import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TK2UserLookupDM from './TK2UserLookupDM'
import TTextDM from './TTextDM'
import TAdressKeyDM from './TAdressKeyDM'
import TTradingPartnerDM from './TTradingPartnerDM'
import AlternativeUnit from './AlternativeUnit'
import TMeasureUnitDM from './TMeasureUnitDM'
import TEkoKomItemDM from './TEkoKomItemDM'

/**
 * Obaly zboží
 */
export default class TEkoKomArticlePackDM extends Data {
	public static className = 'TEkoKomArticlePackDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = false

	/**
	 * Změněno
	 */
	ChangedOn?: Date

	/**
	 * Platné do
	 */
	ValidTo?: Date

	/**
	 * Platné od
	 */
	ValidFrom?: Date

	/**
	 * Název
	 */
	Name?: string

	/**
	 * Zboží
	 */
	ArticleId?: number

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Změnil
	 */
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM

	/**
	 * Omezení obalu
	 */
	@Type(() => TTextDM)
	PackRestriction?: TTextDM

	/**
	 * Adresní klíč
	 */
	@Type(() => TAdressKeyDM)
	CustomerKeyRID?: TAdressKeyDM

	/**
	 * Odběratel/Dodavatel
	 */
	@Type(() => TTradingPartnerDM)
	TradingPartnerId?: TTradingPartnerDM

	/**
	 * Jednotka
	 */
	@Type(() => AlternativeUnit)
	MeasureUnitId?: AlternativeUnit

	/**
	 * Jednotka
	 */
	@Type(() => TMeasureUnitDM)
	ArticleMeasureUnitIdCalc?: TMeasureUnitDM

	/**
	 * undefined
	 */
	@Type(() => TEkoKomItemDM)
	EkokomItemChild?: TEkoKomItemDM[]
}