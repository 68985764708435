import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TJobCardDM from './TJobCardDM'
import TLocationArticleDM from './TLocationArticleDM'
import GoodsName from './GoodsName'
import TWarehouseDM from './TWarehouseDM'
import TTextDM from './TTextDM'
import TOperationDM from './TOperationDM'
import TTradingPartnerDM from './TTradingPartnerDM'
import TMeasureUnitDM from './TMeasureUnitDM'
import TArticleDM from './TArticleDM'
import TCommentDM from './TCommentDM'
import TItemNoteDM from './TItemNoteDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TExternalDocumentItemDM from './TExternalDocumentItemDM'

/**
 * Operace průvodky
 */
export default class TJobCardOperationDM extends Data {
	public static className = 'TJobCardOperationDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = false

	/**
	 * Posun po výrobní dávce
	 */
	IsTransferQtyByLot?: string

	/**
	 * OpTextFormat
	 */
	OperationTextFormat?: number

	/**
	 * SoftSplitting
	 */
	IsSoftSplitting?: string

	/**
	 * PlanScrap
	 */
	IsPlanScrap?: string

	/**
	 * Odvádět
	 */
	CompleteOperation?: string

	/**
	 * Kooperant
	 */
	IsCooperation?: string

	/**
	 * Status
	 */
	StatusId?: number

	/**
	 * Nadřízená operace
	 */
	SuperiorRID?: number

	/**
	 * Operace TP
	 */
	RoutingOperationRID?: number

	/**
	 * Plán. datum do - posl.
	 */
	LastPlannedDateTo?: Date

	/**
	 * Plán. datum od - posl.
	 */
	LastPlannedDateFrom?: Date

	/**
	 * Ukončeno
	 */
	IsFinished?: string

	/**
	 * Fixace
	 */
	IsFixated?: string

	/**
	 * Rozvrženo
	 */
	IsManuallyAdjustedSchedule?: string

	/**
	 * PlannerMessage
	 */
	PlannerMessage?: string

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * Skut. datum do
	 */
	ActualDateTo?: Date

	/**
	 * Skut. datum od
	 */
	ActualDateFrom?: Date

	/**
	 * Částka zm.
	 */
	ScrapAmount?: number

	/**
	 * Částka sk.
	 */
	ActualAmount?: number

	/**
	 * Částka pl.
	 */
	PlannedAmount?: number

	/**
	 * Skut. meziop. čas
	 */
	ActualTeardownTime?: number

	/**
	 * Skut. výrob. čas
	 */
	ActualRunTime?: number

	/**
	 * Skut. přípr. čas
	 */
	ActualSetupTime?: number

	/**
	 * Limit. datum do
	 */
	LimitDateTo?: Date

	/**
	 * Limit. datum od
	 */
	LimitDateFrom?: Date

	/**
	 * Plán. datum do
	 */
	PlannedDateTo?: Date

	/**
	 * Plán. datum od
	 */
	PlannedDateFrom?: Date

	/**
	 * Množ. zmetků
	 */
	ScrapQuantity?: number

	/**
	 * Množství sk.
	 */
	ActualQuantity?: number

	/**
	 * Množství pl.
	 */
	PlanQuantity?: number

	/**
	 * Text operace
	 */
	OperationText?: string

	/**
	 * Datum potv.
	 */
	OperationConfirmationDate?: Date

	/**
	 * Výtěžnost
	 */
	Yield?: number

	/**
	 * Posun
	 */
	TransferQuantity?: number

	/**
	 * Mezioper. čas
	 */
	TeardownTime?: number

	/**
	 * Čas před oper.
	 */
	TimeBefore?: number

	/**
	 * Čas po oper.
	 */
	TimeAfter?: number

	/**
	 * Přípravný čas
	 */
	SetupTime?: number

	/**
	 * Výrobní čas
	 */
	RunTime?: number

	/**
	 * Kód
	 */
	OperationCode?: string

	/**
	 * Pořadí operace
	 */
	SequenceNumber?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * undefined
	 */
	HasAlreadyOutput?: string

	/**
	 * undefined
	 */
	OriginalRID?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Výrobní čas
	 */
	ProductionTimeUMCalc?: number

	/**
	 * Přípravný čas
	 */
	SetupTimeUMCalc?: number

	/**
	 * Čas po
	 */
	TimeAfterUMCalc?: number

	/**
	 * Čas před
	 */
	TimeBeforeUMCalc?: number

	/**
	 * Mezioperační čas
	 */
	TeardownTimeUMCalc?: number

	/**
	 * Popis operace
	 */
	DescriptionCalc?: string

	/**
	 * Plán. čas
	 */
	PlannedDurationCalc?: number

	/**
	 * Skut. čas
	 */
	ActualTimeCalc?: number

	/**
	 * undefined
	 */
	PlannedDate1DateCalc?: Date

	/**
	 * Čas
	 */
	PlannedDate1HourCalc?: Date

	/**
	 * undefined
	 */
	PlannedDate2DateCalc?: Date

	/**
	 * Čas
	 */
	PlannedDate2HourCalc?: Date

	/**
	 * undefined
	 */
	LimitDate1DateCalc?: Date

	/**
	 * Čas
	 */
	LimitDate1TimeCalc?: Date

	/**
	 * undefined
	 */
	LimitDate2DateCalc?: Date

	/**
	 * Čas
	 */
	LimitDate2HourCalc?: Date

	/**
	 * Množství pl.
	 */
	PlannedQuantityUMCalc?: number

	/**
	 * Množství sk.
	 */
	ActualQuantityUMCalc?: number

	/**
	 * Množství zmetků
	 */
	FaultyQuantityUMCalc?: number

	/**
	 * undefined
	 */
	ActualDate1Calc?: Date

	/**
	 * čas
	 */
	ActualTime1Calc?: Date

	/**
	 * undefined
	 */
	ActualDate2Calc?: Date

	/**
	 * Čas
	 */
	ActualTime2Calc?: Date

	/**
	 * Čas posunu
	 */
	TransferTimeCalc?: number

	/**
	 * Rozd. množství
	 */
	DifferenceQuantityCalc?: number

	/**
	 * Rozd. částka
	 */
	DifferenceAmountCalc?: number

	/**
	 * Rozd. čas
	 */
	DifferenceTimeCalc?: number

	/**
	 * Rozd. čas přípr.
	 */
	DifferenceSetupTimeCalc?: number

	/**
	 * Rozd. čas výr.
	 */
	DifferenceOperationTimeCalc?: number

	/**
	 * Rozd. čas meziop.
	 */
	DifferenceTeardownTimeCalc?: number

	/**
	 * Přípravný čas
	 */
	ActualSetupTimeUMCalc?: number

	/**
	 * Výrobní čas
	 */
	ActualProductionTimeUMCalc?: number

	/**
	 * Mezioperační čas
	 */
	ActualTeardownTimeUMCalc?: number

	/**
	 * Čas (%)
	 */
	SpentTimePercentageCalc?: number

	/**
	 * Plán. hodn.
	 */
	PlannedValueCalc?: number

	/**
	 * Skut. hodn.
	 */
	ActualValueCalc?: number

	/**
	 * Plnění (%)
	 */
	PercentageCompleteCalc?: number

	/**
	 * Termín vykrytí
	 */
	CoverageDateCalc?: Date

	/**
	 * BatchRatioCalc
	 */
	BatchRatioCalc?: number

	/**
	 * Časové razítko vykrytí
	 */
	CoverageTimeStampCalc?: Date

	/**
	 * Kód operace - základ
	 */
	OperationCodeBaseCalc?: string

	/**
	 * Kód operace - část
	 */
	OperationCodePartCalc?: string

	/**
	 * Datum blokace pracoviště
	 */
	BlockingDateCalc?: Date

	/**
	 * Plán. čas celkem
	 */
	MASPlannedTimeCalc?: number

	/**
	 * Plán. čas přípravný
	 */
	MASPlannedSetupTimeCalc?: number

	/**
	 * Plán. čas výrobní
	 */
	MASPlannedProductionTimeCalc?: number

	/**
	 * Plán. čas mezioperační
	 */
	MASPlannedTeardownTimeCalc?: number

	/**
	 * Plán. množství
	 */
	MASPlannedQuantityCalc?: number

	/**
	 *  
	 */
	ExistsPlannerMessageCalc?: string

	/**
	 * Průvodka
	 */
	@Type(() => TJobCardDM)
	HeaderRID?: TJobCardDM

	/**
	 * Umístění
	 */
	@Type(() => TLocationArticleDM)
	LocationId?: TLocationArticleDM

	/**
	 * Čís.textu
	 */
	@Type(() => GoodsName)
	TextId?: GoodsName

	/**
	 * Sklad
	 */
	@Type(() => TWarehouseDM)
	WarehouseId?: TWarehouseDM

	/**
	 * Jedn.času
	 */
	@Type(() => TTextDM)
	TimeUnitId?: TTextDM

	/**
	 * Operace
	 */
	@Type(() => TOperationDM)
	OperationId?: TOperationDM

	/**
	 * Kooperant
	 */
	@Type(() => TTradingPartnerDM)
	CooperantId?: TTradingPartnerDM

	/**
	 * undefined
	 */
	@Type(() => TMeasureUnitDM)
	MeasureUnitIdCalc?: TMeasureUnitDM

	/**
	 * undefined
	 */
	@Type(() => TArticleDM)
	ProductIdCalc?: TArticleDM

	/**
	 * Stav vykrytí
	 */
	@Type(() => TTextDM)
	CoveredBitCalc?: TTextDM

	/**
	 *  
	 */
	@Type(() => TTextDM)
	CoveredByStockBitCalc?: TTextDM

	/**
	 * Operace
	 */
	@Type(() => TOperationDM)
	OperationByIdInputCalc?: TOperationDM

	/**
	 * Operace
	 */
	@Type(() => TOperationDM)
	OperationByNameInputIdCalc?: TOperationDM

	/**
	 * Stav vykrytí rozšířený
	 */
	@Type(() => TTextDM)
	CoveredExtendedCalc?: TTextDM

	/**
	 * Komentáře
	 */
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]

	/**
	 * undefined
	 */
	@Type(() => TItemNoteDM)
	ItemNotesChild?: TItemNoteDM[]

	/**
	 * undefined
	 */
	@Type(() => TLanguageMemoTranslationDM)
	ItemTextChild?: TLanguageMemoTranslationDM[]

	/**
	 * Dokumenty
	 */
	@Type(() => TExternalDocumentItemDM)
	ExternalDocumentItemChild?: TExternalDocumentItemDM[]
}