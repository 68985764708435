import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TK2UserLookupDM from './TK2UserLookupDM'
import TCurrencyDM from './TCurrencyDM'
import TCode6DocumentDM from './TCode6DocumentDM'
import TCode5DocumentDM from './TCode5DocumentDM'
import TCode4DocumentDM from './TCode4DocumentDM'
import TCode3DocumentDM from './TCode3DocumentDM'
import TDeviceDocumentDM from './TDeviceDocumentDM'
import TypClaim from './TypClaim'
import TPaymentMethodDM from './TPaymentMethodDM'
import TOfficerDocumentDM from './TOfficerDocumentDM'
import TCode2DocumentDM from './TCode2DocumentDM'
import TCode1DocumentDM from './TCode1DocumentDM'
import TArticleCodeDocumentDM from './TArticleCodeDocumentDM'
import TContractCodeDocumentDM from './TContractCodeDocumentDM'
import TAddressDM from './TAddressDM'
import PrDedMand from './PrDedMand'
import TPartnerDM from './TPartnerDM'
import TPrDeductionDM from './TPrDeductionDM'
import PrInsCode from './PrInsCode'
import TAccountingChartDM from './TAccountingChartDM'
import TEmployedPersonDM from './TEmployedPersonDM'
import TTextDM from './TTextDM'
import TBanksDM from './TBanksDM'
import TPrPeriodDM from './TPrPeriodDM'
import TBookDM from './TBookDM'
import TMarketingActivityTypeDM from './TMarketingActivityTypeDM'
import TCountryDM from './TCountryDM'
import TTownPartDM from './TTownPartDM'
import TTownDM from './TTownDM'
import TMatchingSymbolDM from './TMatchingSymbolDM'
import KonstSymb from './KonstSymb'
import TCostCentreCollectionDocumentDM from './TCostCentreCollectionDocumentDM'
import TCommentDM from './TCommentDM'
import TPrLiabilityDM from './TPrLiabilityDM'
import TPrDeductionOutputComponentDM from './TPrDeductionOutputComponentDM'
import TPrDeductionBankAccountDM from './TPrDeductionBankAccountDM'
import TPrOutputComponentDM from './TPrOutputComponentDM'
import TPrEmploymentDM from './TPrEmploymentDM'
import TExternalDocumentItemDM from './TExternalDocumentItemDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TCompanyLinkPrDeductionDM from './TCompanyLinkPrDeductionDM'
import TAttachedDocumentDM from './TAttachedDocumentDM'
import TOldNoteDM from './TOldNoteDM'

/**
 * Ostatní srážky
 */
export default class TPrDeductionAllDM extends Data {
	public static className = 'TPrDeductionAllDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = true

	/**
	 * Časové razítko
	 */
	TimeStamp?: string

	/**
	 * Vytvořeno
	 */
	CreatedOn?: Date

	/**
	 * Právo na záznam
	 */
	RightGroupId?: number

	/**
	 * XMLField
	 */
	XMLField?: string

	/**
	 * Popis
	 */
	Description?: string

	/**
	 * Dříve splaceno
	 */
	AmountPaid?: number

	/**
	 * Částka celkem
	 */
	TotalAmount?: number

	/**
	 * Částka měsíčně
	 */
	PeriodAmount?: number

	/**
	 * Platnost do
	 */
	ValidTo?: Date

	/**
	 * Platnost od/Doručeno prvnímu plátci
	 */
	ValidFrom?: Date

	/**
	 * Nabytí právní moci
	 */
	LegalDate?: Date

	/**
	 * Č. sml./jedn.
	 */
	ContractNumber?: string

	/**
	 * Středisko
	 */
	CostCentreOldId?: number

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * Změněno
	 */
	ChangedOn?: Date

	/**
	 * Variabil.s.
	 */
	ReferenceNumberOld?: string

	/**
	 * Konst.s.
	 */
	ConstantSymbolOld?: string

	/**
	 * Název
	 */
	Name?: string

	/**
	 * Pořadí
	 */
	Id?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Bank. účet
	 */
	AccountNumberCalc?: string

	/**
	 * IBAN
	 */
	IBANCalc?: string

	/**
	 * Kód poplatku
	 */
	PaymentItemCalc?: string

	/**
	 * Platební titul
	 */
	ChargeCodeCalc?: string

	/**
	 * Spec. symb.
	 */
	SpecificSymbolCalc?: string

	/**
	 * Identifikace
	 */
	DescriptionCalc?: string

	/**
	 * Hromadná platba
	 */
	BulkPaymentBitCalc?: number

	/**
	 * Jednotlivá platba
	 */
	IndividualPaymentIdCalc?: number

	/**
	 * Nevytvářet závazky z mezd
	 */
	NotCreatePrLiabilityBitCalc?: number

	/**
	 * Zobrazit v sestavě výplatní lístek
	 */
	ShowInReportBitCalc?: number

	/**
	 * Kód banky
	 */
	BankCodeNumberCalc?: string

	/**
	 * Včetně ostatních příjmů
	 */
	IncludeOtherIncomeCalc?: string

	/**
	 * Zůstatek
	 */
	BalanceCalc?: number

	/**
	 * Sraženo celkem
	 */
	DeductedTotalAmountCalc?: number

	/**
	 * Doručení aktuálnímu plátci
	 */
	DeliveryDateCalc?: Date

	/**
	 * Celkem
	 */
	AmountCCalc?: number

	/**
	 * Částka závazků
	 */
	PrComponentPeriodAmountCalc?: number

	/**
	 * Částka složek
	 */
	DeductedAmountCalc?: number

	/**
	 * Chyba
	 */
	ErrorCalc?: number

	/**
	 * Částka
	 */
	PrLiabilityAmountCalc?: number

	/**
	 * Měna
	 */
	WageDeductionCurrencyCalc?: string

	/**
	 * Variabilní symbol
	 */
	DecodedReferenceNumberCalc?: string

	/**
	 * Specifický symbol
	 */
	SpecificSymbolDecodedCalc?: string

	/**
	 * Suma závazků z mezd
	 */
	PrLiabilityTotalAmountCalc?: number

	/**
	 * Suma výstupnich složek ze všech období
	 */
	PrOutComAllPerTotalAmountCalc?: number

	/**
	 * Suma výstupnich složek před nastaveným obdobím
	 */
	PrOutComPrevPerTotalAmountCalc?: number

	/**
	 * undefined
	 */
	TextHeaderMemoCalc?: string

	/**
	 * undefined
	 */
	TextFooterMemoCalc?: string

	/**
	 * Poslední komentář
	 */
	LastCommentParamCalc?: string

	/**
	 * Ulice (Adresa)
	 */
	AddressIdStreetCalc?: string

	/**
	 * Číslo orientační (Adresa)
	 */
	AddressIdOrientationNumberCalc?: string

	/**
	 * Číslo popisné (Adresa)
	 */
	AddressIdHouseNumberCalc?: string

	/**
	 * Název (Adresa)
	 */
	AddressIdNameCalc?: string

	/**
	 * PSČ (Adresa)
	 */
	AddressIdTownCodeCalc?: string

	/**
	 * Souřadnice (Adresa)
	 */
	AddressIdCoordinatesCalc?: string

	/**
	 * Variabilní symbol
	 */
	ReferenceNumberCalc?: string

	/**
	 * Právo na záznam
	 */
	RightGroupIdCalc?: number

	/**
	 * Vytvořil
	 */
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM

	/**
	 * Měna
	 */
	@Type(() => TCurrencyDM)
	Currency?: TCurrencyDM

	/**
	 * Kód 6
	 */
	@Type(() => TCode6DocumentDM)
	Code6Id?: TCode6DocumentDM

	/**
	 * Kód 5
	 */
	@Type(() => TCode5DocumentDM)
	Code5Id?: TCode5DocumentDM

	/**
	 * Kód 4
	 */
	@Type(() => TCode4DocumentDM)
	Code4Id?: TCode4DocumentDM

	/**
	 * Kód 3
	 */
	@Type(() => TCode3DocumentDM)
	Code3Id?: TCode3DocumentDM

	/**
	 * Prostředek
	 */
	@Type(() => TDeviceDocumentDM)
	DeviceRID?: TDeviceDocumentDM

	/**
	 * Typ pohledávky
	 */
	@Type(() => TypClaim)
	ClaimTypeId?: TypClaim

	/**
	 * Způsob platby
	 */
	@Type(() => TPaymentMethodDM)
	PaymentMethodId?: TPaymentMethodDM

	/**
	 * Referent
	 */
	@Type(() => TOfficerDocumentDM)
	OfficerId?: TOfficerDocumentDM

	/**
	 * Kód 2
	 */
	@Type(() => TCode2DocumentDM)
	Code2Id?: TCode2DocumentDM

	/**
	 * Kód 1
	 */
	@Type(() => TCode1DocumentDM)
	Code1Id?: TCode1DocumentDM

	/**
	 * Kód zboží
	 */
	@Type(() => TArticleCodeDocumentDM)
	ArticleCodeId?: TArticleCodeDocumentDM

	/**
	 * Kód zakázky
	 */
	@Type(() => TContractCodeDocumentDM)
	ContractCodeRID?: TContractCodeDocumentDM

	/**
	 * Adresa
	 */
	@Type(() => TAddressDM)
	AddressId?: TAddressDM

	/**
	 * Změnil
	 */
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM

	/**
	 * Typ srážky
	 */
	@Type(() => PrDedMand)
	DeductionTypeId?: PrDedMand

	/**
	 * Adresát
	 */
	@Type(() => TPartnerDM)
	PartnerId?: TPartnerDM

	/**
	 * Srážka organizace
	 */
	@Type(() => TPrDeductionDM)
	OrganizationDeductionId?: TPrDeductionDM

	/**
	 * Zdr. poj.
	 */
	@Type(() => PrInsCode)
	HealthInsuranceCodeId?: PrInsCode

	/**
	 * Analytický účet
	 */
	@Type(() => TAccountingChartDM)
	AccountId?: TAccountingChartDM

	/**
	 * Pracovník
	 */
	@Type(() => TEmployedPersonDM)
	EmployedPersonId?: TEmployedPersonDM

	/**
	 * Z
	 */
	@Type(() => TTextDM)
	InvalidRecordImgCalc?: TTextDM

	/**
	 * Banka
	 */
	@Type(() => TBanksDM)
	BankIdCalc?: TBanksDM

	/**
	 * Měna účtu
	 */
	@Type(() => TCurrencyDM)
	CurrencyCalc?: TCurrencyDM

	/**
	 * Vystavil
	 */
	@Type(() => TK2UserLookupDM)
	IssuedByCalc?: TK2UserLookupDM

	/**
	 * Mzdová perioda
	 */
	@Type(() => TPrPeriodDM)
	PeriodIdCalc?: TPrPeriodDM

	/**
	 * Kniha mzdových dokladů
	 */
	@Type(() => TBookDM)
	PrDocumentBookIdCalc?: TBookDM

	/**
	 * Zpracované období
	 */
	@Type(() => TPrPeriodDM)
	ProcessedPeriodIdCalc?: TPrPeriodDM

	/**
	 * Nastavené období
	 */
	@Type(() => TPrPeriodDM)
	UserPeriodIdCalc?: TPrPeriodDM

	/**
	 * D
	 */
	@Type(() => TTextDM)
	DocumentExistsCalc?: TTextDM

	/**
	 * undefined
	 */
	@Type(() => TMarketingActivityTypeDM)
	ActivityTypeRIDCalc?: TMarketingActivityTypeDM

	/**
	 * Stát (Adresa)
	 */
	@Type(() => TCountryDM)
	AddressIdCountryIdCalc?: TCountryDM

	/**
	 * Část obce (Adresa)
	 */
	@Type(() => TTownPartDM)
	AddressIdTownPartIdCalc?: TTownPartDM

	/**
	 * Číslo (Adresa)
	 */
	@Type(() => TTownDM)
	AddressIdTownIdCalc?: TTownDM

	/**
	 * Párovací symbol
	 */
	@Type(() => TMatchingSymbolDM)
	MatchingSymbolIdCalc?: TMatchingSymbolDM

	/**
	 * Konstantní symbol
	 */
	@Type(() => KonstSymb)
	ConstantSymbolCalc?: KonstSymb

	/**
	 * Středisko
	 */
	@Type(() => TCostCentreCollectionDocumentDM)
	CostCentreIdCalc?: TCostCentreCollectionDocumentDM

	/**
	 * Komentáře
	 */
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]

	/**
	 * Závazky z mezd
	 */
	@Type(() => TPrLiabilityDM)
	PrLiabilityChild?: TPrLiabilityDM[]

	/**
	 * Výstupní mzdové složky v nastaveném období
	 */
	@Type(() => TPrDeductionOutputComponentDM)
	PrComponentOutInPeriodChild?: TPrDeductionOutputComponentDM[]

	/**
	 * Bankovní účty
	 */
	@Type(() => TPrDeductionBankAccountDM)
	BankAccountChild?: TPrDeductionBankAccountDM[]

	/**
	 * Závazky z mezd v nastaveném období
	 */
	@Type(() => TPrLiabilityDM)
	PrLiabilityPeriodChild?: TPrLiabilityDM[]

	/**
	 * Výstupní mzdové složky
	 */
	@Type(() => TPrOutputComponentDM)
	PrComponentOutChild?: TPrOutputComponentDM[]

	/**
	 * undefined
	 */
	@Type(() => TPrEmploymentDM)
	PrEmploymentChild?: TPrEmploymentDM[]

	/**
	 * Dokumenty
	 */
	@Type(() => TExternalDocumentItemDM)
	ExternalDocumentItemChild?: TExternalDocumentItemDM[]

	/**
	 * Text hlavičky
	 */
	@Type(() => TLanguageMemoTranslationDM)
	TextHeaderChild?: TLanguageMemoTranslationDM[]

	/**
	 * Text patičky
	 */
	@Type(() => TLanguageMemoTranslationDM)
	TextFooterChild?: TLanguageMemoTranslationDM[]

	/**
	 * undefined
	 */
	@Type(() => TCompanyLinkPrDeductionDM)
	CompanyChild?: TCompanyLinkPrDeductionDM[]

	/**
	 * Svázané doklady
	 */
	@Type(() => TAttachedDocumentDM)
	AttachedDocumentOldChild?: TAttachedDocumentDM[]

	/**
	 * Poznámky
	 */
	@Type(() => TOldNoteDM)
	DeprecatedNoteOldChild?: TOldNoteDM[]

	/**
	 * Období zpracování mezd
	 */
	public PrPeriodChangeCOMMAND(parameters: TPrPeriodChangeCOMMANDParams) {
		return this.executeCommand('PrPeriodChangeCOMMAND', parameters)
	}

	/**
	 * Vystavení závazků z mezd
	 */
	public BulkIssueLiabCOMMAND(parameters: TBulkIssueLiabCOMMANDParams) {
		return this.executeCommand('BulkIssueLiabCOMMAND', parameters)
	}

	/**
	 * Zneplatnění
	 */
	public BulkInvalidationCOMMAND() {
		return this.executeCommand('BulkInvalidationCOMMAND')
	}

	/**
	 * Zplatnění
	 */
	public BulkUnInvalidationCOMMAND() {
		return this.executeCommand('BulkUnInvalidationCOMMAND')
	}

	/**
	 * Zneplatnění/Zplatnění
	 */
	public InvalidationCOMMAND() {
		return this.executeCommand('InvalidationCOMMAND')
	}

	/**
	 * Potvrzení
	 */
	public BulkConfirmCOMMAND() {
		return this.executeCommand('BulkConfirmCOMMAND')
	}

	/**
	 * Odpotvrzení
	 */
	public BulkUnconfirmCOMMAND() {
		return this.executeCommand('BulkUnconfirmCOMMAND')
	}

	/**
	 * Změna data potvrzení
	 */
	public BulkChangeConfirmationDateCOMMAND(parameters: TBulkChangeConfirmationDateCOMMANDParams) {
		return this.executeCommand('BulkChangeConfirmationDateCOMMAND', parameters)
	}

	/**
	 * Storno
	 */
	public BulkCancelCOMMAND() {
		return this.executeCommand('BulkCancelCOMMAND')
	}

	/**
	 * Odstorno
	 */
	public BulkUnCancelCOMMAND() {
		return this.executeCommand('BulkUnCancelCOMMAND')
	}

	/**
	 * Potvrzení
	 */
	public ConfirmCOMMAND() {
		return this.executeCommand('ConfirmCOMMAND')
	}

	/**
	 * Odpotvrzení
	 */
	public UnconfirmCOMMAND() {
		return this.executeCommand('UnconfirmCOMMAND')
	}

	/**
	 * Změna data potvrzení
	 */
	public ChangeConfirmationDateCOMMAND(parameters: TChangeConfirmationDateCOMMANDParams) {
		return this.executeCommand('ChangeConfirmationDateCOMMAND', parameters)
	}

	/**
	 * Storno/Odstorno
	 */
	public CancelCOMMAND() {
		return this.executeCommand('CancelCOMMAND')
	}

	/**
	 * Zrušení stavu
	 */
	public ClearRecordStatusCOMMAND() {
		return this.executeCommand('ClearRecordStatusCOMMAND')
	}

	/**
	 * Změna stavu
	 */
	public ChangeRecordStatusCOMMAND() {
		return this.executeCommand('ChangeRecordStatusCOMMAND')
	}

	/**
	 * 
	 */
	public ChangeRecordStatusSWSCOMMAND(parameters: TChangeRecordStatusSWSCOMMANDParams) {
		return this.executeCommand('ChangeRecordStatusSWSCOMMAND', parameters)
	}

	/**
	 * Zrušení stavu
	 */
	public BulkClearRecordStatusCOMMAND() {
		return this.executeCommand('BulkClearRecordStatusCOMMAND')
	}

	/**
	 * Změna stavu
	 */
	public BulkChangeRecordStatusCOMMAND() {
		return this.executeCommand('BulkChangeRecordStatusCOMMAND')
	}

	/**
	 * Změna atributů
	 */
	public BulkChangeAttributesCOMMAND(parameters: TBulkChangeAttributesCOMMANDParams) {
		return this.executeCommand('BulkChangeAttributesCOMMAND', parameters)
	}

	/**
	 * Aktualizace sady atributů
	 */
	public BulkActualizeAttributesCOMMAND(parameters: TBulkActualizeAttributesCOMMANDParams) {
		return this.executeCommand('BulkActualizeAttributesCOMMAND', parameters)
	}

	/**
	 * Aktualizace sady atributů
	 */
	public ActualizeAttributesCOMMAND() {
		return this.executeCommand('ActualizeAttributesCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public ChangeGroupRightCOMMAND() {
		return this.executeCommand('ChangeGroupRightCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public BulkChangeGroupRightCOMMAND() {
		return this.executeCommand('BulkChangeGroupRightCOMMAND')
	}

	/**
	 * Aktualizace počítaných polí
	 */
	public BulkUpdateCalcFieldsCOMMAND() {
		return this.executeCommand('BulkUpdateCalcFieldsCOMMAND')
	}

	/**
	 * Kontrola legislativy
	 */
	public SwitchOffLegislationValidationCOMMAND() {
		return this.executeCommand('SwitchOffLegislationValidationCOMMAND')
	}
}

export type TPrPeriodChangeCOMMANDParams = {
	/**
	 * Nastavené období
	 */
	IdPeriod?: number
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TBulkIssueLiabCOMMANDParams = {
	/**
	 * Aktuální období
	 */
	Prefix?: number
	/**
	 * Kniha závazků
	 */
	Book?: number
	/**
	 * Datum splatnosti
	 */
	DateDue?: Date
	/**
	 * Vytvořit kontejner dokladů
	 */
	InsertToFilter?: boolean
	/**
	 * Stornovat dříve vytvořené závazky
	 */
	CancelPrevious?: boolean
	/**
	 * Stornovat i potvrzené závazky
	 */
	CancelConfirmed?: boolean
	/**
	 * Potvrdit nově vytvořené závazky
	 */
	ConfirmLiab?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TBulkChangeConfirmationDateCOMMANDParams = {
	/**
	 * Datum potvrzení
	 */
	Confirmation1Date?: Date
	/**
	 * Hodina potvrzení
	 */
	Confirmation1Time?: Date
	/**
	 * Datum 2. potvrzení
	 */
	Confirmation2Date?: Date
	/**
	 * Hodina 2. potvrzení
	 */
	Confirmation2Time?: Date
	/**
	 * Kontrolovat záporné stavy
	 */
	CheckNegativeState?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TChangeConfirmationDateCOMMANDParams = {
	/**
	 * Datum potvrzení
	 */
	Confirmation1Date?: Date
	/**
	 * Hodina potvrzení
	 */
	Confirmation1Time?: Date
	/**
	 * Datum 2. potvrzení
	 */
	Confirmation2Date?: Date
	/**
	 * Hodina 2. potvrzení
	 */
	Confirmation2Time?: Date
	/**
	 * Kontrolovat záporné stavy
	 */
	CheckNegativeState?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TChangeRecordStatusSWSCOMMANDParams = {
	/**
	 * StatusId
	 */
	StatusId?: number
}

export type TBulkChangeAttributesCOMMANDParams = {
	/**
	 * Smazat atributy
	 */
	DeleteAttribute?: boolean
	/**
	 * Smazat všechny volné atributy
	 */
	DeleteFreeAttribute?: boolean
	/**
	 * Nastavit hodnoty atributů
	 */
	SetValues?: boolean
	/**
	 * Přidat nepřipojené atributy
	 */
	AddFreeAttributes?: boolean
	/**
	 * FreeAttributeEnabled
	 */
	FreeAttributeEnabled?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TBulkActualizeAttributesCOMMANDParams = {
	/**
	 * Aktualizovat včetně samotné sady atributů
	 */
	ActualizeAttributeSet?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}