import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TTextDM from './TTextDM'
import TAddressDM from './TAddressDM'

/**
 * Adresy kontaktní osoby
 */
export default class TContactPersonAddressDM extends Data {
	public static className = 'TContactPersonAddressDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = false

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * undefined
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * undefined
	 */
	TaxNumber?: string

	/**
	 * undefined
	 */
	Description?: string

	/**
	 * undefined
	 */
	AddressName?: string

	/**
	 * undefined
	 */
	AddressRID?: number

	/**
	 * undefined
	 */
	VATRegNumber?: string

	/**
	 * undefined
	 */
	CompanyRegNumber?: string

	/**
	 * undefined
	 */
	Email?: string

	/**
	 * undefined
	 */
	Phone?: string

	/**
	 * undefined
	 */
	RID?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * undefined
	 */
	@Type(() => TTextDM)
	SourceId?: TTextDM

	/**
	 * undefined
	 */
	@Type(() => TAddressDM)
	AddressId?: TAddressDM

	/**
	 * undefined
	 */
	@Type(() => TTextDM)
	TypeId?: TTextDM
}