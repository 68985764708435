import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TTextDM from './TTextDM'
import TWebModuleCategoryDM from './TWebModuleCategoryDM'
import TWebAppointmentDM from './TWebAppointmentDM'
import TWebNewsDM from './TWebNewsDM'
import TWebCareerDM from './TWebCareerDM'
import TWebTrainingDM from './TWebTrainingDM'
import TEshopBannerDM from './TEshopBannerDM'
import TWebDownloadDM from './TWebDownloadDM'
import TWebSocialNetworkDM from './TWebSocialNetworkDM'
import TWebTimelineDM from './TWebTimelineDM'
import TWebSupportDM from './TWebSupportDM'
import TWebFAQDM from './TWebFAQDM'
import TWebDictionaryDM from './TWebDictionaryDM'
import TWebBranchDM from './TWebBranchDM'
import TWebContactPersonDM from './TWebContactPersonDM'
import TWebImageDM from './TWebImageDM'
import TWebVideoDM from './TWebVideoDM'
import TRegisteredEmailDM from './TRegisteredEmailDM'
import TWebReferenceDM from './TWebReferenceDM'
import TWebPartnerDM from './TWebPartnerDM'
import TWebPortfolioDM from './TWebPortfolioDM'
import TWebBlogDM from './TWebBlogDM'
import TWebAdvantageDM from './TWebAdvantageDM'

/**
 * Propojení modulů
 */
export default class TWebModuleLinkDM extends Data {
	public static className = 'TWebModuleLinkDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = false

	/**
	 * Pořadí
	 */
	SequenceNumber?: number

	/**
	 * Položka modulu
	 */
	SlaveRID?: number

	/**
	 * Datový modul
	 */
	SlaveDataModuleNumber?: number

	/**
	 * Položka modulu
	 */
	MasterRID?: number

	/**
	 * Datový modul
	 */
	MasterDataModuleNumber?: number

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Název
	 */
	NameCalc?: string

	/**
	 * undefined
	 */
	WebRIDCalc?: number

	/**
	 * Modul webu
	 */
	@Type(() => TTextDM)
	SlaveModuleId?: TTextDM

	/**
	 * Modul webu
	 */
	@Type(() => TTextDM)
	MasterModuleId?: TTextDM

	/**
	 * Typ připojeného prvku
	 */
	@Type(() => TTextDM)
	SlaveTypeIdCalc?: TTextDM

	/**
	 * Kategorie modulu
	 */
	@Type(() => TWebModuleCategoryDM)
	CategoryRIDCalc?: TWebModuleCategoryDM

	/**
	 * Termín
	 */
	@Type(() => TWebAppointmentDM)
	AppointmentRIDCalc?: TWebAppointmentDM

	/**
	 * Novinka
	 */
	@Type(() => TWebNewsDM)
	NewsRIDCalc?: TWebNewsDM

	/**
	 * Kariéra
	 */
	@Type(() => TWebCareerDM)
	CareerRIDCalc?: TWebCareerDM

	/**
	 * Školení
	 */
	@Type(() => TWebTrainingDM)
	LectorRIDCalc?: TWebTrainingDM

	/**
	 * Banner
	 */
	@Type(() => TEshopBannerDM)
	BannerRIDCalc?: TEshopBannerDM

	/**
	 * Ke stažení
	 */
	@Type(() => TWebDownloadDM)
	DownloadRIDCalc?: TWebDownloadDM

	/**
	 * Sociální síť
	 */
	@Type(() => TWebSocialNetworkDM)
	SocialNetworkRIDCalc?: TWebSocialNetworkDM

	/**
	 * Historie
	 */
	@Type(() => TWebTimelineDM)
	TimelineRIDCalc?: TWebTimelineDM

	/**
	 * Podpora
	 */
	@Type(() => TWebSupportDM)
	SupportRIDCalc?: TWebSupportDM

	/**
	 * FAQ
	 */
	@Type(() => TWebFAQDM)
	FaqRIDCalc?: TWebFAQDM

	/**
	 * Pojem
	 */
	@Type(() => TWebDictionaryDM)
	DictionaryRIDCalc?: TWebDictionaryDM

	/**
	 * Pobočka
	 */
	@Type(() => TWebBranchDM)
	BranchRIDCalc?: TWebBranchDM

	/**
	 * Kontaktní osoba
	 */
	@Type(() => TWebContactPersonDM)
	ContactPersonRIDCalc?: TWebContactPersonDM

	/**
	 * Obrázek
	 */
	@Type(() => TWebImageDM)
	ImageRIDCalc?: TWebImageDM

	/**
	 * Video
	 */
	@Type(() => TWebVideoDM)
	VideoRIDCalc?: TWebVideoDM

	/**
	 * Adresa
	 */
	@Type(() => TRegisteredEmailDM)
	NewsletterRIDCalc?: TRegisteredEmailDM

	/**
	 * Reference
	 */
	@Type(() => TWebReferenceDM)
	ReferenceRIDCalc?: TWebReferenceDM

	/**
	 * Partner
	 */
	@Type(() => TWebPartnerDM)
	PartnerRIDCalc?: TWebPartnerDM

	/**
	 * Služba
	 */
	@Type(() => TWebPortfolioDM)
	PortfolioRIDCalc?: TWebPortfolioDM

	/**
	 * Blog
	 */
	@Type(() => TWebBlogDM)
	BlogRIDCalc?: TWebBlogDM

	/**
	 * Přednost
	 */
	@Type(() => TWebAdvantageDM)
	AdvantageRIDCalc?: TWebAdvantageDM
}