import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import ReminderID from './ReminderID'
import TReminderDM from './TReminderDM'
import TTextDM from './TTextDM'

/**
 * Položky upomínek
 */
export default class TDocumentReminderDM extends Data {
	public static className = 'TDocumentReminderDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = false

	/**
	 * Počet dnů prodlení
	 */
	DelayDays?: number

	/**
	 * Úroky z prodlení
	 */
	LateCharges?: number

	/**
	 * Úroky
	 */
	LateChargesC?: number

	/**
	 * Zbývá zaplatit
	 */
	RemainingAmount?: number

	/**
	 * Doplatit
	 */
	RemainingAmountC?: number

	/**
	 * Zaplaceno
	 */
	AmountPaid?: number

	/**
	 * Zaplaceno
	 */
	AmountPaidC?: number

	/**
	 * Brutto
	 */
	AmountGross?: number

	/**
	 * Brutto
	 */
	AmountGrossC?: number

	/**
	 * Datum splatnosti
	 */
	DueDate?: Date

	/**
	 * Datum úč. případu
	 */
	AccountingTransactionDate?: Date

	/**
	 * Číslo dokladu
	 */
	DocumentRID?: number

	/**
	 * Typ dokladu
	 */
	DocumentTypeId?: number

	/**
	 * Číslo pol.
	 */
	SequenceNumber?: number

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Upomínka
	 */
	ReminderDocumentIdentificationCalc?: string

	/**
	 * Datum
	 */
	ReminderDateCalc?: Date

	/**
	 * Měna
	 */
	ReminderCurrencyCalc?: string

	/**
	 * Poř. číslo
	 */
	@Type(() => ReminderID)
	ReminderSequenceNumber?: ReminderID

	/**
	 * Upomínka
	 */
	@Type(() => TReminderDM)
	HeaderRID?: TReminderDM

	/**
	 * Položky upomínky
	 */
	@Type(() => TTextDM)
	ConfirmedOrCanceledIdCalc?: TTextDM
}