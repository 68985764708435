import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import ISWeb from './ISWeb'

/**
 * Web services
 */
export default class TSpecialWebApplicationWebServicesItems extends Data {
	public static className = 'TSpecialWebApplicationWebServicesItems'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = false

	/**
	 * Master
	 */
	Master?: number

	/**
	 * Priority
	 */
	Priority?: number

	/**
	 * RID
	 */
	RID?: number

	/**
	 * RecordMod
	 */
	RecordMod?: number

	/**
	 * RecordES
	 */
	RecordES?: number

	/**
	 * RID
	 */
	RecordID?: number

	/**
	 * RecordState
	 */
	RecordState?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Is primary
	 */
	IsPrimaryCalc?: string

	/**
	 * Url
	 */
	UrlCalc?: string

	/**
	 * Web service
	 */
	@Type(() => ISWeb)
	WebServiceId?: ISWeb
}