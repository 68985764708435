import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TArticleDM from './TArticleDM'
import TK2UserLookupDM from './TK2UserLookupDM'
import AssetPlanType from './AssetPlanType'
import TypVyr from './TypVyr'
import PurchaseWay from './PurchaseWay'
import AccKindPurch from './AccKindPurch'
import MajSkup from './MajSkup'
import FunctType from './FunctType'
import ConstrType from './ConstrType'
import ZarStatus from './ZarStatus'
import AlternativeUnit from './AlternativeUnit'
import TProducerDM from './TProducerDM'
import TPeriodDM from './TPeriodDM'
import TBookDM from './TBookDM'
import TTextDM from './TTextDM'
import TPeriodListDM from './TPeriodListDM'
import TaxOfficeId from './TaxOfficeId'
import KindParcel from './KindParcel'
import ForestType from './ForestType'
import TTownDM from './TTownDM'
import CadTerritory from './CadTerritory'
import KindBuild from './KindBuild'
import TCostCentreCollectionDocumentDM from './TCostCentreCollectionDocumentDM'
import TContractCodeDocumentDM from './TContractCodeDocumentDM'
import TArticleCodeDocumentDM from './TArticleCodeDocumentDM'
import TCode1DocumentDM from './TCode1DocumentDM'
import TCode2DocumentDM from './TCode2DocumentDM'
import TCode3DocumentDM from './TCode3DocumentDM'
import TCode4DocumentDM from './TCode4DocumentDM'
import TCode5DocumentDM from './TCode5DocumentDM'
import TCode6DocumentDM from './TCode6DocumentDM'
import TOfficerDocumentDM from './TOfficerDocumentDM'
import TDeviceDocumentDM from './TDeviceDocumentDM'
import TMatchingSymbolDM from './TMatchingSymbolDM'
import TTradingPartnerDM from './TTradingPartnerDM'
import Pracoviste from './Pracoviste'
import TAssetLocationDocumentDM from './TAssetLocationDocumentDM'
import TAccountingChartDM from './TAccountingChartDM'
import TMarketingActivityTypeDM from './TMarketingActivityTypeDM'
import TWorkflowProcessDM from './TWorkflowProcessDM'
import TWarehouseDM from './TWarehouseDM'
import TCommentDM from './TCommentDM'
import TLanguageTextTranslationDM from './TLanguageTextTranslationDM'
import TAssetConnectedDocumentDM from './TAssetConnectedDocumentDM'
import TServicedDeviceDM from './TServicedDeviceDM'
import TExternalDocumentItemDM from './TExternalDocumentItemDM'
import TWorkflowProcessAsItemDM from './TWorkflowProcessAsItemDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TAttachedDocumentDM from './TAttachedDocumentDM'
import TOldNoteDM from './TOldNoteDM'

/**
 * Majetek
 */
export default class TAssetDM extends Data {
	public static className = 'TAssetDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = true

	/**
	 * Firma
	 */
	BookId_CompanyId?: number

	/**
	 * Skup. právo
	 */
	BookId_RightGroupId?: number

	/**
	 * Původní karta
	 */
	OriginalAssetRID?: number

	/**
	 * Časové razítko
	 */
	TimeStamp?: string

	/**
	 * Změněno
	 */
	ChangedOn?: Date

	/**
	 * Vytvořeno
	 */
	CreatedOn?: Date

	/**
	 * Právo na záznam
	 */
	RightGroupId?: number

	/**
	 * Zpracován 3. plán
	 */
	IsProcessed3?: string

	/**
	 * Zpracován 2. plán
	 */
	IsProcessed2?: string

	/**
	 * Zpracován 1. plán
	 */
	IsProcessed1?: string

	/**
	 * Datum potvrzení
	 */
	ConfirmedOn?: Date

	/**
	 * Změněno nastavení 3
	 */
	IsChangedPlan3?: string

	/**
	 * Změněno nastavení 2
	 */
	IsChangedPlan2?: string

	/**
	 * Změněno nastavení 1
	 */
	IsChangedPlan1?: string

	/**
	 * Vytvoření od data
	 */
	IsCreatedByDecompFromDate?: string

	/**
	 * Stornováno
	 */
	IsCanceledRecord?: string

	/**
	 * Neuplatněné odpisy 3
	 */
	UnappliedDepreciation3?: number

	/**
	 * Neuplatněné odpisy 2
	 */
	UnappliedDepreciation2?: number

	/**
	 * Neuplatněné odpisy 1
	 */
	UnappliedDepreciation1?: number

	/**
	 * Počet odepsaných let 3
	 */
	DepreciatedYear3?: number

	/**
	 * Počet odepsaných let 2
	 */
	DepreciatedYear2?: number

	/**
	 * Počet odepsaných let 1
	 */
	DepreciatedYear1?: number

	/**
	 * Oprávky v roce navedení 3
	 */
	YearAccumulatedDepreciation3?: number

	/**
	 * Oprávky v roce navedení 2
	 */
	YearAccumulatedDepreciation2?: number

	/**
	 * Oprávky v roce navedení 1
	 */
	YearAccumulatedDepreciation1?: number

	/**
	 * Oprávky celkem 3
	 */
	AccumulatedDepreciation3?: number

	/**
	 * Oprávky celkem 2
	 */
	AccumulatedDepreciation2?: number

	/**
	 * Oprávky celkem 1
	 */
	AccumulatedDepreciation1?: number

	/**
	 * Předvolba 3
	 */
	DepreciationPreset3RID?: number

	/**
	 * Předvolba 2
	 */
	DepreciationPreset2RID?: number

	/**
	 * Předvolba 1
	 */
	DepreciationPreset1RID?: number

	/**
	 * Vstupní cena 3
	 */
	InputPrice3?: number

	/**
	 * Vstupní cena 2
	 */
	InputPrice2?: number

	/**
	 * Vstupní cena 1
	 */
	InputPrice1?: number

	/**
	 * Poslední zaúčtování
	 */
	LastPostingDate?: Date

	/**
	 * Poslední potvrzení
	 */
	LastConfirmationItemDate?: Date

	/**
	 * Navedeno v programu
	 */
	ProgramEntry?: Date

	/**
	 * Zařazeno do užívání
	 */
	ActivationDate?: Date

	/**
	 * Vyřazeno
	 */
	RetirementDate?: Date

	/**
	 * Pořízeno
	 */
	AcquisitionDate?: Date

	/**
	 * Obor majetku
	 */
	AssetCategoryRID?: number

	/**
	 * Odpisová skupina
	 */
	DepreciationGroupRID?: number

	/**
	 * CZ - CPA
	 */
	ProductionClassificationRID?: number

	/**
	 * Dotace
	 */
	Grant?: number

	/**
	 * Platnost smlouvy do
	 */
	ContractValidityDate?: Date

	/**
	 * Číslo smlouvy
	 */
	ContractNumber?: string

	/**
	 * Evidenční číslo
	 */
	RegistrationNumber?: string

	/**
	 * Množství
	 */
	Quantity?: number

	/**
	 * Výrobní číslo
	 */
	ProductNumber?: string

	/**
	 * EAN
	 */
	BarCode?: string

	/**
	 * Popis
	 */
	Description?: string

	/**
	 * Název
	 */
	Name?: string

	/**
	 * Inventární číslo
	 */
	InventoryNumber?: string

	/**
	 * Číslo
	 */
	Number?: number

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * undefined
	 */
	BookmarkImgCalc?: string

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Číslo
	 */
	DocumentNumberCalc?: number

	/**
	 * Datum pro "_ver" pole
	 */
	VersionFieldDateCalc?: Date

	/**
	 * Jazykový název
	 */
	LanguageNameCalc?: string

	/**
	 * 202
	 */
	LandRegPropertyRightDateCalc?: Date

	/**
	 * 203
	 */
	IsLandLegalRelationshipCalc?: string

	/**
	 * Stát
	 */
	LandTownCountryIdCalc?: number

	/**
	 * 207 a) před lomítkem
	 */
	LandParcelNumberBeforeSlashCalc?: string

	/**
	 * 207 b)
	 */
	LandParcelPlotAreaCalc?: number

	/**
	 * 207 d)
	 */
	LandParcelBuiltUpPropAcreCalc?: number

	/**
	 * 207 e)
	 */
	LandExemEntitleInAcreageCalc?: number

	/**
	 * 207 f) 1. část
	 */
	LandLegalGroundsExemption1Calc?: string

	/**
	 * 207 f) 2. část
	 */
	LandLegalGroundsExemption2Calc?: string

	/**
	 * 207 f) 3. část
	 */
	LandLegalGroundsExemption3Calc?: string

	/**
	 * 207 g)
	 */
	LandExemptionLastYearCalc?: number

	/**
	 * 209
	 */
	LandDescriptionCalc?: string

	/**
	 * 221 před lomítkem
	 */
	LandPropertyShareBeforeSlashCalc?: string

	/**
	 * Kód obce
	 */
	LandTownNoIdCalc?: number

	/**
	 * Daň
	 */
	LandTaxAmountCalc?: number

	/**
	 * 207 a) za lomítkem
	 */
	LandParcelNumberBehindSlashCalc?: string

	/**
	 * 221 za lomítkem
	 */
	LandPropertyShareBehindSlashCalc?: string

	/**
	 * 302
	 */
	UnitRegPropertyRightDateCalc?: Date

	/**
	 * Stát
	 */
	UnitTownCountryIdCalc?: number

	/**
	 * 306 a)
	 */
	UnitHouseNumberCalc?: string

	/**
	 * 306 b)
	 */
	UnitOrientationNumberCalc?: string

	/**
	 * 306 c) před lomítkem
	 */
	UnitParcelNumberBeforeSlashCalc?: string

	/**
	 * 306 c) za lomítkem
	 */
	UnitParcelNumberBehindSlashCalc?: string

	/**
	 * 306 d)
	 */
	UnitCompletionYearCalc?: string

	/**
	 * 306 f)
	 */
	UnitParcelBuiltUpPropAcreaCalc?: number

	/**
	 * 306 g) 1. část
	 */
	UnitLegalGroundsExemption1Calc?: string

	/**
	 * 306 g) 2. část
	 */
	UnitLegalGroundsExemption2Calc?: string

	/**
	 * 306 g) 3. část
	 */
	UnitLegalGroundsExemption3Calc?: string

	/**
	 * 306 h)
	 */
	UnitExemptionLastYearCalc?: number

	/**
	 * 308
	 */
	UnitDescriptionCalc?: string

	/**
	 * 310
	 */
	IsUnitCoefficientCalc?: string

	/**
	 * 313
	 */
	UnitOvergroundFloorNumberCalc?: number

	/**
	 * 318
	 */
	UnitNonresidentialAreaCalc?: number

	/**
	 * Zvýšení daně
	 */
	IsUnitTaxIncreaseCalc?: string

	/**
	 * 320
	 */
	UnitExempEntitleInAcreageCalc?: number

	/**
	 * Spoluvlastnický podíl - před lomítkem
	 */
	UnitPropertyShareBeforeSlashCalc?: string

	/**
	 * Spoluvlastnický podíl - za lomítkem
	 */
	UnitPropertyShareBehindSlashCalc?: string

	/**
	 * Kód obce
	 */
	UnitTownNumberIdCalc?: number

	/**
	 * Daň
	 */
	UnitTaxAmountCalc?: number

	/**
	 * Pozemky
	 */
	ShowTaxInfoLandCalc?: string

	/**
	 * Stavby a bytové jednotky
	 */
	ShowTaxInfoHouseUnitCalc?: string

	/**
	 * Datum aktuální ceny 1
	 */
	ActualPriceDate1Calc?: Date

	/**
	 * Aktuální cena 1
	 */
	ActualPrice1Calc?: number

	/**
	 * Datum odepsání 1
	 */
	DepreciatedDate1Calc?: Date

	/**
	 * Odepsáno 1
	 */
	DepreciatedAmount1Calc?: number

	/**
	 * Zůstatek 1
	 */
	Balance1Calc?: number

	/**
	 * Datum aktuální ceny 2
	 */
	ActualPriceDate2Calc?: Date

	/**
	 * Aktuální cena 2
	 */
	ActualPrice2Calc?: number

	/**
	 * Datum odepsání 2
	 */
	DepreciatedDate2Calc?: Date

	/**
	 * Odepsáno 2
	 */
	DepreciatedAmount2Calc?: number

	/**
	 * Zůstatek 2
	 */
	Balance2Calc?: number

	/**
	 * Datum aktuální ceny 3
	 */
	ActualPriceDate3Calc?: Date

	/**
	 * Aktuální cena 3
	 */
	ActualPrice3Calc?: number

	/**
	 * Datum odepsání 3
	 */
	DepreciatedDate3Calc?: Date

	/**
	 * Odepsáno 3
	 */
	DepreciatedAmount3Calc?: number

	/**
	 * Zůstatek 3
	 */
	Balance3Calc?: number

	/**
	 * Cena za jednotku
	 */
	UnitPriceCalc?: number

	/**
	 * Soubor
	 */
	IsAssetSetCalc?: string

	/**
	 * Zůstatek 1
	 */
	AcceptsElimBalance1PriceCalc?: number

	/**
	 * Zůstatek 2
	 */
	AcceptsElimBalance2PriceCalc?: number

	/**
	 * Zůstatek 3
	 */
	AcceptsElimBalance3PriceCalc?: number

	/**
	 * undefined
	 */
	CurrencyCalc?: string

	/**
	 * Cena zjištěná podle platných cenových předpisů (pro typ C a D)
	 */
	LandDeterminedPriceCalc?: number

	/**
	 * undefined
	 */
	TextHeaderMemoCalc?: string

	/**
	 * undefined
	 */
	TextFooterMemoCalc?: string

	/**
	 * Poslední komentář
	 */
	LastCommentParamCalc?: string

	/**
	 * undefined
	 */
	OwnAddressIdCalc?: number

	/**
	 * undefined
	 */
	OwnAddressNameCalc?: string

	/**
	 * Doklad
	 */
	DocumentIdentificationCalc?: string

	/**
	 * EAN kód
	 */
	BarcodeCalc?: string

	/**
	 * undefined
	 */
	IsReversedExchangeRateViewCalc?: string

	/**
	 * undefined
	 */
	TaxInfoCalc?: string

	/**
	 * CZ - CPA_ver
	 */
	ProductionClassificationRID_ver?: number

	/**
	 * Právo na záznam
	 */
	RightGroupIdCalc?: number

	/**
	 * Zboží
	 */
	@Type(() => TArticleDM)
	ArticleId?: TArticleDM

	/**
	 * Změnil
	 */
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM

	/**
	 * Vytvořil
	 */
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM

	/**
	 * Typ plánu 3
	 */
	@Type(() => AssetPlanType)
	PlanType3Id?: AssetPlanType

	/**
	 * Typ plánu 2
	 */
	@Type(() => AssetPlanType)
	PlanType2Id?: AssetPlanType

	/**
	 * Typ plánu 1
	 */
	@Type(() => AssetPlanType)
	PlanType1Id?: AssetPlanType

	/**
	 * Způsob vyřazení
	 */
	@Type(() => TypVyr)
	RetirementMethodId?: TypVyr

	/**
	 * Způsob pořízení
	 */
	@Type(() => PurchaseWay)
	AcquisitionMethodId?: PurchaseWay

	/**
	 * Druh účtování
	 */
	@Type(() => AccKindPurch)
	PostingKindId?: AccKindPurch

	/**
	 * Skupina
	 */
	@Type(() => MajSkup)
	AssetGroupId?: MajSkup

	/**
	 * Funkční typ
	 */
	@Type(() => FunctType)
	FunctionaltypeId?: FunctType

	/**
	 * Konstrukční typ
	 */
	@Type(() => ConstrType)
	ConstructionalTypeId?: ConstrType

	/**
	 * Stav
	 */
	@Type(() => ZarStatus)
	StatusId?: ZarStatus

	/**
	 * Jednotka
	 */
	@Type(() => AlternativeUnit)
	MeasureUnitId?: AlternativeUnit

	/**
	 * Výrobce
	 */
	@Type(() => TProducerDM)
	ProducerId?: TProducerDM

	/**
	 * Období
	 */
	@Type(() => TPeriodDM)
	BusinessYearId?: TPeriodDM

	/**
	 * Kniha
	 */
	@Type(() => TBookDM)
	BookId?: TBookDM

	/**
	 * Období
	 */
	@Type(() => TPeriodDM)
	BusinessYearIdCalc?: TPeriodDM

	/**
	 * P/S/V
	 */
	@Type(() => TTextDM)
	ConfirmOrCancelOrRetireIdCalc?: TTextDM

	/**
	 * undefined
	 */
	@Type(() => TPeriodListDM)
	PeriodFromIdCalc?: TPeriodListDM

	/**
	 * undefined
	 */
	@Type(() => TPeriodListDM)
	PeriodToIdCalc?: TPeriodListDM

	/**
	 * Územní pracoviště
	 */
	@Type(() => TaxOfficeId)
	LandTaxOfficeIdCalc?: TaxOfficeId

	/**
	 * 201
	 */
	@Type(() => KindParcel)
	LandParcelTypeLandIdCalc?: KindParcel

	/**
	 * Kód souborů lesních typů pro druh pozemku C
	 */
	@Type(() => ForestType)
	LandCodeIdCalc?: ForestType

	/**
	 * 204
	 */
	@Type(() => TTownDM)
	LandTownIdCalc?: TTownDM

	/**
	 * 206,205
	 */
	@Type(() => CadTerritory)
	LandCadTerritoryIdCalc?: CadTerritory

	/**
	 * 207 c)
	 */
	@Type(() => TTextDM)
	LandLegalRelationshipIdCalc?: TTextDM

	/**
	 * Územní pracoviště
	 */
	@Type(() => TaxOfficeId)
	UnitTaxOfficeIdCalc?: TaxOfficeId

	/**
	 * 301
	 */
	@Type(() => KindBuild)
	UnitParcelTypeLandIdCalc?: KindBuild

	/**
	 * 303
	 */
	@Type(() => TTownDM)
	UnitTownIdCalc?: TTownDM

	/**
	 * 305,304
	 */
	@Type(() => CadTerritory)
	UnitCadTerritoryIdCalc?: CadTerritory

	/**
	 * 306 e)
	 */
	@Type(() => TTextDM)
	UnitLegalRelationshipIdCalc?: TTextDM

	/**
	 * Středisko
	 */
	@Type(() => TCostCentreCollectionDocumentDM)
	CostCentreIdCalc?: TCostCentreCollectionDocumentDM

	/**
	 * Kód zakázky
	 */
	@Type(() => TContractCodeDocumentDM)
	ContractCodeRIDCalc?: TContractCodeDocumentDM

	/**
	 * Kód zboží
	 */
	@Type(() => TArticleCodeDocumentDM)
	ArticleCodeIdCalc?: TArticleCodeDocumentDM

	/**
	 * Kód 1
	 */
	@Type(() => TCode1DocumentDM)
	Code1IdCalc?: TCode1DocumentDM

	/**
	 * Kód 2
	 */
	@Type(() => TCode2DocumentDM)
	Code2IdCalc?: TCode2DocumentDM

	/**
	 * Kód 3
	 */
	@Type(() => TCode3DocumentDM)
	Code3IdCalc?: TCode3DocumentDM

	/**
	 * Kód 4
	 */
	@Type(() => TCode4DocumentDM)
	Code4IdCalc?: TCode4DocumentDM

	/**
	 * Kód 5
	 */
	@Type(() => TCode5DocumentDM)
	Code5IdCalc?: TCode5DocumentDM

	/**
	 * Kód 6
	 */
	@Type(() => TCode6DocumentDM)
	Code6IdCalc?: TCode6DocumentDM

	/**
	 * Referent
	 */
	@Type(() => TOfficerDocumentDM)
	OfficerIdCalc?: TOfficerDocumentDM

	/**
	 * Prostředek
	 */
	@Type(() => TDeviceDocumentDM)
	DeviceRIDCalc?: TDeviceDocumentDM

	/**
	 * Párovací symbol
	 */
	@Type(() => TMatchingSymbolDM)
	MatchingSymbolIdCalc?: TMatchingSymbolDM

	/**
	 * Zákazník
	 */
	@Type(() => TTradingPartnerDM)
	TradingPartnerIdCalc?: TTradingPartnerDM

	/**
	 * Pracoviště
	 */
	@Type(() => Pracoviste)
	WorkPlaceIdCalc?: Pracoviste

	/**
	 * Umístění
	 */
	@Type(() => TAssetLocationDocumentDM)
	LocationRIDCalc?: TAssetLocationDocumentDM

	/**
	 * ZO
	 */
	@Type(() => TTextDM)
	DepreciatedCalc?: TTextDM

	/**
	 * Účet pořízení
	 */
	@Type(() => TAccountingChartDM)
	AcquisitionAccountIdCalc?: TAccountingChartDM

	/**
	 * D
	 */
	@Type(() => TTextDM)
	DocumentExistsCalc?: TTextDM

	/**
	 * undefined
	 */
	@Type(() => TMarketingActivityTypeDM)
	ActivityTypeRIDCalc?: TMarketingActivityTypeDM

	/**
	 * SP
	 */
	@Type(() => TTextDM)
	ProcessStateIdCalc?: TTextDM

	/**
	 * Aktuální proces
	 */
	@Type(() => TWorkflowProcessDM)
	CurrentProcessIdCalc?: TWorkflowProcessDM

	/**
	 * P
	 */
	@Type(() => TTextDM)
	ConfirmedOrCanceledIdCalc?: TTextDM

	/**
	 * undefined
	 */
	@Type(() => TTradingPartnerDM)
	OwnTradingPartnerIdCalc?: TTradingPartnerDM

	/**
	 * Období
	 */
	@Type(() => TPeriodDM)
	CurrentBusinessYearIdCalc?: TPeriodDM

	/**
	 * Sklad
	 */
	@Type(() => TWarehouseDM)
	CurrentWarehouseIdCalc?: TWarehouseDM

	/**
	 * Komentáře
	 */
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]

	/**
	 * undefined
	 */
	@Type(() => TLanguageTextTranslationDM)
	LanguageNameChild?: TLanguageTextTranslationDM[]

	/**
	 * undefined
	 */
	@Type(() => TAssetConnectedDocumentDM)
	ConnectedDocumentChild?: TAssetConnectedDocumentDM[]

	/**
	 * undefined
	 */
	@Type(() => TServicedDeviceDM)
	ServicedDeviceChild?: TServicedDeviceDM[]

	/**
	 * Dokumenty
	 */
	@Type(() => TExternalDocumentItemDM)
	ExternalDocumentItemChild?: TExternalDocumentItemDM[]

	/**
	 * Procesy
	 */
	@Type(() => TWorkflowProcessAsItemDM)
	WorkflowProcessChild?: TWorkflowProcessAsItemDM[]

	/**
	 * Text hlavičky
	 */
	@Type(() => TLanguageMemoTranslationDM)
	TextHeaderChild?: TLanguageMemoTranslationDM[]

	/**
	 * Text patičky
	 */
	@Type(() => TLanguageMemoTranslationDM)
	TextFooterChild?: TLanguageMemoTranslationDM[]

	/**
	 * Svázané doklady
	 */
	@Type(() => TAttachedDocumentDM)
	AttachedDocumentOldChild?: TAttachedDocumentDM[]

	/**
	 * Poznámky
	 */
	@Type(() => TOldNoteDM)
	DeprecatedNoteOldChild?: TOldNoteDM[]

	/**
	 * Částečné potvrzení
	 */
	public PartialConfirmCOMMAND() {
		return this.executeCommand('PartialConfirmCOMMAND')
	}

	/**
	 * Částečné odpotvrzení
	 */
	public PartialUnconfirmCOMMAND() {
		return this.executeCommand('PartialUnconfirmCOMMAND')
	}

	/**
	 * Částečné potvrzení
	 */
	public BulkPartialConfirmCOMMAND() {
		return this.executeCommand('BulkPartialConfirmCOMMAND')
	}

	/**
	 * Částečné odpotvrzení
	 */
	public BulkPartialUnconfirmCOMMAND() {
		return this.executeCommand('BulkPartialUnconfirmCOMMAND')
	}

	/**
	 * Přecenění k rozvahovému dni
	 */
	public BulkRevaluationAtBalanceDayCOMMAND() {
		return this.executeCommand('BulkRevaluationAtBalanceDayCOMMAND')
	}

	/**
	 * Storno přecenění k rozvahovému dni
	 */
	public BulkCancelRevaluationCOMMAND() {
		return this.executeCommand('BulkCancelRevaluationCOMMAND')
	}

	/**
	 * Změna DUZP
	 */
	public InvoiceDateChangeCOMMAND(parameters: TInvoiceDateChangeCOMMANDParams) {
		return this.executeCommand('InvoiceDateChangeCOMMAND', parameters)
	}

	/**
	 * Zaúčtování s likvidací
	 */
	public AccountAssignmentPostingCOMMAND(parameters: TAccountAssignmentPostingCOMMANDParams) {
		return this.executeCommand('AccountAssignmentPostingCOMMAND', parameters)
	}

	/**
	 * Hromadné zaúčtování s likvidací
	 */
	public BulkAccountAssignmentPostingCOMMAND(parameters: TBulkAccountAssignmentPostingCOMMANDParams) {
		return this.executeCommand('BulkAccountAssignmentPostingCOMMAND', parameters)
	}

	/**
	 * Likvidace
	 */
	public AccountAssignmentCOMMAND() {
		return this.executeCommand('AccountAssignmentCOMMAND')
	}

	/**
	 * Zapnout rychlý filtr
	 */
	public FastFilterSwitchCOMMAND() {
		return this.executeCommand('FastFilterSwitchCOMMAND')
	}

	/**
	 * Povolit editaci
	 */
	public EditModeIDNoCOMMAND() {
		return this.executeCommand('EditModeIDNoCOMMAND')
	}

	/**
	 * Povolit editaci
	 */
	public EditModeVATRegisterNoCOMMAND() {
		return this.executeCommand('EditModeVATRegisterNoCOMMAND')
	}

	/**
	 * Povolit editaci
	 */
	public EditModeTaxNoCOMMAND() {
		return this.executeCommand('EditModeTaxNoCOMMAND')
	}

	/**
	 * Změna kontace
	 */
	public BulkChangePostingKeyCOMMAND(parameters: TBulkChangePostingKeyCOMMANDParams) {
		return this.executeCommand('BulkChangePostingKeyCOMMAND', parameters)
	}

	/**
	 * Aktualizace Plánovaného data zaplacení dle Prodlení Dod./Odb.
	 */
	public BulkUpdatePlannedDateOfPaymentByAvgDelayCOMMAND() {
		return this.executeCommand('BulkUpdatePlannedDateOfPaymentByAvgDelayCOMMAND')
	}

	/**
	 * Zobrazovat data od období: %s
	 */
	public ApplyLowerLimitForData() {
		return this.executeCommand('ApplyLowerLimitForData')
	}

	/**
	 * Potvrzení
	 */
	public BulkConfirmCOMMAND() {
		return this.executeCommand('BulkConfirmCOMMAND')
	}

	/**
	 * Odpotvrzení
	 */
	public BulkUnconfirmCOMMAND() {
		return this.executeCommand('BulkUnconfirmCOMMAND')
	}

	/**
	 * Změna data potvrzení
	 */
	public BulkChangeConfirmationDateCOMMAND(parameters: TBulkChangeConfirmationDateCOMMANDParams) {
		return this.executeCommand('BulkChangeConfirmationDateCOMMAND', parameters)
	}

	/**
	 * Storno
	 */
	public BulkCancelCOMMAND() {
		return this.executeCommand('BulkCancelCOMMAND')
	}

	/**
	 * Odstorno
	 */
	public BulkUnCancelCOMMAND() {
		return this.executeCommand('BulkUnCancelCOMMAND')
	}

	/**
	 * Potvrzení
	 */
	public ConfirmCOMMAND() {
		return this.executeCommand('ConfirmCOMMAND')
	}

	/**
	 * Odpotvrzení
	 */
	public UnconfirmCOMMAND() {
		return this.executeCommand('UnconfirmCOMMAND')
	}

	/**
	 * Změna data potvrzení
	 */
	public ChangeConfirmationDateCOMMAND(parameters: TChangeConfirmationDateCOMMANDParams) {
		return this.executeCommand('ChangeConfirmationDateCOMMAND', parameters)
	}

	/**
	 * Storno/Odstorno
	 */
	public CancelCOMMAND() {
		return this.executeCommand('CancelCOMMAND')
	}

	/**
	 * Zrušení stavu
	 */
	public ClearRecordStatusCOMMAND() {
		return this.executeCommand('ClearRecordStatusCOMMAND')
	}

	/**
	 * Změna stavu
	 */
	public ChangeRecordStatusCOMMAND() {
		return this.executeCommand('ChangeRecordStatusCOMMAND')
	}

	/**
	 * 
	 */
	public ChangeRecordStatusSWSCOMMAND(parameters: TChangeRecordStatusSWSCOMMANDParams) {
		return this.executeCommand('ChangeRecordStatusSWSCOMMAND', parameters)
	}

	/**
	 * Zrušení stavu
	 */
	public BulkClearRecordStatusCOMMAND() {
		return this.executeCommand('BulkClearRecordStatusCOMMAND')
	}

	/**
	 * Změna stavu
	 */
	public BulkChangeRecordStatusCOMMAND() {
		return this.executeCommand('BulkChangeRecordStatusCOMMAND')
	}

	/**
	 * Změna atributů
	 */
	public BulkChangeAttributesCOMMAND(parameters: TBulkChangeAttributesCOMMANDParams) {
		return this.executeCommand('BulkChangeAttributesCOMMAND', parameters)
	}

	/**
	 * Aktualizace sady atributů
	 */
	public BulkActualizeAttributesCOMMAND(parameters: TBulkActualizeAttributesCOMMANDParams) {
		return this.executeCommand('BulkActualizeAttributesCOMMAND', parameters)
	}

	/**
	 * Aktualizace sady atributů
	 */
	public ActualizeAttributesCOMMAND() {
		return this.executeCommand('ActualizeAttributesCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public ChangeGroupRightCOMMAND() {
		return this.executeCommand('ChangeGroupRightCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public BulkChangeGroupRightCOMMAND() {
		return this.executeCommand('BulkChangeGroupRightCOMMAND')
	}

	/**
	 * Aktualizace počítaných polí
	 */
	public BulkUpdateCalcFieldsCOMMAND() {
		return this.executeCommand('BulkUpdateCalcFieldsCOMMAND')
	}

	/**
	 * Kontrola legislativy
	 */
	public SwitchOffLegislationValidationCOMMAND() {
		return this.executeCommand('SwitchOffLegislationValidationCOMMAND')
	}
}

export type TInvoiceDateChangeCOMMANDParams = {
	/**
	 * VATDate
	 */
	VATDate?: Date
}

export type TAccountAssignmentPostingCOMMANDParams = {
	/**
	 * Zaúčtuje
	 */
	ACCPosting?: number
	/**
	 * Kniha
	 */
	ACCBook?: number
	/**
	 * Období
	 */
	ACCBussinessYear?: number
	/**
	 * Datum zaúčtování
	 */
	ACCPostingDate?: Date
	/**
	 * Datum od
	 */
	ACCDateFrom?: Date
	/**
	 * Datum do
	 */
	ACCDateTo?: Date
	/**
	 * Zachovat ručně změněné záznamy
	 */
	ACCHandChange?: boolean
	/**
	 * Likvidace bez částky
	 */
	ACCNulAmount?: boolean
	/**
	 * Povolit zaúčtovat doklady s jiným obdobím
	 */
	ACCOtherBusinessYear?: boolean
	/**
	 * Zaúčtovat nevyrovnané saldo
	 */
	ACCBalance?: boolean
}

export type TBulkAccountAssignmentPostingCOMMANDParams = {
	/**
	 * Zaúčtuje
	 */
	ACCPosting?: number
	/**
	 * Kniha
	 */
	ACCBook?: number
	/**
	 * Období
	 */
	ACCBussinessYear?: number
	/**
	 * Datum zaúčtování
	 */
	ACCPostingDate?: Date
	/**
	 * Datum od
	 */
	ACCDateFrom?: Date
	/**
	 * Datum do
	 */
	ACCDateTo?: Date
	/**
	 * Zachovat ručně změněné záznamy
	 */
	ACCHandChange?: boolean
	/**
	 * Likvidace bez částky
	 */
	ACCNulAmount?: boolean
	/**
	 * Povolit zaúčtovat doklady s jiným obdobím
	 */
	ACCOtherBusinessYear?: boolean
	/**
	 * Zaúčtovat nevyrovnané saldo
	 */
	ACCBalance?: boolean
}

export type TBulkChangePostingKeyCOMMANDParams = {
	/**
	 * Kontace
	 */
	Header_PostingKeyX?: boolean
	/**
	 * Header_PostingKey
	 */
	Header_PostingKey?: number
	/**
	 * Účet
	 */
	Header_AccountX?: boolean
	/**
	 * Header_Account
	 */
	Header_Account?: number
	/**
	 * Účet D
	 */
	Header_CreditAcX?: boolean
	/**
	 * Header_CreditAc
	 */
	Header_CreditAc?: number
	/**
	 * Účet MD
	 */
	Header_DebitAcX?: boolean
	/**
	 * Header_DebitAc
	 */
	Header_DebitAc?: number
	/**
	 * Parovací symbol
	 */
	Header_MatchSymbolX?: boolean
	/**
	 * Header_MatchSymbol
	 */
	Header_MatchSymbol?: number
	/**
	 * Středisko
	 */
	Header_CostCentreX?: boolean
	/**
	 * Header_CostCentre
	 */
	Header_CostCentre?: number
	/**
	 * Ze třediska
	 */
	Header_FromCostCentreX?: boolean
	/**
	 * Header_FromCostCentre
	 */
	Header_FromCostCentre?: number
	/**
	 * Na středisko
	 */
	Header_ToCostCentreX?: boolean
	/**
	 * Header_ToCostCentre
	 */
	Header_ToCostCentre?: number
	/**
	 * Kód zakázky
	 */
	Header_ContractCodeX?: boolean
	/**
	 * Header_ContractCode
	 */
	Header_ContractCode?: number
	/**
	 * Z kódu zakázky
	 */
	Header_FromContractCodeX?: boolean
	/**
	 * Header_FromContractCode
	 */
	Header_FromContractCode?: number
	/**
	 * Na kód zakázky
	 */
	Header_ToContractCodeX?: boolean
	/**
	 * Header_ToContractCode
	 */
	Header_ToContractCode?: number
	/**
	 * Prostředek
	 */
	Header_DeviceX?: boolean
	/**
	 * Header_Device
	 */
	Header_Device?: number
	/**
	 * Z prostředku
	 */
	Header_FromDeviceX?: boolean
	/**
	 * Header_FromDevice
	 */
	Header_FromDevice?: number
	/**
	 * Na prostředek
	 */
	Header_ToDeviceX?: boolean
	/**
	 * Header_ToDevice
	 */
	Header_ToDevice?: number
	/**
	 * Referent
	 */
	Header_OfficerX?: boolean
	/**
	 * Header_Officer
	 */
	Header_Officer?: number
	/**
	 * Z referenta
	 */
	Header_FromOfficerX?: boolean
	/**
	 * Header_FromOfficer
	 */
	Header_FromOfficer?: number
	/**
	 * Na referenta
	 */
	Header_ToOfficerX?: boolean
	/**
	 * Header_ToOfficer
	 */
	Header_ToOfficer?: number
	/**
	 * Kód zboží
	 */
	Header_ArticleCodeX?: boolean
	/**
	 * Header_ArticleCode
	 */
	Header_ArticleCode?: number
	/**
	 * Kód 1
	 */
	Header_Code1X?: boolean
	/**
	 * Header_Code1
	 */
	Header_Code1?: number
	/**
	 * Z kódu 1
	 */
	Header_FromCode1X?: boolean
	/**
	 * Header_FromCode1
	 */
	Header_FromCode1?: number
	/**
	 * Na kód 1
	 */
	Header_ToCode1X?: boolean
	/**
	 * Header_ToCode1
	 */
	Header_ToCode1?: number
	/**
	 * Kód 2
	 */
	Header_Code2X?: boolean
	/**
	 * Header_Code2
	 */
	Header_Code2?: number
	/**
	 * Z kódu 2
	 */
	Header_FromCode2X?: boolean
	/**
	 * Header_FromCode2
	 */
	Header_FromCode2?: number
	/**
	 * Na kód 2
	 */
	Header_ToCode2X?: boolean
	/**
	 * Header_ToCode2
	 */
	Header_ToCode2?: number
	/**
	 * Kód 3
	 */
	Header_Code3X?: boolean
	/**
	 * Header_Code3
	 */
	Header_Code3?: number
	/**
	 * Z kódu 3
	 */
	Header_FromCode3X?: boolean
	/**
	 * Header_FromCode3
	 */
	Header_FromCode3?: number
	/**
	 * Na kód 3
	 */
	Header_ToCode3X?: boolean
	/**
	 * Header_ToCode3
	 */
	Header_ToCode3?: number
	/**
	 * Kód 4
	 */
	Header_Code4X?: boolean
	/**
	 * Header_Code4
	 */
	Header_Code4?: number
	/**
	 * Z kódu 4
	 */
	Header_FromCode4X?: boolean
	/**
	 * Header_FromCode4
	 */
	Header_FromCode4?: number
	/**
	 * Na kód 4
	 */
	Header_ToCode4X?: boolean
	/**
	 * Header_ToCode4
	 */
	Header_ToCode4?: number
	/**
	 * Kód 5
	 */
	Header_Code5X?: boolean
	/**
	 * Header_Code5
	 */
	Header_Code5?: number
	/**
	 * Z kódu 5
	 */
	Header_FromCode5X?: boolean
	/**
	 * Header_FromCode5
	 */
	Header_FromCode5?: number
	/**
	 * Na kód 5
	 */
	Header_ToCode5X?: boolean
	/**
	 * Header_ToCode5
	 */
	Header_ToCode5?: number
	/**
	 * Kód 6
	 */
	Header_Code6X?: boolean
	/**
	 * Header_Code6
	 */
	Header_Code6?: number
	/**
	 * Z kódu 6
	 */
	Header_FromCode6X?: boolean
	/**
	 * Header_FromCode6
	 */
	Header_FromCode6?: number
	/**
	 * Na kód 6
	 */
	Header_ToCode6X?: boolean
	/**
	 * Header_ToCode6
	 */
	Header_ToCode6?: number
	/**
	 * Ignorovat cenu příjmu
	 */
	Header_IgnorePriceIncomX?: boolean
	/**
	 * Header_IgnorePriceIncom
	 */
	Header_IgnorePriceIncom?: number
	/**
	 * Header_ShowIgnorePriceIncom
	 */
	Header_ShowIgnorePriceIncom?: boolean
	/**
	 * Kalk.vz.plán.
	 */
	Header_PlannedPriceCMX?: boolean
	/**
	 * Header_PlannedPriceCM
	 */
	Header_PlannedPriceCM?: number
	/**
	 * Kalk.vz.sklad.
	 */
	Header_StockPriceCMX?: boolean
	/**
	 * Header_StockPriceCM
	 */
	Header_StockPriceCM?: number
	/**
	 * Kalk.vz.nákl.
	 */
	Header_CostPriceCMX?: boolean
	/**
	 * Header_CostPriceCM
	 */
	Header_CostPriceCM?: number
	/**
	 * Kontace faktury
	 */
	Item_PostingKeyIX?: boolean
	/**
	 * Item_PostingKeyI
	 */
	Item_PostingKeyI?: number
	/**
	 * Kontace skladového dokladu
	 */
	Item_PostingKeySX?: boolean
	/**
	 * Item_PostingKeyS
	 */
	Item_PostingKeyS?: number
	/**
	 * Kontace rezervačního listu
	 */
	Item_PostingKeyRX?: boolean
	/**
	 * Item_PostingKeyR
	 */
	Item_PostingKeyR?: number
	/**
	 * Kód zakázky
	 */
	Item_ContractCodeX?: boolean
	/**
	 * Item_ContractCode
	 */
	Item_ContractCode?: number
	/**
	 * Kód zakázky 2
	 */
	Item_ContractCode2X?: boolean
	/**
	 * Item_ContractCode2
	 */
	Item_ContractCode2?: number
	/**
	 * Středisko
	 */
	Item_CostCentreX?: boolean
	/**
	 * Item_CostCentre
	 */
	Item_CostCentre?: number
	/**
	 * Středisko 2
	 */
	Item_CostCentre2X?: boolean
	/**
	 * Item_CostCentre2
	 */
	Item_CostCentre2?: number
	/**
	 * Dosadit z karty zboží
	 */
	Item_CostCentreG?: boolean
	/**
	 * Kód zboží
	 */
	Item_ArticleCodeX?: boolean
	/**
	 * Item_ArticleCode
	 */
	Item_ArticleCode?: number
	/**
	 * Dosadit z karty zboží
	 */
	Item_ArticleCodeG?: boolean
	/**
	 * Kód 1
	 */
	Item_Code1X?: boolean
	/**
	 * Item_Code1
	 */
	Item_Code1?: number
	/**
	 * Kód 2
	 */
	Item_Code2X?: boolean
	/**
	 * Item_Code2
	 */
	Item_Code2?: number
	/**
	 * Kód 3
	 */
	Item_Code3X?: boolean
	/**
	 * Item_Code3
	 */
	Item_Code3?: number
	/**
	 * Kód 4
	 */
	Item_Code4X?: boolean
	/**
	 * Item_Code4
	 */
	Item_Code4?: number
	/**
	 * Kód 5
	 */
	Item_Code5X?: boolean
	/**
	 * Item_Code5
	 */
	Item_Code5?: number
	/**
	 * Kód 6
	 */
	Item_Code6X?: boolean
	/**
	 * Item_Code6
	 */
	Item_Code6?: number
	/**
	 * Referent
	 */
	Item_OfficerX?: boolean
	/**
	 * Item_Officer
	 */
	Item_Officer?: number
	/**
	 * Prostředek
	 */
	Item_DeviceX?: boolean
	/**
	 * Item_Device
	 */
	Item_Device?: number
	/**
	 * Parovací symbol
	 */
	Item_MatchSymbolX?: boolean
	/**
	 * Item_MatchSymbol
	 */
	Item_MatchSymbol?: number
	/**
	 * Účet
	 */
	Item_AccountX?: boolean
	/**
	 * Item_Account
	 */
	Item_Account?: number
	/**
	 * Kontace
	 */
	Item_PostingKeyX?: boolean
	/**
	 * Item_PostingKey
	 */
	Item_PostingKey?: number
	/**
	 * Účet D
	 */
	Item_CreditAcX?: boolean
	/**
	 * Item_CreditAc
	 */
	Item_CreditAc?: number
	/**
	 * Účet MD
	 */
	Item_DebitAcX?: boolean
	/**
	 * Item_DebitAc
	 */
	Item_DebitAc?: number
	/**
	 * Podle kontejneru zboží
	 */
	GoodsContainer?: boolean
	/**
	 * S odmazáním z kontejneru
	 */
	DeleteFromContainer?: boolean
	/**
	 * Pouze u prázdných
	 */
	OnlyEmpty?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TBulkChangeConfirmationDateCOMMANDParams = {
	/**
	 * Datum potvrzení
	 */
	Confirmation1Date?: Date
	/**
	 * Hodina potvrzení
	 */
	Confirmation1Time?: Date
	/**
	 * Datum 2. potvrzení
	 */
	Confirmation2Date?: Date
	/**
	 * Hodina 2. potvrzení
	 */
	Confirmation2Time?: Date
	/**
	 * Kontrolovat záporné stavy
	 */
	CheckNegativeState?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TChangeConfirmationDateCOMMANDParams = {
	/**
	 * Datum potvrzení
	 */
	Confirmation1Date?: Date
	/**
	 * Hodina potvrzení
	 */
	Confirmation1Time?: Date
	/**
	 * Datum 2. potvrzení
	 */
	Confirmation2Date?: Date
	/**
	 * Hodina 2. potvrzení
	 */
	Confirmation2Time?: Date
	/**
	 * Kontrolovat záporné stavy
	 */
	CheckNegativeState?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TChangeRecordStatusSWSCOMMANDParams = {
	/**
	 * StatusId
	 */
	StatusId?: number
}

export type TBulkChangeAttributesCOMMANDParams = {
	/**
	 * Smazat atributy
	 */
	DeleteAttribute?: boolean
	/**
	 * Smazat všechny volné atributy
	 */
	DeleteFreeAttribute?: boolean
	/**
	 * Nastavit hodnoty atributů
	 */
	SetValues?: boolean
	/**
	 * Přidat nepřipojené atributy
	 */
	AddFreeAttributes?: boolean
	/**
	 * FreeAttributeEnabled
	 */
	FreeAttributeEnabled?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TBulkActualizeAttributesCOMMANDParams = {
	/**
	 * Aktualizovat včetně samotné sady atributů
	 */
	ActualizeAttributeSet?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}