import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TVATRateDM from './TVATRateDM'
import TMeasureUnitDM from './TMeasureUnitDM'
import TArticleDM from './TArticleDM'

/**
 * Položky objednávky e-shopu
 */
export default class TEshopOrderItemDM extends Data {
	public static className = 'TEshopOrderItemDM'
	public static primaryKey = 'ItemNumber'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.ItemNumber!
	}

	public static isHeader = false

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * ItemNumber
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * ItemRID
	 */
	ItemRID?: number

	/**
	 * PlannedTotalPriceC
	 */
	PlannedTotalPriceC?: number

	/**
	 * PlGrossPriceTotalC
	 */
	PlannedTotalPriceGrossC?: number

	/**
	 * PlannedUnitPriceC
	 */
	PlannedUnitPriceC?: number

	/**
	 * PlannedUnitPriceGrossC
	 */
	PlannedUnitPriceGrossC?: number

	/**
	 * Quantity
	 */
	Quantity?: number

	/**
	 * Name
	 */
	Name?: string

	/**
	 * ItemNumber
	 */
	ItemNumber?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * TaxGroupId
	 */
	@Type(() => TVATRateDM)
	TaxGroupId?: TVATRateDM

	/**
	 * MeasureUnitId
	 */
	@Type(() => TMeasureUnitDM)
	MeasureUnitId?: TMeasureUnitDM

	/**
	 * ArticleId
	 */
	@Type(() => TArticleDM)
	ArticleId?: TArticleDM
}