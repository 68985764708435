import Data from 'react-k2-api/Api/Data'

/**
 * Poznámky
 */
export default class TOldNoteDM extends Data {
	public static className = 'TOldNoteDM'
	public static systemFields = [
		'ItemRIDCalc',
	]

	public static isHeader = false

	/**
	 * Node
	 */
	NodeId?: number

	/**
	 * Právo na záznam
	 */
	RightGroupId?: number

	/**
	 * Formulář
	 */
	FormId?: number

	/**
	 * Unikát
	 */
	NoteId?: number

	/**
	 * Poznámka
	 */
	NoteText?: string

	/**
	 * Ř
	 */
	SequenceNumber?: number

	/**
	 * T
	 */
	TypeId?: string

	/**
	 * Číslo
	 */
	DocumentRID?: number

	/**
	 * Kniha
	 */
	Book?: string

	/**
	 * Doklad
	 */
	DocumentTypeId?: number

	/**
	 * undefined
	 */
	RecordStateImgCalc?: string

	/**
	 * Poznámk&y
	 */
	NoteTextCalc?: string

	/**
	 * Typ poznámky
	 */
	NoteTypeCalc?: string

	/**
	 * ItemRIDCalc
	 */
	ItemRIDCalc?: number

	/**
	 * RecordModeCalc
	 */
	RecordModeCalc?: number
}