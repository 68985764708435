import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TContactPersonDM from './TContactPersonDM'

/**
 * Položky kontatní osoby - partneři
 */
export default class TPartnerContactPersonDM extends Data {
	public static className = 'TPartnerContactPersonDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = false

	/**
	 * AuxiliaryRID
	 */
	AuxiliaryRID?: number

	/**
	 * SlaveRID
	 */
	SlaveRID?: number

	/**
	 * Typ
	 */
	LinkTypeId?: number

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * T
	 */
	DefaultValueImgCalc?: string

	/**
	 * MasterRID
	 */
	@Type(() => TContactPersonDM)
	MasterRID?: TContactPersonDM
}