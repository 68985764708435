import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TTextDM from './TTextDM'
import TEmployedPersonDM from './TEmployedPersonDM'
import TPrPeriodDM from './TPrPeriodDM'
import PrCalTypDay from './PrCalTypDay'
import PrCalTypHours from './PrCalTypHours'
import TPrEmploymentDM from './TPrEmploymentDM'

/**
 * Kalendář - časové úseky vstupní
 */
export default class TPrCalendarEmploymentItemTimeDM extends Data {
	public static className = 'TPrCalendarEmploymentItemTimeDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = true

	/**
	 * Kniha
	 */
	BookId?: number

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * HeaderRID
	 */
	HeaderRID?: number

	/**
	 * Číslo MS
	 */
	ComponentRID?: number

	/**
	 * Nápočet času v ms
	 */
	AddTime?: number

	/**
	 * Přerušení v ms
	 */
	BreakTime?: number

	/**
	 * Celkový čas v ms
	 */
	TotalTime?: number

	/**
	 * Datum do
	 */
	DateTo?: Date

	/**
	 * Datum od
	 */
	DateFrom?: Date

	/**
	 * Typ položky
	 */
	TypeId?: number

	/**
	 * Č.kal.
	 */
	CodeCalendarId?: number

	/**
	 * S.hodn.3
	 */
	StringValue3?: string

	/**
	 * S.hodn.2
	 */
	StringValue2?: string

	/**
	 * Č.hodn.N
	 */
	ShortValue?: number

	/**
	 * Č.hodn.L
	 */
	LongValue?: number

	/**
	 * Čas celk.
	 */
	TimeTotal?: number

	/**
	 * Č.mzd.sl.
	 */
	PrComponentId?: number

	/**
	 * Popis
	 */
	Description?: string

	/**
	 * Čas do
	 */
	TimeTo?: number

	/**
	 * Čas od
	 */
	TimeFrom?: number

	/**
	 * Datum
	 */
	Date?: Date

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * undefined
	 */
	PeriodIdCalc?: number

	/**
	 * undefined
	 */
	DurationTimeCalc?: number

	/**
	 * undefined
	 */
	BreakInHoursCalc?: number

	/**
	 * undefined
	 */
	DateTimeFromCalc?: Date

	/**
	 * undefined
	 */
	DateTimeToCalc?: Date

	/**
	 * undefined
	 */
	ItemStartDateCalc?: Date

	/**
	 * undefined
	 */
	ItemEndDateCalc?: Date

	/**
	 * undefined
	 */
	IntervalDurationInDaysCalc?: number

	/**
	 * Detail č.ús.
	 */
	@Type(() => TTextDM)
	CalendarRecordDetailTypeId?: TTextDM

	/**
	 * Typ směny
	 */
	@Type(() => TTextDM)
	WorkShiftTypeId?: TTextDM

	/**
	 * Č.pr.
	 */
	@Type(() => TEmployedPersonDM)
	EmployedPersonId?: TEmployedPersonDM

	/**
	 * Den/čas
	 */
	@Type(() => TTextDM)
	ItemTypeId?: TTextDM

	/**
	 * Priorita
	 */
	@Type(() => TTextDM)
	PriorityId?: TTextDM

	/**
	 * Perioda
	 */
	@Type(() => TPrPeriodDM)
	PeriodId?: TPrPeriodDM

	/**
	 * Typ dne
	 */
	@Type(() => PrCalTypDay)
	DayTypeId?: PrCalTypDay

	/**
	 * Typ č.ús.
	 */
	@Type(() => PrCalTypHours)
	CalendarRecordTypeId?: PrCalTypHours

	/**
	 * Č.pr.pom.
	 */
	@Type(() => TPrEmploymentDM)
	PrEmploymentId?: TPrEmploymentDM

	/**
	 * undefined
	 */
	@Type(() => TTextDM)
	CalendarSourceTypeIdCalc?: TTextDM

	/**
	 * undefined
	 */
	@Type(() => TTextDM)
	WeekDayIdCalc?: TTextDM

	/**
	 * Změna skupiny práv
	 */
	public ChangeGroupRightCOMMAND() {
		return this.executeCommand('ChangeGroupRightCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public BulkChangeGroupRightCOMMAND() {
		return this.executeCommand('BulkChangeGroupRightCOMMAND')
	}

	/**
	 * Aktualizace počítaných polí
	 */
	public BulkUpdateCalcFieldsCOMMAND() {
		return this.executeCommand('BulkUpdateCalcFieldsCOMMAND')
	}

	/**
	 * Kontrola legislativy
	 */
	public SwitchOffLegislationValidationCOMMAND() {
		return this.executeCommand('SwitchOffLegislationValidationCOMMAND')
	}
}