import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TTextDM from './TTextDM'
import AlternativeUnit from './AlternativeUnit'

/**
 * Měrné jednotky
 */
export default class TEshopMeasureUnitDM extends Data {
	public static className = 'TEshopMeasureUnitDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = false

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * Manip.technika
	 */
	HandlingEquipmentId?: number

	/**
	 * Jedn. plochy
	 */
	AreaUnitId?: number

	/**
	 * Plocha
	 */
	Area?: number

	/**
	 * Netto
	 */
	WeightNet?: number

	/**
	 * Brutto
	 */
	WeightGross?: number

	/**
	 * Hloubka
	 */
	Depth?: number

	/**
	 * Výška
	 */
	Height?: number

	/**
	 * Šířka
	 */
	Width?: number

	/**
	 * Objem
	 */
	Volume?: number

	/**
	 * Poměr základní/altern.
	 */
	Ratio?: number

	/**
	 * Číslo zboží
	 */
	ArticleId?: number

	/**
	 * Číslo
	 */
	Id?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Bkolováno
	 */
	BlockedRecordBitCalc?: number

	/**
	 * undefined
	 */
	BasicOrBlockedImgCalc?: string

	/**
	 * Implicitní pro průvodky
	 */
	DefaultForJobCardBitCalc?: number

	/**
	 * Implicitní pro nákup
	 */
	DefaultForPurchaseBitCalc?: number

	/**
	 * Implicitní pro prodej
	 */
	DefaultForSaleBitCalc?: number

	/**
	 * Implicitní pro převodky
	 */
	DefaultForTransferNoteBitCalc?: number

	/**
	 * Celočíselná hodnota
	 */
	IntegerUnitIdCalc?: number

	/**
	 * Implicitní pro e-shop
	 */
	DefaultForEshopBitCalc?: number

	/**
	 * Zobrazovat na e-shopu
	 */
	ShowOnEshopBitCalc?: number

	/**
	 * Množstevní pro internetový obchod
	 */
	EshopQuantityBitCalc?: number

	/**
	 * Implicitní pro inventury
	 */
	DefaultForStockTakingBitCalc?: number

	/**
	 * Opačný poměr
	 */
	InverseRateCalc?: number

	/**
	 * Jedn. rozměru
	 */
	@Type(() => TTextDM)
	DimensionUnitId?: TTextDM

	/**
	 * Jedn. objemu
	 */
	@Type(() => TTextDM)
	VolumeUnitId?: TTextDM

	/**
	 * Zkratka
	 */
	@Type(() => AlternativeUnit)
	Abbr?: AlternativeUnit
}