import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TJobCardOperationDM from './TJobCardOperationDM'
import TJobCardDM from './TJobCardDM'
import TResourceDM from './TResourceDM'
import TTextDM from './TTextDM'
import TJobCardScheduleDM from './TJobCardScheduleDM'
import TJobCardVariantScheduleDM from './TJobCardVariantScheduleDM'

/**
 * Zdroj průvodky
 */
export default class TJobCardResourceDM extends Data {
	public static className = 'TJobCardResourceDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = false

	/**
	 * TP
	 */
	RoutingResourceRID?: number

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * Datum potv.
	 */
	OperationConfirmationDate?: Date

	/**
	 * Pořadí
	 */
	SequenceNumber?: number

	/**
	 * Počet zdrojů
	 */
	Quantity?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * undefined
	 */
	OperationCode?: string

	/**
	 * undefined
	 */
	OperationSequenceNumber?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * undefined
	 */
	PlannedTimeCalc?: number

	/**
	 * undefined
	 */
	ActualTimeCalc?: number

	/**
	 * undefined
	 */
	DurationCalc?: number

	/**
	 * undefined
	 */
	CalItemTotalDurationCalc?: number

	/**
	 * undefined
	 */
	OperationTimeRemainingCalc?: number

	/**
	 * undefined
	 */
	IsManuallyAdjustedScheduleCalc?: string

	/**
	 * Operace
	 */
	@Type(() => TJobCardOperationDM)
	JobCardOperationRID?: TJobCardOperationDM

	/**
	 * Průvodka
	 */
	@Type(() => TJobCardDM)
	HeaderRID?: TJobCardDM

	/**
	 * Čís.zdroje
	 */
	@Type(() => TResourceDM)
	ResourceId?: TResourceDM

	/**
	 * Čas operace
	 */
	@Type(() => TTextDM)
	OperationTime?: TTextDM

	/**
	 * Skupina
	 */
	@Type(() => TResourceDM)
	GroupId?: TResourceDM

	/**
	 * K
	 */
	@Type(() => TTextDM)
	InconsistentCalItemBitCalc?: TTextDM

	/**
	 * Čís.zdroje
	 */
	@Type(() => TResourceDM)
	ResourceByIdInputCalc?: TResourceDM

	/**
	 * Čís.zdroje
	 */
	@Type(() => TResourceDM)
	ResourceByNameInputCalc?: TResourceDM

	/**
	 * Položky kalendáře
	 */
	@Type(() => TJobCardScheduleDM)
	JobCardScheduleChild?: TJobCardScheduleDM[]

	/**
	 * undefined
	 */
	@Type(() => TJobCardVariantScheduleDM)
	JobCardVariantScheduleChild?: TJobCardVariantScheduleDM[]

	/**
	 * undefined
	 */
	@Type(() => TJobCardScheduleDM)
	JobCardScheduleArchivedChild?: TJobCardScheduleDM[]

	/**
	 * undefined
	 */
	@Type(() => TJobCardScheduleDM)
	JobCardScheduleScheduledChild?: TJobCardScheduleDM[]
}