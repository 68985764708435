import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TransportKind from './TransportKind'
import TLanguageTextTranslationDM from './TLanguageTextTranslationDM'
import TShippingMethodDM from './TShippingMethodDM'

/**
 * Způsob dopravy
 */
export default class TTransportMethodDM extends Data {
	public static className = 'TTransportMethodDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = true

	/**
	 * Vyhrazený způsob odběru
	 */
	IsShippingMethodReserved?: string

	/**
	 * Blokováno
	 */
	IsBlockedRecord?: string

	/**
	 * Maximální hmotnost
	 */
	MaximumWeight?: number

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * Časové razítko
	 */
	TimeStamp?: string

	/**
	 * Dlouhý popis
	 */
	Description?: string

	/**
	 * Právo na záznam
	 */
	RightGroupId?: number

	/**
	 * Popis
	 */
	Name?: string

	/**
	 * &Zkratka
	 */
	Abbr?: string

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * SQLSequenceOrderCalc
	 */
	SQLSequenceOrderCalc?: number

	/**
	 * undefined
	 */
	ShippingMethodRIDCalc?: number

	/**
	 * Jazykový popis
	 */
	LanguageTextCalc?: string

	/**
	 * Právo na záznam
	 */
	RightGroupIdCalc?: number

	/**
	 * Druh dopravy
	 */
	@Type(() => TransportKind)
	TransportModeId?: TransportKind

	/**
	 * undefined
	 */
	@Type(() => TLanguageTextTranslationDM)
	LanguageNameChild?: TLanguageTextTranslationDM[]

	/**
	 * Způsob odběru
	 */
	@Type(() => TShippingMethodDM)
	ShippingMethodChild?: TShippingMethodDM[]

	/**
	 * Změna skupiny práv
	 */
	public ChangeGroupRightCOMMAND() {
		return this.executeCommand('ChangeGroupRightCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public BulkChangeGroupRightCOMMAND() {
		return this.executeCommand('BulkChangeGroupRightCOMMAND')
	}

	/**
	 * Aktualizace počítaných polí
	 */
	public BulkUpdateCalcFieldsCOMMAND() {
		return this.executeCommand('BulkUpdateCalcFieldsCOMMAND')
	}

	/**
	 * Kontrola legislativy
	 */
	public SwitchOffLegislationValidationCOMMAND() {
		return this.executeCommand('SwitchOffLegislationValidationCOMMAND')
	}
}