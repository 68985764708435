import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TK2UserLookupDM from './TK2UserLookupDM'

/**
 * Printer type
 */
export default class TSpecialPrinterType extends Data {
	public static className = 'TSpecialPrinterType'
	public static primaryKey = 'Id'
	public static systemFields = [
	]

	public getPrimaryKey(): PrimaryKey {
		return this.Id!
	}

	public static isHeader = true

	/**
	 * Changed On
	 */
	ChangedOn?: Date

	/**
	 * Created On
	 */
	CreatedOn?: Date

	/**
	 * Name
	 */
	Name?: string

	/**
	 * Abbr
	 */
	Abbr?: string

	/**
	 * Id
	 */
	Id?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Changed By
	 */
	@Type(() => TK2UserLookupDM)
	ChangedBy?: TK2UserLookupDM

	/**
	 * Created By
	 */
	@Type(() => TK2UserLookupDM)
	CreatedBy?: TK2UserLookupDM

	/**
	 * Změna skupiny práv
	 */
	public ChangeGroupRightCOMMAND() {
		return this.executeCommand('ChangeGroupRightCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public BulkChangeGroupRightCOMMAND() {
		return this.executeCommand('BulkChangeGroupRightCOMMAND')
	}

	/**
	 * Aktualizace počítaných polí
	 */
	public BulkUpdateCalcFieldsCOMMAND() {
		return this.executeCommand('BulkUpdateCalcFieldsCOMMAND')
	}

	/**
	 * Kontrola legislativy
	 */
	public SwitchOffLegislationValidationCOMMAND() {
		return this.executeCommand('SwitchOffLegislationValidationCOMMAND')
	}
}