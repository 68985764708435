import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import ElectroGroup from './ElectroGroup'
import TCurrencyDM from './TCurrencyDM'
import GoodsName from './GoodsName'
import TArticleDM from './TArticleDM'

/**
 * Elektroodpad
 */
export default class TArticleElectricalWasteDM extends Data {
	public static className = 'TArticleElectricalWasteDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = false

	/**
	 * Koeficient
	 */
	Koeficient?: number

	/**
	 * Cena
	 */
	Price?: number

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Datum pro "_ver" pole
	 */
	VersionFieldDateCalc?: Date

	/**
	 * Cena_ver
	 */
	Price_ver?: number

	/**
	 * Skupina
	 */
	@Type(() => ElectroGroup)
	ElectroGroupId?: ElectroGroup

	/**
	 * Měna
	 */
	@Type(() => TCurrencyDM)
	Currency?: TCurrencyDM

	/**
	 * Název
	 */
	@Type(() => GoodsName)
	ArticleName?: GoodsName

	/**
	 * Zboží
	 */
	@Type(() => TArticleDM)
	ArticleId?: TArticleDM

	/**
	 * Nadřízené zboží
	 */
	@Type(() => TArticleDM)
	HeaderId?: TArticleDM

	/**
	 * Skupina_ver
	 */
	@Type(() => ElectroGroup)
	ElectroGroupId_ver?: ElectroGroup
}