import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TTextDM from './TTextDM'
import TDocumentShreddingPlanDM from './TDocumentShreddingPlanDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'

/**
 * Konfigurace dokumentu
 */
export default class TExternalDocumentConfigurationDM extends Data {
	public static className = 'TExternalDocumentConfigurationDM'
	public static primaryKey = 'Id'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.Id!
	}

	public static isHeader = true

	/**
	 * Časové razítko
	 */
	TimeStamp?: string

	/**
	 * Právo na záznam
	 */
	RightGroupId?: number

	/**
	 * Typ
	 */
	TypeId?: number

	/**
	 * Popis
	 */
	Description?: string

	/**
	 * Zkratka
	 */
	Abbr?: string

	/**
	 * Číslo
	 */
	Id?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * SQLSequenceOrderCalc
	 */
	SQLSequenceOrderCalc?: number

	/**
	 * Text TXT
	 */
	PlainTextCalc?: string

	/**
	 * Popis
	 */
	LanguageDescriptionCalc?: string

	/**
	 * Přel.
	 */
	TranslatedImgCalc?: string

	/**
	 * Poznámka
	 */
	NoteCalc?: string

	/**
	 * Text
	 */
	TextCalc?: string

	/**
	 * Popis
	 */
	Description100Calc?: string

	/**
	 * undefined
	 */
	ContentTextCalc?: string

	/**
	 * undefined
	 */
	ContentPlainTextCalc?: string

	/**
	 * undefined
	 */
	TextFormatCalc?: number

	/**
	 * Vstupní složka
	 */
	InputPathCalc?: string

	/**
	 * Archivační složka
	 */
	ArchiveFolderPathCalc?: string

	/**
	 * Extenze
	 */
	DefaultExtensionCalc?: string

	/**
	 * Maska
	 */
	MaskCalc?: string

	/**
	 * Spouštěcí aplikace
	 */
	RunCalc?: string

	/**
	 * Parametry aplikace
	 */
	ApplicationParameterCalc?: string

	/**
	 * Výstupní název
	 */
	OutputNameCalc?: string

	/**
	 * Souborová operace
	 */
	CopyFileKindSupplementCalc?: string

	/**
	 * Přenos dokumentu
	 */
	DocumentTransferCalc?: string

	/**
	 * Vstupní složka
	 */
	InputFolderCalc?: string

	/**
	 * Archivační složka
	 */
	ArchivePathCalc?: string

	/**
	 * Popis typu
	 */
	DescriptionCalc?: string

	/**
	 * Složka dokumentu
	 */
	DocumentPathCalc?: string

	/**
	 * Štítky dokumentu
	 */
	TagsCalc?: string

	/**
	 * Zapnuto verzování
	 */
	IsVersioningOnCalc?: string

	/**
	 * Smazat zdrojový soubor po importu
	 */
	DeleteFileAfterImportCalc?: string

	/**
	 * Maximální velikost souboru
	 */
	MaxFileSizeCalc?: number

	/**
	 * undefined
	 */
	NameCalc?: string

	/**
	 * Skrýt v nabídce
	 */
	HiddenInMenuBitCalc?: number

	/**
	 * Právo na záznam
	 */
	RightGroupIdCalc?: number

	/**
	 * Zkratka
	 */
	@Type(() => TTextDM)
	LanguageAbbrCalc?: TTextDM

	/**
	 * Přeloženo
	 */
	@Type(() => TTextDM)
	TranslatedBitCalc?: TTextDM

	/**
	 * Souborová operace
	 */
	@Type(() => TTextDM)
	CopyFileKindIdCalc?: TTextDM

	/**
	 * Skartační plán
	 */
	@Type(() => TDocumentShreddingPlanDM)
	ShreddingPlanIdCalc?: TDocumentShreddingPlanDM

	/**
	 * undefined
	 */
	@Type(() => TLanguageMemoTranslationDM)
	LanguageMemoChild?: TLanguageMemoTranslationDM[]

	/**
	 * Zneplatnění/Zplatnění
	 */
	public InvalidationCOMMAND() {
		return this.executeCommand('InvalidationCOMMAND')
	}

	/**
	 * Výběr typu
	 */
	public TypeIdChangeCOMMAND(parameters: TTypeIdChangeCOMMANDParams) {
		return this.executeCommand('TypeIdChangeCOMMAND', parameters)
	}

	/**
	 * Změna skupiny práv
	 */
	public ChangeGroupRightCOMMAND() {
		return this.executeCommand('ChangeGroupRightCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public BulkChangeGroupRightCOMMAND() {
		return this.executeCommand('BulkChangeGroupRightCOMMAND')
	}

	/**
	 * Aktualizace počítaných polí
	 */
	public BulkUpdateCalcFieldsCOMMAND() {
		return this.executeCommand('BulkUpdateCalcFieldsCOMMAND')
	}

	/**
	 * Kontrola legislativy
	 */
	public SwitchOffLegislationValidationCOMMAND() {
		return this.executeCommand('SwitchOffLegislationValidationCOMMAND')
	}
}

export type TTypeIdChangeCOMMANDParams = {
	/**
	 * Typ
	 */
	TypeId?: number
	/**
	 * MessageText
	 */
	MessageText?: string
}