import Api from './Api'
import Serializer from './Serializer'
import { plainToInstance, instanceToInstance } from 'class-transformer'
import { diff } from 'deep-object-diff'
import axios from 'axios'
import PrimaryKey from './PrimaryKey'
import ISimpleObject from './ISimpleObject'
import IPagedList from './IPagedList'
import IDataObjectWrapper from './IDataObjectWrapper'
import DataList from './DataList'
import ICommandExecuteParams from './ICommandExecuteParams'
import ICommandExecuteResult from './ICommandExecuteResult'
import { Pagination } from './Pagination'
import { PageParams } from './PageParams'

export default class Data {
    private static api = new Api()
    private static serializer = new Serializer()

    public static className: string
    public static primaryKey: string
    public static systemFields: string[]

    public static isHeader: boolean

    private _originalData?: Data
    public _isDeleted?: boolean

    public set originalData(data: Data) {
        if (this._originalData) {
            throw new Error('Property originalData is already assigned.')
        }

        this._originalData = data
    }

    public get originalData(): Data {
        return this._originalData!
    }

    public getPrimaryKey(): PrimaryKey | undefined {
        throw new Error('Method "getPrimaryKey" must be defined in descendant class.')
    }

    public static async get(primaryKey: PrimaryKey, fields?: string[]): Promise<any> {
        const url = this.api.getDataUrl(this.className, primaryKey)
        return axios.post(url,
            {
                Fields: this.addSystemFields(fields)?.join(',')
            },
            {
                headers: this.api.getAuthorizationHeader(this.api.getApiLogin(), this.api.getApiPassword(), url)
            }).then(response => {
                return this.createDataInstance(response.data)
            })
    }

    public static async getList(fields?: string[], conditions?: string[], orderBy?: string[], pageParams?: PageParams): Promise<[any[], Pagination]> {
        const url = this.api.getDataListUrl(this.className)
        return axios.post(url,
            {
                Conditions: conditions?.join(','),
                Fields: this.addSystemFields(fields)?.join(','),
                OrderBy: orderBy?.join(','),
                Page: pageParams?.page,
                PageState: pageParams?.pageState,
                PageSize: pageParams?.pageSize
            },
            {
                headers: this.api.getAuthorizationHeader(this.api.getApiLogin(), this.api.getApiPassword(), url)
            }).then(response => {
                return [this.createDataListInstance(response.data), this.getPagination(response.data)]
            })
    }

    public static async getListBySelection(selectionId: string, fields?: string[], orderBy?: string[], pageParams?: PageParams): Promise<[any[], Pagination]> {
        const url = this.api.getDataListBySelectionUrl(this.className, fields, orderBy, pageParams)
        return axios.post(url,
            {
                SelectionId: selectionId
            },
            {
                headers: this.api.getAuthorizationHeader(this.api.getApiLogin(), this.api.getApiPassword(), url)
            }).then(response => {
                return [this.createDataListInstance(response.data), this.getPagination(response.data)]
            })
    }

    public async put(fields?: string[]): Promise<any> {
        const c = (this.constructor as typeof Data)
        const url = c.api.getDataUrlWithFields(c.className, this.getPrimaryKey(), c.addSystemFields(fields))
        return axios.put(url, c.serializer.unserializeDataObject(this.getChangedData()), {
            headers: c.api.getAuthorizationHeader(c.api.getApiLogin(), c.api.getApiPassword(), url)
        })
            .then(response => {
                return c.createDataInstance(response.data)
            })
    }

    public async post(fields?: string[]): Promise<any> {
        const c = (this.constructor as typeof Data)
        const url = c.api.getDataUrlWithFields(c.className, this.getPrimaryKey(), c.addSystemFields(fields))
        return axios.post(url, c.serializer.unserializeDataObject(this), {
            headers: c.api.getAuthorizationHeader(c.api.getApiLogin(), c.api.getApiPassword(), url)
        }).then(response => {
            return c.createDataInstance(response.data)
        })
    }

    public async executeCommand(commandId: string, parameters?: {}): Promise<ICommandExecuteResult> {
        const c = (this.constructor as typeof Data)
        const url = c.api.getCommandUrl(commandId)
        const data: ICommandExecuteParams = {
            CurrentPrimaryKey: [{
                Name: c.primaryKey,
                Value: this.getPrimaryKey()
            }],
            DataObjectName: c.className
        }

        if (parameters) {
            data.Parameters = Object.entries(parameters).map(keyValue => { return { Name: keyValue[0], Value: keyValue[1] } })
        }

        return axios.post(url, data, {
            headers: c.api.getAuthorizationHeader(c.api.getApiLogin(), c.api.getApiPassword(), url)
        }).then(response => {
            return response.data
        })
    }

    private getChangedData(): Data {
        const changedData: ISimpleObject = {}
        let queue = [{
            dataChanges: diff(this.originalData, this) as ISimpleObject,
            originalData: this,
            changedData: changedData,
            addSystemFields: true
        }]

        while (queue.length > 0) {
            const item = queue.pop()!
            let changedKeys = Object.keys(item.dataChanges).reverse().filter(key => key !== '_originalData')

            if (typeof item.originalData === 'object' && !Array.isArray(item.originalData)) {
                const c = (item.originalData.constructor as typeof Data)
                changedKeys.push(c.primaryKey)
                c.systemFields.forEach(field => changedKeys.push(field))
            }

            for (const [key, value] of Object.entries(item.originalData)) {
                if (!changedKeys.includes(key)) continue
                if (typeof item.dataChanges[key] === 'object') {
                    let myChangedData = item.changedData[key]
                    if (Array.isArray(value)) {
                        item.changedData[key] = []
                        myChangedData = item.changedData[key]
                    } else {
                        myChangedData = plainToInstance(value.constructor as typeof Data, {})
                        if (Array.isArray(item.changedData)) {
                            item.changedData.push(myChangedData)
                        } else {
                            item.changedData[key] = myChangedData
                        }
                    }

                    queue.push({
                        dataChanges: item.dataChanges[key],
                        originalData: value,
                        changedData: myChangedData,
                        addSystemFields: Array.isArray(item.originalData)
                    })
                } else {
                    item.changedData[key] = value
                }
            }
        }

        return plainToInstance(this.constructor as typeof Data, changedData)
    }

    private static createDataInstance(dataObject: IDataObjectWrapper): Data {
        let data = plainToInstance(this, this.serializer.serializeDataObject(dataObject))
        data.originalData = instanceToInstance(data)

        return data
    }

    private static createDataListInstance(pagedList: IPagedList): DataList {
        let dataList = new DataList()
        pagedList.Items.forEach(dataObject => {
            dataList.push(this.createDataInstance(dataObject))
        })

        return dataList
    }

    private static getPagination(pagedList: IPagedList): Pagination {
        const getPageParams: (urlString?: string) => PageParams | undefined = (urlString) => {
            if (!urlString) return undefined
            const url = new URL(urlString)

            return {
                page: url.searchParams.get('page'),
                pageState: url.searchParams.get('pageState'),
                pageSize: url.searchParams.get('pageSize') ? parseInt(url.searchParams.get('pageSize')!) : null
            }
        }

        return {
            firstPage: getPageParams(pagedList.FirstPageURL),
            nextPage: getPageParams(pagedList.NextPageURL),
            prevPage: getPageParams(pagedList.PrevPageURL),
            lastPage: getPageParams(pagedList.LastPageURL)
        }
    }

    private static addSystemFields(fields?: string[]): string[] | undefined {
        if (Array.isArray(fields)) {
            const newFields = fields.slice()
            this.systemFields.forEach(systemField => {
                if (!newFields.includes(systemField)) newFields.push(systemField)
            })

            return newFields
        }

        return fields
    }
}