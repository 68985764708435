import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TTextDM from './TTextDM'
import TCostCentreCollectionDocumentDM from './TCostCentreCollectionDocumentDM'
import TAddressDM from './TAddressDM'
import StockType from './StockType'
import TSPSPostingKindDM from './TSPSPostingKindDM'
import TK2UserLookupDM from './TK2UserLookupDM'
import TAccountingChartDM from './TAccountingChartDM'
import TLocationArticleDM from './TLocationArticleDM'
import TMarketingActivityTypeDM from './TMarketingActivityTypeDM'
import TCountryDM from './TCountryDM'
import TTownPartDM from './TTownPartDM'
import TTownDM from './TTownDM'
import TCommentDM from './TCommentDM'
import TWarehouseZoneDM from './TWarehouseZoneDM'
import TWarehouseItemDM from './TWarehouseItemDM'
import TWarehouseSummationDM from './TWarehouseSummationDM'
import TLanguageTextTranslationDM from './TLanguageTextTranslationDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TAttachedDocumentDM from './TAttachedDocumentDM'
import TOldNoteDM from './TOldNoteDM'

/**
 * Sklady
 */
export default class TWarehouseDM extends Data {
	public static className = 'TWarehouseDM'
	public static primaryKey = 'Id'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.Id!
	}

	public static isHeader = true

	/**
	 * Nastavení
	 */
	Settings?: string

	/**
	 * Firma
	 */
	CompanyId?: number

	/**
	 * Datum blokace skl. pohybů
	 */
	BlockingDate?: Date

	/**
	 * Časové razítko
	 */
	TimeStamp?: string

	/**
	 * Právo na záznam
	 */
	RightGroupId?: number

	/**
	 * Tran_F
	 */
	Transfer_F?: number

	/**
	 * Změněno
	 */
	ChangedOn?: Date

	/**
	 * Skladník
	 */
	WarehousemanId?: number

	/**
	 * Plocha
	 */
	Area?: string

	/**
	 * Název
	 */
	Name?: string

	/**
	 * Zkratka
	 */
	Abbr?: string

	/**
	 * Číslo
	 */
	Id?: number

	/**
	 * Blokace potvrzení
	 */
	EX_BlockConfirm?: string

	/**
	 * EX_ht_pozmnoz
	 */
	EX_ht_pozmnoz?: number

	/**
	 * EX_t_pozmnoz
	 */
	EX_t_pozmnoz?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * S
	 */
	WarehouseTypeImgCalc?: string

	/**
	 * U
	 */
	AccountWarehouseImgCalc?: string

	/**
	 * B
	 */
	BlockedMovesImgCalc?: string

	/**
	 * Jaz.název
	 */
	LanguageTextCalc?: string

	/**
	 * Implicitní účetní
	 */
	IsDefaultAccountingWarehouseCalc?: string

	/**
	 * undefined
	 */
	IsSummingWarehouseCalc?: string

	/**
	 * Sumační
	 */
	SummingBitCalc?: number

	/**
	 * Všechny pohyby skladu
	 */
	AllStockMovementsBitCalc?: number

	/**
	 * Příjemky - přijem
	 */
	ReceiptCardReceiptBitCalc?: number

	/**
	 * Příjemky - výdej
	 */
	ReceiptCardReleaseBitCalc?: number

	/**
	 * Výdejky - příjem
	 */
	ReleaseNoteReceiptBitCalc?: number

	/**
	 * Výdejky - výdej
	 */
	ReleaseNoteReleaseBitCalc?: number

	/**
	 * Převodky - příjem
	 */
	TransferNoteReceiptBitCalc?: number

	/**
	 * Převodky - výdej
	 */
	TransferNoteReleaseBitCalc?: number

	/**
	 * Účetní
	 */
	AccountingWarehouseBitCalc?: number

	/**
	 * Evidování umístění
	 */
	LocationEvidenceBitCalc?: number

	/**
	 * Evidování kódů zákázek
	 */
	ContractCodeEvidenceBitCalc?: number

	/**
	 * Průvodky - příjem
	 */
	JobCardReceiptBitCalc?: number

	/**
	 * Průvodky - výdej
	 */
	JobCardReleaseBitCalc?: number

	/**
	 * Do Rezervováno a Objednáno načítat položky s nezadaným plánovaným skladem
	 */
	LoadWthWrhToResAndOrdBitCalc?: number

	/**
	 * Evidování manipulačních jednotek
	 */
	HandlingUnitRecordsBitCalc?: number

	/**
	 * Automat pouze pro zboží na 1 umístění
	 */
	Automat1IdCalc?: number

	/**
	 * undefined
	 */
	TextHeaderMemoCalc?: string

	/**
	 * undefined
	 */
	TextFooterMemoCalc?: string

	/**
	 * Poslední komentář
	 */
	LastCommentParamCalc?: string

	/**
	 * Ulice (Adresa)
	 */
	AddressIdStreetCalc?: string

	/**
	 * Číslo orientační (Adresa)
	 */
	AddressIdOrientationNumberCalc?: string

	/**
	 * Číslo popisné (Adresa)
	 */
	AddressIdHouseNumberCalc?: string

	/**
	 * Název (Adresa)
	 */
	AddressIdNameCalc?: string

	/**
	 * PSČ (Adresa)
	 */
	AddressIdTownCodeCalc?: string

	/**
	 * Souřadnice (Adresa)
	 */
	AddressIdCoordinatesCalc?: string

	/**
	 * Právo na záznam
	 */
	RightGroupIdCalc?: number

	/**
	 * Typ sumace
	 */
	@Type(() => TTextDM)
	SumCalculationModeId?: TTextDM

	/**
	 * Středisko
	 */
	@Type(() => TCostCentreCollectionDocumentDM)
	CostCentreId?: TCostCentreCollectionDocumentDM

	/**
	 * Adresa
	 */
	@Type(() => TAddressDM)
	AddressId?: TAddressDM

	/**
	 * Typ skladu
	 */
	@Type(() => StockType)
	WarehouseTypeId?: StockType

	/**
	 * Typ skl. ceny
	 */
	@Type(() => TTextDM)
	StockPriceTypeId?: TTextDM

	/**
	 * Druh účt.
	 */
	@Type(() => TSPSPostingKindDM)
	PostingKindId?: TSPSPostingKindDM

	/**
	 * Změnil
	 */
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM

	/**
	 * Účet
	 */
	@Type(() => TAccountingChartDM)
	AccountId?: TAccountingChartDM

	/**
	 * undefined
	 */
	@Type(() => TLocationArticleDM)
	SurplusLocationIdCalc?: TLocationArticleDM

	/**
	 * D
	 */
	@Type(() => TTextDM)
	DocumentExistsCalc?: TTextDM

	/**
	 * undefined
	 */
	@Type(() => TMarketingActivityTypeDM)
	ActivityTypeRIDCalc?: TMarketingActivityTypeDM

	/**
	 * Stát (Adresa)
	 */
	@Type(() => TCountryDM)
	AddressIdCountryIdCalc?: TCountryDM

	/**
	 * Část obce (Adresa)
	 */
	@Type(() => TTownPartDM)
	AddressIdTownPartIdCalc?: TTownPartDM

	/**
	 * Číslo (Adresa)
	 */
	@Type(() => TTownDM)
	AddressIdTownIdCalc?: TTownDM

	/**
	 * Komentáře
	 */
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]

	/**
	 * Skladová zóna
	 */
	@Type(() => TWarehouseZoneDM)
	ZoneChild?: TWarehouseZoneDM[]

	/**
	 * Listové sklady
	 */
	@Type(() => TWarehouseItemDM)
	ItemChild?: TWarehouseItemDM[]

	/**
	 * Sumační sklady
	 */
	@Type(() => TWarehouseSummationDM)
	SummationChild?: TWarehouseSummationDM[]

	/**
	 * undefined
	 */
	@Type(() => TLanguageTextTranslationDM)
	LanguageNameChild?: TLanguageTextTranslationDM[]

	/**
	 * Text hlavičky
	 */
	@Type(() => TLanguageMemoTranslationDM)
	TextHeaderChild?: TLanguageMemoTranslationDM[]

	/**
	 * Text patičky
	 */
	@Type(() => TLanguageMemoTranslationDM)
	TextFooterChild?: TLanguageMemoTranslationDM[]

	/**
	 * Svázané doklady
	 */
	@Type(() => TAttachedDocumentDM)
	AttachedDocumentOldChild?: TAttachedDocumentDM[]

	/**
	 * Poznámky
	 */
	@Type(() => TOldNoteDM)
	DeprecatedNoteOldChild?: TOldNoteDM[]

	/**
	 * Potvrzení
	 */
	public BulkConfirmCOMMAND() {
		return this.executeCommand('BulkConfirmCOMMAND')
	}

	/**
	 * Odpotvrzení
	 */
	public BulkUnconfirmCOMMAND() {
		return this.executeCommand('BulkUnconfirmCOMMAND')
	}

	/**
	 * Změna data potvrzení
	 */
	public BulkChangeConfirmationDateCOMMAND(parameters: TBulkChangeConfirmationDateCOMMANDParams) {
		return this.executeCommand('BulkChangeConfirmationDateCOMMAND', parameters)
	}

	/**
	 * Storno
	 */
	public BulkCancelCOMMAND() {
		return this.executeCommand('BulkCancelCOMMAND')
	}

	/**
	 * Odstorno
	 */
	public BulkUnCancelCOMMAND() {
		return this.executeCommand('BulkUnCancelCOMMAND')
	}

	/**
	 * Potvrzení
	 */
	public ConfirmCOMMAND() {
		return this.executeCommand('ConfirmCOMMAND')
	}

	/**
	 * Odpotvrzení
	 */
	public UnconfirmCOMMAND() {
		return this.executeCommand('UnconfirmCOMMAND')
	}

	/**
	 * Změna data potvrzení
	 */
	public ChangeConfirmationDateCOMMAND(parameters: TChangeConfirmationDateCOMMANDParams) {
		return this.executeCommand('ChangeConfirmationDateCOMMAND', parameters)
	}

	/**
	 * Storno/Odstorno
	 */
	public CancelCOMMAND() {
		return this.executeCommand('CancelCOMMAND')
	}

	/**
	 * Zrušení stavu
	 */
	public ClearRecordStatusCOMMAND() {
		return this.executeCommand('ClearRecordStatusCOMMAND')
	}

	/**
	 * Změna stavu
	 */
	public ChangeRecordStatusCOMMAND() {
		return this.executeCommand('ChangeRecordStatusCOMMAND')
	}

	/**
	 * 
	 */
	public ChangeRecordStatusSWSCOMMAND(parameters: TChangeRecordStatusSWSCOMMANDParams) {
		return this.executeCommand('ChangeRecordStatusSWSCOMMAND', parameters)
	}

	/**
	 * Zrušení stavu
	 */
	public BulkClearRecordStatusCOMMAND() {
		return this.executeCommand('BulkClearRecordStatusCOMMAND')
	}

	/**
	 * Změna stavu
	 */
	public BulkChangeRecordStatusCOMMAND() {
		return this.executeCommand('BulkChangeRecordStatusCOMMAND')
	}

	/**
	 * Změna atributů
	 */
	public BulkChangeAttributesCOMMAND(parameters: TBulkChangeAttributesCOMMANDParams) {
		return this.executeCommand('BulkChangeAttributesCOMMAND', parameters)
	}

	/**
	 * Aktualizace sady atributů
	 */
	public BulkActualizeAttributesCOMMAND(parameters: TBulkActualizeAttributesCOMMANDParams) {
		return this.executeCommand('BulkActualizeAttributesCOMMAND', parameters)
	}

	/**
	 * Aktualizace sady atributů
	 */
	public ActualizeAttributesCOMMAND() {
		return this.executeCommand('ActualizeAttributesCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public ChangeGroupRightCOMMAND() {
		return this.executeCommand('ChangeGroupRightCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public BulkChangeGroupRightCOMMAND() {
		return this.executeCommand('BulkChangeGroupRightCOMMAND')
	}

	/**
	 * Aktualizace počítaných polí
	 */
	public BulkUpdateCalcFieldsCOMMAND() {
		return this.executeCommand('BulkUpdateCalcFieldsCOMMAND')
	}

	/**
	 * Kontrola legislativy
	 */
	public SwitchOffLegislationValidationCOMMAND() {
		return this.executeCommand('SwitchOffLegislationValidationCOMMAND')
	}
}

export type TBulkChangeConfirmationDateCOMMANDParams = {
	/**
	 * Datum potvrzení
	 */
	Confirmation1Date?: Date
	/**
	 * Hodina potvrzení
	 */
	Confirmation1Time?: Date
	/**
	 * Datum 2. potvrzení
	 */
	Confirmation2Date?: Date
	/**
	 * Hodina 2. potvrzení
	 */
	Confirmation2Time?: Date
	/**
	 * Kontrolovat záporné stavy
	 */
	CheckNegativeState?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TChangeConfirmationDateCOMMANDParams = {
	/**
	 * Datum potvrzení
	 */
	Confirmation1Date?: Date
	/**
	 * Hodina potvrzení
	 */
	Confirmation1Time?: Date
	/**
	 * Datum 2. potvrzení
	 */
	Confirmation2Date?: Date
	/**
	 * Hodina 2. potvrzení
	 */
	Confirmation2Time?: Date
	/**
	 * Kontrolovat záporné stavy
	 */
	CheckNegativeState?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TChangeRecordStatusSWSCOMMANDParams = {
	/**
	 * StatusId
	 */
	StatusId?: number
}

export type TBulkChangeAttributesCOMMANDParams = {
	/**
	 * Smazat atributy
	 */
	DeleteAttribute?: boolean
	/**
	 * Smazat všechny volné atributy
	 */
	DeleteFreeAttribute?: boolean
	/**
	 * Nastavit hodnoty atributů
	 */
	SetValues?: boolean
	/**
	 * Přidat nepřipojené atributy
	 */
	AddFreeAttributes?: boolean
	/**
	 * FreeAttributeEnabled
	 */
	FreeAttributeEnabled?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TBulkActualizeAttributesCOMMANDParams = {
	/**
	 * Aktualizovat včetně samotné sady atributů
	 */
	ActualizeAttributeSet?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}