import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TArticleDM from './TArticleDM'
import TTextDM from './TTextDM'
import TLanguageTextTranslationDM from './TLanguageTextTranslationDM'

/**
 * Definice kupónů
 */
export default class TLoyaltyProgramCouponDM extends Data {
	public static className = 'TLoyaltyProgramCouponDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = false

	/**
	 * Název
	 */
	Name?: string

	/**
	 * Platnost do
	 */
	ValidTo?: Date

	/**
	 * Počet dnů
	 */
	ValidDays?: number

	/**
	 * Sleva v procentech
	 */
	IsPercentageForDiscount?: string

	/**
	 * Platí od částky
	 */
	ValidFromPrice?: number

	/**
	 * Hodnota kupónu
	 */
	Discount?: number

	/**
	 * Počet bodů
	 */
	Points?: number

	/**
	 * Věrnostní program
	 */
	LoyaltyProgramId?: number

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * undefined
	 */
	NameCalc?: string

	/**
	 * undefined
	 */
	DiscountSuffixCalc?: string

	/**
	 * undefined
	 */
	DiscountCurrencyCalc?: string

	/**
	 * Karta zboží
	 */
	@Type(() => TArticleDM)
	ArticleId?: TArticleDM

	/**
	 * Typ
	 */
	@Type(() => TTextDM)
	TypeId?: TTextDM

	/**
	 * undefined
	 */
	@Type(() => TLanguageTextTranslationDM)
	LanguageNameChild?: TLanguageTextTranslationDM[]
}