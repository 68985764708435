import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TK2UserLookupDM from './TK2UserLookupDM'
import TContactPersonDM from './TContactPersonDM'
import AreaCode from './AreaCode'
import TAddressDM from './TAddressDM'
import TWarehouseDM from './TWarehouseDM'
import TPartnerDM from './TPartnerDM'
import TTextDM from './TTextDM'
import TElectronicAddressTypeDM from './TElectronicAddressTypeDM'
import TCountryDM from './TCountryDM'
import TMarketingActivityTypeDM from './TMarketingActivityTypeDM'
import TTownPartDM from './TTownPartDM'
import TTownDM from './TTownDM'
import TCommentDM from './TCommentDM'
import TElectronicAddressDM from './TElectronicAddressDM'
import TOpeningHoursDM from './TOpeningHoursDM'
import TExternalDocumentItemDM from './TExternalDocumentItemDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TAttachedDocumentDM from './TAttachedDocumentDM'
import TOldNoteDM from './TOldNoteDM'

/**
 * Pobočky
 */
export default class TBranchDM extends Data {
	public static className = 'TBranchDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = true

	/**
	 * Web
	 */
	WebRID?: number

	/**
	 * Kategorie
	 */
	WebModuleCategoryRID?: number

	/**
	 * Změněno
	 */
	ChangedOn?: Date

	/**
	 * Vytvořeno
	 */
	CreatedOn?: Date

	/**
	 * Datum importu
	 */
	ImportDate?: Date

	/**
	 * ExternalLabel
	 */
	ExternalLabel?: string

	/**
	 * ExternalId
	 */
	ExternalId?: string

	/**
	 * Dlouhý popis
	 */
	LongDescription?: string

	/**
	 * Časové razítko
	 */
	TimeStamp?: string

	/**
	 * Heureka ID
	 */
	HeurekaId?: string

	/**
	 * Popis
	 */
	Description?: string

	/**
	 * Název
	 */
	Name?: string

	/**
	 * Zkratka
	 */
	Abbr?: string

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	BookmarkImgCalc?: string

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Datum pro "_ver" pole
	 */
	VersionedFieldDateCalc?: Date

	/**
	 * undefined
	 */
	ElectronicAddress1Calc?: string

	/**
	 * undefined
	 */
	ElectronicAddress2Calc?: string

	/**
	 * undefined
	 */
	ElectronicAddress3Calc?: string

	/**
	 * undefined
	 */
	ElectronicAddress4Calc?: string

	/**
	 * undefined
	 */
	ElectronicAddress1FormattedCalc?: string

	/**
	 * undefined
	 */
	ElectronicAddress2FormattedCalc?: string

	/**
	 * undefined
	 */
	ElectronicAddress3FormattedCalc?: string

	/**
	 * undefined
	 */
	ElectronicAddress4FormattedCalc?: string

	/**
	 * El. adresa
	 */
	EAddressPARAMCalc?: string

	/**
	 * undefined
	 */
	TextHeaderMemoCalc?: string

	/**
	 * undefined
	 */
	TextFooterMemoCalc?: string

	/**
	 * Poslední komentář
	 */
	LastCommentParamCalc?: string

	/**
	 * Adresa_ver
	 */
	AddressId_ver?: number

	/**
	 * Ulice (Adresa)
	 */
	AddressIdStreetCalc?: string

	/**
	 * Číslo orientační (Adresa)
	 */
	AddressIdOrientationNumberCalc?: string

	/**
	 * Číslo popisné (Adresa)
	 */
	AddressIdHouseNumberCalc?: string

	/**
	 * Název (Adresa)
	 */
	AddressIdNameCalc?: string

	/**
	 * Změněno (Adresa)
	 */
	AddressIdChangedOnCalc?: Date

	/**
	 * PSČ (Adresa)
	 */
	AddressIdTownCodeCalc?: string

	/**
	 * Souřadnice (Adresa)
	 */
	AddressIdCoordinatesCalc?: string

	/**
	 * undefined
	 */
	ElectronicAddressCheckItemFieldCalc?: number

	/**
	 * Změnil
	 */
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM

	/**
	 * Vytvořil
	 */
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM

	/**
	 * Kontaktní osoba
	 */
	@Type(() => TContactPersonDM)
	ContactPersonId?: TContactPersonDM

	/**
	 * Region
	 */
	@Type(() => AreaCode)
	RegionId?: AreaCode

	/**
	 * Adresa
	 */
	@Type(() => TAddressDM)
	AddressId?: TAddressDM

	/**
	 * Sklad
	 */
	@Type(() => TWarehouseDM)
	WarehouseId?: TWarehouseDM

	/**
	 * Partner
	 */
	@Type(() => TPartnerDM)
	PartnerId?: TPartnerDM

	/**
	 * Z
	 */
	@Type(() => TTextDM)
	InvalidRecordImgCalc?: TTextDM

	/**
	 * undefined
	 */
	@Type(() => TElectronicAddressTypeDM)
	ElectronicAddress1TypeIdCalc?: TElectronicAddressTypeDM

	/**
	 * undefined
	 */
	@Type(() => TElectronicAddressTypeDM)
	ElectronicAddress2TypeIdCalc?: TElectronicAddressTypeDM

	/**
	 * undefined
	 */
	@Type(() => TElectronicAddressTypeDM)
	ElectronicAddress3TypeIdCalc?: TElectronicAddressTypeDM

	/**
	 * undefined
	 */
	@Type(() => TElectronicAddressTypeDM)
	ElectronicAddress4TypeIdCalc?: TElectronicAddressTypeDM

	/**
	 * undefined
	 */
	@Type(() => TCountryDM)
	ElectronicAddressCountry1IdCalc?: TCountryDM

	/**
	 * undefined
	 */
	@Type(() => TCountryDM)
	ElectronicAddressCountry2IdCalc?: TCountryDM

	/**
	 * undefined
	 */
	@Type(() => TCountryDM)
	ElectronicAddressCountry3IdCalc?: TCountryDM

	/**
	 * undefined
	 */
	@Type(() => TCountryDM)
	ElectronicAddressCountry4IdCalc?: TCountryDM

	/**
	 * D
	 */
	@Type(() => TTextDM)
	DocumentExistsCalc?: TTextDM

	/**
	 * undefined
	 */
	@Type(() => TMarketingActivityTypeDM)
	ActivityTypeRIDCalc?: TMarketingActivityTypeDM

	/**
	 * Stát (Adresa)
	 */
	@Type(() => TCountryDM)
	AddressIdCountryIdCalc?: TCountryDM

	/**
	 * Část obce (Adresa)
	 */
	@Type(() => TTownPartDM)
	AddressIdTownPartIdCalc?: TTownPartDM

	/**
	 * Číslo (Adresa)
	 */
	@Type(() => TTownDM)
	AddressIdTownIdCalc?: TTownDM

	/**
	 * Komentáře
	 */
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]

	/**
	 * Kontaktní informace
	 */
	@Type(() => TElectronicAddressDM)
	ElectronicAddressChild?: TElectronicAddressDM[]

	/**
	 * undefined
	 */
	@Type(() => TOpeningHoursDM)
	OpeningHoursChild?: TOpeningHoursDM[]

	/**
	 * Dokumenty
	 */
	@Type(() => TExternalDocumentItemDM)
	ExternalDocumentItemChild?: TExternalDocumentItemDM[]

	/**
	 * Text hlavičky
	 */
	@Type(() => TLanguageMemoTranslationDM)
	TextHeaderChild?: TLanguageMemoTranslationDM[]

	/**
	 * Text patičky
	 */
	@Type(() => TLanguageMemoTranslationDM)
	TextFooterChild?: TLanguageMemoTranslationDM[]

	/**
	 * Svázané doklady
	 */
	@Type(() => TAttachedDocumentDM)
	AttachedDocumentOldChild?: TAttachedDocumentDM[]

	/**
	 * Poznámky
	 */
	@Type(() => TOldNoteDM)
	DeprecatedNoteOldChild?: TOldNoteDM[]

	/**
	 * Zneplatnění
	 */
	public BulkInvalidationCOMMAND() {
		return this.executeCommand('BulkInvalidationCOMMAND')
	}

	/**
	 * Zplatnění
	 */
	public BulkUnInvalidationCOMMAND() {
		return this.executeCommand('BulkUnInvalidationCOMMAND')
	}

	/**
	 * Zneplatnění/Zplatnění
	 */
	public InvalidationCOMMAND() {
		return this.executeCommand('InvalidationCOMMAND')
	}

	/**
	 * Potvrzení
	 */
	public BulkConfirmCOMMAND() {
		return this.executeCommand('BulkConfirmCOMMAND')
	}

	/**
	 * Odpotvrzení
	 */
	public BulkUnconfirmCOMMAND() {
		return this.executeCommand('BulkUnconfirmCOMMAND')
	}

	/**
	 * Změna data potvrzení
	 */
	public BulkChangeConfirmationDateCOMMAND(parameters: TBulkChangeConfirmationDateCOMMANDParams) {
		return this.executeCommand('BulkChangeConfirmationDateCOMMAND', parameters)
	}

	/**
	 * Storno
	 */
	public BulkCancelCOMMAND() {
		return this.executeCommand('BulkCancelCOMMAND')
	}

	/**
	 * Odstorno
	 */
	public BulkUnCancelCOMMAND() {
		return this.executeCommand('BulkUnCancelCOMMAND')
	}

	/**
	 * Potvrzení
	 */
	public ConfirmCOMMAND() {
		return this.executeCommand('ConfirmCOMMAND')
	}

	/**
	 * Odpotvrzení
	 */
	public UnconfirmCOMMAND() {
		return this.executeCommand('UnconfirmCOMMAND')
	}

	/**
	 * Změna data potvrzení
	 */
	public ChangeConfirmationDateCOMMAND(parameters: TChangeConfirmationDateCOMMANDParams) {
		return this.executeCommand('ChangeConfirmationDateCOMMAND', parameters)
	}

	/**
	 * Storno/Odstorno
	 */
	public CancelCOMMAND() {
		return this.executeCommand('CancelCOMMAND')
	}

	/**
	 * Zrušení stavu
	 */
	public ClearRecordStatusCOMMAND() {
		return this.executeCommand('ClearRecordStatusCOMMAND')
	}

	/**
	 * Změna stavu
	 */
	public ChangeRecordStatusCOMMAND() {
		return this.executeCommand('ChangeRecordStatusCOMMAND')
	}

	/**
	 * 
	 */
	public ChangeRecordStatusSWSCOMMAND(parameters: TChangeRecordStatusSWSCOMMANDParams) {
		return this.executeCommand('ChangeRecordStatusSWSCOMMAND', parameters)
	}

	/**
	 * Zrušení stavu
	 */
	public BulkClearRecordStatusCOMMAND() {
		return this.executeCommand('BulkClearRecordStatusCOMMAND')
	}

	/**
	 * Změna stavu
	 */
	public BulkChangeRecordStatusCOMMAND() {
		return this.executeCommand('BulkChangeRecordStatusCOMMAND')
	}

	/**
	 * Změna atributů
	 */
	public BulkChangeAttributesCOMMAND(parameters: TBulkChangeAttributesCOMMANDParams) {
		return this.executeCommand('BulkChangeAttributesCOMMAND', parameters)
	}

	/**
	 * Aktualizace sady atributů
	 */
	public BulkActualizeAttributesCOMMAND(parameters: TBulkActualizeAttributesCOMMANDParams) {
		return this.executeCommand('BulkActualizeAttributesCOMMAND', parameters)
	}

	/**
	 * Aktualizace sady atributů
	 */
	public ActualizeAttributesCOMMAND() {
		return this.executeCommand('ActualizeAttributesCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public ChangeGroupRightCOMMAND() {
		return this.executeCommand('ChangeGroupRightCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public BulkChangeGroupRightCOMMAND() {
		return this.executeCommand('BulkChangeGroupRightCOMMAND')
	}

	/**
	 * Aktualizace počítaných polí
	 */
	public BulkUpdateCalcFieldsCOMMAND() {
		return this.executeCommand('BulkUpdateCalcFieldsCOMMAND')
	}

	/**
	 * Kontrola legislativy
	 */
	public SwitchOffLegislationValidationCOMMAND() {
		return this.executeCommand('SwitchOffLegislationValidationCOMMAND')
	}
}

export type TBulkChangeConfirmationDateCOMMANDParams = {
	/**
	 * Datum potvrzení
	 */
	Confirmation1Date?: Date
	/**
	 * Hodina potvrzení
	 */
	Confirmation1Time?: Date
	/**
	 * Datum 2. potvrzení
	 */
	Confirmation2Date?: Date
	/**
	 * Hodina 2. potvrzení
	 */
	Confirmation2Time?: Date
	/**
	 * Kontrolovat záporné stavy
	 */
	CheckNegativeState?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TChangeConfirmationDateCOMMANDParams = {
	/**
	 * Datum potvrzení
	 */
	Confirmation1Date?: Date
	/**
	 * Hodina potvrzení
	 */
	Confirmation1Time?: Date
	/**
	 * Datum 2. potvrzení
	 */
	Confirmation2Date?: Date
	/**
	 * Hodina 2. potvrzení
	 */
	Confirmation2Time?: Date
	/**
	 * Kontrolovat záporné stavy
	 */
	CheckNegativeState?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TChangeRecordStatusSWSCOMMANDParams = {
	/**
	 * StatusId
	 */
	StatusId?: number
}

export type TBulkChangeAttributesCOMMANDParams = {
	/**
	 * Smazat atributy
	 */
	DeleteAttribute?: boolean
	/**
	 * Smazat všechny volné atributy
	 */
	DeleteFreeAttribute?: boolean
	/**
	 * Nastavit hodnoty atributů
	 */
	SetValues?: boolean
	/**
	 * Přidat nepřipojené atributy
	 */
	AddFreeAttributes?: boolean
	/**
	 * FreeAttributeEnabled
	 */
	FreeAttributeEnabled?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TBulkActualizeAttributesCOMMANDParams = {
	/**
	 * Aktualizovat včetně samotné sady atributů
	 */
	ActualizeAttributeSet?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}