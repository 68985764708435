import Data from 'react-k2-api/Api/Data'
import { Type } from 'class-transformer'
import TArticleKindDM from './TArticleKindDM'

/**
 * TBatchCostItemDM
 */
export default class TBatchCostItemDM extends Data {
	public static className = 'TBatchCostItemDM'
	public static systemFields = [
		'RecordID',
	]

	public static isHeader = false

	/**
	 * Nákladová cena
	 */
	CostPrice?: number

	/**
	 * Skladová cena
	 */
	StockPrice?: number

	/**
	 * Plánovaná cena
	 */
	PlannedPrice?: number

	/**
	 * Šarže
	 */
	BatchId?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * undefined
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Druh
	 */
	@Type(() => TArticleKindDM)
	ArticleCategoryId?: TArticleKindDM
}