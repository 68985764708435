import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TTextDM from './TTextDM'
import TRegisteredDataModuleDM from './TRegisteredDataModuleDM'

/**
 * Registrované body
 */
export default class TRegisteredPointDM extends Data {
	public static className = 'TRegisteredPointDM'
	public static primaryKey = 'PointId'
	public static systemFields = [
	]

	public getPrimaryKey(): PrimaryKey {
		return this.PointId!
	}

	public static isHeader = true

	/**
	 * Číslo bodu
	 */
	RecordID?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * SQLSequenceOrderCalc
	 */
	SQLSequenceOrderCalc?: number

	/**
	 * Číslo bodu
	 */
	@Type(() => TTextDM)
	PointId?: TTextDM

	/**
	 * Datový modul
	 */
	@Type(() => TRegisteredDataModuleDM)
	RegisteredDataModuleId?: TRegisteredDataModuleDM

	/**
	 * Změna skupiny práv
	 */
	public ChangeGroupRightCOMMAND() {
		return this.executeCommand('ChangeGroupRightCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public BulkChangeGroupRightCOMMAND() {
		return this.executeCommand('BulkChangeGroupRightCOMMAND')
	}

	/**
	 * Aktualizace počítaných polí
	 */
	public BulkUpdateCalcFieldsCOMMAND() {
		return this.executeCommand('BulkUpdateCalcFieldsCOMMAND')
	}

	/**
	 * Kontrola legislativy
	 */
	public SwitchOffLegislationValidationCOMMAND() {
		return this.executeCommand('SwitchOffLegislationValidationCOMMAND')
	}
}