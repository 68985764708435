import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TRecordNumberDM from './TRecordNumberDM'
import TRegisteredPointDM from './TRegisteredPointDM'

/**
 * Registrované datové moduly
 */
export default class TRegisteredDataModuleDM extends Data {
	public static className = 'TRegisteredDataModuleDM'
	public static primaryKey = 'Number'
	public static systemFields = [
	]

	public getPrimaryKey(): PrimaryKey {
		return this.Number!
	}

	public static isHeader = true

	/**
	 * Popis
	 */
	Description?: string

	/**
	 * Třída
	 */
	ClassName?: string

	/**
	 * Číslo předka DM
	 */
	SuperiorDataModuleNumber?: number

	/**
	 * Číslo DM
	 */
	Number?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * SQLSequenceOrderCalc
	 */
	SQLSequenceOrderCalc?: number

	/**
	 * Má číslování
	 */
	HasNumberingCalc?: string

	/**
	 * Aktuální
	 */
	ActualCalc?: number

	/**
	 * Minimální
	 */
	MinCalc?: number

	/**
	 * Maximální
	 */
	MaxCalc?: number

	/**
	 * Má číslování dokladů
	 */
	HasBookNumberingCalc?: string

	/**
	 * Typ knih
	 */
	BookModuleCalc?: number

	/**
	 * Typ dokumentu
	 */
	DocumentTypeIdCalc?: number

	/**
	 * Čerpáno [%]
	 */
	PercentCalc?: number

	/**
	 * Kontrolní procento
	 */
	AlertPctCalc?: number

	/**
	 * V
	 */
	AlertCalc?: number

	/**
	 * Číslování dokladů
	 */
	@Type(() => TRecordNumberDM)
	BookNumberChild?: TRecordNumberDM[]

	/**
	 * undefined
	 */
	@Type(() => TRegisteredPointDM)
	RegisteredPointChild?: TRegisteredPointDM[]

	/**
	 * Korekce počátečních stavů
	 */
	public BulkNumberingCorrectionCOMMAND() {
		return this.executeCommand('BulkNumberingCorrectionCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public ChangeGroupRightCOMMAND() {
		return this.executeCommand('ChangeGroupRightCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public BulkChangeGroupRightCOMMAND() {
		return this.executeCommand('BulkChangeGroupRightCOMMAND')
	}

	/**
	 * Aktualizace počítaných polí
	 */
	public BulkUpdateCalcFieldsCOMMAND() {
		return this.executeCommand('BulkUpdateCalcFieldsCOMMAND')
	}

	/**
	 * Kontrola legislativy
	 */
	public SwitchOffLegislationValidationCOMMAND() {
		return this.executeCommand('SwitchOffLegislationValidationCOMMAND')
	}
}