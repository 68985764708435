import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TCode6DocumentDM from './TCode6DocumentDM'
import TCode5DocumentDM from './TCode5DocumentDM'
import TCode4DocumentDM from './TCode4DocumentDM'
import TCode3DocumentDM from './TCode3DocumentDM'
import TTextDM from './TTextDM'
import TAccountingJournalDM from './TAccountingJournalDM'
import TBanksDM from './TBanksDM'
import TDeviceDocumentDM from './TDeviceDocumentDM'
import TPeriodDM from './TPeriodDM'
import TBookDM from './TBookDM'
import TSalesOrderDM from './TSalesOrderDM'
import TK2UserLookupDM from './TK2UserLookupDM'
import AdvanceReceivedStatus from './AdvanceReceivedStatus'
import KonstSymb from './KonstSymb'
import TMatchingSymbolDM from './TMatchingSymbolDM'
import TAccountingChartDM from './TAccountingChartDM'
import TOfficerDocumentDM from './TOfficerDocumentDM'
import TCode2DocumentDM from './TCode2DocumentDM'
import TCode1DocumentDM from './TCode1DocumentDM'
import TCostCentreCollectionDocumentDM from './TCostCentreCollectionDocumentDM'
import TContractCodeDocumentDM from './TContractCodeDocumentDM'
import TPaymentMethodDM from './TPaymentMethodDM'
import TTaxTypeDM from './TTaxTypeDM'
import TCurrencyDM from './TCurrencyDM'
import TTradingPartnerDM from './TTradingPartnerDM'
import TAddressDM from './TAddressDM'
import TVATRateDM from './TVATRateDM'
import TMarketingAddressDM from './TMarketingAddressDM'
import TMarketingActivityTypeDM from './TMarketingActivityTypeDM'
import TWorkflowProcessDM from './TWorkflowProcessDM'
import TActivityDM from './TActivityDM'
import TWarehouseDM from './TWarehouseDM'
import TLinkDM from './TLinkDM'
import TCommentDM from './TCommentDM'
import TJobDM from './TJobDM'
import TInternalDocumentDM from './TInternalDocumentDM'
import TRelatedAddressDocumentDM from './TRelatedAddressDocumentDM'
import TAdvanceDeductionAdvanceDM from './TAdvanceDeductionAdvanceDM'
import TDocumentReminderDM from './TDocumentReminderDM'
import TExternalDocumentItemDM from './TExternalDocumentItemDM'
import TWorkflowProcessAsItemDM from './TWorkflowProcessAsItemDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TDocumentAccountingJournalItemDM from './TDocumentAccountingJournalItemDM'
import TAttachedDocumentDM from './TAttachedDocumentDM'
import TOldNoteDM from './TOldNoteDM'

/**
 * Zálohy přijaté
 */
export default class TAdvanceReceivedDM extends Data {
	public static className = 'TAdvanceReceivedDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = true

	/**
	 * Firma
	 */
	BookId_CompanyId?: number

	/**
	 * Skup. právo
	 */
	BookId_RightGroupId?: number

	/**
	 * Vytvářet upomínky
	 */
	CreateReminders?: string

	/**
	 * Plánované datum zaplacení
	 */
	PlannedPaymentDate?: Date

	/**
	 * Nezahrnovat do řízení financí
	 */
	NotIncludeIntoFinancialMngmt?: string

	/**
	 * Specifický symbol
	 */
	SpecificSymbol?: string

	/**
	 * IBAN
	 */
	IBAN?: string

	/**
	 * Vytvořeno
	 */
	CreatedOn?: Date

	/**
	 * Nastavení
	 */
	Settings?: string

	/**
	 * Daňové číslo
	 */
	TaxNumber?: string

	/**
	 * DIČ
	 */
	VATRegNumber?: string

	/**
	 * IČ
	 */
	CompanyRegNumber?: string

	/**
	 * Doplatit M
	 */
	RemainingAmountC?: number

	/**
	 * DPH M
	 */
	AmountVATC?: number

	/**
	 * Netto M
	 */
	AmountNetC?: number

	/**
	 * Brutto M
	 */
	AmountGrossC?: number

	/**
	 * Externí číslo
	 */
	ExternalNumber?: string

	/**
	 * Číslo
	 */
	Number?: number

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * Časové razítko
	 */
	TimeStamp?: string

	/**
	 * Datum UZP
	 */
	TaxPointDate?: Date

	/**
	 * Právo na záznam
	 */
	RightGroupId?: number

	/**
	 * Uhrazeno M
	 */
	AmountSettledC?: number

	/**
	 * Zaplaceno arch. M
	 */
	AmountPaidByArchiveC?: number

	/**
	 * Zaplaceno M
	 */
	AmountPaidC?: number

	/**
	 * Zaplaceno
	 */
	IsPaid?: string

	/**
	 * Zapl. plat. dokl. arch.
	 */
	AmountPaidByArchive?: number

	/**
	 * Datum posl. platby
	 */
	PaymentDate?: Date

	/**
	 * Poč. likv. pol.
	 */
	AccountingItemsCount?: number

	/**
	 * Likvidoval
	 */
	PostedById?: number

	/**
	 * Datum likvidace
	 */
	PostedOn?: Date

	/**
	 * Datum potvrzení
	 */
	ConfirmedOn?: Date

	/**
	 * Změněno
	 */
	ChangedOn?: Date

	/**
	 * Doplatit
	 */
	RemainingAmount?: number

	/**
	 * Zaplaceno
	 */
	AmountPaid?: number

	/**
	 * Uhrazeno
	 */
	AmountSettled?: number

	/**
	 * Popis
	 */
	Description?: string

	/**
	 * Variabilní symbol
	 */
	ReferenceNumber?: string

	/**
	 * Datum splatnosti
	 */
	DueDate?: Date

	/**
	 * Datum úč. případu
	 */
	AccountingTransactionDate?: Date

	/**
	 * Datum vystavení
	 */
	IssueDate?: Date

	/**
	 * Číslo účtu
	 */
	TradingPartnerBankAccount?: string

	/**
	 * Kontace
	 */
	PostingKeyId?: number

	/**
	 * Kurz 2
	 */
	ExchangeRate2?: number

	/**
	 * Kurz
	 */
	ExchangeRate?: number

	/**
	 * DPH
	 */
	AmountVAT?: number

	/**
	 * Netto
	 */
	AmountNet?: number

	/**
	 * Brutto
	 */
	AmountGross?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	BookmarkImgCalc?: string

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Číslo
	 */
	DocumentNumberCalc?: number

	/**
	 * SQLSequenceOrderCalc
	 */
	SQLSequenceOrderCalc?: number

	/**
	 * Datum pro "_ver" pole
	 */
	VersionedFieldDateCalc?: Date

	/**
	 * Odkaz
	 */
	PictureCalc?: string

	/**
	 * Měna vl. f.
	 */
	ClientCurrencyCalc?: string

	/**
	 * Kurz
	 */
	ExchangeRateCalc?: number

	/**
	 * Kurz 2
	 */
	ExchangeRate2Calc?: number

	/**
	 * Splatnost
	 */
	MaturityDaysCalc?: number

	/**
	 * DPH koef.
	 */
	VATCoefficientCalc?: number

	/**
	 * Brutto
	 */
	PriceGrossCalc?: number

	/**
	 * Netto
	 */
	PriceNetCalc?: number

	/**
	 * DPH
	 */
	PriceVATCalc?: number

	/**
	 * Brutto M
	 */
	PriceGrossCCalc?: number

	/**
	 * Netto M
	 */
	PriceNetCCalc?: number

	/**
	 * DPH M
	 */
	PriceVATCCalc?: number

	/**
	 * Měna kř.
	 */
	CurrencyCheckBoxCalc?: string

	/**
	 * Čerpáno
	 */
	DeductedAmountCalc?: number

	/**
	 * Čerpáno M
	 */
	DeductedAmountCCalc?: number

	/**
	 * Dočerpat M
	 */
	RemainsToDeductCCalc?: number

	/**
	 * OnlyPaidBitCalc
	 */
	OnlyPaidBitCalc?: number

	/**
	 * undefined
	 */
	CreditNoteImgCalc?: string

	/**
	 * Uhrazeno M
	 */
	AmountSettledCCalc?: number

	/**
	 * Zaplaceno M
	 */
	AmountPaidCCalc?: number

	/**
	 * Zapl. arch. M
	 */
	AmountPaidByArchiveCalc?: number

	/**
	 * Doplatit M
	 */
	RemainingAmountCCalc?: number

	/**
	 * Daň. dokl.
	 */
	TaxDocumentBitCalc?: number

	/**
	 * undefined
	 */
	NotReachedIncomeHeaInsBitCalc?: number

	/**
	 * undefined
	 */
	PaidAndPaidByArchiveAmountCalc?: number

	/**
	 * undefined
	 */
	CancelOrAmountGrossCalc?: number

	/**
	 * Nez. nebo nevyčerp.
	 */
	IsUnpaidOrUndeductedBitCalc?: number

	/**
	 * Stav daň. dokladu
	 */
	TaxDocumentStateCalc?: string

	/**
	 * Netto účto
	 */
	AccountNetCalc?: number

	/**
	 * DPH účto
	 */
	AccountVATCalc?: number

	/**
	 * Doklad DPH
	 */
	VATDocumentImgCalc?: string

	/**
	 * ExchangeRate2Or1Calc
	 */
	ExchangeRate2Or1Calc?: number

	/**
	 * undefined
	 */
	AdvanceExchangeRateDiffCalc?: number

	/**
	 * undefined
	 */
	AdvanceExchangeRateDiffImgCalc?: string

	/**
	 * undefined
	 */
	InvoiceAddressExistsImgCalc?: string

	/**
	 * undefined
	 */
	DeliveryAddressExistsImgCalc?: string

	/**
	 * undefined
	 */
	PostalAddressExistsImgCalc?: string

	/**
	 * Fakturační adresa (tisk)
	 */
	FullInvoiceAddressIdCalc?: number

	/**
	 * Dodací adresa (tisk)
	 */
	FullDeliveryAddressIdCalc?: number

	/**
	 * Korespondenční adresa (tisk)
	 */
	FullPostalAddressIdCalc?: number

	/**
	 * Koresp./Fakt. adresa (tisk)
	 */
	FullPostalInvoiceAddressIdCalc?: number

	/**
	 * Název z fakt. adresy
	 */
	InvoiceAddressFullNameCalc?: string

	/**
	 * Název z dod. adresy
	 */
	DeliveryAddressFullNameCalc?: string

	/**
	 * Název z koresp. adresy
	 */
	PostalAddressFullNameCalc?: string

	/**
	 * Název z koresp./fakt. adresy
	 */
	PostalInvoiceAddressFullNameCalc?: string

	/**
	 * Dočerpat DPH M
	 */
	RemainsToDeductVATCCalc?: number

	/**
	 * Dočerpat netto M
	 */
	RemainsToDeductNetCCalc?: number

	/**
	 * undefined
	 */
	PaymentDifferenceCalc?: number

	/**
	 * undefined
	 */
	PaymentDifferenceImgCalc?: string

	/**
	 * IncludeDeductedCalc
	 */
	IncludeDeductedCalc?: number

	/**
	 * Dočerpat
	 */
	RemainsToDeductCalc?: number

	/**
	 * Dočerpat netto
	 */
	RemainsToDeductNetCalc?: number

	/**
	 * Dočerpat DPH
	 */
	RemainsToDeductVATCalc?: number

	/**
	 * undefined
	 */
	PriceEGrossCalc?: number

	/**
	 * undefined
	 */
	TotalPriceNetCalc?: number

	/**
	 * undefined
	 */
	AmountVATCalc?: number

	/**
	 * undefined
	 */
	PriceEGrossCCalc?: number

	/**
	 * undefined
	 */
	TotalPriceNetCCalc?: number

	/**
	 * undefined
	 */
	AmountVATCCalc?: number

	/**
	 * undefined
	 */
	EditedTotalAmountVATCalc?: number

	/**
	 * Vícepoložková záloha
	 */
	MultiAdvanceImgCalc?: string

	/**
	 * undefined
	 */
	BlTestAccTransactionDateCalc?: Date

	/**
	 * undefined
	 */
	BlTestTaxPointDateCalc?: Date

	/**
	 * Kurzový rozdíl
	 */
	ExchangeDifferenceCalc?: number

	/**
	 * Dohradit m.
	 */
	SettleCCalc?: number

	/**
	 * undefined
	 */
	QRCodeCZBankOrderStandardCalc?: string

	/**
	 * undefined
	 */
	QRCodeCZBankOrderLargeCalc?: string

	/**
	 * Počet upom.
	 */
	ReminderCountCalc?: number

	/**
	 * Datum upom.
	 */
	ReminderIssueDateCalc?: Date

	/**
	 * Č. upom.
	 */
	ReminderDocumentCalc?: string

	/**
	 * undefined
	 */
	MaturityDateFromCalc?: Date

	/**
	 * undefined
	 */
	CurrencyExchangeRateCalc?: number

	/**
	 * Fakturační adresa
	 */
	InvoiceAddressPlainCalc?: string

	/**
	 * Dodací adresa
	 */
	DeliveryAddressPlainCalc?: string

	/**
	 * Korespondenční adresa
	 */
	PostalAddressPlainCalc?: string

	/**
	 * undefined
	 */
	PostZeroAmountDocumentsBitCalc?: number

	/**
	 * undefined
	 */
	QRCZInvoiceCalc?: string

	/**
	 * DIČ
	 */
	VATRegNumberCalc?: string

	/**
	 * AmountInternalPaidPctCalc
	 */
	AmountInternalPaidPctCalc?: number

	/**
	 * EET
	 */
	IsRecordInEETCalc?: string

	/**
	 * Kód banky
	 */
	BankCodeNumberCalc?: string

	/**
	 * Datum KH
	 */
	VATControlStatementDateCalc?: Date

	/**
	 * undefined
	 */
	FullAccountNumberCalc?: string

	/**
	 * Předpokládané datum zaplacení
	 */
	ExpectedPaymentDateCalc?: Date

	/**
	 * undefined
	 */
	QRCodeSKBankOrderPayBySquareCalc?: string

	/**
	 * Firma
	 */
	TaxTradingPartnerNameCalc?: string

	/**
	 * undefined
	 */
	RoundDifferenceGrossCCalc?: number

	/**
	 * Výpočet DPH shora
	 */
	VATGrossCalcMethodBitCalc?: number

	/**
	 * DIČ z adresáta
	 */
	RecipientVATRegNumberBitCalc?: number

	/**
	 * undefined
	 */
	MultiAdvanceBitCalc?: number

	/**
	 * undefined
	 */
	TextHeaderMemoCalc?: string

	/**
	 * undefined
	 */
	TextFooterMemoCalc?: string

	/**
	 * Poslední komentář
	 */
	LastCommentParamCalc?: string

	/**
	 * Částka dokladu
	 */
	DocumentAmountCalc?: number

	/**
	 * Saldo
	 */
	AccountBalanceCalc?: number

	/**
	 * Účet
	 */
	DocumentAccountCalc?: string

	/**
	 * Z
	 */
	PostedCalc?: string

	/**
	 * Datum zaúčtování
	 */
	PostedOnCalc?: Date

	/**
	 * Rozdíl v zaúčtování
	 */
	AccountingDocDifferenceCalc?: number

	/**
	 * undefined
	 */
	OwnAddressIdCalc?: number

	/**
	 * undefined
	 */
	OwnAddressNameCalc?: string

	/**
	 * Doklad
	 */
	DocumentIdentificationCalc?: string

	/**
	 * ZR
	 */
	PostedManuallyCalc?: string

	/**
	 * EAN kód
	 */
	BarcodeCalc?: string

	/**
	 * Zaúčtovat bez částky
	 */
	PostZeroAmountBitCalc?: number

	/**
	 * IČO
	 */
	CompanyRegNumberCalc?: string

	/**
	 * DIČ
	 */
	VATRegNumberNotFullCalc?: string

	/**
	 * Daňové číslo
	 */
	TaxNumberCalc?: string

	/**
	 * undefined
	 */
	IsReversedExchangeRateViewCalc?: string

	/**
	 * undefined
	 */
	AccAssignmentPostingViewCalc?: string

	/**
	 * Naplánované platby v měně
	 */
	PlannedPaymentAmountCCalc?: number

	/**
	 * Naplánované platby
	 */
	PlannedPaymentAmountCalc?: number

	/**
	 * Zbývá doplatit plán M
	 */
	PlannedRemainingAmountCCalc?: number

	/**
	 * Zbývá doplatit plán
	 */
	PlannedRemainingAmountCalc?: number

	/**
	 * Měna P
	 */
	PlannedCurrencyCalc?: string

	/**
	 * undefined
	 */
	ShowTotalInBaseCurrencyCalc?: string

	/**
	 * undefined
	 */
	ShowRemainingBaseCurrencyCalc?: string

	/**
	 * Prodlení
	 */
	AverageDelayCalc?: number

	/**
	 * Přiznání k DPH
	 */
	VATDocumentIdentificationCalc?: string

	/**
	 * Kurz k datu
	 */
	ExchangeRateDateInformationCalc?: string

	/**
	 * VIES datum
	 */
	RatingViesDateCalc?: Date

	/**
	 * VIES hodnocení
	 */
	RatingViesValueCalc?: number

	/**
	 * VIES konzultace
	 */
	RatingViesKeyCalc?: string

	/**
	 * Právo na záznam
	 */
	RightGroupIdCalc?: number

	/**
	 * Kód 6
	 */
	@Type(() => TCode6DocumentDM)
	Code6Id?: TCode6DocumentDM

	/**
	 * Kód 5
	 */
	@Type(() => TCode5DocumentDM)
	Code5Id?: TCode5DocumentDM

	/**
	 * Kód 4
	 */
	@Type(() => TCode4DocumentDM)
	Code4Id?: TCode4DocumentDM

	/**
	 * Kód 3
	 */
	@Type(() => TCode3DocumentDM)
	Code3Id?: TCode3DocumentDM

	/**
	 * Stav platby
	 */
	@Type(() => TTextDM)
	PaymentStatusId?: TTextDM

	/**
	 * Priorita
	 */
	@Type(() => TTextDM)
	PaymentPriorityId?: TTextDM

	/**
	 * Účetní doklad
	 */
	@Type(() => TAccountingJournalDM)
	AccountingDocumentRID?: TAccountingJournalDM

	/**
	 * Banka
	 */
	@Type(() => TBanksDM)
	BankId?: TBanksDM

	/**
	 * Prostředek
	 */
	@Type(() => TDeviceDocumentDM)
	DeviceRID?: TDeviceDocumentDM

	/**
	 * Období
	 */
	@Type(() => TPeriodDM)
	BusinessYearId?: TPeriodDM

	/**
	 * Kniha
	 */
	@Type(() => TBookDM)
	BookId?: TBookDM

	/**
	 * Bank. účet
	 */
	@Type(() => TBookDM)
	BankBookId?: TBookDM

	/**
	 * Zakázka
	 */
	@Type(() => TSalesOrderDM)
	SalesOrderRID?: TSalesOrderDM

	/**
	 * Přepnul na DD
	 */
	@Type(() => TK2UserLookupDM)
	TaxDocumentSetById?: TK2UserLookupDM

	/**
	 * Vytvořil
	 */
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM

	/**
	 * Stav
	 */
	@Type(() => AdvanceReceivedStatus)
	StatusId?: AdvanceReceivedStatus

	/**
	 * Konstantní symbol
	 */
	@Type(() => KonstSymb)
	ConstantSymbol?: KonstSymb

	/**
	 * Změnil
	 */
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM

	/**
	 * Pár. symbol
	 */
	@Type(() => TMatchingSymbolDM)
	MatchingSymbolId?: TMatchingSymbolDM

	/**
	 * Účet MD
	 */
	@Type(() => TAccountingChartDM)
	DebitAccountId?: TAccountingChartDM

	/**
	 * Účet D
	 */
	@Type(() => TAccountingChartDM)
	CreditAccountId?: TAccountingChartDM

	/**
	 * Referent
	 */
	@Type(() => TOfficerDocumentDM)
	OfficerId?: TOfficerDocumentDM

	/**
	 * Kód 2
	 */
	@Type(() => TCode2DocumentDM)
	Code2Id?: TCode2DocumentDM

	/**
	 * Kód 1
	 */
	@Type(() => TCode1DocumentDM)
	Code1Id?: TCode1DocumentDM

	/**
	 * Středisko
	 */
	@Type(() => TCostCentreCollectionDocumentDM)
	CostCentreId?: TCostCentreCollectionDocumentDM

	/**
	 * Kód zakázky
	 */
	@Type(() => TContractCodeDocumentDM)
	ContractCodeRID?: TContractCodeDocumentDM

	/**
	 * Zp. platby
	 */
	@Type(() => TPaymentMethodDM)
	PaymentMethod?: TPaymentMethodDM

	/**
	 * Typ daně
	 */
	@Type(() => TTaxTypeDM)
	TaxTypeId?: TTaxTypeDM

	/**
	 * Měna
	 */
	@Type(() => TCurrencyDM)
	Currency?: TCurrencyDM

	/**
	 * Odběratel
	 */
	@Type(() => TTradingPartnerDM)
	TradingPartnerId?: TTradingPartnerDM

	/**
	 * Období
	 */
	@Type(() => TPeriodDM)
	BusinessYearIdCalc?: TPeriodDM

	/**
	 * P
	 */
	@Type(() => TTextDM)
	ConfirmedOrCanceledIdCalc?: TTextDM

	/**
	 * Opravný DD
	 */
	@Type(() => TTextDM)
	CreditNoteBitCalc?: TTextDM

	/**
	 * undefined
	 */
	@Type(() => TTextDM)
	ExistsCreditNoteBitCalc?: TTextDM

	/**
	 * Měna kr.
	 */
	@Type(() => TTextDM)
	CurrencyCheckboxBitCalc?: TTextDM

	/**
	 * DPH
	 */
	@Type(() => TTextDM)
	VATBitCalc?: TTextDM

	/**
	 * Zapl.
	 */
	@Type(() => TTextDM)
	PaidStateIdCalc?: TTextDM

	/**
	 * Fakturační adresa
	 */
	@Type(() => TTradingPartnerDM)
	InvoiceAddressTrPartIdCalc?: TTradingPartnerDM

	/**
	 * Dodací adresa
	 */
	@Type(() => TTradingPartnerDM)
	DeliveryAddressTrPartIdCalc?: TTradingPartnerDM

	/**
	 * Doklad DPH
	 */
	@Type(() => TTextDM)
	VATDocumentIdCalc?: TTextDM

	/**
	 * Fakturační adresa
	 */
	@Type(() => TAddressDM)
	InvoiceAddressIdCalc?: TAddressDM

	/**
	 * Dodací adresa
	 */
	@Type(() => TAddressDM)
	DeliveryAddressIdCalc?: TAddressDM

	/**
	 * Korespondenční adresa
	 */
	@Type(() => TAddressDM)
	PostalAddressIdCalc?: TAddressDM

	/**
	 * Poštovní adresa
	 */
	@Type(() => TTradingPartnerDM)
	PostalAddressTrPartIdCalc?: TTradingPartnerDM

	/**
	 * undefined
	 */
	@Type(() => TVATRateDM)
	VATRateIdCalc?: TVATRateDM

	/**
	 * Jedn. adresa - fakturační
	 */
	@Type(() => TMarketingAddressDM)
	SimpleInvoiceAddressRIDCalc?: TMarketingAddressDM

	/**
	 * Jedn. adresa - dodací
	 */
	@Type(() => TMarketingAddressDM)
	SimpleDeliveryAddressRIDCalc?: TMarketingAddressDM

	/**
	 * Jedn. adresa - korespondenční
	 */
	@Type(() => TMarketingAddressDM)
	SimplePostalAddressRIDCalc?: TMarketingAddressDM

	/**
	 * Firma
	 */
	@Type(() => TTradingPartnerDM)
	TaxTradingPartnerIdCalc?: TTradingPartnerDM

	/**
	 * D
	 */
	@Type(() => TTextDM)
	DocumentExistsCalc?: TTextDM

	/**
	 * undefined
	 */
	@Type(() => TMarketingActivityTypeDM)
	ActivityTypeRIDCalc?: TMarketingActivityTypeDM

	/**
	 * SP
	 */
	@Type(() => TTextDM)
	ProcessStateIdCalc?: TTextDM

	/**
	 * Aktuální proces
	 */
	@Type(() => TWorkflowProcessDM)
	CurrentProcessIdCalc?: TWorkflowProcessDM

	/**
	 * undefined
	 */
	@Type(() => TActivityDM)
	ActivityIdCalc?: TActivityDM

	/**
	 * Zaúčtoval
	 */
	@Type(() => TK2UserLookupDM)
	PostedByIdCalc?: TK2UserLookupDM

	/**
	 * undefined
	 */
	@Type(() => TTradingPartnerDM)
	OwnTradingPartnerIdCalc?: TTradingPartnerDM

	/**
	 * Storno
	 */
	@Type(() => TTextDM)
	CanceledRecordBitCalc?: TTextDM

	/**
	 * Příznak Z
	 */
	@Type(() => TTextDM)
	CorrectPostingBitCalc?: TTextDM

	/**
	 * Období
	 */
	@Type(() => TPeriodDM)
	CurrentBusinessYearIdCalc?: TPeriodDM

	/**
	 * Sklad
	 */
	@Type(() => TWarehouseDM)
	CurrentWarehouseIdCalc?: TWarehouseDM

	/**
	 * Odběratel
	 */
	@Type(() => TTradingPartnerDM)
	TradingPartnerByIdInputIdCalc?: TTradingPartnerDM

	/**
	 * Odběratel
	 */
	@Type(() => TTradingPartnerDM)
	TradingPartnerByAbbr2InputIdCalc?: TTradingPartnerDM

	/**
	 * Odběratel
	 */
	@Type(() => TTradingPartnerDM)
	TradingPartnerNameInputIdCalc?: TTradingPartnerDM

	/**
	 * Odběratel
	 */
	@Type(() => TTradingPartnerDM)
	TrPartByCompRegNumberInputIdCalc?: TTradingPartnerDM

	/**
	 * Odkazy
	 */
	@Type(() => TLinkDM)
	LinkChild?: TLinkDM[]

	/**
	 * Komentáře
	 */
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]

	/**
	 * Úkoly
	 */
	@Type(() => TJobDM)
	JobChild?: TJobDM[]

	/**
	 * Interní doklady
	 */
	@Type(() => TInternalDocumentDM)
	InternalDocumentChild?: TInternalDocumentDM[]

	/**
	 * Adresy
	 */
	@Type(() => TRelatedAddressDocumentDM)
	DocumentAddressChild?: TRelatedAddressDocumentDM[]

	/**
	 * Čerpání
	 */
	@Type(() => TAdvanceDeductionAdvanceDM)
	AdvanceDeductionChild?: TAdvanceDeductionAdvanceDM[]

	/**
	 * Položky upomínek
	 */
	@Type(() => TDocumentReminderDM)
	ReminderChild?: TDocumentReminderDM[]

	/**
	 * Seznam aktivit
	 */
	@Type(() => TActivityDM)
	ActivityChild?: TActivityDM[]

	/**
	 * Dokumenty
	 */
	@Type(() => TExternalDocumentItemDM)
	ExternalDocumentItemChild?: TExternalDocumentItemDM[]

	/**
	 * Procesy
	 */
	@Type(() => TWorkflowProcessAsItemDM)
	WorkflowProcessChild?: TWorkflowProcessAsItemDM[]

	/**
	 * Text hlavičky
	 */
	@Type(() => TLanguageMemoTranslationDM)
	TextHeaderChild?: TLanguageMemoTranslationDM[]

	/**
	 * Text patičky
	 */
	@Type(() => TLanguageMemoTranslationDM)
	TextFooterChild?: TLanguageMemoTranslationDM[]

	/**
	 * undefined
	 */
	@Type(() => TDocumentAccountingJournalItemDM)
	DocumentAccJournalItemChild?: TDocumentAccountingJournalItemDM[]

	/**
	 * Svázané doklady
	 */
	@Type(() => TAttachedDocumentDM)
	AttachedDocumentOldChild?: TAttachedDocumentDM[]

	/**
	 * Poznámky
	 */
	@Type(() => TOldNoteDM)
	DeprecatedNoteOldChild?: TOldNoteDM[]

	/**
	 * Nastavit daň. doklad
	 */
	public SetAsTaxDocCOMMAND() {
		return this.executeCommand('SetAsTaxDocCOMMAND')
	}

	/**
	 * Nastavit daň. doklad
	 */
	public BulkSetAsTaxDocCOMMAND() {
		return this.executeCommand('BulkSetAsTaxDocCOMMAND')
	}

	/**
	 * Zrušit daň. doklad
	 */
	public UnsetAsTaxDocCOMMAND() {
		return this.executeCommand('UnsetAsTaxDocCOMMAND')
	}

	/**
	 * Zrušit daň. doklad
	 */
	public BulkUnsetAsTaxDocCOMMAND() {
		return this.executeCommand('BulkUnsetAsTaxDocCOMMAND')
	}

	/**
	 * Korekce pro DD
	 */
	public CorrectionForTaxDocCOMMAND(parameters: TCorrectionForTaxDocCOMMANDParams) {
		return this.executeCommand('CorrectionForTaxDocCOMMAND', parameters)
	}

	/**
	 * Změna platby archivem
	 */
	public ChangePaymentArchCOMMAND(parameters: TChangePaymentArchCOMMANDParams) {
		return this.executeCommand('ChangePaymentArchCOMMAND', parameters)
	}

	/**
	 * Přepočet plateb
	 */
	public PaymentRecalculationCOMMAND() {
		return this.executeCommand('PaymentRecalculationCOMMAND')
	}

	/**
	 * Vybrat PS z účtu
	 */
	public WizardMatchSymbolCOMMAND() {
		return this.executeCommand('WizardMatchSymbolCOMMAND')
	}

	/**
	 * Částečné potvrzení
	 */
	public PartialConfirmCOMMAND() {
		return this.executeCommand('PartialConfirmCOMMAND')
	}

	/**
	 * Částečné odpotvrzení
	 */
	public PartialUnconfirmCOMMAND() {
		return this.executeCommand('PartialUnconfirmCOMMAND')
	}

	/**
	 * Částečné potvrzení
	 */
	public BulkPartialConfirmCOMMAND() {
		return this.executeCommand('BulkPartialConfirmCOMMAND')
	}

	/**
	 * Částečné odpotvrzení
	 */
	public BulkPartialUnconfirmCOMMAND() {
		return this.executeCommand('BulkPartialUnconfirmCOMMAND')
	}

	/**
	 * Přecenění k rozvahovému dni
	 */
	public BulkRevaluationAtBalanceDayCOMMAND() {
		return this.executeCommand('BulkRevaluationAtBalanceDayCOMMAND')
	}

	/**
	 * Storno přecenění k rozvahovému dni
	 */
	public BulkCancelRevaluationCOMMAND() {
		return this.executeCommand('BulkCancelRevaluationCOMMAND')
	}

	/**
	 * Změna DUZP
	 */
	public InvoiceDateChangeCOMMAND(parameters: TInvoiceDateChangeCOMMANDParams) {
		return this.executeCommand('InvoiceDateChangeCOMMAND', parameters)
	}

	/**
	 * Zaúčtování s likvidací
	 */
	public AccountAssignmentPostingCOMMAND(parameters: TAccountAssignmentPostingCOMMANDParams) {
		return this.executeCommand('AccountAssignmentPostingCOMMAND', parameters)
	}

	/**
	 * Hromadné zaúčtování s likvidací
	 */
	public BulkAccountAssignmentPostingCOMMAND(parameters: TBulkAccountAssignmentPostingCOMMANDParams) {
		return this.executeCommand('BulkAccountAssignmentPostingCOMMAND', parameters)
	}

	/**
	 * Likvidace
	 */
	public AccountAssignmentCOMMAND() {
		return this.executeCommand('AccountAssignmentCOMMAND')
	}

	/**
	 * Zapnout rychlý filtr
	 */
	public FastFilterSwitchCOMMAND() {
		return this.executeCommand('FastFilterSwitchCOMMAND')
	}

	/**
	 * Povolit editaci
	 */
	public EditModeIDNoCOMMAND() {
		return this.executeCommand('EditModeIDNoCOMMAND')
	}

	/**
	 * Povolit editaci
	 */
	public EditModeVATRegisterNoCOMMAND() {
		return this.executeCommand('EditModeVATRegisterNoCOMMAND')
	}

	/**
	 * Povolit editaci
	 */
	public EditModeTaxNoCOMMAND() {
		return this.executeCommand('EditModeTaxNoCOMMAND')
	}

	/**
	 * Změna kontace
	 */
	public BulkChangePostingKeyCOMMAND(parameters: TBulkChangePostingKeyCOMMANDParams) {
		return this.executeCommand('BulkChangePostingKeyCOMMAND', parameters)
	}

	/**
	 * Aktualizace Plánovaného data zaplacení dle Prodlení Dod./Odb.
	 */
	public BulkUpdatePlannedDateOfPaymentByAvgDelayCOMMAND() {
		return this.executeCommand('BulkUpdatePlannedDateOfPaymentByAvgDelayCOMMAND')
	}

	/**
	 * Zobrazovat data od období: %s
	 */
	public ApplyLowerLimitForData() {
		return this.executeCommand('ApplyLowerLimitForData')
	}

	/**
	 * Potvrzení
	 */
	public BulkConfirmCOMMAND() {
		return this.executeCommand('BulkConfirmCOMMAND')
	}

	/**
	 * Odpotvrzení
	 */
	public BulkUnconfirmCOMMAND() {
		return this.executeCommand('BulkUnconfirmCOMMAND')
	}

	/**
	 * Změna data potvrzení
	 */
	public BulkChangeConfirmationDateCOMMAND(parameters: TBulkChangeConfirmationDateCOMMANDParams) {
		return this.executeCommand('BulkChangeConfirmationDateCOMMAND', parameters)
	}

	/**
	 * Storno
	 */
	public BulkCancelCOMMAND() {
		return this.executeCommand('BulkCancelCOMMAND')
	}

	/**
	 * Odstorno
	 */
	public BulkUnCancelCOMMAND() {
		return this.executeCommand('BulkUnCancelCOMMAND')
	}

	/**
	 * Potvrzení
	 */
	public ConfirmCOMMAND() {
		return this.executeCommand('ConfirmCOMMAND')
	}

	/**
	 * Odpotvrzení
	 */
	public UnconfirmCOMMAND() {
		return this.executeCommand('UnconfirmCOMMAND')
	}

	/**
	 * Změna data potvrzení
	 */
	public ChangeConfirmationDateCOMMAND(parameters: TChangeConfirmationDateCOMMANDParams) {
		return this.executeCommand('ChangeConfirmationDateCOMMAND', parameters)
	}

	/**
	 * Storno/Odstorno
	 */
	public CancelCOMMAND() {
		return this.executeCommand('CancelCOMMAND')
	}

	/**
	 * Zrušení stavu
	 */
	public ClearRecordStatusCOMMAND() {
		return this.executeCommand('ClearRecordStatusCOMMAND')
	}

	/**
	 * Změna stavu
	 */
	public ChangeRecordStatusCOMMAND() {
		return this.executeCommand('ChangeRecordStatusCOMMAND')
	}

	/**
	 * 
	 */
	public ChangeRecordStatusSWSCOMMAND(parameters: TChangeRecordStatusSWSCOMMANDParams) {
		return this.executeCommand('ChangeRecordStatusSWSCOMMAND', parameters)
	}

	/**
	 * Zrušení stavu
	 */
	public BulkClearRecordStatusCOMMAND() {
		return this.executeCommand('BulkClearRecordStatusCOMMAND')
	}

	/**
	 * Změna stavu
	 */
	public BulkChangeRecordStatusCOMMAND() {
		return this.executeCommand('BulkChangeRecordStatusCOMMAND')
	}

	/**
	 * Změna atributů
	 */
	public BulkChangeAttributesCOMMAND(parameters: TBulkChangeAttributesCOMMANDParams) {
		return this.executeCommand('BulkChangeAttributesCOMMAND', parameters)
	}

	/**
	 * Aktualizace sady atributů
	 */
	public BulkActualizeAttributesCOMMAND(parameters: TBulkActualizeAttributesCOMMANDParams) {
		return this.executeCommand('BulkActualizeAttributesCOMMAND', parameters)
	}

	/**
	 * Aktualizace sady atributů
	 */
	public ActualizeAttributesCOMMAND() {
		return this.executeCommand('ActualizeAttributesCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public ChangeGroupRightCOMMAND() {
		return this.executeCommand('ChangeGroupRightCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public BulkChangeGroupRightCOMMAND() {
		return this.executeCommand('BulkChangeGroupRightCOMMAND')
	}

	/**
	 * Aktualizace počítaných polí
	 */
	public BulkUpdateCalcFieldsCOMMAND() {
		return this.executeCommand('BulkUpdateCalcFieldsCOMMAND')
	}

	/**
	 * Kontrola legislativy
	 */
	public SwitchOffLegislationValidationCOMMAND() {
		return this.executeCommand('SwitchOffLegislationValidationCOMMAND')
	}
}

export type TCorrectionForTaxDocCOMMANDParams = {
	/**
	 * Rozdělit zálohu na dvě
	 */
	DrillAdvance?: boolean
	/**
	 * Snížit částku zálohy podle platby
	 */
	ReduceTheAmountOfTheAdvance?: boolean
	/**
	 * Zvýšit částku zálohy podle platby
	 */
	IncreaseTheAmountOfTheAdvance?: boolean
	/**
	 * Nastavit jako daňový doklad
	 */
	SetAsTaxDoc?: boolean
}

export type TChangePaymentArchCOMMANDParams = {
	/**
	 * Zaplaceno archivem
	 */
	PaidA?: number
	/**
	 * CurrencyCNG
	 */
	CurrencyCNG?: string
	/**
	 * Zaplaceno archivem M
	 */
	PaidCA?: number
	/**
	 * Currency
	 */
	Currency?: string
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TInvoiceDateChangeCOMMANDParams = {
	/**
	 * VATDate
	 */
	VATDate?: Date
}

export type TAccountAssignmentPostingCOMMANDParams = {
	/**
	 * Zaúčtuje
	 */
	ACCPosting?: number
	/**
	 * Kniha
	 */
	ACCBook?: number
	/**
	 * Období
	 */
	ACCBussinessYear?: number
	/**
	 * Datum zaúčtování
	 */
	ACCPostingDate?: Date
	/**
	 * Datum od
	 */
	ACCDateFrom?: Date
	/**
	 * Datum do
	 */
	ACCDateTo?: Date
	/**
	 * Zachovat ručně změněné záznamy
	 */
	ACCHandChange?: boolean
	/**
	 * Likvidace bez částky
	 */
	ACCNulAmount?: boolean
	/**
	 * Povolit zaúčtovat doklady s jiným obdobím
	 */
	ACCOtherBusinessYear?: boolean
	/**
	 * Zaúčtovat nevyrovnané saldo
	 */
	ACCBalance?: boolean
}

export type TBulkAccountAssignmentPostingCOMMANDParams = {
	/**
	 * Zaúčtuje
	 */
	ACCPosting?: number
	/**
	 * Kniha
	 */
	ACCBook?: number
	/**
	 * Období
	 */
	ACCBussinessYear?: number
	/**
	 * Datum zaúčtování
	 */
	ACCPostingDate?: Date
	/**
	 * Datum od
	 */
	ACCDateFrom?: Date
	/**
	 * Datum do
	 */
	ACCDateTo?: Date
	/**
	 * Zachovat ručně změněné záznamy
	 */
	ACCHandChange?: boolean
	/**
	 * Likvidace bez částky
	 */
	ACCNulAmount?: boolean
	/**
	 * Povolit zaúčtovat doklady s jiným obdobím
	 */
	ACCOtherBusinessYear?: boolean
	/**
	 * Zaúčtovat nevyrovnané saldo
	 */
	ACCBalance?: boolean
}

export type TBulkChangePostingKeyCOMMANDParams = {
	/**
	 * Kontace
	 */
	Header_PostingKeyX?: boolean
	/**
	 * Header_PostingKey
	 */
	Header_PostingKey?: number
	/**
	 * Účet
	 */
	Header_AccountX?: boolean
	/**
	 * Header_Account
	 */
	Header_Account?: number
	/**
	 * Účet D
	 */
	Header_CreditAcX?: boolean
	/**
	 * Header_CreditAc
	 */
	Header_CreditAc?: number
	/**
	 * Účet MD
	 */
	Header_DebitAcX?: boolean
	/**
	 * Header_DebitAc
	 */
	Header_DebitAc?: number
	/**
	 * Parovací symbol
	 */
	Header_MatchSymbolX?: boolean
	/**
	 * Header_MatchSymbol
	 */
	Header_MatchSymbol?: number
	/**
	 * Středisko
	 */
	Header_CostCentreX?: boolean
	/**
	 * Header_CostCentre
	 */
	Header_CostCentre?: number
	/**
	 * Ze třediska
	 */
	Header_FromCostCentreX?: boolean
	/**
	 * Header_FromCostCentre
	 */
	Header_FromCostCentre?: number
	/**
	 * Na středisko
	 */
	Header_ToCostCentreX?: boolean
	/**
	 * Header_ToCostCentre
	 */
	Header_ToCostCentre?: number
	/**
	 * Kód zakázky
	 */
	Header_ContractCodeX?: boolean
	/**
	 * Header_ContractCode
	 */
	Header_ContractCode?: number
	/**
	 * Z kódu zakázky
	 */
	Header_FromContractCodeX?: boolean
	/**
	 * Header_FromContractCode
	 */
	Header_FromContractCode?: number
	/**
	 * Na kód zakázky
	 */
	Header_ToContractCodeX?: boolean
	/**
	 * Header_ToContractCode
	 */
	Header_ToContractCode?: number
	/**
	 * Prostředek
	 */
	Header_DeviceX?: boolean
	/**
	 * Header_Device
	 */
	Header_Device?: number
	/**
	 * Z prostředku
	 */
	Header_FromDeviceX?: boolean
	/**
	 * Header_FromDevice
	 */
	Header_FromDevice?: number
	/**
	 * Na prostředek
	 */
	Header_ToDeviceX?: boolean
	/**
	 * Header_ToDevice
	 */
	Header_ToDevice?: number
	/**
	 * Referent
	 */
	Header_OfficerX?: boolean
	/**
	 * Header_Officer
	 */
	Header_Officer?: number
	/**
	 * Z referenta
	 */
	Header_FromOfficerX?: boolean
	/**
	 * Header_FromOfficer
	 */
	Header_FromOfficer?: number
	/**
	 * Na referenta
	 */
	Header_ToOfficerX?: boolean
	/**
	 * Header_ToOfficer
	 */
	Header_ToOfficer?: number
	/**
	 * Kód zboží
	 */
	Header_ArticleCodeX?: boolean
	/**
	 * Header_ArticleCode
	 */
	Header_ArticleCode?: number
	/**
	 * Kód 1
	 */
	Header_Code1X?: boolean
	/**
	 * Header_Code1
	 */
	Header_Code1?: number
	/**
	 * Z kódu 1
	 */
	Header_FromCode1X?: boolean
	/**
	 * Header_FromCode1
	 */
	Header_FromCode1?: number
	/**
	 * Na kód 1
	 */
	Header_ToCode1X?: boolean
	/**
	 * Header_ToCode1
	 */
	Header_ToCode1?: number
	/**
	 * Kód 2
	 */
	Header_Code2X?: boolean
	/**
	 * Header_Code2
	 */
	Header_Code2?: number
	/**
	 * Z kódu 2
	 */
	Header_FromCode2X?: boolean
	/**
	 * Header_FromCode2
	 */
	Header_FromCode2?: number
	/**
	 * Na kód 2
	 */
	Header_ToCode2X?: boolean
	/**
	 * Header_ToCode2
	 */
	Header_ToCode2?: number
	/**
	 * Kód 3
	 */
	Header_Code3X?: boolean
	/**
	 * Header_Code3
	 */
	Header_Code3?: number
	/**
	 * Z kódu 3
	 */
	Header_FromCode3X?: boolean
	/**
	 * Header_FromCode3
	 */
	Header_FromCode3?: number
	/**
	 * Na kód 3
	 */
	Header_ToCode3X?: boolean
	/**
	 * Header_ToCode3
	 */
	Header_ToCode3?: number
	/**
	 * Kód 4
	 */
	Header_Code4X?: boolean
	/**
	 * Header_Code4
	 */
	Header_Code4?: number
	/**
	 * Z kódu 4
	 */
	Header_FromCode4X?: boolean
	/**
	 * Header_FromCode4
	 */
	Header_FromCode4?: number
	/**
	 * Na kód 4
	 */
	Header_ToCode4X?: boolean
	/**
	 * Header_ToCode4
	 */
	Header_ToCode4?: number
	/**
	 * Kód 5
	 */
	Header_Code5X?: boolean
	/**
	 * Header_Code5
	 */
	Header_Code5?: number
	/**
	 * Z kódu 5
	 */
	Header_FromCode5X?: boolean
	/**
	 * Header_FromCode5
	 */
	Header_FromCode5?: number
	/**
	 * Na kód 5
	 */
	Header_ToCode5X?: boolean
	/**
	 * Header_ToCode5
	 */
	Header_ToCode5?: number
	/**
	 * Kód 6
	 */
	Header_Code6X?: boolean
	/**
	 * Header_Code6
	 */
	Header_Code6?: number
	/**
	 * Z kódu 6
	 */
	Header_FromCode6X?: boolean
	/**
	 * Header_FromCode6
	 */
	Header_FromCode6?: number
	/**
	 * Na kód 6
	 */
	Header_ToCode6X?: boolean
	/**
	 * Header_ToCode6
	 */
	Header_ToCode6?: number
	/**
	 * Ignorovat cenu příjmu
	 */
	Header_IgnorePriceIncomX?: boolean
	/**
	 * Header_IgnorePriceIncom
	 */
	Header_IgnorePriceIncom?: number
	/**
	 * Header_ShowIgnorePriceIncom
	 */
	Header_ShowIgnorePriceIncom?: boolean
	/**
	 * Kalk.vz.plán.
	 */
	Header_PlannedPriceCMX?: boolean
	/**
	 * Header_PlannedPriceCM
	 */
	Header_PlannedPriceCM?: number
	/**
	 * Kalk.vz.sklad.
	 */
	Header_StockPriceCMX?: boolean
	/**
	 * Header_StockPriceCM
	 */
	Header_StockPriceCM?: number
	/**
	 * Kalk.vz.nákl.
	 */
	Header_CostPriceCMX?: boolean
	/**
	 * Header_CostPriceCM
	 */
	Header_CostPriceCM?: number
	/**
	 * Kontace faktury
	 */
	Item_PostingKeyIX?: boolean
	/**
	 * Item_PostingKeyI
	 */
	Item_PostingKeyI?: number
	/**
	 * Kontace skladového dokladu
	 */
	Item_PostingKeySX?: boolean
	/**
	 * Item_PostingKeyS
	 */
	Item_PostingKeyS?: number
	/**
	 * Kontace rezervačního listu
	 */
	Item_PostingKeyRX?: boolean
	/**
	 * Item_PostingKeyR
	 */
	Item_PostingKeyR?: number
	/**
	 * Kód zakázky
	 */
	Item_ContractCodeX?: boolean
	/**
	 * Item_ContractCode
	 */
	Item_ContractCode?: number
	/**
	 * Kód zakázky 2
	 */
	Item_ContractCode2X?: boolean
	/**
	 * Item_ContractCode2
	 */
	Item_ContractCode2?: number
	/**
	 * Středisko
	 */
	Item_CostCentreX?: boolean
	/**
	 * Item_CostCentre
	 */
	Item_CostCentre?: number
	/**
	 * Středisko 2
	 */
	Item_CostCentre2X?: boolean
	/**
	 * Item_CostCentre2
	 */
	Item_CostCentre2?: number
	/**
	 * Dosadit z karty zboží
	 */
	Item_CostCentreG?: boolean
	/**
	 * Kód zboží
	 */
	Item_ArticleCodeX?: boolean
	/**
	 * Item_ArticleCode
	 */
	Item_ArticleCode?: number
	/**
	 * Dosadit z karty zboží
	 */
	Item_ArticleCodeG?: boolean
	/**
	 * Kód 1
	 */
	Item_Code1X?: boolean
	/**
	 * Item_Code1
	 */
	Item_Code1?: number
	/**
	 * Kód 2
	 */
	Item_Code2X?: boolean
	/**
	 * Item_Code2
	 */
	Item_Code2?: number
	/**
	 * Kód 3
	 */
	Item_Code3X?: boolean
	/**
	 * Item_Code3
	 */
	Item_Code3?: number
	/**
	 * Kód 4
	 */
	Item_Code4X?: boolean
	/**
	 * Item_Code4
	 */
	Item_Code4?: number
	/**
	 * Kód 5
	 */
	Item_Code5X?: boolean
	/**
	 * Item_Code5
	 */
	Item_Code5?: number
	/**
	 * Kód 6
	 */
	Item_Code6X?: boolean
	/**
	 * Item_Code6
	 */
	Item_Code6?: number
	/**
	 * Referent
	 */
	Item_OfficerX?: boolean
	/**
	 * Item_Officer
	 */
	Item_Officer?: number
	/**
	 * Prostředek
	 */
	Item_DeviceX?: boolean
	/**
	 * Item_Device
	 */
	Item_Device?: number
	/**
	 * Parovací symbol
	 */
	Item_MatchSymbolX?: boolean
	/**
	 * Item_MatchSymbol
	 */
	Item_MatchSymbol?: number
	/**
	 * Účet
	 */
	Item_AccountX?: boolean
	/**
	 * Item_Account
	 */
	Item_Account?: number
	/**
	 * Kontace
	 */
	Item_PostingKeyX?: boolean
	/**
	 * Item_PostingKey
	 */
	Item_PostingKey?: number
	/**
	 * Účet D
	 */
	Item_CreditAcX?: boolean
	/**
	 * Item_CreditAc
	 */
	Item_CreditAc?: number
	/**
	 * Účet MD
	 */
	Item_DebitAcX?: boolean
	/**
	 * Item_DebitAc
	 */
	Item_DebitAc?: number
	/**
	 * Podle kontejneru zboží
	 */
	GoodsContainer?: boolean
	/**
	 * S odmazáním z kontejneru
	 */
	DeleteFromContainer?: boolean
	/**
	 * Pouze u prázdných
	 */
	OnlyEmpty?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TBulkChangeConfirmationDateCOMMANDParams = {
	/**
	 * Datum potvrzení
	 */
	Confirmation1Date?: Date
	/**
	 * Hodina potvrzení
	 */
	Confirmation1Time?: Date
	/**
	 * Datum 2. potvrzení
	 */
	Confirmation2Date?: Date
	/**
	 * Hodina 2. potvrzení
	 */
	Confirmation2Time?: Date
	/**
	 * Kontrolovat záporné stavy
	 */
	CheckNegativeState?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TChangeConfirmationDateCOMMANDParams = {
	/**
	 * Datum potvrzení
	 */
	Confirmation1Date?: Date
	/**
	 * Hodina potvrzení
	 */
	Confirmation1Time?: Date
	/**
	 * Datum 2. potvrzení
	 */
	Confirmation2Date?: Date
	/**
	 * Hodina 2. potvrzení
	 */
	Confirmation2Time?: Date
	/**
	 * Kontrolovat záporné stavy
	 */
	CheckNegativeState?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TChangeRecordStatusSWSCOMMANDParams = {
	/**
	 * StatusId
	 */
	StatusId?: number
}

export type TBulkChangeAttributesCOMMANDParams = {
	/**
	 * Smazat atributy
	 */
	DeleteAttribute?: boolean
	/**
	 * Smazat všechny volné atributy
	 */
	DeleteFreeAttribute?: boolean
	/**
	 * Nastavit hodnoty atributů
	 */
	SetValues?: boolean
	/**
	 * Přidat nepřipojené atributy
	 */
	AddFreeAttributes?: boolean
	/**
	 * FreeAttributeEnabled
	 */
	FreeAttributeEnabled?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TBulkActualizeAttributesCOMMANDParams = {
	/**
	 * Aktualizovat včetně samotné sady atributů
	 */
	ActualizeAttributeSet?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}