import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TEshopDM from './TEshopDM'
import TEshopCategoryInfoDM from './TEshopCategoryInfoDM'

/**
 * Přiřazené kategorie
 */
export default class TEshopArticleCategorySummaryDM extends Data {
	public static className = 'TEshopArticleCategorySummaryDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = false

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * Identifikátor
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * Bylo přiřazení přes skupinu
	 */
	GroupAssignedOldId?: number

	/**
	 * Bylo přímé přiřazení
	 */
	IsArticleAssignedOld?: string

	/**
	 * Přes skupinu
	 */
	GroupAssignedId?: number

	/**
	 * P
	 */
	IsArticleAssigned?: string

	/**
	 * Cesta ke kategorii
	 */
	CategoryPath?: string

	/**
	 * Zboží
	 */
	ArticleId?: number

	/**
	 * Identifikátor
	 */
	RID?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Int. obchod
	 */
	@Type(() => TEshopDM)
	EshopRID?: TEshopDM

	/**
	 * Kategorie
	 */
	@Type(() => TEshopCategoryInfoDM)
	CategoryRIDCalc?: TEshopCategoryInfoDM
}