import Data from 'react-k2-api/Api/Data'
import { Type } from 'class-transformer'
import TFragmentDM from './TFragmentDM'

/**
 * Použití role v univerzálních formulářích
 */
export default class TUserRoleFragmentDM extends Data {
	public static className = 'TUserRoleFragmentDM'
	public static systemFields = [
		'RecordID',
	]

	public static isHeader = false

	/**
	 * Mandant
	 */
	Mandant?: string

	/**
	 * Owner
	 */
	OwnerRID?: number

	/**
	 * Level
	 */
	Level?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * undefined
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * FragmentId
	 */
	@Type(() => TFragmentDM)
	FragmentId?: TFragmentDM
}