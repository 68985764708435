import Data from 'react-k2-api/Api/Data'
import { Type } from 'class-transformer'
import TMeasureUnitDM from './TMeasureUnitDM'
import TArticleDM from './TArticleDM'
import TPriceListDM from './TPriceListDM'

/**
 * Ceníky - seznam zboží
 */
export default class TPriceListArticleDM extends Data {
	public static className = 'TPriceListArticleDM'
	public static systemFields = [
		'RecordID',
	]

	public static isHeader = false

	/**
	 * Platnost do
	 */
	ValidToDate?: Date

	/**
	 * Platnost od
	 */
	ValidFromDate?: Date

	/**
	 * Cena brutto
	 */
	PriceGross?: number

	/**
	 * Netto
	 */
	PriceNet?: number

	/**
	 * Množství
	 */
	Quantity?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * undefined
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * undefined
	 */
	MemoryRID?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Platnost od
	 */
	ValidFromEffectiveCalc?: Date

	/**
	 * Platnost do
	 */
	ValidToEffectiveCalc?: Date

	/**
	 * Jednotka
	 */
	@Type(() => TMeasureUnitDM)
	MeasureUnitId?: TMeasureUnitDM

	/**
	 * Zboží
	 */
	@Type(() => TArticleDM)
	ArticleId?: TArticleDM

	/**
	 * Ceník
	 */
	@Type(() => TPriceListDM)
	PriceListRID?: TPriceListDM
}