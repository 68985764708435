import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TLocationArticleDM from './TLocationArticleDM'
import TK2UserLookupDM from './TK2UserLookupDM'
import TWarehouseDM from './TWarehouseDM'
import TTextDM from './TTextDM'
import TOperationDM from './TOperationDM'
import TTradingPartnerDM from './TTradingPartnerDM'

/**
 * TRoutingVariantOperationDataFilterDM
 */
export default class TRoutingVariantOperationDataFilterDM extends Data {
	public static className = 'TRoutingVariantOperationDataFilterDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = false

	/**
	 * Posun po výrobní dávce
	 */
	IsTransferQtyByLot?: string

	/**
	 * OpTextFormat
	 */
	OperationTextFormat?: number

	/**
	 * PlanScrap
	 */
	EnterRejectsInsteadOfYield?: string

	/**
	 * Supplement
	 */
	IsSupplement?: string

	/**
	 * Odvádět
	 */
	Complete?: string

	/**
	 * Kooperant
	 */
	IsCooperation?: string

	/**
	 * Varianta
	 */
	RoutingId?: number

	/**
	 * Typ TP
	 */
	RoutingTypeId?: number

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * Text operace
	 */
	OperationText?: string

	/**
	 * Čís.textu
	 */
	TextId?: number

	/**
	 * Změněno
	 */
	ChangedOn?: Date

	/**
	 * Výtěžnost
	 */
	Utilization?: number

	/**
	 * Posun
	 */
	TransferQuantity?: number

	/**
	 * Přechod. čas
	 */
	TeardownTime?: number

	/**
	 * Čas před oper.
	 */
	TimeBefore?: number

	/**
	 * Čas po oper.
	 */
	TimeAfter?: number

	/**
	 * Přípravný čas
	 */
	SetupTime?: number

	/**
	 * Výrobní čas
	 */
	RunTime?: number

	/**
	 * Kód
	 */
	OperationCode?: string

	/**
	 * Pořadí operace
	 */
	SequenceNumber?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Výrobní čas
	 */
	ProductionTimeUMCalc?: number

	/**
	 * Přípravný čas
	 */
	SetupTimeUMCalc?: number

	/**
	 * Čas po
	 */
	TimeAfterUMCalc?: number

	/**
	 * Čas před
	 */
	TimeBeforeUMCalc?: number

	/**
	 * Mezioper. čas
	 */
	TeardownTimeUMCalc?: number

	/**
	 * Popis operace
	 */
	DescriptionCalc?: string

	/**
	 * O
	 */
	CompletionImgCalc?: string

	/**
	 * undefined
	 */
	GlobalDefinitionIdCalc?: number

	/**
	 * Umístění
	 */
	@Type(() => TLocationArticleDM)
	LocationId?: TLocationArticleDM

	/**
	 * Změnil
	 */
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM

	/**
	 * Sklad
	 */
	@Type(() => TWarehouseDM)
	WarehouseId?: TWarehouseDM

	/**
	 * Jedn.času
	 */
	@Type(() => TTextDM)
	TimeUnitId?: TTextDM

	/**
	 * Operace
	 */
	@Type(() => TOperationDM)
	OperationId?: TOperationDM

	/**
	 * Kooperant
	 */
	@Type(() => TTradingPartnerDM)
	CooperantId?: TTradingPartnerDM
}