import Data from 'react-k2-api/Api/Data'
import { Type } from 'class-transformer'
import TAccountingJournalDM from './TAccountingJournalDM'
import TTextDM from './TTextDM'
import TPeriodDM from './TPeriodDM'

/**
 * Položky majetku - daňové (1. plán)
 */
export default class TD_ZarPM1 extends Data {
	public static className = 'TD_ZarPM1'
	public static systemFields = [
		'ItemRIDCalc',
	]

	public static isHeader = false

	/**
	 * DMP_RecordMod
	 */
	DMP_RecordMod?: number

	/**
	 * DMP_DetailID
	 */
	DMP_DetailID?: number

	/**
	 * DMP_Change
	 */
	Zmena?: number

	/**
	 * undefined
	 */
	QDate?: Date

	/**
	 * Suma roční
	 */
	SumaRocni?: number

	/**
	 * Předvolba
	 */
	Preselection?: number

	/**
	 * Plán
	 */
	Plan?: string

	/**
	 * Popis
	 */
	Desc?: string

	/**
	 * Likvidoval
	 */
	KdoLik?: number

	/**
	 * Datum likvidace
	 */
	DatLik?: number

	/**
	 * Kont.
	 */
	Pohyb_P?: number

	/**
	 * Kód
	 */
	Kod?: string

	/**
	 * Částka
	 */
	Cena?: number

	/**
	 * Datum
	 */
	Datum?: Date

	/**
	 * Číslo plánu
	 */
	CPl?: number

	/**
	 * undefined
	 */
	RecordStateImgCalc?: string

	/**
	 * ItemRIDCalc
	 */
	ItemRIDCalc?: number

	/**
	 * RecordModeCalc
	 */
	RecordModeCalc?: number

	/**
	 * Číslo úč.dokl.
	 */
	CFUcDok?: number

	/**
	 * Procento
	 */
	CFProcento?: number

	/**
	 * Kód
	 */
	CFKod?: string

	/**
	 * Z
	 */
	CFZa?: string

	/**
	 * O
	 */
	CFOd?: string

	/**
	 * P
	 */
	CFPo?: string

	/**
	 * R
	 */
	CFRo?: string

	/**
	 * Číslo úč.dokl.
	 */
	@Type(() => TAccountingJournalDM)
	AccDocRID?: TAccountingJournalDM

	/**
	 * TP
	 */
	@Type(() => TTextDM)
	Typ_Pol?: TTextDM

	/**
	 * Období
	 */
	@Type(() => TPeriodDM)
	CFUPref?: TPeriodDM
}