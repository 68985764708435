import Data from 'react-k2-api/Api/Data'
import { Type } from 'class-transformer'
import TArticleDM from './TArticleDM'

/**
 * Obrátka zásob
 */
export default class TInventoryTurnoverChartSourceDM extends Data {
	public static className = 'TInventoryTurnoverChartSourceDM'
	public static systemFields = [
	]

	public static isHeader = false

	/**
	 * Obrat - prod.c.
	 */
	TotalSalePrice?: number

	/**
	 * Obrat - skl.c.
	 */
	TotalStockPrice?: number

	/**
	 * Obrat - množ.
	 */
	Quantity?: number

	/**
	 * Prům. účetní hodn.
	 */
	AverageBookValue?: number

	/**
	 * Prům. dispozice
	 */
	AverageAvailable?: number

	/**
	 * Datum
	 */
	DateTo?: Date

	/**
	 * Konfigurace
	 */
	ConfigurationRID?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Datum posl. výpočtu
	 */
	LastComputeDateBitCalc?: number

	/**
	 * Zkratka
	 */
	ConfigurationAbbrCalc?: string

	/**
	 * Název
	 */
	ConfigurationNameCalc?: string

	/**
	 * Obrátka
	 */
	TurnoverRatioCalc?: number

	/**
	 * Doba obratu
	 */
	SalesInventoryAverageDaysCalc?: number

	/**
	 * Zisk
	 */
	ProfitCalc?: number

	/**
	 * Obrat - množ.
	 */
	QuantityUMCalc?: number

	/**
	 * Prům. dispozice
	 */
	EffectiveComputeQuantityUMCalc?: number

	/**
	 * Počet dní
	 */
	DayCountCalc?: number

	/**
	 * undefined
	 */
	ChartValueCalc?: number

	/**
	 * Zboží
	 */
	@Type(() => TArticleDM)
	ArticleId?: TArticleDM
}