import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TLegislationVATRateDm from './TLegislationVATRateDm'
import TLegislationTaxTypeDm from './TLegislationTaxTypeDm'
import TCode6DM from './TCode6DM'
import TCode5DM from './TCode5DM'
import TCode4DM from './TCode4DM'
import TCode3DM from './TCode3DM'
import TPeriodDM from './TPeriodDM'
import TBookDM from './TBookDM'
import TDeviceDM from './TDeviceDM'
import TCurrencyDM from './TCurrencyDM'
import TAccountingChartDM from './TAccountingChartDM'
import TMatchingSymbolDM from './TMatchingSymbolDM'
import TTradingPartnerDM from './TTradingPartnerDM'
import TOfficerDM from './TOfficerDM'
import TCode2DM from './TCode2DM'
import TCode1DM from './TCode1DM'
import TArticleCodeDM from './TArticleCodeDM'
import TContractCodeDocumentDM from './TContractCodeDocumentDM'
import TCostCentreDM from './TCostCentreDM'
import TTextDM from './TTextDM'

/**
 * Položky účetního deníku
 */
export default class TMatchinSymbolAccountingJournalItemDM extends Data {
	public static className = 'TMatchinSymbolAccountingJournalItemDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = false

	/**
	 * Přepočítávat část&ky
	 */
	RecalculateAmount?: string

	/**
	 * Ruční změna
	 */
	IsManuallyChanged?: string

	/**
	 * Storno
	 */
	IsCanceledRecord?: string

	/**
	 * Účetní doklad
	 */
	HeaderRID?: number

	/**
	 * Číslo
	 */
	Number?: number

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * Právo na záznam
	 */
	RightGroupId?: number

	/**
	 * Částka v měně
	 */
	AmountC?: number

	/**
	 * Pořadí
	 */
	SequenceNumber?: number

	/**
	 * Kurz
	 */
	ExchangeRate?: number

	/**
	 * &Amount
	 */
	Amount?: number

	/**
	 * Popis
	 */
	Description?: string

	/**
	 * Den
	 */
	ItemDay?: number

	/**
	 * Datum
	 */
	ItemDate?: Date

	/**
	 * Typ položky
	 */
	ItemTypeId?: number

	/**
	 * Číslo položky
	 */
	ItemNumber?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * undefined
	 */
	IsIdentified?: string

	/**
	 * undefined
	 */
	ComputedRecalculateAmountC?: number

	/**
	 * undefined
	 */
	ComputedRecalculateAmount?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * undefined
	 */
	DateCalc?: Date

	/**
	 * undefined
	 */
	TimeCalc?: Date

	/**
	 * Částka MD
	 */
	MSDebitAmountCalc?: number

	/**
	 * Částka Dal
	 */
	MSCreditAmountCalc?: number

	/**
	 * Právo na záznam
	 */
	RightGroupIdCalc?: number

	/**
	 * Sazba DPH
	 */
	@Type(() => TLegislationVATRateDm)
	VATRateId?: TLegislationVATRateDm

	/**
	 * Typ daně
	 */
	@Type(() => TLegislationTaxTypeDm)
	TaxTypeId?: TLegislationTaxTypeDm

	/**
	 * Kód 6
	 */
	@Type(() => TCode6DM)
	DebitCode6Id?: TCode6DM

	/**
	 * Kód 5
	 */
	@Type(() => TCode5DM)
	DebitCode5Id?: TCode5DM

	/**
	 * Kód 4
	 */
	@Type(() => TCode4DM)
	DebitCode4Id?: TCode4DM

	/**
	 * Kód 3
	 */
	@Type(() => TCode3DM)
	DebitCode3Id?: TCode3DM

	/**
	 * Období
	 */
	@Type(() => TPeriodDM)
	BusinessYearId?: TPeriodDM

	/**
	 * Kniha
	 */
	@Type(() => TBookDM)
	BookId?: TBookDM

	/**
	 * Prostředek
	 */
	@Type(() => TDeviceDM)
	DebitDeviceRID?: TDeviceDM

	/**
	 * Cizí měna
	 */
	@Type(() => TCurrencyDM)
	ForeignCurrency?: TCurrencyDM

	/**
	 * Protiúčet
	 */
	@Type(() => TAccountingChartDM)
	CreditAccountId?: TAccountingChartDM

	/**
	 * Měna
	 */
	@Type(() => TCurrencyDM)
	Currency?: TCurrencyDM

	/**
	 * Pár. symbol
	 */
	@Type(() => TMatchingSymbolDM)
	DebitMatchingSymbolId?: TMatchingSymbolDM

	/**
	 * Zákazník
	 */
	@Type(() => TTradingPartnerDM)
	DebitTradingPartnerId?: TTradingPartnerDM

	/**
	 * Referent
	 */
	@Type(() => TOfficerDM)
	DebitOfficerId?: TOfficerDM

	/**
	 * Kód 2
	 */
	@Type(() => TCode2DM)
	DebitCode2Id?: TCode2DM

	/**
	 * Kód 1
	 */
	@Type(() => TCode1DM)
	DebitCode1Id?: TCode1DM

	/**
	 * Kód zboží
	 */
	@Type(() => TArticleCodeDM)
	DebitArticleCodeId?: TArticleCodeDM

	/**
	 * Kód zakázky
	 */
	@Type(() => TContractCodeDocumentDM)
	DebitContractCodeRID?: TContractCodeDocumentDM

	/**
	 * Středisko
	 */
	@Type(() => TCostCentreDM)
	DebitCostCentreId?: TCostCentreDM

	/**
	 * Účet
	 */
	@Type(() => TAccountingChartDM)
	DebitAccountId?: TAccountingChartDM

	/**
	 * Typ
	 */
	@Type(() => TTextDM)
	HeaderTypeId?: TTextDM

	/**
	 * undefined
	 */
	@Type(() => TCode6DM)
	CreditCode6Id?: TCode6DM

	/**
	 * undefined
	 */
	@Type(() => TCode5DM)
	CreditCode5Id?: TCode5DM

	/**
	 * undefined
	 */
	@Type(() => TCode4DM)
	CreditCode4Id?: TCode4DM

	/**
	 * undefined
	 */
	@Type(() => TCode3DM)
	CreditCode3Id?: TCode3DM

	/**
	 * undefined
	 */
	@Type(() => TDeviceDM)
	CreditDeviceRID?: TDeviceDM

	/**
	 * Zákazník D
	 */
	@Type(() => TTradingPartnerDM)
	CreditTradingPartnerId?: TTradingPartnerDM

	/**
	 * ReferentD
	 */
	@Type(() => TOfficerDM)
	CreditOfficerId?: TOfficerDM

	/**
	 * Kód 2 D
	 */
	@Type(() => TCode2DM)
	CreditCode2Id?: TCode2DM

	/**
	 * Kód 1 D
	 */
	@Type(() => TCode1DM)
	CreditCode1Id?: TCode1DM

	/**
	 * Pár. symbol D
	 */
	@Type(() => TMatchingSymbolDM)
	CreditMatchingSymbolId?: TMatchingSymbolDM

	/**
	 * Kód zboží D
	 */
	@Type(() => TArticleCodeDM)
	CreditArticleCodeId?: TArticleCodeDM

	/**
	 * Kód zakázky D
	 */
	@Type(() => TContractCodeDocumentDM)
	CreditContractCodeRID?: TContractCodeDocumentDM

	/**
	 * Středisko D
	 */
	@Type(() => TCostCentreDM)
	CreditCostCentreId?: TCostCentreDM
}