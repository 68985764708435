import Data from 'react-k2-api/Api/Data'
import { Type } from 'class-transformer'
import TSpecialStringListDM from './TSpecialStringListDM'
import TCurrencyDM from './TCurrencyDM'
import TArticleDM from './TArticleDM'
import TTextDM from './TTextDM'

/**
 * Datumové ceny - hlavička
 */
export default class TArticleSupplierDatePriceDM extends Data {
	public static className = 'TArticleSupplierDatePriceDM'
	public static systemFields = [
		'ItemRIDCalc',
	]

	public static isHeader = false

	/**
	 * Node
	 */
	NodeId?: number

	/**
	 * Režie
	 */
	Overheads?: number

	/**
	 * Unikát
	 */
	ItemNumber?: number

	/**
	 * Datum do
	 */
	DateTo?: Date

	/**
	 * Prodejní cena netto
	 */
	SellingPrice?: number

	/**
	 * Prod. sk.
	 */
	ProductGroupId?: number

	/**
	 * Marže
	 */
	MarginId?: number

	/**
	 * Celní sazebník
	 */
	CustomsTariffId?: number

	/**
	 * Skladová cena
	 */
	StockPrice?: number

	/**
	 * Nákl. pořízení
	 */
	PurchaseCost?: number

	/**
	 * Cena pořízení
	 */
	PurchasePrice?: number

	/**
	 * Kurz
	 */
	ExchangeRate?: number

	/**
	 * Datum
	 */
	DateFrom?: Date

	/**
	 * undefined
	 */
	RecordStateImgCalc?: string

	/**
	 * Kurz
	 */
	ExchangeRateCalc?: number

	/**
	 * Cena pořízení
	 */
	PurchasePriceCalc?: number

	/**
	 * Nákl. pořízení
	 */
	PurchaseCostCalc?: number

	/**
	 * Skl. cena
	 */
	StockPriceCalc?: number

	/**
	 * Prodejní cena brutto
	 */
	SalePriceCalc?: number

	/**
	 * Koef. DPH
	 */
	VATCoefficientCalc?: number

	/**
	 * Součet
	 */
	TotalCalc?: number

	/**
	 * DPH
	 */
	AmountVATCalc?: number

	/**
	 * Marže (%)
	 */
	MarginPctCalc?: number

	/**
	 * Datum
	 */
	DateCalc?: Date

	/**
	 * Režie
	 */
	OverheadsCalc?: number

	/**
	 * %
	 */
	PurchaseCostPctImgCalc?: string

	/**
	 * ItemRIDCalc
	 */
	ItemRIDCalc?: number

	/**
	 * RecordModeCalc
	 */
	RecordModeCalc?: number

	/**
	 * Název
	 */
	@Type(() => TSpecialStringListDM)
	Name?: TSpecialStringListDM

	/**
	 * Měna
	 */
	@Type(() => TCurrencyDM)
	Currency?: TCurrencyDM

	/**
	 * Číslo
	 */
	@Type(() => TArticleDM)
	ArticleId?: TArticleDM

	/**
	 * Nákl. pořízení
	 */
	@Type(() => TTextDM)
	PurchaseCostsPctCalc?: TTextDM
}