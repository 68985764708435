import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TK2UserLookupDM from './TK2UserLookupDM'
import TTextDM from './TTextDM'
import PrCalTyp from './PrCalTyp'
import TPrPeriodDM from './TPrPeriodDM'
import TCommentDM from './TCommentDM'
import TAttendanceWorkShiftTypeDM from './TAttendanceWorkShiftTypeDM'
import TPrCalendarDefinitionDM from './TPrCalendarDefinitionDM'
import TPrCalendarItemTimeDM from './TPrCalendarItemTimeDM'
import TCodeCalendarItemDayDM from './TCodeCalendarItemDayDM'
import TPrCalendarItemDurationDM from './TPrCalendarItemDurationDM'
import TCodeCalendarOverlayDM from './TCodeCalendarOverlayDM'
import TPrCalendarItemVisualDM from './TPrCalendarItemVisualDM'

/**
 * Mzdový kalendář
 */
export default class TPrCalendarDM extends Data {
	public static className = 'TPrCalendarDM'
	public static primaryKey = 'Id'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.Id!
	}

	public static isHeader = true

	/**
	 * Skupina práv
	 */
	RightGroupId?: number

	/**
	 * Vytvořeno
	 */
	CreatedOn?: Date

	/**
	 * BoolValue3
	 */
	BoolValue3?: string

	/**
	 * BoolValue2
	 */
	BoolValue2?: string

	/**
	 * BoolValue1
	 */
	BoolValue1?: string

	/**
	 * Doplňující parametry
	 */
	AdditionalParameters?: string

	/**
	 * Firma
	 */
	CompanyId?: number

	/**
	 * Č.hodn.B4
	 */
	BigintValue4?: number

	/**
	 * Č.hodn.B3
	 */
	BigintValue3?: number

	/**
	 * Č.hodn.B2
	 */
	BigintValue2?: number

	/**
	 * Č.hodn.B1
	 */
	BigintValue1?: number

	/**
	 * Č.hodn.R2
	 */
	RealValue2?: number

	/**
	 * Č.hodn.R1
	 */
	RealValue1?: number

	/**
	 * Časové razítko
	 */
	TimeStamp?: string

	/**
	 * Dat. potvr.
	 */
	ConfirmedOn?: Date

	/**
	 * Do
	 */
	ValidTo?: number

	/**
	 * Od
	 */
	ValidFrom?: number

	/**
	 * Změněno
	 */
	ChangedOn?: Date

	/**
	 * Hlavní typ
	 */
	TypeId?: number

	/**
	 * Popis
	 */
	Description?: string

	/**
	 * Zkratka
	 */
	Abbr?: string

	/**
	 * Čís.
	 */
	Id?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * SQLSequenceOrderCalc
	 */
	SQLSequenceOrderCalc?: number

	/**
	 * Akt. datum
	 */
	CalendarActualDateCalc?: Date

	/**
	 * Poslední směna
	 */
	LastShiftDateCalc?: Date

	/**
	 * Platnost od
	 */
	ValidFromDateCalc?: Date

	/**
	 * Platnost do
	 */
	ValidToDateCalc?: Date

	/**
	 * Hodiny
	 */
	TotalShiftHoursCalc?: number

	/**
	 * Hodiny ve svátek
	 */
	HolidayHourCountCalc?: number

	/**
	 * Dny
	 */
	TotalShiftDaysCalc?: number

	/**
	 * Dny ve svátek
	 */
	HolidayDayCountCalc?: number

	/**
	 * Akt. týden
	 */
	ActualWeekCalc?: number

	/**
	 * Hodiny směn celkem
	 */
	WeekTotalShiftHourCalc?: number

	/**
	 * Hodiny směn ve svátek
	 */
	WeekShiftHourInPublicHlCalc?: number

	/**
	 * Dny směn celkem
	 */
	WeekTotalShiftDayCalc?: number

	/**
	 * Dny směn ve svátek
	 */
	WeekShiftDayInPublicHlCalc?: number

	/**
	 * Akt. rok
	 */
	ActualYearCalc?: number

	/**
	 * Hodiny směn celkem
	 */
	YearTotalShiftHourCalc?: number

	/**
	 * Hodiny směn ve svátek
	 */
	YearShiftHoursInPublicHlCalc?: number

	/**
	 * Dny směn celkem
	 */
	YearTotalShiftDayCalc?: number

	/**
	 * Dny směn ve svátek
	 */
	YearShiftDayInPublicHlCalc?: number

	/**
	 * Rok akt. týdne
	 */
	ActualWeekYearCalc?: number

	/**
	 * Akt. měsíc
	 */
	ActualMonthCalc?: number

	/**
	 * Hodiny směn celkem
	 */
	MonthShiftTimeCountCalc?: number

	/**
	 * Hodiny směn ve svátek
	 */
	PublicHlMonthShiftHoursCalc?: number

	/**
	 * Dny směn celkem
	 */
	MonthShiftDayCountCalc?: number

	/**
	 * Dny směn ve svátek
	 */
	PublicHlMonthShiftDayCountCalc?: number

	/**
	 * Uzavřený kalendář
	 */
	ClosedCalendarBitCalc?: number

	/**
	 * Používat individuální harmonogramy směn
	 */
	UseAttendanceScheduleCalc?: string

	/**
	 * undefined
	 */
	ItemsFromDateCalc?: Date

	/**
	 * undefined
	 */
	ItemsToDateCalc?: Date

	/**
	 * Datum od
	 */
	RangeFromDateCalc?: Date

	/**
	 * Datum do
	 */
	RangeToDateCalc?: Date

	/**
	 * Datum blokování
	 */
	BlockingDateCalc?: Date

	/**
	 * Blokováno
	 */
	BlockedRecordBitCalc?: number

	/**
	 * Týd. prac. doba
	 */
	WeekWorkingHourCalc?: number

	/**
	 * Denní prac. doba
	 */
	DailyWorkingHourCalc?: number

	/**
	 * Přestávka
	 */
	BreakDurationCalc?: number

	/**
	 * Poč. směn/týden
	 */
	ShiftsWeekNumberCalc?: number

	/**
	 * undefined
	 */
	IsFactorySettingsCalc?: string

	/**
	 * Poslední komentář
	 */
	LastCommentParamCalc?: string

	/**
	 * Skupina práv
	 */
	RightGroupIdCalc?: number

	/**
	 * Vytvořil
	 */
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM

	/**
	 * Č.hodn.L
	 */
	@Type(() => TTextDM)
	LongValue?: TTextDM

	/**
	 * Pracovní režim
	 */
	@Type(() => TTextDM)
	ShortValue?: TTextDM

	/**
	 * Změnil
	 */
	@Type(() => TK2UserLookupDM)
	ChangedbyId?: TK2UserLookupDM

	/**
	 * Typ
	 */
	@Type(() => PrCalTyp)
	UserTypeId?: PrCalTyp

	/**
	 * Perioda
	 */
	@Type(() => TPrPeriodDM)
	PeriodIdCalc?: TPrPeriodDM

	/**
	 * Zpracované období
	 */
	@Type(() => TPrPeriodDM)
	ProcessedPeriodIdCalc?: TPrPeriodDM

	/**
	 * Rozvržení pracovní doby
	 */
	@Type(() => TTextDM)
	WorkScheduleCalc?: TTextDM

	/**
	 * Komentáře
	 */
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]

	/**
	 * undefined
	 */
	@Type(() => TAttendanceWorkShiftTypeDM)
	AttendanceWorkShiftTypeChild?: TAttendanceWorkShiftTypeDM[]

	/**
	 * Definiční položky
	 */
	@Type(() => TPrCalendarDefinitionDM)
	DefinitionChild?: TPrCalendarDefinitionDM[]

	/**
	 * Položky - časový úsek dne
	 */
	@Type(() => TPrCalendarItemTimeDM)
	TimeItemChild?: TPrCalendarItemTimeDM[]

	/**
	 * Položky - dny
	 */
	@Type(() => TCodeCalendarItemDayDM)
	DayItemChild?: TCodeCalendarItemDayDM[]

	/**
	 * Položky - časový fond dne
	 */
	@Type(() => TPrCalendarItemDurationDM)
	TimePeriodItemChild?: TPrCalendarItemDurationDM[]

	/**
	 * Externí kalendáře
	 */
	@Type(() => TCodeCalendarOverlayDM)
	CalendarOverlayChild?: TCodeCalendarOverlayDM[]

	/**
	 * undefined
	 */
	@Type(() => TPrCalendarItemVisualDM)
	FragmentItemChild?: TPrCalendarItemVisualDM[]

	/**
	 * Nastavení
	 */
	public EditSettingsCOMMAND() {
		return this.executeCommand('EditSettingsCOMMAND')
	}

	/**
	 * Výběr typu
	 */
	public TypeIdChangeCOMMAND(parameters: TTypeIdChangeCOMMANDParams) {
		return this.executeCommand('TypeIdChangeCOMMAND', parameters)
	}

	/**
	 * Změna skupiny práv
	 */
	public ChangeGroupRightCOMMAND() {
		return this.executeCommand('ChangeGroupRightCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public BulkChangeGroupRightCOMMAND() {
		return this.executeCommand('BulkChangeGroupRightCOMMAND')
	}

	/**
	 * Aktualizace počítaných polí
	 */
	public BulkUpdateCalcFieldsCOMMAND() {
		return this.executeCommand('BulkUpdateCalcFieldsCOMMAND')
	}

	/**
	 * Kontrola legislativy
	 */
	public SwitchOffLegislationValidationCOMMAND() {
		return this.executeCommand('SwitchOffLegislationValidationCOMMAND')
	}
}

export type TTypeIdChangeCOMMANDParams = {
	/**
	 * Typ
	 */
	TypeId?: number
	/**
	 * MessageText
	 */
	MessageText?: string
}