import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TK2UserLookupDM from './TK2UserLookupDM'

/**
 * Vycházky u neschopenek
 */
export default class TPrEmployeeSickNoteWalkTimeOnlyDM extends Data {
	public static className = 'TPrEmployeeSickNoteWalkTimeOnlyDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = false

	/**
	 * Čas do
	 */
	TimeTo?: Date

	/**
	 * Čas od
	 */
	TimeFrom?: Date

	/**
	 * eNeschopenka
	 */
	PrEmployeeSickNoteRID?: number

	/**
	 * Právo na záznam
	 */
	RightGroupId?: number

	/**
	 * Časové razítko
	 */
	TimeStamp?: string

	/**
	 * Změněno
	 */
	ChangedOn?: Date

	/**
	 * Vytvořeno
	 */
	CreatedOn?: Date

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Právo na záznam
	 */
	RightGroupIdCalc?: number

	/**
	 * Změnil
	 */
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM

	/**
	 * Vytvořil
	 */
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM
}