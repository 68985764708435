import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TContractCodeDocumentDM from './TContractCodeDocumentDM'
import TWarehouseDM from './TWarehouseDM'
import TCompanyDividedBookDM from './TCompanyDividedBookDM'
import TK2UserLookupDM from './TK2UserLookupDM'
import TCompanyDM from './TCompanyDM'

/**
 * Firmy
 */
export default class TOwnCompaniesJobCardConfigDM extends Data {
	public static className = 'TOwnCompaniesJobCardConfigDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = false

	/**
	 * Umístění pro neshody
	 */
	RejectionLocationId?: number

	/**
	 * Umístění pro rozpracovanost
	 */
	WIPLocationId?: number

	/**
	 * Umístění pro spotřebu
	 */
	ConsumptionLocationId?: number

	/**
	 * Umístění pro finální výrobek
	 */
	FinalLocationId?: number

	/**
	 * Valid
	 */
	IsValid?: string

	/**
	 * Změněno
	 */
	ChangedOn?: Date

	/**
	 * Record No
	 */
	RecordNumber?: number

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * undefined
	 */
	IconCalc?: number

	/**
	 * Kód zakázky
	 */
	@Type(() => TContractCodeDocumentDM)
	ContractCodeRID?: TContractCodeDocumentDM

	/**
	 * Sklad pro neshody
	 */
	@Type(() => TWarehouseDM)
	RejectionWarehouseId?: TWarehouseDM

	/**
	 * Sklad pro rozpracovanost
	 */
	@Type(() => TWarehouseDM)
	WIPWarehouseId?: TWarehouseDM

	/**
	 * Sklad pro spotřebu
	 */
	@Type(() => TWarehouseDM)
	ConsumptionWarehouseId?: TWarehouseDM

	/**
	 * Sklad pro finální výrobek
	 */
	@Type(() => TWarehouseDM)
	FinalWarehouseId?: TWarehouseDM

	/**
	 * Kniha odváděcích průvodek
	 */
	@Type(() => TCompanyDividedBookDM)
	CompletionJCBookId?: TCompanyDividedBookDM

	/**
	 * Konfigurace výroby
	 */
	@Type(() => TCompanyDividedBookDM)
	PlanningJCBookId?: TCompanyDividedBookDM

	/**
	 * Změnil
	 */
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM

	/**
	 * CompanyId
	 */
	@Type(() => TCompanyDM)
	CompanyId?: TCompanyDM
}