import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TWebBrandDM from './TWebBrandDM'
import TEshopDM from './TEshopDM'

/**
 * Značka na webech
 */
export default class TWebBrandAsItemDM extends Data {
	public static className = 'TWebBrandAsItemDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = false

	/**
	 * Modul webu
	 */
	SlaveModuleId?: number

	/**
	 * Modul webu
	 */
	MasterModuleId?: number

	/**
	 * Pořadí
	 */
	SequenceNumber?: number

	/**
	 * Datový modul
	 */
	SlaveDataModuleNumber?: number

	/**
	 * Položka modulu
	 */
	MasterRID?: number

	/**
	 * Datový modul
	 */
	MasterDataModuleNumber?: number

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Název
	 */
	NameCalc?: string

	/**
	 * Položka modulu
	 */
	@Type(() => TWebBrandDM)
	SlaveRID?: TWebBrandDM

	/**
	 * undefined
	 */
	@Type(() => TEshopDM)
	WebRIDCalc?: TEshopDM
}