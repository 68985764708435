import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TTextDM from './TTextDM'
import TEshopDM from './TEshopDM'
import TK2UserLookupDM from './TK2UserLookupDM'
import TContactPersonDM from './TContactPersonDM'
import TWebModuleCategoryDM from './TWebModuleCategoryDM'
import TMarketingActivityTypeDM from './TMarketingActivityTypeDM'
import TLinkDM from './TLinkDM'
import TCommentDM from './TCommentDM'
import TWebModuleLinkDM from './TWebModuleLinkDM'
import TWebModuleLinkSuperiorDM from './TWebModuleLinkSuperiorDM'
import TWebURLAddressItemDM from './TWebURLAddressItemDM'
import TWebAppointmentMemoryDM from './TWebAppointmentMemoryDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TLanguageMemoTranslationChildDM from './TLanguageMemoTranslationChildDM'
import TAttachedDocumentDM from './TAttachedDocumentDM'
import TOldNoteDM from './TOldNoteDM'

/**
 * Školení, nabídka kurzů
 */
export default class TWebTrainingDM extends Data {
	public static className = 'TWebTrainingDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = true

	/**
	 * Velké číslo 1
	 */
	BigNumber1?: number

	/**
	 * Číslo 1
	 */
	Number1?: number

	/**
	 * Text 3
	 */
	OtherText3?: string

	/**
	 * Text 2
	 */
	OtherText2?: string

	/**
	 * Ikona
	 */
	OtherText1?: string

	/**
	 * URL
	 */
	Url?: string

	/**
	 * Čas zobrazení
	 */
	ShowTime?: number

	/**
	 * Krátký text
	 */
	ShortText?: string

	/**
	 * Datum do
	 */
	DateTo?: Date

	/**
	 * Pořadí
	 */
	SequenceNumber?: number

	/**
	 * Zneplatněno
	 */
	IsInvalidRecord?: string

	/**
	 * Časové razítko
	 */
	TimeStamp?: string

	/**
	 * Změněno
	 */
	ChangedOn?: Date

	/**
	 * Vytvořeno
	 */
	CreatedOn?: Date

	/**
	 * Datum od
	 */
	DateFrom?: Date

	/**
	 * Popis
	 */
	Description?: string

	/**
	 * Krátký popis
	 */
	ShortDescription?: string

	/**
	 * Meta popis
	 */
	MetaDescription?: string

	/**
	 * Meta titulek
	 */
	MetaTitle?: string

	/**
	 * Titulek
	 */
	Title?: string

	/**
	 * Název
	 */
	Name?: string

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Odkaz
	 */
	PictureCalc?: string

	/**
	 * undefined
	 */
	ImageFromLinkFullPathCalc?: string

	/**
	 * undefined
	 */
	UrlCalc?: string

	/**
	 * undefined
	 */
	TextHeaderMemoCalc?: string

	/**
	 * undefined
	 */
	TextFooterMemoCalc?: string

	/**
	 * Poslední komentář
	 */
	LastCommentParamCalc?: string

	/**
	 * Název
	 */
	TranslatedNameCalc?: string

	/**
	 * Titulek
	 */
	TranslatedTitleCalc?: string

	/**
	 * Meta titulek
	 */
	TranslatedMetaTitleCalc?: string

	/**
	 * Meta popis
	 */
	TranslatedMetaDescriptionCalc?: string

	/**
	 * Krátký popis
	 */
	TranslatedShortDescriptionCalc?: string

	/**
	 * Popis
	 */
	TranslatedDescriptionCalc?: string

	/**
	 * Ikona
	 */
	TranslatedOtherText1Calc?: string

	/**
	 * Text 2
	 */
	TranslatedOtherText2Calc?: string

	/**
	 * Text 3
	 */
	TranslatedOtherText3Calc?: string

	/**
	 * Modul
	 */
	@Type(() => TTextDM)
	ModuleId?: TTextDM

	/**
	 * Web
	 */
	@Type(() => TEshopDM)
	ShopRID?: TEshopDM

	/**
	 * Změnil
	 */
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM

	/**
	 * Vytvořil
	 */
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM

	/**
	 * Přístupnost uživatele
	 */
	@Type(() => TTextDM)
	UserAccessId?: TTextDM

	/**
	 * OG typ
	 */
	@Type(() => TTextDM)
	OpenGraphTypeId?: TTextDM

	/**
	 * Autor
	 */
	@Type(() => TContactPersonDM)
	AuthorId?: TContactPersonDM

	/**
	 * Kategorie
	 */
	@Type(() => TWebModuleCategoryDM)
	CategoryRID?: TWebModuleCategoryDM

	/**
	 * Z
	 */
	@Type(() => TTextDM)
	InvalidRecordImgCalc?: TTextDM

	/**
	 * Web pro zobrazení a editaci dat
	 */
	@Type(() => TEshopDM)
	ShownWebRIDCalc?: TEshopDM

	/**
	 * D
	 */
	@Type(() => TTextDM)
	DocumentExistsCalc?: TTextDM

	/**
	 * undefined
	 */
	@Type(() => TMarketingActivityTypeDM)
	ActivityTypeRIDCalc?: TMarketingActivityTypeDM

	/**
	 * TranslationLanguageIdCalc
	 */
	@Type(() => TTextDM)
	TranslationLanguageIdCalc?: TTextDM

	/**
	 * Odkazy
	 */
	@Type(() => TLinkDM)
	LinkChild?: TLinkDM[]

	/**
	 * Komentáře
	 */
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]

	/**
	 * Propojení modulů
	 */
	@Type(() => TWebModuleLinkDM)
	WebModuleLinkChild?: TWebModuleLinkDM[]

	/**
	 * Propojeno k modulům
	 */
	@Type(() => TWebModuleLinkSuperiorDM)
	SuperiorWebModuleLinkChild?: TWebModuleLinkSuperiorDM[]

	/**
	 * undefined
	 */
	@Type(() => TWebURLAddressItemDM)
	WebUrlPageAddressItemChild?: TWebURLAddressItemDM[]

	/**
	 * Termíny
	 */
	@Type(() => TWebAppointmentMemoryDM)
	WebAppointmentChild?: TWebAppointmentMemoryDM[]

	/**
	 * Text hlavičky
	 */
	@Type(() => TLanguageMemoTranslationDM)
	TextHeaderChild?: TLanguageMemoTranslationDM[]

	/**
	 * Text patičky
	 */
	@Type(() => TLanguageMemoTranslationDM)
	TextFooterChild?: TLanguageMemoTranslationDM[]

	/**
	 * undefined
	 */
	@Type(() => TLanguageMemoTranslationChildDM)
	TranslatedFieldValuesChild?: TLanguageMemoTranslationChildDM[]

	/**
	 * Svázané doklady
	 */
	@Type(() => TAttachedDocumentDM)
	AttachedDocumentOldChild?: TAttachedDocumentDM[]

	/**
	 * Poznámky
	 */
	@Type(() => TOldNoteDM)
	DeprecatedNoteOldChild?: TOldNoteDM[]

	/**
	 * Zneplatnění
	 */
	public BulkInvalidationCOMMAND() {
		return this.executeCommand('BulkInvalidationCOMMAND')
	}

	/**
	 * Zplatnění
	 */
	public BulkUnInvalidationCOMMAND() {
		return this.executeCommand('BulkUnInvalidationCOMMAND')
	}

	/**
	 * Zneplatnění/Zplatnění
	 */
	public InvalidationCOMMAND() {
		return this.executeCommand('InvalidationCOMMAND')
	}

	/**
	 * Potvrzení
	 */
	public BulkConfirmCOMMAND() {
		return this.executeCommand('BulkConfirmCOMMAND')
	}

	/**
	 * Odpotvrzení
	 */
	public BulkUnconfirmCOMMAND() {
		return this.executeCommand('BulkUnconfirmCOMMAND')
	}

	/**
	 * Změna data potvrzení
	 */
	public BulkChangeConfirmationDateCOMMAND(parameters: TBulkChangeConfirmationDateCOMMANDParams) {
		return this.executeCommand('BulkChangeConfirmationDateCOMMAND', parameters)
	}

	/**
	 * Storno
	 */
	public BulkCancelCOMMAND() {
		return this.executeCommand('BulkCancelCOMMAND')
	}

	/**
	 * Odstorno
	 */
	public BulkUnCancelCOMMAND() {
		return this.executeCommand('BulkUnCancelCOMMAND')
	}

	/**
	 * Potvrzení
	 */
	public ConfirmCOMMAND() {
		return this.executeCommand('ConfirmCOMMAND')
	}

	/**
	 * Odpotvrzení
	 */
	public UnconfirmCOMMAND() {
		return this.executeCommand('UnconfirmCOMMAND')
	}

	/**
	 * Změna data potvrzení
	 */
	public ChangeConfirmationDateCOMMAND(parameters: TChangeConfirmationDateCOMMANDParams) {
		return this.executeCommand('ChangeConfirmationDateCOMMAND', parameters)
	}

	/**
	 * Storno/Odstorno
	 */
	public CancelCOMMAND() {
		return this.executeCommand('CancelCOMMAND')
	}

	/**
	 * Zrušení stavu
	 */
	public ClearRecordStatusCOMMAND() {
		return this.executeCommand('ClearRecordStatusCOMMAND')
	}

	/**
	 * Změna stavu
	 */
	public ChangeRecordStatusCOMMAND() {
		return this.executeCommand('ChangeRecordStatusCOMMAND')
	}

	/**
	 * 
	 */
	public ChangeRecordStatusSWSCOMMAND(parameters: TChangeRecordStatusSWSCOMMANDParams) {
		return this.executeCommand('ChangeRecordStatusSWSCOMMAND', parameters)
	}

	/**
	 * Zrušení stavu
	 */
	public BulkClearRecordStatusCOMMAND() {
		return this.executeCommand('BulkClearRecordStatusCOMMAND')
	}

	/**
	 * Změna stavu
	 */
	public BulkChangeRecordStatusCOMMAND() {
		return this.executeCommand('BulkChangeRecordStatusCOMMAND')
	}

	/**
	 * Změna atributů
	 */
	public BulkChangeAttributesCOMMAND(parameters: TBulkChangeAttributesCOMMANDParams) {
		return this.executeCommand('BulkChangeAttributesCOMMAND', parameters)
	}

	/**
	 * Aktualizace sady atributů
	 */
	public BulkActualizeAttributesCOMMAND(parameters: TBulkActualizeAttributesCOMMANDParams) {
		return this.executeCommand('BulkActualizeAttributesCOMMAND', parameters)
	}

	/**
	 * Aktualizace sady atributů
	 */
	public ActualizeAttributesCOMMAND() {
		return this.executeCommand('ActualizeAttributesCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public ChangeGroupRightCOMMAND() {
		return this.executeCommand('ChangeGroupRightCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public BulkChangeGroupRightCOMMAND() {
		return this.executeCommand('BulkChangeGroupRightCOMMAND')
	}

	/**
	 * Aktualizace počítaných polí
	 */
	public BulkUpdateCalcFieldsCOMMAND() {
		return this.executeCommand('BulkUpdateCalcFieldsCOMMAND')
	}

	/**
	 * Kontrola legislativy
	 */
	public SwitchOffLegislationValidationCOMMAND() {
		return this.executeCommand('SwitchOffLegislationValidationCOMMAND')
	}
}

export type TBulkChangeConfirmationDateCOMMANDParams = {
	/**
	 * Datum potvrzení
	 */
	Confirmation1Date?: Date
	/**
	 * Hodina potvrzení
	 */
	Confirmation1Time?: Date
	/**
	 * Datum 2. potvrzení
	 */
	Confirmation2Date?: Date
	/**
	 * Hodina 2. potvrzení
	 */
	Confirmation2Time?: Date
	/**
	 * Kontrolovat záporné stavy
	 */
	CheckNegativeState?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TChangeConfirmationDateCOMMANDParams = {
	/**
	 * Datum potvrzení
	 */
	Confirmation1Date?: Date
	/**
	 * Hodina potvrzení
	 */
	Confirmation1Time?: Date
	/**
	 * Datum 2. potvrzení
	 */
	Confirmation2Date?: Date
	/**
	 * Hodina 2. potvrzení
	 */
	Confirmation2Time?: Date
	/**
	 * Kontrolovat záporné stavy
	 */
	CheckNegativeState?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TChangeRecordStatusSWSCOMMANDParams = {
	/**
	 * StatusId
	 */
	StatusId?: number
}

export type TBulkChangeAttributesCOMMANDParams = {
	/**
	 * Smazat atributy
	 */
	DeleteAttribute?: boolean
	/**
	 * Smazat všechny volné atributy
	 */
	DeleteFreeAttribute?: boolean
	/**
	 * Nastavit hodnoty atributů
	 */
	SetValues?: boolean
	/**
	 * Přidat nepřipojené atributy
	 */
	AddFreeAttributes?: boolean
	/**
	 * FreeAttributeEnabled
	 */
	FreeAttributeEnabled?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TBulkActualizeAttributesCOMMANDParams = {
	/**
	 * Aktualizovat včetně samotné sady atributů
	 */
	ActualizeAttributeSet?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}