import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'

/**
 * Hodnoty parametru
 */
export default class TParameterPossibleValueDM extends Data {
	public static className = 'TParameterPossibleValueDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = false

	/**
	 * Hodnota k porovnání
	 */
	ComparisonValue?: number

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * ID zboží
	 */
	ArticleId?: number

	/**
	 * Překlad popisu
	 */
	Description?: string

	/**
	 * Překlad názvu
	 */
	Abbr?: string

	/**
	 * ID par.
	 */
	ParameterId?: number

	/**
	 * Číslo
	 */
	Id?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date
}