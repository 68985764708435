import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TK2UserLookupDM from './TK2UserLookupDM'

/**
 * Printer group
 */
export default class TSpecialPrinterGroup extends Data {
	public static className = 'TSpecialPrinterGroup'
	public static primaryKey = 'RID'
	public static systemFields = [
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = true

	/**
	 * Is Mobile Printer Group
	 */
	IsMobilePrinterGroup?: string

	/**
	 * Description
	 */
	Description?: string

	/**
	 * Changed on
	 */
	ChangedOn?: Date

	/**
	 * Created on
	 */
	CreatedOn?: Date

	/**
	 * Abbr
	 */
	Abbr?: string

	/**
	 * RID
	 */
	RID?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Changed by
	 */
	@Type(() => TK2UserLookupDM)
	ChangedBy?: TK2UserLookupDM

	/**
	 * Created by
	 */
	@Type(() => TK2UserLookupDM)
	CreatedBy?: TK2UserLookupDM

	/**
	 * Změna skupiny práv
	 */
	public ChangeGroupRightCOMMAND() {
		return this.executeCommand('ChangeGroupRightCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public BulkChangeGroupRightCOMMAND() {
		return this.executeCommand('BulkChangeGroupRightCOMMAND')
	}

	/**
	 * Aktualizace počítaných polí
	 */
	public BulkUpdateCalcFieldsCOMMAND() {
		return this.executeCommand('BulkUpdateCalcFieldsCOMMAND')
	}

	/**
	 * Kontrola legislativy
	 */
	public SwitchOffLegislationValidationCOMMAND() {
		return this.executeCommand('SwitchOffLegislationValidationCOMMAND')
	}
}