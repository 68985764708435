import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TEshopTextPageDM from './TEshopTextPageDM'
import TEshopPaymentDM from './TEshopPaymentDM'
import TEshopDeliveryDM from './TEshopDeliveryDM'

/**
 * Odběry a platby e-shopu
 */
export default class TEshopDeliveryPaymentDM extends Data {
	public static className = 'TEshopDeliveryPaymentDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = false

	/**
	 * XmlData
	 */
	XmlData?: string

	/**
	 * Pořadí
	 */
	SequenceNumber?: number

	/**
	 * E-shop
	 */
	ShopRID?: number

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * D
	 */
	DefaultDeliveryImgCalc?: string

	/**
	 * P
	 */
	DefaultPaymentImgCalc?: string

	/**
	 * Výchozí způsob odběru
	 */
	IsDefaultDeliveryCalc?: string

	/**
	 * Výchozí způsob platby
	 */
	IsDefaultPaymentCalc?: string

	/**
	 * Použít při nevyhovující kombinaci dopravy a platby
	 */
	UseShippAndPayInconvenientBitCalc?: number

	/**
	 * PaymentPageRID
	 */
	@Type(() => TEshopTextPageDM)
	PaymentPageRID?: TEshopTextPageDM

	/**
	 * DeliveryPageRID
	 */
	@Type(() => TEshopTextPageDM)
	DeliveryPageRID?: TEshopTextPageDM

	/**
	 * Platba
	 */
	@Type(() => TEshopPaymentDM)
	PaymentRID?: TEshopPaymentDM

	/**
	 * Odběr
	 */
	@Type(() => TEshopDeliveryDM)
	DeliveryRID?: TEshopDeliveryDM
}