import Data from 'react-k2-api/Api/Data'
import { Type } from 'class-transformer'
import DocumentLinkType from './DocumentLinkType'
import TTextDM from './TTextDM'
import TTradingPartnerDM from './TTradingPartnerDM'
import TContractCodeDocumentDM from './TContractCodeDocumentDM'

/**
 * Svázané doklady
 */
export default class TAttachedDocumentDM extends Data {
	public static className = 'TAttachedDocumentDM'
	public static systemFields = [
		'ItemRIDCalc',
	]

	public static isHeader = false

	/**
	 * Join_Typ
	 */
	JoinTypeId?: string

	/**
	 * Node
	 */
	NodeId?: number

	/**
	 * Unikátní číslo
	 */
	UniqId?: number

	/**
	 * Číslo položky
	 */
	SequenceNumber?: number

	/**
	 * Kniha
	 */
	Book?: string

	/**
	 * Typ
	 */
	TypeId?: number

	/**
	 * Tabulka
	 */
	TableNumberId?: number

	/**
	 * Číslo odk.
	 */
	DocumentRID?: number

	/**
	 * Kniha odk.
	 */
	DocumentBook?: string

	/**
	 * Typ odk.
	 */
	DocumentTypeId?: number

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * undefined
	 */
	RecordStateImgCalc?: string

	/**
	 * Popis
	 */
	DescriptionCalc?: string

	/**
	 * Cena
	 */
	PriceCalc?: number

	/**
	 * Číslo dlp
	 */
	DeliveryConfirmationRIDCalc?: number

	/**
	 * Číslo dlv
	 */
	DeliveryNoteRIDCalc?: number

	/**
	 * Číslo fap
	 */
	InvoiceInRIDCalc?: number

	/**
	 * Číslo fav
	 */
	InvoiceOutRIDCalc?: number

	/**
	 * Číslo obp
	 */
	OrderConfirmationRIDCalc?: number

	/**
	 * Číslo obv
	 */
	PurchaseOrderRIDCalc?: number

	/**
	 * Číslo pri
	 */
	ReceiptCardRIDCalc?: number

	/**
	 * Číslo rez
	 */
	ReservingCardRIDCalc?: number

	/**
	 * Číslo vyd
	 */
	ReleaseNoteRIDCalc?: number

	/**
	 * Číslo zak
	 */
	SalesOrderRIDCalc?: number

	/**
	 * ItemRIDCalc
	 */
	ItemRIDCalc?: number

	/**
	 * Číslo nab
	 */
	OpportunityIdCalc?: number

	/**
	 * Číslo akt
	 */
	ActivityIdCalc?: number

	/**
	 * Číslo pdo
	 */
	CashDocumentRIDCalc?: number

	/**
	 * Číslo part
	 */
	PartnerIdCalc?: number

	/**
	 * Číslo mkos
	 */
	ContactPersonIdCalc?: number

	/**
	 * Číslo zbo
	 */
	ArticleIdCalc?: number

	/**
	 * Číslo baz
	 */
	ToTradingPartnerIdCalc?: number

	/**
	 * Číslo zar
	 */
	AssetIdCalc?: number

	/**
	 * Číslo vyr
	 */
	JobCardRIDCalc?: number

	/**
	 * Číslo pre
	 */
	TransferNoteRIDCalc?: number

	/**
	 * Číslo ucd
	 */
	AccountingDocumentRIDCalc?: number

	/**
	 * Číslo mail
	 */
	MailIdCalc?: number

	/**
	 * Číslo jcdpri
	 */
	SADReceiptIdCalc?: number

	/**
	 * Číslo jcdskl
	 */
	SADWarehouseIdCalc?: number

	/**
	 * Číslo jcdvyd
	 */
	SADReleaseIdCalc?: number

	/**
	 * Číslo jcdrea
	 */
	SADRealisationIdCalc?: number

	/**
	 * Číslo jcdtra
	 */
	SADTransitIdCalc?: number

	/**
	 * Číslo jcdzpri
	 */
	SADSimpleReceiptIdCalc?: number

	/**
	 * Číslo jcdzvyd
	 */
	SADSimpleReleaseIdCalc?: number

	/**
	 * Calc na WF - proces
	 */
	WorkflowProcessIdCalc?: number

	/**
	 * Calc na bankovní příkaz
	 */
	BankOrderRIDCalc?: number

	/**
	 * Calc na bankovní výpis
	 */
	BankStatementRIDCalc?: number

	/**
	 * Číslo rozvozu
	 */
	DeliveryOrderRIDCalc?: number

	/**
	 * Číslo dokumentu
	 */
	DocumentIdCalc?: number

	/**
	 * Číslo zálohy přijaté
	 */
	AdvanceReceivedRIDCalc?: number

	/**
	 * Číslo zálohy poskytnuté
	 */
	AdvanceProvidedRIDCalc?: number

	/**
	 * Číslo podacího archu
	 */
	PostingChequeRIDCalc?: number

	/**
	 * Číslo LP odeslané
	 */
	PostSentRIDCalc?: number

	/**
	 * Číslo LP přijaté
	 */
	PostReceivedRIDCalc?: number

	/**
	 * Číslo svozu
	 */
	CollectionIdCalc?: number

	/**
	 * Číslo zásilky
	 */
	ShipmentRIDCalc?: number

	/**
	 * Číslo výrobního příkazu
	 */
	ProductionOrderRIDCalc?: number

	/**
	 * Číslo úkolu
	 */
	JobIdCalc?: number

	/**
	 * Číslo int. dokladu
	 */
	InternalDocumentRIDCalc?: number

	/**
	 * Doklad
	 */
	DocumentIdentifierCalc?: string

	/**
	 * Číslo serv. listu
	 */
	ServiceNoteRIDCalc?: number

	/**
	 * Číslo serv. zakázku
	 */
	ServiceContractRIDCalc?: number

	/**
	 * Číslo nabídky
	 */
	QuotationRIDCalc?: number

	/**
	 * Číslo poptávky
	 */
	RequestForQuotationRIDCalc?: number

	/**
	 * Číslo upomínky
	 */
	ReminderRIDCalc?: number

	/**
	 * Číslo PLI
	 */
	PrLiabilityRIDCalc?: number

	/**
	 * Číslo auta
	 */
	VehicleRIDCalc?: number

	/**
	 * Číslo TRN
	 */
	QualificationRIDCalc?: number

	/**
	 * Číslo PERS
	 */
	EmployedPersonRIDCalc?: number

	/**
	 * Pracovní vztah
	 */
	PrEmploymentRIDCalc?: number

	/**
	 * Číslo VTP
	 */
	RoutingVariantIdCalc?: number

	/**
	 * Číslo projektu
	 */
	ProjectRIDCalc?: number

	/**
	 * Číslo etapy projektu
	 */
	ProjectSPORIDCalc?: number

	/**
	 * Číslo výkazu práce
	 */
	ProjectTimeSheetRIDCalc?: number

	/**
	 * Číslo ost. pohledávky
	 */
	OtherReceivableRIDCalc?: number

	/**
	 * Číslo ost. závazku
	 */
	OtherLiabilityRIDCalc?: number

	/**
	 * Číslo stazky
	 */
	VORIdCalc?: number

	/**
	 * Číslo prostředku
	 */
	DeviceRIDCalc?: number

	/**
	 * Číslo DPH
	 */
	VATIdCalc?: number

	/**
	 * Číslo cest. příkazu
	 */
	TravelOrderRIDCalc?: number

	/**
	 * Číslo šarže
	 */
	BatchIdCalc?: number

	/**
	 * Zdroj
	 */
	ResourceIdCalc?: number

	/**
	 * Mzdový doklad
	 */
	PrEmployeeDocumentRIDCalc?: number

	/**
	 * Mzdový doklad za pracovníka
	 */
	PrPersonDocumentRIDCalc?: number

	/**
	 * Fronta práce
	 */
	ProjectJobQueueRIDCalc?: number

	/**
	 * undefined
	 */
	EshopCategoryRIDCalc?: number

	/**
	 * undefined
	 */
	EshopRIDCalc?: number

	/**
	 * undefined
	 */
	EshopTextPageRIDCalc?: number

	/**
	 * undefined
	 */
	EshopBannerRIDCalc?: number

	/**
	 * undefined
	 */
	EshopRegistrationRIDCalc?: number

	/**
	 * Položka nákupu
	 */
	ItemPurchaseRIDCalc?: number

	/**
	 * Položka prodeje
	 */
	ItemSaleRIDCalc?: number

	/**
	 * Položka platebních dokladů
	 */
	PaymentItemRIDCalc?: number

	/**
	 * Výstupní položka mezd
	 */
	PrComponentOutputItemRIDCalc?: number

	/**
	 * undefined
	 */
	AssetRIDCalc?: number

	/**
	 * undefined
	 */
	AssetStockTakingRIDCalc?: number

	/**
	 * undefined
	 */
	AssetStockTakingListRIDCalc?: number

	/**
	 * undefined
	 */
	SalesContractRIDCalc?: number

	/**
	 * undefined
	 */
	PurchaseContractRIDCalc?: number

	/**
	 * undefined
	 */
	OtherContractRIDCalc?: number

	/**
	 * undefined
	 */
	AssetMovementRIDCalc?: number

	/**
	 * undefined
	 */
	OneStopShopRIDCalc?: number

	/**
	 * undefined
	 */
	HandlingUnitRIDCalc?: number

	/**
	 * Srážky z mezd
	 */
	PrDeductionRIDCalc?: number

	/**
	 * undefined
	 */
	ScheduledServiceOperationRIDCalc?: number

	/**
	 * undefined
	 */
	ServiceRequirementRIDCalc?: number

	/**
	 * undefined
	 */
	ServicedDeviceRIDCalc?: number

	/**
	 * RecordModeCalc
	 */
	RecordModeCalc?: number

	/**
	 * Typ připojení
	 */
	@Type(() => DocumentLinkType)
	LinkTypeId?: DocumentLinkType

	/**
	 * Tabulka odk.
	 */
	@Type(() => TTextDM)
	TableReferenceId?: TTextDM

	/**
	 * CDo
	 */
	@Type(() => TTradingPartnerDM)
	TradingPartnerIdCalc?: TTradingPartnerDM

	/**
	 * CD1
	 */
	@Type(() => TTradingPartnerDM)
	AddresseeTradingPartnerIdCalc?: TTradingPartnerDM

	/**
	 * Kód zakázky
	 */
	@Type(() => TContractCodeDocumentDM)
	ContractCodeRIDCalc?: TContractCodeDocumentDM
}