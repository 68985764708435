import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TK2UserLookupDM from './TK2UserLookupDM'
import TInvoiceOutDM from './TInvoiceOutDM'
import TTradingPartnerDM from './TTradingPartnerDM'
import TContactPersonDM from './TContactPersonDM'
import TCommentDM from './TCommentDM'
import TLoyaltyProgramItemHistoryDM from './TLoyaltyProgramItemHistoryDM'

/**
 * Položky věrnostního programu
 */
export default class TLoyaltyProgramItemDM extends Data {
	public static className = 'TLoyaltyProgramItemDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = false

	/**
	 * Vystaveno
	 */
	PurchaseDate?: Date

	/**
	 * Změněno
	 */
	ChangedOn?: Date

	/**
	 * Vytvořeno
	 */
	CreatedOn?: Date

	/**
	 * Zbývající body
	 */
	RemainingPoints?: number

	/**
	 * Body
	 */
	PointsCount?: number

	/**
	 * Typ odběratele
	 */
	CollectorType?: number

	/**
	 * Odběratel
	 */
	CollectorId?: number

	/**
	 * Věrnostní program
	 */
	LoyaltyProgramId?: number

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * undefined
	 */
	CollectorNameCalc?: string

	/**
	 * Změnil
	 */
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM

	/**
	 * Doklad
	 */
	@Type(() => TInvoiceOutDM)
	DocumentId?: TInvoiceOutDM

	/**
	 * undefined
	 */
	@Type(() => TTradingPartnerDM)
	TradingPartnerBindIdCalc?: TTradingPartnerDM

	/**
	 * undefined
	 */
	@Type(() => TContactPersonDM)
	ContactPersonBindIdCalc?: TContactPersonDM

	/**
	 * Komentáře
	 */
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]

	/**
	 * undefined
	 */
	@Type(() => TLoyaltyProgramItemHistoryDM)
	ItemHistoryChild?: TLoyaltyProgramItemHistoryDM[]
}