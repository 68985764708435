import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TContactPersonDM from './TContactPersonDM'
import TRegisteredPointDM from './TRegisteredPointDM'
import TWorkflowProcessDM from './TWorkflowProcessDM'

/**
 * Procesy
 */
export default class TWorkflowProcessAsItemDM extends Data {
	public static className = 'TWorkflowProcessAsItemDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = false

	/**
	 * Číslo datového modulu dokladu
	 */
	DocumentDataModuleNumberId?: number

	/**
	 * Zneplatněno
	 */
	IsInvalidRecord?: string

	/**
	 * Zneplatnil
	 */
	InvalidRecordById?: number

	/**
	 * Zneplatněno
	 */
	InvalidRecordOn?: Date

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * Produkt
	 */
	ProductHeaderId?: number

	/**
	 * Pracovní krok řešitele
	 */
	WorkStepSolverRID?: number

	/**
	 * Vytvořeno
	 */
	CreatedOn?: Date

	/**
	 * Produkt
	 */
	ProductId?: number

	/**
	 * Typ dokladu
	 */
	OldDocumentTypeId?: number

	/**
	 * Číslo dokladu
	 */
	DocumentNumber?: number

	/**
	 * Kniha dokladu
	 */
	OldDocumentBook?: string

	/**
	 * Tabulka
	 */
	OldDocumentTableNumber?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Vytvořil
	 */
	@Type(() => TContactPersonDM)
	CreatedById?: TContactPersonDM

	/**
	 * Registrovaný bod
	 */
	@Type(() => TRegisteredPointDM)
	RegisteredPointId?: TRegisteredPointDM

	/**
	 * Proces
	 */
	@Type(() => TWorkflowProcessDM)
	ProcessId?: TWorkflowProcessDM
}