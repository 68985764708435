import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TTextDM from './TTextDM'
import TTownDM from './TTownDM'
import TCountryDM from './TCountryDM'
import TTownPartDM from './TTownPartDM'
import TTradingPartnerDM from './TTradingPartnerDM'
import TContactPersonDM from './TContactPersonDM'
import TAddressDM from './TAddressDM'

/**
 * Adresáti - vazební tabulka
 */
export default class TRelatedAddressDM extends Data {
	public static className = 'TRelatedAddressDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = false

	/**
	 * undefined
	 */
	AddressDataModuleExtId?: number

	/**
	 * undefined
	 */
	AddressDataModuleRID?: number

	/**
	 * Identifikace hlavičky
	 */
	HeaderRID?: number

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * AddrIdDm
	 */
	AddressDataModuleNumber?: number

	/**
	 * Číslo DM
	 */
	HeaderDataModuleNumber?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * Odběrné místo (pro editaci)
	 */
	PickUpPoint?: string

	/**
	 * Komentář (pro editaci)
	 */
	Comment?: string

	/**
	 * Fax (pro editaci)
	 */
	Fax?: string

	/**
	 * Email (pro editaci)
	 */
	Email?: string

	/**
	 * Telefon (pro editaci)
	 */
	Phone?: string

	/**
	 * PSČ (pro editaci)
	 */
	TownCode?: string

	/**
	 * Longitude (pro editaci)
	 */
	Longitude?: number

	/**
	 * Latitude (pro editaci)
	 */
	Latitude?: number

	/**
	 * Ulice (pro editaci)
	 */
	Street?: string

	/**
	 * Název (pro editaci)
	 */
	NameText?: string

	/**
	 * Číslo orientační (pro editaci)
	 */
	OrientationNumber?: string

	/**
	 * Číslo popisné (pro editaci)
	 */
	HouseNumber?: string

	/**
	 * Adresy (pro editaci)
	 */
	AddressId?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Název
	 */
	NameCalc?: string

	/**
	 * undefined
	 */
	SimpleAddressBindRIDCalc?: number

	/**
	 * VerificationDateCalc
	 */
	VerificationDateCalc?: Date

	/**
	 * Telefon
	 */
	SimpleAddressPhoneCalc?: string

	/**
	 * Email
	 */
	SimpleAddressEmailCalc?: string

	/**
	 * Fax
	 */
	SimpleAddressFaxCalc?: string

	/**
	 * Komentář
	 */
	SimpleAddressCommentCalc?: string

	/**
	 * CoordinatesCalc
	 */
	CoordinatesCalc?: string

	/**
	 * undefined
	 */
	ImplicitIdCalc?: number

	/**
	 * undefined
	 */
	SimpleAddressPickUpPointCalc?: string

	/**
	 * Typ
	 */
	@Type(() => TTextDM)
	AddressTypeId?: TTextDM

	/**
	 * Město (pro editaci)
	 */
	@Type(() => TTownDM)
	TownId?: TTownDM

	/**
	 * Stát (pro editaci)
	 */
	@Type(() => TCountryDM)
	CountryId?: TCountryDM

	/**
	 * Část obce (pro editaci)
	 */
	@Type(() => TTownPartDM)
	TownPartId?: TTownPartDM

	/**
	 * Zákazník
	 */
	@Type(() => TTradingPartnerDM)
	TradingPartnerBindIdCalc?: TTradingPartnerDM

	/**
	 * Kont. osoba
	 */
	@Type(() => TContactPersonDM)
	ContactPersonBindIdCalc?: TContactPersonDM

	/**
	 * undefined
	 */
	@Type(() => TAddressDM)
	AddressIdCalc?: TAddressDM
}