import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TCurrencyDM from './TCurrencyDM'
import TProductCostingSummaryItemDM from './TProductCostingSummaryItemDM'

/**
 * Kalkulace - Sumace po kategoriích
 */
export default class TProductCostingSummaryDM extends Data {
	public static className = 'TProductCostingSummaryDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = false

	/**
	 * Generováno
	 */
	IsGenerated?: string

	/**
	 * Uživatelsky definovaná cena
	 */
	UserDefinedPrice?: number

	/**
	 * Napočtená cena
	 */
	CalculatedPrice?: number

	/**
	 * Předefinuj cenu
	 */
	RedefinePrice?: string

	/**
	 * Kalkulace
	 */
	HeaderRID?: number

	/**
	 * Uživatelsky definovaná cena
	 */
	UserDefinedPriceC?: number

	/**
	 * Napočtená cena
	 */
	CalculatedPriceC?: number

	/**
	 * Název kategorie
	 */
	Name?: string

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * undefined
	 */
	ExchangeRateCalc?: number

	/**
	 * Jednotková cena
	 */
	@Type(() => TCurrencyDM)
	CurrencyCalc?: TCurrencyDM

	/**
	 * undefined
	 */
	@Type(() => TProductCostingSummaryItemDM)
	SummaryItemChild?: TProductCostingSummaryItemDM[]
}