import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TAdvanceReceivedDM from './TAdvanceReceivedDM'
import TVATRateDM from './TVATRateDM'
import TTextDM from './TTextDM'
import TInvoiceOutDM from './TInvoiceOutDM'

/**
 * Čerpání
 */
export default class TAdvanceDeductionAdvanceDM extends Data {
	public static className = 'TAdvanceDeductionAdvanceDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = false

	/**
	 * Čerpáno DPH M
	 */
	DeductedAmountVATC?: number

	/**
	 * Čerpáno netto M
	 */
	DeductedAmountNetC?: number

	/**
	 * Čerpáno M
	 */
	DeductedAmountGrossC?: number

	/**
	 * Identifikace faktury
	 */
	DocumentRID?: number

	/**
	 * Typ daně
	 */
	TaxTypeId?: number

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * AdvanceItemRID
	 */
	AdvanceItemRID?: number

	/**
	 * Kurz čerpání zálohy v měně zál
	 */
	DeductionExchangeRate?: number

	/**
	 * Čerpáno DPH
	 */
	DeductedAmountVAT?: number

	/**
	 * Čerpáno netto
	 */
	DeductedAmountNet?: number

	/**
	 * Kurzový rozdíl
	 */
	ExchangeDifference?: number

	/**
	 * Čerpáno
	 */
	DeductedAmountGross?: number

	/**
	 * Kurz zálohy
	 */
	AdvanceExchangeRate?: number

	/**
	 * Datum čerpání
	 */
	DeductionDate?: Date

	/**
	 * Typ zálohy
	 */
	AdvanceTypeId?: number

	/**
	 * Číslo položky
	 */
	ItemNumber?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Čerpáno netto
	 */
	CFDeductedNetto?: number

	/**
	 * DPH koef.
	 */
	CFCoefVAT?: number

	/**
	 * Čerpáno DPH
	 */
	DeductedAmountVATCalc?: number

	/**
	 * Kurz zálohy
	 */
	AdvanceExchangeRateCalc?: number

	/**
	 * undefined
	 */
	DeductedAmountNetCalc?: number

	/**
	 * undefined
	 */
	ExtExchangeRateCalc?: number

	/**
	 * Popis
	 */
	DescriptionCalc?: string

	/**
	 * Doklad
	 */
	DocumentFullIdentificationCalc?: string

	/**
	 * Identifikace zálohy
	 */
	@Type(() => TAdvanceReceivedDM)
	AdvanceRID?: TAdvanceReceivedDM

	/**
	 * Sazba DPH
	 */
	@Type(() => TVATRateDM)
	VATRateId?: TVATRateDM

	/**
	 * Typ faktury
	 */
	@Type(() => TTextDM)
	DocumentTypeId?: TTextDM

	/**
	 * Faktura
	 */
	@Type(() => TInvoiceOutDM)
	InvoiceRIDCalc?: TInvoiceOutDM

	/**
	 * Záloha
	 */
	@Type(() => TAdvanceReceivedDM)
	AdvanceRIDCalc?: TAdvanceReceivedDM
}