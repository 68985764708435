import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TCapacityPlanResourceDM from './TCapacityPlanResourceDM'

/**
 * Směny
 */
export default class TCapacityPlanWorkshiftDM extends Data {
	public static className = 'TCapacityPlanWorkshiftDM'
	public static primaryKey = 'RecordNumber'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RecordNumber!
	}

	public static isHeader = false

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * ID
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * undefined
	 */
	PlannedDateTimeTo?: Date

	/**
	 * undefined
	 */
	PlannedDateTimeFrom?: Date

	/**
	 * RID směny
	 */
	WorkshiftRID?: number

	/**
	 * Vytížení %
	 */
	Utilization?: number

	/**
	 * undefined
	 */
	RequestedQuantity?: number

	/**
	 * undefined
	 */
	Capacity?: number

	/**
	 * Směna
	 */
	WorkshiftAbbr?: string

	/**
	 * ID
	 */
	RecordNumber?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * undefined
	 */
	ShowInHoursCalc?: string

	/**
	 * Kapacita
	 */
	CapacityUMCalc?: number

	/**
	 * Požadavky
	 */
	RequestedQuantityUMCalc?: number

	/**
	 * Zdroje
	 */
	@Type(() => TCapacityPlanResourceDM)
	CapacityPlanResourceChild?: TCapacityPlanResourceDM[]
}