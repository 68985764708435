import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TCode6DocumentDM from './TCode6DocumentDM'
import TCode5DocumentDM from './TCode5DocumentDM'
import TCode4DocumentDM from './TCode4DocumentDM'
import TCode3DocumentDM from './TCode3DocumentDM'
import TArticleCodeDocumentDM from './TArticleCodeDocumentDM'
import TCostCentreCollectionDocumentDM from './TCostCentreCollectionDocumentDM'
import TBookDM from './TBookDM'
import TDeviceDocumentDM from './TDeviceDocumentDM'
import TContractCodeDocumentDM from './TContractCodeDocumentDM'
import TK2UserLookupDM from './TK2UserLookupDM'
import TAccountingChartDM from './TAccountingChartDM'
import TPrPeriodDM from './TPrPeriodDM'
import TPrDeductionDM from './TPrDeductionDM'
import TMatchingSymbolDM from './TMatchingSymbolDM'
import TCode2DocumentDM from './TCode2DocumentDM'
import TCode1DocumentDM from './TCode1DocumentDM'
import TCurrencyDM from './TCurrencyDM'
import TEmployedPersonDM from './TEmployedPersonDM'
import TPrEmploymentDM from './TPrEmploymentDM'
import TTextDM from './TTextDM'
import TPrEmploymentSimpleDM from './TPrEmploymentSimpleDM'
import TEmployedPersonSimpleDM from './TEmployedPersonSimpleDM'
import TPrEmployeeDocumentDM from './TPrEmployeeDocumentDM'
import TPrPersonDocumentDM from './TPrPersonDocumentDM'
import TPrBookDocumentDM from './TPrBookDocumentDM'

/**
 * Položky mezd - výstupní mzd. sl.
 */
export default class TPrDeductionOutputComponentDM extends Data {
	public static className = 'TPrDeductionOutputComponentDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = false

	/**
	 * Časové razítko
	 */
	TimeStamp?: string

	/**
	 * Právo na záznam
	 */
	RightGroupId?: number

	/**
	 * Základ nezaokr.
	 */
	UnroundedBase?: number

	/**
	 * Základ
	 */
	Base?: number

	/**
	 * HeaderRID
	 */
	HeaderRID?: number

	/**
	 * OriginTyp
	 */
	OriginalTypeId?: number

	/**
	 * OriginRID
	 */
	OriginalRID?: number

	/**
	 * IdLinkRID
	 */
	LinkRID?: number

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * Kurz
	 */
	ExchangeRate?: number

	/**
	 * Částka nezaokr. v měně firmy
	 */
	UnroundedAmountC?: number

	/**
	 * Částka v měně firmy
	 */
	AmountC?: number

	/**
	 * Hodnota X4
	 */
	ValueX4?: number

	/**
	 * Hodnota X3
	 */
	ValueX3?: number

	/**
	 * Hodnota X2
	 */
	ValueX2?: number

	/**
	 * Hodnota X1
	 */
	ValueX1?: number

	/**
	 * Vytvořeno
	 */
	CreatedOn?: Date

	/**
	 * Údaj. sráž.
	 */
	PrDeductionId?: number

	/**
	 * Link
	 */
	LinkPrComponentGUID?: string

	/**
	 * Setřídění
	 */
	SortPriorityId?: number

	/**
	 * Kal.dny
	 */
	CalendarDays?: number

	/**
	 * Kal.hod.
	 */
	CalendarHours?: number

	/**
	 * Ini. č.
	 */
	StandardId?: number

	/**
	 * Č. jiné mzd. sl.
	 */
	LinkPrComponentId?: number

	/**
	 * Prům. nezaokr.
	 */
	UnroundedAverage?: number

	/**
	 * Zůst. nezaokr.
	 */
	UnroundedBalance?: number

	/**
	 * Částka nezaokr.
	 */
	UnroundedAmount?: number

	/**
	 * Sazba nezaokr.
	 */
	UnroundedTariff?: number

	/**
	 * Č.hodn.R
	 */
	ValueReal?: number

	/**
	 * Č.hodn.D
	 */
	ValueDecimal?: number

	/**
	 * Čas do
	 */
	TimeTo?: number

	/**
	 * Čas od
	 */
	TimeFrom?: number

	/**
	 * Typ č.ús.
	 */
	CalendarRecordTypeId?: number

	/**
	 * Řetěz.hodn.
	 */
	ValueString?: string

	/**
	 * Č.hodn.N
	 */
	ValueShort?: number

	/**
	 * Č.hodn.L
	 */
	ValueLong?: number

	/**
	 * Změněno
	 */
	ChangedOn?: Date

	/**
	 * Kontace
	 */
	PostingKeyId?: number

	/**
	 * Prům.
	 */
	Average?: number

	/**
	 * Zůst.
	 */
	Balance?: number

	/**
	 * Částka
	 */
	Amount?: number

	/**
	 * Sazba
	 */
	Tariff?: number

	/**
	 * Zůst.hod.
	 */
	RemainingHours?: number

	/**
	 * Poč.hod.
	 */
	HourCount?: number

	/**
	 * Dat.do
	 */
	DateTo?: Date

	/**
	 * Dat.od
	 */
	DateFrom?: Date

	/**
	 * Zůst.dní
	 */
	RemainingDays?: number

	/**
	 * Prac.dny
	 */
	DayCount?: number

	/**
	 * Kalendář
	 */
	CalendarId?: number

	/**
	 * Platnost do
	 */
	ValidTo?: Date

	/**
	 * Platnost od
	 */
	ValidFrom?: Date

	/**
	 * Poznámka
	 */
	Note?: string

	/**
	 * Popis
	 */
	Description?: string

	/**
	 * Mzdová složka
	 */
	PrComponentId?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * undefined
	 */
	IsItemCompleteCalc?: string

	/**
	 * Info
	 */
	InformationCalc?: string

	/**
	 * Čtvrtletí
	 */
	ValidFromQuarterCalc?: number

	/**
	 * Rok čtvtletí
	 */
	ValidFromQuarterYearCalc?: number

	/**
	 * Rok-DatumOd
	 */
	DateFromYearCalc?: number

	/**
	 * Měsíc-datumuOd
	 */
	DateFromMonthCalc?: number

	/**
	 * Den-datumuOd
	 */
	DateFromDayCalc?: number

	/**
	 * Číslo týdne
	 */
	DateFromWeekCalc?: number

	/**
	 * Sleva na poplatníka
	 */
	TaxPayerDeductionCalc?: number

	/**
	 * Sleva na studenta
	 */
	TaxStudentAllowanceCalc?: number

	/**
	 * Sleva na invaliditu
	 */
	TaxDisabilityDiscountCalc?: number

	/**
	 * ValueL
	 */
	ValueLongAsDateCalc?: Date

	/**
	 * ValueR
	 */
	ValueRealAsDateCalc?: Date

	/**
	 * Dnů platnosti
	 */
	ValidityDaysCalc?: number

	/**
	 * Dnů data
	 */
	DateDayCountCalc?: number

	/**
	 * ValueD
	 */
	ValueDecimalInDaysCalc?: Date

	/**
	 * TypHours
	 */
	CalendarRecordTypeAsDateCalc?: Date

	/**
	 * NumHr
	 */
	HourCountAsDateCalc?: Date

	/**
	 * RemHr
	 */
	RemainingHoursAsDateCalc?: Date

	/**
	 * Rok z platnostiOd
	 */
	ValidFromYearCalc?: number

	/**
	 * Měsíc z platnostiOd
	 */
	ValidFromMonthCalc?: number

	/**
	 * Den z platnostiOd
	 */
	ValidFromDayCalc?: number

	/**
	 * Číslo týdne z platnosti od
	 */
	ValidFromWeekCalc?: number

	/**
	 * Amount + R_Amount
	 */
	TotalAmountCalc?: number

	/**
	 * Rozhodné čtvrtletí
	 */
	DecisiveQuarterCalc?: number

	/**
	 * Rok rozhodného čtvtletí
	 */
	DecisiveQuarterYearCalc?: number

	/**
	 * Rok z datumu do
	 */
	DateToYearCalc?: number

	/**
	 * Měsíc z datumu do
	 */
	DateToMonthCalc?: number

	/**
	 * Den z datumu do
	 */
	DateToDayCalc?: number

	/**
	 * Číslo týdne do
	 */
	DateToWeekCalc?: number

	/**
	 * Hodiny
	 */
	HoursCountCalc?: number

	/**
	 * Hodiny RemHr
	 */
	RemainingHoursCountCalc?: number

	/**
	 * Hodiny CalHr
	 */
	CalendarHoursCalc?: number

	/**
	 * Zůstatek dovolené
	 */
	RemainingHolidayDayCalc?: number

	/**
	 * Hodiny Balance
	 */
	BalanceAsHoursCalc?: number

	/**
	 * Hodiny R_Balance
	 */
	UnroundedBalanceAsHoursCalc?: number

	/**
	 * Hodiny R_Average
	 */
	UnroundedAverageAsHoursCalc?: number

	/**
	 * Hodiny R_Tariff
	 */
	UnroundedTariffAsHoursCalc?: number

	/**
	 * Hodiny R_Amount
	 */
	UnroundedAmountAsHoursCalc?: number

	/**
	 * Hodiny ValueD
	 */
	ValueDecimalHoursCalc?: number

	/**
	 * Hodiny TimeFrom
	 */
	StartTimeHoursCalc?: number

	/**
	 * Hodiny TimeTo
	 */
	EndTimeHoursCalc?: number

	/**
	 * Real jako long
	 */
	ValueRealAsIntegerX1Calc?: number

	/**
	 * Real jako long
	 */
	ValueRealAsIntegerX2Calc?: number

	/**
	 * Real jako long
	 */
	ValueRealAsIntegerX3Calc?: number

	/**
	 * Real jako long
	 */
	ValueRealAsIntegerX4Calc?: number

	/**
	 * undefined
	 */
	UnroundedBalanceAsDateCalc?: Date

	/**
	 * Kurz
	 */
	HeaderExchangeRateCalc?: number

	/**
	 * Kurz pro zobrazení
	 */
	ExchangeRateCalc?: number

	/**
	 * Částka v měně mandanta
	 */
	AmountCCCalc?: number

	/**
	 * ČástkaR v měně mandanta
	 */
	UnroundedAmountClientCCalc?: number

	/**
	 * Částka v měně záznamu
	 */
	AmountCRCalc?: number

	/**
	 * ČástkaR v měně záznamu
	 */
	UnroundedAmountCCalc?: number

	/**
	 * Měna pro srážku mzdy
	 */
	WageDeductionCurrencyCalc?: string

	/**
	 * Částka pro srážku mzdy
	 */
	DeductedAmountCalc?: number

	/**
	 * Zkratka
	 */
	PCMAbbrCalc?: string

	/**
	 * V cizí měně
	 */
	AmountInCurrencyBitCalc?: number

	/**
	 * Příznak 50
	 */
	Flag50IdCalc?: number

	/**
	 * Příznak 51
	 */
	Flag51IdCalc?: number

	/**
	 * Příznak 52
	 */
	Flag52IdCalc?: number

	/**
	 * Příznak 53
	 */
	Flag53IdCalc?: number

	/**
	 * Příznak 54
	 */
	Flag54IdCalc?: number

	/**
	 * Příznak 55
	 */
	Flag55IdCalc?: number

	/**
	 * Příznak 56
	 */
	Flag56IdCalc?: number

	/**
	 * Příznak 57
	 */
	Flag57IdCalc?: number

	/**
	 * Příznak 58
	 */
	Flag58IdCalc?: number

	/**
	 * Příznak 59
	 */
	Flag59IdCalc?: number

	/**
	 * Příznak 60
	 */
	Flag60IdCalc?: number

	/**
	 * Příznak 61
	 */
	Flag61IdCalc?: number

	/**
	 * Příznak 62
	 */
	Flag62IdCalc?: number

	/**
	 * Příznak 63
	 */
	Flag63IdCalc?: number

	/**
	 * undefined
	 */
	ClearWhilePreCalculationBitCalc?: number

	/**
	 * undefined
	 */
	ClearWhileCalculationBitCalc?: number

	/**
	 * undefined
	 */
	ClearWhileClosePeriodBitCalc?: number

	/**
	 * undefined
	 */
	DefCompCreatedByIdCalc?: number

	/**
	 * undefined
	 */
	DefCreateCalculationItemBitCalc?: number

	/**
	 * undefined
	 */
	DefCompForShiftInputCompIdCalc?: number

	/**
	 * undefined
	 */
	ClearWhenComputePrevPerBitCalc?: number

	/**
	 * undefined
	 */
	PCIFromPreIdCalc?: number

	/**
	 * undefined
	 */
	PCIFromPCDIdCalc?: number

	/**
	 * undefined
	 */
	PCIFromPCMIdCalc?: number

	/**
	 * undefined
	 */
	ImportStandardIdCalc?: number

	/**
	 * undefined
	 */
	ImportAttendanceIdCalc?: number

	/**
	 * undefined
	 */
	ImportProductionIdCalc?: number

	/**
	 * undefined
	 */
	PCOFromPCMIdCalc?: number

	/**
	 * undefined
	 */
	PCOFromScriptIdCalc?: number

	/**
	 * undefined
	 */
	ClearWhileCreatePrComBitCalc?: number

	/**
	 * undefined
	 */
	FirstPrecalculationDoneIdCalc?: number

	/**
	 * Nekontrolovat vůči kalendáři
	 */
	DoNotCheckCalendarCalc?: number

	/**
	 * Částka
	 */
	AmountCalc?: number

	/**
	 * Sazba
	 */
	TaxRateCalc?: number

	/**
	 * Průměr
	 */
	AverageCalc?: number

	/**
	 * Zůstatek
	 */
	BalanceCalc?: number

	/**
	 * Prac.dny
	 */
	WorkingDayCountCalc?: number

	/**
	 * DatumOd
	 */
	DateFromCalc?: Date

	/**
	 * DatumDo
	 */
	DateToCalc?: Date

	/**
	 * Kal. dny
	 */
	CalendarDayCalc?: number

	/**
	 * Hodiny
	 */
	HourCountAsHoursMinutesCalc?: number

	/**
	 * Částka v měně firmy
	 */
	AmountCCalc?: number

	/**
	 * Základ
	 */
	BaseAmountCalc?: number

	/**
	 * Právo na záznam
	 */
	RightGroupIdCalc?: number

	/**
	 * Kód 6
	 */
	@Type(() => TCode6DocumentDM)
	Code6Id?: TCode6DocumentDM

	/**
	 * Kód 5
	 */
	@Type(() => TCode5DocumentDM)
	Code5Id?: TCode5DocumentDM

	/**
	 * Kód 4
	 */
	@Type(() => TCode4DocumentDM)
	Code4Id?: TCode4DocumentDM

	/**
	 * Kód 3
	 */
	@Type(() => TCode3DocumentDM)
	Code3Id?: TCode3DocumentDM

	/**
	 * Kód zboží
	 */
	@Type(() => TArticleCodeDocumentDM)
	ArticleCodeId?: TArticleCodeDocumentDM

	/**
	 * Středisko 2
	 */
	@Type(() => TCostCentreCollectionDocumentDM)
	CostCentre2Id?: TCostCentreCollectionDocumentDM

	/**
	 * Středisko 1
	 */
	@Type(() => TCostCentreCollectionDocumentDM)
	CostCentre1Id?: TCostCentreCollectionDocumentDM

	/**
	 * Kniha
	 */
	@Type(() => TBookDM)
	BookId?: TBookDM

	/**
	 * Prostředek
	 */
	@Type(() => TDeviceDocumentDM)
	DeviceRID?: TDeviceDocumentDM

	/**
	 * Kód zakázky
	 */
	@Type(() => TContractCodeDocumentDM)
	ContractCodeRID?: TContractCodeDocumentDM

	/**
	 * Vytvořil
	 */
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM

	/**
	 * Účet Má dáti
	 */
	@Type(() => TAccountingChartDM)
	DebitAccountId?: TAccountingChartDM

	/**
	 * Účet Dal
	 */
	@Type(() => TAccountingChartDM)
	CreditAccountId?: TAccountingChartDM

	/**
	 * Období platnosti
	 */
	@Type(() => TPrPeriodDM)
	ValidityPeriodId?: TPrPeriodDM

	/**
	 * Srážka pracovníka
	 */
	@Type(() => TPrDeductionDM)
	PersonPrDeductionId?: TPrDeductionDM

	/**
	 * Změnil
	 */
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM

	/**
	 * Pár.symb.
	 */
	@Type(() => TMatchingSymbolDM)
	MatchingSymbolId?: TMatchingSymbolDM

	/**
	 * Srážka organizace
	 */
	@Type(() => TPrDeductionDM)
	OrganizationPrDeductionId?: TPrDeductionDM

	/**
	 * Kód 2
	 */
	@Type(() => TCode2DocumentDM)
	Code2Id?: TCode2DocumentDM

	/**
	 * Kód 1
	 */
	@Type(() => TCode1DocumentDM)
	Code1Id?: TCode1DocumentDM

	/**
	 * Měna
	 */
	@Type(() => TCurrencyDM)
	Currency?: TCurrencyDM

	/**
	 * Pracovník
	 */
	@Type(() => TEmployedPersonDM)
	EmployedPersonId?: TEmployedPersonDM

	/**
	 * Období zpracování
	 */
	@Type(() => TPrPeriodDM)
	PeriodId?: TPrPeriodDM

	/**
	 * Pracovní vztah
	 */
	@Type(() => TPrEmploymentDM)
	PrEmployementId?: TPrEmploymentDM

	/**
	 * Č. periody
	 */
	@Type(() => TPrPeriodDM)
	PeriodIdCalc?: TPrPeriodDM

	/**
	 * Období
	 */
	@Type(() => TPrPeriodDM)
	PeriodToIdCalc?: TPrPeriodDM

	/**
	 * Zdroj
	 */
	@Type(() => TTextDM)
	SourceTypeIdCalc?: TTextDM

	/**
	 * Status mzdové složky
	 */
	@Type(() => TTextDM)
	CorrectBitCalc?: TTextDM

	/**
	 * Perioda vytvoření vstupu
	 */
	@Type(() => TPrPeriodDM)
	InputCreationPeriodIdCalc?: TPrPeriodDM

	/**
	 * Pracovní vztah
	 */
	@Type(() => TPrEmploymentSimpleDM)
	PrEmploymentIdCalc?: TPrEmploymentSimpleDM

	/**
	 * Pracovník
	 */
	@Type(() => TEmployedPersonSimpleDM)
	EmployedPersonSimpleIdCalc?: TEmployedPersonSimpleDM

	/**
	 * Měna
	 */
	@Type(() => TCurrencyDM)
	CurrencyCalc?: TCurrencyDM

	/**
	 * Měna mandanta
	 */
	@Type(() => TCurrencyDM)
	ClientCurrencyCalc?: TCurrencyDM

	/**
	 * Mzdový doklad
	 */
	@Type(() => TPrEmployeeDocumentDM)
	PrEmployeeDocumentRIDCalc?: TPrEmployeeDocumentDM

	/**
	 * Mzdový doklad za pracovníka
	 */
	@Type(() => TPrPersonDocumentDM)
	PrPersonDocumentRIDCalc?: TPrPersonDocumentDM

	/**
	 * Mzdový doklad za knihu
	 */
	@Type(() => TPrBookDocumentDM)
	PrDocumentByBookRIDCalc?: TPrBookDocumentDM
}