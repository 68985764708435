import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TWebBrandDM from './TWebBrandDM'
import TCode6DocumentDM from './TCode6DocumentDM'
import TCode5DocumentDM from './TCode5DocumentDM'
import TCode4DocumentDM from './TCode4DocumentDM'
import TCode3DocumentDM from './TCode3DocumentDM'
import TPriceListCategoryDM from './TPriceListCategoryDM'
import TArticleCodeDocumentDM from './TArticleCodeDocumentDM'
import TCostCentreCollectionDocumentDM from './TCostCentreCollectionDocumentDM'
import TCode2DocumentDM from './TCode2DocumentDM'
import TCode1DocumentDM from './TCode1DocumentDM'
import TPackageTypeDM from './TPackageTypeDM'
import TDeviceDocumentDM from './TDeviceDocumentDM'
import TEshopStockTextDM from './TEshopStockTextDM'
import ABCClass from './ABCClass'
import esIdGoodsGroup from './esIdGoodsGroup'
import WarrantyB from './WarrantyB'
import GoodsStatus from './GoodsStatus'
import TContractCodeDocumentDM from './TContractCodeDocumentDM'
import TParameterSetDM from './TParameterSetDM'
import Availability from './Availability'
import TNomenclatureDM from './TNomenclatureDM'
import TK2UserLookupDM from './TK2UserLookupDM'
import TOfficerDocumentDM from './TOfficerDocumentDM'
import PlanGroup from './PlanGroup'
import KindAccounting from './KindAccounting'
import TArticleKindDM from './TArticleKindDM'
import TCountryDM from './TCountryDM'
import TTextDM from './TTextDM'
import ProductGroup from './ProductGroup'
import TCustomSkupiny_variant from './TCustomSkupiny_variant'
import TTradingPartnerDM from './TTradingPartnerDM'
import TSpecialStringListDM from './TSpecialStringListDM'
import TWarehouseDM from './TWarehouseDM'
import TPriceGroupDM from './TPriceGroupDM'
import TPeriodListDM from './TPeriodListDM'
import TCurrencyDM from './TCurrencyDM'
import PlanType from './PlanType'
import AlternativeUnit from './AlternativeUnit'
import TBatchDM from './TBatchDM'
import TMeasureUnitDM from './TMeasureUnitDM'
import TCoverageConfigurationDM from './TCoverageConfigurationDM'
import TPriceListDM from './TPriceListDM'
import TEshopDM from './TEshopDM'
import TParameterDM from './TParameterDM'
import TMarketingActivityTypeDM from './TMarketingActivityTypeDM'
import TWorkflowProcessDM from './TWorkflowProcessDM'
import TActivityDM from './TActivityDM'
import TRoutingVariantDM from './TRoutingVariantDM'
import TAccountingChartDM from './TAccountingChartDM'
import TJobCardConfigDM from './TJobCardConfigDM'
import TVATRateDM from './TVATRateDM'
import TTaxTypeDM from './TTaxTypeDM'
import TIntrastatTariffDutyDM from './TIntrastatTariffDutyDM'
import TLinkDM from './TLinkDM'
import TCommentDM from './TCommentDM'
import TJobDM from './TJobDM'
import TServiceNoteDM from './TServiceNoteDM'
import TArticleNumberDM from './TArticleNumberDM'
import TEshopSearchWordDM from './TEshopSearchWordDM'
import TEshopCategoryListDM from './TEshopCategoryListDM'
import TABCItemDM from './TABCItemDM'
import TArticleVariantDM from './TArticleVariantDM'
import TInventoryTurnoverDM from './TInventoryTurnoverDM'
import TInventoryTurnoverChartSourceDM from './TInventoryTurnoverChartSourceDM'
import TPredictionModelDM from './TPredictionModelDM'
import TArticleWarehouseDM from './TArticleWarehouseDM'
import TWarehouseZoneStockLevelDM from './TWarehouseZoneStockLevelDM'
import TEshopArticleShopValueTextDM from './TEshopArticleShopValueTextDM'
import TArticleBarCodeDM from './TArticleBarCodeDM'
import TArticleMeasureUnitDM from './TArticleMeasureUnitDM'
import TArticleRoutingVariantDM from './TArticleRoutingVariantDM'
import TArticleBreakdownDM from './TArticleBreakdownDM'
import TArticleRelatedArticleDM from './TArticleRelatedArticleDM'
import TArticleParameterValueDM from './TArticleParameterValueDM'
import TProductKeyItemDM from './TProductKeyItemDM'
import TLanguageTextTranslationDM from './TLanguageTextTranslationDM'
import TArticleSuccessorDM from './TArticleSuccessorDM'
import TStockCardDM from './TStockCardDM'
import TPriceListArticleDM from './TPriceListArticleDM'
import TEkoKomArticlePackDM from './TEkoKomArticlePackDM'
import TArticleElectricalWasteDM from './TArticleElectricalWasteDM'
import TEshopArticleCategorySummaryDM from './TEshopArticleCategorySummaryDM'
import TPriceListItemDirectInputDM from './TPriceListItemDirectInputDM'
import TServicedDeviceDM from './TServicedDeviceDM'
import TABCItemChartSourceDM from './TABCItemChartSourceDM'
import TSupplierPriceDM from './TSupplierPriceDM'
import TProductCostingDM from './TProductCostingDM'
import TWebURLAddressItemDM from './TWebURLAddressItemDM'
import TEshopArticleCompEngineCategoryDM from './TEshopArticleCompEngineCategoryDM'
import TExternalDocumentItemDM from './TExternalDocumentItemDM'
import TWorkflowProcessAsItemDM from './TWorkflowProcessAsItemDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TCompanyLinkArticleDM from './TCompanyLinkArticleDM'
import TLegislationLinkArticleDM from './TLegislationLinkArticleDM'
import TLanguageMemoTranslationChildDM from './TLanguageMemoTranslationChildDM'
import TCustomTArticleDMGrid_Informace from './TCustomTArticleDMGrid_Informace'
import TCustomTArticleDMGrid_Konkurencni_ceny from './TCustomTArticleDMGrid_Konkurencni_ceny'
import TCustomTArticleDMGrid_Vybrani_ceny from './TCustomTArticleDMGrid_Vybrani_ceny'
import TCustomTArticleDMProdeje_forecast from './TCustomTArticleDMProdeje_forecast'
import TArticleSellingPriceDM from './TArticleSellingPriceDM'
import TAttachedDocumentDM from './TAttachedDocumentDM'
import TArticleSupplierDatePriceDM from './TArticleSupplierDatePriceDM'
import TOldNoteDM from './TOldNoteDM'
import TStockCardOldDM from './TStockCardOldDM'

/**
 * Zboží
 */
export default class TArticleDM extends Data {
	public static className = 'TArticleDM'
	public static primaryKey = 'Id'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.Id!
	}

	public static isHeader = true

	/**
	 * Spotř. daň
	 */
	ExciseTaxId?: number

	/**
	 * Vstupuje do EKO-KOMu
	 */
	IsUsedByEkokom?: string

	/**
	 * CZ-CPA
	 */
	ProductionClassificationRID?: number

	/**
	 * Evidence pro EKO-KOM při nákupu
	 */
	EkokomPurchase?: string

	/**
	 * Platba
	 */
	esPayment?: string

	/**
	 * Doprava
	 */
	esTransport?: string

	/**
	 * Automat
	 */
	IsLocationRecordsAutomat?: string

	/**
	 * Kontrolovat částku pro režim PDP
	 */
	CheckAmountForPDP?: string

	/**
	 * Předefinovat skupinu ABC
	 */
	RedefineABC?: string

	/**
	 * Nevytvářet Intrastat
	 */
	NotCreateIntrastat?: string

	/**
	 * Prodejní ceny podle šarží
	 */
	BatchSellingPrice?: string

	/**
	 * Zneplatněno
	 */
	IsInvalidRecord?: string

	/**
	 * Režie (%)
	 */
	PercentForOverHead?: string

	/**
	 * Automat
	 */
	IsBatchRecordsAutomat?: string

	/**
	 * Nevytvářet JCD
	 */
	NoSAD?: string

	/**
	 * Evidence kódů zakázek
	 */
	RecordContractCodes?: string

	/**
	 * Evidence umístění
	 */
	RecordLocations?: string

	/**
	 * Režim měna
	 */
	CurrencyPurchase?: string

	/**
	 * Odb. ceny v produktové skupině
	 */
	CustPriceProdGroup?: string

	/**
	 * Povolen záporný stav
	 */
	IsNegativeLevelAllowed?: string

	/**
	 * Elektronická váha
	 */
	ElectronicWeight?: string

	/**
	 * Automat
	 */
	IsSerialNumberRecordsAutomat?: string

	/**
	 * Pořízení (%)
	 */
	PurchaseCostPercent?: string

	/**
	 * Neuvádět názvy zboží
	 */
	IgnoreArticleName?: string

	/**
	 * Prodej v cizí měně
	 */
	IsSaleInForeignCurrency?: string

	/**
	 * Evidence šarží
	 */
	RecordBatches?: string

	/**
	 * Evidence sériových čísel
	 */
	RecordSerialNumbers?: string

	/**
	 * Vyhodnocovat jako ležák
	 */
	EvaluateAsDeadStock?: string

	/**
	 * Evidovat
	 */
	RecordMaxQuantity?: string

	/**
	 * Evidovat
	 */
	RecordMinQuantity?: string

	/**
	 * OgImage
	 */
	OpenGraphImage?: number

	/**
	 * OG typ
	 */
	OpenGraphType?: number

	/**
	 * URL
	 */
	ESPageURL?: string

	/**
	 * Nadřazená karta
	 */
	MasterCardId?: number

	/**
	 * Doba obratu
	 */
	InventorySalesDays?: number

	/**
	 * Výdrž zásob (dny)
	 */
	StockCover?: number

	/**
	 * Nastavení
	 */
	PredictionSettings?: string

	/**
	 * Service level
	 */
	ServiceLevel?: number

	/**
	 * Průběžná doba
	 */
	LeadTime?: number

	/**
	 * Množina variant
	 */
	VariationSetRID?: number

	/**
	 * Analytika
	 */
	ESPageAnalytics?: string

	/**
	 * Původní cena
	 */
	ESOriginalPrice?: number

	/**
	 * Popis pro porovnávače cen
	 */
	ESFeedDescription?: string

	/**
	 * Název pro porovnávače
	 */
	ESFeedTitle?: string

	/**
	 * Klíčová slova
	 */
	ESPageKeyWords?: string

	/**
	 * Popis stránky
	 */
	ESPageDescription?: string

	/**
	 * Titulek stránky
	 */
	ESPageTitle?: string

	/**
	 * Popis
	 */
	ESDescription?: string

	/**
	 * Poznámka
	 */
	ESNote?: string

	/**
	 * Krátký popis
	 */
	ESShortDescription?: string

	/**
	 * Název
	 */
	ESName?: string

	/**
	 * Základní jedn. / jedn.:
	 */
	IntrastatUnitRatio?: number

	/**
	 * Časové razítko
	 */
	TimeStamp?: string

	/**
	 * Popis
	 */
	Description?: string

	/**
	 * Ležák - dnů max.
	 */
	DeadStockMaxDays?: number

	/**
	 * Ležák - dnů min.
	 */
	DeadStockMinDays?: number

	/**
	 * Koncentrace
	 */
	Concentration?: number

	/**
	 * Vytvořeno
	 */
	CreatedOn?: Date

	/**
	 * Režie
	 */
	Overheads?: number

	/**
	 * Právo na záznam
	 */
	RightGroupId?: number

	/**
	 * Prod. kasou
	 */
	SaleCashDesk?: number

	/**
	 * Počet cen. skupin
	 */
	PriceItemCount?: number

	/**
	 * Změněno
	 */
	ChangedOn?: Date

	/**
	 * Akt. obrátka
	 */
	CurrentTurnover?: number

	/**
	 * Max. obrátka
	 */
	MaxTurnover?: number

	/**
	 * Min. obrátka
	 */
	MinTurnover?: number

	/**
	 * Max. množství
	 */
	MaxQuantity?: number

	/**
	 * Min. množství
	 */
	MinQuantity?: number

	/**
	 * Produktová skupina
	 */
	ProductGroupId?: number

	/**
	 * Dopl. kód
	 */
	AdditionalCode?: string

	/**
	 * Značka
	 */
	TradeMark_old?: string

	/**
	 * Název
	 */
	Name?: string

	/**
	 * Zkratka 2
	 */
	Abbr2?: string

	/**
	 * Zkratka 1
	 */
	Abbr?: string

	/**
	 * Číslo
	 */
	Id?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * rozměr na E-SHOPU
	 */
	EX_ES_Size?: number

	/**
	 * URL na E-SHOPU
	 */
	EX_ES_ExternalUrl?: string

	/**
	 * URL výrobce na E-SHO
	 */
	EX_ES_ManUrl?: string

	/**
	 * viditelné na E-SHOPU
	 */
	EX_ES_Visible?: string

	/**
	 * Název na E-SHOPU
	 */
	EX_ES_Name?: string

	/**
	 * kategorie na E-SHOPU
	 */
	EX_ES_Category?: number

	/**
	 * Dostupnost
	 */
	EX_ES_Dostup?: string

	/**
	 * Doporučená cena DPH
	 */
	EX_ES_Rrp?: number

	/**
	 * Novinka E-SHOPU
	 */
	EX_ES_Novinka?: string

	/**
	 * Doprodej E-SHOPU
	 */
	EX_ES_Doprodej?: string

	/**
	 * Akce E-SHOPU
	 */
	EX_ES_Akce?: string

	/**
	 * Poslední nákup Asie
	 */
	EX_posl_nak_Asie?: number

	/**
	 * Účtovat rozbalné
	 */
	EX_rozbal_uct?: string

	/**
	 * EANy pomocné
	 */
	EX_EAN_pom?: string

	/**
	 * Dokup 2
	 */
	EX_Dokup2?: number

	/**
	 * Vlastní EAN
	 */
	Ex_OwnEAN?: string

	/**
	 * Dokup
	 */
	EX_Dokup?: number

	/**
	 * Antidumping
	 */
	EX_Antidumping?: string

	/**
	 * Kapacita
	 */
	EX_Kapacita?: number

	/**
	 * Nastaveno srovnávačem
	 */
	EX_nast_srovn?: string

	/**
	 * Zal s zas
	 */
	EX_zal_s_zas?: number

	/**
	 * EX_VydatVetsiMnoz
	 */
	EX_VydatVetsiMnoz?: string

	/**
	 * EX_Nerozbalovat
	 */
	EX_Nerozbalovat?: string

	/**
	 * Artikl
	 */
	EX_Artikl?: string

	/**
	 * ČSN
	 */
	EX_CSN?: string

	/**
	 * ISO
	 */
	EX_ISO?: string

	/**
	 * DIN
	 */
	EX_DIN?: string

	/**
	 * Ne t.přep.c do příjm
	 */
	EX_ne_preptyd?: string

	/**
	 * Nekontrolovat marži
	 */
	EX_nek_marzi?: string

	/**
	 * EX_exp_surface
	 */
	EX_exp_surface?: string

	/**
	 * EX_exp_material
	 */
	EX_exp_material?: string

	/**
	 * EX_exp_DIN
	 */
	EX_exp_DIN?: string

	/**
	 * Eshop nerozbal
	 */
	EX_ES_Nerozbal?: string

	/**
	 * Suma výdejů od počát
	 */
	EX_L_Sum_VydR?: number

	/**
	 * Byl pohyb - poč fir
	 */
	EX_L_Byl_pohyb_baz?: number

	/**
	 * EX_StdBaleni
	 */
	EX_StdBaleni?: number

	/**
	 * EX_Flagy
	 */
	EX_Flagy?: number

	/**
	 * EX_Zal_CD2
	 */
	EX_Zal_CD2?: number

	/**
	 * EX_Zal_CDo
	 */
	EX_Zal_CDo?: number

	/**
	 * Záloha ruční RS
	 */
	EX_Zal_RS?: number

	/**
	 * Doba pokrytí E min
	 */
	EX_L_Doba_pok_min_E?: number

	/**
	 * Doba pokrytí E max
	 */
	EX_L_Doba_pok_E?: number

	/**
	 * Log. Vynechat datum
	 */
	EX_L_Vynech_Dat?: Date

	/**
	 * Log. Vynechat
	 */
	EX_L_Vynech?: string

	/**
	 * Doba pokrytí min
	 */
	EX_L_Doba_pokryti_mi?: number

	/**
	 * Doba pokrytí
	 */
	EX_L_Doba_pokryti?: number

	/**
	 * Byl pohyb
	 */
	EX_L_Byl_pohyb?: number

	/**
	 * Roční f nák A
	 */
	EX_L_R_F_Nak_A?: number

	/**
	 * Roční f nák E
	 */
	EX_L_R_F_Nak_E?: number

	/**
	 * Prům. dod. lhůta A
	 */
	EX_L_P_D_Lhuta_A?: number

	/**
	 * Prům. dod. lhůta E
	 */
	EX_L_P_D_Lhuta_E?: number

	/**
	 * Rozlišení XYZ
	 */
	EX_L_XYZ?: string

	/**
	 * Rozlišení ABC
	 */
	EX_L_ABC?: string

	/**
	 * Zkratka 3
	 */
	EX_Zkr3?: string

	/**
	 * EX_Ever_E0
	 */
	EX_Ever_E0?: number

	/**
	 * EX_VydSkrzFir
	 */
	EX_VydSkrzFir?: number

	/**
	 * EX_Nosn_koef
	 */
	EX_Nosn_koef?: number

	/**
	 * undefined
	 */
	BookmarkImgCalc?: string

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * SQLSequenceOrderCalc
	 */
	SQLSequenceOrderCalc?: number

	/**
	 * Odkaz
	 */
	PictureCalc?: string

	/**
	 * Datum pro "_ver" pole
	 */
	VersionFieldDateCalc?: Date

	/**
	 * Kurz
	 */
	ExchangeRateBySettingsCalc?: number

	/**
	 * Cena pořízení
	 */
	ComputedPurchasePriceCalc?: number

	/**
	 * Nákl. pořízení
	 */
	PurchaseCostCalc?: number

	/**
	 * Součet
	 */
	TotalCalc?: number

	/**
	 * Plánovaná skladová cena
	 */
	StockPriceCalc?: number

	/**
	 * Poslední cena
	 */
	LastPriceCalc?: number

	/**
	 * Skutečná cena
	 */
	ActualPriceCalc?: number

	/**
	 * Klouzavá cena
	 */
	SlidingPriceCalc?: number

	/**
	 * Prodejní cena
	 */
	SalePriceCalc?: number

	/**
	 * Měna firmy
	 */
	CompanyCurrencyCalc?: string

	/**
	 * Koef. DPH
	 */
	VATCoefficientCalc?: number

	/**
	 * DPH
	 */
	AmountVATPctCalc?: number

	/**
	 * Marže (%)
	 */
	MarginPctCalc?: number

	/**
	 * Daň
	 */
	VATIdCalc?: number

	/**
	 * Režie
	 */
	OverheadsCalc?: number

	/**
	 * Dod.disp.
	 */
	SupplierWarehouseAvailableCalc?: number

	/**
	 * Dod. kód
	 */
	SupplierCodeCalc?: string

	/**
	 * Dod.cena
	 */
	SupplierPriceCalc?: number

	/**
	 * Cenová skupina
	 */
	PriceGroupPriceCalc?: number

	/**
	 * Přijato
	 */
	ReceivedCalc?: number

	/**
	 * Dispozice
	 */
	AvailableCalc?: number

	/**
	 * Zadáno
	 */
	AssignedCalc?: number

	/**
	 * Expedice
	 */
	DispatchCalc?: number

	/**
	 * Disp. 2
	 */
	Available2Calc?: number

	/**
	 * Účetní hodn.
	 */
	AccountingValueCalc?: number

	/**
	 * Jaz.název
	 */
	LanguageTextCalc?: string

	/**
	 * Rezerv.
	 */
	ReservedUMCalc?: number

	/**
	 * Objedn.
	 */
	OrderedUMCalc?: number

	/**
	 * Přijato
	 */
	ReceivedUMCalc?: number

	/**
	 * Dispozice
	 */
	AvailableUMCalc?: number

	/**
	 * Zadáno
	 */
	AssignedUMCalc?: number

	/**
	 * Expedice
	 */
	DispatchUMCalc?: number

	/**
	 * Disp.2
	 */
	Available2UMCalc?: number

	/**
	 * Dod.popis
	 */
	SupplierDescriptionCalc?: string

	/**
	 * MJ nákup
	 */
	DefaultPurchaseMeasureUnitCalc?: string

	/**
	 * MJ prodej
	 */
	DefaultSaleMeasureUnitCalc?: string

	/**
	 * Prod. cena
	 */
	PriceGroupSellingPriceUMCalc?: number

	/**
	 * Min. množství
	 */
	MinQuantityUMCalc?: number

	/**
	 * Max. množství
	 */
	MaxQuantityUMCalc?: number

	/**
	 * Objedn.nák. MJ
	 */
	OrderedInPurchaseQuantityUMCalc?: number

	/**
	 * V příjmu
	 */
	InReceiptQuantityCalc?: number

	/**
	 * V příjmu
	 */
	InReceiptQuantityUMCalc?: number

	/**
	 * Měna
	 */
	DateDependencyCurrencyCalc?: string

	/**
	 * Kurz
	 */
	DateDependentExchangeRateCalc?: number

	/**
	 * Cena pořízení
	 */
	DateDependencyPurchasePriceCalc?: number

	/**
	 * Náklady pořízení
	 */
	DateDependPurchaseCostPriceCalc?: number

	/**
	 * Skladová cena
	 */
	DateDependencyStockPriceCalc?: number

	/**
	 * Marže
	 */
	DateDependencyMarginCalc?: number

	/**
	 * Prodejní cena
	 */
	DateDependencySellingPriceCalc?: number

	/**
	 * Produktová skupina
	 */
	ProductGroupIdCalc?: number

	/**
	 * Koeficient DPH
	 */
	DateDependencyVATCoefficientCalc?: number

	/**
	 * Režie
	 */
	DateDependencyOverheadsCalc?: number

	/**
	 * Filtr na dod. ceny
	 */
	FilterSupplierPricesBitCalc?: string

	/**
	 * Filtr na skl. kartu
	 */
	FilterStockCardBitCalc?: number

	/**
	 * Rezerv. IS
	 */
	FilterInternalTransferBitCalc?: string

	/**
	 * Min.mn. IS
	 */
	InfWarehouseMinLevelCalc?: number

	/**
	 * Max.mn. IS
	 */
	InfWarehouseMaxLevelCalc?: number

	/**
	 * Dispozice
	 */
	FreeAvailableQuantityCalc?: number

	/**
	 * Zadáno
	 */
	AssignedCCalc?: number

	/**
	 * Objedn.
	 */
	OrderedCalc?: number

	/**
	 * Rezerv.
	 */
	ReservedCalc?: number

	/**
	 * Klouzavá cena
	 */
	StockLevelArticleSlidingPriceCalc?: number

	/**
	 * Celk. množství
	 */
	StockLevelArticleQuantityCalc?: number

	/**
	 * Celk. cena
	 */
	StockLevelArticleTotalPriceCalc?: number

	/**
	 * Posl. cena
	 */
	LastPriceCCalc?: number

	/**
	 * Posl. datum nák.
	 */
	LastPurchaseDateCalc?: Date

	/**
	 * Min. množ.
	 */
	InfWarehouseMinLevelUMCalc?: number

	/**
	 * Max. množ.
	 */
	InfWarehouseMaxLevelUMCalc?: number

	/**
	 * Hmot. netto
	 */
	WeightNetCalc?: number

	/**
	 * Hmot. brutto
	 */
	WeightGrossCalc?: number

	/**
	 * Ležáky min.
	 */
	InfWarehouseDeadStockMinCalc?: number

	/**
	 * Ležáky max.
	 */
	InfWarehouseDeadStockMaxCalc?: number

	/**
	 * Základní MJ
	 */
	BasicMeasureUnitCalc?: string

	/**
	 * Poměr
	 */
	UMCoeficientCalc?: number

	/**
	 * Inv. poměr
	 */
	ReverseCoefficientUMCalc?: number

	/**
	 * Dostupnost
	 */
	MeasureUnitIdCalc?: number

	/**
	 * Jazykový popis
	 */
	LanguageDescriptionCalc?: string

	/**
	 * Nák. cena
	 */
	PurchasePriceCCalc?: number

	/**
	 * Čistá nák. cena
	 */
	PurchasePriceNetCalc?: number

	/**
	 * Typ šarže
	 */
	BatchTypeIdCalc?: number

	/**
	 * Posl. náklady
	 */
	LastIncidentalCostsCalc?: number

	/**
	 * Klouzavá cena
	 */
	SlidingPriceUMCalc?: number

	/**
	 * Prodejní cena MJ
	 */
	SellingPriceUMCalc?: number

	/**
	 * D
	 */
	IsAvailableCalc?: string

	/**
	 * D
	 */
	IsAvailableImgCalc?: string

	/**
	 * Dispozice
	 */
	AvailableQuantityUMCalc?: number

	/**
	 * EshopDescriptionCalc
	 */
	EshopDescriptionCalc?: string

	/**
	 * Denní přehled zásoby
	 */
	DailyStockNowCalc?: number

	/**
	 * Denní přehled min.
	 */
	DailyStockMinCalc?: number

	/**
	 * Denní přehled max.
	 */
	DailyStockMaxCalc?: number

	/**
	 * Původní cena
	 */
	OriginPriceCCalc?: number

	/**
	 * Měna
	 */
	OperationCurrencyCalc?: string

	/**
	 * Dostupnost na skladě
	 */
	IsAvailableInStockCalc?: string

	/**
	 * P
	 */
	CheckRCSModeAmountImgCalc?: string

	/**
	 * Implicitní EAN
	 */
	DefaultBarcodeCalc?: string

	/**
	 * Objednáno v nákupu
	 */
	OrderedInPurchaseQuantityCalc?: number

	/**
	 * Datum od predikce
	 */
	PredictionChartDateFromCalc?: Date

	/**
	 * Objednávací kalendář
	 */
	OrderCalendarIdCalc?: number

	/**
	 * Související
	 */
	RelatedArticleCountCalc?: number

	/**
	 * Alternativní
	 */
	AlternateArticleCountCalc?: number

	/**
	 * První obrázek
	 */
	FirstPictureFileNameCalc?: string

	/**
	 * SEO adresa
	 */
	DetailUrlCalc?: string

	/**
	 * Plastnost do
	 */
	ValidToCalc?: Date

	/**
	 * Datum od
	 */
	PeriodFromDateCalc?: Date

	/**
	 * Datum do
	 */
	PeriodToDateCalc?: Date

	/**
	 * undefined
	 */
	IsPriceListCategoryAct1Calc?: string

	/**
	 * undefined
	 */
	IsPriceListCategoryAct2Calc?: string

	/**
	 * undefined
	 */
	IsPriceListCategoryAct3Calc?: string

	/**
	 * undefined
	 */
	IsPriceListCategoryAct4Calc?: string

	/**
	 * undefined
	 */
	IsPriceListCategoryAct5Calc?: string

	/**
	 * Prodejní cena brutto
	 */
	SellingPriceGrossCalc?: number

	/**
	 * Nabízeno
	 */
	OfferedCalc?: number

	/**
	 * Nabízeno
	 */
	OfferedUMCalc?: number

	/**
	 * Prodejní cena netto
	 */
	SalePriceNetCalc?: number

	/**
	 * undefined
	 */
	IsOnStockCalc?: string

	/**
	 * Dispozice
	 */
	AvailableParamQuantityUMCalc?: number

	/**
	 * SEO adresa webu
	 */
	DetailUrlWebCalc?: string

	/**
	 * Stav pokrytí
	 */
	CoverageLevelCalc?: number

	/**
	 * Stav pokrytí
	 */
	CoverageLevelUMCalc?: number

	/**
	 * Int. rez.
	 */
	InternalReservationQuantityCalc?: number

	/**
	 * Int. rez.
	 */
	InternalReservationQuantityUMCalc?: number

	/**
	 * Dispozice
	 */
	AvailableParamQuantityCalc?: number

	/**
	 * Zadáno
	 */
	AssignedParamQuantityCalc?: number

	/**
	 * Rezervováno
	 */
	ReserverParamQuantityCalc?: number

	/**
	 * Objednáno
	 */
	OrderedParamQuantityCalc?: number

	/**
	 * V příjmu
	 */
	InReceiptParamQuantityCalc?: number

	/**
	 * Zadáno
	 */
	AssignedParamQuantityUMCalc?: number

	/**
	 * Rezervováno
	 */
	ReserverParamQuantityUMCalc?: number

	/**
	 * Objednáno
	 */
	OrderedParamQuantityUMCalc?: number

	/**
	 * V příjmu
	 */
	InReceiptParamQuantityUMCalc?: number

	/**
	 * Mě&na
	 */
	IsPurchaseCurrencyCalc?: string

	/**
	 * Objem
	 */
	TotalCapacityCalc?: number

	/**
	 * Výchozí konfigurace
	 */
	InventoryTurnoverDefConfigCalc?: string

	/**
	 * Výchozí konfigurace
	 */
	ABCAnalysisDefaultConfigCalc?: string

	/**
	 * Vyhodnocovat jako ležák
	 */
	EvaluateAsDeadStockCalc?: string

	/**
	 * undefined
	 */
	UrlCalc?: string

	/**
	 * Dispozice pro e-shop podle parametru
	 */
	AvailableParamTotalQuantityCalc?: number

	/**
	 * Dispozice
	 */
	AvailableZonePARAMCalc?: number

	/**
	 * Dispozice
	 */
	AvailableZoneUMPARAMCalc?: number

	/**
	 * Zadáno
	 */
	AssignedZonePARAMCalc?: number

	/**
	 * Zadáno
	 */
	AssignedZoneUMPARAMCalc?: number

	/**
	 * V příjmu
	 */
	InReceiptZonePARAMCalc?: number

	/**
	 * V příjmu
	 */
	InReceiptZoneUMPARAMCalc?: number

	/**
	 * Objem
	 */
	BasicUnitVolumePARAMCalc?: number

	/**
	 * Hodnota 1. param.
	 */
	Parameter1ValueCalc?: string

	/**
	 * Hodnota 2. param.
	 */
	Parameter2ValueCalc?: string

	/**
	 * Hodnota 3. param.
	 */
	Parameter3ValueCalc?: string

	/**
	 * Hodnota 4. param.
	 */
	Parameter4ValueCalc?: string

	/**
	 * Hodnota 5. param.
	 */
	Parameter5ValueCalc?: string

	/**
	 * Porovnání 1. param.
	 */
	ParameterCompareValue1Calc?: number

	/**
	 * Porovnání 2. param.
	 */
	ParameterCompareValue2Calc?: number

	/**
	 * Porovnání 3. param.
	 */
	ParameterCompareValue3Calc?: number

	/**
	 * Porovnání 4. param.
	 */
	ParameterCompareValue4Calc?: number

	/**
	 * Porovnání 5. param.
	 */
	ParameterCompareValue5Calc?: number

	/**
	 * undefined
	 */
	TextHeaderMemoCalc?: string

	/**
	 * undefined
	 */
	TextFooterMemoCalc?: string

	/**
	 * Poslední komentář
	 */
	LastCommentParamCalc?: string

	/**
	 * Název
	 */
	TranslatedESNameCalc?: string

	/**
	 * Krátký popis
	 */
	TranslatedESShortDescriptionCalc?: string

	/**
	 * Poznámka
	 */
	TranslatedESNoteCalc?: string

	/**
	 * Popis
	 */
	TranslatedDescriptionCalc?: string

	/**
	 * Titulek stránky
	 */
	TranslatedESPageTitleCalc?: string

	/**
	 * Popis stránky
	 */
	TranslatedESPageDescriptionCalc?: string

	/**
	 * Klíčová slova
	 */
	TranslatedESPageKeyWordsCalc?: string

	/**
	 * Analytika
	 */
	TranslatedESPageAnalyticsCalc?: string

	/**
	 * URL
	 */
	TranslatedESPageURLCalc?: string

	/**
	 * Název pro porovnávače
	 */
	TranslatedESFeedTitleCalc?: string

	/**
	 * Popis pro porovnávače cen
	 */
	TranslatedESFeedDescriptionCalc?: string

	/**
	 * Skl. cena
	 */
	PlannedStockPriceCalc?: number

	/**
	 * Náklady pořízení
	 */
	PurchaseCostsCalc?: number

	/**
	 * Cena pořízení
	 */
	PurchasePriceCalc?: number

	/**
	 * Prodejní cena netto
	 */
	SellingPriceCalc?: number

	/**
	 * Marže
	 */
	MarginIdCalc?: number

	/**
	 * Kurz
	 */
	ExchangeRateCalc?: number

	/**
	 * CZ-CPA_ver
	 */
	ProductionClassificationRID_ver?: number

	/**
	 * 1.Alt.j
	 */
	CF_AltJ1?: string

	/**
	 * Poměr 1.AJ
	 */
	CF_AltJ1Pom?: number

	/**
	 * Objedn.-R7
	 */
	CF_Objedn_n7?: number

	/**
	 * sUPS
	 */
	CF_sUPS?: string

	/**
	 * sUPP
	 */
	CF_sUPP?: string

	/**
	 * Objednáno RA bez D
	 */
	CF_Objedn_D?: number

	/**
	 * Disp PRI
	 */
	CF_Disp_PRI?: number

	/**
	 * Price
	 */
	CF_cenaZAK?: number

	/**
	 * Disp zal.
	 */
	CF_Disp_zal?: number

	/**
	 * V příjmu
	 */
	CF_V_Prijmu?: number

	/**
	 * Balení
	 */
	CF_bal_sar?: string

	/**
	 * Balení 2
	 */
	CF_bal_sar2?: string

	/**
	 * Balení pro ceník
	 */
	CF_bal_sar_cenik?: string

	/**
	 * Disp 1
	 */
	CF_disp_zbo_DS?: number

	/**
	 * Disp 2
	 */
	CF_disp_zbo_DS2?: number

	/**
	 * Obrátka
	 */
	CF_obratka?: number

	/**
	 * PZ2E
	 */
	CF_zboPZ2E?: number

	/**
	 * PZ2A
	 */
	CF_zboPZ2A?: number

	/**
	 * Evidence AKTIV
	 */
	CF_zboEvidence_AKTIV?: number

	/**
	 * Entire ES name
	 */
	CF_entireESname?: string

	/**
	 * Poslední nákup Evropa
	 */
	CF_poslDOD?: string

	/**
	 * Alt.jedn. bal poměr
	 */
	CF_AltUnit_Bal?: number

	/**
	 * Objednáno RA bez D potvrzené
	 */
	CF_Objedn_Dpot?: number

	/**
	 * Ve Výrobě
	 */
	CF_Ve_Vyrobe?: number

	/**
	 * CF_s_zasoba
	 */
	CF_s_zasoba?: number

	/**
	 * Název EN
	 */
	CF_Naz_EN?: string

	/**
	 * Název DE
	 */
	CF_Naz_DE?: string

	/**
	 * Stav pokryti
	 */
	CF_StavPokryti?: number

	/**
	 * Požadované doobjednání
	 */
	CF_s_pozmnoz?: number

	/**
	 * Požadované doobjednání v
	 */
	CF_s_pozmnoz_v?: number

	/**
	 * Poptáno 17 E
	 */
	CF_popt_17?: number

	/**
	 * CF_s_zasoba_m
	 */
	CF_s_zasoba_m?: number

	/**
	 * Zbo_mandant
	 */
	CF_Zbo_mandant?: string

	/**
	 * Disp Sokolov
	 */
	CF_Disp_AKTIV_SKL?: number

	/**
	 * Disp PLZ
	 */
	CF_Disp_PLZ?: number

	/**
	 * Disp KVA
	 */
	CF_Disp_KVA?: number

	/**
	 * Disp SVA
	 */
	CF_Disp_SVA?: number

	/**
	 * Cena P1 s DPH
	 */
	CF_Prod_Cena_P1_sDPH?: number

	/**
	 * Cena D6 bez DPH
	 */
	CF_Prod_Cena_D6_bezDPH?: number

	/**
	 * Disp PCE
	 */
	CF_Disp_PCE?: number

	/**
	 * Disp OVA
	 */
	CF_Disp_OVA?: number

	/**
	 * Disp CHO
	 */
	CF_Disp_CHO?: number

	/**
	 * Čárové kódy
	 */
	BarCodesCalc?: string

	/**
	 * Interní text
	 */
	CF_Zbo_inttext?: string

	/**
	 * Disp KOL
	 */
	CF_Disp_KOL?: number

	/**
	 * Návrh forecast
	 */
	CF_navrh_forecast?: number

	/**
	 * Disp BRN
	 */
	CF_Disp_BRN?: number

	/**
	 * ZP
	 */
	BMP_s_zasoba_m?: string

	/**
	 * PNA
	 */
	BMP_posl_nak_Asie?: string

	/**
	 * Keywords
	 */
	CF_Keywords?: string

	/**
	 * Dlouhý popis
	 */
	CF_LongDesc?: string

	/**
	 * Krátký popis
	 */
	CF_ShortDesc?: string

	/**
	 * Kategorie 2
	 */
	CF_Category2?: string

	/**
	 * Kategorie
	 */
	CF_Category?: string

	/**
	 * Právo na záznam
	 */
	RightGroupIdCalc?: number

	/**
	 * Značka
	 */
	@Type(() => TWebBrandDM)
	BrandRID?: TWebBrandDM

	/**
	 * Kód 6
	 */
	@Type(() => TCode6DocumentDM)
	Code6Id?: TCode6DocumentDM

	/**
	 * Kód 5
	 */
	@Type(() => TCode5DocumentDM)
	Code5Id?: TCode5DocumentDM

	/**
	 * Kód 4
	 */
	@Type(() => TCode4DocumentDM)
	Code4Id?: TCode4DocumentDM

	/**
	 * Kód 3
	 */
	@Type(() => TCode3DocumentDM)
	Code3Id?: TCode3DocumentDM

	/**
	 * Kategorie ceníku 5
	 */
	@Type(() => TPriceListCategoryDM)
	PriceListCategory5RID?: TPriceListCategoryDM

	/**
	 * Kategorie ceníku 4
	 */
	@Type(() => TPriceListCategoryDM)
	PriceListCategory4RID?: TPriceListCategoryDM

	/**
	 * Kategorie ceníku 3
	 */
	@Type(() => TPriceListCategoryDM)
	PriceListCategory3RID?: TPriceListCategoryDM

	/**
	 * Kategorie ceníku 2
	 */
	@Type(() => TPriceListCategoryDM)
	PriceListCategory2RID?: TPriceListCategoryDM

	/**
	 * Kategorie ceníku 1
	 */
	@Type(() => TPriceListCategoryDM)
	PriceListCategory1RID?: TPriceListCategoryDM

	/**
	 * Kód zboží
	 */
	@Type(() => TArticleCodeDocumentDM)
	ArticleCodeId?: TArticleCodeDocumentDM

	/**
	 * Středisko
	 */
	@Type(() => TCostCentreCollectionDocumentDM)
	CostCentreId?: TCostCentreCollectionDocumentDM

	/**
	 * Kód 2
	 */
	@Type(() => TCode2DocumentDM)
	Code2Id?: TCode2DocumentDM

	/**
	 * Kód 1
	 */
	@Type(() => TCode1DocumentDM)
	Code1Id?: TCode1DocumentDM

	/**
	 * Typ balíku
	 */
	@Type(() => TPackageTypeDM)
	PackageTypeRID?: TPackageTypeDM

	/**
	 * Prostředek
	 */
	@Type(() => TDeviceDocumentDM)
	DeviceRID?: TDeviceDocumentDM

	/**
	 * Alternativní text
	 */
	@Type(() => TEshopStockTextDM)
	ESStockTextRID?: TEshopStockTextDM

	/**
	 * Skupina ABC vypočtená
	 */
	@Type(() => ABCClass)
	ABCClassCalculatedId?: ABCClass

	/**
	 * Skupina ABC
	 */
	@Type(() => ABCClass)
	ABCClassId?: ABCClass

	/**
	 * Skupina pro e-shop
	 */
	@Type(() => esIdGoodsGroup)
	ESArticleGroupId?: esIdGoodsGroup

	/**
	 * Záruka pro firmy
	 */
	@Type(() => WarrantyB)
	WarrantyB2BId?: WarrantyB

	/**
	 * Záruka konc.zákazníka
	 */
	@Type(() => WarrantyB)
	WarrantyB2CId?: WarrantyB

	/**
	 * Stav
	 */
	@Type(() => GoodsStatus)
	StatusId?: GoodsStatus

	/**
	 * Kód zakázky
	 */
	@Type(() => TContractCodeDocumentDM)
	ContractCodeRID?: TContractCodeDocumentDM

	/**
	 * Zařazení pro servis
	 */
	@Type(() => TParameterSetDM)
	ServiceParameterSetId?: TParameterSetDM

	/**
	 * Dostupnost
	 */
	@Type(() => Availability)
	ESZeroStockTextId?: Availability

	/**
	 * Kategorie
	 */
	@Type(() => TParameterSetDM)
	ParameterSetId?: TParameterSetDM

	/**
	 * Nomenklatura
	 */
	@Type(() => TNomenclatureDM)
	NomenclatureId?: TNomenclatureDM

	/**
	 * Vytvořil
	 */
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM

	/**
	 * Referent
	 */
	@Type(() => TOfficerDocumentDM)
	OfficerId?: TOfficerDocumentDM

	/**
	 * Plánovací skupina
	 */
	@Type(() => PlanGroup)
	PlanningGroupId?: PlanGroup

	/**
	 * Druh účtování
	 */
	@Type(() => KindAccounting)
	PostingKindId?: KindAccounting

	/**
	 * Druh
	 */
	@Type(() => TArticleKindDM)
	ArticleCategoryId?: TArticleKindDM

	/**
	 * Stát pův.
	 */
	@Type(() => TCountryDM)
	OriginCountryId?: TCountryDM

	/**
	 * Změnil
	 */
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM

	/**
	 * Typ
	 */
	@Type(() => TTextDM)
	ArticleTypeId?: TTextDM

	/**
	 * Skupina
	 */
	@Type(() => ProductGroup)
	Group?: ProductGroup

	/**
	 * Skupiny variant
	 */
	@Type(() => TCustomSkupiny_variant)
	EX_sk_var?: TCustomSkupiny_variant

	/**
	 * Dokup dodavatel
	 */
	@Type(() => TTradingPartnerDM)
	EX_Dokup_CDo?: TTradingPartnerDM

	/**
	 * Sekundární dodavatel
	 */
	@Type(() => TTradingPartnerDM)
	EX_L_CDo_2?: TTradingPartnerDM

	/**
	 * Manipulační kategori
	 */
	@Type(() => TSpecialStringListDM)
	EX_L_Manipulace?: TSpecialStringListDM

	/**
	 * L. sortiment
	 */
	@Type(() => TSpecialStringListDM)
	EX_L_Sortiment?: TSpecialStringListDM

	/**
	 * Z
	 */
	@Type(() => TTextDM)
	InvalidRecordImgCalc?: TTextDM

	/**
	 * Informační sklad
	 */
	@Type(() => TWarehouseDM)
	InformationWarehouseIdCalc?: TWarehouseDM

	/**
	 * Cenová skupina
	 */
	@Type(() => TPriceGroupDM)
	PriceGroupIdCalc?: TPriceGroupDM

	/**
	 * Fyzický sklad
	 */
	@Type(() => TWarehouseDM)
	WarehouseIdCalc?: TWarehouseDM

	/**
	 * Firma
	 */
	@Type(() => TTradingPartnerDM)
	SupplierIdCalc?: TTradingPartnerDM

	/**
	 * Fyzický sklad 2
	 */
	@Type(() => TWarehouseDM)
	Warehouse2IdCalc?: TWarehouseDM

	/**
	 * Perioda od
	 */
	@Type(() => TPeriodListDM)
	BookPeriodFromIdCalc?: TPeriodListDM

	/**
	 * Perioda do
	 */
	@Type(() => TPeriodListDM)
	BookPeriodToIdCalc?: TPeriodListDM

	/**
	 * Měna
	 */
	@Type(() => TCurrencyDM)
	UserCurrencyCalc?: TCurrencyDM

	/**
	 * Typ plánu
	 */
	@Type(() => PlanType)
	PlannedTypeIdCalc?: PlanType

	/**
	 * Zobrazit MJ
	 */
	@Type(() => TTextDM)
	ShowMeasureUnitBitCalc?: TTextDM

	/**
	 * Jiná jednotka
	 */
	@Type(() => AlternativeUnit)
	OtherMeasureUnitCalc?: AlternativeUnit

	/**
	 * Typ prod. ceny
	 */
	@Type(() => TTextDM)
	PriceTypeIdCalc?: TTextDM

	/**
	 * Filtr na dod. c. - firma
	 */
	@Type(() => TTradingPartnerDM)
	FilterTradingPartnerIdCalc?: TTradingPartnerDM

	/**
	 * Filtrovat kód zakázky
	 */
	@Type(() => TContractCodeDocumentDM)
	FilterContractCodeRIDCalc?: TContractCodeDocumentDM

	/**
	 * Filtrovat šarži
	 */
	@Type(() => TBatchDM)
	FilterBatchBitCalc?: TBatchDM

	/**
	 * Jednotka
	 */
	@Type(() => TMeasureUnitDM)
	MeasureUnitCalc?: TMeasureUnitDM

	/**
	 * Měna
	 */
	@Type(() => TCurrencyDM)
	PurchaseCurrencyCalc?: TCurrencyDM

	/**
	 * Jazyk pro jazykový překlad
	 */
	@Type(() => TTextDM)
	ParameterLanguageIdCalc?: TTextDM

	/**
	 * Vícerozměrnné množství
	 */
	@Type(() => TTextDM)
	MultidimensionalQuantityBitCalc?: TTextDM

	/**
	 * Predikce
	 */
	@Type(() => TTextDM)
	PredictionStyleIdCalc?: TTextDM

	/**
	 * Sumace na denních přehledech
	 */
	@Type(() => TTextDM)
	DailyInventoryOverviewIdCalc?: TTextDM

	/**
	 * Skladová jednotka, 8. str
	 */
	@Type(() => TMeasureUnitDM)
	StockCardMeasureUnitCalc?: TMeasureUnitDM

	/**
	 * Zákl. jedn.
	 */
	@Type(() => TMeasureUnitDM)
	BaseMeasureUnitIdCalc?: TMeasureUnitDM

	/**
	 * Jednotka pro nákup
	 */
	@Type(() => TMeasureUnitDM)
	DefaultPurchaseMeasureUnitIdCalc?: TMeasureUnitDM

	/**
	 * Jednotka pro prodej
	 */
	@Type(() => TMeasureUnitDM)
	DefaultSaleMeasureUnitIdCalc?: TMeasureUnitDM

	/**
	 * Období od
	 */
	@Type(() => TPeriodListDM)
	PeriodFromIdCalc?: TPeriodListDM

	/**
	 * Období do
	 */
	@Type(() => TPeriodListDM)
	PeriodToIdCalc?: TPeriodListDM

	/**
	 * Nastavení pokrytí
	 */
	@Type(() => TCoverageConfigurationDM)
	CoverageConfigCalc?: TCoverageConfigurationDM

	/**
	 * Jednotka
	 */
	@Type(() => TMeasureUnitDM)
	ImplicitMeasureUnitCalc?: TMeasureUnitDM

	/**
	 * MJ EAN
	 */
	@Type(() => TMeasureUnitDM)
	DefaultBarcodeMeasureUnitIdCalc?: TMeasureUnitDM

	/**
	 * undefined
	 */
	@Type(() => TCurrencyDM)
	ActualCompanyCurrencyCalc?: TCurrencyDM

	/**
	 * Ceník
	 */
	@Type(() => TPriceListDM)
	PriceListForPriceRIDCalc?: TPriceListDM

	/**
	 * Web pro zobrazení a editaci dat
	 */
	@Type(() => TEshopDM)
	ShownWebRIDCalc?: TEshopDM

	/**
	 * 1. parametr
	 */
	@Type(() => TParameterDM)
	Parameter1IdCalc?: TParameterDM

	/**
	 * 2. parametr
	 */
	@Type(() => TParameterDM)
	Parameter2IdCalc?: TParameterDM

	/**
	 * 3. parametr
	 */
	@Type(() => TParameterDM)
	Parameter3IdCalc?: TParameterDM

	/**
	 * 4. parametr
	 */
	@Type(() => TParameterDM)
	Parameter4IdCalc?: TParameterDM

	/**
	 * 5. parametr
	 */
	@Type(() => TParameterDM)
	Parameter5IdCalc?: TParameterDM

	/**
	 * D
	 */
	@Type(() => TTextDM)
	DocumentExistsCalc?: TTextDM

	/**
	 * undefined
	 */
	@Type(() => TMarketingActivityTypeDM)
	ActivityTypeRIDCalc?: TMarketingActivityTypeDM

	/**
	 * SP
	 */
	@Type(() => TTextDM)
	ProcessStateIdCalc?: TTextDM

	/**
	 * Aktuální proces
	 */
	@Type(() => TWorkflowProcessDM)
	CurrentProcessIdCalc?: TWorkflowProcessDM

	/**
	 * undefined
	 */
	@Type(() => TActivityDM)
	ActivityIdCalc?: TActivityDM

	/**
	 * TranslationLanguageIdCalc
	 */
	@Type(() => TTextDM)
	TranslationLanguageIdCalc?: TTextDM

	/**
	 * Opt. Dodavatel
	 */
	@Type(() => TTradingPartnerDM)
	OptimalSupplierIdCalc?: TTradingPartnerDM

	/**
	 * Varianta
	 */
	@Type(() => TRoutingVariantDM)
	RoutingVariantIdCalc?: TRoutingVariantDM

	/**
	 * Účet - nákup
	 */
	@Type(() => TAccountingChartDM)
	PurchaseAccountIdCalc?: TAccountingChartDM

	/**
	 * Účet - prodej
	 */
	@Type(() => TAccountingChartDM)
	SalesAccountIdCalc?: TAccountingChartDM

	/**
	 * Účet - nák. pro podnik kon. c.
	 */
	@Type(() => TAccountingChartDM)
	ConsolidationPurchaseAccountIdCalc?: TAccountingChartDM

	/**
	 * Účet - pro. pro podnik kon. c.
	 */
	@Type(() => TAccountingChartDM)
	ConsolidationSalesAccountIdCalc?: TAccountingChartDM

	/**
	 * Měna
	 */
	@Type(() => TCurrencyDM)
	CurrencyCalc?: TCurrencyDM

	/**
	 * Konfigurace vytváření průvodek
	 */
	@Type(() => TJobCardConfigDM)
	JobCardConfigIdCalc?: TJobCardConfigDM

	/**
	 * DPH
	 */
	@Type(() => TVATRateDM)
	VATRateIdCalc?: TVATRateDM

	/**
	 * Typ daně - nákup
	 */
	@Type(() => TTaxTypeDM)
	PurchaseTaxTypeIdCalc?: TTaxTypeDM

	/**
	 * Typ daně - prodej
	 */
	@Type(() => TTaxTypeDM)
	SaleTaxTypeIdCalc?: TTaxTypeDM

	/**
	 * Typ daně PDP nákup
	 */
	@Type(() => TTaxTypeDM)
	PurchaseTaxTypeRCSIdCalc?: TTaxTypeDM

	/**
	 * Typ daně PDP prodej
	 */
	@Type(() => TTaxTypeDM)
	SaleTaxTypeRCSIdCalc?: TTaxTypeDM

	/**
	 * Celní sazebník
	 */
	@Type(() => TIntrastatTariffDutyDM)
	CustomsTariffIdCalc?: TIntrastatTariffDutyDM

	/**
	 * DPH_Ver
	 */
	@Type(() => TVATRateDM)
	VATRateId_Ver?: TVATRateDM

	/**
	 * Celní sazebník_Ver
	 */
	@Type(() => TIntrastatTariffDutyDM)
	CustomsTariffId_Ver?: TIntrastatTariffDutyDM

	/**
	 * Odkazy
	 */
	@Type(() => TLinkDM)
	LinkChild?: TLinkDM[]

	/**
	 * Komentáře
	 */
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]

	/**
	 * Úkoly
	 */
	@Type(() => TJobDM)
	JobChild?: TJobDM[]

	/**
	 * Seznam servisních listů
	 */
	@Type(() => TServiceNoteDM)
	ServiceNoteChild?: TServiceNoteDM[]

	/**
	 * Seznam čísel
	 */
	@Type(() => TArticleNumberDM)
	ArticleNumberChild?: TArticleNumberDM[]

	/**
	 * Vyhledávaná slova
	 */
	@Type(() => TEshopSearchWordDM)
	EshopSearchWordChild?: TEshopSearchWordDM[]

	/**
	 * Kategorie zboží
	 */
	@Type(() => TEshopCategoryListDM)
	ArticleCategoryChild?: TEshopCategoryListDM[]

	/**
	 * ABC analýzy
	 */
	@Type(() => TABCItemDM)
	ABCItemChild?: TABCItemDM[]

	/**
	 * Varianty zboží
	 */
	@Type(() => TArticleVariantDM)
	ArticleVariantChild?: TArticleVariantDM[]

	/**
	 * Obrátka zásob
	 */
	@Type(() => TInventoryTurnoverDM)
	InventoryTurnoverChild?: TInventoryTurnoverDM[]

	/**
	 * Obrátka zásob graf
	 */
	@Type(() => TInventoryTurnoverChartSourceDM)
	InventoryTurnOverChartSourceChild?: TInventoryTurnoverChartSourceDM[]

	/**
	 * Predikce
	 */
	@Type(() => TPredictionModelDM)
	PredictionModelChild?: TPredictionModelDM[]

	/**
	 * Přehledy zboží/sklad
	 */
	@Type(() => TArticleWarehouseDM)
	ArticleWarehouseChild?: TArticleWarehouseDM[]

	/**
	 * Dispozice ve sklad.zónách
	 */
	@Type(() => TWarehouseZoneStockLevelDM)
	WarehouseZoneStockLevelChild?: TWarehouseZoneStockLevelDM[]

	/**
	 * Příznaky obchodu
	 */
	@Type(() => TEshopArticleShopValueTextDM)
	EshopFlagChild?: TEshopArticleShopValueTextDM[]

	/**
	 * Čárové kódy zboží
	 */
	@Type(() => TArticleBarCodeDM)
	BarCodeChild?: TArticleBarCodeDM[]

	/**
	 * Skl. jednotky
	 */
	@Type(() => TArticleMeasureUnitDM)
	MeasureUnitChild?: TArticleMeasureUnitDM[]

	/**
	 * Variantní tech. postupy
	 */
	@Type(() => TArticleRoutingVariantDM)
	AlternateRoutingChild?: TArticleRoutingVariantDM[]

	/**
	 * Položky rozpisu zboží
	 */
	@Type(() => TArticleBreakdownDM)
	ArticleSupplierPriceChild?: TArticleBreakdownDM[]

	/**
	 * Související zboží
	 */
	@Type(() => TArticleRelatedArticleDM)
	RelatedArticleChild?: TArticleRelatedArticleDM[]

	/**
	 * Parametry
	 */
	@Type(() => TArticleParameterValueDM)
	ParameterValueChild?: TArticleParameterValueDM[]

	/**
	 * Produktový klíč
	 */
	@Type(() => TProductKeyItemDM)
	ProductKeyChild?: TProductKeyItemDM[]

	/**
	 * Jazykové pole
	 */
	@Type(() => TLanguageTextTranslationDM)
	LanguageNameChild?: TLanguageTextTranslationDM[]

	/**
	 * Související zboží master
	 */
	@Type(() => TArticleRelatedArticleDM)
	RelatedMasterArticleChild?: TArticleRelatedArticleDM[]

	/**
	 * Přehled předchůdců
	 */
	@Type(() => TArticleSuccessorDM)
	ArticlePredecessorChild?: TArticleSuccessorDM[]

	/**
	 * Přehled následovníků
	 */
	@Type(() => TArticleSuccessorDM)
	ArticleSuccessorChild?: TArticleSuccessorDM[]

	/**
	 * Skladová karta
	 */
	@Type(() => TStockCardDM)
	StockCardChild?: TStockCardDM[]

	/**
	 * Ceník
	 */
	@Type(() => TPriceListArticleDM)
	PriceListArticleChild?: TPriceListArticleDM[]

	/**
	 * Obaly EKO-KOM
	 */
	@Type(() => TEkoKomArticlePackDM)
	EkokomChild?: TEkoKomArticlePackDM[]

	/**
	 * Elektroodpad
	 */
	@Type(() => TArticleElectricalWasteDM)
	ElectricalWasteChild?: TArticleElectricalWasteDM[]

	/**
	 * undefined
	 */
	@Type(() => TEshopArticleCategorySummaryDM)
	AssignedEshopCategoryChild?: TEshopArticleCategorySummaryDM[]

	/**
	 * undefined
	 */
	@Type(() => TPriceListItemDirectInputDM)
	PriceListDirectInputChild?: TPriceListItemDirectInputDM[]

	/**
	 * undefined
	 */
	@Type(() => TServicedDeviceDM)
	ServicedDeviceChild?: TServicedDeviceDM[]

	/**
	 * undefined
	 */
	@Type(() => TABCItemChartSourceDM)
	ABCItemChartSourceChild?: TABCItemChartSourceDM[]

	/**
	 * undefined
	 */
	@Type(() => TSupplierPriceDM)
	SupplierPriceChild?: TSupplierPriceDM[]

	/**
	 * undefined
	 */
	@Type(() => TProductCostingDM)
	ProductCostingChild?: TProductCostingDM[]

	/**
	 * undefined
	 */
	@Type(() => TWebURLAddressItemDM)
	WebUrlPageAddressItemChild?: TWebURLAddressItemDM[]

	/**
	 * undefined
	 */
	@Type(() => TEshopArticleCompEngineCategoryDM)
	EshopArticleCompEngineCategoryChild?: TEshopArticleCompEngineCategoryDM[]

	/**
	 * Seznam aktivit
	 */
	@Type(() => TActivityDM)
	ActivityChild?: TActivityDM[]

	/**
	 * Dokumenty
	 */
	@Type(() => TExternalDocumentItemDM)
	ExternalDocumentItemChild?: TExternalDocumentItemDM[]

	/**
	 * Procesy
	 */
	@Type(() => TWorkflowProcessAsItemDM)
	WorkflowProcessChild?: TWorkflowProcessAsItemDM[]

	/**
	 * Text hlavičky
	 */
	@Type(() => TLanguageMemoTranslationDM)
	TextHeaderChild?: TLanguageMemoTranslationDM[]

	/**
	 * Text patičky
	 */
	@Type(() => TLanguageMemoTranslationDM)
	TextFooterChild?: TLanguageMemoTranslationDM[]

	/**
	 * undefined
	 */
	@Type(() => TCompanyLinkArticleDM)
	CompanyChild?: TCompanyLinkArticleDM[]

	/**
	 * undefined
	 */
	@Type(() => TLegislationLinkArticleDM)
	LegislationChild?: TLegislationLinkArticleDM[]

	/**
	 * undefined
	 */
	@Type(() => TLanguageMemoTranslationChildDM)
	TranslatedFieldValuesChild?: TLanguageMemoTranslationChildDM[]

	/**
	 * Grid Informace
	 */
	@Type(() => TCustomTArticleDMGrid_Informace)
	Grid_Informace?: TCustomTArticleDMGrid_Informace[]

	/**
	 * Grid Konkurencni  ceny
	 */
	@Type(() => TCustomTArticleDMGrid_Konkurencni_ceny)
	Grid_Konkurencni_ceny?: TCustomTArticleDMGrid_Konkurencni_ceny[]

	/**
	 * Grid Vybrani  ceny
	 */
	@Type(() => TCustomTArticleDMGrid_Vybrani_ceny)
	Grid_Vybrani_ceny?: TCustomTArticleDMGrid_Vybrani_ceny[]

	/**
	 * Prodeje forecast
	 */
	@Type(() => TCustomTArticleDMProdeje_forecast)
	Prodeje_forecast?: TCustomTArticleDMProdeje_forecast[]

	/**
	 * Cenové skupiny a odběratelské ceny
	 */
	@Type(() => TArticleSellingPriceDM)
	ArticleSellingPriceOldChild?: TArticleSellingPriceDM[]

	/**
	 * Svázané doklady
	 */
	@Type(() => TAttachedDocumentDM)
	AttachedDocumentOldChild?: TAttachedDocumentDM[]

	/**
	 * Datumové ceny - hlavička
	 */
	@Type(() => TArticleSupplierDatePriceDM)
	DatePriceOldChild?: TArticleSupplierDatePriceDM[]

	/**
	 * Poznámky
	 */
	@Type(() => TOldNoteDM)
	DeprecatedNoteOldChild?: TOldNoteDM[]

	/**
	 * Položky skladové karty
	 */
	@Type(() => TStockCardOldDM)
	StockCardOldChild?: TStockCardOldDM[]

	/**
	 * Zařadit do klíče/odstranit z klíče
	 */
	public BulkSortingKeyAddRemoveCOMMAND(parameters: TBulkSortingKeyAddRemoveCOMMANDParams) {
		return this.executeCommand('BulkSortingKeyAddRemoveCOMMAND', parameters)
	}

	/**
	 * Změna parametrů zboží
	 */
	public BulkGoodsParametersCOMMAND(parameters: TBulkGoodsParametersCOMMANDParams) {
		return this.executeCommand('BulkGoodsParametersCOMMAND', parameters)
	}

	/**
	 * Změna hodnot pro int. obchody
	 */
	public BulkGoodsShopValuesCOMMAND(parameters: TBulkGoodsShopValuesCOMMANDParams) {
		return this.executeCommand('BulkGoodsShopValuesCOMMAND', parameters)
	}

	/**
	 * Změna sazby DPH
	 */
	public BulkChangeRateOfVatCOMMAND(parameters: TBulkChangeRateOfVatCOMMANDParams) {
		return this.executeCommand('BulkChangeRateOfVatCOMMAND', parameters)
	}

	/**
	 * Přepočet výrobků
	 */
	public ProductRecalculationCOMMAND(parameters: TProductRecalculationCOMMANDParams) {
		return this.executeCommand('ProductRecalculationCOMMAND', parameters)
	}

	/**
	 * Výpočet predikcí
	 */
	public BulkPredictionModelRepeatCalcCOMMAND(parameters: TBulkPredictionModelRepeatCalcCOMMANDParams) {
		return this.executeCommand('BulkPredictionModelRepeatCalcCOMMAND', parameters)
	}

	/**
	 * Výpočet obrátky zásob
	 */
	public InventoryTurnoverRepeatCalcCOMMAND(parameters: TInventoryTurnoverRepeatCalcCOMMANDParams) {
		return this.executeCommand('InventoryTurnoverRepeatCalcCOMMAND', parameters)
	}

	/**
	 * Ceny netto/brutto
	 */
	public SwitchVATCOMMAND() {
		return this.executeCommand('SwitchVATCOMMAND')
	}

	/**
	 * Editace údajů zboží/sklad
	 */
	public BulkEditArticleWarehouseCOMMAND(parameters: TBulkEditArticleWarehouseCOMMANDParams) {
		return this.executeCommand('BulkEditArticleWarehouseCOMMAND', parameters)
	}

	/**
	 * Změna skladových jednotek
	 */
	public BulkGoodsMeasureUnitParamCOMMAND(parameters: TBulkGoodsMeasureUnitParamCOMMANDParams) {
		return this.executeCommand('BulkGoodsMeasureUnitParamCOMMAND', parameters)
	}

	/**
	 * Zneplatnění
	 */
	public BulkInvalidationCOMMAND() {
		return this.executeCommand('BulkInvalidationCOMMAND')
	}

	/**
	 * Zplatnění
	 */
	public BulkUnInvalidationCOMMAND() {
		return this.executeCommand('BulkUnInvalidationCOMMAND')
	}

	/**
	 * Zneplatnění/Zplatnění
	 */
	public InvalidationCOMMAND() {
		return this.executeCommand('InvalidationCOMMAND')
	}

	/**
	 * Potvrzení
	 */
	public BulkConfirmCOMMAND() {
		return this.executeCommand('BulkConfirmCOMMAND')
	}

	/**
	 * Odpotvrzení
	 */
	public BulkUnconfirmCOMMAND() {
		return this.executeCommand('BulkUnconfirmCOMMAND')
	}

	/**
	 * Změna data potvrzení
	 */
	public BulkChangeConfirmationDateCOMMAND(parameters: TBulkChangeConfirmationDateCOMMANDParams) {
		return this.executeCommand('BulkChangeConfirmationDateCOMMAND', parameters)
	}

	/**
	 * Storno
	 */
	public BulkCancelCOMMAND() {
		return this.executeCommand('BulkCancelCOMMAND')
	}

	/**
	 * Odstorno
	 */
	public BulkUnCancelCOMMAND() {
		return this.executeCommand('BulkUnCancelCOMMAND')
	}

	/**
	 * Potvrzení
	 */
	public ConfirmCOMMAND() {
		return this.executeCommand('ConfirmCOMMAND')
	}

	/**
	 * Odpotvrzení
	 */
	public UnconfirmCOMMAND() {
		return this.executeCommand('UnconfirmCOMMAND')
	}

	/**
	 * Změna data potvrzení
	 */
	public ChangeConfirmationDateCOMMAND(parameters: TChangeConfirmationDateCOMMANDParams) {
		return this.executeCommand('ChangeConfirmationDateCOMMAND', parameters)
	}

	/**
	 * Storno/Odstorno
	 */
	public CancelCOMMAND() {
		return this.executeCommand('CancelCOMMAND')
	}

	/**
	 * Zrušení stavu
	 */
	public ClearRecordStatusCOMMAND() {
		return this.executeCommand('ClearRecordStatusCOMMAND')
	}

	/**
	 * Změna stavu
	 */
	public ChangeRecordStatusCOMMAND() {
		return this.executeCommand('ChangeRecordStatusCOMMAND')
	}

	/**
	 * 
	 */
	public ChangeRecordStatusSWSCOMMAND(parameters: TChangeRecordStatusSWSCOMMANDParams) {
		return this.executeCommand('ChangeRecordStatusSWSCOMMAND', parameters)
	}

	/**
	 * Zrušení stavu
	 */
	public BulkClearRecordStatusCOMMAND() {
		return this.executeCommand('BulkClearRecordStatusCOMMAND')
	}

	/**
	 * Změna stavu
	 */
	public BulkChangeRecordStatusCOMMAND() {
		return this.executeCommand('BulkChangeRecordStatusCOMMAND')
	}

	/**
	 * Změna atributů
	 */
	public BulkChangeAttributesCOMMAND(parameters: TBulkChangeAttributesCOMMANDParams) {
		return this.executeCommand('BulkChangeAttributesCOMMAND', parameters)
	}

	/**
	 * Aktualizace sady atributů
	 */
	public BulkActualizeAttributesCOMMAND(parameters: TBulkActualizeAttributesCOMMANDParams) {
		return this.executeCommand('BulkActualizeAttributesCOMMAND', parameters)
	}

	/**
	 * Aktualizace sady atributů
	 */
	public ActualizeAttributesCOMMAND() {
		return this.executeCommand('ActualizeAttributesCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public ChangeGroupRightCOMMAND() {
		return this.executeCommand('ChangeGroupRightCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public BulkChangeGroupRightCOMMAND() {
		return this.executeCommand('BulkChangeGroupRightCOMMAND')
	}

	/**
	 * Aktualizace počítaných polí
	 */
	public BulkUpdateCalcFieldsCOMMAND() {
		return this.executeCommand('BulkUpdateCalcFieldsCOMMAND')
	}

	/**
	 * Kontrola legislativy
	 */
	public SwitchOffLegislationValidationCOMMAND() {
		return this.executeCommand('SwitchOffLegislationValidationCOMMAND')
	}
}

export type TBulkSortingKeyAddRemoveCOMMANDParams = {
	/**
	 * Produktový klíč
	 */
	SortingProductKey?: number
	/**
	 * Adresní klíč
	 */
	SortingAddressKey?: number
	/**
	 * Volba
	 */
	SortingKeyOptionFiltrRecord?: number
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TBulkGoodsParametersCOMMANDParams = {
	/**
	 * Odstranit parametry
	 */
	DeleteParameters?: boolean
	/**
	 * Odstranit všechny volné parametry
	 */
	DeleteFreeParameters?: boolean
	/**
	 * Nastavit hodnoty parametrů
	 */
	SetValues?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TBulkGoodsShopValuesCOMMANDParams = {
	/**
	 * Skupina zboží pro int. obchod
	 */
	SetGoodsGroup?: boolean
	/**
	 * GoodsGroup
	 */
	GoodsGroup?: number
	/**
	 * Klíčová slova
	 */
	SetPageKeyWords?: boolean
	/**
	 * PageKeyWord
	 */
	PageKeyWord?: string
	/**
	 * Přidat
	 */
	esKWAdd?: boolean
	/**
	 * Odebrat
	 */
	esKWRemove?: boolean
	/**
	 * Nahradit
	 */
	esKWReplace?: boolean
	/**
	 * Text při nulovém skladu
	 */
	SetEsAvailability?: boolean
	/**
	 * esAvailability
	 */
	esAvailability?: number
	/**
	 * Alternativní text skl. dostupnosti
	 */
	SetEsAltStockText?: boolean
	/**
	 * esAltStockText
	 */
	esAltStockText?: number
	/**
	 * Původní cena
	 */
	SetEsOrigPrice?: boolean
	/**
	 * esOrigPrice
	 */
	esOrigPrice?: number
	/**
	 * Kategorie int. obchodu
	 */
	SetCategories?: boolean
	/**
	 * Hledaná slova
	 */
	SetSearchWords?: boolean
	/**
	 * Související zboží
	 */
	SetRelatedArticle?: boolean
	/**
	 * Kategorie srovnávačů zboží
	 */
	SetCompEngineCategories?: boolean
	/**
	 * Značka
	 */
	SetESBrand?: boolean
	/**
	 * ESBrand
	 */
	ESBrand?: number
	/**
	 * Pro konkrétní int. obchod
	 */
	ShopRID?: number
	/**
	 * Výchozí kategorie
	 */
	SetMainCategory?: boolean
	/**
	 * MainCategory
	 */
	MainCategory?: number
	/**
	 * Text při nulovém skladu
	 */
	SetAvailability?: boolean
	/**
	 * Availability
	 */
	Availability?: number
	/**
	 * Alternativní text skl. dostupnosti
	 */
	SetAltStockText?: boolean
	/**
	 * StockTextRID
	 */
	StockTextRID?: number
	/**
	 * Klíčová slova
	 */
	SetKeyWords?: boolean
	/**
	 * KeyWords
	 */
	KeyWords?: string
	/**
	 * Přidat
	 */
	KWAdd?: boolean
	/**
	 * Odebrat
	 */
	KWRemove?: boolean
	/**
	 * Nahradit
	 */
	KWReplace?: boolean
	/**
	 * Původní cena
	 */
	SetOrigPrice?: boolean
	/**
	 * OrigPrice
	 */
	OrigPrice?: number
	/**
	 * Přiřazení do vyhledávačů
	 */
	SetFeedAssignment?: boolean
	/**
	 * FeedAssignment
	 */
	FeedAssignment?: number
	/**
	 * Skrýt na e-shopu
	 */
	SetHideOnShop?: boolean
	/**
	 * HideOnShop
	 */
	HideOnShop?: boolean
	/**
	 * Neprodejné zboží
	 */
	SetUnableToBuy?: boolean
	/**
	 * UnableToBuy
	 */
	UnableToBuy?: boolean
	/**
	 * Prodej registrovaným
	 */
	SetUnableToBuyNotLogged?: boolean
	/**
	 * UnableToBuyNotLogged
	 */
	UnableToBuyNotLogged?: boolean
	/**
	 * Akce
	 */
	SetAction?: boolean
	/**
	 * Action
	 */
	Action?: boolean
	/**
	 * Sleva
	 */
	SetReduction?: boolean
	/**
	 * Reduction
	 */
	Reduction?: boolean
	/**
	 * Novinka
	 */
	SetNew?: boolean
	/**
	 * New
	 */
	New?: boolean
	/**
	 * Výprodej
	 */
	SetSellOut?: boolean
	/**
	 * SellOut
	 */
	SellOut?: boolean
	/**
	 * Doprava zdarma
	 */
	SetFreeShipping?: boolean
	/**
	 * FreeShipping
	 */
	FreeShipping?: boolean
	/**
	 * Použité zboží
	 */
	SetSecondHand?: boolean
	/**
	 * SecondHand
	 */
	SecondHand?: boolean
	/**
	 * Tip
	 */
	SetTip?: boolean
	/**
	 * Tip
	 */
	Tip?: boolean
	/**
	 * Připravujeme
	 */
	SetPreparing?: boolean
	/**
	 * Preparing
	 */
	Preparing?: boolean
	/**
	 * Pouze poptat
	 */
	SetQuotationRequest?: boolean
	/**
	 * QuotationRequest
	 */
	QuotationRequest?: boolean
	/**
	 * Minimální odběr
	 */
	SetMinPurchaseQuantity?: boolean
	/**
	 * MinPurchaseQuantity
	 */
	MinPurchaseQuantity?: number
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TBulkChangeRateOfVatCOMMANDParams = {
	/**
	 * Nová sazba DPH
	 */
	RateOfVAT?: number
	/**
	 * Datum platnosti od
	 */
	Date?: Date
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TProductRecalculationCOMMANDParams = {
	/**
	 * Přepočíst plánovanou skladovou cenu výrobku
	 */
	ComputeStockPrice?: boolean
	/**
	 * Kalkulační vzorec
	 */
	CModelStock?: number
	/**
	 * Přepočíst hmotnost výrobku
	 */
	ComputeWeight?: boolean
	/**
	 * Kalkulační vzorec
	 */
	CModelWeight?: number
	/**
	 * Přepočíst všechny varianty
	 */
	AllVariants?: boolean
	/**
	 * Procházet všechny úrovně
	 */
	AllLevels?: boolean
	/**
	 * Vytvářet datumové ceny
	 */
	DatePrices?: boolean
	/**
	 * Datum od
	 */
	DateFrom?: Date
	/**
	 * Datum do
	 */
	DateTo?: Date
	/**
	 * Nezapisovat cenu na kartu zboží
	 */
	NotWritePriceToArticle?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TBulkPredictionModelRepeatCalcCOMMANDParams = {
	/**
	 *  
	 */
	FieldDebugMode?: boolean
	/**
	 *  
	 */
	FieldParallelThreads?: number
	/**
	 *  
	 */
	FieldModelSetup?: boolean
	/**
	 *  
	 */
	FieldPrediction?: boolean
	/**
	 *  
	 */
	FieldDate?: Date
	/**
	 *  
	 */
	FieldAllConf?: boolean
	/**
	 *  
	 */
	FieldConfiguration?: string
}

export type TInventoryTurnoverRepeatCalcCOMMANDParams = {
	/**
	 *  
	 */
	FieldDate?: Date
	/**
	 *  
	 */
	FieldRepeat?: boolean
	/**
	 *  
	 */
	FieldRepeatEvery?: number
	/**
	 *  
	 */
	FieldPeriodType?: number
	/**
	 *  
	 */
	FieldLastDayOfMonth?: boolean
	/**
	 *  
	 */
	FieldAllConfigurations?: boolean
	/**
	 *  
	 */
	FieldConfiguration?: string
	/**
	 *  
	 */
	FieldDeleteHistory?: boolean
}

export type TBulkEditArticleWarehouseCOMMANDParams = {
	/**
	 * Operace
	 */
	Operation?: number
	/**
	 *  
	 */
	ActionStock?: number
	/**
	 *  
	 */
	ActionWarehouseZone?: number
	/**
	 *  
	 */
	ActionMonthNo?: number
	/**
	 *  
	 */
	ActionToMonthNo?: number
	/**
	 *  
	 */
	Stock?: number
	/**
	 *  
	 */
	WarehouseZone?: number
	/**
	 *  
	 */
	MonthNo?: number
	/**
	 *  
	 */
	ToMonthNo?: number
	/**
	 *  
	 */
	OptSupplier?: number
	/**
	 *  
	 */
	OptLocation?: number
	/**
	 * Časová jednotka
	 */
	TimeUnit?: number
	/**
	 * Odhad výdejů
	 */
	ReleaseEstimateCalc?: number
	/**
	 * Evidovat
	 */
	EvMinLevelCalc?: boolean
	/**
	 * Minimum
	 */
	MinLevAUCalc?: number
	/**
	 * Evidovat
	 */
	EvMaxLevelCalc?: boolean
	/**
	 * Maximum
	 */
	MaxLevAUCalc?: number
	/**
	 * Sklad
	 */
	SetStock?: boolean
	/**
	 * Zóna skladu
	 */
	SetWarehouseZone?: boolean
	/**
	 * Měsíc od
	 */
	SetMonthNo?: boolean
	/**
	 * Měsíc do
	 */
	SetToMonthNo?: boolean
	/**
	 * Období
	 */
	SetTimeUnit?: boolean
	/**
	 * Výdeje
	 */
	SetReleaseEstimateCalc?: boolean
	/**
	 * Opt.dodavatel
	 */
	SetOptSupplier?: boolean
	/**
	 * Opt.umístění
	 */
	SetOptLocation?: boolean
	/**
	 *  
	 */
	SetEvMinLevelCalc?: boolean
	/**
	 *  
	 */
	SetMinLevAUCalc?: boolean
	/**
	 *  
	 */
	SetEvMaxLevelCalc?: boolean
	/**
	 *  
	 */
	SetMaxLevAUCalc?: boolean
	/**
	 * Zóna skladu
	 */
	SetActionWarehouseZone?: boolean
	/**
	 * Měsíc od
	 */
	SetActionMonthNo?: boolean
	/**
	 * Měsíc do
	 */
	SetActionToMonthNo?: boolean
	/**
	 * Vyhodnocovat jako ležák
	 */
	DeadStockBitCalc?: boolean
	/**
	 * Ležáky - dny min.
	 */
	DeadStockDayMinimum?: number
	/**
	 * Ležáky - dny max.
	 */
	DeadStockDayMaximum?: number
	/**
	 * Změnit
	 */
	SetDeadStockBitCalc?: boolean
	/**
	 * Ležáky - dny min.
	 */
	SetDeadStockDayMinimum?: boolean
	/**
	 * Ležáky - dny max.
	 */
	SetDeadStockDayMaximum?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TBulkGoodsMeasureUnitParamCOMMANDParams = {
	/**
	 * Jednotka
	 */
	MUId?: number
	/**
	 * Zablokovat
	 */
	Blocked?: boolean
	/**
	 * Poměr skl. / alt. j.
	 */
	SetRatioAltJ?: boolean
	/**
	 * RatioAltJ
	 */
	RatioAltJ?: number
	/**
	 * Poměr alt. / skl. j.
	 */
	SetRatioNotAltJ?: boolean
	/**
	 * RatioNotAltJ
	 */
	RatioNotAltJ?: number
	/**
	 * Objem
	 */
	SetCapacity?: boolean
	/**
	 * Capacity
	 */
	Capacity?: number
	/**
	 * Jednotka objemu
	 */
	SetCapacityUnit?: boolean
	/**
	 * Jednotka objemu
	 */
	CapacityUnit?: number
	/**
	 * Plocha
	 */
	SetArea?: boolean
	/**
	 * Area
	 */
	Area?: number
	/**
	 * Jednotka plochy
	 */
	SetAreaUnit?: boolean
	/**
	 * Jednotka plochy
	 */
	AreaUnit?: number
	/**
	 * Celočíselnost
	 */
	SetIntegerAltJ?: boolean
	/**
	 * Celočíselnost
	 */
	IntegerAltJ?: string
	/**
	 * Implicitní pro nákup
	 */
	SetImplicPurchaseAltJ?: boolean
	/**
	 * Implicitní pro nákup
	 */
	ImplicPurchaseAltJ?: string
	/**
	 * Implicitní pro prodej
	 */
	SetImplicSaleAltJ?: boolean
	/**
	 * Implicitní pro prodej
	 */
	ImplicSaleAltJ?: string
	/**
	 * Implicitní pro průvodky
	 */
	SetImplicProduct?: boolean
	/**
	 * Implicitní pro průvodky
	 */
	ImplicProduct?: string
	/**
	 * Implicitní pro převodky
	 */
	SetImplicTran?: boolean
	/**
	 * Implicitní pro převodky
	 */
	ImplicTran?: string
	/**
	 * Implicitní pro inventury
	 */
	SetImplicInv?: boolean
	/**
	 * Implicitní pro inventury
	 */
	ImplicInv?: string
	/**
	 * Implicitní pro TP
	 */
	SetImplicRV?: boolean
	/**
	 * Implicitní pro inventury
	 */
	ImplicRV?: string
	/**
	 * Implicitní pro e-shop
	 */
	SetImplicIShop?: boolean
	/**
	 * Implicitní pro e-shop
	 */
	ImplicIShop?: string
	/**
	 * Zobrazit na e-shopu
	 */
	SetShowOnIShop?: boolean
	/**
	 * Zobrazit na e-shopu
	 */
	ShowOnIShop?: string
	/**
	 * Množstevní pro e-shop
	 */
	SetSaleByIShop?: boolean
	/**
	 * Množstevní pro e-shop
	 */
	SaleByIShop?: string
	/**
	 * Šířka
	 */
	SetSizeA?: boolean
	/**
	 * SizeA
	 */
	SizeA?: number
	/**
	 * Výška
	 */
	SetSizeB?: boolean
	/**
	 * SizeB
	 */
	SizeB?: number
	/**
	 * Hloubka
	 */
	SetSizeC?: boolean
	/**
	 * SizeC
	 */
	SizeC?: number
	/**
	 * Jednotka rozměru
	 */
	SetSizeUnit?: boolean
	/**
	 * Jednotka rozměru
	 */
	SizeUnit?: number
	/**
	 * Hmotnost netto (kg)
	 */
	SetWeightNet?: boolean
	/**
	 * WeightNet
	 */
	WeightNet?: number
	/**
	 * Hmotnost brutto (kg)
	 */
	SetWeightGross?: boolean
	/**
	 * WeightGross
	 */
	WeightGross?: number
	/**
	 * Manipulační technika
	 */
	SetEquipment?: boolean
	/**
	 * Manipulační technika
	 */
	Equipment?: number
	/**
	 * Spočítat objem podle rozměru
	 */
	SetCapacityCalculate?: boolean
	/**
	 * Spočítat plochu podle rozměru
	 */
	SetAreaCalculate?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TBulkChangeConfirmationDateCOMMANDParams = {
	/**
	 * Datum potvrzení
	 */
	Confirmation1Date?: Date
	/**
	 * Hodina potvrzení
	 */
	Confirmation1Time?: Date
	/**
	 * Datum 2. potvrzení
	 */
	Confirmation2Date?: Date
	/**
	 * Hodina 2. potvrzení
	 */
	Confirmation2Time?: Date
	/**
	 * Kontrolovat záporné stavy
	 */
	CheckNegativeState?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TChangeConfirmationDateCOMMANDParams = {
	/**
	 * Datum potvrzení
	 */
	Confirmation1Date?: Date
	/**
	 * Hodina potvrzení
	 */
	Confirmation1Time?: Date
	/**
	 * Datum 2. potvrzení
	 */
	Confirmation2Date?: Date
	/**
	 * Hodina 2. potvrzení
	 */
	Confirmation2Time?: Date
	/**
	 * Kontrolovat záporné stavy
	 */
	CheckNegativeState?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TChangeRecordStatusSWSCOMMANDParams = {
	/**
	 * StatusId
	 */
	StatusId?: number
}

export type TBulkChangeAttributesCOMMANDParams = {
	/**
	 * Smazat atributy
	 */
	DeleteAttribute?: boolean
	/**
	 * Smazat všechny volné atributy
	 */
	DeleteFreeAttribute?: boolean
	/**
	 * Nastavit hodnoty atributů
	 */
	SetValues?: boolean
	/**
	 * Přidat nepřipojené atributy
	 */
	AddFreeAttributes?: boolean
	/**
	 * FreeAttributeEnabled
	 */
	FreeAttributeEnabled?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TBulkActualizeAttributesCOMMANDParams = {
	/**
	 * Aktualizovat včetně samotné sady atributů
	 */
	ActualizeAttributeSet?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}