import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import ShopPaymentStatus from './ShopPaymentStatus'
import ShopOrderStatus from './ShopOrderStatus'
import TTextDM from './TTextDM'
import TContactPersonDM from './TContactPersonDM'
import TEshopDM from './TEshopDM'
import TSalesOrderDM from './TSalesOrderDM'
import TEshopPaymentDM from './TEshopPaymentDM'
import TEshopDeliveryDM from './TEshopDeliveryDM'
import TCountryDM from './TCountryDM'
import TTradingPartnerDM from './TTradingPartnerDM'
import TCurrencyDM from './TCurrencyDM'
import TPartnerDM from './TPartnerDM'
import TEshopOrderItemDM from './TEshopOrderItemDM'

/**
 * Objednávky e-shopu
 */
export default class TEshopOrderDM extends Data {
	public static className = 'TEshopOrderDM'
	public static primaryKey = 'RID'
	public static systemFields = [
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = true

	/**
	 * Nabídka
	 */
	QuotationRID?: number

	/**
	 * ID transakce
	 */
	TransactionId?: string

	/**
	 * Telefon
	 */
	ContactPhone?: string

	/**
	 * E-mail
	 */
	ContactEmail?: string

	/**
	 * Příjmení
	 */
	ContactLastName?: string

	/**
	 * Jméno
	 */
	ContactFirstName?: string

	/**
	 * IPv6 adresa
	 */
	IPv6Address?: string

	/**
	 * IP adresa
	 */
	IPAddress?: string

	/**
	 * Objednáno
	 */
	OrderDate?: Date

	/**
	 * XML data
	 */
	XMLData?: string

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Datum pro "_ver" pole
	 */
	VersionedFieldDateCalc?: Date

	/**
	 * undefined
	 */
	OrderDateCalc?: Date

	/**
	 * undefined
	 */
	OrderTimeCalc?: Date

	/**
	 * undefined
	 */
	XmlReportCalc?: string

	/**
	 * undefined
	 */
	ConMarketingInfoCalc?: string

	/**
	 * undefined
	 */
	DeliveryAddressNameCalc?: string

	/**
	 * undefined
	 */
	DeliveryAddressPlaceCalc?: string

	/**
	 * undefined
	 */
	DeliveryAddressEmailCalc?: string

	/**
	 * undefined
	 */
	DeliveryAddressPhoneCalc?: string

	/**
	 * undefined
	 */
	DeliveryAddressStreetCalc?: string

	/**
	 * undefined
	 */
	DeliveryAddressHouseNumberCalc?: string

	/**
	 * undefined
	 */
	DeliveryAddressTownCalc?: string

	/**
	 * undefined
	 */
	DeliveryAddressPostCodeCalc?: string

	/**
	 * undefined
	 */
	IsInvoiceAddressIdEnteredCalc?: string

	/**
	 * undefined
	 */
	InvoiceAddressIdNameCalc?: string

	/**
	 * undefined
	 */
	InvoiceAddressIdNumberCalc?: string

	/**
	 * undefined
	 */
	InvoiceAddressIdVatNumberCalc?: string

	/**
	 * undefined
	 */
	InvoiceAddressIdStreetCalc?: string

	/**
	 * undefined
	 */
	InvoiceAddressIdrNumberCalc?: string

	/**
	 * undefined
	 */
	InvoiceAddressIdTownCalc?: string

	/**
	 * undefined
	 */
	InvoiceAddressIdPostCodeCalc?: string

	/**
	 * undefined
	 */
	AdditionalInformation2Calc?: string

	/**
	 * undefined
	 */
	EshopBasketRIDCalc?: number

	/**
	 * undefined
	 */
	AdditionalInformationCalc?: string

	/**
	 * undefined
	 */
	OrderNumberCalc?: string

	/**
	 * undefined
	 */
	AllowSendByPartCalc?: string

	/**
	 * undefined
	 */
	PaidAmountCalc?: number

	/**
	 * undefined
	 */
	InvoiceAddressIdTaxNumberCalc?: string

	/**
	 * undefined
	 */
	DeliveryTermCalc?: Date

	/**
	 * undefined
	 */
	ReportHTMLCalc?: string

	/**
	 * Stav platby
	 */
	@Type(() => ShopPaymentStatus)
	PaymentStatusId?: ShopPaymentStatus

	/**
	 * Stav
	 */
	@Type(() => ShopOrderStatus)
	OrderStatusId?: ShopOrderStatus

	/**
	 * Jazyk
	 */
	@Type(() => TTextDM)
	LanguageId?: TTextDM

	/**
	 * Kontaktní osoba
	 */
	@Type(() => TContactPersonDM)
	ContactPersonId?: TContactPersonDM

	/**
	 * E-shop
	 */
	@Type(() => TEshopDM)
	ShopRID?: TEshopDM

	/**
	 * Zakázka
	 */
	@Type(() => TSalesOrderDM)
	SalesOrderRID?: TSalesOrderDM

	/**
	 * undefined
	 */
	@Type(() => TEshopPaymentDM)
	PaymentMethodRIDCalc?: TEshopPaymentDM

	/**
	 * undefined
	 */
	@Type(() => TEshopDeliveryDM)
	TransportMethodRIDCalc?: TEshopDeliveryDM

	/**
	 * undefined
	 */
	@Type(() => TCountryDM)
	DeliveryAddressCountryIdCalc?: TCountryDM

	/**
	 * undefined
	 */
	@Type(() => TCountryDM)
	InvoiceAddressIdCountryIdCalc?: TCountryDM

	/**
	 * undefined
	 */
	@Type(() => TTradingPartnerDM)
	DeliveryTradingPartnerIdCalc?: TTradingPartnerDM

	/**
	 * undefined
	 */
	@Type(() => TCurrencyDM)
	CurrencyCalc?: TCurrencyDM

	/**
	 * undefined
	 */
	@Type(() => TPartnerDM)
	PartnerIdCalc?: TPartnerDM

	/**
	 * undefined
	 */
	@Type(() => TTradingPartnerDM)
	TradingPartnerIdCalc?: TTradingPartnerDM

	/**
	 * undefined
	 */
	@Type(() => TEshopOrderItemDM)
	EshopBasketItemChild?: TEshopOrderItemDM[]

	/**
	 * Změna skupiny práv
	 */
	public ChangeGroupRightCOMMAND() {
		return this.executeCommand('ChangeGroupRightCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public BulkChangeGroupRightCOMMAND() {
		return this.executeCommand('BulkChangeGroupRightCOMMAND')
	}

	/**
	 * Aktualizace počítaných polí
	 */
	public BulkUpdateCalcFieldsCOMMAND() {
		return this.executeCommand('BulkUpdateCalcFieldsCOMMAND')
	}

	/**
	 * Kontrola legislativy
	 */
	public SwitchOffLegislationValidationCOMMAND() {
		return this.executeCommand('SwitchOffLegislationValidationCOMMAND')
	}
}