import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TK2UserLookupDM from './TK2UserLookupDM'
import TPrPeriodDM from './TPrPeriodDM'

/**
 * Minimální mzdové tarify
 */
export default class TPrMinimalTariffDM extends Data {
	public static className = 'TPrMinimalTariffDM'
	public static primaryKey = 'Id'
	public static systemFields = [
	]

	public getPrimaryKey(): PrimaryKey {
		return this.Id!
	}

	public static isHeader = true

	/**
	 * Firma
	 */
	CompanyId?: number

	/**
	 * Hodnota D
	 */
	ValueD?: string

	/**
	 * Hodnota C
	 */
	ValueC?: string

	/**
	 * Hodnota B
	 */
	ValueB?: string

	/**
	 * Hodnota A
	 */
	ValueA?: string

	/**
	 * Datum změny
	 */
	DChangedOn?: Date

	/**
	 * Datum změny
	 */
	CChangedOn?: Date

	/**
	 * Datum změny
	 */
	BChangedOn?: Date

	/**
	 * Datum změny
	 */
	AChangedOn?: Date

	/**
	 * Popis
	 */
	Description?: string

	/**
	 * Zkratka
	 */
	Abbr?: string

	/**
	 * Číslo
	 */
	Id?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * SQLSequenceOrderCalc
	 */
	SQLSequenceOrderCalc?: number

	/**
	 * Hodnota konstanty
	 */
	ConstantValueBooleanACalc?: number

	/**
	 * P
	 */
	ConstantValueDateACalc?: Date

	/**
	 * Hodinový tarif
	 */
	ConstantValueCurrencyACalc?: number

	/**
	 * Hodnota konstanty
	 */
	ConstantValueIntegerACalc?: number

	/**
	 * Hodnota konstanty
	 */
	ConstantValueRealACalc?: number

	/**
	 * Hodnota konstanty
	 */
	ConstantValueTextACalc?: string

	/**
	 * Čas
	 */
	ConstantValueTimeACalc?: Date

	/**
	 * Srážky z mezd
	 */
	ConstantValueBankDetailACalc?: number

	/**
	 * Způsob srážky
	 */
	ConstantValueComponentACalc?: number

	/**
	 * Hodnota konstanty
	 */
	ConstantValueBooleanBCalc?: number

	/**
	 * Datum
	 */
	ConstantValueDateBCalc?: Date

	/**
	 * Měsíční tarif
	 */
	ConstantValueCurrencyBCalc?: number

	/**
	 * Hodnota konstanty
	 */
	ConstantValueIntegerBCalc?: number

	/**
	 * Hodnota konstanty
	 */
	ConstantValueRealBCalc?: number

	/**
	 * Hodnota konstanty
	 */
	ConstantValueTextBCalc?: string

	/**
	 * Čas
	 */
	ConstantValueTimeBCalc?: Date

	/**
	 * Srážky z mezd
	 */
	ConstantValueBankDetailBCalc?: number

	/**
	 * Způsob srážky
	 */
	ConstantValueComponentBCalc?: number

	/**
	 * Hodnota konstanty
	 */
	ConstantValueBooleanCCalc?: number

	/**
	 * Datum
	 */
	ConstantValueDateCCalc?: Date

	/**
	 * Sazba
	 */
	ConstantValueCurrencyCCalc?: number

	/**
	 * Hodnota konstanty
	 */
	ConstantValueIntegerCCalc?: number

	/**
	 * Hodnota konstanty
	 */
	ConstantValueRealCCalc?: number

	/**
	 * Hodnota konstanty
	 */
	ConstantValueTextCCalc?: string

	/**
	 * Čas
	 */
	ConstantValueTimeCCalc?: Date

	/**
	 * Srážky z mezd
	 */
	ConstantValueBankDetailCCalc?: number

	/**
	 * Způsob srážky
	 */
	ConstantValueComponentCCalc?: number

	/**
	 * Hodnota konstanty
	 */
	ConstantValueBooleanDCalc?: number

	/**
	 * Datum
	 */
	ConstantValueDateDCalc?: Date

	/**
	 * Maximum
	 */
	ConstantValueCurrencyDCalc?: number

	/**
	 * Hodnota konstanty
	 */
	ConstantValueIntegerDCalc?: number

	/**
	 * Hodnota konstanty
	 */
	ConstantValueRealDCalc?: number

	/**
	 * Hodnota konstanty
	 */
	ConstantValueTextDCalc?: string

	/**
	 * Čas
	 */
	ConstantValueTimeDCalc?: Date

	/**
	 * Srážky z mezd
	 */
	ConstantValueBankDetailDCalc?: number

	/**
	 * Způsob srážky
	 */
	ConstantValueComponentDCalc?: number

	/**
	 * undefined
	 */
	WarningCalc?: string

	/**
	 * Změnil
	 */
	@Type(() => TK2UserLookupDM)
	DChangedById?: TK2UserLookupDM

	/**
	 * Změnil
	 */
	@Type(() => TK2UserLookupDM)
	CChangedById?: TK2UserLookupDM

	/**
	 * Změnil
	 */
	@Type(() => TK2UserLookupDM)
	BChangedById?: TK2UserLookupDM

	/**
	 * Změnil
	 */
	@Type(() => TK2UserLookupDM)
	AChangedById?: TK2UserLookupDM

	/**
	 * Nastavené období
	 */
	@Type(() => TPrPeriodDM)
	PeriodIdCalc?: TPrPeriodDM

	/**
	 * Zpracované období
	 */
	@Type(() => TPrPeriodDM)
	ProcessedPeriodIdCalc?: TPrPeriodDM

	/**
	 * Nastavené období
	 */
	@Type(() => TPrPeriodDM)
	UserPeriodIdCalc?: TPrPeriodDM

	/**
	 * Období zpracování mezd
	 */
	public PrPeriodChangeCOMMAND(parameters: TPrPeriodChangeCOMMANDParams) {
		return this.executeCommand('PrPeriodChangeCOMMAND', parameters)
	}

	/**
	 * Změna skupiny práv
	 */
	public ChangeGroupRightCOMMAND() {
		return this.executeCommand('ChangeGroupRightCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public BulkChangeGroupRightCOMMAND() {
		return this.executeCommand('BulkChangeGroupRightCOMMAND')
	}

	/**
	 * Aktualizace počítaných polí
	 */
	public BulkUpdateCalcFieldsCOMMAND() {
		return this.executeCommand('BulkUpdateCalcFieldsCOMMAND')
	}

	/**
	 * Kontrola legislativy
	 */
	public SwitchOffLegislationValidationCOMMAND() {
		return this.executeCommand('SwitchOffLegislationValidationCOMMAND')
	}
}

export type TPrPeriodChangeCOMMANDParams = {
	/**
	 * Nastavené období
	 */
	IdPeriod?: number
	/**
	 * MessageText
	 */
	MessageText?: string
}