import { useEffect, useState } from 'react'
import { Container, Spinner } from 'react-bootstrap'
import { Order, OrderProps } from 'components/Order'
import TSpecialScanAndGoSettings from 'react-k2-api/Data/TSpecialScanAndGoSettings'
import { useErrorHandler } from 'react-error-boundary'

export const App = () => {
  const [settings, setSettings] = useState<TSpecialScanAndGoSettings>()
  const handleError = useErrorHandler()
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const RID = urlParams.get('RID')
  if (!RID) {
    throw new Error(`Není zadaný URL parametr [RID].\nPříklad: ${window.location.href}?RID=1549738245164`)
  }

  useEffect(() => {
    TSpecialScanAndGoSettings.get(RID, [
      'SalesOrderStatusFreeId.Id',
      'SalesOrderStatusOpenId.Id',
      'SalesOrderStatusPendingId.Id',
      'OrderFormId.Abbr',
      'TradingPartnerId.Id',
      'SalesBookId.Id',
      'PlannedWarehouseId.Id',
      'PrinterGroupRID.RID'
    ]).then((scanAndGoSettings: TSpecialScanAndGoSettings) => {
      setSettings(scanAndGoSettings)
    }).catch(handleError)
  }, [])

  if (settings) {
    const orderProps: OrderProps = {
      statusId: {
        free: settings.SalesOrderStatusFreeId!.Id!,
        open: settings.SalesOrderStatusOpenId!.Id!,
        pending: settings.SalesOrderStatusPendingId!.Id!
      },
      orderForm: settings.OrderFormId!.Abbr!,
      tradingPartnerId: settings.TradingPartnerId!.Id!,
      bookId: settings.SalesBookId!.Id!,
      plannedWarehouseId: settings.PlannedWarehouseId!.Id!,
      settings: settings
    }

    return (
      <Container fluid className="p-0 mb-5">
        <Order
          {...orderProps}
        />
      </Container>
    )
  } else {
    return (
      <Container fluid className="mt-5 text-center">
        <Spinner animation="border" variant="primary" />
        <p>Načítání...</p>
      </Container>
    )
  }
}

export default App