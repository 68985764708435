import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TCode6DocumentDM from './TCode6DocumentDM'
import TCode5DocumentDM from './TCode5DocumentDM'
import TCode4DocumentDM from './TCode4DocumentDM'
import TCode3DocumentDM from './TCode3DocumentDM'
import TDeviceDocumentDM from './TDeviceDocumentDM'
import TAddressDM from './TAddressDM'
import TAccountingChartDM from './TAccountingChartDM'
import TMatchingSymbolDM from './TMatchingSymbolDM'
import TOfficerDocumentDM from './TOfficerDocumentDM'
import TCode2DocumentDM from './TCode2DocumentDM'
import TCode1DocumentDM from './TCode1DocumentDM'
import TArticleCodeDocumentDM from './TArticleCodeDocumentDM'
import TContractCodeDocumentDM from './TContractCodeDocumentDM'
import TCostCentreCollectionDocumentDM from './TCostCentreCollectionDocumentDM'
import TVATRateDM from './TVATRateDM'
import TTaxTypeDM from './TTaxTypeDM'
import TCurrencyDM from './TCurrencyDM'
import TTradingPartnerDM from './TTradingPartnerDM'
import TTextDM from './TTextDM'
import TTownDM from './TTownDM'
import TTownPartDM from './TTownPartDM'
import TCountryDM from './TCountryDM'
import TInvoiceInDM from './TInvoiceInDM'
import TInvoiceOutDM from './TInvoiceOutDM'
import TAdvanceProvidedDM from './TAdvanceProvidedDM'
import TAdvanceReceivedDM from './TAdvanceReceivedDM'
import TPrLiabilityDM from './TPrLiabilityDM'
import TCommentDM from './TCommentDM'
import TItemNoteDM from './TItemNoteDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TAssetLinkDM from './TAssetLinkDM'
import TAssetConnectedDocumentDM from './TAssetConnectedDocumentDM'

/**
 * Položky pro zaúčtování
 */
export default class TAccountingFinanceCashItemDM extends Data {
	public static className = 'TAccountingFinanceCashItemDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = false

	/**
	 * Externí reference
	 */
	ExternalReference?: string

	/**
	 * Kniha
	 */
	BookId?: number

	/**
	 * Číslo PD
	 */
	PaidDocumentRID?: number

	/**
	 * Kód banky
	 */
	BankId?: number

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * Číslo dokladu
	 */
	HeaderRID?: number

	/**
	 * Doplň. informace
	 */
	AdditionalInfo?: string

	/**
	 * PLK
	 */
	PaymentCalendarId?: number

	/**
	 * Právo na záznam
	 */
	RightGroupId?: number

	/**
	 * Kontace
	 */
	PostingKeyId?: number

	/**
	 * K. symbol
	 */
	ConstantSymbolId?: string

	/**
	 * Bank. příkaz
	 */
	BankOrderItemId?: number

	/**
	 * Sumace
	 */
	RowId?: number

	/**
	 * Zaplaceno
	 */
	AmountPaid?: number

	/**
	 * Zaplaceno M
	 */
	AmountPaidC?: number

	/**
	 * DPH
	 */
	AmountVAT?: number

	/**
	 * DPH M
	 */
	AmountVatC?: number

	/**
	 * &NettoC
	 */
	AmountNet?: number

	/**
	 * &NettoC
	 */
	AmountNetC?: number

	/**
	 * Kurz z PD
	 */
	PaidDocumentExchangeRate?: number

	/**
	 * Kurz PD
	 */
	PaidItemExchangeRate?: number

	/**
	 * Kurz
	 */
	ExchangeRate?: number

	/**
	 * Datum UZP
	 */
	TaxPointDate?: Date

	/**
	 * Datum úč. příp.
	 */
	AccountingTransactionDate?: Date

	/**
	 * &Variabilní symbol
	 */
	ReferenceNumber?: string

	/**
	 * Protiúčet
	 */
	Description2?: string

	/**
	 * Popis
	 */
	Description?: string

	/**
	 * Číslo karty
	 */
	PaidDocumentCardId?: number

	/**
	 * Číslo PD
	 */
	PaidDocumentIdOld?: number

	/**
	 * Kniha PD
	 */
	PaidDocumentBookOld?: string

	/**
	 * Typ PD
	 */
	PaidDocumentTypeId?: number

	/**
	 * Pořadí
	 */
	SequenceNumber?: number

	/**
	 * Číslo položky
	 */
	ItemId?: number

	/**
	 * Typ dokladu
	 */
	HeaderTypeId?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * Souřadnice (Adresa)
	 */
	AddressIdCoordinates?: string

	/**
	 * PSČ (Adresa)
	 */
	AddressIdTownCode?: string

	/**
	 * undefined
	 */
	AddressIdChanged?: number

	/**
	 * Název (Adresa)
	 */
	AddressIdName?: string

	/**
	 * Číslo popisné (Adresa)
	 */
	AddressIdHouseNumber?: string

	/**
	 * Číslo orientační (Adresa)
	 */
	AddressIdOrientationNumber?: string

	/**
	 * Ulice (Adresa)
	 */
	AddressIdStreet?: string

	/**
	 * undefined
	 */
	OldAmountPaid?: number

	/**
	 * undefined
	 */
	OldPaidDocumentRID?: number

	/**
	 * undefined
	 */
	OldBankOrderItemRID?: number

	/**
	 * undefined
	 */
	OldPaidDocumentTypeId?: number

	/**
	 * undefined
	 */
	OldAmountPaidC?: number

	/**
	 * undefined
	 */
	OldDescription?: string

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Datum úč.p.
	 */
	AccountingTransactionDateCalc?: Date

	/**
	 * Čas
	 */
	AccountingTransactionTimeCalc?: Date

	/**
	 * Var. symbol PF
	 */
	ReferenceNumberInvoiceInCalc?: string

	/**
	 * Var. symbol VF
	 */
	ReferenceNumberInvoiceOutCalc?: string

	/**
	 * Var. symbol VZ
	 */
	ReferenceNumberAdvProvidedCalc?: string

	/**
	 * Var. symbol PZ
	 */
	ReferenceNumberAdvReceivedCalc?: string

	/**
	 * Faktura/Záloha
	 */
	PaidDocumentNameCalc?: string

	/**
	 * Kurz platby
	 */
	ExchangeRateCalc?: number

	/**
	 * Kurz placeného dokladu
	 */
	DocumentExchangeRateCalc?: number

	/**
	 * Kurz platby v měně placeného dokladu
	 */
	PaidDocumentExchangeRateCalc?: number

	/**
	 * Název
	 */
	PaymentDocumentDescriptionCalc?: string

	/**
	 * Název
	 */
	DescriptionCalc?: string

	/**
	 * Datum a čas
	 */
	CompanyDateCalc?: Date

	/**
	 * Var. symbol MZ
	 */
	ReferenceNumberPayrollCalc?: string

	/**
	 * Datum úč. případu placeného dokladu
	 */
	PaidDocAccTransactionDateCalc?: Date

	/**
	 * Brutto placeného dokladu M
	 */
	PaidDocumentAmountGrossCCalc?: number

	/**
	 * Brutto placeného dokladu
	 */
	PaidDocumentAmountGrossCalc?: number

	/**
	 * Zbývá zaplatit M
	 */
	PaidDocumentRemainingAmountCCalc?: number

	/**
	 * Zbývá zaplatit
	 */
	PaidDocRemainingAmountCalc?: number

	/**
	 * Rozdíl platby
	 */
	PaidDocumentDifferenceCalc?: string

	/**
	 * Stav zap.
	 */
	PaymentStatusIdCalc?: number

	/**
	 * Zbývá doplatit
	 */
	RemainingAmountCCalc?: number

	/**
	 * Zbývá doplatit
	 */
	RemainingAmountCalc?: number

	/**
	 * Částka placeného dokladu
	 */
	OriginalPaidDocAmountGrossCCalc?: number

	/**
	 * Číslo OP
	 */
	OtherReceivableRIDCalc?: number

	/**
	 * Číslo OV
	 */
	OtherLiabilityRIDCalc?: number

	/**
	 * Var.symbol OP
	 */
	ReferenceNumberOtherReceivableCalc?: string

	/**
	 * Var.symbol OV
	 */
	ReferenceNumberOtherLiabilityCalc?: string

	/**
	 * DIČ
	 */
	VATRegNumberCalc?: string

	/**
	 * Stornováno
	 */
	CanceledRecordBitCalc?: number

	/**
	 * Rozpouštěno
	 */
	AllocationDoneIdCalc?: number

	/**
	 * Přecenění banky
	 */
	RevaluationBitCalc?: number

	/**
	 * Potvrzeno
	 */
	ConfirmedBitCalc?: number

	/**
	 * Skonto
	 */
	CashDiscountIdCalc?: number

	/**
	 * DP&H
	 */
	VATIdCalc?: number

	/**
	 * Editovat DPH
	 */
	NotRoundBitCalc?: number

	/**
	 * Ručně změněno
	 */
	ManuallyChangedBitCalc?: number

	/**
	 * Kurzový doklad
	 */
	ExchangeDocumentIdCalc?: number

	/**
	 * PaymentStatusPctCalc
	 */
	PaymentStatusPctCalc?: number

	/**
	 * Doklady vs. Příjem/Výdej
	 */
	ShowDocumentPanelCalc?: string

	/**
	 * Je měna firmy
	 */
	IsCompanyCurrencyCalc?: string

	/**
	 * Je přeceněno
	 */
	IsRevaluatedCalc?: string

	/**
	 * Adresa
	 */
	ShowAddressCalc?: string

	/**
	 * SCB
	 */
	BankCodeNumberCalc?: string

	/**
	 * Koeficient DPH
	 */
	VATRateCalc?: number

	/**
	 * Zkratka karty ID
	 */
	IdCardAbbrCalc?: string

	/**
	 * Brutto M
	 */
	AmountGrossCCalc?: number

	/**
	 * Brutto
	 */
	AmountGrossCalc?: number

	/**
	 * Rozdíl
	 */
	DifferenceCalc?: number

	/**
	 * Koef. DPH shora
	 */
	VATFromAboveCoefficientCalc?: number

	/**
	 * Rozdíl vstup/výstup
	 */
	InOutDifferenceCalc?: number

	/**
	 * Kurzový rozdíl
	 */
	ExchangeRateDifferenceCalc?: number

	/**
	 * Příjem M
	 */
	CreditAmountCCalc?: number

	/**
	 * Příjem
	 */
	CreditAmountCalc?: number

	/**
	 * Výdej M
	 */
	DebitAmountCCalc?: number

	/**
	 * Výdej
	 */
	DebitAmountCalc?: number

	/**
	 * Právo na záznam
	 */
	RightGroupIdCalc?: number

	/**
	 * Kód 6
	 */
	@Type(() => TCode6DocumentDM)
	Code6Id?: TCode6DocumentDM

	/**
	 * Kód 5
	 */
	@Type(() => TCode5DocumentDM)
	Code5Id?: TCode5DocumentDM

	/**
	 * Kód 4
	 */
	@Type(() => TCode4DocumentDM)
	Code4Id?: TCode4DocumentDM

	/**
	 * Kód 3
	 */
	@Type(() => TCode3DocumentDM)
	Code3Id?: TCode3DocumentDM

	/**
	 * Prostředek
	 */
	@Type(() => TDeviceDocumentDM)
	DeviceRID?: TDeviceDocumentDM

	/**
	 * Adresa
	 */
	@Type(() => TAddressDM)
	AddressId?: TAddressDM

	/**
	 * Učet 2
	 */
	@Type(() => TAccountingChartDM)
	Account2Id?: TAccountingChartDM

	/**
	 * Učet 1
	 */
	@Type(() => TAccountingChartDM)
	Account1Id?: TAccountingChartDM

	/**
	 * Párovací symbol
	 */
	@Type(() => TMatchingSymbolDM)
	MatchingSymbolId?: TMatchingSymbolDM

	/**
	 * Referent
	 */
	@Type(() => TOfficerDocumentDM)
	OfficerId?: TOfficerDocumentDM

	/**
	 * Kód 2
	 */
	@Type(() => TCode2DocumentDM)
	Code2Id?: TCode2DocumentDM

	/**
	 * Kód 1
	 */
	@Type(() => TCode1DocumentDM)
	Code1Id?: TCode1DocumentDM

	/**
	 * Kód zboží
	 */
	@Type(() => TArticleCodeDocumentDM)
	ArticleCodeId?: TArticleCodeDocumentDM

	/**
	 * Zakázka
	 */
	@Type(() => TContractCodeDocumentDM)
	ContractCodeRID?: TContractCodeDocumentDM

	/**
	 * Středisko
	 */
	@Type(() => TCostCentreCollectionDocumentDM)
	CostCentreId?: TCostCentreCollectionDocumentDM

	/**
	 * Sazba daně
	 */
	@Type(() => TVATRateDM)
	VATRateId?: TVATRateDM

	/**
	 * Typ daně
	 */
	@Type(() => TTaxTypeDM)
	TaxTypeId?: TTaxTypeDM

	/**
	 * Měna PD
	 */
	@Type(() => TCurrencyDM)
	PaidDocumentCurrency?: TCurrencyDM

	/**
	 * Měna
	 */
	@Type(() => TCurrencyDM)
	Currency?: TCurrencyDM

	/**
	 * Dod/Odb
	 */
	@Type(() => TTradingPartnerDM)
	TradingPartnerId?: TTradingPartnerDM

	/**
	 * Typ p.
	 */
	@Type(() => TTextDM)
	ItemTypeId?: TTextDM

	/**
	 * Číslo (Adresa)
	 */
	@Type(() => TTownDM)
	AddressIdTownId?: TTownDM

	/**
	 * Část obce (Adresa)
	 */
	@Type(() => TTownPartDM)
	AddressIdTownPartId?: TTownPartDM

	/**
	 * Stát (Adresa)
	 */
	@Type(() => TCountryDM)
	AddressIdCountryId?: TCountryDM

	/**
	 * Měna mandanta
	 */
	@Type(() => TCurrencyDM)
	CurrencyCalc?: TCurrencyDM

	/**
	 * ID Dod./Odb. placeného dokladu
	 */
	@Type(() => TTradingPartnerDM)
	PaidDocumentTradingPartnerIdCalc?: TTradingPartnerDM

	/**
	 * Číslo PF
	 */
	@Type(() => TInvoiceInDM)
	InvoiceInRIDCalc?: TInvoiceInDM

	/**
	 * Číslo VF
	 */
	@Type(() => TInvoiceOutDM)
	InvoiceOutRIDCalc?: TInvoiceOutDM

	/**
	 * Číslo VZ
	 */
	@Type(() => TAdvanceProvidedDM)
	AdvanceProvidedRIDCalc?: TAdvanceProvidedDM

	/**
	 * Číslo PZ
	 */
	@Type(() => TAdvanceReceivedDM)
	AdvanceReceivedRIDCalc?: TAdvanceReceivedDM

	/**
	 * Číslo MZ
	 */
	@Type(() => TPrLiabilityDM)
	PayrollDocumentRIDCalc?: TPrLiabilityDM

	/**
	 * Účet DPH
	 */
	@Type(() => TAccountingChartDM)
	VATAccountIdCalc?: TAccountingChartDM

	/**
	 * Firma
	 */
	@Type(() => TTradingPartnerDM)
	TradingPartnerVATDateIdCalc?: TTradingPartnerDM

	/**
	 * Účet MD
	 */
	@Type(() => TAccountingChartDM)
	DebitAccountIdCalc?: TAccountingChartDM

	/**
	 * Komentáře
	 */
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]

	/**
	 * undefined
	 */
	@Type(() => TItemNoteDM)
	ItemNotesChild?: TItemNoteDM[]

	/**
	 * undefined
	 */
	@Type(() => TLanguageMemoTranslationDM)
	ItemTextChild?: TLanguageMemoTranslationDM[]

	/**
	 * undefined
	 */
	@Type(() => TAssetLinkDM)
	AssetLinkChild?: TAssetLinkDM[]

	/**
	 * undefined
	 */
	@Type(() => TAssetConnectedDocumentDM)
	AssetConnectedDocumentChild?: TAssetConnectedDocumentDM[]
}