import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TMeasureUnitDM from './TMeasureUnitDM'
import TArticleDM from './TArticleDM'

/**
 * Dárky
 */
export default class TPromotionGiftDM extends Data {
	public static className = 'TPromotionGiftDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = false

	/**
	 * Pořadí
	 */
	SequenceNumber?: number

	/**
	 * Hodnota
	 */
	Value?: number

	/**
	 * Množství
	 */
	Quantity?: number

	/**
	 * Promoakce
	 */
	PromotionRID?: number

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Množství
	 */
	QuantityUMCalc?: number

	/**
	 * Alternativní jednotka
	 */
	@Type(() => TMeasureUnitDM)
	MeasureUnitId?: TMeasureUnitDM

	/**
	 * Zboží
	 */
	@Type(() => TArticleDM)
	ArticleId?: TArticleDM
}