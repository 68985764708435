import Data from 'react-k2-api/Api/Data'
import { Type } from 'class-transformer'
import TPartnerDM from './TPartnerDM'
import TContactPersonDM from './TContactPersonDM'

/**
 * Find by phone
 */
export default class TFindByPhoneDM extends Data {
	public static className = 'TFindByPhoneDM'
	public static systemFields = [
		'ItemRIDCalc',
	]

	public static isHeader = false

	/**
	 * undefined
	 */
	RecordStateImgCalc?: string

	/**
	 * ItemRIDCalc
	 */
	ItemRIDCalc?: number

	/**
	 * RecordModeCalc
	 */
	RecordModeCalc?: number

	/**
	 * Partner
	 */
	@Type(() => TPartnerDM)
	PartnerId?: TPartnerDM

	/**
	 * Contact person
	 */
	@Type(() => TContactPersonDM)
	ContactPersonId?: TContactPersonDM
}