import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import AlternativeUnit from './AlternativeUnit'
import TTextDM from './TTextDM'
import TProductKeyDM from './TProductKeyDM'
import TArticleDM from './TArticleDM'

/**
 * Sortiment
 */
export default class TPromotionRangeDM extends Data {
	public static className = 'TPromotionRangeDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = false

	/**
	 * Pořadí
	 */
	SequenceNumber?: number

	/**
	 * Množství
	 */
	Quantity?: number

	/**
	 * Promoakce
	 */
	PromotionRID?: number

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Jednotka zb.
	 */
	QuantityMeasureUnitCalc?: string

	/**
	 * Jiná jednotka
	 */
	@Type(() => AlternativeUnit)
	MeasureUnitId?: AlternativeUnit

	/**
	 * Typ jednotky
	 */
	@Type(() => TTextDM)
	MeasureUnitTypeId?: TTextDM

	/**
	 * Produktový klíč
	 */
	@Type(() => TProductKeyDM)
	ProductGroupRID?: TProductKeyDM

	/**
	 * Zboží
	 */
	@Type(() => TArticleDM)
	ArticleId?: TArticleDM
}