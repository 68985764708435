import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TCode6DocumentDM from './TCode6DocumentDM'
import TCode5DocumentDM from './TCode5DocumentDM'
import TCode4DocumentDM from './TCode4DocumentDM'
import TCode3DocumentDM from './TCode3DocumentDM'
import TSalesActionDM from './TSalesActionDM'
import TPriceListDM from './TPriceListDM'
import TCostCentreCollectionDocumentDM from './TCostCentreCollectionDocumentDM'
import TDeviceDocumentDM from './TDeviceDocumentDM'
import ShopGroupId from './ShopGroupId'
import PartnerStatus from './PartnerStatus'
import TContractCodeDocumentDM from './TContractCodeDocumentDM'
import TAddressDM from './TAddressDM'
import TCode2DocumentDM from './TCode2DocumentDM'
import TCode1DocumentDM from './TCode1DocumentDM'
import TOfficerDocumentDM from './TOfficerDocumentDM'
import UserCustomValue from './UserCustomValue'
import TContactPersonDM from './TContactPersonDM'
import UserCodeType6 from './UserCodeType6'
import UserCodeType5 from './UserCodeType5'
import UserCodeType4 from './UserCodeType4'
import UserCodeType3 from './UserCodeType3'
import UserCodeType2 from './UserCodeType2'
import UserCodeType1 from './UserCodeType1'
import TK2UserLookupDM from './TK2UserLookupDM'
import Circuit from './Circuit'
import Care from './Care'
import Solvency from './Solvency'
import PartnerOrigin from './PartnerOrigin'
import FirmType from './FirmType'
import SatisfactionName from './SatisfactionName'
import AcquisitionName from './AcquisitionName'
import PriorityName from './PriorityName'
import PartnerClass from './PartnerClass'
import Domain from './Domain'
import AreaCode from './AreaCode'
import TPaymentMethodDM from './TPaymentMethodDM'
import TPriceGroupDM from './TPriceGroupDM'
import Code from './Code'
import CustomerGroup from './CustomerGroup'
import TTradingPartnerDM from './TTradingPartnerDM'
import TTextDM from './TTextDM'
import TElectronicAddressTypeDM from './TElectronicAddressTypeDM'
import TCountryDM from './TCountryDM'
import TMarketingActivityTypeDM from './TMarketingActivityTypeDM'
import TWorkflowProcessDM from './TWorkflowProcessDM'
import TActivityDM from './TActivityDM'
import TTownPartDM from './TTownPartDM'
import TTownDM from './TTownDM'
import TBookDM from './TBookDM'
import TCurrencyDM from './TCurrencyDM'
import TLinkDM from './TLinkDM'
import TCommentDM from './TCommentDM'
import TJobDM from './TJobDM'
import TPartnerBusinessLineDM from './TPartnerBusinessLineDM'
import TOpportunityDM from './TOpportunityDM'
import TQuotationDM from './TQuotationDM'
import TRequestForQuotationDM from './TRequestForQuotationDM'
import TCallHistoryDM from './TCallHistoryDM'
import TPaymentConditionTradingPartnerPurchaseDM from './TPaymentConditionTradingPartnerPurchaseDM'
import TPaymentConditionTradingPartnerSaleDM from './TPaymentConditionTradingPartnerSaleDM'
import TOpenItemListDM from './TOpenItemListDM'
import TElectronicAddressDM from './TElectronicAddressDM'
import TBankAccountTradingPartnerDM from './TBankAccountTradingPartnerDM'
import TRelatedAddressDM from './TRelatedAddressDM'
import TAdressKeyItemDM from './TAdressKeyItemDM'
import TPartnerContactPersonDM from './TPartnerContactPersonDM'
import TDepartmentElectronicAddressDM from './TDepartmentElectronicAddressDM'
import TMarketingSubsumptionDM from './TMarketingSubsumptionDM'
import TBranchDM from './TBranchDM'
import TPartnerJournalDM from './TPartnerJournalDM'
import TActivityTreeGroupedDM from './TActivityTreeGroupedDM'
import TExternalDocumentItemDM from './TExternalDocumentItemDM'
import TWorkflowProcessAsItemDM from './TWorkflowProcessAsItemDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TCompanyLinkPartnerDM from './TCompanyLinkPartnerDM'
import TAttachedDocumentDM from './TAttachedDocumentDM'
import TOldNoteDM from './TOldNoteDM'

/**
 * Partneři
 */
export default class TPartnerDM extends Data {
	public static className = 'TPartnerDM'
	public static primaryKey = 'Id'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.Id!
	}

	public static isHeader = true

	/**
	 * Datová schránka
	 */
	DataBoxId?: string

	/**
	 * Daňové číslo
	 */
	TaxNumber?: string

	/**
	 * VIES konzultace
	 */
	RatingVIESKey?: string

	/**
	 * VIES datum
	 */
	RatingViesDate?: Date

	/**
	 * VIES hodnocení
	 */
	RatingViesValue?: number

	/**
	 * ISIR datum
	 */
	RatingIsirDate?: Date

	/**
	 * ISIR hodnocení
	 */
	RatingIsirValue?: string

	/**
	 * Časové razítko
	 */
	TimeStamp?: string

	/**
	 * Octopus datum
	 */
	RatingOctDate?: Date

	/**
	 * Octopus hodnocení
	 */
	RatingOctValue?: number

	/**
	 * Datum hodnocení
	 */
	RatingDate?: Date

	/**
	 * Hodnocení partnera
	 */
	RatingValue?: number

	/**
	 * Nadř. partner
	 */
	SuperiorPartnerId?: number

	/**
	 * Formát
	 */
	FormatId?: number

	/**
	 * Stav 2
	 */
	Status2Value?: string

	/**
	 * Stav
	 */
	StatusValue?: string

	/**
	 * Uživ11
	 */
	User1Date?: Date

	/**
	 * Uživ9
	 */
	UserString1?: string

	/**
	 * OR a ŽR
	 */
	VerifedOn?: Date

	/**
	 * Právo na záznam
	 */
	RightGroupId?: number

	/**
	 * Vytvořeno
	 */
	CreatedOn?: Date

	/**
	 * Změněno
	 */
	ChangedOn?: Date

	/**
	 * Max credit
	 */
	MaxCredit?: number

	/**
	 * Min. credit
	 */
	MinCredit?: number

	/**
	 * Penále
	 */
	Penalty?: number

	/**
	 * Splatnost
	 */
	MaturityDays?: number

	/**
	 * Komentář
	 */
	Comment?: string

	/**
	 * DIČ
	 */
	VATRegNumber?: string

	/**
	 * IČO
	 */
	CompanyRegNumber?: string

	/**
	 * Firma
	 */
	Name?: string

	/**
	 * Zkratka 2
	 */
	Abbr2?: string

	/**
	 * Zkratka 1
	 */
	Abbr1?: string

	/**
	 * Číslo
	 */
	Id?: number

	/**
	 * undefined
	 */
	BookmarkImgCalc?: string

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * SQLSequenceOrderCalc
	 */
	SQLSequenceOrderCalc?: number

	/**
	 * Datum pro "_ver" pole
	 */
	VersionedFieldDateCalc?: Date

	/**
	 * Odkaz
	 */
	PictureCalc?: string

	/**
	 * Text aktivity
	 */
	CommentActivityTreeCalc?: string

	/**
	 * Počet aktivit
	 */
	ActivityCountCalc?: number

	/**
	 * PDPH nákup
	 */
	VATPayerPurchaseCalc?: number

	/**
	 * Evidence
	 */
	ArticleRecordsBitCalc?: number

	/**
	 * Kredity
	 */
	CreditsEvidenceBitCalc?: number

	/**
	 * Svázaný
	 */
	LinkedBitCalc?: number

	/**
	 * PDPH prodej
	 */
	VATSaleCalc?: number

	/**
	 * El. adresa 1
	 */
	ElectronicAddress1Calc?: string

	/**
	 * El. adresa 2
	 */
	ElectronicAddress2Calc?: string

	/**
	 * El. adresa 3
	 */
	ElectronicAddress3Calc?: string

	/**
	 * El. adresa 4
	 */
	ElectronicAddress4Calc?: string

	/**
	 * undefined
	 */
	ElectronicAddressCheckItemFieldCalc?: number

	/**
	 * El. adresa 1
	 */
	ElectronicAddress1FormattedCalc?: string

	/**
	 * El. adresa 2
	 */
	ElectronicAddress2FormattedCalc?: string

	/**
	 * El. adresa 3
	 */
	ElectronicAddress3FormattedCalc?: string

	/**
	 * El. adresa 4
	 */
	ElectronicAddress4FormattedCalc?: string

	/**
	 * undefined
	 */
	IsRatingCreditcheckEnabledCalc?: string

	/**
	 * undefined
	 */
	IsRatingIsirEnabledCalc?: string

	/**
	 * undefined
	 */
	IsRatingViesEnabledCalc?: string

	/**
	 * undefined
	 */
	IsRatingEnabledCalc?: string

	/**
	 * undefined
	 */
	IsRegistryVerificationEnabledCalc?: string

	/**
	 * Zpracování omezeno
	 */
	LimitedProcessingBitCalc?: number

	/**
	 * Fyzická osoba
	 */
	PhysicalPersonIdCalc?: number

	/**
	 * Komentář marketingu
	 */
	PlainCommentMarketingCalc?: string

	/**
	 * Fakturační adresa z centrály
	 */
	CentralInvoiceAddressBitCalc?: string

	/**
	 * Dodací adresa z centrály
	 */
	CentralDeliveryAddressBitCalc?: string

	/**
	 * Korespondenční adresa z centrály
	 */
	CentralPostalAddressBitCalc?: string

	/**
	 * Název a adresa
	 */
	NameAndAddressCalc?: string

	/**
	 * El. adresa
	 */
	EAddressPARAMCalc?: string

	/**
	 * undefined
	 */
	LogoFileNameCalc?: string

	/**
	 * undefined
	 */
	TextHeaderMemoCalc?: string

	/**
	 * undefined
	 */
	TextFooterMemoCalc?: string

	/**
	 * Poslední komentář
	 */
	LastCommentParamCalc?: string

	/**
	 * Ulice (Korespondenční adresa)
	 */
	AddressIdStreetCalc?: string

	/**
	 * Číslo orientační (Korespondenční adresa)
	 */
	AddressIdOrientationNumberCalc?: string

	/**
	 * Číslo popisné (Korespondenční adresa)
	 */
	AddressIdHouseNumberCalc?: string

	/**
	 * Název (Korespondenční adresa)
	 */
	AddressIdNameCalc?: string

	/**
	 * PSČ (Korespondenční adresa)
	 */
	AddressIdTownCodeCalc?: string

	/**
	 * Souřadnice (Korespondenční adresa)
	 */
	AddressIdCoordinatesCalc?: string

	/**
	 * Právo na záznam
	 */
	RightGroupIdCalc?: number

	/**
	 * Kód 6
	 */
	@Type(() => TCode6DocumentDM)
	Code6Id?: TCode6DocumentDM

	/**
	 * Kód 5
	 */
	@Type(() => TCode5DocumentDM)
	Code5Id?: TCode5DocumentDM

	/**
	 * Kód 4
	 */
	@Type(() => TCode4DocumentDM)
	Code4Id?: TCode4DocumentDM

	/**
	 * Kód 3
	 */
	@Type(() => TCode3DocumentDM)
	Code3Id?: TCode3DocumentDM

	/**
	 * Prodejní akce
	 */
	@Type(() => TSalesActionDM)
	SellingActionRID?: TSalesActionDM

	/**
	 * Ceník
	 */
	@Type(() => TPriceListDM)
	PriceListRID?: TPriceListDM

	/**
	 * Středisko
	 */
	@Type(() => TCostCentreCollectionDocumentDM)
	CostCentreId?: TCostCentreCollectionDocumentDM

	/**
	 * Prostředek
	 */
	@Type(() => TDeviceDocumentDM)
	DeviceRID?: TDeviceDocumentDM

	/**
	 * Skupina
	 */
	@Type(() => ShopGroupId)
	EshopGroupId?: ShopGroupId

	/**
	 * Stav
	 */
	@Type(() => PartnerStatus)
	StatusId?: PartnerStatus

	/**
	 * Kód zakázky
	 */
	@Type(() => TContractCodeDocumentDM)
	ContractCodeRID?: TContractCodeDocumentDM

	/**
	 * Korespondenční adresa
	 */
	@Type(() => TAddressDM)
	AddressId?: TAddressDM

	/**
	 * Kód 2
	 */
	@Type(() => TCode2DocumentDM)
	Code2Id?: TCode2DocumentDM

	/**
	 * Kód 1
	 */
	@Type(() => TCode1DocumentDM)
	Code1Id?: TCode1DocumentDM

	/**
	 * Referent
	 */
	@Type(() => TOfficerDocumentDM)
	OfficerId?: TOfficerDocumentDM

	/**
	 * Uživ10
	 */
	@Type(() => UserCustomValue)
	UserAppellation1Id?: UserCustomValue

	/**
	 * Uživ8
	 */
	@Type(() => TContactPersonDM)
	UserContactPerson2Id?: TContactPersonDM

	/**
	 * Uživ7
	 */
	@Type(() => TContactPersonDM)
	UserContactPerson1Id?: TContactPersonDM

	/**
	 * Uživ6
	 */
	@Type(() => UserCodeType6)
	UserCodeType6Id?: UserCodeType6

	/**
	 * Uživ5
	 */
	@Type(() => UserCodeType5)
	UserCodeType5Id?: UserCodeType5

	/**
	 * Uživ4
	 */
	@Type(() => UserCodeType4)
	UserCodeType4Id?: UserCodeType4

	/**
	 * Uživ3
	 */
	@Type(() => UserCodeType3)
	UserCodeType3Id?: UserCodeType3

	/**
	 * Uživatelský kód 2
	 */
	@Type(() => UserCodeType2)
	UserCodeType2Id?: UserCodeType2

	/**
	 * Uživatelský kód 1
	 */
	@Type(() => UserCodeType1)
	UserCodeType1Id?: UserCodeType1

	/**
	 * Ověřil
	 */
	@Type(() => TK2UserLookupDM)
	VerifedById?: TK2UserLookupDM

	/**
	 * Objem
	 */
	@Type(() => Circuit)
	Volume?: Circuit

	/**
	 * Zodp. osoba
	 */
	@Type(() => TContactPersonDM)
	ResponsiblePersonId?: TContactPersonDM

	/**
	 * Vytvořil
	 */
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM

	/**
	 * Péče
	 */
	@Type(() => Care)
	CareId?: Care

	/**
	 * Bonita
	 */
	@Type(() => Solvency)
	SolvencyId?: Solvency

	/**
	 * Původ
	 */
	@Type(() => PartnerOrigin)
	OriginId?: PartnerOrigin

	/**
	 * Typ firmy
	 */
	@Type(() => FirmType)
	CompanyTypeId?: FirmType

	/**
	 * Spokojenost
	 */
	@Type(() => SatisfactionName)
	SatisfactionId?: SatisfactionName

	/**
	 * Akvizice
	 */
	@Type(() => AcquisitionName)
	AcquisitionId?: AcquisitionName

	/**
	 * Priorita
	 */
	@Type(() => PriorityName)
	PriorityId?: PriorityName

	/**
	 * Třída
	 */
	@Type(() => PartnerClass)
	Classification?: PartnerClass

	/**
	 * Oblast
	 */
	@Type(() => Domain)
	Area?: Domain

	/**
	 * Region
	 */
	@Type(() => AreaCode)
	TerritoryId?: AreaCode

	/**
	 * Změnil
	 */
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM

	/**
	 * Způs. platby
	 */
	@Type(() => TPaymentMethodDM)
	PaymentMethod?: TPaymentMethodDM

	/**
	 * Cenová skupina
	 */
	@Type(() => TPriceGroupDM)
	PriceGroupId?: TPriceGroupDM

	/**
	 * Kód
	 */
	@Type(() => Code)
	Code?: Code

	/**
	 * Skupina
	 */
	@Type(() => CustomerGroup)
	GroupId?: CustomerGroup

	/**
	 * Nadř. zák.
	 */
	@Type(() => TTradingPartnerDM)
	SuperiorTradingPartnerId?: TTradingPartnerDM

	/**
	 * Zákazník
	 */
	@Type(() => TTradingPartnerDM)
	TradingPartnerId?: TTradingPartnerDM

	/**
	 * Z
	 */
	@Type(() => TTextDM)
	InvalidRecordImgCalc?: TTextDM

	/**
	 * Typ el. adresy 1
	 */
	@Type(() => TElectronicAddressTypeDM)
	ElectronicAddress1TypeIdCalc?: TElectronicAddressTypeDM

	/**
	 * Typ el. adresy 2
	 */
	@Type(() => TElectronicAddressTypeDM)
	ElectronicAddress2TypeIdCalc?: TElectronicAddressTypeDM

	/**
	 * Typ el. adresy 3
	 */
	@Type(() => TElectronicAddressTypeDM)
	ElectronicAddress3TypeIdCalc?: TElectronicAddressTypeDM

	/**
	 * Typ el. adresy 4
	 */
	@Type(() => TElectronicAddressTypeDM)
	ElectronicAddress4TypeIdCalc?: TElectronicAddressTypeDM

	/**
	 * Stát el. adresy 1
	 */
	@Type(() => TCountryDM)
	ElectronicAddressCountry1IdCalc?: TCountryDM

	/**
	 * Stát el. adresy 2
	 */
	@Type(() => TCountryDM)
	ElectronicAddressCountry2IdCalc?: TCountryDM

	/**
	 * Stát el. adresy 3
	 */
	@Type(() => TCountryDM)
	ElectronicAddressCountry3IdCalc?: TCountryDM

	/**
	 * Stát el. adresy 4
	 */
	@Type(() => TCountryDM)
	ElectronicAddressCountry4IdCalc?: TCountryDM

	/**
	 * D
	 */
	@Type(() => TTextDM)
	DocumentExistsCalc?: TTextDM

	/**
	 * undefined
	 */
	@Type(() => TMarketingActivityTypeDM)
	ActivityTypeRIDCalc?: TMarketingActivityTypeDM

	/**
	 * SP
	 */
	@Type(() => TTextDM)
	ProcessStateIdCalc?: TTextDM

	/**
	 * Aktuální proces
	 */
	@Type(() => TWorkflowProcessDM)
	CurrentProcessIdCalc?: TWorkflowProcessDM

	/**
	 * undefined
	 */
	@Type(() => TActivityDM)
	ActivityIdCalc?: TActivityDM

	/**
	 * Stát (Korespondenční adresa)
	 */
	@Type(() => TCountryDM)
	AddressIdCountryIdCalc?: TCountryDM

	/**
	 * Část obce (Korespondenční adresa)
	 */
	@Type(() => TTownPartDM)
	AddressIdTownPartIdCalc?: TTownPartDM

	/**
	 * Číslo (Korespondenční adresa)
	 */
	@Type(() => TTownDM)
	AddressIdTownIdCalc?: TTownDM

	/**
	 * Bank. účet
	 */
	@Type(() => TBookDM)
	BankBookIdCalc?: TBookDM

	/**
	 * Měna
	 */
	@Type(() => TCurrencyDM)
	CurrencyCalc?: TCurrencyDM

	/**
	 * Odkazy
	 */
	@Type(() => TLinkDM)
	LinkChild?: TLinkDM[]

	/**
	 * Komentáře
	 */
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]

	/**
	 * Úkoly
	 */
	@Type(() => TJobDM)
	JobChild?: TJobDM[]

	/**
	 * Branže
	 */
	@Type(() => TPartnerBusinessLineDM)
	PartnerBusinessLineChild?: TPartnerBusinessLineDM[]

	/**
	 * Kontaktní osoby - položky
	 */
	@Type(() => TContactPersonDM)
	ContactPersonListChild?: TContactPersonDM[]

	/**
	 * undefined
	 */
	@Type(() => TOpportunityDM)
	OpportunityChild?: TOpportunityDM[]

	/**
	 * undefined
	 */
	@Type(() => TQuotationDM)
	QuotationChild?: TQuotationDM[]

	/**
	 * undefined
	 */
	@Type(() => TRequestForQuotationDM)
	RequestForQuotationChild?: TRequestForQuotationDM[]

	/**
	 * undefined
	 */
	@Type(() => TCallHistoryDM)
	CallHistoryChild?: TCallHistoryDM[]

	/**
	 * undefined
	 */
	@Type(() => TPaymentConditionTradingPartnerPurchaseDM)
	PaymentConditionPurchaseChild?: TPaymentConditionTradingPartnerPurchaseDM[]

	/**
	 * undefined
	 */
	@Type(() => TPaymentConditionTradingPartnerSaleDM)
	PaymentConditionSaleChild?: TPaymentConditionTradingPartnerSaleDM[]

	/**
	 * undefined
	 */
	@Type(() => TOpenItemListDM)
	OpenItemListChild?: TOpenItemListDM[]

	/**
	 * Kontaktní informace
	 */
	@Type(() => TElectronicAddressDM)
	ElectronicAddressChild?: TElectronicAddressDM[]

	/**
	 * undefined
	 */
	@Type(() => TBankAccountTradingPartnerDM)
	BankAccountChild?: TBankAccountTradingPartnerDM[]

	/**
	 * undefined
	 */
	@Type(() => TRelatedAddressDM)
	AddressInvoiceChild?: TRelatedAddressDM[]

	/**
	 * undefined
	 */
	@Type(() => TRelatedAddressDM)
	AddressDeliveryChild?: TRelatedAddressDM[]

	/**
	 * undefined
	 */
	@Type(() => TRelatedAddressDM)
	AddressPostalChild?: TRelatedAddressDM[]

	/**
	 * undefined
	 */
	@Type(() => TRelatedAddressDM)
	AddressAllChild?: TRelatedAddressDM[]

	/**
	 * undefined
	 */
	@Type(() => TAdressKeyItemDM)
	AddressKeyChild?: TAdressKeyItemDM[]

	/**
	 * undefined
	 */
	@Type(() => TPartnerContactPersonDM)
	ContactPersonChild?: TPartnerContactPersonDM[]

	/**
	 * undefined
	 */
	@Type(() => TDepartmentElectronicAddressDM)
	DepartmentChild?: TDepartmentElectronicAddressDM[]

	/**
	 * Marketingové zařazení
	 */
	@Type(() => TMarketingSubsumptionDM)
	MarketingInclusionChild?: TMarketingSubsumptionDM[]

	/**
	 * undefined
	 */
	@Type(() => TBranchDM)
	BranchChild?: TBranchDM[]

	/**
	 * Deník
	 */
	@Type(() => TPartnerJournalDM)
	JournalChild?: TPartnerJournalDM[]

	/**
	 * Aktivity
	 */
	@Type(() => TActivityTreeGroupedDM)
	ActivityTreeGroupedChild?: TActivityTreeGroupedDM[]

	/**
	 * undefined
	 */
	@Type(() => TActivityDM)
	ActivityChild?: TActivityDM[]

	/**
	 * Dokumenty
	 */
	@Type(() => TExternalDocumentItemDM)
	ExternalDocumentItemChild?: TExternalDocumentItemDM[]

	/**
	 * Procesy
	 */
	@Type(() => TWorkflowProcessAsItemDM)
	WorkflowProcessChild?: TWorkflowProcessAsItemDM[]

	/**
	 * Text hlavičky
	 */
	@Type(() => TLanguageMemoTranslationDM)
	TextHeaderChild?: TLanguageMemoTranslationDM[]

	/**
	 * Text patičky
	 */
	@Type(() => TLanguageMemoTranslationDM)
	TextFooterChild?: TLanguageMemoTranslationDM[]

	/**
	 * undefined
	 */
	@Type(() => TCompanyLinkPartnerDM)
	CompanyChild?: TCompanyLinkPartnerDM[]

	/**
	 * Svázané doklady
	 */
	@Type(() => TAttachedDocumentDM)
	AttachedDocumentOldChild?: TAttachedDocumentDM[]

	/**
	 * Poznámky
	 */
	@Type(() => TOldNoteDM)
	DeprecatedNoteOldChild?: TOldNoteDM[]

	/**
	 * Zařadit do klíče/odstranit z klíče
	 */
	public BulkSortingKeyAddRemoveCOMMAND(parameters: TBulkSortingKeyAddRemoveCOMMANDParams) {
		return this.executeCommand('BulkSortingKeyAddRemoveCOMMAND', parameters)
	}

	/**
	 * Změna platebních podmínek
	 */
	public BulkPayCondAddRemoveCOMMAND(parameters: TBulkPayCondAddRemoveCOMMANDParams) {
		return this.executeCommand('BulkPayCondAddRemoveCOMMAND', parameters)
	}

	/**
	 * Aktualizace DIČ
	 */
	public BulkCheckVATCOMMAND() {
		return this.executeCommand('BulkCheckVATCOMMAND')
	}

	/**
	 * Načíst GPS souřadnice
	 */
	public AddressLoadCoordinatesCOMMAND() {
		return this.executeCommand('AddressLoadCoordinatesCOMMAND')
	}

	/**
	 * Zneplatnění
	 */
	public BulkInvalidationCOMMAND() {
		return this.executeCommand('BulkInvalidationCOMMAND')
	}

	/**
	 * Zplatnění
	 */
	public BulkUnInvalidationCOMMAND() {
		return this.executeCommand('BulkUnInvalidationCOMMAND')
	}

	/**
	 * Zneplatnění/Zplatnění
	 */
	public InvalidationCOMMAND() {
		return this.executeCommand('InvalidationCOMMAND')
	}

	/**
	 * Potvrzení
	 */
	public BulkConfirmCOMMAND() {
		return this.executeCommand('BulkConfirmCOMMAND')
	}

	/**
	 * Odpotvrzení
	 */
	public BulkUnconfirmCOMMAND() {
		return this.executeCommand('BulkUnconfirmCOMMAND')
	}

	/**
	 * Změna data potvrzení
	 */
	public BulkChangeConfirmationDateCOMMAND(parameters: TBulkChangeConfirmationDateCOMMANDParams) {
		return this.executeCommand('BulkChangeConfirmationDateCOMMAND', parameters)
	}

	/**
	 * Storno
	 */
	public BulkCancelCOMMAND() {
		return this.executeCommand('BulkCancelCOMMAND')
	}

	/**
	 * Odstorno
	 */
	public BulkUnCancelCOMMAND() {
		return this.executeCommand('BulkUnCancelCOMMAND')
	}

	/**
	 * Potvrzení
	 */
	public ConfirmCOMMAND() {
		return this.executeCommand('ConfirmCOMMAND')
	}

	/**
	 * Odpotvrzení
	 */
	public UnconfirmCOMMAND() {
		return this.executeCommand('UnconfirmCOMMAND')
	}

	/**
	 * Změna data potvrzení
	 */
	public ChangeConfirmationDateCOMMAND(parameters: TChangeConfirmationDateCOMMANDParams) {
		return this.executeCommand('ChangeConfirmationDateCOMMAND', parameters)
	}

	/**
	 * Storno/Odstorno
	 */
	public CancelCOMMAND() {
		return this.executeCommand('CancelCOMMAND')
	}

	/**
	 * Zrušení stavu
	 */
	public ClearRecordStatusCOMMAND() {
		return this.executeCommand('ClearRecordStatusCOMMAND')
	}

	/**
	 * Změna stavu
	 */
	public ChangeRecordStatusCOMMAND() {
		return this.executeCommand('ChangeRecordStatusCOMMAND')
	}

	/**
	 * 
	 */
	public ChangeRecordStatusSWSCOMMAND(parameters: TChangeRecordStatusSWSCOMMANDParams) {
		return this.executeCommand('ChangeRecordStatusSWSCOMMAND', parameters)
	}

	/**
	 * Zrušení stavu
	 */
	public BulkClearRecordStatusCOMMAND() {
		return this.executeCommand('BulkClearRecordStatusCOMMAND')
	}

	/**
	 * Změna stavu
	 */
	public BulkChangeRecordStatusCOMMAND() {
		return this.executeCommand('BulkChangeRecordStatusCOMMAND')
	}

	/**
	 * Změna atributů
	 */
	public BulkChangeAttributesCOMMAND(parameters: TBulkChangeAttributesCOMMANDParams) {
		return this.executeCommand('BulkChangeAttributesCOMMAND', parameters)
	}

	/**
	 * Aktualizace sady atributů
	 */
	public BulkActualizeAttributesCOMMAND(parameters: TBulkActualizeAttributesCOMMANDParams) {
		return this.executeCommand('BulkActualizeAttributesCOMMAND', parameters)
	}

	/**
	 * Aktualizace sady atributů
	 */
	public ActualizeAttributesCOMMAND() {
		return this.executeCommand('ActualizeAttributesCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public ChangeGroupRightCOMMAND() {
		return this.executeCommand('ChangeGroupRightCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public BulkChangeGroupRightCOMMAND() {
		return this.executeCommand('BulkChangeGroupRightCOMMAND')
	}

	/**
	 * Aktualizace počítaných polí
	 */
	public BulkUpdateCalcFieldsCOMMAND() {
		return this.executeCommand('BulkUpdateCalcFieldsCOMMAND')
	}

	/**
	 * Kontrola legislativy
	 */
	public SwitchOffLegislationValidationCOMMAND() {
		return this.executeCommand('SwitchOffLegislationValidationCOMMAND')
	}
}

export type TBulkSortingKeyAddRemoveCOMMANDParams = {
	/**
	 * Produktový klíč
	 */
	SortingProductKey?: number
	/**
	 * Adresní klíč
	 */
	SortingAddressKey?: number
	/**
	 * Volba
	 */
	SortingKeyOptionFiltrRecord?: number
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TBulkPayCondAddRemoveCOMMANDParams = {
	/**
	 * Prodej
	 */
	Sale?: boolean
	/**
	 * Nákup
	 */
	Purchase?: boolean
	/**
	 * Volba
	 */
	PayCondBulkChangeFiltrRecord?: number
	/**
	 * Platba do (dny)
	 */
	DaysIns?: number
	/**
	 * Skonto (%)
	 */
	PercentageIns?: number
	/**
	 * Skonto z netto částky
	 */
	NettoIns?: boolean
	/**
	 * Způsob platby
	 */
	PaymentIns?: string
	/**
	 * Závislá na způsobu platby
	 */
	IndipenIns?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TBulkChangeConfirmationDateCOMMANDParams = {
	/**
	 * Datum potvrzení
	 */
	Confirmation1Date?: Date
	/**
	 * Hodina potvrzení
	 */
	Confirmation1Time?: Date
	/**
	 * Datum 2. potvrzení
	 */
	Confirmation2Date?: Date
	/**
	 * Hodina 2. potvrzení
	 */
	Confirmation2Time?: Date
	/**
	 * Kontrolovat záporné stavy
	 */
	CheckNegativeState?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TChangeConfirmationDateCOMMANDParams = {
	/**
	 * Datum potvrzení
	 */
	Confirmation1Date?: Date
	/**
	 * Hodina potvrzení
	 */
	Confirmation1Time?: Date
	/**
	 * Datum 2. potvrzení
	 */
	Confirmation2Date?: Date
	/**
	 * Hodina 2. potvrzení
	 */
	Confirmation2Time?: Date
	/**
	 * Kontrolovat záporné stavy
	 */
	CheckNegativeState?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TChangeRecordStatusSWSCOMMANDParams = {
	/**
	 * StatusId
	 */
	StatusId?: number
}

export type TBulkChangeAttributesCOMMANDParams = {
	/**
	 * Smazat atributy
	 */
	DeleteAttribute?: boolean
	/**
	 * Smazat všechny volné atributy
	 */
	DeleteFreeAttribute?: boolean
	/**
	 * Nastavit hodnoty atributů
	 */
	SetValues?: boolean
	/**
	 * Přidat nepřipojené atributy
	 */
	AddFreeAttributes?: boolean
	/**
	 * FreeAttributeEnabled
	 */
	FreeAttributeEnabled?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TBulkActualizeAttributesCOMMANDParams = {
	/**
	 * Aktualizovat včetně samotné sady atributů
	 */
	ActualizeAttributeSet?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}