import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TSupplierPriceListDM from './TSupplierPriceListDM'
import TK2UserLookupDM from './TK2UserLookupDM'
import ArticleCode2 from './ArticleCode2'
import ArticleCode1 from './ArticleCode1'
import TRoutingVariantDM from './TRoutingVariantDM'
import TMeasureUnitDM from './TMeasureUnitDM'
import TCurrencyDM from './TCurrencyDM'
import TTradingPartnerDM from './TTradingPartnerDM'
import TArticleDM from './TArticleDM'

/**
 * Dodavatelské ceny
 */
export default class TSupplierPriceDM extends Data {
	public static className = 'TSupplierPriceDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = true

	/**
	 * Kniha
	 */
	BookId?: number

	/**
	 * Časové razítko
	 */
	TimeStamp?: string

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * Minimální odběr
	 */
	MinPurchaseQuantity?: number

	/**
	 * Změněno
	 */
	ChangedOn?: Date

	/**
	 * Kód kontraktu
	 */
	ContractCode?: string

	/**
	 * Produktová skupina
	 */
	SupplierProductGroupId?: number

	/**
	 * Klíčový kód
	 */
	KeyCode?: string

	/**
	 * Datum do
	 */
	DateTo?: Date

	/**
	 * Popis
	 */
	Description?: string

	/**
	 * Dispozice u dodavatele
	 */
	Available?: number

	/**
	 * Odběr po množství
	 */
	DeliveryQuantity?: number

	/**
	 * Počet dnů dodání
	 */
	DeliveryDayCount?: number

	/**
	 * Cena
	 */
	Price?: number

	/**
	 * Dodavatelský kód zboží
	 */
	Code?: string

	/**
	 * Datum od
	 */
	Date?: Date

	/**
	 * Platnost od množství
	 */
	Quantity?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * Odběr po / balení
	 */
	EX_odber_po_bal?: number

	/**
	 * Nabídka
	 */
	EX_Pop?: string

	/**
	 * Nabíku změnil
	 */
	EX_DLCH_Who?: number

	/**
	 * Nabíka změněna
	 */
	EX_DLCH?: Date

	/**
	 * Nabídnutá cena
	 */
	EX_Cena?: number

	/**
	 * EX_MinMnozRS
	 */
	EX_MinMnozRS?: number

	/**
	 * EX_DnuProBlokaci
	 */
	EX_DnuProBlokaci?: number

	/**
	 * EX_Dop_Kod
	 */
	EX_Dop_Kod?: string

	/**
	 * EX_Termin_Mnoz
	 */
	EX_Termin_Mnoz?: number

	/**
	 * EX_Termin_Nab
	 */
	EX_Termin_Nab?: Date

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Množství
	 */
	RealNumberQuantityCalc?: number

	/**
	 * Platnost od množství
	 */
	QuantityUMCalc?: number

	/**
	 * Dodavatelská cena netto
	 */
	PriceNetCalc?: number

	/**
	 * Čistá nákupní cena netto
	 */
	DiscountAdjustedPriceNetCalc?: number

	/**
	 * Čistá nákupní cena brutto
	 */
	DiscountAdjustedPriceGrossCalc?: number

	/**
	 * Dodavatelská cena brutto
	 */
	SupplierPriceGrossCalc?: number

	/**
	 * DPH
	 */
	VATRateCalc?: number

	/**
	 * Zkratka dodavatele
	 */
	TradingPartnerAbbrCalc?: string

	/**
	 * Kód zakázky
	 */
	ContractCodeIdCalc?: number

	/**
	 * Odběr po množství
	 */
	DeliveryQuantityUMCalc?: number

	/**
	 * Minimální odběr
	 */
	MinPurchaseQuantityUMCalc?: number

	/**
	 * Dispozice u dodavatele
	 */
	AvailableQuantityUMCalc?: number

	/**
	 * Koeficient DPH
	 */
	VATCoefficientCalc?: number

	/**
	 * Cena Kč
	 */
	CF_cenaKc?: number

	/**
	 * Dodavatelský ceník
	 */
	@Type(() => TSupplierPriceListDM)
	SupplierPriceListRID?: TSupplierPriceListDM

	/**
	 * Změnil
	 */
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM

	/**
	 * Dodavatelský kód 2
	 */
	@Type(() => ArticleCode2)
	SupplierCode2?: ArticleCode2

	/**
	 * Dodavatelský kód 1
	 */
	@Type(() => ArticleCode1)
	SupplierCode1?: ArticleCode1

	/**
	 * Varianta TP
	 */
	@Type(() => TRoutingVariantDM)
	RoutingVariantId?: TRoutingVariantDM

	/**
	 * Jednotka
	 */
	@Type(() => TMeasureUnitDM)
	MeasureUnitId?: TMeasureUnitDM

	/**
	 * Měna
	 */
	@Type(() => TCurrencyDM)
	Currency?: TCurrencyDM

	/**
	 * Dodavatel
	 */
	@Type(() => TTradingPartnerDM)
	TradingPartnerId?: TTradingPartnerDM

	/**
	 * Zboží
	 */
	@Type(() => TArticleDM)
	ArticleId?: TArticleDM

	/**
	 * Odstranění dodavatelské ceny
	 */
	public DeleteSupplierPriceCOMMAND() {
		return this.executeCommand('DeleteSupplierPriceCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public ChangeGroupRightCOMMAND() {
		return this.executeCommand('ChangeGroupRightCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public BulkChangeGroupRightCOMMAND() {
		return this.executeCommand('BulkChangeGroupRightCOMMAND')
	}

	/**
	 * Aktualizace počítaných polí
	 */
	public BulkUpdateCalcFieldsCOMMAND() {
		return this.executeCommand('BulkUpdateCalcFieldsCOMMAND')
	}

	/**
	 * Kontrola legislativy
	 */
	public SwitchOffLegislationValidationCOMMAND() {
		return this.executeCommand('SwitchOffLegislationValidationCOMMAND')
	}
}