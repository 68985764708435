import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TTextDM from './TTextDM'

/**
 * Podřízené položky
 */
export default class TWebModuleCategoryItemDM extends Data {
	public static className = 'TWebModuleCategoryItemDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = false

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * RID
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * Name
	 */
	Name?: string

	/**
	 * DataModuleNumber
	 */
	DataModuleNumber?: number

	/**
	 * RID
	 */
	RID?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * PK
	 */
	IsSubordinateCategoryCalc?: string

	/**
	 * UserAccessId
	 */
	@Type(() => TTextDM)
	UserAccessId?: TTextDM
}