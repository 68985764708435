import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'

/**
 * Zařazení
 */
export default class TParameterSetAsItemDM extends Data {
	public static className = 'TParameterSetAsItemDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = false

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * Poř. sk.
	 */
	GroupSequenceNumber?: number

	/**
	 * Poř. par.
	 */
	ParameterSequenceNumber?: number

	/**
	 * Parametr
	 */
	ParameterId?: number

	/**
	 * Skupina
	 */
	ParameterGroupId?: number

	/**
	 * Zařazení
	 */
	ParameterSetId?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date
}