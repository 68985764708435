import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TTextDM from './TTextDM'

/**
 * Propojeno k modulům
 */
export default class TWebModuleLinkSuperiorDM extends Data {
	public static className = 'TWebModuleLinkSuperiorDM'
	public static primaryKey = 'RID'
	public static systemFields = [
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = false

	/**
	 * Pořadí
	 */
	SequenceNumber?: number

	/**
	 * Položka modulu
	 */
	SlaveRID?: number

	/**
	 * Datový modul
	 */
	SlaveDataModuleNumber?: number

	/**
	 * Položka modulu
	 */
	MasterRID?: number

	/**
	 * Datový modul
	 */
	MasterDataModuleNumber?: number

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Název
	 */
	NameCalc?: string

	/**
	 * Modul webu
	 */
	@Type(() => TTextDM)
	SlaveModuleId?: TTextDM

	/**
	 * Modul webu
	 */
	@Type(() => TTextDM)
	MasterModuleId?: TTextDM

	/**
	 * Modul
	 */
	@Type(() => TTextDM)
	MasterLinkIdCalc?: TTextDM
}