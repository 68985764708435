import Data from 'react-k2-api/Api/Data'
import { Type } from 'class-transformer'
import TK2UserLookupDM from './TK2UserLookupDM'
import TBookDM from './TBookDM'
import TTextDM from './TTextDM'
import TPrPeriodDM from './TPrPeriodDM'

/**
 * Položky - hodnoty v obdobích
 */
export default class TPrConstantValueItemDM extends Data {
	public static className = 'TPrConstantValueItemDM'
	public static systemFields = [
		'TimeStamp',
		'RecordID',
	]

	public static isHeader = true

	/**
	 * Skupina práv
	 */
	RightGroupId?: number

	/**
	 * Časové razítko
	 */
	TimeStamp?: string

	/**
	 * Vytvořeno
	 */
	CreatedOn?: Date

	/**
	 * Změněno
	 */
	ChangedOn?: Date

	/**
	 * Zobrazení
	 */
	ValuePicture?: string

	/**
	 * Hodnota
	 */
	StringValue?: string

	/**
	 * Číslo
	 */
	Id?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * undefined
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Hodnota
	 */
	ActualTextCalc?: string

	/**
	 * undefined
	 */
	ActualPictureCalc?: string

	/**
	 * Hodnota konstanty
	 */
	ConstantValueBooleanIdCalc?: number

	/**
	 * Hodnota konstanty
	 */
	ConstantValueDateCalc?: Date

	/**
	 * Hodnota konstanty
	 */
	ConstantValueCurrencyCalc?: number

	/**
	 * Hodnota konstanty
	 */
	ConstantValueIntegerCalc?: number

	/**
	 * Hodnota konstanty
	 */
	ConstantValueRealCalc?: number

	/**
	 * Hodnota konstanty
	 */
	ConstantValueTextCalc?: string

	/**
	 * Hodnota konstanty
	 */
	ConstantValueTimeCalc?: Date

	/**
	 * Hodnota konstanty
	 */
	ConstantValueBankDetailIdCalc?: number

	/**
	 * Hodnota konstanty
	 */
	ConstantValueComponentIdCalc?: number

	/**
	 * Hodnota konstanty
	 */
	ConstantValueCalendarIdCalc?: number

	/**
	 * Skupina práv
	 */
	RightGroupIdCalc?: number

	/**
	 * Vytvořil
	 */
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM

	/**
	 * Kniha
	 */
	@Type(() => TBookDM)
	BookId?: TBookDM

	/**
	 * Změnil
	 */
	@Type(() => TK2UserLookupDM)
	ChangedbyId?: TK2UserLookupDM

	/**
	 * Jednotka
	 */
	@Type(() => TTextDM)
	ValueUnitId?: TTextDM

	/**
	 * Typ hodnoty
	 */
	@Type(() => TTextDM)
	ValueTypeId?: TTextDM

	/**
	 * Perioda
	 */
	@Type(() => TPrPeriodDM)
	PeriodId?: TPrPeriodDM

	/**
	 * Nastavené období
	 */
	@Type(() => TPrPeriodDM)
	PeriodIdCalc?: TPrPeriodDM

	/**
	 * Změna skupiny práv
	 */
	public ChangeGroupRightCOMMAND() {
		return this.executeCommand('ChangeGroupRightCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public BulkChangeGroupRightCOMMAND() {
		return this.executeCommand('BulkChangeGroupRightCOMMAND')
	}

	/**
	 * Aktualizace počítaných polí
	 */
	public BulkUpdateCalcFieldsCOMMAND() {
		return this.executeCommand('BulkUpdateCalcFieldsCOMMAND')
	}

	/**
	 * Kontrola legislativy
	 */
	public SwitchOffLegislationValidationCOMMAND() {
		return this.executeCommand('SwitchOffLegislationValidationCOMMAND')
	}
}