import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TK2UserLookupDM from './TK2UserLookupDM'
import TEshopDM from './TEshopDM'
import TParameterSetDM from './TParameterSetDM'
import TTextDM from './TTextDM'
import TMarketingActivityTypeDM from './TMarketingActivityTypeDM'
import TActivityDM from './TActivityDM'
import TLinkDM from './TLinkDM'
import TCommentDM from './TCommentDM'
import TEshopCategoryArticleDM from './TEshopCategoryArticleDM'
import TEshopCategoryArticleGroupDM from './TEshopCategoryArticleGroupDM'
import TEshopArticleListDM from './TEshopArticleListDM'
import TEshopCategoryArticleSummaryDM from './TEshopCategoryArticleSummaryDM'
import TVirtualCategoryParameterDM from './TVirtualCategoryParameterDM'
import TWebURLAddressItemDM from './TWebURLAddressItemDM'
import TExternalDocumentItemDM from './TExternalDocumentItemDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TLanguageMemoTranslationChildDM from './TLanguageMemoTranslationChildDM'
import TAttachedDocumentDM from './TAttachedDocumentDM'
import TOldNoteDM from './TOldNoteDM'

/**
 * Kategorie
 */
export default class TEshopCategoryInfoDM extends Data {
	public static className = 'TEshopCategoryInfoDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = true

	/**
	 * Nezobrazovat mezi kategoriemi
	 */
	IsInvisible?: string

	/**
	 * Vzorová kategorie
	 */
	PatternCategoryRID?: number

	/**
	 * Virtuální kategorie
	 */
	IsVirtual?: string

	/**
	 * OgImage
	 */
	OpenGraphImage?: number

	/**
	 * OG typ
	 */
	OpenGraphTypeId?: number

	/**
	 * Změněno
	 */
	ChangedOn?: Date

	/**
	 * Vytvořeno
	 */
	CreatedOn?: Date

	/**
	 * Právo na záznam
	 */
	RightGroupId?: number

	/**
	 * Cesta
	 */
	CategoryPath?: string

	/**
	 * Časové razítko
	 */
	TimeStamp?: string

	/**
	 * Pořadí
	 */
	SequenceNumber?: number

	/**
	 * Obrázek
	 */
	ImageFileName?: string

	/**
	 * Ikona
	 */
	IconFileName?: string

	/**
	 * Analytika
	 */
	PageAnalytics?: string

	/**
	 * Kanonická URL
	 */
	PageUrlCanonical?: string

	/**
	 * URL
	 */
	PageUrl?: string

	/**
	 * Klíčová slova
	 */
	PageKeyWord?: string

	/**
	 * Meta popis
	 */
	PageDescription?: string

	/**
	 * Meta titulek
	 */
	PageTitle?: string

	/**
	 * Popis
	 */
	Description?: string

	/**
	 * Hlavní nadpis
	 */
	Title?: string

	/**
	 * Název
	 */
	Name?: string

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * EX_kod_prech_syst
	 */
	EX_kod_prech_syst?: string

	/**
	 * EX_id_prech_syst
	 */
	EX_id_prech_syst?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Odkaz
	 */
	PictureCalc?: string

	/**
	 * DescriptionCalc
	 */
	DescriptionCalc?: string

	/**
	 * Zařazení kategorie
	 */
	CategoryPathNameCalc?: string

	/**
	 * Strom kategorií
	 */
	CategoryPathTitleCalc?: string

	/**
	 * undefined
	 */
	NotShowImgCalc?: string

	/**
	 * undefined
	 */
	DetailUrlCalc?: string

	/**
	 * undefined
	 */
	DetailUrlAbsCalc?: string

	/**
	 * Obrázek
	 */
	MainImageFullPathCalc?: string

	/**
	 * undefined
	 */
	UrlCalc?: string

	/**
	 * SEO adresa webu
	 */
	DetailUrlWebCalc?: string

	/**
	 * undefined
	 */
	TextHeaderMemoCalc?: string

	/**
	 * undefined
	 */
	TextFooterMemoCalc?: string

	/**
	 * Poslední komentář
	 */
	LastCommentParamCalc?: string

	/**
	 * Název
	 */
	TranslatedNameCalc?: string

	/**
	 * Hlavní nadpis
	 */
	TranslatedTitleCalc?: string

	/**
	 * Meta titulek
	 */
	TranslatedPageTitleCalc?: string

	/**
	 * Meta popis
	 */
	TranslatedPageDescriptionCalc?: string

	/**
	 * Klíčová slova
	 */
	TranslatedPageKeyWordCalc?: string

	/**
	 * URL
	 */
	TranslatedPageUrlCalc?: string

	/**
	 * Analytika
	 */
	TranslatedPageAnalyticsCalc?: string

	/**
	 * Popis
	 */
	TranslatedDescriptionCalc?: string

	/**
	 * Právo na záznam
	 */
	RightGroupIdCalc?: number

	/**
	 * Změnil
	 */
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM

	/**
	 * Vytvořil
	 */
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM

	/**
	 * E-shop
	 */
	@Type(() => TEshopDM)
	ShopRID?: TEshopDM

	/**
	 * Parametry
	 */
	@Type(() => TParameterSetDM)
	ParameterSetId?: TParameterSetDM

	/**
	 * Z
	 */
	@Type(() => TTextDM)
	InvalidRecordImgCalc?: TTextDM

	/**
	 * &Nezobrazovat
	 */
	@Type(() => TTextDM)
	NotShowBitCalc?: TTextDM

	/**
	 * E-shop pro zobrazení a editaci dat
	 */
	@Type(() => TEshopDM)
	ShownEshopRIDCalc?: TEshopDM

	/**
	 * D
	 */
	@Type(() => TTextDM)
	DocumentExistsCalc?: TTextDM

	/**
	 * undefined
	 */
	@Type(() => TMarketingActivityTypeDM)
	ActivityTypeRIDCalc?: TMarketingActivityTypeDM

	/**
	 * undefined
	 */
	@Type(() => TActivityDM)
	ActivityIdCalc?: TActivityDM

	/**
	 * TranslationLanguageIdCalc
	 */
	@Type(() => TTextDM)
	TranslationLanguageIdCalc?: TTextDM

	/**
	 * Odkazy
	 */
	@Type(() => TLinkDM)
	LinkChild?: TLinkDM[]

	/**
	 * Komentáře
	 */
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]

	/**
	 * Zboží zařazené do kategorie
	 */
	@Type(() => TEshopCategoryArticleDM)
	ArticleChild?: TEshopCategoryArticleDM[]

	/**
	 * Skupiny zařazené do kategorie
	 */
	@Type(() => TEshopCategoryArticleGroupDM)
	EshopCategoryArticleGroupChild?: TEshopCategoryArticleGroupDM[]

	/**
	 * undefined
	 */
	@Type(() => TEshopArticleListDM)
	AllArticleChild?: TEshopArticleListDM[]

	/**
	 * undefined
	 */
	@Type(() => TEshopCategoryArticleSummaryDM)
	AssignedArticleChild?: TEshopCategoryArticleSummaryDM[]

	/**
	 * undefined
	 */
	@Type(() => TVirtualCategoryParameterDM)
	ParameterValueChild?: TVirtualCategoryParameterDM[]

	/**
	 * undefined
	 */
	@Type(() => TWebURLAddressItemDM)
	WebUrlPageAddressItemChild?: TWebURLAddressItemDM[]

	/**
	 * Seznam aktivit
	 */
	@Type(() => TActivityDM)
	ActivityChild?: TActivityDM[]

	/**
	 * Dokumenty
	 */
	@Type(() => TExternalDocumentItemDM)
	ExternalDocumentItemChild?: TExternalDocumentItemDM[]

	/**
	 * Text hlavičky
	 */
	@Type(() => TLanguageMemoTranslationDM)
	TextHeaderChild?: TLanguageMemoTranslationDM[]

	/**
	 * Text patičky
	 */
	@Type(() => TLanguageMemoTranslationDM)
	TextFooterChild?: TLanguageMemoTranslationDM[]

	/**
	 * undefined
	 */
	@Type(() => TLanguageMemoTranslationChildDM)
	TranslatedFieldValuesChild?: TLanguageMemoTranslationChildDM[]

	/**
	 * Svázané doklady
	 */
	@Type(() => TAttachedDocumentDM)
	AttachedDocumentOldChild?: TAttachedDocumentDM[]

	/**
	 * Poznámky
	 */
	@Type(() => TOldNoteDM)
	DeprecatedNoteOldChild?: TOldNoteDM[]

	/**
	 * Zneplatnění
	 */
	public BulkInvalidationCOMMAND() {
		return this.executeCommand('BulkInvalidationCOMMAND')
	}

	/**
	 * Zplatnění
	 */
	public BulkUnInvalidationCOMMAND() {
		return this.executeCommand('BulkUnInvalidationCOMMAND')
	}

	/**
	 * Zneplatnění/Zplatnění
	 */
	public InvalidationCOMMAND() {
		return this.executeCommand('InvalidationCOMMAND')
	}

	/**
	 * Potvrzení
	 */
	public BulkConfirmCOMMAND() {
		return this.executeCommand('BulkConfirmCOMMAND')
	}

	/**
	 * Odpotvrzení
	 */
	public BulkUnconfirmCOMMAND() {
		return this.executeCommand('BulkUnconfirmCOMMAND')
	}

	/**
	 * Změna data potvrzení
	 */
	public BulkChangeConfirmationDateCOMMAND(parameters: TBulkChangeConfirmationDateCOMMANDParams) {
		return this.executeCommand('BulkChangeConfirmationDateCOMMAND', parameters)
	}

	/**
	 * Storno
	 */
	public BulkCancelCOMMAND() {
		return this.executeCommand('BulkCancelCOMMAND')
	}

	/**
	 * Odstorno
	 */
	public BulkUnCancelCOMMAND() {
		return this.executeCommand('BulkUnCancelCOMMAND')
	}

	/**
	 * Potvrzení
	 */
	public ConfirmCOMMAND() {
		return this.executeCommand('ConfirmCOMMAND')
	}

	/**
	 * Odpotvrzení
	 */
	public UnconfirmCOMMAND() {
		return this.executeCommand('UnconfirmCOMMAND')
	}

	/**
	 * Změna data potvrzení
	 */
	public ChangeConfirmationDateCOMMAND(parameters: TChangeConfirmationDateCOMMANDParams) {
		return this.executeCommand('ChangeConfirmationDateCOMMAND', parameters)
	}

	/**
	 * Storno/Odstorno
	 */
	public CancelCOMMAND() {
		return this.executeCommand('CancelCOMMAND')
	}

	/**
	 * Zrušení stavu
	 */
	public ClearRecordStatusCOMMAND() {
		return this.executeCommand('ClearRecordStatusCOMMAND')
	}

	/**
	 * Změna stavu
	 */
	public ChangeRecordStatusCOMMAND() {
		return this.executeCommand('ChangeRecordStatusCOMMAND')
	}

	/**
	 * 
	 */
	public ChangeRecordStatusSWSCOMMAND(parameters: TChangeRecordStatusSWSCOMMANDParams) {
		return this.executeCommand('ChangeRecordStatusSWSCOMMAND', parameters)
	}

	/**
	 * Zrušení stavu
	 */
	public BulkClearRecordStatusCOMMAND() {
		return this.executeCommand('BulkClearRecordStatusCOMMAND')
	}

	/**
	 * Změna stavu
	 */
	public BulkChangeRecordStatusCOMMAND() {
		return this.executeCommand('BulkChangeRecordStatusCOMMAND')
	}

	/**
	 * Změna atributů
	 */
	public BulkChangeAttributesCOMMAND(parameters: TBulkChangeAttributesCOMMANDParams) {
		return this.executeCommand('BulkChangeAttributesCOMMAND', parameters)
	}

	/**
	 * Aktualizace sady atributů
	 */
	public BulkActualizeAttributesCOMMAND(parameters: TBulkActualizeAttributesCOMMANDParams) {
		return this.executeCommand('BulkActualizeAttributesCOMMAND', parameters)
	}

	/**
	 * Aktualizace sady atributů
	 */
	public ActualizeAttributesCOMMAND() {
		return this.executeCommand('ActualizeAttributesCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public ChangeGroupRightCOMMAND() {
		return this.executeCommand('ChangeGroupRightCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public BulkChangeGroupRightCOMMAND() {
		return this.executeCommand('BulkChangeGroupRightCOMMAND')
	}

	/**
	 * Aktualizace počítaných polí
	 */
	public BulkUpdateCalcFieldsCOMMAND() {
		return this.executeCommand('BulkUpdateCalcFieldsCOMMAND')
	}

	/**
	 * Kontrola legislativy
	 */
	public SwitchOffLegislationValidationCOMMAND() {
		return this.executeCommand('SwitchOffLegislationValidationCOMMAND')
	}
}

export type TBulkChangeConfirmationDateCOMMANDParams = {
	/**
	 * Datum potvrzení
	 */
	Confirmation1Date?: Date
	/**
	 * Hodina potvrzení
	 */
	Confirmation1Time?: Date
	/**
	 * Datum 2. potvrzení
	 */
	Confirmation2Date?: Date
	/**
	 * Hodina 2. potvrzení
	 */
	Confirmation2Time?: Date
	/**
	 * Kontrolovat záporné stavy
	 */
	CheckNegativeState?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TChangeConfirmationDateCOMMANDParams = {
	/**
	 * Datum potvrzení
	 */
	Confirmation1Date?: Date
	/**
	 * Hodina potvrzení
	 */
	Confirmation1Time?: Date
	/**
	 * Datum 2. potvrzení
	 */
	Confirmation2Date?: Date
	/**
	 * Hodina 2. potvrzení
	 */
	Confirmation2Time?: Date
	/**
	 * Kontrolovat záporné stavy
	 */
	CheckNegativeState?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TChangeRecordStatusSWSCOMMANDParams = {
	/**
	 * StatusId
	 */
	StatusId?: number
}

export type TBulkChangeAttributesCOMMANDParams = {
	/**
	 * Smazat atributy
	 */
	DeleteAttribute?: boolean
	/**
	 * Smazat všechny volné atributy
	 */
	DeleteFreeAttribute?: boolean
	/**
	 * Nastavit hodnoty atributů
	 */
	SetValues?: boolean
	/**
	 * Přidat nepřipojené atributy
	 */
	AddFreeAttributes?: boolean
	/**
	 * FreeAttributeEnabled
	 */
	FreeAttributeEnabled?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TBulkActualizeAttributesCOMMANDParams = {
	/**
	 * Aktualizovat včetně samotné sady atributů
	 */
	ActualizeAttributeSet?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}