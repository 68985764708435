import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TLocationArticleDM from './TLocationArticleDM'
import TWarehouseDM from './TWarehouseDM'
import HandlingUnitStatus from './HandlingUnitStatus'
import TK2UserLookupDM from './TK2UserLookupDM'
import THandlingUnitTypeDM from './THandlingUnitTypeDM'
import TTextDM from './TTextDM'
import TArticleDM from './TArticleDM'
import TBatchDM from './TBatchDM'
import TMeasureUnitDM from './TMeasureUnitDM'
import TMarketingActivityTypeDM from './TMarketingActivityTypeDM'
import TCommentDM from './TCommentDM'
import TStockCardDM from './TStockCardDM'
import TExternalDocumentItemDM from './TExternalDocumentItemDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TAttachedDocumentDM from './TAttachedDocumentDM'
import TOldNoteDM from './TOldNoteDM'

/**
 * Manipulační jednotky
 */
export default class THandlingUnitArticleDM extends Data {
	public static className = 'THandlingUnitArticleDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = true

	/**
	 * Nastavení
	 */
	Settings?: string

	/**
	 * Časové razítko
	 */
	TimeStamp?: string

	/**
	 * Změněno
	 */
	ChangedOn?: Date

	/**
	 * Popis
	 */
	Description?: string

	/**
	 * Zkratka
	 */
	Abbr?: string

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * Cílová zóna
	 */
	Ex_TargetZoneRID?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Zadáno celkem
	 */
	TotalAssignedCalc?: number

	/**
	 * Celková hmotnost (kg)
	 */
	TotalWeightCalc?: number

	/**
	 * Počet zboží
	 */
	ArticleCountCalc?: number

	/**
	 * Dispozice celkem
	 */
	AvailableTotalQuantityCalc?: number

	/**
	 * Počet šarží
	 */
	BatchCountCalc?: number

	/**
	 * Celková hmotnost (kg)
	 */
	TotalWeight2Calc?: number

	/**
	 * Celková plocha (m2)
	 */
	TotalAreaCalc?: number

	/**
	 * Celkový objem (l)
	 */
	TotalCapacityCalc?: number

	/**
	 * Celková šířka (m)
	 */
	TotalSizeACalc?: number

	/**
	 * Celková výška (m)
	 */
	TotalSizeBCalc?: number

	/**
	 * Celková hloubka (m)
	 */
	TotalSizeCCalc?: number

	/**
	 * Celkové množství
	 */
	TotalQuantityCalc?: number

	/**
	 * Čistá hmotnost (kg)
	 */
	TotalWeightNetCalc?: number

	/**
	 * undefined
	 */
	TotalReceivedCalc?: number

	/**
	 * undefined
	 */
	TotalDispatchCalc?: number

	/**
	 * V příjmu celkem
	 */
	InReceiptTotalQuantityCalc?: number

	/**
	 * V převodu celkem
	 */
	TotalInTransferCalc?: number

	/**
	 * Prázdné
	 */
	IsFormShowEmptyCalc?: string

	/**
	 * Naskladněné
	 */
	IsFormShowInUsedCalc?: string

	/**
	 * Nenulové
	 */
	IsFormShowSkipNullCalc?: string

	/**
	 * undefined
	 */
	InvalidRecordBitCalc?: number

	/**
	 * Celková hmotnost [kg]
	 */
	BarcodeTotalWeight2Calc?: number

	/**
	 * Celková plocha (m2)
	 */
	BarcodeTotalAreaCalc?: number

	/**
	 * Celkový objem (l)
	 */
	BarcodeTotalCapacityCalc?: number

	/**
	 * Celková šířka (m)
	 */
	BarcodeTotalSizeACalc?: number

	/**
	 * Celková výška (m)
	 */
	BarcodeTotalSizeBCalc?: number

	/**
	 * Celková hloubka (m)
	 */
	BarcodeTotalSizeCCalc?: number

	/**
	 * Celkové množství
	 */
	BarcodeTotalAmountCalc?: number

	/**
	 * Zaplnění hmotnostní v procentech
	 */
	FillingPercentageWeightCalc?: number

	/**
	 * Zaplnění objemové v procentech
	 */
	FillingPercentageVolumeCalc?: number

	/**
	 * Celkový objem
	 */
	TotalVolumePARAMCalc?: number

	/**
	 * Zadáno
	 */
	AssignedCalc?: number

	/**
	 * Dispozice
	 */
	AvailableQuantityCalc?: number

	/**
	 * Přijato
	 */
	ReceivedCalc?: number

	/**
	 * Expedice
	 */
	DispatchCalc?: number

	/**
	 * Zadáno
	 */
	AssignedUMCalc?: number

	/**
	 * Dispozice
	 */
	AvailableQuantityUMCalc?: number

	/**
	 * Přijato
	 */
	ReceivedUMCalc?: number

	/**
	 * Expedice
	 */
	DispatchUMCalc?: number

	/**
	 * V příjmu
	 */
	InReceiptQuantityCalc?: number

	/**
	 * V příjmu
	 */
	InReceiptQuantityUMCalc?: number

	/**
	 * V převodu
	 */
	InTransferCalc?: number

	/**
	 * V převodu
	 */
	InTransferUMCalc?: number

	/**
	 * undefined
	 */
	MeasureUnitCoefficientCalc?: number

	/**
	 * undefined
	 */
	TextHeaderMemoCalc?: string

	/**
	 * undefined
	 */
	TextFooterMemoCalc?: string

	/**
	 * Poslední komentář
	 */
	LastCommentParamCalc?: string

	/**
	 * Umístění
	 */
	@Type(() => TLocationArticleDM)
	LocationId?: TLocationArticleDM

	/**
	 * Číslo skladu
	 */
	@Type(() => TWarehouseDM)
	WarehouseId?: TWarehouseDM

	/**
	 * Stav
	 */
	@Type(() => HandlingUnitStatus)
	StatusId?: HandlingUnitStatus

	/**
	 * Změnil
	 */
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM

	/**
	 * Typ
	 */
	@Type(() => THandlingUnitTypeDM)
	HandlingUnitTypeRID?: THandlingUnitTypeDM

	/**
	 * Z
	 */
	@Type(() => TTextDM)
	InvalidRecordImgCalc?: TTextDM

	/**
	 * Č. skladu
	 */
	@Type(() => TWarehouseDM)
	WarehouseZoneRIDCalc?: TWarehouseDM

	/**
	 * Zboží
	 */
	@Type(() => TArticleDM)
	UniqueArticleIdCalc?: TArticleDM

	/**
	 * Šarže
	 */
	@Type(() => TBatchDM)
	BatchIdCalc?: TBatchDM

	/**
	 * Skl. jedn
	 */
	@Type(() => TMeasureUnitDM)
	MeasureUnitCalc?: TMeasureUnitDM

	/**
	 * undefined
	 */
	@Type(() => TArticleDM)
	ArticleIdCalc?: TArticleDM

	/**
	 * D
	 */
	@Type(() => TTextDM)
	DocumentExistsCalc?: TTextDM

	/**
	 * undefined
	 */
	@Type(() => TMarketingActivityTypeDM)
	ActivityTypeRIDCalc?: TMarketingActivityTypeDM

	/**
	 * Komentáře
	 */
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]

	/**
	 * undefined
	 */
	@Type(() => TStockCardDM)
	StockCardChild?: TStockCardDM[]

	/**
	 * Dokumenty
	 */
	@Type(() => TExternalDocumentItemDM)
	ExternalDocumentItemChild?: TExternalDocumentItemDM[]

	/**
	 * Text hlavičky
	 */
	@Type(() => TLanguageMemoTranslationDM)
	TextHeaderChild?: TLanguageMemoTranslationDM[]

	/**
	 * Text patičky
	 */
	@Type(() => TLanguageMemoTranslationDM)
	TextFooterChild?: TLanguageMemoTranslationDM[]

	/**
	 * Svázané doklady
	 */
	@Type(() => TAttachedDocumentDM)
	AttachedDocumentOldChild?: TAttachedDocumentDM[]

	/**
	 * Poznámky
	 */
	@Type(() => TOldNoteDM)
	DeprecatedNoteOldChild?: TOldNoteDM[]

	/**
	 * Zneplatnění
	 */
	public BulkInvalidationCOMMAND() {
		return this.executeCommand('BulkInvalidationCOMMAND')
	}

	/**
	 * Zplatnění
	 */
	public BulkUnInvalidationCOMMAND() {
		return this.executeCommand('BulkUnInvalidationCOMMAND')
	}

	/**
	 * Zneplatnění/Zplatnění
	 */
	public InvalidationCOMMAND() {
		return this.executeCommand('InvalidationCOMMAND')
	}

	/**
	 * Potvrzení
	 */
	public BulkConfirmCOMMAND() {
		return this.executeCommand('BulkConfirmCOMMAND')
	}

	/**
	 * Odpotvrzení
	 */
	public BulkUnconfirmCOMMAND() {
		return this.executeCommand('BulkUnconfirmCOMMAND')
	}

	/**
	 * Změna data potvrzení
	 */
	public BulkChangeConfirmationDateCOMMAND(parameters: TBulkChangeConfirmationDateCOMMANDParams) {
		return this.executeCommand('BulkChangeConfirmationDateCOMMAND', parameters)
	}

	/**
	 * Storno
	 */
	public BulkCancelCOMMAND() {
		return this.executeCommand('BulkCancelCOMMAND')
	}

	/**
	 * Odstorno
	 */
	public BulkUnCancelCOMMAND() {
		return this.executeCommand('BulkUnCancelCOMMAND')
	}

	/**
	 * Potvrzení
	 */
	public ConfirmCOMMAND() {
		return this.executeCommand('ConfirmCOMMAND')
	}

	/**
	 * Odpotvrzení
	 */
	public UnconfirmCOMMAND() {
		return this.executeCommand('UnconfirmCOMMAND')
	}

	/**
	 * Změna data potvrzení
	 */
	public ChangeConfirmationDateCOMMAND(parameters: TChangeConfirmationDateCOMMANDParams) {
		return this.executeCommand('ChangeConfirmationDateCOMMAND', parameters)
	}

	/**
	 * Storno/Odstorno
	 */
	public CancelCOMMAND() {
		return this.executeCommand('CancelCOMMAND')
	}

	/**
	 * Zrušení stavu
	 */
	public ClearRecordStatusCOMMAND() {
		return this.executeCommand('ClearRecordStatusCOMMAND')
	}

	/**
	 * Změna stavu
	 */
	public ChangeRecordStatusCOMMAND() {
		return this.executeCommand('ChangeRecordStatusCOMMAND')
	}

	/**
	 * 
	 */
	public ChangeRecordStatusSWSCOMMAND(parameters: TChangeRecordStatusSWSCOMMANDParams) {
		return this.executeCommand('ChangeRecordStatusSWSCOMMAND', parameters)
	}

	/**
	 * Zrušení stavu
	 */
	public BulkClearRecordStatusCOMMAND() {
		return this.executeCommand('BulkClearRecordStatusCOMMAND')
	}

	/**
	 * Změna stavu
	 */
	public BulkChangeRecordStatusCOMMAND() {
		return this.executeCommand('BulkChangeRecordStatusCOMMAND')
	}

	/**
	 * Změna atributů
	 */
	public BulkChangeAttributesCOMMAND(parameters: TBulkChangeAttributesCOMMANDParams) {
		return this.executeCommand('BulkChangeAttributesCOMMAND', parameters)
	}

	/**
	 * Aktualizace sady atributů
	 */
	public BulkActualizeAttributesCOMMAND(parameters: TBulkActualizeAttributesCOMMANDParams) {
		return this.executeCommand('BulkActualizeAttributesCOMMAND', parameters)
	}

	/**
	 * Aktualizace sady atributů
	 */
	public ActualizeAttributesCOMMAND() {
		return this.executeCommand('ActualizeAttributesCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public ChangeGroupRightCOMMAND() {
		return this.executeCommand('ChangeGroupRightCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public BulkChangeGroupRightCOMMAND() {
		return this.executeCommand('BulkChangeGroupRightCOMMAND')
	}

	/**
	 * Aktualizace počítaných polí
	 */
	public BulkUpdateCalcFieldsCOMMAND() {
		return this.executeCommand('BulkUpdateCalcFieldsCOMMAND')
	}

	/**
	 * Kontrola legislativy
	 */
	public SwitchOffLegislationValidationCOMMAND() {
		return this.executeCommand('SwitchOffLegislationValidationCOMMAND')
	}
}

export type TBulkChangeConfirmationDateCOMMANDParams = {
	/**
	 * Datum potvrzení
	 */
	Confirmation1Date?: Date
	/**
	 * Hodina potvrzení
	 */
	Confirmation1Time?: Date
	/**
	 * Datum 2. potvrzení
	 */
	Confirmation2Date?: Date
	/**
	 * Hodina 2. potvrzení
	 */
	Confirmation2Time?: Date
	/**
	 * Kontrolovat záporné stavy
	 */
	CheckNegativeState?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TChangeConfirmationDateCOMMANDParams = {
	/**
	 * Datum potvrzení
	 */
	Confirmation1Date?: Date
	/**
	 * Hodina potvrzení
	 */
	Confirmation1Time?: Date
	/**
	 * Datum 2. potvrzení
	 */
	Confirmation2Date?: Date
	/**
	 * Hodina 2. potvrzení
	 */
	Confirmation2Time?: Date
	/**
	 * Kontrolovat záporné stavy
	 */
	CheckNegativeState?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TChangeRecordStatusSWSCOMMANDParams = {
	/**
	 * StatusId
	 */
	StatusId?: number
}

export type TBulkChangeAttributesCOMMANDParams = {
	/**
	 * Smazat atributy
	 */
	DeleteAttribute?: boolean
	/**
	 * Smazat všechny volné atributy
	 */
	DeleteFreeAttribute?: boolean
	/**
	 * Nastavit hodnoty atributů
	 */
	SetValues?: boolean
	/**
	 * Přidat nepřipojené atributy
	 */
	AddFreeAttributes?: boolean
	/**
	 * FreeAttributeEnabled
	 */
	FreeAttributeEnabled?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TBulkActualizeAttributesCOMMANDParams = {
	/**
	 * Aktualizovat včetně samotné sady atributů
	 */
	ActualizeAttributeSet?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}