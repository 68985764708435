import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TTextDM from './TTextDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'

/**
 * Jazykové dodatky
 */
export default class TRNScreening extends Data {
	public static className = 'TRNScreening'
	public static primaryKey = 'Id'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.Id!
	}

	public static isHeader = true

	/**
	 * Časové razítko
	 */
	TimeStamp?: string

	/**
	 * Právo na záznam
	 */
	RightGroupId?: number

	/**
	 * Typ
	 */
	TypeId?: number

	/**
	 * Popis
	 */
	Description?: string

	/**
	 * Zkratka
	 */
	Abbr?: string

	/**
	 * Číslo
	 */
	Id?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * SQLSequenceOrderCalc
	 */
	SQLSequenceOrderCalc?: number

	/**
	 * Text TXT
	 */
	PlainTextCalc?: string

	/**
	 * Popis
	 */
	LanguageDescriptionCalc?: string

	/**
	 * Přel.
	 */
	TranslatedImgCalc?: string

	/**
	 * Poznámka
	 */
	NoteCalc?: string

	/**
	 * Text
	 */
	TextCalc?: string

	/**
	 * Popis
	 */
	Description100Calc?: string

	/**
	 * undefined
	 */
	ContentTextCalc?: string

	/**
	 * undefined
	 */
	ContentPlainTextCalc?: string

	/**
	 * undefined
	 */
	TextFormatCalc?: number

	/**
	 * Právo na záznam
	 */
	RightGroupIdCalc?: number

	/**
	 * Zkratka
	 */
	@Type(() => TTextDM)
	LanguageAbbrCalc?: TTextDM

	/**
	 * Přeloženo
	 */
	@Type(() => TTextDM)
	TranslatedBitCalc?: TTextDM

	/**
	 * undefined
	 */
	@Type(() => TLanguageMemoTranslationDM)
	LanguageMemoChild?: TLanguageMemoTranslationDM[]

	/**
	 * Výběr typu
	 */
	public TypeIdChangeCOMMAND(parameters: TTypeIdChangeCOMMANDParams) {
		return this.executeCommand('TypeIdChangeCOMMAND', parameters)
	}

	/**
	 * Změna skupiny práv
	 */
	public ChangeGroupRightCOMMAND() {
		return this.executeCommand('ChangeGroupRightCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public BulkChangeGroupRightCOMMAND() {
		return this.executeCommand('BulkChangeGroupRightCOMMAND')
	}

	/**
	 * Aktualizace počítaných polí
	 */
	public BulkUpdateCalcFieldsCOMMAND() {
		return this.executeCommand('BulkUpdateCalcFieldsCOMMAND')
	}

	/**
	 * Kontrola legislativy
	 */
	public SwitchOffLegislationValidationCOMMAND() {
		return this.executeCommand('SwitchOffLegislationValidationCOMMAND')
	}
}

export type TTypeIdChangeCOMMANDParams = {
	/**
	 * Typ
	 */
	TypeId?: number
	/**
	 * MessageText
	 */
	MessageText?: string
}