import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TK2UserLookupDM from './TK2UserLookupDM'
import TTownDM from './TTownDM'
import Region from './Region'
import TCountryDM from './TCountryDM'

/**
 * Místa
 */
export default class TTownPartDM extends Data {
	public static className = 'TTownPartDM'
	public static primaryKey = 'TownPartId'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.TownPartId!
	}

	public static isHeader = true

	/**
	 * Časové razítko
	 */
	TimeStamp?: string

	/**
	 * Longitude
	 */
	Longitude?: number

	/**
	 * Latitude
	 */
	Latitude?: number

	/**
	 * PSČ
	 */
	TownCode?: string

	/**
	 * Změněno
	 */
	ChangedOn?: Date

	/**
	 * Číslo uzlu pro městskou část
	 */
	TownPartId?: number

	/**
	 * Místo
	 */
	Name?: string

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Změnil
	 */
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM

	/**
	 * Město
	 */
	@Type(() => TTownDM)
	TownId?: TTownDM

	/**
	 * Region
	 */
	@Type(() => Region)
	RegionId?: Region

	/**
	 * Stát
	 */
	@Type(() => TCountryDM)
	CountryId?: TCountryDM

	/**
	 * Změna skupiny práv
	 */
	public ChangeGroupRightCOMMAND() {
		return this.executeCommand('ChangeGroupRightCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public BulkChangeGroupRightCOMMAND() {
		return this.executeCommand('BulkChangeGroupRightCOMMAND')
	}

	/**
	 * Aktualizace počítaných polí
	 */
	public BulkUpdateCalcFieldsCOMMAND() {
		return this.executeCommand('BulkUpdateCalcFieldsCOMMAND')
	}

	/**
	 * Kontrola legislativy
	 */
	public SwitchOffLegislationValidationCOMMAND() {
		return this.executeCommand('SwitchOffLegislationValidationCOMMAND')
	}
}