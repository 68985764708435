import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import EmploymentName from './EmploymentName'
import TPartnerDM from './TPartnerDM'
import TK2UserLookupDM from './TK2UserLookupDM'
import TReductionTypeDM from './TReductionTypeDM'
import TPrEmploymentDM from './TPrEmploymentDM'
import TCommentDM from './TCommentDM'
import TExternalDocumentItemDM from './TExternalDocumentItemDM'

/**
 * Pracovní praxe
 */
export default class TPrEmployeeWorkExperienceAsItemDM extends Data {
	public static className = 'TPrEmployeeWorkExperienceAsItemDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = false

	/**
	 * Celková praxe
	 */
	TotalYearCount?: number

	/**
	 * Započitatelná praxe
	 */
	ReductedYearCount?: number

	/**
	 * Časové razítko
	 */
	TimeStamp?: string

	/**
	 * Název organizace
	 */
	OrganizationName?: string

	/**
	 * Právo na záznam
	 */
	RightGroupId?: number

	/**
	 * Změněno
	 */
	ChangedOn?: Date

	/**
	 * Vytvořeno
	 */
	CreatedOn?: Date

	/**
	 * Dynamická pole
	 */
	XmlField?: string

	/**
	 * Započitatelná praxe [dnů]
	 */
	ReductedDayCount?: number

	/**
	 * Celková praxe [dnů]
	 */
	TotalDayCount?: number

	/**
	 * Datum do
	 */
	DateTo?: Date

	/**
	 * Datum od
	 */
	DateFrom?: Date

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Odečítat
	 */
	SubtractCalc?: string

	/**
	 * Poslední komentář
	 */
	LastCommentParamCalc?: string

	/**
	 * Právo na záznam
	 */
	RightGroupIdCalc?: number

	/**
	 * Pozice
	 */
	@Type(() => EmploymentName)
	JobId?: EmploymentName

	/**
	 * Organizace
	 */
	@Type(() => TPartnerDM)
	OrganizationRID?: TPartnerDM

	/**
	 * Změnil
	 */
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM

	/**
	 * Vytvořil
	 */
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM

	/**
	 * Typ započtení praxe
	 */
	@Type(() => TReductionTypeDM)
	ReductionType?: TReductionTypeDM

	/**
	 * Pracovní vztah
	 */
	@Type(() => TPrEmploymentDM)
	PrEmploymentId?: TPrEmploymentDM

	/**
	 * Komentáře
	 */
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]

	/**
	 * Dokumenty
	 */
	@Type(() => TExternalDocumentItemDM)
	ExternalDocumentItemChild?: TExternalDocumentItemDM[]
}