import Data from 'react-k2-api/Api/Data'

/**
 * Odstávky vozidla
 */
export default class TVehicleShutdownDM extends Data {
	public static className = 'TVehicleShutdownDM'
	public static systemFields = [
		'RecordID',
	]

	public static isHeader = false

	/**
	 * Stát cíl
	 */
	DestinationCountryId?: number

	/**
	 * Stát cíl
	 */
	DestinationCountryOld?: string

	/**
	 * Jízd za den
	 */
	DrivePerDay?: number

	/**
	 * Četnost
	 */
	Frequency?: number

	/**
	 * Maximálně km
	 */
	MaxDistanceKm?: number

	/**
	 * Minimálně km
	 */
	MinDistanceKm?: number

	/**
	 * Osádka
	 */
	CrewId?: number

	/**
	 * Popis
	 */
	Description?: string

	/**
	 * Cíl cesty
	 */
	Destination?: string

	/**
	 * Účel jízdy
	 */
	Purpose?: string

	/**
	 * Pauza do
	 */
	StopEnd?: Date

	/**
	 * Pauza od
	 */
	StopStart?: Date

	/**
	 * Poradi
	 */
	SequenceNumber?: number

	/**
	 * TypDM
	 */
	DataModuleTypeId?: number

	/**
	 * Auto
	 */
	VehicleId?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * undefined
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date
}