import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TK2UserLookupDM from './TK2UserLookupDM'
import TCommentDM from './TCommentDM'
import TPrTariffDateDM from './TPrTariffDateDM'

/**
 * Tarifní tabulky
 */
export default class TPrTariffDM extends Data {
	public static className = 'TPrTariffDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = true

	/**
	 * Standardní číslo
	 */
	StandardId?: number

	/**
	 * Dynamická pole
	 */
	XmlField?: string

	/**
	 * Změněno
	 */
	ChangedOn?: Date

	/**
	 * Vytvořeno
	 */
	CreatedOn?: Date

	/**
	 * Časové razítko
	 */
	TimeStamp?: string

	/**
	 * Popis
	 */
	Description?: string

	/**
	 * Blokováno
	 */
	IsBlockedRecord?: string

	/**
	 * Právo na záznam
	 */
	RightGroupId?: number

	/**
	 * Název
	 */
	Name?: string

	/**
	 * Zkratka
	 */
	Abbr?: string

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Poslední komentář
	 */
	LastCommentParamCalc?: string

	/**
	 * Právo na záznam
	 */
	RightGroupIdCalc?: number

	/**
	 * Změnil
	 */
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM

	/**
	 * Vytvořil
	 */
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM

	/**
	 * Komentáře
	 */
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]

	/**
	 * Tarifní tabulky k datumu
	 */
	@Type(() => TPrTariffDateDM)
	PrTariffDateChild?: TPrTariffDateDM[]

	/**
	 * Export tarifních tabulek
	 */
	public BulkPayrollTariffExportCOMMAND() {
		return this.executeCommand('BulkPayrollTariffExportCOMMAND')
	}

	/**
	 * Import ze souboru XML
	 */
	public ImportXMLCOMMAND() {
		return this.executeCommand('ImportXMLCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public ChangeGroupRightCOMMAND() {
		return this.executeCommand('ChangeGroupRightCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public BulkChangeGroupRightCOMMAND() {
		return this.executeCommand('BulkChangeGroupRightCOMMAND')
	}

	/**
	 * Aktualizace počítaných polí
	 */
	public BulkUpdateCalcFieldsCOMMAND() {
		return this.executeCommand('BulkUpdateCalcFieldsCOMMAND')
	}

	/**
	 * Kontrola legislativy
	 */
	public SwitchOffLegislationValidationCOMMAND() {
		return this.executeCommand('SwitchOffLegislationValidationCOMMAND')
	}
}