import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TCode6DocumentDM from './TCode6DocumentDM'
import TCode5DocumentDM from './TCode5DocumentDM'
import TCode4DocumentDM from './TCode4DocumentDM'
import TCode3DocumentDM from './TCode3DocumentDM'
import TOfficerDM from './TOfficerDM'
import TDeviceDocumentDM from './TDeviceDocumentDM'
import TContactPersonDM from './TContactPersonDM'
import TContractCodeDocumentDM from './TContractCodeDocumentDM'
import TCode2DocumentDM from './TCode2DocumentDM'
import TCode1DocumentDM from './TCode1DocumentDM'
import TEmployedPersonSimpleDM from './TEmployedPersonSimpleDM'
import TCostCentreDocumentDM from './TCostCentreDocumentDM'
import TitleName from './TitleName'
import TK2UserLookupDM from './TK2UserLookupDM'
import PositionName from './PositionName'
import TTextDM from './TTextDM'
import TMarketingActivityTypeDM from './TMarketingActivityTypeDM'
import TCommentDM from './TCommentDM'
import TSalesItemAnalyticalDimensionDM from './TSalesItemAnalyticalDimensionDM'
import TPurchaseItemAnalyticalDimensionDM from './TPurchaseItemAnalyticalDimensionDM'
import TPaymentDocumentItemAnalyticalDimensionDM from './TPaymentDocumentItemAnalyticalDimensionDM'
import TAccountingJournalItemAnalyticalDimensionDM from './TAccountingJournalItemAnalyticalDimensionDM'
import TAssetMovementAnalyticalDimensionDM from './TAssetMovementAnalyticalDimensionDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TAttachedDocumentDM from './TAttachedDocumentDM'
import TOldNoteDM from './TOldNoteDM'

/**
 * Referenti
 */
export default class TOfficerDocumentDM extends Data {
	public static className = 'TOfficerDocumentDM'
	public static primaryKey = 'OfficerId'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.OfficerId!
	}

	public static isHeader = true

	/**
	 * Přiřazené modely pracovní doby dle nastavení pracovních vztahů
	 */
	HasAttendanceWHModel?: string

	/**
	 * Osoba v docházce
	 */
	IsAttendancePerson?: string

	/**
	 * Sazba zahr. stravného
	 */
	ForeignFoodAllowanceRate?: number

	/**
	 * Pořadí
	 */
	SequenceNumber?: number

	/**
	 * B
	 */
	IsBlockedRecord?: string

	/**
	 * &Nevkládat do dokladu
	 */
	NotInsertIntoDocument?: string

	/**
	 * Automaticky nenačítat položky
	 */
	NotLoadItemsAutomatically?: string

	/**
	 * &Automaticky nevyhodnocovat
	 */
	NotEvaluateAutomatically?: string

	/**
	 * Zkratka
	 */
	Abbr?: string

	/**
	 * Skupina
	 */
	EshopGroupId?: number

	/**
	 * GroupContPerson
	 */
	ContactPersonGroupId?: number

	/**
	 * Časové razítko
	 */
	TimeStamp?: string

	/**
	 * Formát
	 */
	FormatId?: number

	/**
	 * Rozená
	 */
	Born?: string

	/**
	 * Skup. práva
	 */
	RightGroupId?: number

	/**
	 * Funkce
	 */
	FunctionId?: number

	/**
	 * Komentář
	 */
	Comment?: string

	/**
	 * Akvizice
	 */
	AcquisitionId?: number

	/**
	 * Spokojenost
	 */
	SatisfactionId?: number

	/**
	 * Násl. tel.
	 */
	NextPhoneCallDate?: Date

	/**
	 * Posl. tel.
	 */
	LastPhoneCallDate?: Date

	/**
	 * Násl. náv.
	 */
	NextVisitDate?: Date

	/**
	 * Posl. náv.
	 */
	LastVisitDate?: Date

	/**
	 * Tel. do
	 */
	PhoneToTime?: Date

	/**
	 * Tel. od
	 */
	PhoneSinceTime?: Date

	/**
	 * Tel. den
	 */
	PhoneDay?: number

	/**
	 * Náv. do
	 */
	VisitToTime?: Date

	/**
	 * Náv. od
	 */
	VisitSinceTime?: Date

	/**
	 * Náv. den
	 */
	VisitingDay?: number

	/**
	 * Zástupce
	 */
	SubstituteId?: number

	/**
	 * Asistent
	 */
	AsistantId?: number

	/**
	 * Priorita
	 */
	PriorityId?: number

	/**
	 * Oddělení
	 */
	DepartmentId?: number

	/**
	 * Stejná os.
	 */
	SamePersonId?: number

	/**
	 * Vznik
	 */
	CreatedOn?: Date

	/**
	 * Změněno
	 */
	ChangedOn?: Date

	/**
	 * Dopr. do zam.
	 */
	TransportTypeId?: number

	/**
	 * Kancelář
	 */
	OfficeId?: number

	/**
	 * Stát
	 */
	CountryId?: number

	/**
	 * Stav
	 */
	MaritalStatus?: string

	/**
	 * Národnost
	 */
	Nationality?: string

	/**
	 * Dat. nar.
	 */
	BirthDate?: Date

	/**
	 * Rod. číslo
	 */
	BirthIdNumber?: string

	/**
	 * Jméno
	 */
	Name?: string

	/**
	 * Příjmení
	 */
	Surname?: string

	/**
	 * Číslo
	 */
	OfficerId?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Celé jméno
	 */
	FullNameCalc?: string

	/**
	 * Celé jméno
	 */
	FullNameTitleCalc?: string

	/**
	 * Vlastní zkratka:
	 */
	OwnAbbrBitCalc?: number

	/**
	 * undefined
	 */
	LimitedProcessingBitCalc?: number

	/**
	 * undefined
	 */
	ShowItemCalc?: number

	/**
	 * Pole1
	 */
	SummationEvaluationField1Calc?: number

	/**
	 * Pole2
	 */
	SummationEvaluationField2Calc?: number

	/**
	 * Pole3
	 */
	SummationEvaluationField3Calc?: number

	/**
	 * Pole4
	 */
	SummationEvaluationField4Calc?: number

	/**
	 * Pole5
	 */
	SummationEvaluationField5Calc?: number

	/**
	 * Pole6
	 */
	SummationEvaluationField6Calc?: number

	/**
	 * Konfigurace vyhodnocení
	 */
	EvaluationConfigCalc?: string

	/**
	 * undefined
	 */
	UseAnalyticalDimensionCalc?: string

	/**
	 * undefined
	 */
	TextHeaderMemoCalc?: string

	/**
	 * undefined
	 */
	TextFooterMemoCalc?: string

	/**
	 * Poslední komentář
	 */
	LastCommentParamCalc?: string

	/**
	 * Skup. práva
	 */
	RightGroupIdCalc?: number

	/**
	 * Kód 6
	 */
	@Type(() => TCode6DocumentDM)
	Code6Id?: TCode6DocumentDM

	/**
	 * Kód 5
	 */
	@Type(() => TCode5DocumentDM)
	Code5Id?: TCode5DocumentDM

	/**
	 * Kód 4
	 */
	@Type(() => TCode4DocumentDM)
	Code4Id?: TCode4DocumentDM

	/**
	 * Kód 3
	 */
	@Type(() => TCode3DocumentDM)
	Code3Id?: TCode3DocumentDM

	/**
	 * Uzel
	 */
	@Type(() => TOfficerDM)
	NodeId?: TOfficerDM

	/**
	 * Prostředek
	 */
	@Type(() => TDeviceDocumentDM)
	DeviceRID?: TDeviceDocumentDM

	/**
	 * Kontaktní osoba
	 */
	@Type(() => TContactPersonDM)
	ContactPersonId?: TContactPersonDM

	/**
	 * Kód zakázky
	 */
	@Type(() => TContractCodeDocumentDM)
	ContractCodeRID?: TContractCodeDocumentDM

	/**
	 * Kód 2
	 */
	@Type(() => TCode2DocumentDM)
	Code2Id?: TCode2DocumentDM

	/**
	 * Kód 1
	 */
	@Type(() => TCode1DocumentDM)
	Code1Id?: TCode1DocumentDM

	/**
	 * Pracovník
	 */
	@Type(() => TEmployedPersonSimpleDM)
	WorkerId?: TEmployedPersonSimpleDM

	/**
	 * Středisko
	 */
	@Type(() => TCostCentreDocumentDM)
	CostCentreId?: TCostCentreDocumentDM

	/**
	 * Titul za jménem
	 */
	@Type(() => TitleName)
	TitleAfterName?: TitleName

	/**
	 * Uživatel
	 */
	@Type(() => TK2UserLookupDM)
	UserId?: TK2UserLookupDM

	/**
	 * Postavení
	 */
	@Type(() => PositionName)
	PositionId?: PositionName

	/**
	 * Vytvořil
	 */
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM

	/**
	 * Změnil
	 */
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM

	/**
	 * Titul
	 */
	@Type(() => TitleName)
	Title?: TitleName

	/**
	 * Z
	 */
	@Type(() => TTextDM)
	InvalidRecordImgCalc?: TTextDM

	/**
	 * D
	 */
	@Type(() => TTextDM)
	DocumentExistsCalc?: TTextDM

	/**
	 * undefined
	 */
	@Type(() => TMarketingActivityTypeDM)
	ActivityTypeRIDCalc?: TMarketingActivityTypeDM

	/**
	 * Komentáře
	 */
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]

	/**
	 * Položky prodeje
	 */
	@Type(() => TSalesItemAnalyticalDimensionDM)
	SalesItemChild?: TSalesItemAnalyticalDimensionDM[]

	/**
	 * Položky nákupu
	 */
	@Type(() => TPurchaseItemAnalyticalDimensionDM)
	PurchaseItemChild?: TPurchaseItemAnalyticalDimensionDM[]

	/**
	 * Položky platebních dokladů
	 */
	@Type(() => TPaymentDocumentItemAnalyticalDimensionDM)
	FinanceItem?: TPaymentDocumentItemAnalyticalDimensionDM[]

	/**
	 * undefined
	 */
	@Type(() => TAccountingJournalItemAnalyticalDimensionDM)
	AccountingJournaItemBookChild?: TAccountingJournalItemAnalyticalDimensionDM[]

	/**
	 * undefined
	 */
	@Type(() => TAssetMovementAnalyticalDimensionDM)
	AssetMovementChild?: TAssetMovementAnalyticalDimensionDM[]

	/**
	 * Text hlavičky
	 */
	@Type(() => TLanguageMemoTranslationDM)
	TextHeaderChild?: TLanguageMemoTranslationDM[]

	/**
	 * Text patičky
	 */
	@Type(() => TLanguageMemoTranslationDM)
	TextFooterChild?: TLanguageMemoTranslationDM[]

	/**
	 * Svázané doklady
	 */
	@Type(() => TAttachedDocumentDM)
	AttachedDocumentOldChild?: TAttachedDocumentDM[]

	/**
	 * Poznámky
	 */
	@Type(() => TOldNoteDM)
	DeprecatedNoteOldChild?: TOldNoteDM[]

	/**
	 * Vyhodnocení
	 */
	public ForceEvaluationCOMMAND() {
		return this.executeCommand('ForceEvaluationCOMMAND')
	}

	/**
	 * Zneplatnění
	 */
	public BulkInvalidationCOMMAND() {
		return this.executeCommand('BulkInvalidationCOMMAND')
	}

	/**
	 * Zplatnění
	 */
	public BulkUnInvalidationCOMMAND() {
		return this.executeCommand('BulkUnInvalidationCOMMAND')
	}

	/**
	 * Zneplatnění/Zplatnění
	 */
	public InvalidationCOMMAND() {
		return this.executeCommand('InvalidationCOMMAND')
	}

	/**
	 * Potvrzení
	 */
	public BulkConfirmCOMMAND() {
		return this.executeCommand('BulkConfirmCOMMAND')
	}

	/**
	 * Odpotvrzení
	 */
	public BulkUnconfirmCOMMAND() {
		return this.executeCommand('BulkUnconfirmCOMMAND')
	}

	/**
	 * Změna data potvrzení
	 */
	public BulkChangeConfirmationDateCOMMAND(parameters: TBulkChangeConfirmationDateCOMMANDParams) {
		return this.executeCommand('BulkChangeConfirmationDateCOMMAND', parameters)
	}

	/**
	 * Storno
	 */
	public BulkCancelCOMMAND() {
		return this.executeCommand('BulkCancelCOMMAND')
	}

	/**
	 * Odstorno
	 */
	public BulkUnCancelCOMMAND() {
		return this.executeCommand('BulkUnCancelCOMMAND')
	}

	/**
	 * Potvrzení
	 */
	public ConfirmCOMMAND() {
		return this.executeCommand('ConfirmCOMMAND')
	}

	/**
	 * Odpotvrzení
	 */
	public UnconfirmCOMMAND() {
		return this.executeCommand('UnconfirmCOMMAND')
	}

	/**
	 * Změna data potvrzení
	 */
	public ChangeConfirmationDateCOMMAND(parameters: TChangeConfirmationDateCOMMANDParams) {
		return this.executeCommand('ChangeConfirmationDateCOMMAND', parameters)
	}

	/**
	 * Storno/Odstorno
	 */
	public CancelCOMMAND() {
		return this.executeCommand('CancelCOMMAND')
	}

	/**
	 * Zrušení stavu
	 */
	public ClearRecordStatusCOMMAND() {
		return this.executeCommand('ClearRecordStatusCOMMAND')
	}

	/**
	 * Změna stavu
	 */
	public ChangeRecordStatusCOMMAND() {
		return this.executeCommand('ChangeRecordStatusCOMMAND')
	}

	/**
	 * 
	 */
	public ChangeRecordStatusSWSCOMMAND(parameters: TChangeRecordStatusSWSCOMMANDParams) {
		return this.executeCommand('ChangeRecordStatusSWSCOMMAND', parameters)
	}

	/**
	 * Zrušení stavu
	 */
	public BulkClearRecordStatusCOMMAND() {
		return this.executeCommand('BulkClearRecordStatusCOMMAND')
	}

	/**
	 * Změna stavu
	 */
	public BulkChangeRecordStatusCOMMAND() {
		return this.executeCommand('BulkChangeRecordStatusCOMMAND')
	}

	/**
	 * Změna atributů
	 */
	public BulkChangeAttributesCOMMAND(parameters: TBulkChangeAttributesCOMMANDParams) {
		return this.executeCommand('BulkChangeAttributesCOMMAND', parameters)
	}

	/**
	 * Aktualizace sady atributů
	 */
	public BulkActualizeAttributesCOMMAND(parameters: TBulkActualizeAttributesCOMMANDParams) {
		return this.executeCommand('BulkActualizeAttributesCOMMAND', parameters)
	}

	/**
	 * Aktualizace sady atributů
	 */
	public ActualizeAttributesCOMMAND() {
		return this.executeCommand('ActualizeAttributesCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public ChangeGroupRightCOMMAND() {
		return this.executeCommand('ChangeGroupRightCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public BulkChangeGroupRightCOMMAND() {
		return this.executeCommand('BulkChangeGroupRightCOMMAND')
	}

	/**
	 * Aktualizace počítaných polí
	 */
	public BulkUpdateCalcFieldsCOMMAND() {
		return this.executeCommand('BulkUpdateCalcFieldsCOMMAND')
	}

	/**
	 * Kontrola legislativy
	 */
	public SwitchOffLegislationValidationCOMMAND() {
		return this.executeCommand('SwitchOffLegislationValidationCOMMAND')
	}
}

export type TBulkChangeConfirmationDateCOMMANDParams = {
	/**
	 * Datum potvrzení
	 */
	Confirmation1Date?: Date
	/**
	 * Hodina potvrzení
	 */
	Confirmation1Time?: Date
	/**
	 * Datum 2. potvrzení
	 */
	Confirmation2Date?: Date
	/**
	 * Hodina 2. potvrzení
	 */
	Confirmation2Time?: Date
	/**
	 * Kontrolovat záporné stavy
	 */
	CheckNegativeState?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TChangeConfirmationDateCOMMANDParams = {
	/**
	 * Datum potvrzení
	 */
	Confirmation1Date?: Date
	/**
	 * Hodina potvrzení
	 */
	Confirmation1Time?: Date
	/**
	 * Datum 2. potvrzení
	 */
	Confirmation2Date?: Date
	/**
	 * Hodina 2. potvrzení
	 */
	Confirmation2Time?: Date
	/**
	 * Kontrolovat záporné stavy
	 */
	CheckNegativeState?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TChangeRecordStatusSWSCOMMANDParams = {
	/**
	 * StatusId
	 */
	StatusId?: number
}

export type TBulkChangeAttributesCOMMANDParams = {
	/**
	 * Smazat atributy
	 */
	DeleteAttribute?: boolean
	/**
	 * Smazat všechny volné atributy
	 */
	DeleteFreeAttribute?: boolean
	/**
	 * Nastavit hodnoty atributů
	 */
	SetValues?: boolean
	/**
	 * Přidat nepřipojené atributy
	 */
	AddFreeAttributes?: boolean
	/**
	 * FreeAttributeEnabled
	 */
	FreeAttributeEnabled?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TBulkActualizeAttributesCOMMANDParams = {
	/**
	 * Aktualizovat včetně samotné sady atributů
	 */
	ActualizeAttributeSet?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}