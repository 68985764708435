import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TCode6DocumentDM from './TCode6DocumentDM'
import TCode5DocumentDM from './TCode5DocumentDM'
import TCode4DocumentDM from './TCode4DocumentDM'
import TCode3DocumentDM from './TCode3DocumentDM'
import TCostCentreDocumentDM from './TCostCentreDocumentDM'
import TDeviceDocumentDM from './TDeviceDocumentDM'
import TOfficerDocumentDM from './TOfficerDocumentDM'
import ShopGroupId from './ShopGroupId'
import TContractCodeDocumentDM from './TContractCodeDocumentDM'
import TContactPersonGroupDM from './TContactPersonGroupDM'
import TCode2DocumentDM from './TCode2DocumentDM'
import TCode1DocumentDM from './TCode1DocumentDM'
import TEmployedPersonSimpleDM from './TEmployedPersonSimpleDM'
import TitleName from './TitleName'
import TK2UserLookupDM from './TK2UserLookupDM'
import PositionName from './PositionName'
import FunctionName from './FunctionName'
import AcquisitionName from './AcquisitionName'
import SatisfactionName from './SatisfactionName'
import TTextDM from './TTextDM'
import PriorityName from './PriorityName'
import DepartmentName from './DepartmentName'
import TransportTypeName from './TransportTypeName'
import OfficeName from './OfficeName'
import TCountryDM from './TCountryDM'
import MaritalStatus from './MaritalStatus'
import Nationality from './Nationality'
import TSpecialPrinterGroup from './TSpecialPrinterGroup'
import TElectronicAddressTypeDM from './TElectronicAddressTypeDM'
import TPartnerDM from './TPartnerDM'
import TEshopRegistrationDM from './TEshopRegistrationDM'
import TAttendanceMovementDM from './TAttendanceMovementDM'
import TMarketingActivityTypeDM from './TMarketingActivityTypeDM'
import TWorkflowProcessDM from './TWorkflowProcessDM'
import TActivityDM from './TActivityDM'
import TLinkDM from './TLinkDM'
import TCommentDM from './TCommentDM'
import TJobDM from './TJobDM'
import TQualificationContactPersonDM from './TQualificationContactPersonDM'
import TOrgStrEmployeeDM from './TOrgStrEmployeeDM'
import TContactPersonPartnerDM from './TContactPersonPartnerDM'
import TContactPersonAddressDM from './TContactPersonAddressDM'
import TEshopFavoriteDM from './TEshopFavoriteDM'
import TDiscountCouponDM from './TDiscountCouponDM'
import TOrgStrTreeChildPersonDM from './TOrgStrTreeChildPersonDM'
import TOnePersonAbsenceDM from './TOnePersonAbsenceDM'
import TWorkflowProcedureRespPersonDM from './TWorkflowProcedureRespPersonDM'
import TElectronicAddressDM from './TElectronicAddressDM'
import TMarketingFamilyMemberDM from './TMarketingFamilyMemberDM'
import TContactPersonEducationDM from './TContactPersonEducationDM'
import TWorkOfficialPositionDM from './TWorkOfficialPositionDM'
import TMarketingSubsumptionDM from './TMarketingSubsumptionDM'
import TEmploymentHistoryDM from './TEmploymentHistoryDM'
import TMarketingLanguageDM from './TMarketingLanguageDM'
import TContactPersonNatureDM from './TContactPersonNatureDM'
import TMarketingHobbyDM from './TMarketingHobbyDM'
import TMarketingGiftDM from './TMarketingGiftDM'
import TContactPersonAddressItemDM from './TContactPersonAddressItemDM'
import TAttendancePersonIdentifyItemDM from './TAttendancePersonIdentifyItemDM'
import TAttendancePersonWHModelItemDM from './TAttendancePersonWHModelItemDM'
import TAttendancePersonWorkplaceItemDM from './TAttendancePersonWorkplaceItemDM'
import TAttendanceWorkTimeReportDM from './TAttendanceWorkTimeReportDM'
import TAttendanceScheduleDM from './TAttendanceScheduleDM'
import TSetContactPersonAddressingDM from './TSetContactPersonAddressingDM'
import TActivityTreeGroupedDM from './TActivityTreeGroupedDM'
import TExternalDocumentItemDM from './TExternalDocumentItemDM'
import TWorkflowProcessAsItemDM from './TWorkflowProcessAsItemDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TAttachedDocumentDM from './TAttachedDocumentDM'
import TOldNoteDM from './TOldNoteDM'

/**
 * Kontaktní osoby - položky
 */
export default class TContactPersonDM extends Data {
	public static className = 'TContactPersonDM'
	public static primaryKey = 'Id'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.Id!
	}

	public static isHeader = true

	/**
	 * Přiřazené modely pracovní doby dle nastavení pracovních vztahů
	 */
	HasAttendanceWHModel?: string

	/**
	 * Osoba v docházce
	 */
	IsAttendancePerson?: string

	/**
	 * Sazba ZS
	 */
	ForeignFoodAllowanceRate?: number

	/**
	 * Pořadí
	 */
	SequenceNumber?: number

	/**
	 * B
	 */
	IsBlockedRecord?: string

	/**
	 * Uzel
	 */
	NodeId?: number

	/**
	 * Nevkládat
	 */
	NotInsertIntoDocument?: string

	/**
	 * N pol.
	 */
	NotLoadItemsAutomatically?: string

	/**
	 * N
	 */
	NotEvaluateAutomatically?: string

	/**
	 * Zkratka
	 */
	Abbr?: string

	/**
	 * Časové razítko
	 */
	TimeStamp?: string

	/**
	 * Formát
	 */
	FormatId?: number

	/**
	 * Rozená
	 */
	Born?: string

	/**
	 * Právo na záznam
	 */
	RightGroupId?: number

	/**
	 * Komentář
	 */
	Comment?: string

	/**
	 * Násl. tel.
	 */
	NextPhoneCallDate?: Date

	/**
	 * Posl. tel.
	 */
	LastPhoneCallDate?: Date

	/**
	 * Násl. náv.
	 */
	NextVisitDate?: Date

	/**
	 * Posl. náv.
	 */
	LastVisitDate?: Date

	/**
	 * Tel. do
	 */
	PhoneToTime?: Date

	/**
	 * Tel. od
	 */
	PhoneSinceTime?: Date

	/**
	 * Náv. do
	 */
	VisitToTime?: Date

	/**
	 * Náv. od
	 */
	VisitSinceTime?: Date

	/**
	 * Vytvořeno
	 */
	CreatedOn?: Date

	/**
	 * Změněno
	 */
	ChangedOn?: Date

	/**
	 * Dat. nar.
	 */
	BirthDate?: Date

	/**
	 * Rod. číslo
	 */
	BirthIdNumber?: string

	/**
	 * Jméno
	 */
	Name?: string

	/**
	 * Příjmení
	 */
	Surname?: string

	/**
	 * Číslo
	 */
	Id?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	BookmarkImgCalc?: string

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * SQLSequenceOrderCalc
	 */
	SQLSequenceOrderCalc?: number

	/**
	 * Datum pro "_ver" pole
	 */
	VersionedFieldDateCalc?: Date

	/**
	 * Odkaz
	 */
	PictureCalc?: string

	/**
	 * RČ 1
	 */
	BirthIdNumberPart1Calc?: string

	/**
	 * RČ 2
	 */
	BirthIdNumberPart2Calc?: string

	/**
	 * Jméno a příjmení
	 */
	SurenameForenameCalc?: string

	/**
	 * Jako interní
	 */
	AsInternalBitCalc?: number

	/**
	 * undefined
	 */
	CommentActivityTreeCalc?: string

	/**
	 * undefined
	 */
	ActivityCountCalc?: number

	/**
	 * El. adresa 1
	 */
	ElectronicAddress1Calc?: string

	/**
	 * El. adresa 2
	 */
	ElectronicAddress2Calc?: string

	/**
	 * El. adresa 3
	 */
	ElectronicAddress3Calc?: string

	/**
	 * El. adresa 4
	 */
	ElectronicAddress4Calc?: string

	/**
	 * Celé jméno 1
	 */
	FullNameCalc?: string

	/**
	 * undefined
	 */
	ElectronicAddressCheckItemFieldCalc?: number

	/**
	 * Celé jméno 2
	 */
	FullNameTitleCalc?: string

	/**
	 * Administrátor
	 */
	IsAdministratorCalc?: string

	/**
	 * undefined
	 */
	LoginNameCalc?: string

	/**
	 * El. adresa 1
	 */
	ElectronicAddress1FormattedCalc?: string

	/**
	 * El. adresa 2
	 */
	ElectronicAddress2FormattedCalc?: string

	/**
	 * El. adresa 3
	 */
	ElectronicAddress3FormattedCalc?: string

	/**
	 * El. adresa 4
	 */
	ElectronicAddress4FormattedCalc?: string

	/**
	 * undefined
	 */
	MultiContactPersonImgCalc?: string

	/**
	 * undefined
	 */
	IsExchangeSyncCalc?: string

	/**
	 * undefined
	 */
	IsExchangeActiveCalc?: string

	/**
	 * Telefon (eshop)
	 */
	EshopPhoneCalc?: string

	/**
	 * Email (eshop)
	 */
	EshopEmailCalc?: string

	/**
	 * Email povoleno (eshop)
	 */
	AllowEshopEmailSendInformationCalc?: string

	/**
	 * ListPartnerCountCalc
	 */
	ListPartnerCountCalc?: number

	/**
	 * Hlavní obrázek
	 */
	FirstPictureFileNameCalc?: string

	/**
	 * PartnerAsStringCountCalc
	 */
	PartnerAsStringCountCalc?: string

	/**
	 * Zpracování omezeno
	 */
	LimitedProcessingBitCalc?: number

	/**
	 * Komentář marketingu
	 */
	PlainCommentMarketingCalc?: string

	/**
	 * Prohlížení údajů webu, e-shopu
	 */
	BrowseWebDataCalc?: string

	/**
	 * El. adresa
	 */
	EAddressPARAMCalc?: string

	/**
	 * undefined
	 */
	TextHeaderMemoCalc?: string

	/**
	 * undefined
	 */
	TextFooterMemoCalc?: string

	/**
	 * Poslední komentář
	 */
	LastCommentParamCalc?: string

	/**
	 * Právo na záznam
	 */
	RightGroupIdCalc?: number

	/**
	 * Kód 6
	 */
	@Type(() => TCode6DocumentDM)
	Code6Id?: TCode6DocumentDM

	/**
	 * Kód 5
	 */
	@Type(() => TCode5DocumentDM)
	Code5Id?: TCode5DocumentDM

	/**
	 * Kód 4
	 */
	@Type(() => TCode4DocumentDM)
	Code4Id?: TCode4DocumentDM

	/**
	 * Kód 3
	 */
	@Type(() => TCode3DocumentDM)
	Code3Id?: TCode3DocumentDM

	/**
	 * Středisko
	 */
	@Type(() => TCostCentreDocumentDM)
	CostCentreId?: TCostCentreDocumentDM

	/**
	 * Prostředek
	 */
	@Type(() => TDeviceDocumentDM)
	DeviceRID?: TDeviceDocumentDM

	/**
	 * Typ
	 */
	@Type(() => TOfficerDocumentDM)
	PersonTypeId?: TOfficerDocumentDM

	/**
	 * Skupina
	 */
	@Type(() => ShopGroupId)
	EshopGroupId?: ShopGroupId

	/**
	 * Kód zakázky
	 */
	@Type(() => TContractCodeDocumentDM)
	ContractCodeRID?: TContractCodeDocumentDM

	/**
	 * GroupContPerson
	 */
	@Type(() => TContactPersonGroupDM)
	ContactPersonGroupRID?: TContactPersonGroupDM

	/**
	 * Kód 2
	 */
	@Type(() => TCode2DocumentDM)
	Code2Id?: TCode2DocumentDM

	/**
	 * Kód 1
	 */
	@Type(() => TCode1DocumentDM)
	Code1Id?: TCode1DocumentDM

	/**
	 * Pracovník
	 */
	@Type(() => TEmployedPersonSimpleDM)
	EmployedPersonId?: TEmployedPersonSimpleDM

	/**
	 * Titul za jménem
	 */
	@Type(() => TitleName)
	TitleAfterName?: TitleName

	/**
	 * Uživatel
	 */
	@Type(() => TK2UserLookupDM)
	UserId?: TK2UserLookupDM

	/**
	 * Postavení
	 */
	@Type(() => PositionName)
	PositionId?: PositionName

	/**
	 * Funkce
	 */
	@Type(() => FunctionName)
	FunctionId?: FunctionName

	/**
	 * Akvizice
	 */
	@Type(() => AcquisitionName)
	AcquisitionId?: AcquisitionName

	/**
	 * Spokojenost
	 */
	@Type(() => SatisfactionName)
	SatisfactionId?: SatisfactionName

	/**
	 * Tel. den
	 */
	@Type(() => TTextDM)
	PhoneDay?: TTextDM

	/**
	 * Náv. den
	 */
	@Type(() => TTextDM)
	VisitingDay?: TTextDM

	/**
	 * Priorita
	 */
	@Type(() => PriorityName)
	PriorityId?: PriorityName

	/**
	 * Oddělení
	 */
	@Type(() => DepartmentName)
	DepartmentId?: DepartmentName

	/**
	 * Vytvořil
	 */
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM

	/**
	 * Změnil
	 */
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM

	/**
	 * Dopr. do zam.
	 */
	@Type(() => TransportTypeName)
	TransportTypeId?: TransportTypeName

	/**
	 * Kancelář
	 */
	@Type(() => OfficeName)
	OfficeId?: OfficeName

	/**
	 * Stát
	 */
	@Type(() => TCountryDM)
	CountryId?: TCountryDM

	/**
	 * Stav
	 */
	@Type(() => MaritalStatus)
	MaritalStatus?: MaritalStatus

	/**
	 * Národnost
	 */
	@Type(() => Nationality)
	Nationality?: Nationality

	/**
	 * Titul
	 */
	@Type(() => TitleName)
	Title?: TitleName

	/**
	 * Tisková skupina
	 */
	@Type(() => TSpecialPrinterGroup)
	Ex_PrinterGroupRID?: TSpecialPrinterGroup

	/**
	 * Z
	 */
	@Type(() => TTextDM)
	InvalidRecordImgCalc?: TTextDM

	/**
	 * Měsíc nar.
	 */
	@Type(() => TTextDM)
	BirthMonthIdCalc?: TTextDM

	/**
	 * Typ el. adresy 1
	 */
	@Type(() => TElectronicAddressTypeDM)
	ElectronicAddress1TypeIdCalc?: TElectronicAddressTypeDM

	/**
	 * Typ el. adresy 2
	 */
	@Type(() => TElectronicAddressTypeDM)
	ElectronicAddress2TypeIdCalc?: TElectronicAddressTypeDM

	/**
	 * Typ el. adresy 3
	 */
	@Type(() => TElectronicAddressTypeDM)
	ElectronicAddress3TypeIdCalc?: TElectronicAddressTypeDM

	/**
	 * Typ el. adresy 4
	 */
	@Type(() => TElectronicAddressTypeDM)
	ElectronicAddress4TypeIdCalc?: TElectronicAddressTypeDM

	/**
	 * Stát el. adresy 1
	 */
	@Type(() => TCountryDM)
	ElectronicAddressCountry1IdCalc?: TCountryDM

	/**
	 * Stát el. adresy 2
	 */
	@Type(() => TCountryDM)
	ElectronicAddressCountry2IdCalc?: TCountryDM

	/**
	 * Stát el. adresy 3
	 */
	@Type(() => TCountryDM)
	ElectronicAddressCountry3IdCalc?: TCountryDM

	/**
	 * Stát el. adresy 4
	 */
	@Type(() => TCountryDM)
	ElectronicAddressCountry4IdCalc?: TCountryDM

	/**
	 * Partner
	 */
	@Type(() => TPartnerDM)
	PartnerRIDCalc?: TPartnerDM

	/**
	 * undefined
	 */
	@Type(() => TPartnerDM)
	PartnerForEshopRIDCalc?: TPartnerDM

	/**
	 * undefined
	 */
	@Type(() => TEshopRegistrationDM)
	CurrentRegistrationRIDCalc?: TEshopRegistrationDM

	/**
	 * Poslední průchod v docházce
	 */
	@Type(() => TAttendanceMovementDM)
	AttendanceLastMovementRIDCalc?: TAttendanceMovementDM

	/**
	 * Přítomnost v docházce
	 */
	@Type(() => TTextDM)
	AttendancePresenceIdCalc?: TTextDM

	/**
	 * D
	 */
	@Type(() => TTextDM)
	DocumentExistsCalc?: TTextDM

	/**
	 * undefined
	 */
	@Type(() => TMarketingActivityTypeDM)
	ActivityTypeRIDCalc?: TMarketingActivityTypeDM

	/**
	 * SP
	 */
	@Type(() => TTextDM)
	ProcessStateIdCalc?: TTextDM

	/**
	 * Aktuální proces
	 */
	@Type(() => TWorkflowProcessDM)
	CurrentProcessIdCalc?: TWorkflowProcessDM

	/**
	 * undefined
	 */
	@Type(() => TActivityDM)
	ActivityIdCalc?: TActivityDM

	/**
	 * Odkazy
	 */
	@Type(() => TLinkDM)
	LinkChild?: TLinkDM[]

	/**
	 * Komentáře
	 */
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]

	/**
	 * Úkoly
	 */
	@Type(() => TJobDM)
	JobChild?: TJobDM[]

	/**
	 * Kvalifikace
	 */
	@Type(() => TQualificationContactPersonDM)
	TrainingChild?: TQualificationContactPersonDM[]

	/**
	 * undefined
	 */
	@Type(() => TOrgStrEmployeeDM)
	OrgEmployeeChild?: TOrgStrEmployeeDM[]

	/**
	 * Partneři
	 */
	@Type(() => TContactPersonPartnerDM)
	PartnerChild?: TContactPersonPartnerDM[]

	/**
	 * undefined
	 */
	@Type(() => TActivityDM)
	ListActivityChild?: TActivityDM[]

	/**
	 * undefined
	 */
	@Type(() => TContactPersonAddressDM)
	AddressForEshopChild?: TContactPersonAddressDM[]

	/**
	 * undefined
	 */
	@Type(() => TEshopFavoriteDM)
	EShopFavoriteChild?: TEshopFavoriteDM[]

	/**
	 * undefined
	 */
	@Type(() => TDiscountCouponDM)
	CouponChild?: TDiscountCouponDM[]

	/**
	 * undefined
	 */
	@Type(() => TOrgStrTreeChildPersonDM)
	ContactPersonChild?: TOrgStrTreeChildPersonDM[]

	/**
	 * undefined
	 */
	@Type(() => TOnePersonAbsenceDM)
	AbsenceChild?: TOnePersonAbsenceDM[]

	/**
	 * undefined
	 */
	@Type(() => TWorkflowProcedureRespPersonDM)
	WorkflowProcedureRespPersonChild?: TWorkflowProcedureRespPersonDM[]

	/**
	 * undefined
	 */
	@Type(() => TEshopRegistrationDM)
	EshopRegistrationChild?: TEshopRegistrationDM[]

	/**
	 * undefined
	 */
	@Type(() => TElectronicAddressDM)
	ElectronicAddressChild?: TElectronicAddressDM[]

	/**
	 * undefined
	 */
	@Type(() => TMarketingFamilyMemberDM)
	MarketingFamilyMemberChild?: TMarketingFamilyMemberDM[]

	/**
	 * undefined
	 */
	@Type(() => TContactPersonEducationDM)
	EducationChild?: TContactPersonEducationDM[]

	/**
	 * undefined
	 */
	@Type(() => TWorkOfficialPositionDM)
	WorkOfficialPositionChild?: TWorkOfficialPositionDM[]

	/**
	 * undefined
	 */
	@Type(() => TMarketingSubsumptionDM)
	MarketingSubsumptionChild?: TMarketingSubsumptionDM[]

	/**
	 * undefined
	 */
	@Type(() => TEmploymentHistoryDM)
	EmploymentHistoryChild?: TEmploymentHistoryDM[]

	/**
	 * undefined
	 */
	@Type(() => TMarketingLanguageDM)
	LanguageChild?: TMarketingLanguageDM[]

	/**
	 * undefined
	 */
	@Type(() => TContactPersonNatureDM)
	ContactPersonNatureChild?: TContactPersonNatureDM[]

	/**
	 * undefined
	 */
	@Type(() => TMarketingHobbyDM)
	MarketingHobbyChild?: TMarketingHobbyDM[]

	/**
	 * undefined
	 */
	@Type(() => TMarketingGiftDM)
	GiftChild?: TMarketingGiftDM[]

	/**
	 * undefined
	 */
	@Type(() => TContactPersonAddressItemDM)
	AddressChild?: TContactPersonAddressItemDM[]

	/**
	 * undefined
	 */
	@Type(() => TAttendancePersonIdentifyItemDM)
	IdentificationChild?: TAttendancePersonIdentifyItemDM[]

	/**
	 * undefined
	 */
	@Type(() => TAttendancePersonWHModelItemDM)
	WHModelChild?: TAttendancePersonWHModelItemDM[]

	/**
	 * undefined
	 */
	@Type(() => TAttendancePersonWorkplaceItemDM)
	WorkPlaceChild?: TAttendancePersonWorkplaceItemDM[]

	/**
	 * undefined
	 */
	@Type(() => TAttendanceMovementDM)
	AttendanceMovementChild?: TAttendanceMovementDM[]

	/**
	 * undefined
	 */
	@Type(() => TAttendanceWorkTimeReportDM)
	AttendanceWorkTimeReportChild?: TAttendanceWorkTimeReportDM[]

	/**
	 * undefined
	 */
	@Type(() => TAttendanceScheduleDM)
	AttendanceScheduleChild?: TAttendanceScheduleDM[]

	/**
	 * undefined
	 */
	@Type(() => TSetContactPersonAddressingDM)
	AddressingChild?: TSetContactPersonAddressingDM[]

	/**
	 * undefined
	 */
	@Type(() => TActivityTreeGroupedDM)
	ActivityTreeGroupedChild?: TActivityTreeGroupedDM[]

	/**
	 * undefined
	 */
	@Type(() => TActivityDM)
	ActivityChild?: TActivityDM[]

	/**
	 * Dokumenty
	 */
	@Type(() => TExternalDocumentItemDM)
	ExternalDocumentItemChild?: TExternalDocumentItemDM[]

	/**
	 * Procesy
	 */
	@Type(() => TWorkflowProcessAsItemDM)
	WorkflowProcessChild?: TWorkflowProcessAsItemDM[]

	/**
	 * Text hlavičky
	 */
	@Type(() => TLanguageMemoTranslationDM)
	TextHeaderChild?: TLanguageMemoTranslationDM[]

	/**
	 * Text patičky
	 */
	@Type(() => TLanguageMemoTranslationDM)
	TextFooterChild?: TLanguageMemoTranslationDM[]

	/**
	 * Svázané doklady
	 */
	@Type(() => TAttachedDocumentDM)
	AttachedDocumentOldChild?: TAttachedDocumentDM[]

	/**
	 * Poznámky
	 */
	@Type(() => TOldNoteDM)
	DeprecatedNoteOldChild?: TOldNoteDM[]

	/**
	 * Zneplatnění
	 */
	public BulkInvalidationCOMMAND() {
		return this.executeCommand('BulkInvalidationCOMMAND')
	}

	/**
	 * Zplatnění
	 */
	public BulkUnInvalidationCOMMAND() {
		return this.executeCommand('BulkUnInvalidationCOMMAND')
	}

	/**
	 * Zneplatnění/Zplatnění
	 */
	public InvalidationCOMMAND() {
		return this.executeCommand('InvalidationCOMMAND')
	}

	/**
	 * Potvrzení
	 */
	public BulkConfirmCOMMAND() {
		return this.executeCommand('BulkConfirmCOMMAND')
	}

	/**
	 * Odpotvrzení
	 */
	public BulkUnconfirmCOMMAND() {
		return this.executeCommand('BulkUnconfirmCOMMAND')
	}

	/**
	 * Změna data potvrzení
	 */
	public BulkChangeConfirmationDateCOMMAND(parameters: TBulkChangeConfirmationDateCOMMANDParams) {
		return this.executeCommand('BulkChangeConfirmationDateCOMMAND', parameters)
	}

	/**
	 * Storno
	 */
	public BulkCancelCOMMAND() {
		return this.executeCommand('BulkCancelCOMMAND')
	}

	/**
	 * Odstorno
	 */
	public BulkUnCancelCOMMAND() {
		return this.executeCommand('BulkUnCancelCOMMAND')
	}

	/**
	 * Potvrzení
	 */
	public ConfirmCOMMAND() {
		return this.executeCommand('ConfirmCOMMAND')
	}

	/**
	 * Odpotvrzení
	 */
	public UnconfirmCOMMAND() {
		return this.executeCommand('UnconfirmCOMMAND')
	}

	/**
	 * Změna data potvrzení
	 */
	public ChangeConfirmationDateCOMMAND(parameters: TChangeConfirmationDateCOMMANDParams) {
		return this.executeCommand('ChangeConfirmationDateCOMMAND', parameters)
	}

	/**
	 * Storno/Odstorno
	 */
	public CancelCOMMAND() {
		return this.executeCommand('CancelCOMMAND')
	}

	/**
	 * Zrušení stavu
	 */
	public ClearRecordStatusCOMMAND() {
		return this.executeCommand('ClearRecordStatusCOMMAND')
	}

	/**
	 * Změna stavu
	 */
	public ChangeRecordStatusCOMMAND() {
		return this.executeCommand('ChangeRecordStatusCOMMAND')
	}

	/**
	 * 
	 */
	public ChangeRecordStatusSWSCOMMAND(parameters: TChangeRecordStatusSWSCOMMANDParams) {
		return this.executeCommand('ChangeRecordStatusSWSCOMMAND', parameters)
	}

	/**
	 * Zrušení stavu
	 */
	public BulkClearRecordStatusCOMMAND() {
		return this.executeCommand('BulkClearRecordStatusCOMMAND')
	}

	/**
	 * Změna stavu
	 */
	public BulkChangeRecordStatusCOMMAND() {
		return this.executeCommand('BulkChangeRecordStatusCOMMAND')
	}

	/**
	 * Změna atributů
	 */
	public BulkChangeAttributesCOMMAND(parameters: TBulkChangeAttributesCOMMANDParams) {
		return this.executeCommand('BulkChangeAttributesCOMMAND', parameters)
	}

	/**
	 * Aktualizace sady atributů
	 */
	public BulkActualizeAttributesCOMMAND(parameters: TBulkActualizeAttributesCOMMANDParams) {
		return this.executeCommand('BulkActualizeAttributesCOMMAND', parameters)
	}

	/**
	 * Aktualizace sady atributů
	 */
	public ActualizeAttributesCOMMAND() {
		return this.executeCommand('ActualizeAttributesCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public ChangeGroupRightCOMMAND() {
		return this.executeCommand('ChangeGroupRightCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public BulkChangeGroupRightCOMMAND() {
		return this.executeCommand('BulkChangeGroupRightCOMMAND')
	}

	/**
	 * Aktualizace počítaných polí
	 */
	public BulkUpdateCalcFieldsCOMMAND() {
		return this.executeCommand('BulkUpdateCalcFieldsCOMMAND')
	}

	/**
	 * Kontrola legislativy
	 */
	public SwitchOffLegislationValidationCOMMAND() {
		return this.executeCommand('SwitchOffLegislationValidationCOMMAND')
	}
}

export type TBulkChangeConfirmationDateCOMMANDParams = {
	/**
	 * Datum potvrzení
	 */
	Confirmation1Date?: Date
	/**
	 * Hodina potvrzení
	 */
	Confirmation1Time?: Date
	/**
	 * Datum 2. potvrzení
	 */
	Confirmation2Date?: Date
	/**
	 * Hodina 2. potvrzení
	 */
	Confirmation2Time?: Date
	/**
	 * Kontrolovat záporné stavy
	 */
	CheckNegativeState?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TChangeConfirmationDateCOMMANDParams = {
	/**
	 * Datum potvrzení
	 */
	Confirmation1Date?: Date
	/**
	 * Hodina potvrzení
	 */
	Confirmation1Time?: Date
	/**
	 * Datum 2. potvrzení
	 */
	Confirmation2Date?: Date
	/**
	 * Hodina 2. potvrzení
	 */
	Confirmation2Time?: Date
	/**
	 * Kontrolovat záporné stavy
	 */
	CheckNegativeState?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TChangeRecordStatusSWSCOMMANDParams = {
	/**
	 * StatusId
	 */
	StatusId?: number
}

export type TBulkChangeAttributesCOMMANDParams = {
	/**
	 * Smazat atributy
	 */
	DeleteAttribute?: boolean
	/**
	 * Smazat všechny volné atributy
	 */
	DeleteFreeAttribute?: boolean
	/**
	 * Nastavit hodnoty atributů
	 */
	SetValues?: boolean
	/**
	 * Přidat nepřipojené atributy
	 */
	AddFreeAttributes?: boolean
	/**
	 * FreeAttributeEnabled
	 */
	FreeAttributeEnabled?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TBulkActualizeAttributesCOMMANDParams = {
	/**
	 * Aktualizovat včetně samotné sady atributů
	 */
	ActualizeAttributeSet?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}