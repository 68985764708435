import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TCodeTypeDM from './TCodeTypeDM'
import TK2UserLookupDM from './TK2UserLookupDM'
import TDeliveryLineDM from './TDeliveryLineDM'
import TTradingPartnerDM from './TTradingPartnerDM'

/**
 * Firma - Transport
 */
export default class TTradingPartnerTransportDM extends Data {
	public static className = 'TTradingPartnerTransportDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = false

	/**
	 * Pořadí
	 */
	SequenceNumber?: number

	/**
	 * Závozník požadován
	 */
	IsHaulerRequired?: string

	/**
	 * Vzdálenost
	 */
	Distance?: number

	/**
	 * Doba vykládky
	 */
	UnloadingTime?: number

	/**
	 * Doba přepravy
	 */
	ShippingTime?: number

	/**
	 * Časové razítko
	 */
	TimeStamp?: string

	/**
	 * Změněno
	 */
	ChangedOn?: Date

	/**
	 * Vytvořeno
	 */
	CreatedOn?: Date

	/**
	 * Aktivní
	 */
	IsActive?: string

	/**
	 * Časové okno do
	 */
	TimeTo?: Date

	/**
	 * Časové okno od
	 */
	TimeFrom?: Date

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Vykládka
	 */
	@Type(() => TCodeTypeDM)
	UnloadTypeId?: TCodeTypeDM

	/**
	 * Změnil
	 */
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM

	/**
	 * Vytvořil
	 */
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM

	/**
	 * Linka
	 */
	@Type(() => TDeliveryLineDM)
	DeliveryLineId?: TDeliveryLineDM

	/**
	 * Firma
	 */
	@Type(() => TTradingPartnerDM)
	TradingPartnerId?: TTradingPartnerDM
}