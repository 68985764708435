import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TRightUserRoleDM from './TRightUserRoleDM'

/**
 * Práva uživatelské role
 */
export default class TUserRoleRightDM extends Data {
	public static className = 'TUserRoleRightDM'
	public static primaryKey = 'UserRightId'
	public static systemFields = [
	]

	public getPrimaryKey(): PrimaryKey {
		return this.UserRightId!
	}

	public static isHeader = true

	/**
	 * UserRightId
	 */
	UserRightId?: string

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * SQLSequenceOrderCalc
	 */
	SQLSequenceOrderCalc?: number

	/**
	 * Číslo
	 */
	RightNumberCalc?: number

	/**
	 * Název
	 */
	CaptionCalc?: string

	/**
	 * Modul
	 */
	ModuleNameCalc?: string

	/**
	 * Funkce
	 */
	FunctionalityCalc?: string

	/**
	 * undefined
	 */
	@Type(() => TRightUserRoleDM)
	UserRoleChild?: TRightUserRoleDM[]

	/**
	 * Změna skupiny práv
	 */
	public ChangeGroupRightCOMMAND() {
		return this.executeCommand('ChangeGroupRightCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public BulkChangeGroupRightCOMMAND() {
		return this.executeCommand('BulkChangeGroupRightCOMMAND')
	}

	/**
	 * Aktualizace počítaných polí
	 */
	public BulkUpdateCalcFieldsCOMMAND() {
		return this.executeCommand('BulkUpdateCalcFieldsCOMMAND')
	}

	/**
	 * Kontrola legislativy
	 */
	public SwitchOffLegislationValidationCOMMAND() {
		return this.executeCommand('SwitchOffLegislationValidationCOMMAND')
	}
}