import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TExternalBatchDM from './TExternalBatchDM'
import TCode6DocumentDM from './TCode6DocumentDM'
import TCode5DocumentDM from './TCode5DocumentDM'
import TCode4DocumentDM from './TCode4DocumentDM'
import TCode3DocumentDM from './TCode3DocumentDM'
import TBookDM from './TBookDM'
import TJobCardResourceDM from './TJobCardResourceDM'
import TArticleCodeDocumentDM from './TArticleCodeDocumentDM'
import TCostCentreCollectionDocumentDM from './TCostCentreCollectionDocumentDM'
import TJobCardOperationDM from './TJobCardOperationDM'
import TJobCardDM from './TJobCardDM'
import TDeviceDocumentDM from './TDeviceDocumentDM'
import THandlingUnitArticleDM from './THandlingUnitArticleDM'
import TContractCodeDocumentDM from './TContractCodeDocumentDM'
import TTextDM from './TTextDM'
import PlanType from './PlanType'
import TRequestedBatchParameterHeaderDM from './TRequestedBatchParameterHeaderDM'
import TCode2DocumentDM from './TCode2DocumentDM'
import TCode1DocumentDM from './TCode1DocumentDM'
import TRoutingVariantDM from './TRoutingVariantDM'
import TLocationArticleDM from './TLocationArticleDM'
import TOfficerDocumentDM from './TOfficerDocumentDM'
import TMeasureUnitDM from './TMeasureUnitDM'
import TWarehouseDM from './TWarehouseDM'
import TBatchDM from './TBatchDM'
import GoodsName from './GoodsName'
import TArticleDM from './TArticleDM'
import TParameterSetDM from './TParameterSetDM'
import KindAccounting from './KindAccounting'
import TCommentDM from './TCommentDM'
import TItemNoteDM from './TItemNoteDM'
import TSerialNumberItemDM from './TSerialNumberItemDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TAttachedBatchParameterValueDM from './TAttachedBatchParameterValueDM'
import TAttachedRequestedBatchParameterDM from './TAttachedRequestedBatchParameterDM'
import TJobCardSubordinateDocumentDM from './TJobCardSubordinateDocumentDM'

/**
 * Položky průvodky
 */
export default class TAccountingFinanceJobCardItemDM extends Data {
	public static className = 'TAccountingFinanceJobCardItemDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = false

	/**
	 * Nadřízená položka
	 */
	SuperiorItemRID?: number

	/**
	 * InverseTranFormula
	 */
	IsInversedTransactionFormula?: string

	/**
	 * PriceRedef
	 */
	IsRedefinedPrice?: string

	/**
	 * InsertedLater
	 */
	IsInsertedLater?: string

	/**
	 * Substitution
	 */
	IsSubstitution?: string

	/**
	 * Supplement
	 */
	IsSupplement?: string

	/**
	 * Násobit množství
	 */
	MultiplyQuantity?: string

	/**
	 * PlanJobCard
	 */
	IsPlanningJobCard?: string

	/**
	 * IgnorePrice
	 */
	IgnoreReceiptPrices?: string

	/**
	 * DataEntry
	 */
	IsCompletionItem?: string

	/**
	 * Nevytvářet podřízené průvodky
	 */
	NotCreateSubJobCards?: string

	/**
	 * Z dispozice
	 */
	IsPromissedAvailability?: string

	/**
	 * NoRecordActualQuantity
	 */
	NotRecordActualQuantity?: string

	/**
	 * Rezervováno
	 */
	IsReserved?: string

	/**
	 * Č. pol. TP
	 */
	RoutingVariantItemRID?: number

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * Dat. rez.
	 */
	ReservationDate?: Date

	/**
	 * Pol. výrobku
	 */
	ProductItem?: number

	/**
	 * Množ. TP
	 */
	RoutingQuantity?: number

	/**
	 * Podř. prův.
	 */
	JobCardBindId?: number

	/**
	 * Nákl. cena / MJ
	 */
	UnitCostPrice?: number

	/**
	 * Výkres
	 */
	Drawing?: string

	/**
	 * Pozice
	 */
	DrawingPosition?: string

	/**
	 * Potvrdil
	 */
	ConfirmedById?: number

	/**
	 * Datum potvrzení
	 */
	ItemConfirmedOn?: Date

	/**
	 * Plán. cena / MJ
	 */
	PlannedUnitPrice?: number

	/**
	 * Datum výdeje
	 */
	ReleaseDate?: Date

	/**
	 * Kontace
	 */
	PostingKeyId?: number

	/**
	 * Skut. cena / MJ
	 */
	UnitPrice?: number

	/**
	 * Skut. množství
	 */
	Quantity?: number

	/**
	 * Plán. množství
	 */
	PlannedQuantity?: number

	/**
	 * Datum potvrzení
	 */
	ConfirmedOn?: Date

	/**
	 * Číslo pol.
	 */
	ItemId?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * undefined
	 */
	CorrectionJobCardOperationRID?: number

	/**
	 * undefined
	 */
	CorrectionHeaderRID?: number

	/**
	 * undefined
	 */
	OriginalRID?: number

	/**
	 * undefined
	 */
	OperationCode?: string

	/**
	 * undefined
	 */
	OperationSequenceNumber?: number

	/**
	 * undefined
	 */
	ArtOverheads?: number

	/**
	 * undefined
	 */
	ArtIsOverheadsInPercentage?: string

	/**
	 * undefined
	 */
	ArtArticleTypeId?: number

	/**
	 * undefined
	 */
	ArtArticleCategoryId?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Mn&ožství
	 */
	PlannedQuantityCalc?: number

	/**
	 * &Množství
	 */
	ActualQuantityCalc?: number

	/**
	 * Cena/MJ
	 */
	PlannedUnitPriceCalc?: number

	/**
	 * Cena celkem
	 */
	PlannedTotalPriceCalc?: number

	/**
	 * Cena/MJ
	 */
	ActualUnitPriceCalc?: number

	/**
	 * Cena celkem
	 */
	ActualTotalPriceCalc?: number

	/**
	 * R
	 */
	ReservedBitCalc?: string

	/**
	 * V
	 */
	IsReleasedCalc?: string

	/**
	 * W
	 */
	JobCardExistsCalc?: string

	/**
	 * Název
	 */
	NameCalc?: string

	/**
	 * Dispozice
	 */
	AvailableCalc?: number

	/**
	 * Z
	 */
	SubstitutionOrSupplementCalc?: string

	/**
	 * Čas pl.
	 */
	PlannedTimeCalc?: number

	/**
	 * Čas sk.
	 */
	ActualTimeCalc?: number

	/**
	 * Množ*Čas pl.
	 */
	PlannedTimeQuantityCalc?: number

	/**
	 * Množ*Čas sk.
	 */
	ActualTimeQuantityCalc?: number

	/**
	 * Kon. výroby
	 */
	IsConfirmedCalc?: string

	/**
	 * Skut.množ.-r.č.
	 */
	ActualQuantityRNCalc?: number

	/**
	 * Rezervováno
	 */
	IsReservedCalc?: string

	/**
	 * Množ.pl.abs.
	 */
	PlannedPositiveQuantityCalc?: number

	/**
	 * D
	 */
	PlannedQuantitySignCalc?: number

	/**
	 * Množ.sk.abs.
	 */
	ActualPositiveQuantityCalc?: number

	/**
	 * D
	 */
	ActualQuantitySignCalc?: number

	/**
	 * D
	 */
	PlannedQuantitySignImgCalc?: string

	/**
	 * D
	 */
	ActualQuantitySignImgCalc?: string

	/**
	 * Název celý
	 */
	FullNameCalc?: string

	/**
	 * Čas zb.
	 */
	RemainingTimeCalc?: number

	/**
	 * Nezáv. oper.
	 */
	IndependentOperationBitCalc?: number

	/**
	 * Čárový kód
	 */
	BarcodeCalc?: string

	/**
	 * Datum výdeje
	 */
	ReleaseDateCalc?: Date

	/**
	 * Čas
	 */
	ReleaseTimeCalc?: Date

	/**
	 * Datum rez.
	 */
	ReservationDateCalc?: Date

	/**
	 * Čas
	 */
	ReservationTimeCalc?: Date

	/**
	 * Zboží - druh
	 */
	ArticleCategoryCalc?: number

	/**
	 * Zboží - typ
	 */
	ArticleTypeCalc?: number

	/**
	 * Zboží - příznaky
	 */
	IsArticlePercentForOverheadsCalc?: string

	/**
	 * Zboží - režie
	 */
	ArticleOverheadsCalc?: number

	/**
	 * Nekorektní počet sériových čísel
	 */
	SNIncorrectCountImgCalc?: string

	/**
	 * BatchTypeIdCalc
	 */
	BatchTypeIdCalc?: number

	/**
	 * Typ položky
	 */
	BOMTypeCalc?: number

	/**
	 * BatchRatioCalc
	 */
	BatchRatioCalc?: number

	/**
	 * Nákl. celk.
	 */
	TotalCostPriceCalc?: number

	/**
	 * Termín vykrytí
	 */
	CoverageDateCalc?: Date

	/**
	 * Popis operace
	 */
	OperationDescriptionCalc?: string

	/**
	 * Časové razítko vykrytí
	 */
	CoverageTimeStampCalc?: Date

	/**
	 * undefined
	 */
	OperationPlannedQuantityCalc?: number

	/**
	 * IgnorePrice
	 */
	IgnoreReceiptPriceCalc?: string

	/**
	 * Sériové číslo
	 */
	SerialNumberInputCalc?: string

	/**
	 * undefined
	 */
	FilterCategoriesCalc?: string

	/**
	 * undefined
	 */
	CategoryListCalc?: string

	/**
	 * undefined
	 */
	IsIgnoredActualValuesCalc?: string

	/**
	 * Text
	 */
	ItemTextCalc?: string

	/**
	 * undefined
	 */
	SerialNumberCountAsStringCalc?: string

	/**
	 * Externí šarže
	 */
	@Type(() => TExternalBatchDM)
	ExternalBatchId?: TExternalBatchDM

	/**
	 * Kód 6
	 */
	@Type(() => TCode6DocumentDM)
	Code6Id?: TCode6DocumentDM

	/**
	 * Kód 5
	 */
	@Type(() => TCode5DocumentDM)
	Code5Id?: TCode5DocumentDM

	/**
	 * Kód 4
	 */
	@Type(() => TCode4DocumentDM)
	Code4Id?: TCode4DocumentDM

	/**
	 * Kód 3
	 */
	@Type(() => TCode3DocumentDM)
	Code3Id?: TCode3DocumentDM

	/**
	 * Kniha
	 */
	@Type(() => TBookDM)
	MainDocumentBookId?: TBookDM

	/**
	 * RID zdroje
	 */
	@Type(() => TJobCardResourceDM)
	JobCardResourceRID?: TJobCardResourceDM

	/**
	 * Kód zboží
	 */
	@Type(() => TArticleCodeDocumentDM)
	ArticleCodeId?: TArticleCodeDocumentDM

	/**
	 * Středisko
	 */
	@Type(() => TCostCentreCollectionDocumentDM)
	CostCentreId?: TCostCentreCollectionDocumentDM

	/**
	 * JCOperationRID
	 */
	@Type(() => TJobCardOperationDM)
	JobCardOperationRID?: TJobCardOperationDM

	/**
	 * HeaderRID
	 */
	@Type(() => TJobCardDM)
	HeaderRID?: TJobCardDM

	/**
	 * Prostředek
	 */
	@Type(() => TDeviceDocumentDM)
	DeviceRID?: TDeviceDocumentDM

	/**
	 * Manipul. j.
	 */
	@Type(() => THandlingUnitArticleDM)
	HandlingUnitRID?: THandlingUnitArticleDM

	/**
	 * Kód zakázky
	 */
	@Type(() => TContractCodeDocumentDM)
	ContractCodeRID?: TContractCodeDocumentDM

	/**
	 * Čas operace
	 */
	@Type(() => TTextDM)
	OperationTime?: TTextDM

	/**
	 * Typ plánu
	 */
	@Type(() => PlanType)
	PlanTypeId?: PlanType

	/**
	 * Pož. vlastnosti
	 */
	@Type(() => TRequestedBatchParameterHeaderDM)
	RequestedBatchParametersId?: TRequestedBatchParameterHeaderDM

	/**
	 * Kód 2
	 */
	@Type(() => TCode2DocumentDM)
	Code2Id?: TCode2DocumentDM

	/**
	 * Kód 1
	 */
	@Type(() => TCode1DocumentDM)
	Code1Id?: TCode1DocumentDM

	/**
	 * Varianta
	 */
	@Type(() => TRoutingVariantDM)
	RoutingVariantId?: TRoutingVariantDM

	/**
	 * Číslo umístění
	 */
	@Type(() => TLocationArticleDM)
	LocationId?: TLocationArticleDM

	/**
	 * Pracovník
	 */
	@Type(() => TOfficerDocumentDM)
	WorkerId?: TOfficerDocumentDM

	/**
	 * Skladová jednotka
	 */
	@Type(() => TMeasureUnitDM)
	MeasureUnitId?: TMeasureUnitDM

	/**
	 * Číslo skladu
	 */
	@Type(() => TWarehouseDM)
	WarehouseId?: TWarehouseDM

	/**
	 * Číslo šarže
	 */
	@Type(() => TBatchDM)
	BatchId?: TBatchDM

	/**
	 * Název
	 */
	@Type(() => GoodsName)
	ArticleNameId?: GoodsName

	/**
	 * Číslo zboží
	 */
	@Type(() => TArticleDM)
	ArticleId?: TArticleDM

	/**
	 * Číslo výrobku
	 */
	@Type(() => TArticleDM)
	ProductIdCalc?: TArticleDM

	/**
	 * Nevytv. prův.
	 */
	@Type(() => TTextDM)
	SuppressCreateJobCardBitCalc?: TTextDM

	/**
	 * Nekorektní počet sériových čísel
	 */
	@Type(() => TTextDM)
	SNIncorrectCountBitCalc?: TTextDM

	/**
	 * ParameterSetIdCalc
	 */
	@Type(() => TParameterSetDM)
	ParameterSetIdCalc?: TParameterSetDM

	/**
	 * Stav vykrytí
	 */
	@Type(() => TTextDM)
	CoveredBitCalc?: TTextDM

	/**
	 *  
	 */
	@Type(() => TTextDM)
	CoveredByStockBitCalc?: TTextDM

	/**
	 * Druh účtování
	 */
	@Type(() => KindAccounting)
	PostingKindIdCalc?: KindAccounting

	/**
	 * Číslo zboží
	 */
	@Type(() => TArticleDM)
	ArticleByIdInputIdCalc?: TArticleDM

	/**
	 * Číslo zboží
	 */
	@Type(() => TArticleDM)
	ArticleByAbbr2InputIdCalc?: TArticleDM

	/**
	 * Číslo zboží
	 */
	@Type(() => TArticleDM)
	ArticleByNameInputIdCalc?: TArticleDM

	/**
	 * Komentáře
	 */
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]

	/**
	 * undefined
	 */
	@Type(() => TItemNoteDM)
	ItemNotesChild?: TItemNoteDM[]

	/**
	 * undefined
	 */
	@Type(() => TSerialNumberItemDM)
	SerialNumberItemChild?: TSerialNumberItemDM[]

	/**
	 * undefined
	 */
	@Type(() => TLanguageMemoTranslationDM)
	ItemTextChild?: TLanguageMemoTranslationDM[]

	/**
	 * undefined
	 */
	@Type(() => TAttachedBatchParameterValueDM)
	BatchParameterChild?: TAttachedBatchParameterValueDM[]

	/**
	 * undefined
	 */
	@Type(() => TAttachedRequestedBatchParameterDM)
	RequestedBatchParameterChild?: TAttachedRequestedBatchParameterDM[]

	/**
	 * undefined
	 */
	@Type(() => TJobCardSubordinateDocumentDM)
	JobCardSubordinateDocChild?: TJobCardSubordinateDocumentDM[]
}