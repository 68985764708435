import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TK2UserLookupDM from './TK2UserLookupDM'
import TTextDM from './TTextDM'

/**
 * Varianty zaplánování výroby
 */
export default class TProductionSchedulingVariantDM extends Data {
	public static className = 'TProductionSchedulingVariantDM'
	public static primaryKey = 'Id'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.Id!
	}

	public static isHeader = true

	/**
	 * Nastavení zaplánování
	 */
	SchedulingSetting?: string

	/**
	 * Skupina práv
	 */
	RightGroupId?: number

	/**
	 * Časové razítko
	 */
	TimeStamp?: string

	/**
	 * Změněno
	 */
	ChangedOn?: Date

	/**
	 * Vytvořeno
	 */
	CreatedOn?: Date

	/**
	 * Popis
	 */
	Description?: string

	/**
	 * Zkratka
	 */
	Abbr?: string

	/**
	 * Číslo
	 */
	Id?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Sdružovat podle výrobních příkazů
	 */
	GroupByProductionOrdersCalc?: string

	/**
	 * Posunout o počet dnů
	 */
	IsMoveByNumberDaysCalc?: string

	/**
	 * Posunouto o počet dnů
	 */
	MoveByNumberDaysCalc?: number

	/**
	 * Skončit v čase
	 */
	IsFinishOnTimeCalc?: string

	/**
	 * Skončeno v čase
	 */
	FinishOnTimeCalc?: Date

	/**
	 * Zaplánovat označené
	 */
	ScheduleMarkedCalc?: string

	/**
	 * Povolit kolize na zdrojích
	 */
	AllowCollisionsOnResourcesCalc?: string

	/**
	 * Optimalizovat posloupnost operací
	 */
	OptimizeOperationsSequenceCalc?: string

	/**
	 * Zohlednit termín pokrytí
	 */
	ConsiderCoverageCalc?: string

	/**
	 * Zaplánovat i nepokryté výrobní stromy
	 */
	ScheduleUncoveredTreesCalc?: string

	/**
	 * Zohlednit dodací lhůty
	 */
	ConsiderDeliveryDaysCalc?: string

	/**
	 * Max. přetížení zdrojů (%)
	 */
	MaxResourceOverloadCalc?: number

	/**
	 * Skupina práv
	 */
	RightGroupIdCalc?: number

	/**
	 * Změnil
	 */
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM

	/**
	 * Vytvořil
	 */
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM

	/**
	 * Způsob zaplánování
	 */
	@Type(() => TTextDM)
	SchedulingMethodCalc?: TTextDM

	/**
	 * Oblast bez kolizí
	 */
	@Type(() => TTextDM)
	AreaWithoutCollisionsCalc?: TTextDM

	/**
	 * Změna skupiny práv
	 */
	public ChangeGroupRightCOMMAND() {
		return this.executeCommand('ChangeGroupRightCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public BulkChangeGroupRightCOMMAND() {
		return this.executeCommand('BulkChangeGroupRightCOMMAND')
	}

	/**
	 * Aktualizace počítaných polí
	 */
	public BulkUpdateCalcFieldsCOMMAND() {
		return this.executeCommand('BulkUpdateCalcFieldsCOMMAND')
	}

	/**
	 * Kontrola legislativy
	 */
	public SwitchOffLegislationValidationCOMMAND() {
		return this.executeCommand('SwitchOffLegislationValidationCOMMAND')
	}
}