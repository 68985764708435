import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TCode6DocumentDM from './TCode6DocumentDM'
import TCode5DocumentDM from './TCode5DocumentDM'
import TCode4DocumentDM from './TCode4DocumentDM'
import TCode3DocumentDM from './TCode3DocumentDM'
import OpportAndActiStatus from './OpportAndActiStatus'
import TPartnerDM from './TPartnerDM'
import TContactPersonDM from './TContactPersonDM'
import TDeviceDocumentDM from './TDeviceDocumentDM'
import TMarketingActivityTypeDM from './TMarketingActivityTypeDM'
import TMarketingSpecificationDM from './TMarketingSpecificationDM'
import ActivityStatus from './ActivityStatus'
import TContractCodeDocumentDM from './TContractCodeDocumentDM'
import TCode2DocumentDM from './TCode2DocumentDM'
import TCode1DocumentDM from './TCode1DocumentDM'
import TOfficerDocumentDM from './TOfficerDocumentDM'
import TCostCentreCollectionDocumentDM from './TCostCentreCollectionDocumentDM'
import TK2UserLookupDM from './TK2UserLookupDM'
import TOpportunityDM from './TOpportunityDM'
import TCampaignDM from './TCampaignDM'
import TActivityDM from './TActivityDM'

/**
 * Aktivity
 */
export default class TActivityTreeGroupedDM extends Data {
	public static className = 'TActivityTreeGroupedDM'
	public static primaryKey = 'Id'
	public static systemFields = [
		'TimeStamp',
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.Id!
	}

	public static isHeader = false

	/**
	 * ID zprávy
	 */
	MessageId?: string

	/**
	 * H
	 */
	RatingId?: number

	/**
	 * Unikátní číslo
	 */
	Id?: number

	/**
	 * Rok
	 */
	YearFrom?: number

	/**
	 * IsGlobalFolder
	 */
	IsGlobalFolder?: number

	/**
	 * Časové razítko
	 */
	TimeStamp?: string

	/**
	 * DocNo
	 */
	DocumentNumber?: number

	/**
	 * DocBook
	 */
	DocumentBookId?: string

	/**
	 * DocType
	 */
	DocumentTypeId?: number

	/**
	 * Čas volání
	 */
	CallCenterCallDate?: Date

	/**
	 * Volající
	 */
	CallCentreId?: number

	/**
	 * Kód zboží
	 */
	ArticleCodeId?: number

	/**
	 * Původní aktivita
	 */
	OriginalActivityId?: number

	/**
	 * Předmět
	 */
	Subject?: number

	/**
	 * Nákladová cena
	 */
	CostPrice?: number

	/**
	 * Kdo potv.
	 */
	ConfirmedById?: number

	/**
	 * Dat. potv.
	 */
	ConfirmedOn?: Date

	/**
	 * Právo na záznam
	 */
	RightGroupId?: number

	/**
	 * Datum real.
	 */
	ExecutionDate?: Date

	/**
	 * Prav. real.
	 */
	ExecutionProbability?: number

	/**
	 * Objem
	 */
	Volume?: number

	/**
	 * Komentář
	 */
	Comment?: string

	/**
	 * Vytvořeno
	 */
	CreatedOn?: Date

	/**
	 * Změněno
	 */
	ChangedOn?: Date

	/**
	 * Datum do
	 */
	DateTo?: Date

	/**
	 * Datum od
	 */
	DateFrom?: Date

	/**
	 * Nadř. akt.
	 */
	SuperiorActivityId?: number

	/**
	 * Název
	 */
	Name?: string

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * Pořadí
	 */
	RecordNo?: number

	/**
	 * Unikátní číslo
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * undefined
	 */
	CountChild?: number

	/**
	 * undefined
	 */
	IsGroupNode?: string

	/**
	 * undefined
	 */
	IsNode?: string

	/**
	 * undefined
	 */
	HeaderRID?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * undefined
	 */
	NodeNameCalc?: string

	/**
	 * undefined
	 */
	CommentCalc?: string

	/**
	 * undefined
	 */
	IsMultiActivityCalc?: string

	/**
	 * Právo na záznam
	 */
	RightGroupIdCalc?: number

	/**
	 * Kód 6
	 */
	@Type(() => TCode6DocumentDM)
	Code6Id?: TCode6DocumentDM

	/**
	 * Kód 5
	 */
	@Type(() => TCode5DocumentDM)
	Code5Id?: TCode5DocumentDM

	/**
	 * Kód 4
	 */
	@Type(() => TCode4DocumentDM)
	Code4Id?: TCode4DocumentDM

	/**
	 * Kód 3
	 */
	@Type(() => TCode3DocumentDM)
	Code3Id?: TCode3DocumentDM

	/**
	 * StatusOpportunity
	 */
	@Type(() => OpportAndActiStatus)
	OpportunityStatusId?: OpportAndActiStatus

	/**
	 * Kontaktní osoba
	 */
	@Type(() => TPartnerDM)
	PartnerId?: TPartnerDM

	/**
	 * Kontaktní osoba
	 */
	@Type(() => TContactPersonDM)
	ContactPersonId?: TContactPersonDM

	/**
	 * Prostředek
	 */
	@Type(() => TDeviceDocumentDM)
	DeviceRID?: TDeviceDocumentDM

	/**
	 * ActivityType
	 */
	@Type(() => TMarketingActivityTypeDM)
	ActivityTypeRID?: TMarketingActivityTypeDM

	/**
	 * Specifikace
	 */
	@Type(() => TMarketingSpecificationDM)
	SpecificationRID?: TMarketingSpecificationDM

	/**
	 * Status
	 */
	@Type(() => ActivityStatus)
	StatusActivityId?: ActivityStatus

	/**
	 * Kód zakázky
	 */
	@Type(() => TContractCodeDocumentDM)
	ContractCodeRID?: TContractCodeDocumentDM

	/**
	 * Kód 2
	 */
	@Type(() => TCode2DocumentDM)
	Code2Id?: TCode2DocumentDM

	/**
	 * Kód 1
	 */
	@Type(() => TCode1DocumentDM)
	Code1Id?: TCode1DocumentDM

	/**
	 * Referent
	 */
	@Type(() => TOfficerDocumentDM)
	OfficerId?: TOfficerDocumentDM

	/**
	 * Středisko
	 */
	@Type(() => TCostCentreCollectionDocumentDM)
	CostCentreId?: TCostCentreCollectionDocumentDM

	/**
	 * Zodp. os.
	 */
	@Type(() => TContactPersonDM)
	ResponsiblePersonId?: TContactPersonDM

	/**
	 * Vytvořil
	 */
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM

	/**
	 * Změnil
	 */
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM

	/**
	 * Příležitost
	 */
	@Type(() => TOpportunityDM)
	OpportunityRID?: TOpportunityDM

	/**
	 * Kampaň
	 */
	@Type(() => TCampaignDM)
	CampaignRID?: TCampaignDM

	/**
	 * Číslo
	 */
	@Type(() => TActivityDM)
	ActivityId?: TActivityDM
}