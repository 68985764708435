import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TK2UserLookupDM from './TK2UserLookupDM'
import TTextDM from './TTextDM'
import TCode6DocumentDM from './TCode6DocumentDM'
import TCode5DocumentDM from './TCode5DocumentDM'
import TCode4DocumentDM from './TCode4DocumentDM'
import TCode3DocumentDM from './TCode3DocumentDM'
import TArticleCodeDocumentDM from './TArticleCodeDocumentDM'
import TCostCentreCollectionDocumentDM from './TCostCentreCollectionDocumentDM'
import TPeriodDM from './TPeriodDM'
import TBookDM from './TBookDM'
import DegreeName from './DegreeName'
import CZNACE from './CZNACE'
import TDeviceDocumentDM from './TDeviceDocumentDM'
import TOrgStrJobDM from './TOrgStrJobDM'
import TPrDeductionDM from './TPrDeductionDM'
import TContractCodeDocumentDM from './TContractCodeDocumentDM'
import TCurrencyDM from './TCurrencyDM'
import TPrCalendarDM from './TPrCalendarDM'
import TAccountingChartDM from './TAccountingChartDM'
import DuvUkoncPP2 from './DuvUkoncPP2'
import TCountryDM from './TCountryDM'
import SkupPrac from './SkupPrac'
import MzdZUJ from './MzdZUJ'
import CZICSE from './CZICSE'
import TTownPartDM from './TTownPartDM'
import ProfessionName from './ProfessionName'
import MzdLAU1 from './MzdLAU1'
import NUTS4 from './NUTS4'
import TMatchingSymbolDM from './TMatchingSymbolDM'
import TCode2DocumentDM from './TCode2DocumentDM'
import TCode1DocumentDM from './TCode1DocumentDM'
import Group2 from './Group2'
import Group1 from './Group1'
import Kategorie2 from './Kategorie2'
import Kategorie1 from './Kategorie1'
import TPrUnionDM from './TPrUnionDM'
import TPrMinimalTariffDM from './TPrMinimalTariffDM'
import TPrInternalTariffDM from './TPrInternalTariffDM'
import EmployeeFunction from './EmployeeFunction'
import DruhCinnosti from './DruhCinnosti'
import RelWork from './RelWork'
import TypWork from './TypWork'
import DuvUkoncPP1 from './DuvUkoncPP1'
import DuvVznikuPP1 from './DuvVznikuPP1'
import TEmployedPersonDM from './TEmployedPersonDM'
import TPrPeriodDM from './TPrPeriodDM'
import TPrEmploymentDM from './TPrEmploymentDM'
import TMarketingActivityTypeDM from './TMarketingActivityTypeDM'
import TWorkflowProcessDM from './TWorkflowProcessDM'
import TTradingPartnerDM from './TTradingPartnerDM'
import TWarehouseDM from './TWarehouseDM'
import TCommentDM from './TCommentDM'
import TPrTransferComponentDM from './TPrTransferComponentDM'
import TPrDefinitionComponentDM from './TPrDefinitionComponentDM'
import TPrInputComponentDM from './TPrInputComponentDM'
import TPrOutputComponentDM from './TPrOutputComponentDM'
import TPrHistoryComponentDM from './TPrHistoryComponentDM'
import TExternalDocumentItemDM from './TExternalDocumentItemDM'
import TWorkflowProcessAsItemDM from './TWorkflowProcessAsItemDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TPrCalendarEmploymentItemTimeDM from './TPrCalendarEmploymentItemTimeDM'
import TPrCalendarEmploymentItemDayDM from './TPrCalendarEmploymentItemDayDM'
import TPrCalendarEmploymentItemVisualDM from './TPrCalendarEmploymentItemVisualDM'
import TAttachedDocumentDM from './TAttachedDocumentDM'
import TOldNoteDM from './TOldNoteDM'

/**
 * Mzdové doklady
 */
export default class TPrEmployeeDocumentDM extends Data {
	public static className = 'TPrEmployeeDocumentDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = true

	/**
	 * Firma
	 */
	BookId_CompanyId?: number

	/**
	 * Skup. právo
	 */
	BookId_RightGroupId?: number

	/**
	 * Vytvořeno
	 */
	CreatedOn?: Date

	/**
	 * Nekopírovat analytické osy
	 */
	NotCopyAnalyticalDimensions?: string

	/**
	 * Jméno
	 */
	FirstName?: string

	/**
	 * Příjmení
	 */
	Surname?: string

	/**
	 * Osobní číslo
	 */
	EmployeePersonNumber?: string

	/**
	 * Protokol uzavření
	 */
	ConfirmationLogId?: number

	/**
	 * Protokol vytvoření záloh
	 */
	CreationPrComponentLogId?: number

	/**
	 * Protokol výpočtu
	 */
	CalculationLogId?: number

	/**
	 * Protokol předvýpočtu
	 */
	PrecalculationLogId?: number

	/**
	 * Zaměstnání malého rozsahu
	 */
	IsShortRangeEmployment?: string

	/**
	 * Vedoucí zaměstnanec
	 */
	IsExecutive?: string

	/**
	 * Bezprostřední nástup
	 */
	IsImmediateStart?: string

	/**
	 * Vynětí z evidenčního počtu
	 */
	IsExemption?: string

	/**
	 * V cizí měně
	 */
	IsInCurrency?: string

	/**
	 * Přesčasy ve mzdě
	 */
	IsNoPaidOverTime?: string

	/**
	 * Dodatková dovolená
	 */
	IsAdditionalLeave?: string

	/**
	 * Prodloužená dovolená
	 */
	IsExtendedLeave?: string

	/**
	 * Běžná dovolená
	 */
	IsNormalLeave?: string

	/**
	 * Automatický výpočet mzdy
	 */
	CalculateWageAutomatically?: string

	/**
	 * Kontrola min. mzdy
	 */
	CheckMinWage?: string

	/**
	 * XMLFieldScript
	 */
	XMLFieldScript?: string

	/**
	 * Číslo
	 */
	Number?: number

	/**
	 * První zaměstnání v ČR
	 */
	IsFirstEmploymentInCZ?: string

	/**
	 * Datum změny sml.
	 */
	ChangeContractDate?: Date

	/**
	 * Dat. podpisu sml.
	 */
	SignatureDate?: Date

	/**
	 * Kurz
	 */
	ExchangeRate?: number

	/**
	 * Uzavřeno
	 */
	ConfirmedOn?: Date

	/**
	 * Vytvořeny zálohy
	 */
	PrComponentCreatedOn?: Date

	/**
	 * Vypočteno
	 */
	CalculatedOn?: Date

	/**
	 * Předvypočteno
	 */
	PrecalculatedOn?: Date

	/**
	 * Zkušební doba
	 */
	ProbationaryPeriodMonthCount?: number

	/**
	 * Pracoviště
	 */
	WorkPlaceId?: string

	/**
	 * Předpokl. ukončení
	 */
	ExpectedTerminationDate?: Date

	/**
	 * Výpov. doba od
	 */
	NoticePeriodFromDate?: Date

	/**
	 * Konec zkuš. doby
	 */
	ProbationaryPeriodEndDate?: Date

	/**
	 * Odstupné
	 */
	RedundancyPaymentMultiple?: number

	/**
	 * Právo na záznam
	 */
	RightGroupId?: number

	/**
	 * Změněno
	 */
	ChangedOn?: Date

	/**
	 * Automat 4
	 */
	Automat4PrComponentId?: number

	/**
	 * Automat 3
	 */
	Automat3PrComponentId?: number

	/**
	 * Automat 2
	 */
	Automat2PrComponentId?: number

	/**
	 * Automat 1
	 */
	Automat1PrComponentId?: number

	/**
	 * Záloha na mzdu
	 */
	AdvanceWagePayment?: number

	/**
	 * Prémie
	 */
	WageBonus?: number

	/**
	 * Osobní ohodnocení II.
	 */
	WagePersonalBonus2?: number

	/**
	 * Osobní ohodnocení
	 */
	WagePersonalBonus?: number

	/**
	 * Základní mzda II.
	 */
	BasicWage2?: number

	/**
	 * Základní mzda
	 */
	BasicWage?: number

	/**
	 * Datum vzniku
	 */
	ContractDate?: Date

	/**
	 * Datum ukončení
	 */
	DateTo?: Date

	/**
	 * Datum nástupu
	 */
	DateFrom?: Date

	/**
	 * Pořadí
	 */
	SequenceNumber?: number

	/**
	 * Časové razítko
	 */
	TimeStamp?: string

	/**
	 * XMLField
	 */
	XMLField?: string

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	BookmarkImgCalc?: string

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Číslo
	 */
	DocumentNumberCalc?: number

	/**
	 * Úplný výpis položek
	 */
	IsItemCompleteCalc?: string

	/**
	 * Délka zkuš. doby
	 */
	ProbationaryPeriodInDaysCalc?: number

	/**
	 * Počet dnů pracovního vztahu
	 */
	EmploymentDayCountCalc?: number

	/**
	 * Hlavní pracovní vztah
	 */
	IsMainEmploymentCalc?: string

	/**
	 * Právní vztah
	 */
	LegalRelationDescriptionCalc?: string

	/**
	 * Předchozí období uzavřeno
	 */
	PreviousPeriodCloseIdCalc?: number

	/**
	 * Prošlo předvýpočtem
	 */
	PrecalculationPassedIdCalc?: number

	/**
	 * První předvýpočet proběhl
	 */
	FirstPrecalculationDoneIdCalc?: number

	/**
	 * Editováno po předvýpočtu
	 */
	EditAfterPrecalculationIdCalc?: number

	/**
	 * Potvrzení předvýpočtu
	 */
	PrecalculationConfirmationIdCalc?: number

	/**
	 * Prošlo vytvořením záloh
	 */
	AdvanceGeneratePassedIdCalc?: number

	/**
	 * Potvrzení vytvoření záloh
	 */
	GenerateAdvanceConfirmIdCalc?: number

	/**
	 * Prošlo výpočtem
	 */
	ComputeBitCalc?: number

	/**
	 * Editováno po výpočtu
	 */
	EditAfterCalculationIdCalc?: number

	/**
	 * Období uzavřeno
	 */
	PeriodIsClosedBitCalc?: number

	/**
	 * Neinicializovaný mzdový doklad
	 */
	NewPrDocumentBitCalc?: number

	/**
	 * Fond pracovní doby (dny)
	 */
	PTFEmplPerWorkTimeFundDaysCalc?: number

	/**
	 * Svátků v pracovní den
	 */
	PTFEmplPerPublicHlDaysCalc?: number

	/**
	 * PČF (v msec), v rámci platnosti PP
	 */
	PTFEmploymentPeriodTimeCalc?: number

	/**
	 * Kal. dny
	 */
	CalendarDayCountCalc?: number

	/**
	 * Kal. dny (S)
	 */
	CalendarContractDayCountCalc?: number

	/**
	 * Fond pracovní doby (dny)
	 */
	PTFTotalPerWrkTimeFundDaysCalc?: number

	/**
	 * Svátků v pracovní den
	 */
	PTFTotalPeriodPublicHlDaysCalc?: number

	/**
	 * PČF (v msec), za celé období
	 */
	PTFTotalPeriodTimeCalc?: number

	/**
	 * Děti
	 */
	EmployeeChildrenCalc?: number

	/**
	 * Děti - odpočet
	 */
	EmployeeChildrenDeductionCalc?: number

	/**
	 * Děti - invalid.
	 */
	EmployeeChildrenDisabilityCalc?: number

	/**
	 * MS z odborů
	 */
	UnionsPrComponentCalc?: number

	/**
	 * Škola
	 */
	SchoolCalc?: string

	/**
	 * Průměr pro mzdové náhrady
	 */
	AverageWageRefundsAmountCalc?: number

	/**
	 * Nárok na běžnou dovolenou v min. roce
	 */
	ClaimNormHlLastYearAmountCalc?: number

	/**
	 * Čerpáno běžné dovolené v min. roce
	 */
	TakenNormHlLastYearAmountCalc?: number

	/**
	 * Nárok na běžnou dovolenou v let. roce
	 */
	ClaimNormHlActYearAmountCalc?: number

	/**
	 * Čerpáno běžné dovolené v let. roce
	 */
	TakenNormHolActYearAmountCalc?: number

	/**
	 * Nárok na dodatkovou dovolenou v let. roce
	 */
	ClaimAddHlActYearAmountCalc?: number

	/**
	 * Čerpáno dodatkové dovolené v let. roce
	 */
	TakenAddHlActYearAmountCalc?: number

	/**
	 * Dovolená loňská - zůstatek
	 */
	RemHlPrevYearAmountCalc?: number

	/**
	 * Dovolená letošní - zůstatek
	 */
	RemHlActYearAmountCalc?: number

	/**
	 * Odpracováno [dny]
	 */
	WorkedDaysCalc?: number

	/**
	 * Hrubá mzda
	 */
	WageAmountGrossCalc?: number

	/**
	 * Čistá mzda
	 */
	WageAmountNetCalc?: number

	/**
	 * Čistý příjem
	 */
	IncomeNetCalc?: number

	/**
	 * Výplata
	 */
	WageAmountCalc?: number

	/**
	 * Vyměřovací základ ze mzdy
	 */
	HIAssessmentBaseWageAmountCalc?: number

	/**
	 * Pojistné zaměstnanec ze mzdy
	 */
	HIWageEmployeeInsuranceAmountCalc?: number

	/**
	 * Vyměřovací základ z min. mzdy
	 */
	HIAssessmentBaseMinWageAmountCalc?: number

	/**
	 * ZP - Pojistné zaměstnanec z neplac. volna
	 */
	HIEmployInsUnpaidHolAmountCalc?: number

	/**
	 * Doplatek do minima
	 */
	HIAddPaymentToMinAmountCalc?: number

	/**
	 * Pojistné zaměstnanec
	 */
	HIEmployeeInsuranceAmountCalc?: number

	/**
	 * Pojistné organizace
	 */
	HIOrganizationAmountCalc?: number

	/**
	 * Odvod celkem
	 */
	HITotalPaymentAmountCalc?: number

	/**
	 * Vyměřovací základ
	 */
	AssessmentBaseAmountCalc?: number

	/**
	 * Pojistné zaměstnanec
	 */
	EmployeeInsuranceAmountCalc?: number

	/**
	 * Pojistné organizace
	 */
	SocialOrgPremiumAmountCalc?: number

	/**
	 * Odvod celkem
	 */
	SocialTotalPaymentAmountCalc?: number

	/**
	 * Úhrn příjmů
	 */
	TaxTotatIncomesAmountCalc?: number

	/**
	 * Pojistné
	 */
	TaxInsuranceAmountCalc?: number

	/**
	 * Základ zálohové daně
	 */
	AdvanceTaxBaseAmountCalc?: number

	/**
	 * Vypočtená záloha daně
	 */
	AdvanceTaxAmountCalc?: number

	/**
	 * Poplatník
	 */
	TaxPayerDeductionCalc?: number

	/**
	 * Invalidita
	 */
	TaxDisabilityDiscountCalc?: number

	/**
	 * Student
	 */
	TaxStudentAllowanceCalc?: number

	/**
	 * Zálohová daň po slevách
	 */
	AdvanceTaxAfterDiscountsCalc?: number

	/**
	 * Nárok
	 */
	TaxClaimCalc?: number

	/**
	 * Sleva na dani
	 */
	TaxAbatementCalc?: number

	/**
	 * Daňový bonus
	 */
	TaxBonusCalc?: number

	/**
	 * Výsledná záloha daně (včetně zvýhodnění)
	 */
	ResultingTaxAdvanceCalc?: number

	/**
	 * Srážková daň
	 */
	TaxDeductionCalc?: number

	/**
	 * Daně - Oprava zálohy daně
	 */
	TaxAdvanceCorrectionCalc?: number

	/**
	 * Daně - Oprava srážkové daně
	 */
	TaxDeductionCorrectionCalc?: number

	/**
	 * Daně - Oprava daňového bonusu
	 */
	TaxBonusCorrectionCalc?: number

	/**
	 * Dosažený vym. zákl. soc. poj.
	 */
	AchieAssessBaseSocAmountCalc?: number

	/**
	 * Dosažený vym. zákl. zdr. poj.
	 */
	AchieAssessBaseHlthAmountCalc?: number

	/**
	 * Dosažený příspěvek org.
	 */
	AchievContribPensionAmountCalc?: number

	/**
	 * Nárok na prodlouženou dovolenou v min. roce
	 */
	ClaimExtHlLastYearAmountCalc?: number

	/**
	 * Čerpání prodloužené dovolené v min. roce
	 */
	TakenExtHolLastYearAmountCalc?: number

	/**
	 * Nárok na prodlouženou dovolenou v let. roce
	 */
	ClaimExtHlActYearAmountCalc?: number

	/**
	 * Čerpání prodloužené dovolené v let. roce
	 */
	TakenExtHlActYearAmountCalc?: number

	/**
	 * Krácení běžné dovolené v let. roce
	 */
	ReductionOrdinaryHlBalanceCalc?: number

	/**
	 * Krácení dodatkové dovolené v let. roce
	 */
	ReductionAddHlBalanceCalc?: number

	/**
	 * Krácení prodloužené dovolené v let. roce
	 */
	ReductionExtendedHlBalanceCalc?: number

	/**
	 * Zůstatek běžné dovolené v let. roce
	 */
	RemNormHlActYearAmountCalc?: number

	/**
	 * Zůstatek dodatkové dovolené v let. roce
	 */
	RemAddHlActYearAmountCalc?: number

	/**
	 * Zůstatek prodloužené dovolené v let. roce
	 */
	RemExtHlActYearAmountCalc?: number

	/**
	 * Zůstatek běžné dovolené v min. roce
	 */
	RemNormHlPrevYearAmountCalc?: number

	/**
	 * Zůstatek prodloužené dovolené v min. roce
	 */
	RemExtHlPrevYearAmountCalc?: number

	/**
	 * Součet nároků dovolené
	 */
	RemTotalHlClaimAmountCalc?: number

	/**
	 * Součet čerpání dovolené
	 */
	RemTotalHlTakingAmountCalc?: number

	/**
	 * Součet krácení dovolené
	 */
	RemTotalHlReductionsAmountCalc?: number

	/**
	 * Součet zůstatků dovolené
	 */
	RemTotalHlRemainingAmountCalc?: number

	/**
	 * Odpracováno [hodiny]
	 */
	WorkedHoursCalc?: number

	/**
	 * Fond pracovní doby (hodiny)
	 */
	PTFEmplPerWrkTimeFundHoursCalc?: number

	/**
	 * Fond pracovní doby (hodiny)
	 */
	PTFTotalPerWorkTimeFuHoursCalc?: number

	/**
	 * Vym. zákl. zdr. poj.
	 */
	HIAssessmentBaseAmountCalc?: number

	/**
	 * ZP - VZ dopl. do minima
	 */
	HIAssessBaseAddPaymToMinAmountCalc?: number

	/**
	 * ZP - VZ nepl. volno
	 */
	HIAssessBsUnpaidHolAmountCalc?: number

	/**
	 * ZP - VZ neoml. absence
	 */
	HIAssessBaseUnexAbsAmountCalc?: number

	/**
	 * ZP - částka zam. nepl. volno
	 */
	HealthEmpUnpaidHlAmountCalc?: number

	/**
	 * ZP - částka zam neoml. absence
	 */
	HIUnexcusedAbsenceAmountCalc?: number

	/**
	 * Celkem
	 */
	AdvanceTaxTotalAmountCalc?: number

	/**
	 * Průměrný hrubý měsíční výdělek
	 */
	AverageIncomeGrossCalc?: number

	/**
	 * Průměrný čistý měsíční výdělek
	 */
	AverageMonthlyEarningsNetCalc?: number

	/**
	 * Neodprac. dny (pro krác. dov.)
	 */
	UnworkedShortHlDaysAmountCalc?: number

	/**
	 * Hodiny přesčas (od poč. roku)
	 */
	BeginningYearOvertimeHoursCalc?: number

	/**
	 * Úvazek sjednaný týdenní
	 */
	AgreedWorkloadWeekCalc?: number

	/**
	 * Úvazek sjednaný denní
	 */
	AgreedWorkloadDayCalc?: number

	/**
	 * Úvazek stanovený týdenní
	 */
	StatedWorkloadWeekCalc?: number

	/**
	 * Úvazek stanovený denní
	 */
	StatedWorkloadDayCalc?: number

	/**
	 * Úhrn příjmů
	 */
	TaxTotalDeductIncomesAmountCalc?: number

	/**
	 * Pojistné
	 */
	TaxDeductionInsuranceAmountCalc?: number

	/**
	 * Základ srážkové daně
	 */
	TaxDeductionBaseAmountCalc?: number

	/**
	 * Rozdíl na dani po slevě
	 */
	TaxAfterDiscountDifferenceCalc?: number

	/**
	 * Rozdíl na daňovém bonusu
	 */
	TaxBonusDifferenceCalc?: number

	/**
	 * Celková kompenzace
	 */
	TotalCompensationCalc?: number

	/**
	 * Dosažený vyměřovací základ
	 */
	ActualAssessBaseSocialAmountCalc?: number

	/**
	 * Dosažený vyměřovací základ
	 */
	ActualAssessBaseHealthAmountCalc?: number

	/**
	 * Vyměřovací základ
	 */
	HlAssessmentBaseAmountCalc?: number

	/**
	 * Vyloučené dny
	 */
	HlExcludedDaysAmountCalc?: number

	/**
	 * Vyměřovací základ
	 */
	PensInsAssessBasisAmountCalc?: number

	/**
	 * Vyloučené doby
	 */
	PensionInsurExcDaysAmountCalc?: number

	/**
	 * Hodiny
	 */
	InputValueForAverageHourCalc?: number

	/**
	 * Mzda
	 */
	InputValueForAverageAmountCalc?: number

	/**
	 * Hodiny přesčas (nezlikvidované)
	 */
	NotDiscardedOvertimeHoursCalc?: number

	/**
	 * Kurz
	 */
	ExchangeRateCalc?: number

	/**
	 * Hrubá mzda
	 */
	WageAmountGrossClientCCalc?: number

	/**
	 * Čistá mzda
	 */
	WageAmountNetClientCCalc?: number

	/**
	 * Čistý příjem
	 */
	IncomeNetClientCCalc?: number

	/**
	 * Vyplaceno
	 */
	WagePaidAmountClientCCalc?: number

	/**
	 * Rozdíl hodin vůči fondu
	 */
	FundHourDifferenceCalc?: number

	/**
	 * Hodiny
	 */
	TotalPlannedShiftHoursCalc?: number

	/**
	 * Hodiny ve svátek
	 */
	PlannedHolidayHourCountCalc?: number

	/**
	 * Dny
	 */
	TotalPlannedShiftDaysCalc?: number

	/**
	 * Dny ve svátek
	 */
	PlannedHolidayDayCountCalc?: number

	/**
	 * Hodiny
	 */
	TotalActualShiftHoursCalc?: number

	/**
	 * Hodiny ve svátek
	 */
	ActualHolidayHourCountCalc?: number

	/**
	 * Dny
	 */
	TotalActualShiftDaysCalc?: number

	/**
	 * Dny ve svátek
	 */
	ActualHolidayDayCountCalc?: number

	/**
	 * Čistý příjem pro exekuce
	 */
	DistraintsIncomeNetCalc?: number

	/**
	 * Dovolená loňská běžná nárok
	 */
	CompClaiNormHlLastYearAmountCalc?: number

	/**
	 * Dovolená loňská běžná zůstatek
	 */
	RemNormHlPrevYearCompAmountCalc?: number

	/**
	 * Dovolená loňská běžná čerpání
	 */
	ComTakenNrmHlLastYearAmountCalc?: number

	/**
	 * Dovolená letošní běžná nárok
	 */
	CompClaiNormHlActYearAmountCalc?: number

	/**
	 * Dovolená letošní běžná zůstatek
	 */
	RemNormHlActYearCompAmountCalc?: number

	/**
	 * Dovolená letošní běžná krácení
	 */
	CompReductOrdinaryHlBalanceCalc?: number

	/**
	 * Dovolená letošní běžná čerpání
	 */
	CompTakenNormHlActYearAmountCalc?: number

	/**
	 * Dovolená letošní dodatková nárok
	 */
	CompClaimAddHlActYearAmountCalc?: number

	/**
	 * Dovolená letošní dodatková zůstatek
	 */
	RemAddHlActYearCompAmountCalc?: number

	/**
	 * Dovolená letošní dodatková krácení
	 */
	CompReductAddHlBalanceCalc?: number

	/**
	 * Dovolená letošní dodatková čerpání
	 */
	CompTakenAddHlActYearAmountCalc?: number

	/**
	 * Dovolená letošní prodloužená nárok
	 */
	CompClaimExtHlActYearAmountCalc?: number

	/**
	 * Dovolená letošní prodloužená zůstatek
	 */
	RemExtHlActYearCompAmountCalc?: number

	/**
	 * Dovolená letošní prodloužená krácení
	 */
	CompReductExtHlBalanceCalc?: number

	/**
	 * Dovolená letošní prodloužená čerpání
	 */
	ComTakenExtHlActYearAmountCalc?: number

	/**
	 * Dovolená loňská prodloužená nárok
	 */
	CompClaiExtHlLastYearAmountCalc?: number

	/**
	 * Dovolená loňská prodloužená zůstatek
	 */
	RemExtHlPrevYearCompAmountCalc?: number

	/**
	 * Dovolená loňská prodloužená čerpání
	 */
	CompTakenExtHlLastYearAmountCalc?: number

	/**
	 * Dovolená součet zůstatků
	 */
	RemTotalHlRemCompAmountCalc?: number

	/**
	 * Průměrný evid. počet fyzický
	 */
	AverageEvidenceNumberCalc?: number

	/**
	 * Průměrný evid. počet přepočtený
	 */
	AverageRecalcEvidenceNumberCalc?: number

	/**
	 * Hodiny pro průměrný přepočtený počet
	 */
	WorkHoursStateCalc?: number

	/**
	 * Počet dnů v evid. počtu
	 */
	DaysForEvidenceNumberCalc?: number

	/**
	 * Dny vynětí
	 */
	ExamptionDaysCalc?: number

	/**
	 * Kratší prac. doba
	 */
	ShortenedWorkTimeCalc?: string

	/**
	 * Hodiny přesčas
	 */
	OvertimeHoursCalc?: number

	/**
	 * Hodiny skupiny 16
	 */
	TotalHourGroupIni16Calc?: number

	/**
	 * Dny z kalendáře
	 */
	CalendarDayCalc?: string

	/**
	 * Hodiny stanovené
	 */
	PTFHoursSetCalc?: number

	/**
	 * Sick days - nárok
	 */
	SickDaysClaimCalc?: number

	/**
	 * Sick days - čerpání
	 */
	SickDaysUsedCalc?: number

	/**
	 * Sick days - zůstatek
	 */
	SickDaysBalanceCalc?: number

	/**
	 * Počet vyživ. osob
	 */
	DependentPersonCountCalc?: number

	/**
	 * Sick days - zůstatek
	 */
	SickDaysBalanceComputeCalc?: number

	/**
	 * Za kalend. rok
	 */
	IsHolidayPerCalendarYearCalc?: string

	/**
	 * Ostatní příjmy
	 */
	OtherIncomesCalc?: number

	/**
	 * Ztížené prostředí
	 */
	DifficultEnvironmentCalc?: string

	/**
	 * Odprac. dny ve ztíž. prost.
	 */
	DayWorkedInDifficultEnvCalc?: number

	/**
	 * Odprac. dny ve ztíž. prost.
	 */
	CompWorkInDifficultEnvDaysCalc?: number

	/**
	 * Sick days
	 */
	AllowSickDaysCalc?: string

	/**
	 * Kratší pr. doba [%]
	 */
	ShortenedWorkTimePctCalc?: number

	/**
	 * Kratší pr. doba [poměr]
	 */
	ShortenedWorkTimeRatioCalc?: number

	/**
	 * Dosažené hodiny DPP
	 */
	AgreementHourCalc?: number

	/**
	 * V evid. počtu k poslednímu dni období
	 */
	IsLastDayInEvidenceNumberCalc?: string

	/**
	 * Hodiny přesčas (nezlikvidované)
	 */
	CompNotDiscardOvertimeHoursCalc?: number

	/**
	 * Hodiny přesčas od počátku roku
	 */
	CompBeginYearOvertimeHoursCalc?: number

	/**
	 * Rozdíl vůči MČF
	 */
	WTFHourDifferenceCalc?: number

	/**
	 * Vyměřovací základ snížený
	 */
	AssessmentBaseReductedAmountCalc?: number

	/**
	 * Odvod organizace snížený
	 */
	OrgPaymentsReductedAmountCalc?: number

	/**
	 * Snížení vyměřovacího základu
	 */
	AssessmentBaseReductedCalc?: string

	/**
	 * Platnost
	 */
	EmploymentValidityOFBitCalc?: number

	/**
	 * Provést roční zúčtování
	 */
	DoTaxStatementBitCalc?: number

	/**
	 * Vytvořena MS přeplatku daně
	 */
	PrComponentCreatedBitCalc?: number

	/**
	 * Nedosáhl rozhodného příjmu
	 */
	NotReachedIncomeHeaInsBitCalc?: number

	/**
	 * Nedosáhl rozhodného příjmu
	 */
	NotReachedPenInsuranceBitCalc?: number

	/**
	 * V kontu prac. doby
	 */
	HasAverageForWorkingTimeAccountCalc?: number

	/**
	 * Překrytí příjmu a vyloučené doby
	 */
	OverlapIncomeBitCalc?: number

	/**
	 * Pravděpodobný průměrný výdělek
	 */
	HasProbablyAverageCalc?: string

	/**
	 * undefined
	 */
	TextHeaderMemoCalc?: string

	/**
	 * undefined
	 */
	TextFooterMemoCalc?: string

	/**
	 * Poslední komentář
	 */
	LastCommentParamCalc?: string

	/**
	 * undefined
	 */
	IsChangedCalc?: string

	/**
	 * undefined
	 */
	OwnAddressIdCalc?: number

	/**
	 * undefined
	 */
	OwnAddressNameCalc?: string

	/**
	 * Doklad
	 */
	DocumentIdentificationCalc?: string

	/**
	 * EAN kód
	 */
	BarcodeCalc?: string

	/**
	 * undefined
	 */
	IsReversedExchangeRateViewCalc?: string

	/**
	 * Odpracované hodiny pro nárok dovolené
	 */
	WorkedHoursForHolidayAmountCalc?: number

	/**
	 * Plně započtené náhradní doby
	 */
	AltTimeFullCompHourHolidayAmountCalc?: number

	/**
	 * Částečně započtené náhradní doby
	 */
	AltTimePartCompHourHolidayAmountCalc?: number

	/**
	 * Odpracované hodiny pro nárok dovolené
	 */
	WorkedHoursForHolidayCompAmountCalc?: number

	/**
	 * Plně započtené náhradní doby
	 */
	AltTimeFullCompHourHolidayCompAmountCalc?: number

	/**
	 * Částečně započtené náhradní doby
	 */
	AltTimePartCompHourHolidayCompAmountCalc?: number

	/**
	 * Průměrná sjednaná týdenní doba
	 */
	AverageAgreedWeeklyTimeCalc?: number

	/**
	 * Odpracované hodiny pro nárok dodatkové dovolené
	 */
	WorkedHoursForAddHolidayAmountCalc?: number

	/**
	 * Odpracované hodiny pro nárok dodatkové dovolené
	 */
	WorkedHoursForAddHolidayCompAmountCalc?: number

	/**
	 * Různá jednotka dovolené
	 */
	IsDifferentHolidayUnitCalc?: string

	/**
	 * Dovolená letošní běžná zůstatek (cca dny)
	 */
	RemNormHlActYearCompDaysAmountCalc?: number

	/**
	 * Dovolená letošní prodloužená zůstatek (cca dny)
	 */
	RemExtHlActYearCompDaysAmountCalc?: number

	/**
	 * Dovolená letošní dodatková zůstatek (cca dny)
	 */
	RemAddHlActYearCompDaysAmountCalc?: number

	/**
	 * Dovolená loňská běžná zůstatek (cca dny)
	 */
	RemNormHlPrevYearCompDaysAmountCalc?: number

	/**
	 * Dovolená loňská prodloužená zůstatek (cca dny)
	 */
	RemExtHlPrevYearCompDaysAmountCalc?: number

	/**
	 * Dovolená celkem zůstatek (cca dny)
	 */
	RemTotalHlRemCompDaysAmountCalc?: number

	/**
	 * Dovolená celkem nárok
	 */
	RemTotalHlClaimCompAmountCalc?: number

	/**
	 * Dovolená celkem čerpání
	 */
	RemTotalHlTakingCompAmountCalc?: number

	/**
	 * Dovolená celkem krácení
	 */
	RemTotalHlReductionsCompAmountCalc?: number

	/**
	 * Dovolená letošní běžná (nárok do období)
	 */
	TillClaimNormHlActYearCalc?: number

	/**
	 * Dovolená letošní prodloužená (nárok do období)
	 */
	TillClaimExtHlActYearCalc?: number

	/**
	 * Dovolená letošní dodatková (nárok do období)
	 */
	TillClaimAddHlActYearCalc?: number

	/**
	 * Splněna podmínka pro započtení
	 */
	IsPartCompHourConditionCalc?: string

	/**
	 * Maximum hodin pro započtení
	 */
	PartCompHourMaxCalc?: number

	/**
	 * Překročeno maximum hodin pro započtení
	 */
	IdPartCompHourMaxReachedCalc?: string

	/**
	 * Hodiny pro podmínku započtení
	 */
	PartCompHourConditionCalc?: number

	/**
	 * Splněna podmínka pro nárok
	 */
	IsClaimHlConditionCalc?: string

	/**
	 * Započteno hodin pro výpočet nároku dovolené
	 */
	CountedHoursHlCalc?: number

	/**
	 * Započteno týdnů pro výpočet nároku dovolené
	 */
	CountedWeeksHlCalc?: number

	/**
	 * Započteno hodin pro výpočet nároku dodatkové dovolené
	 */
	CountedHoursAddHlCalc?: number

	/**
	 * Započteno týdnů pro výpočet nároku dodatkové dovolené
	 */
	CountedWeeksAddHlCalc?: number

	/**
	 * Existuje nárok na dovolenou 2020
	 */
	HasHoliday2020Calc?: string

	/**
	 * Nárok na dovolenou 2020
	 */
	ClaimHoliday2020Calc?: number

	/**
	 * Čerpání dovolené 2020
	 */
	TakenHoliday2020Calc?: number

	/**
	 * Zůstatek dovolené 2020
	 */
	RemHoliday2020Calc?: number

	/**
	 * Nárok na dovolenou 2020 po výpočtu
	 */
	CompClaimHoliday2020Calc?: number

	/**
	 * Čerpání dovolené 2020 po výpočtu
	 */
	CompTakenHoliday2020Calc?: number

	/**
	 * Zůstatek dovolené 2020 po výpočtu
	 */
	CompRemHoliday2020Calc?: number

	/**
	 * Dovolená letošní běžná zůstatek (cca dny)
	 */
	RemNormHlActYearDaysAmountCalc?: number

	/**
	 * Dovolená letošní prodloužená zůstatek (cca dny)
	 */
	RemExtHlActYearDaysAmountCalc?: number

	/**
	 * Dovolená letošní dodatková zůstatek (cca dny)
	 */
	RemAddHlActYearDaysAmountCalc?: number

	/**
	 * Dovolená loňská běžná zůstatek (cca dny)
	 */
	RemNormHlPrevYearDaysAmountCalc?: number

	/**
	 * Dovolená loňská prodloužená zůstatek (cca dny)
	 */
	RemExtHlPrevYearDaysAmountCalc?: number

	/**
	 * Dovolená celkem zůstatek (cca dny)
	 */
	RemTotalHlRemDaysAmountCalc?: number

	/**
	 * Předpokládané odpracované hodiny pro nárok dovolené
	 */
	PresumedWorkedHoursForHolidayCalc?: number

	/**
	 * Předpokládané plně započtené náhradní doby
	 */
	PresumedAltTimeFullHoursForHolidayCalc?: number

	/**
	 * Předpokládané částečně započtené náhradní doby
	 */
	PresumedAltTimePartHoursForHolidayCalc?: number

	/**
	 * Započteno hodin (vč. předpokladu) pro výpočet nároku dovolené
	 */
	YearCountedHoursHlCalc?: number

	/**
	 * Započteno týdnů (vč. předpokladu) pro výpočet nároku dovolené
	 */
	YearCountedWeeksHlCalc?: number

	/**
	 * Započteno hodin (vč. předpokladu) pro výpočet nároku dodatkové dovolené
	 */
	YearCountedHoursAddHlCalc?: number

	/**
	 * Započteno týdnů (vč. předpokladu) pro výpočet nároku dodatkové dovolené
	 */
	YearCountedWeeksAddHlCalc?: number

	/**
	 * Dovolená letošní běžná (nárok vč. předpokladu)
	 */
	YearTillClaimNormHlActYearCalc?: number

	/**
	 * Dovolená letošní prodloužená (nárok vč. předpokladu)
	 */
	YearTillClaimExtHlActYearCalc?: number

	/**
	 * Dovolená letošní dodatková (nárok vč. předpokladu)
	 */
	YearTillClaimAddHlActYearCalc?: number

	/**
	 * Celkové odpracované hodiny pro nárok dovolené
	 */
	SumWorkedHoursForHolidayCalc?: number

	/**
	 * Celkové plně započtené náhradní doby
	 */
	SumAltTimeFullHoursForHolidayCalc?: number

	/**
	 * Celkové částečně započtené náhradní doby
	 */
	SumAltTimePartHoursForHolidayCalc?: number

	/**
	 * Bude splněna podmínka pro nárok
	 */
	IsYearClaimHlConditionCalc?: string

	/**
	 * Bude splněna podmínka pro započtení
	 */
	IsYearPartCompHourConditionCalc?: string

	/**
	 * Bude překročeno maximum hodin pro započtení
	 */
	IsYearPartCompHourMaxReachedCalc?: string

	/**
	 * Předpokládané odpracované hodiny pro nárok dovolené
	 */
	PresumedWorkedHoursForHolidayPreCMPCalc?: number

	/**
	 * Předpokládané plně započtené náhradní doby
	 */
	PresumedAltTimeFullHoursForHolidayPreCMPCalc?: number

	/**
	 * Předpokládané částečně započtené náhradní doby
	 */
	PresumedAltTimePartHoursForHolidayPreCMPCalc?: number

	/**
	 * Započteno hodin (vč. předpokladu) pro výpočet nároku dovolené
	 */
	YearCountedHoursHlPreCMPCalc?: number

	/**
	 * Započteno týdnů (vč. předpokladu) pro výpočet nároku dovolené
	 */
	YearCountedWeeksHlPreCMPCalc?: number

	/**
	 * Započteno hodin (vč. předpokladu) pro výpočet nároku dodatkové dovolené
	 */
	YearCountedHoursAddHlPreCMPCalc?: number

	/**
	 * Započteno týdnů (vč. předpokladu) pro výpočet nároku dodatkové dovolené
	 */
	YearCountedWeeksAddHlPreCMPCalc?: number

	/**
	 * Dovolená letošní běžná (nárok vč. předpokladu)
	 */
	YearTillClaimNormHlActYearPreCMPCalc?: number

	/**
	 * Dovolená letošní prodloužená (nárok vč. předpokladu)
	 */
	YearTillClaimExtHlActYearPreCMPCalc?: number

	/**
	 * Dovolená letošní dodatková (nárok vč. předpokladu)
	 */
	YearTillClaimAddHlActYearPreCMPCalc?: number

	/**
	 * Celkové odpracované hodiny pro nárok dovolené
	 */
	SumWorkedHoursForHolidayPreCMPCalc?: number

	/**
	 * Celkové plně započtené náhradní doby
	 */
	SumAltTimeFullHoursForHolidayPreCMPCalc?: number

	/**
	 * Celkové částečně započtené náhradní doby
	 */
	SumAltTimePartHoursForHolidayPreCMPCalc?: number

	/**
	 * Bude splněna podmínka pro nárok
	 */
	IsYearClaimHlConditionPreCMPCalc?: string

	/**
	 * Bude splněna podmínka pro započtení
	 */
	IsYearPartCompHourConditionPreCMPCalc?: string

	/**
	 * Bude překročeno maximum hodin pro započtení
	 */
	IsYearPartCompHourMaxReachedPreCMPCalc?: string

	/**
	 * Záměr uplatňovat slevu na pojistném
	 */
	HasApplySocDiscountIntentionCalc?: string

	/**
	 * Uplatňována sleva na pojistném
	 */
	HasApplySocDiscountCalc?: string

	/**
	 * Výše slevy na pojistném
	 */
	SocDiscountAmountCalc?: number

	/**
	 * Právo na záznam
	 */
	RightGroupIdCalc?: number

	/**
	 * Vytvořil
	 */
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM

	/**
	 * Odstupné dle
	 */
	@Type(() => TTextDM)
	RedundancyPaymentParagraphId?: TTextDM

	/**
	 * Kód 6
	 */
	@Type(() => TCode6DocumentDM)
	Code6Id?: TCode6DocumentDM

	/**
	 * Kód 5
	 */
	@Type(() => TCode5DocumentDM)
	Code5Id?: TCode5DocumentDM

	/**
	 * Kód 4
	 */
	@Type(() => TCode4DocumentDM)
	Code4Id?: TCode4DocumentDM

	/**
	 * Kód 3
	 */
	@Type(() => TCode3DocumentDM)
	Code3Id?: TCode3DocumentDM

	/**
	 * Kód zboží
	 */
	@Type(() => TArticleCodeDocumentDM)
	ArticleCodeId?: TArticleCodeDocumentDM

	/**
	 * Středisko 2
	 */
	@Type(() => TCostCentreCollectionDocumentDM)
	CostCentre2Id?: TCostCentreCollectionDocumentDM

	/**
	 * Středisko
	 */
	@Type(() => TCostCentreCollectionDocumentDM)
	CostCentre1Id?: TCostCentreCollectionDocumentDM

	/**
	 * Období
	 */
	@Type(() => TPeriodDM)
	BusinessYearId?: TPeriodDM

	/**
	 * Kniha
	 */
	@Type(() => TBookDM)
	BookId?: TBookDM

	/**
	 * Požadované vzdělání
	 */
	@Type(() => DegreeName)
	RequiredEducationLevelId?: DegreeName

	/**
	 * CZ-NACE
	 */
	@Type(() => CZNACE)
	CZNACEId?: CZNACE

	/**
	 * Prostředek
	 */
	@Type(() => TDeviceDocumentDM)
	DeviceRID?: TDeviceDocumentDM

	/**
	 * Pracovní místo
	 */
	@Type(() => TOrgStrJobDM)
	OrgStrJobRID?: TOrgStrJobDM

	/**
	 * Srážka výplaty
	 */
	@Type(() => TPrDeductionDM)
	WageDeductionId?: TPrDeductionDM

	/**
	 * Kód zakázky
	 */
	@Type(() => TContractCodeDocumentDM)
	ContractCodeRID?: TContractCodeDocumentDM

	/**
	 * Měna
	 */
	@Type(() => TCurrencyDM)
	Currency?: TCurrencyDM

	/**
	 * Finanční úřad
	 */
	@Type(() => TPrDeductionDM)
	TaxOfficeId?: TPrDeductionDM

	/**
	 * Časový fond
	 */
	@Type(() => TPrCalendarDM)
	WorkFundCalendarId?: TPrCalendarDM

	/**
	 * Uzavřel
	 */
	@Type(() => TK2UserLookupDM)
	ConfirmedById?: TK2UserLookupDM

	/**
	 * Vytvořil zálohy
	 */
	@Type(() => TK2UserLookupDM)
	PrComponentCreatedById?: TK2UserLookupDM

	/**
	 * Vypočetl
	 */
	@Type(() => TK2UserLookupDM)
	CalculatedById?: TK2UserLookupDM

	/**
	 * Předvypočetl
	 */
	@Type(() => TK2UserLookupDM)
	PrecalculatedById?: TK2UserLookupDM

	/**
	 * Účet zdr. poj.
	 */
	@Type(() => TAccountingChartDM)
	HealthInsuranceAccountId?: TAccountingChartDM

	/**
	 * Účet soc. zab.
	 */
	@Type(() => TAccountingChartDM)
	SocialSecurityAccountId?: TAccountingChartDM

	/**
	 * Doplň. důvod ukončení
	 */
	@Type(() => DuvUkoncPP2)
	AditionalTerminationReasonId?: DuvUkoncPP2

	/**
	 * Stát
	 */
	@Type(() => TCountryDM)
	WorkCountryId?: TCountryDM

	/**
	 * Skupina
	 */
	@Type(() => SkupPrac)
	GroupId?: SkupPrac

	/**
	 * ZUJ
	 */
	@Type(() => MzdZUJ)
	AdministrativeDivisionId?: MzdZUJ

	/**
	 * CZ-ICSE
	 */
	@Type(() => CZICSE)
	CZICSEId?: CZICSE

	/**
	 * Místo
	 */
	@Type(() => TTownPartDM)
	WorkTownPartId?: TTownPartDM

	/**
	 * Středisko výplatní
	 */
	@Type(() => TCostCentreCollectionDocumentDM)
	PayrollCentreId?: TCostCentreCollectionDocumentDM

	/**
	 * CZ-ISCO
	 */
	@Type(() => ProfessionName)
	CZISCOId?: ProfessionName

	/**
	 * LAU1
	 */
	@Type(() => MzdLAU1)
	LAU1Id?: MzdLAU1

	/**
	 * NUTS4
	 */
	@Type(() => NUTS4)
	NUTS4Id?: NUTS4

	/**
	 * Změnil
	 */
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM

	/**
	 * Účet Má dáti
	 */
	@Type(() => TAccountingChartDM)
	DebitAccountId?: TAccountingChartDM

	/**
	 * Účet Dal
	 */
	@Type(() => TAccountingChartDM)
	CreditAccountId?: TAccountingChartDM

	/**
	 * Párovací symbol
	 */
	@Type(() => TMatchingSymbolDM)
	MatchingSymbolId?: TMatchingSymbolDM

	/**
	 * Kód 2
	 */
	@Type(() => TCode2DocumentDM)
	Code2Id?: TCode2DocumentDM

	/**
	 * Kód 1
	 */
	@Type(() => TCode1DocumentDM)
	Code1Id?: TCode1DocumentDM

	/**
	 * Skupina 2
	 */
	@Type(() => Group2)
	Group2Id?: Group2

	/**
	 * Skupina 1
	 */
	@Type(() => Group1)
	Group1Id?: Group1

	/**
	 * Kategorie 2
	 */
	@Type(() => Kategorie2)
	Category2Id?: Kategorie2

	/**
	 * Kategorie 1
	 */
	@Type(() => Kategorie1)
	Category1Id?: Kategorie1

	/**
	 * Druh činnosti
	 */
	@Type(() => TTextDM)
	SocialActivityTypeId?: TTextDM

	/**
	 * Způsob danění
	 */
	@Type(() => TTextDM)
	TaxDeductionTypeId?: TTextDM

	/**
	 * Způsob srážky soc. zab.
	 */
	@Type(() => TTextDM)
	DeductionSocialSecurityId?: TTextDM

	/**
	 * Způsob srážky zdr. poj.
	 */
	@Type(() => TTextDM)
	DeductionHealthInsuranceId?: TTextDM

	/**
	 * Poplatník
	 */
	@Type(() => TTextDM)
	TaxPayerTypeId?: TTextDM

	/**
	 * Odborová organizace
	 */
	@Type(() => TPrUnionDM)
	UnionId?: TPrUnionDM

	/**
	 * Forma mzdy
	 */
	@Type(() => TTextDM)
	WageTypeId?: TTextDM

	/**
	 * Zaručená mzda
	 */
	@Type(() => TPrMinimalTariffDM)
	GuaranteedWage?: TPrMinimalTariffDM

	/**
	 * Interní tarif
	 */
	@Type(() => TPrInternalTariffDM)
	InternalTariffId?: TPrInternalTariffDM

	/**
	 * Stanovená prac. doba
	 */
	@Type(() => TPrCalendarDM)
	LegalCalendarId?: TPrCalendarDM

	/**
	 * Pracovní doba
	 */
	@Type(() => TPrCalendarDM)
	WorkShiftCalendarId?: TPrCalendarDM

	/**
	 * Zařazení
	 */
	@Type(() => EmployeeFunction)
	JobClassificationId?: EmployeeFunction

	/**
	 * Doba trvání
	 */
	@Type(() => TTextDM)
	DurationTypeId?: TTextDM

	/**
	 * Druh prac. pom.
	 */
	@Type(() => DruhCinnosti)
	EmploymentTypeId?: DruhCinnosti

	/**
	 * Právní vztah dle
	 */
	@Type(() => TTextDM)
	LegalRelationId?: TTextDM

	/**
	 * Právní vztah
	 */
	@Type(() => RelWork)
	LegalSubrelation2Id?: RelWork

	/**
	 * Prac. právní vztah
	 */
	@Type(() => TTextDM)
	LegalSubrelation1Id?: TTextDM

	/**
	 * Druh práce
	 */
	@Type(() => TypWork)
	WorkTypeId?: TypWork

	/**
	 * Důvod ukončení
	 */
	@Type(() => DuvUkoncPP1)
	TerminationReasonId?: DuvUkoncPP1

	/**
	 * Důvod vzniku
	 */
	@Type(() => DuvVznikuPP1)
	CommencementReasonId?: DuvVznikuPP1

	/**
	 * Platnost
	 */
	@Type(() => TTextDM)
	EmploymentValidity?: TTextDM

	/**
	 * Pracovník
	 */
	@Type(() => TEmployedPersonDM)
	EmployedPersonId?: TEmployedPersonDM

	/**
	 * Mzdové období
	 */
	@Type(() => TPrPeriodDM)
	PeriodId?: TPrPeriodDM

	/**
	 * Pracovní vztah
	 */
	@Type(() => TPrEmploymentDM)
	PrEmploymentId?: TPrEmploymentDM

	/**
	 * Období
	 */
	@Type(() => TPeriodDM)
	BusinessYearIdCalc?: TPeriodDM

	/**
	 * Měna
	 */
	@Type(() => TCurrencyDM)
	CurrencyCalc?: TCurrencyDM

	/**
	 * Měna firmy
	 */
	@Type(() => TCurrencyDM)
	ClientCurrencyCalc?: TCurrencyDM

	/**
	 * S
	 */
	@Type(() => TTextDM)
	EmploymentValidityBitCalc?: TTextDM

	/**
	 * undefined
	 */
	@Type(() => TTextDM)
	ConfirmedPeriodIdCalc?: TTextDM

	/**
	 * P
	 */
	@Type(() => TTextDM)
	ConfirmedOrCanceledIdCalc?: TTextDM

	/**
	 * Z
	 */
	@Type(() => TTextDM)
	AdvanceGenerateStateIdCalc?: TTextDM

	/**
	 * PV
	 */
	@Type(() => TTextDM)
	PrecalculationStateIdCalc?: TTextDM

	/**
	 * V
	 */
	@Type(() => TTextDM)
	ComputeStateIdCalc?: TTextDM

	/**
	 * Zpracované období
	 */
	@Type(() => TPrPeriodDM)
	ProcessedPeriodIdCalc?: TPrPeriodDM

	/**
	 * Invalid.
	 */
	@Type(() => TTextDM)
	DisabilityTypeIdCalc?: TTextDM

	/**
	 * Zdravotní pojišťovna
	 */
	@Type(() => TPrDeductionDM)
	HealthInsuranceCompanyIdCalc?: TPrDeductionDM

	/**
	 * Platnost k datu
	 */
	@Type(() => TTextDM)
	EmploymentValidityDateCalc?: TTextDM

	/**
	 * undefined
	 */
	@Type(() => TTextDM)
	EditTypeIdCalc?: TTextDM

	/**
	 * D
	 */
	@Type(() => TTextDM)
	DocumentExistsCalc?: TTextDM

	/**
	 * undefined
	 */
	@Type(() => TMarketingActivityTypeDM)
	ActivityTypeRIDCalc?: TMarketingActivityTypeDM

	/**
	 * SP
	 */
	@Type(() => TTextDM)
	ProcessStateIdCalc?: TTextDM

	/**
	 * Aktuální proces
	 */
	@Type(() => TWorkflowProcessDM)
	CurrentProcessIdCalc?: TWorkflowProcessDM

	/**
	 * undefined
	 */
	@Type(() => TTradingPartnerDM)
	OwnTradingPartnerIdCalc?: TTradingPartnerDM

	/**
	 * Období
	 */
	@Type(() => TPeriodDM)
	CurrentBusinessYearIdCalc?: TPeriodDM

	/**
	 * Sklad
	 */
	@Type(() => TWarehouseDM)
	CurrentWarehouseIdCalc?: TWarehouseDM

	/**
	 * Jednotka letošní dovolené
	 */
	@Type(() => TTextDM)
	CurrentYearHolidayUnitIdCalc?: TTextDM

	/**
	 * Jednotka loňské dovolené
	 */
	@Type(() => TTextDM)
	PreviousYearHolidayUnitIdCalc?: TTextDM

	/**
	 * Důvod uplatnění slevy na pojistném
	 */
	@Type(() => TTextDM)
	SocDiscountReasonIdCalc?: TTextDM

	/**
	 * Komentáře
	 */
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]

	/**
	 * undefined
	 */
	@Type(() => TPrTransferComponentDM)
	CalculationPrComPeriodChild?: TPrTransferComponentDM[]

	/**
	 * undefined
	 */
	@Type(() => TPrTransferComponentDM)
	CalculationPrComPerEmpChild?: TPrTransferComponentDM[]

	/**
	 * Trvalé mzdové složky - úplný výpis položek
	 */
	@Type(() => TPrDefinitionComponentDM)
	PrComPermanentCompleteChild?: TPrDefinitionComponentDM[]

	/**
	 * Trvalé mzdové složky za pracovníka - úplný výpis položek
	 */
	@Type(() => TPrDefinitionComponentDM)
	PrComPermanEmpCompleteChild?: TPrDefinitionComponentDM[]

	/**
	 * Vstupní mzdové složky - úplný výpis položek
	 */
	@Type(() => TPrInputComponentDM)
	PrComponentInputCompleteChild?: TPrInputComponentDM[]

	/**
	 * Vstupní mzdové složky za pracovníka - úplný výpis položek
	 */
	@Type(() => TPrInputComponentDM)
	PrComInputEmpCompleteChild?: TPrInputComponentDM[]

	/**
	 * Výstupní mzdové složky - úplný výpis položek
	 */
	@Type(() => TPrOutputComponentDM)
	PrComponentOutputCompleteChild?: TPrOutputComponentDM[]

	/**
	 * Výstupní mzdové složky za pracovníka - úplný výpis položek
	 */
	@Type(() => TPrOutputComponentDM)
	PrComOutEmployeeCompleteChild?: TPrOutputComponentDM[]

	/**
	 * Mzdové složky historie - období zpracování
	 */
	@Type(() => TPrHistoryComponentDM)
	PrComHistoryProcessingPerChild?: TPrHistoryComponentDM[]

	/**
	 * Mzdové složky historie za pracovníka - období zpracování
	 */
	@Type(() => TPrHistoryComponentDM)
	PrComHistEmpProcessPeriodChild?: TPrHistoryComponentDM[]

	/**
	 * Mzdové složky historie - období platnosti
	 */
	@Type(() => TPrHistoryComponentDM)
	PrComHistoryValidPeriodChild?: TPrHistoryComponentDM[]

	/**
	 * Mzdové složky historie za pracovníka - období platnosti
	 */
	@Type(() => TPrHistoryComponentDM)
	PrComHistEmpValidPeriodChild?: TPrHistoryComponentDM[]

	/**
	 * Dokumenty
	 */
	@Type(() => TExternalDocumentItemDM)
	ExternalDocumentItemChild?: TExternalDocumentItemDM[]

	/**
	 * Procesy
	 */
	@Type(() => TWorkflowProcessAsItemDM)
	WorkflowProcessChild?: TWorkflowProcessAsItemDM[]

	/**
	 * Text hlavičky
	 */
	@Type(() => TLanguageMemoTranslationDM)
	TextHeaderChild?: TLanguageMemoTranslationDM[]

	/**
	 * Text patičky
	 */
	@Type(() => TLanguageMemoTranslationDM)
	TextFooterChild?: TLanguageMemoTranslationDM[]

	/**
	 * Kalendář - časové úseky vstupní
	 */
	@Type(() => TPrCalendarEmploymentItemTimeDM)
	PrCalEmploymItemTimeInputChild?: TPrCalendarEmploymentItemTimeDM[]

	/**
	 * Kalendář skutečnosti mezd - časový úsek - O
	 */
	@Type(() => TPrCalendarEmploymentItemTimeDM)
	PrCalEmplItemTimeOutputChild?: TPrCalendarEmploymentItemTimeDM[]

	/**
	 * Kalendář skutečnosti mezd - časový úsek - W
	 */
	@Type(() => TPrCalendarEmploymentItemTimeDM)
	PrCalEmplItemTimeWorkingChild?: TPrCalendarEmploymentItemTimeDM[]

	/**
	 * Kalendář skutečnosti mezd - charakteristika dne
	 */
	@Type(() => TPrCalendarEmploymentItemDayDM)
	PrCalendarEmploymentItemDayChild?: TPrCalendarEmploymentItemDayDM[]

	/**
	 * Plán - rozvrh pracovní doby
	 */
	@Type(() => TPrCalendarEmploymentItemVisualDM)
	PrCalEmploymItemFrgInputChild?: TPrCalendarEmploymentItemVisualDM[]

	/**
	 * Skutečnost - evidence pracovní doby
	 */
	@Type(() => TPrCalendarEmploymentItemVisualDM)
	PrCalendarEmplItemFrgOutChild?: TPrCalendarEmploymentItemVisualDM[]

	/**
	 * Svázané doklady
	 */
	@Type(() => TAttachedDocumentDM)
	AttachedDocumentOldChild?: TAttachedDocumentDM[]

	/**
	 * Poznámky
	 */
	@Type(() => TOldNoteDM)
	DeprecatedNoteOldChild?: TOldNoteDM[]

	/**
	 * Uzavření období
	 */
	public ConfirmPersDetailCOMMAND() {
		return this.executeCommand('ConfirmPersDetailCOMMAND')
	}

	/**
	 * Odblokování období
	 */
	public ReConfirmPersDetailCOMMAND() {
		return this.executeCommand('ReConfirmPersDetailCOMMAND')
	}

	/**
	 * Výpočet
	 */
	public ComputePersDetailCOMMAND() {
		return this.executeCommand('ComputePersDetailCOMMAND')
	}

	/**
	 * Předvýpočet
	 */
	public PreComputePersDetailCOMMAND() {
		return this.executeCommand('PreComputePersDetailCOMMAND')
	}

	/**
	 * Volba výpočtu
	 */
	public CmpDetailDlgCOMMAND(parameters: TCmpDetailDlgCOMMANDParams) {
		return this.executeCommand('CmpDetailDlgCOMMAND', parameters)
	}

	/**
	 * Částečné potvrzení
	 */
	public PartialConfirmCOMMAND() {
		return this.executeCommand('PartialConfirmCOMMAND')
	}

	/**
	 * Částečné odpotvrzení
	 */
	public PartialUnconfirmCOMMAND() {
		return this.executeCommand('PartialUnconfirmCOMMAND')
	}

	/**
	 * Částečné potvrzení
	 */
	public BulkPartialConfirmCOMMAND() {
		return this.executeCommand('BulkPartialConfirmCOMMAND')
	}

	/**
	 * Částečné odpotvrzení
	 */
	public BulkPartialUnconfirmCOMMAND() {
		return this.executeCommand('BulkPartialUnconfirmCOMMAND')
	}

	/**
	 * Přecenění k rozvahovému dni
	 */
	public BulkRevaluationAtBalanceDayCOMMAND() {
		return this.executeCommand('BulkRevaluationAtBalanceDayCOMMAND')
	}

	/**
	 * Storno přecenění k rozvahovému dni
	 */
	public BulkCancelRevaluationCOMMAND() {
		return this.executeCommand('BulkCancelRevaluationCOMMAND')
	}

	/**
	 * Změna DUZP
	 */
	public InvoiceDateChangeCOMMAND(parameters: TInvoiceDateChangeCOMMANDParams) {
		return this.executeCommand('InvoiceDateChangeCOMMAND', parameters)
	}

	/**
	 * Zaúčtování s likvidací
	 */
	public AccountAssignmentPostingCOMMAND(parameters: TAccountAssignmentPostingCOMMANDParams) {
		return this.executeCommand('AccountAssignmentPostingCOMMAND', parameters)
	}

	/**
	 * Hromadné zaúčtování s likvidací
	 */
	public BulkAccountAssignmentPostingCOMMAND(parameters: TBulkAccountAssignmentPostingCOMMANDParams) {
		return this.executeCommand('BulkAccountAssignmentPostingCOMMAND', parameters)
	}

	/**
	 * Likvidace
	 */
	public AccountAssignmentCOMMAND() {
		return this.executeCommand('AccountAssignmentCOMMAND')
	}

	/**
	 * Zapnout rychlý filtr
	 */
	public FastFilterSwitchCOMMAND() {
		return this.executeCommand('FastFilterSwitchCOMMAND')
	}

	/**
	 * Povolit editaci
	 */
	public EditModeIDNoCOMMAND() {
		return this.executeCommand('EditModeIDNoCOMMAND')
	}

	/**
	 * Povolit editaci
	 */
	public EditModeVATRegisterNoCOMMAND() {
		return this.executeCommand('EditModeVATRegisterNoCOMMAND')
	}

	/**
	 * Povolit editaci
	 */
	public EditModeTaxNoCOMMAND() {
		return this.executeCommand('EditModeTaxNoCOMMAND')
	}

	/**
	 * Změna kontace
	 */
	public BulkChangePostingKeyCOMMAND(parameters: TBulkChangePostingKeyCOMMANDParams) {
		return this.executeCommand('BulkChangePostingKeyCOMMAND', parameters)
	}

	/**
	 * Aktualizace Plánovaného data zaplacení dle Prodlení Dod./Odb.
	 */
	public BulkUpdatePlannedDateOfPaymentByAvgDelayCOMMAND() {
		return this.executeCommand('BulkUpdatePlannedDateOfPaymentByAvgDelayCOMMAND')
	}

	/**
	 * Zobrazovat data od období: %s
	 */
	public ApplyLowerLimitForData() {
		return this.executeCommand('ApplyLowerLimitForData')
	}

	/**
	 * Potvrzení
	 */
	public BulkConfirmCOMMAND() {
		return this.executeCommand('BulkConfirmCOMMAND')
	}

	/**
	 * Odpotvrzení
	 */
	public BulkUnconfirmCOMMAND() {
		return this.executeCommand('BulkUnconfirmCOMMAND')
	}

	/**
	 * Změna data potvrzení
	 */
	public BulkChangeConfirmationDateCOMMAND(parameters: TBulkChangeConfirmationDateCOMMANDParams) {
		return this.executeCommand('BulkChangeConfirmationDateCOMMAND', parameters)
	}

	/**
	 * Storno
	 */
	public BulkCancelCOMMAND() {
		return this.executeCommand('BulkCancelCOMMAND')
	}

	/**
	 * Odstorno
	 */
	public BulkUnCancelCOMMAND() {
		return this.executeCommand('BulkUnCancelCOMMAND')
	}

	/**
	 * Potvrzení
	 */
	public ConfirmCOMMAND() {
		return this.executeCommand('ConfirmCOMMAND')
	}

	/**
	 * Odpotvrzení
	 */
	public UnconfirmCOMMAND() {
		return this.executeCommand('UnconfirmCOMMAND')
	}

	/**
	 * Změna data potvrzení
	 */
	public ChangeConfirmationDateCOMMAND(parameters: TChangeConfirmationDateCOMMANDParams) {
		return this.executeCommand('ChangeConfirmationDateCOMMAND', parameters)
	}

	/**
	 * Storno/Odstorno
	 */
	public CancelCOMMAND() {
		return this.executeCommand('CancelCOMMAND')
	}

	/**
	 * Zrušení stavu
	 */
	public ClearRecordStatusCOMMAND() {
		return this.executeCommand('ClearRecordStatusCOMMAND')
	}

	/**
	 * Změna stavu
	 */
	public ChangeRecordStatusCOMMAND() {
		return this.executeCommand('ChangeRecordStatusCOMMAND')
	}

	/**
	 * 
	 */
	public ChangeRecordStatusSWSCOMMAND(parameters: TChangeRecordStatusSWSCOMMANDParams) {
		return this.executeCommand('ChangeRecordStatusSWSCOMMAND', parameters)
	}

	/**
	 * Zrušení stavu
	 */
	public BulkClearRecordStatusCOMMAND() {
		return this.executeCommand('BulkClearRecordStatusCOMMAND')
	}

	/**
	 * Změna stavu
	 */
	public BulkChangeRecordStatusCOMMAND() {
		return this.executeCommand('BulkChangeRecordStatusCOMMAND')
	}

	/**
	 * Změna atributů
	 */
	public BulkChangeAttributesCOMMAND(parameters: TBulkChangeAttributesCOMMANDParams) {
		return this.executeCommand('BulkChangeAttributesCOMMAND', parameters)
	}

	/**
	 * Aktualizace sady atributů
	 */
	public BulkActualizeAttributesCOMMAND(parameters: TBulkActualizeAttributesCOMMANDParams) {
		return this.executeCommand('BulkActualizeAttributesCOMMAND', parameters)
	}

	/**
	 * Aktualizace sady atributů
	 */
	public ActualizeAttributesCOMMAND() {
		return this.executeCommand('ActualizeAttributesCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public ChangeGroupRightCOMMAND() {
		return this.executeCommand('ChangeGroupRightCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public BulkChangeGroupRightCOMMAND() {
		return this.executeCommand('BulkChangeGroupRightCOMMAND')
	}

	/**
	 * Aktualizace počítaných polí
	 */
	public BulkUpdateCalcFieldsCOMMAND() {
		return this.executeCommand('BulkUpdateCalcFieldsCOMMAND')
	}

	/**
	 * Kontrola legislativy
	 */
	public SwitchOffLegislationValidationCOMMAND() {
		return this.executeCommand('SwitchOffLegislationValidationCOMMAND')
	}
}

export type TCmpDetailDlgCOMMANDParams = {
	/**
	 * PeriodIdDM
	 */
	PeriodIdDM?: number
	/**
	 * BookId
	 */
	BookId?: number
	/**
	 * Při průchodu vynechávat už dříve zpracované údaje
	 */
	SkipCmp?: boolean
	/**
	 * Kontrola záznamů
	 */
	CheckPers?: boolean
	/**
	 * Předvýpočet (příprava vstupů) SHIFT + F3
	 */
	PreComputePers?: boolean
	/**
	 * Potvrzení předvýpočtu
	 */
	PreConfirmPers?: boolean
	/**
	 * Odpotvrzení předvýpočtu
	 */
	RePreConfirmPers?: boolean
	/**
	 * Vytvoření MS (záloha na mzdu)
	 */
	GenPers?: boolean
	/**
	 * Potvrzení vytvoření MS
	 */
	GenConfirmPers?: boolean
	/**
	 * Odpotvrzení vytvoření MS
	 */
	ReGenConfirmPers?: boolean
	/**
	 * Výpočet CTRL + F3
	 */
	ComputePers?: boolean
	/**
	 * Uzavření období
	 */
	ConfirmPers?: boolean
	/**
	 * Odblokování uzavřeného období
	 */
	ReConfirmPers?: boolean
	/**
	 * Výpočet knihy
	 */
	ComputeBook?: boolean
	/**
	 * Uzavření období knihy
	 */
	ConfirmBook?: boolean
	/**
	 * Odblokování uzavřeného období knihy
	 */
	ReConfirmBook?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TInvoiceDateChangeCOMMANDParams = {
	/**
	 * VATDate
	 */
	VATDate?: Date
}

export type TAccountAssignmentPostingCOMMANDParams = {
	/**
	 * Zaúčtuje
	 */
	ACCPosting?: number
	/**
	 * Kniha
	 */
	ACCBook?: number
	/**
	 * Období
	 */
	ACCBussinessYear?: number
	/**
	 * Datum zaúčtování
	 */
	ACCPostingDate?: Date
	/**
	 * Datum od
	 */
	ACCDateFrom?: Date
	/**
	 * Datum do
	 */
	ACCDateTo?: Date
	/**
	 * Zachovat ručně změněné záznamy
	 */
	ACCHandChange?: boolean
	/**
	 * Likvidace bez částky
	 */
	ACCNulAmount?: boolean
	/**
	 * Povolit zaúčtovat doklady s jiným obdobím
	 */
	ACCOtherBusinessYear?: boolean
	/**
	 * Zaúčtovat nevyrovnané saldo
	 */
	ACCBalance?: boolean
}

export type TBulkAccountAssignmentPostingCOMMANDParams = {
	/**
	 * Zaúčtuje
	 */
	ACCPosting?: number
	/**
	 * Kniha
	 */
	ACCBook?: number
	/**
	 * Období
	 */
	ACCBussinessYear?: number
	/**
	 * Datum zaúčtování
	 */
	ACCPostingDate?: Date
	/**
	 * Datum od
	 */
	ACCDateFrom?: Date
	/**
	 * Datum do
	 */
	ACCDateTo?: Date
	/**
	 * Zachovat ručně změněné záznamy
	 */
	ACCHandChange?: boolean
	/**
	 * Likvidace bez částky
	 */
	ACCNulAmount?: boolean
	/**
	 * Povolit zaúčtovat doklady s jiným obdobím
	 */
	ACCOtherBusinessYear?: boolean
	/**
	 * Zaúčtovat nevyrovnané saldo
	 */
	ACCBalance?: boolean
}

export type TBulkChangePostingKeyCOMMANDParams = {
	/**
	 * Kontace
	 */
	Header_PostingKeyX?: boolean
	/**
	 * Header_PostingKey
	 */
	Header_PostingKey?: number
	/**
	 * Účet
	 */
	Header_AccountX?: boolean
	/**
	 * Header_Account
	 */
	Header_Account?: number
	/**
	 * Účet D
	 */
	Header_CreditAcX?: boolean
	/**
	 * Header_CreditAc
	 */
	Header_CreditAc?: number
	/**
	 * Účet MD
	 */
	Header_DebitAcX?: boolean
	/**
	 * Header_DebitAc
	 */
	Header_DebitAc?: number
	/**
	 * Parovací symbol
	 */
	Header_MatchSymbolX?: boolean
	/**
	 * Header_MatchSymbol
	 */
	Header_MatchSymbol?: number
	/**
	 * Středisko
	 */
	Header_CostCentreX?: boolean
	/**
	 * Header_CostCentre
	 */
	Header_CostCentre?: number
	/**
	 * Ze třediska
	 */
	Header_FromCostCentreX?: boolean
	/**
	 * Header_FromCostCentre
	 */
	Header_FromCostCentre?: number
	/**
	 * Na středisko
	 */
	Header_ToCostCentreX?: boolean
	/**
	 * Header_ToCostCentre
	 */
	Header_ToCostCentre?: number
	/**
	 * Kód zakázky
	 */
	Header_ContractCodeX?: boolean
	/**
	 * Header_ContractCode
	 */
	Header_ContractCode?: number
	/**
	 * Z kódu zakázky
	 */
	Header_FromContractCodeX?: boolean
	/**
	 * Header_FromContractCode
	 */
	Header_FromContractCode?: number
	/**
	 * Na kód zakázky
	 */
	Header_ToContractCodeX?: boolean
	/**
	 * Header_ToContractCode
	 */
	Header_ToContractCode?: number
	/**
	 * Prostředek
	 */
	Header_DeviceX?: boolean
	/**
	 * Header_Device
	 */
	Header_Device?: number
	/**
	 * Z prostředku
	 */
	Header_FromDeviceX?: boolean
	/**
	 * Header_FromDevice
	 */
	Header_FromDevice?: number
	/**
	 * Na prostředek
	 */
	Header_ToDeviceX?: boolean
	/**
	 * Header_ToDevice
	 */
	Header_ToDevice?: number
	/**
	 * Referent
	 */
	Header_OfficerX?: boolean
	/**
	 * Header_Officer
	 */
	Header_Officer?: number
	/**
	 * Z referenta
	 */
	Header_FromOfficerX?: boolean
	/**
	 * Header_FromOfficer
	 */
	Header_FromOfficer?: number
	/**
	 * Na referenta
	 */
	Header_ToOfficerX?: boolean
	/**
	 * Header_ToOfficer
	 */
	Header_ToOfficer?: number
	/**
	 * Kód zboží
	 */
	Header_ArticleCodeX?: boolean
	/**
	 * Header_ArticleCode
	 */
	Header_ArticleCode?: number
	/**
	 * Kód 1
	 */
	Header_Code1X?: boolean
	/**
	 * Header_Code1
	 */
	Header_Code1?: number
	/**
	 * Z kódu 1
	 */
	Header_FromCode1X?: boolean
	/**
	 * Header_FromCode1
	 */
	Header_FromCode1?: number
	/**
	 * Na kód 1
	 */
	Header_ToCode1X?: boolean
	/**
	 * Header_ToCode1
	 */
	Header_ToCode1?: number
	/**
	 * Kód 2
	 */
	Header_Code2X?: boolean
	/**
	 * Header_Code2
	 */
	Header_Code2?: number
	/**
	 * Z kódu 2
	 */
	Header_FromCode2X?: boolean
	/**
	 * Header_FromCode2
	 */
	Header_FromCode2?: number
	/**
	 * Na kód 2
	 */
	Header_ToCode2X?: boolean
	/**
	 * Header_ToCode2
	 */
	Header_ToCode2?: number
	/**
	 * Kód 3
	 */
	Header_Code3X?: boolean
	/**
	 * Header_Code3
	 */
	Header_Code3?: number
	/**
	 * Z kódu 3
	 */
	Header_FromCode3X?: boolean
	/**
	 * Header_FromCode3
	 */
	Header_FromCode3?: number
	/**
	 * Na kód 3
	 */
	Header_ToCode3X?: boolean
	/**
	 * Header_ToCode3
	 */
	Header_ToCode3?: number
	/**
	 * Kód 4
	 */
	Header_Code4X?: boolean
	/**
	 * Header_Code4
	 */
	Header_Code4?: number
	/**
	 * Z kódu 4
	 */
	Header_FromCode4X?: boolean
	/**
	 * Header_FromCode4
	 */
	Header_FromCode4?: number
	/**
	 * Na kód 4
	 */
	Header_ToCode4X?: boolean
	/**
	 * Header_ToCode4
	 */
	Header_ToCode4?: number
	/**
	 * Kód 5
	 */
	Header_Code5X?: boolean
	/**
	 * Header_Code5
	 */
	Header_Code5?: number
	/**
	 * Z kódu 5
	 */
	Header_FromCode5X?: boolean
	/**
	 * Header_FromCode5
	 */
	Header_FromCode5?: number
	/**
	 * Na kód 5
	 */
	Header_ToCode5X?: boolean
	/**
	 * Header_ToCode5
	 */
	Header_ToCode5?: number
	/**
	 * Kód 6
	 */
	Header_Code6X?: boolean
	/**
	 * Header_Code6
	 */
	Header_Code6?: number
	/**
	 * Z kódu 6
	 */
	Header_FromCode6X?: boolean
	/**
	 * Header_FromCode6
	 */
	Header_FromCode6?: number
	/**
	 * Na kód 6
	 */
	Header_ToCode6X?: boolean
	/**
	 * Header_ToCode6
	 */
	Header_ToCode6?: number
	/**
	 * Ignorovat cenu příjmu
	 */
	Header_IgnorePriceIncomX?: boolean
	/**
	 * Header_IgnorePriceIncom
	 */
	Header_IgnorePriceIncom?: number
	/**
	 * Header_ShowIgnorePriceIncom
	 */
	Header_ShowIgnorePriceIncom?: boolean
	/**
	 * Kalk.vz.plán.
	 */
	Header_PlannedPriceCMX?: boolean
	/**
	 * Header_PlannedPriceCM
	 */
	Header_PlannedPriceCM?: number
	/**
	 * Kalk.vz.sklad.
	 */
	Header_StockPriceCMX?: boolean
	/**
	 * Header_StockPriceCM
	 */
	Header_StockPriceCM?: number
	/**
	 * Kalk.vz.nákl.
	 */
	Header_CostPriceCMX?: boolean
	/**
	 * Header_CostPriceCM
	 */
	Header_CostPriceCM?: number
	/**
	 * Kontace faktury
	 */
	Item_PostingKeyIX?: boolean
	/**
	 * Item_PostingKeyI
	 */
	Item_PostingKeyI?: number
	/**
	 * Kontace skladového dokladu
	 */
	Item_PostingKeySX?: boolean
	/**
	 * Item_PostingKeyS
	 */
	Item_PostingKeyS?: number
	/**
	 * Kontace rezervačního listu
	 */
	Item_PostingKeyRX?: boolean
	/**
	 * Item_PostingKeyR
	 */
	Item_PostingKeyR?: number
	/**
	 * Kód zakázky
	 */
	Item_ContractCodeX?: boolean
	/**
	 * Item_ContractCode
	 */
	Item_ContractCode?: number
	/**
	 * Kód zakázky 2
	 */
	Item_ContractCode2X?: boolean
	/**
	 * Item_ContractCode2
	 */
	Item_ContractCode2?: number
	/**
	 * Středisko
	 */
	Item_CostCentreX?: boolean
	/**
	 * Item_CostCentre
	 */
	Item_CostCentre?: number
	/**
	 * Středisko 2
	 */
	Item_CostCentre2X?: boolean
	/**
	 * Item_CostCentre2
	 */
	Item_CostCentre2?: number
	/**
	 * Dosadit z karty zboží
	 */
	Item_CostCentreG?: boolean
	/**
	 * Kód zboží
	 */
	Item_ArticleCodeX?: boolean
	/**
	 * Item_ArticleCode
	 */
	Item_ArticleCode?: number
	/**
	 * Dosadit z karty zboží
	 */
	Item_ArticleCodeG?: boolean
	/**
	 * Kód 1
	 */
	Item_Code1X?: boolean
	/**
	 * Item_Code1
	 */
	Item_Code1?: number
	/**
	 * Kód 2
	 */
	Item_Code2X?: boolean
	/**
	 * Item_Code2
	 */
	Item_Code2?: number
	/**
	 * Kód 3
	 */
	Item_Code3X?: boolean
	/**
	 * Item_Code3
	 */
	Item_Code3?: number
	/**
	 * Kód 4
	 */
	Item_Code4X?: boolean
	/**
	 * Item_Code4
	 */
	Item_Code4?: number
	/**
	 * Kód 5
	 */
	Item_Code5X?: boolean
	/**
	 * Item_Code5
	 */
	Item_Code5?: number
	/**
	 * Kód 6
	 */
	Item_Code6X?: boolean
	/**
	 * Item_Code6
	 */
	Item_Code6?: number
	/**
	 * Referent
	 */
	Item_OfficerX?: boolean
	/**
	 * Item_Officer
	 */
	Item_Officer?: number
	/**
	 * Prostředek
	 */
	Item_DeviceX?: boolean
	/**
	 * Item_Device
	 */
	Item_Device?: number
	/**
	 * Parovací symbol
	 */
	Item_MatchSymbolX?: boolean
	/**
	 * Item_MatchSymbol
	 */
	Item_MatchSymbol?: number
	/**
	 * Účet
	 */
	Item_AccountX?: boolean
	/**
	 * Item_Account
	 */
	Item_Account?: number
	/**
	 * Kontace
	 */
	Item_PostingKeyX?: boolean
	/**
	 * Item_PostingKey
	 */
	Item_PostingKey?: number
	/**
	 * Účet D
	 */
	Item_CreditAcX?: boolean
	/**
	 * Item_CreditAc
	 */
	Item_CreditAc?: number
	/**
	 * Účet MD
	 */
	Item_DebitAcX?: boolean
	/**
	 * Item_DebitAc
	 */
	Item_DebitAc?: number
	/**
	 * Podle kontejneru zboží
	 */
	GoodsContainer?: boolean
	/**
	 * S odmazáním z kontejneru
	 */
	DeleteFromContainer?: boolean
	/**
	 * Pouze u prázdných
	 */
	OnlyEmpty?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TBulkChangeConfirmationDateCOMMANDParams = {
	/**
	 * Datum potvrzení
	 */
	Confirmation1Date?: Date
	/**
	 * Hodina potvrzení
	 */
	Confirmation1Time?: Date
	/**
	 * Datum 2. potvrzení
	 */
	Confirmation2Date?: Date
	/**
	 * Hodina 2. potvrzení
	 */
	Confirmation2Time?: Date
	/**
	 * Kontrolovat záporné stavy
	 */
	CheckNegativeState?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TChangeConfirmationDateCOMMANDParams = {
	/**
	 * Datum potvrzení
	 */
	Confirmation1Date?: Date
	/**
	 * Hodina potvrzení
	 */
	Confirmation1Time?: Date
	/**
	 * Datum 2. potvrzení
	 */
	Confirmation2Date?: Date
	/**
	 * Hodina 2. potvrzení
	 */
	Confirmation2Time?: Date
	/**
	 * Kontrolovat záporné stavy
	 */
	CheckNegativeState?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TChangeRecordStatusSWSCOMMANDParams = {
	/**
	 * StatusId
	 */
	StatusId?: number
}

export type TBulkChangeAttributesCOMMANDParams = {
	/**
	 * Smazat atributy
	 */
	DeleteAttribute?: boolean
	/**
	 * Smazat všechny volné atributy
	 */
	DeleteFreeAttribute?: boolean
	/**
	 * Nastavit hodnoty atributů
	 */
	SetValues?: boolean
	/**
	 * Přidat nepřipojené atributy
	 */
	AddFreeAttributes?: boolean
	/**
	 * FreeAttributeEnabled
	 */
	FreeAttributeEnabled?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TBulkActualizeAttributesCOMMANDParams = {
	/**
	 * Aktualizovat včetně samotné sady atributů
	 */
	ActualizeAttributeSet?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}