import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TTradingPartnerDM from './TTradingPartnerDM'
import TArticleDM from './TArticleDM'
import TK2UserLookupDM from './TK2UserLookupDM'
import Coupon from './Coupon'
import TCurrencyDM from './TCurrencyDM'
import TContactPersonDM from './TContactPersonDM'
import TSalesActionDM from './TSalesActionDM'
import CouponStatus from './CouponStatus'
import TTextDM from './TTextDM'
import TMarketingActivityTypeDM from './TMarketingActivityTypeDM'
import TCommentDM from './TCommentDM'
import TSalesItemBookDM from './TSalesItemBookDM'
import TExternalDocumentItemDM from './TExternalDocumentItemDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TLanguageMemoTranslationChildDM from './TLanguageMemoTranslationChildDM'
import TAttachedDocumentDM from './TAttachedDocumentDM'
import TOldNoteDM from './TOldNoteDM'

/**
 * Slevové kupóny
 */
export default class TDiscountCouponDM extends Data {
	public static className = 'TDiscountCouponDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'Timestamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = true

	/**
	 * Skupina
	 */
	LoyaltyProgramGroupRID?: number

	/**
	 * uplatnit v libovolné měně
	 */
	UseForVariousCurrency?: string

	/**
	 * Položka prodeje
	 */
	SalesItemRID?: number

	/**
	 * Dárkový poukaz
	 */
	IsVoucher?: string

	/**
	 * Popis
	 */
	Description?: string

	/**
	 * Maximální sleva
	 */
	MaxDiscount?: number

	/**
	 * Opakování
	 */
	MaxRepetition?: number

	/**
	 * Časové razítko
	 */
	Timestamp?: string

	/**
	 * Změněno
	 */
	ChangedOn?: Date

	/**
	 * Sleva
	 */
	Discount?: number

	/**
	 * Platí od částky
	 */
	ValidFromPrice?: number

	/**
	 * Počet dnů
	 */
	ValidDays?: number

	/**
	 * Platnost do
	 */
	ValidToDate?: Date

	/**
	 * Platnost od
	 */
	ValidFromDate?: Date

	/**
	 * Kód
	 */
	CouponCode?: string

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * SQLSequenceOrderCalc
	 */
	SQLSequenceOrderCalc?: number

	/**
	 * Datum pro "_ver" pole
	 */
	VersionedFieldDateCalc?: Date

	/**
	 * Neomezený
	 */
	IsUnlimitedCalc?: string

	/**
	 * P
	 */
	ConfirmedBitCalc?: number

	/**
	 * unikátní zákazník
	 */
	UniqueTradingPartnerBitCalc?: number

	/**
	 * Doprava zdarma
	 */
	FreeTransportBitCalc?: number

	/**
	 * undefined
	 */
	TextHeaderMemoCalc?: string

	/**
	 * undefined
	 */
	TextFooterMemoCalc?: string

	/**
	 * Poslední komentář
	 */
	LastCommentParamCalc?: string

	/**
	 * Popis
	 */
	TranslatedDescriptionCalc?: string

	/**
	 * Zákazník
	 */
	@Type(() => TTradingPartnerDM)
	TradingPartnerId?: TTradingPartnerDM

	/**
	 * Karta zboží
	 */
	@Type(() => TArticleDM)
	ArticleId?: TArticleDM

	/**
	 * Změnil
	 */
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM

	/**
	 * Dodatek
	 */
	@Type(() => Coupon)
	SupplementId?: Coupon

	/**
	 * Měna
	 */
	@Type(() => TCurrencyDM)
	Currency?: TCurrencyDM

	/**
	 * Kontaktni osoba
	 */
	@Type(() => TContactPersonDM)
	ContactPersonId?: TContactPersonDM

	/**
	 * Kuponová akce
	 */
	@Type(() => TSalesActionDM)
	SellingActionRID?: TSalesActionDM

	/**
	 * Stav
	 */
	@Type(() => CouponStatus)
	StatusId?: CouponStatus

	/**
	 * Sleva %
	 */
	@Type(() => TTextDM)
	PercentageDiscountBitCalc?: TTextDM

	/**
	 * z &netto částky
	 */
	@Type(() => TTextDM)
	NetDiscountBitCalc?: TTextDM

	/**
	 * Použití
	 */
	@Type(() => TTextDM)
	UsingIdCalc?: TTextDM

	/**
	 * Jednorázový
	 */
	@Type(() => TTextDM)
	SingleUseBitCalc?: TTextDM

	/**
	 * Opakovatelný
	 */
	@Type(() => TTextDM)
	RepeatableBitCalc?: TTextDM

	/**
	 * zaokrouhlit na jednotky
	 */
	@Type(() => TTextDM)
	RoundToUnitBitCalc?: TTextDM

	/**
	 * D
	 */
	@Type(() => TTextDM)
	DocumentExistsCalc?: TTextDM

	/**
	 * undefined
	 */
	@Type(() => TMarketingActivityTypeDM)
	ActivityTypeRIDCalc?: TMarketingActivityTypeDM

	/**
	 * TranslationLanguageIdCalc
	 */
	@Type(() => TTextDM)
	TranslationLanguageIdCalc?: TTextDM

	/**
	 * Komentáře
	 */
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]

	/**
	 * Položky prodeje
	 */
	@Type(() => TSalesItemBookDM)
	SalesItemChild?: TSalesItemBookDM[]

	/**
	 * Dokumenty
	 */
	@Type(() => TExternalDocumentItemDM)
	ExternalDocumentItemChild?: TExternalDocumentItemDM[]

	/**
	 * Text hlavičky
	 */
	@Type(() => TLanguageMemoTranslationDM)
	TextHeaderChild?: TLanguageMemoTranslationDM[]

	/**
	 * Text patičky
	 */
	@Type(() => TLanguageMemoTranslationDM)
	TextFooterChild?: TLanguageMemoTranslationDM[]

	/**
	 * undefined
	 */
	@Type(() => TLanguageMemoTranslationChildDM)
	TranslatedFieldValuesChild?: TLanguageMemoTranslationChildDM[]

	/**
	 * Svázané doklady
	 */
	@Type(() => TAttachedDocumentDM)
	AttachedDocumentOldChild?: TAttachedDocumentDM[]

	/**
	 * Poznámky
	 */
	@Type(() => TOldNoteDM)
	DeprecatedNoteOldChild?: TOldNoteDM[]

	/**
	 * Rozmnožení kupónů
	 */
	public CouponCreateCOMMAND(parameters: TCouponCreateCOMMANDParams) {
		return this.executeCommand('CouponCreateCOMMAND', parameters)
	}

	/**
	 * Aktivace kupónu
	 */
	public CouponActivationCOMMAND(parameters: TCouponActivationCOMMANDParams) {
		return this.executeCommand('CouponActivationCOMMAND', parameters)
	}

	/**
	 * Aktivace kupónů
	 */
	public BulkCouponActivationCOMMAND(parameters: TBulkCouponActivationCOMMANDParams) {
		return this.executeCommand('BulkCouponActivationCOMMAND', parameters)
	}

	/**
	 * Deaktivace kupónu
	 */
	public CouponDeActivationCOMMAND() {
		return this.executeCommand('CouponDeActivationCOMMAND')
	}

	/**
	 * Deaktivace kupónů
	 */
	public BulkCouponDeActivationCOMMAND() {
		return this.executeCommand('BulkCouponDeActivationCOMMAND')
	}

	/**
	 * Zneplatnění
	 */
	public CouponInvalidationCOMMAND() {
		return this.executeCommand('CouponInvalidationCOMMAND')
	}

	/**
	 * Potvrzení
	 */
	public BulkConfirmCOMMAND() {
		return this.executeCommand('BulkConfirmCOMMAND')
	}

	/**
	 * Odpotvrzení
	 */
	public BulkUnconfirmCOMMAND() {
		return this.executeCommand('BulkUnconfirmCOMMAND')
	}

	/**
	 * Změna data potvrzení
	 */
	public BulkChangeConfirmationDateCOMMAND(parameters: TBulkChangeConfirmationDateCOMMANDParams) {
		return this.executeCommand('BulkChangeConfirmationDateCOMMAND', parameters)
	}

	/**
	 * Storno
	 */
	public BulkCancelCOMMAND() {
		return this.executeCommand('BulkCancelCOMMAND')
	}

	/**
	 * Odstorno
	 */
	public BulkUnCancelCOMMAND() {
		return this.executeCommand('BulkUnCancelCOMMAND')
	}

	/**
	 * Potvrzení
	 */
	public ConfirmCOMMAND() {
		return this.executeCommand('ConfirmCOMMAND')
	}

	/**
	 * Odpotvrzení
	 */
	public UnconfirmCOMMAND() {
		return this.executeCommand('UnconfirmCOMMAND')
	}

	/**
	 * Změna data potvrzení
	 */
	public ChangeConfirmationDateCOMMAND(parameters: TChangeConfirmationDateCOMMANDParams) {
		return this.executeCommand('ChangeConfirmationDateCOMMAND', parameters)
	}

	/**
	 * Storno/Odstorno
	 */
	public CancelCOMMAND() {
		return this.executeCommand('CancelCOMMAND')
	}

	/**
	 * Zrušení stavu
	 */
	public ClearRecordStatusCOMMAND() {
		return this.executeCommand('ClearRecordStatusCOMMAND')
	}

	/**
	 * Změna stavu
	 */
	public ChangeRecordStatusCOMMAND() {
		return this.executeCommand('ChangeRecordStatusCOMMAND')
	}

	/**
	 * 
	 */
	public ChangeRecordStatusSWSCOMMAND(parameters: TChangeRecordStatusSWSCOMMANDParams) {
		return this.executeCommand('ChangeRecordStatusSWSCOMMAND', parameters)
	}

	/**
	 * Zrušení stavu
	 */
	public BulkClearRecordStatusCOMMAND() {
		return this.executeCommand('BulkClearRecordStatusCOMMAND')
	}

	/**
	 * Změna stavu
	 */
	public BulkChangeRecordStatusCOMMAND() {
		return this.executeCommand('BulkChangeRecordStatusCOMMAND')
	}

	/**
	 * Změna atributů
	 */
	public BulkChangeAttributesCOMMAND(parameters: TBulkChangeAttributesCOMMANDParams) {
		return this.executeCommand('BulkChangeAttributesCOMMAND', parameters)
	}

	/**
	 * Aktualizace sady atributů
	 */
	public BulkActualizeAttributesCOMMAND(parameters: TBulkActualizeAttributesCOMMANDParams) {
		return this.executeCommand('BulkActualizeAttributesCOMMAND', parameters)
	}

	/**
	 * Aktualizace sady atributů
	 */
	public ActualizeAttributesCOMMAND() {
		return this.executeCommand('ActualizeAttributesCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public ChangeGroupRightCOMMAND() {
		return this.executeCommand('ChangeGroupRightCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public BulkChangeGroupRightCOMMAND() {
		return this.executeCommand('BulkChangeGroupRightCOMMAND')
	}

	/**
	 * Aktualizace počítaných polí
	 */
	public BulkUpdateCalcFieldsCOMMAND() {
		return this.executeCommand('BulkUpdateCalcFieldsCOMMAND')
	}

	/**
	 * Kontrola legislativy
	 */
	public SwitchOffLegislationValidationCOMMAND() {
		return this.executeCommand('SwitchOffLegislationValidationCOMMAND')
	}
}

export type TCouponCreateCOMMANDParams = {
	/**
	 * Počet kupónů
	 */
	CouponCount?: number
	/**
	 * Počáteční část kódu
	 */
	TextBefore?: string
	/**
	 * Konečná část kódu
	 */
	TextBehind?: string
}

export type TCouponActivationCOMMANDParams = {
	/**
	 * Datum od
	 */
	DateFrom?: Date
}

export type TBulkCouponActivationCOMMANDParams = {
	/**
	 * Datum od
	 */
	DateFrom?: Date
}

export type TBulkChangeConfirmationDateCOMMANDParams = {
	/**
	 * Datum potvrzení
	 */
	Confirmation1Date?: Date
	/**
	 * Hodina potvrzení
	 */
	Confirmation1Time?: Date
	/**
	 * Datum 2. potvrzení
	 */
	Confirmation2Date?: Date
	/**
	 * Hodina 2. potvrzení
	 */
	Confirmation2Time?: Date
	/**
	 * Kontrolovat záporné stavy
	 */
	CheckNegativeState?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TChangeConfirmationDateCOMMANDParams = {
	/**
	 * Datum potvrzení
	 */
	Confirmation1Date?: Date
	/**
	 * Hodina potvrzení
	 */
	Confirmation1Time?: Date
	/**
	 * Datum 2. potvrzení
	 */
	Confirmation2Date?: Date
	/**
	 * Hodina 2. potvrzení
	 */
	Confirmation2Time?: Date
	/**
	 * Kontrolovat záporné stavy
	 */
	CheckNegativeState?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TChangeRecordStatusSWSCOMMANDParams = {
	/**
	 * StatusId
	 */
	StatusId?: number
}

export type TBulkChangeAttributesCOMMANDParams = {
	/**
	 * Smazat atributy
	 */
	DeleteAttribute?: boolean
	/**
	 * Smazat všechny volné atributy
	 */
	DeleteFreeAttribute?: boolean
	/**
	 * Nastavit hodnoty atributů
	 */
	SetValues?: boolean
	/**
	 * Přidat nepřipojené atributy
	 */
	AddFreeAttributes?: boolean
	/**
	 * FreeAttributeEnabled
	 */
	FreeAttributeEnabled?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TBulkActualizeAttributesCOMMANDParams = {
	/**
	 * Aktualizovat včetně samotné sady atributů
	 */
	ActualizeAttributeSet?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}