import Data from 'react-k2-api/Api/Data'
import { Type } from 'class-transformer'
import TWebContactPersonDM from './TWebContactPersonDM'
import TWebBranchDM from './TWebBranchDM'

/**
 * Termíny
 */
export default class TWebAppointmentMemoryDM extends Data {
	public static className = 'TWebAppointmentMemoryDM'
	public static systemFields = [
		'RecordID',
	]

	public static isHeader = false

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * undefined
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * Volných míst
	 */
	FreePlaces?: number

	/**
	 * Kapacita
	 */
	Capacity?: number

	/**
	 * Popis
	 */
	Description?: string

	/**
	 * Konec
	 */
	DateTo?: Date

	/**
	 * Začátek
	 */
	DateFrom?: Date

	/**
	 * Místo
	 */
	Place?: string

	/**
	 * Propojení modulů
	 */
	WebLinkRID?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Datum začátku
	 */
	DateFromCalc?: Date

	/**
	 * Datum konce
	 */
	DateToCalc?: Date

	/**
	 * Čas začátku
	 */
	TimeFromCalc?: Date

	/**
	 * Čas konce
	 */
	TimeToCalc?: Date

	/**
	 * Lektor
	 */
	@Type(() => TWebContactPersonDM)
	LectorRID?: TWebContactPersonDM

	/**
	 * Pobočka
	 */
	@Type(() => TWebBranchDM)
	BranchRID?: TWebBranchDM
}