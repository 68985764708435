import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TTextDM from './TTextDM'
import TK2UserLookupDM from './TK2UserLookupDM'
import TPrTariffLevelDM from './TPrTariffLevelDM'

/**
 * Tarifní tabulky k datumu
 */
export default class TPrTariffDateDM extends Data {
	public static className = 'TPrTariffDateDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = false

	/**
	 * Změněno
	 */
	ChangedOn?: Date

	/**
	 * Vytvořeno
	 */
	CreatedOn?: Date

	/**
	 * Časové razítko
	 */
	TimeStamp?: string

	/**
	 * Právo na záznam
	 */
	RightGroupId?: number

	/**
	 * Tarifní tabulka
	 */
	PrTariffRID?: number

	/**
	 * Datum od
	 */
	ValidFrom?: Date

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Právo na záznam
	 */
	RightGroupIdCalc?: number

	/**
	 * Stupeň do
	 */
	@Type(() => TTextDM)
	LevelTo?: TTextDM

	/**
	 * Stupeň od
	 */
	@Type(() => TTextDM)
	LevelFrom?: TTextDM

	/**
	 * Změnil
	 */
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM

	/**
	 * Vytvořil
	 */
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM

	/**
	 * Třída do
	 */
	@Type(() => TTextDM)
	GradeTo?: TTextDM

	/**
	 * Třída od
	 */
	@Type(() => TTextDM)
	GradeFrom?: TTextDM

	/**
	 * Tarifní stupně
	 */
	@Type(() => TPrTariffLevelDM)
	PrTariffLevelChild?: TPrTariffLevelDM[]
}