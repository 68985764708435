import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TExternalBatchDM from './TExternalBatchDM'
import TCode6DocumentDM from './TCode6DocumentDM'
import TCode5DocumentDM from './TCode5DocumentDM'
import TCode4DocumentDM from './TCode4DocumentDM'
import TCode3DocumentDM from './TCode3DocumentDM'
import WarrantyB from './WarrantyB'
import TBatchDM from './TBatchDM'
import TRequestedBatchParameterHeaderDM from './TRequestedBatchParameterHeaderDM'
import TDeviceDocumentDM from './TDeviceDocumentDM'
import TPriceGroupDM from './TPriceGroupDM'
import TRoutingVariantDM from './TRoutingVariantDM'
import TMarketingPhaseDM from './TMarketingPhaseDM'
import TArticleCodeDocumentDM from './TArticleCodeDocumentDM'
import TOfficerDocumentDM from './TOfficerDocumentDM'
import TCode2DocumentDM from './TCode2DocumentDM'
import TCode1DocumentDM from './TCode1DocumentDM'
import TContractCodeDocumentDM from './TContractCodeDocumentDM'
import TCostCentreCollectionDocumentDM from './TCostCentreCollectionDocumentDM'
import QuotationReqItemStat from './QuotationReqItemStat'
import TArticleDM from './TArticleDM'
import MrktngDocItemGroup from './MrktngDocItemGroup'
import TQuotationDM from './TQuotationDM'
import TMeasureUnitDM from './TMeasureUnitDM'
import AlternativeUnit from './AlternativeUnit'
import TPriceListCalculationDM from './TPriceListCalculationDM'

/**
 * Požadované položky
 */
export default class TQuotationRequestedItemDM extends Data {
	public static className = 'TQuotationRequestedItemDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = true

	/**
	 * Kalkulace
	 */
	CostingBind?: number

	/**
	 * Datum realizace
	 */
	ImplementationDate?: Date

	/**
	 * Sazba DPH
	 */
	VATRateId?: number

	/**
	 * Požadovaná položka
	 */
	RequiredItemRID?: number

	/**
	 * Interní text
	 */
	InternalText?: string

	/**
	 * Obchodní text
	 */
	BusinessText?: string

	/**
	 * Očekávaná cena
	 */
	EstimatedPrice?: number

	/**
	 * Nákladová cena
	 */
	CostPrice?: number

	/**
	 * Katalogová cena
	 */
	ListPrice?: number

	/**
	 * Nabízená cena
	 */
	OfferedPrice?: number

	/**
	 * Jednotka
	 */
	MeasureUnitId?: number

	/**
	 * Množství
	 */
	Quantity?: number

	/**
	 * &Název položky
	 */
	ArticleName?: string

	/**
	 * Pořadí
	 */
	SequenceNumber?: number

	/**
	 * Typ položky
	 */
	ItemTypeId?: number

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * undefined
	 */
	OriginalRID?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * &Množství
	 */
	QuantityUMCalc?: number

	/**
	 * Měna
	 */
	CurrencyCalc?: string

	/**
	 * Nabíd. cena
	 */
	ExchangeRateCalc?: number

	/**
	 * Očekáv. cena / MJ
	 */
	EstimatedUnitPriceCalc?: number

	/**
	 * Očekávaná cena
	 */
	EstimatedTotalPriceCalc?: number

	/**
	 * Očekáv. &cena / MJ
	 */
	EstimatedPriceCCalc?: number

	/**
	 * Očekávaná cena
	 */
	EstimatedTotalPriceCCalc?: number

	/**
	 * Katalog. cena / MJ
	 */
	CatalogUnitPriceCalc?: number

	/**
	 * Nab. cena netto celkem
	 */
	OfferedTotalPriceNetCCalc?: number

	/**
	 * MJ
	 */
	MeasureUnitCalc?: string

	/**
	 * MJ koef.
	 */
	MeasureUnitCoefficientCalc?: number

	/**
	 * Název produktu
	 */
	ArticleNameCalc?: string

	/**
	 * Sleva
	 */
	OfferredTotalDiscountCalc?: number

	/**
	 * Sleva
	 */
	OfferedTotalDiscountCCalc?: number

	/**
	 * Zisk
	 */
	OfferredTotalProfitCalc?: number

	/**
	 * Zisk
	 */
	OfferedTotalProfitCCalc?: number

	/**
	 * Katalog. cena
	 */
	OfferredTotalCatalogPriceCalc?: number

	/**
	 * Katalog. cena
	 */
	OfferedTotalCatalogPriceCCalc?: number

	/**
	 * Nákl. cena
	 */
	OfferredTotalCostPriceCalc?: number

	/**
	 * Nákl. cena
	 */
	OfferedTotalCostPriceCCalc?: number

	/**
	 * Nabíd. cena
	 */
	OfferredTotalPriceCalc?: number

	/**
	 * Sleva
	 */
	OfferredDiscountPctCalc?: number

	/**
	 * Měna
	 */
	MainCurrencyCalc?: string

	/**
	 * DPH koef.
	 */
	CoefficientVATCalc?: number

	/**
	 * Realizováno
	 */
	ImplementationDateCalc?: Date

	/**
	 * Zb
	 */
	NotContainsArticleCalc?: string

	/**
	 * undefined
	 */
	ArticleTypeIdCalc?: number

	/**
	 * Externí šarže
	 */
	@Type(() => TExternalBatchDM)
	ExternalBatchId?: TExternalBatchDM

	/**
	 * Kód 6
	 */
	@Type(() => TCode6DocumentDM)
	Code6Id?: TCode6DocumentDM

	/**
	 * Kód 5
	 */
	@Type(() => TCode5DocumentDM)
	Code5Id?: TCode5DocumentDM

	/**
	 * Kód 4
	 */
	@Type(() => TCode4DocumentDM)
	Code4Id?: TCode4DocumentDM

	/**
	 * Kód 3
	 */
	@Type(() => TCode3DocumentDM)
	Code3Id?: TCode3DocumentDM

	/**
	 * Záruka
	 */
	@Type(() => WarrantyB)
	WarrantyId?: WarrantyB

	/**
	 * Šarže
	 */
	@Type(() => TBatchDM)
	BatchId?: TBatchDM

	/**
	 * Požadované parametry
	 */
	@Type(() => TRequestedBatchParameterHeaderDM)
	RequestedBatchParametersId?: TRequestedBatchParameterHeaderDM

	/**
	 * Prostředek
	 */
	@Type(() => TDeviceDocumentDM)
	DeviceRID?: TDeviceDocumentDM

	/**
	 * Cenová skupina
	 */
	@Type(() => TPriceGroupDM)
	PriceGroupId?: TPriceGroupDM

	/**
	 * Varianta
	 */
	@Type(() => TRoutingVariantDM)
	RoutingVariantId?: TRoutingVariantDM

	/**
	 * E&tapa
	 */
	@Type(() => TMarketingPhaseDM)
	PhaseRID?: TMarketingPhaseDM

	/**
	 * Kód zboží
	 */
	@Type(() => TArticleCodeDocumentDM)
	ArticleCodeId?: TArticleCodeDocumentDM

	/**
	 * Referent
	 */
	@Type(() => TOfficerDocumentDM)
	OfficerId?: TOfficerDocumentDM

	/**
	 * Kód 2
	 */
	@Type(() => TCode2DocumentDM)
	Code2Id?: TCode2DocumentDM

	/**
	 * Kód 1
	 */
	@Type(() => TCode1DocumentDM)
	Code1Id?: TCode1DocumentDM

	/**
	 * Kód zakázky
	 */
	@Type(() => TContractCodeDocumentDM)
	ContractCodeRID?: TContractCodeDocumentDM

	/**
	 * Středisko
	 */
	@Type(() => TCostCentreCollectionDocumentDM)
	CostCentreId?: TCostCentreCollectionDocumentDM

	/**
	 * Stav
	 */
	@Type(() => QuotationReqItemStat)
	ItemStatusId?: QuotationReqItemStat

	/**
	 * Zboží
	 */
	@Type(() => TArticleDM)
	ArticleId?: TArticleDM

	/**
	 * Skupina
	 */
	@Type(() => MrktngDocItemGroup)
	GroupId?: MrktngDocItemGroup

	/**
	 * HeaderRID
	 */
	@Type(() => TQuotationDM)
	HeaderRID?: TQuotationDM

	/**
	 * Jednotka
	 */
	@Type(() => TMeasureUnitDM)
	MeasureUnitByArticleIdCalc?: TMeasureUnitDM

	/**
	 * Jednotka
	 */
	@Type(() => AlternativeUnit)
	GeneralMueasureUnitIdCalc?: AlternativeUnit

	/**
	 * Zboží
	 */
	@Type(() => TArticleDM)
	ArticleByIdInputIdCalc?: TArticleDM

	/**
	 * Zboží
	 */
	@Type(() => TArticleDM)
	ArticleByAbbr2InputIdCalc?: TArticleDM

	/**
	 * Zboží
	 */
	@Type(() => TArticleDM)
	ArticleByNameInputIdCalc?: TArticleDM

	/**
	 * Průběh výpočtu ceny
	 */
	@Type(() => TPriceListCalculationDM)
	PriceCalculationChild?: TPriceListCalculationDM[]

	/**
	 * Přesun položky do nabízených
	 */
	public TransferItemsCOMMAND() {
		return this.executeCommand('TransferItemsCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public ChangeGroupRightCOMMAND() {
		return this.executeCommand('ChangeGroupRightCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public BulkChangeGroupRightCOMMAND() {
		return this.executeCommand('BulkChangeGroupRightCOMMAND')
	}

	/**
	 * Aktualizace počítaných polí
	 */
	public BulkUpdateCalcFieldsCOMMAND() {
		return this.executeCommand('BulkUpdateCalcFieldsCOMMAND')
	}

	/**
	 * Kontrola legislativy
	 */
	public SwitchOffLegislationValidationCOMMAND() {
		return this.executeCommand('SwitchOffLegislationValidationCOMMAND')
	}
}