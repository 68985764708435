import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TSalesOrderDM from './TSalesOrderDM'

/**
 * Uplatnění na dokladech
 */
export default class TPromotionApplicationMemoryDM extends Data {
	public static className = 'TPromotionApplicationMemoryDM'
	public static primaryKey = 'DocumentRID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.DocumentRID!
	}

	public static isHeader = false

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * undefined
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * undefined
	 */
	AppliedDate?: Date

	/**
	 * undefined
	 */
	AppliedCount?: number

	/**
	 * undefined
	 */
	DocumentRID?: number

	/**
	 * undefined
	 */
	DocumentTypeId?: number

	/**
	 * undefined
	 */
	RID?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Prodaných položek
	 */
	SoldQuantityCalc?: number

	/**
	 * Prodáno za (netto)
	 */
	SoldPriceNetCalc?: number

	/**
	 * Prodáno za (brutto)
	 */
	SoldPriceGrossCalc?: number

	/**
	 * Dárků
	 */
	GiftCountCalc?: number

	/**
	 * Zakázka
	 */
	@Type(() => TSalesOrderDM)
	SalesOrderRIDCalc?: TSalesOrderDM
}