import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TTextDM from './TTextDM'

/**
 * Položky přestávek typu směny modulu pracovní doby
 */
export default class TAttendanceWorkShiftBreakItemDM extends Data {
	public static className = 'TAttendanceWorkShiftBreakItemDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = false

	/**
	 * Do
	 */
	TimeTo?: number

	/**
	 * Od
	 */
	TimeFrom?: number

	/**
	 * Trvání směny před přestávkou
	 */
	BeforBreakDuration?: number

	/**
	 * Trvání směny po přestávce
	 */
	AfterBreakDuration?: number

	/**
	 * Trvání přestávky
	 */
	Duration?: number

	/**
	 * Pořadí
	 */
	SequenceNumber?: number

	/**
	 * Typ pracovní směny
	 */
	WorkShiftTypeRID?: number

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Typ přestávky
	 */
	@Type(() => TTextDM)
	BreakTypeId?: TTextDM

	/**
	 * Typ začátku přestávky
	 */
	@Type(() => TTextDM)
	BreakBeginTypeId?: TTextDM
}