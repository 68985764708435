import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TK2UserLookupDM from './TK2UserLookupDM'
import DegreeName from './DegreeName'
import SchoolName from './SchoolName'
import EducationFieldName from './EducationFieldName'
import TypeOfTestName from './TypeOfTestName'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'

/**
 * Vzdělání
 */
export default class TContactPersonEducationDM extends Data {
	public static className = 'TContactPersonEducationDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = false

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * Komentář
	 */
	Comment?: string

	/**
	 * Vznik
	 */
	CreatedOn?: Date

	/**
	 * Změněno
	 */
	ChangedOn?: Date

	/**
	 * Ukončeno
	 */
	CompletedDate?: Date

	/**
	 * Pořadí
	 */
	SequenceNumber?: number

	/**
	 * Kont. os.
	 */
	ContactPersonId?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Vytvořil
	 */
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM

	/**
	 * Změnil
	 */
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM

	/**
	 * Stupeň
	 */
	@Type(() => DegreeName)
	Degree?: DegreeName

	/**
	 * Škola
	 */
	@Type(() => SchoolName)
	SchoolId?: SchoolName

	/**
	 * Obor
	 */
	@Type(() => EducationFieldName)
	Field?: EducationFieldName

	/**
	 * Druh zk.
	 */
	@Type(() => TypeOfTestName)
	ExamType?: TypeOfTestName

	/**
	 * undefined
	 */
	@Type(() => TLanguageMemoTranslationDM)
	CommentChild?: TLanguageMemoTranslationDM[]
}