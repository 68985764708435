import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TTextDM from './TTextDM'
import TABCItemDM from './TABCItemDM'

/**
 * ABC Analýzy
 */
export default class TABCAnalysisDM extends Data {
	public static className = 'TABCAnalysisDM'
	public static primaryKey = 'RID'
	public static systemFields = [
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = true

	/**
	 * Zkratka
	 */
	Abbr?: string

	/**
	 * Hodnota
	 */
	TotalValue?: number

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * Název
	 */
	Name?: string

	/**
	 * Počet položek
	 */
	ItemCount?: number

	/**
	 * Datum do
	 */
	DateTo?: Date

	/**
	 * Datum od
	 */
	DateFrom?: Date

	/**
	 * Konfigurace
	 */
	ABCConfigRID?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * undefined
	 */
	TrendCalc?: number

	/**
	 * undefined
	 */
	LastFlagBitCalc?: number

	/**
	 * Modul
	 */
	@Type(() => TTextDM)
	DataModuleNumber?: TTextDM

	/**
	 * undefined
	 */
	@Type(() => TABCItemDM)
	ItemChild?: TABCItemDM[]

	/**
	 * undefined
	 */
	@Type(() => TABCItemDM)
	NonZeroItemChild?: TABCItemDM[]

	/**
	 * Změna skupiny práv
	 */
	public ChangeGroupRightCOMMAND() {
		return this.executeCommand('ChangeGroupRightCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public BulkChangeGroupRightCOMMAND() {
		return this.executeCommand('BulkChangeGroupRightCOMMAND')
	}

	/**
	 * Aktualizace počítaných polí
	 */
	public BulkUpdateCalcFieldsCOMMAND() {
		return this.executeCommand('BulkUpdateCalcFieldsCOMMAND')
	}

	/**
	 * Kontrola legislativy
	 */
	public SwitchOffLegislationValidationCOMMAND() {
		return this.executeCommand('SwitchOffLegislationValidationCOMMAND')
	}
}