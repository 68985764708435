import Data from 'react-k2-api/Api/Data'
import { Type } from 'class-transformer'
import TK2UserLookupDM from './TK2UserLookupDM'
import TContactPersonDM from './TContactPersonDM'

/**
 * Historie zadání
 */
export default class TTaskHistoryDM extends Data {
	public static className = 'TTaskHistoryDM'
	public static systemFields = [
		'RecordID',
	]

	public static isHeader = false

	/**
	 * Typ modulu
	 */
	ModuleTypeId?: number

	/**
	 * Pořadí
	 */
	SequenceNumber?: number

	/**
	 * Komentář
	 */
	Memo?: string

	/**
	 * Datum do
	 */
	DateTo?: Date

	/**
	 * Datum od
	 */
	DateFrom?: Date

	/**
	 * Typ komentáře
	 */
	CommentTypeId?: number

	/**
	 * Úkol
	 */
	TaskId?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * Pořadí
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Uživatel
	 */
	@Type(() => TK2UserLookupDM)
	UserId?: TK2UserLookupDM

	/**
	 * Autor
	 */
	@Type(() => TContactPersonDM)
	AuthorId?: TContactPersonDM
}