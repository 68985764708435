import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TSpecialPrinterGroup from './TSpecialPrinterGroup'
import TK2UserLookupDM from './TK2UserLookupDM'
import ContractStatus from './ContractStatus'
import FormOfOrder from './FormOfOrder'
import TTradingPartnerDM from './TTradingPartnerDM'
import TWarehouseDM from './TWarehouseDM'
import TBookDM from './TBookDM'

/**
 * Scan&Go Settings
 */
export default class TSpecialScanAndGoSettings extends Data {
	public static className = 'TSpecialScanAndGoSettings'
	public static primaryKey = 'RID'
	public static systemFields = [
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = true

	/**
	 * Změněno
	 */
	ChangedOn?: Date

	/**
	 * Vytvořeno
	 */
	CreatedOn?: Date

	/**
	 * Popis
	 */
	Description?: string

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Printer Group
	 */
	@Type(() => TSpecialPrinterGroup)
	PrinterGroupRID?: TSpecialPrinterGroup

	/**
	 * Změnil
	 */
	@Type(() => TK2UserLookupDM)
	ChangedBy?: TK2UserLookupDM

	/**
	 * Vytvořil
	 */
	@Type(() => TK2UserLookupDM)
	CreatedBy?: TK2UserLookupDM

	/**
	 * Stav zakázky "Ke zpracování"
	 */
	@Type(() => ContractStatus)
	SalesOrderStatusPendingId?: ContractStatus

	/**
	 * Stav zakázky "Otevřená"
	 */
	@Type(() => ContractStatus)
	SalesOrderStatusOpenId?: ContractStatus

	/**
	 * Stav zakázky "Volná"
	 */
	@Type(() => ContractStatus)
	SalesOrderStatusFreeId?: ContractStatus

	/**
	 * Forma objednávky
	 */
	@Type(() => FormOfOrder)
	OrderFormId?: FormOfOrder

	/**
	 * Zákazník
	 */
	@Type(() => TTradingPartnerDM)
	TradingPartnerId?: TTradingPartnerDM

	/**
	 * Plánovaný sklad
	 */
	@Type(() => TWarehouseDM)
	PlannedWarehouseId?: TWarehouseDM

	/**
	 * Kniha prodeje
	 */
	@Type(() => TBookDM)
	SalesBookId?: TBookDM

	/**
	 * Změna skupiny práv
	 */
	public ChangeGroupRightCOMMAND() {
		return this.executeCommand('ChangeGroupRightCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public BulkChangeGroupRightCOMMAND() {
		return this.executeCommand('BulkChangeGroupRightCOMMAND')
	}

	/**
	 * Aktualizace počítaných polí
	 */
	public BulkUpdateCalcFieldsCOMMAND() {
		return this.executeCommand('BulkUpdateCalcFieldsCOMMAND')
	}

	/**
	 * Kontrola legislativy
	 */
	public SwitchOffLegislationValidationCOMMAND() {
		return this.executeCommand('SwitchOffLegislationValidationCOMMAND')
	}
}