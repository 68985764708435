import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TCode6DocumentDM from './TCode6DocumentDM'
import TCode5DocumentDM from './TCode5DocumentDM'
import TCode4DocumentDM from './TCode4DocumentDM'
import TCode3DocumentDM from './TCode3DocumentDM'
import TArticleCodeDocumentDM from './TArticleCodeDocumentDM'
import TCostCentreCollectionDocumentDM from './TCostCentreCollectionDocumentDM'
import TTextDM from './TTextDM'
import ActivityStatus from './ActivityStatus'
import TDeviceDocumentDM from './TDeviceDocumentDM'
import TMarketingActivityTypeDM from './TMarketingActivityTypeDM'
import TMarketingSpecificationDM from './TMarketingSpecificationDM'
import OpportAndActiStatus from './OpportAndActiStatus'
import TContractCodeDocumentDM from './TContractCodeDocumentDM'
import TCode2DocumentDM from './TCode2DocumentDM'
import TCode1DocumentDM from './TCode1DocumentDM'
import TOfficerDocumentDM from './TOfficerDocumentDM'
import TK2UserLookupDM from './TK2UserLookupDM'
import TContactPersonDM from './TContactPersonDM'
import TOpportunityDM from './TOpportunityDM'
import TActivityDM from './TActivityDM'
import TCampaignDM from './TCampaignDM'
import TPartnerDM from './TPartnerDM'
import TWorkflowProcessDM from './TWorkflowProcessDM'
import TCommentDM from './TCommentDM'
import TJobDM from './TJobDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TMarketingActivityItemP2PDM from './TMarketingActivityItemP2PDM'
import TActivityTreeGroupedDM from './TActivityTreeGroupedDM'
import TExternalDocumentItemDM from './TExternalDocumentItemDM'
import TWorkflowProcessAsItemDM from './TWorkflowProcessAsItemDM'
import TAttachedDocumentDM from './TAttachedDocumentDM'
import TOldNoteDM from './TOldNoteDM'

/**
 * Aktivity
 */
export default class TActivityTreeDM extends Data {
	public static className = 'TActivityTreeDM'
	public static primaryKey = 'Id'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.Id!
	}

	public static isHeader = false

	/**
	 * ID zprávy
	 */
	MessageId?: string

	/**
	 * IsGlobalFolder
	 */
	IsGlobalFolder?: number

	/**
	 * Časové razítko
	 */
	TimeStamp?: string

	/**
	 * DocNo
	 */
	DocumentNumber?: number

	/**
	 * DocBook
	 */
	DocumentBookId?: string

	/**
	 * Čas volání
	 */
	CallCenterCallDate?: Date

	/**
	 * Volající
	 */
	CallCenterId?: number

	/**
	 * Původní aktivita
	 */
	OriginalActivityId?: number

	/**
	 * Předmět
	 */
	Subject?: number

	/**
	 * Nákladová cena
	 */
	CostPrice?: number

	/**
	 * Dat. potv.
	 */
	ConfirmedOn?: Date

	/**
	 * Formát
	 */
	FormatId?: number

	/**
	 * Právo na záznam
	 */
	RightGroupId?: number

	/**
	 * Datum real.
	 */
	ExecutionDate?: Date

	/**
	 * Pravděpodobnost
	 */
	ExecutionProbability?: number

	/**
	 * Objem
	 */
	Volume?: number

	/**
	 * Název
	 */
	Comment?: string

	/**
	 * Vytvořeno
	 */
	CreatedOn?: Date

	/**
	 * Změněno
	 */
	ChangedOn?: Date

	/**
	 * Datum do
	 */
	DateTo?: Date

	/**
	 * Datum od
	 */
	DateFrom?: Date

	/**
	 * Název
	 */
	Name?: string

	/**
	 * Číslo
	 */
	Id?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	BookmarkImgCalc?: string

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * SQLSequenceOrderCalc
	 */
	SQLSequenceOrderCalc?: number

	/**
	 * Datum pro "_ver" pole
	 */
	VersionedFieldDateCalc?: Date

	/**
	 * Prav. real. N
	 */
	ExecutionPropabilitySCalc?: number

	/**
	 * Datum real. N
	 */
	SuperiorActivityRealizDateCalc?: Date

	/**
	 * Objem N
	 */
	SuperiorActivityVolumeCalc?: number

	/**
	 * Sledovat
	 */
	MonitorCalc?: string

	/**
	 * Skrýt
	 */
	HideCalc?: string

	/**
	 * K
	 */
	IsCommentCalc?: string

	/**
	 * Komentář
	 */
	CommentActivityTreeCalc?: string

	/**
	 * undefined
	 */
	ActivityCountCalc?: number

	/**
	 * undefined
	 */
	LanguageCommentCalc?: string

	/**
	 * Text
	 */
	CommentCalc?: string

	/**
	 * Datum od
	 */
	DateFromCalc?: Date

	/**
	 * Čas od
	 */
	StartTimeCalc?: Date

	/**
	 * Datum do
	 */
	DateToCalc?: Date

	/**
	 * Čas do
	 */
	EndTimeCalc?: Date

	/**
	 * undefined
	 */
	TimeSpanCalc?: Date

	/**
	 * CallHistoryIdCalc
	 */
	CallHistoryIdCalc?: number

	/**
	 * Ú
	 */
	IsJobExistBitCalc?: number

	/**
	 * undefined
	 */
	JobExistImgCalc?: string

	/**
	 * M
	 */
	MultiActivityImgCalc?: string

	/**
	 * Identifikace
	 */
	DocumentIdentificationCalc?: string

	/**
	 * Složka
	 */
	GlobalFolderIdCalc?: number

	/**
	 * ListP2PCountCalc
	 */
	ListP2PCountCalc?: number

	/**
	 * PartnerAsStringCountCalc
	 */
	PartnerAsStringCountCalc?: string

	/**
	 * PersonAsStringCountCalc
	 */
	PersonAsStringCountCalc?: string

	/**
	 * undefined
	 */
	ShowActivityTreeCalc?: string

	/**
	 * undefined
	 */
	NodeNameCalc?: string

	/**
	 * undefined
	 */
	SubActivityCountCalc?: number

	/**
	 * Hodnota aktivity
	 */
	ActivityValueCalc?: number

	/**
	 * undefined
	 */
	TextHeaderMemoCalc?: string

	/**
	 * undefined
	 */
	TextFooterMemoCalc?: string

	/**
	 * Poslední komentář
	 */
	LastCommentParamCalc?: string

	/**
	 * Právo na záznam
	 */
	RightGroupIdCalc?: number

	/**
	 * Kód 6
	 */
	@Type(() => TCode6DocumentDM)
	Code6Id?: TCode6DocumentDM

	/**
	 * Kód 5
	 */
	@Type(() => TCode5DocumentDM)
	Code5Id?: TCode5DocumentDM

	/**
	 * Kód 4
	 */
	@Type(() => TCode4DocumentDM)
	Code4Id?: TCode4DocumentDM

	/**
	 * Kód 3
	 */
	@Type(() => TCode3DocumentDM)
	Code3Id?: TCode3DocumentDM

	/**
	 * Kód zboží
	 */
	@Type(() => TArticleCodeDocumentDM)
	ArticleCodeId?: TArticleCodeDocumentDM

	/**
	 * Středisko
	 */
	@Type(() => TCostCentreCollectionDocumentDM)
	CostCentreId?: TCostCentreCollectionDocumentDM

	/**
	 * H
	 */
	@Type(() => TTextDM)
	RatingId?: TTextDM

	/**
	 * Stav
	 */
	@Type(() => ActivityStatus)
	StatusActivityId?: ActivityStatus

	/**
	 * Prostředek
	 */
	@Type(() => TDeviceDocumentDM)
	DeviceRID?: TDeviceDocumentDM

	/**
	 * Typ
	 */
	@Type(() => TMarketingActivityTypeDM)
	ActivityTypeRID?: TMarketingActivityTypeDM

	/**
	 * Specifikace
	 */
	@Type(() => TMarketingSpecificationDM)
	SpecificationRID?: TMarketingSpecificationDM

	/**
	 * Stav příležitosti
	 */
	@Type(() => OpportAndActiStatus)
	OpportunityStatusId?: OpportAndActiStatus

	/**
	 * Kód zakázky
	 */
	@Type(() => TContractCodeDocumentDM)
	ContractCodeRID?: TContractCodeDocumentDM

	/**
	 * DocType
	 */
	@Type(() => TTextDM)
	DocumentTypeId?: TTextDM

	/**
	 * Kód 2
	 */
	@Type(() => TCode2DocumentDM)
	Code2Id?: TCode2DocumentDM

	/**
	 * Kód 1
	 */
	@Type(() => TCode1DocumentDM)
	Code1Id?: TCode1DocumentDM

	/**
	 * Referent
	 */
	@Type(() => TOfficerDocumentDM)
	OfficerId?: TOfficerDocumentDM

	/**
	 * Kdo potv.
	 */
	@Type(() => TK2UserLookupDM)
	ConfirmedById?: TK2UserLookupDM

	/**
	 * Zodp. os.
	 */
	@Type(() => TContactPersonDM)
	ResponsiblePersonId?: TContactPersonDM

	/**
	 * Vytvořil
	 */
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM

	/**
	 * Změnil
	 */
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM

	/**
	 * Příležitost
	 */
	@Type(() => TOpportunityDM)
	OpportunityRID?: TOpportunityDM

	/**
	 * Nadř. akt.
	 */
	@Type(() => TActivityDM)
	SuperiorActivityId?: TActivityDM

	/**
	 * Kampaň
	 */
	@Type(() => TCampaignDM)
	CampaignRID?: TCampaignDM

	/**
	 * Z
	 */
	@Type(() => TTextDM)
	InvalidRecordImgCalc?: TTextDM

	/**
	 * Akvizice N
	 */
	@Type(() => OpportAndActiStatus)
	AcquisitionIdCalc?: OpportAndActiStatus

	/**
	 * P
	 */
	@Type(() => TTextDM)
	ConfirmedBitCalc?: TTextDM

	/**
	 * Kontaktní osoba
	 */
	@Type(() => TContactPersonDM)
	ContactPersonRIDCalc?: TContactPersonDM

	/**
	 * Partner
	 */
	@Type(() => TPartnerDM)
	PartnerRIDCalc?: TPartnerDM

	/**
	 * D
	 */
	@Type(() => TTextDM)
	DocumentExistsCalc?: TTextDM

	/**
	 * undefined
	 */
	@Type(() => TMarketingActivityTypeDM)
	ActivityTypeRIDCalc?: TMarketingActivityTypeDM

	/**
	 * SP
	 */
	@Type(() => TTextDM)
	ProcessStateIdCalc?: TTextDM

	/**
	 * Aktuální proces
	 */
	@Type(() => TWorkflowProcessDM)
	CurrentProcessIdCalc?: TWorkflowProcessDM

	/**
	 * undefined
	 */
	@Type(() => TActivityDM)
	ActivityIdCalc?: TActivityDM

	/**
	 * Komentáře
	 */
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]

	/**
	 * Úkoly
	 */
	@Type(() => TJobDM)
	JobChild?: TJobDM[]

	/**
	 * Komentář
	 */
	@Type(() => TLanguageMemoTranslationDM)
	CommentMarketChild?: TLanguageMemoTranslationDM[]

	/**
	 * Kontaktní osoby a partneři - položky
	 */
	@Type(() => TMarketingActivityItemP2PDM)
	ContactPersonsPartnerItemChild?: TMarketingActivityItemP2PDM[]

	/**
	 * Aktivity
	 */
	@Type(() => TActivityTreeGroupedDM)
	ActivityTreeGroupedChild?: TActivityTreeGroupedDM[]

	/**
	 * undefined
	 */
	@Type(() => TActivityDM)
	ActivityChild?: TActivityDM[]

	/**
	 * Dokumenty
	 */
	@Type(() => TExternalDocumentItemDM)
	ExternalDocumentItemChild?: TExternalDocumentItemDM[]

	/**
	 * Procesy
	 */
	@Type(() => TWorkflowProcessAsItemDM)
	WorkflowProcessChild?: TWorkflowProcessAsItemDM[]

	/**
	 * Text hlavičky
	 */
	@Type(() => TLanguageMemoTranslationDM)
	TextHeaderChild?: TLanguageMemoTranslationDM[]

	/**
	 * Text patičky
	 */
	@Type(() => TLanguageMemoTranslationDM)
	TextFooterChild?: TLanguageMemoTranslationDM[]

	/**
	 * Svázané doklady
	 */
	@Type(() => TAttachedDocumentDM)
	AttachedDocumentOldChild?: TAttachedDocumentDM[]

	/**
	 * Poznámky
	 */
	@Type(() => TOldNoteDM)
	DeprecatedNoteOldChild?: TOldNoteDM[]
}