import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TK2UserLookupDM from './TK2UserLookupDM'
import TRegisteredDataModuleDM from './TRegisteredDataModuleDM'
import TTextDM from './TTextDM'
import TFragmentRasterDM from './TFragmentRasterDM'
import TFragmentAssignDM from './TFragmentAssignDM'

/**
 * Fragments
 */
export default class TFragmentDM extends Data {
	public static className = 'TFragmentDM'
	public static primaryKey = 'Id'
	public static systemFields = [
	]

	public getPrimaryKey(): PrimaryKey {
		return this.Id!
	}

	public static isHeader = true

	/**
	 * RootIdentifier
	 */
	RootIdentifier?: string

	/**
	 * ClientType
	 */
	ClientTypeId?: number

	/**
	 * Změněno
	 */
	ChangedOn?: Date

	/**
	 * Vytvořeno
	 */
	CreatedOn?: Date

	/**
	 * Content
	 */
	Content?: string

	/**
	 * Kind
	 */
	KindId?: number

	/**
	 * Group
	 */
	GroupId?: number

	/**
	 * Category
	 */
	CategoryId?: number

	/**
	 * identifikace
	 */
	Identifier?: string

	/**
	 * Číslo
	 */
	Id?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Knihovní
	 */
	IsLibraryFragmentCalc?: string

	/**
	 * Naimportovaný
	 */
	IsImportedCalc?: string

	/**
	 * Změnil
	 */
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM

	/**
	 * Vytvořil
	 */
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM

	/**
	 * DataMNo
	 */
	@Type(() => TRegisteredDataModuleDM)
	DataModuleNumber?: TRegisteredDataModuleDM

	/**
	 * CompType
	 */
	@Type(() => TTextDM)
	ComponentTypeId?: TTextDM

	/**
	 * Raster
	 */
	@Type(() => TFragmentRasterDM)
	RasterRID?: TFragmentRasterDM

	/**
	 * State
	 */
	@Type(() => TTextDM)
	StateId?: TTextDM

	/**
	 * undefined
	 */
	@Type(() => TFragmentAssignDM)
	ItemChild?: TFragmentAssignDM[]

	/**
	 * Změna skupiny práv
	 */
	public ChangeGroupRightCOMMAND() {
		return this.executeCommand('ChangeGroupRightCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public BulkChangeGroupRightCOMMAND() {
		return this.executeCommand('BulkChangeGroupRightCOMMAND')
	}

	/**
	 * Aktualizace počítaných polí
	 */
	public BulkUpdateCalcFieldsCOMMAND() {
		return this.executeCommand('BulkUpdateCalcFieldsCOMMAND')
	}

	/**
	 * Kontrola legislativy
	 */
	public SwitchOffLegislationValidationCOMMAND() {
		return this.executeCommand('SwitchOffLegislationValidationCOMMAND')
	}
}