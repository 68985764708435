import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TAddressDM from './TAddressDM'
import TK2UserLookupDM from './TK2UserLookupDM'
import TTownDM from './TTownDM'
import TTownPartDM from './TTownPartDM'
import TCountryDM from './TCountryDM'

/**
 * Adresy místa pobytu
 */
export default class TPrEmployeeSickNoteAddressDM extends Data {
	public static className = 'TPrEmployeeSickNoteAddressDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = false

	/**
	 * Datum do
	 */
	DateTo?: Date

	/**
	 * Datum od
	 */
	DateFrom?: Date

	/**
	 * eNeschopenka
	 */
	PrEmployeeSickNoteRID?: number

	/**
	 * Právo na záznam
	 */
	RightGroupId?: number

	/**
	 * Časové razítko
	 */
	TimeStamp?: string

	/**
	 * Změněno
	 */
	ChangedOn?: Date

	/**
	 * Vytvořeno
	 */
	CreatedOn?: Date

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * Souřadnice (Adresa místa pobytu)
	 */
	AddressIdCoordinates?: string

	/**
	 * PSČ (Adresa místa pobytu)
	 */
	AddressIdTownCode?: string

	/**
	 * undefined
	 */
	AddressIdChanged?: number

	/**
	 * Název (Adresa místa pobytu)
	 */
	AddressIdName?: string

	/**
	 * Číslo popisné (Adresa místa pobytu)
	 */
	AddressIdHouseNumber?: string

	/**
	 * Číslo orientační (Adresa místa pobytu)
	 */
	AddressIdOrientationNumber?: string

	/**
	 * Ulice (Adresa místa pobytu)
	 */
	AddressIdStreet?: string

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Právo na záznam
	 */
	RightGroupIdCalc?: number

	/**
	 * Adresa místa pobytu
	 */
	@Type(() => TAddressDM)
	AddressId?: TAddressDM

	/**
	 * Změnil
	 */
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM

	/**
	 * Vytvořil
	 */
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM

	/**
	 * Číslo (Adresa místa pobytu)
	 */
	@Type(() => TTownDM)
	AddressIdTownId?: TTownDM

	/**
	 * Část obce (Adresa místa pobytu)
	 */
	@Type(() => TTownPartDM)
	AddressIdTownPartId?: TTownPartDM

	/**
	 * Stát (Adresa místa pobytu)
	 */
	@Type(() => TCountryDM)
	AddressIdCountryId?: TCountryDM
}