import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TSpecialPrinterGroup from './TSpecialPrinterGroup'
import TSpecialPrinter from './TSpecialPrinter'
import TSpecialPrinterType from './TSpecialPrinterType'
import TContactPersonDM from './TContactPersonDM'
import TK2UserLookupDM from './TK2UserLookupDM'
import TCommentDM from './TCommentDM'
import TSpecialPrintBufferParameters from './TSpecialPrintBufferParameters'

/**
 * Print buffer
 */
export default class TSpecialPrintBuffer extends Data {
	public static className = 'TSpecialPrintBuffer'
	public static primaryKey = 'RID'
	public static systemFields = [
		'Timestamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = true

	/**
	 * Is Double Side
	 */
	IsDoubleSide?: string

	/**
	 * Formation GUID
	 */
	FormationGUID?: string

	/**
	 * Printing paused
	 */
	IsPrintingPaused?: string

	/**
	 * Print start date
	 */
	PrintStartDate?: Date

	/**
	 * Use default printer
	 */
	UseDefaultPrinter?: string

	/**
	 * File type
	 */
	FileType?: number

	/**
	 * Filename
	 */
	Filename?: string

	/**
	 * Is printed
	 */
	IsPrinted?: string

	/**
	 * Printer Address
	 */
	PrinterAddress?: string

	/**
	 * Is special
	 */
	IsSpecial?: string

	/**
	 * Print date
	 */
	PrintDate?: Date

	/**
	 * Addition date
	 */
	AdditionDate?: Date

	/**
	 * Count
	 */
	Count?: number

	/**
	 * Formation
	 */
	Formation?: string

	/**
	 * Record ID
	 */
	MasterID?: number

	/**
	 * Number of module
	 */
	MasterDataMNo?: number

	/**
	 * Changed on
	 */
	ChangedOn?: Date

	/**
	 * Created on
	 */
	CreatedOn?: Date

	/**
	 * Timestamp
	 */
	Timestamp?: string

	/**
	 * Flags
	 */
	Flags?: number

	/**
	 * RID
	 */
	RID?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Poslední komentář
	 */
	LastCommentParamCalc?: string

	/**
	 * File type title
	 */
	FileTypeTitleCalc?: string

	/**
	 * Is File Exists
	 */
	IsFileExists?: number

	/**
	 * Printer group
	 */
	@Type(() => TSpecialPrinterGroup)
	PrinterGroup?: TSpecialPrinterGroup

	/**
	 * Printer
	 */
	@Type(() => TSpecialPrinter)
	Printer?: TSpecialPrinter

	/**
	 * Printer type
	 */
	@Type(() => TSpecialPrinterType)
	PrinterType?: TSpecialPrinterType

	/**
	 * Contact person
	 */
	@Type(() => TContactPersonDM)
	ContactPersonId?: TContactPersonDM

	/**
	 * Changed by
	 */
	@Type(() => TK2UserLookupDM)
	ChangedBy?: TK2UserLookupDM

	/**
	 * Created by
	 */
	@Type(() => TK2UserLookupDM)
	CreatedBy?: TK2UserLookupDM

	/**
	 * Komentáře
	 */
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]

	/**
	 * Parameters
	 */
	@Type(() => TSpecialPrintBufferParameters)
	Parameters?: TSpecialPrintBufferParameters[]

	/**
	 * Změna skupiny práv
	 */
	public ChangeGroupRightCOMMAND() {
		return this.executeCommand('ChangeGroupRightCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public BulkChangeGroupRightCOMMAND() {
		return this.executeCommand('BulkChangeGroupRightCOMMAND')
	}

	/**
	 * Aktualizace počítaných polí
	 */
	public BulkUpdateCalcFieldsCOMMAND() {
		return this.executeCommand('BulkUpdateCalcFieldsCOMMAND')
	}

	/**
	 * Kontrola legislativy
	 */
	public SwitchOffLegislationValidationCOMMAND() {
		return this.executeCommand('SwitchOffLegislationValidationCOMMAND')
	}
}