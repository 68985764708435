import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TArticleDM from './TArticleDM'

/**
 * Vystavené zboží
 */
export default class TEshopExposedArticleDM extends Data {
	public static className = 'TEshopExposedArticleDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = false

	/**
	 * Upřednostnit brutto cenu
	 */
	PreferGrossPrice?: string

	/**
	 * Neslevnitelné
	 */
	IsFinalPrice?: string

	/**
	 * Podřízená varianta
	 */
	IsSlaveVariation?: string

	/**
	 * Měna
	 */
	Currency?: string

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * Cena b.
	 */
	PriceGross?: number

	/**
	 * Cena n.
	 */
	PriceNet?: number

	/**
	 * Zákazník
	 */
	TradingPartnerId?: number

	/**
	 * Cenová skupina
	 */
	PriceGroupId?: number

	/**
	 * E-shop
	 */
	ShopRID?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Zboží
	 */
	@Type(() => TArticleDM)
	ArticleId?: TArticleDM
}