import Data from 'react-k2-api/Api/Data'

/**
 * Knihy
 */
export default class TOldBookDM extends Data {
	public static className = 'TOldBookDM'
	public static systemFields = [
	]

	public static isHeader = true

	/**
	 * undefined
	 */
	CompanyId?: number

	/**
	 * PraSk
	 */
	RightGroupId?: number

	/**
	 * Popis
	 */
	Description?: string

	/**
	 * Kniha
	 */
	Book?: string

	/**
	 * E_
	 */
	TableId?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Txt
	 */
	TextCalc?: string

	/**
	 * PraSk
	 */
	RightGroupIdCalc?: number

	/**
	 * Změna skupiny práv
	 */
	public ChangeGroupRightCOMMAND() {
		return this.executeCommand('ChangeGroupRightCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public BulkChangeGroupRightCOMMAND() {
		return this.executeCommand('BulkChangeGroupRightCOMMAND')
	}

	/**
	 * Aktualizace počítaných polí
	 */
	public BulkUpdateCalcFieldsCOMMAND() {
		return this.executeCommand('BulkUpdateCalcFieldsCOMMAND')
	}

	/**
	 * Kontrola legislativy
	 */
	public SwitchOffLegislationValidationCOMMAND() {
		return this.executeCommand('SwitchOffLegislationValidationCOMMAND')
	}
}