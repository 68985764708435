import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TK2UserLookupDM from './TK2UserLookupDM'
import TTextDM from './TTextDM'
import TContactPersonDM from './TContactPersonDM'
import TPrEmploymentSimpleDM from './TPrEmploymentSimpleDM'
import TPrEmploymentDM from './TPrEmploymentDM'
import TMarketingActivityTypeDM from './TMarketingActivityTypeDM'
import TWorkflowProcessDM from './TWorkflowProcessDM'
import TCostCentreCollectionDocumentDM from './TCostCentreCollectionDocumentDM'
import TCommentDM from './TCommentDM'
import TWorkflowProcessAsItemDM from './TWorkflowProcessAsItemDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TCompanyLinkEmployedPersonDM from './TCompanyLinkEmployedPersonDM'
import TAttachedDocumentDM from './TAttachedDocumentDM'
import TOldNoteDM from './TOldNoteDM'

/**
 * Personální údaje zjednodušené
 */
export default class TEmployedPersonSimpleDM extends Data {
	public static className = 'TEmployedPersonSimpleDM'
	public static primaryKey = 'Id'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.Id!
	}

	public static isHeader = true

	/**
	 * Datum kódu změny
	 */
	HISChangeCodeDate?: Date

	/**
	 * Kód změny
	 */
	LastHISChangeCode?: string

	/**
	 * Přihlášen ZP
	 */
	IsHealthInsuranceRegistered?: string

	/**
	 * Osoba pracující v noci
	 */
	IsNightWorkingPerson?: string

	/**
	 * Rodný jazyk
	 */
	NativeLanguageId?: number

	/**
	 * Předchozí příjmení
	 */
	LastSurname?: string

	/**
	 * Stravenková karta
	 */
	LuncheonVoucherNumber?: string

	/**
	 * Vytvořeno
	 */
	CreatedOn?: Date

	/**
	 * XMLFieldScript
	 */
	XMLFieldScript?: string

	/**
	 * Vydal
	 */
	IdentityCardAuthority?: string

	/**
	 * Vydání OP
	 */
	IdentityCardIssuedOn?: Date

	/**
	 * Platnost OP
	 */
	IdentityCardExpiryDate?: Date

	/**
	 * ISIR datum
	 */
	RatingIsirDate?: Date

	/**
	 * ISIR hodnocení
	 */
	RatingIsirValue?: string

	/**
	 * Typ
	 */
	TypeId?: number

	/**
	 * Org. j. OSSZ
	 */
	OrganizationUnitPWDsId?: number

	/**
	 * DIČ
	 */
	VATRegNumber?: string

	/**
	 * Číslo zdrav. pojištění
	 */
	HealthInsurranceNumber?: string

	/**
	 * Počet vychovaných dětí
	 */
	BroughtUpChildrenCount?: number

	/**
	 * Pohlaví
	 */
	SexId?: number

	/**
	 * Důch. sp. do
	 */
	RetirementSavingDateTo?: Date

	/**
	 * Důch. sp. od
	 */
	RetirementSavingDateFrom?: Date

	/**
	 * Důch. sp. - č. účastníka
	 */
	RetirementSavingMemberNumber?: string

	/**
	 * Důch. sp. - partner
	 */
	RetirementSavingPartnerId?: number

	/**
	 * Č. rozhodnutí důch.
	 */
	PensionDecisionNumber?: string

	/**
	 * Email
	 */
	Email?: string

	/**
	 * Invalidita do
	 */
	DisabilityDateTo?: Date

	/**
	 * Invalidita od
	 */
	DisabilityDateFrom?: Date

	/**
	 * Sleva na invaliditu
	 */
	DisabilityId?: number

	/**
	 * XMLFields
	 */
	XMLField?: string

	/**
	 * Časové razítko
	 */
	TimeStamp?: string

	/**
	 * Stát rezidenta
	 */
	ResidentCountryId?: number

	/**
	 * Heslo
	 */
	EmailPassword?: string

	/**
	 * Státní příslušnost
	 */
	NationalityCountryId?: number

	/**
	 * Stát narození
	 */
	BirthCountryId?: number

	/**
	 * Místo narození
	 */
	BirthTownPartId?: number

	/**
	 * Plátce důchodu
	 */
	PensionPayerAddressId?: number

	/**
	 * Kontaktní adresa
	 */
	ContactAddressId?: number

	/**
	 * Přechodný pobyt
	 */
	TemporaryAddressId?: number

	/**
	 * Trvalý pobyt
	 */
	PermanentAddressId?: number

	/**
	 * Titul za
	 */
	DegreeAfterName?: string

	/**
	 * NewFields
	 */
	NewFields?: string

	/**
	 * ZZZZ
	 */
	AdditionalInfo?: string

	/**
	 * Plátce důchodu
	 */
	PensionPayer?: string

	/**
	 * DZPS
	 */
	WorkingAbility?: string

	/**
	 * Telefon domů 2
	 */
	TemporaryPhone?: string

	/**
	 * Telefon domů
	 */
	PermanentPhone?: string

	/**
	 * Počet vyživovaných osob
	 */
	DependentChildrenCount?: number

	/**
	 * Datum vzniku důchodu
	 */
	PensionDate?: Date

	/**
	 * Druh důch.
	 */
	PensionTypeId?: string

	/**
	 * Právo na záznam
	 */
	RightGroupId?: number

	/**
	 * Změněno
	 */
	ChangedOn?: Date

	/**
	 * Datum narození
	 */
	BirthDate?: Date

	/**
	 * Zdravotní pojišťovna
	 */
	HealthInsuranceId?: number

	/**
	 * Způsob dopravy
	 */
	TransportMode?: string

	/**
	 * Rodné příjmení
	 */
	BirthName?: string

	/**
	 * Titul
	 */
	Degree?: string

	/**
	 * Příjmení
	 */
	Surname?: string

	/**
	 * CisP
	 */
	Id?: number

	/**
	 * Vzdělání
	 */
	EducationLevel?: string

	/**
	 * Řidičský průkaz
	 */
	DriversLicence?: string

	/**
	 * Číslo pasu
	 */
	PassportNumber?: string

	/**
	 * Číslo OP
	 */
	IdentityCardNumber?: string

	/**
	 * Datum změny rod. stavu
	 */
	MaritalStatusDate?: Date

	/**
	 * Rodinný stav
	 */
	MaritalStatus?: string

	/**
	 * Telefon 2
	 */
	Phone2?: string

	/**
	 * Telefon
	 */
	Phone1?: string

	/**
	 * Rodné číslo
	 */
	BirthIdNumber?: string

	/**
	 * Jméno
	 */
	FirstName?: string

	/**
	 * Osobní číslo
	 */
	EmployeePersonalNumber?: string

	/**
	 * EX_Login
	 */
	EX_Login?: string

	/**
	 * EX_Zarazeni
	 */
	EX_Zarazeni?: string

	/**
	 * EX_LFS_USERNAME
	 */
	EX_LFS_USERNAME?: string

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Jméno
	 */
	ForenameSurnameOldCalc?: string

	/**
	 * Jméno
	 */
	ForenameSurnameCalc?: string

	/**
	 * Jméno
	 */
	ForenameSurnameTitleCalc?: string

	/**
	 * Jméno
	 */
	FullNameWithTitleCalc?: string

	/**
	 * Jméno
	 */
	FullNameCalc?: string

	/**
	 * Osobní číslo
	 */
	PersonalNumberCalc?: number

	/**
	 * undefined
	 */
	TextHeaderMemoCalc?: string

	/**
	 * undefined
	 */
	TextFooterMemoCalc?: string

	/**
	 * Poslední komentář
	 */
	LastCommentParamCalc?: string

	/**
	 * Právo na záznam
	 */
	RightGroupIdCalc?: number

	/**
	 * Vytvořil
	 */
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM

	/**
	 * Změnil
	 */
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM

	/**
	 * Z
	 */
	@Type(() => TTextDM)
	InvalidRecordImgCalc?: TTextDM

	/**
	 * Platnost ze všech prac. poměrů
	 */
	@Type(() => TTextDM)
	ValidityBitCalc?: TTextDM

	/**
	 * Kontaktní osoba
	 */
	@Type(() => TContactPersonDM)
	ContactPersonIdCalc?: TContactPersonDM

	/**
	 * Uživatel
	 */
	@Type(() => TK2UserLookupDM)
	K2UserIdCalc?: TK2UserLookupDM

	/**
	 * Platnost pracovních vztahů k datu
	 */
	@Type(() => TTextDM)
	EmploymentValidityDateCalc?: TTextDM

	/**
	 * Hlavní pracovní vztah
	 */
	@Type(() => TPrEmploymentSimpleDM)
	MainEmploymentIdCalc?: TPrEmploymentSimpleDM

	/**
	 * Hlavní pracovní vztah
	 */
	@Type(() => TPrEmploymentDM)
	MainPrEmploymentIdCalc?: TPrEmploymentDM

	/**
	 * Platnost
	 */
	@Type(() => TTextDM)
	EmploymentValidityBitCalc?: TTextDM

	/**
	 * D
	 */
	@Type(() => TTextDM)
	DocumentExistsCalc?: TTextDM

	/**
	 * undefined
	 */
	@Type(() => TMarketingActivityTypeDM)
	ActivityTypeRIDCalc?: TMarketingActivityTypeDM

	/**
	 * SP
	 */
	@Type(() => TTextDM)
	ProcessStateIdCalc?: TTextDM

	/**
	 * Aktuální proces
	 */
	@Type(() => TWorkflowProcessDM)
	CurrentProcessIdCalc?: TWorkflowProcessDM

	/**
	 * Středisko kmenové
	 */
	@Type(() => TCostCentreCollectionDocumentDM)
	CostCentreIdCalc?: TCostCentreCollectionDocumentDM

	/**
	 * Komentáře
	 */
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]

	/**
	 * undefined
	 */
	@Type(() => TPrEmploymentSimpleDM)
	PrEmploymentChild?: TPrEmploymentSimpleDM[]

	/**
	 * Procesy
	 */
	@Type(() => TWorkflowProcessAsItemDM)
	WorkflowProcessChild?: TWorkflowProcessAsItemDM[]

	/**
	 * Text hlavičky
	 */
	@Type(() => TLanguageMemoTranslationDM)
	TextHeaderChild?: TLanguageMemoTranslationDM[]

	/**
	 * Text patičky
	 */
	@Type(() => TLanguageMemoTranslationDM)
	TextFooterChild?: TLanguageMemoTranslationDM[]

	/**
	 * undefined
	 */
	@Type(() => TCompanyLinkEmployedPersonDM)
	CompanyChild?: TCompanyLinkEmployedPersonDM[]

	/**
	 * Svázané doklady
	 */
	@Type(() => TAttachedDocumentDM)
	AttachedDocumentOldChild?: TAttachedDocumentDM[]

	/**
	 * Poznámky
	 */
	@Type(() => TOldNoteDM)
	DeprecatedNoteOldChild?: TOldNoteDM[]

	/**
	 * Zneplatnění
	 */
	public BulkInvalidationCOMMAND() {
		return this.executeCommand('BulkInvalidationCOMMAND')
	}

	/**
	 * Zplatnění
	 */
	public BulkUnInvalidationCOMMAND() {
		return this.executeCommand('BulkUnInvalidationCOMMAND')
	}

	/**
	 * Zneplatnění/Zplatnění
	 */
	public InvalidationCOMMAND() {
		return this.executeCommand('InvalidationCOMMAND')
	}

	/**
	 * Potvrzení
	 */
	public BulkConfirmCOMMAND() {
		return this.executeCommand('BulkConfirmCOMMAND')
	}

	/**
	 * Odpotvrzení
	 */
	public BulkUnconfirmCOMMAND() {
		return this.executeCommand('BulkUnconfirmCOMMAND')
	}

	/**
	 * Změna data potvrzení
	 */
	public BulkChangeConfirmationDateCOMMAND(parameters: TBulkChangeConfirmationDateCOMMANDParams) {
		return this.executeCommand('BulkChangeConfirmationDateCOMMAND', parameters)
	}

	/**
	 * Storno
	 */
	public BulkCancelCOMMAND() {
		return this.executeCommand('BulkCancelCOMMAND')
	}

	/**
	 * Odstorno
	 */
	public BulkUnCancelCOMMAND() {
		return this.executeCommand('BulkUnCancelCOMMAND')
	}

	/**
	 * Potvrzení
	 */
	public ConfirmCOMMAND() {
		return this.executeCommand('ConfirmCOMMAND')
	}

	/**
	 * Odpotvrzení
	 */
	public UnconfirmCOMMAND() {
		return this.executeCommand('UnconfirmCOMMAND')
	}

	/**
	 * Změna data potvrzení
	 */
	public ChangeConfirmationDateCOMMAND(parameters: TChangeConfirmationDateCOMMANDParams) {
		return this.executeCommand('ChangeConfirmationDateCOMMAND', parameters)
	}

	/**
	 * Storno/Odstorno
	 */
	public CancelCOMMAND() {
		return this.executeCommand('CancelCOMMAND')
	}

	/**
	 * Zrušení stavu
	 */
	public ClearRecordStatusCOMMAND() {
		return this.executeCommand('ClearRecordStatusCOMMAND')
	}

	/**
	 * Změna stavu
	 */
	public ChangeRecordStatusCOMMAND() {
		return this.executeCommand('ChangeRecordStatusCOMMAND')
	}

	/**
	 * 
	 */
	public ChangeRecordStatusSWSCOMMAND(parameters: TChangeRecordStatusSWSCOMMANDParams) {
		return this.executeCommand('ChangeRecordStatusSWSCOMMAND', parameters)
	}

	/**
	 * Zrušení stavu
	 */
	public BulkClearRecordStatusCOMMAND() {
		return this.executeCommand('BulkClearRecordStatusCOMMAND')
	}

	/**
	 * Změna stavu
	 */
	public BulkChangeRecordStatusCOMMAND() {
		return this.executeCommand('BulkChangeRecordStatusCOMMAND')
	}

	/**
	 * Změna atributů
	 */
	public BulkChangeAttributesCOMMAND(parameters: TBulkChangeAttributesCOMMANDParams) {
		return this.executeCommand('BulkChangeAttributesCOMMAND', parameters)
	}

	/**
	 * Aktualizace sady atributů
	 */
	public BulkActualizeAttributesCOMMAND(parameters: TBulkActualizeAttributesCOMMANDParams) {
		return this.executeCommand('BulkActualizeAttributesCOMMAND', parameters)
	}

	/**
	 * Aktualizace sady atributů
	 */
	public ActualizeAttributesCOMMAND() {
		return this.executeCommand('ActualizeAttributesCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public ChangeGroupRightCOMMAND() {
		return this.executeCommand('ChangeGroupRightCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public BulkChangeGroupRightCOMMAND() {
		return this.executeCommand('BulkChangeGroupRightCOMMAND')
	}

	/**
	 * Aktualizace počítaných polí
	 */
	public BulkUpdateCalcFieldsCOMMAND() {
		return this.executeCommand('BulkUpdateCalcFieldsCOMMAND')
	}

	/**
	 * Kontrola legislativy
	 */
	public SwitchOffLegislationValidationCOMMAND() {
		return this.executeCommand('SwitchOffLegislationValidationCOMMAND')
	}
}

export type TBulkChangeConfirmationDateCOMMANDParams = {
	/**
	 * Datum potvrzení
	 */
	Confirmation1Date?: Date
	/**
	 * Hodina potvrzení
	 */
	Confirmation1Time?: Date
	/**
	 * Datum 2. potvrzení
	 */
	Confirmation2Date?: Date
	/**
	 * Hodina 2. potvrzení
	 */
	Confirmation2Time?: Date
	/**
	 * Kontrolovat záporné stavy
	 */
	CheckNegativeState?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TChangeConfirmationDateCOMMANDParams = {
	/**
	 * Datum potvrzení
	 */
	Confirmation1Date?: Date
	/**
	 * Hodina potvrzení
	 */
	Confirmation1Time?: Date
	/**
	 * Datum 2. potvrzení
	 */
	Confirmation2Date?: Date
	/**
	 * Hodina 2. potvrzení
	 */
	Confirmation2Time?: Date
	/**
	 * Kontrolovat záporné stavy
	 */
	CheckNegativeState?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TChangeRecordStatusSWSCOMMANDParams = {
	/**
	 * StatusId
	 */
	StatusId?: number
}

export type TBulkChangeAttributesCOMMANDParams = {
	/**
	 * Smazat atributy
	 */
	DeleteAttribute?: boolean
	/**
	 * Smazat všechny volné atributy
	 */
	DeleteFreeAttribute?: boolean
	/**
	 * Nastavit hodnoty atributů
	 */
	SetValues?: boolean
	/**
	 * Přidat nepřipojené atributy
	 */
	AddFreeAttributes?: boolean
	/**
	 * FreeAttributeEnabled
	 */
	FreeAttributeEnabled?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TBulkActualizeAttributesCOMMANDParams = {
	/**
	 * Aktualizovat včetně samotné sady atributů
	 */
	ActualizeAttributeSet?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}