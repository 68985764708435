import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TTextDM from './TTextDM'
import TCapacityPlanWorkshiftDM from './TCapacityPlanWorkshiftDM'

/**
 * CapacityPlanDay
 */
export default class TCapacityPlanDayDM extends Data {
	public static className = 'TCapacityPlanDayDM'
	public static primaryKey = 'Date'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.Date!
	}

	public static isHeader = false

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * undefined
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * undefined
	 */
	PlannedDateTimeTo?: Date

	/**
	 * undefined
	 */
	PlannedDateTimeFrom?: Date

	/**
	 * Vytížení %
	 */
	Utilization?: number

	/**
	 * undefined
	 */
	RequestedQuantity?: number

	/**
	 * undefined
	 */
	Capacity?: number

	/**
	 * Datum
	 */
	Date?: Date

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * undefined
	 */
	ShowInHoursCalc?: string

	/**
	 * Kapacita
	 */
	CapacityUMCalc?: number

	/**
	 * Požadavky
	 */
	RequestedQuantityUMCalc?: number

	/**
	 * Den
	 */
	@Type(() => TTextDM)
	WeekDayId?: TTextDM

	/**
	 * Směny
	 */
	@Type(() => TCapacityPlanWorkshiftDM)
	CapacityPlanWorkshiftChild?: TCapacityPlanWorkshiftDM[]
}