import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TParameterGroupDM from './TParameterGroupDM'
import TTextDM from './TTextDM'
import TParameterDM from './TParameterDM'

/**
 * Parametry typu šarže
 */
export default class TBatchTypeItemDM extends Data {
	public static className = 'TBatchTypeItemDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = false

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * Poř. sk.
	 */
	GroupSequenceNumber?: number

	/**
	 * Poř. vl.
	 */
	SequenceNumber?: number

	/**
	 * Č. typu šar.
	 */
	BatchTypeId?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Parametr
	 */
	NameCalc?: string

	/**
	 * Popis
	 */
	DescriptionCalc?: string

	/**
	 * Č. skup.
	 */
	@Type(() => TParameterGroupDM)
	GroupId?: TParameterGroupDM

	/**
	 * Typ par.
	 */
	@Type(() => TTextDM)
	ValueType?: TTextDM

	/**
	 * č. par.
	 */
	@Type(() => TParameterDM)
	ParameterId?: TParameterDM
}