import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TTextDM from './TTextDM'

/**
 * Atributy
 */
export default class TAttributeValueDM extends Data {
	public static className = 'TAttributeValueDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = false

	/**
	 * Pořadí
	 */
	SequenceNumber?: number

	/**
	 * Hodnota
	 */
	Value?: string

	/**
	 * ID atr.
	 */
	AttributeId?: number

	/**
	 * RID hlavičky
	 */
	HeaderRID?: number

	/**
	 * Č. DM
	 */
	HeaderDM?: number

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * undefined
	 */
	IconCalc?: number

	/**
	 * undefined
	 */
	ValueCalc?: string

	/**
	 * undefined
	 */
	ValueLongCalc?: number

	/**
	 * undefined
	 */
	ValueDecimalCalc?: number

	/**
	 * undefined
	 */
	ValueDateCalc?: Date

	/**
	 * undefined
	 */
	ValueTimeCalc?: Date

	/**
	 * undefined
	 */
	ValueListCalc?: string

	/**
	 * undefined
	 */
	CheckedBindListValueCalc?: string

	/**
	 * undefined
	 */
	@Type(() => TTextDM)
	ValueBoolCalc?: TTextDM

	/**
	 * undefined
	 */
	@Type(() => TTextDM)
	ValueBoolEmptyCalc?: TTextDM
}