import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TK2UserLookupDM from './TK2UserLookupDM'
import TPStatus from './TPStatus'
import TOfficerDocumentDM from './TOfficerDocumentDM'
import TRequestedBatchParameterHeaderDM from './TRequestedBatchParameterHeaderDM'
import TWarehouseDM from './TWarehouseDM'
import TRoutingVariantDM from './TRoutingVariantDM'
import TArticleDM from './TArticleDM'
import TTextDM from './TTextDM'

/**
 * Variantní technologické postupy
 */
export default class TArticleRoutingVariantDM extends Data {
	public static className = 'TArticleRoutingVariantDM'
	public static primaryKey = 'Id'
	public static systemFields = [
		'TimeStamp',
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.Id!
	}

	public static isHeader = false

	/**
	 * Blokace výběru - obchod
	 */
	BlockTradeDocuments?: string

	/**
	 * Blokace výběru - průvodky
	 */
	BlockJobCards?: string

	/**
	 * InvalidRecord
	 */
	IsInvalidRecord?: string

	/**
	 * Neúplný TP
	 */
	IsIncompleteRouting?: string

	/**
	 * StockUnit
	 */
	MeasureUnitId?: number

	/**
	 * Série do
	 */
	SerieTo?: number

	/**
	 * Série od
	 */
	SerieFrom?: number

	/**
	 * Časové razítko
	 */
	TimeStamp?: string

	/**
	 * Název
	 */
	ArticleName?: string

	/**
	 * Zkratka 2
	 */
	ArticleAbbr2?: string

	/**
	 * Zkratka 1
	 */
	ArticleAbbr1?: string

	/**
	 * Vytvořeno
	 */
	CreatedOn?: Date

	/**
	 * Právo na záznam
	 */
	RightGroupId?: number

	/**
	 * Průběžná doba
	 */
	LeadTime?: number

	/**
	 * Plán. skl. cena/ MJ
	 */
	StockPrice?: number

	/**
	 * Výrobní dávka
	 */
	ProductionLot?: number

	/**
	 * Kalkulační dávka
	 */
	CalculationLot?: number

	/**
	 * Datum do
	 */
	ValidTo?: Date

	/**
	 * Datum
	 */
	ValidFrom?: Date

	/**
	 * Hmotn.(kg) / MJ :
	 */
	Weight?: number

	/**
	 * Popis
	 */
	Description?: string

	/**
	 * Počet pol.
	 */
	ItemCount?: number

	/**
	 * Změněno
	 */
	ChangedOn?: Date

	/**
	 * Výkres
	 */
	Drawing?: string

	/**
	 * Množství
	 */
	Quantity?: number

	/**
	 * Varianta
	 */
	Abbr?: string

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * Unikát
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Hmotnost celkem
	 */
	TotalWeightCalc?: number

	/**
	 * Plán. skl. cena celkem
	 */
	TotalPriceCalc?: number

	/**
	 * Právo na záznam
	 */
	RightGroupIdCalc?: number

	/**
	 * Vytvořil
	 */
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM

	/**
	 * Stav
	 */
	@Type(() => TPStatus)
	StatusId?: TPStatus

	/**
	 * Osoba (kusovník)
	 */
	@Type(() => TOfficerDocumentDM)
	BOMOfficerId?: TOfficerDocumentDM

	/**
	 * ReqAttr
	 */
	@Type(() => TRequestedBatchParameterHeaderDM)
	RequestedBatchParametersId?: TRequestedBatchParameterHeaderDM

	/**
	 * Č. skladu
	 */
	@Type(() => TWarehouseDM)
	WarehouseId?: TWarehouseDM

	/**
	 * Unikát
	 */
	@Type(() => TRoutingVariantDM)
	Id?: TRoutingVariantDM

	/**
	 * Osoba (TP)
	 */
	@Type(() => TOfficerDocumentDM)
	OfficerId?: TOfficerDocumentDM

	/**
	 * Změnil
	 */
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM

	/**
	 * Číslo zboží
	 */
	@Type(() => TArticleDM)
	ArticleId?: TArticleDM

	/**
	 * Z
	 */
	@Type(() => TTextDM)
	InvalidRecordImgCalc?: TTextDM
}