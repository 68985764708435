import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TRoutingVariantOperationDM from './TRoutingVariantOperationDM'
import TResourceDM from './TResourceDM'
import TTextDM from './TTextDM'
import TRoutingVariantResourceSubstitutionDM from './TRoutingVariantResourceSubstitutionDM'
import TItemNoteDM from './TItemNoteDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'

/**
 * Zdroje
 */
export default class TRoutingVariantResourceDM extends Data {
	public static className = 'TRoutingVariantResourceDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = false

	/**
	 * Varianta
	 */
	RoutingId?: number

	/**
	 * Typ TP
	 */
	RoutingTypeId?: number

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * Pořadí
	 */
	SequenceNumber?: number

	/**
	 * Počet zdrojů
	 */
	Quantity?: number

	/**
	 * Poznámka
	 */
	NoteId?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * undefined
	 */
	OriginalRID?: number

	/**
	 * undefined
	 */
	OperationCode?: string

	/**
	 * undefined
	 */
	OperationSequenceNumber?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * undefined
	 */
	OriginalRIDCalc?: number

	/**
	 * RID oper.
	 */
	@Type(() => TRoutingVariantOperationDM)
	RoutingOperationRID?: TRoutingVariantOperationDM

	/**
	 * GroupId
	 */
	@Type(() => TResourceDM)
	GroupId?: TResourceDM

	/**
	 * Čís.zdroje
	 */
	@Type(() => TResourceDM)
	ResourceId?: TResourceDM

	/**
	 * Čas operace
	 */
	@Type(() => TTextDM)
	OperationTime?: TTextDM

	/**
	 * Čís.zdroje
	 */
	@Type(() => TResourceDM)
	ResourceByIdInputCalc?: TResourceDM

	/**
	 * Čís.zdroje
	 */
	@Type(() => TResourceDM)
	ResourceByNameInputCalc?: TResourceDM

	/**
	 * undefined
	 */
	@Type(() => TRoutingVariantResourceSubstitutionDM)
	RoutingVariantResSubstChild?: TRoutingVariantResourceSubstitutionDM[]

	/**
	 * undefined
	 */
	@Type(() => TItemNoteDM)
	ItemNotesChild?: TItemNoteDM[]

	/**
	 * undefined
	 */
	@Type(() => TLanguageMemoTranslationDM)
	ItemTextChild?: TLanguageMemoTranslationDM[]
}