import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import AlternativeUnit from './AlternativeUnit'

/**
 * Vzorce pro vícerozměrné množství
 */
export default class TParameterSetFormulaDM extends Data {
	public static className = 'TParameterSetFormulaDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = false

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * Proměnné pro zadání množství
	 */
	OtherVariable?: string

	/**
	 * Vzorec
	 */
	Formula?: string

	/**
	 * Číslo
	 */
	Id?: number

	/**
	 * Č. hlav.
	 */
	HeaderId?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * undefined
	 */
	FormulaErrorImgCalc?: string

	/**
	 * undefined
	 */
	VariableErrorImgCalc?: string

	/**
	 * Vzorec pro určení koeficientu
	 */
	FormulaForCoefficientIdCalc?: number

	/**
	 * Platnost pro výdej
	 */
	ForSaleIdCalc?: number

	/**
	 * Platnost pro příjem
	 */
	ForPurchaseIdCalc?: number

	/**
	 * Vzorec
	 */
	FormulaErrImgCalc?: number

	/**
	 * Proměnné
	 */
	VariablesErrImgCalc?: number

	/**
	 * Skl. jednotka
	 */
	@Type(() => AlternativeUnit)
	MeasureUnit?: AlternativeUnit
}