import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TParameterGroupDM from './TParameterGroupDM'
import TParameterDM from './TParameterDM'
import ParameterUnit from './ParameterUnit'
import TParameterPossibleValueListDM from './TParameterPossibleValueListDM'

/**
 * Požadované vlastnosti šarží - položky
 */
export default class TAttachedRequestedBatchParameterDM extends Data {
	public static className = 'TAttachedRequestedBatchParameterDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = false

	/**
	 * Pořadí
	 */
	SequenceNumber?: number

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * jazyk
	 */
	LanguageId?: number

	/**
	 * Unik. ID
	 */
	UniqueValueId?: number

	/**
	 * Hodnota k porovnání
	 */
	ComparisonValue?: number

	/**
	 * Priorita
	 */
	PriorityId?: number

	/**
	 * Hodnota
	 */
	Value?: string

	/**
	 * Č. DM
	 */
	HeaderDM?: number

	/**
	 * ID
	 */
	HeaderId?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * P
	 */
	RequiredBitCalc?: number

	/**
	 * P
	 */
	IsRequiredImgCalc?: string

	/**
	 * Parametr
	 */
	NameCalc?: string

	/**
	 * Popis
	 */
	DescriptionCalc?: string

	/**
	 * Hodnota
	 */
	ValueCalc?: string

	/**
	 * Hodnota
	 */
	ValueStringCalc?: string

	/**
	 * Hodnota
	 */
	ValueIntegerCalc?: number

	/**
	 * Hodnota
	 */
	ValueDecimalCalc?: number

	/**
	 * undefined
	 */
	ValueDateCalc?: Date

	/**
	 * Hodnota
	 */
	ValueTimeCalc?: Date

	/**
	 * undefined
	 */
	ValueFromListCalc?: string

	/**
	 * Skupina
	 */
	@Type(() => TParameterGroupDM)
	ParameterGroupId?: TParameterGroupDM

	/**
	 * Parametr
	 */
	@Type(() => TParameterDM)
	ParameterId?: TParameterDM

	/**
	 * Jednotka
	 */
	@Type(() => ParameterUnit)
	UnitId?: ParameterUnit

	/**
	 * Hodnota
	 */
	@Type(() => TParameterPossibleValueListDM)
	CheckedBindListValueCalc?: TParameterPossibleValueListDM
}