import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'

/**
 * Jazykové texty
 */
export default class TLanguageMemoTranslationChildDM extends Data {
	public static className = 'TLanguageMemoTranslationChildDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = false

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * Časové razítko
	 */
	TimeStamp?: string

	/**
	 * DetailModul
	 */
	DetailDataModuleNumber?: number

	/**
	 * Lang
	 */
	LanguageId?: number

	/**
	 * Memo
	 */
	Memo?: string

	/**
	 * Format
	 */
	Format?: number

	/**
	 * FieldID
	 */
	FieldNumber?: number

	/**
	 * DetailUniqID
	 */
	ItemId?: number

	/**
	 * MasterID
	 */
	MasterRID?: number

	/**
	 * MasterType
	 */
	HeaderTypeId?: number

	/**
	 * MasterSerie
	 */
	HeaderBook?: string

	/**
	 * MasterModul
	 */
	HeaderDataModuleNumber?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * undefined
	 */
	FieldNameCalc?: string
}