import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TTextDM from './TTextDM'
import TPrCalendarDM from './TPrCalendarDM'
import TBookDM from './TBookDM'
import TCode6DocumentDM from './TCode6DocumentDM'
import TCode5DocumentDM from './TCode5DocumentDM'
import TCode4DocumentDM from './TCode4DocumentDM'
import TCode3DocumentDM from './TCode3DocumentDM'
import TOrgStrTreeUnitDM from './TOrgStrTreeUnitDM'
import TK2UserLookupDM from './TK2UserLookupDM'
import TArticleCodeDocumentDM from './TArticleCodeDocumentDM'
import TCostCentreCollectionDocumentDM from './TCostCentreCollectionDocumentDM'
import DegreeName from './DegreeName'
import CZNACE from './CZNACE'
import TDeviceDocumentDM from './TDeviceDocumentDM'
import TOrgStrJobDM from './TOrgStrJobDM'
import TPrDeductionDM from './TPrDeductionDM'
import TContractCodeDocumentDM from './TContractCodeDocumentDM'
import TCurrencyDM from './TCurrencyDM'
import TAccountingChartDM from './TAccountingChartDM'
import DuvUkoncPP2 from './DuvUkoncPP2'
import TCountryDM from './TCountryDM'
import SkupPrac from './SkupPrac'
import MzdZUJ from './MzdZUJ'
import CZICSE from './CZICSE'
import TTownPartDM from './TTownPartDM'
import ProfessionName from './ProfessionName'
import MzdLAU1 from './MzdLAU1'
import NUTS4 from './NUTS4'
import TMatchingSymbolDM from './TMatchingSymbolDM'
import TCode2DocumentDM from './TCode2DocumentDM'
import TCode1DocumentDM from './TCode1DocumentDM'
import Group2 from './Group2'
import Group1 from './Group1'
import Kategorie2 from './Kategorie2'
import Kategorie1 from './Kategorie1'
import TPrUnionDM from './TPrUnionDM'
import TPrMinimalTariffDM from './TPrMinimalTariffDM'
import TPrInternalTariffDM from './TPrInternalTariffDM'
import EmployeeFunction from './EmployeeFunction'
import DruhCinnosti from './DruhCinnosti'
import RelWork from './RelWork'
import TypWork from './TypWork'
import DuvUkoncPP1 from './DuvUkoncPP1'
import DuvVznikuPP1 from './DuvVznikuPP1'
import TEmployedPersonSimpleDM from './TEmployedPersonSimpleDM'
import TPrPeriodDM from './TPrPeriodDM'
import TPrTariffDM from './TPrTariffDM'
import PrempReasons from './PrempReasons'
import PrEmpWorksCatalog from './PrEmpWorksCatalog'
import TMarketingActivityTypeDM from './TMarketingActivityTypeDM'
import TCommentDM from './TCommentDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TAttachedDocumentDM from './TAttachedDocumentDM'
import TOldNoteDM from './TOldNoteDM'

/**
 * Pracovní vztahy zjednodušené
 */
export default class TPrEmploymentSimpleDM extends Data {
	public static className = 'TPrEmploymentSimpleDM'
	public static primaryKey = 'Id'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.Id!
	}

	public static isHeader = true

	/**
	 * Záměr uplatňovat slevu na pojistném
	 */
	HasApplySocDiscountIntention?: string

	/**
	 * Datum typu akce
	 */
	SINActionCodeDate?: Date

	/**
	 * Přihlášen SP
	 */
	IsSocialInsuranceRegistered?: string

	/**
	 * ID pojistného vztahu
	 */
	SocialInsuranceIdentifier?: string

	/**
	 * Nekopírovat analytické osy
	 */
	NotCopyAnalyticalDimensions?: string

	/**
	 * Jméno
	 */
	FirstName?: string

	/**
	 * Příjmení
	 */
	Surname?: string

	/**
	 * Osobní číslo
	 */
	EmployeePersonNumber?: string

	/**
	 * Zkratka
	 */
	Abbr?: string

	/**
	 * Vytvořeno
	 */
	CreatedOn?: Date

	/**
	 * Zaměstnání malého rozsahu
	 */
	IsShortRangeEmployment?: string

	/**
	 * Vedoucí zaměstnanec
	 */
	IsExecutive?: string

	/**
	 * Bezprostřední nástup
	 */
	IsImmediateStart?: string

	/**
	 * Vynětí z evidenčního počtu
	 */
	IsExemption?: string

	/**
	 * V cizí měně
	 */
	IsInCurrency?: string

	/**
	 * Přesčasy ve mzdě
	 */
	IsNoPaidOverTime?: string

	/**
	 * Dodatková dovolená
	 */
	IsAdditionalLeave?: string

	/**
	 * Prodloužená dovolená
	 */
	IsExtendedLeave?: string

	/**
	 * Běžná dovolená
	 */
	IsNormalLeave?: string

	/**
	 * Automatický výpočet mzdy
	 */
	CalculateWageAutomatically?: string

	/**
	 * Kontrola min. mzdy
	 */
	CheckMinWage?: string

	/**
	 * XMLFieldScript
	 */
	XMLFieldScript?: string

	/**
	 * Neplatný záznam
	 */
	IsInvalidRecord?: string

	/**
	 * První zaměstnání v ČR
	 */
	IsFirstEmploymentInCZ?: string

	/**
	 * Datum změny sml.
	 */
	ChangeContractDate?: Date

	/**
	 * Dat. podpisu sml.
	 */
	SignatureDate?: Date

	/**
	 * Zkušební doba
	 */
	ProbationaryPeriodMonthCount?: number

	/**
	 * Pracoviště
	 */
	WorkPlaceId?: string

	/**
	 * Předpokl. ukončení
	 */
	ExpectedTerminationDate?: Date

	/**
	 * Výpov. doba od
	 */
	NoticePeriodFrom?: Date

	/**
	 * Konec zkuš. doby
	 */
	ProbationaryPeriodEndDate?: Date

	/**
	 * Odstupné
	 */
	RedundancyPaymentMultiple?: number

	/**
	 * Právo na záznam
	 */
	RightGroupId?: number

	/**
	 * Změněno
	 */
	ChangedOn?: Date

	/**
	 * Automat 4
	 */
	Automat4PrComponentId?: number

	/**
	 * Automat 3
	 */
	Automat3PrComponentId?: number

	/**
	 * Automat 2
	 */
	Automat2PrComponentId?: number

	/**
	 * Automat 1
	 */
	Automat1PrComponentId?: number

	/**
	 * Záloha na mzdu
	 */
	AdvanceWagePayment?: number

	/**
	 * Prémie
	 */
	WageBonus?: number

	/**
	 * Osobní ohodnocení II.
	 */
	WagePersonalBonus2?: number

	/**
	 * Osobní ohodnocení
	 */
	WagePersonalBonus?: number

	/**
	 * Základní mzda II.
	 */
	BasicWage2?: number

	/**
	 * Základní mzda
	 */
	BasicWage?: number

	/**
	 * Datum vzniku
	 */
	ContractDate?: Date

	/**
	 * Datum ukončení
	 */
	TerminationDate?: Date

	/**
	 * Datum nástupu
	 */
	EntryDate?: Date

	/**
	 * Firma
	 */
	CompanyId?: number

	/**
	 * Časové razítko
	 */
	TimeStamp?: string

	/**
	 * XMLField
	 */
	XMLField?: string

	/**
	 * Pořadí
	 */
	SequenceNumber?: number

	/**
	 * Pracovní vztah
	 */
	Id?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * SQLSequenceOrderCalc
	 */
	SQLSequenceOrderCalc?: number

	/**
	 * Úplný výpis
	 */
	IsItemCompleteCalc?: string

	/**
	 * Délka zkuš. doby
	 */
	ProbationaryPeriodInDaysCalc?: number

	/**
	 * Počet dnů pracovního vztahu
	 */
	EmploymentDayCountCalc?: number

	/**
	 * Hlavní pracovní vztah
	 */
	IsMainEmploymentCalc?: string

	/**
	 * Právní vztah
	 */
	LegalRelationDescriptionCalc?: string

	/**
	 * S
	 */
	IsEmploymentValidityDateCalc?: string

	/**
	 * Ztížené prostředí
	 */
	DifficultEnvironmentCalc?: string

	/**
	 * Sick days
	 */
	AllowSickDaysCalc?: string

	/**
	 * Příplatek osobní
	 */
	PersonalBonusAmountCalc?: number

	/**
	 * Příplatek zvláštní
	 */
	ExtraBonusCalc?: number

	/**
	 * Příplatek za vedení
	 */
	ManagementBonusAmountCalc?: number

	/**
	 * Výjimečné zařazení do třídy
	 */
	IsExceptionallyGradeSetCalc?: string

	/**
	 * Zvláštní způsob určení tarifu
	 */
	IsSpecialLevelSetCalc?: string

	/**
	 * Vedoucí pozice
	 */
	IsLeadingPositionCalc?: string

	/**
	 * Příplatek za ztížené prac. pr.
	 */
	DifficultEnvironmentRateCalc?: number

	/**
	 * Datum nástupu
	 */
	DateFrom?: Date

	/**
	 * Datum ukončení
	 */
	DateTo?: Date

	/**
	 * Pracovní vztah
	 */
	PrEmploymentId?: number

	/**
	 * Popis pracovního vztahu
	 */
	LegalRelationSequenceDescriptionCalc?: string

	/**
	 * Zařazení do výpočtu mezd
	 */
	ActivationDateCalc?: Date

	/**
	 * Kontaktní osoba
	 */
	ContactPersonIdCalc?: number

	/**
	 * Úvazek sjednaný týdenní
	 */
	AgreedWorkloadWeekCalc?: number

	/**
	 * Úvazek sjednaný denní
	 */
	AgreedWorkloadDayCalc?: number

	/**
	 * Úvazek stanovený týdenní
	 */
	StatedWorkloadWeekCalc?: number

	/**
	 * Úvazek stanovený denní
	 */
	StatedWorkloadDayCalc?: number

	/**
	 * Kratší prac. doba
	 */
	ShortenedWorkTimeCalc?: string

	/**
	 * Kratší pr. doba [%]
	 */
	ShortenedWorkTimePctCalc?: number

	/**
	 * Kratší pr. doba [poměr]
	 */
	ShortenedWorkTimeRatioCalc?: number

	/**
	 * undefined
	 */
	TextHeaderMemoCalc?: string

	/**
	 * undefined
	 */
	TextFooterMemoCalc?: string

	/**
	 * Poslední komentář
	 */
	LastCommentParamCalc?: string

	/**
	 * Právo na záznam
	 */
	RightGroupIdCalc?: number

	/**
	 * Důvod uplatnění slevy na pojistném
	 */
	@Type(() => TTextDM)
	SocDiscountReasonId?: TTextDM

	/**
	 * Typ akce
	 */
	@Type(() => TTextDM)
	LastSINActionCodeId?: TTextDM

	/**
	 * Další kalendář
	 */
	@Type(() => TPrCalendarDM)
	AdditionalCalendarId?: TPrCalendarDM

	/**
	 * Kniha docházky
	 */
	@Type(() => TBookDM)
	AttendanceBookId?: TBookDM

	/**
	 * Odstupné dle
	 */
	@Type(() => TTextDM)
	RedundancyPaymentParagraphId?: TTextDM

	/**
	 * Kód 6
	 */
	@Type(() => TCode6DocumentDM)
	Code6Id?: TCode6DocumentDM

	/**
	 * Kód 5
	 */
	@Type(() => TCode5DocumentDM)
	Code5Id?: TCode5DocumentDM

	/**
	 * Kód 4
	 */
	@Type(() => TCode4DocumentDM)
	Code4Id?: TCode4DocumentDM

	/**
	 * Kód 3
	 */
	@Type(() => TCode3DocumentDM)
	Code3Id?: TCode3DocumentDM

	/**
	 * Pracovní pozice
	 */
	@Type(() => TOrgStrTreeUnitDM)
	OrgStrTreeUnitRID?: TOrgStrTreeUnitDM

	/**
	 * Vytvořil
	 */
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM

	/**
	 * Kód zboží
	 */
	@Type(() => TArticleCodeDocumentDM)
	ArticleCodeId?: TArticleCodeDocumentDM

	/**
	 * Středisko 2
	 */
	@Type(() => TCostCentreCollectionDocumentDM)
	CostCentre2Id?: TCostCentreCollectionDocumentDM

	/**
	 * Středisko
	 */
	@Type(() => TCostCentreCollectionDocumentDM)
	CostCentre1Id?: TCostCentreCollectionDocumentDM

	/**
	 * Kniha mzdových dokladů
	 */
	@Type(() => TBookDM)
	BookId?: TBookDM

	/**
	 * Požadované vzdělání
	 */
	@Type(() => DegreeName)
	RequiredEducationLevelId?: DegreeName

	/**
	 * CZ-NACE
	 */
	@Type(() => CZNACE)
	CZNACEId?: CZNACE

	/**
	 * Prostředek
	 */
	@Type(() => TDeviceDocumentDM)
	DeviceRID?: TDeviceDocumentDM

	/**
	 * Pracovní místo
	 */
	@Type(() => TOrgStrJobDM)
	OrgStrJobRID?: TOrgStrJobDM

	/**
	 * Srážka výplaty
	 */
	@Type(() => TPrDeductionDM)
	WageDeductionId?: TPrDeductionDM

	/**
	 * Kód zakázky
	 */
	@Type(() => TContractCodeDocumentDM)
	ContractCodeRID?: TContractCodeDocumentDM

	/**
	 * Měna
	 */
	@Type(() => TCurrencyDM)
	Currency?: TCurrencyDM

	/**
	 * Finanční úřad
	 */
	@Type(() => TPrDeductionDM)
	TaxOfficeId?: TPrDeductionDM

	/**
	 * Časový fond
	 */
	@Type(() => TPrCalendarDM)
	WorkFundCalendarId?: TPrCalendarDM

	/**
	 * Účet zdr. poj.
	 */
	@Type(() => TAccountingChartDM)
	HealthInsuranceAccountId?: TAccountingChartDM

	/**
	 * Účet soc. zab.
	 */
	@Type(() => TAccountingChartDM)
	SocialSecurityAccountId?: TAccountingChartDM

	/**
	 * Doplň. důvod ukončení
	 */
	@Type(() => DuvUkoncPP2)
	AditionalTerminationReasonId?: DuvUkoncPP2

	/**
	 * Stát
	 */
	@Type(() => TCountryDM)
	WorkCountryId?: TCountryDM

	/**
	 * Skupina
	 */
	@Type(() => SkupPrac)
	GroupId?: SkupPrac

	/**
	 * ZUJ
	 */
	@Type(() => MzdZUJ)
	AdministrativeDivisionId?: MzdZUJ

	/**
	 * CZ-ICSE
	 */
	@Type(() => CZICSE)
	CZICSEId?: CZICSE

	/**
	 * Místo
	 */
	@Type(() => TTownPartDM)
	WorkTownPartId?: TTownPartDM

	/**
	 * Středisko výplatní
	 */
	@Type(() => TCostCentreCollectionDocumentDM)
	PayrollCentreId?: TCostCentreCollectionDocumentDM

	/**
	 * CZ-ISCO
	 */
	@Type(() => ProfessionName)
	CZISCOId?: ProfessionName

	/**
	 * LAU1
	 */
	@Type(() => MzdLAU1)
	LAU1Id?: MzdLAU1

	/**
	 * NUTS4
	 */
	@Type(() => NUTS4)
	NUTS4Id?: NUTS4

	/**
	 * Změnil
	 */
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM

	/**
	 * Účet Má dáti
	 */
	@Type(() => TAccountingChartDM)
	DebitAccountId?: TAccountingChartDM

	/**
	 * Účet Dal
	 */
	@Type(() => TAccountingChartDM)
	CreditAccountId?: TAccountingChartDM

	/**
	 * Párovací symbol
	 */
	@Type(() => TMatchingSymbolDM)
	MatchingSymbolId?: TMatchingSymbolDM

	/**
	 * Kód 2
	 */
	@Type(() => TCode2DocumentDM)
	Code2Id?: TCode2DocumentDM

	/**
	 * Kód 1
	 */
	@Type(() => TCode1DocumentDM)
	Code1Id?: TCode1DocumentDM

	/**
	 * Skupina 2
	 */
	@Type(() => Group2)
	Group2Id?: Group2

	/**
	 * Skupina 1
	 */
	@Type(() => Group1)
	Group1Id?: Group1

	/**
	 * Kategorie 2
	 */
	@Type(() => Kategorie2)
	Category2Id?: Kategorie2

	/**
	 * Kategorie 1
	 */
	@Type(() => Kategorie1)
	Category1Id?: Kategorie1

	/**
	 * Druh činnosti
	 */
	@Type(() => TTextDM)
	SocialActivityTypeId?: TTextDM

	/**
	 * Způsob danění
	 */
	@Type(() => TTextDM)
	TaxDeductionTypeId?: TTextDM

	/**
	 * Způsob srážky soc. zab.
	 */
	@Type(() => TTextDM)
	DeductionSocialSecurityId?: TTextDM

	/**
	 * Způsob srážky zdr. poj.
	 */
	@Type(() => TTextDM)
	DeductionHealthInsuranceId?: TTextDM

	/**
	 * Poplatník
	 */
	@Type(() => TTextDM)
	TaxPayerTypeId?: TTextDM

	/**
	 * Odborová organizace
	 */
	@Type(() => TPrUnionDM)
	UnionId?: TPrUnionDM

	/**
	 * Forma mzdy
	 */
	@Type(() => TTextDM)
	WageTypeId?: TTextDM

	/**
	 * Zaručená mzda
	 */
	@Type(() => TPrMinimalTariffDM)
	GuaranteedWage?: TPrMinimalTariffDM

	/**
	 * Interní tarif
	 */
	@Type(() => TPrInternalTariffDM)
	InternalTariffId?: TPrInternalTariffDM

	/**
	 * Stanovená prac. doba
	 */
	@Type(() => TPrCalendarDM)
	LegalCalendarId?: TPrCalendarDM

	/**
	 * Pracovní doba
	 */
	@Type(() => TPrCalendarDM)
	WorkShiftCalendarId?: TPrCalendarDM

	/**
	 * Zařazení
	 */
	@Type(() => EmployeeFunction)
	JobClassificationId?: EmployeeFunction

	/**
	 * Doba trvání
	 */
	@Type(() => TTextDM)
	DurationTypeId?: TTextDM

	/**
	 * Druh prac. pom.
	 */
	@Type(() => DruhCinnosti)
	EmploymentTypeId?: DruhCinnosti

	/**
	 * Právní vztah dle
	 */
	@Type(() => TTextDM)
	LegalRelationId?: TTextDM

	/**
	 * Právní vztah
	 */
	@Type(() => RelWork)
	LegalSubrelation2Id?: RelWork

	/**
	 * Prac. právní vztah
	 */
	@Type(() => TTextDM)
	LegalSubrelation1Id?: TTextDM

	/**
	 * Druh práce
	 */
	@Type(() => TypWork)
	WorkTypeId?: TypWork

	/**
	 * Důvod ukončení
	 */
	@Type(() => DuvUkoncPP1)
	TerminationReasonId?: DuvUkoncPP1

	/**
	 * Důvod vzniku
	 */
	@Type(() => DuvVznikuPP1)
	CommencementReasonId?: DuvVznikuPP1

	/**
	 * Pracovník
	 */
	@Type(() => TEmployedPersonSimpleDM)
	EmployedPersonId?: TEmployedPersonSimpleDM

	/**
	 * Z
	 */
	@Type(() => TTextDM)
	InvalidRecordImgCalc?: TTextDM

	/**
	 * Zpracovávané období
	 */
	@Type(() => TPrPeriodDM)
	ProcessPeriodIdCalc?: TPrPeriodDM

	/**
	 * Měna
	 */
	@Type(() => TCurrencyDM)
	CurrencyCalc?: TCurrencyDM

	/**
	 * Měna firmy
	 */
	@Type(() => TCurrencyDM)
	ClientCurrencyCalc?: TCurrencyDM

	/**
	 * S
	 */
	@Type(() => TTextDM)
	EmploymentValidityBitCalc?: TTextDM

	/**
	 * Kniha mzdových dokladů
	 */
	@Type(() => TBookDM)
	PrDocumentBookIdCalc?: TBookDM

	/**
	 * S
	 */
	@Type(() => TTextDM)
	EmploymentValidityOFBitCalc?: TTextDM

	/**
	 * S
	 */
	@Type(() => TTextDM)
	EmploymentValidityDateCalc?: TTextDM

	/**
	 * Nastavené období
	 */
	@Type(() => TPrPeriodDM)
	UserPeriodIdCalc?: TPrPeriodDM

	/**
	 * Prošlo vytvořením záloh
	 */
	@Type(() => TPrPeriodDM)
	PeriodToIdCalc?: TPrPeriodDM

	/**
	 * Editováno po výpočtu
	 */
	@Type(() => TPrPeriodDM)
	LastPeriodIdCalc?: TPrPeriodDM

	/**
	 * Tarifní tabulka
	 */
	@Type(() => TPrTariffDM)
	PayrollTariffRIDCalc?: TPrTariffDM

	/**
	 * Třída
	 */
	@Type(() => TTextDM)
	GradeIdCalc?: TTextDM

	/**
	 * Stupeň
	 */
	@Type(() => TTextDM)
	LevelIdCalc?: TTextDM

	/**
	 * Důvod
	 */
	@Type(() => PrempReasons)
	PersonalBonusReasonIdCalc?: PrempReasons

	/**
	 * Důvod
	 */
	@Type(() => PrempReasons)
	ExtraBonusReasonIdCalc?: PrempReasons

	/**
	 * Důvod
	 */
	@Type(() => PrempReasons)
	ManagementBonusReasonIdCalc?: PrempReasons

	/**
	 * Stupeň řízení
	 */
	@Type(() => TTextDM)
	ManagementLevelIdCalc?: TTextDM

	/**
	 * Příplatek zvláštní - skupina
	 */
	@Type(() => TTextDM)
	ExtraBonusGroupIdCalc?: TTextDM

	/**
	 * Důvod
	 */
	@Type(() => PrempReasons)
	DifficultEnvironmentRateReasonCalc?: PrempReasons

	/**
	 * Katalog prací
	 */
	@Type(() => PrEmpWorksCatalog)
	WorksCatalogIdCalc?: PrEmpWorksCatalog

	/**
	 * Zpracované období
	 */
	@Type(() => TPrPeriodDM)
	ProcessedPeriodIdCalc?: TPrPeriodDM

	/**
	 * D
	 */
	@Type(() => TTextDM)
	DocumentExistsCalc?: TTextDM

	/**
	 * undefined
	 */
	@Type(() => TMarketingActivityTypeDM)
	ActivityTypeRIDCalc?: TMarketingActivityTypeDM

	/**
	 * Komentáře
	 */
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]

	/**
	 * Text hlavičky
	 */
	@Type(() => TLanguageMemoTranslationDM)
	TextHeaderChild?: TLanguageMemoTranslationDM[]

	/**
	 * Text patičky
	 */
	@Type(() => TLanguageMemoTranslationDM)
	TextFooterChild?: TLanguageMemoTranslationDM[]

	/**
	 * Svázané doklady
	 */
	@Type(() => TAttachedDocumentDM)
	AttachedDocumentOldChild?: TAttachedDocumentDM[]

	/**
	 * Poznámky
	 */
	@Type(() => TOldNoteDM)
	DeprecatedNoteOldChild?: TOldNoteDM[]

	/**
	 * Zneplatnění
	 */
	public BulkInvalidationCOMMAND() {
		return this.executeCommand('BulkInvalidationCOMMAND')
	}

	/**
	 * Zplatnění
	 */
	public BulkUnInvalidationCOMMAND() {
		return this.executeCommand('BulkUnInvalidationCOMMAND')
	}

	/**
	 * Zneplatnění/Zplatnění
	 */
	public InvalidationCOMMAND() {
		return this.executeCommand('InvalidationCOMMAND')
	}

	/**
	 * Potvrzení
	 */
	public BulkConfirmCOMMAND() {
		return this.executeCommand('BulkConfirmCOMMAND')
	}

	/**
	 * Odpotvrzení
	 */
	public BulkUnconfirmCOMMAND() {
		return this.executeCommand('BulkUnconfirmCOMMAND')
	}

	/**
	 * Změna data potvrzení
	 */
	public BulkChangeConfirmationDateCOMMAND(parameters: TBulkChangeConfirmationDateCOMMANDParams) {
		return this.executeCommand('BulkChangeConfirmationDateCOMMAND', parameters)
	}

	/**
	 * Storno
	 */
	public BulkCancelCOMMAND() {
		return this.executeCommand('BulkCancelCOMMAND')
	}

	/**
	 * Odstorno
	 */
	public BulkUnCancelCOMMAND() {
		return this.executeCommand('BulkUnCancelCOMMAND')
	}

	/**
	 * Potvrzení
	 */
	public ConfirmCOMMAND() {
		return this.executeCommand('ConfirmCOMMAND')
	}

	/**
	 * Odpotvrzení
	 */
	public UnconfirmCOMMAND() {
		return this.executeCommand('UnconfirmCOMMAND')
	}

	/**
	 * Změna data potvrzení
	 */
	public ChangeConfirmationDateCOMMAND(parameters: TChangeConfirmationDateCOMMANDParams) {
		return this.executeCommand('ChangeConfirmationDateCOMMAND', parameters)
	}

	/**
	 * Storno/Odstorno
	 */
	public CancelCOMMAND() {
		return this.executeCommand('CancelCOMMAND')
	}

	/**
	 * Zrušení stavu
	 */
	public ClearRecordStatusCOMMAND() {
		return this.executeCommand('ClearRecordStatusCOMMAND')
	}

	/**
	 * Změna stavu
	 */
	public ChangeRecordStatusCOMMAND() {
		return this.executeCommand('ChangeRecordStatusCOMMAND')
	}

	/**
	 * 
	 */
	public ChangeRecordStatusSWSCOMMAND(parameters: TChangeRecordStatusSWSCOMMANDParams) {
		return this.executeCommand('ChangeRecordStatusSWSCOMMAND', parameters)
	}

	/**
	 * Zrušení stavu
	 */
	public BulkClearRecordStatusCOMMAND() {
		return this.executeCommand('BulkClearRecordStatusCOMMAND')
	}

	/**
	 * Změna stavu
	 */
	public BulkChangeRecordStatusCOMMAND() {
		return this.executeCommand('BulkChangeRecordStatusCOMMAND')
	}

	/**
	 * Změna atributů
	 */
	public BulkChangeAttributesCOMMAND(parameters: TBulkChangeAttributesCOMMANDParams) {
		return this.executeCommand('BulkChangeAttributesCOMMAND', parameters)
	}

	/**
	 * Aktualizace sady atributů
	 */
	public BulkActualizeAttributesCOMMAND(parameters: TBulkActualizeAttributesCOMMANDParams) {
		return this.executeCommand('BulkActualizeAttributesCOMMAND', parameters)
	}

	/**
	 * Aktualizace sady atributů
	 */
	public ActualizeAttributesCOMMAND() {
		return this.executeCommand('ActualizeAttributesCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public ChangeGroupRightCOMMAND() {
		return this.executeCommand('ChangeGroupRightCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public BulkChangeGroupRightCOMMAND() {
		return this.executeCommand('BulkChangeGroupRightCOMMAND')
	}

	/**
	 * Aktualizace počítaných polí
	 */
	public BulkUpdateCalcFieldsCOMMAND() {
		return this.executeCommand('BulkUpdateCalcFieldsCOMMAND')
	}

	/**
	 * Kontrola legislativy
	 */
	public SwitchOffLegislationValidationCOMMAND() {
		return this.executeCommand('SwitchOffLegislationValidationCOMMAND')
	}
}

export type TBulkChangeConfirmationDateCOMMANDParams = {
	/**
	 * Datum potvrzení
	 */
	Confirmation1Date?: Date
	/**
	 * Hodina potvrzení
	 */
	Confirmation1Time?: Date
	/**
	 * Datum 2. potvrzení
	 */
	Confirmation2Date?: Date
	/**
	 * Hodina 2. potvrzení
	 */
	Confirmation2Time?: Date
	/**
	 * Kontrolovat záporné stavy
	 */
	CheckNegativeState?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TChangeConfirmationDateCOMMANDParams = {
	/**
	 * Datum potvrzení
	 */
	Confirmation1Date?: Date
	/**
	 * Hodina potvrzení
	 */
	Confirmation1Time?: Date
	/**
	 * Datum 2. potvrzení
	 */
	Confirmation2Date?: Date
	/**
	 * Hodina 2. potvrzení
	 */
	Confirmation2Time?: Date
	/**
	 * Kontrolovat záporné stavy
	 */
	CheckNegativeState?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TChangeRecordStatusSWSCOMMANDParams = {
	/**
	 * StatusId
	 */
	StatusId?: number
}

export type TBulkChangeAttributesCOMMANDParams = {
	/**
	 * Smazat atributy
	 */
	DeleteAttribute?: boolean
	/**
	 * Smazat všechny volné atributy
	 */
	DeleteFreeAttribute?: boolean
	/**
	 * Nastavit hodnoty atributů
	 */
	SetValues?: boolean
	/**
	 * Přidat nepřipojené atributy
	 */
	AddFreeAttributes?: boolean
	/**
	 * FreeAttributeEnabled
	 */
	FreeAttributeEnabled?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TBulkActualizeAttributesCOMMANDParams = {
	/**
	 * Aktualizovat včetně samotné sady atributů
	 */
	ActualizeAttributeSet?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}