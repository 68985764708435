import Data from 'react-k2-api/Api/Data'
import { Type } from 'class-transformer'
import TAccountingJournalDM from './TAccountingJournalDM'
import TTextDM from './TTextDM'
import TPeriodDM from './TPeriodDM'
import TCostCentreCollectionDocumentDM from './TCostCentreCollectionDocumentDM'
import ZarUmisteni from './ZarUmisteni'
import Pracoviste from './Pracoviste'
import TArticleCodeDocumentDM from './TArticleCodeDocumentDM'
import TContractCodeDocumentDM from './TContractCodeDocumentDM'
import TOfficerDocumentDM from './TOfficerDocumentDM'
import TCode1DocumentDM from './TCode1DocumentDM'
import TCode2DocumentDM from './TCode2DocumentDM'
import TTradingPartnerDM from './TTradingPartnerDM'
import TMatchingSymbolDM from './TMatchingSymbolDM'
import TDeviceDocumentDM from './TDeviceDocumentDM'
import TCode3DocumentDM from './TCode3DocumentDM'
import TCode4DocumentDM from './TCode4DocumentDM'
import TCode5DocumentDM from './TCode5DocumentDM'
import TCode6DocumentDM from './TCode6DocumentDM'

/**
 * TD_ZarPC
 */
export default class TD_ZarPC extends Data {
	public static className = 'TD_ZarPC'
	public static systemFields = [
		'ItemRIDCalc',
	]

	public static isHeader = false

	/**
	 * Předvolba
	 */
	PreselectionId?: number

	/**
	 * Plán
	 */
	Plan?: string

	/**
	 * Popis
	 */
	Desc?: string

	/**
	 * Kod
	 */
	Kod?: string

	/**
	 * Cis
	 */
	Cis?: number

	/**
	 * Likvidoval
	 */
	Likvid?: number

	/**
	 * Dat.likv.
	 */
	DLikvid?: Date

	/**
	 * Pohyb_P
	 */
	Pohyb_P?: number

	/**
	 * Částka
	 */
	CENA?: number

	/**
	 * Datum
	 */
	ROK?: Date

	/**
	 * Číslo
	 */
	CISLO?: number

	/**
	 * Druh
	 */
	TM?: string

	/**
	 * undefined
	 */
	RecordStateImgCalc?: string

	/**
	 * ItemRIDCalc
	 */
	ItemRIDCalc?: number

	/**
	 * RecordModeCalc
	 */
	RecordModeCalc?: number

	/**
	 * Číslo
	 */
	CFUcDok?: number

	/**
	 * Z
	 */
	CFZa?: string

	/**
	 * O
	 */
	CFOd?: string

	/**
	 * P
	 */
	CFPo?: string

	/**
	 * Kód
	 */
	CFKod?: string

	/**
	 * Účetní doklad
	 */
	@Type(() => TAccountingJournalDM)
	AccDocRID?: TAccountingJournalDM

	/**
	 * Typ_Pol
	 */
	@Type(() => TTextDM)
	Typ_Pol?: TTextDM

	/**
	 * Období
	 */
	@Type(() => TPeriodDM)
	CFUPref?: TPeriodDM

	/**
	 * CostCentreCalc
	 */
	@Type(() => TCostCentreCollectionDocumentDM)
	CostCentreCalc?: TCostCentreCollectionDocumentDM

	/**
	 * CFKod_Umi
	 */
	@Type(() => ZarUmisteni)
	CFKod_Umi?: ZarUmisteni

	/**
	 * CFKod_Pra
	 */
	@Type(() => Pracoviste)
	CFKod_Pra?: Pracoviste

	/**
	 * ArticleCodeCalc
	 */
	@Type(() => TArticleCodeDocumentDM)
	ArticleCodeCalc?: TArticleCodeDocumentDM

	/**
	 * ContractCodeCalc
	 */
	@Type(() => TContractCodeDocumentDM)
	ContractCodeCalc?: TContractCodeDocumentDM

	/**
	 * OfficerCalc
	 */
	@Type(() => TOfficerDocumentDM)
	OfficerCalc?: TOfficerDocumentDM

	/**
	 * Code1Calc
	 */
	@Type(() => TCode1DocumentDM)
	Code1Calc?: TCode1DocumentDM

	/**
	 * Code2Calc
	 */
	@Type(() => TCode2DocumentDM)
	Code2Calc?: TCode2DocumentDM

	/**
	 * CFKod_Cdo
	 */
	@Type(() => TTradingPartnerDM)
	CFKod_Cdo?: TTradingPartnerDM

	/**
	 * CFKod_PS
	 */
	@Type(() => TMatchingSymbolDM)
	CFKod_PS?: TMatchingSymbolDM

	/**
	 * DeviceCalc
	 */
	@Type(() => TDeviceDocumentDM)
	DeviceCalc?: TDeviceDocumentDM

	/**
	 * Code3Calc
	 */
	@Type(() => TCode3DocumentDM)
	Code3Calc?: TCode3DocumentDM

	/**
	 * Code4Calc
	 */
	@Type(() => TCode4DocumentDM)
	Code4Calc?: TCode4DocumentDM

	/**
	 * Code5Calc
	 */
	@Type(() => TCode5DocumentDM)
	Code5Calc?: TCode5DocumentDM

	/**
	 * Code6Calc
	 */
	@Type(() => TCode6DocumentDM)
	Code6Calc?: TCode6DocumentDM
}