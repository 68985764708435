import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TJobCardConfigDM from './TJobCardConfigDM'
import TAccountingChartDM from './TAccountingChartDM'
import TTradingPartnerDM from './TTradingPartnerDM'
import TK2UserLookupDM from './TK2UserLookupDM'
import TCompanyDM from './TCompanyDM'

/**
 * Firmy
 */
export default class TCompanyLinkArticleDM extends Data {
	public static className = 'TCompanyLinkArticleDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = false

	/**
	 * Kurz
	 */
	ExchangeRate?: number

	/**
	 * Měna
	 */
	Currency?: string

	/**
	 * Marže
	 */
	MarginId?: number

	/**
	 * Prodejní cena netto
	 */
	SellingPrice?: number

	/**
	 * Cena pořízení
	 */
	PurchasePrice?: number

	/**
	 * Náklady pořízení
	 */
	PurchaseCosts?: number

	/**
	 * Skl. cena
	 */
	PlannedStockPrice?: number

	/**
	 * Varianta
	 */
	RoutingVariantId?: number

	/**
	 * Valid
	 */
	IsValid?: string

	/**
	 * Změněno
	 */
	ChangedOn?: Date

	/**
	 * RecID
	 */
	RecordNumber?: number

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * undefined
	 */
	IconCalc?: number

	/**
	 * Konfigurace vytváření průvodek
	 */
	@Type(() => TJobCardConfigDM)
	JobCardConfigId?: TJobCardConfigDM

	/**
	 * Účet - pro. pro podnik kon. c.
	 */
	@Type(() => TAccountingChartDM)
	ConsolidationSalesAccountId?: TAccountingChartDM

	/**
	 * Účet - nák. pro podnik kon. c.
	 */
	@Type(() => TAccountingChartDM)
	ConsolidationPurchaseAccountId?: TAccountingChartDM

	/**
	 * Účet - prodej
	 */
	@Type(() => TAccountingChartDM)
	SalesAccountId?: TAccountingChartDM

	/**
	 * Účet - nákup
	 */
	@Type(() => TAccountingChartDM)
	PurchaseAccountId?: TAccountingChartDM

	/**
	 * Opt. Dodavatel
	 */
	@Type(() => TTradingPartnerDM)
	OptimalSupplierId?: TTradingPartnerDM

	/**
	 * Změnil
	 */
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM

	/**
	 * CompanyId
	 */
	@Type(() => TCompanyDM)
	CompanyId?: TCompanyDM
}