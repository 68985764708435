import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TTextDM from './TTextDM'
import PrEmpWorksCatalog from './PrEmpWorksCatalog'
import TPrTariffDM from './TPrTariffDM'
import TK2UserLookupDM from './TK2UserLookupDM'
import MzdLAU1 from './MzdLAU1'
import Group2 from './Group2'
import Group1 from './Group1'
import SkupPrac from './SkupPrac'
import Kategorie2 from './Kategorie2'
import Kategorie1 from './Kategorie1'
import DegreeName from './DegreeName'
import TPrMinimalTariffDM from './TPrMinimalTariffDM'
import TPrInternalTariffDM from './TPrInternalTariffDM'
import ProfessionName from './ProfessionName'
import CatWork from './CatWork'
import TypWork from './TypWork'
import TMarketingActivityTypeDM from './TMarketingActivityTypeDM'
import TCommentDM from './TCommentDM'
import TOrgStrTreeUnitDM from './TOrgStrTreeUnitDM'
import TPrEmploymentDM from './TPrEmploymentDM'
import TExternalDocumentItemDM from './TExternalDocumentItemDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TCompanyLinkDM from './TCompanyLinkDM'
import TAttachedDocumentDM from './TAttachedDocumentDM'
import TOldNoteDM from './TOldNoteDM'

/**
 * Pracovní místo
 */
export default class TOrgStrJobDM extends Data {
	public static className = 'TOrgStrJobDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = true

	/**
	 * Časové razítko
	 */
	TimeStamp?: string

	/**
	 * XmlField
	 */
	XmlField?: string

	/**
	 * Vytvořeno
	 */
	CreatedOn?: Date

	/**
	 * Změněno
	 */
	ChangedOn?: Date

	/**
	 * Platnost do
	 */
	ValidTo?: Date

	/**
	 * Platnost od
	 */
	ValidFrom?: Date

	/**
	 * Právo na záznam
	 */
	RightGroupId?: number

	/**
	 * Firma
	 */
	CompanyIdOld?: number

	/**
	 * Popis
	 */
	Description?: string

	/**
	 * Název
	 */
	Name?: string

	/**
	 * Zkratka
	 */
	Abbr?: string

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Platnost změn k
	 */
	FieldHistoryDateCalc?: Date

	/**
	 * Datum pro "_ver" pole
	 */
	VersionedFieldDateCalc?: Date

	/**
	 * Datum pro "_ver" pole
	 */
	VersionFieldDateCalc?: Date

	/**
	 * undefined
	 */
	PeriodIdCalc?: number

	/**
	 * undefined
	 */
	TextHeaderMemoCalc?: string

	/**
	 * undefined
	 */
	TextFooterMemoCalc?: string

	/**
	 * Poslední komentář
	 */
	LastCommentParamCalc?: string

	/**
	 * Právo na záznam
	 */
	RightGroupIdCalc?: number

	/**
	 * Stupeň řízení
	 */
	@Type(() => TTextDM)
	ManagementLevelId?: TTextDM

	/**
	 * Třída
	 */
	@Type(() => TTextDM)
	GradeId?: TTextDM

	/**
	 * Katalog prací
	 */
	@Type(() => PrEmpWorksCatalog)
	WorkCatalogId?: PrEmpWorksCatalog

	/**
	 * Tarifní tabulka
	 */
	@Type(() => TPrTariffDM)
	PayrollTariffRID?: TPrTariffDM

	/**
	 * Vytvořil
	 */
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM

	/**
	 * Změnil
	 */
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM

	/**
	 * LAU1
	 */
	@Type(() => MzdLAU1)
	LAU1Id?: MzdLAU1

	/**
	 * Skupina 2
	 */
	@Type(() => Group2)
	Group2Id?: Group2

	/**
	 * Skupina 1
	 */
	@Type(() => Group1)
	Group1Id?: Group1

	/**
	 * Skupina
	 */
	@Type(() => SkupPrac)
	WorkerGroupId?: SkupPrac

	/**
	 * Kategorie 2
	 */
	@Type(() => Kategorie2)
	Category2Id?: Kategorie2

	/**
	 * Kategorie 1
	 */
	@Type(() => Kategorie1)
	Category1Id?: Kategorie1

	/**
	 * Minimální vzdělání
	 */
	@Type(() => DegreeName)
	EducationLevelId?: DegreeName

	/**
	 * Rozvr. prac. doby
	 */
	@Type(() => TTextDM)
	WorkTimeScheduleId?: TTextDM

	/**
	 * Pracovní režim
	 */
	@Type(() => TTextDM)
	WorkingModeId?: TTextDM

	/**
	 * Zaručená mzda
	 */
	@Type(() => TPrMinimalTariffDM)
	MinTariffId?: TPrMinimalTariffDM

	/**
	 * Interní tarif
	 */
	@Type(() => TPrInternalTariffDM)
	InternalTariffId?: TPrInternalTariffDM

	/**
	 * CZ-ISCO
	 */
	@Type(() => ProfessionName)
	CZISCOId?: ProfessionName

	/**
	 * Prac. právní vztah
	 */
	@Type(() => TTextDM)
	LegalSubrelation1Id?: TTextDM

	/**
	 * Kategorie práce
	 */
	@Type(() => CatWork)
	WorkCategoryId?: CatWork

	/**
	 * Druh práce
	 */
	@Type(() => TypWork)
	WorkTypeId?: TypWork

	/**
	 * Z
	 */
	@Type(() => TTextDM)
	InvalidRecordImgCalc?: TTextDM

	/**
	 * D
	 */
	@Type(() => TTextDM)
	DocumentExistsCalc?: TTextDM

	/**
	 * undefined
	 */
	@Type(() => TMarketingActivityTypeDM)
	ActivityTypeRIDCalc?: TMarketingActivityTypeDM

	/**
	 * Rozvr. prac. doby_ver
	 */
	@Type(() => TTextDM)
	WorkTimeScheduleId_ver?: TTextDM

	/**
	 * Zaručená mzda_ver
	 */
	@Type(() => TPrMinimalTariffDM)
	MinTariffId_ver?: TPrMinimalTariffDM

	/**
	 * LAU1_ver
	 */
	@Type(() => MzdLAU1)
	LAU1Id_ver?: MzdLAU1

	/**
	 * Katalog prací_ver
	 */
	@Type(() => PrEmpWorksCatalog)
	WorkCatalogId_ver?: PrEmpWorksCatalog

	/**
	 * Druh práce_ver
	 */
	@Type(() => TypWork)
	WorkTypeId_ver?: TypWork

	/**
	 * Stupeň řízení_ver
	 */
	@Type(() => TTextDM)
	ManagementLevelId_ver?: TTextDM

	/**
	 * Kategorie 2_ver
	 */
	@Type(() => Kategorie2)
	Category2Id_ver?: Kategorie2

	/**
	 * Minimální vzdělání_ver
	 */
	@Type(() => DegreeName)
	EducationLevelId_ver?: DegreeName

	/**
	 * Tarifní tabulka_ver
	 */
	@Type(() => TPrTariffDM)
	PayrollTariffRID_ver?: TPrTariffDM

	/**
	 * Skupina 1_ver
	 */
	@Type(() => Group1)
	Group1Id_ver?: Group1

	/**
	 * Kategorie 1_ver
	 */
	@Type(() => Kategorie1)
	Category1Id_ver?: Kategorie1

	/**
	 * Interní tarif_ver
	 */
	@Type(() => TPrInternalTariffDM)
	InternalTariffId_ver?: TPrInternalTariffDM

	/**
	 * Třída_ver
	 */
	@Type(() => TTextDM)
	GradeId_ver?: TTextDM

	/**
	 * Skupina 2_ver
	 */
	@Type(() => Group2)
	Group2Id_ver?: Group2

	/**
	 * Skupina_ver
	 */
	@Type(() => SkupPrac)
	WorkerGroupId_ver?: SkupPrac

	/**
	 * CZ-ISCO_ver
	 */
	@Type(() => ProfessionName)
	CZISCOId_ver?: ProfessionName

	/**
	 * Kategorie práce_ver
	 */
	@Type(() => CatWork)
	WorkCategoryId_ver?: CatWork

	/**
	 * Pracovní režim_ver
	 */
	@Type(() => TTextDM)
	WorkingModeId_ver?: TTextDM

	/**
	 * Prac. právní vztah_ver
	 */
	@Type(() => TTextDM)
	LegalSubrelation1Id_ver?: TTextDM

	/**
	 * Komentáře
	 */
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]

	/**
	 * Organizační jednotky
	 */
	@Type(() => TOrgStrTreeUnitDM)
	OrgStrUnitChild?: TOrgStrTreeUnitDM[]

	/**
	 * undefined
	 */
	@Type(() => TPrEmploymentDM)
	PrEmploymentChild?: TPrEmploymentDM[]

	/**
	 * Dokumenty
	 */
	@Type(() => TExternalDocumentItemDM)
	ExternalDocumentItemChild?: TExternalDocumentItemDM[]

	/**
	 * Text hlavičky
	 */
	@Type(() => TLanguageMemoTranslationDM)
	TextHeaderChild?: TLanguageMemoTranslationDM[]

	/**
	 * Text patičky
	 */
	@Type(() => TLanguageMemoTranslationDM)
	TextFooterChild?: TLanguageMemoTranslationDM[]

	/**
	 * undefined
	 */
	@Type(() => TCompanyLinkDM)
	CompanyChild?: TCompanyLinkDM[]

	/**
	 * Svázané doklady
	 */
	@Type(() => TAttachedDocumentDM)
	AttachedDocumentOldChild?: TAttachedDocumentDM[]

	/**
	 * Poznámky
	 */
	@Type(() => TOldNoteDM)
	DeprecatedNoteOldChild?: TOldNoteDM[]

	/**
	 * Zneplatnění
	 */
	public BulkInvalidationCOMMAND() {
		return this.executeCommand('BulkInvalidationCOMMAND')
	}

	/**
	 * Zplatnění
	 */
	public BulkUnInvalidationCOMMAND() {
		return this.executeCommand('BulkUnInvalidationCOMMAND')
	}

	/**
	 * Zneplatnění/Zplatnění
	 */
	public InvalidationCOMMAND() {
		return this.executeCommand('InvalidationCOMMAND')
	}

	/**
	 * Potvrzení
	 */
	public BulkConfirmCOMMAND() {
		return this.executeCommand('BulkConfirmCOMMAND')
	}

	/**
	 * Odpotvrzení
	 */
	public BulkUnconfirmCOMMAND() {
		return this.executeCommand('BulkUnconfirmCOMMAND')
	}

	/**
	 * Změna data potvrzení
	 */
	public BulkChangeConfirmationDateCOMMAND(parameters: TBulkChangeConfirmationDateCOMMANDParams) {
		return this.executeCommand('BulkChangeConfirmationDateCOMMAND', parameters)
	}

	/**
	 * Storno
	 */
	public BulkCancelCOMMAND() {
		return this.executeCommand('BulkCancelCOMMAND')
	}

	/**
	 * Odstorno
	 */
	public BulkUnCancelCOMMAND() {
		return this.executeCommand('BulkUnCancelCOMMAND')
	}

	/**
	 * Potvrzení
	 */
	public ConfirmCOMMAND() {
		return this.executeCommand('ConfirmCOMMAND')
	}

	/**
	 * Odpotvrzení
	 */
	public UnconfirmCOMMAND() {
		return this.executeCommand('UnconfirmCOMMAND')
	}

	/**
	 * Změna data potvrzení
	 */
	public ChangeConfirmationDateCOMMAND(parameters: TChangeConfirmationDateCOMMANDParams) {
		return this.executeCommand('ChangeConfirmationDateCOMMAND', parameters)
	}

	/**
	 * Storno/Odstorno
	 */
	public CancelCOMMAND() {
		return this.executeCommand('CancelCOMMAND')
	}

	/**
	 * Zrušení stavu
	 */
	public ClearRecordStatusCOMMAND() {
		return this.executeCommand('ClearRecordStatusCOMMAND')
	}

	/**
	 * Změna stavu
	 */
	public ChangeRecordStatusCOMMAND() {
		return this.executeCommand('ChangeRecordStatusCOMMAND')
	}

	/**
	 * 
	 */
	public ChangeRecordStatusSWSCOMMAND(parameters: TChangeRecordStatusSWSCOMMANDParams) {
		return this.executeCommand('ChangeRecordStatusSWSCOMMAND', parameters)
	}

	/**
	 * Zrušení stavu
	 */
	public BulkClearRecordStatusCOMMAND() {
		return this.executeCommand('BulkClearRecordStatusCOMMAND')
	}

	/**
	 * Změna stavu
	 */
	public BulkChangeRecordStatusCOMMAND() {
		return this.executeCommand('BulkChangeRecordStatusCOMMAND')
	}

	/**
	 * Změna atributů
	 */
	public BulkChangeAttributesCOMMAND(parameters: TBulkChangeAttributesCOMMANDParams) {
		return this.executeCommand('BulkChangeAttributesCOMMAND', parameters)
	}

	/**
	 * Aktualizace sady atributů
	 */
	public BulkActualizeAttributesCOMMAND(parameters: TBulkActualizeAttributesCOMMANDParams) {
		return this.executeCommand('BulkActualizeAttributesCOMMAND', parameters)
	}

	/**
	 * Aktualizace sady atributů
	 */
	public ActualizeAttributesCOMMAND() {
		return this.executeCommand('ActualizeAttributesCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public ChangeGroupRightCOMMAND() {
		return this.executeCommand('ChangeGroupRightCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public BulkChangeGroupRightCOMMAND() {
		return this.executeCommand('BulkChangeGroupRightCOMMAND')
	}

	/**
	 * Aktualizace počítaných polí
	 */
	public BulkUpdateCalcFieldsCOMMAND() {
		return this.executeCommand('BulkUpdateCalcFieldsCOMMAND')
	}

	/**
	 * Kontrola legislativy
	 */
	public SwitchOffLegislationValidationCOMMAND() {
		return this.executeCommand('SwitchOffLegislationValidationCOMMAND')
	}
}

export type TBulkChangeConfirmationDateCOMMANDParams = {
	/**
	 * Datum potvrzení
	 */
	Confirmation1Date?: Date
	/**
	 * Hodina potvrzení
	 */
	Confirmation1Time?: Date
	/**
	 * Datum 2. potvrzení
	 */
	Confirmation2Date?: Date
	/**
	 * Hodina 2. potvrzení
	 */
	Confirmation2Time?: Date
	/**
	 * Kontrolovat záporné stavy
	 */
	CheckNegativeState?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TChangeConfirmationDateCOMMANDParams = {
	/**
	 * Datum potvrzení
	 */
	Confirmation1Date?: Date
	/**
	 * Hodina potvrzení
	 */
	Confirmation1Time?: Date
	/**
	 * Datum 2. potvrzení
	 */
	Confirmation2Date?: Date
	/**
	 * Hodina 2. potvrzení
	 */
	Confirmation2Time?: Date
	/**
	 * Kontrolovat záporné stavy
	 */
	CheckNegativeState?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TChangeRecordStatusSWSCOMMANDParams = {
	/**
	 * StatusId
	 */
	StatusId?: number
}

export type TBulkChangeAttributesCOMMANDParams = {
	/**
	 * Smazat atributy
	 */
	DeleteAttribute?: boolean
	/**
	 * Smazat všechny volné atributy
	 */
	DeleteFreeAttribute?: boolean
	/**
	 * Nastavit hodnoty atributů
	 */
	SetValues?: boolean
	/**
	 * Přidat nepřipojené atributy
	 */
	AddFreeAttributes?: boolean
	/**
	 * FreeAttributeEnabled
	 */
	FreeAttributeEnabled?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TBulkActualizeAttributesCOMMANDParams = {
	/**
	 * Aktualizovat včetně samotné sady atributů
	 */
	ActualizeAttributeSet?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}