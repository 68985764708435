import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import FormOfOrder from './FormOfOrder'
import JCDP20_a1 from './JCDP20_a1'
import TShippingMethodDM from './TShippingMethodDM'
import TTransportMethodDM from './TTransportMethodDM'
import TCompanyDividedBookDM from './TCompanyDividedBookDM'
import TK2UserLookupDM from './TK2UserLookupDM'
import TCompanyDM from './TCompanyDM'

/**
 * Firmy
 */
export default class TCompanyLinkTradingPartnerDM extends Data {
	public static className = 'TCompanyLinkTradingPartnerDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = false

	/**
	 * Zp. platby
	 */
	PurchasePaymentMethodId?: string

	/**
	 * Zp. platby
	 */
	SalePaymentMethodId?: string

	/**
	 * Měna
	 */
	Currency?: string

	/**
	 * Valid
	 */
	IsValid?: string

	/**
	 * Změněno
	 */
	ChangedOn?: Date

	/**
	 * RecID
	 */
	RecordNumber?: number

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * undefined
	 */
	IconCalc?: number

	/**
	 * undefined
	 */
	TransportMethodRIDCalc?: number

	/**
	 * undefined
	 */
	TransportMethodPurchaseRIDCalc?: number

	/**
	 * Forma objednávky
	 */
	@Type(() => FormOfOrder)
	PurchaseOrderFormId?: FormOfOrder

	/**
	 * Dodací podmínky
	 */
	@Type(() => JCDP20_a1)
	PurchaseDeliveryTermsId?: JCDP20_a1

	/**
	 * Zp. odběru
	 */
	@Type(() => TShippingMethodDM)
	PurchaseShippingMethodId?: TShippingMethodDM

	/**
	 * Zp. dopravy
	 */
	@Type(() => TTransportMethodDM)
	PurchaseTransportMethodRID?: TTransportMethodDM

	/**
	 * Forma objednávky
	 */
	@Type(() => FormOfOrder)
	SaleOrderFormId?: FormOfOrder

	/**
	 * Dodací podmínky
	 */
	@Type(() => JCDP20_a1)
	SaleDeliveryTermsId?: JCDP20_a1

	/**
	 * Zp. odběru
	 */
	@Type(() => TShippingMethodDM)
	SaleShippingMethodId?: TShippingMethodDM

	/**
	 * Zp. dopravy
	 */
	@Type(() => TTransportMethodDM)
	SaleTransportMethodRID?: TTransportMethodDM

	/**
	 * Bank. účet
	 */
	@Type(() => TCompanyDividedBookDM)
	BankBookId?: TCompanyDividedBookDM

	/**
	 * Změnil
	 */
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM

	/**
	 * CompanyId
	 */
	@Type(() => TCompanyDM)
	CompanyId?: TCompanyDM
}