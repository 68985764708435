import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TUserRoleRightDM from './TUserRoleRightDM'
import TK2UserLookupDM from './TK2UserLookupDM'
import TRightUserRoleDM from './TRightUserRoleDM'

/**
 * Definice práv
 */
export default class TUserRoleRightAsItemDM extends Data {
	public static className = 'TUserRoleRightAsItemDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = false

	/**
	 * Přiděleno
	 */
	ToPermit?: string

	/**
	 * HeaderId
	 */
	HeaderId?: number

	/**
	 * Časové razítko
	 */
	TimeStamp?: string

	/**
	 * Změněno
	 */
	ChangedOn?: Date

	/**
	 * Vytvořeno
	 */
	CreatedOn?: Date

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Číslo
	 */
	RightNumberCalc?: number

	/**
	 * Název
	 */
	CaptionCalc?: string

	/**
	 * Modul
	 */
	ModuleNameCalc?: string

	/**
	 * Funkce
	 */
	FunctionalityCalc?: string

	/**
	 * Redundantní
	 */
	IsSameInRoleCalc?: string

	/**
	 * Přebráno
	 */
	InRoleCalc?: number

	/**
	 * UserRightID
	 */
	@Type(() => TUserRoleRightDM)
	UserRightId?: TUserRoleRightDM

	/**
	 * Změnil
	 */
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM

	/**
	 * Vytvořil
	 */
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM

	/**
	 * undefined
	 */
	@Type(() => TRightUserRoleDM)
	UserRoleChild?: TRightUserRoleDM[]
}