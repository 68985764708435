import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TAllowedTransportMethodDM from './TAllowedTransportMethodDM'
import TAllowedPaymentMethodDM from './TAllowedPaymentMethodDM'
import TCode6DocumentDM from './TCode6DocumentDM'
import TCode5DocumentDM from './TCode5DocumentDM'
import TCode4DocumentDM from './TCode4DocumentDM'
import TCode3DocumentDM from './TCode3DocumentDM'
import TBookDM from './TBookDM'
import TTextDM from './TTextDM'
import TSalesActionDM from './TSalesActionDM'
import TPriceListDM from './TPriceListDM'
import TCostCentreCollectionDocumentDM from './TCostCentreCollectionDocumentDM'
import TDeviceDocumentDM from './TDeviceDocumentDM'
import TAccountingChartDM from './TAccountingChartDM'
import ABCClass from './ABCClass'
import TContractCodeDocumentDM from './TContractCodeDocumentDM'
import TPartnerDM from './TPartnerDM'
import TAddressDM from './TAddressDM'
import TK2UserLookupDM from './TK2UserLookupDM'
import TOfficerDocumentDM from './TOfficerDocumentDM'
import TCode2DocumentDM from './TCode2DocumentDM'
import TCode1DocumentDM from './TCode1DocumentDM'
import Domain from './Domain'
import AreaCode from './AreaCode'
import TPriceGroupDM from './TPriceGroupDM'
import Code from './Code'
import CustomerGroup from './CustomerGroup'
import TCodeTypeDM from './TCodeTypeDM'
import TSpecialWebApplicationRight from './TSpecialWebApplicationRight'
import TCountryDM from './TCountryDM'
import TSpecialStringListDM from './TSpecialStringListDM'
import TTransportMethodDM from './TTransportMethodDM'
import TCurrencyDM from './TCurrencyDM'
import TBanksDM from './TBanksDM'
import TElectronicAddressTypeDM from './TElectronicAddressTypeDM'
import PartnerStatus from './PartnerStatus'
import TMarketingActivityTypeDM from './TMarketingActivityTypeDM'
import TWorkflowProcessDM from './TWorkflowProcessDM'
import TActivityDM from './TActivityDM'
import TTownPartDM from './TTownPartDM'
import TTownDM from './TTownDM'
import TCompanyDividedBookDM from './TCompanyDividedBookDM'
import TPaymentMethodDM from './TPaymentMethodDM'
import TShippingMethodDM from './TShippingMethodDM'
import JCDP20_a1 from './JCDP20_a1'
import FormOfOrder from './FormOfOrder'
import TTaxTypeDM from './TTaxTypeDM'
import TLinkDM from './TLinkDM'
import TCommentDM from './TCommentDM'
import TJobDM from './TJobDM'
import TServiceNoteDM from './TServiceNoteDM'
import TSalesOrderDM from './TSalesOrderDM'
import TPurchaseOrderDM from './TPurchaseOrderDM'
import TOpenItemListDM from './TOpenItemListDM'
import TReminderDM from './TReminderDM'
import TOpportunityDM from './TOpportunityDM'
import TQuotationDM from './TQuotationDM'
import TRequestForQuotationDM from './TRequestForQuotationDM'
import TShipmentDM from './TShipmentDM'
import TParcelDM from './TParcelDM'
import TPostEnvelopeSentDM from './TPostEnvelopeSentDM'
import TPostEnvelopeReceivedDM from './TPostEnvelopeReceivedDM'
import TPurchaseItemBookDM from './TPurchaseItemBookDM'
import TSalesItemBookDM from './TSalesItemBookDM'
import TAdvanceReceivedDM from './TAdvanceReceivedDM'
import TAdvanceProvidedDM from './TAdvanceProvidedDM'
import TDeliveryConfirmationDM from './TDeliveryConfirmationDM'
import TDeliveryNoteDM from './TDeliveryNoteDM'
import TInvoiceInDM from './TInvoiceInDM'
import TInvoiceOutDM from './TInvoiceOutDM'
import TOrderConfirmationDM from './TOrderConfirmationDM'
import TReceiptCardDM from './TReceiptCardDM'
import TReservingCardDM from './TReservingCardDM'
import TReleaseNoteDM from './TReleaseNoteDM'
import TABCItemDM from './TABCItemDM'
import TPaymentConditionTradingPartnerPurchaseDM from './TPaymentConditionTradingPartnerPurchaseDM'
import TPaymentConditionTradingPartnerSaleDM from './TPaymentConditionTradingPartnerSaleDM'
import TRelatedAddressDM from './TRelatedAddressDM'
import TAdressKeyItemDM from './TAdressKeyItemDM'
import TElectronicAddressDM from './TElectronicAddressDM'
import TBankAccountTradingPartnerDM from './TBankAccountTradingPartnerDM'
import TPartnerContactPersonDM from './TPartnerContactPersonDM'
import TBranchDM from './TBranchDM'
import TTradingPartnerPriceGroupDM from './TTradingPartnerPriceGroupDM'
import TCustomerCreditDM from './TCustomerCreditDM'
import TOpenItemListTrendDM from './TOpenItemListTrendDM'
import TServicedDeviceDM from './TServicedDeviceDM'
import TABCItemChartSourceDM from './TABCItemChartSourceDM'
import TSupplierPriceDM from './TSupplierPriceDM'
import TProductCostingDM from './TProductCostingDM'
import TTradingPartnerTransportDM from './TTradingPartnerTransportDM'
import TVATRegNumberDM from './TVATRegNumberDM'
import TExternalDocumentItemDM from './TExternalDocumentItemDM'
import TWorkflowProcessAsItemDM from './TWorkflowProcessAsItemDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TCompanyLinkTradingPartnerDM from './TCompanyLinkTradingPartnerDM'
import TLegislationLinkTradingPartnerDM from './TLegislationLinkTradingPartnerDM'
import TSpecialTTradingPartnerDMSalesOrderContractChild from './TSpecialTTradingPartnerDMSalesOrderContractChild'
import TCustomTTradingPartnerDMDod_Odb_P_Ind_C from './TCustomTTradingPartnerDMDod_Odb_P_Ind_C'
import TAttachedDocumentDM from './TAttachedDocumentDM'
import TOldNoteDM from './TOldNoteDM'

/**
 * Dodavatelé - odběratelé
 */
export default class TTradingPartnerDM extends Data {
	public static className = 'TTradingPartnerDM'
	public static primaryKey = 'Id'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.Id!
	}

	public static isHeader = true

	/**
	 * Nezahrnovat do řízení financí
	 */
	NotIncludeIntoFinancialMngmt?: string

	/**
	 * DIČ z adresáta dokladu
	 */
	IsRecipientVATRegNumber?: string

	/**
	 * Poznámka k dopravě
	 */
	TransportDescription?: string

	/**
	 * EDI povoleno
	 */
	IsEnabledEdi?: string

	/**
	 * GLN identifikátor
	 */
	EdiGln?: string

	/**
	 * Neevidovat
	 */
	IsFictitious?: string

	/**
	 * Vytvářet upomínky
	 */
	CreatePurchaseReminders?: string

	/**
	 * Vytvářet upomínky
	 */
	CreateSaleReminders?: string

	/**
	 * Prodlení (dny)
	 */
	AvgDelayPurchase?: number

	/**
	 * Datová schránka
	 */
	DataBoxId?: string

	/**
	 * PAP Partner
	 */
	PAPPartnerId?: string

	/**
	 * K
	 */
	CreditExists?: string

	/**
	 * Počet dnů od začátku měsíce
	 */
	MaturityDayCount?: number

	/**
	 * Posun měsíců
	 */
	PaymentShiftMonthCount?: number

	/**
	 * SP - datum nespolehlivosti od
	 */
	RatingCrpFromDate?: Date

	/**
	 * SP - datum hodnocení
	 */
	RatingCrpDate?: Date

	/**
	 * SP
	 */
	RatingCrpValue?: number

	/**
	 * Časové razítko
	 */
	TimeStamp?: string

	/**
	 * Vytvořeno
	 */
	CreatedOn?: Date

	/**
	 * Daňové číslo
	 */
	TaxNumber?: string

	/**
	 * Registr
	 */
	BusinessRegister?: string

	/**
	 * Sleva (%)
	 */
	DiscountPercentage?: number

	/**
	 * Prodlení (dny)
	 */
	AvgDelaySale?: number

	/**
	 * Rez3
	 */
	Reserved3?: string

	/**
	 * Rez2
	 */
	Reserved2?: string

	/**
	 * Rez1
	 */
	Reserved1?: string

	/**
	 * Právo na záznam
	 */
	RightGroupId?: number

	/**
	 * Stav faktur vydaných
	 */
	CurrentStateInvOut?: number

	/**
	 * Stav faktur přijatých
	 */
	CurrentStateInvIn?: number

	/**
	 * Zkratka 2
	 */
	Abbr2?: string

	/**
	 * Změněno
	 */
	ChangedOn?: Date

	/**
	 * Maximální půjčka
	 */
	MaxLoan?: number

	/**
	 * Maximální dluh
	 */
	MaxDebt?: number

	/**
	 * Úrok
	 */
	Penalty?: number

	/**
	 * DIČ
	 */
	VATRegNumber?: string

	/**
	 * IČ
	 */
	CompanyRegNumber?: string

	/**
	 * Firma
	 */
	Name?: string

	/**
	 * Zkratka 1
	 */
	Abbr?: string

	/**
	 * Číslo
	 */
	Id?: number

	/**
	 * ID na E-SHOPU
	 */
	EX_ES_EcpId?: number

	/**
	 * Jméno na E-SHOPU
	 */
	EX_ES_Jmeno?: string

	/**
	 * Příjmení na E-SHOPU
	 */
	EX_ES_Prijmeni?: string

	/**
	 * Titul na E-SHOPU
	 */
	EX_ES_Titul?: string

	/**
	 * Shop IDu na E-SHOPU
	 */
	EX_ES_EcpId_S?: string

	/**
	 * Neaktivní na E-SHOPU
	 */
	EX_ES_Neaktivni?: string

	/**
	 * Pošli cenik E-SHOPU
	 */
	EX_ES_Posli_Cenik?: string

	/**
	 * DL povolen prodejna
	 */
	EX_prodejna_dl_lze?: string

	/**
	 * VIP prodejna
	 */
	EX_prodejna_VIP?: string

	/**
	 * EX_P_VUB2B
	 */
	EX_P_VUB2B?: string

	/**
	 * EX_pk_idk2
	 */
	EX_pk_idk2?: number

	/**
	 * EX_pk_ic
	 */
	EX_pk_ic?: string

	/**
	 * EX_pk_po
	 */
	EX_pk_po?: string

	/**
	 * EX_pk_odb
	 */
	EX_pk_odb?: string

	/**
	 * Do knihy prodeje v cílovém mandantovi
	 */
	EX_PM_Do_Kn_pr?: string

	/**
	 * Od kdy přenášet faktury vydané
	 */
	EX_PM_Fav_Dat_Od?: Date

	/**
	 * Do knihy prodeje v tomto mandantovi
	 */
	EX_PM_Do_Kn_prod?: number

	/**
	 * První znak knihy (nákupu) z jiného manda
	 */
	EX_PM_Jinde_Kn_Zac?: string

	/**
	 * Mandant přenosu dokladů
	 */
	EX_PM_Mandant?: string

	/**
	 * FAV potvr. do fronty mail
	 */
	EX_fav_potv_frmail?: string

	/**
	 * DL bez cen
	 */
	EX_ch_DLVbezCEN?: string

	/**
	 * Part.ind.ceny jen 1. nejlevnější
	 */
	EX_p_ind_ceny_1nej?: string

	/**
	 * Faktura příloha ISDOC
	 */
	EX_ch_gen_favISDOC?: string

	/**
	 * Part.ind.ceny % marže
	 */
	EX_p_ind_ceny_marze?: number

	/**
	 * Part.ind.ceny % dolů
	 */
	EX_p_ind_ceny_dolu?: number

	/**
	 * Part.ind.ceny
	 */
	EX_p_ind_ceny?: string

	/**
	 * Vybrané sestavy v Ks
	 */
	EX_v_sest_ks?: string

	/**
	 * Auto nefakturovat
	 */
	EX_auto_nefakturovat?: string

	/**
	 * Dní dodání
	 */
	EX_DaysToDelivery?: number

	/**
	 * EX_ch_csv_typZEMPLIN
	 */
	EX_ch_csv_typZEMPLIN?: string

	/**
	 * EX_ch_csv_typ5slp
	 */
	EX_ch_csv_typ5slp?: string

	/**
	 * EX_ch_tisk_favZkr2
	 */
	EX_ch_tisk_favZkr2?: string

	/**
	 * EX_ch_fav_NEckTOS
	 */
	EX_ch_fav_NEckTOS?: string

	/**
	 * EX_ch_csv_typTOS
	 */
	EX_ch_csv_typTOS?: string

	/**
	 * EX_ch_csv_vKUSECH
	 */
	EX_ch_csv_vKUSECH?: string

	/**
	 * EX_ch_tisk_favZkr
	 */
	EX_ch_tisk_favZkr?: string

	/**
	 * EX_ch_tisk_favI1
	 */
	EX_ch_tisk_favI1?: string

	/**
	 * EX_ch_gen_dlvCSV
	 */
	EX_ch_gen_dlvCSV?: string

	/**
	 * EX_ch_gen_favCSV
	 */
	EX_ch_gen_favCSV?: string

	/**
	 * EX_ch_ukazstatpuvodu
	 */
	EX_ch_ukazstatpuvodu?: string

	/**
	 * EX_ch_prohlaseniosho
	 */
	EX_ch_prohlaseniosho?: string

	/**
	 * EX_ch_raditFdleDLV
	 */
	EX_ch_raditFdleDLV?: string

	/**
	 * EX_ch_jednaPRILOHA
	 */
	EX_ch_jednaPRILOHA?: string

	/**
	 * EX_ch_genOBP
	 */
	EX_ch_genOBP?: string

	/**
	 * EX_ch_tisk_barZKRtyp
	 */
	EX_ch_tisk_barZKRtyp?: string

	/**
	 * EX_ch_fak_ipostou
	 */
	EX_ch_fak_ipostou?: string

	/**
	 * EX_ch_tisk_barCODE
	 */
	EX_ch_tisk_barCODE?: string

	/**
	 * EX_ch_genDODAK
	 */
	EX_ch_genDODAK?: string

	/**
	 * EX_ch_tisknout
	 */
	EX_ch_tisknout?: string

	/**
	 * EX_ch_KCiOrignal
	 */
	EX_ch_KCiOrignal?: string

	/**
	 * EX_ch_nemailovatFAKt
	 */
	EX_ch_nemailovatFAKt?: string

	/**
	 * EX_ch_tisk_prohodODB
	 */
	EX_ch_tisk_prohodODB?: string

	/**
	 * EX_ch_tisk_kodODB
	 */
	EX_ch_tisk_kodODB?: string

	/**
	 * EX_ch_tisk_KC
	 */
	EX_ch_tisk_KC?: string

	/**
	 * EX_ch_tisk_4desmista
	 */
	EX_ch_tisk_4desmista?: string

	/**
	 * Mazání duplicitních kódů
	 */
	Ex_DuplicationBehavi?: number

	/**
	 * EX_GenZakCSV
	 */
	EX_GenZakCSV?: string

	/**
	 * Mailovat PDF nákup
	 */
	EX_Nak_PDF?: string

	/**
	 * EX_mailPRI
	 */
	EX_mailPRI?: string

	/**
	 * EX_mailPRI_OD
	 */
	EX_mailPRI_OD?: Date

	/**
	 * EX_Vybr_dod
	 */
	EX_Vybr_dod?: string

	/**
	 * EX_KanbanNotCheckDup
	 */
	EX_KanbanNotCheckDup?: string

	/**
	 * Konkurent ES
	 */
	EX_Konkurent_ES?: string

	/**
	 * Přeskočit kompletaci
	 */
	Ex_SkipCompletion?: string

	/**
	 * Povolen XML feed
	 */
	EX_xmlFeedEnabled?: string

	/**
	 * Požadovaná výstupní kontrola
	 */
	Ex_IsRequiredOC?: string

	/**
	 * Ex_FakturovatJC
	 */
	Ex_FakturovatJC?: string

	/**
	 * Kanban tisk pruvodni
	 */
	Ex_KanbanTiskPruList?: string

	/**
	 * Ptát se počet štíků
	 */
	Ex_PtatSePocetStitku?: string

	/**
	 * Kanban simple
	 */
	Ex_KanbanSimple?: string

	/**
	 * No postage
	 */
	Ex_NoPostage?: string

	/**
	 * Fakturovat ohrádky
	 */
	Ex_FakturovatOhradky?: string

	/**
	 * Fakturovat palety
	 */
	Ex_FakturovatPalety?: string

	/**
	 * Vydat vetši množství
	 */
	Ex_VydatVetsiMnoz?: string

	/**
	 * Nevykrývat
	 */
	Ex_Nevykryvat?: string

	/**
	 * Vychystat papírově
	 */
	Ex_VychystatPapirove?: string

	/**
	 * Přikládat FAV k zási
	 */
	Ex_PrikladatFavKZas?: string

	/**
	 * Grupovat podle diviz
	 */
	Ex_GrupovatDleDivizi?: string

	/**
	 * Tisk štítků zákazník
	 */
	Ex_TiskStitkuZakaz?: string

	/**
	 * Datum - Kontrola FAV
	 */
	EX_KontrFavDa?: Date

	/**
	 * Kontrola FAV po spla
	 */
	EX_KontrFavPo?: string

	/**
	 * Konkurent
	 */
	EX_Konkurent?: string

	/**
	 * EX_sec_EcpId
	 */
	EX_sec_EcpId?: number

	/**
	 * EX_sec_EcpId_S
	 */
	EX_sec_EcpId_S?: string

	/**
	 * Nek. DIČ na zakázce
	 */
	EX_Nekont_DIC?: string

	/**
	 * Pozastavit platby
	 */
	EX_Pozast_Plat?: string

	/**
	 * EX_Flagy
	 */
	EX_Flagy_old?: number

	/**
	 * Neslučovat FAV
	 */
	EX_NeslucovatFAV?: string

	/**
	 * Ex_Cenik_Periodicky
	 */
	Ex_Cenik_Periodicky?: string

	/**
	 * EX_Asie
	 */
	EX_Asie?: string

	/**
	 * Potvrzenka excel
	 */
	EX_Potvrz_xls?: string

	/**
	 * NZ toler. množ. výr.
	 */
	EX_NZ_dod_toler?: string

	/**
	 * Konsignační zákazník
	 */
	EX_Zak_konsi?: string

	/**
	 * Adresu dodání na obj
	 */
	EX_Obv_DAd?: string

	/**
	 * EX_ZP_D_Nak
	 */
	EX_ZP_D_Nak?: string

	/**
	 * Neimportovat dopr ES
	 */
	EX_es_neimdopr?: string

	/**
	 * EX_Kupeg_Pozn
	 */
	EX_Kupeg_Pozn?: string

	/**
	 * EX_Fact_Pozn
	 */
	EX_Fact_Pozn?: string

	/**
	 * EX_Fact_DatZ
	 */
	EX_Fact_DatZ?: Date

	/**
	 * EX_Kupeg_DatZ
	 */
	EX_Kupeg_DatZ?: Date

	/**
	 * EX_mailOBP_OD
	 */
	EX_mailOBP_OD?: Date

	/**
	 * EX_nemail_prav
	 */
	EX_nemail_prav?: string

	/**
	 * EX_uD_prav
	 */
	EX_uD_prav?: number

	/**
	 * EX_uD_U3
	 */
	EX_uD_U3?: number

	/**
	 * EX_uD_U2
	 */
	EX_uD_U2?: number

	/**
	 * EX_uD_U1
	 */
	EX_uD_U1?: number

	/**
	 * EX_uD_prip
	 */
	EX_uD_prip?: number

	/**
	 * EX_KontrolaDLV
	 */
	EX_KontrolaDLV?: string

	/**
	 * EX_Kupeg_Castka
	 */
	EX_Kupeg_Castka?: number

	/**
	 * EX_Kupeg_DatOd
	 */
	EX_Kupeg_DatOd?: Date

	/**
	 * EX_Bonus
	 */
	EX_Bonus?: string

	/**
	 * Ex_Zak_E1
	 */
	Ex_Zak_E1?: string

	/**
	 * Ex_NeRozbalne
	 */
	Ex_NeRozbalne?: string

	/**
	 * Ex_Expres
	 */
	Ex_Expres?: string

	/**
	 * Počítat jako Asie
	 */
	EX_je_A?: string

	/**
	 * EX_akc_sk_vzdy
	 */
	EX_akc_sk_vzdy?: string

	/**
	 * EX_mail_cenik_bcc
	 */
	EX_mail_cenik_bcc?: string

	/**
	 * EX_NeslucovatDLV
	 */
	EX_NeslucovatDLV?: string

	/**
	 * Nezap počít nakl
	 */
	EX_ne_pocNAKL?: string

	/**
	 * Dny zpět VIP
	 */
	EX_Dzpet_VIP?: number

	/**
	 * Ceník ZKR3
	 */
	EX_CSV_zkr3?: string

	/**
	 * Připomínky spl. FAV
	 */
	EX_mail_prip?: string

	/**
	 * EX_nemail_upo
	 */
	EX_nemail_upo?: string

	/**
	 * EX_Info_ES
	 */
	EX_Info_ES?: number

	/**
	 * Blokovat na eshopu
	 */
	EX_Blok_ES?: string

	/**
	 * EX_Neslucovat
	 */
	EX_Neslucovat?: string

	/**
	 * EX_fact_csob
	 */
	EX_fact_csob?: string

	/**
	 * EX_MailDlvBalik
	 */
	EX_MailDlvBalik?: string

	/**
	 * EX_flagt3
	 */
	EX_flagt3?: number

	/**
	 * EX_mekrs_ozn
	 */
	EX_mekrs_ozn?: string

	/**
	 * EX_mailD_OD
	 */
	EX_mailD_OD?: Date

	/**
	 * EX_mailF_OD
	 */
	EX_mailF_OD?: Date

	/**
	 * EX_mail_cenik
	 */
	EX_mail_cenik?: string

	/**
	 * EX_CSV_noDISP
	 */
	EX_CSV_noDISP?: string

	/**
	 * EX_ne_PA
	 */
	EX_ne_PA?: string

	/**
	 * EX_dopr_pozn
	 */
	EX_dopr_pozn?: string

	/**
	 * EX_Zp_D_EXW
	 */
	EX_Zp_D_EXW?: string

	/**
	 * EX_Zp_D_DDU
	 */
	EX_Zp_D_DDU?: string

	/**
	 * EX_dopr_nakl_DDU
	 */
	EX_dopr_nakl_DDU?: number

	/**
	 * efektiv lim.dopr EXW
	 */
	EX_dopr_limit_EXW?: number

	/**
	 * limit dopravné DDU
	 */
	EX_dopr_limit_DDU?: number

	/**
	 * EX_dopr_nakl
	 */
	EX_dopr_nakl?: number

	/**
	 * EX_ne_PO
	 */
	EX_ne_PO?: string

	/**
	 * EX_flagt2
	 */
	EX_flagt2?: number

	/**
	 * EX_dopr_limit
	 */
	EX_dopr_limit?: number

	/**
	 * EX_cenik
	 */
	EX_cenik?: string

	/**
	 * EX_flagt
	 */
	EX_flagt?: number

	/**
	 * undefined
	 */
	BookmarkImgCalc?: string

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * SQLSequenceOrderCalc
	 */
	SQLSequenceOrderCalc?: number

	/**
	 * Datum pro "_ver" pole
	 */
	VersionedFieldDateCalc?: Date

	/**
	 * Odkaz
	 */
	PictureCalc?: string

	/**
	 * Datum pro "_ver" pole
	 */
	VersionFieldDateCalc?: Date

	/**
	 * Evidovat kredity
	 */
	CreditEvidenceBitCalc?: number

	/**
	 * Měna
	 */
	CompanyCurrencyCalc?: string

	/**
	 * Název a adresa
	 */
	NameAndAddressCalc?: string

	/**
	 * Ban. účet
	 */
	BankAccountNumberCalc?: string

	/**
	 * Zahr. banka
	 */
	ForeignBankBitCalc?: number

	/**
	 * Čerpáno přij.
	 */
	AdvProvidedDeductedAmountCalc?: number

	/**
	 * Dočerpat  přij.
	 */
	ExistsTrPartAdvNotPaidBitCalc?: number

	/**
	 * Čerpáno posk.
	 */
	AdvReceiveDeductedAmountCalc?: number

	/**
	 * Dočerpat  posk.
	 */
	AdvProvRemainsToDedcAmountCalc?: number

	/**
	 * IČO
	 */
	CompanyRegNumberCalc?: string

	/**
	 * DIČ
	 */
	VATRegistrationNumberCalc?: string

	/**
	 * undefined
	 */
	TradeRegAutomaticTaxNumberCalc?: string

	/**
	 * Daňové číslo
	 */
	TaxNumberCalc?: string

	/**
	 * El. adresa 1
	 */
	ElectronicAddress1Calc?: string

	/**
	 * El. adresa 2
	 */
	ElectronicAddress2Calc?: string

	/**
	 * El. adresa 3
	 */
	ElectronicAddress3Calc?: string

	/**
	 * El. adresa 4
	 */
	ElectronicAddress4Calc?: string

	/**
	 * undefined
	 */
	IBANCalc?: string

	/**
	 * undefined
	 */
	ExistsAdvReceiveUnpaidBitCalc?: number

	/**
	 * undefined
	 */
	ExistsAdvanceProvidedUnpaidBitCalc?: number

	/**
	 * undefined
	 */
	ExistsAdvReceiveNotPaidImgCalc?: string

	/**
	 * undefined
	 */
	ExistsAdvProvidedNotPaidImgCalc?: string

	/**
	 * undefined
	 */
	ElectronicAddressCheckItemFieldCalc?: number

	/**
	 * El. adresa 1
	 */
	ElectronicAddress1FormattedCalc?: string

	/**
	 * El. adresa 2
	 */
	ElectronicAddress2FormattedCalc?: string

	/**
	 * El. adresa 3
	 */
	ElectronicAddress3FormattedCalc?: string

	/**
	 * El. adresa 4
	 */
	ElectronicAddress4FormattedCalc?: string

	/**
	 * undefined
	 */
	BankSpecificSymbolCalc?: string

	/**
	 * Fakturační adresa - název
	 */
	InvoiceAddressNameCalc?: string

	/**
	 * Dodací adresa - název
	 */
	DeliveryAddressNameCalc?: string

	/**
	 * Korespondenční adresa - název
	 */
	PostalAddressNameCalc?: string

	/**
	 * Blokace potvrzení faktury vydané
	 */
	ConfirmedInvoiceOutBitCalc?: number

	/**
	 * Blokace vytvoření faktury vydané
	 */
	BlockingCreateInvoiceOutBitCalc?: number

	/**
	 * Blokace potvrzení faktury přijaté
	 */
	ConfirmedInvoiceInBitCalc?: number

	/**
	 * Blokace vytvoření faktury přijaté
	 */
	BlockingCreateInvoiceInBitCalc?: number

	/**
	 * Blokace potvrzení výdejky
	 */
	ConfirmedReleaseNoteBitCalc?: number

	/**
	 * Blokace potvrzení příjemky
	 */
	ConfirmedReceiptCardBitCalc?: number

	/**
	 * Blokace vytvoření příjemky
	 */
	BlockingCreateReceiptCardBitCalc?: number

	/**
	 * Blokace potvrzení rezervačního listu
	 */
	ConfirmedReservingCardBitCalc?: number

	/**
	 * Blokace vytvoření rezervačního listu
	 */
	BlockingCreateReservingCardBitCalc?: number

	/**
	 * Je zneplatněný
	 */
	InvalidRecordBitCalc?: number

	/**
	 * Fakturační adresa z centrály
	 */
	CentralInvoiceAddressBitCalc?: number

	/**
	 * Dodací adresa z centrály
	 */
	CentralDeliveryAddressBitCalc?: number

	/**
	 * Korespondenční adresa z centrály
	 */
	CentralPostalAddressBitCalc?: number

	/**
	 * Pohyblivá splatnost
	 */
	FloatingMaturityPurchaseBitCalc?: number

	/**
	 * Pohyblivá splatnost
	 */
	FloatingMaturitySaleBitCalc?: number

	/**
	 * Předefinovat skupinu ABC
	 */
	RedefineABCBitCalc?: number

	/**
	 * Přepočet fakturace po periodách
	 */
	RecalculationByPeriodBitCalc?: number

	/**
	 * Zaměstnává zaměstnance se zdr. postižením
	 */
	HealthHandicapIdCalc?: number

	/**
	 * undefined
	 */
	CreditInStateCalc?: number

	/**
	 * undefined
	 */
	CreditOutStateCalc?: number

	/**
	 * Podnik konsolidačního celku
	 */
	ConsolidatedGroupCompanyIdCalc?: number

	/**
	 * Kód banky
	 */
	BankCodeNumberCalc?: string

	/**
	 * undefined
	 */
	IsRatingCreditcheckEnabledCalc?: string

	/**
	 * undefined
	 */
	IsRatingIsirEnabledCalc?: string

	/**
	 * undefined
	 */
	IsRatingViesEnabledCalc?: string

	/**
	 * undefined
	 */
	IsRatingCrpEnabledCalc?: string

	/**
	 * undefined
	 */
	IsRatingEnabledCalc?: string

	/**
	 * Pouze zboží z ceníku
	 */
	ArticleFromPriceListIdCalc?: number

	/**
	 * undefined
	 */
	IntrastatCountryIdCalc?: number

	/**
	 * Překročení kreditu
	 */
	CreditWarningCalc?: number

	/**
	 * undefined
	 */
	IsNotifyCreditCalc?: string

	/**
	 * Zpracování omezeno
	 */
	LimitedProcessingBitCalc?: number

	/**
	 * Fyzická osoba
	 */
	PhysicalPersonIdCalc?: number

	/**
	 * Splatnost (dny)
	 */
	ActualMaturityDaysCalc?: number

	/**
	 * Splatnost (dny)
	 */
	PlannedMaturityDaysCalc?: number

	/**
	 * Skupinové DIČ
	 */
	GroupVATIdCalc?: number

	/**
	 * undefined
	 */
	TransportMethodRIDCalc?: number

	/**
	 * Výchozí konfigurace
	 */
	ABCAnalysisDefaultConfigCalc?: string

	/**
	 * Intrastat mimo EU
	 */
	IntrastatOutsideEUBitCalc?: number

	/**
	 * El. adresa
	 */
	EAddressPARAMCalc?: string

	/**
	 * Interní text
	 */
	InternalTextCalc?: string

	/**
	 * undefined
	 */
	TransportMethodPurchaseRIDCalc?: number

	/**
	 * undefined
	 */
	TextHeaderMemoCalc?: string

	/**
	 * undefined
	 */
	TextFooterMemoCalc?: string

	/**
	 * Poslední komentář
	 */
	LastCommentParamCalc?: string

	/**
	 * Ulice (Korespondenční adresa)
	 */
	AddressIdStreetCalc?: string

	/**
	 * Číslo orientační (Korespondenční adresa)
	 */
	AddressIdOrientationNumberCalc?: string

	/**
	 * Číslo popisné (Korespondenční adresa)
	 */
	AddressIdHouseNumberCalc?: string

	/**
	 * Název (Korespondenční adresa)
	 */
	AddressIdNameCalc?: string

	/**
	 * Změněno (Korespondenční adresa)
	 */
	AddressIdChangedOnCalc?: Date

	/**
	 * PSČ (Korespondenční adresa)
	 */
	AddressIdTownCodeCalc?: string

	/**
	 * Souřadnice (Korespondenční adresa)
	 */
	AddressIdCoordinatesCalc?: string

	/**
	 * IČ_ver
	 */
	CompanyRegNumber_ver?: string

	/**
	 * Firma_ver
	 */
	Name_ver?: string

	/**
	 * Registr_ver
	 */
	BusinessRegister_ver?: string

	/**
	 * DIČ_ver
	 */
	VATRegNumber_ver?: string

	/**
	 * Daňové číslo_ver
	 */
	TaxNumber_ver?: string

	/**
	 * DPH na dokladech nákupu
	 */
	IsVATPayerPurchaseCalc?: string

	/**
	 * DPH na dokladech prodeje
	 */
	IsVATPayerSaleCalc?: string

	/**
	 * Zákonný úrok pro nákup
	 */
	IsStatutoryInterestPurchaseCalc?: string

	/**
	 * Zákonný úrok pro prodej
	 */
	IsStatutoryInterestSaleCalc?: string

	/**
	 * Saldo po spl.
	 */
	CF_SaldoPo?: number

	/**
	 * Je FTP
	 */
	CF_JeFTP?: string

	/**
	 * CF_doprDOD
	 */
	CF_doprDOD?: number

	/**
	 * Factoring
	 */
	CF_factoring?: string

	/**
	 * Baz_mandant
	 */
	CF_Baz_mandant?: string

	/**
	 * Číslo objednávky Sokolov
	 */
	CF_zakaznik_c_obj_10?: string

	/**
	 * Číslo objednávky Plzeň
	 */
	CF_zakaznik_c_obj_20?: string

	/**
	 * Číslo objednávky Karlovy Vary
	 */
	CF_zakaznik_c_obj_30?: string

	/**
	 * Číslo objednávky Svatava
	 */
	CF_zakaznik_c_obj_40?: string

	/**
	 * Zákazník poznámka Sokolov
	 */
	CF_Zakaznik_poznamka_10?: string

	/**
	 * Zákazník poznámka Plzeň
	 */
	CF_Zakaznik_poznamka_20?: string

	/**
	 * Zákazník poznámka Karlovy Vary
	 */
	CF_Zakaznik_poznamka_30?: string

	/**
	 * Zákazník poznámka Svatava
	 */
	CF_Zakaznik_poznamka_40?: string

	/**
	 * Číslo objednávky Pardubice
	 */
	CF_zakaznik_c_obj_50?: string

	/**
	 * Číslo objednávky Ostrava
	 */
	CF_zakaznik_c_obj_60?: string

	/**
	 * Číslo objednávky Chomutov
	 */
	CF_zakaznik_c_obj_70?: string

	/**
	 * Zákazník poznámka Pardubice
	 */
	CF_Zakaznik_poznamka_50?: string

	/**
	 * Zákazník poznámka Ostrava
	 */
	CF_Zakaznik_poznamka_60?: string

	/**
	 * Zákazník poznámka Chomutov
	 */
	CF_Zakaznik_poznamka_70?: string

	/**
	 * Zákazník poznámka Kolín
	 */
	CF_Zakaznik_poznamka_80?: string

	/**
	 * Číslo objednávky Kolín
	 */
	CF_zakaznik_c_obj_80?: string

	/**
	 * Číslo objednávky Brno
	 */
	CF_zakaznik_c_obj_90?: string

	/**
	 * Zákazník poznámka Brno
	 */
	CF_Zakaznik_poznamka_90?: string

	/**
	 * Právo na záznam
	 */
	RightGroupIdCalc?: number

	/**
	 * Povolené způsoby dopravy
	 */
	@Type(() => TAllowedTransportMethodDM)
	PurchAllowedTransportMethodRID?: TAllowedTransportMethodDM

	/**
	 * Povolené způsoby platby
	 */
	@Type(() => TAllowedPaymentMethodDM)
	PurchAllowedPaymentMethodRID?: TAllowedPaymentMethodDM

	/**
	 * Kód 6
	 */
	@Type(() => TCode6DocumentDM)
	Code6Id?: TCode6DocumentDM

	/**
	 * Kód 5
	 */
	@Type(() => TCode5DocumentDM)
	Code5Id?: TCode5DocumentDM

	/**
	 * Kód 4
	 */
	@Type(() => TCode4DocumentDM)
	Code4Id?: TCode4DocumentDM

	/**
	 * Kód 3
	 */
	@Type(() => TCode3DocumentDM)
	Code3Id?: TCode3DocumentDM

	/**
	 * Bankovní účet pro platby
	 */
	@Type(() => TBookDM)
	PurchaseBankBookId?: TBookDM

	/**
	 * Priorita
	 */
	@Type(() => TTextDM)
	PaymentPriorityId?: TTextDM

	/**
	 * Prodejní akce
	 */
	@Type(() => TSalesActionDM)
	SellingActionRID?: TSalesActionDM

	/**
	 * Ceník
	 */
	@Type(() => TPriceListDM)
	PriceListRID?: TPriceListDM

	/**
	 * Středisko
	 */
	@Type(() => TCostCentreCollectionDocumentDM)
	CostCentreId?: TCostCentreCollectionDocumentDM

	/**
	 * Povolené způsoby dopravy
	 */
	@Type(() => TAllowedTransportMethodDM)
	AllowedTransportMethodRID?: TAllowedTransportMethodDM

	/**
	 * Povolené způsoby platby
	 */
	@Type(() => TAllowedPaymentMethodDM)
	AllowedPaymentMethodRID?: TAllowedPaymentMethodDM

	/**
	 * Prostředek
	 */
	@Type(() => TDeviceDocumentDM)
	DeviceRID?: TDeviceDocumentDM

	/**
	 * Účet MD VZ
	 */
	@Type(() => TAccountingChartDM)
	DebitAccAdvPrvId?: TAccountingChartDM

	/**
	 * Účet D VZ
	 */
	@Type(() => TAccountingChartDM)
	CreditAccAdvPrvId?: TAccountingChartDM

	/**
	 * Účet MD PZ
	 */
	@Type(() => TAccountingChartDM)
	DebitAccAdvRecId?: TAccountingChartDM

	/**
	 * Účet D PZ
	 */
	@Type(() => TAccountingChartDM)
	CreditAccAdvRecId?: TAccountingChartDM

	/**
	 * Skupina ABC vypočtená
	 */
	@Type(() => ABCClass)
	ABCClassCalcId?: ABCClass

	/**
	 * Skupina ABC
	 */
	@Type(() => ABCClass)
	ABCClassId?: ABCClass

	/**
	 * Kód zakázky
	 */
	@Type(() => TContractCodeDocumentDM)
	ContractCodeRID?: TContractCodeDocumentDM

	/**
	 * Partner
	 */
	@Type(() => TPartnerDM)
	PartnerId?: TPartnerDM

	/**
	 * Korespondenční adresa
	 */
	@Type(() => TAddressDM)
	AddressId?: TAddressDM

	/**
	 * Vytvořil
	 */
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM

	/**
	 * Pracovník
	 */
	@Type(() => TOfficerDocumentDM)
	OfficerId?: TOfficerDocumentDM

	/**
	 * Kód 2
	 */
	@Type(() => TCode2DocumentDM)
	Code2Id?: TCode2DocumentDM

	/**
	 * Kód 1
	 */
	@Type(() => TCode1DocumentDM)
	Code1Id?: TCode1DocumentDM

	/**
	 * Jazyk
	 */
	@Type(() => TTextDM)
	LangId?: TTextDM

	/**
	 * Oblast
	 */
	@Type(() => Domain)
	AreaId?: Domain

	/**
	 * Region
	 */
	@Type(() => AreaCode)
	TerritoryId?: AreaCode

	/**
	 * Změnil
	 */
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM

	/**
	 * Záv. účet
	 */
	@Type(() => TAccountingChartDM)
	PayablesAccountId?: TAccountingChartDM

	/**
	 * Pohl. účet
	 */
	@Type(() => TAccountingChartDM)
	ReceivablesAccountId?: TAccountingChartDM

	/**
	 * Cen. sk.
	 */
	@Type(() => TPriceGroupDM)
	PriceGroupId?: TPriceGroupDM

	/**
	 * Kód
	 */
	@Type(() => Code)
	CodeId?: Code

	/**
	 * Skupina
	 */
	@Type(() => CustomerGroup)
	GroupId?: CustomerGroup

	/**
	 * Bankovní účet 2
	 */
	@Type(() => TBookDM)
	EX_BankBookId_2?: TBookDM

	/**
	 * EX_ExportType
	 */
	@Type(() => TCodeTypeDM)
	EX_ExportType?: TCodeTypeDM

	/**
	 * Právo
	 */
	@Type(() => TSpecialWebApplicationRight)
	Ex_WebRightRID?: TSpecialWebApplicationRight

	/**
	 * Stát původu výrobce
	 */
	@Type(() => TCountryDM)
	EX_StateOfOrigin?: TCountryDM

	/**
	 * Typ fakturace
	 */
	@Type(() => TSpecialStringListDM)
	EX_Typ_Fakturace?: TSpecialStringListDM

	/**
	 * EX_Zp_D_EXW_rid
	 */
	@Type(() => TTransportMethodDM)
	EX_Zp_D_EXW_rid?: TTransportMethodDM

	/**
	 * EX_Zp_D_DDU_rid
	 */
	@Type(() => TTransportMethodDM)
	EX_Zp_D_DDU_rid?: TTransportMethodDM

	/**
	 * Způsob dopravy - nák
	 */
	@Type(() => TTransportMethodDM)
	EX_TransportMethod_N?: TTransportMethodDM

	/**
	 * Odpovědný referent
	 */
	@Type(() => TK2UserLookupDM)
	EX_L_Dod_Referent?: TK2UserLookupDM

	/**
	 * EX_Kupeg_Mena
	 */
	@Type(() => TCurrencyDM)
	EX_Kupeg_Mena?: TCurrencyDM

	/**
	 * ABC zařazení
	 */
	@Type(() => TSpecialStringListDM)
	EX_ABC?: TSpecialStringListDM

	/**
	 * Externí obchodník
	 */
	@Type(() => TK2UserLookupDM)
	EX_Exter_Obchodnik?: TK2UserLookupDM

	/**
	 * EX_Obchodnik
	 */
	@Type(() => TK2UserLookupDM)
	EX_Obchodnik?: TK2UserLookupDM

	/**
	 * Kdy ceník
	 */
	@Type(() => TSpecialStringListDM)
	EX_CSV_kdyC?: TSpecialStringListDM

	/**
	 * EX_Jed_EXW
	 */
	@Type(() => TSpecialStringListDM)
	EX_Jed_EXW?: TSpecialStringListDM

	/**
	 * EX_Jed_DDU
	 */
	@Type(() => TSpecialStringListDM)
	EX_Jed_DDU?: TSpecialStringListDM

	/**
	 * Z
	 */
	@Type(() => TTextDM)
	InvalidRecordImgCalc?: TTextDM

	/**
	 * VF
	 */
	@Type(() => TTextDM)
	BlockInvoiceOutBitCalc?: TTextDM

	/**
	 * PF
	 */
	@Type(() => TTextDM)
	BlockInvoiceInBitCalc?: TTextDM

	/**
	 * VY
	 */
	@Type(() => TTextDM)
	BlockReleaseNoteBitCalc?: TTextDM

	/**
	 * PR
	 */
	@Type(() => TTextDM)
	BlockReceiptCardBitCalc?: TTextDM

	/**
	 * RE
	 */
	@Type(() => TTextDM)
	BlockReservingCardBitCalc?: TTextDM

	/**
	 * undefined
	 */
	@Type(() => TBanksDM)
	BankIdCalc?: TBanksDM

	/**
	 * Typ el. adresy 1
	 */
	@Type(() => TElectronicAddressTypeDM)
	ElectronicAddress1TypeIdCalc?: TElectronicAddressTypeDM

	/**
	 * Typ el. adresy 2
	 */
	@Type(() => TElectronicAddressTypeDM)
	ElectronicAddress2TypeIdCalc?: TElectronicAddressTypeDM

	/**
	 * Typ el. adresy 3
	 */
	@Type(() => TElectronicAddressTypeDM)
	ElectronicAddress3TypeIdCalc?: TElectronicAddressTypeDM

	/**
	 * Typ el. adresy 4
	 */
	@Type(() => TElectronicAddressTypeDM)
	ElectronicAddress4TypeIdCalc?: TElectronicAddressTypeDM

	/**
	 * Stát el. adresy 1
	 */
	@Type(() => TCountryDM)
	ElectronicAddressCountry1IdCalc?: TCountryDM

	/**
	 * Stát el. adresy 2
	 */
	@Type(() => TCountryDM)
	ElectronicAddressCountry2IdCalc?: TCountryDM

	/**
	 * Stát el. adresy 3
	 */
	@Type(() => TCountryDM)
	ElectronicAddressCountry3IdCalc?: TCountryDM

	/**
	 * Stát el. adresy 4
	 */
	@Type(() => TCountryDM)
	ElectronicAddressCountry4IdCalc?: TCountryDM

	/**
	 * Stav
	 */
	@Type(() => PartnerStatus)
	StatusIdCalc?: PartnerStatus

	/**
	 * Fakturační adresa
	 */
	@Type(() => TAddressDM)
	InvoiceAddressIdCalc?: TAddressDM

	/**
	 * Dodací adresa
	 */
	@Type(() => TAddressDM)
	DeliveryAddressIdCalc?: TAddressDM

	/**
	 * Korespondenční adresa
	 */
	@Type(() => TAddressDM)
	PostalAddressIdCalc?: TAddressDM

	/**
	 * Partner
	 */
	@Type(() => TPartnerDM)
	PartnerIdCalc?: TPartnerDM

	/**
	 * D
	 */
	@Type(() => TTextDM)
	DocumentExistsCalc?: TTextDM

	/**
	 * undefined
	 */
	@Type(() => TMarketingActivityTypeDM)
	ActivityTypeRIDCalc?: TMarketingActivityTypeDM

	/**
	 * SP
	 */
	@Type(() => TTextDM)
	ProcessStateIdCalc?: TTextDM

	/**
	 * Aktuální proces
	 */
	@Type(() => TWorkflowProcessDM)
	CurrentProcessIdCalc?: TWorkflowProcessDM

	/**
	 * undefined
	 */
	@Type(() => TActivityDM)
	ActivityIdCalc?: TActivityDM

	/**
	 * Korespondenční adresa_ver
	 */
	@Type(() => TAddressDM)
	AddressId_ver?: TAddressDM

	/**
	 * Stát (Korespondenční adresa)
	 */
	@Type(() => TCountryDM)
	AddressIdCountryIdCalc?: TCountryDM

	/**
	 * Část obce (Korespondenční adresa)
	 */
	@Type(() => TTownPartDM)
	AddressIdTownPartIdCalc?: TTownPartDM

	/**
	 * Číslo (Korespondenční adresa)
	 */
	@Type(() => TTownDM)
	AddressIdTownIdCalc?: TTownDM

	/**
	 * Bank. účet
	 */
	@Type(() => TCompanyDividedBookDM)
	BankBookIdCalc?: TCompanyDividedBookDM

	/**
	 * Měna
	 */
	@Type(() => TCurrencyDM)
	CurrencyCalc?: TCurrencyDM

	/**
	 * Zp. platby
	 */
	@Type(() => TPaymentMethodDM)
	SalePaymentMethodIdCalc?: TPaymentMethodDM

	/**
	 * Zp. dopravy
	 */
	@Type(() => TTransportMethodDM)
	SaleTransportMethodRIDCalc?: TTransportMethodDM

	/**
	 * Zp. odběru
	 */
	@Type(() => TShippingMethodDM)
	SaleShippingMethodIdCalc?: TShippingMethodDM

	/**
	 * Dodací podmínky
	 */
	@Type(() => JCDP20_a1)
	SaleDeliveryTermsIdCalc?: JCDP20_a1

	/**
	 * Forma objednávky
	 */
	@Type(() => FormOfOrder)
	SaleOrderFormIdCalc?: FormOfOrder

	/**
	 * Zp. platby
	 */
	@Type(() => TPaymentMethodDM)
	PurchasePaymentMethodIdCalc?: TPaymentMethodDM

	/**
	 * Zp. dopravy
	 */
	@Type(() => TTransportMethodDM)
	PurchaseTransportMethodRIDCalc?: TTransportMethodDM

	/**
	 * Zp. odběru
	 */
	@Type(() => TShippingMethodDM)
	PurchaseShippingMethodIdCalc?: TShippingMethodDM

	/**
	 * Dodací podmínky
	 */
	@Type(() => JCDP20_a1)
	PurchaseDeliveryTermsIdCalc?: JCDP20_a1

	/**
	 * Forma objednávky
	 */
	@Type(() => FormOfOrder)
	PurchaseOrderFormIdCalc?: FormOfOrder

	/**
	 * Typ daně - nákup
	 */
	@Type(() => TTaxTypeDM)
	PurchaseTaxTypeIdCalc?: TTaxTypeDM

	/**
	 * Typ daně - prodej
	 */
	@Type(() => TTaxTypeDM)
	SaleTaxTypeIdCalc?: TTaxTypeDM

	/**
	 * Odkazy
	 */
	@Type(() => TLinkDM)
	LinkChild?: TLinkDM[]

	/**
	 * Komentáře
	 */
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]

	/**
	 * Úkoly
	 */
	@Type(() => TJobDM)
	JobChild?: TJobDM[]

	/**
	 * undefined
	 */
	@Type(() => TServiceNoteDM)
	ServiceNoteChild?: TServiceNoteDM[]

	/**
	 * undefined
	 */
	@Type(() => TServiceNoteDM)
	ServiceNoteSupplierChild?: TServiceNoteDM[]

	/**
	 * undefined
	 */
	@Type(() => TSalesOrderDM)
	SalesOrderChild?: TSalesOrderDM[]

	/**
	 * undefined
	 */
	@Type(() => TPurchaseOrderDM)
	PurchaseOrderChild?: TPurchaseOrderDM[]

	/**
	 * undefined
	 */
	@Type(() => TOpenItemListDM)
	OpenItemChild?: TOpenItemListDM[]

	/**
	 * undefined
	 */
	@Type(() => TReminderDM)
	ReminderChild?: TReminderDM[]

	/**
	 * undefined
	 */
	@Type(() => TOpportunityDM)
	OpportunityChild?: TOpportunityDM[]

	/**
	 * undefined
	 */
	@Type(() => TQuotationDM)
	QuotationChild?: TQuotationDM[]

	/**
	 * undefined
	 */
	@Type(() => TRequestForQuotationDM)
	RequestForQuotationChild?: TRequestForQuotationDM[]

	/**
	 * undefined
	 */
	@Type(() => TShipmentDM)
	ShipmentChild?: TShipmentDM[]

	/**
	 * undefined
	 */
	@Type(() => TParcelDM)
	ParcelChild?: TParcelDM[]

	/**
	 * undefined
	 */
	@Type(() => TPostEnvelopeSentDM)
	PostEnvelopeSentChild?: TPostEnvelopeSentDM[]

	/**
	 * undefined
	 */
	@Type(() => TPostEnvelopeReceivedDM)
	PostEnvelopeReceivedChild?: TPostEnvelopeReceivedDM[]

	/**
	 * undefined
	 */
	@Type(() => TPurchaseItemBookDM)
	PurchaseItemChild?: TPurchaseItemBookDM[]

	/**
	 * undefined
	 */
	@Type(() => TSalesItemBookDM)
	SalesItemChild?: TSalesItemBookDM[]

	/**
	 * undefined
	 */
	@Type(() => TAdvanceReceivedDM)
	AdvanceReceivedChild?: TAdvanceReceivedDM[]

	/**
	 * undefined
	 */
	@Type(() => TAdvanceProvidedDM)
	AdvanceProvidedChild?: TAdvanceProvidedDM[]

	/**
	 * undefined
	 */
	@Type(() => TDeliveryConfirmationDM)
	DeliveryConfirmationChild?: TDeliveryConfirmationDM[]

	/**
	 * undefined
	 */
	@Type(() => TDeliveryNoteDM)
	DeliveryNoteChild?: TDeliveryNoteDM[]

	/**
	 * undefined
	 */
	@Type(() => TInvoiceInDM)
	InvoiceInChild?: TInvoiceInDM[]

	/**
	 * undefined
	 */
	@Type(() => TInvoiceOutDM)
	InvoiceOutChild?: TInvoiceOutDM[]

	/**
	 * undefined
	 */
	@Type(() => TOrderConfirmationDM)
	OrderConfirmationChild?: TOrderConfirmationDM[]

	/**
	 * undefined
	 */
	@Type(() => TReceiptCardDM)
	ReceiptCardChild?: TReceiptCardDM[]

	/**
	 * undefined
	 */
	@Type(() => TReservingCardDM)
	ReservingCardChild?: TReservingCardDM[]

	/**
	 * undefined
	 */
	@Type(() => TReleaseNoteDM)
	ReleaseNoteChild?: TReleaseNoteDM[]

	/**
	 * ABC analýzy
	 */
	@Type(() => TABCItemDM)
	ABCItemChild?: TABCItemDM[]

	/**
	 * Platební podmínky zákazníka pro prodej
	 */
	@Type(() => TPaymentConditionTradingPartnerPurchaseDM)
	PaymentConditionPurchaseChild?: TPaymentConditionTradingPartnerPurchaseDM[]

	/**
	 * Platební podmínky zákazníka pro prodej
	 */
	@Type(() => TPaymentConditionTradingPartnerSaleDM)
	PaymentConditionSaleChild?: TPaymentConditionTradingPartnerSaleDM[]

	/**
	 * undefined
	 */
	@Type(() => TRelatedAddressDM)
	AddressInvoiceChild?: TRelatedAddressDM[]

	/**
	 * undefined
	 */
	@Type(() => TRelatedAddressDM)
	AddressDeliveryChild?: TRelatedAddressDM[]

	/**
	 * undefined
	 */
	@Type(() => TRelatedAddressDM)
	AddressPostalChild?: TRelatedAddressDM[]

	/**
	 * undefined
	 */
	@Type(() => TRelatedAddressDM)
	AddressAllChild?: TRelatedAddressDM[]

	/**
	 * undefined
	 */
	@Type(() => TAdressKeyItemDM)
	AddressKeyChild?: TAdressKeyItemDM[]

	/**
	 * Kontaktní informace
	 */
	@Type(() => TElectronicAddressDM)
	ElectronicAddressChild?: TElectronicAddressDM[]

	/**
	 * Bankovní účty
	 */
	@Type(() => TBankAccountTradingPartnerDM)
	BankAccountChild?: TBankAccountTradingPartnerDM[]

	/**
	 * undefined
	 */
	@Type(() => TPartnerContactPersonDM)
	ContactPersonChild?: TPartnerContactPersonDM[]

	/**
	 * undefined
	 */
	@Type(() => TPriceListDM)
	PriceListChild?: TPriceListDM[]

	/**
	 * undefined
	 */
	@Type(() => TBranchDM)
	BranchChild?: TBranchDM[]

	/**
	 * undefined
	 */
	@Type(() => TTradingPartnerPriceGroupDM)
	TradingPartnerPriceGroupChild?: TTradingPartnerPriceGroupDM[]

	/**
	 * Kredity
	 */
	@Type(() => TCustomerCreditDM)
	CreditChild?: TCustomerCreditDM[]

	/**
	 * undefined
	 */
	@Type(() => TOpenItemListTrendDM)
	OpenItemListTrendChild?: TOpenItemListTrendDM[]

	/**
	 * undefined
	 */
	@Type(() => TServicedDeviceDM)
	ServicedDeviceChild?: TServicedDeviceDM[]

	/**
	 * undefined
	 */
	@Type(() => TABCItemChartSourceDM)
	ABCItemChartSourceChild?: TABCItemChartSourceDM[]

	/**
	 * undefined
	 */
	@Type(() => TSupplierPriceDM)
	SupplierPriceChild?: TSupplierPriceDM[]

	/**
	 * undefined
	 */
	@Type(() => TProductCostingDM)
	ProductCostingChild?: TProductCostingDM[]

	/**
	 * Dopravní linky
	 */
	@Type(() => TTradingPartnerTransportDM)
	TransportChild?: TTradingPartnerTransportDM[]

	/**
	 * undefined
	 */
	@Type(() => TVATRegNumberDM)
	VATRegNumberChild?: TVATRegNumberDM[]

	/**
	 * undefined
	 */
	@Type(() => TActivityDM)
	ActivityChild?: TActivityDM[]

	/**
	 * Dokumenty
	 */
	@Type(() => TExternalDocumentItemDM)
	ExternalDocumentItemChild?: TExternalDocumentItemDM[]

	/**
	 * Procesy
	 */
	@Type(() => TWorkflowProcessAsItemDM)
	WorkflowProcessChild?: TWorkflowProcessAsItemDM[]

	/**
	 * Text hlavičky
	 */
	@Type(() => TLanguageMemoTranslationDM)
	TextHeaderChild?: TLanguageMemoTranslationDM[]

	/**
	 * Text patičky
	 */
	@Type(() => TLanguageMemoTranslationDM)
	TextFooterChild?: TLanguageMemoTranslationDM[]

	/**
	 * undefined
	 */
	@Type(() => TCompanyLinkTradingPartnerDM)
	CompanyChild?: TCompanyLinkTradingPartnerDM[]

	/**
	 * undefined
	 */
	@Type(() => TLegislationLinkTradingPartnerDM)
	LegislationChild?: TLegislationLinkTradingPartnerDM[]

	/**
	 * Rámcová smlouva
	 */
	@Type(() => TSpecialTTradingPartnerDMSalesOrderContractChild)
	SalesOrderContractChild?: TSpecialTTradingPartnerDMSalesOrderContractChild[]

	/**
	 * Dod./Odb. k výpočtu partnerského individuálního ceníku
	 */
	@Type(() => TCustomTTradingPartnerDMDod_Odb_P_Ind_C)
	Dod_Odb_P_Ind_C?: TCustomTTradingPartnerDMDod_Odb_P_Ind_C[]

	/**
	 * Svázané doklady
	 */
	@Type(() => TAttachedDocumentDM)
	AttachedDocumentOldChild?: TAttachedDocumentDM[]

	/**
	 * Poznámky
	 */
	@Type(() => TOldNoteDM)
	DeprecatedNoteOldChild?: TOldNoteDM[]

	/**
	 * Zařadit do klíče/odstranit z klíče
	 */
	public BulkSortingKeyAddRemoveCOMMAND(parameters: TBulkSortingKeyAddRemoveCOMMANDParams) {
		return this.executeCommand('BulkSortingKeyAddRemoveCOMMAND', parameters)
	}

	/**
	 * Změna platebních podmínek
	 */
	public BulkPayCondAddRemoveCOMMAND(parameters: TBulkPayCondAddRemoveCOMMANDParams) {
		return this.executeCommand('BulkPayCondAddRemoveCOMMAND', parameters)
	}

	/**
	 * Aktualizace DIČ
	 */
	public BulkCheckVATCOMMAND() {
		return this.executeCommand('BulkCheckVATCOMMAND')
	}

	/**
	 * Načíst GPS souřadnice
	 */
	public AddressLoadCoordinatesCOMMAND() {
		return this.executeCommand('AddressLoadCoordinatesCOMMAND')
	}

	/**
	 * Aktualizace Prodlení prodeje
	 */
	public BulkUpdateAvgDelayCOMMAND(parameters: TBulkUpdateAvgDelayCOMMANDParams) {
		return this.executeCommand('BulkUpdateAvgDelayCOMMAND', parameters)
	}

	/**
	 * Zneplatnění
	 */
	public BulkInvalidationCOMMAND() {
		return this.executeCommand('BulkInvalidationCOMMAND')
	}

	/**
	 * Zplatnění
	 */
	public BulkUnInvalidationCOMMAND() {
		return this.executeCommand('BulkUnInvalidationCOMMAND')
	}

	/**
	 * Zneplatnění/Zplatnění
	 */
	public InvalidationCOMMAND() {
		return this.executeCommand('InvalidationCOMMAND')
	}

	/**
	 * Potvrzení
	 */
	public BulkConfirmCOMMAND() {
		return this.executeCommand('BulkConfirmCOMMAND')
	}

	/**
	 * Odpotvrzení
	 */
	public BulkUnconfirmCOMMAND() {
		return this.executeCommand('BulkUnconfirmCOMMAND')
	}

	/**
	 * Změna data potvrzení
	 */
	public BulkChangeConfirmationDateCOMMAND(parameters: TBulkChangeConfirmationDateCOMMANDParams) {
		return this.executeCommand('BulkChangeConfirmationDateCOMMAND', parameters)
	}

	/**
	 * Storno
	 */
	public BulkCancelCOMMAND() {
		return this.executeCommand('BulkCancelCOMMAND')
	}

	/**
	 * Odstorno
	 */
	public BulkUnCancelCOMMAND() {
		return this.executeCommand('BulkUnCancelCOMMAND')
	}

	/**
	 * Potvrzení
	 */
	public ConfirmCOMMAND() {
		return this.executeCommand('ConfirmCOMMAND')
	}

	/**
	 * Odpotvrzení
	 */
	public UnconfirmCOMMAND() {
		return this.executeCommand('UnconfirmCOMMAND')
	}

	/**
	 * Změna data potvrzení
	 */
	public ChangeConfirmationDateCOMMAND(parameters: TChangeConfirmationDateCOMMANDParams) {
		return this.executeCommand('ChangeConfirmationDateCOMMAND', parameters)
	}

	/**
	 * Storno/Odstorno
	 */
	public CancelCOMMAND() {
		return this.executeCommand('CancelCOMMAND')
	}

	/**
	 * Zrušení stavu
	 */
	public ClearRecordStatusCOMMAND() {
		return this.executeCommand('ClearRecordStatusCOMMAND')
	}

	/**
	 * Změna stavu
	 */
	public ChangeRecordStatusCOMMAND() {
		return this.executeCommand('ChangeRecordStatusCOMMAND')
	}

	/**
	 * 
	 */
	public ChangeRecordStatusSWSCOMMAND(parameters: TChangeRecordStatusSWSCOMMANDParams) {
		return this.executeCommand('ChangeRecordStatusSWSCOMMAND', parameters)
	}

	/**
	 * Zrušení stavu
	 */
	public BulkClearRecordStatusCOMMAND() {
		return this.executeCommand('BulkClearRecordStatusCOMMAND')
	}

	/**
	 * Změna stavu
	 */
	public BulkChangeRecordStatusCOMMAND() {
		return this.executeCommand('BulkChangeRecordStatusCOMMAND')
	}

	/**
	 * Změna atributů
	 */
	public BulkChangeAttributesCOMMAND(parameters: TBulkChangeAttributesCOMMANDParams) {
		return this.executeCommand('BulkChangeAttributesCOMMAND', parameters)
	}

	/**
	 * Aktualizace sady atributů
	 */
	public BulkActualizeAttributesCOMMAND(parameters: TBulkActualizeAttributesCOMMANDParams) {
		return this.executeCommand('BulkActualizeAttributesCOMMAND', parameters)
	}

	/**
	 * Aktualizace sady atributů
	 */
	public ActualizeAttributesCOMMAND() {
		return this.executeCommand('ActualizeAttributesCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public ChangeGroupRightCOMMAND() {
		return this.executeCommand('ChangeGroupRightCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public BulkChangeGroupRightCOMMAND() {
		return this.executeCommand('BulkChangeGroupRightCOMMAND')
	}

	/**
	 * Aktualizace počítaných polí
	 */
	public BulkUpdateCalcFieldsCOMMAND() {
		return this.executeCommand('BulkUpdateCalcFieldsCOMMAND')
	}

	/**
	 * Kontrola legislativy
	 */
	public SwitchOffLegislationValidationCOMMAND() {
		return this.executeCommand('SwitchOffLegislationValidationCOMMAND')
	}
}

export type TBulkSortingKeyAddRemoveCOMMANDParams = {
	/**
	 * Produktový klíč
	 */
	SortingProductKey?: number
	/**
	 * Adresní klíč
	 */
	SortingAddressKey?: number
	/**
	 * Volba
	 */
	SortingKeyOptionFiltrRecord?: number
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TBulkPayCondAddRemoveCOMMANDParams = {
	/**
	 * Prodej
	 */
	Sale?: boolean
	/**
	 * Nákup
	 */
	Purchase?: boolean
	/**
	 * Volba
	 */
	PayCondBulkChangeFiltrRecord?: number
	/**
	 * Platba do (dny)
	 */
	DaysIns?: number
	/**
	 * Skonto (%)
	 */
	PercentageIns?: number
	/**
	 * Skonto z netto částky
	 */
	NettoIns?: boolean
	/**
	 * Způsob platby
	 */
	PaymentIns?: string
	/**
	 * Závislá na způsobu platby
	 */
	IndipenIns?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TBulkUpdateAvgDelayCOMMANDParams = {
	/**
	 * Datum
	 */
	Value?: Date
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TBulkChangeConfirmationDateCOMMANDParams = {
	/**
	 * Datum potvrzení
	 */
	Confirmation1Date?: Date
	/**
	 * Hodina potvrzení
	 */
	Confirmation1Time?: Date
	/**
	 * Datum 2. potvrzení
	 */
	Confirmation2Date?: Date
	/**
	 * Hodina 2. potvrzení
	 */
	Confirmation2Time?: Date
	/**
	 * Kontrolovat záporné stavy
	 */
	CheckNegativeState?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TChangeConfirmationDateCOMMANDParams = {
	/**
	 * Datum potvrzení
	 */
	Confirmation1Date?: Date
	/**
	 * Hodina potvrzení
	 */
	Confirmation1Time?: Date
	/**
	 * Datum 2. potvrzení
	 */
	Confirmation2Date?: Date
	/**
	 * Hodina 2. potvrzení
	 */
	Confirmation2Time?: Date
	/**
	 * Kontrolovat záporné stavy
	 */
	CheckNegativeState?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TChangeRecordStatusSWSCOMMANDParams = {
	/**
	 * StatusId
	 */
	StatusId?: number
}

export type TBulkChangeAttributesCOMMANDParams = {
	/**
	 * Smazat atributy
	 */
	DeleteAttribute?: boolean
	/**
	 * Smazat všechny volné atributy
	 */
	DeleteFreeAttribute?: boolean
	/**
	 * Nastavit hodnoty atributů
	 */
	SetValues?: boolean
	/**
	 * Přidat nepřipojené atributy
	 */
	AddFreeAttributes?: boolean
	/**
	 * FreeAttributeEnabled
	 */
	FreeAttributeEnabled?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TBulkActualizeAttributesCOMMANDParams = {
	/**
	 * Aktualizovat včetně samotné sady atributů
	 */
	ActualizeAttributeSet?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}