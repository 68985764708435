import Data from 'react-k2-api/Api/Data'
import { Type } from 'class-transformer'
import TExternalDocumentDM from './TExternalDocumentDM'
import TTextDM from './TTextDM'

/**
 * Dokumenty
 */
export default class TExternalDocumentItemDM extends Data {
	public static className = 'TExternalDocumentItemDM'
	public static systemFields = [
		'RecordID',
	]

	public static isHeader = false

	/**
	 * Node
	 */
	NodeId?: number

	/**
	 * Unik
	 */
	Id?: number

	/**
	 * Č. E_
	 */
	FileModuleNumber?: number

	/**
	 * Č. Dm
	 */
	DataModuleNumber?: number

	/**
	 * Č. dokl.
	 */
	DocumentNumber?: number

	/**
	 * Kniha
	 */
	Book?: string

	/**
	 * Typ dokl.
	 */
	TypeId?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * Č. doku.
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * EX_ES_File_Dalsi_E3
	 */
	EX_ES_File_Dalsi_E3?: number

	/**
	 * EX_ES_File_Zmena_E3
	 */
	EX_ES_File_Zmena_E3?: Date

	/**
	 * EX_ES_File_Size_E3
	 */
	EX_ES_File_Size_E3?: number

	/**
	 * EX_ES_File_Dalsi
	 */
	EX_ES_File_Dalsi?: number

	/**
	 * EX_ES_File_Zmena
	 */
	EX_ES_File_Zmena?: Date

	/**
	 * EX_ES_File_Size
	 */
	EX_ES_File_Size?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * undefined
	 */
	ExternalDocumentFileCalc?: string

	/**
	 * undefined
	 */
	DataModuleNameCalc?: string

	/**
	 * undefined
	 */
	DocumentIdentifierCalc?: string

	/**
	 * undefined
	 */
	DocumentDescriptionCalc?: string

	/**
	 * undefined
	 */
	K2PictureAbbrCalc?: string

	/**
	 * Zkratka dokumentu
	 */
	AbbrCalc?: string

	/**
	 * undefined
	 */
	IsMyRevisionCalc?: string

	/**
	 * undefined
	 */
	IsRevisionCalc?: string

	/**
	 * undefined
	 */
	IsDMSCalc?: string

	/**
	 * Typ dokumentu
	 */
	TypeCalc?: string

	/**
	 * Název dokumentu
	 */
	NameCalc?: string

	/**
	 * Č. doku.
	 */
	@Type(() => TExternalDocumentDM)
	DocumentId?: TExternalDocumentDM

	/**
	 * undefined
	 */
	@Type(() => TTextDM)
	ExternalDocumentStateIdCalc?: TTextDM

	/**
	 * undefined
	 */
	@Type(() => TTextDM)
	DMSStateIdCalc?: TTextDM
}