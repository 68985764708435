import Data from 'react-k2-api/Api/Data'
import { Type } from 'class-transformer'
import TK2UserLookupDM from './TK2UserLookupDM'
import TCompanyDM from './TCompanyDM'

/**
 * Firmy
 */
export default class TCompanyLinkProcedureHeaderDM extends Data {
	public static className = 'TCompanyLinkProcedureHeaderDM'
	public static systemFields = [
		'RecordID',
	]

	public static isHeader = false

	/**
	 * Valid
	 */
	IsValid?: string

	/**
	 * Změněno
	 */
	ChangedOn?: Date

	/**
	 * RecID
	 */
	RecordNumber?: number

	/**
	 * TableId
	 */
	TableId?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * undefined
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * undefined
	 */
	IconCalc?: number

	/**
	 * Změnil
	 */
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM

	/**
	 * CompanyId
	 */
	@Type(() => TCompanyDM)
	CompanyId?: TCompanyDM
}