import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TEshopDeliveryDM from './TEshopDeliveryDM'
import TPartnerDM from './TPartnerDM'
import TDiscountCouponDM from './TDiscountCouponDM'
import TEshopDM from './TEshopDM'
import TContactPersonDM from './TContactPersonDM'
import TTextDM from './TTextDM'
import TMarketingActivityTypeDM from './TMarketingActivityTypeDM'
import TCommentDM from './TCommentDM'
import TEshopBasketItemDM from './TEshopBasketItemDM'
import TEshopBasketArticleDM from './TEshopBasketArticleDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TAttachedDocumentDM from './TAttachedDocumentDM'
import TOldNoteDM from './TOldNoteDM'

/**
 * Košíky
 */
export default class TEshopBasketDM extends Data {
	public static className = 'TEshopBasketDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = true

	/**
	 * Fakturační adresa e-mail
	 */
	InvoiceAddressEmail?: string

	/**
	 * Dodací adresa e-mail
	 */
	DeliveryAddressEmail?: string

	/**
	 * Číslo Alza zakázky
	 */
	AlzaOrderNumber?: string

	/**
	 * Odběrné místo
	 */
	PickUpPointId?: string

	/**
	 * Měna objednávky
	 */
	CurrencyCode?: string

	/**
	 * WebData
	 */
	WebData?: string

	/**
	 * Počet zboží v košíku
	 */
	Quantity?: number

	/**
	 * Rozdíl při zaokrouhlení
	 */
	PriceDifference?: number

	/**
	 * Cena brutto
	 */
	PriceGross?: number

	/**
	 * Cena netto
	 */
	PriceNet?: number

	/**
	 * Dodací adresa název
	 */
	DeliveryAddressCompany?: string

	/**
	 * Datum objednání
	 */
	OrderedDate?: Date

	/**
	 * Pobočka odběru
	 */
	TransportBranchId?: number

	/**
	 * Způsob platby
	 */
	PaymentId?: number

	/**
	 * Dodací adresa daňové číslo
	 */
	DeliveryAddressTaxId?: string

	/**
	 * Dodací adresa IČ
	 */
	DeliveryAddressCompanyRegNo?: string

	/**
	 * Dodací adresa DIČ
	 */
	DeliveryAddressVATRegNo?: string

	/**
	 * Dodací adresa stát
	 */
	DeliveryAddressCountryId?: number

	/**
	 * Dodací adresa PSČ
	 */
	DeliveryAddressZIP?: string

	/**
	 * Dodací adresa město
	 */
	DeliveryAddressCity?: string

	/**
	 * Dodací adresa ulice
	 */
	DeliveryAddressStreet?: string

	/**
	 * Dodací adresa telefon
	 */
	DeliveryAddressPhone?: string

	/**
	 * Dodací adresa komentář
	 */
	DeliveryAddressComment?: string

	/**
	 * Dodací adresa
	 */
	DeliveryAddressRID?: number

	/**
	 * Fakturační adresa název
	 */
	InvoiceAddressCompany?: string

	/**
	 * Fakturační adresa stát
	 */
	InvoiceAddressCountryId?: number

	/**
	 * Fakturační adresa PSČ
	 */
	InvoiceAddressZIP?: string

	/**
	 * Fakturační adresa město
	 */
	InvoiceAddressCity?: string

	/**
	 * Fakturační adresa telefon
	 */
	InvoiceAddressPhone?: string

	/**
	 * Fakturační adresa komentář
	 */
	InvoiceAddressComment?: string

	/**
	 * IP adresa
	 */
	IPAddress?: string

	/**
	 * Zakázka
	 */
	ContractRID?: number

	/**
	 * Sdílený
	 */
	IsShared?: string

	/**
	 * Oblíbený
	 */
	IsFavorite?: string

	/**
	 * Nabídkový
	 */
	IsForQuotation?: string

	/**
	 * Dodatečné info.
	 */
	AdditionalInfo?: string

	/**
	 * Objednáno
	 */
	IsOrdered?: string

	/**
	 * Název košíku
	 */
	Name?: string

	/**
	 * Časové razítko
	 */
	TimeStamp?: string

	/**
	 * Fakturační adresa daňové číslo
	 */
	InvoiceAddressTaxId?: string

	/**
	 * Fakturační adresa IČ
	 */
	InvoiceAddressCompanyRegNo?: string

	/**
	 * Fakturační adresa DIČ
	 */
	InvoiceAddressVATRegNo?: string

	/**
	 * Fakturační adresa
	 */
	InvoiceAddressRID?: number

	/**
	 * Nabídka
	 */
	QuotationRID?: number

	/**
	 * Stav platby
	 */
	PaymentStatusId?: number

	/**
	 * Stav
	 */
	OrderStatusId?: number

	/**
	 * ID transakce
	 */
	TransactionId?: string

	/**
	 * Jazyk
	 */
	LanguageId?: number

	/**
	 * Telefon
	 */
	ContactPhone?: string

	/**
	 * E-mail
	 */
	ContactEmail?: string

	/**
	 * Příjmení
	 */
	ContactLastName?: string

	/**
	 * Jméno
	 */
	ContactFirstName?: string

	/**
	 * Fakturační adresa ulice
	 */
	InvoiceAddressStreet?: string

	/**
	 * IPv6 adresa
	 */
	IPv6Address?: string

	/**
	 * Koncové datum
	 */
	EndDate?: Date

	/**
	 * Počáteční datum
	 */
	StartDate?: Date

	/**
	 * Session
	 */
	Session?: string

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * undefined
	 */
	EncodedRIDCalc?: string

	/**
	 * Netto
	 */
	TotalPriceNetCalc?: number

	/**
	 * Brutto
	 */
	TotalPriceGrossCalc?: number

	/**
	 * undefined
	 */
	ItemsCountCalc?: number

	/**
	 * undefined
	 */
	CouponErrorCalc?: string

	/**
	 * undefined
	 */
	CouponPriceNetCalc?: number

	/**
	 * undefined
	 */
	CouponPriceGrossCalc?: number

	/**
	 * undefined
	 */
	CouponCodeCalc?: string

	/**
	 * undefined
	 */
	ShopCurrencyExchangeRateCalc?: number

	/**
	 * undefined
	 */
	ShopCurrencyCalc?: string

	/**
	 * Netto po slevě
	 */
	ReducedTotalPriceNetCalc?: number

	/**
	 * Brutto po slevě
	 */
	ReducedTotalPriceGrossCalc?: number

	/**
	 * undefined
	 */
	TotalQuantityCalc?: number

	/**
	 * undefined
	 */
	TotalPromotionPriceCalc?: number

	/**
	 * undefined
	 */
	CombPromotionTotalPriceCalc?: number

	/**
	 * undefined
	 */
	LastAddedArticleIdCalc?: number

	/**
	 * undefined
	 */
	LastAddedQuantityCalc?: number

	/**
	 * undefined
	 */
	LastAddedPriceNetCalc?: number

	/**
	 * undefined
	 */
	LastAddedPriceGrossCalc?: number

	/**
	 * undefined
	 */
	PromotionTotalPriceGrossCalc?: number

	/**
	 * undefined
	 */
	CombPromotionTotalPriceGrossCalc?: number

	/**
	 * undefined
	 */
	PromotionTotalPriceNetCalc?: number

	/**
	 * undefined
	 */
	CombPromotionTotalPriceNetCalc?: number

	/**
	 * undefined
	 */
	PromotionDiscountCalc?: number

	/**
	 * undefined
	 */
	EshopLegislationIdCalc?: number

	/**
	 * undefined
	 */
	DelivCountryLegislationIdCalc?: number

	/**
	 * undefined
	 */
	TextHeaderMemoCalc?: string

	/**
	 * undefined
	 */
	TextFooterMemoCalc?: string

	/**
	 * Poslední komentář
	 */
	LastCommentParamCalc?: string

	/**
	 * Zp. dopravy
	 */
	@Type(() => TEshopDeliveryDM)
	DeliveryRID?: TEshopDeliveryDM

	/**
	 * Partner
	 */
	@Type(() => TPartnerDM)
	PartnerId?: TPartnerDM

	/**
	 * Kupón
	 */
	@Type(() => TDiscountCouponDM)
	CouponRID?: TDiscountCouponDM

	/**
	 * E-shop
	 */
	@Type(() => TEshopDM)
	ShopRID?: TEshopDM

	/**
	 * Kontaktní osoba
	 */
	@Type(() => TContactPersonDM)
	ContactPersonId?: TContactPersonDM

	/**
	 * Z
	 */
	@Type(() => TTextDM)
	InvalidRecordImgCalc?: TTextDM

	/**
	 * D
	 */
	@Type(() => TTextDM)
	DocumentExistsCalc?: TTextDM

	/**
	 * undefined
	 */
	@Type(() => TMarketingActivityTypeDM)
	ActivityTypeRIDCalc?: TMarketingActivityTypeDM

	/**
	 * Komentáře
	 */
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]

	/**
	 * Položky košíku
	 */
	@Type(() => TEshopBasketItemDM)
	ItemChild?: TEshopBasketItemDM[]

	/**
	 * undefined
	 */
	@Type(() => TEshopBasketArticleDM)
	ArticleChild?: TEshopBasketArticleDM[]

	/**
	 * Text hlavičky
	 */
	@Type(() => TLanguageMemoTranslationDM)
	TextHeaderChild?: TLanguageMemoTranslationDM[]

	/**
	 * Text patičky
	 */
	@Type(() => TLanguageMemoTranslationDM)
	TextFooterChild?: TLanguageMemoTranslationDM[]

	/**
	 * Svázané doklady
	 */
	@Type(() => TAttachedDocumentDM)
	AttachedDocumentOldChild?: TAttachedDocumentDM[]

	/**
	 * Poznámky
	 */
	@Type(() => TOldNoteDM)
	DeprecatedNoteOldChild?: TOldNoteDM[]

	/**
	 * Zneplatnění
	 */
	public BulkInvalidationCOMMAND() {
		return this.executeCommand('BulkInvalidationCOMMAND')
	}

	/**
	 * Zplatnění
	 */
	public BulkUnInvalidationCOMMAND() {
		return this.executeCommand('BulkUnInvalidationCOMMAND')
	}

	/**
	 * Zneplatnění/Zplatnění
	 */
	public InvalidationCOMMAND() {
		return this.executeCommand('InvalidationCOMMAND')
	}

	/**
	 * Potvrzení
	 */
	public BulkConfirmCOMMAND() {
		return this.executeCommand('BulkConfirmCOMMAND')
	}

	/**
	 * Odpotvrzení
	 */
	public BulkUnconfirmCOMMAND() {
		return this.executeCommand('BulkUnconfirmCOMMAND')
	}

	/**
	 * Změna data potvrzení
	 */
	public BulkChangeConfirmationDateCOMMAND(parameters: TBulkChangeConfirmationDateCOMMANDParams) {
		return this.executeCommand('BulkChangeConfirmationDateCOMMAND', parameters)
	}

	/**
	 * Storno
	 */
	public BulkCancelCOMMAND() {
		return this.executeCommand('BulkCancelCOMMAND')
	}

	/**
	 * Odstorno
	 */
	public BulkUnCancelCOMMAND() {
		return this.executeCommand('BulkUnCancelCOMMAND')
	}

	/**
	 * Potvrzení
	 */
	public ConfirmCOMMAND() {
		return this.executeCommand('ConfirmCOMMAND')
	}

	/**
	 * Odpotvrzení
	 */
	public UnconfirmCOMMAND() {
		return this.executeCommand('UnconfirmCOMMAND')
	}

	/**
	 * Změna data potvrzení
	 */
	public ChangeConfirmationDateCOMMAND(parameters: TChangeConfirmationDateCOMMANDParams) {
		return this.executeCommand('ChangeConfirmationDateCOMMAND', parameters)
	}

	/**
	 * Storno/Odstorno
	 */
	public CancelCOMMAND() {
		return this.executeCommand('CancelCOMMAND')
	}

	/**
	 * Zrušení stavu
	 */
	public ClearRecordStatusCOMMAND() {
		return this.executeCommand('ClearRecordStatusCOMMAND')
	}

	/**
	 * Změna stavu
	 */
	public ChangeRecordStatusCOMMAND() {
		return this.executeCommand('ChangeRecordStatusCOMMAND')
	}

	/**
	 * 
	 */
	public ChangeRecordStatusSWSCOMMAND(parameters: TChangeRecordStatusSWSCOMMANDParams) {
		return this.executeCommand('ChangeRecordStatusSWSCOMMAND', parameters)
	}

	/**
	 * Zrušení stavu
	 */
	public BulkClearRecordStatusCOMMAND() {
		return this.executeCommand('BulkClearRecordStatusCOMMAND')
	}

	/**
	 * Změna stavu
	 */
	public BulkChangeRecordStatusCOMMAND() {
		return this.executeCommand('BulkChangeRecordStatusCOMMAND')
	}

	/**
	 * Změna atributů
	 */
	public BulkChangeAttributesCOMMAND(parameters: TBulkChangeAttributesCOMMANDParams) {
		return this.executeCommand('BulkChangeAttributesCOMMAND', parameters)
	}

	/**
	 * Aktualizace sady atributů
	 */
	public BulkActualizeAttributesCOMMAND(parameters: TBulkActualizeAttributesCOMMANDParams) {
		return this.executeCommand('BulkActualizeAttributesCOMMAND', parameters)
	}

	/**
	 * Aktualizace sady atributů
	 */
	public ActualizeAttributesCOMMAND() {
		return this.executeCommand('ActualizeAttributesCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public ChangeGroupRightCOMMAND() {
		return this.executeCommand('ChangeGroupRightCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public BulkChangeGroupRightCOMMAND() {
		return this.executeCommand('BulkChangeGroupRightCOMMAND')
	}

	/**
	 * Aktualizace počítaných polí
	 */
	public BulkUpdateCalcFieldsCOMMAND() {
		return this.executeCommand('BulkUpdateCalcFieldsCOMMAND')
	}

	/**
	 * Kontrola legislativy
	 */
	public SwitchOffLegislationValidationCOMMAND() {
		return this.executeCommand('SwitchOffLegislationValidationCOMMAND')
	}
}

export type TBulkChangeConfirmationDateCOMMANDParams = {
	/**
	 * Datum potvrzení
	 */
	Confirmation1Date?: Date
	/**
	 * Hodina potvrzení
	 */
	Confirmation1Time?: Date
	/**
	 * Datum 2. potvrzení
	 */
	Confirmation2Date?: Date
	/**
	 * Hodina 2. potvrzení
	 */
	Confirmation2Time?: Date
	/**
	 * Kontrolovat záporné stavy
	 */
	CheckNegativeState?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TChangeConfirmationDateCOMMANDParams = {
	/**
	 * Datum potvrzení
	 */
	Confirmation1Date?: Date
	/**
	 * Hodina potvrzení
	 */
	Confirmation1Time?: Date
	/**
	 * Datum 2. potvrzení
	 */
	Confirmation2Date?: Date
	/**
	 * Hodina 2. potvrzení
	 */
	Confirmation2Time?: Date
	/**
	 * Kontrolovat záporné stavy
	 */
	CheckNegativeState?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TChangeRecordStatusSWSCOMMANDParams = {
	/**
	 * StatusId
	 */
	StatusId?: number
}

export type TBulkChangeAttributesCOMMANDParams = {
	/**
	 * Smazat atributy
	 */
	DeleteAttribute?: boolean
	/**
	 * Smazat všechny volné atributy
	 */
	DeleteFreeAttribute?: boolean
	/**
	 * Nastavit hodnoty atributů
	 */
	SetValues?: boolean
	/**
	 * Přidat nepřipojené atributy
	 */
	AddFreeAttributes?: boolean
	/**
	 * FreeAttributeEnabled
	 */
	FreeAttributeEnabled?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TBulkActualizeAttributesCOMMANDParams = {
	/**
	 * Aktualizovat včetně samotné sady atributů
	 */
	ActualizeAttributeSet?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}