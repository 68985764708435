import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TRoutingVariantOperationDM from './TRoutingVariantOperationDM'
import TArticleCodeDocumentDM from './TArticleCodeDocumentDM'
import TCostCentreCollectionDocumentDM from './TCostCentreCollectionDocumentDM'
import TCode2DocumentDM from './TCode2DocumentDM'
import TCode1DocumentDM from './TCode1DocumentDM'
import TTextDM from './TTextDM'
import TRequestedBatchParameterHeaderDM from './TRequestedBatchParameterHeaderDM'
import TK2UserLookupDM from './TK2UserLookupDM'
import TRoutingVariantDM from './TRoutingVariantDM'
import TWarehouseDM from './TWarehouseDM'
import GoodsName from './GoodsName'
import TMeasureUnitDM from './TMeasureUnitDM'
import TArticleDM from './TArticleDM'

/**
 * TRoutingVariantItemDataFilterDM
 */
export default class TRoutingVariantItemDataFilterDM extends Data {
	public static className = 'TRoutingVariantItemDataFilterDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = false

	/**
	 * Doplněk
	 */
	IsSupplement?: string

	/**
	 * Násobit množství
	 */
	MultiplyQuantity?: string

	/**
	 * Předef. cenu
	 */
	IsRedefinedPrice?: string

	/**
	 * Zdroj TP
	 */
	RoutingResourceRID?: number

	/**
	 * Číslo
	 */
	RoutingId?: number

	/**
	 * Typ TP
	 */
	RoutingTypeId?: number

	/**
	 * Č. nadř. položky
	 */
	RoutingMainItemRID?: number

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * Odpad (%)
	 */
	Scrap?: number

	/**
	 * Změněno
	 */
	ChangedOn?: Date

	/**
	 * Výkres
	 */
	Drawing?: string

	/**
	 * Pozice
	 */
	Position?: string

	/**
	 * Skl. cena
	 */
	StockPrice?: number

	/**
	 * Množství
	 */
	Quantity?: number

	/**
	 * Pol.
	 */
	ItemId?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Z
	 */
	SubstitutionOrSupplementCalc?: string

	/**
	 * Kód při zad.
	 */
	CodeDescriptionCalc?: string

	/**
	 * DPH při zad.
	 */
	VATDescriptionCalc?: string

	/**
	 * Množství brutto
	 */
	QuantityGrossCalc?: number

	/**
	 * Cena / MJ
	 */
	RedefUnitPriceCalc?: number

	/**
	 * Cena celkem
	 */
	RedefTotalPriceGrossCalc?: number

	/**
	 * Název
	 */
	NameCalc?: string

	/**
	 * Skl. cena ze zb.
	 */
	StockPriceCalc?: number

	/**
	 * Cena / MJ
	 */
	StockUnitPriceCalc?: number

	/**
	 * Cena celkem brutto
	 */
	StockTotalPriceGrossCalc?: number

	/**
	 * Čas
	 */
	TimeCalc?: number

	/**
	 * Množ*Čas
	 */
	TimeQuantityCalc?: number

	/**
	 * Množství
	 */
	PositiveQuantityGrossCalc?: number

	/**
	 * D
	 */
	QuantitySignBitCalc?: number

	/**
	 * D
	 */
	QuantitySignImgCalc?: string

	/**
	 * Název celý
	 */
	FullNameCalc?: string

	/**
	 * Nezáv. oper.
	 */
	IndependentOperationBitCalc?: number

	/**
	 * Skl. cena pol.
	 */
	PriceCalc?: number

	/**
	 * Cena / MJ
	 */
	UnitPriceCalc?: number

	/**
	 * Cena celkem
	 */
	TotalPriceCalc?: number

	/**
	 * Množství netto
	 */
	QuantityNetCalc?: number

	/**
	 * Množství netto
	 */
	QuantityNetUMCalc?: number

	/**
	 * Cena celkem netto
	 */
	TotalStockPriceNetCalc?: number

	/**
	 * undefined
	 */
	RedefTotalPriceNetCalc?: number

	/**
	 * undefined
	 */
	PositiveQuantityNetCalc?: number

	/**
	 * undefined
	 */
	BOMTypeCalc?: number

	/**
	 * Čárový kód
	 */
	BarcodeCalc?: string

	/**
	 * undefined
	 */
	IsSubstitutionCalc?: string

	/**
	 * RID oper.
	 */
	@Type(() => TRoutingVariantOperationDM)
	RoutingOperationRID?: TRoutingVariantOperationDM

	/**
	 * Kód zboží
	 */
	@Type(() => TArticleCodeDocumentDM)
	ArticleCodeId?: TArticleCodeDocumentDM

	/**
	 * Středisko
	 */
	@Type(() => TCostCentreCollectionDocumentDM)
	CostCentreId?: TCostCentreCollectionDocumentDM

	/**
	 * Kód 2
	 */
	@Type(() => TCode2DocumentDM)
	Code2Id?: TCode2DocumentDM

	/**
	 * Kód 1
	 */
	@Type(() => TCode1DocumentDM)
	Code1Id?: TCode1DocumentDM

	/**
	 * Čas operace
	 */
	@Type(() => TTextDM)
	OperationTime?: TTextDM

	/**
	 * Pož. vlastnosti
	 */
	@Type(() => TRequestedBatchParameterHeaderDM)
	RequestedBatchParametersId?: TRequestedBatchParameterHeaderDM

	/**
	 * Změnil
	 */
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM

	/**
	 * Varianta p.
	 */
	@Type(() => TRoutingVariantDM)
	RoutingVariantId?: TRoutingVariantDM

	/**
	 * Číslo skladu
	 */
	@Type(() => TWarehouseDM)
	WarehouseId?: TWarehouseDM

	/**
	 * Název
	 */
	@Type(() => GoodsName)
	ArticleNameId?: GoodsName

	/**
	 * Skladová jednotka
	 */
	@Type(() => TMeasureUnitDM)
	MeasureUnitId?: TMeasureUnitDM

	/**
	 * Č. podř. zboží
	 */
	@Type(() => TArticleDM)
	ArticleId?: TArticleDM

	/**
	 * Č. podř. zboží
	 */
	@Type(() => TArticleDM)
	ArticleByIdInputIdCalc?: TArticleDM

	/**
	 * Č. podř. zboží
	 */
	@Type(() => TArticleDM)
	ArticleByAbbr2InputIdCalc?: TArticleDM

	/**
	 * Č. podř. zboží
	 */
	@Type(() => TArticleDM)
	ArticleByNameInputIdCalc?: TArticleDM
}