import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TCachedBookDM from './TCachedBookDM'

/**
 * Konfigurace pokrytí
 */
export default class TCoverageConfigurationDM extends Data {
	public static className = 'TCoverageConfigurationDM'
	public static primaryKey = 'GUID'
	public static systemFields = [
	]

	public getPrimaryKey(): PrimaryKey {
		return this.GUID!
	}

	public static isHeader = true

	/**
	 * GUID
	 */
	GUID?: string

	/**
	 * Zkratka
	 */
	Abbr?: string

	/**
	 * Popis
	 */
	Descr?: string

	/**
	 * Počet dní dopředu od aktuálního data
	 */
	DaysCount?: number

	/**
	 * undefined
	 */
	LoadIgnoreSale?: number

	/**
	 * undefined
	 */
	LoadIgnorePurchase?: number

	/**
	 * undefined
	 */
	LoadIgnoreJC?: number

	/**
	 * undefined
	 */
	LoadIgnoreTN?: number

	/**
	 * undefined
	 */
	LoadIgnoreCoverageType?: number

	/**
	 * Výčet typů pokrytí
	 */
	CoverageTypes?: string

	/**
	 * Zobrazovat hodnoty dobropisů
	 */
	ShowCreditNotes?: string

	/**
	 * Výčet parametrů
	 */
	IncludeParameters?: string

	/**
	 * Výčet firem
	 */
	OwnCompanies?: string

	/**
	 * Time Stamp
	 */
	TimeStamp?: Date

	/**
	 * CollectionItem
	 */
	CollectionItem?: number

	/**
	 * Výčet knih prodeje
	 */
	@Type(() => TCachedBookDM)
	BooksOfSale?: TCachedBookDM

	/**
	 * Výčet knih nákupu
	 */
	@Type(() => TCachedBookDM)
	BooksOfPurchase?: TCachedBookDM

	/**
	 * Výčet knih průvodek
	 */
	@Type(() => TCachedBookDM)
	BooksOfJC?: TCachedBookDM

	/**
	 * Výčet knih převodek
	 */
	@Type(() => TCachedBookDM)
	BooksOfTN?: TCachedBookDM
}