import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TK2UserLookupDM from './TK2UserLookupDM'
import TAddressDM from './TAddressDM'
import TTextDM from './TTextDM'
import TEmployedPersonDM from './TEmployedPersonDM'
import TTownDM from './TTownDM'
import TTownPartDM from './TTownPartDM'
import TCountryDM from './TCountryDM'
import TContactPersonDM from './TContactPersonDM'
import TCommentDM from './TCommentDM'
import TItemNoteDM from './TItemNoteDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TExternalDocumentItemDM from './TExternalDocumentItemDM'

/**
 * Rodinní příslušníci
 */
export default class TFamilyDM extends Data {
	public static className = 'TFamilyDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = true

	/**
	 * Skupina práv
	 */
	RightGroupId?: number

	/**
	 * Časové razítko
	 */
	TimeStamp?: string

	/**
	 * Změněno
	 */
	ChangedOn?: Date

	/**
	 * Vytvořeno
	 */
	CreatedOn?: Date

	/**
	 * XMLField
	 */
	XMLField?: string

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * Pořadí dítěte
	 */
	ChildSequenceNumber?: number

	/**
	 * NewFields
	 */
	NewFieldId?: string

	/**
	 * Datum do
	 */
	DateTo?: Date

	/**
	 * Datum od
	 */
	DateFrom?: Date

	/**
	 * DanD
	 */
	TaxInfo?: string

	/**
	 * RPZO
	 */
	InSameCompany?: string

	/**
	 * CFax
	 */
	FaxNumber?: string

	/**
	 * Telefon
	 */
	PhoneNumber?: string

	/**
	 * Datum narození
	 */
	BirthDate?: Date

	/**
	 * Příjmení
	 */
	Surname?: string

	/**
	 * Jméno
	 */
	FirstName?: string

	/**
	 * Pořadí
	 */
	SequenceNumber?: number

	/**
	 * Invalidita
	 */
	Disability?: string

	/**
	 * NazZ
	 */
	EmployerName?: string

	/**
	 * Rodné číslo
	 */
	PersonalId?: string

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * Souřadnice (Adresa)
	 */
	AddressIdCoordinates?: string

	/**
	 * PSČ (Adresa)
	 */
	AddressIdTownCode?: string

	/**
	 * undefined
	 */
	AddressIdChanged?: number

	/**
	 * Název (Adresa)
	 */
	AddressIdName?: string

	/**
	 * Číslo popisné (Adresa)
	 */
	AddressIdHouseNumber?: string

	/**
	 * Číslo orientační (Adresa)
	 */
	AddressIdOrientationNumber?: string

	/**
	 * Ulice (Adresa)
	 */
	AddressIdStreet?: string

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Rodné
	 */
	BirthIdNumberPart1Calc?: string

	/**
	 * číslo
	 */
	BirthIdNumberPart2Calc?: string

	/**
	 * Datum úmrtí
	 */
	DeathDateCalc?: Date

	/**
	 * Jméno
	 */
	FullNameCalc?: string

	/**
	 * Vyživovaná osoba
	 */
	DependentPersonBitCalc?: number

	/**
	 * Průkaz ZTP/P
	 */
	PSDCardIdCalc?: number

	/**
	 * Zaměstnán ve stejné organizaci
	 */
	SameCompanyBitCalc?: number

	/**
	 * Věk
	 */
	AgeCalc?: number

	/**
	 * Poslední komentář
	 */
	LastCommentParamCalc?: string

	/**
	 * Skupina práv
	 */
	RightGroupIdCalc?: number

	/**
	 * Změnil
	 */
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM

	/**
	 * Vytvořil
	 */
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM

	/**
	 * Adresa
	 */
	@Type(() => TAddressDM)
	AddressId?: TAddressDM

	/**
	 * Vztah
	 */
	@Type(() => TTextDM)
	Relationship?: TTextDM

	/**
	 * Pracovník
	 */
	@Type(() => TEmployedPersonDM)
	PersonId?: TEmployedPersonDM

	/**
	 * Číslo (Adresa)
	 */
	@Type(() => TTownDM)
	AddressIdTownId?: TTownDM

	/**
	 * Část obce (Adresa)
	 */
	@Type(() => TTownPartDM)
	AddressIdTownPartId?: TTownPartDM

	/**
	 * Stát (Adresa)
	 */
	@Type(() => TCountryDM)
	AddressIdCountryId?: TCountryDM

	/**
	 * Kontaktní osoba
	 */
	@Type(() => TContactPersonDM)
	ContactPersonIdCalc?: TContactPersonDM

	/**
	 * Komentáře
	 */
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]

	/**
	 * undefined
	 */
	@Type(() => TItemNoteDM)
	ItemNotesChild?: TItemNoteDM[]

	/**
	 * undefined
	 */
	@Type(() => TLanguageMemoTranslationDM)
	ItemTextChild?: TLanguageMemoTranslationDM[]

	/**
	 * Dokumenty
	 */
	@Type(() => TExternalDocumentItemDM)
	ExternalDocumentItemChild?: TExternalDocumentItemDM[]

	/**
	 * Změna skupiny práv
	 */
	public ChangeGroupRightCOMMAND() {
		return this.executeCommand('ChangeGroupRightCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public BulkChangeGroupRightCOMMAND() {
		return this.executeCommand('BulkChangeGroupRightCOMMAND')
	}

	/**
	 * Aktualizace počítaných polí
	 */
	public BulkUpdateCalcFieldsCOMMAND() {
		return this.executeCommand('BulkUpdateCalcFieldsCOMMAND')
	}

	/**
	 * Kontrola legislativy
	 */
	public SwitchOffLegislationValidationCOMMAND() {
		return this.executeCommand('SwitchOffLegislationValidationCOMMAND')
	}
}