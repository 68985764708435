import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TK2UserLookupDM from './TK2UserLookupDM'
import TCode6DocumentDM from './TCode6DocumentDM'
import TCode5DocumentDM from './TCode5DocumentDM'
import TCode4DocumentDM from './TCode4DocumentDM'
import TCode3DocumentDM from './TCode3DocumentDM'
import TDeviceDocumentDM from './TDeviceDocumentDM'
import TOrgStrJobDM from './TOrgStrJobDM'
import TEmployedPersonSimpleDM from './TEmployedPersonSimpleDM'
import TCode2DocumentDM from './TCode2DocumentDM'
import TCode1DocumentDM from './TCode1DocumentDM'
import TArticleCodeDocumentDM from './TArticleCodeDocumentDM'
import TContractCodeDocumentDM from './TContractCodeDocumentDM'
import TCostCentreCollectionDocumentDM from './TCostCentreCollectionDocumentDM'
import OrgTreeUnit from './OrgTreeUnit'
import TOrgStrTreeDM from './TOrgStrTreeDM'
import TOrgStrDeputyDM from './TOrgStrDeputyDM'
import TOrgStrEmployeeDM from './TOrgStrEmployeeDM'
import TPrEmploymentDM from './TPrEmploymentDM'

/**
 * Organizační jednotka
 */
export default class TOrgStrTreeUnitDM extends Data {
	public static className = 'TOrgStrTreeUnitDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = true

	/**
	 * Skupina práv
	 */
	RightGroupId?: number

	/**
	 * Časové razítko
	 */
	TimeStamp?: string

	/**
	 * Změněno
	 */
	ChangedOn?: Date

	/**
	 * Vytvořeno
	 */
	CreatedOn?: Date

	/**
	 * Neplatná jednotka
	 */
	IsInvalidRecord?: string

	/**
	 * Plánovaný úvazek
	 */
	PlannedTime?: number

	/**
	 * Plánovaný počet
	 */
	PlannedCount?: number

	/**
	 * Název
	 */
	Name?: string

	/**
	 * Zkratka
	 */
	Abbr?: string

	/**
	 * Pořadí
	 */
	SequenceNumber?: number

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Úroveň
	 */
	LevelIdCalc?: number

	/**
	 * Počet položek (jednotek)
	 */
	MeasureUnitItemsCountCalc?: number

	/**
	 * Počet položek (jednotek) nadřízené jednotky
	 */
	SuperiorUnitItemCountCalc?: number

	/**
	 * Pořadí v rámci nadřízené jednotky
	 */
	SequenceNumberInSuperiorUnitCalc?: number

	/**
	 * Poslední jednotka v rámci nadřízené jednotky
	 */
	IsLastItemInSuperiorUnitCalc?: string

	/**
	 * Skutečný počet
	 */
	ActualCountCalc?: number

	/**
	 * Rozdíl
	 */
	DifferenceCalc?: number

	/**
	 * K datu
	 */
	ActualValidToDateCalc?: Date

	/**
	 * Zobrazit neplatné obsazené pracovníky
	 */
	ShowInvalidEmployeeCalc?: string

	/**
	 * Skupina práv
	 */
	RightGroupIdCalc?: number

	/**
	 * Změnil
	 */
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM

	/**
	 * Vytvořil
	 */
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM

	/**
	 * Kód 6
	 */
	@Type(() => TCode6DocumentDM)
	Code6Id?: TCode6DocumentDM

	/**
	 * Kód 5
	 */
	@Type(() => TCode5DocumentDM)
	Code5Id?: TCode5DocumentDM

	/**
	 * Kód 4
	 */
	@Type(() => TCode4DocumentDM)
	Code4Id?: TCode4DocumentDM

	/**
	 * Kód 3
	 */
	@Type(() => TCode3DocumentDM)
	Code3Id?: TCode3DocumentDM

	/**
	 * Prostředek
	 */
	@Type(() => TDeviceDocumentDM)
	DeviceRID?: TDeviceDocumentDM

	/**
	 * Pracovní místo
	 */
	@Type(() => TOrgStrJobDM)
	OrgStrJobRID?: TOrgStrJobDM

	/**
	 * Referent
	 */
	@Type(() => TEmployedPersonSimpleDM)
	OfficerId?: TEmployedPersonSimpleDM

	/**
	 * Kód 2
	 */
	@Type(() => TCode2DocumentDM)
	Code2Id?: TCode2DocumentDM

	/**
	 * Kód 1
	 */
	@Type(() => TCode1DocumentDM)
	Code1Id?: TCode1DocumentDM

	/**
	 * Kód zboží
	 */
	@Type(() => TArticleCodeDocumentDM)
	ArticleCodeId?: TArticleCodeDocumentDM

	/**
	 * Kód zakázky
	 */
	@Type(() => TContractCodeDocumentDM)
	ContractCodeRID?: TContractCodeDocumentDM

	/**
	 * Středisko
	 */
	@Type(() => TCostCentreCollectionDocumentDM)
	CostCentreId?: TCostCentreCollectionDocumentDM

	/**
	 * Typ
	 */
	@Type(() => OrgTreeUnit)
	TypeId?: OrgTreeUnit

	/**
	 * Podřízená struktura
	 */
	@Type(() => TOrgStrTreeDM)
	OrgStrTreeRID?: TOrgStrTreeDM

	/**
	 * Organizační struktura
	 */
	@Type(() => TOrgStrTreeDM)
	MasterRID?: TOrgStrTreeDM

	/**
	 * Zastupující organizační jednotky
	 */
	@Type(() => TOrgStrDeputyDM)
	OrgDeputyChild?: TOrgStrDeputyDM[]

	/**
	 * Obsazení pracovníci
	 */
	@Type(() => TOrgStrEmployeeDM)
	OrgEmployeeChild?: TOrgStrEmployeeDM[]

	/**
	 * Pracovní vztahy
	 */
	@Type(() => TPrEmploymentDM)
	PrEmploymentChild?: TPrEmploymentDM[]

	/**
	 * Změna role v org. jednotce
	 */
	public RoleEditingCOMMAND(parameters: TRoleEditingCOMMANDParams) {
		return this.executeCommand('RoleEditingCOMMAND', parameters)
	}

	/**
	 * Storno položky
	 */
	public BulkCancelItemCOMMAND() {
		return this.executeCommand('BulkCancelItemCOMMAND')
	}

	/**
	 * Odstorno položky
	 */
	public BulkUnCancelItemCOMMAND() {
		return this.executeCommand('BulkUnCancelItemCOMMAND')
	}

	/**
	 * První potvrzení položky
	 */
	public BulkFirstConfirmItemCOMMAND() {
		return this.executeCommand('BulkFirstConfirmItemCOMMAND')
	}

	/**
	 * Potvrzení položky
	 */
	public BulkConfirmItemCOMMAND() {
		return this.executeCommand('BulkConfirmItemCOMMAND')
	}

	/**
	 * Odpotvrzení položky
	 */
	public BulkUnConfirmItemCOMMAND() {
		return this.executeCommand('BulkUnConfirmItemCOMMAND')
	}

	/**
	 * Storno položky
	 */
	public CancelItemCOMMAND() {
		return this.executeCommand('CancelItemCOMMAND')
	}

	/**
	 * Odstorno položky
	 */
	public UnCancelItemCOMMAND() {
		return this.executeCommand('UnCancelItemCOMMAND')
	}

	/**
	 * První potvrzení položky
	 */
	public FirstConfirmItemCOMMAND() {
		return this.executeCommand('FirstConfirmItemCOMMAND')
	}

	/**
	 * Potvrzení položky
	 */
	public ConfirmItemCOMMAND() {
		return this.executeCommand('ConfirmItemCOMMAND')
	}

	/**
	 * Odpotvrzení položky
	 */
	public UnConfirmItemCOMMAND() {
		return this.executeCommand('UnConfirmItemCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public ChangeGroupRightCOMMAND() {
		return this.executeCommand('ChangeGroupRightCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public BulkChangeGroupRightCOMMAND() {
		return this.executeCommand('BulkChangeGroupRightCOMMAND')
	}

	/**
	 * Aktualizace počítaných polí
	 */
	public BulkUpdateCalcFieldsCOMMAND() {
		return this.executeCommand('BulkUpdateCalcFieldsCOMMAND')
	}

	/**
	 * Kontrola legislativy
	 */
	public SwitchOffLegislationValidationCOMMAND() {
		return this.executeCommand('SwitchOffLegislationValidationCOMMAND')
	}
}

export type TRoleEditingCOMMANDParams = {
	/**
	 * Akce
	 */
	ActionOnResource?: number
	/**
	 * Role
	 */
	Role?: number
}