import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TEshopDM from './TEshopDM'
import TCodeTypeBindPropertyIdLinkTypeDM from './TCodeTypeBindPropertyIdLinkTypeDM'

/**
 * Odkazy
 */
export default class TLinkDM extends Data {
	public static className = 'TLinkDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = false

	/**
	 * Pořadí
	 */
	SequenceNumber?: number

	/**
	 * Časové razítko
	 */
	TimeStamp?: string

	/**
	 * Popis
	 */
	Description?: string

	/**
	 * Název
	 */
	Title?: string

	/**
	 * Odkaz
	 */
	Link?: string

	/**
	 * Typ odkazu
	 */
	LinkTypeId?: number

	/**
	 * TableID
	 */
	TableId?: number

	/**
	 * Vlastník
	 */
	MasterRID?: number

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * undefined
	 */
	FullPathCalc?: string

	/**
	 * Web, e-shop
	 */
	@Type(() => TEshopDM)
	ShopRID?: TEshopDM

	/**
	 * Kategorie
	 */
	@Type(() => TCodeTypeBindPropertyIdLinkTypeDM)
	CategoryId?: TCodeTypeBindPropertyIdLinkTypeDM
}