import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TTradingPartnerDM from './TTradingPartnerDM'

/**
 * NOFGrid Pohyby
 */
export default class TCustomTSalesItemBookDMNOFGrid_Pohyby extends Data {
	public static className = 'TCustomTSalesItemBookDMNOFGrid_Pohyby'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = false

	/**
	 * Master
	 */
	Master?: number

	/**
	 * Ci N
	 */
	CiN?: number

	/**
	 * Ci
	 */
	Ci?: number

	/**
	 * Stav
	 */
	Stav?: number

	/**
	 * Vyde
	 */
	Vyde?: number

	/**
	 * Prij
	 */
	Prij?: number

	/**
	 * CFSk C
	 */
	CFSkC?: number

	/**
	 * CFDocument
	 */
	CFDocument?: string

	/**
	 * Dokl
	 */
	Dokl?: string

	/**
	 * Dat P
	 */
	DatP?: Date

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * RecordMod
	 */
	RecordMod?: number

	/**
	 * RecordES
	 */
	RecordES?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * RecordState
	 */
	RecordState?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * CDo
	 */
	@Type(() => TTradingPartnerDM)
	CDo?: TTradingPartnerDM
}