import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TTextDM from './TTextDM'
import ReminderID from './ReminderID'

/**
 * Položky upomínky
 */
export default class TReminderItemDM extends Data {
	public static className = 'TReminderItemDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = false

	/**
	 * Počet dnů prodlení
	 */
	DelayDays?: number

	/**
	 * Úroky z prodlení
	 */
	LateCharges?: number

	/**
	 * Úroky
	 */
	LateChargesC?: number

	/**
	 * Zbývá zaplatit
	 */
	RemainingAmount?: number

	/**
	 * Doplatit
	 */
	RemainingAmountC?: number

	/**
	 * Zaplaceno
	 */
	AmountPaid?: number

	/**
	 * Zaplaceno
	 */
	AmountPaidC?: number

	/**
	 * Brutto
	 */
	AmountGross?: number

	/**
	 * Brutto
	 */
	AmountGrossC?: number

	/**
	 * Datum splatnosti
	 */
	DueDate?: Date

	/**
	 * Datum úč. případu
	 */
	AccountingTransactionDate?: Date

	/**
	 * Číslo dokladu
	 */
	DocumentRID?: number

	/**
	 * Číslo pol.
	 */
	SequenceNumber?: number

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * Upomínka
	 */
	HeaderRID?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Variabilní symbol
	 */
	ReferenceNumberCalc?: string

	/**
	 * Měna
	 */
	CurrencyCalc?: string

	/**
	 * Faktura přijatá
	 */
	InvoiceInRIDCalc?: number

	/**
	 * Faktura vydaná
	 */
	InvoiceOutRIDCalc?: number

	/**
	 * Záloha vydaná
	 */
	AdvanceProvidedRIDCalc?: number

	/**
	 * Záloha přijatá
	 */
	AdvanceReceivedRIDCalc?: number

	/**
	 * Doklad
	 */
	DocumentIdentificationCalc?: string

	/**
	 * Ostatní závazek
	 */
	OtherLiabilityRIDCalc?: number

	/**
	 * Ostatní pohledávka
	 */
	OtherReceivableRIDCalc?: number

	/**
	 * Typ dokladu
	 */
	@Type(() => TTextDM)
	DocumentTypeId?: TTextDM

	/**
	 * Poř. číslo
	 */
	@Type(() => ReminderID)
	ReminderSequenceNumber?: ReminderID
}