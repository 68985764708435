import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TTextDM from './TTextDM'
import TWarehouseZoneDM from './TWarehouseZoneDM'
import TLocationArticleDM from './TLocationArticleDM'
import TTradingPartnerDM from './TTradingPartnerDM'
import TWarehouseDM from './TWarehouseDM'
import TSpecialSector from './TSpecialSector'

/**
 * Přehledy zboží/sklad
 */
export default class TArticleWarehouseDM extends Data {
	public static className = 'TArticleWarehouseDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = true

	/**
	 * Nastavení
	 */
	Settings?: string

	/**
	 * Odhad výdejů
	 */
	ReleaseEstimate?: number

	/**
	 * Ležák - dny max.
	 */
	DeadStockDayMaximum?: number

	/**
	 * Ležák - dny min.
	 */
	DeadStockDayMinimum?: number

	/**
	 * Maximum
	 */
	Maximum?: number

	/**
	 * Minimum
	 */
	Minimum?: number

	/**
	 * Zboží
	 */
	ArticleId?: number

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * Poslední optimalizace
	 */
	Ex_LastOptimizedOn?: Date

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * undefined
	 */
	MeasureUnitCalc?: string

	/**
	 * undefined
	 */
	InfWarehouseMinLevelUMCalc?: number

	/**
	 * undefined
	 */
	InfWarehouseMaxLevelUMCalc?: number

	/**
	 * Výdeje
	 */
	ReleaseQuantityCalc?: number

	/**
	 * Evidovat
	 */
	MinLevelRecordsBitCalc?: number

	/**
	 * Evidovat
	 */
	MaxLevelRecordsBitCalc?: number

	/**
	 * Vyhodnocovat jako ležák
	 */
	DeadStockBitCalc?: number

	/**
	 * Povolen záporný stav
	 */
	AllowNegativeStockLevelCalc?: number

	/**
	 * Časová jednotka
	 */
	@Type(() => TTextDM)
	TimeUnitId?: TTextDM

	/**
	 * Měsíc do
	 */
	@Type(() => TTextDM)
	MonthTo?: TTextDM

	/**
	 * Zóna
	 */
	@Type(() => TWarehouseZoneDM)
	WarehouseZoneRID?: TWarehouseZoneDM

	/**
	 * Opt.umístění
	 */
	@Type(() => TLocationArticleDM)
	OptimalLocationId?: TLocationArticleDM

	/**
	 * Opt.dodavatel
	 */
	@Type(() => TTradingPartnerDM)
	OptimalSupplierId?: TTradingPartnerDM

	/**
	 * Měsíc od
	 */
	@Type(() => TTextDM)
	MonthFrom?: TTextDM

	/**
	 * Sklad
	 */
	@Type(() => TWarehouseDM)
	WarehouseId?: TWarehouseDM

	/**
	 * Sektor
	 */
	@Type(() => TSpecialSector)
	Ex_SectorId?: TSpecialSector

	/**
	 * Změna skupiny práv
	 */
	public ChangeGroupRightCOMMAND() {
		return this.executeCommand('ChangeGroupRightCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public BulkChangeGroupRightCOMMAND() {
		return this.executeCommand('BulkChangeGroupRightCOMMAND')
	}

	/**
	 * Aktualizace počítaných polí
	 */
	public BulkUpdateCalcFieldsCOMMAND() {
		return this.executeCommand('BulkUpdateCalcFieldsCOMMAND')
	}

	/**
	 * Kontrola legislativy
	 */
	public SwitchOffLegislationValidationCOMMAND() {
		return this.executeCommand('SwitchOffLegislationValidationCOMMAND')
	}
}