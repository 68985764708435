import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TTextDM from './TTextDM'
import TCalculationModelDM from './TCalculationModelDM'
import TMarketingActivityTypeDM from './TMarketingActivityTypeDM'
import TJobCardConfigDM from './TJobCardConfigDM'
import TCommentDM from './TCommentDM'
import TLanguageTextTranslationDM from './TLanguageTextTranslationDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TCompanyLinkArticleCategoryDM from './TCompanyLinkArticleCategoryDM'
import TAttachedDocumentDM from './TAttachedDocumentDM'
import TOldNoteDM from './TOldNoteDM'

/**
 * Druh zboží
 */
export default class TArticleKindDM extends Data {
	public static className = 'TArticleKindDM'
	public static primaryKey = 'Id'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.Id!
	}

	public static isHeader = true

	/**
	 * Vytvářet průvodky určené k provázání
	 */
	IsIntendedForJobCardBinding?: string

	/**
	 * Průvodka
	 */
	NotCreateJobCard?: string

	/**
	 * Automat
	 */
	IsLocationRecordsAutomat?: string

	/**
	 * Automat
	 */
	IsBatchRecordsAutomat?: string

	/**
	 * Automat
	 */
	IsSerialNumberRecordsAutomat?: string

	/**
	 * Oddělit v sestavách
	 */
	SeparateInReport?: string

	/**
	 * Převodka
	 */
	NotCreateTransferNote?: string

	/**
	 * Faktura vydaná
	 */
	NotCreateInvoiceOut?: string

	/**
	 * Dodací list
	 */
	NotCreateDeliveryNote?: string

	/**
	 * Výdejka
	 */
	NotCreateReleaseNote?: string

	/**
	 * Rezervační list
	 */
	NotCreateReservingCard?: string

	/**
	 * Objednávka přijatá
	 */
	NotCreateOrderIn?: string

	/**
	 * Faktura přijatá
	 */
	NotCreateInvoiceIn?: string

	/**
	 * Příjemka
	 */
	NotCreateReceiptCard?: string

	/**
	 * Potvrzení dodání
	 */
	NotCreateDeliveryConf?: string

	/**
	 * Vedlejší náklad
	 */
	IsIncidentalCost?: string

	/**
	 * Položka kusovníku
	 */
	IsBillOfMaterialItem?: string

	/**
	 * Nepřidávat do plánovací  průvodky
	 */
	NotAddToPlanJobCard?: string

	/**
	 * Vazba převodek a průvodek
	 */
	LinkTransferNoteAndJobCard?: string

	/**
	 * Nevytvářet podřízené průvodky
	 */
	NotCreateSubJobCards?: string

	/**
	 * Výrobek nebo polotovar
	 */
	IsProductOrSubassembly?: string

	/**
	 * Zpracování přepočtů po periodách
	 */
	IsPeriodRecalc?: string

	/**
	 * Zákaz fyzických přepočtů
	 */
	NoPhysicalRecalc?: string

	/**
	 * Zákaz účetního přepočtu
	 */
	NoAccountingRecalc?: string

	/**
	 * Násobit časem operace
	 */
	MultiplyByTime?: string

	/**
	 * Přednastavení příznaků výdeje
	 */
	PresetRelease?: string

	/**
	 * Přednastavení příznaků rezervace
	 */
	PresetReservation?: string

	/**
	 * Nezávislost na výtěžnosti
	 */
	IsIndependentOnYield?: string

	/**
	 * Nezávislost na vyráběném množství
	 */
	IsIndependentOnProducedQty?: string

	/**
	 * Neevidovat skutečné množství
	 */
	NotRecordActualQuantity?: string

	/**
	 * Časové razítko
	 */
	TimeStamp?: string

	/**
	 * Právo na záznam
	 */
	RightGroupId?: number

	/**
	 * Popis
	 */
	Description?: string

	/**
	 * Zkratka
	 */
	Abbr?: string

	/**
	 * Číslo
	 */
	Id?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Jazykový popis
	 */
	LanguageTextCalc?: string

	/**
	 * undefined
	 */
	TextHeaderMemoCalc?: string

	/**
	 * undefined
	 */
	TextFooterMemoCalc?: string

	/**
	 * Poslední komentář
	 */
	LastCommentParamCalc?: string

	/**
	 * Právo na záznam
	 */
	RightGroupIdCalc?: number

	/**
	 * RecordsContractCode
	 */
	@Type(() => TTextDM)
	RecordContractCodes?: TTextDM

	/**
	 * RecordsLocation
	 */
	@Type(() => TTextDM)
	RecordLocations?: TTextDM

	/**
	 * RecordsBatch
	 */
	@Type(() => TTextDM)
	RecordBatches?: TTextDM

	/**
	 * RecordsSerialNumbers
	 */
	@Type(() => TTextDM)
	RecordSerialNumbers?: TTextDM

	/**
	 * KV hmotnostní
	 */
	@Type(() => TCalculationModelDM)
	WeightCalculationModelId?: TCalculationModelDM

	/**
	 * KV nákladový
	 */
	@Type(() => TCalculationModelDM)
	CostPriceCalculationModelId?: TCalculationModelDM

	/**
	 * KV skladový
	 */
	@Type(() => TCalculationModelDM)
	StockPriceCalculationModelId?: TCalculationModelDM

	/**
	 * KV plánovaný
	 */
	@Type(() => TCalculationModelDM)
	PlannedPriceCalculationModelId?: TCalculationModelDM

	/**
	 * D
	 */
	@Type(() => TTextDM)
	DocumentExistsCalc?: TTextDM

	/**
	 * undefined
	 */
	@Type(() => TMarketingActivityTypeDM)
	ActivityTypeRIDCalc?: TMarketingActivityTypeDM

	/**
	 * Konfigurace vytváření průvodek
	 */
	@Type(() => TJobCardConfigDM)
	JobCardConfigIdCalc?: TJobCardConfigDM

	/**
	 * Komentáře
	 */
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]

	/**
	 * undefined
	 */
	@Type(() => TLanguageTextTranslationDM)
	LanguageDescriptionChild?: TLanguageTextTranslationDM[]

	/**
	 * Text hlavičky
	 */
	@Type(() => TLanguageMemoTranslationDM)
	TextHeaderChild?: TLanguageMemoTranslationDM[]

	/**
	 * Text patičky
	 */
	@Type(() => TLanguageMemoTranslationDM)
	TextFooterChild?: TLanguageMemoTranslationDM[]

	/**
	 * undefined
	 */
	@Type(() => TCompanyLinkArticleCategoryDM)
	CompanyChild?: TCompanyLinkArticleCategoryDM[]

	/**
	 * Svázané doklady
	 */
	@Type(() => TAttachedDocumentDM)
	AttachedDocumentOldChild?: TAttachedDocumentDM[]

	/**
	 * Poznámky
	 */
	@Type(() => TOldNoteDM)
	DeprecatedNoteOldChild?: TOldNoteDM[]

	/**
	 * Potvrzení
	 */
	public BulkConfirmCOMMAND() {
		return this.executeCommand('BulkConfirmCOMMAND')
	}

	/**
	 * Odpotvrzení
	 */
	public BulkUnconfirmCOMMAND() {
		return this.executeCommand('BulkUnconfirmCOMMAND')
	}

	/**
	 * Změna data potvrzení
	 */
	public BulkChangeConfirmationDateCOMMAND(parameters: TBulkChangeConfirmationDateCOMMANDParams) {
		return this.executeCommand('BulkChangeConfirmationDateCOMMAND', parameters)
	}

	/**
	 * Storno
	 */
	public BulkCancelCOMMAND() {
		return this.executeCommand('BulkCancelCOMMAND')
	}

	/**
	 * Odstorno
	 */
	public BulkUnCancelCOMMAND() {
		return this.executeCommand('BulkUnCancelCOMMAND')
	}

	/**
	 * Potvrzení
	 */
	public ConfirmCOMMAND() {
		return this.executeCommand('ConfirmCOMMAND')
	}

	/**
	 * Odpotvrzení
	 */
	public UnconfirmCOMMAND() {
		return this.executeCommand('UnconfirmCOMMAND')
	}

	/**
	 * Změna data potvrzení
	 */
	public ChangeConfirmationDateCOMMAND(parameters: TChangeConfirmationDateCOMMANDParams) {
		return this.executeCommand('ChangeConfirmationDateCOMMAND', parameters)
	}

	/**
	 * Storno/Odstorno
	 */
	public CancelCOMMAND() {
		return this.executeCommand('CancelCOMMAND')
	}

	/**
	 * Zrušení stavu
	 */
	public ClearRecordStatusCOMMAND() {
		return this.executeCommand('ClearRecordStatusCOMMAND')
	}

	/**
	 * Změna stavu
	 */
	public ChangeRecordStatusCOMMAND() {
		return this.executeCommand('ChangeRecordStatusCOMMAND')
	}

	/**
	 * 
	 */
	public ChangeRecordStatusSWSCOMMAND(parameters: TChangeRecordStatusSWSCOMMANDParams) {
		return this.executeCommand('ChangeRecordStatusSWSCOMMAND', parameters)
	}

	/**
	 * Zrušení stavu
	 */
	public BulkClearRecordStatusCOMMAND() {
		return this.executeCommand('BulkClearRecordStatusCOMMAND')
	}

	/**
	 * Změna stavu
	 */
	public BulkChangeRecordStatusCOMMAND() {
		return this.executeCommand('BulkChangeRecordStatusCOMMAND')
	}

	/**
	 * Změna atributů
	 */
	public BulkChangeAttributesCOMMAND(parameters: TBulkChangeAttributesCOMMANDParams) {
		return this.executeCommand('BulkChangeAttributesCOMMAND', parameters)
	}

	/**
	 * Aktualizace sady atributů
	 */
	public BulkActualizeAttributesCOMMAND(parameters: TBulkActualizeAttributesCOMMANDParams) {
		return this.executeCommand('BulkActualizeAttributesCOMMAND', parameters)
	}

	/**
	 * Aktualizace sady atributů
	 */
	public ActualizeAttributesCOMMAND() {
		return this.executeCommand('ActualizeAttributesCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public ChangeGroupRightCOMMAND() {
		return this.executeCommand('ChangeGroupRightCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public BulkChangeGroupRightCOMMAND() {
		return this.executeCommand('BulkChangeGroupRightCOMMAND')
	}

	/**
	 * Aktualizace počítaných polí
	 */
	public BulkUpdateCalcFieldsCOMMAND() {
		return this.executeCommand('BulkUpdateCalcFieldsCOMMAND')
	}

	/**
	 * Kontrola legislativy
	 */
	public SwitchOffLegislationValidationCOMMAND() {
		return this.executeCommand('SwitchOffLegislationValidationCOMMAND')
	}
}

export type TBulkChangeConfirmationDateCOMMANDParams = {
	/**
	 * Datum potvrzení
	 */
	Confirmation1Date?: Date
	/**
	 * Hodina potvrzení
	 */
	Confirmation1Time?: Date
	/**
	 * Datum 2. potvrzení
	 */
	Confirmation2Date?: Date
	/**
	 * Hodina 2. potvrzení
	 */
	Confirmation2Time?: Date
	/**
	 * Kontrolovat záporné stavy
	 */
	CheckNegativeState?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TChangeConfirmationDateCOMMANDParams = {
	/**
	 * Datum potvrzení
	 */
	Confirmation1Date?: Date
	/**
	 * Hodina potvrzení
	 */
	Confirmation1Time?: Date
	/**
	 * Datum 2. potvrzení
	 */
	Confirmation2Date?: Date
	/**
	 * Hodina 2. potvrzení
	 */
	Confirmation2Time?: Date
	/**
	 * Kontrolovat záporné stavy
	 */
	CheckNegativeState?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TChangeRecordStatusSWSCOMMANDParams = {
	/**
	 * StatusId
	 */
	StatusId?: number
}

export type TBulkChangeAttributesCOMMANDParams = {
	/**
	 * Smazat atributy
	 */
	DeleteAttribute?: boolean
	/**
	 * Smazat všechny volné atributy
	 */
	DeleteFreeAttribute?: boolean
	/**
	 * Nastavit hodnoty atributů
	 */
	SetValues?: boolean
	/**
	 * Přidat nepřipojené atributy
	 */
	AddFreeAttributes?: boolean
	/**
	 * FreeAttributeEnabled
	 */
	FreeAttributeEnabled?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TBulkActualizeAttributesCOMMANDParams = {
	/**
	 * Aktualizovat včetně samotné sady atributů
	 */
	ActualizeAttributeSet?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}