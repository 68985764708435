import Data from 'react-k2-api/Api/Data'

/**
 * TWorkflowProcedureRespPersonDM
 */
export default class TWorkflowProcedureRespPersonDM extends Data {
	public static className = 'TWorkflowProcedureRespPersonDM'
	public static systemFields = [
		'RecordID',
	]

	public static isHeader = false

	/**
	 * PersonId
	 */
	PersonId?: number

	/**
	 * DiagramId
	 */
	ProcedureId?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * undefined
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date
}