import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TExternalBatchDM from './TExternalBatchDM'
import TCode6DocumentDM from './TCode6DocumentDM'
import TCode5DocumentDM from './TCode5DocumentDM'
import TCode4DocumentDM from './TCode4DocumentDM'
import TCode3DocumentDM from './TCode3DocumentDM'
import TCodeTypeDM from './TCodeTypeDM'
import TBookDM from './TBookDM'
import TArticleCodeDocumentDM from './TArticleCodeDocumentDM'
import TCostCentreCollectionDocumentDM from './TCostCentreCollectionDocumentDM'
import TDeviceDocumentDM from './TDeviceDocumentDM'
import TTransferNoteDM from './TTransferNoteDM'
import THandlingUnitArticleDM from './THandlingUnitArticleDM'
import TContractCodeDocumentDM from './TContractCodeDocumentDM'
import TTradingPartnerDM from './TTradingPartnerDM'
import TRoutingVariantDM from './TRoutingVariantDM'
import PlanType from './PlanType'
import TRequestedBatchParameterHeaderDM from './TRequestedBatchParameterHeaderDM'
import TCode2DocumentDM from './TCode2DocumentDM'
import TCode1DocumentDM from './TCode1DocumentDM'
import TOfficerDocumentDM from './TOfficerDocumentDM'
import TLocationArticleDM from './TLocationArticleDM'
import TBatchDM from './TBatchDM'
import TMeasureUnitDM from './TMeasureUnitDM'
import TWarehouseDM from './TWarehouseDM'
import GoodsName from './GoodsName'
import TArticleDM from './TArticleDM'
import TTextDM from './TTextDM'
import KindAccounting from './KindAccounting'
import TCommentDM from './TCommentDM'
import TItemNoteDM from './TItemNoteDM'
import TSerialNumberItemDM from './TSerialNumberItemDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TAttachedBatchParameterValueDM from './TAttachedBatchParameterValueDM'
import TAttachedRequestedBatchParameterDM from './TAttachedRequestedBatchParameterDM'

/**
 * Položky převodky
 */
export default class TAccountingFinanceTransferNoteItemDM extends Data {
	public static className = 'TAccountingFinanceTransferNoteItemDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = false

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * Datum rezervace
	 */
	ReservationDate?: Date

	/**
	 * Nákl. c. na sklad
	 */
	UnitCostPriceTo?: number

	/**
	 * Skl. c. na sklad
	 */
	UnitStockPriceTo?: number

	/**
	 * OrigItem
	 */
	OriginalNo?: number

	/**
	 * Nákl. cena / MJ
	 */
	UnitCostPriceFrom?: number

	/**
	 * Poznámka
	 */
	NoteId?: number

	/**
	 * Pohyb_P
	 */
	PostingKeyId?: number

	/**
	 * Cena / MJ
	 */
	UnitStockPriceFrom?: number

	/**
	 * Množství
	 */
	Quantity?: number

	/**
	 * Datum potvrzení
	 */
	ConfirmedOn?: Date

	/**
	 * Číslo pol.
	 */
	ItemId?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * Požadované balení
	 */
	Ex_RequiredPackage?: number

	/**
	 * Ex_SaleItemRID
	 */
	Ex_SaleItemRID?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * &Množství
	 */
	QuantityUMCalc?: number

	/**
	 * Cena ze skladu / MJ
	 */
	UnitPriceWarehouseFromCalc?: number

	/**
	 * Cena na sklad / MJ
	 */
	UnitPriceWarehouseToCalc?: number

	/**
	 * Cena ze skladu celkem
	 */
	TotalPriceWarehouseFromCalc?: number

	/**
	 * Cena na sklad celkem
	 */
	TotalPriceWarehouseToCalc?: number

	/**
	 * Název
	 */
	NameCalc?: string

	/**
	 * Množství
	 */
	PositiveQuantityCalc?: number

	/**
	 * Množství - r.č.
	 */
	QuantityRNCalc?: number

	/**
	 * Název celý
	 */
	FullNameCalc?: string

	/**
	 * Čárový kód
	 */
	BarcodeCalc?: string

	/**
	 * undefined
	 */
	SNIncorrectCountImgCalc?: string

	/**
	 * BatchTypeCalc
	 */
	BatchTypeCalc?: number

	/**
	 * BatchRatioCalc
	 */
	BatchRatioCalc?: number

	/**
	 * BatchFromIdCalc
	 */
	BatchFromIdCalc?: number

	/**
	 * ContractCodeFromRIDCalc
	 */
	ContractCodeFromRIDCalc?: number

	/**
	 * LocationFromIdCalc
	 */
	LocationFromIdCalc?: number

	/**
	 * BatchToIdCalc
	 */
	BatchToIdCalc?: number

	/**
	 * ContractCodeToRIDCalc
	 */
	ContractCodeToRIDCalc?: number

	/**
	 * LocationToIdCalc
	 */
	LocationToIdCalc?: number

	/**
	 * Nákl. celk.
	 */
	TotalCostPriceCalc?: number

	/**
	 * Na nákl. celk.
	 */
	CostPriceWarehouseToCalc?: number

	/**
	 * I
	 */
	IdenticalSourceDestinationCalc?: number

	/**
	 * I
	 */
	IdenticalSourceDestinationImgCalc?: string

	/**
	 * HandlingUnitFromRIDCalc
	 */
	HandlingUnitFromRIDCalc?: number

	/**
	 * HandlingUnitToRIDCalc
	 */
	HandlingUnitToRIDCalc?: number

	/**
	 * undefined
	 */
	LockTypeCalc?: number

	/**
	 * undefined
	 */
	ReservedBitCalc?: number

	/**
	 * undefined
	 */
	ReleaseBitCalc?: number

	/**
	 * BatchParameterRatioCalc
	 */
	BatchParameterRatioCalc?: number

	/**
	 * undefined
	 */
	QuantityByBatchTypeUMCalc?: number

	/**
	 * undefined
	 */
	TotalWeightGrossCalc?: number

	/**
	 * undefined
	 */
	ReservationDateCalc?: Date

	/**
	 * Čas
	 */
	ReservationTimeCalc?: Date

	/**
	 * undefined
	 */
	WarehouseZoneFromRIDCalc?: number

	/**
	 * undefined
	 */
	WarehouseZoneToRIDCalc?: number

	/**
	 * Total weight netto
	 */
	TotalWeightNetCalc?: number

	/**
	 * undefined
	 */
	IgnoreReceiptPriceCalc?: number

	/**
	 * Text
	 */
	ItemTextCalc?: string

	/**
	 * undefined
	 */
	SerialNumberInputCalc?: string

	/**
	 * Objem celkem
	 */
	TotalVolumePARAMCalc?: number

	/**
	 * undefined
	 */
	SerialNumberCountAsStringCalc?: string

	/**
	 * Externí šarže na
	 */
	@Type(() => TExternalBatchDM)
	ExternalBatchToId?: TExternalBatchDM

	/**
	 * Externí šarže
	 */
	@Type(() => TExternalBatchDM)
	ExternalBatchFromId?: TExternalBatchDM

	/**
	 * Na kód 6
	 */
	@Type(() => TCode6DocumentDM)
	Code6ToId?: TCode6DocumentDM

	/**
	 * Z kódu 6
	 */
	@Type(() => TCode6DocumentDM)
	Code6FromId?: TCode6DocumentDM

	/**
	 * Na kód 5
	 */
	@Type(() => TCode5DocumentDM)
	Code5ToId?: TCode5DocumentDM

	/**
	 * Z kódu 5
	 */
	@Type(() => TCode5DocumentDM)
	Code5FromId?: TCode5DocumentDM

	/**
	 * Na kód 4
	 */
	@Type(() => TCode4DocumentDM)
	Code4ToId?: TCode4DocumentDM

	/**
	 * Z kódu 4
	 */
	@Type(() => TCode4DocumentDM)
	Code4FromId?: TCode4DocumentDM

	/**
	 * Na kód 3
	 */
	@Type(() => TCode3DocumentDM)
	Code3ToId?: TCode3DocumentDM

	/**
	 * Z kódu 3
	 */
	@Type(() => TCode3DocumentDM)
	Code3FromId?: TCode3DocumentDM

	/**
	 * undefined
	 */
	@Type(() => TCodeTypeDM)
	MobileTerminalStatusId?: TCodeTypeDM

	/**
	 * Kniha
	 */
	@Type(() => TBookDM)
	MainDocumentBookId?: TBookDM

	/**
	 * Kód zboží
	 */
	@Type(() => TArticleCodeDocumentDM)
	ArticleCodeId?: TArticleCodeDocumentDM

	/**
	 * Na středisko
	 */
	@Type(() => TCostCentreCollectionDocumentDM)
	CostCentreToId?: TCostCentreCollectionDocumentDM

	/**
	 * Ze střediska
	 */
	@Type(() => TCostCentreCollectionDocumentDM)
	CostCentreFromId?: TCostCentreCollectionDocumentDM

	/**
	 * Na prostředek
	 */
	@Type(() => TDeviceDocumentDM)
	DeviceToRID?: TDeviceDocumentDM

	/**
	 * Z prostředku
	 */
	@Type(() => TDeviceDocumentDM)
	DeviceFromRID?: TDeviceDocumentDM

	/**
	 * HeaderRID
	 */
	@Type(() => TTransferNoteDM)
	HeaderRID?: TTransferNoteDM

	/**
	 * Na manipul. j.
	 */
	@Type(() => THandlingUnitArticleDM)
	HandlingUnitToRID?: THandlingUnitArticleDM

	/**
	 * Z manipul. j.
	 */
	@Type(() => THandlingUnitArticleDM)
	HandlingUnitFromRID?: THandlingUnitArticleDM

	/**
	 * Na kód zakázky
	 */
	@Type(() => TContractCodeDocumentDM)
	ContractCodeToRID?: TContractCodeDocumentDM

	/**
	 * Z kódu zakázky
	 */
	@Type(() => TContractCodeDocumentDM)
	ContractCodeFromRID?: TContractCodeDocumentDM

	/**
	 * Dodavatel
	 */
	@Type(() => TTradingPartnerDM)
	SupplierId?: TTradingPartnerDM

	/**
	 * Varianta
	 */
	@Type(() => TRoutingVariantDM)
	RoutingVariantId?: TRoutingVariantDM

	/**
	 * Typ plánu
	 */
	@Type(() => PlanType)
	PlanTypeId?: PlanType

	/**
	 * Pož. vlastnosti
	 */
	@Type(() => TRequestedBatchParameterHeaderDM)
	RequestedBatchParametersId?: TRequestedBatchParameterHeaderDM

	/**
	 * Na_KodSPS2
	 */
	@Type(() => TCode2DocumentDM)
	Code2ToId?: TCode2DocumentDM

	/**
	 * Z_KoduSPS2
	 */
	@Type(() => TCode2DocumentDM)
	Code2FromId?: TCode2DocumentDM

	/**
	 * Na_KodSPS1
	 */
	@Type(() => TCode1DocumentDM)
	Code1ToId?: TCode1DocumentDM

	/**
	 * Z_KoduSPS1
	 */
	@Type(() => TCode1DocumentDM)
	Code1FromId?: TCode1DocumentDM

	/**
	 * ToPerson
	 */
	@Type(() => TOfficerDocumentDM)
	OfficerToId?: TOfficerDocumentDM

	/**
	 * FromPerson
	 */
	@Type(() => TOfficerDocumentDM)
	OfficerFromId?: TOfficerDocumentDM

	/**
	 * Číslo umístění na
	 */
	@Type(() => TLocationArticleDM)
	LocationToId?: TLocationArticleDM

	/**
	 * Číslo umístění ze
	 */
	@Type(() => TLocationArticleDM)
	LocationFromId?: TLocationArticleDM

	/**
	 * Číslo šarže na
	 */
	@Type(() => TBatchDM)
	BatchToId?: TBatchDM

	/**
	 * Číslo šarže
	 */
	@Type(() => TBatchDM)
	BatchFromId?: TBatchDM

	/**
	 * Skladová jednotka
	 */
	@Type(() => TMeasureUnitDM)
	MeasureUnitId?: TMeasureUnitDM

	/**
	 * Číslo skladu na
	 */
	@Type(() => TWarehouseDM)
	WarehouseToId?: TWarehouseDM

	/**
	 * Číslo skladu ze
	 */
	@Type(() => TWarehouseDM)
	WarehouseFromId?: TWarehouseDM

	/**
	 * Název
	 */
	@Type(() => GoodsName)
	ArticleNameId?: GoodsName

	/**
	 * Číslo zboží
	 */
	@Type(() => TArticleDM)
	ArticleId?: TArticleDM

	/**
	 * D
	 */
	@Type(() => TTextDM)
	QuantitySignBitCalc?: TTextDM

	/**
	 * undefined
	 */
	@Type(() => TTextDM)
	SNIncorrectCountBitCalc?: TTextDM

	/**
	 * WarehouseFromIdCalc
	 */
	@Type(() => TWarehouseDM)
	WarehouseFromIdCalc?: TWarehouseDM

	/**
	 * WarehouseToByQuantitySignIdCalc
	 */
	@Type(() => TWarehouseDM)
	WarehouseToByQuantitySignIdCalc?: TWarehouseDM

	/**
	 * WarehouseFromByQuantitySignIdCalc
	 */
	@Type(() => TWarehouseDM)
	WarehouseFromByQuantitySignIdCalc?: TWarehouseDM

	/**
	 * WarehouseToIdCalc
	 */
	@Type(() => TWarehouseDM)
	WarehouseToIdCalc?: TWarehouseDM

	/**
	 * Číslo zboží
	 */
	@Type(() => TArticleDM)
	ArticleByIdInputIdCalc?: TArticleDM

	/**
	 * Číslo zboží
	 */
	@Type(() => TArticleDM)
	ArticleByAbbr2InputIdCalc?: TArticleDM

	/**
	 * Číslo zboží
	 */
	@Type(() => TArticleDM)
	ArticleByNameInputIdCalc?: TArticleDM

	/**
	 * Druh účtování
	 */
	@Type(() => KindAccounting)
	PostingKindIdCalc?: KindAccounting

	/**
	 * Komentáře
	 */
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]

	/**
	 * undefined
	 */
	@Type(() => TItemNoteDM)
	ItemNotesChild?: TItemNoteDM[]

	/**
	 * undefined
	 */
	@Type(() => TSerialNumberItemDM)
	SerialNumberItemChild?: TSerialNumberItemDM[]

	/**
	 * undefined
	 */
	@Type(() => TLanguageMemoTranslationDM)
	ItemTextChild?: TLanguageMemoTranslationDM[]

	/**
	 * undefined
	 */
	@Type(() => TAttachedBatchParameterValueDM)
	BatchParameterChild?: TAttachedBatchParameterValueDM[]

	/**
	 * undefined
	 */
	@Type(() => TAttachedRequestedBatchParameterDM)
	RequestedBatchParameterChild?: TAttachedRequestedBatchParameterDM[]
}