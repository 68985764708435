import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TWarehouseDM from './TWarehouseDM'
import TWMSEquipmentDM from './TWMSEquipmentDM'
import TOfficerDocumentDM from './TOfficerDocumentDM'
import TWMSQueueDM from './TWMSQueueDM'
import Priority from './Priority'
import TDeliveryLineDM from './TDeliveryLineDM'
import TScheduledServiceOperationDM from './TScheduledServiceOperationDM'
import TServicedDeviceDM from './TServicedDeviceDM'
import TCode6DocumentDM from './TCode6DocumentDM'
import TCode5DocumentDM from './TCode5DocumentDM'
import TCode4DocumentDM from './TCode4DocumentDM'
import TCode3DocumentDM from './TCode3DocumentDM'
import TShippingMethodDM from './TShippingMethodDM'
import TSalesActionDM from './TSalesActionDM'
import TPriceListDM from './TPriceListDM'
import TCostCentreCollectionDocumentDM from './TCostCentreCollectionDocumentDM'
import TEshopOrderDM from './TEshopOrderDM'
import TDeviceDocumentDM from './TDeviceDocumentDM'
import JCDP20_a1 from './JCDP20_a1'
import TPeriodDM from './TPeriodDM'
import TBookDM from './TBookDM'
import TContactPersonDM from './TContactPersonDM'
import TTaxTypeDM from './TTaxTypeDM'
import TTransportMethodDM from './TTransportMethodDM'
import TContractCodeDocumentDM from './TContractCodeDocumentDM'
import ContractStatus from './ContractStatus'
import PlanType from './PlanType'
import TCode2DocumentDM from './TCode2DocumentDM'
import TCode1DocumentDM from './TCode1DocumentDM'
import TMatchingSymbolDM from './TMatchingSymbolDM'
import TK2UserLookupDM from './TK2UserLookupDM'
import FormOfOrder from './FormOfOrder'
import TPaymentMethodDM from './TPaymentMethodDM'
import TermOfDelivery from './TermOfDelivery'
import TCurrencyDM from './TCurrencyDM'
import TPriceGroupDM from './TPriceGroupDM'
import TTradingPartnerDM from './TTradingPartnerDM'
import TPartnerDM from './TPartnerDM'
import TTextDM from './TTextDM'
import TBatchDM from './TBatchDM'
import TAddressDM from './TAddressDM'
import TQuotationDM from './TQuotationDM'
import TMarketingAddressDM from './TMarketingAddressDM'
import TDeliveryPlanDM from './TDeliveryPlanDM'
import TDeliveryOrderDM from './TDeliveryOrderDM'
import TDeliveryPlanItemBookDM from './TDeliveryPlanItemBookDM'
import TEdiQueueDM from './TEdiQueueDM'
import TMarketingActivityTypeDM from './TMarketingActivityTypeDM'
import TWorkflowProcessDM from './TWorkflowProcessDM'
import TActivityDM from './TActivityDM'
import TLinkDM from './TLinkDM'
import TCommentDM from './TCommentDM'
import TJobDM from './TJobDM'
import TAdvanceReceivedDM from './TAdvanceReceivedDM'
import TInvoiceOutDM from './TInvoiceOutDM'
import TReservingCardDM from './TReservingCardDM'
import TDeliveryNoteDM from './TDeliveryNoteDM'
import TOrderConfirmationDM from './TOrderConfirmationDM'
import TReleaseNoteDM from './TReleaseNoteDM'
import TSalesItemBookDM from './TSalesItemBookDM'
import TPaymentConditionTradeDM from './TPaymentConditionTradeDM'
import TRelatedAddressDocumentDM from './TRelatedAddressDocumentDM'
import TTaxSummaryDM from './TTaxSummaryDM'
import TSalesItemDM from './TSalesItemDM'
import TAccountingSalesItemDM from './TAccountingSalesItemDM'
import TTransferNoteDM from './TTransferNoteDM'
import TAppliedCouponDM from './TAppliedCouponDM'
import TAppliedPromotionDM from './TAppliedPromotionDM'
import TProductCostingDM from './TProductCostingDM'
import TExternalDocumentItemDM from './TExternalDocumentItemDM'
import TWorkflowProcessAsItemDM from './TWorkflowProcessAsItemDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TCustomTSalesOrderDMInformace_P from './TCustomTSalesOrderDMInformace_P'
import TAttachedDocumentDM from './TAttachedDocumentDM'
import TOldNoteDM from './TOldNoteDM'

/**
 * Zakázky
 */
export default class TSalesOrderDM extends Data {
	public static className = 'TSalesOrderDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = true

	/**
	 * Firma
	 */
	BookId_CompanyId?: number

	/**
	 * Skup. právo
	 */
	BookId_RightGroupId?: number

	/**
	 * Právo na záznam
	 */
	TradingPartnerId_RightGroupId?: number

	/**
	 * Dod. adresa
	 */
	DeliveryAddressTrPartId?: number

	/**
	 * Očekávané trvání
	 */
	ExpectedJobDuration?: number

	/**
	 * Požadovaný konec
	 */
	RequiredEndDate?: Date

	/**
	 * Požadovaný začátek
	 */
	RequiredStartDate?: Date

	/**
	 * Datum do
	 */
	EndDate?: Date

	/**
	 * Datum od
	 */
	StartDate?: Date

	/**
	 * Expedice
	 */
	DispatchDate?: Date

	/**
	 * Potvrzeno na
	 */
	DeliveryConfirmedForDate?: Date

	/**
	 * Nezahrnovat do řízení financí
	 */
	NotIncludeIntoFinancialMngmt?: string

	/**
	 * Specifický symbol
	 */
	SpecificSymbol?: string

	/**
	 * Vytvořeno
	 */
	CreatedOn?: Date

	/**
	 * Nastavení
	 */
	Settings?: string

	/**
	 * Daňové číslo
	 */
	TaxNumber?: string

	/**
	 * DIČ
	 */
	VATRegNumber?: string

	/**
	 * IČ
	 */
	CompanyRegNumber?: string

	/**
	 * Číslo
	 */
	Number?: number

	/**
	 * Netto M
	 */
	AmountNetC?: number

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * Časové razítko
	 */
	TimeStamp?: string

	/**
	 * Č.objednávky
	 */
	OrderNumber?: string

	/**
	 * Právo na záznam
	 */
	RightGroupId?: number

	/**
	 * Poc_Pol
	 */
	ItemCount?: number

	/**
	 * Změněno
	 */
	ChangedOn?: Date

	/**
	 * Potvrzeno
	 */
	ConfirmedOn?: Date

	/**
	 * Vystaveno
	 */
	IssueDate?: Date

	/**
	 * Dodáno
	 */
	DesiredDeliveryDate?: Date

	/**
	 * Kontaktní informace
	 */
	ContactInformation?: string

	/**
	 * Popis
	 */
	Description?: string

	/**
	 * Kurz
	 */
	ExchangeRate?: number

	/**
	 * Netto
	 */
	AmountNet?: number

	/**
	 * Var.symb.
	 */
	ReferenceNumber?: string

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * ID na E-SHOPU
	 */
	EX_ES_EcpId?: number

	/**
	 * kód zásilky na E-SHO
	 */
	EX_ES_TrackingCode?: string

	/**
	 * Nová objednávka
	 */
	EX_ES_Prichozi?: string

	/**
	 * Partner
	 */
	EX_ES_Partner?: number

	/**
	 * Potvrzenka exportována do jiného mandant
	 */
	EX_PM_Exported_Pot?: string

	/**
	 * Exportovat potvrzenku do jiného mandanta
	 */
	EX_PM_Exp_Potvrz?: string

	/**
	 * RID Obv z jiného mandanta
	 */
	EX_PM_RID_Obv?: number

	/**
	 * EX_pom_retk
	 */
	EX_pom_retk?: number

	/**
	 * Celoroční objednávka
	 */
	EX_celroc_obj?: string

	/**
	 * EX_id_prech_syst
	 */
	EX_id_prech_syst?: string

	/**
	 * Odběrné místo
	 */
	EX_OdberneMisto?: string

	/**
	 * EX_pom_kasa
	 */
	EX_pom_kasa?: string

	/**
	 * EX_Fpomoc_NZ
	 */
	EX_Fpomoc_NZ?: number

	/**
	 * Nevkládat dopravné
	 */
	EX_NoPostage?: string

	/**
	 * EX_Konsignace
	 */
	EX_Konsignace?: string

	/**
	 * Neslučovat faktury vydané
	 */
	EX_NeslucovatFAV?: string

	/**
	 * EX_Flagy
	 */
	EX_Flagy?: number

	/**
	 * EX_Pomocne_Terminy
	 */
	EX_Pomocne_Terminy?: number

	/**
	 * Nab. neblok. mail
	 */
	EX_Nab_nebl_mail?: string

	/**
	 * Vychystávat kompletně
	 */
	EX_Kompletne?: string

	/**
	 * NZ real připomenutí
	 */
	EX_realNZpripSENT?: string

	/**
	 * EX_ck_scan
	 */
	EX_ck_scan?: string

	/**
	 * Blokace kopírovat ceny (jen RS)
	 */
	EX_CopyPricesRS?: string

	/**
	 * NZ datum aukce
	 */
	EX_Dat_aukce?: Date

	/**
	 * NZ připravena částeč
	 */
	EX_readyNZcast?: string

	/**
	 * Připraveno k poptání
	 */
	EX_readyFORpopt?: string

	/**
	 * Nevkládat rozbalné
	 */
	EX_NeRozbalne?: string

	/**
	 * EX_nKonverze
	 */
	EX_nKonverze?: number

	/**
	 * NZ zadána od zákazní
	 */
	EX_zadNZ?: string

	/**
	 * NZ do zakázky
	 */
	EX_zakNZ?: number

	/**
	 * EX_Uni_CSV
	 */
	EX_Uni_CSV?: string

	/**
	 * EX_EM_Tel
	 */
	EX_EM_Tel?: string

	/**
	 * EX_EM_Email
	 */
	EX_EM_Email?: string

	/**
	 * EX_EM_Jmeno
	 */
	EX_EM_Jmeno?: string

	/**
	 * NZ připravena
	 */
	EX_readyNZ?: string

	/**
	 * Neslučovat dodací listy
	 */
	EX_NeslucovatDLV?: string

	/**
	 * EX_CVyst
	 */
	EX_CVyst?: Date

	/**
	 * EX_GenpopNZ
	 */
	EX_GenpopNZ?: string

	/**
	 * Neslučovat zakázky
	 */
	EX_Neslucovat?: string

	/**
	 * undefined
	 */
	BookmarkImgCalc?: string

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Číslo
	 */
	DocumentNumberCalc?: number

	/**
	 * SQLSequenceOrderCalc
	 */
	SQLSequenceOrderCalc?: number

	/**
	 * Datum pro "_ver" pole
	 */
	VersionedFieldDateCalc?: Date

	/**
	 * Odkaz
	 */
	PictureCalc?: string

	/**
	 * DPH
	 */
	AmountVATCalc?: number

	/**
	 * Brutto c
	 */
	AmountGrossCalc?: number

	/**
	 * DPH M
	 */
	AmountVATCCalc?: number

	/**
	 * Brutto cm
	 */
	AmountGrossCCalc?: number

	/**
	 * Kurz
	 */
	ExchangeRateCalc?: number

	/**
	 * Měna vl. f.
	 */
	CompanyCurrencyCalc?: string

	/**
	 * PrepMena
	 */
	CurrencySwitchCalc?: number

	/**
	 * V
	 */
	SNReceiptReleaseFilterImgCalc?: string

	/**
	 * Fi_C_Zbo
	 */
	FilterArticleIdCalc?: number

	/**
	 * Fi_Zap
	 */
	IsFilterOnCalc?: string

	/**
	 * Fi_ZapSar
	 */
	FilterBatchOnBitCalc?: number

	/**
	 * Netto p
	 */
	TotalItemPlannedPriceNetCalc?: number

	/**
	 * Skl p
	 */
	TotalItemPlStockPriceCalc?: number

	/**
	 * Skl pm
	 */
	TotalItemPlStockPriceCCalc?: number

	/**
	 * Zisk
	 */
	ProfitCalc?: number

	/**
	 * Zisk M
	 */
	ProfitCCalc?: number

	/**
	 * Zisk
	 */
	MarginCalc?: number

	/**
	 * V měně
	 */
	CurrencyBySwitchCalc?: string

	/**
	 * K
	 */
	AssignedContractRIDCalc?: number

	/**
	 * Brutto c
	 */
	DocumentAmountGrossCalc?: number

	/**
	 * Brutto cm
	 */
	DocumentAmountGrossCCalc?: number

	/**
	 * Reduction value
	 */
	ReductionValueCalc?: number

	/**
	 * Reduction abs
	 */
	ReductionTypeBitCalc?: number

	/**
	 * Reduction abs.
	 */
	ReductionAmountCalc?: number

	/**
	 * Reduction zn.
	 */
	ReductionSignCalc?: number

	/**
	 * Reduction zn.
	 */
	ReductionSignImgCalc?: string

	/**
	 * Požadované dodání
	 */
	DeliveryDateCalc?: Date

	/**
	 * Čas dodání
	 */
	DeliveryTimeCalc?: Date

	/**
	 * Plánovaná přirážka
	 */
	PlannedSurchargeAmountCalc?: number

	/**
	 * Skl p
	 */
	TotalItemPlStockBySettPriceCalc?: number

	/**
	 * Datum rozvozu
	 */
	DeliveryOrderDateCalc?: Date

	/**
	 * Čerpáno O/Z
	 */
	AdvanceDeductedAmountCalc?: number

	/**
	 * Čerpáno dod.
	 */
	AdvDeductTrPartAmountCalc?: number

	/**
	 * Dočerpat O/P
	 */
	AdvanceRemainsToDedcAmountCalc?: number

	/**
	 * Dočerpat dod.
	 */
	AdvRemainsToDedcTrPartAmountCalc?: number

	/**
	 * Čerpáno O/Z M
	 */
	AdvanceDeductedAmountCCalc?: number

	/**
	 * Čerpáno dod.
	 */
	AdvDeductedTrPartAmountCCalc?: number

	/**
	 * Dočerpat O/P M
	 */
	AdvRemainsToDeductAmountCCalc?: number

	/**
	 * Dočerpat dod. M
	 */
	AdvRemainsToDeductTrPartAmountCCalc?: number

	/**
	 * Ex. zál.
	 */
	ExistsNotDeductedAdvanceBitCalc?: number

	/**
	 * Splatnost
	 */
	PayConditionsMaturityDaysCalc?: number

	/**
	 * Nezapl. zál.
	 */
	ExistsAdvanceUnpaidBitCalc?: number

	/**
	 * Nezapl. zál. Z
	 */
	ExistsTrPartAdvNotPaidBitCalc?: number

	/**
	 * undefined
	 */
	InvoiceAddressExistsImgCalc?: string

	/**
	 * undefined
	 */
	DeliveryAddressExistsImgCalc?: string

	/**
	 * undefined
	 */
	PostalAddressExistsImgCalc?: string

	/**
	 * Název z fakt. adresy
	 */
	InvoiceAddressFullNameCalc?: string

	/**
	 * Název z dod. adresy
	 */
	DeliveryAddressFullNameCalc?: string

	/**
	 * Název z koresp. adresy
	 */
	PostalAddressFullNameCalc?: string

	/**
	 * Název z koresp./fakt. adresy
	 */
	PostalInvoiceAddressFullNameCalc?: string

	/**
	 * undefined
	 */
	TotalItemActualPriceNetCalc?: number

	/**
	 * Skl s
	 */
	TotalItemActualStockPriceCalc?: number

	/**
	 * Skl sm
	 */
	TotalItemActualStockPriceCCalc?: number

	/**
	 * undefined
	 */
	ActualProfitCalc?: number

	/**
	 * Skut. zisk
	 */
	ActualProfitCCalc?: number

	/**
	 * Skut. marže
	 */
	ActualMarginAmountCalc?: number

	/**
	 * Skutečná přirážka
	 */
	ActualSurchargeAmountCalc?: number

	/**
	 * Plán. skladová cena
	 */
	TotalItemActStockSettPriceCalc?: number

	/**
	 * Ex. zál. (zák.)
	 */
	ExistsNotDedcAdvTrPartBitCalc?: number

	/**
	 * undefined
	 */
	QRCodeCZBankOrderStandardCalc?: string

	/**
	 * undefined
	 */
	QRCodeCZBankOrderLargeCalc?: string

	/**
	 * undefined
	 */
	QRCodeSKBankOrderPayBySquareCalc?: string

	/**
	 * undefined
	 */
	QRSKBankOrderCSOBCalc?: string

	/**
	 * Interní text
	 */
	InternalTextCalc?: string

	/**
	 * ExternalAddressCalc
	 */
	ExternalAddressCalc?: string

	/**
	 * TradingPartnerInternalTextCalc
	 */
	TradingPartnerInternalTextCalc?: string

	/**
	 * Cizí měna
	 */
	ForeignCurrencyBitCalc?: number

	/**
	 * Datum od
	 */
	DateFromCalc?: Date

	/**
	 * Čas od
	 */
	StartTimeCalc?: Date

	/**
	 * Datum do
	 */
	DateToCalc?: Date

	/**
	 * Čas do
	 */
	EndTimeCalc?: Date

	/**
	 * Fakturační adresa
	 */
	InvoiceAddressPlainCalc?: string

	/**
	 * Dodací adresa
	 */
	DeliveryAddressPlainCalc?: string

	/**
	 * Korespondenční adresa
	 */
	PostalAddressPlainCalc?: string

	/**
	 * Chybné částky
	 */
	IncorrectAmountBitCalc?: number

	/**
	 * Zapnuto DPH
	 */
	VATTurnedOnBitCalc?: number

	/**
	 * Výpočet DPH shora
	 */
	VATGrossCalcMethodBitCalc?: number

	/**
	 * Typy daní v položkách
	 */
	ItemTaxTypeCalc?: string

	/**
	 * QR faktura
	 */
	QRCZInvoiceCalc?: string

	/**
	 * DIČ
	 */
	VATRegNumberCalc?: string

	/**
	 * Firma
	 */
	TaxTradingPartnerIdCalc?: number

	/**
	 * Prodej pouze z ceníku
	 */
	ArticleFromPriceListIdCalc?: number

	/**
	 * undefined
	 */
	TransportMethodRIDCalc?: number

	/**
	 * Hmotnost netto
	 */
	TotalWeightNetCalc?: number

	/**
	 * Hmotnost brutto
	 */
	TotalWeightGrossCalc?: number

	/**
	 * undefined
	 */
	DeliveryOrderItemRIDCalc?: number

	/**
	 * undefined
	 */
	DeliveryOrderTimeCalc?: Date

	/**
	 * Objem celkem
	 */
	TotalVolumePARAMCalc?: number

	/**
	 * Firma
	 */
	TaxTradingPartnerNameCalc?: string

	/**
	 * Požadovaný začátek - datum
	 */
	RequiredStartDateCalc?: Date

	/**
	 * Požadovaný začátek - čas
	 */
	RequiredStartTimeCalc?: Date

	/**
	 * Požadovaný konec - datum
	 */
	RequiredEndDateCalc?: Date

	/**
	 * Požadovaný konec - čas
	 */
	RequiredEndTimeCalc?: Date

	/**
	 * Zakódované RID
	 */
	RIDForEShopCalc?: string

	/**
	 * Číslo bankovního účtu
	 */
	BankAccountCalc?: string

	/**
	 * Kód banky
	 */
	BankCodeCalc?: string

	/**
	 * SWIFT
	 */
	SWIFTCalc?: string

	/**
	 * IBAN
	 */
	IBANCalc?: string

	/**
	 * Časové razítko vykrytí
	 */
	CoverageTimeStampCalc?: Date

	/**
	 * Celé jméno kontaktní osoby
	 */
	ContactPersonFullNameCalc?: string

	/**
	 * Jméno kontaktní osoby
	 */
	ContactPersonNameCalc?: string

	/**
	 * Příjmení kontaktní osoby
	 */
	ContactPersonSurnameCalc?: string

	/**
	 * E-mail kontaktní osoby
	 */
	ContactPersonEmailCalc?: string

	/**
	 * Telefon kontaktní osoby
	 */
	ContactPersonPhoneCalc?: string

	/**
	 * Zasílat informace
	 */
	IsContactPersonSendInfoCalc?: string

	/**
	 * Režim náhradního plnění
	 */
	FacultativeCompensationBitCalc?: number

	/**
	 * Variabilní symbol pro platbu z e-shopu
	 */
	EshopReferenceNumberCalc?: string

	/**
	 * Zaplaceno
	 */
	IsEshopPaidCalc?: string

	/**
	 * Potvrzeno na - datum
	 */
	DeliveryConfirmedForDateCalc?: Date

	/**
	 * Potvrzeno na - čas
	 */
	DeliveryConfirmedForTimeCalc?: Date

	/**
	 * Datum expedice
	 */
	DispatchDateCalc?: Date

	/**
	 * Čas expedice
	 */
	DispatchTimeCalc?: Date

	/**
	 * Častka pro platbu na eshopu
	 */
	EshopToPaymentAmountCalc?: number

	/**
	 * undefined
	 */
	TotalPromotionPriceCalc?: number

	/**
	 * undefined
	 */
	CombPromotionTotalPriceCalc?: number

	/**
	 * undefined
	 */
	PromotionDiscountCalc?: number

	/**
	 * undefined
	 */
	OrderConfirmationUrlCalc?: string

	/**
	 * undefined
	 */
	TextHeaderMemoCalc?: string

	/**
	 * undefined
	 */
	TextFooterMemoCalc?: string

	/**
	 * Poslední komentář
	 */
	LastCommentParamCalc?: string

	/**
	 * undefined
	 */
	OwnAddressIdCalc?: number

	/**
	 * undefined
	 */
	OwnAddressNameCalc?: string

	/**
	 * Doklad
	 */
	DocumentIdentificationCalc?: string

	/**
	 * EAN kód
	 */
	BarcodeCalc?: string

	/**
	 * IČO
	 */
	CompanyRegNumberCalc?: string

	/**
	 * DIČ
	 */
	VATRegNumberNotFullCalc?: string

	/**
	 * Daňové číslo
	 */
	TaxNumberCalc?: string

	/**
	 * undefined
	 */
	IsReversedExchangeRateViewCalc?: string

	/**
	 * VIES datum
	 */
	RatingViesDateCalc?: Date

	/**
	 * VIES hodnocení
	 */
	RatingViesValueCalc?: number

	/**
	 * VIES konzultace
	 */
	RatingViesKeyCalc?: string

	/**
	 * Hmotnost
	 */
	CF_hmotn_zak?: number

	/**
	 * Dluznik
	 */
	CF_splZAK?: number

	/**
	 * Stav zakázky
	 */
	CF_Stav_zak?: number

	/**
	 * Stav zakázky
	 */
	BMP_Stav_zak?: string

	/**
	 * Stav Nak
	 */
	CF_NZ_StavZak_NA?: number

	/**
	 * Stav Pro
	 */
	CF_NZ_StavZak_PR?: number

	/**
	 * Zisk
	 */
	CF_M_Kc_Zisk?: number

	/**
	 * Náklady
	 */
	CF_M_Kc_Naklady?: number

	/**
	 * Marže
	 */
	CF_M_Kc_Marze?: number

	/**
	 * Přirážka
	 */
	CF_M_Kc_Prirazka?: number

	/**
	 * NZ úspěšnost
	 */
	CF_NZ_uspesnost?: number

	/**
	 * Zak_mandant
	 */
	CF_Zak_mandant?: string

	/**
	 * Právo na záznam
	 */
	RightGroupIdCalc?: number

	/**
	 * Plánovaný sklad
	 */
	@Type(() => TWarehouseDM)
	PlannedWarehouseId?: TWarehouseDM

	/**
	 * Manip.technika
	 */
	@Type(() => TWMSEquipmentDM)
	HandlingEquipmentId?: TWMSEquipmentDM

	/**
	 * Skladník
	 */
	@Type(() => TOfficerDocumentDM)
	WarehousemanId?: TOfficerDocumentDM

	/**
	 * Fronta
	 */
	@Type(() => TWMSQueueDM)
	QueueId?: TWMSQueueDM

	/**
	 * Priorita
	 */
	@Type(() => Priority)
	PriorityId?: Priority

	/**
	 * Linka
	 */
	@Type(() => TDeliveryLineDM)
	DeliveryLineId?: TDeliveryLineDM

	/**
	 * Servisní úkon
	 */
	@Type(() => TScheduledServiceOperationDM)
	ServiceOperationRID?: TScheduledServiceOperationDM

	/**
	 * Servisované zařízení
	 */
	@Type(() => TServicedDeviceDM)
	ServicedDeviceRID?: TServicedDeviceDM

	/**
	 * Kód 6
	 */
	@Type(() => TCode6DocumentDM)
	Code6Id?: TCode6DocumentDM

	/**
	 * Kód 5
	 */
	@Type(() => TCode5DocumentDM)
	Code5Id?: TCode5DocumentDM

	/**
	 * Kód 4
	 */
	@Type(() => TCode4DocumentDM)
	Code4Id?: TCode4DocumentDM

	/**
	 * Kód 3
	 */
	@Type(() => TCode3DocumentDM)
	Code3Id?: TCode3DocumentDM

	/**
	 * Zp.odběru
	 */
	@Type(() => TShippingMethodDM)
	ShippingMethodId?: TShippingMethodDM

	/**
	 * Prodejní akce
	 */
	@Type(() => TSalesActionDM)
	SellingActionRID?: TSalesActionDM

	/**
	 * Ceník
	 */
	@Type(() => TPriceListDM)
	PriceListRID?: TPriceListDM

	/**
	 * Středisko
	 */
	@Type(() => TCostCentreCollectionDocumentDM)
	CostCentreId?: TCostCentreCollectionDocumentDM

	/**
	 * Obj. z e-shopu
	 */
	@Type(() => TEshopOrderDM)
	EshopOrderRID?: TEshopOrderDM

	/**
	 * Prostředek
	 */
	@Type(() => TDeviceDocumentDM)
	DeviceRID?: TDeviceDocumentDM

	/**
	 * Dodací podmínky
	 */
	@Type(() => JCDP20_a1)
	DeliveryTermsId?: JCDP20_a1

	/**
	 * Období
	 */
	@Type(() => TPeriodDM)
	BusinessYearId?: TPeriodDM

	/**
	 * Kniha
	 */
	@Type(() => TBookDM)
	BookId?: TBookDM

	/**
	 * Kontaktní osoba
	 */
	@Type(() => TContactPersonDM)
	ContactPersonId?: TContactPersonDM

	/**
	 * Typ daně
	 */
	@Type(() => TTaxTypeDM)
	TaxTypeId?: TTaxTypeDM

	/**
	 * Bank. účet
	 */
	@Type(() => TBookDM)
	BankBookId?: TBookDM

	/**
	 * Zp. dopravy
	 */
	@Type(() => TTransportMethodDM)
	TransportMethodRID?: TTransportMethodDM

	/**
	 * Kód zakázky
	 */
	@Type(() => TContractCodeDocumentDM)
	ContractCodeRID?: TContractCodeDocumentDM

	/**
	 * Stav
	 */
	@Type(() => ContractStatus)
	StatusId?: ContractStatus

	/**
	 * Typ plánu
	 */
	@Type(() => PlanType)
	PlanTypeId?: PlanType

	/**
	 * Kód 2
	 */
	@Type(() => TCode2DocumentDM)
	Code2Id?: TCode2DocumentDM

	/**
	 * Kód 1
	 */
	@Type(() => TCode1DocumentDM)
	Code1Id?: TCode1DocumentDM

	/**
	 * Referent
	 */
	@Type(() => TOfficerDocumentDM)
	OfficerId?: TOfficerDocumentDM

	/**
	 * Pár.symb.
	 */
	@Type(() => TMatchingSymbolDM)
	MatchingSymbolId?: TMatchingSymbolDM

	/**
	 * Vytvořil
	 */
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM

	/**
	 * Změnil
	 */
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM

	/**
	 * Forma ob.
	 */
	@Type(() => FormOfOrder)
	OrderForm?: FormOfOrder

	/**
	 * Zp.platby
	 */
	@Type(() => TPaymentMethodDM)
	PaymentMethod?: TPaymentMethodDM

	/**
	 * Lhůta
	 */
	@Type(() => TermOfDelivery)
	DeliveryTime?: TermOfDelivery

	/**
	 * Měna
	 */
	@Type(() => TCurrencyDM)
	Currency?: TCurrencyDM

	/**
	 * Cenová skupina
	 */
	@Type(() => TPriceGroupDM)
	PriceGroupId?: TPriceGroupDM

	/**
	 * Odběratel
	 */
	@Type(() => TTradingPartnerDM)
	TradingPartnerId?: TTradingPartnerDM

	/**
	 * EX_Dodavatel
	 */
	@Type(() => TTradingPartnerDM)
	EX_Dodavatel?: TTradingPartnerDM

	/**
	 * NZ partner
	 */
	@Type(() => TPartnerDM)
	EX_NZ_partner?: TPartnerDM

	/**
	 * Období
	 */
	@Type(() => TPeriodDM)
	BusinessYearIdCalc?: TPeriodDM

	/**
	 * P
	 */
	@Type(() => TTextDM)
	ConfirmedOrCanceledIdCalc?: TTextDM

	/**
	 * Upraveno
	 */
	@Type(() => TTextDM)
	EditedAmountBitCalc?: TTextDM

	/**
	 * CFFi_PlMi
	 */
	@Type(() => TTextDM)
	SNRecRelFilterStateCalc?: TTextDM

	/**
	 * Fi_Zaruky
	 */
	@Type(() => TBatchDM)
	FilterBatchIdCalc?: TBatchDM

	/**
	 * d
	 */
	@Type(() => TTextDM)
	DeliveryNoteDocExistsIdCalc?: TTextDM

	/**
	 * f
	 */
	@Type(() => TTextDM)
	InvoiceDocumentExistsIdCalc?: TTextDM

	/**
	 * o
	 */
	@Type(() => TTextDM)
	PurchaseOrderDocumentExistsIdCalc?: TTextDM

	/**
	 * r
	 */
	@Type(() => TTextDM)
	ReservingCardDocumentExistsIdCalc?: TTextDM

	/**
	 * v
	 */
	@Type(() => TTextDM)
	ReleaseNoteDocumentExistsIdCalc?: TTextDM

	/**
	 * W
	 */
	@Type(() => TTextDM)
	JobCardDocumentExistsIdCalc?: TTextDM

	/**
	 * Fakt. adresa
	 */
	@Type(() => TTradingPartnerDM)
	InvoiceAddressTrPartIdCalc?: TTradingPartnerDM

	/**
	 * Dod. adresa
	 */
	@Type(() => TTradingPartnerDM)
	DeliveryAddressTrPartIdCalc?: TTradingPartnerDM

	/**
	 * Cizí měna
	 */
	@Type(() => TTextDM)
	ForeignCurrencySwitchBitCalc?: TTextDM

	/**
	 * DPH
	 */
	@Type(() => TTextDM)
	ForeignVATBitCalc?: TTextDM

	/**
	 * Fakturační adresa
	 */
	@Type(() => TAddressDM)
	InvoiceAddressIdCalc?: TAddressDM

	/**
	 * Dodací adresa
	 */
	@Type(() => TAddressDM)
	DeliveryAddressIdCalc?: TAddressDM

	/**
	 * Korespondenční adresa
	 */
	@Type(() => TAddressDM)
	PostalAddressIdCalc?: TAddressDM

	/**
	 * Fakturační adresa (tisk)
	 */
	@Type(() => TAddressDM)
	FullInvoiceAddressIdCalc?: TAddressDM

	/**
	 * Dodací adresa (tisk)
	 */
	@Type(() => TAddressDM)
	FullDeliveryAddressIdCalc?: TAddressDM

	/**
	 * Korespondenční adresa (tisk)
	 */
	@Type(() => TAddressDM)
	FullPostalAddressIdCalc?: TAddressDM

	/**
	 * Koresp./Fakt. adresa (tisk)
	 */
	@Type(() => TAddressDM)
	FullPostalInvoiceAddressIdCalc?: TAddressDM

	/**
	 * Kor. adresa
	 */
	@Type(() => TTradingPartnerDM)
	PostalAddressTrPartIdCalc?: TTradingPartnerDM

	/**
	 * P/S
	 */
	@Type(() => TTextDM)
	ConfirmedOrCanceled2IdCalc?: TTextDM

	/**
	 * undefined
	 */
	@Type(() => TQuotationDM)
	MarketingDocumentRIDCalc?: TQuotationDM

	/**
	 * Jedn. adresa - fakturační
	 */
	@Type(() => TMarketingAddressDM)
	SimpleInvoiceAddressRIDCalc?: TMarketingAddressDM

	/**
	 * Jedn. adresa - dodací
	 */
	@Type(() => TMarketingAddressDM)
	SimpleDeliveryAddressRIDCalc?: TMarketingAddressDM

	/**
	 * Jedn. adresa - korespondenční
	 */
	@Type(() => TMarketingAddressDM)
	SimplePostalAddressRIDCalc?: TMarketingAddressDM

	/**
	 * undefined
	 */
	@Type(() => TDeliveryPlanDM)
	DeliveryPlanRIDCalc?: TDeliveryPlanDM

	/**
	 * undefined
	 */
	@Type(() => TDeliveryOrderDM)
	DeliveryOrderRIDCalc?: TDeliveryOrderDM

	/**
	 * undefined
	 */
	@Type(() => TDeliveryPlanItemBookDM)
	DeliveryPlanItemRIDCalc?: TDeliveryPlanItemBookDM

	/**
	 * Stav v příchozí EDI frontě
	 */
	@Type(() => TTextDM)
	EdiQueueMessageInStatusIdCalc?: TTextDM

	/**
	 * Zařazení v příchozí EDI frontě
	 */
	@Type(() => TEdiQueueDM)
	EdiQueueMessageInRIDCalc?: TEdiQueueDM

	/**
	 *  
	 */
	@Type(() => TTextDM)
	CoveredBitCalc?: TTextDM

	/**
	 *  
	 */
	@Type(() => TTextDM)
	CoveredByStockBitCalc?: TTextDM

	/**
	 * Zasílat objednávku po částech
	 */
	@Type(() => TTextDM)
	SendByPartsBitCalc?: TTextDM

	/**
	 * Objednávka e-shopu
	 */
	@Type(() => TEshopOrderDM)
	EshopOrderRIDCalc?: TEshopOrderDM

	/**
	 * undefined
	 */
	@Type(() => TTextDM)
	CoveredExtendedCalc?: TTextDM

	/**
	 * D
	 */
	@Type(() => TTextDM)
	DocumentExistsCalc?: TTextDM

	/**
	 * undefined
	 */
	@Type(() => TMarketingActivityTypeDM)
	ActivityTypeRIDCalc?: TMarketingActivityTypeDM

	/**
	 * SP
	 */
	@Type(() => TTextDM)
	ProcessStateIdCalc?: TTextDM

	/**
	 * Aktuální proces
	 */
	@Type(() => TWorkflowProcessDM)
	CurrentProcessIdCalc?: TWorkflowProcessDM

	/**
	 * undefined
	 */
	@Type(() => TActivityDM)
	ActivityIdCalc?: TActivityDM

	/**
	 * undefined
	 */
	@Type(() => TTradingPartnerDM)
	OwnTradingPartnerIdCalc?: TTradingPartnerDM

	/**
	 * Storno
	 */
	@Type(() => TTextDM)
	CanceledRecordBitCalc?: TTextDM

	/**
	 * Období
	 */
	@Type(() => TPeriodDM)
	CurrentBusinessYearIdCalc?: TPeriodDM

	/**
	 * Sklad
	 */
	@Type(() => TWarehouseDM)
	CurrentWarehouseIdCalc?: TWarehouseDM

	/**
	 * Odběratel
	 */
	@Type(() => TTradingPartnerDM)
	TradingPartnerByIdInputIdCalc?: TTradingPartnerDM

	/**
	 * Odběratel
	 */
	@Type(() => TTradingPartnerDM)
	TradingPartnerByAbbr2InputIdCalc?: TTradingPartnerDM

	/**
	 * Odběratel
	 */
	@Type(() => TTradingPartnerDM)
	TradingPartnerNameInputIdCalc?: TTradingPartnerDM

	/**
	 * Odběratel
	 */
	@Type(() => TTradingPartnerDM)
	TrPartByCompRegNumberInputIdCalc?: TTradingPartnerDM

	/**
	 * Odkazy
	 */
	@Type(() => TLinkDM)
	LinkChild?: TLinkDM[]

	/**
	 * Komentáře
	 */
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]

	/**
	 * Úkoly
	 */
	@Type(() => TJobDM)
	JobChild?: TJobDM[]

	/**
	 * Zálohy přijaté
	 */
	@Type(() => TAdvanceReceivedDM)
	AdvanceReceiveForDocumentChild?: TAdvanceReceivedDM[]

	/**
	 * Faktury vydané
	 */
	@Type(() => TInvoiceOutDM)
	InvoiceOutChild?: TInvoiceOutDM[]

	/**
	 * Rezervační listy
	 */
	@Type(() => TReservingCardDM)
	ReservingCardChild?: TReservingCardDM[]

	/**
	 * Dodací listy
	 */
	@Type(() => TDeliveryNoteDM)
	DeliveryNoteChild?: TDeliveryNoteDM[]

	/**
	 * Objedávky přijaté
	 */
	@Type(() => TOrderConfirmationDM)
	OrderConfirmationChild?: TOrderConfirmationDM[]

	/**
	 * Výdejky
	 */
	@Type(() => TReleaseNoteDM)
	ReleaseNoteChild?: TReleaseNoteDM[]

	/**
	 * undefined
	 */
	@Type(() => TSalesItemBookDM)
	ItemChild?: TSalesItemBookDM[]

	/**
	 * Platební podmínky
	 */
	@Type(() => TPaymentConditionTradeDM)
	PaymentConditionChild?: TPaymentConditionTradeDM[]

	/**
	 * Adresáti
	 */
	@Type(() => TRelatedAddressDocumentDM)
	DocumentAddressChild?: TRelatedAddressDocumentDM[]

	/**
	 * Daňová rekapitulace
	 */
	@Type(() => TTaxSummaryDM)
	TaxSummaryChild?: TTaxSummaryDM[]

	/**
	 * Položky prodeje
	 */
	@Type(() => TSalesItemDM)
	SalesItemChild?: TSalesItemDM[]

	/**
	 * undefined
	 */
	@Type(() => TAccountingSalesItemDM)
	AccountingSalesItemChild?: TAccountingSalesItemDM[]

	/**
	 * undefined
	 */
	@Type(() => TTransferNoteDM)
	TransferNoteChild?: TTransferNoteDM[]

	/**
	 * Zálohy odběratele
	 */
	@Type(() => TAdvanceReceivedDM)
	TradingPartnerAdvanceChild?: TAdvanceReceivedDM[]

	/**
	 * undefined
	 */
	@Type(() => TAppliedCouponDM)
	CouponApplicationChild?: TAppliedCouponDM[]

	/**
	 * undefined
	 */
	@Type(() => TAppliedPromotionDM)
	PromotionApplicationChild?: TAppliedPromotionDM[]

	/**
	 * undefined
	 */
	@Type(() => TProductCostingDM)
	ProductCostingChild?: TProductCostingDM[]

	/**
	 * Seznam aktivit
	 */
	@Type(() => TActivityDM)
	ActivityChild?: TActivityDM[]

	/**
	 * Dokumenty
	 */
	@Type(() => TExternalDocumentItemDM)
	ExternalDocumentItemChild?: TExternalDocumentItemDM[]

	/**
	 * Procesy
	 */
	@Type(() => TWorkflowProcessAsItemDM)
	WorkflowProcessChild?: TWorkflowProcessAsItemDM[]

	/**
	 * Text hlavičky
	 */
	@Type(() => TLanguageMemoTranslationDM)
	TextHeaderChild?: TLanguageMemoTranslationDM[]

	/**
	 * Text patičky
	 */
	@Type(() => TLanguageMemoTranslationDM)
	TextFooterChild?: TLanguageMemoTranslationDM[]

	/**
	 * Informace
	 */
	@Type(() => TCustomTSalesOrderDMInformace_P)
	Informace_P?: TCustomTSalesOrderDMInformace_P[]

	/**
	 * Svázané doklady
	 */
	@Type(() => TAttachedDocumentDM)
	AttachedDocumentOldChild?: TAttachedDocumentDM[]

	/**
	 * Poznámky
	 */
	@Type(() => TOldNoteDM)
	DeprecatedNoteOldChild?: TOldNoteDM[]

	/**
	 * Přepočet pokrytí
	 */
	public BulkCoverageRecalculationCOMMAND(parameters: TBulkCoverageRecalculationCOMMANDParams) {
		return this.executeCommand('BulkCoverageRecalculationCOMMAND', parameters)
	}

	/**
	 * Ceny v měně / bez měny
	 */
	public SwitchShowCurrencyCOMMAND() {
		return this.executeCommand('SwitchShowCurrencyCOMMAND')
	}

	/**
	 * Plánované datum zaplacení
	 */
	public PlanPayDateCOMMAND(parameters: TPlanPayDateCOMMANDParams) {
		return this.executeCommand('PlanPayDateCOMMAND', parameters)
	}

	/**
	 * Nastavení platebních podmínek
	 */
	public PaymentConditionSettingCOMMAND(parameters: TPaymentConditionSettingCOMMANDParams) {
		return this.executeCommand('PaymentConditionSettingCOMMAND', parameters)
	}

	/**
	 * Přepočet plateb
	 */
	public PaymentRecalculationCOMMAND() {
		return this.executeCommand('PaymentRecalculationCOMMAND')
	}

	/**
	 * Změna platby archivem
	 */
	public ChangePaymentArchCOMMAND(parameters: TChangePaymentArchCOMMANDParams) {
		return this.executeCommand('ChangePaymentArchCOMMAND', parameters)
	}

	/**
	 * Doplň šarže a umístění
	 */
	public CompleteBatchLocCOMMAND() {
		return this.executeCommand('CompleteBatchLocCOMMAND')
	}

	/**
	 * Vybrat PS z účtu
	 */
	public WizardMatchSymbolCOMMAND() {
		return this.executeCommand('WizardMatchSymbolCOMMAND')
	}

	/**
	 * 
	 */
	public SetSimpleInvoiceAddressCOMMAND(parameters: TSetSimpleInvoiceAddressCOMMANDParams) {
		return this.executeCommand('SetSimpleInvoiceAddressCOMMAND', parameters)
	}

	/**
	 * 
	 */
	public SetSimpleDeliveryAddressCOMMAND(parameters: TSetSimpleDeliveryAddressCOMMANDParams) {
		return this.executeCommand('SetSimpleDeliveryAddressCOMMAND', parameters)
	}

	/**
	 * Přidat položky na podřízený doklad (WS)
	 */
	public AddItemsToSubDocWSCOMMAND(parameters: TAddItemsToSubDocWSCOMMANDParams) {
		return this.executeCommand('AddItemsToSubDocWSCOMMAND', parameters)
	}

	/**
	 * Odebrat položky z podřízeného dokladu (WS)
	 */
	public RemoveItemsFromSubDocWSCOMMAND(parameters: TRemoveItemsFromSubDocWSCOMMANDParams) {
		return this.executeCommand('RemoveItemsFromSubDocWSCOMMAND', parameters)
	}

	/**
	 * Kontejner zboží
	 */
	public AddGoodsToContainerCOMMAND(parameters: TAddGoodsToContainerCOMMANDParams) {
		return this.executeCommand('AddGoodsToContainerCOMMAND', parameters)
	}

	/**
	 * Částečné potvrzení
	 */
	public PartialConfirmCOMMAND() {
		return this.executeCommand('PartialConfirmCOMMAND')
	}

	/**
	 * Částečné odpotvrzení
	 */
	public PartialUnconfirmCOMMAND() {
		return this.executeCommand('PartialUnconfirmCOMMAND')
	}

	/**
	 * Částečné potvrzení
	 */
	public BulkPartialConfirmCOMMAND() {
		return this.executeCommand('BulkPartialConfirmCOMMAND')
	}

	/**
	 * Částečné odpotvrzení
	 */
	public BulkPartialUnconfirmCOMMAND() {
		return this.executeCommand('BulkPartialUnconfirmCOMMAND')
	}

	/**
	 * Přecenění k rozvahovému dni
	 */
	public BulkRevaluationAtBalanceDayCOMMAND() {
		return this.executeCommand('BulkRevaluationAtBalanceDayCOMMAND')
	}

	/**
	 * Storno přecenění k rozvahovému dni
	 */
	public BulkCancelRevaluationCOMMAND() {
		return this.executeCommand('BulkCancelRevaluationCOMMAND')
	}

	/**
	 * Změna DUZP
	 */
	public InvoiceDateChangeCOMMAND(parameters: TInvoiceDateChangeCOMMANDParams) {
		return this.executeCommand('InvoiceDateChangeCOMMAND', parameters)
	}

	/**
	 * Zaúčtování s likvidací
	 */
	public AccountAssignmentPostingCOMMAND(parameters: TAccountAssignmentPostingCOMMANDParams) {
		return this.executeCommand('AccountAssignmentPostingCOMMAND', parameters)
	}

	/**
	 * Hromadné zaúčtování s likvidací
	 */
	public BulkAccountAssignmentPostingCOMMAND(parameters: TBulkAccountAssignmentPostingCOMMANDParams) {
		return this.executeCommand('BulkAccountAssignmentPostingCOMMAND', parameters)
	}

	/**
	 * Likvidace
	 */
	public AccountAssignmentCOMMAND() {
		return this.executeCommand('AccountAssignmentCOMMAND')
	}

	/**
	 * Zapnout rychlý filtr
	 */
	public FastFilterSwitchCOMMAND() {
		return this.executeCommand('FastFilterSwitchCOMMAND')
	}

	/**
	 * Povolit editaci
	 */
	public EditModeIDNoCOMMAND() {
		return this.executeCommand('EditModeIDNoCOMMAND')
	}

	/**
	 * Povolit editaci
	 */
	public EditModeVATRegisterNoCOMMAND() {
		return this.executeCommand('EditModeVATRegisterNoCOMMAND')
	}

	/**
	 * Povolit editaci
	 */
	public EditModeTaxNoCOMMAND() {
		return this.executeCommand('EditModeTaxNoCOMMAND')
	}

	/**
	 * Změna kontace
	 */
	public BulkChangePostingKeyCOMMAND(parameters: TBulkChangePostingKeyCOMMANDParams) {
		return this.executeCommand('BulkChangePostingKeyCOMMAND', parameters)
	}

	/**
	 * Aktualizace Plánovaného data zaplacení dle Prodlení Dod./Odb.
	 */
	public BulkUpdatePlannedDateOfPaymentByAvgDelayCOMMAND() {
		return this.executeCommand('BulkUpdatePlannedDateOfPaymentByAvgDelayCOMMAND')
	}

	/**
	 * Zobrazovat data od období: %s
	 */
	public ApplyLowerLimitForData() {
		return this.executeCommand('ApplyLowerLimitForData')
	}

	/**
	 * Potvrzení
	 */
	public BulkConfirmCOMMAND() {
		return this.executeCommand('BulkConfirmCOMMAND')
	}

	/**
	 * Odpotvrzení
	 */
	public BulkUnconfirmCOMMAND() {
		return this.executeCommand('BulkUnconfirmCOMMAND')
	}

	/**
	 * Změna data potvrzení
	 */
	public BulkChangeConfirmationDateCOMMAND(parameters: TBulkChangeConfirmationDateCOMMANDParams) {
		return this.executeCommand('BulkChangeConfirmationDateCOMMAND', parameters)
	}

	/**
	 * Storno
	 */
	public BulkCancelCOMMAND() {
		return this.executeCommand('BulkCancelCOMMAND')
	}

	/**
	 * Odstorno
	 */
	public BulkUnCancelCOMMAND() {
		return this.executeCommand('BulkUnCancelCOMMAND')
	}

	/**
	 * Potvrzení
	 */
	public ConfirmCOMMAND() {
		return this.executeCommand('ConfirmCOMMAND')
	}

	/**
	 * Odpotvrzení
	 */
	public UnconfirmCOMMAND() {
		return this.executeCommand('UnconfirmCOMMAND')
	}

	/**
	 * Změna data potvrzení
	 */
	public ChangeConfirmationDateCOMMAND(parameters: TChangeConfirmationDateCOMMANDParams) {
		return this.executeCommand('ChangeConfirmationDateCOMMAND', parameters)
	}

	/**
	 * Storno/Odstorno
	 */
	public CancelCOMMAND() {
		return this.executeCommand('CancelCOMMAND')
	}

	/**
	 * Zrušení stavu
	 */
	public ClearRecordStatusCOMMAND() {
		return this.executeCommand('ClearRecordStatusCOMMAND')
	}

	/**
	 * Změna stavu
	 */
	public ChangeRecordStatusCOMMAND() {
		return this.executeCommand('ChangeRecordStatusCOMMAND')
	}

	/**
	 * 
	 */
	public ChangeRecordStatusSWSCOMMAND(parameters: TChangeRecordStatusSWSCOMMANDParams) {
		return this.executeCommand('ChangeRecordStatusSWSCOMMAND', parameters)
	}

	/**
	 * Zrušení stavu
	 */
	public BulkClearRecordStatusCOMMAND() {
		return this.executeCommand('BulkClearRecordStatusCOMMAND')
	}

	/**
	 * Změna stavu
	 */
	public BulkChangeRecordStatusCOMMAND() {
		return this.executeCommand('BulkChangeRecordStatusCOMMAND')
	}

	/**
	 * Změna atributů
	 */
	public BulkChangeAttributesCOMMAND(parameters: TBulkChangeAttributesCOMMANDParams) {
		return this.executeCommand('BulkChangeAttributesCOMMAND', parameters)
	}

	/**
	 * Aktualizace sady atributů
	 */
	public BulkActualizeAttributesCOMMAND(parameters: TBulkActualizeAttributesCOMMANDParams) {
		return this.executeCommand('BulkActualizeAttributesCOMMAND', parameters)
	}

	/**
	 * Aktualizace sady atributů
	 */
	public ActualizeAttributesCOMMAND() {
		return this.executeCommand('ActualizeAttributesCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public ChangeGroupRightCOMMAND() {
		return this.executeCommand('ChangeGroupRightCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public BulkChangeGroupRightCOMMAND() {
		return this.executeCommand('BulkChangeGroupRightCOMMAND')
	}

	/**
	 * Aktualizace počítaných polí
	 */
	public BulkUpdateCalcFieldsCOMMAND() {
		return this.executeCommand('BulkUpdateCalcFieldsCOMMAND')
	}

	/**
	 * Kontrola legislativy
	 */
	public SwitchOffLegislationValidationCOMMAND() {
		return this.executeCommand('SwitchOffLegislationValidationCOMMAND')
	}
}

export type TBulkCoverageRecalculationCOMMANDParams = {
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TPlanPayDateCOMMANDParams = {
	/**
	 * Datum
	 */
	Value?: Date
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TPaymentConditionSettingCOMMANDParams = {
	/**
	 * Při zaplacení do
	 */
	PACOF_Days1?: number
	/**
	 * Skonto
	 */
	PACOF_Perc1?: number
	/**
	 * Částka
	 */
	PACOF_Amount1?: number
	/**
	 * Při zaplacení do
	 */
	PACOF_Days2?: number
	/**
	 * Skonto
	 */
	PACOF_Perc2?: number
	/**
	 * Částka
	 */
	PACOF_Amount2?: number
	/**
	 * Skonto z netto částky
	 */
	PACOF_Netto?: boolean
	/**
	 * NalezenaSplatnost
	 */
	NalezenaSplatnost?: boolean
	/**
	 * NalezenoSkonto1
	 */
	NalezenoSkonto1?: boolean
	/**
	 * NalezenoSkonto2
	 */
	NalezenoSkonto2?: boolean
	/**
	 * UniqSplatnost
	 */
	UniqSplatnost?: number
	/**
	 * UniqSkonto1
	 */
	UniqSkonto1?: number
	/**
	 * UniqSkonto2
	 */
	UniqSkonto2?: number
	/**
	 * BruttoM
	 */
	BruttoM?: number
	/**
	 * NettoM
	 */
	NettoM?: number
}

export type TChangePaymentArchCOMMANDParams = {
	/**
	 * Zaplaceno archivem
	 */
	PaidA?: number
	/**
	 * CurrencyCNG
	 */
	CurrencyCNG?: string
	/**
	 * Zaplaceno archivem M
	 */
	PaidCA?: number
	/**
	 * Currency
	 */
	Currency?: string
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TSetSimpleInvoiceAddressCOMMANDParams = {
	/**
	 * Name
	 */
	Name?: string
	/**
	 * Street
	 */
	Street?: string
	/**
	 * Number
	 */
	Number?: string
	/**
	 * Town
	 */
	Town?: string
	/**
	 * PostCode
	 */
	PostCode?: string
	/**
	 * Region
	 */
	Region?: string
	/**
	 * Country
	 */
	Country?: number
	/**
	 * Email
	 */
	Email?: string
	/**
	 * Phone
	 */
	Phone?: string
	/**
	 * Comment
	 */
	Comment?: string
	/**
	 * Fax
	 */
	Fax?: string
	/**
	 * IdNo
	 */
	IdNo?: string
	/**
	 * VatIdNo
	 */
	VatIdNo?: string
	/**
	 * PickupPoint
	 */
	PickupPoint?: string
}

export type TSetSimpleDeliveryAddressCOMMANDParams = {
	/**
	 * Name
	 */
	Name?: string
	/**
	 * Street
	 */
	Street?: string
	/**
	 * Number
	 */
	Number?: string
	/**
	 * Town
	 */
	Town?: string
	/**
	 * PostCode
	 */
	PostCode?: string
	/**
	 * Region
	 */
	Region?: string
	/**
	 * Country
	 */
	Country?: number
	/**
	 * Email
	 */
	Email?: string
	/**
	 * Phone
	 */
	Phone?: string
	/**
	 * Comment
	 */
	Comment?: string
	/**
	 * Fax
	 */
	Fax?: string
	/**
	 * IdNo
	 */
	IdNo?: string
	/**
	 * VatIdNo
	 */
	VatIdNo?: string
	/**
	 * PickupPoint
	 */
	PickupPoint?: string
}

export type TAddItemsToSubDocWSCOMMANDParams = {
	/**
	 * Seznam RIDů položek oddělených středníkem
	 */
	ItemRIDs?: string
	/**
	 * Faktura vydaná
	 */
	InvoiceOut?: boolean
	/**
	 * Dodací list
	 */
	DeliveryNote?: boolean
	/**
	 * Výdejka
	 */
	ReleaseNote?: boolean
	/**
	 * Objednávka přijatá
	 */
	OrderConfirmation?: boolean
	/**
	 * Rezervační list
	 */
	ReservingCard?: boolean
	/**
	 * Faktura přijatá
	 */
	InvoiceIn?: boolean
	/**
	 * Potvrzení dodání
	 */
	DeliveryConfirmation?: boolean
	/**
	 * Příjemka
	 */
	ReceiptCard?: boolean
}

export type TRemoveItemsFromSubDocWSCOMMANDParams = {
	/**
	 * Seznam RIDů položek oddělených středníkem
	 */
	ItemRIDs?: string
	/**
	 * Faktura vydaná
	 */
	InvoiceOut?: boolean
	/**
	 * Dodací list
	 */
	DeliveryNote?: boolean
	/**
	 * Výdejka
	 */
	ReleaseNote?: boolean
	/**
	 * Objednávka přijatá
	 */
	OrderConfirmation?: boolean
	/**
	 * Rezervační list
	 */
	ReservingCard?: boolean
	/**
	 * Faktura přijatá
	 */
	InvoiceIn?: boolean
	/**
	 * Potvrzení dodání
	 */
	DeliveryConfirmation?: boolean
	/**
	 * Příjemka
	 */
	ReceiptCard?: boolean
}

export type TAddGoodsToContainerCOMMANDParams = {
	/**
	 * Přidat záznamy do kontejneru
	 */
	AddToExistingFilter?: boolean
	/**
	 * Vytvořit kontejner obsahující pouze tyto záznamy
	 */
	CreateNewFilter?: boolean
	/**
	 * Kontejner vytvořit podle vybraného dokladu
	 */
	OnlyActualDocument?: boolean
	/**
	 * Kontejner vytvořit podle označených položek vybraného dokladu
	 */
	OnlyActualDocumentSelectedItems?: boolean
	/**
	 * Kontejner vytvořit podle všech označených dokladů
	 */
	AllSelectedDocuments?: boolean
	/**
	 * Kontejner vytvořit podle všech dokladů v kontejneru/filtru
	 */
	AllDocumentsInFilter?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TInvoiceDateChangeCOMMANDParams = {
	/**
	 * VATDate
	 */
	VATDate?: Date
}

export type TAccountAssignmentPostingCOMMANDParams = {
	/**
	 * Zaúčtuje
	 */
	ACCPosting?: number
	/**
	 * Kniha
	 */
	ACCBook?: number
	/**
	 * Období
	 */
	ACCBussinessYear?: number
	/**
	 * Datum zaúčtování
	 */
	ACCPostingDate?: Date
	/**
	 * Datum od
	 */
	ACCDateFrom?: Date
	/**
	 * Datum do
	 */
	ACCDateTo?: Date
	/**
	 * Zachovat ručně změněné záznamy
	 */
	ACCHandChange?: boolean
	/**
	 * Likvidace bez částky
	 */
	ACCNulAmount?: boolean
	/**
	 * Povolit zaúčtovat doklady s jiným obdobím
	 */
	ACCOtherBusinessYear?: boolean
	/**
	 * Zaúčtovat nevyrovnané saldo
	 */
	ACCBalance?: boolean
}

export type TBulkAccountAssignmentPostingCOMMANDParams = {
	/**
	 * Zaúčtuje
	 */
	ACCPosting?: number
	/**
	 * Kniha
	 */
	ACCBook?: number
	/**
	 * Období
	 */
	ACCBussinessYear?: number
	/**
	 * Datum zaúčtování
	 */
	ACCPostingDate?: Date
	/**
	 * Datum od
	 */
	ACCDateFrom?: Date
	/**
	 * Datum do
	 */
	ACCDateTo?: Date
	/**
	 * Zachovat ručně změněné záznamy
	 */
	ACCHandChange?: boolean
	/**
	 * Likvidace bez částky
	 */
	ACCNulAmount?: boolean
	/**
	 * Povolit zaúčtovat doklady s jiným obdobím
	 */
	ACCOtherBusinessYear?: boolean
	/**
	 * Zaúčtovat nevyrovnané saldo
	 */
	ACCBalance?: boolean
}

export type TBulkChangePostingKeyCOMMANDParams = {
	/**
	 * Kontace
	 */
	Header_PostingKeyX?: boolean
	/**
	 * Header_PostingKey
	 */
	Header_PostingKey?: number
	/**
	 * Účet
	 */
	Header_AccountX?: boolean
	/**
	 * Header_Account
	 */
	Header_Account?: number
	/**
	 * Účet D
	 */
	Header_CreditAcX?: boolean
	/**
	 * Header_CreditAc
	 */
	Header_CreditAc?: number
	/**
	 * Účet MD
	 */
	Header_DebitAcX?: boolean
	/**
	 * Header_DebitAc
	 */
	Header_DebitAc?: number
	/**
	 * Parovací symbol
	 */
	Header_MatchSymbolX?: boolean
	/**
	 * Header_MatchSymbol
	 */
	Header_MatchSymbol?: number
	/**
	 * Středisko
	 */
	Header_CostCentreX?: boolean
	/**
	 * Header_CostCentre
	 */
	Header_CostCentre?: number
	/**
	 * Ze třediska
	 */
	Header_FromCostCentreX?: boolean
	/**
	 * Header_FromCostCentre
	 */
	Header_FromCostCentre?: number
	/**
	 * Na středisko
	 */
	Header_ToCostCentreX?: boolean
	/**
	 * Header_ToCostCentre
	 */
	Header_ToCostCentre?: number
	/**
	 * Kód zakázky
	 */
	Header_ContractCodeX?: boolean
	/**
	 * Header_ContractCode
	 */
	Header_ContractCode?: number
	/**
	 * Z kódu zakázky
	 */
	Header_FromContractCodeX?: boolean
	/**
	 * Header_FromContractCode
	 */
	Header_FromContractCode?: number
	/**
	 * Na kód zakázky
	 */
	Header_ToContractCodeX?: boolean
	/**
	 * Header_ToContractCode
	 */
	Header_ToContractCode?: number
	/**
	 * Prostředek
	 */
	Header_DeviceX?: boolean
	/**
	 * Header_Device
	 */
	Header_Device?: number
	/**
	 * Z prostředku
	 */
	Header_FromDeviceX?: boolean
	/**
	 * Header_FromDevice
	 */
	Header_FromDevice?: number
	/**
	 * Na prostředek
	 */
	Header_ToDeviceX?: boolean
	/**
	 * Header_ToDevice
	 */
	Header_ToDevice?: number
	/**
	 * Referent
	 */
	Header_OfficerX?: boolean
	/**
	 * Header_Officer
	 */
	Header_Officer?: number
	/**
	 * Z referenta
	 */
	Header_FromOfficerX?: boolean
	/**
	 * Header_FromOfficer
	 */
	Header_FromOfficer?: number
	/**
	 * Na referenta
	 */
	Header_ToOfficerX?: boolean
	/**
	 * Header_ToOfficer
	 */
	Header_ToOfficer?: number
	/**
	 * Kód zboží
	 */
	Header_ArticleCodeX?: boolean
	/**
	 * Header_ArticleCode
	 */
	Header_ArticleCode?: number
	/**
	 * Kód 1
	 */
	Header_Code1X?: boolean
	/**
	 * Header_Code1
	 */
	Header_Code1?: number
	/**
	 * Z kódu 1
	 */
	Header_FromCode1X?: boolean
	/**
	 * Header_FromCode1
	 */
	Header_FromCode1?: number
	/**
	 * Na kód 1
	 */
	Header_ToCode1X?: boolean
	/**
	 * Header_ToCode1
	 */
	Header_ToCode1?: number
	/**
	 * Kód 2
	 */
	Header_Code2X?: boolean
	/**
	 * Header_Code2
	 */
	Header_Code2?: number
	/**
	 * Z kódu 2
	 */
	Header_FromCode2X?: boolean
	/**
	 * Header_FromCode2
	 */
	Header_FromCode2?: number
	/**
	 * Na kód 2
	 */
	Header_ToCode2X?: boolean
	/**
	 * Header_ToCode2
	 */
	Header_ToCode2?: number
	/**
	 * Kód 3
	 */
	Header_Code3X?: boolean
	/**
	 * Header_Code3
	 */
	Header_Code3?: number
	/**
	 * Z kódu 3
	 */
	Header_FromCode3X?: boolean
	/**
	 * Header_FromCode3
	 */
	Header_FromCode3?: number
	/**
	 * Na kód 3
	 */
	Header_ToCode3X?: boolean
	/**
	 * Header_ToCode3
	 */
	Header_ToCode3?: number
	/**
	 * Kód 4
	 */
	Header_Code4X?: boolean
	/**
	 * Header_Code4
	 */
	Header_Code4?: number
	/**
	 * Z kódu 4
	 */
	Header_FromCode4X?: boolean
	/**
	 * Header_FromCode4
	 */
	Header_FromCode4?: number
	/**
	 * Na kód 4
	 */
	Header_ToCode4X?: boolean
	/**
	 * Header_ToCode4
	 */
	Header_ToCode4?: number
	/**
	 * Kód 5
	 */
	Header_Code5X?: boolean
	/**
	 * Header_Code5
	 */
	Header_Code5?: number
	/**
	 * Z kódu 5
	 */
	Header_FromCode5X?: boolean
	/**
	 * Header_FromCode5
	 */
	Header_FromCode5?: number
	/**
	 * Na kód 5
	 */
	Header_ToCode5X?: boolean
	/**
	 * Header_ToCode5
	 */
	Header_ToCode5?: number
	/**
	 * Kód 6
	 */
	Header_Code6X?: boolean
	/**
	 * Header_Code6
	 */
	Header_Code6?: number
	/**
	 * Z kódu 6
	 */
	Header_FromCode6X?: boolean
	/**
	 * Header_FromCode6
	 */
	Header_FromCode6?: number
	/**
	 * Na kód 6
	 */
	Header_ToCode6X?: boolean
	/**
	 * Header_ToCode6
	 */
	Header_ToCode6?: number
	/**
	 * Ignorovat cenu příjmu
	 */
	Header_IgnorePriceIncomX?: boolean
	/**
	 * Header_IgnorePriceIncom
	 */
	Header_IgnorePriceIncom?: number
	/**
	 * Header_ShowIgnorePriceIncom
	 */
	Header_ShowIgnorePriceIncom?: boolean
	/**
	 * Kalk.vz.plán.
	 */
	Header_PlannedPriceCMX?: boolean
	/**
	 * Header_PlannedPriceCM
	 */
	Header_PlannedPriceCM?: number
	/**
	 * Kalk.vz.sklad.
	 */
	Header_StockPriceCMX?: boolean
	/**
	 * Header_StockPriceCM
	 */
	Header_StockPriceCM?: number
	/**
	 * Kalk.vz.nákl.
	 */
	Header_CostPriceCMX?: boolean
	/**
	 * Header_CostPriceCM
	 */
	Header_CostPriceCM?: number
	/**
	 * Kontace faktury
	 */
	Item_PostingKeyIX?: boolean
	/**
	 * Item_PostingKeyI
	 */
	Item_PostingKeyI?: number
	/**
	 * Kontace skladového dokladu
	 */
	Item_PostingKeySX?: boolean
	/**
	 * Item_PostingKeyS
	 */
	Item_PostingKeyS?: number
	/**
	 * Kontace rezervačního listu
	 */
	Item_PostingKeyRX?: boolean
	/**
	 * Item_PostingKeyR
	 */
	Item_PostingKeyR?: number
	/**
	 * Kód zakázky
	 */
	Item_ContractCodeX?: boolean
	/**
	 * Item_ContractCode
	 */
	Item_ContractCode?: number
	/**
	 * Kód zakázky 2
	 */
	Item_ContractCode2X?: boolean
	/**
	 * Item_ContractCode2
	 */
	Item_ContractCode2?: number
	/**
	 * Středisko
	 */
	Item_CostCentreX?: boolean
	/**
	 * Item_CostCentre
	 */
	Item_CostCentre?: number
	/**
	 * Středisko 2
	 */
	Item_CostCentre2X?: boolean
	/**
	 * Item_CostCentre2
	 */
	Item_CostCentre2?: number
	/**
	 * Dosadit z karty zboží
	 */
	Item_CostCentreG?: boolean
	/**
	 * Kód zboží
	 */
	Item_ArticleCodeX?: boolean
	/**
	 * Item_ArticleCode
	 */
	Item_ArticleCode?: number
	/**
	 * Dosadit z karty zboží
	 */
	Item_ArticleCodeG?: boolean
	/**
	 * Kód 1
	 */
	Item_Code1X?: boolean
	/**
	 * Item_Code1
	 */
	Item_Code1?: number
	/**
	 * Kód 2
	 */
	Item_Code2X?: boolean
	/**
	 * Item_Code2
	 */
	Item_Code2?: number
	/**
	 * Kód 3
	 */
	Item_Code3X?: boolean
	/**
	 * Item_Code3
	 */
	Item_Code3?: number
	/**
	 * Kód 4
	 */
	Item_Code4X?: boolean
	/**
	 * Item_Code4
	 */
	Item_Code4?: number
	/**
	 * Kód 5
	 */
	Item_Code5X?: boolean
	/**
	 * Item_Code5
	 */
	Item_Code5?: number
	/**
	 * Kód 6
	 */
	Item_Code6X?: boolean
	/**
	 * Item_Code6
	 */
	Item_Code6?: number
	/**
	 * Referent
	 */
	Item_OfficerX?: boolean
	/**
	 * Item_Officer
	 */
	Item_Officer?: number
	/**
	 * Prostředek
	 */
	Item_DeviceX?: boolean
	/**
	 * Item_Device
	 */
	Item_Device?: number
	/**
	 * Parovací symbol
	 */
	Item_MatchSymbolX?: boolean
	/**
	 * Item_MatchSymbol
	 */
	Item_MatchSymbol?: number
	/**
	 * Účet
	 */
	Item_AccountX?: boolean
	/**
	 * Item_Account
	 */
	Item_Account?: number
	/**
	 * Kontace
	 */
	Item_PostingKeyX?: boolean
	/**
	 * Item_PostingKey
	 */
	Item_PostingKey?: number
	/**
	 * Účet D
	 */
	Item_CreditAcX?: boolean
	/**
	 * Item_CreditAc
	 */
	Item_CreditAc?: number
	/**
	 * Účet MD
	 */
	Item_DebitAcX?: boolean
	/**
	 * Item_DebitAc
	 */
	Item_DebitAc?: number
	/**
	 * Podle kontejneru zboží
	 */
	GoodsContainer?: boolean
	/**
	 * S odmazáním z kontejneru
	 */
	DeleteFromContainer?: boolean
	/**
	 * Pouze u prázdných
	 */
	OnlyEmpty?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TBulkChangeConfirmationDateCOMMANDParams = {
	/**
	 * Datum potvrzení
	 */
	Confirmation1Date?: Date
	/**
	 * Hodina potvrzení
	 */
	Confirmation1Time?: Date
	/**
	 * Datum 2. potvrzení
	 */
	Confirmation2Date?: Date
	/**
	 * Hodina 2. potvrzení
	 */
	Confirmation2Time?: Date
	/**
	 * Kontrolovat záporné stavy
	 */
	CheckNegativeState?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TChangeConfirmationDateCOMMANDParams = {
	/**
	 * Datum potvrzení
	 */
	Confirmation1Date?: Date
	/**
	 * Hodina potvrzení
	 */
	Confirmation1Time?: Date
	/**
	 * Datum 2. potvrzení
	 */
	Confirmation2Date?: Date
	/**
	 * Hodina 2. potvrzení
	 */
	Confirmation2Time?: Date
	/**
	 * Kontrolovat záporné stavy
	 */
	CheckNegativeState?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TChangeRecordStatusSWSCOMMANDParams = {
	/**
	 * StatusId
	 */
	StatusId?: number
}

export type TBulkChangeAttributesCOMMANDParams = {
	/**
	 * Smazat atributy
	 */
	DeleteAttribute?: boolean
	/**
	 * Smazat všechny volné atributy
	 */
	DeleteFreeAttribute?: boolean
	/**
	 * Nastavit hodnoty atributů
	 */
	SetValues?: boolean
	/**
	 * Přidat nepřipojené atributy
	 */
	AddFreeAttributes?: boolean
	/**
	 * FreeAttributeEnabled
	 */
	FreeAttributeEnabled?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TBulkActualizeAttributesCOMMANDParams = {
	/**
	 * Aktualizovat včetně samotné sady atributů
	 */
	ActualizeAttributeSet?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}