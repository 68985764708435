import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TMeasureUnitDM from './TMeasureUnitDM'

/**
 * Čárové kódy
 */
export default class TArticleBarCodeDM extends Data {
	public static className = 'TArticleBarCodeDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = false

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * Popis
	 */
	Description?: string

	/**
	 * Čárový kód
	 */
	Barcode?: string

	/**
	 * Číslo
	 */
	Id?: number

	/**
	 * Typ
	 */
	TypeId?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * Vlastní EAN
	 */
	Ex_IsOwnEAN?: string

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * &EDI
	 */
	EDIBitCalc?: number

	/**
	 * &Výchozí
	 */
	DefaultBitCalc?: number

	/**
	 * Skladová jednotka
	 */
	@Type(() => TMeasureUnitDM)
	MeasureUnitId?: TMeasureUnitDM

	/**
	 * Skladová jednotka
	 */
	@Type(() => TMeasureUnitDM)
	MeasureUnitIdCalc?: TMeasureUnitDM
}