import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TExternalBatchDM from './TExternalBatchDM'
import TTextDM from './TTextDM'
import BatchStatus from './BatchStatus'
import KindAccounting from './KindAccounting'
import TRoutingVariantDM from './TRoutingVariantDM'
import TCountryDM from './TCountryDM'
import GoodsName from './GoodsName'
import Kvalita from './Kvalita'
import Typ from './Typ'
import TK2UserLookupDM from './TK2UserLookupDM'
import TArticleDM from './TArticleDM'
import TTradingPartnerDM from './TTradingPartnerDM'
import TWarehouseDM from './TWarehouseDM'
import TMeasureUnitDM from './TMeasureUnitDM'
import TPeriodListDM from './TPeriodListDM'
import TMarketingActivityTypeDM from './TMarketingActivityTypeDM'
import TWorkflowProcessDM from './TWorkflowProcessDM'
import TLinkDM from './TLinkDM'
import TCommentDM from './TCommentDM'
import TBatchParameterValueDM from './TBatchParameterValueDM'
import TBatchCostItemDM from './TBatchCostItemDM'
import TStockCardDM from './TStockCardDM'
import TBatchAllDM from './TBatchAllDM'
import TExternalDocumentItemDM from './TExternalDocumentItemDM'
import TWorkflowProcessAsItemDM from './TWorkflowProcessAsItemDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TAttachedDocumentDM from './TAttachedDocumentDM'
import TOldNoteDM from './TOldNoteDM'
import TStockCardOldDM from './TStockCardOldDM'

/**
 * Šarže
 */
export default class TBatchDM extends Data {
	public static className = 'TBatchDM'
	public static primaryKey = 'Id'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.Id!
	}

	public static isHeader = true

	/**
	 * Expirace
	 */
	ExpirationDate?: Date

	/**
	 * Firma
	 */
	CompanyId?: number

	/**
	 * Časové razítko
	 */
	TimeStamp?: string

	/**
	 * Jednotka
	 */
	AUnit?: string

	/**
	 * Koeficient
	 */
	Coefficient?: number

	/**
	 * Id_Zrk_Vl
	 */
	TypeId?: number

	/**
	 * Nákladová cena
	 */
	CostPrice?: number

	/**
	 * Skladová cena
	 */
	StockPrice?: number

	/**
	 * Počet výst.
	 */
	OutputDayCount?: number

	/**
	 * D.výstupu
	 */
	OutputDate?: Date

	/**
	 * Počet vst.
	 */
	InputDayCount?: number

	/**
	 * D.vstupu
	 */
	InputDate?: Date

	/**
	 * Změněno
	 */
	ChangedOn?: Date

	/**
	 * Prodejní cena
	 */
	SellingPrice?: number

	/**
	 * Popis
	 */
	Description?: string

	/**
	 * Zkratka
	 */
	Abbr?: string

	/**
	 * Číslo
	 */
	Id?: number

	/**
	 * Tavby
	 */
	EX_Tavby?: string

	/**
	 * EX_MnozPal
	 */
	EX_MnozPal?: number

	/**
	 * EX_MnozKar
	 */
	EX_MnozKar?: number

	/**
	 * EX_Skl_Cena
	 */
	EX_Skl_Cena?: number

	/**
	 * EX_DPZ
	 */
	EX_DPZ?: Date

	/**
	 * EX_SarBal
	 */
	EX_SarBal?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * SQLSequenceOrderCalc
	 */
	SQLSequenceOrderCalc?: number

	/**
	 * Datum pro "_ver" pole
	 */
	VersionedFieldDateCalc?: Date

	/**
	 * Odkaz
	 */
	PictureCalc?: string

	/**
	 * Přijato
	 */
	ReceivedCalc?: number

	/**
	 * Dispozice
	 */
	AvailableCalc?: number

	/**
	 * Zadáno
	 */
	AssignedCalc?: number

	/**
	 * Disp.2
	 */
	Available2Calc?: number

	/**
	 * Dispozice
	 */
	AvailableUMCalc?: number

	/**
	 * Zadáno
	 */
	AssignedUMCalc?: number

	/**
	 * Disp.2
	 */
	Available2UMCalc?: number

	/**
	 * Přijato
	 */
	ReceivedUMCalc?: number

	/**
	 * Účetní hodn.
	 */
	BookValueCalc?: number

	/**
	 * Expedice
	 */
	DispatchCalc?: number

	/**
	 * Expedice
	 */
	DispatchUMCalc?: number

	/**
	 * V příjmu
	 */
	InReceiptQuantityCalc?: number

	/**
	 * V příjmu
	 */
	InReceiptQuantityUMCalc?: number

	/**
	 * V převodu
	 */
	InTransferCalc?: number

	/**
	 * V převodu
	 */
	InTransferUMCalc?: number

	/**
	 * undefined
	 */
	MeasureUnitCoefficientCalc?: number

	/**
	 * Pouze šarže firmy
	 */
	ShowRecordActualCompanyCalc?: string

	/**
	 * undefined
	 */
	ExpirationDateCalc?: Date

	/**
	 * undefined
	 */
	ExpirationDateTimeCalc?: Date

	/**
	 * Dispozice koef.
	 */
	AvailableCoefficientCalc?: number

	/**
	 * Zadáno koef.
	 */
	AssignedCoefficientCalc?: number

	/**
	 * undefined
	 */
	ParameterSetIdCalc?: number

	/**
	 * Povolen záporný stav
	 */
	PaidStateIdCalc?: number

	/**
	 * Nedosazovat
	 */
	NotInsertStockPriceBitCalc?: number

	/**
	 * undefined
	 */
	UnspecifiedBitCalc?: number

	/**
	 * Nedosazovat
	 */
	NotInsertCostPriceBitCalc?: number

	/**
	 * Nedosazovat
	 */
	NotInsertSellingPriceBitCalc?: number

	/**
	 * Prodejní cena podle šarže
	 */
	BatchSellingPriceCalc?: number

	/**
	 * Automaticky nevydávat
	 */
	NotReleaseAutomaticallyBitCalc?: number

	/**
	 * undefined
	 */
	PeriodFromDateCalc?: Date

	/**
	 * undefined
	 */
	PeriodToDateCalc?: Date

	/**
	 * Nenulové
	 */
	SkipNullCalc?: string

	/**
	 * Vlastnost šarže
	 */
	BatchValuePARAMCalc?: string

	/**
	 * 1.parametr
	 */
	Parameter01Calc?: string

	/**
	 * 2.parametr
	 */
	Parameter02Calc?: string

	/**
	 * 3.parametr
	 */
	Parameter03Calc?: string

	/**
	 * 4.parametr
	 */
	Parameter04Calc?: string

	/**
	 * 5.parametr
	 */
	Parameter05Calc?: string

	/**
	 * 6.parametr
	 */
	Parameter06Calc?: string

	/**
	 * 7.parametr
	 */
	Parameter07Calc?: string

	/**
	 * 8.parametr
	 */
	Parameter08Calc?: string

	/**
	 * 9.parametr
	 */
	Parameter09Calc?: string

	/**
	 * 10.parametr
	 */
	Parameter10Calc?: string

	/**
	 * 11.parametr
	 */
	Parameter11Calc?: string

	/**
	 * 12.parametr
	 */
	Parameter12Calc?: string

	/**
	 * 13.parametr
	 */
	Parameter13Calc?: string

	/**
	 * 14.parametr
	 */
	Parameter14Calc?: string

	/**
	 * 15.parametr
	 */
	Parameter15Calc?: string

	/**
	 * 16.parametr
	 */
	Parameter16Calc?: string

	/**
	 * 17.parametr
	 */
	Parameter17Calc?: string

	/**
	 * 18.parametr
	 */
	Parameter18Calc?: string

	/**
	 * 19.parametr
	 */
	Parameter19Calc?: string

	/**
	 * 20.parametr
	 */
	Parameter20Calc?: string

	/**
	 * 21.parametr
	 */
	Parameter21Calc?: string

	/**
	 * 22.parametr
	 */
	Parameter22Calc?: string

	/**
	 * 23.parametr
	 */
	Parameter23Calc?: string

	/**
	 * 24.parametr
	 */
	Parameter24Calc?: string

	/**
	 * 25.parametr
	 */
	Parameter25Calc?: string

	/**
	 * 26.parametr
	 */
	Parameter26Calc?: string

	/**
	 * 27.parametr
	 */
	Parameter27Calc?: string

	/**
	 * 28.parametr
	 */
	Parameter28Calc?: string

	/**
	 * 29.parametr
	 */
	Parameter29Calc?: string

	/**
	 * 30.parametr
	 */
	Parameter30Calc?: string

	/**
	 * 31.parametr
	 */
	Parameter31Calc?: string

	/**
	 * 32.parametr
	 */
	Parameter32Calc?: string

	/**
	 * 33.parametr
	 */
	Parameter33Calc?: string

	/**
	 * 34.parametr
	 */
	Parameter34Calc?: string

	/**
	 * 35.parametr
	 */
	Parameter35Calc?: string

	/**
	 * 36.parametr
	 */
	Parameter36Calc?: string

	/**
	 * 37.parametr
	 */
	Parameter37Calc?: string

	/**
	 * 38.parametr
	 */
	Parameter38Calc?: string

	/**
	 * 39.parametr
	 */
	Parameter39Calc?: string

	/**
	 * 40.parametr
	 */
	Parameter40Calc?: string

	/**
	 * 41.parametr
	 */
	Parameter41Calc?: string

	/**
	 * 42.parametr
	 */
	Parameter42Calc?: string

	/**
	 * 43.parametr
	 */
	Parameter43Calc?: string

	/**
	 * 44.parametr
	 */
	Parameter44Calc?: string

	/**
	 * 45.parametr
	 */
	Parameter45Calc?: string

	/**
	 * 46.parametr
	 */
	Parameter46Calc?: string

	/**
	 * 47.parametr
	 */
	Parameter47Calc?: string

	/**
	 * 48.parametr
	 */
	Parameter48Calc?: string

	/**
	 * 49.parametr
	 */
	Parameter49Calc?: string

	/**
	 * 50.parametr
	 */
	Parameter50Calc?: string

	/**
	 * 51.parametr
	 */
	Parameter51Calc?: string

	/**
	 * 52.parametr
	 */
	Parameter52Calc?: string

	/**
	 * 53.parametr
	 */
	Parameter53Calc?: string

	/**
	 * 54.parametr
	 */
	Parameter54Calc?: string

	/**
	 * 55.parametr
	 */
	Parameter55Calc?: string

	/**
	 * 56.parametr
	 */
	Parameter56Calc?: string

	/**
	 * 57.parametr
	 */
	Parameter57Calc?: string

	/**
	 * 58.parametr
	 */
	Parameter58Calc?: string

	/**
	 * 59.parametr
	 */
	Parameter59Calc?: string

	/**
	 * 60.parametr
	 */
	Parameter60Calc?: string

	/**
	 * 61.parametr
	 */
	Parameter61Calc?: string

	/**
	 * 62.parametr
	 */
	Parameter62Calc?: string

	/**
	 * 63.parametr
	 */
	Parameter63Calc?: string

	/**
	 * 64.parametr
	 */
	Parameter64Calc?: string

	/**
	 * 65.parametr
	 */
	Parameter65Calc?: string

	/**
	 * 66.parametr
	 */
	Parameter66Calc?: string

	/**
	 * 67.parametr
	 */
	Parameter67Calc?: string

	/**
	 * 68.parametr
	 */
	Parameter68Calc?: string

	/**
	 * 69.parametr
	 */
	Parameter69Calc?: string

	/**
	 * 70.parametr
	 */
	Parameter70Calc?: string

	/**
	 * 71.parametr
	 */
	Parameter71Calc?: string

	/**
	 * 72.parametr
	 */
	Parameter72Calc?: string

	/**
	 * 73.parametr
	 */
	Parameter73Calc?: string

	/**
	 * 74.parametr
	 */
	Parameter74Calc?: string

	/**
	 * 75.parametr
	 */
	Parameter75Calc?: string

	/**
	 * 76.parametr
	 */
	Parameter76Calc?: string

	/**
	 * 77.parametr
	 */
	Parameter77Calc?: string

	/**
	 * 78.parametr
	 */
	Parameter78Calc?: string

	/**
	 * 79.parametr
	 */
	Parameter79Calc?: string

	/**
	 * 80.parametr
	 */
	Parameter80Calc?: string

	/**
	 * 81.parametr
	 */
	Parameter81Calc?: string

	/**
	 * 82.parametr
	 */
	Parameter82Calc?: string

	/**
	 * 83.parametr
	 */
	Parameter83Calc?: string

	/**
	 * 84.parametr
	 */
	Parameter84Calc?: string

	/**
	 * 85.parametr
	 */
	Parameter85Calc?: string

	/**
	 * 86.parametr
	 */
	Parameter86Calc?: string

	/**
	 * 87.parametr
	 */
	Parameter87Calc?: string

	/**
	 * 88.parametr
	 */
	Parameter88Calc?: string

	/**
	 * 89.parametr
	 */
	Parameter89Calc?: string

	/**
	 * 90.parametr
	 */
	Parameter90Calc?: string

	/**
	 * 91.parametr
	 */
	Parameter91Calc?: string

	/**
	 * 92.parametr
	 */
	Parameter92Calc?: string

	/**
	 * 93.parametr
	 */
	Parameter93Calc?: string

	/**
	 * 94.parametr
	 */
	Parameter94Calc?: string

	/**
	 * 95.parametr
	 */
	Parameter95Calc?: string

	/**
	 * 96.parametr
	 */
	Parameter96Calc?: string

	/**
	 * 97.parametr
	 */
	Parameter97Calc?: string

	/**
	 * 98.parametr
	 */
	Parameter98Calc?: string

	/**
	 * 99.parametr
	 */
	Parameter99Calc?: string

	/**
	 * Parameter01TypeCalc
	 */
	Parameter01TypeCalc?: number

	/**
	 * Parameter02TypeCalc
	 */
	Parameter02TypeCalc?: number

	/**
	 * Parameter03TypeCalc
	 */
	Parameter03TypeCalc?: number

	/**
	 * Parameter04TypeCalc
	 */
	Parameter04TypeCalc?: number

	/**
	 * Parameter05TypeCalc
	 */
	Parameter05TypeCalc?: number

	/**
	 * Parameter06TypeCalc
	 */
	Parameter06TypeCalc?: number

	/**
	 * Parameter07TypeCalc
	 */
	Parameter07TypeCalc?: number

	/**
	 * Parameter08TypeCalc
	 */
	Parameter08TypeCalc?: number

	/**
	 * Parameter09TypeCalc
	 */
	Parameter09TypeCalc?: number

	/**
	 * Parameter10TypeCalc
	 */
	Parameter10TypeCalc?: number

	/**
	 * Parameter11TypeCalc
	 */
	Parameter11TypeCalc?: number

	/**
	 * Parameter12TypeCalc
	 */
	Parameter12TypeCalc?: number

	/**
	 * Parameter13TypeCalc
	 */
	Parameter13TypeCalc?: number

	/**
	 * Parameter14TypeCalc
	 */
	Parameter14TypeCalc?: number

	/**
	 * Parameter15TypeCalc
	 */
	Parameter15TypeCalc?: number

	/**
	 * Parameter16TypeCalc
	 */
	Parameter16TypeCalc?: number

	/**
	 * Parameter17TypeCalc
	 */
	Parameter17TypeCalc?: number

	/**
	 * Parameter18TypeCalc
	 */
	Parameter18TypeCalc?: number

	/**
	 * Parameter19TypeCalc
	 */
	Parameter19TypeCalc?: number

	/**
	 * Parameter20TypeCalc
	 */
	Parameter20TypeCalc?: number

	/**
	 * Parameter21TypeCalc
	 */
	Parameter21TypeCalc?: number

	/**
	 * Parameter22TypeCalc
	 */
	Parameter22TypeCalc?: number

	/**
	 * Parameter23TypeCalc
	 */
	Parameter23TypeCalc?: number

	/**
	 * Parameter24TypeCalc
	 */
	Parameter24TypeCalc?: number

	/**
	 * Parameter25TypeCalc
	 */
	Parameter25TypeCalc?: number

	/**
	 * Parameter26TypeCalc
	 */
	Parameter26TypeCalc?: number

	/**
	 * Parameter27TypeCalc
	 */
	Parameter27TypeCalc?: number

	/**
	 * Parameter28TypeCalc
	 */
	Parameter28TypeCalc?: number

	/**
	 * Parameter29TypeCalc
	 */
	Parameter29TypeCalc?: number

	/**
	 * Parameter30TypeCalc
	 */
	Parameter30TypeCalc?: number

	/**
	 * Parameter31TypeCalc
	 */
	Parameter31TypeCalc?: number

	/**
	 * Parameter32TypeCalc
	 */
	Parameter32TypeCalc?: number

	/**
	 * Parameter33TypeCalc
	 */
	Parameter33TypeCalc?: number

	/**
	 * Parameter34TypeCalc
	 */
	Parameter34TypeCalc?: number

	/**
	 * Parameter35TypeCalc
	 */
	Parameter35TypeCalc?: number

	/**
	 * Parameter36TypeCalc
	 */
	Parameter36TypeCalc?: number

	/**
	 * Parameter37TypeCalc
	 */
	Parameter37TypeCalc?: number

	/**
	 * Parameter38TypeCalc
	 */
	Parameter38TypeCalc?: number

	/**
	 * Parameter39TypeCalc
	 */
	Parameter39TypeCalc?: number

	/**
	 * Parameter40TypeCalc
	 */
	Parameter40TypeCalc?: number

	/**
	 * Parameter41TypeCalc
	 */
	Parameter41TypeCalc?: number

	/**
	 * Parameter42TypeCalc
	 */
	Parameter42TypeCalc?: number

	/**
	 * Parameter43TypeCalc
	 */
	Parameter43TypeCalc?: number

	/**
	 * Parameter44TypeCalc
	 */
	Parameter44TypeCalc?: number

	/**
	 * Parameter45TypeCalc
	 */
	Parameter45TypeCalc?: number

	/**
	 * Parameter46TypeCalc
	 */
	Parameter46TypeCalc?: number

	/**
	 * Parameter47TypeCalc
	 */
	Parameter47TypeCalc?: number

	/**
	 * Parameter48TypeCalc
	 */
	Parameter48TypeCalc?: number

	/**
	 * Parameter49TypeCalc
	 */
	Parameter49TypeCalc?: number

	/**
	 * Parameter50TypeCalc
	 */
	Parameter50TypeCalc?: number

	/**
	 * Parameter51TypeCalc
	 */
	Parameter51TypeCalc?: number

	/**
	 * Parameter52TypeCalc
	 */
	Parameter52TypeCalc?: number

	/**
	 * Parameter53TypeCalc
	 */
	Parameter53TypeCalc?: number

	/**
	 * Parameter54TypeCalc
	 */
	Parameter54TypeCalc?: number

	/**
	 * Parameter55TypeCalc
	 */
	Parameter55TypeCalc?: number

	/**
	 * Parameter56TypeCalc
	 */
	Parameter56TypeCalc?: number

	/**
	 * Parameter57TypeCalc
	 */
	Parameter57TypeCalc?: number

	/**
	 * Parameter58TypeCalc
	 */
	Parameter58TypeCalc?: number

	/**
	 * Parameter59TypeCalc
	 */
	Parameter59TypeCalc?: number

	/**
	 * Parameter60TypeCalc
	 */
	Parameter60TypeCalc?: number

	/**
	 * Parameter61TypeCalc
	 */
	Parameter61TypeCalc?: number

	/**
	 * Parameter62TypeCalc
	 */
	Parameter62TypeCalc?: number

	/**
	 * Parameter63TypeCalc
	 */
	Parameter63TypeCalc?: number

	/**
	 * Parameter64TypeCalc
	 */
	Parameter64TypeCalc?: number

	/**
	 * Parameter65TypeCalc
	 */
	Parameter65TypeCalc?: number

	/**
	 * Parameter66TypeCalc
	 */
	Parameter66TypeCalc?: number

	/**
	 * Parameter67TypeCalc
	 */
	Parameter67TypeCalc?: number

	/**
	 * Parameter68TypeCalc
	 */
	Parameter68TypeCalc?: number

	/**
	 * Parameter69TypeCalc
	 */
	Parameter69TypeCalc?: number

	/**
	 * Parameter70TypeCalc
	 */
	Parameter70TypeCalc?: number

	/**
	 * Parameter71TypeCalc
	 */
	Parameter71TypeCalc?: number

	/**
	 * Parameter72TypeCalc
	 */
	Parameter72TypeCalc?: number

	/**
	 * Parameter73TypeCalc
	 */
	Parameter73TypeCalc?: number

	/**
	 * Parameter74TypeCalc
	 */
	Parameter74TypeCalc?: number

	/**
	 * Parameter75TypeCalc
	 */
	Parameter75TypeCalc?: number

	/**
	 * Parameter76TypeCalc
	 */
	Parameter76TypeCalc?: number

	/**
	 * Parameter77TypeCalc
	 */
	Parameter77TypeCalc?: number

	/**
	 * Parameter78TypeCalc
	 */
	Parameter78TypeCalc?: number

	/**
	 * Parameter79TypeCalc
	 */
	Parameter79TypeCalc?: number

	/**
	 * Parameter80TypeCalc
	 */
	Parameter80TypeCalc?: number

	/**
	 * Parameter81TypeCalc
	 */
	Parameter81TypeCalc?: number

	/**
	 * Parameter82TypeCalc
	 */
	Parameter82TypeCalc?: number

	/**
	 * Parameter83TypeCalc
	 */
	Parameter83TypeCalc?: number

	/**
	 * Parameter84TypeCalc
	 */
	Parameter84TypeCalc?: number

	/**
	 * Parameter85TypeCalc
	 */
	Parameter85TypeCalc?: number

	/**
	 * Parameter86TypeCalc
	 */
	Parameter86TypeCalc?: number

	/**
	 * Parameter87TypeCalc
	 */
	Parameter87TypeCalc?: number

	/**
	 * Parameter88TypeCalc
	 */
	Parameter88TypeCalc?: number

	/**
	 * Parameter89TypeCalc
	 */
	Parameter89TypeCalc?: number

	/**
	 * Parameter90TypeCalc
	 */
	Parameter90TypeCalc?: number

	/**
	 * Parameter91TypeCalc
	 */
	Parameter91TypeCalc?: number

	/**
	 * Parameter92TypeCalc
	 */
	Parameter92TypeCalc?: number

	/**
	 * Parameter93TypeCalc
	 */
	Parameter93TypeCalc?: number

	/**
	 * Parameter94TypeCalc
	 */
	Parameter94TypeCalc?: number

	/**
	 * Parameter95TypeCalc
	 */
	Parameter95TypeCalc?: number

	/**
	 * Parameter96TypeCalc
	 */
	Parameter96TypeCalc?: number

	/**
	 * Parameter97TypeCalc
	 */
	Parameter97TypeCalc?: number

	/**
	 * Parameter98TypeCalc
	 */
	Parameter98TypeCalc?: number

	/**
	 * Parameter99TypeCalc
	 */
	Parameter99TypeCalc?: number

	/**
	 * undefined
	 */
	TextHeaderMemoCalc?: string

	/**
	 * undefined
	 */
	TextFooterMemoCalc?: string

	/**
	 * Poslední komentář
	 */
	LastCommentParamCalc?: string

	/**
	 * Externí šarže
	 */
	@Type(() => TExternalBatchDM)
	ExternalBatchId?: TExternalBatchDM

	/**
	 * Úroveň šarže
	 */
	@Type(() => TTextDM)
	BatchLevelId?: TTextDM

	/**
	 * Stav
	 */
	@Type(() => BatchStatus)
	StatusId?: BatchStatus

	/**
	 * Druh účt.
	 */
	@Type(() => KindAccounting)
	PostingKindId?: KindAccounting

	/**
	 * Varianta
	 */
	@Type(() => TRoutingVariantDM)
	RoutingVariantId?: TRoutingVariantDM

	/**
	 * Stát pův.
	 */
	@Type(() => TCountryDM)
	OriginCountryId?: TCountryDM

	/**
	 * Název
	 */
	@Type(() => GoodsName)
	NameId?: GoodsName

	/**
	 * Kvalita
	 */
	@Type(() => Kvalita)
	QualityId?: Kvalita

	/**
	 * Typ
	 */
	@Type(() => Typ)
	KindId?: Typ

	/**
	 * Změnil
	 */
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM

	/**
	 * Číslo zboží
	 */
	@Type(() => TArticleDM)
	ArticleId?: TArticleDM

	/**
	 * EX_SarCdo
	 */
	@Type(() => TTradingPartnerDM)
	EX_SarCdo?: TTradingPartnerDM

	/**
	 * Sklad
	 */
	@Type(() => TWarehouseDM)
	WarehouseIdCalc?: TWarehouseDM

	/**
	 * Zboží
	 */
	@Type(() => TArticleDM)
	ArticleIdCalc?: TArticleDM

	/**
	 * Skl. jedn.
	 */
	@Type(() => TMeasureUnitDM)
	MeasureUnitCalc?: TMeasureUnitDM

	/**
	 * undefined
	 */
	@Type(() => TMeasureUnitDM)
	StockMeasureUnitCalc?: TMeasureUnitDM

	/**
	 * Rozpracovaná výroba
	 */
	@Type(() => TTextDM)
	ProductionInProgressBitCalc?: TTextDM

	/**
	 * Období od
	 */
	@Type(() => TPeriodListDM)
	PeriodFromIdCalc?: TPeriodListDM

	/**
	 * Období do
	 */
	@Type(() => TPeriodListDM)
	PeriodToIdCalc?: TPeriodListDM

	/**
	 * D
	 */
	@Type(() => TTextDM)
	DocumentExistsCalc?: TTextDM

	/**
	 * undefined
	 */
	@Type(() => TMarketingActivityTypeDM)
	ActivityTypeRIDCalc?: TMarketingActivityTypeDM

	/**
	 * SP
	 */
	@Type(() => TTextDM)
	ProcessStateIdCalc?: TTextDM

	/**
	 * Aktuální proces
	 */
	@Type(() => TWorkflowProcessDM)
	CurrentProcessIdCalc?: TWorkflowProcessDM

	/**
	 * Odkazy
	 */
	@Type(() => TLinkDM)
	LinkChild?: TLinkDM[]

	/**
	 * Komentáře
	 */
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]

	/**
	 * undefined
	 */
	@Type(() => TBatchParameterValueDM)
	BatchParameterChild?: TBatchParameterValueDM[]

	/**
	 * undefined
	 */
	@Type(() => TBatchCostItemDM)
	BatchCostChild?: TBatchCostItemDM[]

	/**
	 * undefined
	 */
	@Type(() => TStockCardDM)
	StockCardChild?: TStockCardDM[]

	/**
	 * undefined
	 */
	@Type(() => TBatchAllDM)
	SubordinateBatchChild?: TBatchAllDM[]

	/**
	 * Dokumenty
	 */
	@Type(() => TExternalDocumentItemDM)
	ExternalDocumentItemChild?: TExternalDocumentItemDM[]

	/**
	 * Procesy
	 */
	@Type(() => TWorkflowProcessAsItemDM)
	WorkflowProcessChild?: TWorkflowProcessAsItemDM[]

	/**
	 * Text hlavičky
	 */
	@Type(() => TLanguageMemoTranslationDM)
	TextHeaderChild?: TLanguageMemoTranslationDM[]

	/**
	 * Text patičky
	 */
	@Type(() => TLanguageMemoTranslationDM)
	TextFooterChild?: TLanguageMemoTranslationDM[]

	/**
	 * Svázané doklady
	 */
	@Type(() => TAttachedDocumentDM)
	AttachedDocumentOldChild?: TAttachedDocumentDM[]

	/**
	 * Poznámky
	 */
	@Type(() => TOldNoteDM)
	DeprecatedNoteOldChild?: TOldNoteDM[]

	/**
	 * Položky skladové karty
	 */
	@Type(() => TStockCardOldDM)
	OldStockCardOldChild?: TStockCardOldDM[]

	/**
	 * Potvrzení
	 */
	public BulkConfirmCOMMAND() {
		return this.executeCommand('BulkConfirmCOMMAND')
	}

	/**
	 * Odpotvrzení
	 */
	public BulkUnconfirmCOMMAND() {
		return this.executeCommand('BulkUnconfirmCOMMAND')
	}

	/**
	 * Změna data potvrzení
	 */
	public BulkChangeConfirmationDateCOMMAND(parameters: TBulkChangeConfirmationDateCOMMANDParams) {
		return this.executeCommand('BulkChangeConfirmationDateCOMMAND', parameters)
	}

	/**
	 * Storno
	 */
	public BulkCancelCOMMAND() {
		return this.executeCommand('BulkCancelCOMMAND')
	}

	/**
	 * Odstorno
	 */
	public BulkUnCancelCOMMAND() {
		return this.executeCommand('BulkUnCancelCOMMAND')
	}

	/**
	 * Potvrzení
	 */
	public ConfirmCOMMAND() {
		return this.executeCommand('ConfirmCOMMAND')
	}

	/**
	 * Odpotvrzení
	 */
	public UnconfirmCOMMAND() {
		return this.executeCommand('UnconfirmCOMMAND')
	}

	/**
	 * Změna data potvrzení
	 */
	public ChangeConfirmationDateCOMMAND(parameters: TChangeConfirmationDateCOMMANDParams) {
		return this.executeCommand('ChangeConfirmationDateCOMMAND', parameters)
	}

	/**
	 * Storno/Odstorno
	 */
	public CancelCOMMAND() {
		return this.executeCommand('CancelCOMMAND')
	}

	/**
	 * Zrušení stavu
	 */
	public ClearRecordStatusCOMMAND() {
		return this.executeCommand('ClearRecordStatusCOMMAND')
	}

	/**
	 * Změna stavu
	 */
	public ChangeRecordStatusCOMMAND() {
		return this.executeCommand('ChangeRecordStatusCOMMAND')
	}

	/**
	 * 
	 */
	public ChangeRecordStatusSWSCOMMAND(parameters: TChangeRecordStatusSWSCOMMANDParams) {
		return this.executeCommand('ChangeRecordStatusSWSCOMMAND', parameters)
	}

	/**
	 * Zrušení stavu
	 */
	public BulkClearRecordStatusCOMMAND() {
		return this.executeCommand('BulkClearRecordStatusCOMMAND')
	}

	/**
	 * Změna stavu
	 */
	public BulkChangeRecordStatusCOMMAND() {
		return this.executeCommand('BulkChangeRecordStatusCOMMAND')
	}

	/**
	 * Změna atributů
	 */
	public BulkChangeAttributesCOMMAND(parameters: TBulkChangeAttributesCOMMANDParams) {
		return this.executeCommand('BulkChangeAttributesCOMMAND', parameters)
	}

	/**
	 * Aktualizace sady atributů
	 */
	public BulkActualizeAttributesCOMMAND(parameters: TBulkActualizeAttributesCOMMANDParams) {
		return this.executeCommand('BulkActualizeAttributesCOMMAND', parameters)
	}

	/**
	 * Aktualizace sady atributů
	 */
	public ActualizeAttributesCOMMAND() {
		return this.executeCommand('ActualizeAttributesCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public ChangeGroupRightCOMMAND() {
		return this.executeCommand('ChangeGroupRightCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public BulkChangeGroupRightCOMMAND() {
		return this.executeCommand('BulkChangeGroupRightCOMMAND')
	}

	/**
	 * Aktualizace počítaných polí
	 */
	public BulkUpdateCalcFieldsCOMMAND() {
		return this.executeCommand('BulkUpdateCalcFieldsCOMMAND')
	}

	/**
	 * Kontrola legislativy
	 */
	public SwitchOffLegislationValidationCOMMAND() {
		return this.executeCommand('SwitchOffLegislationValidationCOMMAND')
	}
}

export type TBulkChangeConfirmationDateCOMMANDParams = {
	/**
	 * Datum potvrzení
	 */
	Confirmation1Date?: Date
	/**
	 * Hodina potvrzení
	 */
	Confirmation1Time?: Date
	/**
	 * Datum 2. potvrzení
	 */
	Confirmation2Date?: Date
	/**
	 * Hodina 2. potvrzení
	 */
	Confirmation2Time?: Date
	/**
	 * Kontrolovat záporné stavy
	 */
	CheckNegativeState?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TChangeConfirmationDateCOMMANDParams = {
	/**
	 * Datum potvrzení
	 */
	Confirmation1Date?: Date
	/**
	 * Hodina potvrzení
	 */
	Confirmation1Time?: Date
	/**
	 * Datum 2. potvrzení
	 */
	Confirmation2Date?: Date
	/**
	 * Hodina 2. potvrzení
	 */
	Confirmation2Time?: Date
	/**
	 * Kontrolovat záporné stavy
	 */
	CheckNegativeState?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TChangeRecordStatusSWSCOMMANDParams = {
	/**
	 * StatusId
	 */
	StatusId?: number
}

export type TBulkChangeAttributesCOMMANDParams = {
	/**
	 * Smazat atributy
	 */
	DeleteAttribute?: boolean
	/**
	 * Smazat všechny volné atributy
	 */
	DeleteFreeAttribute?: boolean
	/**
	 * Nastavit hodnoty atributů
	 */
	SetValues?: boolean
	/**
	 * Přidat nepřipojené atributy
	 */
	AddFreeAttributes?: boolean
	/**
	 * FreeAttributeEnabled
	 */
	FreeAttributeEnabled?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TBulkActualizeAttributesCOMMANDParams = {
	/**
	 * Aktualizovat včetně samotné sady atributů
	 */
	ActualizeAttributeSet?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}