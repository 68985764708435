import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TK2UserLookupDM from './TK2UserLookupDM'
import TContactPersonDM from './TContactPersonDM'
import TCampaignDM from './TCampaignDM'
import TOpportunityDM from './TOpportunityDM'
import TPartnerDM from './TPartnerDM'
import TypCallCentrum from './TypCallCentrum'
import TActivityDM from './TActivityDM'
import TTextDM from './TTextDM'
import TMarketingActivityTypeDM from './TMarketingActivityTypeDM'
import TCommentDM from './TCommentDM'
import TJobDM from './TJobDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TAttachedDocumentDM from './TAttachedDocumentDM'
import TFindByPhoneDM from './TFindByPhoneDM'
import TOldNoteDM from './TOldNoteDM'

/**
 * Historie volání
 */
export default class TCallHistoryDM extends Data {
	public static className = 'TCallHistoryDM'
	public static primaryKey = 'Id'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.Id!
	}

	public static isHeader = true

	/**
	 * Právo na záznam
	 */
	RightGroupId?: number

	/**
	 * Časové razítko
	 */
	TimeStamp?: string

	/**
	 * Délka zvonění
	 */
	RingingTime?: number

	/**
	 * Unikátní číslo hovoru
	 */
	CallId?: string

	/**
	 * Název
	 */
	Comment?: string

	/**
	 * Změněno
	 */
	ChangedOn?: Date

	/**
	 * Vytvořeno
	 */
	CreatedOn?: Date

	/**
	 * Potvrzeno
	 */
	ConfirmedOn?: Date

	/**
	 * Popis
	 */
	Description?: string

	/**
	 * Přesměrováno na
	 */
	ForwardedTo?: number

	/**
	 * Konec hovoru
	 */
	DateTo?: Date

	/**
	 * Začátek hovoru
	 */
	DateFrom?: Date

	/**
	 * Číslo volaného
	 */
	PhoneNumberTo?: string

	/**
	 * Číslo volajícího
	 */
	PhoneNumberFrom?: string

	/**
	 * Číslo
	 */
	Id?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Text
	 */
	CommentCalc?: string

	/**
	 * Začátek hovoru (datum)
	 */
	DateFromCalc?: Date

	/**
	 * Začátek hovoru (čas)
	 */
	StartTimeCalc?: Date

	/**
	 * Konec hovoru (datum)
	 */
	DateToCalc?: Date

	/**
	 * Konec hovoru (čas)
	 */
	EndTimeCalc?: Date

	/**
	 * Interní hovor
	 */
	IsInternalCallCalc?: string

	/**
	 * Délka hovoru
	 */
	CallLenghtCalc?: Date

	/**
	 * undefined
	 */
	CallLenghtMSecCalc?: number

	/**
	 * Zaneprázdněn
	 */
	IsBusyCalc?: string

	/**
	 * undefined
	 */
	TextHeaderMemoCalc?: string

	/**
	 * undefined
	 */
	TextFooterMemoCalc?: string

	/**
	 * Poslední komentář
	 */
	LastCommentParamCalc?: string

	/**
	 * Právo na záznam
	 */
	RightGroupIdCalc?: number

	/**
	 * Změnil
	 */
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM

	/**
	 * Vytvořil
	 */
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM

	/**
	 * Zodpovědná osoba
	 */
	@Type(() => TContactPersonDM)
	ResponsiblePersonId?: TContactPersonDM

	/**
	 * Kampaň
	 */
	@Type(() => TCampaignDM)
	CampaignRID?: TCampaignDM

	/**
	 * Příležitost
	 */
	@Type(() => TOpportunityDM)
	OpportunityRID?: TOpportunityDM

	/**
	 * Partner
	 */
	@Type(() => TPartnerDM)
	PartnerId?: TPartnerDM

	/**
	 * Kontaktní osoba
	 */
	@Type(() => TContactPersonDM)
	ContactPersonId?: TContactPersonDM

	/**
	 * Typ hovoru
	 */
	@Type(() => TypCallCentrum)
	CallTypeId?: TypCallCentrum

	/**
	 * Aktivita
	 */
	@Type(() => TActivityDM)
	ActivityId?: TActivityDM

	/**
	 * D
	 */
	@Type(() => TTextDM)
	DocumentExistsCalc?: TTextDM

	/**
	 * undefined
	 */
	@Type(() => TMarketingActivityTypeDM)
	ActivityTypeRIDCalc?: TMarketingActivityTypeDM

	/**
	 * Komentáře
	 */
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]

	/**
	 * Úkoly
	 */
	@Type(() => TJobDM)
	JobChild?: TJobDM[]

	/**
	 * Text hlavičky
	 */
	@Type(() => TLanguageMemoTranslationDM)
	TextHeaderChild?: TLanguageMemoTranslationDM[]

	/**
	 * Text patičky
	 */
	@Type(() => TLanguageMemoTranslationDM)
	TextFooterChild?: TLanguageMemoTranslationDM[]

	/**
	 * Svázané doklady
	 */
	@Type(() => TAttachedDocumentDM)
	AttachedDocumentOldChild?: TAttachedDocumentDM[]

	/**
	 * Find by phone
	 */
	@Type(() => TFindByPhoneDM)
	DataModuleFindByPhoneOldChild?: TFindByPhoneDM[]

	/**
	 * Poznámky
	 */
	@Type(() => TOldNoteDM)
	DeprecatedNoteOldChild?: TOldNoteDM[]

	/**
	 * Potvrzení
	 */
	public BulkConfirmCOMMAND() {
		return this.executeCommand('BulkConfirmCOMMAND')
	}

	/**
	 * Odpotvrzení
	 */
	public BulkUnconfirmCOMMAND() {
		return this.executeCommand('BulkUnconfirmCOMMAND')
	}

	/**
	 * Změna data potvrzení
	 */
	public BulkChangeConfirmationDateCOMMAND(parameters: TBulkChangeConfirmationDateCOMMANDParams) {
		return this.executeCommand('BulkChangeConfirmationDateCOMMAND', parameters)
	}

	/**
	 * Storno
	 */
	public BulkCancelCOMMAND() {
		return this.executeCommand('BulkCancelCOMMAND')
	}

	/**
	 * Odstorno
	 */
	public BulkUnCancelCOMMAND() {
		return this.executeCommand('BulkUnCancelCOMMAND')
	}

	/**
	 * Potvrzení
	 */
	public ConfirmCOMMAND() {
		return this.executeCommand('ConfirmCOMMAND')
	}

	/**
	 * Odpotvrzení
	 */
	public UnconfirmCOMMAND() {
		return this.executeCommand('UnconfirmCOMMAND')
	}

	/**
	 * Změna data potvrzení
	 */
	public ChangeConfirmationDateCOMMAND(parameters: TChangeConfirmationDateCOMMANDParams) {
		return this.executeCommand('ChangeConfirmationDateCOMMAND', parameters)
	}

	/**
	 * Storno/Odstorno
	 */
	public CancelCOMMAND() {
		return this.executeCommand('CancelCOMMAND')
	}

	/**
	 * Zrušení stavu
	 */
	public ClearRecordStatusCOMMAND() {
		return this.executeCommand('ClearRecordStatusCOMMAND')
	}

	/**
	 * Změna stavu
	 */
	public ChangeRecordStatusCOMMAND() {
		return this.executeCommand('ChangeRecordStatusCOMMAND')
	}

	/**
	 * 
	 */
	public ChangeRecordStatusSWSCOMMAND(parameters: TChangeRecordStatusSWSCOMMANDParams) {
		return this.executeCommand('ChangeRecordStatusSWSCOMMAND', parameters)
	}

	/**
	 * Zrušení stavu
	 */
	public BulkClearRecordStatusCOMMAND() {
		return this.executeCommand('BulkClearRecordStatusCOMMAND')
	}

	/**
	 * Změna stavu
	 */
	public BulkChangeRecordStatusCOMMAND() {
		return this.executeCommand('BulkChangeRecordStatusCOMMAND')
	}

	/**
	 * Změna atributů
	 */
	public BulkChangeAttributesCOMMAND(parameters: TBulkChangeAttributesCOMMANDParams) {
		return this.executeCommand('BulkChangeAttributesCOMMAND', parameters)
	}

	/**
	 * Aktualizace sady atributů
	 */
	public BulkActualizeAttributesCOMMAND(parameters: TBulkActualizeAttributesCOMMANDParams) {
		return this.executeCommand('BulkActualizeAttributesCOMMAND', parameters)
	}

	/**
	 * Aktualizace sady atributů
	 */
	public ActualizeAttributesCOMMAND() {
		return this.executeCommand('ActualizeAttributesCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public ChangeGroupRightCOMMAND() {
		return this.executeCommand('ChangeGroupRightCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public BulkChangeGroupRightCOMMAND() {
		return this.executeCommand('BulkChangeGroupRightCOMMAND')
	}

	/**
	 * Aktualizace počítaných polí
	 */
	public BulkUpdateCalcFieldsCOMMAND() {
		return this.executeCommand('BulkUpdateCalcFieldsCOMMAND')
	}

	/**
	 * Kontrola legislativy
	 */
	public SwitchOffLegislationValidationCOMMAND() {
		return this.executeCommand('SwitchOffLegislationValidationCOMMAND')
	}
}

export type TBulkChangeConfirmationDateCOMMANDParams = {
	/**
	 * Datum potvrzení
	 */
	Confirmation1Date?: Date
	/**
	 * Hodina potvrzení
	 */
	Confirmation1Time?: Date
	/**
	 * Datum 2. potvrzení
	 */
	Confirmation2Date?: Date
	/**
	 * Hodina 2. potvrzení
	 */
	Confirmation2Time?: Date
	/**
	 * Kontrolovat záporné stavy
	 */
	CheckNegativeState?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TChangeConfirmationDateCOMMANDParams = {
	/**
	 * Datum potvrzení
	 */
	Confirmation1Date?: Date
	/**
	 * Hodina potvrzení
	 */
	Confirmation1Time?: Date
	/**
	 * Datum 2. potvrzení
	 */
	Confirmation2Date?: Date
	/**
	 * Hodina 2. potvrzení
	 */
	Confirmation2Time?: Date
	/**
	 * Kontrolovat záporné stavy
	 */
	CheckNegativeState?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TChangeRecordStatusSWSCOMMANDParams = {
	/**
	 * StatusId
	 */
	StatusId?: number
}

export type TBulkChangeAttributesCOMMANDParams = {
	/**
	 * Smazat atributy
	 */
	DeleteAttribute?: boolean
	/**
	 * Smazat všechny volné atributy
	 */
	DeleteFreeAttribute?: boolean
	/**
	 * Nastavit hodnoty atributů
	 */
	SetValues?: boolean
	/**
	 * Přidat nepřipojené atributy
	 */
	AddFreeAttributes?: boolean
	/**
	 * FreeAttributeEnabled
	 */
	FreeAttributeEnabled?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TBulkActualizeAttributesCOMMANDParams = {
	/**
	 * Aktualizovat včetně samotné sady atributů
	 */
	ActualizeAttributeSet?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}