import React, { useState, createContext, useContext } from "react"
import { InformationModal, InformationModalProps } from "./InformationModal"
import { ConfirmationModal, ConfirmationModalProps } from "./ConfirmationModal"
import { NumericKeyboardModal, NumericKeyboardModalProps } from "./NumericKeyboardModal"
import { LookupModal, LookupModalProps } from "./LookupModal"

export const MODAL_TYPES = {
    INFORMATION_MODAL: "INFORMAION_MODAL",
    CONFIRMATION_MODAL: "CONFIRMATION_MODAL",
    NUMERIC_KEYBOARD_MODAL: "NUMERIC_KEYBOARD_MODAL",
    LOOKUP_MODAL: "LOOKUP_MODAL"
}

const MODAL_COMPONENTS: any = {
    [MODAL_TYPES.INFORMATION_MODAL]: InformationModal,
    [MODAL_TYPES.CONFIRMATION_MODAL]: ConfirmationModal,
    [MODAL_TYPES.NUMERIC_KEYBOARD_MODAL]: NumericKeyboardModal,
    [MODAL_TYPES.LOOKUP_MODAL]: LookupModal
}

type GlobalModalContextState = {
    showModal: (modalType: string, modalProps?: InformationModalProps | ConfirmationModalProps | NumericKeyboardModalProps | LookupModalProps) => void
    hideModal: () => void
    store: {
        modalType: string | null
        modalProps?: InformationModalProps | ConfirmationModalProps | NumericKeyboardModalProps | LookupModalProps
    }
}

const initalState: GlobalModalContextState = {
    showModal: () => { },
    hideModal: () => { },
    store: {
        modalType: null,
        modalProps: undefined
    }
}

const GlobalModalContext = createContext(initalState)
export const useGlobalModalContext = () => useContext(GlobalModalContext)

export const GlobalModal: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [store, setStore] = useState<GlobalModalContextState["store"]>(initalState.store)
    const { modalType, modalProps } = store

    const showModal = (modalType: string, modalProps: GlobalModalContextState["store"]["modalProps"] = undefined) => {
        setStore({
            ...store,
            modalType,
            modalProps
        })
    }

    const hideModal = () => {
        setStore({
            ...store,
            modalType: null,
            modalProps: undefined
        })
    }

    const renderComponent = () => {
        if (!modalType) return null
        const ModalComponent = MODAL_COMPONENTS[modalType]

        if (!ModalComponent) return null

        return <ModalComponent id="global-modal" {...modalProps} />
    }

    return (
        <GlobalModalContext.Provider value={{ store, showModal, hideModal }}>
            {renderComponent()}
            {children}
        </GlobalModalContext.Provider>
    )
}