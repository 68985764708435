import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import WorkerProtectiveEquipmentReasons from './WorkerProtectiveEquipmentReasons'
import TReceiptCardDM from './TReceiptCardDM'
import TPeriodicityDM from './TPeriodicityDM'
import TReleaseNoteDM from './TReleaseNoteDM'
import TMeasureUnitDM from './TMeasureUnitDM'
import TPrEmploymentDM from './TPrEmploymentDM'
import TEmployedPersonDM from './TEmployedPersonDM'
import TArticleDM from './TArticleDM'
import TK2UserLookupDM from './TK2UserLookupDM'
import TEmployedPersonSimpleDM from './TEmployedPersonSimpleDM'
import TPrEmploymentSimpleDM from './TPrEmploymentSimpleDM'
import TCommentDM from './TCommentDM'
import TExternalDocumentItemDM from './TExternalDocumentItemDM'
import TCompanyLinkDM from './TCompanyLinkDM'

/**
 * Osobní ochranné pracovní prostředky
 */
export default class TWorkerProtectionEquipmentAsItemDM extends Data {
	public static className = 'TWorkerProtectionEquipmentAsItemDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = false

	/**
	 * Datum vrácení
	 */
	ReturnDate?: Date

	/**
	 * Předpokládané datum expirace
	 */
	ReturnAssumedDate?: Date

	/**
	 * Datum výdeje
	 */
	IssueDate?: Date

	/**
	 * Množství
	 */
	Amount?: number

	/**
	 * Právo na záznam
	 */
	RightGroupId?: number

	/**
	 * Časové razítko
	 */
	TimeStamp?: string

	/**
	 * Změněno
	 */
	ChangedOn?: Date

	/**
	 * Vytvořeno
	 */
	CreatedOn?: Date

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Poslední komentář
	 */
	LastCommentParamCalc?: string

	/**
	 * Právo na záznam
	 */
	RightGroupIdCalc?: number

	/**
	 * Důvod poskytnutí
	 */
	@Type(() => WorkerProtectiveEquipmentReasons)
	ReasonId?: WorkerProtectiveEquipmentReasons

	/**
	 * Příjemka
	 */
	@Type(() => TReceiptCardDM)
	ReceiptCardRID?: TReceiptCardDM

	/**
	 * Periodicita
	 */
	@Type(() => TPeriodicityDM)
	PeriodicityRID?: TPeriodicityDM

	/**
	 * Výdejka
	 */
	@Type(() => TReleaseNoteDM)
	ReleaseNoteRID?: TReleaseNoteDM

	/**
	 * Jednotka
	 */
	@Type(() => TMeasureUnitDM)
	MeasureUnitId?: TMeasureUnitDM

	/**
	 * Pracovní vztah
	 */
	@Type(() => TPrEmploymentDM)
	PrEmploymentId?: TPrEmploymentDM

	/**
	 * Pracovník
	 */
	@Type(() => TEmployedPersonDM)
	WorkerId?: TEmployedPersonDM

	/**
	 * Zboží
	 */
	@Type(() => TArticleDM)
	ArticleId?: TArticleDM

	/**
	 * Změnil
	 */
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM

	/**
	 * Vytvořil
	 */
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM

	/**
	 * Pracovník
	 */
	@Type(() => TEmployedPersonSimpleDM)
	EmployedPersonSimpleIdCalc?: TEmployedPersonSimpleDM

	/**
	 * Pracovní vztah
	 */
	@Type(() => TPrEmploymentSimpleDM)
	PrEmploymentSimpleIdCalc?: TPrEmploymentSimpleDM

	/**
	 * Komentáře
	 */
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]

	/**
	 * Dokumenty
	 */
	@Type(() => TExternalDocumentItemDM)
	ExternalDocumentItemChild?: TExternalDocumentItemDM[]

	/**
	 * undefined
	 */
	@Type(() => TCompanyLinkDM)
	CompanyChild?: TCompanyLinkDM[]
}