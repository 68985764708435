import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TRoutingVariantDM from './TRoutingVariantDM'
import TArticleDM from './TArticleDM'
import TMeasureUnitDM from './TMeasureUnitDM'
import AlternativeUnit from './AlternativeUnit'
import TCurrencyDM from './TCurrencyDM'

/**
 * Kalkulace - položky kategorií
 */
export default class TProductCostingSummaryItemDM extends Data {
	public static className = 'TProductCostingSummaryItemDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = false

	/**
	 * Generováno
	 */
	IsGenerated?: string

	/**
	 * Cena výrobku
	 */
	ProductPrice?: number

	/**
	 * Cena
	 */
	MaterialPrice?: number

	/**
	 * Alternativní jednotka
	 */
	MeasureUnitId?: number

	/**
	 * Cena výrobku
	 */
	ProductPriceC?: number

	/**
	 * Cena
	 */
	MaterialPriceC?: number

	/**
	 * Množství
	 */
	Quantity?: number

	/**
	 * Název
	 */
	Name?: string

	/**
	 * undefined
	 */
	SummaryRID?: number

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Množství
	 */
	QuantityCalc?: number

	/**
	 * Jednotková cena
	 */
	PriceCUMCalc?: number

	/**
	 * Celková cena
	 */
	TotalPriceCCalc?: number

	/**
	 * Celková cena
	 */
	TotalPriceCalc?: number

	/**
	 * undefined
	 */
	ExchangeRateCalc?: number

	/**
	 * Varianta TP
	 */
	@Type(() => TRoutingVariantDM)
	RoutingVariantId?: TRoutingVariantDM

	/**
	 * Zboží
	 */
	@Type(() => TArticleDM)
	ArticleId?: TArticleDM

	/**
	 * undefined
	 */
	@Type(() => TMeasureUnitDM)
	ArticleMeasureUnitIdCalc?: TMeasureUnitDM

	/**
	 * undefined
	 */
	@Type(() => AlternativeUnit)
	GeneralMeasureUnitIdCalc?: AlternativeUnit

	/**
	 * Měna
	 */
	@Type(() => TCurrencyDM)
	CurrencyCalc?: TCurrencyDM

	/**
	 * undefined
	 */
	@Type(() => AlternativeUnit)
	MeasureUnitGridIdCalc?: AlternativeUnit
}