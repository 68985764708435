import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import esFeedAssignment from './esFeedAssignment'
import TEshopStockTextDM from './TEshopStockTextDM'
import Availability from './Availability'
import TEshopCategoryInfoDM from './TEshopCategoryInfoDM'
import TEshopDM from './TEshopDM'
import TArticleDM from './TArticleDM'
import TTextDM from './TTextDM'
import TWebURLAddressItemDM from './TWebURLAddressItemDM'
import TLanguageMemoTranslationChildDM from './TLanguageMemoTranslationChildDM'

/**
 * Příznaky zboží pro e-shopy
 */
export default class TEshopArticleShopValueTextDM extends Data {
	public static className = 'TEshopArticleShopValueTextDM'
	public static primaryKey = 'RID'
	public static systemFields = [
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = true

	/**
	 * Minimální odběr
	 */
	MinPurchaseQuantity?: number

	/**
	 * URL
	 */
	PageUrl?: string

	/**
	 * Analytika
	 */
	PageAnalytics?: string

	/**
	 * Pouze poptat
	 */
	IsQuotationRequest?: string

	/**
	 * Klíčová slova
	 */
	KeyWords?: string

	/**
	 * Popis stránky
	 */
	PageDescription?: string

	/**
	 * Název stránky
	 */
	PageTitle?: string

	/**
	 * Popis
	 */
	Description?: string

	/**
	 * Původní cena
	 */
	OriginalPrice?: number

	/**
	 * Poznámka
	 */
	Note?: string

	/**
	 * Krátký popis
	 */
	ShortDescription?: string

	/**
	 * Název
	 */
	Name?: string

	/**
	 * Připravujeme
	 */
	IsComingSoon?: string

	/**
	 * Tip
	 */
	IsTip?: string

	/**
	 * Použité zboží
	 */
	IsSecondHand?: string

	/**
	 * Doprava zdarma
	 */
	IsFreeShipping?: string

	/**
	 * Výprodej
	 */
	IsSellout?: string

	/**
	 * Novinka
	 */
	IsNewArticle?: string

	/**
	 * Sleva
	 */
	IsDiscount?: string

	/**
	 * Akce
	 */
	IsCampaign?: string

	/**
	 * Prodej registrovaným
	 */
	IsUnableToBuyNotLogged?: string

	/**
	 * Neprodejné zboží
	 */
	IsUnableToBuy?: string

	/**
	 * Skrýt na e-shopu
	 */
	IsHiddenOnShop?: string

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * DescriptionCalc
	 */
	DescriptionCalc?: string

	/**
	 * Měna
	 */
	CurrencyCalc?: string

	/**
	 * undefined
	 */
	UrlCalc?: string

	/**
	 * Název
	 */
	TranslatedNameCalc?: string

	/**
	 * Krátký popis
	 */
	TranslatedShortDescriptionCalc?: string

	/**
	 * Poznámka
	 */
	TranslatedNoteCalc?: string

	/**
	 * Popis
	 */
	TranslatedDescriptionCalc?: string

	/**
	 * Název stránky
	 */
	TranslatedPageTitleCalc?: string

	/**
	 * Popis stránky
	 */
	TranslatedPageDescriptionCalc?: string

	/**
	 * Klíčová slova
	 */
	TranslatedKeyWordsCalc?: string

	/**
	 * Analytika
	 */
	TranslatedPageAnalyticsCalc?: string

	/**
	 * URL
	 */
	TranslatedPageUrlCalc?: string

	/**
	 * Přiřazení do vyhledávačů
	 */
	@Type(() => esFeedAssignment)
	FeedAssignmentId?: esFeedAssignment

	/**
	 * Alt. text skl. dostupnosti
	 */
	@Type(() => TEshopStockTextDM)
	StockTextRID?: TEshopStockTextDM

	/**
	 * Dostupnost
	 */
	@Type(() => Availability)
	AvailabilityId?: Availability

	/**
	 * Výchozí kategorie
	 */
	@Type(() => TEshopCategoryInfoDM)
	MainCategoryRID?: TEshopCategoryInfoDM

	/**
	 * E-shop
	 */
	@Type(() => TEshopDM)
	ShopRID?: TEshopDM

	/**
	 * Zboží
	 */
	@Type(() => TArticleDM)
	ArticleId?: TArticleDM

	/**
	 * undefined
	 */
	@Type(() => TTextDM)
	AssignedCalc?: TTextDM

	/**
	 * TranslationLanguageIdCalc
	 */
	@Type(() => TTextDM)
	TranslationLanguageIdCalc?: TTextDM

	/**
	 * undefined
	 */
	@Type(() => TWebURLAddressItemDM)
	WebUrlPageAddressItemChild?: TWebURLAddressItemDM[]

	/**
	 * undefined
	 */
	@Type(() => TLanguageMemoTranslationChildDM)
	TranslatedFieldValuesChild?: TLanguageMemoTranslationChildDM[]

	/**
	 * Změna skupiny práv
	 */
	public ChangeGroupRightCOMMAND() {
		return this.executeCommand('ChangeGroupRightCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public BulkChangeGroupRightCOMMAND() {
		return this.executeCommand('BulkChangeGroupRightCOMMAND')
	}

	/**
	 * Aktualizace počítaných polí
	 */
	public BulkUpdateCalcFieldsCOMMAND() {
		return this.executeCommand('BulkUpdateCalcFieldsCOMMAND')
	}

	/**
	 * Kontrola legislativy
	 */
	public SwitchOffLegislationValidationCOMMAND() {
		return this.executeCommand('SwitchOffLegislationValidationCOMMAND')
	}
}