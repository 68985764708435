import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TPaymentMethodDM from './TPaymentMethodDM'
import TCompanyLinkDM from './TCompanyLinkDM'

/**
 * Platební podmínky zákazníka pro nákup
 */
export default class TPaymentConditionTradingPartnerPurchaseDM extends Data {
	public static className = 'TPaymentConditionTradingPartnerPurchaseDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = false

	/**
	 * Identifikace hlavičky
	 */
	HeaderRID?: number

	/**
	 * Typ hlavičky
	 */
	HeaderTypeId?: number

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * Datum
	 */
	CashDiscountDate?: Date

	/**
	 * Skonto (%)
	 */
	CashDiscountPercentage?: number

	/**
	 * Platba do (dny)
	 */
	PaymentMaxDay?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Popis podmínky
	 */
	ConditionDescriptionCalc?: string

	/**
	 * Závislá na způsobu platby
	 */
	PaymentTypeBitCalc?: number

	/**
	 * Skonto z netto částky
	 */
	NetBitCalc?: number

	/**
	 * Způsob platby
	 */
	@Type(() => TPaymentMethodDM)
	PaymentTypeId?: TPaymentMethodDM

	/**
	 * undefined
	 */
	@Type(() => TCompanyLinkDM)
	CompanyChild?: TCompanyLinkDM[]
}