import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TDiscountCouponDM from './TDiscountCouponDM'
import TPromotionDM from './TPromotionDM'
import TArticleDM from './TArticleDM'

/**
 * Položky košíku
 */
export default class TEshopBasketItemDM extends Data {
	public static className = 'TEshopBasketItemDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = false

	/**
	 * Body věrnostního programu
	 */
	LoyaltyProgramPointCount?: number

	/**
	 * Věrnostní program
	 */
	LoyaltyProgramRID?: number

	/**
	 * Chyba kupónu
	 */
	CouponErrId?: number

	/**
	 * Poukaz
	 */
	IsVoucher?: string

	/**
	 * Dárek
	 */
	IsGift?: string

	/**
	 * Seskupení
	 */
	PromotionGroupId?: number

	/**
	 * WebData
	 */
	WebData?: string

	/**
	 * Dodatečná info. CRC
	 */
	AdditionalInfoCRC?: string

	/**
	 * Sazba daně
	 */
	VATId?: number

	/**
	 * Časové razítko
	 */
	TimeStamp?: string

	/**
	 * Nadřízená položka
	 */
	SuperiorRID?: number

	/**
	 * Katalogová cena Netto
	 */
	ListPriceNet?: number

	/**
	 * Katalogová cena Brutto
	 */
	ListPriceGross?: number

	/**
	 * Procentuální sleva
	 */
	IsPercentageDiscount?: string

	/**
	 * Sleva
	 */
	Discount?: number

	/**
	 * Popis
	 */
	ItemDescription?: string

	/**
	 * Název
	 */
	ItemName?: string

	/**
	 * Cena Netto
	 */
	PriceNet?: number

	/**
	 * Cena Brutto
	 */
	PriceGross?: number

	/**
	 * Pořadí
	 */
	SequenceNumber?: number

	/**
	 * Dodatečná info.
	 */
	AdditionalInfo?: string

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * Množství
	 */
	Quantity?: number

	/**
	 * Košík
	 */
	BasketRID?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * undefined
	 */
	MeasureUnitIdCalc?: number

	/**
	 * undefined
	 */
	RatioUMCalc?: number

	/**
	 * undefined
	 */
	QuantityUMCalc?: number

	/**
	 * undefined
	 */
	DiscountCalc?: number

	/**
	 * undefined
	 */
	PriceEditedCalc?: number

	/**
	 * undefined
	 */
	PromotionTypeCalc?: number

	/**
	 * Slevový kupón
	 */
	@Type(() => TDiscountCouponDM)
	CouponRID?: TDiscountCouponDM

	/**
	 * Promoakce
	 */
	@Type(() => TPromotionDM)
	PromotionRID?: TPromotionDM

	/**
	 * Zboží
	 */
	@Type(() => TArticleDM)
	ArticleId?: TArticleDM
}