import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import AlternativeUnit from './AlternativeUnit'
import TTextDM from './TTextDM'
import TPriceListDM from './TPriceListDM'
import TProductKeyDM from './TProductKeyDM'
import TArticleDM from './TArticleDM'
import TPriceListCategoryDM from './TPriceListCategoryDM'
import TCurrencyDM from './TCurrencyDM'
import TPriceListItemDM from './TPriceListItemDM'

/**
 * Definice
 */
export default class TPriceListDefinitionDM extends Data {
	public static className = 'TPriceListDefinitionDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = false

	/**
	 * Převzít všechny množstevní ceny
	 */
	TakeAllQuantityPrices?: string

	/**
	 * Pouze pro násobek množství
	 */
	IsOnlyForMultipleOfQuantity?: string

	/**
	 * Kurz
	 */
	ExchangeRate?: number

	/**
	 * Neaplikovat další slevy
	 */
	IsFinalPrice?: string

	/**
	 * Nepřepočítávat
	 */
	IsLocked?: string

	/**
	 * Brutto
	 */
	PriceGross?: number

	/**
	 * Netto
	 */
	PriceNet?: number

	/**
	 * Zaokrouhlovat brutto částku
	 */
	IsGrossPriceRounding?: string

	/**
	 * Relativní
	 */
	IsRelative?: string

	/**
	 * Položku ceníku uplatnit pouze při prodeji v zadané jednotce
	 */
	IsOnlyForMeasureUnit?: string

	/**
	 * Zdroj ceny
	 */
	PriceSourceRID?: number

	/**
	 * Zdroj položek
	 */
	ArticleSourceRID?: number

	/**
	 * Typ zdroje položek
	 */
	ArticleSourceTypeId?: number

	/**
	 * Zadáno v %
	 */
	IsPercentage?: string

	/**
	 * Baťa
	 */
	RoundingCorrection?: number

	/**
	 * Konstanta
	 */
	RoundingConstant?: number

	/**
	 * Řád
	 */
	RoundingOrder?: number

	/**
	 * Sleva
	 */
	Reduction?: number

	/**
	 * Množství
	 */
	Quantity?: number

	/**
	 * Ceník
	 */
	PriceListRID?: number

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * undefined
	 */
	ArticleSrcProductGrRIDCalc?: number

	/**
	 * undefined
	 */
	PriceSourceMarginRIDCalc?: number

	/**
	 * undefined
	 */
	ArticleSourceDescriptionCalc?: string

	/**
	 * undefined
	 */
	PriceSourceDescriptionCalc?: string

	/**
	 * Jednotka
	 */
	QuantityMeasureUnitDescriptionCalc?: string

	/**
	 * T
	 */
	DiscountTypeSuffixCalc?: string

	/**
	 * undefined
	 */
	VATCoefficientCalc?: number

	/**
	 * Kurz
	 */
	ExchangeRateCalc?: number

	/**
	 * Jiná jednotka
	 */
	@Type(() => AlternativeUnit)
	MeasureUnitId?: AlternativeUnit

	/**
	 * Typ jednotky
	 */
	@Type(() => TTextDM)
	MeasureUnitTypeId?: TTextDM

	/**
	 * Typ zdroje ceny
	 */
	@Type(() => TTextDM)
	PriceSourceTypeId?: TTextDM

	/**
	 * undefined
	 */
	@Type(() => TPriceListDM)
	ArticleSourcePriceListRIDCalc?: TPriceListDM

	/**
	 * undefined
	 */
	@Type(() => TProductKeyDM)
	ArticleSourceProductKeyRIDCalc?: TProductKeyDM

	/**
	 * undefined
	 */
	@Type(() => TArticleDM)
	ArticleSourceArticleRIDCalc?: TArticleDM

	/**
	 * undefined
	 */
	@Type(() => TPriceListDM)
	PriceSourcePriceListRIDCalc?: TPriceListDM

	/**
	 * undefined
	 */
	@Type(() => TPriceListCategoryDM)
	ArticleSourceCategory1RIDCalc?: TPriceListCategoryDM

	/**
	 * undefined
	 */
	@Type(() => TPriceListCategoryDM)
	ArticleSourceCategory2RIDCalc?: TPriceListCategoryDM

	/**
	 * undefined
	 */
	@Type(() => TPriceListCategoryDM)
	ArticleSourceCategory3RIDCalc?: TPriceListCategoryDM

	/**
	 * undefined
	 */
	@Type(() => TPriceListCategoryDM)
	ArticleSourceCategory4RIDCalc?: TPriceListCategoryDM

	/**
	 * undefined
	 */
	@Type(() => TPriceListCategoryDM)
	ArticleSourceCategory5RIDCalc?: TPriceListCategoryDM

	/**
	 * Měna
	 */
	@Type(() => TCurrencyDM)
	ExchangeRateToCurrencyCalc?: TCurrencyDM

	/**
	 * Měna zdroje
	 */
	@Type(() => TCurrencyDM)
	ExchangeRateFromCurrencyCalc?: TCurrencyDM

	/**
	 * Položky definice
	 */
	@Type(() => TPriceListItemDM)
	ItemChild?: TPriceListItemDM[]
}