import Data from 'react-k2-api/Api/Data'
import { Type } from 'class-transformer'
import TCachedBookDM from './TCachedBookDM'
import TPeriodDM from './TPeriodDM'

/**
 * Číslování dokladů
 */
export default class TRecordNumberDM extends Data {
	public static className = 'TRecordNumberDM'
	public static systemFields = [
		'RecordID',
	]

	public static isHeader = false

	/**
	 * Typ dokladu
	 */
	DocumentType?: number

	/**
	 * Číslování
	 */
	NumberingType?: number

	/**
	 * Maximální
	 */
	MaxValue?: number

	/**
	 * Minimální
	 */
	MinValue?: number

	/**
	 * Aktuální
	 */
	CurrentValue?: number

	/**
	 * Serie
	 */
	Serie?: string

	/**
	 * E_Doc
	 */
	TableId?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * undefined
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * V
	 */
	AlertCalc?: number

	/**
	 * Čerpáno [%]
	 */
	PercentCalc?: number

	/**
	 * Kniha
	 */
	@Type(() => TCachedBookDM)
	BookId?: TCachedBookDM

	/**
	 * Období
	 */
	@Type(() => TPeriodDM)
	BusinessYearId?: TPeriodDM
}