import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TWMSEquipmentDM from './TWMSEquipmentDM'
import TTextDM from './TTextDM'
import AlternativeUnit from './AlternativeUnit'

/**
 * Alternativní Jednotky
 */
export default class TArticleMeasureUnitDM extends Data {
	public static className = 'TArticleMeasureUnitDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = false

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * Plocha
	 */
	Area?: number

	/**
	 * Netto
	 */
	WeightNet?: number

	/**
	 * Brutto
	 */
	WeightGross?: number

	/**
	 * Hloubka
	 */
	Depth?: number

	/**
	 * Výška
	 */
	Height?: number

	/**
	 * Šířka
	 */
	Width?: number

	/**
	 * Objem
	 */
	Volume?: number

	/**
	 * Poměr základní/altern.
	 */
	Ratio?: number

	/**
	 * Číslo zboží
	 */
	ArticleId?: number

	/**
	 * Číslo
	 */
	Id?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Poměr alternativní/zákl.
	 */
	InverseRateCalc?: number

	/**
	 * undefined
	 */
	BasicBlockedIdCalc?: number

	/**
	 * Netto
	 */
	WeightNetCalc?: number

	/**
	 * undefined
	 */
	CaptionRatioCalc?: string

	/**
	 * undefined
	 */
	CaptionNotRatioCalc?: string

	/**
	 * undefined
	 */
	CaptionBasicCalc?: number

	/**
	 * undefined
	 */
	LanguageTextCalc?: string

	/**
	 * Č. jednotky
	 */
	MeasureUnitIdCalc?: number

	/**
	 * Manip.technika
	 */
	@Type(() => TWMSEquipmentDM)
	HandlingEquipmentId?: TWMSEquipmentDM

	/**
	 * Jedn. plochy
	 */
	@Type(() => TTextDM)
	AreaUnitId?: TTextDM

	/**
	 * Jedn. rozměru
	 */
	@Type(() => TTextDM)
	DimensionUnitId?: TTextDM

	/**
	 * Jedn. objemu
	 */
	@Type(() => TTextDM)
	VolumeUnitId?: TTextDM

	/**
	 * Zkratka
	 */
	@Type(() => AlternativeUnit)
	Abbr?: AlternativeUnit

	/**
	 * Bkolováno
	 */
	@Type(() => TTextDM)
	BlockedRecordBitCalc?: TTextDM

	/**
	 * Implicitní pro průvodky
	 */
	@Type(() => TTextDM)
	DefaultForJobCardBitCalc?: TTextDM

	/**
	 * Implicitní pro nákup
	 */
	@Type(() => TTextDM)
	DefaultForPurchaseBitCalc?: TTextDM

	/**
	 * Implicitní pro prodej
	 */
	@Type(() => TTextDM)
	DefaultForSaleBitCalc?: TTextDM

	/**
	 * Implicitní pro převodky
	 */
	@Type(() => TTextDM)
	DefaultForTransferNoteBitCalc?: TTextDM

	/**
	 * Celočíselná hodnota
	 */
	@Type(() => TTextDM)
	IntegerUnitIdCalc?: TTextDM

	/**
	 * Implicitní pro e-shop
	 */
	@Type(() => TTextDM)
	DefaultForEshopBitCalc?: TTextDM

	/**
	 * Zobrazovat na e-shopu
	 */
	@Type(() => TTextDM)
	ShowOnEshopBitCalc?: TTextDM

	/**
	 * Množstevní pro internetový obchod
	 */
	@Type(() => TTextDM)
	EshopQuantityBitCalc?: TTextDM

	/**
	 * Implicitní pro inventury
	 */
	@Type(() => TTextDM)
	DefaultForStockTakingBitCalc?: TTextDM

	/**
	 * Implicitní pro TP
	 */
	@Type(() => TTextDM)
	DefaultForRoutingVariantBitCalc?: TTextDM
}