import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TCountryDM from './TCountryDM'

/**
 * Typy opravných položek k pohledávkám
 */
export default class TReceivableAdjustingEntryTypeDM extends Data {
	public static className = 'TReceivableAdjustingEntryTypeDM'
	public static primaryKey = 'RID'
	public static systemFields = [
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = true

	/**
	 * Procento odpisu
	 */
	Percentage?: number

	/**
	 * Karta int. dokl. zrušení
	 */
	DocumentCardCancelId?: number

	/**
	 * Pro účetní oprávky
	 */
	IsForAccounting?: string

	/**
	 * Priorita
	 */
	PriorityId?: number

	/**
	 * Popis
	 */
	Description?: string

	/**
	 * Zkratka
	 */
	Abbr?: string

	/**
	 * Podmínky
	 */
	Conditions?: string

	/**
	 * Karta int. dokl.
	 */
	DocumentCardId?: number

	/**
	 * Platnost do
	 */
	DateTo?: Date

	/**
	 * Platnost od
	 */
	DateFrom?: Date

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * undefined
	 */
	CurrencyCalc?: string

	/**
	 * Prodlení (ve dnech) od
	 */
	DelayDaysMinCalc?: number

	/**
	 * Prodlení (ve dnech) do
	 */
	DelayDaysMaxCalc?: number

	/**
	 * Prodlení (v měsících) od
	 */
	DelayMonthsMinCalc?: number

	/**
	 * Prodlení (v měsících) do
	 */
	DelayMonthsMaxCalc?: number

	/**
	 * Hodnota pohledávky od
	 */
	ValueMinCalc?: number

	/**
	 * Hodnota pohledávky do
	 */
	ValueMaxCalc?: number

	/**
	 * Celk. hodnota pohledávek dlužníka od
	 */
	ReceivablesFromTotalValueCalc?: number

	/**
	 * Celk. hodnota pohledávek dlužníka do
	 */
	ReceivablesToTotalValueCalc?: number

	/**
	 * Dlužník v insolvenci
	 */
	IsInsolventCompanyCalc?: string

	/**
	 * undefined
	 */
	ImportExportTypeCalc?: string

	/**
	 * Stát
	 */
	@Type(() => TCountryDM)
	CountryId?: TCountryDM

	/**
	 * Změna skupiny práv
	 */
	public ChangeGroupRightCOMMAND() {
		return this.executeCommand('ChangeGroupRightCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public BulkChangeGroupRightCOMMAND() {
		return this.executeCommand('BulkChangeGroupRightCOMMAND')
	}

	/**
	 * Aktualizace počítaných polí
	 */
	public BulkUpdateCalcFieldsCOMMAND() {
		return this.executeCommand('BulkUpdateCalcFieldsCOMMAND')
	}

	/**
	 * Kontrola legislativy
	 */
	public SwitchOffLegislationValidationCOMMAND() {
		return this.executeCommand('SwitchOffLegislationValidationCOMMAND')
	}
}