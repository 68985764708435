import Data from 'react-k2-api/Api/Data'
import { Type } from 'class-transformer'
import TTextDM from './TTextDM'

/**
 * Oslovení
 */
export default class TSetContactPersonAddressingDM extends Data {
	public static className = 'TSetContactPersonAddressingDM'
	public static systemFields = [
		'RecordID',
	]

	public static isHeader = false

	/**
	 * Value
	 */
	Value?: string

	/**
	 * Číslo
	 */
	ContactPersonId?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * undefined
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * TypeId
	 */
	@Type(() => TTextDM)
	TypeId?: TTextDM
}