import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TWMSEquipmentDM from './TWMSEquipmentDM'
import LocationsStatus from './LocationsStatus'
import TWarehouseZoneDM from './TWarehouseZoneDM'
import TTradingPartnerDM from './TTradingPartnerDM'
import TK2UserLookupDM from './TK2UserLookupDM'
import TWarehouseDM from './TWarehouseDM'
import TTextDM from './TTextDM'
import TArticleDM from './TArticleDM'
import TPeriodListDM from './TPeriodListDM'
import TMarketingActivityTypeDM from './TMarketingActivityTypeDM'
import TCommentDM from './TCommentDM'
import TStockCardDM from './TStockCardDM'
import TExternalDocumentItemDM from './TExternalDocumentItemDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TAttachedDocumentDM from './TAttachedDocumentDM'
import TOldNoteDM from './TOldNoteDM'
import TStockCardOldDM from './TStockCardOldDM'

/**
 * Umístění
 */
export default class TLocationSimpleDM extends Data {
	public static className = 'TLocationSimpleDM'
	public static primaryKey = 'Id'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.Id!
	}

	public static isHeader = true

	/**
	 * Nezahrnovat do sumačních stavů pro e-shopy
	 */
	IgnoreSumCalculation?: string

	/**
	 * Pozice
	 */
	Position?: string

	/**
	 * Police
	 */
	Shelf?: string

	/**
	 * Sloupec
	 */
	Bay?: string

	/**
	 * Řada
	 */
	Row?: string

	/**
	 * Ulička
	 */
	Aisle?: string

	/**
	 * Oblast
	 */
	Area?: string

	/**
	 * Formát zkratky
	 */
	AbbrFormatRID?: number

	/**
	 * Nastavení
	 */
	Settings?: string

	/**
	 * Souřadnice
	 */
	Coordinates?: string

	/**
	 * Časové razítko
	 */
	TimeStamp?: string

	/**
	 * Typ
	 */
	LocationTypeId?: number

	/**
	 * Změněno
	 */
	ChangedOn?: Date

	/**
	 * Min. množství
	 */
	MinQuantity?: number

	/**
	 * Max. množství
	 */
	MaxQuantity?: number

	/**
	 * Popis
	 */
	Description?: string

	/**
	 * Zkratka
	 */
	Abbr?: string

	/**
	 * Číslo
	 */
	Id?: number

	/**
	 * Neoptimalizovat umístění
	 */
	Ex_NoOptimization?: string

	/**
	 * Neposílat na eshop
	 */
	EX_No_Eshop?: string

	/**
	 * Nepočítat do cen
	 */
	EX_No_Ceny?: string

	/**
	 * Rozlišení ABC
	 */
	EX_L_ABC?: string

	/**
	 * Manipulační kategori
	 */
	EX_L_Manipulace?: string

	/**
	 * EX_TypVydeje
	 */
	EX_TypVydeje?: number

	/**
	 * EX_PrioritaVyd
	 */
	EX_PrioritaVyd?: number

	/**
	 * Ex_Oblast
	 */
	Ex_Oblast?: string

	/**
	 * Ex_Hala
	 */
	Ex_Hala?: string

	/**
	 * Ex_BlokPrVy
	 */
	Ex_BlokPrVy?: string

	/**
	 * Ex_CDoRekl
	 */
	Ex_CDoRekl?: number

	/**
	 * Ex_KontCis
	 */
	Ex_KontCis?: number

	/**
	 * Ex_Nosnost
	 */
	Ex_Nosnost?: number

	/**
	 * Ex_LFS_MistoK2
	 */
	Ex_LFS_MistoK2?: string

	/**
	 * Ex_Sektor
	 */
	Ex_Sektor?: string

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * B
	 */
	BlockedRecordImgCalc?: string

	/**
	 * Dispozice celkem
	 */
	AvailableTotalQuantityCalc?: number

	/**
	 * Zadáno celkem
	 */
	TotalAssignedCalc?: number

	/**
	 * Celk. hmotnost (kg)
	 */
	TotalWeightCalc?: number

	/**
	 * Počet zboží
	 */
	ArticleCountCalc?: number

	/**
	 * Formát
	 */
	FormatAbbrCalc?: string

	/**
	 * undefined
	 */
	InvalidRecordBitCalc?: number

	/**
	 * Blokovat pohyby
	 */
	BlockedRecordBitCalc?: number

	/**
	 * Přijato celkem
	 */
	TotalReceivedCalc?: number

	/**
	 * Expedice celkem
	 */
	TotalDispatchCalc?: number

	/**
	 * V příjmu celkem
	 */
	InReceiptTotalQuantityCalc?: number

	/**
	 * V převodu celkem
	 */
	TotalInTransferCalc?: number

	/**
	 * Prázdné
	 */
	IsFormShowEmptyCalc?: string

	/**
	 * Naskladněné
	 */
	IsFormShowInUsedCalc?: string

	/**
	 * Nenulové
	 */
	IsFormShowSkipNullCalc?: string

	/**
	 * Automaticky nevydávat
	 */
	NotReleaseAutomaticallyBitCalc?: number

	/**
	 * Zaplnění hmotnostní v procentech
	 */
	FillingPercentageWeightCalc?: number

	/**
	 * Zaplnění objemové v procentech
	 */
	FillingPercentageVolumeCalc?: number

	/**
	 * Celkový objem
	 */
	TotalVolumePARAMCalc?: number

	/**
	 * undefined
	 */
	TextHeaderMemoCalc?: string

	/**
	 * undefined
	 */
	TextFooterMemoCalc?: string

	/**
	 * Poslední komentář
	 */
	LastCommentParamCalc?: string

	/**
	 * Manip.technika
	 */
	@Type(() => TWMSEquipmentDM)
	HandlingEquipmentId?: TWMSEquipmentDM

	/**
	 * Stav
	 */
	@Type(() => LocationsStatus)
	StatusId?: LocationsStatus

	/**
	 * Zóna
	 */
	@Type(() => TWarehouseZoneDM)
	WarehouseZoneRID?: TWarehouseZoneDM

	/**
	 * Zákazník
	 */
	@Type(() => TTradingPartnerDM)
	TradingPartnerId?: TTradingPartnerDM

	/**
	 * Změnil
	 */
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM

	/**
	 * Číslo skladu
	 */
	@Type(() => TWarehouseDM)
	WarehouseId?: TWarehouseDM

	/**
	 * Z
	 */
	@Type(() => TTextDM)
	InvalidRecordImgCalc?: TTextDM

	/**
	 * Č. skladu
	 */
	@Type(() => TWarehouseDM)
	WarehouseIdCalc?: TWarehouseDM

	/**
	 * Č. zboží
	 */
	@Type(() => TArticleDM)
	ArticleIdCalc?: TArticleDM

	/**
	 * undefined
	 */
	@Type(() => TArticleDM)
	FilterArticleIdCalc?: TArticleDM

	/**
	 * undefined
	 */
	@Type(() => TWarehouseZoneDM)
	WarehouseZoneRIDCalc?: TWarehouseZoneDM

	/**
	 * Perioda od
	 */
	@Type(() => TPeriodListDM)
	PeriodFromIdCalc?: TPeriodListDM

	/**
	 * Perioda do
	 */
	@Type(() => TPeriodListDM)
	PeriodToIdCalc?: TPeriodListDM

	/**
	 * D
	 */
	@Type(() => TTextDM)
	DocumentExistsCalc?: TTextDM

	/**
	 * undefined
	 */
	@Type(() => TMarketingActivityTypeDM)
	ActivityTypeRIDCalc?: TMarketingActivityTypeDM

	/**
	 * Komentáře
	 */
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]

	/**
	 * undefined
	 */
	@Type(() => TStockCardDM)
	StockCardChild?: TStockCardDM[]

	/**
	 * Dokumenty
	 */
	@Type(() => TExternalDocumentItemDM)
	ExternalDocumentItemChild?: TExternalDocumentItemDM[]

	/**
	 * Text hlavičky
	 */
	@Type(() => TLanguageMemoTranslationDM)
	TextHeaderChild?: TLanguageMemoTranslationDM[]

	/**
	 * Text patičky
	 */
	@Type(() => TLanguageMemoTranslationDM)
	TextFooterChild?: TLanguageMemoTranslationDM[]

	/**
	 * Svázané doklady
	 */
	@Type(() => TAttachedDocumentDM)
	AttachedDocumentOldChild?: TAttachedDocumentDM[]

	/**
	 * Poznámky
	 */
	@Type(() => TOldNoteDM)
	DeprecatedNoteOldChild?: TOldNoteDM[]

	/**
	 * Položky skladové karty
	 */
	@Type(() => TStockCardOldDM)
	OldStockCardOldChild?: TStockCardOldDM[]

	/**
	 * Zneplatnění
	 */
	public BulkInvalidationCOMMAND() {
		return this.executeCommand('BulkInvalidationCOMMAND')
	}

	/**
	 * Zplatnění
	 */
	public BulkUnInvalidationCOMMAND() {
		return this.executeCommand('BulkUnInvalidationCOMMAND')
	}

	/**
	 * Zneplatnění/Zplatnění
	 */
	public InvalidationCOMMAND() {
		return this.executeCommand('InvalidationCOMMAND')
	}

	/**
	 * Potvrzení
	 */
	public BulkConfirmCOMMAND() {
		return this.executeCommand('BulkConfirmCOMMAND')
	}

	/**
	 * Odpotvrzení
	 */
	public BulkUnconfirmCOMMAND() {
		return this.executeCommand('BulkUnconfirmCOMMAND')
	}

	/**
	 * Změna data potvrzení
	 */
	public BulkChangeConfirmationDateCOMMAND(parameters: TBulkChangeConfirmationDateCOMMANDParams) {
		return this.executeCommand('BulkChangeConfirmationDateCOMMAND', parameters)
	}

	/**
	 * Storno
	 */
	public BulkCancelCOMMAND() {
		return this.executeCommand('BulkCancelCOMMAND')
	}

	/**
	 * Odstorno
	 */
	public BulkUnCancelCOMMAND() {
		return this.executeCommand('BulkUnCancelCOMMAND')
	}

	/**
	 * Potvrzení
	 */
	public ConfirmCOMMAND() {
		return this.executeCommand('ConfirmCOMMAND')
	}

	/**
	 * Odpotvrzení
	 */
	public UnconfirmCOMMAND() {
		return this.executeCommand('UnconfirmCOMMAND')
	}

	/**
	 * Změna data potvrzení
	 */
	public ChangeConfirmationDateCOMMAND(parameters: TChangeConfirmationDateCOMMANDParams) {
		return this.executeCommand('ChangeConfirmationDateCOMMAND', parameters)
	}

	/**
	 * Storno/Odstorno
	 */
	public CancelCOMMAND() {
		return this.executeCommand('CancelCOMMAND')
	}

	/**
	 * Zrušení stavu
	 */
	public ClearRecordStatusCOMMAND() {
		return this.executeCommand('ClearRecordStatusCOMMAND')
	}

	/**
	 * Změna stavu
	 */
	public ChangeRecordStatusCOMMAND() {
		return this.executeCommand('ChangeRecordStatusCOMMAND')
	}

	/**
	 * 
	 */
	public ChangeRecordStatusSWSCOMMAND(parameters: TChangeRecordStatusSWSCOMMANDParams) {
		return this.executeCommand('ChangeRecordStatusSWSCOMMAND', parameters)
	}

	/**
	 * Zrušení stavu
	 */
	public BulkClearRecordStatusCOMMAND() {
		return this.executeCommand('BulkClearRecordStatusCOMMAND')
	}

	/**
	 * Změna stavu
	 */
	public BulkChangeRecordStatusCOMMAND() {
		return this.executeCommand('BulkChangeRecordStatusCOMMAND')
	}

	/**
	 * Změna atributů
	 */
	public BulkChangeAttributesCOMMAND(parameters: TBulkChangeAttributesCOMMANDParams) {
		return this.executeCommand('BulkChangeAttributesCOMMAND', parameters)
	}

	/**
	 * Aktualizace sady atributů
	 */
	public BulkActualizeAttributesCOMMAND(parameters: TBulkActualizeAttributesCOMMANDParams) {
		return this.executeCommand('BulkActualizeAttributesCOMMAND', parameters)
	}

	/**
	 * Aktualizace sady atributů
	 */
	public ActualizeAttributesCOMMAND() {
		return this.executeCommand('ActualizeAttributesCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public ChangeGroupRightCOMMAND() {
		return this.executeCommand('ChangeGroupRightCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public BulkChangeGroupRightCOMMAND() {
		return this.executeCommand('BulkChangeGroupRightCOMMAND')
	}

	/**
	 * Aktualizace počítaných polí
	 */
	public BulkUpdateCalcFieldsCOMMAND() {
		return this.executeCommand('BulkUpdateCalcFieldsCOMMAND')
	}

	/**
	 * Kontrola legislativy
	 */
	public SwitchOffLegislationValidationCOMMAND() {
		return this.executeCommand('SwitchOffLegislationValidationCOMMAND')
	}
}

export type TBulkChangeConfirmationDateCOMMANDParams = {
	/**
	 * Datum potvrzení
	 */
	Confirmation1Date?: Date
	/**
	 * Hodina potvrzení
	 */
	Confirmation1Time?: Date
	/**
	 * Datum 2. potvrzení
	 */
	Confirmation2Date?: Date
	/**
	 * Hodina 2. potvrzení
	 */
	Confirmation2Time?: Date
	/**
	 * Kontrolovat záporné stavy
	 */
	CheckNegativeState?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TChangeConfirmationDateCOMMANDParams = {
	/**
	 * Datum potvrzení
	 */
	Confirmation1Date?: Date
	/**
	 * Hodina potvrzení
	 */
	Confirmation1Time?: Date
	/**
	 * Datum 2. potvrzení
	 */
	Confirmation2Date?: Date
	/**
	 * Hodina 2. potvrzení
	 */
	Confirmation2Time?: Date
	/**
	 * Kontrolovat záporné stavy
	 */
	CheckNegativeState?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TChangeRecordStatusSWSCOMMANDParams = {
	/**
	 * StatusId
	 */
	StatusId?: number
}

export type TBulkChangeAttributesCOMMANDParams = {
	/**
	 * Smazat atributy
	 */
	DeleteAttribute?: boolean
	/**
	 * Smazat všechny volné atributy
	 */
	DeleteFreeAttribute?: boolean
	/**
	 * Nastavit hodnoty atributů
	 */
	SetValues?: boolean
	/**
	 * Přidat nepřipojené atributy
	 */
	AddFreeAttributes?: boolean
	/**
	 * FreeAttributeEnabled
	 */
	FreeAttributeEnabled?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TBulkActualizeAttributesCOMMANDParams = {
	/**
	 * Aktualizovat včetně samotné sady atributů
	 */
	ActualizeAttributeSet?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}