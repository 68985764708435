import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TCostCentreCollectionDocumentDM from './TCostCentreCollectionDocumentDM'
import TWorkplaceDM from './TWorkplaceDM'
import TK2UserLookupDM from './TK2UserLookupDM'
import ResKind from './ResKind'
import TTextDM from './TTextDM'
import PlanType from './PlanType'
import TMarketingActivityTypeDM from './TMarketingActivityTypeDM'
import TActivityDM from './TActivityDM'
import TCommentDM from './TCommentDM'
import TJobDM from './TJobDM'
import TRoutingVariantItemSimpleDM from './TRoutingVariantItemSimpleDM'
import TCapacityPlanDetailDM from './TCapacityPlanDetailDM'
import TJobCardScheduleQueueDM from './TJobCardScheduleQueueDM'
import TCapacityPlanDayDM from './TCapacityPlanDayDM'
import TSubordinateResourceReducedDM from './TSubordinateResourceReducedDM'
import TServicedDeviceDM from './TServicedDeviceDM'
import TSchedulingVariantCalendarDM from './TSchedulingVariantCalendarDM'
import TExternalDocumentItemDM from './TExternalDocumentItemDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TAttachedDocumentDM from './TAttachedDocumentDM'
import TOldNoteDM from './TOldNoteDM'
import TSubordinateResourceDM from './TSubordinateResourceDM'

/**
 * Zdroje
 */
export default class TResourceDM extends Data {
	public static className = 'TResourceDM'
	public static primaryKey = 'ResourceId'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.ResourceId!
	}

	public static isHeader = true

	/**
	 * Pomocný zdroj
	 */
	IsAuxiliaryResource?: string

	/**
	 * Nerozdělovat operace - směny
	 */
	NoSplitOperationWorkShift?: string

	/**
	 * Nekontrolovat časy na průvodce
	 */
	NotCheckJobCardTime?: string

	/**
	 * Jedna operace na zdroji
	 */
	IsSingleOperation?: string

	/**
	 * Sumační
	 */
	IsSumming?: string

	/**
	 * Počet zdrojů z průvodky
	 */
	ChangeTimeJobCard?: string

	/**
	 * Skupina
	 */
	IsGroup?: string

	/**
	 * Čas operace podle počtu zdrojů
	 */
	ChangeTime?: string

	/**
	 * Nerozdělovat operace - požadavky
	 */
	NoSplitOperations?: string

	/**
	 * InvalidRecord
	 */
	IsInvalidRecord?: string

	/**
	 * Povolit kolize
	 */
	AllowCollisions?: string

	/**
	 * Vlsstní firma
	 */
	CompanyId?: number

	/**
	 * Časové razítko
	 */
	TimeStamp?: string

	/**
	 * Využití (%)
	 */
	UtilizationRate?: number

	/**
	 * Změněno
	 */
	ChangedOn?: Date

	/**
	 * Vytvořeno
	 */
	CreatedOn?: Date

	/**
	 * Právo na záznam
	 */
	RightGroupid?: number

	/**
	 * Čís.kal.
	 */
	CalendarId?: number

	/**
	 * Název
	 */
	Name?: string

	/**
	 * Zkratka
	 */
	Abbr?: string

	/**
	 * Číslo
	 */
	ResourceId?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * S
	 */
	SummingImgCalc?: number

	/**
	 * Jaz. název
	 */
	LanguageTextCalc?: string

	/**
	 * Kapacita
	 */
	TotalCapacityCalc?: number

	/**
	 * Opožděné
	 */
	TotalDelayedCalc?: number

	/**
	 * Požadavky
	 */
	TotalRequirementsCalc?: number

	/**
	 * Vytížení (%)
	 */
	TotalUtilizationCalc?: number

	/**
	 * undefined
	 */
	DateFromCalc?: Date

	/**
	 * undefined
	 */
	DateToCalc?: Date

	/**
	 * undefined
	 */
	ShiftDelayedBitCalc?: number

	/**
	 * undefined
	 */
	ConfirmedOrCanceledImgCalc?: string

	/**
	 * undefined
	 */
	ShowInHoursCalc?: string

	/**
	 * Opožděné
	 */
	TotalDelayedUMCalc?: number

	/**
	 * Kapacita
	 */
	TotalCapacityUMCalc?: number

	/**
	 * Požadavky
	 */
	TotalRequirementsUMCalc?: number

	/**
	 * Rozvrh výroby
	 */
	ManuallyAdjustedScheduleOnlyCalc?: string

	/**
	 * Povolit kolize
	 */
	AllowCollisionsCalc?: string

	/**
	 * Včetně opožděných
	 */
	IncludingDelayedCalc?: string

	/**
	 * Rozdělit na jednotlivé zdroje
	 */
	SplitByMembersCalc?: string

	/**
	 * Požadavky
	 */
	TotalRequirementsUMParamCalc?: number

	/**
	 * Vytížení (%)
	 */
	TotalUtilizationParamCalc?: number

	/**
	 * Přetížené zdroje
	 */
	HighUtilizationCalc?: string

	/**
	 * undefined
	 */
	FilteringConditionsEnabledCalc?: string

	/**
	 * undefined
	 */
	TextHeaderMemoCalc?: string

	/**
	 * undefined
	 */
	TextFooterMemoCalc?: string

	/**
	 * Poslední komentář
	 */
	LastCommentParamCalc?: string

	/**
	 * Právo na záznam
	 */
	RightGroupIdCalc?: number

	/**
	 * Středisko
	 */
	@Type(() => TCostCentreCollectionDocumentDM)
	CostCentreId?: TCostCentreCollectionDocumentDM

	/**
	 * Pracoviště
	 */
	@Type(() => TWorkplaceDM)
	WorkplaceRID?: TWorkplaceDM

	/**
	 * Změnil
	 */
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM

	/**
	 * Vytvořil
	 */
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM

	/**
	 * Typ
	 */
	@Type(() => ResKind)
	ResourceTypeId?: ResKind

	/**
	 * Z
	 */
	@Type(() => TTextDM)
	InvalidRecordImgCalc?: TTextDM

	/**
	 * Typ plánu
	 */
	@Type(() => PlanType)
	PlannedTypeIdCalc?: PlanType

	/**
	 * D
	 */
	@Type(() => TTextDM)
	DocumentExistsCalc?: TTextDM

	/**
	 * undefined
	 */
	@Type(() => TMarketingActivityTypeDM)
	ActivityTypeRIDCalc?: TMarketingActivityTypeDM

	/**
	 * undefined
	 */
	@Type(() => TActivityDM)
	ActivityIdCalc?: TActivityDM

	/**
	 * Komentáře
	 */
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]

	/**
	 * Úkoly
	 */
	@Type(() => TJobDM)
	JobChild?: TJobDM[]

	/**
	 * Položky TP
	 */
	@Type(() => TRoutingVariantItemSimpleDM)
	RoutingVariantItemChild?: TRoutingVariantItemSimpleDM[]

	/**
	 * undefined
	 */
	@Type(() => TCapacityPlanDetailDM)
	CapacityPlanDetailChild?: TCapacityPlanDetailDM[]

	/**
	 * Fronta práce
	 */
	@Type(() => TJobCardScheduleQueueDM)
	JobCardScheduleChild?: TJobCardScheduleQueueDM[]

	/**
	 * Dny
	 */
	@Type(() => TCapacityPlanDayDM)
	CapacityPlanDayChild?: TCapacityPlanDayDM[]

	/**
	 * undefined
	 */
	@Type(() => TSubordinateResourceReducedDM)
	ResourceChild?: TSubordinateResourceReducedDM[]

	/**
	 * undefined
	 */
	@Type(() => TServicedDeviceDM)
	ServicedDeviceChild?: TServicedDeviceDM[]

	/**
	 * undefined
	 */
	@Type(() => TSchedulingVariantCalendarDM)
	SchedulingVariantCalendarChild?: TSchedulingVariantCalendarDM[]

	/**
	 * Seznam aktivit
	 */
	@Type(() => TActivityDM)
	ActivityChild?: TActivityDM[]

	/**
	 * Dokumenty
	 */
	@Type(() => TExternalDocumentItemDM)
	ExternalDocumentItemChild?: TExternalDocumentItemDM[]

	/**
	 * Text hlavičky
	 */
	@Type(() => TLanguageMemoTranslationDM)
	TextHeaderChild?: TLanguageMemoTranslationDM[]

	/**
	 * Text patičky
	 */
	@Type(() => TLanguageMemoTranslationDM)
	TextFooterChild?: TLanguageMemoTranslationDM[]

	/**
	 * Svázané doklady
	 */
	@Type(() => TAttachedDocumentDM)
	AttachedDocumentOldChild?: TAttachedDocumentDM[]

	/**
	 * Poznámky
	 */
	@Type(() => TOldNoteDM)
	DeprecatedNoteOldChild?: TOldNoteDM[]

	/**
	 * TSubordinateResourceDM
	 */
	@Type(() => TSubordinateResourceDM)
	SubordinateResourceOldChild?: TSubordinateResourceDM[]

	/**
	 * d
	 */
	public WorkplaceDateDefCOMMAND(parameters: TWorkplaceDateDefCOMMANDParams) {
		return this.executeCommand('WorkplaceDateDefCOMMAND', parameters)
	}

	/**
	 * .
	 */
	public WorkplaceShiftDelayedCOMMAND() {
		return this.executeCommand('WorkplaceShiftDelayedCOMMAND')
	}

	/**
	 * h
	 */
	public WorkplaceShowHourOnCOMMAND() {
		return this.executeCommand('WorkplaceShowHourOnCOMMAND')
	}

	/**
	 * m
	 */
	public WorkplaceShowHourOffCOMMAND() {
		return this.executeCommand('WorkplaceShowHourOffCOMMAND')
	}

	/**
	 * Hromadná změna položek
	 */
	public BulkChangeItemsJCCOMMAND(parameters: TBulkChangeItemsJCCOMMANDParams) {
		return this.executeCommand('BulkChangeItemsJCCOMMAND', parameters)
	}

	/**
	 * Zneplatnění
	 */
	public BulkInvalidationCOMMAND() {
		return this.executeCommand('BulkInvalidationCOMMAND')
	}

	/**
	 * Zplatnění
	 */
	public BulkUnInvalidationCOMMAND() {
		return this.executeCommand('BulkUnInvalidationCOMMAND')
	}

	/**
	 * Zneplatnění/Zplatnění
	 */
	public InvalidationCOMMAND() {
		return this.executeCommand('InvalidationCOMMAND')
	}

	/**
	 * Potvrzení
	 */
	public BulkConfirmCOMMAND() {
		return this.executeCommand('BulkConfirmCOMMAND')
	}

	/**
	 * Odpotvrzení
	 */
	public BulkUnconfirmCOMMAND() {
		return this.executeCommand('BulkUnconfirmCOMMAND')
	}

	/**
	 * Změna data potvrzení
	 */
	public BulkChangeConfirmationDateCOMMAND(parameters: TBulkChangeConfirmationDateCOMMANDParams) {
		return this.executeCommand('BulkChangeConfirmationDateCOMMAND', parameters)
	}

	/**
	 * Storno
	 */
	public BulkCancelCOMMAND() {
		return this.executeCommand('BulkCancelCOMMAND')
	}

	/**
	 * Odstorno
	 */
	public BulkUnCancelCOMMAND() {
		return this.executeCommand('BulkUnCancelCOMMAND')
	}

	/**
	 * Potvrzení
	 */
	public ConfirmCOMMAND() {
		return this.executeCommand('ConfirmCOMMAND')
	}

	/**
	 * Odpotvrzení
	 */
	public UnconfirmCOMMAND() {
		return this.executeCommand('UnconfirmCOMMAND')
	}

	/**
	 * Změna data potvrzení
	 */
	public ChangeConfirmationDateCOMMAND(parameters: TChangeConfirmationDateCOMMANDParams) {
		return this.executeCommand('ChangeConfirmationDateCOMMAND', parameters)
	}

	/**
	 * Storno/Odstorno
	 */
	public CancelCOMMAND() {
		return this.executeCommand('CancelCOMMAND')
	}

	/**
	 * Zrušení stavu
	 */
	public ClearRecordStatusCOMMAND() {
		return this.executeCommand('ClearRecordStatusCOMMAND')
	}

	/**
	 * Změna stavu
	 */
	public ChangeRecordStatusCOMMAND() {
		return this.executeCommand('ChangeRecordStatusCOMMAND')
	}

	/**
	 * 
	 */
	public ChangeRecordStatusSWSCOMMAND(parameters: TChangeRecordStatusSWSCOMMANDParams) {
		return this.executeCommand('ChangeRecordStatusSWSCOMMAND', parameters)
	}

	/**
	 * Zrušení stavu
	 */
	public BulkClearRecordStatusCOMMAND() {
		return this.executeCommand('BulkClearRecordStatusCOMMAND')
	}

	/**
	 * Změna stavu
	 */
	public BulkChangeRecordStatusCOMMAND() {
		return this.executeCommand('BulkChangeRecordStatusCOMMAND')
	}

	/**
	 * Změna atributů
	 */
	public BulkChangeAttributesCOMMAND(parameters: TBulkChangeAttributesCOMMANDParams) {
		return this.executeCommand('BulkChangeAttributesCOMMAND', parameters)
	}

	/**
	 * Aktualizace sady atributů
	 */
	public BulkActualizeAttributesCOMMAND(parameters: TBulkActualizeAttributesCOMMANDParams) {
		return this.executeCommand('BulkActualizeAttributesCOMMAND', parameters)
	}

	/**
	 * Aktualizace sady atributů
	 */
	public ActualizeAttributesCOMMAND() {
		return this.executeCommand('ActualizeAttributesCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public ChangeGroupRightCOMMAND() {
		return this.executeCommand('ChangeGroupRightCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public BulkChangeGroupRightCOMMAND() {
		return this.executeCommand('BulkChangeGroupRightCOMMAND')
	}

	/**
	 * Aktualizace počítaných polí
	 */
	public BulkUpdateCalcFieldsCOMMAND() {
		return this.executeCommand('BulkUpdateCalcFieldsCOMMAND')
	}

	/**
	 * Kontrola legislativy
	 */
	public SwitchOffLegislationValidationCOMMAND() {
		return this.executeCommand('SwitchOffLegislationValidationCOMMAND')
	}
}

export type TWorkplaceDateDefCOMMANDParams = {
	/**
	 * Datum od
	 */
	DateFrom?: Date
	/**
	 * Datum do
	 */
	DateTo?: Date
	/**
	 * Typ periody
	 */
	TypeOfPeriod?: number
	/**
	 * Počet period
	 */
	NumberOfPeriod?: number
	/**
	 * Nastavovat ručně
	 */
	Manually?: boolean
}

export type TBulkChangeItemsJCCOMMANDParams = {
	/**
	 * Hlavičkový modul
	 */
	HeaderType?: number
	/**
	 * Položkový modul
	 */
	ItemsType?: number
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TBulkChangeConfirmationDateCOMMANDParams = {
	/**
	 * Datum potvrzení
	 */
	Confirmation1Date?: Date
	/**
	 * Hodina potvrzení
	 */
	Confirmation1Time?: Date
	/**
	 * Datum 2. potvrzení
	 */
	Confirmation2Date?: Date
	/**
	 * Hodina 2. potvrzení
	 */
	Confirmation2Time?: Date
	/**
	 * Kontrolovat záporné stavy
	 */
	CheckNegativeState?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TChangeConfirmationDateCOMMANDParams = {
	/**
	 * Datum potvrzení
	 */
	Confirmation1Date?: Date
	/**
	 * Hodina potvrzení
	 */
	Confirmation1Time?: Date
	/**
	 * Datum 2. potvrzení
	 */
	Confirmation2Date?: Date
	/**
	 * Hodina 2. potvrzení
	 */
	Confirmation2Time?: Date
	/**
	 * Kontrolovat záporné stavy
	 */
	CheckNegativeState?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TChangeRecordStatusSWSCOMMANDParams = {
	/**
	 * StatusId
	 */
	StatusId?: number
}

export type TBulkChangeAttributesCOMMANDParams = {
	/**
	 * Smazat atributy
	 */
	DeleteAttribute?: boolean
	/**
	 * Smazat všechny volné atributy
	 */
	DeleteFreeAttribute?: boolean
	/**
	 * Nastavit hodnoty atributů
	 */
	SetValues?: boolean
	/**
	 * Přidat nepřipojené atributy
	 */
	AddFreeAttributes?: boolean
	/**
	 * FreeAttributeEnabled
	 */
	FreeAttributeEnabled?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TBulkActualizeAttributesCOMMANDParams = {
	/**
	 * Aktualizovat včetně samotné sady atributů
	 */
	ActualizeAttributeSet?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}