import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TTextDM from './TTextDM'
import TD_Zar from './TD_Zar'

/**
 * Položky dokladu z karet majetku
 */
export default class TAssetLinkDM extends Data {
	public static className = 'TAssetLinkDM'
	public static primaryKey = 'RID'
	public static systemFields = [
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = true

	/**
	 * Popis
	 */
	Description?: string

	/**
	 * Částka
	 */
	Amount?: number

	/**
	 * Datum
	 */
	DocumentDate?: Date

	/**
	 * ID položky dokladu
	 */
	DocumentNumberItemRID?: number

	/**
	 * Typ dokladu
	 */
	DocumentTypeId?: number

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Typ položky
	 */
	@Type(() => TTextDM)
	ItemTypeId?: TTextDM

	/**
	 * Unikátní číslo H
	 */
	@Type(() => TD_Zar)
	HeaderRID?: TD_Zar

	/**
	 * Změna skupiny práv
	 */
	public ChangeGroupRightCOMMAND() {
		return this.executeCommand('ChangeGroupRightCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public BulkChangeGroupRightCOMMAND() {
		return this.executeCommand('BulkChangeGroupRightCOMMAND')
	}

	/**
	 * Aktualizace počítaných polí
	 */
	public BulkUpdateCalcFieldsCOMMAND() {
		return this.executeCommand('BulkUpdateCalcFieldsCOMMAND')
	}

	/**
	 * Kontrola legislativy
	 */
	public SwitchOffLegislationValidationCOMMAND() {
		return this.executeCommand('SwitchOffLegislationValidationCOMMAND')
	}
}