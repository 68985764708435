import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TArticleDM from './TArticleDM'
import RelatedGoodsType from './RelatedGoodsType'

/**
 * Související zboží
 */
export default class TEshopRelatedArticleDM extends Data {
	public static className = 'TEshopRelatedArticleDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = false

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * Order
	 */
	SequenceNumber?: number

	/**
	 * Jednotka
	 */
	MeasureUnitId?: number

	/**
	 * Množství
	 */
	Quantity?: number

	/**
	 * Nadř. zboží
	 */
	MasterArticleId?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Zboží
	 */
	@Type(() => TArticleDM)
	ArticleId?: TArticleDM

	/**
	 * Typ vztahu
	 */
	@Type(() => RelatedGoodsType)
	RelationTypeId?: RelatedGoodsType
}