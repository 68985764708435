import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TJobCardDM from './TJobCardDM'
import TArticleDM from './TArticleDM'
import TTradingPartnerDM from './TTradingPartnerDM'
import TOperationDM from './TOperationDM'
import TTextDM from './TTextDM'

/**
 * Výrobní plán
 */
export default class TProductionOrderPlanDM extends Data {
	public static className = 'TProductionOrderPlanDM'
	public static primaryKey = 'RowNumber'
	public static systemFields = [
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RowNumber!
	}

	public static isHeader = false

	/**
	 * Číslo řádku
	 */
	RecordID?: number

	/**
	 * Číslo řádku
	 */
	RowNumber?: number

	/**
	 * Ukončeno
	 */
	IsFinished?: string

	/**
	 * Mezioper. čas
	 */
	TransitTime?: number

	/**
	 * Výrobní čas
	 */
	RunTime?: number

	/**
	 * Přípravný čas
	 */
	SetupTime?: number

	/**
	 * Skut.hodn.
	 */
	ActualValue?: number

	/**
	 * Plán.hodn.
	 */
	PlanValue?: number

	/**
	 * Příznaky operace
	 */
	OperationCompletion?: string

	/**
	 * Typ průvodky
	 */
	JobCardTypeId?: number

	/**
	 * Text operace pruvodky
	 */
	OperationText?: string

	/**
	 * Částka - zmetky
	 */
	ScrapCost?: number

	/**
	 * Množ. zmetků
	 */
	ScrapQuantity?: number

	/**
	 * MJ
	 */
	MeasureUnit?: string

	/**
	 * Skrytý
	 */
	Hidden?: number

	/**
	 * Dat. potv. op.
	 */
	OperationConfirmationDate?: Date

	/**
	 * Skut. termín do
	 */
	ActualDateTo?: Date

	/**
	 * Skut. termín od
	 */
	ActualDateFrom?: Date

	/**
	 * Limit. termín do
	 */
	LimitDate2?: Date

	/**
	 * Limit. termín od
	 */
	LimitDate1?: Date

	/**
	 * Plán. termín do
	 */
	PlannedDate2?: Date

	/**
	 * Plán. termín od
	 */
	PlannedDate1?: Date

	/**
	 * Čas sk.
	 */
	ActualDuration?: number

	/**
	 * Čas pl.
	 */
	PlannedTime?: number

	/**
	 * Nákl. sk.
	 */
	ActualAmount?: number

	/**
	 * Nákl. pl.
	 */
	PlannedCostPrice?: number

	/**
	 * Množ. sk.
	 */
	ActualQuantity?: number

	/**
	 * Množ. pl.
	 */
	PlannedQuantity?: number

	/**
	 * Popis operace
	 */
	OperationName?: string

	/**
	 * Kód
	 */
	OperationCode?: string

	/**
	 * Pořadí
	 */
	OperationSequenceNumber?: number

	/**
	 * Číslo op. prův.
	 */
	JobCardOperationRID?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * SQLSequenceOrderCalc
	 */
	SQLSequenceOrderCalc?: number

	/**
	 * Průvodka
	 */
	JobCardCalc?: string

	/**
	 * Množ. %
	 */
	QuantityPctCalc?: number

	/**
	 * Nákl. %
	 */
	CostPctCalc?: number

	/**
	 * Čas (%)
	 */
	SpentTimePercentageCalc?: number

	/**
	 * Operace
	 */
	OperationDescriptionCalc?: string

	/**
	 * Množ. pl.
	 */
	PlannedAllOperationQuantityCalc?: number

	/**
	 * Množ. sk.
	 */
	ActualQuantityCalc?: number

	/**
	 * Nákl. pl.
	 */
	PlannedUnitIncidentalCostCalc?: number

	/**
	 * Nákl. sk.
	 */
	ActualCostPriceCalc?: number

	/**
	 * Čas pl.
	 */
	PlannedTimeCalc?: number

	/**
	 * Čas sk.
	 */
	ActualDurationCalc?: number

	/**
	 * Množ. zmetků
	 */
	FaultyQuantityCalc?: number

	/**
	 * Částka - zmetky
	 */
	FaultyCostAmountCalc?: number

	/**
	 * Plnění (%)
	 */
	PercentageCompleteCalc?: number

	/**
	 * Plánovaný koeficient
	 */
	PlannedCoefficientCalc?: number

	/**
	 * Reálný koeficient
	 */
	ActualCoefficientCalc?: number

	/**
	 * Průvodka
	 */
	@Type(() => TJobCardDM)
	JobCardRID?: TJobCardDM

	/**
	 * Výrobek
	 */
	@Type(() => TArticleDM)
	ProductId?: TArticleDM

	/**
	 * Kooperant
	 */
	@Type(() => TTradingPartnerDM)
	CooperantId?: TTradingPartnerDM

	/**
	 * Operace
	 */
	@Type(() => TOperationDM)
	OperationId?: TOperationDM

	/**
	 * P.
	 */
	@Type(() => TTextDM)
	ConfirmedOrCanceledIdCalc?: TTextDM
}