import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TTextDM from './TTextDM'

/**
 * Proměnné procesu
 */
export default class TWorkflowProcessVariableDM extends Data {
	public static className = 'TWorkflowProcessVariableDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = false

	/**
	 * Zobrazit CheckBox
	 */
	IsControlAsCheckBox?: string

	/**
	 * Počítaná
	 */
	IsValueCalculated?: string

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * Param4
	 */
	BindParameter4?: number

	/**
	 * Param3
	 */
	BindParameter3?: number

	/**
	 * Param2
	 */
	BindParameter2?: number

	/**
	 * Param1
	 */
	BindParameter1?: number

	/**
	 * Vazba
	 */
	BindTypeId?: number

	/**
	 * Proces
	 */
	ProcessId?: number

	/**
	 * Záhlaví
	 */
	Label?: string

	/**
	 * Maska
	 */
	Picture?: string

	/**
	 * Decimál
	 */
	Decimal?: number

	/**
	 * Velikost
	 */
	Size?: number

	/**
	 * Datový typ
	 */
	DataTypeId?: number

	/**
	 * Název
	 */
	Name?: string

	/**
	 * Identifikátor
	 */
	ProgrammerName?: string

	/**
	 * Hodnota
	 */
	Value?: string

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Number
	 */
	ValueNumberCalc?: number

	/**
	 * Currency
	 */
	ValueCurrencyCalc?: number

	/**
	 * Date
	 */
	ValueDateCalc?: Date

	/**
	 * String
	 */
	ValueStringCalc?: string

	/**
	 * Long
	 */
	ValueLongCalc?: number

	/**
	 * Time
	 */
	ValueTimeCalc?: Date

	/**
	 * Hodnota
	 */
	ValueCalc?: string

	/**
	 * Boolean
	 */
	@Type(() => TTextDM)
	ValueBoolCalc?: TTextDM
}