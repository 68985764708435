import { useEffect, useRef, useState } from "react"
import TSalesOrderDM from "react-k2-api/Data/TSalesOrderDM"
import TDeliveryNoteDM from "react-k2-api/Data/TDeliveryNoteDM"
import TReleaseNoteDM from "react-k2-api/Data/TReleaseNoteDM"
import TSalesItemDM from 'react-k2-api/Data/TSalesItemDM'
import TArticleDM from 'react-k2-api/Data/TArticleDM'
import DocStatus from 'react-k2-api/Data/DocStatus'
import TBookDM from 'react-k2-api/Data/TBookDM'
import TTradingPartnerDM from 'react-k2-api/Data/TTradingPartnerDM'
import FormOfOrder from 'react-k2-api/Data/FormOfOrder'
import TWarehouseDM from 'react-k2-api/Data/TWarehouseDM'
import { instanceToInstance } from "class-transformer"
import { Button, Container, Spinner, Table } from "react-bootstrap"
import { ItemList } from "components/ItemList"
import { BarcodeInput, HandleBarcodeInputData, HandleBarcodeInputDataList } from "components/BarcodeInput"
import TMeasureUnitDM from "react-k2-api/Data/TMeasureUnitDM"
import { useErrorHandler } from "react-error-boundary"
import TSpecialPrintBuffer from "react-k2-api/Data/TSpecialPrintBuffer"
import TSpecialScanAndGoSettings from "react-k2-api/Data/TSpecialScanAndGoSettings"
import TSpecialPrinterType from "react-k2-api/Data/TSpecialPrinterType"
import { useGlobalModalContext, MODAL_TYPES } from "components/GlobalModal/GlobalModal"
import { HandleItemDelete, HandleItemSave } from "components/Item"

export type OrderProps = {
    statusId: {
        open: number,
        free: number,
        pending: number
    },
    orderForm: string,
    tradingPartnerId: number,
    bookId: number,
    plannedWarehouseId: number,
    settings: TSpecialScanAndGoSettings
}

const fields = [
    'DocumentIdentificationCalc',
    'TradingPartnerId.Name',
    'TradingPartnerId.AddressId.TownPartId.Name',
    'TradingPartnerId.EX_prodejna_dl_lze',
    'StatusId.Id',
    'AmountNetC',
    'AmountGrossCCalc',
    'Currency.Abbr',
    'SalesItemChild.ItemNumber',
    'SalesItemChild.ArticleId.LanguageTextCalc',
    'SalesItemChild.Quantity',
    'SalesItemChild.MeasureUnitId.Abbr.Abbr',
    'SalesItemChild.PlannedTotalPriceNetCCalc',
    'SalesItemChild.PlannedTotalPriceGrossCCalc',
    'SalesItemChild.HeaderCurrencyCalc.Abbr',
    'SalesItemChild.DeliveryNoteRID.RID',
    'SalesItemChild.ReleaseNoteRID.RID',
    'SalesItemChild.ReleaseNoteStateIdCalc.Id',
    'SalesItemChild.DeliveryConfirmationStateIdCalc.Id',
    'SalesItemChild.InvoiceOutStateIdCalc.Id',
    'SalesItemChild.ReservingCardStateIdCalc.Id',
    'SalesItemChild.OrderConfirmationStateIdCalc.Id',
    'CanceledRecordBitCalc.Id'
]

export const Order = (props: OrderProps) => {
    const [order, setOrder] = useState(new TSalesOrderDM())
    const [loading, setLoading] = useState(false)
    const { showModal } = useGlobalModalContext()
    const globalModalStore = useGlobalModalContext().store

    const barcodeInputRef = useRef<HTMLInputElement>(null)

    const handleError = useErrorHandler()

    useEffect(() => {
        const orderRID = localStorage.getItem('orderRID')
        if (orderRID) {
            TSalesOrderDM.get(orderRID, fields).then((order: TSalesOrderDM) => {
                if (order.CanceledRecordBitCalc?.Id === 0 && (order.StatusId?.Id === props.statusId.free || order.StatusId?.Id === props.statusId.open)) {
                    setOrder(order)
                } else {
                    localStorage.removeItem('orderRID')
                }
            }).catch(() => {
                localStorage.removeItem('orderRID')
            })
        }

        barcodeInputRef.current?.focus()
    }, [props])

    const handleItemDelete: HandleItemDelete = (item) => {
        showModal(MODAL_TYPES.CONFIRMATION_MODAL, {
            title: "Opravdu chcete smazat položku?",
            handleConfirmation: () => {
                const newOrder = instanceToInstance(order)
                const items = newOrder.SalesItemChild?.filter(x => x.RID === item.RID)
                if (items?.length === 1) {
                    items[0]._isDeleted = true

                    if (newOrder.SalesItemChild?.length === 1) {
                        handleCancelClick()
                    } else {
                        newOrder.put(fields).then(setOrder)
                    }
                }
            },
            handleClose: () => barcodeInputRef.current?.focus()
        })
    }

    const handleCancelClick = () => {
        showModal(MODAL_TYPES.CONFIRMATION_MODAL, {
            title: "Opravdu chcete zrušit zakázku?",
            handleConfirmation: () => {
                const newOrder = instanceToInstance(order)
                newOrder.StatusId = new DocStatus()
                newOrder.StatusId.Id = props.statusId.free
                newOrder.SalesItemChild?.forEach((item: TSalesItemDM) => item._isDeleted = true)
                newOrder.put(fields).then(() => {
                    setOrder(new TSalesOrderDM())
                    localStorage.removeItem('orderRID')
                }).catch(handleError)
            },
            handleClose: () => barcodeInputRef.current?.focus()
        })
    }

    const handleFinishClick = () => {
        showModal(MODAL_TYPES.CONFIRMATION_MODAL, {
            title: "Opravdu chcete dokončit zakázku?",
            handleConfirmation: () => {
                const finishOrder = (order: TSalesOrderDM) => {
                    const newOrder = instanceToInstance(order)
                    newOrder.StatusId = new DocStatus()
                    newOrder.StatusId.Id = props.statusId.pending
                    newOrder.put(fields).then(() => {
                        setOrder(new TSalesOrderDM())
                        setLoading(false)
                        localStorage.removeItem('orderRID')
                    }).catch(handleError)
                }

                if (!order.TradingPartnerId?.EX_prodejna_dl_lze) {
                    finishOrder(order)
                    return
                }

                showModal(MODAL_TYPES.CONFIRMATION_MODAL, {
                    title: "Vytvořit výdejku a dodací list?",
                    handleClose: () => {
                        finishOrder(order)
                        barcodeInputRef.current?.focus()
                    },
                    handleConfirmation: () => {
                        setLoading(true)
                        order.AddItemsToSubDocWSCOMMAND({
                            ReleaseNote: true,
                            DeliveryNote: true,
                            ItemRIDs: order.SalesItemChild?.map(item => item.RID).join(';')
                        }).then(result => {
                            if (!result.CommandCompleted) throw new Error("Nepodařilo se vytvořit podřízené doklady.")

                            TSalesOrderDM.get(order.RID!, fields).then((order: TSalesOrderDM) => {
                                const items = order.SalesItemChild!.filter(item => item.ReleaseNoteRID && item.ReleaseNoteRID !== 0 && item.ReleaseNoteStateIdCalc?.Id !== 2)
                                if (!items || items.length === 0) throw new Error("Nepodařilo se vytvořit výdejku.")

                                TReleaseNoteDM.get(items[0].ReleaseNoteRID!).then((releaseNote: TReleaseNoteDM) => {
                                    releaseNote.ConfirmCOMMAND().then(result => {
                                        if (!result.CommandCompleted) throw new Error("Nepodařilo se potvrdit výdejku.")
                                        const items = order.SalesItemChild!.filter(item => item.DeliveryNoteRID && item.DeliveryNoteRID !== 0 && item.DeliveryConfirmationStateIdCalc?.Id !== 2)
                                        if (!items || items.length === 0) throw new Error("Nepodařilo se vytvořit dodací list.")
                                        TDeliveryNoteDM.get(items![0].DeliveryNoteRID!).then((deliveryNote: TDeliveryNoteDM) => {
                                            deliveryNote.ConfirmCOMMAND().then(result => {
                                                if (!result.CommandCompleted) throw new Error("Nepodařilo se potvrdit dodací list.")

                                                if (props.settings.PrinterGroupRID?.RID) {
                                                    TSalesOrderDM.get(order.RID!, fields).then(order => {
                                                        const printBuffer = new TSpecialPrintBuffer()
                                                        printBuffer.Formation = 'SEST005DLVSPMAT_AKTUAL.AM'
                                                        printBuffer.IsSpecial = 'true'
                                                        printBuffer.PrinterGroup = instanceToInstance(props.settings.PrinterGroupRID)
                                                        printBuffer.PrinterType = new TSpecialPrinterType()
                                                        printBuffer.PrinterType.Id = 2
                                                        printBuffer.MasterDataMNo = 93
                                                        printBuffer.MasterID = order.SalesItemChild![0].DeliveryConfirmationRID
                                                        printBuffer.post().then(() => finishOrder(order))
                                                    })
                                                } else {
                                                    finishOrder(order)
                                                }
                                            }).catch(handleError)
                                        }).catch(handleError)
                                    }).catch(handleError)
                                }).catch(handleError)
                            }).catch(handleError)
                        }).catch(handleError)
                    }
                })
            }
        })
    }

    const getOrder = async (): Promise<TSalesOrderDM> => {
        if (order.RID) return order

        return TSalesOrderDM.getList(fields, [
            `BookId.Id;EQ;${props.bookId}`,
            `StatusId.Id;EQ;${props.statusId.free}`,
            `OrderForm.Abbr;EQ;${props.orderForm}`,
            `CanceledRecordBitCalc.Id;EQ;0`
        ]).then(([orderList]) => {
            if (orderList.length > 0) {
                const order = orderList[0] as TSalesOrderDM
                order.StatusId = new DocStatus()
                order.StatusId.Id = props.statusId.open
                return order.put(fields).catch(handleError)
            } else {
                const newOrder = instanceToInstance(order)
                newOrder.BookId = new TBookDM()
                newOrder.BookId.Id = props.bookId
                if (!newOrder.TradingPartnerId?.Id) {
                    newOrder.TradingPartnerId = new TTradingPartnerDM()
                    newOrder.TradingPartnerId.Id = props.tradingPartnerId
                }

                newOrder.Description = 'Samoobsluha'
                newOrder.StatusId = new DocStatus()
                newOrder.StatusId.Id = props.statusId.open
                newOrder.OrderForm = new FormOfOrder()
                newOrder.OrderForm.Abbr = props.orderForm
                return newOrder.post(fields).then(order => {
                    localStorage.setItem('orderRID', order.RID!.toString())
                    return order
                }).catch(handleError)
            }
        })
    }

    const handleItemSave: HandleItemSave = (item) => {
        getOrder().then(order => {
            const newOrder = instanceToInstance(order)
            newOrder.SalesItemChild = newOrder.SalesItemChild!.filter(x => x.RID !== item.RID)
            newOrder.SalesItemChild!.push(item)
            newOrder.put(fields).then(setOrder).catch(handleError)
            barcodeInputRef.current?.focus()
        })
    }

    const handleBarcodeInputDataList: HandleBarcodeInputDataList = (c, dataList) => {
        switch (c) {
            case TArticleDM:
            case TSalesOrderDM:
                handleBarcodeInputData(c, dataList[0])
                break
            case TTradingPartnerDM:
                showModal(MODAL_TYPES.LOOKUP_MODAL, {
                    title: "Vyberte pobočku",
                    dataList,
                    handleDataRender: (data, key) => {
                        const tradingPartner = data as TTradingPartnerDM
                        return <div key={key}>{tradingPartner.Name}, {tradingPartner.AddressId?.TownPartId?.Name}</div>
                    },
                    handleConfirmation(dataList) {
                        handleBarcodeInputData(c, dataList[0])
                    },
                })
                break
        }
    }

    const handleBarcodeInputData: HandleBarcodeInputData = (c, data) => {
        barcodeInputRef.current?.focus()

        if (!data) {
            switch (c) {
                case TArticleDM:
                    showModal(MODAL_TYPES.INFORMATION_MODAL, { title: "Zboží nenalezeno.", handleClose: () => barcodeInputRef.current?.focus() })
                    return
                case TSalesOrderDM:
                    showModal(MODAL_TYPES.INFORMATION_MODAL, { title: "Zakázka nenalezena.", handleClose: () => barcodeInputRef.current?.focus() })
                    return
                case TTradingPartnerDM:
                    showModal(MODAL_TYPES.INFORMATION_MODAL, { title: "Zákazník nenalezen.", handleClose: () => barcodeInputRef.current?.focus() })
                    return
                default:
                    showModal(MODAL_TYPES.INFORMATION_MODAL, { title: "Záznam nenalezen.", handleClose: () => barcodeInputRef.current?.focus() })
                    return
            }
        }

        switch (c) {
            case TArticleDM: {
                const article = data as TArticleDM

                const items = order.SalesItemChild?.filter(
                    item => item.ArticleId?.Id === article.Id &&
                        item.ReleaseNoteStateIdCalc?.Id !== 2 &&
                        item.DeliveryConfirmationStateIdCalc?.Id !== 2 &&
                        item.InvoiceOutStateIdCalc?.Id !== 2 &&
                        item.ReservingCardStateIdCalc?.Id !== 2 &&
                        item.OrderConfirmationStateIdCalc?.Id !== 2
                )

                const newItem = items && items.length > 0 ? instanceToInstance(items[0]) : new TSalesItemDM()
                if (!newItem.RID) {
                    newItem.PlannedWarehouseId = new TWarehouseDM()
                    newItem.PlannedWarehouseId.Id = props.plannedWarehouseId
                    newItem.Quantity = 0
                }

                newItem.ArticleId = new TArticleDM()
                newItem.ArticleId.Id = article.Id
                const altUnits = article.MeasureUnitChild?.filter(measureUnit => measureUnit.Id === 0)
                if (altUnits?.length === 1) {
                    newItem.MeasureUnitId = new TMeasureUnitDM()
                    newItem.MeasureUnitId.Abbr = instanceToInstance(altUnits[0].Abbr)
                }

                showModal(MODAL_TYPES.NUMERIC_KEYBOARD_MODAL, {
                    handleClose: (value) => {
                        if (!value) {
                            barcodeInputRef.current?.focus()
                            return
                        }

                        newItem.Quantity = parseFloat(value.replace(",", "."))
                        if (newItem.MeasureUnitId?.Abbr?.Abbr === "sks") {
                            newItem.Quantity /= 100
                        }

                        handleItemSave(newItem)
                    },
                    defaultValue: newItem.MeasureUnitId?.Abbr?.Abbr === "sks" ? (newItem.Quantity! * 100).toString().replace(".", ",") : newItem.Quantity?.toString().replace(".", ","),
                    inputGroupText: newItem.MeasureUnitId?.Abbr?.Abbr?.replace("sks", "ks")
                })
                break
            }
            case TSalesOrderDM: {
                const newOrder = data as TSalesOrderDM
                newOrder.StatusId = new DocStatus()
                newOrder.StatusId.Id = props.statusId.open
                newOrder.put(fields).then((order) => {
                    setOrder(order)
                    localStorage.setItem('orderRID', order.RID!.toString())
                }).catch(handleError)
                break
            }
            case TTradingPartnerDM: {
                const newOrder = instanceToInstance(order)
                newOrder.TradingPartnerId = instanceToInstance(data as TTradingPartnerDM)
                if (newOrder.RID) {
                    newOrder.put(fields).then(setOrder).catch(handleError)
                } else {
                    setOrder(newOrder)
                }

                break
            }
        }
    }

    return (
        <>
            {loading ?
                <Container className="mt-3 text-center">
                    <Spinner variant="primary" animation={"border"} />
                </Container> :
                <Table className="order">
                    <thead>
                        <tr>
                            <th colSpan={3}>{order.DocumentIdentificationCalc} {order.TradingPartnerId ? `${order.TradingPartnerId.Name}` : ''}{order.TradingPartnerId?.AddressId ? `, ${order.TradingPartnerId.AddressId?.TownPartId?.Name}` : ''}</th>
                        </tr>
                    </thead>
                    <ItemList
                        items={order.SalesItemChild!}
                        handleSave={handleItemSave}
                        handleDelete={handleItemDelete}
                    />
                    <tfoot>
                        {order.SalesItemChild ?
                            (<tr className="fw-bold">
                                <td>
                                    Celkem
                                </td>
                                <td>
                                    {order.AmountNetC?.toFixed(2).replace(".", ",")} &nbsp;{order.Currency?.Abbr} bez DPH<br />
                                    {order.AmountGrossCCalc?.toFixed(2).replace(".", ",")}&nbsp;{order.Currency?.Abbr} s DPH
                                </td>
                                <td>
                                </td>
                            </tr>) :
                            ''}
                        <tr>
                            <td colSpan={3}>
                                <BarcodeInput
                                    inputRef={barcodeInputRef}
                                    handleData={handleBarcodeInputData}
                                    handleDataList={handleBarcodeInputDataList}
                                    handleBlur={(e) => {
                                        if (globalModalStore.modalType) return
                                        setTimeout(() => {
                                            e.target.focus()
                                        }, 10)
                                    }}
                                    fields={{
                                        [TArticleDM.className]: [
                                            'MeasureUnitChild.Id',
                                            'MeasureUnitChild.Abbr.Abbr'
                                        ],
                                        [TSalesOrderDM.className]: fields,
                                        [TTradingPartnerDM.className]: [
                                            'Name',
                                            'AddressId.TownPartId.Name'
                                        ]
                                    }}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={3}>
                                <div className="d-flex justify-content-between ml-5">
                                    <Button variant="danger" onClick={handleCancelClick} disabled={!order.RID}>Zrušit</Button>
                                    <a className="btn btn-primary" href={process.env.REACT_APP_HOMEPAGE_URL}>Rozcestník</a>
                                    <Button variant="success" onClick={handleFinishClick} disabled={!order.RID}>Dokončit</Button>
                                </div>
                            </td>
                        </tr>
                    </tfoot>
                </Table>
            }
        </>
    )
}