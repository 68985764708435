import Data from 'react-k2-api/Api/Data'
import { Type } from 'class-transformer'
import TJobCardDM from './TJobCardDM'
import TArticleKindDM from './TArticleKindDM'
import TArticleDM from './TArticleDM'

/**
 * Výrobní náklady
 */
export default class TProductionOrderCostDM extends Data {
	public static className = 'TProductionOrderCostDM'
	public static systemFields = [
		'RecordID',
	]

	public static isHeader = false

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * undefined
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * Číslo řádku
	 */
	RowNumber?: number

	/**
	 * Mezioperační čas sk.
	 */
	ActualTeardownTime?: number

	/**
	 * Výrobní čas sk.
	 */
	ActualRunTime?: number

	/**
	 * Přípravný čas sk.
	 */
	ActualSetupTime?: number

	/**
	 * Mezioperační čas pl.
	 */
	TransitTime?: number

	/**
	 * Výrobní čas pl.
	 */
	RunTime?: number

	/**
	 * Přípravný čas pl.
	 */
	SetupTime?: number

	/**
	 * Typ času operace
	 */
	OperationTimeType?: number

	/**
	 * MJ
	 */
	MeasureUnit?: string

	/**
	 * Nákl. sk.
	 */
	ActualAmount?: number

	/**
	 * Nákl. pl.
	 */
	PlannedCostPrice?: number

	/**
	 * Množ. sk.
	 */
	ActualQuantity?: number

	/**
	 * Množ. pl.
	 */
	PlannedQuantity?: number

	/**
	 * Druh
	 */
	KindAbbr?: string

	/**
	 * Název zboží
	 */
	ArticleName?: string

	/**
	 * Číslo op. prův.
	 */
	JobCardOperationRID?: number

	/**
	 * Číslo pol. prův.
	 */
	SequenceNumber?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Množ. %
	 */
	QuantityPctCalc?: number

	/**
	 * Nákl. %
	 */
	CostPctCalc?: number

	/**
	 * Číslo prův.
	 */
	@Type(() => TJobCardDM)
	JobCardRID?: TJobCardDM

	/**
	 * Druh
	 */
	@Type(() => TArticleKindDM)
	ArticleCategoryId?: TArticleKindDM

	/**
	 * Číslo zboží
	 */
	@Type(() => TArticleDM)
	ArticleId?: TArticleDM
}