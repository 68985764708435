import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import ServiceOperationType from './ServiceOperationType'
import TMaintenanceFrequencyDefinitionTemplateDM from './TMaintenanceFrequencyDefinitionTemplateDM'
import TOfficerDocumentDM from './TOfficerDocumentDM'
import TTextDM from './TTextDM'
import TBookDM from './TBookDM'
import TK2UserLookupDM from './TK2UserLookupDM'
import TScheduledServiceOperationDM from './TScheduledServiceOperationDM'
import TServiceOperationOfficerDM from './TServiceOperationOfficerDM'

/**
 * Definice plánovaných servisních úkonů - časový interval
 */
export default class TMaintenanceFrequencyDefinitionDM extends Data {
	public static className = 'TMaintenanceFrequencyDefinitionDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = true

	/**
	 * Generuj další úkon
	 */
	GenerateNextOperation?: string

	/**
	 * Zařazení pro parametry
	 */
	ServiceParameterGroupId?: number

	/**
	 * Předstih
	 */
	GenerationHorizon?: number

	/**
	 * Jednotka pro předstih
	 */
	GenerationHorizonUnit?: number

	/**
	 * Zadání úkonu
	 */
	OperationInstructions?: string

	/**
	 * Plánovaná doba úkonu
	 */
	PlannedOperationDuration?: number

	/**
	 * Popis definice
	 */
	Description?: string

	/**
	 * Počet opakování
	 */
	RepetitionCount?: number

	/**
	 * Datum a čas zahájení
	 */
	StartDate?: Date

	/**
	 * Časový interval
	 */
	Frequency?: number

	/**
	 * Servisované zařízení
	 */
	HeaderRID?: number

	/**
	 * Změněno
	 */
	ChangedOn?: Date

	/**
	 * Vytvořeno
	 */
	CreatedOn?: Date

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Datum poslední vytvořené servisní operace
	 */
	LastGeneratedOperationDateCalc?: Date

	/**
	 * ErrorLogType servisního úkonu
	 */
	@Type(() => ServiceOperationType)
	ServiceTypeId?: ServiceOperationType

	/**
	 * Šablona definice úkonů
	 */
	@Type(() => TMaintenanceFrequencyDefinitionTemplateDM)
	DefinitionTemplateId?: TMaintenanceFrequencyDefinitionTemplateDM

	/**
	 * Plánovaný pracovník
	 */
	@Type(() => TOfficerDocumentDM)
	PlannedServicemanId?: TOfficerDocumentDM

	/**
	 * Časová jednotka
	 */
	@Type(() => TTextDM)
	TimeUnitId?: TTextDM

	/**
	 * Kniha
	 */
	@Type(() => TBookDM)
	BookId?: TBookDM

	/**
	 * Jednotka časového intervalu
	 */
	@Type(() => TTextDM)
	FrequencyUnitId?: TTextDM

	/**
	 * Změnil
	 */
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM

	/**
	 * Vytvořil
	 */
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM

	/**
	 * undefined
	 */
	@Type(() => TScheduledServiceOperationDM)
	ScheduledServiceOperationChild?: TScheduledServiceOperationDM[]

	/**
	 * undefined
	 */
	@Type(() => TServiceOperationOfficerDM)
	DefinitionOfficerChild?: TServiceOperationOfficerDM[]

	/**
	 * Změna skupiny práv
	 */
	public ChangeGroupRightCOMMAND() {
		return this.executeCommand('ChangeGroupRightCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public BulkChangeGroupRightCOMMAND() {
		return this.executeCommand('BulkChangeGroupRightCOMMAND')
	}

	/**
	 * Aktualizace počítaných polí
	 */
	public BulkUpdateCalcFieldsCOMMAND() {
		return this.executeCommand('BulkUpdateCalcFieldsCOMMAND')
	}

	/**
	 * Kontrola legislativy
	 */
	public SwitchOffLegislationValidationCOMMAND() {
		return this.executeCommand('SwitchOffLegislationValidationCOMMAND')
	}
}