import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TK2UserLookupDM from './TK2UserLookupDM'
import TTextDM from './TTextDM'
import TLanguageTextTranslationDM from './TLanguageTextTranslationDM'

/**
 * Obecné číselníky
 */
export default class Kategorie1 extends Data {
	public static className = 'Kategorie1'
	public static primaryKey = 'Id'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.Id!
	}

	public static isHeader = true

	/**
	 * Časové razítko
	 */
	TimeStamp?: string

	/**
	 * Dlouhý popis
	 */
	Description?: string

	/**
	 * Právo na záznam
	 */
	RightGroupId?: number

	/**
	 * Číslo
	 */
	Id?: number

	/**
	 * Popis
	 */
	ShortDescription?: string

	/**
	 * Zkratka
	 */
	Abbr?: string

	/**
	 * Typ
	 */
	TypeId?: number

	/**
	 * Nevkládat do popisu
	 */
	EX_Ne_Popis?: string

	/**
	 * Popis matice
	 */
	EX_Txt_Matice?: string

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * B
	 */
	BlockedRecordImgCalc?: string

	/**
	 * Stav
	 */
	StatusCalc?: string

	/**
	 * Blokováno
	 */
	BlockedRecordBitCalc?: number

	/**
	 * Upozorňovat
	 */
	NotifyBitCalc?: number

	/**
	 * Vyřazení likvidací
	 */
	IsScrappedWhenDisposalCalc?: string

	/**
	 * Jazykový popis
	 */
	LanguageTextCalc?: string

	/**
	 * Právo na záznam
	 */
	RightGroupIdCalc?: number

	/**
	 * Referent logistiky
	 */
	@Type(() => TK2UserLookupDM)
	EX_L_SkZ_Referent?: TK2UserLookupDM

	/**
	 * Aktuální typ číselníku
	 */
	@Type(() => TTextDM)
	CurrentTypeRIDCalc?: TTextDM

	/**
	 * undefined
	 */
	@Type(() => TLanguageTextTranslationDM)
	LanguageShortDescriptionChild?: TLanguageTextTranslationDM[]

	/**
	 * Blokovat
	 */
	public BlockCOMMAND() {
		return this.executeCommand('BlockCOMMAND')
	}

	/**
	 * Odblokovat
	 */
	public UnblockCOMMAND() {
		return this.executeCommand('UnblockCOMMAND')
	}

	/**
	 * Výběr typu
	 */
	public TypeIdChangeCOMMAND(parameters: TTypeIdChangeCOMMANDParams) {
		return this.executeCommand('TypeIdChangeCOMMAND', parameters)
	}

	/**
	 * Změna skupiny práv
	 */
	public ChangeGroupRightCOMMAND() {
		return this.executeCommand('ChangeGroupRightCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public BulkChangeGroupRightCOMMAND() {
		return this.executeCommand('BulkChangeGroupRightCOMMAND')
	}

	/**
	 * Aktualizace počítaných polí
	 */
	public BulkUpdateCalcFieldsCOMMAND() {
		return this.executeCommand('BulkUpdateCalcFieldsCOMMAND')
	}

	/**
	 * Kontrola legislativy
	 */
	public SwitchOffLegislationValidationCOMMAND() {
		return this.executeCommand('SwitchOffLegislationValidationCOMMAND')
	}
}

export type TTypeIdChangeCOMMANDParams = {
	/**
	 * Typ
	 */
	TypeId?: number
	/**
	 * MessageText
	 */
	MessageText?: string
}