import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TExternalBatchDM from './TExternalBatchDM'
import TOfficerDocumentDM from './TOfficerDocumentDM'
import TArticleDM from './TArticleDM'
import TBatchDM from './TBatchDM'
import TRoutingVariantDM from './TRoutingVariantDM'
import TMeasureUnitDM from './TMeasureUnitDM'
import AlternativeUnit from './AlternativeUnit'

/**
 * Komponenty
 */
export default class TServicedDeviceItemDM extends Data {
	public static className = 'TServicedDeviceItemDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = false

	/**
	 * Převodka
	 */
	TransferNoteRID?: number

	/**
	 * Výdejka
	 */
	ReleaseNoteRID?: number

	/**
	 * Popis
	 */
	Description?: string

	/**
	 * Instalováno
	 */
	InstalledOn?: Date

	/**
	 * Vyřazeno
	 */
	DiscardedOn?: Date

	/**
	 * Je vyřazen
	 */
	IsDiscarded?: string

	/**
	 * Jednotka
	 */
	MeasureUnitId?: number

	/**
	 * Množství
	 */
	Quantity?: number

	/**
	 * Výrobní číslo
	 */
	ProductionNumber?: string

	/**
	 * Sériové číslo
	 */
	SerialNumber?: string

	/**
	 * Hlavička
	 */
	HeaderRID?: number

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * Pořadí
	 */
	RecordNo?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Název komponenty
	 */
	ArticleAbbrCalc?: string

	/**
	 * Jednotka
	 */
	MeasureUnitAbbrCalc?: string

	/**
	 * Externí šarže
	 */
	@Type(() => TExternalBatchDM)
	ExternalBatchId?: TExternalBatchDM

	/**
	 * Zařadil
	 */
	@Type(() => TOfficerDocumentDM)
	InstalledById?: TOfficerDocumentDM

	/**
	 * Vyřadil
	 */
	@Type(() => TOfficerDocumentDM)
	DiscardedById?: TOfficerDocumentDM

	/**
	 * Zboží
	 */
	@Type(() => TArticleDM)
	ArticleId?: TArticleDM

	/**
	 * Šarže
	 */
	@Type(() => TBatchDM)
	BatchId?: TBatchDM

	/**
	 * Varianta TP
	 */
	@Type(() => TRoutingVariantDM)
	RoutingVariantId?: TRoutingVariantDM

	/**
	 * Jednotka
	 */
	@Type(() => TMeasureUnitDM)
	ArticleMeasureUnitIdCalc?: TMeasureUnitDM

	/**
	 * Jednotka
	 */
	@Type(() => AlternativeUnit)
	GeneralMeasureUnitIdCalc?: AlternativeUnit
}