import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TParameterDM from './TParameterDM'
import TParameterGroupDM from './TParameterGroupDM'

/**
 * Parametry zařazení
 */
export default class TParameterSetItemDM extends Data {
	public static className = 'TParameterSetItemDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = false

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * Poř. sk.
	 */
	GroupSequenceNumber?: number

	/**
	 * Poř. par.
	 */
	ParameterSequenceNumber?: number

	/**
	 * Zařazení
	 */
	ParameterSetId?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Parametr
	 */
	NameCalc?: string

	/**
	 * Popis
	 */
	DescriptionCalc?: string

	/**
	 * undefined
	 */
	ValueFromBatchBitCalc?: number

	/**
	 * Š
	 */
	ValueFromBatchImgCalc?: string

	/**
	 * Proměnná
	 */
	VariableNameCalc?: string

	/**
	 * Zobrazovat hodnoty filtru
	 */
	ShowFiltValuesBitCalc?: number

	/**
	 * Pro filtrování zobrazovat rozsah hodnot
	 */
	FilterShowRangeBitCalc?: number

	/**
	 * Nižší hodnota parametru je lepší
	 */
	LowerValueBetterBitCalc?: number

	/**
	 * Vyšší hodnota parametru je lepší
	 */
	HigherValueBetterBitCalc?: number

	/**
	 * Parametr pro rozlišení varianty zboží
	 */
	ForVariationBitCalc?: number

	/**
	 * Řadit podle porovnávací hodnoty
	 */
	UseComparisonValueBitCalc?: number

	/**
	 * Nezobrazovat na internetovém obchodě
	 */
	NotShowOnEshopBitCalc?: number

	/**
	 * Parametr
	 */
	@Type(() => TParameterDM)
	ParameterId?: TParameterDM

	/**
	 * Skupina
	 */
	@Type(() => TParameterGroupDM)
	ParameterGroupId?: TParameterGroupDM
}