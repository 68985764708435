import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TOldNoteTypeDM from './TOldNoteTypeDM'

/**
 * TItemNoteDM
 */
export default class TItemNoteDM extends Data {
	public static className = 'TItemNoteDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = false

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * Právo na záznam
	 */
	RightGroupId?: number

	/**
	 * Formulář
	 */
	FormId?: number

	/**
	 * Poznámka
	 */
	Note?: string

	/**
	 * Ř.
	 */
	SequenceNumber?: number

	/**
	 * Typ
	 */
	TypeId?: string

	/**
	 * C_Pozn
	 */
	NoteRID?: number

	/**
	 * C_Dok
	 */
	DocumentRID?: number

	/**
	 * Kniha
	 */
	Book?: string

	/**
	 * Typ_Dok
	 */
	DocumentTypeId?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Poznámk&y
	 */
	NoteTextCalc?: string

	/**
	 * Typ poznámky
	 */
	NoteTypeCalc?: string

	/**
	 * Právo na záznam
	 */
	RightGroupIdCalc?: number

	/**
	 * undefined
	 */
	@Type(() => TOldNoteTypeDM)
	TypeNumberIdCalc?: TOldNoteTypeDM
}