import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TDeliveryLineDM from './TDeliveryLineDM'
import TVehicleDM from './TVehicleDM'
import AreaCode from './AreaCode'
import TDeviceDocumentDM from './TDeviceDocumentDM'
import TOfficerDocumentDM from './TOfficerDocumentDM'
import TArticleCodeDocumentDM from './TArticleCodeDocumentDM'
import TContractCodeDocumentDM from './TContractCodeDocumentDM'
import TCode6DocumentDM from './TCode6DocumentDM'
import TCode5DocumentDM from './TCode5DocumentDM'
import TCode4DocumentDM from './TCode4DocumentDM'
import TCode3DocumentDM from './TCode3DocumentDM'
import TCode2DocumentDM from './TCode2DocumentDM'
import TCode1DocumentDM from './TCode1DocumentDM'
import TCostCentreCollectionDocumentDM from './TCostCentreCollectionDocumentDM'
import TK2UserLookupDM from './TK2UserLookupDM'
import DeliveryOrderStatus from './DeliveryOrderStatus'
import TPeriodDM from './TPeriodDM'
import TBookDM from './TBookDM'
import TTextDM from './TTextDM'
import TMarketingActivityTypeDM from './TMarketingActivityTypeDM'
import TTradingPartnerDM from './TTradingPartnerDM'
import TWarehouseDM from './TWarehouseDM'
import TCommentDM from './TCommentDM'
import TDeliveryPlanItemDM from './TDeliveryPlanItemDM'
import TDeliveryMeasureUnitDM from './TDeliveryMeasureUnitDM'
import TExternalDocumentItemDM from './TExternalDocumentItemDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TAttachedDocumentDM from './TAttachedDocumentDM'
import TOldNoteDM from './TOldNoteDM'

/**
 * Plánované rozvozy
 */
export default class TDeliveryPlanDM extends Data {
	public static className = 'TDeliveryPlanDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = true

	/**
	 * Firma
	 */
	BookId_CompanyId?: number

	/**
	 * Skup. právo
	 */
	BookId_RightGroupId?: number

	/**
	 * Objem
	 */
	Volume?: number

	/**
	 * Hmotnost
	 */
	Weight?: number

	/**
	 * Termín do
	 */
	DateTo?: Date

	/**
	 * Termín od
	 */
	DateFrom?: Date

	/**
	 * Potvrzení 2.
	 */
	ConfirmedOn2?: Date

	/**
	 * Potvrzení 1.
	 */
	ConfirmedOn1?: Date

	/**
	 * Popis
	 */
	Description?: string

	/**
	 * Stornováno
	 */
	IsCanceledRecord?: string

	/**
	 * Skupina práv
	 */
	RightGroupId?: number

	/**
	 * Časové razítko
	 */
	TimeStamp?: string

	/**
	 * Změněno
	 */
	ChangedOn?: Date

	/**
	 * Vytvořeno
	 */
	CreatedOn?: Date

	/**
	 * Číslo dokladu
	 */
	Number?: number

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * undefined
	 */
	BookmarkImgCalc?: string

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Číslo
	 */
	DocumentNumberCalc?: number

	/**
	 * undefined
	 */
	DeliveryTimeFromDateCalc?: Date

	/**
	 * undefined
	 */
	DeliveryTimeFromTimeCalc?: Date

	/**
	 * undefined
	 */
	DeliveryTimeToDateCalc?: Date

	/**
	 * undefined
	 */
	DeliveryTimeToTimeCalc?: Date

	/**
	 * undefined
	 */
	TotalVolumePARAMCalc?: number

	/**
	 * undefined
	 */
	TextHeaderMemoCalc?: string

	/**
	 * undefined
	 */
	TextFooterMemoCalc?: string

	/**
	 * Poslední komentář
	 */
	LastCommentParamCalc?: string

	/**
	 * undefined
	 */
	OwnAddressIdCalc?: number

	/**
	 * undefined
	 */
	OwnAddressNameCalc?: string

	/**
	 * Doklad
	 */
	DocumentIdentificationCalc?: string

	/**
	 * EAN kód
	 */
	BarcodeCalc?: string

	/**
	 * undefined
	 */
	IsReversedExchangeRateViewCalc?: string

	/**
	 * Skupina práv
	 */
	RightGroupIdCalc?: number

	/**
	 * Rozvozová linka
	 */
	@Type(() => TDeliveryLineDM)
	DeliveryLineId?: TDeliveryLineDM

	/**
	 * Vozidlo
	 */
	@Type(() => TVehicleDM)
	VehicleId?: TVehicleDM

	/**
	 * Region
	 */
	@Type(() => AreaCode)
	RegionId?: AreaCode

	/**
	 * Prostředek
	 */
	@Type(() => TDeviceDocumentDM)
	DeviceRID?: TDeviceDocumentDM

	/**
	 * Referent
	 */
	@Type(() => TOfficerDocumentDM)
	OfficerId?: TOfficerDocumentDM

	/**
	 * Kód zboží
	 */
	@Type(() => TArticleCodeDocumentDM)
	ArticleCodeId?: TArticleCodeDocumentDM

	/**
	 * Kód zakázky
	 */
	@Type(() => TContractCodeDocumentDM)
	ContractCodeRID?: TContractCodeDocumentDM

	/**
	 * Kód 6
	 */
	@Type(() => TCode6DocumentDM)
	Code6Id?: TCode6DocumentDM

	/**
	 * Kód 5
	 */
	@Type(() => TCode5DocumentDM)
	Code5Id?: TCode5DocumentDM

	/**
	 * Kód 4
	 */
	@Type(() => TCode4DocumentDM)
	Code4Id?: TCode4DocumentDM

	/**
	 * Kód 3
	 */
	@Type(() => TCode3DocumentDM)
	Code3Id?: TCode3DocumentDM

	/**
	 * Kód 2
	 */
	@Type(() => TCode2DocumentDM)
	Code2Id?: TCode2DocumentDM

	/**
	 * Kód 1
	 */
	@Type(() => TCode1DocumentDM)
	Code1Id?: TCode1DocumentDM

	/**
	 * Středisko
	 */
	@Type(() => TCostCentreCollectionDocumentDM)
	CostCentreId?: TCostCentreCollectionDocumentDM

	/**
	 * Potvrdil 2.
	 */
	@Type(() => TK2UserLookupDM)
	ConfirmedBy2Id?: TK2UserLookupDM

	/**
	 * Potvrdil 1.
	 */
	@Type(() => TK2UserLookupDM)
	ConfirmedBy1Id?: TK2UserLookupDM

	/**
	 * Stav
	 */
	@Type(() => DeliveryOrderStatus)
	StatusId?: DeliveryOrderStatus

	/**
	 * Změnil
	 */
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM

	/**
	 * Vytvořil
	 */
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM

	/**
	 * Období
	 */
	@Type(() => TPeriodDM)
	BusinessYearId?: TPeriodDM

	/**
	 * Kniha
	 */
	@Type(() => TBookDM)
	BookId?: TBookDM

	/**
	 * Období
	 */
	@Type(() => TPeriodDM)
	BusinessYearIdCalc?: TPeriodDM

	/**
	 * undefined
	 */
	@Type(() => TTextDM)
	ConfirmedOrCanceledIdCalc?: TTextDM

	/**
	 * D
	 */
	@Type(() => TTextDM)
	DocumentExistsCalc?: TTextDM

	/**
	 * undefined
	 */
	@Type(() => TMarketingActivityTypeDM)
	ActivityTypeRIDCalc?: TMarketingActivityTypeDM

	/**
	 * undefined
	 */
	@Type(() => TTradingPartnerDM)
	OwnTradingPartnerIdCalc?: TTradingPartnerDM

	/**
	 * Období
	 */
	@Type(() => TPeriodDM)
	CurrentBusinessYearIdCalc?: TPeriodDM

	/**
	 * Sklad
	 */
	@Type(() => TWarehouseDM)
	CurrentWarehouseIdCalc?: TWarehouseDM

	/**
	 * Komentáře
	 */
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]

	/**
	 * undefined
	 */
	@Type(() => TDeliveryPlanItemDM)
	ItemChild?: TDeliveryPlanItemDM[]

	/**
	 * undefined
	 */
	@Type(() => TDeliveryMeasureUnitDM)
	MeasureUnitChild?: TDeliveryMeasureUnitDM[]

	/**
	 * Dokumenty
	 */
	@Type(() => TExternalDocumentItemDM)
	ExternalDocumentItemChild?: TExternalDocumentItemDM[]

	/**
	 * Text hlavičky
	 */
	@Type(() => TLanguageMemoTranslationDM)
	TextHeaderChild?: TLanguageMemoTranslationDM[]

	/**
	 * Text patičky
	 */
	@Type(() => TLanguageMemoTranslationDM)
	TextFooterChild?: TLanguageMemoTranslationDM[]

	/**
	 * Svázané doklady
	 */
	@Type(() => TAttachedDocumentDM)
	AttachedDocumentOldChild?: TAttachedDocumentDM[]

	/**
	 * Poznámky
	 */
	@Type(() => TOldNoteDM)
	DeprecatedNoteOldChild?: TOldNoteDM[]

	/**
	 * Načtení prvotních dokladů
	 */
	public LoadPrimaryDocumentsCOMMAND(parameters: TLoadPrimaryDocumentsCOMMANDParams) {
		return this.executeCommand('LoadPrimaryDocumentsCOMMAND', parameters)
	}

	/**
	 * Přidat doklady linky
	 */
	public DocumentsDeliveryLineCOMMAND(parameters: TDocumentsDeliveryLineCOMMANDParams) {
		return this.executeCommand('DocumentsDeliveryLineCOMMAND', parameters)
	}

	/**
	 * Seřadit položky podle linky
	 */
	public OrderItemByDeliveryLineCOMMAND() {
		return this.executeCommand('OrderItemByDeliveryLineCOMMAND')
	}

	/**
	 * Přepočet hmotnosti a objemu
	 */
	public RecountWeightAndVolumeCOMMAND() {
		return this.executeCommand('RecountWeightAndVolumeCOMMAND')
	}

	/**
	 * Částečné potvrzení
	 */
	public PartialConfirmCOMMAND() {
		return this.executeCommand('PartialConfirmCOMMAND')
	}

	/**
	 * Částečné odpotvrzení
	 */
	public PartialUnconfirmCOMMAND() {
		return this.executeCommand('PartialUnconfirmCOMMAND')
	}

	/**
	 * Částečné potvrzení
	 */
	public BulkPartialConfirmCOMMAND() {
		return this.executeCommand('BulkPartialConfirmCOMMAND')
	}

	/**
	 * Částečné odpotvrzení
	 */
	public BulkPartialUnconfirmCOMMAND() {
		return this.executeCommand('BulkPartialUnconfirmCOMMAND')
	}

	/**
	 * Přecenění k rozvahovému dni
	 */
	public BulkRevaluationAtBalanceDayCOMMAND() {
		return this.executeCommand('BulkRevaluationAtBalanceDayCOMMAND')
	}

	/**
	 * Storno přecenění k rozvahovému dni
	 */
	public BulkCancelRevaluationCOMMAND() {
		return this.executeCommand('BulkCancelRevaluationCOMMAND')
	}

	/**
	 * Změna DUZP
	 */
	public InvoiceDateChangeCOMMAND(parameters: TInvoiceDateChangeCOMMANDParams) {
		return this.executeCommand('InvoiceDateChangeCOMMAND', parameters)
	}

	/**
	 * Zaúčtování s likvidací
	 */
	public AccountAssignmentPostingCOMMAND(parameters: TAccountAssignmentPostingCOMMANDParams) {
		return this.executeCommand('AccountAssignmentPostingCOMMAND', parameters)
	}

	/**
	 * Hromadné zaúčtování s likvidací
	 */
	public BulkAccountAssignmentPostingCOMMAND(parameters: TBulkAccountAssignmentPostingCOMMANDParams) {
		return this.executeCommand('BulkAccountAssignmentPostingCOMMAND', parameters)
	}

	/**
	 * Likvidace
	 */
	public AccountAssignmentCOMMAND() {
		return this.executeCommand('AccountAssignmentCOMMAND')
	}

	/**
	 * Zapnout rychlý filtr
	 */
	public FastFilterSwitchCOMMAND() {
		return this.executeCommand('FastFilterSwitchCOMMAND')
	}

	/**
	 * Povolit editaci
	 */
	public EditModeIDNoCOMMAND() {
		return this.executeCommand('EditModeIDNoCOMMAND')
	}

	/**
	 * Povolit editaci
	 */
	public EditModeVATRegisterNoCOMMAND() {
		return this.executeCommand('EditModeVATRegisterNoCOMMAND')
	}

	/**
	 * Povolit editaci
	 */
	public EditModeTaxNoCOMMAND() {
		return this.executeCommand('EditModeTaxNoCOMMAND')
	}

	/**
	 * Změna kontace
	 */
	public BulkChangePostingKeyCOMMAND(parameters: TBulkChangePostingKeyCOMMANDParams) {
		return this.executeCommand('BulkChangePostingKeyCOMMAND', parameters)
	}

	/**
	 * Aktualizace Plánovaného data zaplacení dle Prodlení Dod./Odb.
	 */
	public BulkUpdatePlannedDateOfPaymentByAvgDelayCOMMAND() {
		return this.executeCommand('BulkUpdatePlannedDateOfPaymentByAvgDelayCOMMAND')
	}

	/**
	 * Zobrazovat data od období: %s
	 */
	public ApplyLowerLimitForData() {
		return this.executeCommand('ApplyLowerLimitForData')
	}

	/**
	 * Potvrzení
	 */
	public BulkConfirmCOMMAND() {
		return this.executeCommand('BulkConfirmCOMMAND')
	}

	/**
	 * Odpotvrzení
	 */
	public BulkUnconfirmCOMMAND() {
		return this.executeCommand('BulkUnconfirmCOMMAND')
	}

	/**
	 * Změna data potvrzení
	 */
	public BulkChangeConfirmationDateCOMMAND(parameters: TBulkChangeConfirmationDateCOMMANDParams) {
		return this.executeCommand('BulkChangeConfirmationDateCOMMAND', parameters)
	}

	/**
	 * Storno
	 */
	public BulkCancelCOMMAND() {
		return this.executeCommand('BulkCancelCOMMAND')
	}

	/**
	 * Odstorno
	 */
	public BulkUnCancelCOMMAND() {
		return this.executeCommand('BulkUnCancelCOMMAND')
	}

	/**
	 * Potvrzení
	 */
	public ConfirmCOMMAND() {
		return this.executeCommand('ConfirmCOMMAND')
	}

	/**
	 * Odpotvrzení
	 */
	public UnconfirmCOMMAND() {
		return this.executeCommand('UnconfirmCOMMAND')
	}

	/**
	 * Změna data potvrzení
	 */
	public ChangeConfirmationDateCOMMAND(parameters: TChangeConfirmationDateCOMMANDParams) {
		return this.executeCommand('ChangeConfirmationDateCOMMAND', parameters)
	}

	/**
	 * Storno/Odstorno
	 */
	public CancelCOMMAND() {
		return this.executeCommand('CancelCOMMAND')
	}

	/**
	 * Zrušení stavu
	 */
	public ClearRecordStatusCOMMAND() {
		return this.executeCommand('ClearRecordStatusCOMMAND')
	}

	/**
	 * Změna stavu
	 */
	public ChangeRecordStatusCOMMAND() {
		return this.executeCommand('ChangeRecordStatusCOMMAND')
	}

	/**
	 * 
	 */
	public ChangeRecordStatusSWSCOMMAND(parameters: TChangeRecordStatusSWSCOMMANDParams) {
		return this.executeCommand('ChangeRecordStatusSWSCOMMAND', parameters)
	}

	/**
	 * Zrušení stavu
	 */
	public BulkClearRecordStatusCOMMAND() {
		return this.executeCommand('BulkClearRecordStatusCOMMAND')
	}

	/**
	 * Změna stavu
	 */
	public BulkChangeRecordStatusCOMMAND() {
		return this.executeCommand('BulkChangeRecordStatusCOMMAND')
	}

	/**
	 * Změna atributů
	 */
	public BulkChangeAttributesCOMMAND(parameters: TBulkChangeAttributesCOMMANDParams) {
		return this.executeCommand('BulkChangeAttributesCOMMAND', parameters)
	}

	/**
	 * Aktualizace sady atributů
	 */
	public BulkActualizeAttributesCOMMAND(parameters: TBulkActualizeAttributesCOMMANDParams) {
		return this.executeCommand('BulkActualizeAttributesCOMMAND', parameters)
	}

	/**
	 * Aktualizace sady atributů
	 */
	public ActualizeAttributesCOMMAND() {
		return this.executeCommand('ActualizeAttributesCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public ChangeGroupRightCOMMAND() {
		return this.executeCommand('ChangeGroupRightCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public BulkChangeGroupRightCOMMAND() {
		return this.executeCommand('BulkChangeGroupRightCOMMAND')
	}

	/**
	 * Aktualizace počítaných polí
	 */
	public BulkUpdateCalcFieldsCOMMAND() {
		return this.executeCommand('BulkUpdateCalcFieldsCOMMAND')
	}

	/**
	 * Kontrola legislativy
	 */
	public SwitchOffLegislationValidationCOMMAND() {
		return this.executeCommand('SwitchOffLegislationValidationCOMMAND')
	}
}

export type TLoadPrimaryDocumentsCOMMANDParams = {
	/**
	 * Kontejner výdejek
	 */
	ReleaseNoteContainer?: boolean
	/**
	 * Kontejner dodacích listů
	 */
	DeliveryNoteContainer?: boolean
	/**
	 * Kontejner rezervačních listů
	 */
	ReservingCardContainer?: boolean
	/**
	 * Kontejner faktur vydaných
	 */
	InvoiceOutContainer?: boolean
	/**
	 * Kontejner faktur přijatých
	 */
	InvoiceInContainer?: boolean
	/**
	 * Kontejner příjemek
	 */
	ReceiptCardContainer?: boolean
	/**
	 * Kontejner potvrzení dodání
	 */
	DeliveryConfirmationContainer?: boolean
	/**
	 * Kontejner převodek
	 */
	TransferNoteContainer?: boolean
}

export type TDocumentsDeliveryLineCOMMANDParams = {
	/**
	 * Rozvozová linka
	 */
	DeliveryLine?: number
	/**
	 * Datum dodání
	 */
	DeliveryDate?: Date
	/**
	 * Zakázky
	 */
	SalesOrderDocument?: boolean
	/**
	 * Výdejky
	 */
	ReleaseNoteDocument?: boolean
	/**
	 * Dodací listy
	 */
	DeliveryNoteDocument?: boolean
	/**
	 * Rezervační listy
	 */
	ReservingCardDocument?: boolean
	/**
	 * Faktury vydané
	 */
	InvoiceOutDocument?: boolean
	/**
	 * Objednávky
	 */
	PurchaseOrderDocument?: boolean
	/**
	 * Faktury přijaté
	 */
	InvoiceInDocument?: boolean
	/**
	 * Příjemky
	 */
	ReceiptCardDocument?: boolean
	/**
	 * Potvrzení dodání
	 */
	DeliveryConfirmationDocument?: boolean
	/**
	 * Převodky
	 */
	TransferNoteDocument?: boolean
}

export type TInvoiceDateChangeCOMMANDParams = {
	/**
	 * VATDate
	 */
	VATDate?: Date
}

export type TAccountAssignmentPostingCOMMANDParams = {
	/**
	 * Zaúčtuje
	 */
	ACCPosting?: number
	/**
	 * Kniha
	 */
	ACCBook?: number
	/**
	 * Období
	 */
	ACCBussinessYear?: number
	/**
	 * Datum zaúčtování
	 */
	ACCPostingDate?: Date
	/**
	 * Datum od
	 */
	ACCDateFrom?: Date
	/**
	 * Datum do
	 */
	ACCDateTo?: Date
	/**
	 * Zachovat ručně změněné záznamy
	 */
	ACCHandChange?: boolean
	/**
	 * Likvidace bez částky
	 */
	ACCNulAmount?: boolean
	/**
	 * Povolit zaúčtovat doklady s jiným obdobím
	 */
	ACCOtherBusinessYear?: boolean
	/**
	 * Zaúčtovat nevyrovnané saldo
	 */
	ACCBalance?: boolean
}

export type TBulkAccountAssignmentPostingCOMMANDParams = {
	/**
	 * Zaúčtuje
	 */
	ACCPosting?: number
	/**
	 * Kniha
	 */
	ACCBook?: number
	/**
	 * Období
	 */
	ACCBussinessYear?: number
	/**
	 * Datum zaúčtování
	 */
	ACCPostingDate?: Date
	/**
	 * Datum od
	 */
	ACCDateFrom?: Date
	/**
	 * Datum do
	 */
	ACCDateTo?: Date
	/**
	 * Zachovat ručně změněné záznamy
	 */
	ACCHandChange?: boolean
	/**
	 * Likvidace bez částky
	 */
	ACCNulAmount?: boolean
	/**
	 * Povolit zaúčtovat doklady s jiným obdobím
	 */
	ACCOtherBusinessYear?: boolean
	/**
	 * Zaúčtovat nevyrovnané saldo
	 */
	ACCBalance?: boolean
}

export type TBulkChangePostingKeyCOMMANDParams = {
	/**
	 * Kontace
	 */
	Header_PostingKeyX?: boolean
	/**
	 * Header_PostingKey
	 */
	Header_PostingKey?: number
	/**
	 * Účet
	 */
	Header_AccountX?: boolean
	/**
	 * Header_Account
	 */
	Header_Account?: number
	/**
	 * Účet D
	 */
	Header_CreditAcX?: boolean
	/**
	 * Header_CreditAc
	 */
	Header_CreditAc?: number
	/**
	 * Účet MD
	 */
	Header_DebitAcX?: boolean
	/**
	 * Header_DebitAc
	 */
	Header_DebitAc?: number
	/**
	 * Parovací symbol
	 */
	Header_MatchSymbolX?: boolean
	/**
	 * Header_MatchSymbol
	 */
	Header_MatchSymbol?: number
	/**
	 * Středisko
	 */
	Header_CostCentreX?: boolean
	/**
	 * Header_CostCentre
	 */
	Header_CostCentre?: number
	/**
	 * Ze třediska
	 */
	Header_FromCostCentreX?: boolean
	/**
	 * Header_FromCostCentre
	 */
	Header_FromCostCentre?: number
	/**
	 * Na středisko
	 */
	Header_ToCostCentreX?: boolean
	/**
	 * Header_ToCostCentre
	 */
	Header_ToCostCentre?: number
	/**
	 * Kód zakázky
	 */
	Header_ContractCodeX?: boolean
	/**
	 * Header_ContractCode
	 */
	Header_ContractCode?: number
	/**
	 * Z kódu zakázky
	 */
	Header_FromContractCodeX?: boolean
	/**
	 * Header_FromContractCode
	 */
	Header_FromContractCode?: number
	/**
	 * Na kód zakázky
	 */
	Header_ToContractCodeX?: boolean
	/**
	 * Header_ToContractCode
	 */
	Header_ToContractCode?: number
	/**
	 * Prostředek
	 */
	Header_DeviceX?: boolean
	/**
	 * Header_Device
	 */
	Header_Device?: number
	/**
	 * Z prostředku
	 */
	Header_FromDeviceX?: boolean
	/**
	 * Header_FromDevice
	 */
	Header_FromDevice?: number
	/**
	 * Na prostředek
	 */
	Header_ToDeviceX?: boolean
	/**
	 * Header_ToDevice
	 */
	Header_ToDevice?: number
	/**
	 * Referent
	 */
	Header_OfficerX?: boolean
	/**
	 * Header_Officer
	 */
	Header_Officer?: number
	/**
	 * Z referenta
	 */
	Header_FromOfficerX?: boolean
	/**
	 * Header_FromOfficer
	 */
	Header_FromOfficer?: number
	/**
	 * Na referenta
	 */
	Header_ToOfficerX?: boolean
	/**
	 * Header_ToOfficer
	 */
	Header_ToOfficer?: number
	/**
	 * Kód zboží
	 */
	Header_ArticleCodeX?: boolean
	/**
	 * Header_ArticleCode
	 */
	Header_ArticleCode?: number
	/**
	 * Kód 1
	 */
	Header_Code1X?: boolean
	/**
	 * Header_Code1
	 */
	Header_Code1?: number
	/**
	 * Z kódu 1
	 */
	Header_FromCode1X?: boolean
	/**
	 * Header_FromCode1
	 */
	Header_FromCode1?: number
	/**
	 * Na kód 1
	 */
	Header_ToCode1X?: boolean
	/**
	 * Header_ToCode1
	 */
	Header_ToCode1?: number
	/**
	 * Kód 2
	 */
	Header_Code2X?: boolean
	/**
	 * Header_Code2
	 */
	Header_Code2?: number
	/**
	 * Z kódu 2
	 */
	Header_FromCode2X?: boolean
	/**
	 * Header_FromCode2
	 */
	Header_FromCode2?: number
	/**
	 * Na kód 2
	 */
	Header_ToCode2X?: boolean
	/**
	 * Header_ToCode2
	 */
	Header_ToCode2?: number
	/**
	 * Kód 3
	 */
	Header_Code3X?: boolean
	/**
	 * Header_Code3
	 */
	Header_Code3?: number
	/**
	 * Z kódu 3
	 */
	Header_FromCode3X?: boolean
	/**
	 * Header_FromCode3
	 */
	Header_FromCode3?: number
	/**
	 * Na kód 3
	 */
	Header_ToCode3X?: boolean
	/**
	 * Header_ToCode3
	 */
	Header_ToCode3?: number
	/**
	 * Kód 4
	 */
	Header_Code4X?: boolean
	/**
	 * Header_Code4
	 */
	Header_Code4?: number
	/**
	 * Z kódu 4
	 */
	Header_FromCode4X?: boolean
	/**
	 * Header_FromCode4
	 */
	Header_FromCode4?: number
	/**
	 * Na kód 4
	 */
	Header_ToCode4X?: boolean
	/**
	 * Header_ToCode4
	 */
	Header_ToCode4?: number
	/**
	 * Kód 5
	 */
	Header_Code5X?: boolean
	/**
	 * Header_Code5
	 */
	Header_Code5?: number
	/**
	 * Z kódu 5
	 */
	Header_FromCode5X?: boolean
	/**
	 * Header_FromCode5
	 */
	Header_FromCode5?: number
	/**
	 * Na kód 5
	 */
	Header_ToCode5X?: boolean
	/**
	 * Header_ToCode5
	 */
	Header_ToCode5?: number
	/**
	 * Kód 6
	 */
	Header_Code6X?: boolean
	/**
	 * Header_Code6
	 */
	Header_Code6?: number
	/**
	 * Z kódu 6
	 */
	Header_FromCode6X?: boolean
	/**
	 * Header_FromCode6
	 */
	Header_FromCode6?: number
	/**
	 * Na kód 6
	 */
	Header_ToCode6X?: boolean
	/**
	 * Header_ToCode6
	 */
	Header_ToCode6?: number
	/**
	 * Ignorovat cenu příjmu
	 */
	Header_IgnorePriceIncomX?: boolean
	/**
	 * Header_IgnorePriceIncom
	 */
	Header_IgnorePriceIncom?: number
	/**
	 * Header_ShowIgnorePriceIncom
	 */
	Header_ShowIgnorePriceIncom?: boolean
	/**
	 * Kalk.vz.plán.
	 */
	Header_PlannedPriceCMX?: boolean
	/**
	 * Header_PlannedPriceCM
	 */
	Header_PlannedPriceCM?: number
	/**
	 * Kalk.vz.sklad.
	 */
	Header_StockPriceCMX?: boolean
	/**
	 * Header_StockPriceCM
	 */
	Header_StockPriceCM?: number
	/**
	 * Kalk.vz.nákl.
	 */
	Header_CostPriceCMX?: boolean
	/**
	 * Header_CostPriceCM
	 */
	Header_CostPriceCM?: number
	/**
	 * Kontace faktury
	 */
	Item_PostingKeyIX?: boolean
	/**
	 * Item_PostingKeyI
	 */
	Item_PostingKeyI?: number
	/**
	 * Kontace skladového dokladu
	 */
	Item_PostingKeySX?: boolean
	/**
	 * Item_PostingKeyS
	 */
	Item_PostingKeyS?: number
	/**
	 * Kontace rezervačního listu
	 */
	Item_PostingKeyRX?: boolean
	/**
	 * Item_PostingKeyR
	 */
	Item_PostingKeyR?: number
	/**
	 * Kód zakázky
	 */
	Item_ContractCodeX?: boolean
	/**
	 * Item_ContractCode
	 */
	Item_ContractCode?: number
	/**
	 * Kód zakázky 2
	 */
	Item_ContractCode2X?: boolean
	/**
	 * Item_ContractCode2
	 */
	Item_ContractCode2?: number
	/**
	 * Středisko
	 */
	Item_CostCentreX?: boolean
	/**
	 * Item_CostCentre
	 */
	Item_CostCentre?: number
	/**
	 * Středisko 2
	 */
	Item_CostCentre2X?: boolean
	/**
	 * Item_CostCentre2
	 */
	Item_CostCentre2?: number
	/**
	 * Dosadit z karty zboží
	 */
	Item_CostCentreG?: boolean
	/**
	 * Kód zboží
	 */
	Item_ArticleCodeX?: boolean
	/**
	 * Item_ArticleCode
	 */
	Item_ArticleCode?: number
	/**
	 * Dosadit z karty zboží
	 */
	Item_ArticleCodeG?: boolean
	/**
	 * Kód 1
	 */
	Item_Code1X?: boolean
	/**
	 * Item_Code1
	 */
	Item_Code1?: number
	/**
	 * Kód 2
	 */
	Item_Code2X?: boolean
	/**
	 * Item_Code2
	 */
	Item_Code2?: number
	/**
	 * Kód 3
	 */
	Item_Code3X?: boolean
	/**
	 * Item_Code3
	 */
	Item_Code3?: number
	/**
	 * Kód 4
	 */
	Item_Code4X?: boolean
	/**
	 * Item_Code4
	 */
	Item_Code4?: number
	/**
	 * Kód 5
	 */
	Item_Code5X?: boolean
	/**
	 * Item_Code5
	 */
	Item_Code5?: number
	/**
	 * Kód 6
	 */
	Item_Code6X?: boolean
	/**
	 * Item_Code6
	 */
	Item_Code6?: number
	/**
	 * Referent
	 */
	Item_OfficerX?: boolean
	/**
	 * Item_Officer
	 */
	Item_Officer?: number
	/**
	 * Prostředek
	 */
	Item_DeviceX?: boolean
	/**
	 * Item_Device
	 */
	Item_Device?: number
	/**
	 * Parovací symbol
	 */
	Item_MatchSymbolX?: boolean
	/**
	 * Item_MatchSymbol
	 */
	Item_MatchSymbol?: number
	/**
	 * Účet
	 */
	Item_AccountX?: boolean
	/**
	 * Item_Account
	 */
	Item_Account?: number
	/**
	 * Kontace
	 */
	Item_PostingKeyX?: boolean
	/**
	 * Item_PostingKey
	 */
	Item_PostingKey?: number
	/**
	 * Účet D
	 */
	Item_CreditAcX?: boolean
	/**
	 * Item_CreditAc
	 */
	Item_CreditAc?: number
	/**
	 * Účet MD
	 */
	Item_DebitAcX?: boolean
	/**
	 * Item_DebitAc
	 */
	Item_DebitAc?: number
	/**
	 * Podle kontejneru zboží
	 */
	GoodsContainer?: boolean
	/**
	 * S odmazáním z kontejneru
	 */
	DeleteFromContainer?: boolean
	/**
	 * Pouze u prázdných
	 */
	OnlyEmpty?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TBulkChangeConfirmationDateCOMMANDParams = {
	/**
	 * Datum potvrzení
	 */
	Confirmation1Date?: Date
	/**
	 * Hodina potvrzení
	 */
	Confirmation1Time?: Date
	/**
	 * Datum 2. potvrzení
	 */
	Confirmation2Date?: Date
	/**
	 * Hodina 2. potvrzení
	 */
	Confirmation2Time?: Date
	/**
	 * Kontrolovat záporné stavy
	 */
	CheckNegativeState?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TChangeConfirmationDateCOMMANDParams = {
	/**
	 * Datum potvrzení
	 */
	Confirmation1Date?: Date
	/**
	 * Hodina potvrzení
	 */
	Confirmation1Time?: Date
	/**
	 * Datum 2. potvrzení
	 */
	Confirmation2Date?: Date
	/**
	 * Hodina 2. potvrzení
	 */
	Confirmation2Time?: Date
	/**
	 * Kontrolovat záporné stavy
	 */
	CheckNegativeState?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TChangeRecordStatusSWSCOMMANDParams = {
	/**
	 * StatusId
	 */
	StatusId?: number
}

export type TBulkChangeAttributesCOMMANDParams = {
	/**
	 * Smazat atributy
	 */
	DeleteAttribute?: boolean
	/**
	 * Smazat všechny volné atributy
	 */
	DeleteFreeAttribute?: boolean
	/**
	 * Nastavit hodnoty atributů
	 */
	SetValues?: boolean
	/**
	 * Přidat nepřipojené atributy
	 */
	AddFreeAttributes?: boolean
	/**
	 * FreeAttributeEnabled
	 */
	FreeAttributeEnabled?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TBulkActualizeAttributesCOMMANDParams = {
	/**
	 * Aktualizovat včetně samotné sady atributů
	 */
	ActualizeAttributeSet?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}