import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TK2UserLookupDM from './TK2UserLookupDM'
import TEkoKomPackagingDM from './TEkoKomPackagingDM'

/**
 * Položky pro EKO-KOM
 */
export default class TEkoKomItemDM extends Data {
	public static className = 'TEkoKomItemDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = false

	/**
	 * Množství
	 */
	LitteringQuantity?: number

	/**
	 * Změněno
	 */
	ChangedOn?: Date

	/**
	 * Typ obalu
	 */
	PackTypeRID?: number

	/**
	 * Hmotnost
	 */
	Weight?: number

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Změnil
	 */
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM

	/**
	 * Obal
	 */
	@Type(() => TEkoKomPackagingDM)
	PackagingRID?: TEkoKomPackagingDM
}