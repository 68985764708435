import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TTextDM from './TTextDM'
import TCode6DocumentDM from './TCode6DocumentDM'
import TCode5DocumentDM from './TCode5DocumentDM'
import TCode4DocumentDM from './TCode4DocumentDM'
import TCode3DocumentDM from './TCode3DocumentDM'
import TK2UserLookupDM from './TK2UserLookupDM'
import TOfficerDocumentDM from './TOfficerDocumentDM'
import TCode2DocumentDM from './TCode2DocumentDM'
import TCode1DocumentDM from './TCode1DocumentDM'
import TArticleCodeDocumentDM from './TArticleCodeDocumentDM'
import TDeviceDocumentDM from './TDeviceDocumentDM'
import TTradingPartnerDM from './TTradingPartnerDM'
import TContractCodeDocumentDM from './TContractCodeDocumentDM'
import TCostCentreAccountingDM from './TCostCentreAccountingDM'
import GroupAccounts from './GroupAccounts'
import TPeriodListDM from './TPeriodListDM'
import TMarketingActivityTypeDM from './TMarketingActivityTypeDM'
import TCommentDM from './TCommentDM'
import TLanguageTextTranslationDM from './TLanguageTextTranslationDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TCompanyLinkDM from './TCompanyLinkDM'
import TAttachedDocumentDM from './TAttachedDocumentDM'
import TOldNoteDM from './TOldNoteDM'

/**
 * Účetní rozvrh
 */
export default class TAccountingChartDM extends Data {
	public static className = 'TAccountingChartDM'
	public static primaryKey = 'Id'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.Id!
	}

	public static isHeader = true

	/**
	 * Kód 6
	 */
	IsStatCode6Dimension?: string

	/**
	 * Kód 5
	 */
	IsStatCode5Dimension?: string

	/**
	 * Kód 4
	 */
	IsStatCode4Dimension?: string

	/**
	 * Kód 3
	 */
	IsStatCode3Dimension?: string

	/**
	 * Kód 6
	 */
	IsCode6Dimension?: string

	/**
	 * Kód 5
	 */
	IsCode5Dimension?: string

	/**
	 * Kód 4
	 */
	IsCode4Dimension?: string

	/**
	 * Kód 3
	 */
	IsCode3Dimension?: string

	/**
	 * Dlouhodobý
	 */
	IsLongTerm?: string

	/**
	 * Přepočítat stat.
	 */
	RecalculateStatistics?: string

	/**
	 * Rež&ie
	 */
	IsDirected?: string

	/**
	 * &Daňově neuznatelný
	 */
	IsNonAllowable?: string

	/**
	 * načítat po obdobích
	 */
	IsInPartsReading?: string

	/**
	 * Sa&ldokontní
	 */
	IsOpenItem?: string

	/**
	 * Sbě&rný
	 */
	IsSummary?: string

	/**
	 * Vytvořeno
	 */
	CreatedOn?: Date

	/**
	 * &Prostředek
	 */
	IsStatDeviceDimension?: string

	/**
	 * Zákaz&ník
	 */
	IsStatTradingPDimension?: string

	/**
	 * &Referent
	 */
	IsStatOfficerDimension?: string

	/**
	 * Kó&d 2
	 */
	IsStatCode2Dimension?: string

	/**
	 * &Kód 1
	 */
	IsStatCode1Dimension?: string

	/**
	 * Kód z&boží
	 */
	IsStatArticleCodeDimension?: string

	/**
	 * Kód &zakázky
	 */
	IsStatContractCodeDimension?: string

	/**
	 * &Středisko
	 */
	IsStatCostCentreDimension?: string

	/**
	 * Prostředek
	 */
	IsDeviceDimension?: string

	/**
	 * Zákazník
	 */
	IsTradingPartnerDimension?: string

	/**
	 * Referent
	 */
	IsOfficerDimension?: string

	/**
	 * Kód 2
	 */
	IsCode2Dimension?: string

	/**
	 * Kód 1
	 */
	IsCode1Dimension?: string

	/**
	 * Kód zboží
	 */
	IsArticleCodeDimension?: string

	/**
	 * Kód zakázky
	 */
	IsContractCodeDimension?: string

	/**
	 * Středisko
	 */
	IsCostCentreDimension?: string

	/**
	 * Časové razítko
	 */
	TimeStamp?: string

	/**
	 * Změněno
	 */
	ChangedOn?: Date

	/**
	 * Název
	 */
	Name?: string

	/**
	 * Účet
	 */
	Abbr?: string

	/**
	 * Ci
	 */
	Id?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * SQLSequenceOrderCalc
	 */
	SQLSequenceOrderCalc?: number

	/**
	 * Jaz.název
	 */
	LanguageTextCalc?: string

	/**
	 * Seznam středisek
	 */
	DimensionCostCentreCalc?: string

	/**
	 * Seznam kódů zakázek
	 */
	DimensionContractCodeCalc?: string

	/**
	 * Seznam zákazníků
	 */
	DimensionTradingPartnerCalc?: string

	/**
	 * Seznam prostředků
	 */
	DimensionDeviceCalc?: string

	/**
	 * Seznam kódů zboží
	 */
	DimensionArticleCodeCalc?: string

	/**
	 * Seznam kódů 1
	 */
	DimensionCode1Calc?: string

	/**
	 * Seznam kódů 2
	 */
	DimensionCode2Calc?: string

	/**
	 * Seznam referentů
	 */
	DimensionOfficerCalc?: string

	/**
	 * Seznam kódů 3
	 */
	DimensionCode3Calc?: string

	/**
	 * Seznam kódů 4
	 */
	DimensionCode4Calc?: string

	/**
	 * Seznam kódů 5
	 */
	DimensionCode5Calc?: string

	/**
	 * Seznam kódů 6
	 */
	DimensionCode6Calc?: string

	/**
	 * undefined
	 */
	TextHeaderMemoCalc?: string

	/**
	 * undefined
	 */
	TextFooterMemoCalc?: string

	/**
	 * Poslední komentář
	 */
	LastCommentParamCalc?: string

	/**
	 * Vstup Z
	 */
	@Type(() => TTextDM)
	TradingPartnerInputId?: TTextDM

	/**
	 * Vstup Kód 6
	 */
	@Type(() => TTextDM)
	Code6InputId?: TTextDM

	/**
	 * Vstup Kód 5
	 */
	@Type(() => TTextDM)
	Code5InputId?: TTextDM

	/**
	 * Vstup Kód 4
	 */
	@Type(() => TTextDM)
	Code4InputId?: TTextDM

	/**
	 * Vstup Kód 3
	 */
	@Type(() => TTextDM)
	Code3InputId?: TTextDM

	/**
	 * Kód 6 - Do
	 */
	@Type(() => TCode6DocumentDM)
	Code6ToId?: TCode6DocumentDM

	/**
	 * Kód 6 - Od
	 */
	@Type(() => TCode6DocumentDM)
	Code6FromId?: TCode6DocumentDM

	/**
	 * Kód 5 - Do
	 */
	@Type(() => TCode5DocumentDM)
	Code5ToId?: TCode5DocumentDM

	/**
	 * Kód 5 - Od
	 */
	@Type(() => TCode5DocumentDM)
	Code5FromId?: TCode5DocumentDM

	/**
	 * Kód 4 - Do
	 */
	@Type(() => TCode4DocumentDM)
	Code4ToId?: TCode4DocumentDM

	/**
	 * Kód 4 - Od
	 */
	@Type(() => TCode4DocumentDM)
	Code4FromId?: TCode4DocumentDM

	/**
	 * Kód 3 - Do
	 */
	@Type(() => TCode3DocumentDM)
	Code3ToId?: TCode3DocumentDM

	/**
	 * Kód 3 - Od
	 */
	@Type(() => TCode3DocumentDM)
	Code3FromId?: TCode3DocumentDM

	/**
	 * Operátor pro kód 6
	 */
	@Type(() => TTextDM)
	Code6OperatorId?: TTextDM

	/**
	 * Operátor pro kód 5
	 */
	@Type(() => TTextDM)
	Code5OperatorId?: TTextDM

	/**
	 * Operátor pro kód 4
	 */
	@Type(() => TTextDM)
	Code4OperatorId?: TTextDM

	/**
	 * Operátor pro kód 3
	 */
	@Type(() => TTextDM)
	Code3OperatorId?: TTextDM

	/**
	 * Vstup Pár. symbol
	 */
	@Type(() => TTextDM)
	MatchingSymbolInputId?: TTextDM

	/**
	 * Vstup Prostředek
	 */
	@Type(() => TTextDM)
	DeviceInputId?: TTextDM

	/**
	 * Vstup Referent
	 */
	@Type(() => TTextDM)
	OfficerInputId?: TTextDM

	/**
	 * Vstup Kód 2
	 */
	@Type(() => TTextDM)
	Code2InputId?: TTextDM

	/**
	 * Vstup Kód 1
	 */
	@Type(() => TTextDM)
	Code1InputId?: TTextDM

	/**
	 * Vstup Kód zboží
	 */
	@Type(() => TTextDM)
	ArticleCodeInputId?: TTextDM

	/**
	 * Vstup Kód zakázky
	 */
	@Type(() => TTextDM)
	ContractCodeInputId?: TTextDM

	/**
	 * Vstup Středisko
	 */
	@Type(() => TTextDM)
	CostCentreInputId?: TTextDM

	/**
	 * Vytvořil
	 */
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM

	/**
	 * Referent Do
	 */
	@Type(() => TOfficerDocumentDM)
	OfficerToId?: TOfficerDocumentDM

	/**
	 * Referent Od
	 */
	@Type(() => TOfficerDocumentDM)
	OfficerFromId?: TOfficerDocumentDM

	/**
	 * Kód 2 Do
	 */
	@Type(() => TCode2DocumentDM)
	Code2ToId?: TCode2DocumentDM

	/**
	 * Kód 2 Od
	 */
	@Type(() => TCode2DocumentDM)
	Code2FromId?: TCode2DocumentDM

	/**
	 * Kód 1 Do
	 */
	@Type(() => TCode1DocumentDM)
	Code1ToId?: TCode1DocumentDM

	/**
	 * Kód 1 Od
	 */
	@Type(() => TCode1DocumentDM)
	Code1FromId?: TCode1DocumentDM

	/**
	 * Kód zboží Do
	 */
	@Type(() => TArticleCodeDocumentDM)
	ArticleCodeToId?: TArticleCodeDocumentDM

	/**
	 * Kód zboží Od
	 */
	@Type(() => TArticleCodeDocumentDM)
	ArticleCodeFromId?: TArticleCodeDocumentDM

	/**
	 * Prostředek Do
	 */
	@Type(() => TDeviceDocumentDM)
	DeviceToRID?: TDeviceDocumentDM

	/**
	 * Prostředek Od
	 */
	@Type(() => TDeviceDocumentDM)
	DeviceFromRID?: TDeviceDocumentDM

	/**
	 * Zákazník Do
	 */
	@Type(() => TTradingPartnerDM)
	TradingPartnerToId?: TTradingPartnerDM

	/**
	 * Zákazník Od
	 */
	@Type(() => TTradingPartnerDM)
	TradingPartnerFromId?: TTradingPartnerDM

	/**
	 * Kód zakázky Do
	 */
	@Type(() => TContractCodeDocumentDM)
	ContractCodeToRID?: TContractCodeDocumentDM

	/**
	 * Kód zakázky Od
	 */
	@Type(() => TContractCodeDocumentDM)
	ContractCodeFromRID?: TContractCodeDocumentDM

	/**
	 * Středisko Do
	 */
	@Type(() => TCostCentreAccountingDM)
	CostCentreToId?: TCostCentreAccountingDM

	/**
	 * Středisko Od
	 */
	@Type(() => TCostCentreAccountingDM)
	CostCentreFromId?: TCostCentreAccountingDM

	/**
	 * Operátor pro referenta
	 */
	@Type(() => TTextDM)
	OfficerOperatorId?: TTextDM

	/**
	 * Operátor pro kód 2
	 */
	@Type(() => TTextDM)
	Code2OperatorId?: TTextDM

	/**
	 * Operátor pro kód 1
	 */
	@Type(() => TTextDM)
	Code1OperatorId?: TTextDM

	/**
	 * Operátor pro kód zboží
	 */
	@Type(() => TTextDM)
	ArticleCodeOperatorId?: TTextDM

	/**
	 * Operátor pro prostředek
	 */
	@Type(() => TTextDM)
	DeviceOperatorId?: TTextDM

	/**
	 * Operátor pro zákazníka
	 */
	@Type(() => TTextDM)
	TradingPartnerOperatorId?: TTextDM

	/**
	 * Operátor pro kód zakázky
	 */
	@Type(() => TTextDM)
	ContractCodeOperatorId?: TTextDM

	/**
	 * Operátor pro středisko
	 */
	@Type(() => TTextDM)
	CostCentreOperatorId?: TTextDM

	/**
	 * Skupina účtů
	 */
	@Type(() => GroupAccounts)
	AccountGroupId?: GroupAccounts

	/**
	 * Platnost do
	 */
	@Type(() => TPeriodListDM)
	ValidToPeriodId?: TPeriodListDM

	/**
	 * Platnost od
	 */
	@Type(() => TPeriodListDM)
	ValidFromPeriodId?: TPeriodListDM

	/**
	 * Změnil
	 */
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM

	/**
	 * Typ
	 */
	@Type(() => TTextDM)
	TypeId?: TTextDM

	/**
	 * Období od
	 */
	@Type(() => TPeriodListDM)
	PerFromCalc?: TPeriodListDM

	/**
	 * Období do
	 */
	@Type(() => TPeriodListDM)
	PerToCalc?: TPeriodListDM

	/**
	 * D
	 */
	@Type(() => TTextDM)
	DocumentExistsCalc?: TTextDM

	/**
	 * undefined
	 */
	@Type(() => TMarketingActivityTypeDM)
	ActivityTypeRIDCalc?: TMarketingActivityTypeDM

	/**
	 * Komentáře
	 */
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]

	/**
	 * undefined
	 */
	@Type(() => TLanguageTextTranslationDM)
	LanguageNameChild?: TLanguageTextTranslationDM[]

	/**
	 * Text hlavičky
	 */
	@Type(() => TLanguageMemoTranslationDM)
	TextHeaderChild?: TLanguageMemoTranslationDM[]

	/**
	 * Text patičky
	 */
	@Type(() => TLanguageMemoTranslationDM)
	TextFooterChild?: TLanguageMemoTranslationDM[]

	/**
	 * undefined
	 */
	@Type(() => TCompanyLinkDM)
	CompanyChild?: TCompanyLinkDM[]

	/**
	 * Svázané doklady
	 */
	@Type(() => TAttachedDocumentDM)
	AttachedDocumentOldChild?: TAttachedDocumentDM[]

	/**
	 * Poznámky
	 */
	@Type(() => TOldNoteDM)
	DeprecatedNoteOldChild?: TOldNoteDM[]

	/**
	 * Potvrzení
	 */
	public BulkConfirmCOMMAND() {
		return this.executeCommand('BulkConfirmCOMMAND')
	}

	/**
	 * Odpotvrzení
	 */
	public BulkUnconfirmCOMMAND() {
		return this.executeCommand('BulkUnconfirmCOMMAND')
	}

	/**
	 * Změna data potvrzení
	 */
	public BulkChangeConfirmationDateCOMMAND(parameters: TBulkChangeConfirmationDateCOMMANDParams) {
		return this.executeCommand('BulkChangeConfirmationDateCOMMAND', parameters)
	}

	/**
	 * Storno
	 */
	public BulkCancelCOMMAND() {
		return this.executeCommand('BulkCancelCOMMAND')
	}

	/**
	 * Odstorno
	 */
	public BulkUnCancelCOMMAND() {
		return this.executeCommand('BulkUnCancelCOMMAND')
	}

	/**
	 * Potvrzení
	 */
	public ConfirmCOMMAND() {
		return this.executeCommand('ConfirmCOMMAND')
	}

	/**
	 * Odpotvrzení
	 */
	public UnconfirmCOMMAND() {
		return this.executeCommand('UnconfirmCOMMAND')
	}

	/**
	 * Změna data potvrzení
	 */
	public ChangeConfirmationDateCOMMAND(parameters: TChangeConfirmationDateCOMMANDParams) {
		return this.executeCommand('ChangeConfirmationDateCOMMAND', parameters)
	}

	/**
	 * Storno/Odstorno
	 */
	public CancelCOMMAND() {
		return this.executeCommand('CancelCOMMAND')
	}

	/**
	 * Zrušení stavu
	 */
	public ClearRecordStatusCOMMAND() {
		return this.executeCommand('ClearRecordStatusCOMMAND')
	}

	/**
	 * Změna stavu
	 */
	public ChangeRecordStatusCOMMAND() {
		return this.executeCommand('ChangeRecordStatusCOMMAND')
	}

	/**
	 * 
	 */
	public ChangeRecordStatusSWSCOMMAND(parameters: TChangeRecordStatusSWSCOMMANDParams) {
		return this.executeCommand('ChangeRecordStatusSWSCOMMAND', parameters)
	}

	/**
	 * Zrušení stavu
	 */
	public BulkClearRecordStatusCOMMAND() {
		return this.executeCommand('BulkClearRecordStatusCOMMAND')
	}

	/**
	 * Změna stavu
	 */
	public BulkChangeRecordStatusCOMMAND() {
		return this.executeCommand('BulkChangeRecordStatusCOMMAND')
	}

	/**
	 * Změna atributů
	 */
	public BulkChangeAttributesCOMMAND(parameters: TBulkChangeAttributesCOMMANDParams) {
		return this.executeCommand('BulkChangeAttributesCOMMAND', parameters)
	}

	/**
	 * Aktualizace sady atributů
	 */
	public BulkActualizeAttributesCOMMAND(parameters: TBulkActualizeAttributesCOMMANDParams) {
		return this.executeCommand('BulkActualizeAttributesCOMMAND', parameters)
	}

	/**
	 * Aktualizace sady atributů
	 */
	public ActualizeAttributesCOMMAND() {
		return this.executeCommand('ActualizeAttributesCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public ChangeGroupRightCOMMAND() {
		return this.executeCommand('ChangeGroupRightCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public BulkChangeGroupRightCOMMAND() {
		return this.executeCommand('BulkChangeGroupRightCOMMAND')
	}

	/**
	 * Aktualizace počítaných polí
	 */
	public BulkUpdateCalcFieldsCOMMAND() {
		return this.executeCommand('BulkUpdateCalcFieldsCOMMAND')
	}

	/**
	 * Kontrola legislativy
	 */
	public SwitchOffLegislationValidationCOMMAND() {
		return this.executeCommand('SwitchOffLegislationValidationCOMMAND')
	}
}

export type TBulkChangeConfirmationDateCOMMANDParams = {
	/**
	 * Datum potvrzení
	 */
	Confirmation1Date?: Date
	/**
	 * Hodina potvrzení
	 */
	Confirmation1Time?: Date
	/**
	 * Datum 2. potvrzení
	 */
	Confirmation2Date?: Date
	/**
	 * Hodina 2. potvrzení
	 */
	Confirmation2Time?: Date
	/**
	 * Kontrolovat záporné stavy
	 */
	CheckNegativeState?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TChangeConfirmationDateCOMMANDParams = {
	/**
	 * Datum potvrzení
	 */
	Confirmation1Date?: Date
	/**
	 * Hodina potvrzení
	 */
	Confirmation1Time?: Date
	/**
	 * Datum 2. potvrzení
	 */
	Confirmation2Date?: Date
	/**
	 * Hodina 2. potvrzení
	 */
	Confirmation2Time?: Date
	/**
	 * Kontrolovat záporné stavy
	 */
	CheckNegativeState?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TChangeRecordStatusSWSCOMMANDParams = {
	/**
	 * StatusId
	 */
	StatusId?: number
}

export type TBulkChangeAttributesCOMMANDParams = {
	/**
	 * Smazat atributy
	 */
	DeleteAttribute?: boolean
	/**
	 * Smazat všechny volné atributy
	 */
	DeleteFreeAttribute?: boolean
	/**
	 * Nastavit hodnoty atributů
	 */
	SetValues?: boolean
	/**
	 * Přidat nepřipojené atributy
	 */
	AddFreeAttributes?: boolean
	/**
	 * FreeAttributeEnabled
	 */
	FreeAttributeEnabled?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TBulkActualizeAttributesCOMMANDParams = {
	/**
	 * Aktualizovat včetně samotné sady atributů
	 */
	ActualizeAttributeSet?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}