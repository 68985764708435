import Data from 'react-k2-api/Api/Data'
import { Type } from 'class-transformer'
import TWorkflowRoleDM from './TWorkflowRoleDM'
import TContactPersonDM from './TContactPersonDM'
import TOrgStrTreeUnitDM from './TOrgStrTreeUnitDM'

/**
 * Podřízené osoby v org. jednotkách
 */
export default class TOrgStrTreeChildPersonDM extends Data {
	public static className = 'TOrgStrTreeChildPersonDM'
	public static systemFields = [
		'RecordID',
	]

	public static isHeader = false

	/**
	 * CostCentre
	 */
	CostCentreId?: number

	/**
	 * undefined
	 */
	OrgStrUnitSuperiorRID?: number

	/**
	 * undefined
	 */
	OrgStrTreeRID?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * undefined
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * undefined
	 */
	@Type(() => TWorkflowRoleDM)
	RoleId?: TWorkflowRoleDM

	/**
	 * undefined
	 */
	@Type(() => TContactPersonDM)
	ContactPersonId?: TContactPersonDM

	/**
	 * undefined
	 */
	@Type(() => TOrgStrTreeUnitDM)
	OrgStrUnitRID?: TOrgStrTreeUnitDM

	/**
	 * undefined
	 */
	@Type(() => TOrgStrTreeUnitDM)
	OrgStrUnitRootRID?: TOrgStrTreeUnitDM
}