import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TK2UserLookupDM from './TK2UserLookupDM'
import TCompanyDividedBookDM from './TCompanyDividedBookDM'
import TWarehouseDM from './TWarehouseDM'
import TContractCodeDocumentDM from './TContractCodeDocumentDM'
import TLocationArticleDM from './TLocationArticleDM'
import TJobCardConfigFieldDM from './TJobCardConfigFieldDM'
import TOwnCompaniesJobCardConfigDM from './TOwnCompaniesJobCardConfigDM'

/**
 * Konfigurace vytváření průvodek
 */
export default class TJobCardConfigDM extends Data {
	public static className = 'TJobCardConfigDM'
	public static primaryKey = 'Id'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.Id!
	}

	public static isHeader = true

	/**
	 * Skupina práv
	 */
	RightGroupId?: number

	/**
	 * Název
	 */
	Name?: string

	/**
	 * Časové razítko
	 */
	TimeStamp?: string

	/**
	 * Změněno
	 */
	ChangedOn?: Date

	/**
	 * Vytvořeno
	 */
	CreatedOn?: Date

	/**
	 * Zkratka
	 */
	Abbr?: string

	/**
	 * Číslo
	 */
	Id?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Skupina práv
	 */
	RightGroupIdCalc?: number

	/**
	 * Změnil
	 */
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM

	/**
	 * Vytvořil
	 */
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM

	/**
	 * Konfigurace výroby
	 */
	@Type(() => TCompanyDividedBookDM)
	PlanningJCBookIdCalc?: TCompanyDividedBookDM

	/**
	 * Kniha odváděcích průvodek
	 */
	@Type(() => TCompanyDividedBookDM)
	CompletionJCBookIdCalc?: TCompanyDividedBookDM

	/**
	 * Sklad pro finální výrobek
	 */
	@Type(() => TWarehouseDM)
	FinalWarehouseIdCalc?: TWarehouseDM

	/**
	 * Sklad pro spotřebu
	 */
	@Type(() => TWarehouseDM)
	ConsumptionWarehouseIdCalc?: TWarehouseDM

	/**
	 * Sklad pro rozpracovanost
	 */
	@Type(() => TWarehouseDM)
	WIPWarehouseIdCalc?: TWarehouseDM

	/**
	 * Sklad pro neshody
	 */
	@Type(() => TWarehouseDM)
	RejectionWarehouseIdCalc?: TWarehouseDM

	/**
	 * Kód zakázky
	 */
	@Type(() => TContractCodeDocumentDM)
	ContractCodeRIDCalc?: TContractCodeDocumentDM

	/**
	 * Umístění pro finální výrobek
	 */
	@Type(() => TLocationArticleDM)
	FinalLocationIdCalc?: TLocationArticleDM

	/**
	 * Umístění pro spotřebu
	 */
	@Type(() => TLocationArticleDM)
	ConsumptionLocationIdCalc?: TLocationArticleDM

	/**
	 * Umístění pro rozpracovanost
	 */
	@Type(() => TLocationArticleDM)
	WIPLocationIdCalc?: TLocationArticleDM

	/**
	 * Umístění pro neshody
	 */
	@Type(() => TLocationArticleDM)
	RejectionLocationIdCalc?: TLocationArticleDM

	/**
	 * Další pole průvodky
	 */
	@Type(() => TJobCardConfigFieldDM)
	AddedFieldChild?: TJobCardConfigFieldDM[]

	/**
	 * undefined
	 */
	@Type(() => TOwnCompaniesJobCardConfigDM)
	CompanyChild?: TOwnCompaniesJobCardConfigDM[]

	/**
	 * Změna skupiny práv
	 */
	public ChangeGroupRightCOMMAND() {
		return this.executeCommand('ChangeGroupRightCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public BulkChangeGroupRightCOMMAND() {
		return this.executeCommand('BulkChangeGroupRightCOMMAND')
	}

	/**
	 * Aktualizace počítaných polí
	 */
	public BulkUpdateCalcFieldsCOMMAND() {
		return this.executeCommand('BulkUpdateCalcFieldsCOMMAND')
	}

	/**
	 * Kontrola legislativy
	 */
	public SwitchOffLegislationValidationCOMMAND() {
		return this.executeCommand('SwitchOffLegislationValidationCOMMAND')
	}
}