import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TPrPeriodDM from './TPrPeriodDM'
import TPrEmploymentDM from './TPrEmploymentDM'
import TPrConstantGroupDM from './TPrConstantGroupDM'
import TPrConstantGroupItemBaseDM from './TPrConstantGroupItemBaseDM'

/**
 * Pracovní vztahy přiřazené skupinám mzdových konstant
 */
export default class TPrConstantGroupEmploymentDM extends Data {
	public static className = 'TPrConstantGroupEmploymentDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = false

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Období platnosti do
	 */
	@Type(() => TPrPeriodDM)
	PeriodToId?: TPrPeriodDM

	/**
	 * Období platnosti od
	 */
	@Type(() => TPrPeriodDM)
	PeriodFromId?: TPrPeriodDM

	/**
	 * Pracovní vztah
	 */
	@Type(() => TPrEmploymentDM)
	PrEmploymentId?: TPrEmploymentDM

	/**
	 * Zařazení pracovního vztahu
	 */
	@Type(() => TPrConstantGroupDM)
	PrConstantGroupRID?: TPrConstantGroupDM

	/**
	 * undefined
	 */
	@Type(() => TPrConstantGroupItemBaseDM)
	PrConstantGroupItemBaseChild?: TPrConstantGroupItemBaseDM[]
}