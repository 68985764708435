import Data from 'react-k2-api/Api/Data'
import { Type } from 'class-transformer'
import TArticleDM from './TArticleDM'

/**
 * Zboží pro kategorii
 */
export default class TEshopArticleListDM extends Data {
	public static className = 'TEshopArticleListDM'
	public static systemFields = [
		'RecordID',
	]

	public static isHeader = false

	/**
	 * Kategorie
	 */
	CategoryRID?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * Zboží
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Název
	 */
	ArticleNameCalc?: string

	/**
	 * Zboží
	 */
	@Type(() => TArticleDM)
	ArticleId?: TArticleDM
}