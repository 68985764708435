import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'

/**
 * Kurzovní lístek
 */
export default class TCurrencyCountryExchangeRateDM extends Data {
	public static className = 'TCurrencyCountryExchangeRateDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = false

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * Aktuální
	 */
	IsCurrent?: string

	/**
	 * Firma
	 */
	CompanyId?: number

	/**
	 * Valuty prodej
	 */
	ValutaExchangeRateSale?: number

	/**
	 * Valuty střed
	 */
	ValutaExchangeRateMiddle?: number

	/**
	 * Valuty nákup
	 */
	ValutaExchangeRatePurchase?: number

	/**
	 * Devizy podej
	 */
	ForeignExchangeRateSale?: number

	/**
	 * Devizy střed
	 */
	ForeignExchangeRateMiddle?: number

	/**
	 * Devizy nákup
	 */
	ForeignExchangeRatePurchase?: number

	/**
	 * Jednotka
	 */
	MainCurrencyUnit?: number

	/**
	 * Datum
	 */
	ExchangeRateDate?: Date

	/**
	 * Mena
	 */
	Currency?: string

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Devizy nákup
	 */
	BuyNonCashAmountCalc?: number

	/**
	 * Devizy střed
	 */
	MiddleNonCashAmountCalc?: number

	/**
	 * Devizy prodej
	 */
	SellNonCashAmountCalc?: number

	/**
	 * Valuty nákup
	 */
	BuyCashAmountCalc?: number

	/**
	 * Valuty střed
	 */
	MiddleCashAmountCalc?: number

	/**
	 * Valuty prodej
	 */
	SellCashAmountCalc?: number
}