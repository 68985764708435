import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TPrPeriodDM from './TPrPeriodDM'
import TPrConstantDM from './TPrConstantDM'
import TPrConstantGroupDM from './TPrConstantGroupDM'
import TTextDM from './TTextDM'
import TPrDeductionDM from './TPrDeductionDM'

/**
 * Mzdové konstanty ve skupinách
 */
export default class TPrConstantGroupItemBaseDM extends Data {
	public static className = 'TPrConstantGroupItemBaseDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = false

	/**
	 * Hodnota
	 */
	StringValue?: string

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Hodnota
	 */
	ConstantValueStringCalc?: string

	/**
	 * Hodnota
	 */
	ActualTextCalc?: string

	/**
	 * Kniha platnosti hodnoty
	 */
	BookIdCalc?: number

	/**
	 * Hodnota konstanty
	 */
	ConstantValueDateCalc?: Date

	/**
	 * Hodnota konstanty
	 */
	ConstantValueCurrencyCalc?: number

	/**
	 * Hodnota konstanty
	 */
	ConstantValueIntegerCalc?: number

	/**
	 * Hodnota konstanty
	 */
	ConstantValueRealCalc?: number

	/**
	 * Hodnota konstanty
	 */
	ConstantValueTextCalc?: string

	/**
	 * Hodnota konstanty
	 */
	ConstantValueTimeCalc?: Date

	/**
	 * Hodnota konstanty
	 */
	ConstantValueComponentIdCalc?: number

	/**
	 * Hodnota konstanty
	 */
	ConstantValueCalendarIdCalc?: number

	/**
	 * Období platnosti do
	 */
	@Type(() => TPrPeriodDM)
	PeriodToId?: TPrPeriodDM

	/**
	 * Období platnosti od
	 */
	@Type(() => TPrPeriodDM)
	PeriodFromId?: TPrPeriodDM

	/**
	 * Mzdová konstanta
	 */
	@Type(() => TPrConstantDM)
	PrConstantId?: TPrConstantDM

	/**
	 * Zařazení pracovního vztahu
	 */
	@Type(() => TPrConstantGroupDM)
	PrConstantGroupRID?: TPrConstantGroupDM

	/**
	 * Hodnota konstanty
	 */
	@Type(() => TTextDM)
	ConstantValueBooleanIdCalc?: TTextDM

	/**
	 * Hodnota konstanty
	 */
	@Type(() => TPrDeductionDM)
	ConstantValueBankDetailIdCalc?: TPrDeductionDM
}