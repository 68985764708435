import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TK2UserLookupDM from './TK2UserLookupDM'
import TBookDM from './TBookDM'
import Effect from './Effect'
import Outcome from './Outcome'
import TContactPersonDM from './TContactPersonDM'
import TQualificationDM from './TQualificationDM'
import TCommentDM from './TCommentDM'

/**
 * Položky kvalifikace pro kontaktní osoby
 */
export default class TQualificationContactPersonDM extends Data {
	public static className = 'TQualificationContactPersonDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = true

	/**
	 * Skupina práv
	 */
	RightGroupId?: number

	/**
	 * Časové razítko
	 */
	TimeStamp?: string

	/**
	 * Změněno
	 */
	ChangedOn?: Date

	/**
	 * Vytvořeno
	 */
	CreatedOn?: Date

	/**
	 * XMLFields
	 */
	XMLField?: string

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * Účinnost
	 */
	EffectMemo?: string

	/**
	 * Výsledek
	 */
	OutcomeMemo?: string

	/**
	 * Platnost do
	 */
	ValidTo?: Date

	/**
	 * Účinnost
	 */
	EffectAbbr?: string

	/**
	 * Výsledek
	 */
	OutcomeAbbr?: string

	/**
	 * Výnosy
	 */
	ReturnsC?: number

	/**
	 * Typ
	 */
	TypeId?: number

	/**
	 * Vyhodnocení
	 */
	Screening?: number

	/**
	 * Náklady
	 */
	Costs?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Platnost do
	 */
	ValiFromDateCalc?: Date

	/**
	 * Poslední komentář
	 */
	LastCommentParamCalc?: string

	/**
	 * Skupina práv
	 */
	RightGroupIdCalc?: number

	/**
	 * Změnil
	 */
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM

	/**
	 * Vytvořil
	 */
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM

	/**
	 * Kniha
	 */
	@Type(() => TBookDM)
	BookId?: TBookDM

	/**
	 * Účinnost
	 */
	@Type(() => Effect)
	EffectId?: Effect

	/**
	 * Výsledek
	 */
	@Type(() => Outcome)
	OutcomeId?: Outcome

	/**
	 * Osoba
	 */
	@Type(() => TContactPersonDM)
	EmployedPersonId?: TContactPersonDM

	/**
	 * Kvalifikace
	 */
	@Type(() => TQualificationDM)
	QualificationRID?: TQualificationDM

	/**
	 * Komentáře
	 */
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]

	/**
	 * Změna skupiny práv
	 */
	public ChangeGroupRightCOMMAND() {
		return this.executeCommand('ChangeGroupRightCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public BulkChangeGroupRightCOMMAND() {
		return this.executeCommand('BulkChangeGroupRightCOMMAND')
	}

	/**
	 * Aktualizace počítaných polí
	 */
	public BulkUpdateCalcFieldsCOMMAND() {
		return this.executeCommand('BulkUpdateCalcFieldsCOMMAND')
	}

	/**
	 * Kontrola legislativy
	 */
	public SwitchOffLegislationValidationCOMMAND() {
		return this.executeCommand('SwitchOffLegislationValidationCOMMAND')
	}
}