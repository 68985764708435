import Data from 'react-k2-api/Api/Data'
import { Type } from 'class-transformer'
import TRegisteredDataModuleDM from './TRegisteredDataModuleDM'

/**
 * Servisní úkony - režim tabulka
 */
export default class TServiceOperationDM extends Data {
	public static className = 'TServiceOperationDM'
	public static systemFields = [
		'RecordID',
	]

	public static isHeader = false

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * Pořadí
	 */
	RecordNo?: number

	/**
	 * undefined
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * undefined
	 */
	SuperiorHeaderRID?: number

	/**
	 * undefined
	 */
	DecisiveDate?: Date

	/**
	 * undefined
	 */
	ItemNumber?: number

	/**
	 * undefined
	 */
	ChildType?: number

	/**
	 * undefined
	 */
	HeaderRID?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Doklad
	 */
	IdentificationCalc?: string

	/**
	 * undefined
	 */
	DescriptionCalc?: string

	/**
	 * undefined
	 */
	RecordPermissionCalc?: string

	/**
	 * undefined
	 */
	IsConfirmedCalc?: string

	/**
	 * undefined
	 */
	ModuleNameCalc?: string

	/**
	 * undefined
	 */
	@Type(() => TRegisteredDataModuleDM)
	HeaderTypeId?: TRegisteredDataModuleDM
}