import Data from 'react-k2-api/Api/Data'
import { Type } from 'class-transformer'
import TK2UserLookupDM from './TK2UserLookupDM'
import ZarStatus from './ZarStatus'
import PurchaseWay from './PurchaseWay'
import TypDeprGr from './TypDeprGr'
import AccKindPurch from './AccKindPurch'
import FunctType from './FunctType'
import TypPlanuZar from './TypPlanuZar'
import TypVyr from './TypVyr'
import MajSkup from './MajSkup'
import ConstrType from './ConstrType'
import TOldBookDM from './TOldBookDM'
import TPeriodDM from './TPeriodDM'
import TTextDM from './TTextDM'
import TMarketingActivityTypeDM from './TMarketingActivityTypeDM'
import TWorkflowProcessDM from './TWorkflowProcessDM'
import TTradingPartnerDM from './TTradingPartnerDM'
import TWarehouseDM from './TWarehouseDM'
import TAccountingJournalDM from './TAccountingJournalDM'
import TPeriodListDM from './TPeriodListDM'
import TOfficerDocumentDM from './TOfficerDocumentDM'
import TCostCentreCollectionDocumentDM from './TCostCentreCollectionDocumentDM'
import ZarUmisteni from './ZarUmisteni'
import Pracoviste from './Pracoviste'
import TContractCodeDocumentDM from './TContractCodeDocumentDM'
import TArticleCodeDocumentDM from './TArticleCodeDocumentDM'
import TCode1DocumentDM from './TCode1DocumentDM'
import TCode2DocumentDM from './TCode2DocumentDM'
import TMatchingSymbolDM from './TMatchingSymbolDM'
import TDeviceDocumentDM from './TDeviceDocumentDM'
import TAccountingChartDM from './TAccountingChartDM'
import KindBuild from './KindBuild'
import KindParcel from './KindParcel'
import CadTerritory from './CadTerritory'
import TCode3DocumentDM from './TCode3DocumentDM'
import TCode4DocumentDM from './TCode4DocumentDM'
import TCode5DocumentDM from './TCode5DocumentDM'
import TCode6DocumentDM from './TCode6DocumentDM'
import TJobDM from './TJobDM'
import TExternalDocumentItemDM from './TExternalDocumentItemDM'
import TWorkflowProcessAsItemDM from './TWorkflowProcessAsItemDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TDocumentAccountingJournalItemDM from './TDocumentAccountingJournalItemDM'
import TAssetLinkItemDM from './TAssetLinkItemDM'
import TD_ZarPC from './TD_ZarPC'
import TD_ZarPM1 from './TD_ZarPM1'
import TD_ZarPM2 from './TD_ZarPM2'
import TAttachedDocumentDM from './TAttachedDocumentDM'
import TOldNoteDM from './TOldNoteDM'

/**
 * Majetek
 */
export default class TD_Zar extends Data {
	public static className = 'TD_Zar'
	public static systemFields = [
	]

	public static isHeader = true

	/**
	 * CZ-CPA
	 */
	ProductionClassificationRID?: number

	/**
	 * Vytvořeno
	 */
	CreationDate?: Date

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * Neupl. odpisy 2
	 */
	UADeprSK2?: number

	/**
	 * Neupl. odpisy 1
	 */
	UADeprSK1?: number

	/**
	 * Oprávky v roce navedení 2st.
	 */
	OldDeprY2?: number

	/**
	 * Oprávky v roce navedení 1st.
	 */
	OldDeprY1?: number

	/**
	 * Evidenční číslo
	 */
	RegistrationNo?: string

	/**
	 * Číslo smlouvy
	 */
	ContractNo?: string

	/**
	 * Dotace
	 */
	Grant?: number

	/**
	 * Prost.-proc. evidence
	 */
	SpaceProcessId?: number

	/**
	 * Předvolba2
	 */
	PreselectionId2?: number

	/**
	 * Předvolba1
	 */
	PreselectionId1?: number

	/**
	 * EAN
	 */
	EAN?: string

	/**
	 * DatumDo
	 */
	DAT_TO?: Date

	/**
	 * Počet let odp2.st.
	 */
	YearBefore2?: number

	/**
	 * Počet let odp1.st.
	 */
	YearBefore1?: number

	/**
	 * PlaCis
	 */
	PlaCis?: number

	/**
	 * SouCis
	 */
	SouCis?: number

	/**
	 * InvCis
	 */
	InvCis?: string

	/**
	 * Crc2
	 */
	Crc2?: number

	/**
	 * Poc_LPolA
	 */
	Poc_LPolA?: number

	/**
	 * Poc_LPol
	 */
	Poc_LPol?: number

	/**
	 * Poc_PolA
	 */
	Poc_PolA?: number

	/**
	 * Poc_Pol
	 */
	Poc_Pol?: number

	/**
	 * PohybV
	 */
	PohybV?: number

	/**
	 * PohybO
	 */
	PohybO?: number

	/**
	 * PohybZ
	 */
	PohybZ?: number

	/**
	 * Obor
	 */
	TRIDA?: number

	/**
	 * Oprávky2st.
	 */
	OPR2_ST?: number

	/**
	 * Oprávky1st.
	 */
	OPR1_ST?: number

	/**
	 * Prod.cena
	 */
	PROD_C?: number

	/**
	 * Vst.cena
	 */
	VST_CENA?: number

	/**
	 * DBD
	 */
	DBD?: Date

	/**
	 * Dat.posl.odp.
	 */
	DPO?: Date

	/**
	 * Dat.zav.do prg.
	 */
	DZO?: Date

	/**
	 * Dat.zař.
	 */
	DAT_ZAR?: Date

	/**
	 * Dat.vyř.
	 */
	DAT_VYR?: Date

	/**
	 * Změněno
	 */
	DPZ?: Date

	/**
	 * Dat.poř.
	 */
	DAT_POR?: Date

	/**
	 * Výr.čís.
	 */
	VYR_CIS?: string

	/**
	 * Popis
	 */
	POPIS?: string

	/**
	 * Název
	 */
	NAZEV?: string

	/**
	 * Volny
	 */
	Volny?: number

	/**
	 * Číslo
	 */
	CISLO?: number

	/**
	 * undefined
	 */
	BookmarkImgCalc?: string

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Číslo
	 */
	DocumentNumberCalc?: number

	/**
	 * SQLSequenceOrderCalc
	 */
	SQLSequenceOrderCalc?: number

	/**
	 * Datum pro "_ver" pole
	 */
	VersionedFieldDateCalc?: Date

	/**
	 * Datum pro "_ver" pole
	 */
	VersionFieldDateCalc?: Date

	/**
	 * undefined
	 */
	TextHeaderMemoCalc?: string

	/**
	 * undefined
	 */
	TextFooterMemoCalc?: string

	/**
	 * Částka dokladu
	 */
	DocumentAmountCalc?: number

	/**
	 * Saldo
	 */
	AccountBalanceCalc?: number

	/**
	 * Účet
	 */
	DocumentAccountCalc?: string

	/**
	 * Z
	 */
	PostedCalc?: string

	/**
	 * Datum zaúčtování
	 */
	PostedOnCalc?: Date

	/**
	 * Rozdíl v zaúčtování
	 */
	AccountingDocDifferenceCalc?: number

	/**
	 * undefined
	 */
	OwnAddressIdCalc?: number

	/**
	 * undefined
	 */
	OwnAddressNameCalc?: string

	/**
	 * Doklad
	 */
	DocumentIdentificationCalc?: string

	/**
	 * ZR
	 */
	PostedManuallyCalc?: string

	/**
	 * EAN kód
	 */
	BarcodeCalc?: string

	/**
	 * Zaúčtovat bez částky
	 */
	PostZeroAmountBitCalc?: number

	/**
	 * undefined
	 */
	IsReversedExchangeRateViewCalc?: string

	/**
	 * undefined
	 */
	AccAssignmentPostingViewCalc?: string

	/**
	 * Stav
	 */
	CFPotvr?: string

	/**
	 * Odpisy1
	 */
	CFOpr1?: number

	/**
	 * Odpisy2
	 */
	CFOpr2?: number

	/**
	 * Zůstatek1
	 */
	CFZust1?: number

	/**
	 * Zůstatek2
	 */
	CFZust2?: number

	/**
	 * FldAktCena1
	 */
	CFAktC1?: number

	/**
	 * FldAktCena2
	 */
	CFAktC2?: number

	/**
	 * Akt.datum1
	 */
	CFAktD1?: Date

	/**
	 * Akt.datum2
	 */
	CFAktD2?: Date

	/**
	 * undefined
	 */
	BMOdepsan?: string

	/**
	 * P
	 */
	BMPotStoVyr?: string

	/**
	 * S/PS/P
	 */
	BMSoubor?: string

	/**
	 * Další plány
	 */
	BMDalPlan?: string

	/**
	 * Likvidoval
	 */
	CFLikvid?: number

	/**
	 * Datum likvidace
	 */
	CFDLikvid?: Date

	/**
	 * Datum potvrzení
	 */
	CFHlDatP?: Date

	/**
	 * Odpisy1
	 */
	CFKnOpr1?: number

	/**
	 * Odpisy2
	 */
	CFKnOpr2?: number

	/**
	 * Akt.cena1
	 */
	CFKnAktC1?: number

	/**
	 * Akt.cena2
	 */
	CFKnAktC2?: number

	/**
	 * Akt.datum1
	 */
	CFKnAktD1?: Date

	/**
	 * Akt.datum2
	 */
	CFKnAktD2?: Date

	/**
	 * Č.hl.
	 */
	CFHoldCislo?: number

	/**
	 * Roční odp.1
	 */
	CFKnRoc1?: number

	/**
	 * Roční odp.2
	 */
	CFKnRoc2?: number

	/**
	 * Měsíční odp.1
	 */
	CFKnMes1?: number

	/**
	 * Měsíční odp.2
	 */
	CFKnMes2?: number

	/**
	 * Jaz.název
	 */
	LanguageTextCalc?: string

	/**
	 * Inv.číslo
	 */
	CFInvCis?: string

	/**
	 * Korekce oprávek 1.pl.
	 */
	CFOprSt1?: number

	/**
	 * Korekce oprávek 2.pl.
	 */
	CFOprSt2?: number

	/**
	 * Příjmení
	 */
	CFKnPri?: string

	/**
	 * Výpočtový typ plánu 1
	 */
	CFTypPla1?: number

	/**
	 * Výpočtový typ plánu 2
	 */
	CFTypPla2?: number

	/**
	 * Rok odpisu 1
	 */
	CFYear1?: number

	/**
	 * Rok odpisu 2
	 */
	CFYear2?: number

	/**
	 * Referent
	 */
	CFPers?: string

	/**
	 * Změněn 1. plán
	 */
	BMChange1?: string

	/**
	 * Změněn 2. plán
	 */
	BMChange2?: string

	/**
	 * Zaúčtováno1
	 */
	CFKnAcc1?: number

	/**
	 * Zaúčtováno2
	 */
	CFKnAcc2?: number

	/**
	 * Datum posl. odpisu1
	 */
	CFDPO1?: Date

	/**
	 * Datum posl. odpisu2
	 */
	CFDPO2?: Date

	/**
	 * Datum posl. zaúčt.1
	 */
	CFDLA1?: Date

	/**
	 * Datum posl. zaúčt.2
	 */
	CFDLA2?: Date

	/**
	 * Měna firmy
	 */
	CompanyCurrencyCalc?: string

	/**
	 * Zůstatek 1
	 */
	BalanceAcceptDisp1Calc?: number

	/**
	 * Zůstatek 2
	 */
	BalanceAcceptDisp2Calc?: number

	/**
	 * Daň
	 */
	TaxCalc?: number

	/**
	 * Daň. informace
	 */
	TaxInfoCalc?: string

	/**
	 * CZ-CPA_ver
	 */
	ProductionClassificationRID_ver?: number

	/**
	 * Crc2
	 */
	RightGroupIdCalc?: number

	/**
	 * Vytvořil
	 */
	@Type(() => TK2UserLookupDM)
	CreationWho?: TK2UserLookupDM

	/**
	 * Stav
	 */
	@Type(() => ZarStatus)
	Status?: ZarStatus

	/**
	 * Způsob pořízení
	 */
	@Type(() => PurchaseWay)
	PurchaseWayId?: PurchaseWay

	/**
	 * Odp. skupina
	 */
	@Type(() => TypDeprGr)
	DeprGrId?: TypDeprGr

	/**
	 * Druh účtování
	 */
	@Type(() => AccKindPurch)
	AccKindId?: AccKindPurch

	/**
	 * Funkční typ
	 */
	@Type(() => FunctType)
	FTypeId?: FunctType

	/**
	 * Typ2
	 */
	@Type(() => TypPlanuZar)
	Typ2?: TypPlanuZar

	/**
	 * Typ1
	 */
	@Type(() => TypPlanuZar)
	Typ1?: TypPlanuZar

	/**
	 * TypVyr
	 */
	@Type(() => TypVyr)
	TypVyr?: TypVyr

	/**
	 * Změnil
	 */
	@Type(() => TK2UserLookupDM)
	DPZ_Kdo?: TK2UserLookupDM

	/**
	 * Skup
	 */
	@Type(() => MajSkup)
	Skup?: MajSkup

	/**
	 * Konstrukční typ
	 */
	@Type(() => ConstrType)
	Kod?: ConstrType

	/**
	 * Druh
	 */
	@Type(() => TOldBookDM)
	MAJETEK?: TOldBookDM

	/**
	 * Období
	 */
	@Type(() => TPeriodDM)
	BusinessYearIdCalc?: TPeriodDM

	/**
	 * D
	 */
	@Type(() => TTextDM)
	DocumentExistsCalc?: TTextDM

	/**
	 * undefined
	 */
	@Type(() => TMarketingActivityTypeDM)
	ActivityTypeRIDCalc?: TMarketingActivityTypeDM

	/**
	 * SP
	 */
	@Type(() => TTextDM)
	ProcessStateIdCalc?: TTextDM

	/**
	 * Aktuální proces
	 */
	@Type(() => TWorkflowProcessDM)
	CurrentProcessIdCalc?: TWorkflowProcessDM

	/**
	 * P
	 */
	@Type(() => TTextDM)
	ConfirmedOrCanceledIdCalc?: TTextDM

	/**
	 * Zaúčtoval
	 */
	@Type(() => TK2UserLookupDM)
	PostedByIdCalc?: TK2UserLookupDM

	/**
	 * undefined
	 */
	@Type(() => TTradingPartnerDM)
	OwnTradingPartnerIdCalc?: TTradingPartnerDM

	/**
	 * Storno
	 */
	@Type(() => TTextDM)
	CanceledRecordBitCalc?: TTextDM

	/**
	 * Příznak Z
	 */
	@Type(() => TTextDM)
	CorrectPostingBitCalc?: TTextDM

	/**
	 * Období
	 */
	@Type(() => TPeriodDM)
	CurrentBusinessYearIdCalc?: TPeriodDM

	/**
	 * Sklad
	 */
	@Type(() => TWarehouseDM)
	CurrentWarehouseIdCalc?: TWarehouseDM

	/**
	 * Rok1
	 */
	@Type(() => TTextDM)
	CFRok1?: TTextDM

	/**
	 * Rok2
	 */
	@Type(() => TTextDM)
	CFRok2?: TTextDM

	/**
	 * Číslo úč.dokl.
	 */
	@Type(() => TAccountingJournalDM)
	CFUcDok?: TAccountingJournalDM

	/**
	 * S
	 */
	@Type(() => TTextDM)
	CFPotStorno?: TTextDM

	/**
	 * A Perioda od
	 */
	@Type(() => TPeriodListDM)
	CFPerOd?: TPeriodListDM

	/**
	 * A Perioda do
	 */
	@Type(() => TPeriodListDM)
	CFPerDo?: TPeriodListDM

	/**
	 * Odepsán 1. plán
	 */
	@Type(() => TTextDM)
	CFOdep1?: TTextDM

	/**
	 * Odepsán 2. plán
	 */
	@Type(() => TTextDM)
	CFOdep2?: TTextDM

	/**
	 * Odepsány oba plány
	 */
	@Type(() => TTextDM)
	CFOdep?: TTextDM

	/**
	 * Referent
	 */
	@Type(() => TOfficerDocumentDM)
	CFCPrij?: TOfficerDocumentDM

	/**
	 * Středisko
	 */
	@Type(() => TCostCentreCollectionDocumentDM)
	CFKnStr?: TCostCentreCollectionDocumentDM

	/**
	 * Umístění
	 */
	@Type(() => ZarUmisteni)
	CFKnUmi?: ZarUmisteni

	/**
	 * Pracoviště
	 */
	@Type(() => Pracoviste)
	CFKnPra?: Pracoviste

	/**
	 * Kód zakázky
	 */
	@Type(() => TContractCodeDocumentDM)
	CFKnZak?: TContractCodeDocumentDM

	/**
	 * Kód zboží
	 */
	@Type(() => TArticleCodeDocumentDM)
	CFKnZbo?: TArticleCodeDocumentDM

	/**
	 * Kód 1
	 */
	@Type(() => TCode1DocumentDM)
	CFKnKod1?: TCode1DocumentDM

	/**
	 * Kód 2
	 */
	@Type(() => TCode2DocumentDM)
	CFKnKod2?: TCode2DocumentDM

	/**
	 * Pár. symbol
	 */
	@Type(() => TMatchingSymbolDM)
	CFKnPS?: TMatchingSymbolDM

	/**
	 * Zákazník
	 */
	@Type(() => TTradingPartnerDM)
	CFKnCdo?: TTradingPartnerDM

	/**
	 * Prostředek
	 */
	@Type(() => TDeviceDocumentDM)
	DeviceCalc?: TDeviceDocumentDM

	/**
	 * Účet pořízení
	 */
	@Type(() => TAccountingChartDM)
	CFAccountPurch?: TAccountingChartDM

	/**
	 * Druh stavby
	 */
	@Type(() => KindBuild)
	KindBuildCalc?: KindBuild

	/**
	 * Druh pozemku
	 */
	@Type(() => KindParcel)
	KindParcelCalc?: KindParcel

	/**
	 * Katastrální území
	 */
	@Type(() => CadTerritory)
	CadTerritoryCalc?: CadTerritory

	/**
	 * Kód 3
	 */
	@Type(() => TCode3DocumentDM)
	CFKnKod3?: TCode3DocumentDM

	/**
	 * Kód 4
	 */
	@Type(() => TCode4DocumentDM)
	CFKnKod4?: TCode4DocumentDM

	/**
	 * Kód 5
	 */
	@Type(() => TCode5DocumentDM)
	CFKnKod5?: TCode5DocumentDM

	/**
	 * Kód 6
	 */
	@Type(() => TCode6DocumentDM)
	CFKnKod6?: TCode6DocumentDM

	/**
	 * Úkoly
	 */
	@Type(() => TJobDM)
	JobChild?: TJobDM[]

	/**
	 * Dokumenty
	 */
	@Type(() => TExternalDocumentItemDM)
	ExternalDocumentItemChild?: TExternalDocumentItemDM[]

	/**
	 * Procesy
	 */
	@Type(() => TWorkflowProcessAsItemDM)
	WorkflowProcessChild?: TWorkflowProcessAsItemDM[]

	/**
	 * Text hlavičky
	 */
	@Type(() => TLanguageMemoTranslationDM)
	TextHeaderChild?: TLanguageMemoTranslationDM[]

	/**
	 * Text patičky
	 */
	@Type(() => TLanguageMemoTranslationDM)
	TextFooterChild?: TLanguageMemoTranslationDM[]

	/**
	 * undefined
	 */
	@Type(() => TDocumentAccountingJournalItemDM)
	DocumentAccJournalItemChild?: TDocumentAccountingJournalItemDM[]

	/**
	 * Položky majetku
	 */
	@Type(() => TAssetLinkItemDM)
	AssetLinkItemChild?: TAssetLinkItemDM[]

	/**
	 * TD_ZarPC
	 */
	@Type(() => TD_ZarPC)
	AssetAccountingItemOldChild?: TD_ZarPC[]

	/**
	 * Položky majetku - daňové (1. plán)
	 */
	@Type(() => TD_ZarPM1)
	AssetPlan1ItemOldChild?: TD_ZarPM1[]

	/**
	 * Položky majetku - účetní (2. plán)
	 */
	@Type(() => TD_ZarPM2)
	AssetPlan2ItemOldChild?: TD_ZarPM2[]

	/**
	 * Svázané doklady
	 */
	@Type(() => TAttachedDocumentDM)
	AttachedDocumentOldChild?: TAttachedDocumentDM[]

	/**
	 * Poznámky
	 */
	@Type(() => TOldNoteDM)
	DeprecatedNoteOldChild?: TOldNoteDM[]

	/**
	 * Částečné potvrzení
	 */
	public PartialConfirmCOMMAND() {
		return this.executeCommand('PartialConfirmCOMMAND')
	}

	/**
	 * Částečné odpotvrzení
	 */
	public PartialUnconfirmCOMMAND() {
		return this.executeCommand('PartialUnconfirmCOMMAND')
	}

	/**
	 * Částečné potvrzení
	 */
	public BulkPartialConfirmCOMMAND() {
		return this.executeCommand('BulkPartialConfirmCOMMAND')
	}

	/**
	 * Částečné odpotvrzení
	 */
	public BulkPartialUnconfirmCOMMAND() {
		return this.executeCommand('BulkPartialUnconfirmCOMMAND')
	}

	/**
	 * Přecenění k rozvahovému dni
	 */
	public BulkRevaluationAtBalanceDayCOMMAND() {
		return this.executeCommand('BulkRevaluationAtBalanceDayCOMMAND')
	}

	/**
	 * Storno přecenění k rozvahovému dni
	 */
	public BulkCancelRevaluationCOMMAND() {
		return this.executeCommand('BulkCancelRevaluationCOMMAND')
	}

	/**
	 * Změna DUZP
	 */
	public InvoiceDateChangeCOMMAND(parameters: TInvoiceDateChangeCOMMANDParams) {
		return this.executeCommand('InvoiceDateChangeCOMMAND', parameters)
	}

	/**
	 * Zaúčtování s likvidací
	 */
	public AccountAssignmentPostingCOMMAND(parameters: TAccountAssignmentPostingCOMMANDParams) {
		return this.executeCommand('AccountAssignmentPostingCOMMAND', parameters)
	}

	/**
	 * Hromadné zaúčtování s likvidací
	 */
	public BulkAccountAssignmentPostingCOMMAND(parameters: TBulkAccountAssignmentPostingCOMMANDParams) {
		return this.executeCommand('BulkAccountAssignmentPostingCOMMAND', parameters)
	}

	/**
	 * Likvidace
	 */
	public AccountAssignmentCOMMAND() {
		return this.executeCommand('AccountAssignmentCOMMAND')
	}

	/**
	 * Zapnout rychlý filtr
	 */
	public FastFilterSwitchCOMMAND() {
		return this.executeCommand('FastFilterSwitchCOMMAND')
	}

	/**
	 * Povolit editaci
	 */
	public EditModeIDNoCOMMAND() {
		return this.executeCommand('EditModeIDNoCOMMAND')
	}

	/**
	 * Povolit editaci
	 */
	public EditModeVATRegisterNoCOMMAND() {
		return this.executeCommand('EditModeVATRegisterNoCOMMAND')
	}

	/**
	 * Povolit editaci
	 */
	public EditModeTaxNoCOMMAND() {
		return this.executeCommand('EditModeTaxNoCOMMAND')
	}

	/**
	 * Změna kontace
	 */
	public BulkChangePostingKeyCOMMAND(parameters: TBulkChangePostingKeyCOMMANDParams) {
		return this.executeCommand('BulkChangePostingKeyCOMMAND', parameters)
	}

	/**
	 * Aktualizace Plánovaného data zaplacení dle Prodlení Dod./Odb.
	 */
	public BulkUpdatePlannedDateOfPaymentByAvgDelayCOMMAND() {
		return this.executeCommand('BulkUpdatePlannedDateOfPaymentByAvgDelayCOMMAND')
	}

	/**
	 * Zobrazovat data od období: %s
	 */
	public ApplyLowerLimitForData() {
		return this.executeCommand('ApplyLowerLimitForData')
	}

	/**
	 * Zrušení stavu
	 */
	public ClearRecordStatusCOMMAND() {
		return this.executeCommand('ClearRecordStatusCOMMAND')
	}

	/**
	 * Změna stavu
	 */
	public ChangeRecordStatusCOMMAND() {
		return this.executeCommand('ChangeRecordStatusCOMMAND')
	}

	/**
	 * 
	 */
	public ChangeRecordStatusSWSCOMMAND(parameters: TChangeRecordStatusSWSCOMMANDParams) {
		return this.executeCommand('ChangeRecordStatusSWSCOMMAND', parameters)
	}

	/**
	 * Zrušení stavu
	 */
	public BulkClearRecordStatusCOMMAND() {
		return this.executeCommand('BulkClearRecordStatusCOMMAND')
	}

	/**
	 * Změna stavu
	 */
	public BulkChangeRecordStatusCOMMAND() {
		return this.executeCommand('BulkChangeRecordStatusCOMMAND')
	}

	/**
	 * Změna atributů
	 */
	public BulkChangeAttributesCOMMAND(parameters: TBulkChangeAttributesCOMMANDParams) {
		return this.executeCommand('BulkChangeAttributesCOMMAND', parameters)
	}

	/**
	 * Aktualizace sady atributů
	 */
	public BulkActualizeAttributesCOMMAND(parameters: TBulkActualizeAttributesCOMMANDParams) {
		return this.executeCommand('BulkActualizeAttributesCOMMAND', parameters)
	}

	/**
	 * Aktualizace sady atributů
	 */
	public ActualizeAttributesCOMMAND() {
		return this.executeCommand('ActualizeAttributesCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public ChangeGroupRightCOMMAND() {
		return this.executeCommand('ChangeGroupRightCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public BulkChangeGroupRightCOMMAND() {
		return this.executeCommand('BulkChangeGroupRightCOMMAND')
	}

	/**
	 * Aktualizace počítaných polí
	 */
	public BulkUpdateCalcFieldsCOMMAND() {
		return this.executeCommand('BulkUpdateCalcFieldsCOMMAND')
	}

	/**
	 * Kontrola legislativy
	 */
	public SwitchOffLegislationValidationCOMMAND() {
		return this.executeCommand('SwitchOffLegislationValidationCOMMAND')
	}
}

export type TInvoiceDateChangeCOMMANDParams = {
	/**
	 * VATDate
	 */
	VATDate?: Date
}

export type TAccountAssignmentPostingCOMMANDParams = {
	/**
	 * Zaúčtuje
	 */
	ACCPosting?: number
	/**
	 * Kniha
	 */
	ACCBook?: number
	/**
	 * Období
	 */
	ACCBussinessYear?: number
	/**
	 * Datum zaúčtování
	 */
	ACCPostingDate?: Date
	/**
	 * Datum od
	 */
	ACCDateFrom?: Date
	/**
	 * Datum do
	 */
	ACCDateTo?: Date
	/**
	 * Zachovat ručně změněné záznamy
	 */
	ACCHandChange?: boolean
	/**
	 * Likvidace bez částky
	 */
	ACCNulAmount?: boolean
	/**
	 * Povolit zaúčtovat doklady s jiným obdobím
	 */
	ACCOtherBusinessYear?: boolean
	/**
	 * Zaúčtovat nevyrovnané saldo
	 */
	ACCBalance?: boolean
}

export type TBulkAccountAssignmentPostingCOMMANDParams = {
	/**
	 * Zaúčtuje
	 */
	ACCPosting?: number
	/**
	 * Kniha
	 */
	ACCBook?: number
	/**
	 * Období
	 */
	ACCBussinessYear?: number
	/**
	 * Datum zaúčtování
	 */
	ACCPostingDate?: Date
	/**
	 * Datum od
	 */
	ACCDateFrom?: Date
	/**
	 * Datum do
	 */
	ACCDateTo?: Date
	/**
	 * Zachovat ručně změněné záznamy
	 */
	ACCHandChange?: boolean
	/**
	 * Likvidace bez částky
	 */
	ACCNulAmount?: boolean
	/**
	 * Povolit zaúčtovat doklady s jiným obdobím
	 */
	ACCOtherBusinessYear?: boolean
	/**
	 * Zaúčtovat nevyrovnané saldo
	 */
	ACCBalance?: boolean
}

export type TBulkChangePostingKeyCOMMANDParams = {
	/**
	 * Kontace
	 */
	Header_PostingKeyX?: boolean
	/**
	 * Header_PostingKey
	 */
	Header_PostingKey?: number
	/**
	 * Účet
	 */
	Header_AccountX?: boolean
	/**
	 * Header_Account
	 */
	Header_Account?: number
	/**
	 * Účet D
	 */
	Header_CreditAcX?: boolean
	/**
	 * Header_CreditAc
	 */
	Header_CreditAc?: number
	/**
	 * Účet MD
	 */
	Header_DebitAcX?: boolean
	/**
	 * Header_DebitAc
	 */
	Header_DebitAc?: number
	/**
	 * Parovací symbol
	 */
	Header_MatchSymbolX?: boolean
	/**
	 * Header_MatchSymbol
	 */
	Header_MatchSymbol?: number
	/**
	 * Středisko
	 */
	Header_CostCentreX?: boolean
	/**
	 * Header_CostCentre
	 */
	Header_CostCentre?: number
	/**
	 * Ze třediska
	 */
	Header_FromCostCentreX?: boolean
	/**
	 * Header_FromCostCentre
	 */
	Header_FromCostCentre?: number
	/**
	 * Na středisko
	 */
	Header_ToCostCentreX?: boolean
	/**
	 * Header_ToCostCentre
	 */
	Header_ToCostCentre?: number
	/**
	 * Kód zakázky
	 */
	Header_ContractCodeX?: boolean
	/**
	 * Header_ContractCode
	 */
	Header_ContractCode?: number
	/**
	 * Z kódu zakázky
	 */
	Header_FromContractCodeX?: boolean
	/**
	 * Header_FromContractCode
	 */
	Header_FromContractCode?: number
	/**
	 * Na kód zakázky
	 */
	Header_ToContractCodeX?: boolean
	/**
	 * Header_ToContractCode
	 */
	Header_ToContractCode?: number
	/**
	 * Prostředek
	 */
	Header_DeviceX?: boolean
	/**
	 * Header_Device
	 */
	Header_Device?: number
	/**
	 * Z prostředku
	 */
	Header_FromDeviceX?: boolean
	/**
	 * Header_FromDevice
	 */
	Header_FromDevice?: number
	/**
	 * Na prostředek
	 */
	Header_ToDeviceX?: boolean
	/**
	 * Header_ToDevice
	 */
	Header_ToDevice?: number
	/**
	 * Referent
	 */
	Header_OfficerX?: boolean
	/**
	 * Header_Officer
	 */
	Header_Officer?: number
	/**
	 * Z referenta
	 */
	Header_FromOfficerX?: boolean
	/**
	 * Header_FromOfficer
	 */
	Header_FromOfficer?: number
	/**
	 * Na referenta
	 */
	Header_ToOfficerX?: boolean
	/**
	 * Header_ToOfficer
	 */
	Header_ToOfficer?: number
	/**
	 * Kód zboží
	 */
	Header_ArticleCodeX?: boolean
	/**
	 * Header_ArticleCode
	 */
	Header_ArticleCode?: number
	/**
	 * Kód 1
	 */
	Header_Code1X?: boolean
	/**
	 * Header_Code1
	 */
	Header_Code1?: number
	/**
	 * Z kódu 1
	 */
	Header_FromCode1X?: boolean
	/**
	 * Header_FromCode1
	 */
	Header_FromCode1?: number
	/**
	 * Na kód 1
	 */
	Header_ToCode1X?: boolean
	/**
	 * Header_ToCode1
	 */
	Header_ToCode1?: number
	/**
	 * Kód 2
	 */
	Header_Code2X?: boolean
	/**
	 * Header_Code2
	 */
	Header_Code2?: number
	/**
	 * Z kódu 2
	 */
	Header_FromCode2X?: boolean
	/**
	 * Header_FromCode2
	 */
	Header_FromCode2?: number
	/**
	 * Na kód 2
	 */
	Header_ToCode2X?: boolean
	/**
	 * Header_ToCode2
	 */
	Header_ToCode2?: number
	/**
	 * Kód 3
	 */
	Header_Code3X?: boolean
	/**
	 * Header_Code3
	 */
	Header_Code3?: number
	/**
	 * Z kódu 3
	 */
	Header_FromCode3X?: boolean
	/**
	 * Header_FromCode3
	 */
	Header_FromCode3?: number
	/**
	 * Na kód 3
	 */
	Header_ToCode3X?: boolean
	/**
	 * Header_ToCode3
	 */
	Header_ToCode3?: number
	/**
	 * Kód 4
	 */
	Header_Code4X?: boolean
	/**
	 * Header_Code4
	 */
	Header_Code4?: number
	/**
	 * Z kódu 4
	 */
	Header_FromCode4X?: boolean
	/**
	 * Header_FromCode4
	 */
	Header_FromCode4?: number
	/**
	 * Na kód 4
	 */
	Header_ToCode4X?: boolean
	/**
	 * Header_ToCode4
	 */
	Header_ToCode4?: number
	/**
	 * Kód 5
	 */
	Header_Code5X?: boolean
	/**
	 * Header_Code5
	 */
	Header_Code5?: number
	/**
	 * Z kódu 5
	 */
	Header_FromCode5X?: boolean
	/**
	 * Header_FromCode5
	 */
	Header_FromCode5?: number
	/**
	 * Na kód 5
	 */
	Header_ToCode5X?: boolean
	/**
	 * Header_ToCode5
	 */
	Header_ToCode5?: number
	/**
	 * Kód 6
	 */
	Header_Code6X?: boolean
	/**
	 * Header_Code6
	 */
	Header_Code6?: number
	/**
	 * Z kódu 6
	 */
	Header_FromCode6X?: boolean
	/**
	 * Header_FromCode6
	 */
	Header_FromCode6?: number
	/**
	 * Na kód 6
	 */
	Header_ToCode6X?: boolean
	/**
	 * Header_ToCode6
	 */
	Header_ToCode6?: number
	/**
	 * Ignorovat cenu příjmu
	 */
	Header_IgnorePriceIncomX?: boolean
	/**
	 * Header_IgnorePriceIncom
	 */
	Header_IgnorePriceIncom?: number
	/**
	 * Header_ShowIgnorePriceIncom
	 */
	Header_ShowIgnorePriceIncom?: boolean
	/**
	 * Kalk.vz.plán.
	 */
	Header_PlannedPriceCMX?: boolean
	/**
	 * Header_PlannedPriceCM
	 */
	Header_PlannedPriceCM?: number
	/**
	 * Kalk.vz.sklad.
	 */
	Header_StockPriceCMX?: boolean
	/**
	 * Header_StockPriceCM
	 */
	Header_StockPriceCM?: number
	/**
	 * Kalk.vz.nákl.
	 */
	Header_CostPriceCMX?: boolean
	/**
	 * Header_CostPriceCM
	 */
	Header_CostPriceCM?: number
	/**
	 * Kontace faktury
	 */
	Item_PostingKeyIX?: boolean
	/**
	 * Item_PostingKeyI
	 */
	Item_PostingKeyI?: number
	/**
	 * Kontace skladového dokladu
	 */
	Item_PostingKeySX?: boolean
	/**
	 * Item_PostingKeyS
	 */
	Item_PostingKeyS?: number
	/**
	 * Kontace rezervačního listu
	 */
	Item_PostingKeyRX?: boolean
	/**
	 * Item_PostingKeyR
	 */
	Item_PostingKeyR?: number
	/**
	 * Kód zakázky
	 */
	Item_ContractCodeX?: boolean
	/**
	 * Item_ContractCode
	 */
	Item_ContractCode?: number
	/**
	 * Kód zakázky 2
	 */
	Item_ContractCode2X?: boolean
	/**
	 * Item_ContractCode2
	 */
	Item_ContractCode2?: number
	/**
	 * Středisko
	 */
	Item_CostCentreX?: boolean
	/**
	 * Item_CostCentre
	 */
	Item_CostCentre?: number
	/**
	 * Středisko 2
	 */
	Item_CostCentre2X?: boolean
	/**
	 * Item_CostCentre2
	 */
	Item_CostCentre2?: number
	/**
	 * Dosadit z karty zboží
	 */
	Item_CostCentreG?: boolean
	/**
	 * Kód zboží
	 */
	Item_ArticleCodeX?: boolean
	/**
	 * Item_ArticleCode
	 */
	Item_ArticleCode?: number
	/**
	 * Dosadit z karty zboží
	 */
	Item_ArticleCodeG?: boolean
	/**
	 * Kód 1
	 */
	Item_Code1X?: boolean
	/**
	 * Item_Code1
	 */
	Item_Code1?: number
	/**
	 * Kód 2
	 */
	Item_Code2X?: boolean
	/**
	 * Item_Code2
	 */
	Item_Code2?: number
	/**
	 * Kód 3
	 */
	Item_Code3X?: boolean
	/**
	 * Item_Code3
	 */
	Item_Code3?: number
	/**
	 * Kód 4
	 */
	Item_Code4X?: boolean
	/**
	 * Item_Code4
	 */
	Item_Code4?: number
	/**
	 * Kód 5
	 */
	Item_Code5X?: boolean
	/**
	 * Item_Code5
	 */
	Item_Code5?: number
	/**
	 * Kód 6
	 */
	Item_Code6X?: boolean
	/**
	 * Item_Code6
	 */
	Item_Code6?: number
	/**
	 * Referent
	 */
	Item_OfficerX?: boolean
	/**
	 * Item_Officer
	 */
	Item_Officer?: number
	/**
	 * Prostředek
	 */
	Item_DeviceX?: boolean
	/**
	 * Item_Device
	 */
	Item_Device?: number
	/**
	 * Parovací symbol
	 */
	Item_MatchSymbolX?: boolean
	/**
	 * Item_MatchSymbol
	 */
	Item_MatchSymbol?: number
	/**
	 * Účet
	 */
	Item_AccountX?: boolean
	/**
	 * Item_Account
	 */
	Item_Account?: number
	/**
	 * Kontace
	 */
	Item_PostingKeyX?: boolean
	/**
	 * Item_PostingKey
	 */
	Item_PostingKey?: number
	/**
	 * Účet D
	 */
	Item_CreditAcX?: boolean
	/**
	 * Item_CreditAc
	 */
	Item_CreditAc?: number
	/**
	 * Účet MD
	 */
	Item_DebitAcX?: boolean
	/**
	 * Item_DebitAc
	 */
	Item_DebitAc?: number
	/**
	 * Podle kontejneru zboží
	 */
	GoodsContainer?: boolean
	/**
	 * S odmazáním z kontejneru
	 */
	DeleteFromContainer?: boolean
	/**
	 * Pouze u prázdných
	 */
	OnlyEmpty?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TChangeRecordStatusSWSCOMMANDParams = {
	/**
	 * StatusId
	 */
	StatusId?: number
}

export type TBulkChangeAttributesCOMMANDParams = {
	/**
	 * Smazat atributy
	 */
	DeleteAttribute?: boolean
	/**
	 * Smazat všechny volné atributy
	 */
	DeleteFreeAttribute?: boolean
	/**
	 * Nastavit hodnoty atributů
	 */
	SetValues?: boolean
	/**
	 * Přidat nepřipojené atributy
	 */
	AddFreeAttributes?: boolean
	/**
	 * FreeAttributeEnabled
	 */
	FreeAttributeEnabled?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TBulkActualizeAttributesCOMMANDParams = {
	/**
	 * Aktualizovat včetně samotné sady atributů
	 */
	ActualizeAttributeSet?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}