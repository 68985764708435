import { SyntheticEvent, useState } from "react"
import { Button, FormControl, InputGroup, Modal } from "react-bootstrap"
import { useGlobalModalContext } from "./GlobalModal"

export type HandleNumericKeyboardClose = (value: string | undefined) => void

export type NumericKeyboardModalProps = {
    handleClose: HandleNumericKeyboardClose
    defaultValue?: string
    inputGroupText?: string
    allowLeadingZero?: boolean
}

export const NumericKeyboardModal = () => {
    const { hideModal, store } = useGlobalModalContext()
    const { handleClose, defaultValue, inputGroupText, allowLeadingZero = false } = store.modalProps as NumericKeyboardModalProps
    const [value, setValue] = useState(defaultValue ? defaultValue : "")
    const handleClick = (e: SyntheticEvent) => {
        const newChar = (e.target as HTMLButtonElement).dataset.value
        const newValue = !allowLeadingZero && value === "0" ? (newChar === "," ? `${value}${newChar}` : `${newChar}`) : `${value}${newChar}`
        setValue(newValue)
    }

    const handleChange = (e: SyntheticEvent) => {
        setValue((e.target as HTMLInputElement).value)
    }

    const handleDelete = () => {
        if (value) {
            setValue(value.slice(0, -1))
        }
    }

    const handleClear = () => {
        setValue("")
    }

    return (
        <Modal
            show={true}
            backdrop="static"
            keyboard={false}
            onHide={() => {
                hideModal()
                handleClose(value)
            }}
        >
            <Modal.Header>
                {inputGroupText ?
                    <InputGroup>
                        <FormControl
                            type="text"
                            value={value}
                            onChange={handleChange}
                        />
                        <InputGroup.Text>{inputGroupText}</InputGroup.Text>
                    </InputGroup> :
                    <FormControl
                        type="text"
                        value={value}
                        onChange={handleChange}
                    />
                }
            </Modal.Header>
            <Modal.Body>
                <div className="container">
                    <div className="row">
                        <div className="col-4 p-1">
                            <Button className="w-100" onClick={handleClick} data-value="7">7</Button>
                        </div>
                        <div className="col-4 p-1">
                            <Button className="w-100" onClick={handleClick} data-value="8">8</Button>
                        </div>
                        <div className="col-4 p-1">
                            <Button className="w-100" onClick={handleClick} data-value="9">9</Button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-4 p-1">
                            <Button className="w-100" onClick={handleClick} data-value="4">4</Button>
                        </div>
                        <div className="col-4 p-1">
                            <Button className="w-100" onClick={handleClick} data-value="5">5</Button>
                        </div>
                        <div className="col-4 p-1">
                            <Button className="w-100" onClick={handleClick} data-value="6">6</Button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-4 p-1">
                            <Button className="w-100" onClick={handleClick} data-value="1">1</Button>
                        </div>
                        <div className="col-4 p-1">
                            <Button className="w-100" onClick={handleClick} data-value="2">2</Button>
                        </div>
                        <div className="col-4 p-1">
                            <Button className="w-100" onClick={handleClick} data-value="3">3</Button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-4 p-1">
                            <Button className="w-100" onClick={handleClick} data-value="0">0</Button>
                        </div>
                        <div className="col-4 p-1">
                            <Button className="w-100" onClick={handleClick} data-value=",">,</Button>
                        </div>
                        <div className="col-4 p-1">
                            <Button className="w-100" variant="danger" onClick={handleClear}>CE</Button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-4 p-1">
                            <Button className="w-100" variant="danger" onClick={() => {
                                hideModal()
                                handleClose(undefined)
                            }}>
                                <i className="bi bi-x-lg" />
                            </Button>
                        </div>
                        <div className="col-4 p-1">
                            <Button className="w-100" variant="danger" onClick={handleDelete}><i className="bi bi-backspace" /></Button>
                        </div>
                        <div className="col-4 p-1">
                            <Button className="w-100" variant="success" onClick={() => {
                                hideModal()
                                handleClose(value)
                            }}>
                                <i className="bi bi-check-lg" />
                            </Button>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}