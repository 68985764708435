import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TTextDM from './TTextDM'
import TAssetDM from './TAssetDM'

/**
 * Přiřazené doklady
 */
export default class TAssetConnectedDocumentDM extends Data {
	public static className = 'TAssetConnectedDocumentDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = false

	/**
	 * Popis
	 */
	Description?: string

	/**
	 * Částka
	 */
	Amount?: number

	/**
	 * Datum
	 */
	DocumentDate?: Date

	/**
	 * Číslo položky dokladu
	 */
	DocumentItemRID?: number

	/**
	 * Typ oblasti
	 */
	DocumentTypeItemId?: number

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Číslo dokladu
	 */
	DocumentIdentifierCalc?: string

	/**
	 * undefined
	 */
	SalesItemBindRIDCalc?: number

	/**
	 * undefined
	 */
	PurchaseItemBindRIDCalc?: number

	/**
	 * undefined
	 */
	PaymentItemBindRIDCalc?: number

	/**
	 * Unikátní číslo dokladu
	 */
	DocumentRIDCalc?: number

	/**
	 * undefined
	 */
	OtherLiabilityRIDCalc?: number

	/**
	 * undefined
	 */
	OtherReceivableRIDCalc?: number

	/**
	 * Typ dokladu
	 */
	@Type(() => TTextDM)
	DocumentTypeId?: TTextDM

	/**
	 * Typ položky
	 */
	@Type(() => TTextDM)
	AssetItemTypeId?: TTextDM

	/**
	 * Majetek
	 */
	@Type(() => TAssetDM)
	AssetRID?: TAssetDM
}