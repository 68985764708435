import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TLegislationDm from './TLegislationDm'
import TTextDM from './TTextDM'
import TLanguageTextTranslationDM from './TLanguageTextTranslationDM'

/**
 * Typ daně s legislativou
 */
export default class TLegislationTaxTypeDm extends Data {
	public static className = 'TLegislationTaxTypeDm'
	public static primaryKey = 'Id'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.Id!
	}

	public static isHeader = true

	/**
	 * Lze použít na zálohách
	 */
	CanBeUsedOnAdvance?: string

	/**
	 * Časové razítko
	 */
	TimeStamp?: string

	/**
	 * Právo na záznam
	 */
	RightGroupId?: number

	/**
	 * Text na fakturu
	 */
	InvoiceText?: string

	/**
	 * Použití
	 */
	Application?: string

	/**
	 * Popis
	 */
	Description?: string

	/**
	 * Zkratka
	 */
	Abbr?: string

	/**
	 * Číslo
	 */
	Id?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Jazykový popis
	 */
	LanguageDescriptionCalc?: string

	/**
	 * Jazykový text na fakturu
	 */
	LanguageInvoiceTextCalc?: string

	/**
	 * EET - základ nepodléhající DPH
	 */
	EETNotSubjectVATCalc?: number

	/**
	 * Řádek DPH 1
	 */
	VATRow1Calc?: number

	/**
	 * Řádek DPH 2
	 */
	VATRow2Calc?: number

	/**
	 * Řádek DPH 3
	 */
	VATRow3Calc?: number

	/**
	 * Řádek DPH 4
	 */
	VATRow4Calc?: number

	/**
	 * Řádek DPH 5
	 */
	VATRow5Calc?: number

	/**
	 * Řádek DPH 6
	 */
	VATRow6Calc?: number

	/**
	 * Právo na záznam
	 */
	RightGroupIdCalc?: number

	/**
	 * Legislativa
	 */
	@Type(() => TLegislationDm)
	LegislationId?: TLegislationDm

	/**
	 * Blokováno
	 */
	@Type(() => TTextDM)
	BlockedRecordBitCalc?: TTextDM

	/**
	 * Samovyměření DPH
	 */
	@Type(() => TTextDM)
	VATIdCalc?: TTextDM

	/**
	 * Samovyměření záloh
	 */
	@Type(() => TTextDM)
	VATAdvancesSelfAssessmentIdCalc?: TTextDM

	/**
	 * Režim PDP
	 */
	@Type(() => TTextDM)
	RCSSystemIdCalc?: TTextDM

	/**
	 * Pouze na výstupu
	 */
	@Type(() => TTextDM)
	OutputOnlyBitCalc?: TTextDM

	/**
	 * Legislativa
	 */
	@Type(() => TLegislationDm)
	LegislationIdCalc?: TLegislationDm

	/**
	 * undefined
	 */
	@Type(() => TLanguageTextTranslationDM)
	LanguageDescriptionChild?: TLanguageTextTranslationDM[]

	/**
	 * undefined
	 */
	@Type(() => TLanguageTextTranslationDM)
	LanguageInvoiceTextChild?: TLanguageTextTranslationDM[]

	/**
	 * Změna skupiny práv
	 */
	public ChangeGroupRightCOMMAND() {
		return this.executeCommand('ChangeGroupRightCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public BulkChangeGroupRightCOMMAND() {
		return this.executeCommand('BulkChangeGroupRightCOMMAND')
	}

	/**
	 * Aktualizace počítaných polí
	 */
	public BulkUpdateCalcFieldsCOMMAND() {
		return this.executeCommand('BulkUpdateCalcFieldsCOMMAND')
	}

	/**
	 * Kontrola legislativy
	 */
	public SwitchOffLegislationValidationCOMMAND() {
		return this.executeCommand('SwitchOffLegislationValidationCOMMAND')
	}
}