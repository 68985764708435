import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TrackingStatus from './TrackingStatus'
import ManipulationUnit from './ManipulationUnit'
import TShipmentCollectionDM from './TShipmentCollectionDM'
import TShipmentDM from './TShipmentDM'
import TK2UserLookupDM from './TK2UserLookupDM'
import TTextDM from './TTextDM'
import TShipmentServiceDM from './TShipmentServiceDM'
import TDeliveryNoteDM from './TDeliveryNoteDM'
import TMarketingActivityTypeDM from './TMarketingActivityTypeDM'
import TCommentDM from './TCommentDM'
import TSalesItemBookDM from './TSalesItemBookDM'
import TParcelStatusDM from './TParcelStatusDM'
import TExternalDocumentItemDM from './TExternalDocumentItemDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TAttachedDocumentDM from './TAttachedDocumentDM'
import TOldNoteDM from './TOldNoteDM'

/**
 * Balíky
 */
export default class TParcelDM extends Data {
	public static className = 'TParcelDM'
	public static primaryKey = 'Id'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.Id!
	}

	public static isHeader = true

	/**
	 * Adresa pro sledování
	 */
	TrackingAddress?: string

	/**
	 * Počet MJ
	 */
	ManipulationUnitCount?: number

	/**
	 * SuperiorParcel
	 */
	SuperiorParcelId?: number

	/**
	 * Vytvořeno
	 */
	CreatedOn?: Date

	/**
	 * Změněno
	 */
	ChangedOn?: Date

	/**
	 * Časové razítko
	 */
	TimeStamp?: string

	/**
	 * Právo na záznam
	 */
	RightGroupId?: number

	/**
	 * DocType
	 */
	DocumentTypeId?: number

	/**
	 * XMLField
	 */
	XMLField?: string

	/**
	 * Perioda
	 */
	Period?: string

	/**
	 * OrderX
	 */
	SequenceNumber?: number

	/**
	 * Cena
	 */
	Price?: number

	/**
	 * Výška
	 */
	Height?: number

	/**
	 * Šířka
	 */
	Width?: number

	/**
	 * Délka
	 */
	Length?: number

	/**
	 * Hmotnost
	 */
	Weight?: number

	/**
	 * Hodnota balíku
	 */
	ValueStated?: number

	/**
	 * Kód balíku 4
	 */
	ParcelCode4?: string

	/**
	 * Kód balíku 3
	 */
	ParcelCode3?: string

	/**
	 * Kód balíku 2
	 */
	ParcelCode2?: string

	/**
	 * Kód balíku 1
	 */
	ParcelCode?: number

	/**
	 * Pořadové číslo
	 */
	ServiceSequenceNumber?: number

	/**
	 * Číslo
	 */
	Id?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * SQLSequenceOrderCalc
	 */
	SQLSequenceOrderCalc?: number

	/**
	 * undefined
	 */
	ShipmentStateIdCalc?: number

	/**
	 * undefined
	 */
	ShipmentStateImgCalc?: string

	/**
	 * undefined
	 */
	SwapServiceCalc?: string

	/**
	 * DPZ stavu balíku
	 */
	StatusChangedOnCalc?: Date

	/**
	 * undefined
	 */
	TextHeaderMemoCalc?: string

	/**
	 * undefined
	 */
	TextFooterMemoCalc?: string

	/**
	 * Poslední komentář
	 */
	LastCommentParamCalc?: string

	/**
	 * Právo na záznam
	 */
	RightGroupIdCalc?: number

	/**
	 * Stav přepravy
	 */
	@Type(() => TrackingStatus)
	TrackingStatusId?: TrackingStatus

	/**
	 * Manipulační jednotka
	 */
	@Type(() => ManipulationUnit)
	ManipulationUnitId?: ManipulationUnit

	/**
	 * Svoz
	 */
	@Type(() => TShipmentCollectionDM)
	ShipmentCollectionRID?: TShipmentCollectionDM

	/**
	 * Zásilka
	 */
	@Type(() => TShipmentDM)
	ShipmentRID?: TShipmentDM

	/**
	 * Vytvořil
	 */
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM

	/**
	 * Změnil
	 */
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM

	/**
	 * Stav
	 */
	@Type(() => TTextDM)
	StatusId?: TTextDM

	/**
	 * Jedn. hmotnosti
	 */
	@Type(() => TTextDM)
	WeightUnitId?: TTextDM

	/**
	 * Jedn. rozměru
	 */
	@Type(() => TTextDM)
	DimensionUnitId?: TTextDM

	/**
	 * Služba
	 */
	@Type(() => TShipmentServiceDM)
	ShipmentServiceId?: TShipmentServiceDM

	/**
	 * Dodací list
	 */
	@Type(() => TDeliveryNoteDM)
	DeliveryNoteRID?: TDeliveryNoteDM

	/**
	 * D
	 */
	@Type(() => TTextDM)
	DocumentExistsCalc?: TTextDM

	/**
	 * undefined
	 */
	@Type(() => TMarketingActivityTypeDM)
	ActivityTypeRIDCalc?: TMarketingActivityTypeDM

	/**
	 * Komentáře
	 */
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]

	/**
	 * undefined
	 */
	@Type(() => TSalesItemBookDM)
	DeliveryNoteItemChild?: TSalesItemBookDM[]

	/**
	 * undefined
	 */
	@Type(() => TParcelStatusDM)
	ParcelTrackingStatusChild?: TParcelStatusDM[]

	/**
	 * Dokumenty
	 */
	@Type(() => TExternalDocumentItemDM)
	ExternalDocumentItemChild?: TExternalDocumentItemDM[]

	/**
	 * Text hlavičky
	 */
	@Type(() => TLanguageMemoTranslationDM)
	TextHeaderChild?: TLanguageMemoTranslationDM[]

	/**
	 * Text patičky
	 */
	@Type(() => TLanguageMemoTranslationDM)
	TextFooterChild?: TLanguageMemoTranslationDM[]

	/**
	 * Svázané doklady
	 */
	@Type(() => TAttachedDocumentDM)
	AttachedDocumentOldChild?: TAttachedDocumentDM[]

	/**
	 * Poznámky
	 */
	@Type(() => TOldNoteDM)
	DeprecatedNoteOldChild?: TOldNoteDM[]

	/**
	 * Potvrzení
	 */
	public BulkConfirmCOMMAND() {
		return this.executeCommand('BulkConfirmCOMMAND')
	}

	/**
	 * Odpotvrzení
	 */
	public BulkUnconfirmCOMMAND() {
		return this.executeCommand('BulkUnconfirmCOMMAND')
	}

	/**
	 * Změna data potvrzení
	 */
	public BulkChangeConfirmationDateCOMMAND(parameters: TBulkChangeConfirmationDateCOMMANDParams) {
		return this.executeCommand('BulkChangeConfirmationDateCOMMAND', parameters)
	}

	/**
	 * Storno
	 */
	public BulkCancelCOMMAND() {
		return this.executeCommand('BulkCancelCOMMAND')
	}

	/**
	 * Odstorno
	 */
	public BulkUnCancelCOMMAND() {
		return this.executeCommand('BulkUnCancelCOMMAND')
	}

	/**
	 * Potvrzení
	 */
	public ConfirmCOMMAND() {
		return this.executeCommand('ConfirmCOMMAND')
	}

	/**
	 * Odpotvrzení
	 */
	public UnconfirmCOMMAND() {
		return this.executeCommand('UnconfirmCOMMAND')
	}

	/**
	 * Změna data potvrzení
	 */
	public ChangeConfirmationDateCOMMAND(parameters: TChangeConfirmationDateCOMMANDParams) {
		return this.executeCommand('ChangeConfirmationDateCOMMAND', parameters)
	}

	/**
	 * Storno/Odstorno
	 */
	public CancelCOMMAND() {
		return this.executeCommand('CancelCOMMAND')
	}

	/**
	 * Zrušení stavu
	 */
	public ClearRecordStatusCOMMAND() {
		return this.executeCommand('ClearRecordStatusCOMMAND')
	}

	/**
	 * Změna stavu
	 */
	public ChangeRecordStatusCOMMAND() {
		return this.executeCommand('ChangeRecordStatusCOMMAND')
	}

	/**
	 * 
	 */
	public ChangeRecordStatusSWSCOMMAND(parameters: TChangeRecordStatusSWSCOMMANDParams) {
		return this.executeCommand('ChangeRecordStatusSWSCOMMAND', parameters)
	}

	/**
	 * Zrušení stavu
	 */
	public BulkClearRecordStatusCOMMAND() {
		return this.executeCommand('BulkClearRecordStatusCOMMAND')
	}

	/**
	 * Změna stavu
	 */
	public BulkChangeRecordStatusCOMMAND() {
		return this.executeCommand('BulkChangeRecordStatusCOMMAND')
	}

	/**
	 * Změna atributů
	 */
	public BulkChangeAttributesCOMMAND(parameters: TBulkChangeAttributesCOMMANDParams) {
		return this.executeCommand('BulkChangeAttributesCOMMAND', parameters)
	}

	/**
	 * Aktualizace sady atributů
	 */
	public BulkActualizeAttributesCOMMAND(parameters: TBulkActualizeAttributesCOMMANDParams) {
		return this.executeCommand('BulkActualizeAttributesCOMMAND', parameters)
	}

	/**
	 * Aktualizace sady atributů
	 */
	public ActualizeAttributesCOMMAND() {
		return this.executeCommand('ActualizeAttributesCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public ChangeGroupRightCOMMAND() {
		return this.executeCommand('ChangeGroupRightCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public BulkChangeGroupRightCOMMAND() {
		return this.executeCommand('BulkChangeGroupRightCOMMAND')
	}

	/**
	 * Aktualizace počítaných polí
	 */
	public BulkUpdateCalcFieldsCOMMAND() {
		return this.executeCommand('BulkUpdateCalcFieldsCOMMAND')
	}

	/**
	 * Kontrola legislativy
	 */
	public SwitchOffLegislationValidationCOMMAND() {
		return this.executeCommand('SwitchOffLegislationValidationCOMMAND')
	}
}

export type TBulkChangeConfirmationDateCOMMANDParams = {
	/**
	 * Datum potvrzení
	 */
	Confirmation1Date?: Date
	/**
	 * Hodina potvrzení
	 */
	Confirmation1Time?: Date
	/**
	 * Datum 2. potvrzení
	 */
	Confirmation2Date?: Date
	/**
	 * Hodina 2. potvrzení
	 */
	Confirmation2Time?: Date
	/**
	 * Kontrolovat záporné stavy
	 */
	CheckNegativeState?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TChangeConfirmationDateCOMMANDParams = {
	/**
	 * Datum potvrzení
	 */
	Confirmation1Date?: Date
	/**
	 * Hodina potvrzení
	 */
	Confirmation1Time?: Date
	/**
	 * Datum 2. potvrzení
	 */
	Confirmation2Date?: Date
	/**
	 * Hodina 2. potvrzení
	 */
	Confirmation2Time?: Date
	/**
	 * Kontrolovat záporné stavy
	 */
	CheckNegativeState?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TChangeRecordStatusSWSCOMMANDParams = {
	/**
	 * StatusId
	 */
	StatusId?: number
}

export type TBulkChangeAttributesCOMMANDParams = {
	/**
	 * Smazat atributy
	 */
	DeleteAttribute?: boolean
	/**
	 * Smazat všechny volné atributy
	 */
	DeleteFreeAttribute?: boolean
	/**
	 * Nastavit hodnoty atributů
	 */
	SetValues?: boolean
	/**
	 * Přidat nepřipojené atributy
	 */
	AddFreeAttributes?: boolean
	/**
	 * FreeAttributeEnabled
	 */
	FreeAttributeEnabled?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TBulkActualizeAttributesCOMMANDParams = {
	/**
	 * Aktualizovat včetně samotné sady atributů
	 */
	ActualizeAttributeSet?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}