import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TMeasureUnitDM from './TMeasureUnitDM'
import TK2UserLookupDM from './TK2UserLookupDM'
import TPStatus from './TPStatus'
import TOfficerDocumentDM from './TOfficerDocumentDM'
import TRequestedBatchParameterHeaderDM from './TRequestedBatchParameterHeaderDM'
import TWarehouseDM from './TWarehouseDM'
import TArticleDM from './TArticleDM'
import TTextDM from './TTextDM'
import TMarketingActivityTypeDM from './TMarketingActivityTypeDM'
import TWorkflowProcessDM from './TWorkflowProcessDM'
import TCommentDM from './TCommentDM'
import TRoutingVariantResourceDM from './TRoutingVariantResourceDM'
import TRoutingVariantItemDM from './TRoutingVariantItemDM'
import TRoutingVariantOperationDM from './TRoutingVariantOperationDM'
import TRoutingVariantItemDataFilterDM from './TRoutingVariantItemDataFilterDM'
import TRoutingVariantOperationDataFilterDM from './TRoutingVariantOperationDataFilterDM'
import TRoutingVariantResourceDataFilterDM from './TRoutingVariantResourceDataFilterDM'
import TProductCostingDM from './TProductCostingDM'
import TAttachedRequestedBatchParameterDM from './TAttachedRequestedBatchParameterDM'
import TExternalDocumentItemDM from './TExternalDocumentItemDM'
import TWorkflowProcessAsItemDM from './TWorkflowProcessAsItemDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TAttachedDocumentDM from './TAttachedDocumentDM'
import TOldNoteDM from './TOldNoteDM'

/**
 * Víceúrovňové variantní technologicke postupy
 */
export default class TRoutingVariantDM extends Data {
	public static className = 'TRoutingVariantDM'
	public static primaryKey = 'Id'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.Id!
	}

	public static isHeader = true

	/**
	 * Blokace výběru - obchod
	 */
	BlockTradeDocuments?: string

	/**
	 * Blokace výběru - průvodky
	 */
	BlockJobCards?: string

	/**
	 * InvalidRecord
	 */
	IsInvalidRecord?: string

	/**
	 * Neúplný TP
	 */
	IsIncompleteRouting?: string

	/**
	 * Série do
	 */
	SerieTo?: number

	/**
	 * Série od
	 */
	SerieFrom?: number

	/**
	 * Časové razítko
	 */
	TimeStamp?: string

	/**
	 * Název
	 */
	ArticleName?: string

	/**
	 * Zkratka 2
	 */
	ArticleAbbr2?: string

	/**
	 * Zkratka 1
	 */
	ArticleAbbr1?: string

	/**
	 * Vytvořeno
	 */
	CreatedOn?: Date

	/**
	 * Právo na záznam
	 */
	RightGroupId?: number

	/**
	 * Průběžná doba
	 */
	LeadTime?: number

	/**
	 * Plán. skl. cena/ MJ
	 */
	StockPrice?: number

	/**
	 * Výrobní dávka
	 */
	ProductionLot?: number

	/**
	 * Kalkulační dávka
	 */
	CalculationLot?: number

	/**
	 * Unikát
	 */
	Id?: number

	/**
	 * Datum do
	 */
	ValidTo?: Date

	/**
	 * Datum
	 */
	ValidFrom?: Date

	/**
	 * Hmotn.(kg) / MJ :
	 */
	Weight?: number

	/**
	 * Popis
	 */
	Description?: string

	/**
	 * Počet pol.
	 */
	ItemCount?: number

	/**
	 * Změněno
	 */
	ChangedOn?: Date

	/**
	 * Výkres
	 */
	Drawing?: string

	/**
	 * Množství
	 */
	Quantity?: number

	/**
	 * Varianta
	 */
	Abbr?: string

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * SQLSequenceOrderCalc
	 */
	SQLSequenceOrderCalc?: number

	/**
	 * Datum pro "_ver" pole
	 */
	VersionedFieldDateCalc?: Date

	/**
	 * Hmotnost celkem
	 */
	TotalWeightCalc?: number

	/**
	 * Plán. skl. cena celkem
	 */
	TotalPriceCalc?: number

	/**
	 * undefined
	 */
	FilterKindsBitCalc?: number

	/**
	 * undefined
	 */
	DefaultVariantImgCalc?: string

	/**
	 * undefined
	 */
	NameCalc?: string

	/**
	 * Aktuální TP
	 */
	ActualRoutingVariantNameCalc?: string

	/**
	 * Množství
	 */
	QuantityCalc?: number

	/**
	 * Kalkulační dávka
	 */
	CalculationLotCalc?: number

	/**
	 * Výrobní dávka
	 */
	BatchQuantityCalc?: number

	/**
	 * Implicitní
	 */
	IsImplicitVariantCalc?: string

	/**
	 * OperationTextCalc
	 */
	OperationTextCalc?: string

	/**
	 * undefined
	 */
	TextHeaderMemoCalc?: string

	/**
	 * undefined
	 */
	TextFooterMemoCalc?: string

	/**
	 * Poslední komentář
	 */
	LastCommentParamCalc?: string

	/**
	 * Právo na záznam
	 */
	RightGroupIdCalc?: number

	/**
	 * StockUnit
	 */
	@Type(() => TMeasureUnitDM)
	MeasureUnitId?: TMeasureUnitDM

	/**
	 * Vytvořil
	 */
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM

	/**
	 * Stav
	 */
	@Type(() => TPStatus)
	StatusId?: TPStatus

	/**
	 * Osoba (kusovník)
	 */
	@Type(() => TOfficerDocumentDM)
	BOMOfficerId?: TOfficerDocumentDM

	/**
	 * ReqAttr
	 */
	@Type(() => TRequestedBatchParameterHeaderDM)
	RequestedBatchParametersId?: TRequestedBatchParameterHeaderDM

	/**
	 * Č. skladu
	 */
	@Type(() => TWarehouseDM)
	WarehouseId?: TWarehouseDM

	/**
	 * Osoba (TP)
	 */
	@Type(() => TOfficerDocumentDM)
	OfficerId?: TOfficerDocumentDM

	/**
	 * Změnil
	 */
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM

	/**
	 * Číslo zboží
	 */
	@Type(() => TArticleDM)
	ArticleId?: TArticleDM

	/**
	 * Z
	 */
	@Type(() => TTextDM)
	InvalidRecordImgCalc?: TTextDM

	/**
	 * undefined
	 */
	@Type(() => TMeasureUnitDM)
	StockMeasureUnitAbbrCalc?: TMeasureUnitDM

	/**
	 * D
	 */
	@Type(() => TTextDM)
	DocumentExistsCalc?: TTextDM

	/**
	 * undefined
	 */
	@Type(() => TMarketingActivityTypeDM)
	ActivityTypeRIDCalc?: TMarketingActivityTypeDM

	/**
	 * SP
	 */
	@Type(() => TTextDM)
	ProcessStateIdCalc?: TTextDM

	/**
	 * Aktuální proces
	 */
	@Type(() => TWorkflowProcessDM)
	CurrentProcessIdCalc?: TWorkflowProcessDM

	/**
	 * Komentáře
	 */
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]

	/**
	 * Zdroje
	 */
	@Type(() => TRoutingVariantResourceDM)
	RoutingVariantResourceChild?: TRoutingVariantResourceDM[]

	/**
	 * undefined
	 */
	@Type(() => TRoutingVariantItemDM)
	RoutingVariantItemChild?: TRoutingVariantItemDM[]

	/**
	 * Operace
	 */
	@Type(() => TRoutingVariantOperationDM)
	RoutingVariantOperationChild?: TRoutingVariantOperationDM[]

	/**
	 * undefined
	 */
	@Type(() => TRoutingVariantItemDataFilterDM)
	RoutingVariantItemDataFilterChild?: TRoutingVariantItemDataFilterDM[]

	/**
	 * undefined
	 */
	@Type(() => TRoutingVariantOperationDataFilterDM)
	RoutingVarOpDataFilterChild?: TRoutingVariantOperationDataFilterDM[]

	/**
	 * undefined
	 */
	@Type(() => TRoutingVariantResourceDataFilterDM)
	RoutingVariantResFilterChild?: TRoutingVariantResourceDataFilterDM[]

	/**
	 * undefined
	 */
	@Type(() => TProductCostingDM)
	ProductCostingChild?: TProductCostingDM[]

	/**
	 * Seznam požadovaných parametrů
	 */
	@Type(() => TAttachedRequestedBatchParameterDM)
	RequestedBatchParameterChild?: TAttachedRequestedBatchParameterDM[]

	/**
	 * Dokumenty
	 */
	@Type(() => TExternalDocumentItemDM)
	ExternalDocumentItemChild?: TExternalDocumentItemDM[]

	/**
	 * Procesy
	 */
	@Type(() => TWorkflowProcessAsItemDM)
	WorkflowProcessChild?: TWorkflowProcessAsItemDM[]

	/**
	 * Text hlavičky
	 */
	@Type(() => TLanguageMemoTranslationDM)
	TextHeaderChild?: TLanguageMemoTranslationDM[]

	/**
	 * Text patičky
	 */
	@Type(() => TLanguageMemoTranslationDM)
	TextFooterChild?: TLanguageMemoTranslationDM[]

	/**
	 * Svázané doklady
	 */
	@Type(() => TAttachedDocumentDM)
	AttachedDocumentOldChild?: TAttachedDocumentDM[]

	/**
	 * Poznámky
	 */
	@Type(() => TOldNoteDM)
	DeprecatedNoteOldChild?: TOldNoteDM[]

	/**
	 * Zobrazit vybranou operaci
	 */
	public ShowItemsByOperationCOMMAND() {
		return this.executeCommand('ShowItemsByOperationCOMMAND')
	}

	/**
	 * Zobrazit všechny operace
	 */
	public ShowItemsAllCOMMAND() {
		return this.executeCommand('ShowItemsAllCOMMAND')
	}

	/**
	 * Možnosti TPV
	 */
	public RoutingSettingsCOMMAND(parameters: TRoutingSettingsCOMMANDParams) {
		return this.executeCommand('RoutingSettingsCOMMAND', parameters)
	}

	/**
	 * Kopírování varianty TP
	 */
	public CopyVariantItemsCOMMAND(parameters: TCopyVariantItemsCOMMANDParams) {
		return this.executeCommand('CopyVariantItemsCOMMAND', parameters)
	}

	/**
	 * Hromadná změna položek
	 */
	public BulkChangeItemsJCCOMMAND(parameters: TBulkChangeItemsJCCOMMANDParams) {
		return this.executeCommand('BulkChangeItemsJCCOMMAND', parameters)
	}

	/**
	 * Vytvoření kalkulací
	 */
	public CreateProductCostingCOMMAND(parameters: TCreateProductCostingCOMMANDParams) {
		return this.executeCommand('CreateProductCostingCOMMAND', parameters)
	}

	/**
	 * Vytvoření kalkulací
	 */
	public CreateProductCostingBulkCOMMAND(parameters: TCreateProductCostingBulkCOMMANDParams) {
		return this.executeCommand('CreateProductCostingBulkCOMMAND', parameters)
	}

	/**
	 * Zneplatnění
	 */
	public BulkInvalidationCOMMAND() {
		return this.executeCommand('BulkInvalidationCOMMAND')
	}

	/**
	 * Zplatnění
	 */
	public BulkUnInvalidationCOMMAND() {
		return this.executeCommand('BulkUnInvalidationCOMMAND')
	}

	/**
	 * Zneplatnění/Zplatnění
	 */
	public InvalidationCOMMAND() {
		return this.executeCommand('InvalidationCOMMAND')
	}

	/**
	 * Potvrzení
	 */
	public BulkConfirmCOMMAND() {
		return this.executeCommand('BulkConfirmCOMMAND')
	}

	/**
	 * Odpotvrzení
	 */
	public BulkUnconfirmCOMMAND() {
		return this.executeCommand('BulkUnconfirmCOMMAND')
	}

	/**
	 * Změna data potvrzení
	 */
	public BulkChangeConfirmationDateCOMMAND(parameters: TBulkChangeConfirmationDateCOMMANDParams) {
		return this.executeCommand('BulkChangeConfirmationDateCOMMAND', parameters)
	}

	/**
	 * Storno
	 */
	public BulkCancelCOMMAND() {
		return this.executeCommand('BulkCancelCOMMAND')
	}

	/**
	 * Odstorno
	 */
	public BulkUnCancelCOMMAND() {
		return this.executeCommand('BulkUnCancelCOMMAND')
	}

	/**
	 * Potvrzení
	 */
	public ConfirmCOMMAND() {
		return this.executeCommand('ConfirmCOMMAND')
	}

	/**
	 * Odpotvrzení
	 */
	public UnconfirmCOMMAND() {
		return this.executeCommand('UnconfirmCOMMAND')
	}

	/**
	 * Změna data potvrzení
	 */
	public ChangeConfirmationDateCOMMAND(parameters: TChangeConfirmationDateCOMMANDParams) {
		return this.executeCommand('ChangeConfirmationDateCOMMAND', parameters)
	}

	/**
	 * Storno/Odstorno
	 */
	public CancelCOMMAND() {
		return this.executeCommand('CancelCOMMAND')
	}

	/**
	 * Zrušení stavu
	 */
	public ClearRecordStatusCOMMAND() {
		return this.executeCommand('ClearRecordStatusCOMMAND')
	}

	/**
	 * Změna stavu
	 */
	public ChangeRecordStatusCOMMAND() {
		return this.executeCommand('ChangeRecordStatusCOMMAND')
	}

	/**
	 * 
	 */
	public ChangeRecordStatusSWSCOMMAND(parameters: TChangeRecordStatusSWSCOMMANDParams) {
		return this.executeCommand('ChangeRecordStatusSWSCOMMAND', parameters)
	}

	/**
	 * Zrušení stavu
	 */
	public BulkClearRecordStatusCOMMAND() {
		return this.executeCommand('BulkClearRecordStatusCOMMAND')
	}

	/**
	 * Změna stavu
	 */
	public BulkChangeRecordStatusCOMMAND() {
		return this.executeCommand('BulkChangeRecordStatusCOMMAND')
	}

	/**
	 * Změna atributů
	 */
	public BulkChangeAttributesCOMMAND(parameters: TBulkChangeAttributesCOMMANDParams) {
		return this.executeCommand('BulkChangeAttributesCOMMAND', parameters)
	}

	/**
	 * Aktualizace sady atributů
	 */
	public BulkActualizeAttributesCOMMAND(parameters: TBulkActualizeAttributesCOMMANDParams) {
		return this.executeCommand('BulkActualizeAttributesCOMMAND', parameters)
	}

	/**
	 * Aktualizace sady atributů
	 */
	public ActualizeAttributesCOMMAND() {
		return this.executeCommand('ActualizeAttributesCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public ChangeGroupRightCOMMAND() {
		return this.executeCommand('ChangeGroupRightCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public BulkChangeGroupRightCOMMAND() {
		return this.executeCommand('BulkChangeGroupRightCOMMAND')
	}

	/**
	 * Aktualizace počítaných polí
	 */
	public BulkUpdateCalcFieldsCOMMAND() {
		return this.executeCommand('BulkUpdateCalcFieldsCOMMAND')
	}

	/**
	 * Kontrola legislativy
	 */
	public SwitchOffLegislationValidationCOMMAND() {
		return this.executeCommand('SwitchOffLegislationValidationCOMMAND')
	}
}

export type TRoutingSettingsCOMMANDParams = {
	/**
	 * Zobrazit zkratku ve stromu
	 */
	AbbrInTree?: boolean
	/**
	 * Pro zboží zobrazit Zkratku 2
	 */
	AbbrInTree2?: boolean
	/**
	 * Zobrazit název ve stromu
	 */
	NameInTree?: boolean
	/**
	 * Zobrazit text operace
	 */
	OpeText?: boolean
	/**
	 * Skrýt strom
	 */
	HideTree?: boolean
	/**
	 * Skrýt zdroje
	 */
	HideRes?: boolean
	/**
	 * Nepřenačítat strom
	 */
	NoReload?: boolean
	/**
	 * Úroveň rozpadu stromu
	 */
	ExpandLevel?: number
	/**
	 * Přírůstek pořadí operace
	 */
	IncrementSize?: number
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TCopyVariantItemsCOMMANDParams = {
	/**
	 * Varianta TP
	 */
	VariantId?: number
	/**
	 * Výrobek
	 */
	ArticleId?: number
	/**
	 * Kopírovat operace a zdroje
	 */
	CopyOperations?: boolean
	/**
	 * Kopírovat položky kusovníku
	 */
	CopyItems?: boolean
	/**
	 * Kopírovat pouze označené položky
	 */
	CopySelected?: boolean
	/**
	 * Způsob přidání položek
	 */
	ActionType?: number
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TBulkChangeItemsJCCOMMANDParams = {
	/**
	 * Hlavičkový modul
	 */
	HeaderType?: number
	/**
	 * Položkový modul
	 */
	ItemsType?: number
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TCreateProductCostingCOMMANDParams = {
	/**
	 * Kniha
	 */
	Book?: number
	/**
	 * Dávky (oddělené středníkem)
	 */
	Quantity?: string
	/**
	 * Vytvořit kontejner nových kalkulací
	 */
	NewFilter?: boolean
}

export type TCreateProductCostingBulkCOMMANDParams = {
	/**
	 * Kniha
	 */
	Book?: number
	/**
	 * Dávky (oddělené středníkem)
	 */
	Quantity?: string
	/**
	 * Vytvořit kontejner nových kalkulací
	 */
	NewFilter?: boolean
}

export type TBulkChangeConfirmationDateCOMMANDParams = {
	/**
	 * Datum potvrzení
	 */
	Confirmation1Date?: Date
	/**
	 * Hodina potvrzení
	 */
	Confirmation1Time?: Date
	/**
	 * Datum 2. potvrzení
	 */
	Confirmation2Date?: Date
	/**
	 * Hodina 2. potvrzení
	 */
	Confirmation2Time?: Date
	/**
	 * Kontrolovat záporné stavy
	 */
	CheckNegativeState?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TChangeConfirmationDateCOMMANDParams = {
	/**
	 * Datum potvrzení
	 */
	Confirmation1Date?: Date
	/**
	 * Hodina potvrzení
	 */
	Confirmation1Time?: Date
	/**
	 * Datum 2. potvrzení
	 */
	Confirmation2Date?: Date
	/**
	 * Hodina 2. potvrzení
	 */
	Confirmation2Time?: Date
	/**
	 * Kontrolovat záporné stavy
	 */
	CheckNegativeState?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TChangeRecordStatusSWSCOMMANDParams = {
	/**
	 * StatusId
	 */
	StatusId?: number
}

export type TBulkChangeAttributesCOMMANDParams = {
	/**
	 * Smazat atributy
	 */
	DeleteAttribute?: boolean
	/**
	 * Smazat všechny volné atributy
	 */
	DeleteFreeAttribute?: boolean
	/**
	 * Nastavit hodnoty atributů
	 */
	SetValues?: boolean
	/**
	 * Přidat nepřipojené atributy
	 */
	AddFreeAttributes?: boolean
	/**
	 * FreeAttributeEnabled
	 */
	FreeAttributeEnabled?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TBulkActualizeAttributesCOMMANDParams = {
	/**
	 * Aktualizovat včetně samotné sady atributů
	 */
	ActualizeAttributeSet?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}