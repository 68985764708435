import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import THandlingUnitArticleDM from './THandlingUnitArticleDM'
import TLocationArticleDM from './TLocationArticleDM'
import TBatchDM from './TBatchDM'
import TContractCodeDocumentDM from './TContractCodeDocumentDM'
import TWarehouseDM from './TWarehouseDM'
import TArticleDM from './TArticleDM'
import TTextDM from './TTextDM'

/**
 * WarehouseTransfer
 */
export default class TWarehouseTransferDM extends Data {
	public static className = 'TWarehouseTransferDM'
	public static primaryKey = 'RecordNumber'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RecordNumber!
	}

	public static isHeader = false

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * undefined
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * undefined
	 */
	RecordNumber?: number

	/**
	 * Převedeno
	 */
	TransferredQuantity?: number

	/**
	 * Množ.
	 */
	OriginalQuantity?: number

	/**
	 * undefined
	 */
	SuperiorRID?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Manipul.jednotka
	 */
	@Type(() => THandlingUnitArticleDM)
	HandlingUnitRID?: THandlingUnitArticleDM

	/**
	 * Umístění
	 */
	@Type(() => TLocationArticleDM)
	LocationId?: TLocationArticleDM

	/**
	 * Šarže
	 */
	@Type(() => TBatchDM)
	BatchId?: TBatchDM

	/**
	 * Kód z.
	 */
	@Type(() => TContractCodeDocumentDM)
	ContractCodeRID?: TContractCodeDocumentDM

	/**
	 * Sklad
	 */
	@Type(() => TWarehouseDM)
	WarehouseId?: TWarehouseDM

	/**
	 * Zboží
	 */
	@Type(() => TArticleDM)
	ArticleId?: TArticleDM

	/**
	 * undefined
	 */
	@Type(() => TTextDM)
	SuperiorDocumentTypeId?: TTextDM
}