import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'

/**
 * Celní kurzy
 */
export default class TCustomExchangeRateDM extends Data {
	public static className = 'TCustomExchangeRateDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = false

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * Firma
	 */
	CompanyId?: number

	/**
	 * Právo na záznam
	 */
	RightGroupId?: number

	/**
	 * K. jedn.
	 */
	ExchangeRateUnitCount?: number

	/**
	 * Kurz
	 */
	ExchangeRate?: number

	/**
	 * Měna
	 */
	Currency?: string

	/**
	 * Datum
	 */
	ExchangeRateDate?: Date

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Kurz
	 */
	ExchangeRateCalc?: number

	/**
	 * Právo na záznam
	 */
	RightGroupIdCalc?: number
}