import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TAdvanceReceivedDM from './TAdvanceReceivedDM'
import TTaxTypeDM from './TTaxTypeDM'
import TVATRateDM from './TVATRateDM'
import TTextDM from './TTextDM'

/**
 * Čerpání záloh
 */
export default class TAdvanceDeductionInvoiceDM extends Data {
	public static className = 'TAdvanceDeductionInvoiceDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = false

	/**
	 * Čerpáno DPH M
	 */
	DeductedAmountVATC?: number

	/**
	 * Čerpáno netto M
	 */
	DeductedAmountNetC?: number

	/**
	 * Čerpáno M
	 */
	DeductedAmountGrossC?: number

	/**
	 * Identifikace faktury
	 */
	DocumentRID?: number

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * AdvanceItemRID
	 */
	AdvanceItemRID?: number

	/**
	 * Kurz čerpání zálohy v měně zál
	 */
	DeductionExchangeRate?: number

	/**
	 * Čerpáno DPH
	 */
	DeductedAmountVAT?: number

	/**
	 * Čerpáno netto
	 */
	DeductedAmountNet?: number

	/**
	 * Kurzový rozdíl
	 */
	ExchangeDifference?: number

	/**
	 * Čerpáno
	 */
	DeductedAmountGross?: number

	/**
	 * Kurz zálohy
	 */
	AdvanceExchangeRate?: number

	/**
	 * Datum čerpání
	 */
	DeductionDate?: Date

	/**
	 * Typ zálohy
	 */
	AdvanceTypeId?: number

	/**
	 * Číslo položky
	 */
	ItemNumber?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * undefined
	 */
	OldDeductedAmountVATC?: number

	/**
	 * undefined
	 */
	OldDeductedAmountNetC?: number

	/**
	 * undefined
	 */
	OldDeductedAmountC?: number

	/**
	 * undefined
	 */
	OldDeductedAmountVAT?: number

	/**
	 * undefined
	 */
	OldDeductedAmountNet?: number

	/**
	 * undefined
	 */
	OldDeductedAmount?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Čerpáno netto
	 */
	CFDeductedNetto?: number

	/**
	 * Čerpáno brutto F
	 */
	CFInvDeducted?: number

	/**
	 * Čerpáno brutto FM
	 */
	InvoiceDeductedAmountGrossCCalc?: number

	/**
	 * Čerpáno netto F
	 */
	CFInvDeductedNetto?: number

	/**
	 * Čerpáno netto FM
	 */
	CFInvDeductedNettoM?: number

	/**
	 * DPH koef.
	 */
	CFCoefVAT?: number

	/**
	 * Kurz fa.
	 */
	CFCourseInv?: number

	/**
	 * Čerpáno DPH
	 */
	DeductedAmountVATCalc?: number

	/**
	 * Kurz zálohy
	 */
	AdvanceExchangeRateCalc?: number

	/**
	 * Kurz zál. v měně F
	 */
	AdvanceInvoiceCurrExchRateCalc?: number

	/**
	 * undefined
	 */
	DeductedAmountNetCalc?: number

	/**
	 * undefined
	 */
	ExtExchangeRateCalc?: number

	/**
	 * undefined
	 */
	InvoiceIntDeductedAmountGrossCalc?: number

	/**
	 * undefined
	 */
	InvoiceIntDeductedAmountGrossCCalc?: number

	/**
	 * Identifikace zálohy
	 */
	@Type(() => TAdvanceReceivedDM)
	AdvanceRID?: TAdvanceReceivedDM

	/**
	 * Typ daně
	 */
	@Type(() => TTaxTypeDM)
	TaxTypeId?: TTaxTypeDM

	/**
	 * Sazba DPH
	 */
	@Type(() => TVATRateDM)
	VATRateId?: TVATRateDM

	/**
	 * Typ faktury
	 */
	@Type(() => TTextDM)
	DocumentTypeId?: TTextDM
}