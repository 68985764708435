import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TResourceDM from './TResourceDM'

/**
 * Zdroje - detail
 */
export default class TCapacityPlanDetailDM extends Data {
	public static className = 'TCapacityPlanDetailDM'
	public static primaryKey = 'RecordNumber'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RecordNumber!
	}

	public static isHeader = false

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * undefined
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * undefined
	 */
	IsGroup?: string

	/**
	 * undefined
	 */
	IsSummationResource?: string

	/**
	 * undefined
	 */
	PlannedDateTimeTo?: Date

	/**
	 * undefined
	 */
	WorkshiftAbbr?: string

	/**
	 * undefined
	 */
	PlannedDateTimeFrom?: Date

	/**
	 * undefined
	 */
	RecordNumber?: number

	/**
	 * undefined
	 */
	ScheduledOnlyRequest?: number

	/**
	 * undefined
	 */
	Request?: number

	/**
	 * undefined
	 */
	Capacity?: number

	/**
	 * undefined
	 */
	WorkshiftRID?: number

	/**
	 * undefined
	 */
	WorkshiftDate?: Date

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * undefined
	 */
	@Type(() => TResourceDM)
	ResourceId?: TResourceDM
}