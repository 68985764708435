import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'

/**
 * Parameters
 */
export default class TSpecialPrintBufferParameters extends Data {
	public static className = 'TSpecialPrintBufferParameters'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = false

	/**
	 * Master
	 */
	Master?: number

	/**
	 * Boolean value
	 */
	BooleanValue?: string

	/**
	 * Float value
	 */
	FloatValue?: number

	/**
	 * Integer value
	 */
	IntegerValue?: number

	/**
	 * String value
	 */
	StringValue?: string

	/**
	 * Type value
	 */
	TypeValue?: number

	/**
	 * Name
	 */
	Name?: string

	/**
	 * RID
	 */
	RID?: number

	/**
	 * RecordMod
	 */
	RecordMod?: number

	/**
	 * RecordES
	 */
	RecordES?: number

	/**
	 * RID
	 */
	RecordID?: number

	/**
	 * RecordState
	 */
	RecordState?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Type value title
	 */
	TypeValueTitleCalc?: string

	/**
	 * String value
	 */
	StringValueCalc?: string

	/**
	 * Integer value
	 */
	IntegerValueCalc?: number

	/**
	 * Float value
	 */
	FloatValueCalc?: number

	/**
	 * Boolean value
	 */
	BooleanValueCalc?: string

	/**
	 * Value
	 */
	ValueCalc?: string
}