import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import Sensitivity from './Sensitivity'
import PrCalTypHours from './PrCalTypHours'
import TWorkflowPriorityDM from './TWorkflowPriorityDM'
import TExternalDocumentDM from './TExternalDocumentDM'
import TK2UserLookupDM from './TK2UserLookupDM'
import TContactPersonDM from './TContactPersonDM'
import WkfDiagramChapter from './WkfDiagramChapter'
import WkfDiagramClassification from './WkfDiagramClassification'
import WkfDiagramNorm from './WkfDiagramNorm'
import TWorkflowProcedureHeaderDM from './TWorkflowProcedureHeaderDM'
import TTextDM from './TTextDM'
import TMarketingActivityTypeDM from './TMarketingActivityTypeDM'
import TCommentDM from './TCommentDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TExternalDocumentItemDM from './TExternalDocumentItemDM'
import TCompanyLinkDM from './TCompanyLinkDM'
import TAttachedDocumentDM from './TAttachedDocumentDM'
import TOldNoteDM from './TOldNoteDM'

/**
 * Postupy workflow
 */
export default class TWorkflowProcedureDM extends Data {
	public static className = 'TWorkflowProcedureDM'
	public static primaryKey = 'Id'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.Id!
	}

	public static isHeader = true

	/**
	 * Systémový
	 */
	IsSystemProcedure?: string

	/**
	 * Stornováno
	 */
	IsCanceledRecord?: string

	/**
	 * Zobrazit zadání
	 */
	IsShowTask?: string

	/**
	 * Povinná zodpovědná osoba
	 */
	IsResponsiblePersonRequired?: string

	/**
	 * Směrnice
	 */
	IsProcedureDirective?: string

	/**
	 * Nastavení editoru
	 */
	EditorSettings?: string

	/**
	 * Časové razítko
	 */
	TimeStamp?: string

	/**
	 * Max. doba kroku [h]
	 */
	MaxTimeStep?: number

	/**
	 * Max. doba [h]
	 */
	MaxTime?: number

	/**
	 * Kalendář
	 */
	CalendarId?: number

	/**
	 * Potvrzeno
	 */
	ConfirmedOn?: Date

	/**
	 * Přezkoumáno
	 */
	TestedDate?: Date

	/**
	 * Revize
	 */
	Revision?: number

	/**
	 * Vydání
	 */
	Release?: number

	/**
	 * Platnost do
	 */
	ValidTo?: Date

	/**
	 * Platnost od
	 */
	ValidFrom?: Date

	/**
	 * Schváleno
	 */
	ApprovedDate?: Date

	/**
	 * Změněno
	 */
	ChangedOn?: Date

	/**
	 * Vytvořeno
	 */
	CreatedOn?: Date

	/**
	 * Verze
	 */
	VersionNumber?: number

	/**
	 * Číslo
	 */
	Id?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Datum pro "_ver" pole
	 */
	VersionedFieldDateCalc?: Date

	/**
	 * Název
	 */
	NameCalc?: string

	/**
	 * Nasazeno
	 */
	DeploymentStatusCalc?: number

	/**
	 * Platnost od - datum
	 */
	ValidDateFromCalc?: Date

	/**
	 * Platnost od - čas
	 */
	ValidTimeFromCalc?: Date

	/**
	 * Platnost do - datum
	 */
	ValidDateToCalc?: Date

	/**
	 * Platnost do - čas
	 */
	ValidTimeToCalc?: Date

	/**
	 * undefined
	 */
	DescriptionMemoCalc?: string

	/**
	 * undefined
	 */
	TargetMemoCalc?: string

	/**
	 * Název
	 */
	NodeStepNameCalc?: string

	/**
	 * Zkratka
	 */
	NodeAbbrCalc?: string

	/**
	 * Dokumentační text
	 */
	NodeDocumentTextCalc?: string

	/**
	 * Seskupení
	 */
	NodeStepGroupCalc?: string

	/**
	 * Číslo
	 */
	NodeRIDCalc?: number

	/**
	 * NodeLanguageNameCalc
	 */
	NodeLanguageNameCalc?: string

	/**
	 * Kalendář
	 */
	NodeCalendarIdCalc?: number

	/**
	 * Max. doba [h]
	 */
	NodeMaxTimeCalc?: number

	/**
	 * Název
	 */
	ArrowNameCalc?: string

	/**
	 * Pořadí
	 */
	ArrowSequenceNumberCalc?: number

	/**
	 * Číslo
	 */
	ArrowRIDCalc?: number

	/**
	 * ArrowLanguageNameCalc
	 */
	ArrowLanguageNameCalc?: string

	/**
	 * DiagramSVGCalc
	 */
	DiagramSVGCalc?: string

	/**
	 * notifikace - Nový krok
	 */
	NodeIsNotifyNewStepCalc?: string

	/**
	 * notifikace - Termin splnění
	 */
	NodeIsNotifyTimeFulfillCalc?: string

	/**
	 * Zohledni středisko
	 */
	NodeIsHoldCostCentreCalc?: string

	/**
	 * Řešitel je nadřízený
	 */
	NodeIsSuperiorSolverCalc?: string

	/**
	 * undefined
	 */
	TextHeaderMemoCalc?: string

	/**
	 * undefined
	 */
	TextFooterMemoCalc?: string

	/**
	 * Poslední komentář
	 */
	LastCommentParamCalc?: string

	/**
	 * Citlivost
	 */
	@Type(() => Sensitivity)
	SensitivityId?: Sensitivity

	/**
	 * typ čas. úseku
	 */
	@Type(() => PrCalTypHours)
	CalendarRecordTypeId?: PrCalTypHours

	/**
	 * Priorita
	 */
	@Type(() => TWorkflowPriorityDM)
	PriorityId?: TWorkflowPriorityDM

	/**
	 * Dokument postupu
	 */
	@Type(() => TExternalDocumentDM)
	ProcedureDocumentId?: TExternalDocumentDM

	/**
	 * Potvrdil
	 */
	@Type(() => TK2UserLookupDM)
	ConfirmedById?: TK2UserLookupDM

	/**
	 * Přezkoumal
	 */
	@Type(() => TContactPersonDM)
	TestedById?: TContactPersonDM

	/**
	 * Kapitola
	 */
	@Type(() => WkfDiagramChapter)
	ChapterId?: WkfDiagramChapter

	/**
	 * Zařazení
	 */
	@Type(() => WkfDiagramClassification)
	ClassificationId?: WkfDiagramClassification

	/**
	 * Norma
	 */
	@Type(() => WkfDiagramNorm)
	NormId?: WkfDiagramNorm

	/**
	 * Schválil
	 */
	@Type(() => TContactPersonDM)
	ApprovedById?: TContactPersonDM

	/**
	 * Změnil
	 */
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM

	/**
	 * Vytvořil
	 */
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM

	/**
	 * Hlavička
	 */
	@Type(() => TWorkflowProcedureHeaderDM)
	HeaderId?: TWorkflowProcedureHeaderDM

	/**
	 * Způsob výběru
	 */
	@Type(() => TTextDM)
	NodeMethodSelectingSolverIdCalc?: TTextDM

	/**
	 * Podřízený postup
	 */
	@Type(() => TWorkflowProcedureHeaderDM)
	NodeSubordinateProcedureIdCalc?: TWorkflowProcedureHeaderDM

	/**
	 * Priorita
	 */
	@Type(() => TWorkflowPriorityDM)
	NodePriorityIdCalc?: TWorkflowPriorityDM

	/**
	 * Typ čas. úseku
	 */
	@Type(() => PrCalTypHours)
	NodeCalendarRecordTypeIdCalc?: PrCalTypHours

	/**
	 * Stav procesu
	 */
	@Type(() => TTextDM)
	ArrowProcessStateIdCalc?: TTextDM

	/**
	 * D
	 */
	@Type(() => TTextDM)
	DocumentExistsCalc?: TTextDM

	/**
	 * undefined
	 */
	@Type(() => TMarketingActivityTypeDM)
	ActivityTypeRIDCalc?: TMarketingActivityTypeDM

	/**
	 * P
	 */
	@Type(() => TTextDM)
	ConfirmedOrCanceledIdCalc?: TTextDM

	/**
	 * Komentáře
	 */
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]

	/**
	 * Popis
	 */
	@Type(() => TLanguageMemoTranslationDM)
	DescriptionChild?: TLanguageMemoTranslationDM[]

	/**
	 * Cíl
	 */
	@Type(() => TLanguageMemoTranslationDM)
	TargetChild?: TLanguageMemoTranslationDM[]

	/**
	 * Dokumenty
	 */
	@Type(() => TExternalDocumentItemDM)
	ExternalDocumentItemChild?: TExternalDocumentItemDM[]

	/**
	 * Text hlavičky
	 */
	@Type(() => TLanguageMemoTranslationDM)
	TextHeaderChild?: TLanguageMemoTranslationDM[]

	/**
	 * Text patičky
	 */
	@Type(() => TLanguageMemoTranslationDM)
	TextFooterChild?: TLanguageMemoTranslationDM[]

	/**
	 * undefined
	 */
	@Type(() => TCompanyLinkDM)
	CompanyChild?: TCompanyLinkDM[]

	/**
	 * Svázané doklady
	 */
	@Type(() => TAttachedDocumentDM)
	AttachedDocumentOldChild?: TAttachedDocumentDM[]

	/**
	 * Poznámky
	 */
	@Type(() => TOldNoteDM)
	DeprecatedNoteOldChild?: TOldNoteDM[]

	/**
	 * Potvrzení
	 */
	public BulkConfirmCOMMAND() {
		return this.executeCommand('BulkConfirmCOMMAND')
	}

	/**
	 * Odpotvrzení
	 */
	public BulkUnconfirmCOMMAND() {
		return this.executeCommand('BulkUnconfirmCOMMAND')
	}

	/**
	 * Změna data potvrzení
	 */
	public BulkChangeConfirmationDateCOMMAND(parameters: TBulkChangeConfirmationDateCOMMANDParams) {
		return this.executeCommand('BulkChangeConfirmationDateCOMMAND', parameters)
	}

	/**
	 * Storno
	 */
	public BulkCancelCOMMAND() {
		return this.executeCommand('BulkCancelCOMMAND')
	}

	/**
	 * Odstorno
	 */
	public BulkUnCancelCOMMAND() {
		return this.executeCommand('BulkUnCancelCOMMAND')
	}

	/**
	 * Potvrzení
	 */
	public ConfirmCOMMAND() {
		return this.executeCommand('ConfirmCOMMAND')
	}

	/**
	 * Odpotvrzení
	 */
	public UnconfirmCOMMAND() {
		return this.executeCommand('UnconfirmCOMMAND')
	}

	/**
	 * Změna data potvrzení
	 */
	public ChangeConfirmationDateCOMMAND(parameters: TChangeConfirmationDateCOMMANDParams) {
		return this.executeCommand('ChangeConfirmationDateCOMMAND', parameters)
	}

	/**
	 * Storno/Odstorno
	 */
	public CancelCOMMAND() {
		return this.executeCommand('CancelCOMMAND')
	}

	/**
	 * Zrušení stavu
	 */
	public ClearRecordStatusCOMMAND() {
		return this.executeCommand('ClearRecordStatusCOMMAND')
	}

	/**
	 * Změna stavu
	 */
	public ChangeRecordStatusCOMMAND() {
		return this.executeCommand('ChangeRecordStatusCOMMAND')
	}

	/**
	 * 
	 */
	public ChangeRecordStatusSWSCOMMAND(parameters: TChangeRecordStatusSWSCOMMANDParams) {
		return this.executeCommand('ChangeRecordStatusSWSCOMMAND', parameters)
	}

	/**
	 * Zrušení stavu
	 */
	public BulkClearRecordStatusCOMMAND() {
		return this.executeCommand('BulkClearRecordStatusCOMMAND')
	}

	/**
	 * Změna stavu
	 */
	public BulkChangeRecordStatusCOMMAND() {
		return this.executeCommand('BulkChangeRecordStatusCOMMAND')
	}

	/**
	 * Změna atributů
	 */
	public BulkChangeAttributesCOMMAND(parameters: TBulkChangeAttributesCOMMANDParams) {
		return this.executeCommand('BulkChangeAttributesCOMMAND', parameters)
	}

	/**
	 * Aktualizace sady atributů
	 */
	public BulkActualizeAttributesCOMMAND(parameters: TBulkActualizeAttributesCOMMANDParams) {
		return this.executeCommand('BulkActualizeAttributesCOMMAND', parameters)
	}

	/**
	 * Aktualizace sady atributů
	 */
	public ActualizeAttributesCOMMAND() {
		return this.executeCommand('ActualizeAttributesCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public ChangeGroupRightCOMMAND() {
		return this.executeCommand('ChangeGroupRightCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public BulkChangeGroupRightCOMMAND() {
		return this.executeCommand('BulkChangeGroupRightCOMMAND')
	}

	/**
	 * Aktualizace počítaných polí
	 */
	public BulkUpdateCalcFieldsCOMMAND() {
		return this.executeCommand('BulkUpdateCalcFieldsCOMMAND')
	}

	/**
	 * Kontrola legislativy
	 */
	public SwitchOffLegislationValidationCOMMAND() {
		return this.executeCommand('SwitchOffLegislationValidationCOMMAND')
	}
}

export type TBulkChangeConfirmationDateCOMMANDParams = {
	/**
	 * Datum potvrzení
	 */
	Confirmation1Date?: Date
	/**
	 * Hodina potvrzení
	 */
	Confirmation1Time?: Date
	/**
	 * Datum 2. potvrzení
	 */
	Confirmation2Date?: Date
	/**
	 * Hodina 2. potvrzení
	 */
	Confirmation2Time?: Date
	/**
	 * Kontrolovat záporné stavy
	 */
	CheckNegativeState?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TChangeConfirmationDateCOMMANDParams = {
	/**
	 * Datum potvrzení
	 */
	Confirmation1Date?: Date
	/**
	 * Hodina potvrzení
	 */
	Confirmation1Time?: Date
	/**
	 * Datum 2. potvrzení
	 */
	Confirmation2Date?: Date
	/**
	 * Hodina 2. potvrzení
	 */
	Confirmation2Time?: Date
	/**
	 * Kontrolovat záporné stavy
	 */
	CheckNegativeState?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TChangeRecordStatusSWSCOMMANDParams = {
	/**
	 * StatusId
	 */
	StatusId?: number
}

export type TBulkChangeAttributesCOMMANDParams = {
	/**
	 * Smazat atributy
	 */
	DeleteAttribute?: boolean
	/**
	 * Smazat všechny volné atributy
	 */
	DeleteFreeAttribute?: boolean
	/**
	 * Nastavit hodnoty atributů
	 */
	SetValues?: boolean
	/**
	 * Přidat nepřipojené atributy
	 */
	AddFreeAttributes?: boolean
	/**
	 * FreeAttributeEnabled
	 */
	FreeAttributeEnabled?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TBulkActualizeAttributesCOMMANDParams = {
	/**
	 * Aktualizovat včetně samotné sady atributů
	 */
	ActualizeAttributeSet?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}