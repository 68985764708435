import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import VehicleStatus from './VehicleStatus'
import TOfficerDocumentDM from './TOfficerDocumentDM'
import TCode2DocumentDM from './TCode2DocumentDM'
import TCode1DocumentDM from './TCode1DocumentDM'
import TK2UserLookupDM from './TK2UserLookupDM'
import TCode6DocumentDM from './TCode6DocumentDM'
import TCode5DocumentDM from './TCode5DocumentDM'
import TCode4DocumentDM from './TCode4DocumentDM'
import TCode3DocumentDM from './TCode3DocumentDM'
import TArticleCodeDocumentDM from './TArticleCodeDocumentDM'
import TCostCentreDocumentDM from './TCostCentreDocumentDM'
import TDeviceDocumentDM from './TDeviceDocumentDM'
import TContractCodeDocumentDM from './TContractCodeDocumentDM'
import RoadTaxRate from './RoadTaxRate'
import TSpecialStringListDM from './TSpecialStringListDM'
import TVehicleTypeDM from './TVehicleTypeDM'
import TTextDM from './TTextDM'
import TMarketingActivityTypeDM from './TMarketingActivityTypeDM'
import TWorkflowProcessDM from './TWorkflowProcessDM'
import TActivityDM from './TActivityDM'
import TCommentDM from './TCommentDM'
import TJobDM from './TJobDM'
import TVehicleRateDM from './TVehicleRateDM'
import TVehicleConsumptionDM from './TVehicleConsumptionDM'
import TServicedDeviceDM from './TServicedDeviceDM'
import TDeliveryMeasureUnitDM from './TDeliveryMeasureUnitDM'
import TVehicleShutdownDM from './TVehicleShutdownDM'
import TExternalDocumentItemDM from './TExternalDocumentItemDM'
import TWorkflowProcessAsItemDM from './TWorkflowProcessAsItemDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TAttachedDocumentDM from './TAttachedDocumentDM'
import TOldNoteDM from './TOldNoteDM'

/**
 * Vozidla
 */
export default class TVehicleDM extends Data {
	public static className = 'TVehicleDM'
	public static primaryKey = 'Id'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.Id!
	}

	public static isHeader = true

	/**
	 * Počet euro palet
	 */
	EuroPalletCount?: number

	/**
	 * Výška skříně
	 */
	TankHeight?: number

	/**
	 * Nosnost
	 */
	LoadCapacity?: number

	/**
	 * Malý TP
	 */
	RegistrationCertificateNumber?: string

	/**
	 * Velký TP
	 */
	RegistrationNumber?: string

	/**
	 * VIN
	 */
	VIN?: string

	/**
	 * Zneplatněno
	 */
	IsInvalidRecord?: string

	/**
	 * Skupina práv
	 */
	RightGroupId?: number

	/**
	 * Vytvořeno
	 */
	CreatedOn?: Date

	/**
	 * Konec platnosti TP
	 */
	InspectionExpirationDate?: Date

	/**
	 * Časové razítko
	 */
	TimeStamp?: string

	/**
	 * Datum první registrace
	 */
	FirstRegistrationDate?: Date

	/**
	 * Šířka skříně
	 */
	TankWidth?: number

	/**
	 * Délka skříně
	 */
	TankLength?: number

	/**
	 * Objem
	 */
	TankVolume?: number

	/**
	 * Koef. spotřeby
	 */
	ConsumptionCoefficient?: number

	/**
	 * Dat. výr. motoru
	 */
	EngineManufactureDate?: Date

	/**
	 * Počet poh. náprav
	 */
	DrivenAxleCount?: number

	/**
	 * SPZ
	 */
	PlateNumber?: string

	/**
	 * Změněno
	 */
	ChangedOn?: Date

	/**
	 * Mis. konce pos. jízdy
	 */
	LastDriveLocation?: string

	/**
	 * Dat. konce pos. jízdy
	 */
	LastDriveDate?: Date

	/**
	 * Dat. vyřazení
	 */
	RetirementDate?: Date

	/**
	 * Dat. zařazení
	 */
	StartUseDate?: Date

	/**
	 * Dat. výroby
	 */
	ManufactureDate?: Date

	/**
	 * Počet náprav
	 */
	AxleCount?: number

	/**
	 * Max. počet osob
	 */
	MaximumPersonCount?: number

	/**
	 * Hmotnost
	 */
	Weight?: number

	/**
	 * Obsah motoru
	 */
	EngineCapacity?: number

	/**
	 * Prům. spotřeba
	 */
	AverageConsumption?: number

	/**
	 * Akt. stav tach.
	 */
	OdometerCurrentReading?: number

	/**
	 * Poč. stav tach.
	 */
	OdometerInitialReading?: number

	/**
	 * Popis
	 */
	Description?: string

	/**
	 * Zkratka 2
	 */
	Abbr2?: string

	/**
	 * Zkratka 1
	 */
	Abbr?: string

	/**
	 * Číslo
	 */
	Id?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * SQLSequenceOrderCalc
	 */
	SQLSequenceOrderCalc?: number

	/**
	 * undefined
	 */
	FirstPictureFileNameCalc?: string

	/**
	 * undefined
	 */
	IsTaxPayerChangeCalc?: number

	/**
	 * undefined
	 */
	TextHeaderMemoCalc?: string

	/**
	 * undefined
	 */
	TextFooterMemoCalc?: string

	/**
	 * Poslední komentář
	 */
	LastCommentParamCalc?: string

	/**
	 * Skupina práv
	 */
	RightGroupIdCalc?: number

	/**
	 * Stav
	 */
	@Type(() => VehicleStatus)
	StatusId?: VehicleStatus

	/**
	 * Referent
	 */
	@Type(() => TOfficerDocumentDM)
	OfficerId?: TOfficerDocumentDM

	/**
	 * Kód 2
	 */
	@Type(() => TCode2DocumentDM)
	Code2Id?: TCode2DocumentDM

	/**
	 * Kód 1
	 */
	@Type(() => TCode1DocumentDM)
	Code1Id?: TCode1DocumentDM

	/**
	 * Vytvořil
	 */
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM

	/**
	 * Kód 6
	 */
	@Type(() => TCode6DocumentDM)
	Code6Id?: TCode6DocumentDM

	/**
	 * Kód 5
	 */
	@Type(() => TCode5DocumentDM)
	Code5Id?: TCode5DocumentDM

	/**
	 * Kód 4
	 */
	@Type(() => TCode4DocumentDM)
	Code4Id?: TCode4DocumentDM

	/**
	 * Kód 3
	 */
	@Type(() => TCode3DocumentDM)
	Code3Id?: TCode3DocumentDM

	/**
	 * Kód zboží
	 */
	@Type(() => TArticleCodeDocumentDM)
	ArticleCodeId?: TArticleCodeDocumentDM

	/**
	 * Středisko
	 */
	@Type(() => TCostCentreDocumentDM)
	CostCentreId?: TCostCentreDocumentDM

	/**
	 * Prostředek
	 */
	@Type(() => TDeviceDocumentDM)
	DeviceRID?: TDeviceDocumentDM

	/**
	 * Kód zakázky
	 */
	@Type(() => TContractCodeDocumentDM)
	ContractCodeRID?: TContractCodeDocumentDM

	/**
	 * Silniční daň
	 */
	@Type(() => RoadTaxRate)
	RoadTaxId?: RoadTaxRate

	/**
	 * Prac. zařazení 4
	 */
	@Type(() => TSpecialStringListDM)
	Passanger4Grade?: TSpecialStringListDM

	/**
	 * Osádka 4
	 */
	@Type(() => TOfficerDocumentDM)
	Passanger4Id?: TOfficerDocumentDM

	/**
	 * Druh
	 */
	@Type(() => TVehicleTypeDM)
	CarKind?: TVehicleTypeDM

	/**
	 * Druh karoserie
	 */
	@Type(() => TSpecialStringListDM)
	BodyType?: TSpecialStringListDM

	/**
	 * Typ
	 */
	@Type(() => TSpecialStringListDM)
	CarType?: TSpecialStringListDM

	/**
	 * Tov. značka
	 */
	@Type(() => TSpecialStringListDM)
	CarMake?: TSpecialStringListDM

	/**
	 * Změnil
	 */
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM

	/**
	 * Prac. zařazení 3
	 */
	@Type(() => TSpecialStringListDM)
	Passanger3Grade?: TSpecialStringListDM

	/**
	 * Prac. zařazení 2
	 */
	@Type(() => TSpecialStringListDM)
	Passanger2Grade?: TSpecialStringListDM

	/**
	 * Prac. zařazení 1
	 */
	@Type(() => TSpecialStringListDM)
	Passanger1Grade?: TSpecialStringListDM

	/**
	 * Osádka 3
	 */
	@Type(() => TOfficerDocumentDM)
	Passanger3Id?: TOfficerDocumentDM

	/**
	 * Osádka 2
	 */
	@Type(() => TOfficerDocumentDM)
	Passanger2Id?: TOfficerDocumentDM

	/**
	 * Osádka 1
	 */
	@Type(() => TOfficerDocumentDM)
	Passanger1Id?: TOfficerDocumentDM

	/**
	 * Palivo
	 */
	@Type(() => TSpecialStringListDM)
	Fuel?: TSpecialStringListDM

	/**
	 * Skupina
	 */
	@Type(() => TSpecialStringListDM)
	Group?: TSpecialStringListDM

	/**
	 * Z
	 */
	@Type(() => TTextDM)
	InvalidRecordImgCalc?: TTextDM

	/**
	 * D
	 */
	@Type(() => TTextDM)
	DocumentExistsCalc?: TTextDM

	/**
	 * undefined
	 */
	@Type(() => TMarketingActivityTypeDM)
	ActivityTypeRIDCalc?: TMarketingActivityTypeDM

	/**
	 * SP
	 */
	@Type(() => TTextDM)
	ProcessStateIdCalc?: TTextDM

	/**
	 * Aktuální proces
	 */
	@Type(() => TWorkflowProcessDM)
	CurrentProcessIdCalc?: TWorkflowProcessDM

	/**
	 * undefined
	 */
	@Type(() => TActivityDM)
	ActivityIdCalc?: TActivityDM

	/**
	 * Komentáře
	 */
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]

	/**
	 * Úkoly
	 */
	@Type(() => TJobDM)
	JobChild?: TJobDM[]

	/**
	 * undefined
	 */
	@Type(() => TVehicleRateDM)
	RateChild?: TVehicleRateDM[]

	/**
	 * undefined
	 */
	@Type(() => TVehicleConsumptionDM)
	ConsumptionChild?: TVehicleConsumptionDM[]

	/**
	 * undefined
	 */
	@Type(() => TServicedDeviceDM)
	ServicedDeviceChild?: TServicedDeviceDM[]

	/**
	 * undefined
	 */
	@Type(() => TDeliveryMeasureUnitDM)
	MeasureUnitChild?: TDeliveryMeasureUnitDM[]

	/**
	 * undefined
	 */
	@Type(() => TVehicleShutdownDM)
	ShutdownChild?: TVehicleShutdownDM[]

	/**
	 * Seznam aktivit
	 */
	@Type(() => TActivityDM)
	ActivityChild?: TActivityDM[]

	/**
	 * Dokumenty
	 */
	@Type(() => TExternalDocumentItemDM)
	ExternalDocumentItemChild?: TExternalDocumentItemDM[]

	/**
	 * Procesy
	 */
	@Type(() => TWorkflowProcessAsItemDM)
	WorkflowProcessChild?: TWorkflowProcessAsItemDM[]

	/**
	 * Text hlavičky
	 */
	@Type(() => TLanguageMemoTranslationDM)
	TextHeaderChild?: TLanguageMemoTranslationDM[]

	/**
	 * Text patičky
	 */
	@Type(() => TLanguageMemoTranslationDM)
	TextFooterChild?: TLanguageMemoTranslationDM[]

	/**
	 * Svázané doklady
	 */
	@Type(() => TAttachedDocumentDM)
	AttachedDocumentOldChild?: TAttachedDocumentDM[]

	/**
	 * Poznámky
	 */
	@Type(() => TOldNoteDM)
	DeprecatedNoteOldChild?: TOldNoteDM[]

	/**
	 * Zneplatnění
	 */
	public BulkInvalidationCOMMAND() {
		return this.executeCommand('BulkInvalidationCOMMAND')
	}

	/**
	 * Zplatnění
	 */
	public BulkUnInvalidationCOMMAND() {
		return this.executeCommand('BulkUnInvalidationCOMMAND')
	}

	/**
	 * Zneplatnění/Zplatnění
	 */
	public InvalidationCOMMAND() {
		return this.executeCommand('InvalidationCOMMAND')
	}

	/**
	 * Potvrzení
	 */
	public BulkConfirmCOMMAND() {
		return this.executeCommand('BulkConfirmCOMMAND')
	}

	/**
	 * Odpotvrzení
	 */
	public BulkUnconfirmCOMMAND() {
		return this.executeCommand('BulkUnconfirmCOMMAND')
	}

	/**
	 * Změna data potvrzení
	 */
	public BulkChangeConfirmationDateCOMMAND(parameters: TBulkChangeConfirmationDateCOMMANDParams) {
		return this.executeCommand('BulkChangeConfirmationDateCOMMAND', parameters)
	}

	/**
	 * Storno
	 */
	public BulkCancelCOMMAND() {
		return this.executeCommand('BulkCancelCOMMAND')
	}

	/**
	 * Odstorno
	 */
	public BulkUnCancelCOMMAND() {
		return this.executeCommand('BulkUnCancelCOMMAND')
	}

	/**
	 * Potvrzení
	 */
	public ConfirmCOMMAND() {
		return this.executeCommand('ConfirmCOMMAND')
	}

	/**
	 * Odpotvrzení
	 */
	public UnconfirmCOMMAND() {
		return this.executeCommand('UnconfirmCOMMAND')
	}

	/**
	 * Změna data potvrzení
	 */
	public ChangeConfirmationDateCOMMAND(parameters: TChangeConfirmationDateCOMMANDParams) {
		return this.executeCommand('ChangeConfirmationDateCOMMAND', parameters)
	}

	/**
	 * Storno/Odstorno
	 */
	public CancelCOMMAND() {
		return this.executeCommand('CancelCOMMAND')
	}

	/**
	 * Zrušení stavu
	 */
	public ClearRecordStatusCOMMAND() {
		return this.executeCommand('ClearRecordStatusCOMMAND')
	}

	/**
	 * Změna stavu
	 */
	public ChangeRecordStatusCOMMAND() {
		return this.executeCommand('ChangeRecordStatusCOMMAND')
	}

	/**
	 * 
	 */
	public ChangeRecordStatusSWSCOMMAND(parameters: TChangeRecordStatusSWSCOMMANDParams) {
		return this.executeCommand('ChangeRecordStatusSWSCOMMAND', parameters)
	}

	/**
	 * Zrušení stavu
	 */
	public BulkClearRecordStatusCOMMAND() {
		return this.executeCommand('BulkClearRecordStatusCOMMAND')
	}

	/**
	 * Změna stavu
	 */
	public BulkChangeRecordStatusCOMMAND() {
		return this.executeCommand('BulkChangeRecordStatusCOMMAND')
	}

	/**
	 * Změna atributů
	 */
	public BulkChangeAttributesCOMMAND(parameters: TBulkChangeAttributesCOMMANDParams) {
		return this.executeCommand('BulkChangeAttributesCOMMAND', parameters)
	}

	/**
	 * Aktualizace sady atributů
	 */
	public BulkActualizeAttributesCOMMAND(parameters: TBulkActualizeAttributesCOMMANDParams) {
		return this.executeCommand('BulkActualizeAttributesCOMMAND', parameters)
	}

	/**
	 * Aktualizace sady atributů
	 */
	public ActualizeAttributesCOMMAND() {
		return this.executeCommand('ActualizeAttributesCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public ChangeGroupRightCOMMAND() {
		return this.executeCommand('ChangeGroupRightCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public BulkChangeGroupRightCOMMAND() {
		return this.executeCommand('BulkChangeGroupRightCOMMAND')
	}

	/**
	 * Aktualizace počítaných polí
	 */
	public BulkUpdateCalcFieldsCOMMAND() {
		return this.executeCommand('BulkUpdateCalcFieldsCOMMAND')
	}

	/**
	 * Kontrola legislativy
	 */
	public SwitchOffLegislationValidationCOMMAND() {
		return this.executeCommand('SwitchOffLegislationValidationCOMMAND')
	}
}

export type TBulkChangeConfirmationDateCOMMANDParams = {
	/**
	 * Datum potvrzení
	 */
	Confirmation1Date?: Date
	/**
	 * Hodina potvrzení
	 */
	Confirmation1Time?: Date
	/**
	 * Datum 2. potvrzení
	 */
	Confirmation2Date?: Date
	/**
	 * Hodina 2. potvrzení
	 */
	Confirmation2Time?: Date
	/**
	 * Kontrolovat záporné stavy
	 */
	CheckNegativeState?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TChangeConfirmationDateCOMMANDParams = {
	/**
	 * Datum potvrzení
	 */
	Confirmation1Date?: Date
	/**
	 * Hodina potvrzení
	 */
	Confirmation1Time?: Date
	/**
	 * Datum 2. potvrzení
	 */
	Confirmation2Date?: Date
	/**
	 * Hodina 2. potvrzení
	 */
	Confirmation2Time?: Date
	/**
	 * Kontrolovat záporné stavy
	 */
	CheckNegativeState?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TChangeRecordStatusSWSCOMMANDParams = {
	/**
	 * StatusId
	 */
	StatusId?: number
}

export type TBulkChangeAttributesCOMMANDParams = {
	/**
	 * Smazat atributy
	 */
	DeleteAttribute?: boolean
	/**
	 * Smazat všechny volné atributy
	 */
	DeleteFreeAttribute?: boolean
	/**
	 * Nastavit hodnoty atributů
	 */
	SetValues?: boolean
	/**
	 * Přidat nepřipojené atributy
	 */
	AddFreeAttributes?: boolean
	/**
	 * FreeAttributeEnabled
	 */
	FreeAttributeEnabled?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TBulkActualizeAttributesCOMMANDParams = {
	/**
	 * Aktualizovat včetně samotné sady atributů
	 */
	ActualizeAttributeSet?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}