import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TK2UserLookupDM from './TK2UserLookupDM'
import EmploymentName from './EmploymentName'
import ProfessionName from './ProfessionName'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'

/**
 * Pracovní zařazení
 */
export default class TWorkOfficialPositionDM extends Data {
	public static className = 'TWorkOfficialPositionDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = false

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * Komentář
	 */
	Comment?: string

	/**
	 * Vytvořeno
	 */
	CreatedOn?: Date

	/**
	 * Změněno
	 */
	ChangedOn?: Date

	/**
	 * Zařazen do
	 */
	EmployedToDate?: Date

	/**
	 * Partner
	 */
	Employer?: string

	/**
	 * Zařazen od
	 */
	EmployedFromDate?: Date

	/**
	 * Pořadí
	 */
	SequenceNumber?: number

	/**
	 * Kont. os.
	 */
	ContactPersonId?: number

	/**
	 * Typ
	 */
	TypeId?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Vytvořil
	 */
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM

	/**
	 * Změnil
	 */
	@Type(() => TK2UserLookupDM)
	ChangedbyId?: TK2UserLookupDM

	/**
	 * Zařazení
	 */
	@Type(() => EmploymentName)
	Employment?: EmploymentName

	/**
	 * Povolání
	 */
	@Type(() => ProfessionName)
	Profession?: ProfessionName

	/**
	 * undefined
	 */
	@Type(() => TLanguageMemoTranslationDM)
	CommentChild?: TLanguageMemoTranslationDM[]
}