import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TArticleDM from './TArticleDM'

/**
 * Souhrn přiřazeného zboží
 */
export default class TEshopCategoryArticleSummaryDM extends Data {
	public static className = 'TEshopCategoryArticleSummaryDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = false

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * RID
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * Podřízená kategorie
	 */
	SubordinateCategory?: string

	/**
	 * Skryto
	 */
	IsHideOnShop?: string

	/**
	 * Název pro int. obchod
	 */
	NameForEshop?: string

	/**
	 * Sk.
	 */
	IsGroupAssigned?: string

	/**
	 * Zb.
	 */
	IsArticleAssigned?: string

	/**
	 * RID
	 */
	RID?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Zboží
	 */
	@Type(() => TArticleDM)
	ArticleId?: TArticleDM
}