import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TAddressDM from './TAddressDM'
import TK2UserLookupDM from './TK2UserLookupDM'
import TCountryDM from './TCountryDM'
import TTownPartDM from './TTownPartDM'
import TTownDM from './TTownDM'

/**
 * Adresy
 */
export default class TMarketingAddressDM extends Data {
	public static className = 'TMarketingAddressDM'
	public static primaryKey = 'RID'
	public static systemFields = [
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = true

	/**
	 * Odběrné místo
	 */
	PickUpPointId?: string

	/**
	 * Daňové číslo
	 */
	TaxNumber?: string

	/**
	 * IČ
	 */
	CompanyRegNumber?: string

	/**
	 * DIČ
	 */
	VATRegNumber?: string

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * Fax
	 */
	Fax?: string

	/**
	 * Email
	 */
	Email?: string

	/**
	 * Datový modul
	 */
	DataModuleNumber?: number

	/**
	 * Komentář
	 */
	Comment?: string

	/**
	 * Vytvořeno
	 */
	CreatedOn?: Date

	/**
	 * Změněno
	 */
	ChangedOn?: Date

	/**
	 * Telefon
	 */
	Phone?: string

	/**
	 * Typ
	 */
	TypeId?: string

	/**
	 * Datum
	 */
	ValidDate?: Date

	/**
	 * Pořadí
	 */
	SequenceOrder?: number

	/**
	 * Kont.os.
	 */
	ContactPersonId?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * RegionCalc
	 */
	RegionCalc?: string

	/**
	 * RegionFieldCalc
	 */
	RegionFieldCalc?: string

	/**
	 * Ulice (Adresa)
	 */
	AddressIdStreetCalc?: string

	/**
	 * Číslo orientační (Adresa)
	 */
	AddressIdOrientationNumberCalc?: string

	/**
	 * Číslo popisné (Adresa)
	 */
	AddressIdHouseNumberCalc?: string

	/**
	 * Název (Adresa)
	 */
	AddressIdNameCalc?: string

	/**
	 * PSČ (Adresa)
	 */
	AddressIdTownCodeCalc?: string

	/**
	 * Souřadnice (Adresa)
	 */
	AddressIdCoordinatesCalc?: string

	/**
	 * Adresa
	 */
	@Type(() => TAddressDM)
	AddressId?: TAddressDM

	/**
	 * Vytvořil
	 */
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM

	/**
	 * Změnil
	 */
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM

	/**
	 * Stát (Adresa)
	 */
	@Type(() => TCountryDM)
	AddressIdCountryIdCalc?: TCountryDM

	/**
	 * Část obce (Adresa)
	 */
	@Type(() => TTownPartDM)
	AddressIdTownPartIdCalc?: TTownPartDM

	/**
	 * Číslo (Adresa)
	 */
	@Type(() => TTownDM)
	AddressIdTownIdCalc?: TTownDM

	/**
	 * Změna skupiny práv
	 */
	public ChangeGroupRightCOMMAND() {
		return this.executeCommand('ChangeGroupRightCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public BulkChangeGroupRightCOMMAND() {
		return this.executeCommand('BulkChangeGroupRightCOMMAND')
	}

	/**
	 * Aktualizace počítaných polí
	 */
	public BulkUpdateCalcFieldsCOMMAND() {
		return this.executeCommand('BulkUpdateCalcFieldsCOMMAND')
	}

	/**
	 * Kontrola legislativy
	 */
	public SwitchOffLegislationValidationCOMMAND() {
		return this.executeCommand('SwitchOffLegislationValidationCOMMAND')
	}
}