import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TArticleCodeDocumentDM from './TArticleCodeDocumentDM'
import TVATRateDM from './TVATRateDM'
import GoodsName from './GoodsName'
import TMeasureUnitDM from './TMeasureUnitDM'
import TArticleDM from './TArticleDM'

/**
 * Dodavatelské ceny
 */
export default class TArticleBreakdownDM extends Data {
	public static className = 'TArticleBreakdownDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = false

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * Cena
	 */
	Price?: number

	/**
	 * Množství
	 */
	Quantity?: number

	/**
	 * Pořadí
	 */
	SequenceNumber?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Množství
	 */
	QuantityUMCalc?: number

	/**
	 * Množství abs.
	 */
	PositiveQuantityCalc?: number

	/**
	 * Znam.množ.
	 */
	QuantitySignCalc?: number

	/**
	 * undefined
	 */
	QuantitySignImgCalc?: string

	/**
	 * undefined
	 */
	ArticleCodeTextCalc?: string

	/**
	 * undefined
	 */
	TaxTextCalc?: string

	/**
	 * Cena/ MJ
	 */
	UnitPriceCalc?: number

	/**
	 * Kód zboží
	 */
	@Type(() => TArticleCodeDocumentDM)
	ArticleCodeId?: TArticleCodeDocumentDM

	/**
	 * DPH
	 */
	@Type(() => TVATRateDM)
	VATRateId?: TVATRateDM

	/**
	 * Název
	 */
	@Type(() => GoodsName)
	NameId?: GoodsName

	/**
	 * MJ
	 */
	@Type(() => TMeasureUnitDM)
	MeasureUnitId?: TMeasureUnitDM

	/**
	 * Č. zboží
	 */
	@Type(() => TArticleDM)
	ArticleId?: TArticleDM

	/**
	 * Č. zboží - rozpisu
	 */
	@Type(() => TArticleDM)
	MasterArticleId?: TArticleDM
}