import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TResourceDM from './TResourceDM'

/**
 * Zdroje
 */
export default class TCapacityPlanResourceDM extends Data {
	public static className = 'TCapacityPlanResourceDM'
	public static primaryKey = 'Resource'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.Resource!
	}

	public static isHeader = false

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * undefined
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * undefined
	 */
	PlannedDateTimeTo?: Date

	/**
	 * undefined
	 */
	PlannedDateTimeFrom?: Date

	/**
	 * Vytížení %
	 */
	Utilization?: number

	/**
	 * undefined
	 */
	RequestedQuantity?: number

	/**
	 * undefined
	 */
	Capacity?: number

	/**
	 * Zdroj
	 */
	Resource?: string

	/**
	 * ID
	 */
	RecordNumber?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * undefined
	 */
	ShowInHoursCalc?: string

	/**
	 * Kapacita
	 */
	CapacityUMCalc?: number

	/**
	 * Požadavky
	 */
	RequestedQuantityUMCalc?: number

	/**
	 * Číslo zdroje
	 */
	@Type(() => TResourceDM)
	ResourceId?: TResourceDM
}