import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TWarehouseDM from './TWarehouseDM'
import AlternateStockTextMeaning from './AlternateStockTextMeaning'
import AlternateStockText from './AlternateStockText'
import TLinkDM from './TLinkDM'

/**
 * Alternativní text stavu skladu podle množství
 */
export default class TEshopStockTextItemDM extends Data {
	public static className = 'TEshopStockTextItemDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = false

	/**
	 * Množství od
	 */
	QuantityFrom?: number

	/**
	 * Alternativní text
	 */
	StockTextRID?: number

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Odkaz
	 */
	PictureCalc?: string

	/**
	 * Sklad
	 */
	@Type(() => TWarehouseDM)
	WarehouseId?: TWarehouseDM

	/**
	 * Význam textu
	 */
	@Type(() => AlternateStockTextMeaning)
	TextMeaningId?: AlternateStockTextMeaning

	/**
	 * Text
	 */
	@Type(() => AlternateStockText)
	TextId?: AlternateStockText

	/**
	 * Odkazy
	 */
	@Type(() => TLinkDM)
	LinkChild?: TLinkDM[]
}