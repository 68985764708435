import { Button, Modal } from "react-bootstrap"
import { useGlobalModalContext } from "./GlobalModal"

export type InformationModalProps = {
    title: string
    closeButton?: string
    handleClose?: () => void
}

export const InformationModal = () => {
    const { hideModal, store } = useGlobalModalContext()
    const { title, closeButton, handleClose } = store.modalProps as InformationModalProps

    return (
        <Modal show={true}
            backdrop="static"
            keyboard={false}
            onHide={() => {
                hideModal()
                handleClose && handleClose()
            }}
        >
            <Modal.Header>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Footer>
                <Button variant="primary" onClick={() => {
                    hideModal()
                    handleClose && handleClose()
                }}>
                    {closeButton || "OK"}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}