import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TArticleDM from './TArticleDM'
import TEshopCategoryInfoDM from './TEshopCategoryInfoDM'
import Availability from './Availability'
import TEshopStockTextItemAloneDM from './TEshopStockTextItemAloneDM'
import TLinkDM from './TLinkDM'
import TEshopRelatedArticleDM from './TEshopRelatedArticleDM'
import TEshopMeasureUnitDM from './TEshopMeasureUnitDM'

/**
 * ESGoodsAsChild
 */
export default class TEshopBasketArticleDM extends Data {
	public static className = 'TEshopBasketArticleDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = false

	/**
	 * IsPayment
	 */
	IsPayment?: string

	/**
	 * IsTransport
	 */
	IsTransport?: string

	/**
	 * ArticleShopValueRID
	 */
	ArticleShopValueRID?: number

	/**
	 * IsQuotationRequest
	 */
	IsQuotationRequest?: string

	/**
	 * IsSlaveVariation
	 */
	IsSlaveVariation?: string

	/**
	 * IsBasketDeniedNotLogged
	 */
	IsBasketDeniedNotLogged?: string

	/**
	 * StockTextRID
	 */
	StockTextRID?: number

	/**
	 * VariationSetId
	 */
	VariationSetId?: number

	/**
	 * Currency
	 */
	Currency?: string

	/**
	 * LanguageId
	 */
	LanguageId?: number

	/**
	 * PriceGroupId
	 */
	PriceGroupId?: number

	/**
	 * TradingPartnerId
	 */
	TradingPartnerId?: number

	/**
	 * IsBasketDenied
	 */
	IsBasketDenied?: string

	/**
	 * AvailabilityLength
	 */
	AvailabilityLength?: number

	/**
	 * AvailabilityAbbr
	 */
	AvailabilityAbbr?: string

	/**
	 * Available
	 */
	Available?: number

	/**
	 * BasePriceInt
	 */
	BasePriceInt?: number

	/**
	 * NewsPriorityId
	 */
	NewsPriorityId?: number

	/**
	 * RatingId
	 */
	RatingId?: number

	/**
	 * VisitCount
	 */
	VisitCount?: number

	/**
	 * SoldCount
	 */
	SoldCount?: number

	/**
	 * EshopOriginalPriceNet
	 */
	EshopOriginalPriceNet?: number

	/**
	 * BasePriceNet
	 */
	BasePriceNet?: number

	/**
	 * ShopRID
	 */
	ShopRID?: number

	/**
	 * IsCampaign
	 */
	IsCampaign?: string

	/**
	 * IsComingSoon
	 */
	IsComingSoon?: string

	/**
	 * IsSecondHand
	 */
	IsSecondHand?: string

	/**
	 * IsSellOut
	 */
	IsSellOut?: string

	/**
	 * IsDiscount
	 */
	IsDiscount?: string

	/**
	 * IsTip
	 */
	IsTip?: string

	/**
	 * IsFreeShipping
	 */
	IsFreeShipping?: string

	/**
	 * IsNewArticle
	 */
	IsNewArticle?: string

	/**
	 * WarrantyCompanyLength
	 */
	WarrantyCompanyLength?: number

	/**
	 * WarrantyCompanyAbbr
	 */
	WarrantyCompanyAbbr?: string

	/**
	 * WarrantyCustomerLength
	 */
	WarrantyCustomerLength?: number

	/**
	 * WarrantyCustomerAbbr
	 */
	WarrantyCustomerAbbr?: string

	/**
	 * EshopOrigPrice
	 */
	EshopOrigPrice?: number

	/**
	 * EshopXmlDescription
	 */
	EshopXmlDescription?: string

	/**
	 * EshopXmlTitle
	 */
	EshopXmlTitle?: string

	/**
	 * EshopPageKeyWords
	 */
	EshopPageKeyWords?: string

	/**
	 * EshopPageDescription
	 */
	EshopPageDescription?: string

	/**
	 * EshopPageTitle
	 */
	EshopPageTitle?: string

	/**
	 * EshopNote
	 */
	EshopNote?: string

	/**
	 * EshopShortDescription
	 */
	EshopShortDescription?: string

	/**
	 * EshopArticleGroupId
	 */
	EshopArticleGroupId?: number

	/**
	 * StatusId
	 */
	StatusId?: number

	/**
	 * VATRateId
	 */
	VATRateId?: number

	/**
	 * CustomsTariffId
	 */
	CustomsTariffId?: number

	/**
	 * Description
	 */
	Description?: string

	/**
	 * ParameterSetId
	 */
	ParameterSetId?: number

	/**
	 * ArticleCategoryId
	 */
	ArticleCategoryId?: number

	/**
	 * OriginCountryId
	 */
	OriginCountryId?: number

	/**
	 * RightGroupId
	 */
	RightGroupId?: number

	/**
	 * ArticleTypeId
	 */
	ArticleTypeId?: number

	/**
	 * PackingId
	 */
	PackingId?: number

	/**
	 * BasePrice
	 */
	BasePrice?: number

	/**
	 * Producer
	 */
	Producer?: string

	/**
	 * Name
	 */
	Name?: string

	/**
	 * Abbr2
	 */
	Abbr2?: string

	/**
	 * Abbr1
	 */
	Abbr1?: string

	/**
	 * ArticleGroupId
	 */
	ArticleGroupId?: string

	/**
	 * ArticleId
	 */
	ArticleId?: number

	/**
	 * CouponErrId
	 */
	CouponErrId?: number

	/**
	 * IsVoucher
	 */
	IsVoucher?: string

	/**
	 * CouponRID
	 */
	CouponRID?: number

	/**
	 * SuperiorRID
	 */
	SuperiorRID?: number

	/**
	 * ListPriceNet
	 */
	ListPriceNet?: number

	/**
	 * ListPriceGross
	 */
	ListPriceGross?: number

	/**
	 * IsPercentageDiscount
	 */
	IsPercentageDiscount?: string

	/**
	 * Discount
	 */
	Discount?: number

	/**
	 * ItemDescription
	 */
	ItemDescription?: string

	/**
	 * ItemName
	 */
	ItemName?: string

	/**
	 * PriceNet
	 */
	PriceNet?: number

	/**
	 * PriceGross
	 */
	PriceGross?: number

	/**
	 * SequenceNumber
	 */
	SequenceNumber?: number

	/**
	 * AdditionalInfo
	 */
	AdditionalInfo?: string

	/**
	 * RID
	 */
	RID?: number

	/**
	 * Quantity
	 */
	Quantity?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * RID
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Odkaz
	 */
	PictureCalc?: string

	/**
	 * SEO
	 */
	SEONameCalc?: string

	/**
	 * Impl. jednotka (prodej)
	 */
	DefaultSaleMeasureUnitCalc?: string

	/**
	 * celočís. impl. j.
	 */
	DefaultSaleUnitIntegerIdCalc?: number

	/**
	 * Impl. jednotka (e-shop)
	 */
	DefaultEshopMeasureUnitCalc?: string

	/**
	 * celočís. impl. j.
	 */
	DefaultEshopUnitIntegerIdCalc?: number

	/**
	 * undefined
	 */
	EshopSaleMeasureUnitRatioCalc?: number

	/**
	 * undefined
	 */
	DetailUrlCalc?: string

	/**
	 * undefined
	 */
	DetailUrlAbsCalc?: string

	/**
	 * undefined
	 */
	TranslatedDescriptionCalc?: string

	/**
	 * undefined
	 */
	TranslatedPageTitleCalc?: string

	/**
	 * undefined
	 */
	TranslatedPageDescriptionCalc?: string

	/**
	 * undefined
	 */
	TranslatedKeyWordsCalc?: string

	/**
	 * undefined
	 */
	ComputedPriceNetCalc?: number

	/**
	 * undefined
	 */
	GrossPriceCalc?: number

	/**
	 * undefined
	 */
	OriginPriceNetCalc?: number

	/**
	 * undefined
	 */
	OriginPriceGrossCalc?: number

	/**
	 * undefined
	 */
	EshopMeasureUnitIdCalc?: number

	/**
	 * undefined
	 */
	EshopMeasureUnitRatioCalc?: number

	/**
	 * Skladem
	 */
	AvailableInEShopUnitQuantityCalc?: number

	/**
	 * Množstevní krok
	 */
	EshopMeasureUnitStepQuantityCalc?: number

	/**
	 * undefined
	 */
	IsAddedCalc?: string

	/**
	 * undefined
	 */
	AddedQuantityCalc?: number

	/**
	 * undefined
	 */
	AddedPriceNetCalc?: number

	/**
	 * undefined
	 */
	AddedPriceGrossCalc?: number

	/**
	 * undefined
	 */
	QuantityUMCalc?: number

	/**
	 * undefined
	 */
	EshopBasketPriceNetCalc?: number

	/**
	 * undefined
	 */
	EshopBasketPriceGrossCalc?: number

	/**
	 * undefined
	 */
	EshopBasketTotalPriceNetCalc?: number

	/**
	 * undefined
	 */
	EshopBasketTotalPriceGrossCalc?: number

	/**
	 * undefined
	 */
	ComputedDiscountCalc?: number

	/**
	 * undefined
	 */
	EshopBasketReducedPriceCalc?: number

	/**
	 * undefined
	 */
	EshopBasketReducedTotalPriceNetCalc?: number

	/**
	 * undefined
	 */
	EshopBasketReducedTotalPriceGrossCalc?: number

	/**
	 * RightGroupId
	 */
	RightGroupIdCalc?: number

	/**
	 * MasterId
	 */
	@Type(() => TArticleDM)
	MasterId?: TArticleDM

	/**
	 * MainCategoryRID
	 */
	@Type(() => TEshopCategoryInfoDM)
	MainCategoryRID?: TEshopCategoryInfoDM

	/**
	 * ArticleBindId
	 */
	@Type(() => TArticleDM)
	ArticleBindId?: TArticleDM

	/**
	 * AvailabilityId
	 */
	@Type(() => Availability)
	AvailabilityId?: Availability

	/**
	 * undefined
	 */
	@Type(() => TEshopStockTextItemAloneDM)
	StockTextItemRIDCalc?: TEshopStockTextItemAloneDM

	/**
	 * Odkazy
	 */
	@Type(() => TLinkDM)
	LinkChild?: TLinkDM[]

	/**
	 * undefined
	 */
	@Type(() => TEshopRelatedArticleDM)
	RelatedArticleDetailChild?: TEshopRelatedArticleDM[]

	/**
	 * undefined
	 */
	@Type(() => TEshopMeasureUnitDM)
	MeasureUnitChild?: TEshopMeasureUnitDM[]

	/**
	 * undefined
	 */
	@Type(() => TEshopRelatedArticleDM)
	RelatedArticlePreBasketChild?: TEshopRelatedArticleDM[]

	/**
	 * undefined
	 */
	@Type(() => TEshopRelatedArticleDM)
	RelatedArticleBasketChild?: TEshopRelatedArticleDM[]
}