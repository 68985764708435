import Data from 'react-k2-api/Api/Data'
import { Type } from 'class-transformer'
import TBatchDM from './TBatchDM'
import TCode6DocumentDM from './TCode6DocumentDM'
import TCode5DocumentDM from './TCode5DocumentDM'
import TCode4DocumentDM from './TCode4DocumentDM'
import TCode3DocumentDM from './TCode3DocumentDM'
import TJobCardDM from './TJobCardDM'
import TWarehouseZoneDM from './TWarehouseZoneDM'
import TBookDM from './TBookDM'
import TWMSQueueDM from './TWMSQueueDM'
import Priority from './Priority'
import TDeviceDocumentDM from './TDeviceDocumentDM'
import TTradingPartnerDM from './TTradingPartnerDM'
import TCostCentreCollectionDocumentDM from './TCostCentreCollectionDocumentDM'
import TArticleCodeDocumentDM from './TArticleCodeDocumentDM'
import TCode2DocumentDM from './TCode2DocumentDM'
import TCode1DocumentDM from './TCode1DocumentDM'
import TRoutingVariantDM from './TRoutingVariantDM'
import TInvoiceOutDM from './TInvoiceOutDM'
import TDeliveryNoteDM from './TDeliveryNoteDM'
import TReservingCardDM from './TReservingCardDM'
import TOrderConfirmationDM from './TOrderConfirmationDM'
import TSalesOrderDM from './TSalesOrderDM'
import TInvoiceInDM from './TInvoiceInDM'
import TDeliveryConfirmationDM from './TDeliveryConfirmationDM'
import TPurchaseOrderDM from './TPurchaseOrderDM'
import TOfficerDocumentDM from './TOfficerDocumentDM'
import PlanType from './PlanType'
import THandlingUnitArticleDM from './THandlingUnitArticleDM'
import TLocationArticleDM from './TLocationArticleDM'
import TContractCodeDocumentDM from './TContractCodeDocumentDM'
import TWarehouseDM from './TWarehouseDM'
import TArticleDM from './TArticleDM'
import TMeasureUnitDM from './TMeasureUnitDM'
import TTransferNoteDM from './TTransferNoteDM'
import TReleaseNoteDM from './TReleaseNoteDM'
import TReceiptCardDM from './TReceiptCardDM'
import TPeriodDM from './TPeriodDM'
import TTextDM from './TTextDM'

/**
 * StockCard
 */
export default class TStockCardDM extends Data {
	public static className = 'TStockCardDM'
	public static systemFields = [
		'RecordID',
	]

	public static isHeader = false

	/**
	 * Kniha výroby
	 */
	ProductionBookId?: number

	/**
	 * PlanningJCItem
	 */
	IsPlanningJobCardItem?: string

	/**
	 * Číslo položky
	 */
	ItemNumber?: number

	/**
	 * Výdej na skladové kartě
	 */
	ReleaseQuantity?: number

	/**
	 * Příjem na skladové kartě
	 */
	ReceiptQuantity?: number

	/**
	 * DateTo
	 */
	EndDate?: Date

	/**
	 * DateFrom
	 */
	StartDate?: Date

	/**
	 * Rezervováno
	 */
	ReservationDate?: Date

	/**
	 * Datum dodání
	 */
	DeliveryDate?: Date

	/**
	 * Pož.datum
	 */
	DesiredDeliveryDate?: Date

	/**
	 * Hmotnost
	 */
	Weight?: number

	/**
	 * Fakturovaná cena
	 */
	InvoicePrice?: number

	/**
	 * Vedlejší náklady
	 */
	IncidentalCosts?: number

	/**
	 * Nákladová cena
	 */
	CostPrice?: number

	/**
	 * Skladová cena
	 */
	StockPrice?: number

	/**
	 * Čistá cena
	 */
	StockPriceNet?: number

	/**
	 * Množství
	 */
	Quantity?: number

	/**
	 * Číslo
	 */
	Number?: number

	/**
	 * Datum potvrzení
	 */
	ConfirmedOn?: Date

	/**
	 * Převod
	 */
	TransferSign?: number

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * undefined
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * undefined
	 */
	BatchCoefficient?: number

	/**
	 * undefined
	 */
	BatchMeasureUnit?: string

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Příjem
	 */
	ReceiptCalc?: number

	/**
	 * Výdej
	 */
	ReleaseCalc?: number

	/**
	 * Stav
	 */
	LevelCalc?: number

	/**
	 * Skladová cena
	 */
	StockPriceCalc?: number

	/**
	 * Typ
	 */
	DocumentTypeCalc?: string

	/**
	 * Nákladová cena
	 */
	UnitCostPriceCalc?: number

	/**
	 * Doklad
	 */
	DocumentCalc?: string

	/**
	 * Saldo - množství
	 */
	OpenItemQuantityCalc?: number

	/**
	 * Saldo - částka
	 */
	OpenItemAmountCalc?: number

	/**
	 * Měna firmy
	 */
	CompanyCurrencyCalc?: string

	/**
	 * Obrat - množství - příjem
	 */
	TurnoverReceiptQuantityCalc?: number

	/**
	 * Obrat - množství - výdej
	 */
	TurnoverReleaseQuantityCalc?: number

	/**
	 * Obrat - částka - příjem
	 */
	TurnoverReceiptAmountCalc?: number

	/**
	 * Obrat - částka - výdej
	 */
	TurnoverReleaseAmountCalc?: number

	/**
	 * Zůstatek - množství
	 */
	QuantityBalanceCalc?: number

	/**
	 * Zůstatek - částka
	 */
	BalanceCalc?: number

	/**
	 * Počáteční stav - množství
	 */
	LevelBaseQuantityCalc?: number

	/**
	 * Počáteční stav - částka
	 */
	LevelBaseAmountCalc?: number

	/**
	 * Koeficient skladové jednotky
	 */
	MeasureUnitCoefficientCalc?: number

	/**
	 * Interní převodky
	 */
	ShowInternalTransferNoteCalc?: string

	/**
	 * undefined
	 */
	LevelQuantityCalc?: number

	/**
	 * Externí šarže
	 */
	@Type(() => TBatchDM)
	ExternalBatchId?: TBatchDM

	/**
	 * Kód 6
	 */
	@Type(() => TCode6DocumentDM)
	Code6Id?: TCode6DocumentDM

	/**
	 * Kód 5
	 */
	@Type(() => TCode5DocumentDM)
	Code5Id?: TCode5DocumentDM

	/**
	 * Kód 4
	 */
	@Type(() => TCode4DocumentDM)
	Code4Id?: TCode4DocumentDM

	/**
	 * Kód 3
	 */
	@Type(() => TCode3DocumentDM)
	Code3Id?: TCode3DocumentDM

	/**
	 * Průvodka
	 */
	@Type(() => TJobCardDM)
	JobCardRID?: TJobCardDM

	/**
	 * Na zónu
	 */
	@Type(() => TWarehouseZoneDM)
	TransferWarehouseZoneRID?: TWarehouseZoneDM

	/**
	 * Zóna
	 */
	@Type(() => TWarehouseZoneDM)
	WarehouseZoneRID?: TWarehouseZoneDM

	/**
	 * Kniha skladu
	 */
	@Type(() => TBookDM)
	WarehouseBookId?: TBookDM

	/**
	 * Kniha prodeje
	 */
	@Type(() => TBookDM)
	SalesBookId?: TBookDM

	/**
	 * Kniha nákupu
	 */
	@Type(() => TBookDM)
	PurchaseBookId?: TBookDM

	/**
	 * Fronta
	 */
	@Type(() => TWMSQueueDM)
	QueueId?: TWMSQueueDM

	/**
	 * Priority
	 */
	@Type(() => Priority)
	PriorityId?: Priority

	/**
	 * Prostředek
	 */
	@Type(() => TDeviceDocumentDM)
	DeviceRID?: TDeviceDocumentDM

	/**
	 * Dodavatel
	 */
	@Type(() => TTradingPartnerDM)
	SupplierId?: TTradingPartnerDM

	/**
	 * Středisko
	 */
	@Type(() => TCostCentreCollectionDocumentDM)
	CostCentreId?: TCostCentreCollectionDocumentDM

	/**
	 * Kód zboží
	 */
	@Type(() => TArticleCodeDocumentDM)
	ArticleCodeId?: TArticleCodeDocumentDM

	/**
	 * Kód 2
	 */
	@Type(() => TCode2DocumentDM)
	Code2Id?: TCode2DocumentDM

	/**
	 * Kód 1
	 */
	@Type(() => TCode1DocumentDM)
	Code1Id?: TCode1DocumentDM

	/**
	 * Varianta TP
	 */
	@Type(() => TRoutingVariantDM)
	RoutingVariantId?: TRoutingVariantDM

	/**
	 * Faktura vydaná
	 */
	@Type(() => TInvoiceOutDM)
	InvoiceOutRID?: TInvoiceOutDM

	/**
	 * Dodací list
	 */
	@Type(() => TDeliveryNoteDM)
	DeliveryNoteRID?: TDeliveryNoteDM

	/**
	 * Rezervační list
	 */
	@Type(() => TReservingCardDM)
	ReservingCardRID?: TReservingCardDM

	/**
	 * Objednávka přijatá
	 */
	@Type(() => TOrderConfirmationDM)
	OrderConfirmationRID?: TOrderConfirmationDM

	/**
	 * Zakázka
	 */
	@Type(() => TSalesOrderDM)
	SalesOrderRID?: TSalesOrderDM

	/**
	 * Faktura přijatá
	 */
	@Type(() => TInvoiceInDM)
	InvoiceInRID?: TInvoiceInDM

	/**
	 * Potvrzení dodání
	 */
	@Type(() => TDeliveryConfirmationDM)
	DeliveryConfirmationRID?: TDeliveryConfirmationDM

	/**
	 * Objednávka
	 */
	@Type(() => TPurchaseOrderDM)
	PurchaseOrderRID?: TPurchaseOrderDM

	/**
	 * Zákazník
	 */
	@Type(() => TTradingPartnerDM)
	MainDocumentTradingPartnerId?: TTradingPartnerDM

	/**
	 * Referent
	 */
	@Type(() => TOfficerDocumentDM)
	OfficerId?: TOfficerDocumentDM

	/**
	 * Skladník
	 */
	@Type(() => TOfficerDocumentDM)
	WarehousemanId?: TOfficerDocumentDM

	/**
	 * Typ plánu
	 */
	@Type(() => PlanType)
	PlanTypeId?: PlanType

	/**
	 * Manipul.jedn.
	 */
	@Type(() => THandlingUnitArticleDM)
	HandlingUnitRID?: THandlingUnitArticleDM

	/**
	 * Umístění
	 */
	@Type(() => TLocationArticleDM)
	LocationId?: TLocationArticleDM

	/**
	 * Šarže
	 */
	@Type(() => TBatchDM)
	BatchId?: TBatchDM

	/**
	 * Kód zakázky
	 */
	@Type(() => TContractCodeDocumentDM)
	ContractCodeRID?: TContractCodeDocumentDM

	/**
	 * Sklad převodky
	 */
	@Type(() => TWarehouseDM)
	TransferWarehouseId?: TWarehouseDM

	/**
	 * Plán.sklad
	 */
	@Type(() => TWarehouseDM)
	PlannedWarehouseId?: TWarehouseDM

	/**
	 * Sklad
	 */
	@Type(() => TWarehouseDM)
	WarehouseId?: TWarehouseDM

	/**
	 * Zboží
	 */
	@Type(() => TArticleDM)
	ArticleId?: TArticleDM

	/**
	 * Jednotka
	 */
	@Type(() => TMeasureUnitDM)
	MeasureUnitId?: TMeasureUnitDM

	/**
	 * Převodka
	 */
	@Type(() => TTransferNoteDM)
	TransferNoteRID?: TTransferNoteDM

	/**
	 * Výdejka
	 */
	@Type(() => TReleaseNoteDM)
	ReleaseNoteRID?: TReleaseNoteDM

	/**
	 * Příjemka
	 */
	@Type(() => TReceiptCardDM)
	ReceiptCardRID?: TReceiptCardDM

	/**
	 * Období
	 */
	@Type(() => TPeriodDM)
	BusinessYearId?: TPeriodDM

	/**
	 * Kniha
	 */
	@Type(() => TBookDM)
	BookId?: TBookDM

	/**
	 * Typ
	 */
	@Type(() => TTextDM)
	ItemTypeId?: TTextDM
}