import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TK2UserDM from './TK2UserDM'
import TTradingPartnerDM from './TTradingPartnerDM'

/**
 * Grid Vybrani  ceny
 */
export default class TCustomTPurchaseItemDMGrid_Vybrani_ceny extends Data {
	public static className = 'TCustomTPurchaseItemDMGrid_Vybrani_ceny'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = false

	/**
	 * Master
	 */
	Master?: number

	/**
	 * % k C0
	 */
	Proc_k_C0?: number

	/**
	 * Cena Kc
	 */
	CenaKc?: number

	/**
	 * CenaEUR
	 */
	CenaEUR?: number

	/**
	 * Disp
	 */
	Disp?: number

	/**
	 * UniqID
	 */
	UniqID?: number

	/**
	 * NodeID
	 */
	NodeID?: number

	/**
	 * DLCH
	 */
	DLCH?: Date

	/**
	 * Kod
	 */
	Kod?: string

	/**
	 * Mena
	 */
	Mena?: string

	/**
	 * Cena
	 */
	Cena?: number

	/**
	 * Mnoz
	 */
	Mnoz?: number

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * RecordMod
	 */
	RecordMod?: number

	/**
	 * RecordES
	 */
	RecordES?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * RecordState
	 */
	RecordState?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * DLCH Who
	 */
	@Type(() => TK2UserDM)
	DLCH_Who?: TK2UserDM

	/**
	 * CDo
	 */
	@Type(() => TTradingPartnerDM)
	CDo?: TTradingPartnerDM
}