import Data from 'react-k2-api/Api/Data'
import { Type } from 'class-transformer'
import TAccountingChartDM from './TAccountingChartDM'
import TPaymentMethodDM from './TPaymentMethodDM'
import TOfficerDocumentDM from './TOfficerDocumentDM'
import TCostCentreCollectionDocumentDM from './TCostCentreCollectionDocumentDM'
import TTextDM from './TTextDM'
import TPeriodDM from './TPeriodDM'
import TCurrencyDM from './TCurrencyDM'
import TTradingPartnerDM from './TTradingPartnerDM'
import InvoiceOutStatus from './InvoiceOutStatus'
import InvoiceInStatus from './InvoiceInStatus'
import AdvanceProvidedStatus from './AdvanceProvidedStatus'
import AdvanceReceivedStatus from './AdvanceReceivedStatus'
import OtherLiabilityStatus from './OtherLiabilityStatus'
import OtherReceivableStatus from './OtherReceivableStatus'
import TAdvanceDeductionAdvanceDM from './TAdvanceDeductionAdvanceDM'

/**
 * Saldokonto
 */
export default class TOpenItemListDM extends Data {
	public static className = 'TOpenItemListDM'
	public static systemFields = [
	]

	public static isHeader = true

	/**
	 * Dny prodlení k datu
	 */
	DelayDaysToDate?: number

	/**
	 * Ostatní pohledávka
	 */
	OtherReceivableRID?: number

	/**
	 * Ostatní závazek
	 */
	OtherLiabilityRID?: number

	/**
	 * Záloha přijatá
	 */
	AdvanceReceivedRID?: number

	/**
	 * Záloha vydaná
	 */
	AdvanceProvidedRID?: number

	/**
	 * Faktura přijatá
	 */
	InvoiceInRID?: number

	/**
	 * Faktura vydaná
	 */
	InvoiceOutRID?: number

	/**
	 * Brutto M
	 */
	AmountGrossC?: number

	/**
	 * Popis
	 */
	Description?: string

	/**
	 * Variabilní symbol
	 */
	ReferenceNumber?: string

	/**
	 * Datum UZP
	 */
	TaxPointDate?: Date

	/**
	 * Datum úč. případu
	 */
	AccountingTransactionDate?: Date

	/**
	 * Položka zálohy
	 */
	ItemRID?: number

	/**
	 * K.
	 */
	DocumentAbbr?: string

	/**
	 * Stav dokladu
	 */
	DocumentStatusId?: number

	/**
	 * Brutto
	 */
	AmountGross?: number

	/**
	 * Zaplac./Čerp.
	 */
	AmountPaid?: number

	/**
	 * Zaplac. M/Čerp. M
	 */
	AmountPaidC?: number

	/**
	 * Číslo
	 */
	Id?: number

	/**
	 * Dopl./Dočerp.
	 */
	RemainingAmount?: number

	/**
	 * Dopl. M/Dočerp. M
	 */
	RemainingAmountC?: number

	/**
	 * Brutto M
	 */
	BankOrderAmountGrossC?: number

	/**
	 * Splatno
	 */
	DueDate?: Date

	/**
	 * Číslo
	 */
	DocumentRID?: number

	/**
	 * K.
	 */
	DocumentBookId?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * SQLSequenceOrderCalc
	 */
	SQLSequenceOrderCalc?: number

	/**
	 * Faktura vydaná
	 */
	InvoiceOutRIDCalc?: number

	/**
	 * Faktura přijatá
	 */
	InvoiceInRIDCalc?: number

	/**
	 * Záloha vydaná
	 */
	AdvanceProvidedRIDCalc?: number

	/**
	 * Záloha přijatá
	 */
	AdvanceReceivedRIDCalc?: number

	/**
	 * Dopl./Dočerp.
	 */
	RemainingAmountCalc?: number

	/**
	 * Dopl. M/Dočerp. M
	 */
	RemainingAmountCCalc?: number

	/**
	 * Datum úč. případu
	 */
	AccountingTransactionDateCalc?: Date

	/**
	 * Datum UZP
	 */
	TaxPointDateCalc?: Date

	/**
	 * Variabilní symbol
	 */
	ReferenceNumberCalc?: string

	/**
	 * Popis
	 */
	DescriptionCalc?: string

	/**
	 * Dny prodlení
	 */
	DelayDaysCalc?: number

	/**
	 * Úroky M
	 */
	LateChargesCCalc?: number

	/**
	 * Úroky
	 */
	LateChargesCalc?: number

	/**
	 * Stav dokladu
	 */
	DocumentStatusCalc?: string

	/**
	 * Dopl. M/Dočerp. M
	 */
	OldRemainingAmountCCalc?: number

	/**
	 * Dopl./Dočerp.
	 */
	OldRemainingAmountCalc?: number

	/**
	 * Zaplac. M/Čerp. M
	 */
	AmountDeductedPaidCCalc?: number

	/**
	 * Zaplac./Čerp.
	 */
	AmountDeductedPaidCalc?: number

	/**
	 * Brutto M
	 */
	PriceGrossCCalc?: number

	/**
	 * Zaplaceno
	 */
	PaidTypeIdCalc?: number

	/**
	 * Ostatní závazek
	 */
	OtherLiabilityRIDCalc?: number

	/**
	 * Ostatní pohledávka
	 */
	OtherReceivableRIDCalc?: number

	/**
	 * Doklad
	 */
	DocumentIdentificationCalc?: string

	/**
	 * Počet upomínek
	 */
	RemindersNumberCalc?: number

	/**
	 * Dat. vyst. upomínky
	 */
	ReminderIssueDateCalc?: Date

	/**
	 * Firma
	 */
	DocumentBookCompanyIdCalc?: number

	/**
	 * Účet
	 */
	@Type(() => TAccountingChartDM)
	AccountId?: TAccountingChartDM

	/**
	 * Způsob platby
	 */
	@Type(() => TPaymentMethodDM)
	PaymentMethod?: TPaymentMethodDM

	/**
	 * Referent
	 */
	@Type(() => TOfficerDocumentDM)
	Officer2Id?: TOfficerDocumentDM

	/**
	 * Středisko
	 */
	@Type(() => TCostCentreCollectionDocumentDM)
	CostCentreId?: TCostCentreCollectionDocumentDM

	/**
	 * Typ dokladu
	 */
	@Type(() => TTextDM)
	DocumentTableId?: TTextDM

	/**
	 * Ob.
	 */
	@Type(() => TPeriodDM)
	BusinessYearId?: TPeriodDM

	/**
	 * Měna
	 */
	@Type(() => TCurrencyDM)
	Currency?: TCurrencyDM

	/**
	 * Firma
	 */
	@Type(() => TTradingPartnerDM)
	TradingPartnerId?: TTradingPartnerDM

	/**
	 * Typ
	 */
	@Type(() => TTextDM)
	DocumentTypeId?: TTextDM

	/**
	 * Stav VF
	 */
	@Type(() => InvoiceOutStatus)
	InvoiceOutStatusIdCalc?: InvoiceOutStatus

	/**
	 * Stav PF
	 */
	@Type(() => InvoiceInStatus)
	InvoiceInStatusIdCalc?: InvoiceInStatus

	/**
	 * Stav VZ
	 */
	@Type(() => AdvanceProvidedStatus)
	AdvanceProvidedStatusIdCalc?: AdvanceProvidedStatus

	/**
	 * Stav PZ
	 */
	@Type(() => AdvanceReceivedStatus)
	AdvanceReceivedStatusIdCalc?: AdvanceReceivedStatus

	/**
	 * Potvrzeno
	 */
	@Type(() => TTextDM)
	ConfirmedBitCalc?: TTextDM

	/**
	 * Stav OV
	 */
	@Type(() => OtherLiabilityStatus)
	OtherLiabilityStatusIdCalc?: OtherLiabilityStatus

	/**
	 * Stav OP
	 */
	@Type(() => OtherReceivableStatus)
	OtherReceivableStatusIdCalc?: OtherReceivableStatus

	/**
	 * Způsob platby
	 */
	@Type(() => TPaymentMethodDM)
	PaymentMethodCalc?: TPaymentMethodDM

	/**
	 * Středisko
	 */
	@Type(() => TCostCentreCollectionDocumentDM)
	CostCentreIdCalc?: TCostCentreCollectionDocumentDM

	/**
	 * Referent
	 */
	@Type(() => TOfficerDocumentDM)
	OfficerIdCalc?: TOfficerDocumentDM

	/**
	 * Čerpání
	 */
	@Type(() => TAdvanceDeductionAdvanceDM)
	AdvanceDeductionChild?: TAdvanceDeductionAdvanceDM[]

	/**
	 * Změna skupiny práv
	 */
	public ChangeGroupRightCOMMAND() {
		return this.executeCommand('ChangeGroupRightCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public BulkChangeGroupRightCOMMAND() {
		return this.executeCommand('BulkChangeGroupRightCOMMAND')
	}

	/**
	 * Aktualizace počítaných polí
	 */
	public BulkUpdateCalcFieldsCOMMAND() {
		return this.executeCommand('BulkUpdateCalcFieldsCOMMAND')
	}

	/**
	 * Kontrola legislativy
	 */
	public SwitchOffLegislationValidationCOMMAND() {
		return this.executeCommand('SwitchOffLegislationValidationCOMMAND')
	}
}