import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import ABCClass from './ABCClass'
import TABCAnalysisDM from './TABCAnalysisDM'
import TArticleDM from './TArticleDM'
import TTradingPartnerDM from './TTradingPartnerDM'

/**
 * ABC Analýza - Položky
 */
export default class TABCItemChartSourceDM extends Data {
	public static className = 'TABCItemChartSourceDM'
	public static primaryKey = 'RID'
	public static systemFields = [
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = true

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * Modul
	 */
	DataModuleNumber?: number

	/**
	 * Datum do
	 */
	DateTo?: Date

	/**
	 * Konfigurace
	 */
	ABCConfigRID?: number

	/**
	 * % kumulovaně
	 */
	PercentageSum?: number

	/**
	 * % z celku
	 */
	Percentage?: number

	/**
	 * Hodnota
	 */
	ItemValue?: number

	/**
	 * Pořadí
	 */
	ItemNumber?: number

	/**
	 * Číslo
	 */
	Id?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * undefined
	 */
	TrendCalc?: number

	/**
	 * undefined
	 */
	AbbrCalc?: string

	/**
	 * undefined
	 */
	NameCalc?: string

	/**
	 * Skupina
	 */
	@Type(() => ABCClass)
	ABCClassId?: ABCClass

	/**
	 * ABC Analýza
	 */
	@Type(() => TABCAnalysisDM)
	ABCAnalysisRID?: TABCAnalysisDM

	/**
	 * undefined
	 */
	@Type(() => TArticleDM)
	ArticleIdCalc?: TArticleDM

	/**
	 * undefined
	 */
	@Type(() => TTradingPartnerDM)
	TradingPartnerIdCalc?: TTradingPartnerDM

	/**
	 * Změna skupiny práv
	 */
	public ChangeGroupRightCOMMAND() {
		return this.executeCommand('ChangeGroupRightCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public BulkChangeGroupRightCOMMAND() {
		return this.executeCommand('BulkChangeGroupRightCOMMAND')
	}

	/**
	 * Aktualizace počítaných polí
	 */
	public BulkUpdateCalcFieldsCOMMAND() {
		return this.executeCommand('BulkUpdateCalcFieldsCOMMAND')
	}

	/**
	 * Kontrola legislativy
	 */
	public SwitchOffLegislationValidationCOMMAND() {
		return this.executeCommand('SwitchOffLegislationValidationCOMMAND')
	}
}