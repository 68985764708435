import 'reflect-metadata'
import React from 'react'
import ReactDOM from 'react-dom/client'
import App from 'components/App'
import reportWebVitals from './reportWebVitals'
import { ErrorBoundary, FallbackProps } from 'react-error-boundary'
import { AxiosError } from 'axios'
import { Container, Button } from 'react-bootstrap'
import GlobalModal from 'components/GlobalModal'
import './index.scss'

const ErrorFallback = ({ error, resetErrorBoundary }: FallbackProps) => {
  const message = error instanceof AxiosError ?
    (error as AxiosError).response?.data as string :
    error.message

  return (
    <Container className="mt-5 text-center">
      <i className="bi bi-exclamation-triangle-fill text-danger h1" />
      <p className="h2">Chyba za běhu aplikace</p>
      <p className="lead mb-5">{message}</p>
      <Button onClick={resetErrorBoundary}>Zkusit znovu <i className="bi bi-arrow-clockwise" /></Button>
    </Container>
  )
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

root.render(
  <React.StrictMode>
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <GlobalModal>
        <App />
      </GlobalModal>
    </ErrorBoundary>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
