import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TCode6DocumentDM from './TCode6DocumentDM'
import TCode5DocumentDM from './TCode5DocumentDM'
import TCode4DocumentDM from './TCode4DocumentDM'
import TCode3DocumentDM from './TCode3DocumentDM'
import TDeviceDocumentDM from './TDeviceDocumentDM'
import TBanksDM from './TBanksDM'
import TAccountingChartDM from './TAccountingChartDM'
import TMatchingSymbolDM from './TMatchingSymbolDM'
import TOfficerDocumentDM from './TOfficerDocumentDM'
import TCode2DocumentDM from './TCode2DocumentDM'
import TCode1DocumentDM from './TCode1DocumentDM'
import TArticleCodeDocumentDM from './TArticleCodeDocumentDM'
import TContractCodeDocumentDM from './TContractCodeDocumentDM'
import TCostCentreCollectionDocumentDM from './TCostCentreCollectionDocumentDM'
import TVATRateDM from './TVATRateDM'
import TTaxTypeDM from './TTaxTypeDM'
import TCurrencyDM from './TCurrencyDM'
import TTradingPartnerDM from './TTradingPartnerDM'
import TTextDM from './TTextDM'
import TInvoiceInDM from './TInvoiceInDM'
import TInvoiceOutDM from './TInvoiceOutDM'
import TAdvanceProvidedDM from './TAdvanceProvidedDM'
import TAdvanceReceivedDM from './TAdvanceReceivedDM'
import TPrLiabilityDM from './TPrLiabilityDM'
import AlternativeUnit from './AlternativeUnit'

/**
 * Položky platebních dokladů
 */
export default class TPaymentDocumentItemAnalyticalDimensionDM extends Data {
	public static className = 'TPaymentDocumentItemAnalyticalDimensionDM'
	public static primaryKey = 'RID'
	public static systemFields = [
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = true

	/**
	 * Externí reference
	 */
	ExternalReference?: string

	/**
	 * Kniha
	 */
	BookId?: number

	/**
	 * Číslo PD
	 */
	PaidDocumentRID?: number

	/**
	 * Adresa
	 */
	AddressId?: number

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * Číslo dokladu
	 */
	HeaderRID?: number

	/**
	 * Doplň. informace
	 */
	AdditionalInfo?: string

	/**
	 * PLK
	 */
	PaymentCalendarId?: number

	/**
	 * Právo na záznam
	 */
	RightGroupId?: number

	/**
	 * Kontace
	 */
	PostingKeyId?: number

	/**
	 * K. symbol
	 */
	ConstantSymbolId?: string

	/**
	 * Bank. příkaz
	 */
	BankOrderItemId?: number

	/**
	 * Sumace
	 */
	RowId?: number

	/**
	 * Zaplaceno
	 */
	AmountPaid?: number

	/**
	 * Zaplaceno M
	 */
	AmountPaidC?: number

	/**
	 * DPH
	 */
	AmountVAT?: number

	/**
	 * DPH M
	 */
	AmountVatC?: number

	/**
	 * &NettoC
	 */
	AmountNet?: number

	/**
	 * &NettoC
	 */
	AmountNetC?: number

	/**
	 * Kurz z PD
	 */
	PaidDocumentExchangeRate?: number

	/**
	 * Kurz PD
	 */
	PaidItemExchangeRate?: number

	/**
	 * Kurz
	 */
	ExchangeRate?: number

	/**
	 * Datum UZP
	 */
	TaxPointDate?: Date

	/**
	 * Datum úč. příp.
	 */
	AccountingTransactionDate?: Date

	/**
	 * &Variabilní symbol
	 */
	ReferenceNumber?: string

	/**
	 * Protiúčet
	 */
	Description2?: string

	/**
	 * Popis
	 */
	Description?: string

	/**
	 * Číslo karty
	 */
	PaidDocumentCardId?: number

	/**
	 * Číslo PD
	 */
	PaidDocumentIdOld?: number

	/**
	 * Kniha PD
	 */
	PaidDocumentBookOld?: string

	/**
	 * Typ PD
	 */
	PaidDocumentTypeId?: number

	/**
	 * Typ p.
	 */
	ItemTypeId?: number

	/**
	 * Pořadí
	 */
	SequenceNumber?: number

	/**
	 * Číslo položky
	 */
	ItemId?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Var. symbol PF
	 */
	ReferenceNumberInvoiceInCalc?: string

	/**
	 * Var. symbol VF
	 */
	ReferenceNumberInvoiceOutCalc?: string

	/**
	 * Var. symbol VZ
	 */
	ReferenceNumberAdvProvidedCalc?: string

	/**
	 * Var. symbol PZ
	 */
	ReferenceNumberAdvReceivedCalc?: string

	/**
	 * Kurz platby
	 */
	ExchangeRateCalc?: number

	/**
	 * Kurz placeného dokladu
	 */
	DocumentExchangeRateCalc?: number

	/**
	 * Kurz platby v měně placeného dokladu
	 */
	PaidDocumentExchangeRateCalc?: number

	/**
	 * Var. symbol MZ
	 */
	ReferenceNumberPayrollCalc?: string

	/**
	 * Zbývá doplatit
	 */
	RemainingAmountCCalc?: number

	/**
	 * Zbývá doplatit
	 */
	RemainingAmountCalc?: number

	/**
	 * Číslo OP
	 */
	OtherReceivableRIDCalc?: number

	/**
	 * Číslo OV
	 */
	OtherLiabilityRIDCalc?: number

	/**
	 * Var.symbol OP
	 */
	ReferenceNumberOtherReceivableCalc?: string

	/**
	 * Var.symbol OV
	 */
	ReferenceNumberPurchaseOrderCalc?: string

	/**
	 * Placený doklad
	 */
	PaidDocumentNameCalc?: string

	/**
	 * Datum dod./odb.
	 */
	CompanyDateCalc?: Date

	/**
	 * Datum úč. případu
	 */
	AccountingTransactionDateCalc?: Date

	/**
	 * Doklad
	 */
	DocumentIdentificationCalc?: string

	/**
	 * Typ položky
	 */
	ItemTypeCalc?: string

	/**
	 * Netto M
	 */
	AmountNetCCalc?: number

	/**
	 * Netto
	 */
	AmountNetCalc?: number

	/**
	 * Bank. výpis
	 */
	BankStatementRIDCalc?: number

	/**
	 * Pokl. doklad
	 */
	CashDocumentRIDCalc?: number

	/**
	 * Int. doklad
	 */
	InternalDocumentRIDCalc?: number

	/**
	 * Kontace IN
	 */
	PostingKeyInternalDocumentIdCalc?: number

	/**
	 * Kontace BV
	 */
	PostingKeyBankStatementIdCalc?: number

	/**
	 * Kontace PO
	 */
	PostingKeyCashDocumentIdCalc?: number

	/**
	 * Zkratka kontace
	 */
	PostingKeyAbbrCalc?: string

	/**
	 * Množství
	 */
	QuantityCalc?: number

	/**
	 * Brutto M
	 */
	AmountGrossCCalc?: number

	/**
	 * Brutto
	 */
	AmountGrossCalc?: number

	/**
	 * Právo na záznam
	 */
	RightGroupIdCalc?: number

	/**
	 * Kód 6
	 */
	@Type(() => TCode6DocumentDM)
	Code6Id?: TCode6DocumentDM

	/**
	 * Kód 5
	 */
	@Type(() => TCode5DocumentDM)
	Code5Id?: TCode5DocumentDM

	/**
	 * Kód 4
	 */
	@Type(() => TCode4DocumentDM)
	Code4Id?: TCode4DocumentDM

	/**
	 * Kód 3
	 */
	@Type(() => TCode3DocumentDM)
	Code3Id?: TCode3DocumentDM

	/**
	 * Prostředek
	 */
	@Type(() => TDeviceDocumentDM)
	DeviceRID?: TDeviceDocumentDM

	/**
	 * Kód banky
	 */
	@Type(() => TBanksDM)
	BankId?: TBanksDM

	/**
	 * Učet 2
	 */
	@Type(() => TAccountingChartDM)
	Account2Id?: TAccountingChartDM

	/**
	 * Učet 1
	 */
	@Type(() => TAccountingChartDM)
	Account1Id?: TAccountingChartDM

	/**
	 * Párovací symbol
	 */
	@Type(() => TMatchingSymbolDM)
	MatchingSymbolId?: TMatchingSymbolDM

	/**
	 * Referent
	 */
	@Type(() => TOfficerDocumentDM)
	OfficerId?: TOfficerDocumentDM

	/**
	 * Kód 2
	 */
	@Type(() => TCode2DocumentDM)
	Code2Id?: TCode2DocumentDM

	/**
	 * Kód 1
	 */
	@Type(() => TCode1DocumentDM)
	Code1Id?: TCode1DocumentDM

	/**
	 * Kód zboží
	 */
	@Type(() => TArticleCodeDocumentDM)
	ArticleCodeId?: TArticleCodeDocumentDM

	/**
	 * Zakázka
	 */
	@Type(() => TContractCodeDocumentDM)
	ContractCodeRID?: TContractCodeDocumentDM

	/**
	 * Středisko
	 */
	@Type(() => TCostCentreCollectionDocumentDM)
	CostCentreId?: TCostCentreCollectionDocumentDM

	/**
	 * Sazba daně
	 */
	@Type(() => TVATRateDM)
	VATRateId?: TVATRateDM

	/**
	 * Typ daně
	 */
	@Type(() => TTaxTypeDM)
	TaxTypeId?: TTaxTypeDM

	/**
	 * Měna PD
	 */
	@Type(() => TCurrencyDM)
	PaidDocumentCurrency?: TCurrencyDM

	/**
	 * Měna
	 */
	@Type(() => TCurrencyDM)
	Currency?: TCurrencyDM

	/**
	 * Dod/Odb
	 */
	@Type(() => TTradingPartnerDM)
	TradingPartnerId?: TTradingPartnerDM

	/**
	 * Typ dokladu
	 */
	@Type(() => TTextDM)
	HeaderTypeId?: TTextDM

	/**
	 * Číslo PF
	 */
	@Type(() => TInvoiceInDM)
	InvoiceInRIDCalc?: TInvoiceInDM

	/**
	 * Číslo VF
	 */
	@Type(() => TInvoiceOutDM)
	InvoiceOutRIDCalc?: TInvoiceOutDM

	/**
	 * Číslo VZ
	 */
	@Type(() => TAdvanceProvidedDM)
	AdvanceProvidedRIDCalc?: TAdvanceProvidedDM

	/**
	 * Číslo PZ
	 */
	@Type(() => TAdvanceReceivedDM)
	AdvanceReceivedRIDCalc?: TAdvanceReceivedDM

	/**
	 * Číslo MZ
	 */
	@Type(() => TPrLiabilityDM)
	PayrollDocumentRIDCalc?: TPrLiabilityDM

	/**
	 * Účet DPH
	 */
	@Type(() => TAccountingChartDM)
	VATAccountIdCalc?: TAccountingChartDM

	/**
	 * Typ položky pokl. d.
	 */
	@Type(() => TTextDM)
	CashDocumentItemTypeIdCalc?: TTextDM

	/**
	 * Typ položky int. d.
	 */
	@Type(() => TTextDM)
	InternalDocumentItemTypeIdCalc?: TTextDM

	/**
	 * Typ položky bank. výpisu
	 */
	@Type(() => TTextDM)
	BankStatementItemTypeIdCalc?: TTextDM

	/**
	 * P/S
	 */
	@Type(() => TTextDM)
	ConfirmedOrCanceledIdCalc?: TTextDM

	/**
	 * Alt.j.
	 */
	@Type(() => AlternativeUnit)
	MeasureUnitIdCalc?: AlternativeUnit

	/**
	 * Účet MD
	 */
	@Type(() => TAccountingChartDM)
	DebitAccountIdCalc?: TAccountingChartDM

	/**
	 * Účet D
	 */
	@Type(() => TAccountingChartDM)
	CreditAccountIdCalc?: TAccountingChartDM

	/**
	 * Změna skupiny práv
	 */
	public ChangeGroupRightCOMMAND() {
		return this.executeCommand('ChangeGroupRightCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public BulkChangeGroupRightCOMMAND() {
		return this.executeCommand('BulkChangeGroupRightCOMMAND')
	}

	/**
	 * Aktualizace počítaných polí
	 */
	public BulkUpdateCalcFieldsCOMMAND() {
		return this.executeCommand('BulkUpdateCalcFieldsCOMMAND')
	}

	/**
	 * Kontrola legislativy
	 */
	public SwitchOffLegislationValidationCOMMAND() {
		return this.executeCommand('SwitchOffLegislationValidationCOMMAND')
	}
}