import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TEshopCompEngineCategoryDM from './TEshopCompEngineCategoryDM'
import TTextDM from './TTextDM'

/**
 * Kategorie srovnávačů cen pro zboží
 */
export default class TEshopArticleCompEngineCategoryDM extends Data {
	public static className = 'TEshopArticleCompEngineCategoryDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = false

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * Zboží
	 */
	ArticleId?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Kategorie srovnávače
	 */
	@Type(() => TEshopCompEngineCategoryDM)
	CategoryRID?: TEshopCompEngineCategoryDM

	/**
	 * Typ srovnávače
	 */
	@Type(() => TTextDM)
	ComparisonEngineId?: TTextDM
}