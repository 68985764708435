import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TTaxTypeDM from './TTaxTypeDM'
import TVATRateDM from './TVATRateDM'
import TTextDM from './TTextDM'

/**
 * Daňová rekapitulace
 */
export default class TTaxSummaryDM extends Data {
	public static className = 'TTaxSummaryDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = false

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * Identifikace hlavičky
	 */
	HeaderRID?: number

	/**
	 * Typ hlavičky
	 */
	HeaderTypeId?: number

	/**
	 * Brutto M
	 */
	AmountGrossC?: number

	/**
	 * DPH M
	 */
	AmountVATC?: number

	/**
	 * Netto M
	 */
	AmountNetC?: number

	/**
	 * &Brutto cena
	 */
	AmountGross?: number

	/**
	 * &DPH
	 */
	AmountVAT?: number

	/**
	 * &Netto cena
	 */
	AmountNet?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * undefined
	 */
	NormalItemsCount?: number

	/**
	 * undefined
	 */
	TotalNetC?: number

	/**
	 * undefined
	 */
	TotalNet?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Měna hl.
	 */
	HeaderCurrencyCalc?: string

	/**
	 * Měna cng
	 */
	CompanyCurrencyCalc?: string

	/**
	 * Netto cena položek
	 */
	ItemAmountNetCalc?: number

	/**
	 * DPH S
	 */
	ItemAmountVATCalc?: number

	/**
	 * Netto SB
	 */
	ItemAmountNetCCalc?: number

	/**
	 * Netto MD
	 */
	MultiDocumentAmountNetCCalc?: number

	/**
	 * Úč. netto
	 */
	AccountAmountNetCalc?: number

	/**
	 * Úč. DPH
	 */
	AccountAmountVATCalc?: number

	/**
	 * Typ daně
	 */
	@Type(() => TTaxTypeDM)
	TaxTypeId?: TTaxTypeDM

	/**
	 * Daň
	 */
	@Type(() => TVATRateDM)
	VATRateId?: TVATRateDM

	/**
	 * undefined
	 */
	@Type(() => TTextDM)
	ChangedAmountBitCalc?: TTextDM

	/**
	 * DPH
	 */
	@Type(() => TTextDM)
	VATSwitchBitCalc?: TTextDM

	/**
	 * undefined
	 */
	@Type(() => TTextDM)
	AllocatedBitCalc?: TTextDM

	/**
	 * undefined
	 */
	@Type(() => TTextDM)
	NotOnlyAdvanceBitCalc?: TTextDM
}