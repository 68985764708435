import Data from 'react-k2-api/Api/Data'
import { Type } from 'class-transformer'
import TWarehouseZoneDM from './TWarehouseZoneDM'
import TWarehouseDM from './TWarehouseDM'
import TArticleDM from './TArticleDM'

/**
 * Dispozice ve sklad.zónách
 */
export default class TWarehouseZoneStockLevelDM extends Data {
	public static className = 'TWarehouseZoneStockLevelDM'
	public static systemFields = [
		'RecordID',
	]

	public static isHeader = false

	/**
	 * Zkratka
	 */
	Abbr?: string

	/**
	 * Max. množství
	 */
	MaxLevel?: number

	/**
	 * Min. množství
	 */
	MinLevel?: number

	/**
	 * Účetní hodn.
	 */
	BookValue?: number

	/**
	 * Expedice
	 */
	Dispatch?: number

	/**
	 * Zadáno
	 */
	Assigned?: number

	/**
	 * Dispozice
	 */
	Available?: number

	/**
	 * Přijato
	 */
	Received?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * undefined
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Zóna
	 */
	@Type(() => TWarehouseZoneDM)
	WarehouseZoneRID?: TWarehouseZoneDM

	/**
	 * Sklad
	 */
	@Type(() => TWarehouseDM)
	WarehouseId?: TWarehouseDM

	/**
	 * Zboží
	 */
	@Type(() => TArticleDM)
	ArticleId?: TArticleDM
}