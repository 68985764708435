import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TK2UserLookupDM from './TK2UserLookupDM'

/**
 * Tarifní stupně
 */
export default class TPrTariffLevelDM extends Data {
	public static className = 'TPrTariffLevelDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = false

	/**
	 * Změněno
	 */
	ChangedOn?: Date

	/**
	 * Vytvořeno
	 */
	CreatedOn?: Date

	/**
	 * Časové razítko
	 */
	TimeStamp?: string

	/**
	 * Tarifní tabulka k datu
	 */
	PrTariffDateRID?: number

	/**
	 * Hodnoty pro tarifní třídy
	 */
	XmlField?: string

	/**
	 * Právo na záznam
	 */
	RightGroupId?: number

	/**
	 * Požadovaná praxe
	 */
	WorkExperienceYearCount?: number

	/**
	 * Stupeň
	 */
	Level?: number

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Třída 1
	 */
	Grade01Calc?: number

	/**
	 * Třída 2
	 */
	Grade02Calc?: number

	/**
	 * Třída 3
	 */
	Grade03Calc?: number

	/**
	 * Třída 4
	 */
	Grade04Calc?: number

	/**
	 * Třída 5
	 */
	Grade05Calc?: number

	/**
	 * Třída 6
	 */
	Grade06Calc?: number

	/**
	 * Třída 7
	 */
	Grade07Calc?: number

	/**
	 * Třída 8
	 */
	Grade08Calc?: number

	/**
	 * Třída 9
	 */
	Grade09Calc?: number

	/**
	 * Třída 10
	 */
	Grade10Calc?: number

	/**
	 * Třída 11
	 */
	Grade11Calc?: number

	/**
	 * Třída 12
	 */
	Grade12Calc?: number

	/**
	 * Třída 13
	 */
	Grade13Calc?: number

	/**
	 * Třída 14
	 */
	Grade14Calc?: number

	/**
	 * Třída 15
	 */
	Grade15Calc?: number

	/**
	 * Třída 16
	 */
	Grade16Calc?: number

	/**
	 * Třída 17
	 */
	Grade17Calc?: number

	/**
	 * Třída 18
	 */
	Grade18Calc?: number

	/**
	 * Třída 19
	 */
	Grade19Calc?: number

	/**
	 * Třída 20
	 */
	Grade20Calc?: number

	/**
	 * Právo na záznam
	 */
	RightGroupIdCalc?: number

	/**
	 * Změnil
	 */
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM

	/**
	 * Vytvořil
	 */
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM
}