import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TTextDM from './TTextDM'

/**
 * Položky majetku
 */
export default class TAssetLinkItemDM extends Data {
	public static className = 'TAssetLinkItemDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = false

	/**
	 * Popis
	 */
	Description?: string

	/**
	 * Částka
	 */
	Amount?: number

	/**
	 * Datum
	 */
	DocumentDate?: Date

	/**
	 * ID položky dokladu
	 */
	DocumentNumberItemRID?: number

	/**
	 * Typ dokladu
	 */
	DocumentTypeId?: number

	/**
	 * Unikátní číslo H
	 */
	HeaderRID?: number

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * undefined
	 */
	ICItemRIDCalc?: number

	/**
	 * undefined
	 */
	CaItemRIDCalc?: number

	/**
	 * undefined
	 */
	IIItemRIDCalc?: number

	/**
	 * undefined
	 */
	IOItemRIDCalc?: number

	/**
	 * undefined
	 */
	RNItemRIDCalc?: number

	/**
	 * undefined
	 */
	DocIdentificationCalc?: string

	/**
	 * Typ položky
	 */
	@Type(() => TTextDM)
	ItemTypeId?: TTextDM

	/**
	 * undefined
	 */
	@Type(() => TTextDM)
	DocTypeCalc?: TTextDM

	/**
	 * undefined
	 */
	@Type(() => TTextDM)
	DocTypeShowCalc?: TTextDM
}