import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TArticleDM from './TArticleDM'

/**
 * Kalkulace - strom
 */
export default class TProductCostingTreeDM extends Data {
	public static className = 'TProductCostingTreeDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = false

	/**
	 * Alternativní jednotka
	 */
	AlternateUnit?: number

	/**
	 * Plán. jedn. cena
	 */
	UnitPlannedPrice?: number

	/**
	 * Závislý čas
	 */
	DependentTime?: number

	/**
	 * Nezávislý čas
	 */
	IndependentTime?: number

	/**
	 * Množství
	 */
	Quantity?: number

	/**
	 * Množství v průvodce
	 */
	JobCardQuantity?: number

	/**
	 * undefined
	 */
	HeaderNodeRID?: number

	/**
	 * undefined
	 */
	ProductCostingRID?: number

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * Pořadí
	 */
	RecordNo?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * undefined
	 */
	ExchangeRateCalc?: number

	/**
	 * undefined
	 */
	CurrencyCalc?: string

	/**
	 * undefined
	 */
	UnitPlannedPriceCCalc?: number

	/**
	 * Zboží
	 */
	@Type(() => TArticleDM)
	ArticleId?: TArticleDM
}