import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TTextDM from './TTextDM'
import TEkoKomPackagingUsageDM from './TEkoKomPackagingUsageDM'

/**
 * EKO-KOM Obaly
 */
export default class TEkoKomPackagingDM extends Data {
	public static className = 'TEkoKomPackagingDM'
	public static primaryKey = 'RID'
	public static systemFields = [
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = true

	/**
	 * Množství
	 */
	LitteringQuantity?: number

	/**
	 * Barva
	 */
	ColoringId?: number

	/**
	 * Tvar
	 */
	FormId?: number

	/**
	 * Obsahuje nápoje
	 */
	ContainsBeverage?: string

	/**
	 * Platnost od
	 */
	ValidFrom?: Date

	/**
	 * Palety EUR
	 */
	IsEURPallet?: string

	/**
	 * Palety prosté
	 */
	IsPallet?: string

	/**
	 * Výchozí váha
	 */
	Weight?: number

	/**
	 * Statistická evidence
	 */
	IsReportStatistics?: string

	/**
	 * Platnost do
	 */
	ValidTo?: Date

	/**
	 * Popis
	 */
	Description?: string

	/**
	 * Zkratka
	 */
	Abbr?: string

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * undefined
	 */
	IsCompositeEvaluationCalc?: string

	/**
	 * undefined
	 */
	MainMaterialIdCalc?: number

	/**
	 * undefined
	 */
	MainSubordinateKindIdCalc?: number

	/**
	 * undefined
	 */
	RecycledPctCalc?: number

	/**
	 * undefined
	 */
	LitteringEnabledCalc?: string

	/**
	 * Litteringový obal
	 */
	@Type(() => TTextDM)
	LitteringId?: TTextDM

	/**
	 * Původ
	 */
	@Type(() => TTextDM)
	OriginId?: TTextDM

	/**
	 * Druh materiálu
	 */
	@Type(() => TTextDM)
	PackKindId?: TTextDM

	/**
	 * Směr
	 */
	@Type(() => TTextDM)
	PackDirectId?: TTextDM

	/**
	 * Typ
	 */
	@Type(() => TTextDM)
	PackTypeId?: TTextDM

	/**
	 * Režim
	 */
	@Type(() => TTextDM)
	PackModeId?: TTextDM

	/**
	 * Kompozit
	 */
	@Type(() => TTextDM)
	CompositeIdCalc?: TTextDM

	/**
	 * Tvar
	 */
	@Type(() => TTextDM)
	FormIdCalc?: TTextDM

	/**
	 * Tvar
	 */
	@Type(() => TTextDM)
	BeverageCartonFormIdCalc?: TTextDM

	/**
	 * Barva
	 */
	@Type(() => TTextDM)
	ColorIdCalc?: TTextDM

	/**
	 * Barva
	 */
	@Type(() => TTextDM)
	PETSaleColoringIdCalc?: TTextDM

	/**
	 * undefined
	 */
	@Type(() => TEkoKomPackagingUsageDM)
	ArticleItemChild?: TEkoKomPackagingUsageDM[]

	/**
	 * Změna skupiny práv
	 */
	public ChangeGroupRightCOMMAND() {
		return this.executeCommand('ChangeGroupRightCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public BulkChangeGroupRightCOMMAND() {
		return this.executeCommand('BulkChangeGroupRightCOMMAND')
	}

	/**
	 * Aktualizace počítaných polí
	 */
	public BulkUpdateCalcFieldsCOMMAND() {
		return this.executeCommand('BulkUpdateCalcFieldsCOMMAND')
	}

	/**
	 * Kontrola legislativy
	 */
	public SwitchOffLegislationValidationCOMMAND() {
		return this.executeCommand('SwitchOffLegislationValidationCOMMAND')
	}
}