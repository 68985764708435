import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TCountryDM from './TCountryDM'
import TTextDM from './TTextDM'
import TCurrencyCountryExchangeRateDM from './TCurrencyCountryExchangeRateDM'
import TCustomExchangeRateDM from './TCustomExchangeRateDM'

/**
 * Měna
 */
export default class TCurrencyDM extends Data {
	public static className = 'TCurrencyDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = true

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * Zaokr. kost. - cest. příkazy
	 */
	RoundingTravelOrdersConstant?: number

	/**
	 * Zaokr. řád - cest.příkazy
	 */
	RoundingTravelOrdersOrder?: number

	/**
	 * Časové razítko
	 */
	TimeStamp?: string

	/**
	 * Právo na záznam
	 */
	RightGroupId?: number

	/**
	 * Zaokr. kost. - prodej
	 */
	RoundingInvoiceOutConstant?: number

	/**
	 * Zaokr. řád - prodej
	 */
	RoundingInvoiceOutOrder?: number

	/**
	 * Zaokr. konst. - nákup
	 */
	RoundingInvoiceInConstant?: number

	/**
	 * Zaokr. řád - nákup
	 */
	RoundingInvoiceInOrder?: number

	/**
	 * KProV
	 */
	ValutaRateSale?: number

	/**
	 * KStrV
	 */
	ValutaRateMiddle?: number

	/**
	 * KNakV
	 */
	ValutaRatePurchase?: number

	/**
	 * KPro
	 */
	ExchangeRateSale?: number

	/**
	 * KStr
	 */
	ExchangeRateMiddle?: number

	/**
	 * KNak
	 */
	ExchangeRatePurchase?: number

	/**
	 * Počet jednotek
	 */
	CurrencyUnitNumber?: number

	/**
	 * Datum
	 */
	ExchangeRateDate?: Date

	/**
	 * Kód měny
	 */
	Code?: string

	/**
	 * Měna
	 */
	Abbr?: string

	/**
	 * EX_Kriz_Kurz
	 */
	EX_Kriz_Kurz?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * undefined
	 */
	CompanyIdCalc?: number

	/**
	 * Právo na záznam
	 */
	RightGroupIdCalc?: number

	/**
	 * Stát
	 */
	@Type(() => TCountryDM)
	CountryId?: TCountryDM

	/**
	 * Z
	 */
	@Type(() => TTextDM)
	InvalidRecordImgCalc?: TTextDM

	/**
	 * undefined
	 */
	@Type(() => TCurrencyCountryExchangeRateDM)
	ExchangeRateChild?: TCurrencyCountryExchangeRateDM[]

	/**
	 * undefined
	 */
	@Type(() => TCustomExchangeRateDM)
	CustomExchangeRateChild?: TCustomExchangeRateDM[]

	/**
	 * Aktuální kurz
	 */
	public ExchangeRateSetAsActualCOMMAND() {
		return this.executeCommand('ExchangeRateSetAsActualCOMMAND')
	}

	/**
	 * Zneplatnění/Zplatnění
	 */
	public InvalidationCOMMAND() {
		return this.executeCommand('InvalidationCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public ChangeGroupRightCOMMAND() {
		return this.executeCommand('ChangeGroupRightCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public BulkChangeGroupRightCOMMAND() {
		return this.executeCommand('BulkChangeGroupRightCOMMAND')
	}

	/**
	 * Aktualizace počítaných polí
	 */
	public BulkUpdateCalcFieldsCOMMAND() {
		return this.executeCommand('BulkUpdateCalcFieldsCOMMAND')
	}

	/**
	 * Kontrola legislativy
	 */
	public SwitchOffLegislationValidationCOMMAND() {
		return this.executeCommand('SwitchOffLegislationValidationCOMMAND')
	}
}