import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TK2UserLookupDM from './TK2UserLookupDM'
import TArticleDM from './TArticleDM'
import TMeasureUnitDM from './TMeasureUnitDM'
import TTextDM from './TTextDM'
import TPredictionItemDM from './TPredictionItemDM'

/**
 * Predikční modely
 */
export default class TPredictionModelDM extends Data {
	public static className = 'TPredictionModelDM'
	public static primaryKey = 'RID'
	public static systemFields = [
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = true

	/**
	 * Uložené parametry
	 */
	Settings?: string

	/**
	 * Datum poslední predikce
	 */
	PredictionLastDate?: Date

	/**
	 * Datum první predikce
	 */
	PredictionFirstDate?: Date

	/**
	 * Datum 1. objednání
	 */
	ReorderDate?: Date

	/**
	 * Service Level
	 */
	ServiceLevel?: number

	/**
	 * Průběžná doba
	 */
	LeadTime?: number

	/**
	 * Poslední datum prodeje
	 */
	LastSalesDate?: Date

	/**
	 * Agregace
	 */
	Aggregation?: string

	/**
	 * Typ dat
	 */
	DataType?: string

	/**
	 * Konfigurace
	 */
	InventoryConfig?: number

	/**
	 * Časové razítko
	 */
	TimeStamp?: number

	/**
	 * Vytvořeno
	 */
	CreatedOn?: Date

	/**
	 * Archivní datum
	 */
	ArchiveDate?: Date

	/**
	 * Ohodnocení
	 */
	Evaluation?: number

	/**
	 * Návrh zásob
	 */
	Proposal?: string

	/**
	 * Assessment
	 */
	Assessment?: string

	/**
	 * Parametry
	 */
	Parameter?: string

	/**
	 * Popis
	 */
	Description?: string

	/**
	 * Základní model
	 */
	BasicModel?: string

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * undefined
	 */
	ConfigAbbrCalc?: string

	/**
	 * undefined
	 */
	ConfigNameCalc?: string

	/**
	 * undefined
	 */
	SafetyStockCalc?: number

	/**
	 * undefined
	 */
	ReorderPointCalc?: number

	/**
	 * undefined
	 */
	OrderQuantityCalc?: number

	/**
	 * undefined
	 */
	StockoutDateCalc?: Date

	/**
	 * undefined
	 */
	StockoutDurationCalc?: number

	/**
	 * undefined
	 */
	PredictionModelTypeImgCalc?: string

	/**
	 * undefined
	 */
	PredictionChartDateFromCalc?: Date

	/**
	 * undefined
	 */
	ModelSetupDateCalc?: Date

	/**
	 * undefined
	 */
	PredictionDateCalc?: Date

	/**
	 * undefined
	 */
	InventoryDateCalc?: Date

	/**
	 * Vytvořil
	 */
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM

	/**
	 * Číslo zboží
	 */
	@Type(() => TArticleDM)
	ArticleId?: TArticleDM

	/**
	 * undefined
	 */
	@Type(() => TMeasureUnitDM)
	MeasureUnitIdCalc?: TMeasureUnitDM

	/**
	 * undefined
	 */
	@Type(() => TTextDM)
	PredictionStyleIdCalc?: TTextDM

	/**
	 * undefined
	 */
	@Type(() => TPredictionItemDM)
	PredictionItemChild?: TPredictionItemDM[]

	/**
	 * undefined
	 */
	@Type(() => TPredictionItemDM)
	PredictionItemPredictionChild?: TPredictionItemDM[]

	/**
	 * undefined
	 */
	@Type(() => TPredictionItemDM)
	PredictionItemInvPropChild?: TPredictionItemDM[]

	/**
	 * undefined
	 */
	@Type(() => TPredictionItemDM)
	PredictItemDailySalesHistoryChild?: TPredictionItemDM[]

	/**
	 * undefined
	 */
	@Type(() => TPredictionItemDM)
	PredicItemDailyStockLevHistChild?: TPredictionItemDM[]

	/**
	 * undefined
	 */
	@Type(() => TPredictionItemDM)
	PredictionItemNativePredictionChild?: TPredictionItemDM[]

	/**
	 * undefined
	 */
	@Type(() => TPredictionItemDM)
	PredictionItemLowerUpperChild?: TPredictionItemDM[]

	/**
	 * undefined
	 */
	@Type(() => TPredictionItemDM)
	PredictionItemNativeSalesChild?: TPredictionItemDM[]

	/**
	 * undefined
	 */
	@Type(() => TPredictionItemDM)
	PredictionItemOrderedChild?: TPredictionItemDM[]

	/**
	 * undefined
	 */
	@Type(() => TPredictionItemDM)
	PredictionItemSupposedOrderedChild?: TPredictionItemDM[]

	/**
	 * undefined
	 */
	@Type(() => TPredictionItemDM)
	PredictionItemSSChild?: TPredictionItemDM[]

	/**
	 * undefined
	 */
	@Type(() => TPredictionItemDM)
	PredictionItemROPChild?: TPredictionItemDM[]

	/**
	 * Změna skupiny práv
	 */
	public ChangeGroupRightCOMMAND() {
		return this.executeCommand('ChangeGroupRightCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public BulkChangeGroupRightCOMMAND() {
		return this.executeCommand('BulkChangeGroupRightCOMMAND')
	}

	/**
	 * Aktualizace počítaných polí
	 */
	public BulkUpdateCalcFieldsCOMMAND() {
		return this.executeCommand('BulkUpdateCalcFieldsCOMMAND')
	}

	/**
	 * Kontrola legislativy
	 */
	public SwitchOffLegislationValidationCOMMAND() {
		return this.executeCommand('SwitchOffLegislationValidationCOMMAND')
	}
}