import Data from 'react-k2-api/Api/Data'
import { Type } from 'class-transformer'
import TMeasureUnitDM from './TMeasureUnitDM'
import ArticleCode2 from './ArticleCode2'
import ArticleCode1 from './ArticleCode1'
import TRoutingVariantDM from './TRoutingVariantDM'
import TCurrencyDM from './TCurrencyDM'
import TPriceGroupDM from './TPriceGroupDM'
import TArticleDM from './TArticleDM'
import TTradingPartnerDM from './TTradingPartnerDM'
import TTextDM from './TTextDM'

/**
 * Cenové skupiny a odběratelské ceny
 */
export default class TArticleSellingPriceDM extends Data {
	public static className = 'TArticleSellingPriceDM'
	public static systemFields = [
		'ItemRIDCalc',
	]

	public static isHeader = false

	/**
	 * Node
	 */
	NodeID?: number

	/**
	 * Klíčový kód
	 */
	KeyCode?: string

	/**
	 * Datum do
	 */
	DateTo?: Date

	/**
	 * Unikát
	 */
	UniqID?: number

	/**
	 * Datum
	 */
	Date?: Date

	/**
	 * Cena
	 */
	Price?: number

	/**
	 * Množ.
	 */
	Quantity?: number

	/**
	 * Číslo DS
	 */
	PriceGroupNo?: number

	/**
	 * EX_Prepocet
	 */
	EX_Prepocet?: number

	/**
	 * undefined
	 */
	RecordStateImgCalc?: string

	/**
	 * ItemRIDCalc
	 */
	ItemRIDCalc?: number

	/**
	 * Cen.sk.(&D)
	 */
	PriceGroupCalc?: string

	/**
	 * Cena (%)
	 */
	PricePctCalc?: number

	/**
	 * Cena
	 */
	PriceCalc?: number

	/**
	 * Cena netto
	 */
	PriceNetClientCCalc?: number

	/**
	 * Netto-měna
	 */
	PriceNetCCalc?: number

	/**
	 * Cena netto
	 */
	PriceNetCalc?: number

	/**
	 * Cena brutto
	 */
	PriceGrossClientCCalc?: number

	/**
	 * Brutto-měna
	 */
	PriceGrossCCalc?: number

	/**
	 * Cena brutto
	 */
	PriceGrossCalc?: number

	/**
	 * Zákl. cena
	 */
	BasicPriceCalc?: number

	/**
	 * Příznak-cen.sk.
	 */
	PriceGroupBitCalc?: number

	/**
	 * Příznak - odb. c.
	 */
	TradingPartnerPriceBitCalc?: number

	/**
	 * Příznak - rel. c.
	 */
	RelativePriceBitCalc?: number

	/**
	 * Měna
	 */
	CurrencyCalc?: string

	/**
	 * Tř. pole
	 */
	SortingCalc?: string

	/**
	 * Kurz
	 */
	ExchangeRateCalc?: number

	/**
	 * Koef. DPH
	 */
	VATCoefficientCalc?: number

	/**
	 * Částka od
	 */
	FromAmountCalc?: number

	/**
	 * Množství
	 */
	QuantityCalc?: number

	/**
	 * Koef. MJ
	 */
	MeasureUnitCoefficientCalc?: number

	/**
	 * RecordModeCalc
	 */
	RecordModeCalc?: number

	/**
	 * Netto EUR
	 */
	CF_NettoEUR?: number

	/**
	 * Alt.j.
	 */
	@Type(() => TMeasureUnitDM)
	MeasureUnitId?: TMeasureUnitDM

	/**
	 * Kód 2
	 */
	@Type(() => ArticleCode2)
	CustomerCode2?: ArticleCode2

	/**
	 * Kód 1
	 */
	@Type(() => ArticleCode1)
	CustomerCode1?: ArticleCode1

	/**
	 * Varianta
	 */
	@Type(() => TRoutingVariantDM)
	RoutingVariantId?: TRoutingVariantDM

	/**
	 * Měna
	 */
	@Type(() => TCurrencyDM)
	Currency?: TCurrencyDM

	/**
	 * Zákl. cena
	 */
	@Type(() => TPriceGroupDM)
	SuperiorPriceGroupId?: TPriceGroupDM

	/**
	 * Číslo
	 */
	@Type(() => TArticleDM)
	ArticleId?: TArticleDM

	/**
	 * Číslo CS
	 */
	@Type(() => TPriceGroupDM)
	PriceGroup1IdCalc?: TPriceGroupDM

	/**
	 * Číslo odb.
	 */
	@Type(() => TTradingPartnerDM)
	PriceGroup2IdCalc?: TTradingPartnerDM

	/**
	 * Nepřepočítávat
	 */
	@Type(() => TTextDM)
	NotRecalculateBitCalc?: TTextDM

	/**
	 * Akce
	 */
	@Type(() => TTextDM)
	ActionBitCalc?: TTextDM
}