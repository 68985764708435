import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TOnePersonAbsenceDM from './TOnePersonAbsenceDM'
import TTextDM from './TTextDM'
import TAttendanceMovementIntervalDM from './TAttendanceMovementIntervalDM'
import TAttendanceWorkTimeReportItemDM from './TAttendanceWorkTimeReportItemDM'
import TAttendanceWorkTimeReportDM from './TAttendanceWorkTimeReportDM'
import TK2UserLookupDM from './TK2UserLookupDM'

/**
 * Zpracované intervaly
 */
export default class TAttendanceProcessedIntervalDM extends Data {
	public static className = 'TAttendanceProcessedIntervalDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = true

	/**
	 * Délka intervalu
	 */
	HoursCount?: number

	/**
	 * Datum a čas do
	 */
	MovementDateTo?: Date

	/**
	 * Datum a čas od
	 */
	MovementDateFrom?: Date

	/**
	 * Kniha
	 */
	BookId?: number

	/**
	 * Skupina práv
	 */
	RightGroupId?: number

	/**
	 * Časové razítko
	 */
	TimeStamp?: string

	/**
	 * Změněno
	 */
	ChangedOn?: Date

	/**
	 * Vytvořeno
	 */
	CreatedOn?: Date

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Čas od
	 */
	MovementDateFromCalc?: Date

	/**
	 * Čas do
	 */
	MovementDateToCalc?: Date

	/**
	 * Popis
	 */
	TooltipCalc?: string

	/**
	 * Skupina práv
	 */
	RightGroupIdCalc?: number

	/**
	 * Plánovaná absence
	 */
	@Type(() => TOnePersonAbsenceDM)
	PersonAbsenceRID?: TOnePersonAbsenceDM

	/**
	 * Původ
	 */
	@Type(() => TTextDM)
	OriginTypeId?: TTextDM

	/**
	 * Typ intervalu
	 */
	@Type(() => TAttendanceMovementIntervalDM)
	MovementSubTypeRID?: TAttendanceMovementIntervalDM

	/**
	 * Den pracovního listu
	 */
	@Type(() => TAttendanceWorkTimeReportItemDM)
	WorkTimeReportItemRID?: TAttendanceWorkTimeReportItemDM

	/**
	 * Pracovní list
	 */
	@Type(() => TAttendanceWorkTimeReportDM)
	WorkTimeReportRID?: TAttendanceWorkTimeReportDM

	/**
	 * Změnil
	 */
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM

	/**
	 * Vytvořil
	 */
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM

	/**
	 * Změna skupiny práv
	 */
	public ChangeGroupRightCOMMAND() {
		return this.executeCommand('ChangeGroupRightCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public BulkChangeGroupRightCOMMAND() {
		return this.executeCommand('BulkChangeGroupRightCOMMAND')
	}

	/**
	 * Aktualizace počítaných polí
	 */
	public BulkUpdateCalcFieldsCOMMAND() {
		return this.executeCommand('BulkUpdateCalcFieldsCOMMAND')
	}

	/**
	 * Kontrola legislativy
	 */
	public SwitchOffLegislationValidationCOMMAND() {
		return this.executeCommand('SwitchOffLegislationValidationCOMMAND')
	}
}