import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TPromotionDM from './TPromotionDM'

/**
 * Uplatněné promo akce
 */
export default class TAppliedPromotionDM extends Data {
	public static className = 'TAppliedPromotionDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = false

	/**
	 * Datum uplatnění
	 */
	AppliedDate?: Date

	/**
	 * Počet uplatnění
	 */
	AppliedCount?: number

	/**
	 * Identifikátor dokladu
	 */
	DocumentRID?: number

	/**
	 * Typ dokladu
	 */
	DocumentTypeId?: number

	/**
	 * Skupina promoakce
	 */
	PromotionGroupRID?: number

	/**
	 * RID
	 */
	RID?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * RID
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * undefined
	 */
	PromotionTypeIdCalc?: number

	/**
	 * Promoakce
	 */
	@Type(() => TPromotionDM)
	PromotionRID?: TPromotionDM
}