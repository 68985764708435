import { ChangeEvent, RefObject, SyntheticEvent, useState } from "react"
import { Button, Form, FormControl, InputGroup } from "react-bootstrap"
import Data from "react-k2-api/Api/Data"
import TArticleDM from "react-k2-api/Data/TArticleDM"
import TSalesOrderDM from "react-k2-api/Data/TSalesOrderDM"
import TTradingPartnerDM from "react-k2-api/Data/TTradingPartnerDM"
import { useErrorHandler } from "react-error-boundary"
import { useGlobalModalContext, MODAL_TYPES } from "components/GlobalModal/GlobalModal"

export type HandleBarcodeInputData = (c: typeof Data, data?: Data) => void
export type HandleBarcodeInputDataList = (c: typeof Data, dataList: Data[]) => void

export type BarcodeInputProps = {
    handleData: HandleBarcodeInputData
    handleDataList: HandleBarcodeInputDataList
    handleBlur?: React.FocusEventHandler<HTMLInputElement>
    inputRef: RefObject<HTMLInputElement>
    fields?: { [className: string]: string[] }
}

export const BarcodeInput = ({ handleData, handleDataList, handleBlur, inputRef, fields }: BarcodeInputProps) => {
    const [barcode, setBarcode] = useState("")
    const [loading, setLoading] = useState(false)
    const { showModal } = useGlobalModalContext()
    const salesOrderRegex = /(131)(.{2,20})(?=(\d{10}))/
    const tradingPartnerRegex = /^\d{8}$/
    const getClassFromBarcode = (barcode: string): typeof Data => {
        if (barcode.match(salesOrderRegex)) {
            return TSalesOrderDM
        }

        if (barcode.match(tradingPartnerRegex)) {
            return TTradingPartnerDM
        }

        return TArticleDM
    }

    const handleError = useErrorHandler()
    const handleSearch = (barcode: string) => {
        if (!barcode) return

        setLoading(true)
        const c = getClassFromBarcode(barcode)
        const requestedFields = fields && fields[c.className] ? fields[c.className] : []

        switch (c) {
            case TArticleDM: {
                TArticleDM.getList(requestedFields, [`BarCodeChild.Barcode;EQ;${barcode}`]).then(([articleList]) => {
                    if (articleList.length > 0) {
                        if (articleList.length === 1) handleData(TArticleDM, articleList[0])
                        else handleDataList(TArticleDM, articleList)
                        setLoading(false)
                        setBarcode("")
                    } else {
                        TArticleDM.getList(requestedFields, [`Abbr;EQ;${barcode}`]).then(([articleList]) => {
                            if (articleList.length > 0) {
                                if (articleList.length === 1) handleData(TArticleDM, articleList[0])
                                else handleDataList(TArticleDM, articleList)
                            } else {
                                handleData(TArticleDM)
                            }

                            setLoading(false)
                            setBarcode("")
                        }).catch(handleError)
                    }
                }).catch(handleError)
                break
            }
            case TSalesOrderDM: {
                const match = barcode.match(salesOrderRegex)
                if (match) {
                    TSalesOrderDM.getList(
                        requestedFields,
                        [
                            `BookId.Abbr;EQ;${match[2]}`,
                            `BusinessYearId.Id;EQ;${match[3].substring(0, 3)}`,
                            `Number;EQ;${parseInt(match[3].substring(3))}`
                        ]).then(([salesOrderList]) => {
                            if (salesOrderList.length > 0) {
                                if (salesOrderList.length === 1) handleData(TSalesOrderDM, salesOrderList[0])
                                else handleDataList(TSalesOrderDM, salesOrderList)
                            } else {
                                handleData(TSalesOrderDM)
                            }

                            setLoading(false)
                            setBarcode("")
                        }).catch(handleError)
                } else {
                    handleData(TSalesOrderDM)
                }
                break
            }
            case TTradingPartnerDM: {
                TTradingPartnerDM.getList(requestedFields, [
                    `CompanyRegNumber;EQ;${barcode}`
                ]).then(([tradingPartnerList]) => {
                    if (tradingPartnerList.length > 0) {
                        if (tradingPartnerList.length === 1) handleData(TTradingPartnerDM, tradingPartnerList[0])
                        else handleDataList(TTradingPartnerDM, tradingPartnerList)
                    } else {
                        handleData(TTradingPartnerDM)
                    }

                    setLoading(false)
                    setBarcode("")
                }).catch(handleError)
                break
            }
        }
    }

    const handleSubmit = (e: SyntheticEvent) => {
        e.preventDefault()
        handleSearch(barcode)
    }

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setBarcode(e.currentTarget.value)
    }

    return (
        <>
            <Form onSubmit={handleSubmit}>
                <InputGroup>
                    <FormControl
                        type="text"
                        ref={inputRef}
                        value={barcode}
                        onChange={handleChange}
                        placeholder="Načtěte čárový kód"
                        disabled={loading}
                        autoFocus={true}
                        onBlur={handleBlur}
                    />
                    <Button onClick={() => {
                        showModal(MODAL_TYPES.NUMERIC_KEYBOARD_MODAL, {
                            handleClose: (value) => {
                                if (value) handleSearch(value)
                            },
                            allowLeadingZero: true
                        })
                    }}>
                        <i className="bi bi-pencil"></i>
                    </Button>
                </InputGroup>
            </Form>
        </>
    )
}