import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TAccountingChartDM from './TAccountingChartDM'
import TMatchinSymbolAccountingJournalItemDM from './TMatchinSymbolAccountingJournalItemDM'

/**
 * Párovací symboly
 */
export default class TMatchingSymbolDM extends Data {
	public static className = 'TMatchingSymbolDM'
	public static primaryKey = 'Id'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.Id!
	}

	public static isHeader = true

	/**
	 * Spárováno
	 */
	IsMatched?: string

	/**
	 * Blokováno
	 */
	IsBlockedRecord?: string

	/**
	 * Firma
	 */
	CompanyId?: number

	/**
	 * Časové razítko
	 */
	TimeStamp?: string

	/**
	 * Arch.obrat D
	 */
	ArchiveTotalCreditAmount?: number

	/**
	 * Arch.obrat MD
	 */
	ArchiveTotalDebitAmount?: number

	/**
	 * Popis
	 */
	Description?: string

	/**
	 * Obrat D
	 */
	TotalCreditAmount?: number

	/**
	 * Obrat MD
	 */
	TotalDebitAmount?: number

	/**
	 * Zkratka
	 */
	Abbr?: string

	/**
	 * Číslo
	 */
	Id?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * SQLSequenceOrderCalc
	 */
	SQLSequenceOrderCalc?: number

	/**
	 * Rozdíl MD a D
	 */
	DebitAndCreditDifferenceCalc?: number

	/**
	 * Účet
	 */
	@Type(() => TAccountingChartDM)
	AccountId?: TAccountingChartDM

	/**
	 * undefined
	 */
	@Type(() => TAccountingChartDM)
	SelectedAccountIdCalc?: TAccountingChartDM

	/**
	 * undefined
	 */
	@Type(() => TMatchinSymbolAccountingJournalItemDM)
	AccountingJournaItemBookChild?: TMatchinSymbolAccountingJournalItemDM[]

	/**
	 * Změna skupiny práv
	 */
	public ChangeGroupRightCOMMAND() {
		return this.executeCommand('ChangeGroupRightCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public BulkChangeGroupRightCOMMAND() {
		return this.executeCommand('BulkChangeGroupRightCOMMAND')
	}

	/**
	 * Aktualizace počítaných polí
	 */
	public BulkUpdateCalcFieldsCOMMAND() {
		return this.executeCommand('BulkUpdateCalcFieldsCOMMAND')
	}

	/**
	 * Kontrola legislativy
	 */
	public SwitchOffLegislationValidationCOMMAND() {
		return this.executeCommand('SwitchOffLegislationValidationCOMMAND')
	}
}