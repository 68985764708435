import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TK2UserLookupDM from './TK2UserLookupDM'
import TTextDM from './TTextDM'
import CountryGroup from './CountryGroup'
import TCurrencyDM from './TCurrencyDM'
import TLanguageTextTranslationDM from './TLanguageTextTranslationDM'

/**
 * Státy
 */
export default class TCountryDM extends Data {
	public static className = 'TCountryDM'
	public static primaryKey = 'CountryId'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.CountryId!
	}

	public static isHeader = true

	/**
	 * Skupina práv
	 */
	RightGroupId?: number

	/**
	 * Vytvořeno
	 */
	CreatedOn?: Date

	/**
	 * Plný název
	 */
	FullCountryName?: string

	/**
	 * Kód N3
	 */
	CountryCodeN3?: string

	/**
	 * Kód A3
	 */
	CountryCodeA3?: string

	/**
	 * Časové razítko
	 */
	TimeStamp?: string

	/**
	 * Telefonní předvolba
	 */
	DialingCode?: string

	/**
	 * Nastavení
	 */
	Settings?: string

	/**
	 * Název
	 */
	Name?: string

	/**
	 * Změněno
	 */
	ChangedOn?: Date

	/**
	 * Číslo
	 */
	CountryId?: number

	/**
	 * Kód
	 */
	CountryCode?: string

	/**
	 * Zkratka
	 */
	Abbr?: string

	/**
	 * EX_Asie
	 */
	EX_Asie?: string

	/**
	 * EX_neni_EU
	 */
	EX_neni_EU?: string

	/**
	 * EX_Tariff
	 */
	EX_Tariff?: string

	/**
	 * EX_Origin
	 */
	EX_Origin?: string

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Intrastat
	 */
	CountryIntrastatIdCalc?: number

	/**
	 * Název státu
	 */
	LanguageTextCalc?: string

	/**
	 * Blokováno
	 */
	BlockedRecordBitCalc?: number

	/**
	 * Plný název státu
	 */
	FullCountryNameTranslatedTextCalc?: string

	/**
	 * Kód státu
	 */
	CountryCodeCalc?: string

	/**
	 * Skupina práv
	 */
	RightGroupIdCalc?: number

	/**
	 * Vytvořil
	 */
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM

	/**
	 * Jazyk
	 */
	@Type(() => TTextDM)
	LanguageId?: TTextDM

	/**
	 * Skupina
	 */
	@Type(() => CountryGroup)
	GroupId?: CountryGroup

	/**
	 * Změnil
	 */
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM

	/**
	 * Měna
	 */
	@Type(() => TCurrencyDM)
	Currency?: TCurrencyDM

	/**
	 * Název státu
	 */
	@Type(() => TLanguageTextTranslationDM)
	LanguageNameChild?: TLanguageTextTranslationDM[]

	/**
	 * Plný název státu
	 */
	@Type(() => TLanguageTextTranslationDM)
	LanguageFullCountryNameChild?: TLanguageTextTranslationDM[]

	/**
	 * Změna skupiny práv
	 */
	public ChangeGroupRightCOMMAND() {
		return this.executeCommand('ChangeGroupRightCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public BulkChangeGroupRightCOMMAND() {
		return this.executeCommand('BulkChangeGroupRightCOMMAND')
	}

	/**
	 * Aktualizace počítaných polí
	 */
	public BulkUpdateCalcFieldsCOMMAND() {
		return this.executeCommand('BulkUpdateCalcFieldsCOMMAND')
	}

	/**
	 * Kontrola legislativy
	 */
	public SwitchOffLegislationValidationCOMMAND() {
		return this.executeCommand('SwitchOffLegislationValidationCOMMAND')
	}
}