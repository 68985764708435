import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import AlternativeUnit from './AlternativeUnit'

/**
 * Doprava - měrná jednotka
 */
export default class TDeliveryMeasureUnitDM extends Data {
	public static className = 'TDeliveryMeasureUnitDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = false

	/**
	 * Časové razítko
	 */
	TimeStamp?: string

	/**
	 * Množství
	 */
	Quantity?: number

	/**
	 * Číslo položky
	 */
	Number?: number

	/**
	 * Číslo nadřízeného dokladu
	 */
	HeaderRID?: number

	/**
	 * Typ nadřízeného dokladu
	 */
	HeaderTypeId?: number

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Měrná jednotka
	 */
	@Type(() => AlternativeUnit)
	MeasureUnitId?: AlternativeUnit
}