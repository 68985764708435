import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TCurrencyDM from './TCurrencyDM'

/**
 * Zdarma od částky
 */
export default class TEshopFreeDeliveryDM extends Data {
	public static className = 'TEshopFreeDeliveryDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = false

	/**
	 * Použít i pro ostatní měny
	 */
	UseForOtherCurrencies?: string

	/**
	 * Zdarma od částky
	 */
	PriceFrom?: number

	/**
	 * Odběr
	 */
	DeliveryRID?: number

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Měna
	 */
	@Type(() => TCurrencyDM)
	Currency?: TCurrencyDM
}