import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import ServiceOperationType from './ServiceOperationType'
import TTextDM from './TTextDM'
import TK2UserLookupDM from './TK2UserLookupDM'
import TBookDM from './TBookDM'

/**
 * Šablony definic úkonů
 */
export default class TMaintenanceFrequencyDefinitionTemplateDM extends Data {
	public static className = 'TMaintenanceFrequencyDefinitionTemplateDM'
	public static primaryKey = 'Id'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.Id!
	}

	public static isHeader = true

	/**
	 * Generuj další úkon
	 */
	GenerateNextOperation?: string

	/**
	 * Počet opakování
	 */
	RepetitionCount?: number

	/**
	 * Zařazení pro parametry
	 */
	ServiceParameterGroupId?: number

	/**
	 * Předstih
	 */
	GenerationHorizon?: number

	/**
	 * Jednotka pro předstih
	 */
	GenerationHorizonUnit?: number

	/**
	 * Zadání úkonu
	 */
	OperationInstructions?: string

	/**
	 * Plánovaná doba úkonu
	 */
	PlannedOperationDuration?: number

	/**
	 * Časový interval
	 */
	Frequency?: number

	/**
	 * Skupina práv
	 */
	RightGroupId?: number

	/**
	 * Časové razítko
	 */
	TimeStamp?: string

	/**
	 * Změněno
	 */
	ChangedOn?: Date

	/**
	 * Vytvořeno
	 */
	CreatedOn?: Date

	/**
	 * Popis definice
	 */
	Description?: string

	/**
	 * Zkratka
	 */
	Abbr?: string

	/**
	 * Číslo
	 */
	Id?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Skupina práv
	 */
	RightGroupIdCalc?: number

	/**
	 * Typ servisního úkonu
	 */
	@Type(() => ServiceOperationType)
	ServiceTypeId?: ServiceOperationType

	/**
	 * Časová jednotka
	 */
	@Type(() => TTextDM)
	TimeUnitId?: TTextDM

	/**
	 * Jednotka časového intervalu
	 */
	@Type(() => TTextDM)
	FrequencyUnitId?: TTextDM

	/**
	 * Změnil
	 */
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM

	/**
	 * Vytvořil
	 */
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM

	/**
	 * Kniha
	 */
	@Type(() => TBookDM)
	BookId?: TBookDM

	/**
	 * Změna skupiny práv
	 */
	public ChangeGroupRightCOMMAND() {
		return this.executeCommand('ChangeGroupRightCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public BulkChangeGroupRightCOMMAND() {
		return this.executeCommand('BulkChangeGroupRightCOMMAND')
	}

	/**
	 * Aktualizace počítaných polí
	 */
	public BulkUpdateCalcFieldsCOMMAND() {
		return this.executeCommand('BulkUpdateCalcFieldsCOMMAND')
	}

	/**
	 * Kontrola legislativy
	 */
	public SwitchOffLegislationValidationCOMMAND() {
		return this.executeCommand('SwitchOffLegislationValidationCOMMAND')
	}
}