import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TTextDM from './TTextDM'
import TUserRoleForUserDM from './TUserRoleForUserDM'

/**
 * Uživatelé
 */
export default class TK2UserDM extends Data {
	public static className = 'TK2UserDM'
	public static primaryKey = 'Id'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.Id!
	}

	public static isHeader = true

	/**
	 * Servisní uživatel
	 */
	IsServiceUser?: string

	/**
	 * Uživatelská role
	 */
	UserRoleId?: number

	/**
	 * Dvoufaktorové ověřování při přístupu z webu
	 */
	IsTwoFactorAuth?: string

	/**
	 * Středisko
	 */
	Center?: string

	/**
	 * Oddělení
	 */
	Department?: string

	/**
	 * Email
	 */
	Email?: string

	/**
	 * Fax
	 */
	Fax?: string

	/**
	 * Mobil
	 */
	Mobile?: string

	/**
	 * Telefon
	 */
	Phone?: string

	/**
	 * Skript
	 */
	DefaultScript?: string

	/**
	 * Čip
	 */
	CardNumber?: string

	/**
	 * Přístup z webu povolen
	 */
	IsWebLoginAllowed?: string

	/**
	 * Špatné heslo
	 */
	PasswordLastBadDate?: Date

	/**
	 * Zbývá
	 */
	PasswordTryLeft?: number

	/**
	 * Hash 6
	 */
	PasswordHistory6?: string

	/**
	 * Hash 5
	 */
	PasswordHistory5?: string

	/**
	 * Hash 4
	 */
	PasswordHistory4?: string

	/**
	 * Hash 3
	 */
	PasswordHistory3?: string

	/**
	 * Hash 2
	 */
	PasswordHistory2?: string

	/**
	 * Hash 1
	 */
	PasswordHistory1?: string

	/**
	 * Platnost hesla
	 */
	PasswordValidDate?: Date

	/**
	 * Politika hesla
	 */
	PasswordPolicyId?: number

	/**
	 * Hash
	 */
	Hash?: string

	/**
	 * Časové razítko
	 */
	TimeStamp?: string

	/**
	 * AD_SID
	 */
	ActiveDirectorySID?: string

	/**
	 * Kód
	 */
	Code?: string

	/**
	 * Poznámka
	 */
	Note?: string

	/**
	 * NotUsed2
	 */
	NotUsed2?: number

	/**
	 * Priority
	 */
	PriorityId?: number

	/**
	 * NotUsed1
	 */
	NotUsed1?: number

	/**
	 * ActiveRequest
	 */
	ActiveRequest?: string

	/**
	 * Funkce
	 */
	Position?: string

	/**
	 * Jméno
	 */
	Name?: string

	/**
	 * Mandant
	 */
	Client?: string

	/**
	 * Číslo
	 */
	Id?: number

	/**
	 * Zkratka
	 */
	Login?: string

	/**
	 * Aktuální
	 */
	ActualRecordNumberCalc?: string

	/**
	 * Minimální
	 */
	MinRecordNumberCalc?: string

	/**
	 * Maximální
	 */
	MaxRecordNumberCalc?: string

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Adresář
	 */
	DirectoryCalc?: string

	/**
	 * Číslo stavu
	 */
	StateNumberCalc?: number

	/**
	 * S
	 */
	StateImgCalc?: string

	/**
	 * Skupina pro formuláře
	 */
	NumberGroupFormularCalc?: number

	/**
	 * Stav
	 */
	@Type(() => TTextDM)
	Active?: TTextDM

	/**
	 * undefined
	 */
	@Type(() => TUserRoleForUserDM)
	UserRoleChild?: TUserRoleForUserDM[]

	/**
	 * Změna skupiny práv
	 */
	public ChangeGroupRightCOMMAND() {
		return this.executeCommand('ChangeGroupRightCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public BulkChangeGroupRightCOMMAND() {
		return this.executeCommand('BulkChangeGroupRightCOMMAND')
	}

	/**
	 * Aktualizace počítaných polí
	 */
	public BulkUpdateCalcFieldsCOMMAND() {
		return this.executeCommand('BulkUpdateCalcFieldsCOMMAND')
	}

	/**
	 * Kontrola legislativy
	 */
	public SwitchOffLegislationValidationCOMMAND() {
		return this.executeCommand('SwitchOffLegislationValidationCOMMAND')
	}
}