import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TMeasureUnitDM from './TMeasureUnitDM'
import TArticleDM from './TArticleDM'
import TPriceListDM from './TPriceListDM'
import TPriceListCalculationDM from './TPriceListCalculationDM'

/**
 * Ceníky - položky
 */
export default class TPriceListItemBookDM extends Data {
	public static className = 'TPriceListItemBookDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = true

	/**
	 * Časové razítko
	 */
	TimeStamp?: string

	/**
	 * Kniha
	 */
	PriceListBookId?: number

	/**
	 * Platnost do
	 */
	ValidToDate?: Date

	/**
	 * Platnost od
	 */
	ValidFromDate?: Date

	/**
	 * Pouze pro násobek množství
	 */
	IsOnlyForMultipleOfQuantity?: string

	/**
	 * Kurz
	 */
	ExchangeRate?: number

	/**
	 * Editováno
	 */
	IsEdited?: string

	/**
	 * Položku ceníku uplatnit pouze při prodeji v zadané jednotce
	 */
	IsOnlyForMeasureUnit?: string

	/**
	 * Neaplikovat další slevy
	 */
	IsFinalPrice?: string

	/**
	 * Brutto
	 */
	PriceGross?: number

	/**
	 * Původní cena
	 */
	OriginalPriceNet?: number

	/**
	 * Definice výpočtu
	 */
	DefinitionRID?: number

	/**
	 * Netto
	 */
	PriceNet?: number

	/**
	 * Množství
	 */
	Quantity?: number

	/**
	 * Název
	 */
	ItemName?: string

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * undefined
	 */
	IsByDefinitionCalc?: string

	/**
	 * undefined
	 */
	VATCoefficientCalc?: number

	/**
	 * undefined
	 */
	CurrencyCodeCalc?: string

	/**
	 * Jednotka
	 */
	@Type(() => TMeasureUnitDM)
	MeasureUnitId?: TMeasureUnitDM

	/**
	 * Zboží
	 */
	@Type(() => TArticleDM)
	ArticleId?: TArticleDM

	/**
	 * Ceník
	 */
	@Type(() => TPriceListDM)
	PriceListRID?: TPriceListDM

	/**
	 * Průběh výpočtu ceny
	 */
	@Type(() => TPriceListCalculationDM)
	PriceCalculationChild?: TPriceListCalculationDM[]

	/**
	 * Změna skupiny práv
	 */
	public ChangeGroupRightCOMMAND() {
		return this.executeCommand('ChangeGroupRightCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public BulkChangeGroupRightCOMMAND() {
		return this.executeCommand('BulkChangeGroupRightCOMMAND')
	}

	/**
	 * Aktualizace počítaných polí
	 */
	public BulkUpdateCalcFieldsCOMMAND() {
		return this.executeCommand('BulkUpdateCalcFieldsCOMMAND')
	}

	/**
	 * Kontrola legislativy
	 */
	public SwitchOffLegislationValidationCOMMAND() {
		return this.executeCommand('SwitchOffLegislationValidationCOMMAND')
	}
}