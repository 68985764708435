import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TransportKind from './TransportKind'
import TCountryDM from './TCountryDM'
import JCDP20_a1 from './JCDP20_a1'
import JCDObchod from './JCDObchod'
import TScheduledServiceOperationDM from './TScheduledServiceOperationDM'
import TServicedDeviceDM from './TServicedDeviceDM'
import TDeliveryLineDM from './TDeliveryLineDM'
import TCode6DocumentDM from './TCode6DocumentDM'
import TCode5DocumentDM from './TCode5DocumentDM'
import TCode4DocumentDM from './TCode4DocumentDM'
import TCode3DocumentDM from './TCode3DocumentDM'
import TShippingMethodDM from './TShippingMethodDM'
import TAccountingJournalDM from './TAccountingJournalDM'
import TSalesActionDM from './TSalesActionDM'
import TPriceListDM from './TPriceListDM'
import TCostCentreCollectionDocumentDM from './TCostCentreCollectionDocumentDM'
import TWMSEquipmentDM from './TWMSEquipmentDM'
import TWarehouseZoneDM from './TWarehouseZoneDM'
import TWMSQueueDM from './TWMSQueueDM'
import Priority from './Priority'
import TOfficerDocumentDM from './TOfficerDocumentDM'
import TDeviceDocumentDM from './TDeviceDocumentDM'
import TPeriodDM from './TPeriodDM'
import TBookDM from './TBookDM'
import TSalesOrderDM from './TSalesOrderDM'
import TTransportMethodDM from './TTransportMethodDM'
import TContractCodeDocumentDM from './TContractCodeDocumentDM'
import ReleaseStatusCalc from './ReleaseStatusCalc'
import PlanType from './PlanType'
import TCode2DocumentDM from './TCode2DocumentDM'
import TCode1DocumentDM from './TCode1DocumentDM'
import TMatchingSymbolDM from './TMatchingSymbolDM'
import TK2UserLookupDM from './TK2UserLookupDM'
import TWarehouseDM from './TWarehouseDM'
import TTradingPartnerDM from './TTradingPartnerDM'
import TSpecialStringListDM from './TSpecialStringListDM'
import TCurrencyDM from './TCurrencyDM'
import TTextDM from './TTextDM'
import TBatchDM from './TBatchDM'
import TAddressDM from './TAddressDM'
import TMarketingAddressDM from './TMarketingAddressDM'
import TDeliveryPlanDM from './TDeliveryPlanDM'
import TDeliveryOrderDM from './TDeliveryOrderDM'
import TDeliveryPlanItemBookDM from './TDeliveryPlanItemBookDM'
import TEdiQueueDM from './TEdiQueueDM'
import TMarketingActivityTypeDM from './TMarketingActivityTypeDM'
import TWorkflowProcessDM from './TWorkflowProcessDM'
import TActivityDM from './TActivityDM'
import TLinkDM from './TLinkDM'
import TCommentDM from './TCommentDM'
import TJobDM from './TJobDM'
import TInvoiceOutDM from './TInvoiceOutDM'
import TReservingCardDM from './TReservingCardDM'
import TDeliveryNoteDM from './TDeliveryNoteDM'
import TOrderConfirmationDM from './TOrderConfirmationDM'
import TSalesItemBookDM from './TSalesItemBookDM'
import TExciseTaxSummaryDM from './TExciseTaxSummaryDM'
import TRelatedAddressDocumentDM from './TRelatedAddressDocumentDM'
import TSuperiorDocumentDM from './TSuperiorDocumentDM'
import TSalesItemDM from './TSalesItemDM'
import TAccountingSalesItemDM from './TAccountingSalesItemDM'
import TTransferNoteDM from './TTransferNoteDM'
import TWarehouseTransferDM from './TWarehouseTransferDM'
import TDeliveryOrderItemBookDM from './TDeliveryOrderItemBookDM'
import TExternalDocumentItemDM from './TExternalDocumentItemDM'
import TWorkflowProcessAsItemDM from './TWorkflowProcessAsItemDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TDocumentAccountingJournalItemDM from './TDocumentAccountingJournalItemDM'
import TAttachedDocumentDM from './TAttachedDocumentDM'
import TOldNoteDM from './TOldNoteDM'

/**
 * Výdejky
 */
export default class TReleaseNoteDM extends Data {
	public static className = 'TReleaseNoteDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = true

	/**
	 * Firma
	 */
	BookId_CompanyId?: number

	/**
	 * Skup. právo
	 */
	BookId_RightGroupId?: number

	/**
	 * Právo na záznam
	 */
	SalesOrderRID_RightGroupId?: number

	/**
	 * Právo na záznam
	 */
	TradingPartnerId_RightGroupId?: number

	/**
	 * Dod. adresa
	 */
	DeliveryAddressTrPartId?: number

	/**
	 * Očekávané trvání
	 */
	ExpectedJobDuration?: number

	/**
	 * Požadovaný konec
	 */
	RequiredEndDate?: Date

	/**
	 * Požadovaný začátek
	 */
	RequiredStartDate?: Date

	/**
	 * Intrastat mimo EU
	 */
	IntrastatOutsideEU?: string

	/**
	 * Dobropis v odeslání
	 */
	IntrastatCreditNote?: string

	/**
	 * Malá zásilka
	 */
	IntrastatSmallPackage?: string

	/**
	 * Nazahrnovat do Intrastatu
	 */
	NotIncludeToIntrastat?: string

	/**
	 * Datum přechodu hranice
	 */
	BorderCrossingDate?: Date

	/**
	 * Vytvořeno
	 */
	CreatedOn?: Date

	/**
	 * Nastavení
	 */
	Settings?: string

	/**
	 * Pořadí
	 */
	SequenceNumber?: number

	/**
	 * Datum do
	 */
	EndDate?: Date

	/**
	 * Datum od
	 */
	StartDate?: Date

	/**
	 * Číslo
	 */
	Number?: number

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * Časové razítko
	 */
	TimeStamp?: string

	/**
	 * Likvidoval
	 */
	PostedById?: number

	/**
	 * Likvidováno
	 */
	PostedOn?: Date

	/**
	 * Právo na záznam
	 */
	RightGroupId?: number

	/**
	 * Poc_Lpol
	 */
	AccountingItemsCount?: number

	/**
	 * Poc_Pol
	 */
	ItemCount?: number

	/**
	 * Změněno
	 */
	ChangedOn?: Date

	/**
	 * Čas potvrzení 2
	 */
	ConfirmationTime2?: Date

	/**
	 * Potvrzeno 2
	 */
	ConfirmationDate2?: Date

	/**
	 * Čas potvrzení
	 */
	ConfirmationTime?: Date

	/**
	 * Potvrzeno
	 */
	ConfirmationDate?: Date

	/**
	 * Vystaveno
	 */
	IssueDate?: Date

	/**
	 * Kontace
	 */
	PostingKeyId?: number

	/**
	 * Popis
	 */
	Description?: string

	/**
	 * Převzal
	 */
	AcceptedBy?: string

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * Konec vychystání
	 */
	Ex_FinishDate?: Date

	/**
	 * Začátek vychystání
	 */
	Ex_StartDate?: Date

	/**
	 * Ex_SkipCompletion
	 */
	Ex_SkipCompletion?: string

	/**
	 * EX_Pomocne_Terminy
	 */
	EX_Pomocne_Terminy?: number

	/**
	 * EX_JIT
	 */
	EX_JIT?: string

	/**
	 * EX_MistoBaleni
	 */
	EX_MistoBaleni?: string

	/**
	 * EX_blok
	 */
	EX_blok?: string

	/**
	 * EX_Slouceni
	 */
	EX_Slouceni?: string

	/**
	 * EX_Ohradka
	 */
	EX_Ohradka?: number

	/**
	 * EX_Prior
	 */
	EX_Prior?: number

	/**
	 * EX_Cvych
	 */
	EX_Cvych?: Date

	/**
	 * EX_Dvych
	 */
	EX_Dvych?: Date

	/**
	 * undefined
	 */
	BookmarkImgCalc?: string

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Číslo
	 */
	DocumentNumberCalc?: number

	/**
	 * SQLSequenceOrderCalc
	 */
	SQLSequenceOrderCalc?: number

	/**
	 * Datum pro "_ver" pole
	 */
	VersionedFieldDateCalc?: Date

	/**
	 * Odkaz
	 */
	PictureCalc?: string

	/**
	 * Kurz
	 */
	ExchangeRateCalc?: number

	/**
	 * Měna vl. f.
	 */
	CompanyCurrencyCalc?: string

	/**
	 * Kurz Z.
	 */
	HeaderExchangeRateCalc?: number

	/**
	 * PrepMena
	 */
	CurrencySwitchCalc?: number

	/**
	 * V
	 */
	SNReceiptReleaseFilterImgCalc?: string

	/**
	 * Fi_C_Zbo
	 */
	FilterArticleIdCalc?: number

	/**
	 * Fi_Zap
	 */
	IsFilterOnCalc?: string

	/**
	 * Fi_ZapSar
	 */
	FilterBatchOnBitCalc?: number

	/**
	 * Netto p
	 */
	TotalItemPlannedPriceNetCalc?: number

	/**
	 * Skl p
	 */
	TotalItemPlStockPriceCalc?: number

	/**
	 * Skl pm
	 */
	TotalItemPlStockPriceCCalc?: number

	/**
	 * Zisk
	 */
	ProfitCalc?: number

	/**
	 * Zisk M
	 */
	ProfitCCalc?: number

	/**
	 * Zisk
	 */
	MarginCalc?: number

	/**
	 * V měně
	 */
	CurrencyBySwitchCalc?: string

	/**
	 * i
	 */
	IgnorePriceCalc?: number

	/**
	 * K
	 */
	AssignedContractRIDCalc?: number

	/**
	 * Reduction value
	 */
	ReductionValueCalc?: number

	/**
	 * Reduction abs
	 */
	ReductionTypeBitCalc?: number

	/**
	 * Reduction abs.
	 */
	ReductionAmountCalc?: number

	/**
	 * Reduction zn.
	 */
	ReductionSignCalc?: number

	/**
	 * Reduction zn.
	 */
	ReductionSignImgCalc?: string

	/**
	 * Plánovaná přirážka
	 */
	PlannedSurchargeAmountCalc?: number

	/**
	 * Skl p
	 */
	TotalItemPlStockBySettPriceCalc?: number

	/**
	 * Datum rozvozu
	 */
	DeliveryOrderDateCalc?: Date

	/**
	 * undefined
	 */
	InvoiceAddressExistsImgCalc?: string

	/**
	 * undefined
	 */
	DeliveryAddressExistsImgCalc?: string

	/**
	 * undefined
	 */
	PostalAddressExistsImgCalc?: string

	/**
	 * Název z fakt. adresy
	 */
	InvoiceAddressFullNameCalc?: string

	/**
	 * Název z dod. adresy
	 */
	DeliveryAddressFullNameCalc?: string

	/**
	 * Název z koresp. adresy
	 */
	PostalAddressFullNameCalc?: string

	/**
	 * Název z koresp./fakt. adresy
	 */
	PostalInvoiceAddressFullNameCalc?: string

	/**
	 * undefined
	 */
	TotalItemActualPriceNetCalc?: number

	/**
	 * Skl s
	 */
	TotalItemActualStockPriceCalc?: number

	/**
	 * Skl sm
	 */
	TotalItemActualStockPriceCCalc?: number

	/**
	 * undefined
	 */
	ActualProfitCalc?: number

	/**
	 * Skut. zisk
	 */
	ActualProfitCCalc?: number

	/**
	 * Skut. marže
	 */
	ActualMarginAmountCalc?: number

	/**
	 * Skutečná přirážka
	 */
	ActualSurchargeAmountCalc?: number

	/**
	 * Plán. skladová cena
	 */
	TotalItemActStockSettPriceCalc?: number

	/**
	 * undefined
	 */
	QRCodeCZBankOrderStandardCalc?: string

	/**
	 * undefined
	 */
	QRCodeCZBankOrderLargeCalc?: string

	/**
	 * undefined
	 */
	QRCodeSKBankOrderPayBySquareCalc?: string

	/**
	 * undefined
	 */
	QRSKBankOrderCSOBCalc?: string

	/**
	 * undefined
	 */
	MarketingDocumentRIDCalc?: number

	/**
	 * ExternalAddressCalc
	 */
	ExternalAddressCalc?: string

	/**
	 * TradingPartnerInternalTextCalc
	 */
	TradingPartnerInternalTextCalc?: string

	/**
	 * Datum od
	 */
	DateFromCalc?: Date

	/**
	 * Čas od
	 */
	StartTimeCalc?: Date

	/**
	 * Datum do
	 */
	DateToCalc?: Date

	/**
	 * Čas do
	 */
	EndTimeCalc?: Date

	/**
	 * Fakturační adresa
	 */
	InvoiceAddressPlainCalc?: string

	/**
	 * Dodací adresa
	 */
	DeliveryAddressPlainCalc?: string

	/**
	 * Korespondenční adresa
	 */
	PostalAddressPlainCalc?: string

	/**
	 * Chybné částky
	 */
	IncorrectAmountBitCalc?: number

	/**
	 * Výpočet DPH shora
	 */
	VATGrossCalcMethodBitCalc?: number

	/**
	 * Více nadřízených dokladů
	 */
	MultiDocumentBitCalc?: number

	/**
	 * V likvidaci bez částky
	 */
	PostZeroAmountDocumentsBitCalc?: number

	/**
	 * Spotřební daň
	 */
	ExciseTaxBitCalc?: number

	/**
	 * Neshodná sériová čísla na skladovém dokladu
	 */
	NotEqualSerialNumberBitCalc?: number

	/**
	 * Odpotvrzení bez skladové evidence
	 */
	SerialNumberNotUpdatedBitCalc?: number

	/**
	 * QR faktura
	 */
	QRCZInvoiceCalc?: string

	/**
	 * DIČ
	 */
	VATRegNumberCalc?: string

	/**
	 * Firma
	 */
	TaxTradingPartnerIdCalc?: number

	/**
	 * Prodej pouze z ceníku
	 */
	ArticleFromPriceListIdCalc?: number

	/**
	 * undefined
	 */
	TransportMethodRIDCalc?: number

	/**
	 * Hmotnost netto
	 */
	TotalWeightNetCalc?: number

	/**
	 * Hmotnost brutto
	 */
	TotalWeightGrossCalc?: number

	/**
	 * undefined
	 */
	DeliveryOrderItemRIDCalc?: number

	/**
	 * undefined
	 */
	DeliveryOrderTimeCalc?: Date

	/**
	 * Objem celkem
	 */
	TotalVolumePARAMCalc?: number

	/**
	 * Firma
	 */
	TaxTradingPartnerNameCalc?: string

	/**
	 * Požadovaný začátek - datum
	 */
	RequiredStartDateCalc?: Date

	/**
	 * Požadovaný začátek - čas
	 */
	RequiredStartTimeCalc?: Date

	/**
	 * Požadovaný konec - datum
	 */
	RequiredEndDateCalc?: Date

	/**
	 * Požadovaný konec - čas
	 */
	RequiredEndTimeCalc?: Date

	/**
	 * undefined
	 */
	TextHeaderMemoCalc?: string

	/**
	 * undefined
	 */
	TextFooterMemoCalc?: string

	/**
	 * Poslední komentář
	 */
	LastCommentParamCalc?: string

	/**
	 * Částka dokladu
	 */
	DocumentAmountCalc?: number

	/**
	 * Saldo
	 */
	AccountBalanceCalc?: number

	/**
	 * Účet
	 */
	DocumentAccountCalc?: string

	/**
	 * Z
	 */
	PostedCalc?: string

	/**
	 * Datum zaúčtování
	 */
	PostedOnCalc?: Date

	/**
	 * Rozdíl v zaúčtování
	 */
	AccountingDocDifferenceCalc?: number

	/**
	 * undefined
	 */
	OwnAddressIdCalc?: number

	/**
	 * undefined
	 */
	OwnAddressNameCalc?: string

	/**
	 * Doklad
	 */
	DocumentIdentificationCalc?: string

	/**
	 * ZR
	 */
	PostedManuallyCalc?: string

	/**
	 * EAN kód
	 */
	BarcodeCalc?: string

	/**
	 * Zaúčtovat bez částky
	 */
	PostZeroAmountBitCalc?: number

	/**
	 * undefined
	 */
	IsReversedExchangeRateViewCalc?: string

	/**
	 * undefined
	 */
	AccAssignmentPostingViewCalc?: string

	/**
	 * Hmotnost
	 */
	CF_hmotn_vyd?: number

	/**
	 * Historie vychystání
	 */
	CF_HistVych?: string

	/**
	 * Čas vychystání
	 */
	PickingTimeCalc?: number

	/**
	 * Právo na záznam
	 */
	RightGroupIdCalc?: number

	/**
	 * Druh dopravy
	 */
	@Type(() => TransportKind)
	IntrastatTransportKindId?: TransportKind

	/**
	 * Stát odeslání
	 */
	@Type(() => TCountryDM)
	IntrastatCountryId?: TCountryDM

	/**
	 * Dodací podmínky
	 */
	@Type(() => JCDP20_a1)
	DeliveryTermsId?: JCDP20_a1

	/**
	 * Transakce
	 */
	@Type(() => JCDObchod)
	IntrastatTransactionId?: JCDObchod

	/**
	 * Servisní úkon
	 */
	@Type(() => TScheduledServiceOperationDM)
	ServiceOperationRID?: TScheduledServiceOperationDM

	/**
	 * Servisované zařízení
	 */
	@Type(() => TServicedDeviceDM)
	ServicedDeviceRID?: TServicedDeviceDM

	/**
	 * Linka
	 */
	@Type(() => TDeliveryLineDM)
	DeliveryLineId?: TDeliveryLineDM

	/**
	 * Kód 6
	 */
	@Type(() => TCode6DocumentDM)
	Code6Id?: TCode6DocumentDM

	/**
	 * Kód 5
	 */
	@Type(() => TCode5DocumentDM)
	Code5Id?: TCode5DocumentDM

	/**
	 * Kód 4
	 */
	@Type(() => TCode4DocumentDM)
	Code4Id?: TCode4DocumentDM

	/**
	 * Kód 3
	 */
	@Type(() => TCode3DocumentDM)
	Code3Id?: TCode3DocumentDM

	/**
	 * Zp.odběru
	 */
	@Type(() => TShippingMethodDM)
	ShippingMethodId?: TShippingMethodDM

	/**
	 * Účetní doklad
	 */
	@Type(() => TAccountingJournalDM)
	AccountingDocumentRID?: TAccountingJournalDM

	/**
	 * Prodejní akce
	 */
	@Type(() => TSalesActionDM)
	SellingActionRID?: TSalesActionDM

	/**
	 * Ceník
	 */
	@Type(() => TPriceListDM)
	PriceListRID?: TPriceListDM

	/**
	 * Středisko
	 */
	@Type(() => TCostCentreCollectionDocumentDM)
	CostCentreId?: TCostCentreCollectionDocumentDM

	/**
	 * Manip.technika
	 */
	@Type(() => TWMSEquipmentDM)
	HandlingEquipmentId?: TWMSEquipmentDM

	/**
	 * Sklad.zóna
	 */
	@Type(() => TWarehouseZoneDM)
	WarehouseZoneRID?: TWarehouseZoneDM

	/**
	 * Fronta
	 */
	@Type(() => TWMSQueueDM)
	QueueId?: TWMSQueueDM

	/**
	 * Priorita
	 */
	@Type(() => Priority)
	PriorityId?: Priority

	/**
	 * Skladník
	 */
	@Type(() => TOfficerDocumentDM)
	WarehousemanId?: TOfficerDocumentDM

	/**
	 * Prostředek
	 */
	@Type(() => TDeviceDocumentDM)
	DeviceRID?: TDeviceDocumentDM

	/**
	 * Období
	 */
	@Type(() => TPeriodDM)
	BusinessYearId?: TPeriodDM

	/**
	 * Kniha
	 */
	@Type(() => TBookDM)
	BookId?: TBookDM

	/**
	 * Zakázka
	 */
	@Type(() => TSalesOrderDM)
	SalesOrderRID?: TSalesOrderDM

	/**
	 * Zp. dopravy
	 */
	@Type(() => TTransportMethodDM)
	TransportMethodRID?: TTransportMethodDM

	/**
	 * Kód zakázky
	 */
	@Type(() => TContractCodeDocumentDM)
	ContractCodeRID?: TContractCodeDocumentDM

	/**
	 * Stav
	 */
	@Type(() => ReleaseStatusCalc)
	StatusId?: ReleaseStatusCalc

	/**
	 * Typ plánu
	 */
	@Type(() => PlanType)
	PlanTypeId?: PlanType

	/**
	 * Kód 2
	 */
	@Type(() => TCode2DocumentDM)
	Code2Id?: TCode2DocumentDM

	/**
	 * Kód 1
	 */
	@Type(() => TCode1DocumentDM)
	Code1Id?: TCode1DocumentDM

	/**
	 * Referent
	 */
	@Type(() => TOfficerDocumentDM)
	OfficerId?: TOfficerDocumentDM

	/**
	 * Pár.symb.
	 */
	@Type(() => TMatchingSymbolDM)
	MatchingSymbolId?: TMatchingSymbolDM

	/**
	 * Vytvořil
	 */
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM

	/**
	 * Vyskladnil 2
	 */
	@Type(() => TK2UserLookupDM)
	ConfirmedBy2Id?: TK2UserLookupDM

	/**
	 * Vyskladnil
	 */
	@Type(() => TK2UserLookupDM)
	ConfirmedById?: TK2UserLookupDM

	/**
	 * Změnil
	 */
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM

	/**
	 * Sklad
	 */
	@Type(() => TWarehouseDM)
	WarehouseId?: TWarehouseDM

	/**
	 * Firma
	 */
	@Type(() => TTradingPartnerDM)
	TradingPartnerId?: TTradingPartnerDM

	/**
	 * EX_Nosic
	 */
	@Type(() => TSpecialStringListDM)
	EX_Nosic?: TSpecialStringListDM

	/**
	 * EX_Stav
	 */
	@Type(() => TSpecialStringListDM)
	EX_Stav?: TSpecialStringListDM

	/**
	 * Období
	 */
	@Type(() => TPeriodDM)
	BusinessYearIdCalc?: TPeriodDM

	/**
	 * Měna nadř. d.
	 */
	@Type(() => TCurrencyDM)
	HeaderCurrencyCalc?: TCurrencyDM

	/**
	 * P
	 */
	@Type(() => TTextDM)
	ConfirmedOrCanceledIdCalc?: TTextDM

	/**
	 * CFFi_PlMi
	 */
	@Type(() => TTextDM)
	SNRecRelFilterStateCalc?: TTextDM

	/**
	 * Fi_Zaruky
	 */
	@Type(() => TBatchDM)
	FilterBatchIdCalc?: TBatchDM

	/**
	 * N
	 */
	@Type(() => TTextDM)
	SerialNumberInconsistencyBitCalc?: TTextDM

	/**
	 * Doklad je na Intrastatu
	 */
	@Type(() => TTextDM)
	IntrastatDocumentIdCalc?: TTextDM

	/**
	 * Fakt. adresa
	 */
	@Type(() => TTradingPartnerDM)
	InvoiceAddressTrPartIdCalc?: TTradingPartnerDM

	/**
	 * Dod. adresa
	 */
	@Type(() => TTradingPartnerDM)
	DeliveryAddressTrPartIdCalc?: TTradingPartnerDM

	/**
	 * Cizí měna
	 */
	@Type(() => TTextDM)
	ForeignCurrencySwitchBitCalc?: TTextDM

	/**
	 * DPH
	 */
	@Type(() => TTextDM)
	ForeignVATBitCalc?: TTextDM

	/**
	 * Fakturační adresa
	 */
	@Type(() => TAddressDM)
	InvoiceAddressIdCalc?: TAddressDM

	/**
	 * Dodací adresa
	 */
	@Type(() => TAddressDM)
	DeliveryAddressIdCalc?: TAddressDM

	/**
	 * Korespondenční adresa
	 */
	@Type(() => TAddressDM)
	PostalAddressIdCalc?: TAddressDM

	/**
	 * Fakturační adresa (tisk)
	 */
	@Type(() => TAddressDM)
	FullInvoiceAddressIdCalc?: TAddressDM

	/**
	 * Dodací adresa (tisk)
	 */
	@Type(() => TAddressDM)
	FullDeliveryAddressIdCalc?: TAddressDM

	/**
	 * Korespondenční adresa (tisk)
	 */
	@Type(() => TAddressDM)
	FullPostalAddressIdCalc?: TAddressDM

	/**
	 * Koresp./Fakt. adresa (tisk)
	 */
	@Type(() => TAddressDM)
	FullPostalInvoiceAddressIdCalc?: TAddressDM

	/**
	 * Kor. adresa
	 */
	@Type(() => TTradingPartnerDM)
	PostalAddressTrPartIdCalc?: TTradingPartnerDM

	/**
	 * P/S
	 */
	@Type(() => TTextDM)
	ConfirmedOrCanceled2IdCalc?: TTextDM

	/**
	 * Jedn. adresa - fakturační
	 */
	@Type(() => TMarketingAddressDM)
	SimpleInvoiceAddressRIDCalc?: TMarketingAddressDM

	/**
	 * Jedn. adresa - dodací
	 */
	@Type(() => TMarketingAddressDM)
	SimpleDeliveryAddressRIDCalc?: TMarketingAddressDM

	/**
	 * Jedn. adresa - korespondenční
	 */
	@Type(() => TMarketingAddressDM)
	SimplePostalAddressRIDCalc?: TMarketingAddressDM

	/**
	 * undefined
	 */
	@Type(() => TDeliveryPlanDM)
	DeliveryPlanRIDCalc?: TDeliveryPlanDM

	/**
	 * undefined
	 */
	@Type(() => TDeliveryOrderDM)
	DeliveryOrderRIDCalc?: TDeliveryOrderDM

	/**
	 * undefined
	 */
	@Type(() => TDeliveryPlanItemBookDM)
	DeliveryPlanItemRIDCalc?: TDeliveryPlanItemBookDM

	/**
	 * Stav v příchozí EDI frontě
	 */
	@Type(() => TTextDM)
	EdiQueueMessageInStatusIdCalc?: TTextDM

	/**
	 * Zařazení v příchozí EDI frontě
	 */
	@Type(() => TEdiQueueDM)
	EdiQueueMessageInRIDCalc?: TEdiQueueDM

	/**
	 * D
	 */
	@Type(() => TTextDM)
	DocumentExistsCalc?: TTextDM

	/**
	 * undefined
	 */
	@Type(() => TMarketingActivityTypeDM)
	ActivityTypeRIDCalc?: TMarketingActivityTypeDM

	/**
	 * SP
	 */
	@Type(() => TTextDM)
	ProcessStateIdCalc?: TTextDM

	/**
	 * Aktuální proces
	 */
	@Type(() => TWorkflowProcessDM)
	CurrentProcessIdCalc?: TWorkflowProcessDM

	/**
	 * undefined
	 */
	@Type(() => TActivityDM)
	ActivityIdCalc?: TActivityDM

	/**
	 * Zaúčtoval
	 */
	@Type(() => TK2UserLookupDM)
	PostedByIdCalc?: TK2UserLookupDM

	/**
	 * undefined
	 */
	@Type(() => TTradingPartnerDM)
	OwnTradingPartnerIdCalc?: TTradingPartnerDM

	/**
	 * Storno
	 */
	@Type(() => TTextDM)
	CanceledRecordBitCalc?: TTextDM

	/**
	 * Příznak Z
	 */
	@Type(() => TTextDM)
	CorrectPostingBitCalc?: TTextDM

	/**
	 * Období
	 */
	@Type(() => TPeriodDM)
	CurrentBusinessYearIdCalc?: TPeriodDM

	/**
	 * Sklad
	 */
	@Type(() => TWarehouseDM)
	CurrentWarehouseIdCalc?: TWarehouseDM

	/**
	 * Firma
	 */
	@Type(() => TTradingPartnerDM)
	TradingPartnerByIdInputIdCalc?: TTradingPartnerDM

	/**
	 * Firma
	 */
	@Type(() => TTradingPartnerDM)
	TradingPartnerByAbbr2InputIdCalc?: TTradingPartnerDM

	/**
	 * Firma
	 */
	@Type(() => TTradingPartnerDM)
	TradingPartnerNameInputIdCalc?: TTradingPartnerDM

	/**
	 * Firma
	 */
	@Type(() => TTradingPartnerDM)
	TrPartByCompRegNumberInputIdCalc?: TTradingPartnerDM

	/**
	 * Odkazy
	 */
	@Type(() => TLinkDM)
	LinkChild?: TLinkDM[]

	/**
	 * Komentáře
	 */
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]

	/**
	 * Úkoly
	 */
	@Type(() => TJobDM)
	JobChild?: TJobDM[]

	/**
	 * Faktury vydané
	 */
	@Type(() => TInvoiceOutDM)
	InvoiceOutChild?: TInvoiceOutDM[]

	/**
	 * Rezervační listy
	 */
	@Type(() => TReservingCardDM)
	ReservingCardChild?: TReservingCardDM[]

	/**
	 * Dodací listy
	 */
	@Type(() => TDeliveryNoteDM)
	DeliveryNoteChild?: TDeliveryNoteDM[]

	/**
	 * Objedávky přijaté
	 */
	@Type(() => TOrderConfirmationDM)
	OrderConfirmationChild?: TOrderConfirmationDM[]

	/**
	 * Zakázky
	 */
	@Type(() => TSalesOrderDM)
	SalesOrderChild?: TSalesOrderDM[]

	/**
	 * undefined
	 */
	@Type(() => TSalesItemBookDM)
	ItemChild?: TSalesItemBookDM[]

	/**
	 * Spotřební daň
	 */
	@Type(() => TExciseTaxSummaryDM)
	DocumentExciseTaxChild?: TExciseTaxSummaryDM[]

	/**
	 * Adresáti
	 */
	@Type(() => TRelatedAddressDocumentDM)
	DocumentAddressChild?: TRelatedAddressDocumentDM[]

	/**
	 * Nadřízené doklady
	 */
	@Type(() => TSuperiorDocumentDM)
	SuperiorDocumentChild?: TSuperiorDocumentDM[]

	/**
	 * Položky prodeje
	 */
	@Type(() => TSalesItemDM)
	SalesItemChild?: TSalesItemDM[]

	/**
	 * undefined
	 */
	@Type(() => TAccountingSalesItemDM)
	AccountingSalesItemChild?: TAccountingSalesItemDM[]

	/**
	 * undefined
	 */
	@Type(() => TTransferNoteDM)
	TransferNoteChild?: TTransferNoteDM[]

	/**
	 * undefined
	 */
	@Type(() => TWarehouseTransferDM)
	WarehouseTransferChild?: TWarehouseTransferDM[]

	/**
	 * undefined
	 */
	@Type(() => TDeliveryOrderItemBookDM)
	DistributionChild?: TDeliveryOrderItemBookDM[]

	/**
	 * Seznam aktivit
	 */
	@Type(() => TActivityDM)
	ActivityChild?: TActivityDM[]

	/**
	 * Dokumenty
	 */
	@Type(() => TExternalDocumentItemDM)
	ExternalDocumentItemChild?: TExternalDocumentItemDM[]

	/**
	 * Procesy
	 */
	@Type(() => TWorkflowProcessAsItemDM)
	WorkflowProcessChild?: TWorkflowProcessAsItemDM[]

	/**
	 * Text hlavičky
	 */
	@Type(() => TLanguageMemoTranslationDM)
	TextHeaderChild?: TLanguageMemoTranslationDM[]

	/**
	 * Text patičky
	 */
	@Type(() => TLanguageMemoTranslationDM)
	TextFooterChild?: TLanguageMemoTranslationDM[]

	/**
	 * undefined
	 */
	@Type(() => TDocumentAccountingJournalItemDM)
	DocumentAccJournalItemChild?: TDocumentAccountingJournalItemDM[]

	/**
	 * Svázané doklady
	 */
	@Type(() => TAttachedDocumentDM)
	AttachedDocumentOldChild?: TAttachedDocumentDM[]

	/**
	 * Poznámky
	 */
	@Type(() => TOldNoteDM)
	DeprecatedNoteOldChild?: TOldNoteDM[]

	/**
	 * Potvrzení bez kontroly
	 */
	public BulkConfirmWithoutCheckCOMMAND() {
		return this.executeCommand('BulkConfirmWithoutCheckCOMMAND')
	}

	/**
	 * Odpotvrzení bez kontroly
	 */
	public BulkUnConfirmWithoutCheckCOMMAND() {
		return this.executeCommand('BulkUnConfirmWithoutCheckCOMMAND')
	}

	/**
	 * Ceny v měně / bez měny
	 */
	public SwitchShowCurrencyCOMMAND() {
		return this.executeCommand('SwitchShowCurrencyCOMMAND')
	}

	/**
	 * Plánované datum zaplacení
	 */
	public PlanPayDateCOMMAND(parameters: TPlanPayDateCOMMANDParams) {
		return this.executeCommand('PlanPayDateCOMMAND', parameters)
	}

	/**
	 * Nastavení platebních podmínek
	 */
	public PaymentConditionSettingCOMMAND(parameters: TPaymentConditionSettingCOMMANDParams) {
		return this.executeCommand('PaymentConditionSettingCOMMAND', parameters)
	}

	/**
	 * Přepočet plateb
	 */
	public PaymentRecalculationCOMMAND() {
		return this.executeCommand('PaymentRecalculationCOMMAND')
	}

	/**
	 * Změna platby archivem
	 */
	public ChangePaymentArchCOMMAND(parameters: TChangePaymentArchCOMMANDParams) {
		return this.executeCommand('ChangePaymentArchCOMMAND', parameters)
	}

	/**
	 * Doplň šarže a umístění
	 */
	public CompleteBatchLocCOMMAND() {
		return this.executeCommand('CompleteBatchLocCOMMAND')
	}

	/**
	 * Vybrat PS z účtu
	 */
	public WizardMatchSymbolCOMMAND() {
		return this.executeCommand('WizardMatchSymbolCOMMAND')
	}

	/**
	 * 
	 */
	public SetSimpleInvoiceAddressCOMMAND(parameters: TSetSimpleInvoiceAddressCOMMANDParams) {
		return this.executeCommand('SetSimpleInvoiceAddressCOMMAND', parameters)
	}

	/**
	 * 
	 */
	public SetSimpleDeliveryAddressCOMMAND(parameters: TSetSimpleDeliveryAddressCOMMANDParams) {
		return this.executeCommand('SetSimpleDeliveryAddressCOMMAND', parameters)
	}

	/**
	 * Přidat položky na podřízený doklad (WS)
	 */
	public AddItemsToSubDocWSCOMMAND(parameters: TAddItemsToSubDocWSCOMMANDParams) {
		return this.executeCommand('AddItemsToSubDocWSCOMMAND', parameters)
	}

	/**
	 * Odebrat položky z podřízeného dokladu (WS)
	 */
	public RemoveItemsFromSubDocWSCOMMAND(parameters: TRemoveItemsFromSubDocWSCOMMANDParams) {
		return this.executeCommand('RemoveItemsFromSubDocWSCOMMAND', parameters)
	}

	/**
	 * Kontejner zboží
	 */
	public AddGoodsToContainerCOMMAND(parameters: TAddGoodsToContainerCOMMANDParams) {
		return this.executeCommand('AddGoodsToContainerCOMMAND', parameters)
	}

	/**
	 * Částečné potvrzení
	 */
	public PartialConfirmCOMMAND() {
		return this.executeCommand('PartialConfirmCOMMAND')
	}

	/**
	 * Částečné odpotvrzení
	 */
	public PartialUnconfirmCOMMAND() {
		return this.executeCommand('PartialUnconfirmCOMMAND')
	}

	/**
	 * Částečné potvrzení
	 */
	public BulkPartialConfirmCOMMAND() {
		return this.executeCommand('BulkPartialConfirmCOMMAND')
	}

	/**
	 * Částečné odpotvrzení
	 */
	public BulkPartialUnconfirmCOMMAND() {
		return this.executeCommand('BulkPartialUnconfirmCOMMAND')
	}

	/**
	 * Přecenění k rozvahovému dni
	 */
	public BulkRevaluationAtBalanceDayCOMMAND() {
		return this.executeCommand('BulkRevaluationAtBalanceDayCOMMAND')
	}

	/**
	 * Storno přecenění k rozvahovému dni
	 */
	public BulkCancelRevaluationCOMMAND() {
		return this.executeCommand('BulkCancelRevaluationCOMMAND')
	}

	/**
	 * Změna DUZP
	 */
	public InvoiceDateChangeCOMMAND(parameters: TInvoiceDateChangeCOMMANDParams) {
		return this.executeCommand('InvoiceDateChangeCOMMAND', parameters)
	}

	/**
	 * Zaúčtování s likvidací
	 */
	public AccountAssignmentPostingCOMMAND(parameters: TAccountAssignmentPostingCOMMANDParams) {
		return this.executeCommand('AccountAssignmentPostingCOMMAND', parameters)
	}

	/**
	 * Hromadné zaúčtování s likvidací
	 */
	public BulkAccountAssignmentPostingCOMMAND(parameters: TBulkAccountAssignmentPostingCOMMANDParams) {
		return this.executeCommand('BulkAccountAssignmentPostingCOMMAND', parameters)
	}

	/**
	 * Likvidace
	 */
	public AccountAssignmentCOMMAND() {
		return this.executeCommand('AccountAssignmentCOMMAND')
	}

	/**
	 * Zapnout rychlý filtr
	 */
	public FastFilterSwitchCOMMAND() {
		return this.executeCommand('FastFilterSwitchCOMMAND')
	}

	/**
	 * Povolit editaci
	 */
	public EditModeIDNoCOMMAND() {
		return this.executeCommand('EditModeIDNoCOMMAND')
	}

	/**
	 * Povolit editaci
	 */
	public EditModeVATRegisterNoCOMMAND() {
		return this.executeCommand('EditModeVATRegisterNoCOMMAND')
	}

	/**
	 * Povolit editaci
	 */
	public EditModeTaxNoCOMMAND() {
		return this.executeCommand('EditModeTaxNoCOMMAND')
	}

	/**
	 * Změna kontace
	 */
	public BulkChangePostingKeyCOMMAND(parameters: TBulkChangePostingKeyCOMMANDParams) {
		return this.executeCommand('BulkChangePostingKeyCOMMAND', parameters)
	}

	/**
	 * Aktualizace Plánovaného data zaplacení dle Prodlení Dod./Odb.
	 */
	public BulkUpdatePlannedDateOfPaymentByAvgDelayCOMMAND() {
		return this.executeCommand('BulkUpdatePlannedDateOfPaymentByAvgDelayCOMMAND')
	}

	/**
	 * Zobrazovat data od období: %s
	 */
	public ApplyLowerLimitForData() {
		return this.executeCommand('ApplyLowerLimitForData')
	}

	/**
	 * Potvrzení
	 */
	public BulkConfirmCOMMAND() {
		return this.executeCommand('BulkConfirmCOMMAND')
	}

	/**
	 * Odpotvrzení
	 */
	public BulkUnconfirmCOMMAND() {
		return this.executeCommand('BulkUnconfirmCOMMAND')
	}

	/**
	 * Změna data potvrzení
	 */
	public BulkChangeConfirmationDateCOMMAND(parameters: TBulkChangeConfirmationDateCOMMANDParams) {
		return this.executeCommand('BulkChangeConfirmationDateCOMMAND', parameters)
	}

	/**
	 * Storno
	 */
	public BulkCancelCOMMAND() {
		return this.executeCommand('BulkCancelCOMMAND')
	}

	/**
	 * Odstorno
	 */
	public BulkUnCancelCOMMAND() {
		return this.executeCommand('BulkUnCancelCOMMAND')
	}

	/**
	 * Potvrzení
	 */
	public ConfirmCOMMAND() {
		return this.executeCommand('ConfirmCOMMAND')
	}

	/**
	 * Odpotvrzení
	 */
	public UnconfirmCOMMAND() {
		return this.executeCommand('UnconfirmCOMMAND')
	}

	/**
	 * Změna data potvrzení
	 */
	public ChangeConfirmationDateCOMMAND(parameters: TChangeConfirmationDateCOMMANDParams) {
		return this.executeCommand('ChangeConfirmationDateCOMMAND', parameters)
	}

	/**
	 * Storno/Odstorno
	 */
	public CancelCOMMAND() {
		return this.executeCommand('CancelCOMMAND')
	}

	/**
	 * Zrušení stavu
	 */
	public ClearRecordStatusCOMMAND() {
		return this.executeCommand('ClearRecordStatusCOMMAND')
	}

	/**
	 * Změna stavu
	 */
	public ChangeRecordStatusCOMMAND() {
		return this.executeCommand('ChangeRecordStatusCOMMAND')
	}

	/**
	 * 
	 */
	public ChangeRecordStatusSWSCOMMAND(parameters: TChangeRecordStatusSWSCOMMANDParams) {
		return this.executeCommand('ChangeRecordStatusSWSCOMMAND', parameters)
	}

	/**
	 * Zrušení stavu
	 */
	public BulkClearRecordStatusCOMMAND() {
		return this.executeCommand('BulkClearRecordStatusCOMMAND')
	}

	/**
	 * Změna stavu
	 */
	public BulkChangeRecordStatusCOMMAND() {
		return this.executeCommand('BulkChangeRecordStatusCOMMAND')
	}

	/**
	 * Změna atributů
	 */
	public BulkChangeAttributesCOMMAND(parameters: TBulkChangeAttributesCOMMANDParams) {
		return this.executeCommand('BulkChangeAttributesCOMMAND', parameters)
	}

	/**
	 * Aktualizace sady atributů
	 */
	public BulkActualizeAttributesCOMMAND(parameters: TBulkActualizeAttributesCOMMANDParams) {
		return this.executeCommand('BulkActualizeAttributesCOMMAND', parameters)
	}

	/**
	 * Aktualizace sady atributů
	 */
	public ActualizeAttributesCOMMAND() {
		return this.executeCommand('ActualizeAttributesCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public ChangeGroupRightCOMMAND() {
		return this.executeCommand('ChangeGroupRightCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public BulkChangeGroupRightCOMMAND() {
		return this.executeCommand('BulkChangeGroupRightCOMMAND')
	}

	/**
	 * Aktualizace počítaných polí
	 */
	public BulkUpdateCalcFieldsCOMMAND() {
		return this.executeCommand('BulkUpdateCalcFieldsCOMMAND')
	}

	/**
	 * Kontrola legislativy
	 */
	public SwitchOffLegislationValidationCOMMAND() {
		return this.executeCommand('SwitchOffLegislationValidationCOMMAND')
	}
}

export type TPlanPayDateCOMMANDParams = {
	/**
	 * Datum
	 */
	Value?: Date
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TPaymentConditionSettingCOMMANDParams = {
	/**
	 * Při zaplacení do
	 */
	PACOF_Days1?: number
	/**
	 * Skonto
	 */
	PACOF_Perc1?: number
	/**
	 * Částka
	 */
	PACOF_Amount1?: number
	/**
	 * Při zaplacení do
	 */
	PACOF_Days2?: number
	/**
	 * Skonto
	 */
	PACOF_Perc2?: number
	/**
	 * Částka
	 */
	PACOF_Amount2?: number
	/**
	 * Skonto z netto částky
	 */
	PACOF_Netto?: boolean
	/**
	 * NalezenaSplatnost
	 */
	NalezenaSplatnost?: boolean
	/**
	 * NalezenoSkonto1
	 */
	NalezenoSkonto1?: boolean
	/**
	 * NalezenoSkonto2
	 */
	NalezenoSkonto2?: boolean
	/**
	 * UniqSplatnost
	 */
	UniqSplatnost?: number
	/**
	 * UniqSkonto1
	 */
	UniqSkonto1?: number
	/**
	 * UniqSkonto2
	 */
	UniqSkonto2?: number
	/**
	 * BruttoM
	 */
	BruttoM?: number
	/**
	 * NettoM
	 */
	NettoM?: number
}

export type TChangePaymentArchCOMMANDParams = {
	/**
	 * Zaplaceno archivem
	 */
	PaidA?: number
	/**
	 * CurrencyCNG
	 */
	CurrencyCNG?: string
	/**
	 * Zaplaceno archivem M
	 */
	PaidCA?: number
	/**
	 * Currency
	 */
	Currency?: string
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TSetSimpleInvoiceAddressCOMMANDParams = {
	/**
	 * Name
	 */
	Name?: string
	/**
	 * Street
	 */
	Street?: string
	/**
	 * Number
	 */
	Number?: string
	/**
	 * Town
	 */
	Town?: string
	/**
	 * PostCode
	 */
	PostCode?: string
	/**
	 * Region
	 */
	Region?: string
	/**
	 * Country
	 */
	Country?: number
	/**
	 * Email
	 */
	Email?: string
	/**
	 * Phone
	 */
	Phone?: string
	/**
	 * Comment
	 */
	Comment?: string
	/**
	 * Fax
	 */
	Fax?: string
	/**
	 * IdNo
	 */
	IdNo?: string
	/**
	 * VatIdNo
	 */
	VatIdNo?: string
	/**
	 * PickupPoint
	 */
	PickupPoint?: string
}

export type TSetSimpleDeliveryAddressCOMMANDParams = {
	/**
	 * Name
	 */
	Name?: string
	/**
	 * Street
	 */
	Street?: string
	/**
	 * Number
	 */
	Number?: string
	/**
	 * Town
	 */
	Town?: string
	/**
	 * PostCode
	 */
	PostCode?: string
	/**
	 * Region
	 */
	Region?: string
	/**
	 * Country
	 */
	Country?: number
	/**
	 * Email
	 */
	Email?: string
	/**
	 * Phone
	 */
	Phone?: string
	/**
	 * Comment
	 */
	Comment?: string
	/**
	 * Fax
	 */
	Fax?: string
	/**
	 * IdNo
	 */
	IdNo?: string
	/**
	 * VatIdNo
	 */
	VatIdNo?: string
	/**
	 * PickupPoint
	 */
	PickupPoint?: string
}

export type TAddItemsToSubDocWSCOMMANDParams = {
	/**
	 * Seznam RIDů položek oddělených středníkem
	 */
	ItemRIDs?: string
	/**
	 * Faktura vydaná
	 */
	InvoiceOut?: boolean
	/**
	 * Dodací list
	 */
	DeliveryNote?: boolean
	/**
	 * Výdejka
	 */
	ReleaseNote?: boolean
	/**
	 * Objednávka přijatá
	 */
	OrderConfirmation?: boolean
	/**
	 * Rezervační list
	 */
	ReservingCard?: boolean
	/**
	 * Faktura přijatá
	 */
	InvoiceIn?: boolean
	/**
	 * Potvrzení dodání
	 */
	DeliveryConfirmation?: boolean
	/**
	 * Příjemka
	 */
	ReceiptCard?: boolean
}

export type TRemoveItemsFromSubDocWSCOMMANDParams = {
	/**
	 * Seznam RIDů položek oddělených středníkem
	 */
	ItemRIDs?: string
	/**
	 * Faktura vydaná
	 */
	InvoiceOut?: boolean
	/**
	 * Dodací list
	 */
	DeliveryNote?: boolean
	/**
	 * Výdejka
	 */
	ReleaseNote?: boolean
	/**
	 * Objednávka přijatá
	 */
	OrderConfirmation?: boolean
	/**
	 * Rezervační list
	 */
	ReservingCard?: boolean
	/**
	 * Faktura přijatá
	 */
	InvoiceIn?: boolean
	/**
	 * Potvrzení dodání
	 */
	DeliveryConfirmation?: boolean
	/**
	 * Příjemka
	 */
	ReceiptCard?: boolean
}

export type TAddGoodsToContainerCOMMANDParams = {
	/**
	 * Přidat záznamy do kontejneru
	 */
	AddToExistingFilter?: boolean
	/**
	 * Vytvořit kontejner obsahující pouze tyto záznamy
	 */
	CreateNewFilter?: boolean
	/**
	 * Kontejner vytvořit podle vybraného dokladu
	 */
	OnlyActualDocument?: boolean
	/**
	 * Kontejner vytvořit podle označených položek vybraného dokladu
	 */
	OnlyActualDocumentSelectedItems?: boolean
	/**
	 * Kontejner vytvořit podle všech označených dokladů
	 */
	AllSelectedDocuments?: boolean
	/**
	 * Kontejner vytvořit podle všech dokladů v kontejneru/filtru
	 */
	AllDocumentsInFilter?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TInvoiceDateChangeCOMMANDParams = {
	/**
	 * VATDate
	 */
	VATDate?: Date
}

export type TAccountAssignmentPostingCOMMANDParams = {
	/**
	 * Zaúčtuje
	 */
	ACCPosting?: number
	/**
	 * Kniha
	 */
	ACCBook?: number
	/**
	 * Období
	 */
	ACCBussinessYear?: number
	/**
	 * Datum zaúčtování
	 */
	ACCPostingDate?: Date
	/**
	 * Datum od
	 */
	ACCDateFrom?: Date
	/**
	 * Datum do
	 */
	ACCDateTo?: Date
	/**
	 * Zachovat ručně změněné záznamy
	 */
	ACCHandChange?: boolean
	/**
	 * Likvidace bez částky
	 */
	ACCNulAmount?: boolean
	/**
	 * Povolit zaúčtovat doklady s jiným obdobím
	 */
	ACCOtherBusinessYear?: boolean
	/**
	 * Zaúčtovat nevyrovnané saldo
	 */
	ACCBalance?: boolean
}

export type TBulkAccountAssignmentPostingCOMMANDParams = {
	/**
	 * Zaúčtuje
	 */
	ACCPosting?: number
	/**
	 * Kniha
	 */
	ACCBook?: number
	/**
	 * Období
	 */
	ACCBussinessYear?: number
	/**
	 * Datum zaúčtování
	 */
	ACCPostingDate?: Date
	/**
	 * Datum od
	 */
	ACCDateFrom?: Date
	/**
	 * Datum do
	 */
	ACCDateTo?: Date
	/**
	 * Zachovat ručně změněné záznamy
	 */
	ACCHandChange?: boolean
	/**
	 * Likvidace bez částky
	 */
	ACCNulAmount?: boolean
	/**
	 * Povolit zaúčtovat doklady s jiným obdobím
	 */
	ACCOtherBusinessYear?: boolean
	/**
	 * Zaúčtovat nevyrovnané saldo
	 */
	ACCBalance?: boolean
}

export type TBulkChangePostingKeyCOMMANDParams = {
	/**
	 * Kontace
	 */
	Header_PostingKeyX?: boolean
	/**
	 * Header_PostingKey
	 */
	Header_PostingKey?: number
	/**
	 * Účet
	 */
	Header_AccountX?: boolean
	/**
	 * Header_Account
	 */
	Header_Account?: number
	/**
	 * Účet D
	 */
	Header_CreditAcX?: boolean
	/**
	 * Header_CreditAc
	 */
	Header_CreditAc?: number
	/**
	 * Účet MD
	 */
	Header_DebitAcX?: boolean
	/**
	 * Header_DebitAc
	 */
	Header_DebitAc?: number
	/**
	 * Parovací symbol
	 */
	Header_MatchSymbolX?: boolean
	/**
	 * Header_MatchSymbol
	 */
	Header_MatchSymbol?: number
	/**
	 * Středisko
	 */
	Header_CostCentreX?: boolean
	/**
	 * Header_CostCentre
	 */
	Header_CostCentre?: number
	/**
	 * Ze třediska
	 */
	Header_FromCostCentreX?: boolean
	/**
	 * Header_FromCostCentre
	 */
	Header_FromCostCentre?: number
	/**
	 * Na středisko
	 */
	Header_ToCostCentreX?: boolean
	/**
	 * Header_ToCostCentre
	 */
	Header_ToCostCentre?: number
	/**
	 * Kód zakázky
	 */
	Header_ContractCodeX?: boolean
	/**
	 * Header_ContractCode
	 */
	Header_ContractCode?: number
	/**
	 * Z kódu zakázky
	 */
	Header_FromContractCodeX?: boolean
	/**
	 * Header_FromContractCode
	 */
	Header_FromContractCode?: number
	/**
	 * Na kód zakázky
	 */
	Header_ToContractCodeX?: boolean
	/**
	 * Header_ToContractCode
	 */
	Header_ToContractCode?: number
	/**
	 * Prostředek
	 */
	Header_DeviceX?: boolean
	/**
	 * Header_Device
	 */
	Header_Device?: number
	/**
	 * Z prostředku
	 */
	Header_FromDeviceX?: boolean
	/**
	 * Header_FromDevice
	 */
	Header_FromDevice?: number
	/**
	 * Na prostředek
	 */
	Header_ToDeviceX?: boolean
	/**
	 * Header_ToDevice
	 */
	Header_ToDevice?: number
	/**
	 * Referent
	 */
	Header_OfficerX?: boolean
	/**
	 * Header_Officer
	 */
	Header_Officer?: number
	/**
	 * Z referenta
	 */
	Header_FromOfficerX?: boolean
	/**
	 * Header_FromOfficer
	 */
	Header_FromOfficer?: number
	/**
	 * Na referenta
	 */
	Header_ToOfficerX?: boolean
	/**
	 * Header_ToOfficer
	 */
	Header_ToOfficer?: number
	/**
	 * Kód zboží
	 */
	Header_ArticleCodeX?: boolean
	/**
	 * Header_ArticleCode
	 */
	Header_ArticleCode?: number
	/**
	 * Kód 1
	 */
	Header_Code1X?: boolean
	/**
	 * Header_Code1
	 */
	Header_Code1?: number
	/**
	 * Z kódu 1
	 */
	Header_FromCode1X?: boolean
	/**
	 * Header_FromCode1
	 */
	Header_FromCode1?: number
	/**
	 * Na kód 1
	 */
	Header_ToCode1X?: boolean
	/**
	 * Header_ToCode1
	 */
	Header_ToCode1?: number
	/**
	 * Kód 2
	 */
	Header_Code2X?: boolean
	/**
	 * Header_Code2
	 */
	Header_Code2?: number
	/**
	 * Z kódu 2
	 */
	Header_FromCode2X?: boolean
	/**
	 * Header_FromCode2
	 */
	Header_FromCode2?: number
	/**
	 * Na kód 2
	 */
	Header_ToCode2X?: boolean
	/**
	 * Header_ToCode2
	 */
	Header_ToCode2?: number
	/**
	 * Kód 3
	 */
	Header_Code3X?: boolean
	/**
	 * Header_Code3
	 */
	Header_Code3?: number
	/**
	 * Z kódu 3
	 */
	Header_FromCode3X?: boolean
	/**
	 * Header_FromCode3
	 */
	Header_FromCode3?: number
	/**
	 * Na kód 3
	 */
	Header_ToCode3X?: boolean
	/**
	 * Header_ToCode3
	 */
	Header_ToCode3?: number
	/**
	 * Kód 4
	 */
	Header_Code4X?: boolean
	/**
	 * Header_Code4
	 */
	Header_Code4?: number
	/**
	 * Z kódu 4
	 */
	Header_FromCode4X?: boolean
	/**
	 * Header_FromCode4
	 */
	Header_FromCode4?: number
	/**
	 * Na kód 4
	 */
	Header_ToCode4X?: boolean
	/**
	 * Header_ToCode4
	 */
	Header_ToCode4?: number
	/**
	 * Kód 5
	 */
	Header_Code5X?: boolean
	/**
	 * Header_Code5
	 */
	Header_Code5?: number
	/**
	 * Z kódu 5
	 */
	Header_FromCode5X?: boolean
	/**
	 * Header_FromCode5
	 */
	Header_FromCode5?: number
	/**
	 * Na kód 5
	 */
	Header_ToCode5X?: boolean
	/**
	 * Header_ToCode5
	 */
	Header_ToCode5?: number
	/**
	 * Kód 6
	 */
	Header_Code6X?: boolean
	/**
	 * Header_Code6
	 */
	Header_Code6?: number
	/**
	 * Z kódu 6
	 */
	Header_FromCode6X?: boolean
	/**
	 * Header_FromCode6
	 */
	Header_FromCode6?: number
	/**
	 * Na kód 6
	 */
	Header_ToCode6X?: boolean
	/**
	 * Header_ToCode6
	 */
	Header_ToCode6?: number
	/**
	 * Ignorovat cenu příjmu
	 */
	Header_IgnorePriceIncomX?: boolean
	/**
	 * Header_IgnorePriceIncom
	 */
	Header_IgnorePriceIncom?: number
	/**
	 * Header_ShowIgnorePriceIncom
	 */
	Header_ShowIgnorePriceIncom?: boolean
	/**
	 * Kalk.vz.plán.
	 */
	Header_PlannedPriceCMX?: boolean
	/**
	 * Header_PlannedPriceCM
	 */
	Header_PlannedPriceCM?: number
	/**
	 * Kalk.vz.sklad.
	 */
	Header_StockPriceCMX?: boolean
	/**
	 * Header_StockPriceCM
	 */
	Header_StockPriceCM?: number
	/**
	 * Kalk.vz.nákl.
	 */
	Header_CostPriceCMX?: boolean
	/**
	 * Header_CostPriceCM
	 */
	Header_CostPriceCM?: number
	/**
	 * Kontace faktury
	 */
	Item_PostingKeyIX?: boolean
	/**
	 * Item_PostingKeyI
	 */
	Item_PostingKeyI?: number
	/**
	 * Kontace skladového dokladu
	 */
	Item_PostingKeySX?: boolean
	/**
	 * Item_PostingKeyS
	 */
	Item_PostingKeyS?: number
	/**
	 * Kontace rezervačního listu
	 */
	Item_PostingKeyRX?: boolean
	/**
	 * Item_PostingKeyR
	 */
	Item_PostingKeyR?: number
	/**
	 * Kód zakázky
	 */
	Item_ContractCodeX?: boolean
	/**
	 * Item_ContractCode
	 */
	Item_ContractCode?: number
	/**
	 * Kód zakázky 2
	 */
	Item_ContractCode2X?: boolean
	/**
	 * Item_ContractCode2
	 */
	Item_ContractCode2?: number
	/**
	 * Středisko
	 */
	Item_CostCentreX?: boolean
	/**
	 * Item_CostCentre
	 */
	Item_CostCentre?: number
	/**
	 * Středisko 2
	 */
	Item_CostCentre2X?: boolean
	/**
	 * Item_CostCentre2
	 */
	Item_CostCentre2?: number
	/**
	 * Dosadit z karty zboží
	 */
	Item_CostCentreG?: boolean
	/**
	 * Kód zboží
	 */
	Item_ArticleCodeX?: boolean
	/**
	 * Item_ArticleCode
	 */
	Item_ArticleCode?: number
	/**
	 * Dosadit z karty zboží
	 */
	Item_ArticleCodeG?: boolean
	/**
	 * Kód 1
	 */
	Item_Code1X?: boolean
	/**
	 * Item_Code1
	 */
	Item_Code1?: number
	/**
	 * Kód 2
	 */
	Item_Code2X?: boolean
	/**
	 * Item_Code2
	 */
	Item_Code2?: number
	/**
	 * Kód 3
	 */
	Item_Code3X?: boolean
	/**
	 * Item_Code3
	 */
	Item_Code3?: number
	/**
	 * Kód 4
	 */
	Item_Code4X?: boolean
	/**
	 * Item_Code4
	 */
	Item_Code4?: number
	/**
	 * Kód 5
	 */
	Item_Code5X?: boolean
	/**
	 * Item_Code5
	 */
	Item_Code5?: number
	/**
	 * Kód 6
	 */
	Item_Code6X?: boolean
	/**
	 * Item_Code6
	 */
	Item_Code6?: number
	/**
	 * Referent
	 */
	Item_OfficerX?: boolean
	/**
	 * Item_Officer
	 */
	Item_Officer?: number
	/**
	 * Prostředek
	 */
	Item_DeviceX?: boolean
	/**
	 * Item_Device
	 */
	Item_Device?: number
	/**
	 * Parovací symbol
	 */
	Item_MatchSymbolX?: boolean
	/**
	 * Item_MatchSymbol
	 */
	Item_MatchSymbol?: number
	/**
	 * Účet
	 */
	Item_AccountX?: boolean
	/**
	 * Item_Account
	 */
	Item_Account?: number
	/**
	 * Kontace
	 */
	Item_PostingKeyX?: boolean
	/**
	 * Item_PostingKey
	 */
	Item_PostingKey?: number
	/**
	 * Účet D
	 */
	Item_CreditAcX?: boolean
	/**
	 * Item_CreditAc
	 */
	Item_CreditAc?: number
	/**
	 * Účet MD
	 */
	Item_DebitAcX?: boolean
	/**
	 * Item_DebitAc
	 */
	Item_DebitAc?: number
	/**
	 * Podle kontejneru zboží
	 */
	GoodsContainer?: boolean
	/**
	 * S odmazáním z kontejneru
	 */
	DeleteFromContainer?: boolean
	/**
	 * Pouze u prázdných
	 */
	OnlyEmpty?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TBulkChangeConfirmationDateCOMMANDParams = {
	/**
	 * Datum potvrzení
	 */
	Confirmation1Date?: Date
	/**
	 * Hodina potvrzení
	 */
	Confirmation1Time?: Date
	/**
	 * Datum 2. potvrzení
	 */
	Confirmation2Date?: Date
	/**
	 * Hodina 2. potvrzení
	 */
	Confirmation2Time?: Date
	/**
	 * Kontrolovat záporné stavy
	 */
	CheckNegativeState?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TChangeConfirmationDateCOMMANDParams = {
	/**
	 * Datum potvrzení
	 */
	Confirmation1Date?: Date
	/**
	 * Hodina potvrzení
	 */
	Confirmation1Time?: Date
	/**
	 * Datum 2. potvrzení
	 */
	Confirmation2Date?: Date
	/**
	 * Hodina 2. potvrzení
	 */
	Confirmation2Time?: Date
	/**
	 * Kontrolovat záporné stavy
	 */
	CheckNegativeState?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TChangeRecordStatusSWSCOMMANDParams = {
	/**
	 * StatusId
	 */
	StatusId?: number
}

export type TBulkChangeAttributesCOMMANDParams = {
	/**
	 * Smazat atributy
	 */
	DeleteAttribute?: boolean
	/**
	 * Smazat všechny volné atributy
	 */
	DeleteFreeAttribute?: boolean
	/**
	 * Nastavit hodnoty atributů
	 */
	SetValues?: boolean
	/**
	 * Přidat nepřipojené atributy
	 */
	AddFreeAttributes?: boolean
	/**
	 * FreeAttributeEnabled
	 */
	FreeAttributeEnabled?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TBulkActualizeAttributesCOMMANDParams = {
	/**
	 * Aktualizovat včetně samotné sady atributů
	 */
	ActualizeAttributeSet?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}