import Data from 'react-k2-api/Api/Data'
import { Type } from 'class-transformer'
import TTextDM from './TTextDM'
import TUserRoleDM from './TUserRoleDM'
import TK2UserLookupDM from './TK2UserLookupDM'

/**
 * FragmentAssign
 */
export default class TFragmentAssignDM extends Data {
	public static className = 'TFragmentAssignDM'
	public static systemFields = [
		'RecordID',
	]

	public static isHeader = false

	/**
	 * Mandant
	 */
	Mandant?: string

	/**
	 * Owner
	 */
	OwnerRID?: number

	/**
	 * FragmentId
	 */
	FragmentId?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * undefined
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Level
	 */
	@Type(() => TTextDM)
	Level?: TTextDM

	/**
	 * UserGroupRIDCalc
	 */
	@Type(() => TUserRoleDM)
	UserGroupRIDCalc?: TUserRoleDM

	/**
	 * K2UserIdCalc
	 */
	@Type(() => TK2UserLookupDM)
	K2UserIdCalc?: TK2UserLookupDM
}