import { Button, Modal } from "react-bootstrap"
import { useGlobalModalContext } from "./GlobalModal";

export type ConfirmationModalProps = {
    title: string
    confirmationButton?: string
    closeButton?: string
    handleClose?: () => void
    handleConfirmation?: () => void
}

export const ConfirmationModal = () => {
    const { hideModal, store } = useGlobalModalContext()
    const { title, confirmationButton, closeButton, handleClose, handleConfirmation } = store.modalProps as ConfirmationModalProps

    return (
        <Modal show={true}
            backdrop="static"
            keyboard={false}
            onHide={() => {
                handleClose && handleClose()
                hideModal()
            }}
        >
            <Modal.Header>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Footer>
                <Button variant="danger" className="me-auto" onClick={() => {
                    hideModal()
                    handleClose && handleClose()
                }}>
                    {closeButton || "Ne"}
                </Button>
                <Button variant="primary" onClick={() => {
                    hideModal()
                    handleConfirmation && handleConfirmation()
                }}>
                    {confirmationButton || "Ano"}
                </Button>
            </Modal.Footer>
        </Modal >
    )
}