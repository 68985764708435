import TSalesItemDM from 'react-k2-api/Data/TSalesItemDM'
import { Button } from 'react-bootstrap'
import { instanceToInstance } from 'class-transformer'
import { useGlobalModalContext, MODAL_TYPES } from "components/GlobalModal/GlobalModal"

export type HandleItemSave = (item: TSalesItemDM) => void
export type HandleItemDelete = (item: TSalesItemDM) => void

export type ItemProps = {
    item: TSalesItemDM
    handleDelete: HandleItemDelete
    handleSave: HandleItemSave
}

export const Item = ({ item, handleDelete, handleSave }: ItemProps) => {
    const { showModal } = useGlobalModalContext()
    const amount = item.MeasureUnitId?.Abbr?.Abbr === "sks" ? item.Quantity! * 100 : item.Quantity

    return (
        <tr>
            <td>
                #{item.ItemNumber} {item.ArticleId?.LanguageTextCalc}<br />
                {amount?.toString().replace(".", ",")}&nbsp;{item.MeasureUnitId?.Abbr?.Abbr?.replace("sks", "ks")}
            </td>
            <td>
                {item.PlannedTotalPriceNetCCalc?.toFixed(2).replace(".", ",")}&nbsp;{item.HeaderCurrencyCalc?.Abbr} bez DPH<br />
                {item.PlannedTotalPriceGrossCCalc?.toFixed(2).replace(".", ",")}&nbsp;{item.HeaderCurrencyCalc?.Abbr} s DPH
            </td>
            <td className="text-end">
                {item.ReleaseNoteStateIdCalc?.Id === 2 || item.DeliveryConfirmationStateIdCalc?.Id === 2 || item.InvoiceOutStateIdCalc?.Id === 2 || item.ReservingCardStateIdCalc?.Id === 2 || item.OrderConfirmationStateIdCalc?.Id === 2 ?
                    <i className="bi bi-lock-fill h1" /> :
                    <>
                        <Button variant="primary" onClick={() => showModal(MODAL_TYPES.NUMERIC_KEYBOARD_MODAL, {
                            handleClose: value => {
                                if (!value) return
                                const newItem = instanceToInstance(item)
                                newItem.Quantity = parseFloat(value?.replace(",", "."))
                                if (item.MeasureUnitId?.Abbr?.Abbr === "sks") {
                                    newItem.Quantity /= 100
                                }

                                handleSave(newItem)
                            },
                            defaultValue: amount?.toString().replace(".", ","),
                            inputGroupText: item.MeasureUnitId?.Abbr?.Abbr?.replace("sks", "ks")
                        })}>
                            <i className="bi bi-pencil" />
                        </Button>
                        <Button variant="danger ms-2" onClick={() => handleDelete(item)}>Smazat</Button>
                    </>}
            </td>
        </tr>
    )
}