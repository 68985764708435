import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TPartnerDM from './TPartnerDM'
import TAdressKeyDM from './TAdressKeyDM'

/**
 * Položky adresních klíčů
 */
export default class TAdressKeyItemDM extends Data {
	public static className = 'TAdressKeyItemDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = false

	/**
	 * Pořadí
	 */
	SequenceOrder?: number

	/**
	 * Hodnotový DM
	 */
	MainDataModuleNumber?: number

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Hodnota
	 */
	@Type(() => TPartnerDM)
	MainValue?: TPartnerDM

	/**
	 * Klíč
	 */
	@Type(() => TAdressKeyDM)
	SortingKeyRID?: TAdressKeyDM
}