import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TTextDM from './TTextDM'
import TWarehouseDM from './TWarehouseDM'
import TBatchDM from './TBatchDM'
import TLocationArticleDM from './TLocationArticleDM'
import TContractCodeDocumentDM from './TContractCodeDocumentDM'
import THandlingUnitArticleDM from './THandlingUnitArticleDM'

/**
 * Sériová čísla
 */
export default class TSerialNumberItemDM extends Data {
	public static className = 'TSerialNumberItemDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = true

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * Č. zboží
	 */
	ArticleId?: number

	/**
	 * Stav
	 */
	StatusId?: number

	/**
	 * Nadř. sér. číslo
	 */
	SuperiorSerialNumber?: string

	/**
	 * Výrobní číslo
	 */
	ProductionNumber?: string

	/**
	 * Sériové číslo
	 */
	SerialNumber?: string

	/**
	 * Číslo p.
	 */
	ItemRID?: number

	/**
	 * Typ d.
	 */
	DocumentTypeId?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * undefined
	 */
	InsertCount?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * ArticleIdCalc
	 */
	ArticleIdCalc?: number

	/**
	 * UserWarehouseIdCalc
	 */
	UserWarehouseIdCalc?: number

	/**
	 * S
	 */
	@Type(() => TTextDM)
	StatusIdCalc?: TTextDM

	/**
	 * WarehouseIdCalc
	 */
	@Type(() => TWarehouseDM)
	WarehouseIdCalc?: TWarehouseDM

	/**
	 * BatchIdCalc
	 */
	@Type(() => TBatchDM)
	BatchIdCalc?: TBatchDM

	/**
	 * LocationIdCalc
	 */
	@Type(() => TLocationArticleDM)
	LocationIdCalc?: TLocationArticleDM

	/**
	 * ContractCodeRIDCalc
	 */
	@Type(() => TContractCodeDocumentDM)
	ContractCodeRIDCalc?: TContractCodeDocumentDM

	/**
	 * HandlingUnitRIDCalc
	 */
	@Type(() => THandlingUnitArticleDM)
	HandlingUnitRIDCalc?: THandlingUnitArticleDM

	/**
	 * Změna skupiny práv
	 */
	public ChangeGroupRightCOMMAND() {
		return this.executeCommand('ChangeGroupRightCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public BulkChangeGroupRightCOMMAND() {
		return this.executeCommand('BulkChangeGroupRightCOMMAND')
	}

	/**
	 * Aktualizace počítaných polí
	 */
	public BulkUpdateCalcFieldsCOMMAND() {
		return this.executeCommand('BulkUpdateCalcFieldsCOMMAND')
	}

	/**
	 * Kontrola legislativy
	 */
	public SwitchOffLegislationValidationCOMMAND() {
		return this.executeCommand('SwitchOffLegislationValidationCOMMAND')
	}
}