import Data from 'react-k2-api/Api/Data'
import { Type } from 'class-transformer'
import TWMSEquipmentDM from './TWMSEquipmentDM'
import TTextDM from './TTextDM'
import AlternativeUnit from './AlternativeUnit'
import TArticleDM from './TArticleDM'

/**
 * Alternativní jednotky
 */
export default class TMeasureUnitDM extends Data {
	public static className = 'TMeasureUnitDM'
	public static systemFields = [
	]

	public static isHeader = true

	/**
	 * undefined
	 */
	IsBlockedRecord?: string

	/**
	 * Plocha
	 */
	Area?: number

	/**
	 * Hmotn. netto
	 */
	WeightNet?: number

	/**
	 * Hmotn. brutto
	 */
	WeightGross?: number

	/**
	 * Rozměr C
	 */
	SizeC?: number

	/**
	 * Rozměr B
	 */
	SizeB?: number

	/**
	 * Rozměr A
	 */
	SizeA?: number

	/**
	 * Objem
	 */
	Volume?: number

	/**
	 * Poměr
	 */
	Ratio?: number

	/**
	 * Č.
	 */
	RecordNumber?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * P
	 */
	BasicOrBlockedImgCalc?: string

	/**
	 * Hmotn. netto
	 */
	ComputedWeightNetCalc?: number

	/**
	 * Hmotn. brutto
	 */
	ComputedWeightGrossCalc?: number

	/**
	 * undefined
	 */
	BlockedCalc?: number

	/**
	 * Implicitní pro průvodky
	 */
	DefaultForJobCardCalc?: number

	/**
	 * Implicitní pro nákup
	 */
	DefaultForPurchaseCalc?: number

	/**
	 * Implicitní pro prodej
	 */
	DefaultForSaleCalc?: number

	/**
	 * Implicitní pro převodky
	 */
	DefaultForTransferNoteCalc?: number

	/**
	 * Celočíselná hodnota
	 */
	IntegerUnitCalc?: number

	/**
	 * Implicitní pro e-shop
	 */
	DefaultForEshopCalc?: number

	/**
	 * Zobrazovat na e-shopu
	 */
	ShowOnEshopBitCalc?: number

	/**
	 * undefined
	 */
	StocktakingsDefaultBitCalc?: number

	/**
	 * Č. jednotky
	 */
	MeasureUnitIdCalc?: number

	/**
	 * Implicitní pro TP
	 */
	DefaultForRoutingVariantBitCalc?: number

	/**
	 * Manip. technika
	 */
	@Type(() => TWMSEquipmentDM)
	EquipmentId?: TWMSEquipmentDM

	/**
	 * Jedn. plochy
	 */
	@Type(() => TTextDM)
	AreaUnitId?: TTextDM

	/**
	 * Jedn. rozměru
	 */
	@Type(() => TTextDM)
	SizeMeasureUnit?: TTextDM

	/**
	 * Jedn. objemu
	 */
	@Type(() => TTextDM)
	CapacityMeasureUnitId?: TTextDM

	/**
	 * Jednotka
	 */
	@Type(() => AlternativeUnit)
	Abbr?: AlternativeUnit

	/**
	 * Č. zboží
	 */
	@Type(() => TArticleDM)
	ArticleId?: TArticleDM

	/**
	 * Změna skupiny práv
	 */
	public ChangeGroupRightCOMMAND() {
		return this.executeCommand('ChangeGroupRightCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public BulkChangeGroupRightCOMMAND() {
		return this.executeCommand('BulkChangeGroupRightCOMMAND')
	}

	/**
	 * Aktualizace počítaných polí
	 */
	public BulkUpdateCalcFieldsCOMMAND() {
		return this.executeCommand('BulkUpdateCalcFieldsCOMMAND')
	}

	/**
	 * Kontrola legislativy
	 */
	public SwitchOffLegislationValidationCOMMAND() {
		return this.executeCommand('SwitchOffLegislationValidationCOMMAND')
	}
}