import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TTextDM from './TTextDM'

/**
 * Jazykové texty
 */
export default class TLanguageTextTranslationDM extends Data {
	public static className = 'TLanguageTextTranslationDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = false

	/**
	 * Časové razítko
	 */
	TimeStamp?: string

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * Text
	 */
	Text?: string

	/**
	 * C_Pole
	 */
	FieldNumber?: number

	/**
	 * C_Dok
	 */
	DocumentNumber?: number

	/**
	 * Kniha
	 */
	BookId?: string

	/**
	 * Typ_Dok
	 */
	DocumentTypeId?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Jazyk
	 */
	@Type(() => TTextDM)
	LanguageId?: TTextDM
}