import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TExternalBatchDM from './TExternalBatchDM'
import TScheduledServiceOperationDM from './TScheduledServiceOperationDM'
import TServicedDeviceDM from './TServicedDeviceDM'
import TCode6DocumentDM from './TCode6DocumentDM'
import TCode5DocumentDM from './TCode5DocumentDM'
import TCode4DocumentDM from './TCode4DocumentDM'
import TCode3DocumentDM from './TCode3DocumentDM'
import Priority from './Priority'
import TCodeTypeDM from './TCodeTypeDM'
import TMatchingSymbolDM from './TMatchingSymbolDM'
import TPromotionDM from './TPromotionDM'
import TArticleCodeDocumentDM from './TArticleCodeDocumentDM'
import TCostCentreCollectionDocumentDM from './TCostCentreCollectionDocumentDM'
import TDeviceDocumentDM from './TDeviceDocumentDM'
import THandlingUnitArticleDM from './THandlingUnitArticleDM'
import TBookDM from './TBookDM'
import TTradingPartnerDM from './TTradingPartnerDM'
import TReleaseNoteDM from './TReleaseNoteDM'
import TDeliveryNoteDM from './TDeliveryNoteDM'
import TReservingCardDM from './TReservingCardDM'
import TOrderConfirmationDM from './TOrderConfirmationDM'
import TInvoiceOutDM from './TInvoiceOutDM'
import TSalesOrderDM from './TSalesOrderDM'
import TTaxTypeDM from './TTaxTypeDM'
import TContractCodeDocumentDM from './TContractCodeDocumentDM'
import TServiceNoteDM from './TServiceNoteDM'
import TNomenclatureDM from './TNomenclatureDM'
import TAccountingChartDM from './TAccountingChartDM'
import TRoutingVariantDM from './TRoutingVariantDM'
import PlanType from './PlanType'
import TCountryDM from './TCountryDM'
import TWarehouseDM from './TWarehouseDM'
import TCode2DocumentDM from './TCode2DocumentDM'
import TCode1DocumentDM from './TCode1DocumentDM'
import TOfficerDocumentDM from './TOfficerDocumentDM'
import TRequestedBatchParameterHeaderDM from './TRequestedBatchParameterHeaderDM'
import TLocationArticleDM from './TLocationArticleDM'
import TPriceGroupDM from './TPriceGroupDM'
import TVATRateDM from './TVATRateDM'
import TMeasureUnitDM from './TMeasureUnitDM'
import TBatchDM from './TBatchDM'
import GoodsName from './GoodsName'
import TArticleDM from './TArticleDM'
import TCurrencyDM from './TCurrencyDM'
import TTextDM from './TTextDM'
import TParameterSetDM from './TParameterSetDM'
import JCDObchod from './JCDObchod'
import JCDP20_a1 from './JCDP20_a1'
import SpecialMove from './SpecialMove'
import TransportKind from './TransportKind'
import TJobCardDM from './TJobCardDM'
import TIntrastatTariffDutyDM from './TIntrastatTariffDutyDM'
import TPriceListDM from './TPriceListDM'
import TSupplierPriceDM from './TSupplierPriceDM'
import TLoyaltyProgramDM from './TLoyaltyProgramDM'
import TK2UserLookupDM from './TK2UserLookupDM'
import TPeriodDM from './TPeriodDM'
import TProductionSchedulingVariantDM from './TProductionSchedulingVariantDM'
import TItemNoteDM from './TItemNoteDM'
import TSerialNumberItemDM from './TSerialNumberItemDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TJobCardSubordinateDocumentDM from './TJobCardSubordinateDocumentDM'
import TCustomTSalesItemBookDMNOFGrid_Konkurencni_ceny from './TCustomTSalesItemBookDMNOFGrid_Konkurencni_ceny'
import TCustomTSalesItemBookDMNOFGrid_Vybrani_dod from './TCustomTSalesItemBookDMNOFGrid_Vybrani_dod'
import TCustomTSalesItemBookDMNOFGrid_Pohyby from './TCustomTSalesItemBookDMNOFGrid_Pohyby'

/**
 * Položky prodeje
 */
export default class TSalesItemAnalyticalDimensionDM extends Data {
	public static className = 'TSalesItemAnalyticalDimensionDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = true

	/**
	 * Právo na záznam
	 */
	MainDocumentTradingPartnerId_RightGroupId?: number

	/**
	 * Firma
	 */
	MainDocumentBookId_CompanyId?: number

	/**
	 * Skup. právo
	 */
	MainDocumentBookId_RightGroupId?: number

	/**
	 * Právo na záznam
	 */
	ReservingCardRID_SalesOrderRID_RightGroupId?: number

	/**
	 * Zakázka
	 */
	ReservingCardRID_SalesOrderRID?: number

	/**
	 * Právo na záznam
	 */
	ReservingCardRID_RightGroupId?: number

	/**
	 * Právo na záznam
	 */
	OrderConfirmationRID_SalesOrderRID_RightGroupId?: number

	/**
	 * Zakázka
	 */
	OrderConfirmationRID_SalesOrderRID?: number

	/**
	 * Právo na záznam
	 */
	OrderConfirmationRID_RightGroupId?: number

	/**
	 * Právo na záznam
	 */
	DeliveryNoteRID_SalesOrderRID_RightGroupId?: number

	/**
	 * Zakázka
	 */
	DeliveryNoteRID_SalesOrderRID?: number

	/**
	 * Právo na záznam
	 */
	DeliveryNoteRID_RightGroupid?: number

	/**
	 * Právo na záznam
	 */
	ReleaseNoteRID_SalesOrderRID_RightGroupId?: number

	/**
	 * Zakázka
	 */
	ReleaseNoteRID_SalesOrderRID?: number

	/**
	 * Právo na záznam
	 */
	ReleaseNoteRID_RightGroupId?: number

	/**
	 * Právo na záznam
	 */
	InvoiceOutRID_SalesOrderRID_RightGroupId?: number

	/**
	 * Zakázka
	 */
	InvoiceOutRID_SalesOrderRID?: number

	/**
	 * Právo na záznam
	 */
	InvoiceOutRID_RightGroupId?: number

	/**
	 * Právo na záznam
	 */
	SalesOrderRID_RightGroupId?: number

	/**
	 * Původní cena fakturovaná
	 */
	InvoiceOriginalUnitPrice?: number

	/**
	 * Původní cena plánovaná
	 */
	PlannedOriginalUnitPrice?: number

	/**
	 * Kalkulace
	 */
	CostingBind?: number

	/**
	 * První potvrzeno na
	 */
	FirstDeliveryConfirmedForDate?: Date

	/**
	 * Potvrzeno na
	 */
	DeliveryConfirmedForDate?: Date

	/**
	 * Expedice
	 */
	DispatchDate?: Date

	/**
	 * Interní zakázka
	 */
	IsInternalContract?: string

	/**
	 * Dárek
	 */
	IsGift?: string

	/**
	 * Seskupení
	 */
	PromotionGroupRID?: number

	/**
	 * Hlavní položka
	 */
	MainItemRID?: number

	/**
	 * Časové razítko
	 */
	TimeStamp?: string

	/**
	 * Slevový kupón
	 */
	CouponRID?: number

	/**
	 * Sleva F
	 */
	InvoiceDiscount?: number

	/**
	 * Sleva (&g)
	 */
	PlannedDiscount?: number

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * XmlData
	 */
	XmlData?: string

	/**
	 * Záruka
	 */
	Warranty?: number

	/**
	 * Datum přechodu hranice
	 */
	BorderCrossingDate?: Date

	/**
	 * Spotřební daň
	 */
	ExciseTaxId?: number

	/**
	 * Pož. datum
	 */
	DesiredDeliveryDate?: Date

	/**
	 * Kat. cena
	 */
	CatalogPrice?: number

	/**
	 * Datum rezervace
	 */
	ReservationDate?: Date

	/**
	 * JCBind
	 */
	JobCardBindId?: number

	/**
	 * UnitCost
	 */
	UnitCostPrice?: number

	/**
	 * # položky
	 */
	OriginalNoSalesOrder?: number

	/**
	 * # položky
	 */
	OriginalNoReservingCard?: number

	/**
	 * OrigItemV
	 */
	OriginalNoReleaseNote?: number

	/**
	 * # položky
	 */
	OriginalNoOrderConfirmation?: number

	/**
	 * OrigItemD
	 */
	OriginalNoDeliveryNote?: number

	/**
	 * # položky
	 */
	OriginalNoInvoiceOut?: number

	/**
	 * PolP
	 */
	NoteId?: number

	/**
	 * Kontace R
	 */
	ReservingCardPostingKeyId?: number

	/**
	 * Pl. skl. cena
	 */
	PlannedStockPrice?: number

	/**
	 * Ce_JePl
	 */
	PlannedUnitPrice?: number

	/**
	 * Kontace F
	 */
	InvoicePostingKeyId?: number

	/**
	 * Kontace V
	 */
	ReleaseNotePostingKeyId?: number

	/**
	 * Skl. cena
	 */
	StockPrice?: number

	/**
	 * Ce_Jedn
	 */
	InvoiceUnitPrice?: number

	/**
	 * Množství
	 */
	Quantity?: number

	/**
	 * Prijem
	 */
	Tag?: number

	/**
	 * DatP
	 */
	ConfirmedOn?: Date

	/**
	 * C_Pol
	 */
	ItemNumber?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * Zákaznická reference 7
	 */
	Ex_CustomerRef7?: string

	/**
	 * Zákaznická reference 6
	 */
	Ex_CustomerRef6?: string

	/**
	 * EX_copy_RID
	 */
	EX_copy_RID?: number

	/**
	 * Zákaznická reference 5
	 */
	Ex_CustomerRef5?: string

	/**
	 * Zákaznická reference 4
	 */
	Ex_CustomerRef4?: string

	/**
	 * Zákaznická reference 3
	 */
	Ex_CustomerRef3?: string

	/**
	 * EX_konv_Mnoz
	 */
	EX_konv_Mnoz?: number

	/**
	 * Zákaznická reference 2
	 */
	Ex_CustomerRef2?: string

	/**
	 * Zákaznická reference
	 */
	Ex_CustomerRef?: string

	/**
	 * Požadované balení
	 */
	Ex_RequiredPackage?: number

	/**
	 * Nekontrolovat minimální marži
	 */
	EX_nekontrol_marzi?: string

	/**
	 * EX_Fpomoc_NZ
	 */
	EX_Fpomoc_NZ?: number

	/**
	 * Ex_OriginItemDebt
	 */
	Ex_OriginItemDebt?: number

	/**
	 * Ex_CpolZakObj
	 */
	Ex_CpolZakObj?: number

	/**
	 * EX_Nosic
	 */
	EX_Nosic?: string

	/**
	 * Pomocné pole pro pře
	 */
	EX_L_exclude?: number

	/**
	 * EX_Stav
	 */
	EX_Stav?: number

	/**
	 * EX_rl_skryj
	 */
	EX_rl_skryj?: string

	/**
	 * EX_nReal
	 */
	EX_nReal?: string

	/**
	 * EX_nNabidka_odes_dat
	 */
	EX_nNabidka_odes_dat?: Date

	/**
	 * EX_nTweeks
	 */
	EX_nTweeks?: number

	/**
	 * EX_nTisk_C
	 */
	EX_nTisk_C?: string

	/**
	 * EX_nDalsiPol
	 */
	EX_nDalsiPol?: number

	/**
	 * EX_nNabidka_odes
	 */
	EX_nNabidka_odes?: number

	/**
	 * EX_nMnoz
	 */
	EX_nMnoz?: number

	/**
	 * EX_nCDo
	 */
	EX_nCDo?: number

	/**
	 * EX_nC_Zbo
	 */
	EX_nC_Zbo?: number

	/**
	 * EX_Pom
	 */
	EX_Pom?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Kurz hl.
	 */
	ExchangeRateCalc?: number

	/**
	 * Kurz fa.
	 */
	InvoiceExchangeRateCalc?: number

	/**
	 * &Množství
	 */
	QuantityUMCalc?: number

	/**
	 * Koef.dph
	 */
	VATCoefficientCalc?: number

	/**
	 * DPH
	 */
	InvoicePriceVATCalc?: number

	/**
	 * Netto pl./MJ
	 */
	PlannedUnitPriceNetCalc?: number

	/**
	 * Netto fa./MJ
	 */
	InvoiceUnitPriceNetCalc?: number

	/**
	 * Skl.cena/MJ
	 */
	StockUnitPriceCalc?: number

	/**
	 * Pl.sc./MJ
	 */
	PlannedUnitStockPriceCalc?: number

	/**
	 * Netto pl.
	 */
	PlannedTotalPriceNetCalc?: number

	/**
	 * Netto fa.
	 */
	InvoiceTotalPriceNetCalc?: number

	/**
	 * Skl.cena
	 */
	StockTotalPriceCalc?: number

	/**
	 * Pl.s.cena
	 */
	PlannedTotalStockPriceCalc?: number

	/**
	 * Plán. &cena netto / MJ
	 */
	PlannedUnitPriceNetCCalc?: number

	/**
	 * Faktur. &cena netto / MJ
	 */
	InvoiceUnitPriceNetCCalc?: number

	/**
	 * Skladová &cena / MJ
	 */
	StockUnitPriceCCalc?: number

	/**
	 * Plán. skladová &cena / MJ
	 */
	PlannedUnitStockPriceCCalc?: number

	/**
	 * Plán. cena netto celkem
	 */
	PlannedTotalPriceNetCCalc?: number

	/**
	 * Faktur. cena netto celkem
	 */
	InvoiceTotalPriceNetCCalc?: number

	/**
	 * Skladová cena celkem
	 */
	StockTotalPriceCCalc?: number

	/**
	 * Plán. skladová cena celkem
	 */
	PlannedTotalStockPriceCCalc?: number

	/**
	 * Brutto pl./MJ
	 */
	PlannedUnitPriceGrossCalc?: number

	/**
	 * Brutto fa./MJ
	 */
	InvoiceUnitPriceGrossCalc?: number

	/**
	 * Brutto pl.
	 */
	PlannedTotalPriceGrossCalc?: number

	/**
	 * Brutto fa.
	 */
	InvoiceTotalPriceGrossCalc?: number

	/**
	 * Plán. &cena brutto / MJ
	 */
	PlannedUnitPriceGrossCCalc?: number

	/**
	 * Faktur. &cena brutto / MJ
	 */
	InvoiceUnitPriceGrossCCalc?: number

	/**
	 * Plán. cena brutto celkem
	 */
	PlannedTotalPriceGrossCCalc?: number

	/**
	 * Brutto fa.
	 */
	InvoiceTotalPriceGrossCCalc?: number

	/**
	 * Název
	 */
	NameCalc?: string

	/**
	 * Sk.p.-sk./MJ
	 */
	StockPriceDifferenceCalc?: number

	/**
	 * Marže pl./MJ
	 */
	PlannedMarginCalc?: number

	/**
	 * Marže fa./MJ
	 */
	InvoiceMarginCalc?: number

	/**
	 * Marže pl./MJ
	 */
	PlannedMarginCCalc?: number

	/**
	 * Marže fa./MJ
	 */
	InvoiceMarginCCalc?: number

	/**
	 * Marže pl. %
	 */
	PlannedMarginPctCalc?: number

	/**
	 * Marže fa. %
	 */
	InvoiceMarginPctCalc?: number

	/**
	 * Množství
	 */
	PositiveQuantityCalc?: number

	/**
	 * Název celý
	 */
	FullNameCalc?: string

	/**
	 * Množství
	 */
	ActualQuantityRNCalc?: number

	/**
	 * Částka SD
	 */
	ExciseTaxAmountCalc?: number

	/**
	 * Faktur. cena netto / set
	 */
	SetInvPriceCalc?: number

	/**
	 * Faktur. cena netto / set / MJ
	 */
	SetInvUnitPriceCalc?: number

	/**
	 * Faktur. cena netto / set / MJ
	 */
	SetInvUnitPriceCCalc?: number

	/**
	 * Faktur. cena netto
	 */
	SetInvTotalPriceCalc?: number

	/**
	 * Faktur. cena netto
	 */
	SetInvTotalPriceCCalc?: number

	/**
	 * Faktur. cena brutto / set
	 */
	SetInvPriceGrossCalc?: number

	/**
	 * Faktur. cena brutto / set / MJ
	 */
	SetInvUnitPriceGrossCalc?: number

	/**
	 * Faktur. cena brutto / set / MJ
	 */
	SetInvUnitPriceGrossCCalc?: number

	/**
	 * Faktur. cena brutto
	 */
	SetInvTotalPriceGrossCalc?: number

	/**
	 * Faktur. cena brutto
	 */
	SetInvTotalPriceGrossCCalc?: number

	/**
	 * Plán. cena netto / set
	 */
	SetPlPriceCalc?: number

	/**
	 * Plán. cena netto / set / MJ
	 */
	SetPlUnitPriceCalc?: number

	/**
	 * Plán. cena netto / set / MJ
	 */
	SetPlUnitPriceCCalc?: number

	/**
	 * Plán. cena netto
	 */
	SetPlTotalPriceCalc?: number

	/**
	 * Plán. cena netto
	 */
	SetPlTotalPriceCCalc?: number

	/**
	 * Plán. cena brutto / set
	 */
	SetPlPriceGrossCalc?: number

	/**
	 * Plán. cena brutto / set / MJ
	 */
	SetPlUnitPriceGrossCalc?: number

	/**
	 * Plán. cena brutto / set / MJ
	 */
	SetPlUnitPriceGrossCCalc?: number

	/**
	 * Plán. cena brutto
	 */
	SetPlTotalPriceGrossCalc?: number

	/**
	 * Plán. cena brutto
	 */
	SetPlTotalPriceGrossCCalc?: number

	/**
	 * Položka setu zboží
	 */
	IsFromSetCalc?: number

	/**
	 * Čárový kód
	 */
	BarcodeCalc?: string

	/**
	 * Netto pl./ZMJ
	 */
	PlannedBaseUnitPriceCalc?: number

	/**
	 * Brutto pl./ZMJ
	 */
	PlannedBaseUnitPriceGrossCalc?: number

	/**
	 * Brutto pl./ZMJ
	 */
	PlannedBaseUnitPriceCCalc?: number

	/**
	 * Brutto pl./ZMJ
	 */
	PlannedBaseUnitPriceGrossCCalc?: number

	/**
	 * H
	 */
	MainItemCalc?: number

	/**
	 * Kurz sk.
	 */
	StockDocumentExchangeRateCalc?: number

	/**
	 * Netto pl./MJ bez slevy
	 */
	PlannedOriginalUnitPriceNetCalc?: number

	/**
	 * Netto fa./MJ bez slevy
	 */
	InvoiceOriginalUnitPriceNetCalc?: number

	/**
	 * Netto pl. bez slevy
	 */
	PlannedOriginalTotalPriceNetCalc?: number

	/**
	 * Netto fa. bez slevy
	 */
	InvoiceOriginalTotalPriceNetCalc?: number

	/**
	 * Plánovaná M / MJ
	 */
	PlannedOriginalUnitPriceNetCCalc?: number

	/**
	 * Fakturovaná M / MJ
	 */
	InvoiceOriginalUnitPriceNetCCalc?: number

	/**
	 * Plánovaná M
	 */
	PlannedOriginalTotalPriceNetCCalc?: number

	/**
	 * Fakturovaná M
	 */
	InvoiceOriginalTotalPriceNetCCalc?: number

	/**
	 * Brutto pl./MJ bez slevy
	 */
	PlannedOriginalUnitPriceGrossCalc?: number

	/**
	 * Brutto fa./MJ bez slevy
	 */
	InvoiceOriginalUnitPriceGrossCalc?: number

	/**
	 * Brutto pl. bez slevy
	 */
	PlannedOriginalTotalPriceGrossCalc?: number

	/**
	 * Brutto fa. bez slevy
	 */
	InvoiceOriginalTotalPriceGrossCalc?: number

	/**
	 * Plánovaná M / MJ
	 */
	PlannedOriginalUnitPriceGrossCCalc?: number

	/**
	 * Fakturovaná M / MJ
	 */
	InvoiceOriginalUnitPriceGrossCCalc?: number

	/**
	 * Plánovaná M
	 */
	PlannedOriginalTotalPriceGrossCCalc?: number

	/**
	 * Fakturovaná M
	 */
	InvoiceOriginalTotalPriceGrossCCalc?: number

	/**
	 * Rezerv. datum
	 */
	ReservationOnlyDateCalc?: Date

	/**
	 * Čas
	 */
	ReservationOnlyTimeCalc?: Date

	/**
	 * Sleva v %
	 */
	DiscountInPercentageBitCalc?: number

	/**
	 * BatchParameterRatioCalc
	 */
	BatchParameterRatioCalc?: number

	/**
	 * Kat. cena/MJ
	 */
	CatalogUnitPriceCalc?: number

	/**
	 * Kat. cena
	 */
	CatalogTotalPriceCalc?: number

	/**
	 * Katalogová / MJ
	 */
	CatalogUnitPriceCCalc?: number

	/**
	 * Katalogová
	 */
	CatalogTotalPriceCCalc?: number

	/**
	 * Kat. cena brutto/MJ
	 */
	CatalogUnitPriceGrossCalc?: number

	/**
	 * Kat. cena brutto
	 */
	CatalogTotalPriceGrossCalc?: number

	/**
	 * Katalogová / MJ
	 */
	CatalogUnitPriceGrossCCalc?: number

	/**
	 * Katalogová
	 */
	CatalogTotalPriceGrossCCalc?: number

	/**
	 * K. sleva
	 */
	CatalogPriceDiscountCalc?: number

	/**
	 * K. sleva celk.
	 */
	CatalogPriceTotalDiscountCalc?: number

	/**
	 * K. sleva [%]
	 */
	CatalogPriceDiscountPctCalc?: number

	/**
	 * Požad. - datum
	 */
	DesiredDeliveryDateCalc?: Date

	/**
	 * Čas
	 */
	DesiredDeliveryTimeCalc?: Date

	/**
	 * BatchTypeIdCalc
	 */
	BatchTypeIdCalc?: number

	/**
	 * Total weight
	 */
	TotalWeightCalc?: number

	/**
	 * D
	 */
	PriceGroup1ImgCalc?: number

	/**
	 * O
	 */
	PriceGroup2ImgCalc?: number

	/**
	 * Dodavatelský kód
	 */
	SupplierCodeCalc?: string

	/**
	 * PlannedVATCoefficientCalc
	 */
	PlannedVATCoefficientCalc?: number

	/**
	 * InvoiceVATCoefficientCalc
	 */
	InvoiceVATCoefficientCalc?: number

	/**
	 * Konec záruky
	 */
	WarrantyEndDateCalc?: Date

	/**
	 * Nákl. celk.
	 */
	TotalCostPriceCalc?: number

	/**
	 * DateForVersionCalc
	 */
	DateForVersionCalc?: Date

	/**
	 * DateFromInvoiceCalc
	 */
	DateFromInvoiceCalc?: Date

	/**
	 * DateFromDeliveryNoteCalc
	 */
	DateFromDeliveryNoteCalc?: Date

	/**
	 * Sleva (&g)
	 */
	PlannedDiscountCalc?: number

	/**
	 * Sleva F
	 */
	InvoiceDiscountCalc?: number

	/**
	 * Total weight netto
	 */
	TotalWeightNetCalc?: number

	/**
	 * WarehouseForBatchIdCalc
	 */
	WarehouseForBatchIdCalc?: number

	/**
	 * WarehouseForContractCodeIdCalc
	 */
	WarehouseForContractCodeIdCalc?: number

	/**
	 * Sleva v %
	 */
	PlDiscountPercentageBitCalc?: number

	/**
	 * Kniha prův.
	 */
	JobCardBookCalc?: string

	/**
	 * Plán výroby
	 */
	JobCardPlanEndDateCalc?: Date

	/**
	 * Prodlení - pož.
	 */
	JobCardDelayRequiredCalc?: number

	/**
	 * Prodlení - rez.
	 */
	JobCardDelayReservedCalc?: number

	/**
	 * Termín vykrytí
	 */
	CoverageDateCalc?: Date

	/**
	 * Šarže
	 */
	BatchCalc?: number

	/**
	 * PDP
	 */
	RCSModeBitCalc?: number

	/**
	 * Netto pro DPH shora
	 */
	AccountingTotalPriceNetCalc?: number

	/**
	 * DPH pro výpočet shora
	 */
	AccountingTotalPriceVATCalc?: number

	/**
	 * Netto pro DPH shora / jedn. M
	 */
	AccountingPriceNetCCalc?: number

	/**
	 * Netto pro DPH shora M
	 */
	AccountingTotalPriceNetCCalc?: number

	/**
	 * DPH pro výpočet shora M
	 */
	AccountingTotalPriceVATCCalc?: number

	/**
	 * 1. Produktová skupina
	 */
	PriceGroup1BitCalc?: number

	/**
	 * 2. Produktová skupina
	 */
	PriceGroup2BitCalc?: number

	/**
	 * Ručně změněná cena
	 */
	ManuallyChangedPriceBitCalc?: number

	/**
	 * Sleva se vztahuje jen na fakturovanou cenu
	 */
	DiscountOnlyInvoicePriceBitCalc?: number

	/**
	 * Položka rozpadlá při DoplnSarzeVydeju
	 */
	DuplicateItemBitCalc?: number

	/**
	 * Použit vzorec pro opačnou transakci (výdej/příjem)
	 */
	InverseTransformFormulaBitCalc?: number

	/**
	 * Vedlejší náklad
	 */
	IncidentalCostBitCalc?: number

	/**
	 * Nulové skutečné vedlejší náklady
	 */
	ZeroActualIncidentalCostBitCalc?: number

	/**
	 * Nezahrnovat do Intrastatu
	 */
	NotIncludeToIntrastatBitCalc?: number

	/**
	 * Položka z faktury má dobropis
	 */
	InvoiceItemHasCreditNoteBitCalc?: number

	/**
	 * Ignorovat cenu příjmu
	 */
	IgnoreReceiptPriceCalc?: number

	/**
	 * Hmotnost netto (kg)
	 */
	WeightNetCalc?: number

	/**
	 * Fakturovaná hodnota
	 */
	InvoicedPriceCCalc?: number

	/**
	 * undefined
	 */
	WarehouseZoneRIDCalc?: number

	/**
	 * W
	 */
	JobCardStateCalc?: number

	/**
	 * Typ slevy pl.
	 */
	DiscountTypePlanSuffixCalc?: string

	/**
	 * Typ slevy fa.
	 */
	DiscountTypeInvoiceSuffixCalc?: string

	/**
	 * Sériové číslo
	 */
	SerialNumberInputCalc?: string

	/**
	 * Časové razítko vykrytí
	 */
	CoverageTimeStampCalc?: Date

	/**
	 * undefined
	 */
	IsProhibitedByPriceListCalc?: string

	/**
	 * Režim náhradního plnění
	 */
	FacultativeCompensationBitCalc?: number

	/**
	 * Expedice - datum
	 */
	DispatchDateCalc?: Date

	/**
	 * Čas
	 */
	DispatchTimeCalc?: Date

	/**
	 * Potvrzeno na - datum
	 */
	DeliveryConfirmedDateCalc?: Date

	/**
	 * Čas
	 */
	DeliveryConfirmedTimeCalc?: Date

	/**
	 * Poprvé potvrzeno na - datum
	 */
	FirstDelivConfirmForDateCalc?: Date

	/**
	 * Poprvé potvrzeno na - čas
	 */
	FirstDelivConfirmForTimeCalc?: Date

	/**
	 * ExciseTaxDateCalc
	 */
	ExciseTaxDateCalc?: Date

	/**
	 * Prodl. výroby- rez.
	 */
	ProductionDelayReservationCalc?: number

	/**
	 * Prodl. výroby - potvr.
	 */
	ProductionDelayConfirmationCalc?: number

	/**
	 * Datum pro EKO-KOM
	 */
	EkokomDateCalc?: Date

	/**
	 * Total capacity
	 */
	TotalCapacityCalc?: number

	/**
	 * undefined
	 */
	PromotionTypeCalc?: number

	/**
	 * Dispozice z dodavatelské ceny
	 */
	SupplierPriceAvailableCalc?: number

	/**
	 * Cena ve věrnostním programu
	 */
	LoyaltyProgramPriceCalc?: number

	/**
	 * Skupina ve věrnostním programu
	 */
	LoyaltyProgramGroupIdCalc?: number

	/**
	 * Sleva Z (brutto)
	 */
	PlannedDiscountGrossCalc?: number

	/**
	 * Sleva F (brutto)
	 */
	InvoiceDiscountGrossCalc?: number

	/**
	 * Plán výroby - Datum do z podřízené průvodky podle varianty
	 */
	JobCardPlanEndDateByVariantCalc?: Date

	/**
	 * Název zboží pro e-shop
	 */
	EshopArticleNameCalc?: string

	/**
	 * Pl. datum Do (Kořenová průvodka)
	 */
	RootPlannedDateTo?: Date

	/**
	 * Množství
	 */
	QuantityCalc?: number

	/**
	 * Požadováno
	 */
	DesiredDeliveryCalc?: Date

	/**
	 * Prodl. rez.
	 */
	DelayResourceCalc?: number

	/**
	 * Prodl. pož.
	 */
	DelayRequestCalc?: number

	/**
	 * Rezervováno
	 */
	ReservationDateCalc?: Date

	/**
	 * undefined
	 */
	LockCalc?: number

	/**
	 * B
	 */
	LockImgCalc?: string

	/**
	 * Potvrzeno na - datum
	 */
	DeliveryConfirmedItemDateCalc?: Date

	/**
	 * Expedice - datum
	 */
	DispatchItemDateCalc?: Date

	/**
	 * Externí šarže
	 */
	@Type(() => TExternalBatchDM)
	ExternalBatchId?: TExternalBatchDM

	/**
	 * Servisní úkon
	 */
	@Type(() => TScheduledServiceOperationDM)
	ServiceOperationRID?: TScheduledServiceOperationDM

	/**
	 * Servisované zařízení
	 */
	@Type(() => TServicedDeviceDM)
	ServicedDeviceRID?: TServicedDeviceDM

	/**
	 * Kód 6
	 */
	@Type(() => TCode6DocumentDM)
	Code6Id?: TCode6DocumentDM

	/**
	 * Kód 5
	 */
	@Type(() => TCode5DocumentDM)
	Code5Id?: TCode5DocumentDM

	/**
	 * Kód 4
	 */
	@Type(() => TCode4DocumentDM)
	Code4Id?: TCode4DocumentDM

	/**
	 * Kód 3
	 */
	@Type(() => TCode3DocumentDM)
	Code3Id?: TCode3DocumentDM

	/**
	 * Priorita
	 */
	@Type(() => Priority)
	PriorityId?: Priority

	/**
	 * MTStatus
	 */
	@Type(() => TCodeTypeDM)
	MobileTerminalStatusId?: TCodeTypeDM

	/**
	 * Párovací symbol
	 */
	@Type(() => TMatchingSymbolDM)
	MatchingSymbolId?: TMatchingSymbolDM

	/**
	 * Promoakce
	 */
	@Type(() => TPromotionDM)
	PromotionRID?: TPromotionDM

	/**
	 * Kód zboží
	 */
	@Type(() => TArticleCodeDocumentDM)
	ArticleCodeId?: TArticleCodeDocumentDM

	/**
	 * Středisko
	 */
	@Type(() => TCostCentreCollectionDocumentDM)
	CostCentreId?: TCostCentreCollectionDocumentDM

	/**
	 * Prostředek
	 */
	@Type(() => TDeviceDocumentDM)
	DeviceRID?: TDeviceDocumentDM

	/**
	 * Manipul. j.
	 */
	@Type(() => THandlingUnitArticleDM)
	HandlingUnitRID?: THandlingUnitArticleDM

	/**
	 * Kniha
	 */
	@Type(() => TBookDM)
	MainDocumentBookId?: TBookDM

	/**
	 * Zákazník
	 */
	@Type(() => TTradingPartnerDM)
	MainDocumentTradingPartnerId?: TTradingPartnerDM

	/**
	 * Výdejka
	 */
	@Type(() => TReleaseNoteDM)
	ReleaseNoteRID?: TReleaseNoteDM

	/**
	 * Dodací list
	 */
	@Type(() => TDeliveryNoteDM)
	DeliveryNoteRID?: TDeliveryNoteDM

	/**
	 * Rezervační list
	 */
	@Type(() => TReservingCardDM)
	ReservingCardRID?: TReservingCardDM

	/**
	 * Objednávka přijatá
	 */
	@Type(() => TOrderConfirmationDM)
	OrderConfirmationRID?: TOrderConfirmationDM

	/**
	 * Faktura vydaná
	 */
	@Type(() => TInvoiceOutDM)
	InvoiceOutRID?: TInvoiceOutDM

	/**
	 * Zakázka
	 */
	@Type(() => TSalesOrderDM)
	SalesOrderRID?: TSalesOrderDM

	/**
	 * Typ daně
	 */
	@Type(() => TTaxTypeDM)
	TaxTypeId?: TTaxTypeDM

	/**
	 * Kód zakázky
	 */
	@Type(() => TContractCodeDocumentDM)
	ContractCodeRID?: TContractCodeDocumentDM

	/**
	 * Servisní list
	 */
	@Type(() => TServiceNoteDM)
	ServiceNoteRID?: TServiceNoteDM

	/**
	 * Nomenklatura
	 */
	@Type(() => TNomenclatureDM)
	NomenclatureId?: TNomenclatureDM

	/**
	 * Účet
	 */
	@Type(() => TAccountingChartDM)
	AccountId?: TAccountingChartDM

	/**
	 * Dodavatel
	 */
	@Type(() => TTradingPartnerDM)
	SupplierId?: TTradingPartnerDM

	/**
	 * Varianta TP
	 */
	@Type(() => TRoutingVariantDM)
	RoutingVariantId?: TRoutingVariantDM

	/**
	 * Typ plánu
	 */
	@Type(() => PlanType)
	PlanTypeId?: PlanType

	/**
	 * Stát pův.
	 */
	@Type(() => TCountryDM)
	OriginCountryId?: TCountryDM

	/**
	 * Plán. sklad
	 */
	@Type(() => TWarehouseDM)
	PlannedWarehouseId?: TWarehouseDM

	/**
	 * Kód 2
	 */
	@Type(() => TCode2DocumentDM)
	Code2Id?: TCode2DocumentDM

	/**
	 * Kód 1
	 */
	@Type(() => TCode1DocumentDM)
	Code1Id?: TCode1DocumentDM

	/**
	 * Referent
	 */
	@Type(() => TOfficerDocumentDM)
	OfficerId?: TOfficerDocumentDM

	/**
	 * Pož. parametry
	 */
	@Type(() => TRequestedBatchParameterHeaderDM)
	RequestedBatchParametersId?: TRequestedBatchParameterHeaderDM

	/**
	 * Umístění
	 */
	@Type(() => TLocationArticleDM)
	LocationId?: TLocationArticleDM

	/**
	 * Cen. sk.
	 */
	@Type(() => TPriceGroupDM)
	PriceGroupId?: TPriceGroupDM

	/**
	 * Daň
	 */
	@Type(() => TVATRateDM)
	VATRateId?: TVATRateDM

	/**
	 * Jednotka
	 */
	@Type(() => TMeasureUnitDM)
	MeasureUnitId?: TMeasureUnitDM

	/**
	 * Sklad
	 */
	@Type(() => TWarehouseDM)
	WarehouseId?: TWarehouseDM

	/**
	 * Šarže
	 */
	@Type(() => TBatchDM)
	BatchId?: TBatchDM

	/**
	 * Název
	 */
	@Type(() => GoodsName)
	ArticleNameId?: GoodsName

	/**
	 * Zboží
	 */
	@Type(() => TArticleDM)
	ArticleId?: TArticleDM

	/**
	 * Měna nadř. d.
	 */
	@Type(() => TCurrencyDM)
	HeaderCurrencyCalc?: TCurrencyDM

	/**
	 * Měna fa.
	 */
	@Type(() => TCurrencyDM)
	InvoiceCurrencyCalc?: TCurrencyDM

	/**
	 * Skl.
	 */
	@Type(() => TWarehouseDM)
	WarehouseIdCalc?: TWarehouseDM

	/**
	 * D
	 */
	@Type(() => TTextDM)
	QuantitySignBitCalc?: TTextDM

	/**
	 * Měna sk.
	 */
	@Type(() => TCurrencyDM)
	StockCurrencyCalc?: TCurrencyDM

	/**
	 * undefined
	 */
	@Type(() => TCostCentreCollectionDocumentDM)
	BindCostCentreIdCalc?: TCostCentreCollectionDocumentDM

	/**
	 * undefined
	 */
	@Type(() => TContractCodeDocumentDM)
	BindContractCodeRIDCalc?: TContractCodeDocumentDM

	/**
	 * undefined
	 */
	@Type(() => TCode1DocumentDM)
	BindCode1IdCalc?: TCode1DocumentDM

	/**
	 * undefined
	 */
	@Type(() => TCode2DocumentDM)
	BindCode2IdCalc?: TCode2DocumentDM

	/**
	 * undefined
	 */
	@Type(() => TOfficerDocumentDM)
	BindOfficerIdCalc?: TOfficerDocumentDM

	/**
	 * undefined
	 */
	@Type(() => TPriceGroupDM)
	BindPriceGroupIdCalc?: TPriceGroupDM

	/**
	 * undefined
	 */
	@Type(() => TTradingPartnerDM)
	BindTradingPartnerIdCalc?: TTradingPartnerDM

	/**
	 * N
	 */
	@Type(() => TTextDM)
	SNIncorrectCountBitCalc?: TTextDM

	/**
	 * Cen. sk.
	 */
	@Type(() => TPriceGroupDM)
	PriceGroupIdCalc?: TPriceGroupDM

	/**
	 * Dobropis. položka
	 */
	@Type(() => TTextDM)
	ExistsCreditNoteBitCalc?: TTextDM

	/**
	 * WarehouseForIdCalc
	 */
	@Type(() => TWarehouseDM)
	WarehouseForIdCalc?: TWarehouseDM

	/**
	 * ParameterSetIdCalc
	 */
	@Type(() => TParameterSetDM)
	ParameterSetIdCalc?: TParameterSetDM

	/**
	 * Transakce
	 */
	@Type(() => JCDObchod)
	TransactionIdCalc?: JCDObchod

	/**
	 * Dodací podmínky
	 */
	@Type(() => JCDP20_a1)
	DeliveryConditionIdCalc?: JCDP20_a1

	/**
	 * Zvláštní pohyb
	 */
	@Type(() => SpecialMove)
	SpecialMoveIdCalc?: SpecialMove

	/**
	 * Druh dopravy
	 */
	@Type(() => TransportKind)
	TransportTypeIdCalc?: TransportKind

	/**
	 * Stát určení
	 */
	@Type(() => TCountryDM)
	CountryIdCalc?: TCountryDM

	/**
	 * Č. prův.
	 */
	@Type(() => TJobCardDM)
	JobCardRIDCalc?: TJobCardDM

	/**
	 *  
	 */
	@Type(() => TTextDM)
	CoveredBitCalc?: TTextDM

	/**
	 *  
	 */
	@Type(() => TTextDM)
	CoveredByStockBitCalc?: TTextDM

	/**
	 * Platný typ daně
	 */
	@Type(() => TTaxTypeDM)
	TaxTypeIdCalc?: TTaxTypeDM

	/**
	 * undefined
	 */
	@Type(() => TDeviceDocumentDM)
	BindDeviceRIDCalc?: TDeviceDocumentDM

	/**
	 * D
	 */
	@Type(() => TTextDM)
	DeliveryConfirmationStateIdCalc?: TTextDM

	/**
	 * F
	 */
	@Type(() => TTextDM)
	InvoiceOutStateIdCalc?: TTextDM

	/**
	 * V
	 */
	@Type(() => TTextDM)
	ReleaseNoteStateIdCalc?: TTextDM

	/**
	 * O
	 */
	@Type(() => TTextDM)
	OrderConfirmationStateIdCalc?: TTextDM

	/**
	 * R
	 */
	@Type(() => TTextDM)
	ReservingCardStateIdCalc?: TTextDM

	/**
	 * Celní sazebník
	 */
	@Type(() => TIntrastatTariffDutyDM)
	CustomsTariffIdCalc?: TIntrastatTariffDutyDM

	/**
	 * Zboží
	 */
	@Type(() => TArticleDM)
	ArticleByIdInputIdCalc?: TArticleDM

	/**
	 * Zboží
	 */
	@Type(() => TArticleDM)
	ArticleByAbbr2InputIdCalc?: TArticleDM

	/**
	 * Zboží
	 */
	@Type(() => TArticleDM)
	ArticleByNameInputIdCalc?: TArticleDM

	/**
	 * undefined
	 */
	@Type(() => TPriceListDM)
	PriceListRIDCalc?: TPriceListDM

	/**
	 * undefined
	 */
	@Type(() => TCode3DocumentDM)
	BindCode3IdCalc?: TCode3DocumentDM

	/**
	 * undefined
	 */
	@Type(() => TCode4DocumentDM)
	BindCode4IdCalc?: TCode4DocumentDM

	/**
	 * undefined
	 */
	@Type(() => TCode5DocumentDM)
	BindCode5IdCalc?: TCode5DocumentDM

	/**
	 * undefined
	 */
	@Type(() => TCode6DocumentDM)
	BindCode6IdCalc?: TCode6DocumentDM

	/**
	 * V předstihu
	 */
	@Type(() => TTextDM)
	ProductionReliability1BitCalc?: TTextDM

	/**
	 * V termínu
	 */
	@Type(() => TTextDM)
	ProductionReliability2BitCalc?: TTextDM

	/**
	 * V ohrožení
	 */
	@Type(() => TTextDM)
	ProductionReliability3BitCalc?: TTextDM

	/**
	 * V prodlení
	 */
	@Type(() => TTextDM)
	ProductionReliability4BitCalc?: TTextDM

	/**
	 * Spolehlivost výroby
	 */
	@Type(() => TTextDM)
	ProductionReliabilityIdCalc?: TTextDM

	/**
	 * undefined
	 */
	@Type(() => TTextDM)
	ProductionReliability5BitCalc?: TTextDM

	/**
	 * undefined
	 */
	@Type(() => TTextDM)
	ProductionReliability6BitCalc?: TTextDM

	/**
	 * RID z dodavatelské ceny
	 */
	@Type(() => TSupplierPriceDM)
	SupplierPriceRIDCalc?: TSupplierPriceDM

	/**
	 * RID Věrnostní programy
	 */
	@Type(() => TLoyaltyProgramDM)
	LoyaltyProgramRIDCalc?: TLoyaltyProgramDM

	/**
	 * undefined
	 */
	@Type(() => TWarehouseDM)
	InformationWarehouseIdCalc?: TWarehouseDM

	/**
	 * Blok. uživatel
	 */
	@Type(() => TK2UserLookupDM)
	LockUserIdCalc?: TK2UserLookupDM

	/**
	 * undefined
	 */
	@Type(() => TPeriodDM)
	CurrentBusinessYearIdCalc?: TPeriodDM

	/**
	 * Varianta zaplánování výroby
	 */
	@Type(() => TProductionSchedulingVariantDM)
	JobCardItemSchedulingVariantIdCalc?: TProductionSchedulingVariantDM

	/**
	 * undefined
	 */
	@Type(() => TItemNoteDM)
	ItemNotesChild?: TItemNoteDM[]

	/**
	 * undefined
	 */
	@Type(() => TSerialNumberItemDM)
	SerialNumberItemChild?: TSerialNumberItemDM[]

	/**
	 * undefined
	 */
	@Type(() => TLanguageMemoTranslationDM)
	ItemTextChild?: TLanguageMemoTranslationDM[]

	/**
	 * Podřízené doklady
	 */
	@Type(() => TJobCardSubordinateDocumentDM)
	JobCardSubordinateDocChild?: TJobCardSubordinateDocumentDM[]

	/**
	 * NOFGrid Konkurencni  ceny
	 */
	@Type(() => TCustomTSalesItemBookDMNOFGrid_Konkurencni_ceny)
	NOFGrid_Konkurencni_ceny?: TCustomTSalesItemBookDMNOFGrid_Konkurencni_ceny[]

	/**
	 * NOFGrid Vybrani  dod
	 */
	@Type(() => TCustomTSalesItemBookDMNOFGrid_Vybrani_dod)
	NOFGrid_Vybrani_dod?: TCustomTSalesItemBookDMNOFGrid_Vybrani_dod[]

	/**
	 * NOFGrid Pohyby
	 */
	@Type(() => TCustomTSalesItemBookDMNOFGrid_Pohyby)
	NOFGrid_Pohyby?: TCustomTSalesItemBookDMNOFGrid_Pohyby[]

	/**
	 * Změna skupiny práv
	 */
	public ChangeGroupRightCOMMAND() {
		return this.executeCommand('ChangeGroupRightCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public BulkChangeGroupRightCOMMAND() {
		return this.executeCommand('BulkChangeGroupRightCOMMAND')
	}

	/**
	 * Aktualizace počítaných polí
	 */
	public BulkUpdateCalcFieldsCOMMAND() {
		return this.executeCommand('BulkUpdateCalcFieldsCOMMAND')
	}

	/**
	 * Kontrola legislativy
	 */
	public SwitchOffLegislationValidationCOMMAND() {
		return this.executeCommand('SwitchOffLegislationValidationCOMMAND')
	}
}