import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TSpecialStringListDM from './TSpecialStringListDM'

/**
 * Auta - Spotřeba
 */
export default class TVehicleConsumptionDM extends Data {
	public static className = 'TVehicleConsumptionDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = false

	/**
	 * Časové razítko
	 */
	TimeStamp?: string

	/**
	 * Popis
	 */
	Description?: string

	/**
	 * Normovaná spotřeba
	 */
	StandardConsumtion?: number

	/**
	 * Auto
	 */
	VehicleId?: number

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Typ terénu
	 */
	@Type(() => TSpecialStringListDM)
	TerrainType?: TSpecialStringListDM
}