import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TExternalBatchDM from './TExternalBatchDM'
import TCode6DocumentDM from './TCode6DocumentDM'
import TCode5DocumentDM from './TCode5DocumentDM'
import TCode4DocumentDM from './TCode4DocumentDM'
import TCode3DocumentDM from './TCode3DocumentDM'
import WarrantyB from './WarrantyB'
import TBatchDM from './TBatchDM'
import TRequestedBatchParameterHeaderDM from './TRequestedBatchParameterHeaderDM'
import TDeviceDocumentDM from './TDeviceDocumentDM'
import TPriceGroupDM from './TPriceGroupDM'
import TRoutingVariantDM from './TRoutingVariantDM'
import TMarketingPhaseDM from './TMarketingPhaseDM'
import TVATRateDM from './TVATRateDM'
import TArticleCodeDocumentDM from './TArticleCodeDocumentDM'
import TOfficerDocumentDM from './TOfficerDocumentDM'
import TCode2DocumentDM from './TCode2DocumentDM'
import TCode1DocumentDM from './TCode1DocumentDM'
import TContractCodeDocumentDM from './TContractCodeDocumentDM'
import TCostCentreCollectionDocumentDM from './TCostCentreCollectionDocumentDM'
import TQuotationRequestedItemDM from './TQuotationRequestedItemDM'
import QuotationOfrItemStat from './QuotationOfrItemStat'
import TArticleDM from './TArticleDM'
import MrktngDocItemGroup from './MrktngDocItemGroup'
import TQuotationDM from './TQuotationDM'
import TMeasureUnitDM from './TMeasureUnitDM'
import AlternativeUnit from './AlternativeUnit'
import TReqForQuoRequestedItemAloneDM from './TReqForQuoRequestedItemAloneDM'
import TReqForQuoOfferedItemAloneDM from './TReqForQuoOfferedItemAloneDM'
import TPriceListCalculationDM from './TPriceListCalculationDM'
import TProductCostingDM from './TProductCostingDM'

/**
 * Nabízené položky
 */
export default class TQuotationOfferedItemDM extends Data {
	public static className = 'TQuotationOfferedItemDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = false

	/**
	 * Kalkulace
	 */
	CostingBind?: number

	/**
	 * Datum realizace
	 */
	ImplementationDate?: Date

	/**
	 * Interní text
	 */
	InternalText?: string

	/**
	 * Obchodní text
	 */
	BusinessText?: string

	/**
	 * Očekávaná cena
	 */
	EstimatedPrice?: number

	/**
	 * Nákladová cena
	 */
	CostPrice?: number

	/**
	 * Katalogová cena
	 */
	ListPrice?: number

	/**
	 * Nabízená cena
	 */
	OfferedPrice?: number

	/**
	 * Jednotka
	 */
	MeasureUnitId?: number

	/**
	 * Množství
	 */
	Quantity?: number

	/**
	 * &Název položky
	 */
	ArticleName?: string

	/**
	 * Pořadí
	 */
	SequenceNumber?: number

	/**
	 * Typ položky
	 */
	ItemTypeId?: number

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * &Množství
	 */
	QuantityUMCalc?: number

	/**
	 * Měna
	 */
	CurrencyCalc?: string

	/**
	 * Nabíd. cena
	 */
	ExchangeRateCalc?: number

	/**
	 * Sleva
	 */
	DiscountCalc?: number

	/**
	 * Sleva / MJ
	 */
	UnitDiscountCalc?: number

	/**
	 * Sleva
	 */
	TotalDiscountCalc?: number

	/**
	 * Sleva / MJ
	 */
	DiscountCCalc?: number

	/**
	 * Sleva celkem
	 */
	TotalDiscountCCalc?: number

	/**
	 * Zisk
	 */
	ProfitCalc?: number

	/**
	 * Zisk / MJ
	 */
	UnitProfitCalc?: number

	/**
	 * Zisk
	 */
	TotalProfitCalc?: number

	/**
	 * &Zisk / MJ
	 */
	ProfitCCalc?: number

	/**
	 * Zisk
	 */
	TotalProfitCCalc?: number

	/**
	 * Očekáv. cena / MJ
	 */
	EstimatedUnitPriceCalc?: number

	/**
	 * Očekávaná cena
	 */
	EstimatedTotalPriceCalc?: number

	/**
	 * Očekáv. &cena / MJ
	 */
	EstimatedPriceCCalc?: number

	/**
	 * Očekávaná cena
	 */
	EstimatedTotalPriceCCalc?: number

	/**
	 * Katalog. cena / MJ
	 */
	CatalogUnitPriceCalc?: number

	/**
	 * Katalog. cena
	 */
	CatalogTotalPriceCalc?: number

	/**
	 * Katalogová / MJ
	 */
	CatalogUnitPriceCCalc?: number

	/**
	 * Katalogová celkem
	 */
	CatalogTotalPriceCCalc?: number

	/**
	 * Nab. cena / MJ (netto)
	 */
	OfferedUnitPriceNetCalc?: number

	/**
	 * Nabíd. cena (netto)
	 */
	OfferedTotalPriceNetCalc?: number

	/**
	 * Nab. &cena netto /MJ
	 */
	OfferedUnitPriceNetCCalc?: number

	/**
	 * Nab. cena netto celkem
	 */
	OfferedTotalPriceNetCCalc?: number

	/**
	 * Nákl. cena / MJ
	 */
	UnitCostPriceCalc?: number

	/**
	 * Nákl. cena
	 */
	TotalCostPriceCalc?: number

	/**
	 * Nák&lady / MJ
	 */
	CostPriceCCalc?: number

	/**
	 * Náklady celkem
	 */
	TotalCostPriceCCalc?: number

	/**
	 * MJ
	 */
	MeasureUnitCalc?: string

	/**
	 * MJ koef.
	 */
	MeasureUnitCoefficientCalc?: number

	/**
	 * Název požad. prod.
	 */
	RequiredItemNameCalc?: string

	/**
	 * Název produktu
	 */
	ArticleNameCalc?: string

	/**
	 * Započítáva&t do celkové ceny dokladu
	 */
	UseForDocumentTotalAmountBitCalc?: number

	/**
	 * Sle&va
	 */
	DiscountPctCalc?: number

	/**
	 * Nab. cena / MJ (brutto)
	 */
	OfferedUnitPriceGrossCalc?: number

	/**
	 * Nabíd. cena (brutto)
	 */
	OfferedTotalPriceGrossCalc?: number

	/**
	 * Nab. &cena brutto /MJ
	 */
	OfferedUnitPriceGrossCCalc?: number

	/**
	 * Nab. cena brutto celkem
	 */
	OfferedTotalPriceGrossCCalc?: number

	/**
	 * Měna
	 */
	MainCurrencyCalc?: string

	/**
	 * DPH koef.
	 */
	CoefficientVATCalc?: number

	/**
	 * Realizováno
	 */
	ImplementationDateCalc?: Date

	/**
	 * Zb
	 */
	NotContainsArticleCalc?: string

	/**
	 * Zisk (&q)
	 */
	ProfitPctCalc?: number

	/**
	 * undefined
	 */
	PriceGroup1ImgCalc?: number

	/**
	 * undefined
	 */
	PriceGroup2ImgCalc?: number

	/**
	 * undefined
	 */
	Deal1ImgCalc?: string

	/**
	 * undefined
	 */
	Deal2ImgCalc?: string

	/**
	 * undefined
	 */
	ArticleTypeIdCalc?: number

	/**
	 * Sleva / MJ (brutto)
	 */
	DiscountGrossCCalc?: number

	/**
	 * Sleva celkem (brutto)
	 */
	TotalDiscountGrossCCalc?: number

	/**
	 * Externí šarže
	 */
	@Type(() => TExternalBatchDM)
	ExternalBatchId?: TExternalBatchDM

	/**
	 * Kód 6
	 */
	@Type(() => TCode6DocumentDM)
	Code6Id?: TCode6DocumentDM

	/**
	 * Kód 5
	 */
	@Type(() => TCode5DocumentDM)
	Code5Id?: TCode5DocumentDM

	/**
	 * Kód 4
	 */
	@Type(() => TCode4DocumentDM)
	Code4Id?: TCode4DocumentDM

	/**
	 * Kód 3
	 */
	@Type(() => TCode3DocumentDM)
	Code3Id?: TCode3DocumentDM

	/**
	 * Záruka
	 */
	@Type(() => WarrantyB)
	WarrantyId?: WarrantyB

	/**
	 * Šarže
	 */
	@Type(() => TBatchDM)
	BatchId?: TBatchDM

	/**
	 * Požadované parametry
	 */
	@Type(() => TRequestedBatchParameterHeaderDM)
	RequestedBatchParametersId?: TRequestedBatchParameterHeaderDM

	/**
	 * Prostředek
	 */
	@Type(() => TDeviceDocumentDM)
	DeviceRID?: TDeviceDocumentDM

	/**
	 * Cenová skupina
	 */
	@Type(() => TPriceGroupDM)
	PriceGroupId?: TPriceGroupDM

	/**
	 * Varianta
	 */
	@Type(() => TRoutingVariantDM)
	RoutingVariantId?: TRoutingVariantDM

	/**
	 * E&tapa
	 */
	@Type(() => TMarketingPhaseDM)
	PhaseRID?: TMarketingPhaseDM

	/**
	 * Sazba DPH
	 */
	@Type(() => TVATRateDM)
	VATRateId?: TVATRateDM

	/**
	 * Kód zboží
	 */
	@Type(() => TArticleCodeDocumentDM)
	ArticleCodeId?: TArticleCodeDocumentDM

	/**
	 * Referent
	 */
	@Type(() => TOfficerDocumentDM)
	OfficerId?: TOfficerDocumentDM

	/**
	 * Kód 2
	 */
	@Type(() => TCode2DocumentDM)
	Code2Id?: TCode2DocumentDM

	/**
	 * Kód 1
	 */
	@Type(() => TCode1DocumentDM)
	Code1Id?: TCode1DocumentDM

	/**
	 * Kód zakázky
	 */
	@Type(() => TContractCodeDocumentDM)
	ContractCodeRID?: TContractCodeDocumentDM

	/**
	 * Středisko
	 */
	@Type(() => TCostCentreCollectionDocumentDM)
	CostCentreId?: TCostCentreCollectionDocumentDM

	/**
	 * Požadovaná položka
	 */
	@Type(() => TQuotationRequestedItemDM)
	RequiredItemRID?: TQuotationRequestedItemDM

	/**
	 * Stav
	 */
	@Type(() => QuotationOfrItemStat)
	ItemStatusId?: QuotationOfrItemStat

	/**
	 * Zboží
	 */
	@Type(() => TArticleDM)
	ArticleId?: TArticleDM

	/**
	 * Skupina
	 */
	@Type(() => MrktngDocItemGroup)
	GroupId?: MrktngDocItemGroup

	/**
	 * HeaderRID
	 */
	@Type(() => TQuotationDM)
	HeaderRID?: TQuotationDM

	/**
	 * Jednotka
	 */
	@Type(() => TMeasureUnitDM)
	MeasureUnitByArticleIdCalc?: TMeasureUnitDM

	/**
	 * Jednotka
	 */
	@Type(() => AlternativeUnit)
	GeneralMueasureUnitIdCalc?: AlternativeUnit

	/**
	 * Zboží
	 */
	@Type(() => TArticleDM)
	ArticleByIdInputIdCalc?: TArticleDM

	/**
	 * Zboží
	 */
	@Type(() => TArticleDM)
	ArticleByAbbr2InputIdCalc?: TArticleDM

	/**
	 * Zboží
	 */
	@Type(() => TArticleDM)
	ArticleByNameInputIdCalc?: TArticleDM

	/**
	 * Požadované položky poptávek
	 */
	@Type(() => TReqForQuoRequestedItemAloneDM)
	ReqForQuotRequestedItemChild?: TReqForQuoRequestedItemAloneDM[]

	/**
	 * Nabízené položky poptávek
	 */
	@Type(() => TReqForQuoOfferedItemAloneDM)
	ReqForQuotOfferedItemChild?: TReqForQuoOfferedItemAloneDM[]

	/**
	 * Průběh výpočtu ceny
	 */
	@Type(() => TPriceListCalculationDM)
	PriceCalculationChild?: TPriceListCalculationDM[]

	/**
	 * undefined
	 */
	@Type(() => TProductCostingDM)
	ProductCostingChild?: TProductCostingDM[]
}