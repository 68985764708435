import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TSalesOrderDM from './TSalesOrderDM'

/**
 * Rámcová smlouva
 */
export default class TSpecialTTradingPartnerDMSalesOrderContractChild extends Data {
	public static className = 'TSpecialTTradingPartnerDMSalesOrderContractChild'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = false

	/**
	 * Master
	 */
	Master?: number

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * RecordMod
	 */
	RecordMod?: number

	/**
	 * RecordES
	 */
	RecordES?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * RecordState
	 */
	RecordState?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Zakázka
	 */
	@Type(() => TSalesOrderDM)
	SalesOrderRID?: TSalesOrderDM
}