import { RawAxiosRequestHeaders } from 'axios'
import HmacMD5 from 'crypto-js/hmac-md5'
import encBase64 from 'crypto-js/enc-base64'
import PrimaryKey from './PrimaryKey'
import { PageParams } from './PageParams'
import Formation from './Formation'

export default class Api {
    constructor() {
        const apiUrl = this.getApiUrl()
        if (apiUrl === undefined || apiUrl === '') {
            throw new Error('env variable REACT_APP_K2_API_URL is not defined.')
        }
    }

    public getDataUrl(className: string, primaryKey?: PrimaryKey): string {
        return `${this.getApiUrl()}Data/${className}${primaryKey ? `/${primaryKey}` : ""}`
    }

    public getDataUrlWithFields(className: string, primaryKey?: PrimaryKey, fields?: string[]): string {
        const url = new URL(this.getDataUrl(className, primaryKey))
        if (fields) url.searchParams.append("fields", fields.join(","))
        return url.href
    }

    public getDataListUrl(className: string): string {
        return `${this.getApiUrl()}Data/GetList/${className}`
    }

    public getDataListBySelectionUrl(className: string, fields?: string[], orderBy?: string[], pageParams?: PageParams): string {
        const url = new URL(`${this.getApiUrl()}Data/GetListBySelection/${className}`)
        if (fields) url.searchParams.append("fields", fields.join(","))
        if (orderBy) url.searchParams.append("orderBy", orderBy.join(","))
        if (pageParams) {
            Object.entries(pageParams).forEach(keyValue => {
                if (keyValue[1]) {
                    url.searchParams.append(keyValue[0].toString(), keyValue[1].toString())
                }
            })
        }
        return url.href
    }

    public getCommandUrl(commandId: string): string {
        return `${this.getApiUrl()}Data/command/${commandId}`
    }

    public getFormationExecuteUrl(c: typeof Formation): string {
        return `${this.getApiUrl()}Formation/${c.folder}/${c.formationName}/${c.extension}`
    }

    public getFormationDownloadUrl(c: typeof Formation): string {
        return `${this.getApiUrl()}Formation/download/${c.folder}/${c.formationName}/${c.extension}`
    }

    public getFormationHtmlUrl(c: typeof Formation): string {
        return `${this.getApiUrl()}Formation/html/${c.folder}/${c.formationName}/${c.extension}`
    }

    public getFormationRawUrl(c: typeof Formation): string {
        return `${this.getApiUrl()}Formation/raw/${c.folder}/${c.formationName}/${c.extension}`
    }

    public getAuthorizationHeader(login: string | undefined, password: string, url: string): RawAxiosRequestHeaders {
        if (!login) return {}
        return {
            Authorization: `${login}:${HmacMD5(decodeURIComponent(url).toUpperCase(), password).toString(encBase64)}`
        }
    }

    public getApiUrl(): string {
        if (!process.env.REACT_APP_K2_API_URL) throw new Error('env variable REACT_APP_K2_API_URL is not defined.')
        if (process.env.REACT_APP_K2_API_URL.slice(-1) !== '/') return `${process.env.REACT_APP_K2_API_URL}/`
        return process.env.REACT_APP_K2_API_URL
    }

    public getApiLogin(): string {
        if (!process.env.REACT_APP_K2_API_LOGIN) throw new Error('env variable REACT_APP_K2_API_LOGIN is not defined.')
        return process.env.REACT_APP_K2_API_LOGIN
    }

    public getApiPassword(): string {
        return process.env.REACT_APP_K2_API_PASSWORD!
    }
}