import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TTextDM from './TTextDM'
import TPrCalendarDM from './TPrCalendarDM'
import TBookDM from './TBookDM'
import TCode6DocumentDM from './TCode6DocumentDM'
import TCode5DocumentDM from './TCode5DocumentDM'
import TCode4DocumentDM from './TCode4DocumentDM'
import TCode3DocumentDM from './TCode3DocumentDM'
import TOrgStrTreeUnitDM from './TOrgStrTreeUnitDM'
import TK2UserLookupDM from './TK2UserLookupDM'
import TArticleCodeDocumentDM from './TArticleCodeDocumentDM'
import TCostCentreCollectionDocumentDM from './TCostCentreCollectionDocumentDM'
import DegreeName from './DegreeName'
import CZNACE from './CZNACE'
import TDeviceDocumentDM from './TDeviceDocumentDM'
import TOrgStrJobDM from './TOrgStrJobDM'
import TPrDeductionDM from './TPrDeductionDM'
import TContractCodeDocumentDM from './TContractCodeDocumentDM'
import TCurrencyDM from './TCurrencyDM'
import TAccountingChartDM from './TAccountingChartDM'
import DuvUkoncPP2 from './DuvUkoncPP2'
import TCountryDM from './TCountryDM'
import SkupPrac from './SkupPrac'
import MzdZUJ from './MzdZUJ'
import CZICSE from './CZICSE'
import TTownPartDM from './TTownPartDM'
import ProfessionName from './ProfessionName'
import MzdLAU1 from './MzdLAU1'
import NUTS4 from './NUTS4'
import TMatchingSymbolDM from './TMatchingSymbolDM'
import TCode2DocumentDM from './TCode2DocumentDM'
import TCode1DocumentDM from './TCode1DocumentDM'
import Group2 from './Group2'
import Group1 from './Group1'
import Kategorie2 from './Kategorie2'
import Kategorie1 from './Kategorie1'
import TPrUnionDM from './TPrUnionDM'
import TPrMinimalTariffDM from './TPrMinimalTariffDM'
import TPrInternalTariffDM from './TPrInternalTariffDM'
import EmployeeFunction from './EmployeeFunction'
import DruhCinnosti from './DruhCinnosti'
import RelWork from './RelWork'
import TypWork from './TypWork'
import DuvUkoncPP1 from './DuvUkoncPP1'
import DuvVznikuPP1 from './DuvVznikuPP1'
import TEmployedPersonDM from './TEmployedPersonDM'
import TPrPeriodDM from './TPrPeriodDM'
import TPrTariffDM from './TPrTariffDM'
import PrempReasons from './PrempReasons'
import PrEmpWorksCatalog from './PrEmpWorksCatalog'
import TPrEmployeeDocumentDM from './TPrEmployeeDocumentDM'
import TMarketingActivityTypeDM from './TMarketingActivityTypeDM'
import TWorkflowProcessDM from './TWorkflowProcessDM'
import TCommentDM from './TCommentDM'
import TPrConstantGroupEmploymentDM from './TPrConstantGroupEmploymentDM'
import TPrDefinitionComponentDM from './TPrDefinitionComponentDM'
import TPrEmployeeWorkExperienceAsItemDM from './TPrEmployeeWorkExperienceAsItemDM'
import TPrEmployeeWorkTaskAsItemDM from './TPrEmployeeWorkTaskAsItemDM'
import TPrLongTermAbsenceDM from './TPrLongTermAbsenceDM'
import TPrEmployeeSickNoteDM from './TPrEmployeeSickNoteDM'
import TOrgStrEmployeeDM from './TOrgStrEmployeeDM'
import TAttendanceScheduleDM from './TAttendanceScheduleDM'
import TOnePersonAbsenceDM from './TOnePersonAbsenceDM'
import TAttendanceWorkTimeReportDM from './TAttendanceWorkTimeReportDM'
import TAttendanceMovementDM from './TAttendanceMovementDM'
import TElectronicSubmissionPersonDM from './TElectronicSubmissionPersonDM'
import TExternalDocumentItemDM from './TExternalDocumentItemDM'
import TWorkflowProcessAsItemDM from './TWorkflowProcessAsItemDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TAttachedDocumentDM from './TAttachedDocumentDM'
import TOldNoteDM from './TOldNoteDM'

/**
 * Pracovní vztahy
 */
export default class TPrEmploymentDM extends Data {
	public static className = 'TPrEmploymentDM'
	public static primaryKey = 'Id'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.Id!
	}

	public static isHeader = true

	/**
	 * Záměr uplatňovat slevu na pojistném
	 */
	HasApplySocDiscountIntention?: string

	/**
	 * Datum typu akce
	 */
	SINActionCodeDate?: Date

	/**
	 * Přihlášen SP
	 */
	IsSocialInsuranceRegistered?: string

	/**
	 * ID pojistného vztahu
	 */
	SocialInsuranceIdentifier?: string

	/**
	 * Nekopírovat analytické osy
	 */
	NotCopyAnalyticalDimensions?: string

	/**
	 * Jméno
	 */
	FirstName?: string

	/**
	 * Příjmení
	 */
	Surname?: string

	/**
	 * Osobní číslo
	 */
	EmployeePersonNumber?: string

	/**
	 * Zkratka
	 */
	Abbr?: string

	/**
	 * Vytvořeno
	 */
	CreatedOn?: Date

	/**
	 * Zaměstnání malého rozsahu
	 */
	IsShortRangeEmployment?: string

	/**
	 * Vedoucí zaměstnanec
	 */
	IsExecutive?: string

	/**
	 * Bezprostřední nástup
	 */
	IsImmediateStart?: string

	/**
	 * Vynětí z evidenčního počtu
	 */
	IsExemption?: string

	/**
	 * V cizí měně
	 */
	IsInCurrency?: string

	/**
	 * Přesčasy ve mzdě
	 */
	IsNoPaidOverTime?: string

	/**
	 * Dodatková dovolená
	 */
	IsAdditionalLeave?: string

	/**
	 * Prodloužená dovolená
	 */
	IsExtendedLeave?: string

	/**
	 * Běžná dovolená
	 */
	IsNormalLeave?: string

	/**
	 * Automatický výpočet mzdy
	 */
	CalculateWageAutomatically?: string

	/**
	 * Kontrola min. mzdy
	 */
	CheckMinWage?: string

	/**
	 * XMLFieldScript
	 */
	XMLFieldScript?: string

	/**
	 * Neplatný záznam
	 */
	IsInvalidRecord?: string

	/**
	 * První zaměstnání v ČR
	 */
	IsFirstEmploymentInCZ?: string

	/**
	 * Datum změny sml.
	 */
	ChangeContractDate?: Date

	/**
	 * Dat. podpisu sml.
	 */
	SignatureDate?: Date

	/**
	 * Zkušební doba
	 */
	ProbationaryPeriodMonthCount?: number

	/**
	 * Pracoviště
	 */
	WorkPlaceId?: string

	/**
	 * Předpokl. ukončení
	 */
	ExpectedTerminationDate?: Date

	/**
	 * Výpov. doba od
	 */
	NoticePeriodFrom?: Date

	/**
	 * Konec zkuš. doby
	 */
	ProbationaryPeriodEndDate?: Date

	/**
	 * Odstupné
	 */
	RedundancyPaymentMultiple?: number

	/**
	 * Právo na záznam
	 */
	RightGroupId?: number

	/**
	 * Změněno
	 */
	ChangedOn?: Date

	/**
	 * Automat 4
	 */
	Automat4PrComponentId?: number

	/**
	 * Automat 3
	 */
	Automat3PrComponentId?: number

	/**
	 * Automat 2
	 */
	Automat2PrComponentId?: number

	/**
	 * Automat 1
	 */
	Automat1PrComponentId?: number

	/**
	 * Záloha na mzdu
	 */
	AdvanceWagePayment?: number

	/**
	 * Prémie
	 */
	WageBonus?: number

	/**
	 * Osobní ohodnocení II.
	 */
	WagePersonalBonus2?: number

	/**
	 * Osobní ohodnocení
	 */
	WagePersonalBonus?: number

	/**
	 * Základní mzda II.
	 */
	BasicWage2?: number

	/**
	 * Základní mzda
	 */
	BasicWage?: number

	/**
	 * Datum vzniku
	 */
	ContractDate?: Date

	/**
	 * Datum ukončení
	 */
	TerminationDate?: Date

	/**
	 * Datum nástupu
	 */
	EntryDate?: Date

	/**
	 * Firma
	 */
	CompanyId?: number

	/**
	 * Časové razítko
	 */
	TimeStamp?: string

	/**
	 * XMLField
	 */
	XMLField?: string

	/**
	 * Pořadí
	 */
	SequenceNumber?: number

	/**
	 * Pracovní vztah
	 */
	Id?: number

	/**
	 * Pracovní vztah
	 */
	RecordID?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Platnost změn k
	 */
	FieldHistoryDateCalc?: Date

	/**
	 * SQLSequenceOrderCalc
	 */
	SQLSequenceOrderCalc?: number

	/**
	 * Datum pro "_ver" pole
	 */
	VersionFieldDateCalc?: Date

	/**
	 * Úplný výpis
	 */
	IsItemCompleteCalc?: string

	/**
	 * Délka zkuš. doby
	 */
	ProbationaryPeriodInDaysCalc?: number

	/**
	 * Počet dnů pracovního vztahu
	 */
	EmploymentDayCountCalc?: number

	/**
	 * Hlavní pracovní vztah
	 */
	IsMainEmploymentCalc?: string

	/**
	 * Právní vztah
	 */
	LegalRelationDescriptionCalc?: string

	/**
	 * S
	 */
	IsEmploymentValidityDateCalc?: string

	/**
	 * Ztížené prostředí
	 */
	DifficultEnvironmentCalc?: string

	/**
	 * Sick days
	 */
	AllowSickDaysCalc?: string

	/**
	 * Příplatek osobní
	 */
	PersonalBonusAmountCalc?: number

	/**
	 * Příplatek zvláštní
	 */
	ExtraBonusCalc?: number

	/**
	 * Příplatek za vedení
	 */
	ManagementBonusAmountCalc?: number

	/**
	 * Výjimečné zařazení do třídy
	 */
	IsExceptionallyGradeSetCalc?: string

	/**
	 * Zvláštní způsob určení tarifu
	 */
	IsSpecialLevelSetCalc?: string

	/**
	 * Vedoucí pozice
	 */
	IsLeadingPositionCalc?: string

	/**
	 * Příplatek za ztížené prac. pr.
	 */
	DifficultEnvironmentRateCalc?: number

	/**
	 * Datum nástupu
	 */
	DateFrom?: Date

	/**
	 * Datum ukončení
	 */
	DateTo?: Date

	/**
	 * Pracovní vztah
	 */
	PrEmploymentId?: number

	/**
	 * Popis pracovního vztahu
	 */
	LegalRelationSequenceDescriptionCalc?: string

	/**
	 * Zařazení do výpočtu mezd
	 */
	ActivationDateCalc?: Date

	/**
	 * Období uzavření výpočtu
	 */
	ConfirmedPeriodIdCalc?: number

	/**
	 * Datum uzavření výpočtu
	 */
	ConfirmedOnCalc?: Date

	/**
	 * Započitatelná předchozí praxe [roky]
	 */
	CreditablePrevExpYearsCalc?: number

	/**
	 * Započitatelná předchozí praxe [dny]
	 */
	CreditablePrevExpDaysCalc?: number

	/**
	 * Praxe aktuálního vztahu [roky]
	 */
	ActualWorkExperienceYearCalc?: number

	/**
	 * Praxe aktuálního vztahu [dny]
	 */
	ActualWorkExperienceDayCalc?: number

	/**
	 * Celková praxe [dny]
	 */
	WorkExperienceDaysDateCalc?: number

	/**
	 * Celková praxe [roky]
	 */
	WorkExperienceYearsDateCalc?: number

	/**
	 * Platový tarif
	 */
	SalaryTariffCalc?: number

	/**
	 * Platový tarif
	 */
	SalaryTariffShortCalc?: number

	/**
	 * Stupeň dle praxe
	 */
	WorkExperienceLevelDateCalc?: number

	/**
	 * Datum nápočtu praxe
	 */
	WorkExperienceDateCalc?: Date

	/**
	 * Počet let praxe pro další stupeň
	 */
	NextLevelWorkExperienceYearsCalc?: number

	/**
	 * Datum dosažení dalšího stupně
	 */
	NextLevelDateCalc?: Date

	/**
	 * Příplatek osobní
	 */
	PersonalBonusShortAmountCalc?: number

	/**
	 * Příplatek zvláštní
	 */
	ExtraBonusShortCalc?: number

	/**
	 * Příplatek za vedení
	 */
	ManagementBonusShortAmountCalc?: number

	/**
	 * Příplatek za ztíž. prac. pr.
	 */
	DifficultEnvironmentRateShortCalc?: number

	/**
	 * Kontaktní osoba
	 */
	ContactPersonIdCalc?: number

	/**
	 * Datum začátku z období vzniku vztahu
	 */
	ContractPeriodBeginDateCalc?: Date

	/**
	 * Úvazek sjednaný týdenní
	 */
	AgreedWorkloadWeekCalc?: number

	/**
	 * Úvazek sjednaný denní
	 */
	AgreedWorkloadDayCalc?: number

	/**
	 * Úvazek stanovený týdenní
	 */
	StatedWorkloadWeekCalc?: number

	/**
	 * Úvazek stanovený denní
	 */
	StatedWorkloadDayCalc?: number

	/**
	 * Kratší prac. doba
	 */
	ShortenedWorkTimeCalc?: string

	/**
	 * Kratší pr. doba [%]
	 */
	ShortenedWorkTimePctCalc?: number

	/**
	 * Kratší pr. doba [poměr]
	 */
	ShortenedWorkTimeRatioCalc?: number

	/**
	 * undefined
	 */
	TextHeaderMemoCalc?: string

	/**
	 * undefined
	 */
	TextFooterMemoCalc?: string

	/**
	 * Poslední komentář
	 */
	LastCommentParamCalc?: string

	/**
	 * Právo na záznam
	 */
	RightGroupIdCalc?: number

	/**
	 * Příplatek zvláštní_ver
	 */
	ExtraBonusCalc_ver?: number

	/**
	 * Datum nástupu_ver
	 */
	EntryDate_ver?: Date

	/**
	 * První zaměstnání v ČR_ver
	 */
	IsFirstEmploymentInCZ_ver?: string

	/**
	 * Základní mzda_ver
	 */
	BasicWage_ver?: number

	/**
	 * Osobní ohodnocení_ver
	 */
	WagePersonalBonus_ver?: number

	/**
	 * Příplatek za vedení_ver
	 */
	ManagementBonusAmountCalc_ver?: number

	/**
	 * Přesčasy ve mzdě_ver
	 */
	IsNoPaidOverTime_ver?: string

	/**
	 * Sick days_ver
	 */
	AllowSickDaysCalc_ver?: string

	/**
	 * V cizí měně_ver
	 */
	IsInCurrency_ver?: string

	/**
	 * Záloha na mzdu_ver
	 */
	AdvanceWagePayment_ver?: number

	/**
	 * Prémie_ver
	 */
	WageBonus_ver?: number

	/**
	 * Zaměstnání malého rozsahu_ver
	 */
	IsShortRangeEmployment_ver?: string

	/**
	 * Automat 2_ver
	 */
	Automat2PrComponentId_ver?: number

	/**
	 * Pracoviště_ver
	 */
	WorkPlaceId_ver?: string

	/**
	 * Příplatek osobní_ver
	 */
	PersonalBonusAmountCalc_ver?: number

	/**
	 * Předpokl. ukončení_ver
	 */
	ExpectedTerminationDate_ver?: Date

	/**
	 * Odstupné_ver
	 */
	RedundancyPaymentMultiple_ver?: number

	/**
	 * Automat 1_ver
	 */
	Automat1PrComponentId_ver?: number

	/**
	 * Vedoucí zaměstnanec_ver
	 */
	IsExecutive_ver?: string

	/**
	 * Výjimečné zařazení do třídy_ver
	 */
	IsExceptionallyGradeSetCalc_ver?: string

	/**
	 * Datum ukončení_ver
	 */
	TerminationDate_ver?: Date

	/**
	 * Konec zkuš. doby_ver
	 */
	ProbationaryPeriodEndDate_ver?: Date

	/**
	 * Dat. podpisu sml._ver
	 */
	SignatureDate_ver?: Date

	/**
	 * Bezprostřední nástup_ver
	 */
	IsImmediateStart_ver?: string

	/**
	 * Výpov. doba od_ver
	 */
	NoticePeriodFrom_ver?: Date

	/**
	 * Vynětí z evidenčního počtu_ver
	 */
	IsExemption_ver?: string

	/**
	 * Příplatek za ztížené prac. pr._ver
	 */
	DifficultEnvironmentRateCalc_ver?: number

	/**
	 * Dodatková dovolená_ver
	 */
	IsAdditionalLeave_ver?: string

	/**
	 * Základní mzda II._ver
	 */
	BasicWage2_ver?: number

	/**
	 * Ztížené prostředí_ver
	 */
	DifficultEnvironmentCalc_ver?: string

	/**
	 * Osobní ohodnocení II._ver
	 */
	WagePersonalBonus2_ver?: number

	/**
	 * Zvláštní způsob určení tarifu_ver
	 */
	IsSpecialLevelSetCalc_ver?: string

	/**
	 * Běžná dovolená_ver
	 */
	IsNormalLeave_ver?: string

	/**
	 * Automat 3_ver
	 */
	Automat3PrComponentId_ver?: number

	/**
	 * Zkušební doba_ver
	 */
	ProbationaryPeriodMonthCount_ver?: number

	/**
	 * Vedoucí pozice_ver
	 */
	IsLeadingPositionCalc_ver?: string

	/**
	 * Automatický výpočet mzdy_ver
	 */
	CalculateWageAutomatically_ver?: string

	/**
	 * Záměr uplatňovat slevu na pojistném_ver
	 */
	HasApplySocDiscountIntention_ver?: string

	/**
	 * Kontrola min. mzdy_ver
	 */
	CheckMinWage_ver?: string

	/**
	 * Automat 4_ver
	 */
	Automat4PrComponentId_ver?: number

	/**
	 * Nekopírovat analytické osy_ver
	 */
	NotCopyAnalyticalDimensions_ver?: string

	/**
	 * Datum změny sml._ver
	 */
	ChangeContractDate_ver?: Date

	/**
	 * Prodloužená dovolená_ver
	 */
	IsExtendedLeave_ver?: string

	/**
	 * Důvod uplatnění slevy na pojistném
	 */
	@Type(() => TTextDM)
	SocDiscountReasonId?: TTextDM

	/**
	 * Typ akce
	 */
	@Type(() => TTextDM)
	LastSINActionCodeId?: TTextDM

	/**
	 * Další kalendář
	 */
	@Type(() => TPrCalendarDM)
	AdditionalCalendarId?: TPrCalendarDM

	/**
	 * Kniha docházky
	 */
	@Type(() => TBookDM)
	AttendanceBookId?: TBookDM

	/**
	 * Odstupné dle
	 */
	@Type(() => TTextDM)
	RedundancyPaymentParagraphId?: TTextDM

	/**
	 * Kód 6
	 */
	@Type(() => TCode6DocumentDM)
	Code6Id?: TCode6DocumentDM

	/**
	 * Kód 5
	 */
	@Type(() => TCode5DocumentDM)
	Code5Id?: TCode5DocumentDM

	/**
	 * Kód 4
	 */
	@Type(() => TCode4DocumentDM)
	Code4Id?: TCode4DocumentDM

	/**
	 * Kód 3
	 */
	@Type(() => TCode3DocumentDM)
	Code3Id?: TCode3DocumentDM

	/**
	 * Pracovní pozice
	 */
	@Type(() => TOrgStrTreeUnitDM)
	OrgStrTreeUnitRID?: TOrgStrTreeUnitDM

	/**
	 * Vytvořil
	 */
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM

	/**
	 * Kód zboží
	 */
	@Type(() => TArticleCodeDocumentDM)
	ArticleCodeId?: TArticleCodeDocumentDM

	/**
	 * Středisko 2
	 */
	@Type(() => TCostCentreCollectionDocumentDM)
	CostCentre2Id?: TCostCentreCollectionDocumentDM

	/**
	 * Středisko
	 */
	@Type(() => TCostCentreCollectionDocumentDM)
	CostCentre1Id?: TCostCentreCollectionDocumentDM

	/**
	 * Kniha mzdových dokladů
	 */
	@Type(() => TBookDM)
	BookId?: TBookDM

	/**
	 * Požadované vzdělání
	 */
	@Type(() => DegreeName)
	RequiredEducationLevelId?: DegreeName

	/**
	 * CZ-NACE
	 */
	@Type(() => CZNACE)
	CZNACEId?: CZNACE

	/**
	 * Prostředek
	 */
	@Type(() => TDeviceDocumentDM)
	DeviceRID?: TDeviceDocumentDM

	/**
	 * Pracovní místo
	 */
	@Type(() => TOrgStrJobDM)
	OrgStrJobRID?: TOrgStrJobDM

	/**
	 * Srážka výplaty
	 */
	@Type(() => TPrDeductionDM)
	WageDeductionId?: TPrDeductionDM

	/**
	 * Kód zakázky
	 */
	@Type(() => TContractCodeDocumentDM)
	ContractCodeRID?: TContractCodeDocumentDM

	/**
	 * Měna
	 */
	@Type(() => TCurrencyDM)
	Currency?: TCurrencyDM

	/**
	 * Finanční úřad
	 */
	@Type(() => TPrDeductionDM)
	TaxOfficeId?: TPrDeductionDM

	/**
	 * Časový fond
	 */
	@Type(() => TPrCalendarDM)
	WorkFundCalendarId?: TPrCalendarDM

	/**
	 * Účet zdr. poj.
	 */
	@Type(() => TAccountingChartDM)
	HealthInsuranceAccountId?: TAccountingChartDM

	/**
	 * Účet soc. zab.
	 */
	@Type(() => TAccountingChartDM)
	SocialSecurityAccountId?: TAccountingChartDM

	/**
	 * Doplň. důvod ukončení
	 */
	@Type(() => DuvUkoncPP2)
	AditionalTerminationReasonId?: DuvUkoncPP2

	/**
	 * Stát
	 */
	@Type(() => TCountryDM)
	WorkCountryId?: TCountryDM

	/**
	 * Skupina
	 */
	@Type(() => SkupPrac)
	GroupId?: SkupPrac

	/**
	 * ZUJ
	 */
	@Type(() => MzdZUJ)
	AdministrativeDivisionId?: MzdZUJ

	/**
	 * CZ-ICSE
	 */
	@Type(() => CZICSE)
	CZICSEId?: CZICSE

	/**
	 * Místo
	 */
	@Type(() => TTownPartDM)
	WorkTownPartId?: TTownPartDM

	/**
	 * Středisko výplatní
	 */
	@Type(() => TCostCentreCollectionDocumentDM)
	PayrollCentreId?: TCostCentreCollectionDocumentDM

	/**
	 * CZ-ISCO
	 */
	@Type(() => ProfessionName)
	CZISCOId?: ProfessionName

	/**
	 * LAU1
	 */
	@Type(() => MzdLAU1)
	LAU1Id?: MzdLAU1

	/**
	 * NUTS4
	 */
	@Type(() => NUTS4)
	NUTS4Id?: NUTS4

	/**
	 * Změnil
	 */
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM

	/**
	 * Účet Má dáti
	 */
	@Type(() => TAccountingChartDM)
	DebitAccountId?: TAccountingChartDM

	/**
	 * Účet Dal
	 */
	@Type(() => TAccountingChartDM)
	CreditAccountId?: TAccountingChartDM

	/**
	 * Párovací symbol
	 */
	@Type(() => TMatchingSymbolDM)
	MatchingSymbolId?: TMatchingSymbolDM

	/**
	 * Kód 2
	 */
	@Type(() => TCode2DocumentDM)
	Code2Id?: TCode2DocumentDM

	/**
	 * Kód 1
	 */
	@Type(() => TCode1DocumentDM)
	Code1Id?: TCode1DocumentDM

	/**
	 * Skupina 2
	 */
	@Type(() => Group2)
	Group2Id?: Group2

	/**
	 * Skupina 1
	 */
	@Type(() => Group1)
	Group1Id?: Group1

	/**
	 * Kategorie 2
	 */
	@Type(() => Kategorie2)
	Category2Id?: Kategorie2

	/**
	 * Kategorie 1
	 */
	@Type(() => Kategorie1)
	Category1Id?: Kategorie1

	/**
	 * Druh činnosti
	 */
	@Type(() => TTextDM)
	SocialActivityTypeId?: TTextDM

	/**
	 * Způsob danění
	 */
	@Type(() => TTextDM)
	TaxDeductionTypeId?: TTextDM

	/**
	 * Způsob srážky soc. zab.
	 */
	@Type(() => TTextDM)
	DeductionSocialSecurityId?: TTextDM

	/**
	 * Způsob srážky zdr. poj.
	 */
	@Type(() => TTextDM)
	DeductionHealthInsuranceId?: TTextDM

	/**
	 * Poplatník
	 */
	@Type(() => TTextDM)
	TaxPayerTypeId?: TTextDM

	/**
	 * Odborová organizace
	 */
	@Type(() => TPrUnionDM)
	UnionId?: TPrUnionDM

	/**
	 * Forma mzdy
	 */
	@Type(() => TTextDM)
	WageTypeId?: TTextDM

	/**
	 * Zaručená mzda
	 */
	@Type(() => TPrMinimalTariffDM)
	GuaranteedWage?: TPrMinimalTariffDM

	/**
	 * Interní tarif
	 */
	@Type(() => TPrInternalTariffDM)
	InternalTariffId?: TPrInternalTariffDM

	/**
	 * Stanovená prac. doba
	 */
	@Type(() => TPrCalendarDM)
	LegalCalendarId?: TPrCalendarDM

	/**
	 * Pracovní doba
	 */
	@Type(() => TPrCalendarDM)
	WorkShiftCalendarId?: TPrCalendarDM

	/**
	 * Zařazení
	 */
	@Type(() => EmployeeFunction)
	JobClassificationId?: EmployeeFunction

	/**
	 * Doba trvání
	 */
	@Type(() => TTextDM)
	DurationTypeId?: TTextDM

	/**
	 * Druh prac. pom.
	 */
	@Type(() => DruhCinnosti)
	EmploymentTypeId?: DruhCinnosti

	/**
	 * Právní vztah dle
	 */
	@Type(() => TTextDM)
	LegalRelationId?: TTextDM

	/**
	 * Právní vztah
	 */
	@Type(() => RelWork)
	LegalSubrelation2Id?: RelWork

	/**
	 * Prac. právní vztah
	 */
	@Type(() => TTextDM)
	LegalSubrelation1Id?: TTextDM

	/**
	 * Druh práce
	 */
	@Type(() => TypWork)
	WorkTypeId?: TypWork

	/**
	 * Důvod ukončení
	 */
	@Type(() => DuvUkoncPP1)
	TerminationReasonId?: DuvUkoncPP1

	/**
	 * Důvod vzniku
	 */
	@Type(() => DuvVznikuPP1)
	CommencementReasonId?: DuvVznikuPP1

	/**
	 * Pracovník
	 */
	@Type(() => TEmployedPersonDM)
	EmployedPersonId?: TEmployedPersonDM

	/**
	 * Z
	 */
	@Type(() => TTextDM)
	InvalidRecordImgCalc?: TTextDM

	/**
	 * Zpracovávané období
	 */
	@Type(() => TPrPeriodDM)
	ProcessPeriodIdCalc?: TPrPeriodDM

	/**
	 * Měna
	 */
	@Type(() => TCurrencyDM)
	CurrencyCalc?: TCurrencyDM

	/**
	 * Měna firmy
	 */
	@Type(() => TCurrencyDM)
	ClientCurrencyCalc?: TCurrencyDM

	/**
	 * S
	 */
	@Type(() => TTextDM)
	EmploymentValidityBitCalc?: TTextDM

	/**
	 * Kniha mzdových dokladů
	 */
	@Type(() => TBookDM)
	PrDocumentBookIdCalc?: TBookDM

	/**
	 * S
	 */
	@Type(() => TTextDM)
	EmploymentValidityOFBitCalc?: TTextDM

	/**
	 * S
	 */
	@Type(() => TTextDM)
	EmploymentValidityDateCalc?: TTextDM

	/**
	 * Nastavené období
	 */
	@Type(() => TPrPeriodDM)
	UserPeriodIdCalc?: TPrPeriodDM

	/**
	 * Prošlo vytvořením záloh
	 */
	@Type(() => TPrPeriodDM)
	PeriodToIdCalc?: TPrPeriodDM

	/**
	 * Editováno po výpočtu
	 */
	@Type(() => TPrPeriodDM)
	LastPeriodIdCalc?: TPrPeriodDM

	/**
	 * Tarifní tabulka
	 */
	@Type(() => TPrTariffDM)
	PayrollTariffRIDCalc?: TPrTariffDM

	/**
	 * Třída
	 */
	@Type(() => TTextDM)
	GradeIdCalc?: TTextDM

	/**
	 * Stupeň
	 */
	@Type(() => TTextDM)
	LevelIdCalc?: TTextDM

	/**
	 * Důvod
	 */
	@Type(() => PrempReasons)
	PersonalBonusReasonIdCalc?: PrempReasons

	/**
	 * Důvod
	 */
	@Type(() => PrempReasons)
	ExtraBonusReasonIdCalc?: PrempReasons

	/**
	 * Důvod
	 */
	@Type(() => PrempReasons)
	ManagementBonusReasonIdCalc?: PrempReasons

	/**
	 * Stupeň řízení
	 */
	@Type(() => TTextDM)
	ManagementLevelIdCalc?: TTextDM

	/**
	 * Příplatek zvláštní - skupina
	 */
	@Type(() => TTextDM)
	ExtraBonusGroupIdCalc?: TTextDM

	/**
	 * Důvod
	 */
	@Type(() => PrempReasons)
	DifficultEnvironmentRateReasonCalc?: PrempReasons

	/**
	 * Katalog prací
	 */
	@Type(() => PrEmpWorksCatalog)
	WorksCatalogIdCalc?: PrEmpWorksCatalog

	/**
	 * Zpracované období
	 */
	@Type(() => TPrPeriodDM)
	ProcessedPeriodIdCalc?: TPrPeriodDM

	/**
	 * undefined
	 */
	@Type(() => TPrEmployeeDocumentDM)
	ActualPrEmployeeDocumentRIDCalc?: TPrEmployeeDocumentDM

	/**
	 * D
	 */
	@Type(() => TTextDM)
	DocumentExistsCalc?: TTextDM

	/**
	 * undefined
	 */
	@Type(() => TMarketingActivityTypeDM)
	ActivityTypeRIDCalc?: TMarketingActivityTypeDM

	/**
	 * SP
	 */
	@Type(() => TTextDM)
	ProcessStateIdCalc?: TTextDM

	/**
	 * Aktuální proces
	 */
	@Type(() => TWorkflowProcessDM)
	CurrentProcessIdCalc?: TWorkflowProcessDM

	/**
	 * Interní tarif_ver
	 */
	@Type(() => TPrInternalTariffDM)
	InternalTariffId_ver?: TPrInternalTariffDM

	/**
	 * Důvod ukončení_ver
	 */
	@Type(() => DuvUkoncPP1)
	TerminationReasonId_ver?: DuvUkoncPP1

	/**
	 * Kód 1_ver
	 */
	@Type(() => TCode1DocumentDM)
	Code1Id_ver?: TCode1DocumentDM

	/**
	 * Druh práce_ver
	 */
	@Type(() => TypWork)
	WorkTypeId_ver?: TypWork

	/**
	 * Právní vztah dle_ver
	 */
	@Type(() => TTextDM)
	LegalRelationId_ver?: TTextDM

	/**
	 * Katalog prací_ver
	 */
	@Type(() => PrEmpWorksCatalog)
	WorksCatalogIdCalc_ver?: PrEmpWorksCatalog

	/**
	 * Důvod uplatnění slevy na pojistném_ver
	 */
	@Type(() => TTextDM)
	SocDiscountReasonId_ver?: TTextDM

	/**
	 * Doba trvání_ver
	 */
	@Type(() => TTextDM)
	DurationTypeId_ver?: TTextDM

	/**
	 * Důvod_ver
	 */
	@Type(() => PrempReasons)
	ExtraBonusReasonIdCalc_ver?: PrempReasons

	/**
	 * Odstupné dle_ver
	 */
	@Type(() => TTextDM)
	RedundancyPaymentParagraphId_ver?: TTextDM

	/**
	 * Účet Má dáti_ver
	 */
	@Type(() => TAccountingChartDM)
	DebitAccountId_ver?: TAccountingChartDM

	/**
	 * Důvod vzniku_ver
	 */
	@Type(() => DuvVznikuPP1)
	CommencementReasonId_ver?: DuvVznikuPP1

	/**
	 * Způsob srážky zdr. poj._ver
	 */
	@Type(() => TTextDM)
	DeductionHealthInsuranceId_ver?: TTextDM

	/**
	 * Kód 4_ver
	 */
	@Type(() => TCode4DocumentDM)
	Code4Id_ver?: TCode4DocumentDM

	/**
	 * Příplatek zvláštní - skupina_ver
	 */
	@Type(() => TTextDM)
	ExtraBonusGroupIdCalc_ver?: TTextDM

	/**
	 * Zařazení_ver
	 */
	@Type(() => EmployeeFunction)
	JobClassificationId_ver?: EmployeeFunction

	/**
	 * Důvod_ver
	 */
	@Type(() => PrempReasons)
	PersonalBonusReasonIdCalc_ver?: PrempReasons

	/**
	 * Skupina 1_ver
	 */
	@Type(() => Group1)
	Group1Id_ver?: Group1

	/**
	 * Druh prac. pom._ver
	 */
	@Type(() => DruhCinnosti)
	EmploymentTypeId_ver?: DruhCinnosti

	/**
	 * Kategorie 1_ver
	 */
	@Type(() => Kategorie1)
	Category1Id_ver?: Kategorie1

	/**
	 * Druh činnosti_ver
	 */
	@Type(() => TTextDM)
	SocialActivityTypeId_ver?: TTextDM

	/**
	 * Párovací symbol_ver
	 */
	@Type(() => TMatchingSymbolDM)
	MatchingSymbolId_ver?: TMatchingSymbolDM

	/**
	 * CZ-ISCO_ver
	 */
	@Type(() => ProfessionName)
	CZISCOId_ver?: ProfessionName

	/**
	 * Forma mzdy_ver
	 */
	@Type(() => TTextDM)
	WageTypeId_ver?: TTextDM

	/**
	 * Způsob srážky soc. zab._ver
	 */
	@Type(() => TTextDM)
	DeductionSocialSecurityId_ver?: TTextDM

	/**
	 * Právní vztah_ver
	 */
	@Type(() => RelWork)
	LegalSubrelation2Id_ver?: RelWork

	/**
	 * Pracovní pozice_ver
	 */
	@Type(() => TOrgStrTreeUnitDM)
	OrgStrTreeUnitRID_ver?: TOrgStrTreeUnitDM

	/**
	 * Způsob danění_ver
	 */
	@Type(() => TTextDM)
	TaxDeductionTypeId_ver?: TTextDM

	/**
	 * Kategorie 2_ver
	 */
	@Type(() => Kategorie2)
	Category2Id_ver?: Kategorie2

	/**
	 * Požadované vzdělání_ver
	 */
	@Type(() => DegreeName)
	RequiredEducationLevelId_ver?: DegreeName

	/**
	 * Srážka výplaty_ver
	 */
	@Type(() => TPrDeductionDM)
	WageDeductionId_ver?: TPrDeductionDM

	/**
	 * Třída_ver
	 */
	@Type(() => TTextDM)
	GradeIdCalc_ver?: TTextDM

	/**
	 * Stát_ver
	 */
	@Type(() => TCountryDM)
	WorkCountryId_ver?: TCountryDM

	/**
	 * Poplatník_ver
	 */
	@Type(() => TTextDM)
	TaxPayerTypeId_ver?: TTextDM

	/**
	 * Pracovní doba_ver
	 */
	@Type(() => TPrCalendarDM)
	WorkShiftCalendarId_ver?: TPrCalendarDM

	/**
	 * Kód 6_ver
	 */
	@Type(() => TCode6DocumentDM)
	Code6Id_ver?: TCode6DocumentDM

	/**
	 * Důvod_ver
	 */
	@Type(() => PrempReasons)
	DifficultEnvironmentRateReasonCalc_ver?: PrempReasons

	/**
	 * Kód zboží_ver
	 */
	@Type(() => TArticleCodeDocumentDM)
	ArticleCodeId_ver?: TArticleCodeDocumentDM

	/**
	 * Stupeň_ver
	 */
	@Type(() => TTextDM)
	LevelIdCalc_ver?: TTextDM

	/**
	 * Prac. právní vztah_ver
	 */
	@Type(() => TTextDM)
	LegalSubrelation1Id_ver?: TTextDM

	/**
	 * Časový fond_ver
	 */
	@Type(() => TPrCalendarDM)
	WorkFundCalendarId_ver?: TPrCalendarDM

	/**
	 * Účet Dal_ver
	 */
	@Type(() => TAccountingChartDM)
	CreditAccountId_ver?: TAccountingChartDM

	/**
	 * Další kalendář_ver
	 */
	@Type(() => TPrCalendarDM)
	AdditionalCalendarId_ver?: TPrCalendarDM

	/**
	 * Tarifní tabulka_ver
	 */
	@Type(() => TPrTariffDM)
	PayrollTariffRIDCalc_ver?: TPrTariffDM

	/**
	 * Středisko 2_ver
	 */
	@Type(() => TCostCentreCollectionDocumentDM)
	CostCentre2Id_ver?: TCostCentreCollectionDocumentDM

	/**
	 * Kód 3_ver
	 */
	@Type(() => TCode3DocumentDM)
	Code3Id_ver?: TCode3DocumentDM

	/**
	 * NUTS4_ver
	 */
	@Type(() => NUTS4)
	NUTS4Id_ver?: NUTS4

	/**
	 * Místo_ver
	 */
	@Type(() => TTownPartDM)
	WorkTownPartId_ver?: TTownPartDM

	/**
	 * Finanční úřad_ver
	 */
	@Type(() => TPrDeductionDM)
	TaxOfficeId_ver?: TPrDeductionDM

	/**
	 * Prostředek_ver
	 */
	@Type(() => TDeviceDocumentDM)
	DeviceRID_ver?: TDeviceDocumentDM

	/**
	 * LAU1_ver
	 */
	@Type(() => MzdLAU1)
	LAU1Id_ver?: MzdLAU1

	/**
	 * Kód 5_ver
	 */
	@Type(() => TCode5DocumentDM)
	Code5Id_ver?: TCode5DocumentDM

	/**
	 * Stanovená prac. doba_ver
	 */
	@Type(() => TPrCalendarDM)
	LegalCalendarId_ver?: TPrCalendarDM

	/**
	 * ZUJ_ver
	 */
	@Type(() => MzdZUJ)
	AdministrativeDivisionId_ver?: MzdZUJ

	/**
	 * CZ-ICSE_ver
	 */
	@Type(() => CZICSE)
	CZICSEId_ver?: CZICSE

	/**
	 * Účet zdr. poj._ver
	 */
	@Type(() => TAccountingChartDM)
	HealthInsuranceAccountId_ver?: TAccountingChartDM

	/**
	 * Kód zakázky_ver
	 */
	@Type(() => TContractCodeDocumentDM)
	ContractCodeRID_ver?: TContractCodeDocumentDM

	/**
	 * Doplň. důvod ukončení_ver
	 */
	@Type(() => DuvUkoncPP2)
	AditionalTerminationReasonId_ver?: DuvUkoncPP2

	/**
	 * Středisko_ver
	 */
	@Type(() => TCostCentreCollectionDocumentDM)
	CostCentre1Id_ver?: TCostCentreCollectionDocumentDM

	/**
	 * Skupina 2_ver
	 */
	@Type(() => Group2)
	Group2Id_ver?: Group2

	/**
	 * Středisko výplatní_ver
	 */
	@Type(() => TCostCentreCollectionDocumentDM)
	PayrollCentreId_ver?: TCostCentreCollectionDocumentDM

	/**
	 * Skupina_ver
	 */
	@Type(() => SkupPrac)
	GroupId_ver?: SkupPrac

	/**
	 * Kód 2_ver
	 */
	@Type(() => TCode2DocumentDM)
	Code2Id_ver?: TCode2DocumentDM

	/**
	 * Účet soc. zab._ver
	 */
	@Type(() => TAccountingChartDM)
	SocialSecurityAccountId_ver?: TAccountingChartDM

	/**
	 * Odborová organizace_ver
	 */
	@Type(() => TPrUnionDM)
	UnionId_ver?: TPrUnionDM

	/**
	 * Měna_ver
	 */
	@Type(() => TCurrencyDM)
	Currency_ver?: TCurrencyDM

	/**
	 * Stupeň řízení_ver
	 */
	@Type(() => TTextDM)
	ManagementLevelIdCalc_ver?: TTextDM

	/**
	 * Zaručená mzda_ver
	 */
	@Type(() => TPrMinimalTariffDM)
	GuaranteedWage_ver?: TPrMinimalTariffDM

	/**
	 * Důvod_ver
	 */
	@Type(() => PrempReasons)
	ManagementBonusReasonIdCalc_ver?: PrempReasons

	/**
	 * Pracovní místo_ver
	 */
	@Type(() => TOrgStrJobDM)
	OrgStrJobRID_ver?: TOrgStrJobDM

	/**
	 * Kniha docházky_ver
	 */
	@Type(() => TBookDM)
	AttendanceBookId_ver?: TBookDM

	/**
	 * CZ-NACE_ver
	 */
	@Type(() => CZNACE)
	CZNACEId_ver?: CZNACE

	/**
	 * Komentáře
	 */
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]

	/**
	 * undefined
	 */
	@Type(() => TPrConstantGroupEmploymentDM)
	PrConstantGroupEmploymentChild?: TPrConstantGroupEmploymentDM[]

	/**
	 * Trvalé mzdové složky
	 */
	@Type(() => TPrDefinitionComponentDM)
	PrComPermanentCompleteChild?: TPrDefinitionComponentDM[]

	/**
	 * Mzdové doklady
	 */
	@Type(() => TPrEmployeeDocumentDM)
	PrBookDocumentChild?: TPrEmployeeDocumentDM[]

	/**
	 * Pracovní praxe
	 */
	@Type(() => TPrEmployeeWorkExperienceAsItemDM)
	PrEmployeeWorkExperienceChild?: TPrEmployeeWorkExperienceAsItemDM[]

	/**
	 * Pracovní úkoly
	 */
	@Type(() => TPrEmployeeWorkTaskAsItemDM)
	PrEmployeeWorkTaskChild?: TPrEmployeeWorkTaskAsItemDM[]

	/**
	 * Dlouhodobé nepřítomnosti
	 */
	@Type(() => TPrLongTermAbsenceDM)
	PrLongTermAbsenceChild?: TPrLongTermAbsenceDM[]

	/**
	 * eNeschopenky
	 */
	@Type(() => TPrEmployeeSickNoteDM)
	PrEmployeeSickNoteChild?: TPrEmployeeSickNoteDM[]

	/**
	 * Obsazený pracovník
	 */
	@Type(() => TOrgStrEmployeeDM)
	OrgStrEmployeeChild?: TOrgStrEmployeeDM[]

	/**
	 * undefined
	 */
	@Type(() => TAttendanceScheduleDM)
	AttendanceScheduleChild?: TAttendanceScheduleDM[]

	/**
	 * undefined
	 */
	@Type(() => TOnePersonAbsenceDM)
	PersonAbsenceChild?: TOnePersonAbsenceDM[]

	/**
	 * undefined
	 */
	@Type(() => TAttendanceWorkTimeReportDM)
	AttendanceWorkTimeReportChild?: TAttendanceWorkTimeReportDM[]

	/**
	 * undefined
	 */
	@Type(() => TAttendanceMovementDM)
	AttendanceMovementChild?: TAttendanceMovementDM[]

	/**
	 * Osoby v elektronickém podání
	 */
	@Type(() => TElectronicSubmissionPersonDM)
	ElectronicSubmissionPersonChild?: TElectronicSubmissionPersonDM[]

	/**
	 * Dokumenty
	 */
	@Type(() => TExternalDocumentItemDM)
	ExternalDocumentItemChild?: TExternalDocumentItemDM[]

	/**
	 * Procesy
	 */
	@Type(() => TWorkflowProcessAsItemDM)
	WorkflowProcessChild?: TWorkflowProcessAsItemDM[]

	/**
	 * Text hlavičky
	 */
	@Type(() => TLanguageMemoTranslationDM)
	TextHeaderChild?: TLanguageMemoTranslationDM[]

	/**
	 * Text patičky
	 */
	@Type(() => TLanguageMemoTranslationDM)
	TextFooterChild?: TLanguageMemoTranslationDM[]

	/**
	 * Svázané doklady
	 */
	@Type(() => TAttachedDocumentDM)
	AttachedDocumentOldChild?: TAttachedDocumentDM[]

	/**
	 * Poznámky
	 */
	@Type(() => TOldNoteDM)
	DeprecatedNoteOldChild?: TOldNoteDM[]

	/**
	 * Vytvořit mzdový doklad
	 */
	public BulkCreatePrEmpDocCOMMAND(parameters: TBulkCreatePrEmpDocCOMMANDParams) {
		return this.executeCommand('BulkCreatePrEmpDocCOMMAND', parameters)
	}

	/**
	 * Kopie trvalých položek do jiné knihy mzdových dokladů
	 */
	public BulkCreatePrComCCOMMAND(parameters: TBulkCreatePrComCCOMMANDParams) {
		return this.executeCommand('BulkCreatePrComCCOMMAND', parameters)
	}

	/**
	 * Smazat záznam
	 */
	public DeletePrEmploymentCOMMAND() {
		return this.executeCommand('DeletePrEmploymentCOMMAND')
	}

	/**
	 * Postoupení do dalšího stupně
	 */
	public BulkPremploymentLevelUpCOMMAND(parameters: TBulkPremploymentLevelUpCOMMANDParams) {
		return this.executeCommand('BulkPremploymentLevelUpCOMMAND', parameters)
	}

	/**
	 * Výpočet datumu konce zkušební doby
	 */
	public ComputeTestTimeCOMMAND() {
		return this.executeCommand('ComputeTestTimeCOMMAND')
	}

	/**
	 * Výpočet datumu konce zkušební doby
	 */
	public BulkComputeTestTimeCOMMAND() {
		return this.executeCommand('BulkComputeTestTimeCOMMAND')
	}

	/**
	 * Zneplatnění
	 */
	public BulkInvalidationCOMMAND() {
		return this.executeCommand('BulkInvalidationCOMMAND')
	}

	/**
	 * Zplatnění
	 */
	public BulkUnInvalidationCOMMAND() {
		return this.executeCommand('BulkUnInvalidationCOMMAND')
	}

	/**
	 * Zneplatnění/Zplatnění
	 */
	public InvalidationCOMMAND() {
		return this.executeCommand('InvalidationCOMMAND')
	}

	/**
	 * Potvrzení
	 */
	public BulkConfirmCOMMAND() {
		return this.executeCommand('BulkConfirmCOMMAND')
	}

	/**
	 * Odpotvrzení
	 */
	public BulkUnconfirmCOMMAND() {
		return this.executeCommand('BulkUnconfirmCOMMAND')
	}

	/**
	 * Změna data potvrzení
	 */
	public BulkChangeConfirmationDateCOMMAND(parameters: TBulkChangeConfirmationDateCOMMANDParams) {
		return this.executeCommand('BulkChangeConfirmationDateCOMMAND', parameters)
	}

	/**
	 * Storno
	 */
	public BulkCancelCOMMAND() {
		return this.executeCommand('BulkCancelCOMMAND')
	}

	/**
	 * Odstorno
	 */
	public BulkUnCancelCOMMAND() {
		return this.executeCommand('BulkUnCancelCOMMAND')
	}

	/**
	 * Potvrzení
	 */
	public ConfirmCOMMAND() {
		return this.executeCommand('ConfirmCOMMAND')
	}

	/**
	 * Odpotvrzení
	 */
	public UnconfirmCOMMAND() {
		return this.executeCommand('UnconfirmCOMMAND')
	}

	/**
	 * Změna data potvrzení
	 */
	public ChangeConfirmationDateCOMMAND(parameters: TChangeConfirmationDateCOMMANDParams) {
		return this.executeCommand('ChangeConfirmationDateCOMMAND', parameters)
	}

	/**
	 * Storno/Odstorno
	 */
	public CancelCOMMAND() {
		return this.executeCommand('CancelCOMMAND')
	}

	/**
	 * Zrušení stavu
	 */
	public ClearRecordStatusCOMMAND() {
		return this.executeCommand('ClearRecordStatusCOMMAND')
	}

	/**
	 * Změna stavu
	 */
	public ChangeRecordStatusCOMMAND() {
		return this.executeCommand('ChangeRecordStatusCOMMAND')
	}

	/**
	 * 
	 */
	public ChangeRecordStatusSWSCOMMAND(parameters: TChangeRecordStatusSWSCOMMANDParams) {
		return this.executeCommand('ChangeRecordStatusSWSCOMMAND', parameters)
	}

	/**
	 * Zrušení stavu
	 */
	public BulkClearRecordStatusCOMMAND() {
		return this.executeCommand('BulkClearRecordStatusCOMMAND')
	}

	/**
	 * Změna stavu
	 */
	public BulkChangeRecordStatusCOMMAND() {
		return this.executeCommand('BulkChangeRecordStatusCOMMAND')
	}

	/**
	 * Změna atributů
	 */
	public BulkChangeAttributesCOMMAND(parameters: TBulkChangeAttributesCOMMANDParams) {
		return this.executeCommand('BulkChangeAttributesCOMMAND', parameters)
	}

	/**
	 * Aktualizace sady atributů
	 */
	public BulkActualizeAttributesCOMMAND(parameters: TBulkActualizeAttributesCOMMANDParams) {
		return this.executeCommand('BulkActualizeAttributesCOMMAND', parameters)
	}

	/**
	 * Aktualizace sady atributů
	 */
	public ActualizeAttributesCOMMAND() {
		return this.executeCommand('ActualizeAttributesCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public ChangeGroupRightCOMMAND() {
		return this.executeCommand('ChangeGroupRightCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public BulkChangeGroupRightCOMMAND() {
		return this.executeCommand('BulkChangeGroupRightCOMMAND')
	}

	/**
	 * Aktualizace počítaných polí
	 */
	public BulkUpdateCalcFieldsCOMMAND() {
		return this.executeCommand('BulkUpdateCalcFieldsCOMMAND')
	}

	/**
	 * Kontrola legislativy
	 */
	public SwitchOffLegislationValidationCOMMAND() {
		return this.executeCommand('SwitchOffLegislationValidationCOMMAND')
	}
}

export type TBulkCreatePrEmpDocCOMMANDParams = {
	/**
	 * Mzdové období od
	 */
	IdPeriodFrom?: number
	/**
	 * Mzdové období do
	 */
	IdPeriodTo?: number
	/**
	 * Kniha mzdových dokladů
	 */
	PayrollDocumentsBook?: number
	/**
	 * Povolit vytvoření dokladů i do jiné knihy
	 */
	AllowOtherBook?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TBulkCreatePrComCCOMMANDParams = {
	/**
	 * Kniha mzdových dokladů do
	 */
	PayrollDocumentsBookTo?: number
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TBulkPremploymentLevelUpCOMMANDParams = {
	/**
	 * Datum od
	 */
	DateFrom?: Date
	/**
	 * Datum do
	 */
	DateTo?: Date
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TBulkChangeConfirmationDateCOMMANDParams = {
	/**
	 * Datum potvrzení
	 */
	Confirmation1Date?: Date
	/**
	 * Hodina potvrzení
	 */
	Confirmation1Time?: Date
	/**
	 * Datum 2. potvrzení
	 */
	Confirmation2Date?: Date
	/**
	 * Hodina 2. potvrzení
	 */
	Confirmation2Time?: Date
	/**
	 * Kontrolovat záporné stavy
	 */
	CheckNegativeState?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TChangeConfirmationDateCOMMANDParams = {
	/**
	 * Datum potvrzení
	 */
	Confirmation1Date?: Date
	/**
	 * Hodina potvrzení
	 */
	Confirmation1Time?: Date
	/**
	 * Datum 2. potvrzení
	 */
	Confirmation2Date?: Date
	/**
	 * Hodina 2. potvrzení
	 */
	Confirmation2Time?: Date
	/**
	 * Kontrolovat záporné stavy
	 */
	CheckNegativeState?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TChangeRecordStatusSWSCOMMANDParams = {
	/**
	 * StatusId
	 */
	StatusId?: number
}

export type TBulkChangeAttributesCOMMANDParams = {
	/**
	 * Smazat atributy
	 */
	DeleteAttribute?: boolean
	/**
	 * Smazat všechny volné atributy
	 */
	DeleteFreeAttribute?: boolean
	/**
	 * Nastavit hodnoty atributů
	 */
	SetValues?: boolean
	/**
	 * Přidat nepřipojené atributy
	 */
	AddFreeAttributes?: boolean
	/**
	 * FreeAttributeEnabled
	 */
	FreeAttributeEnabled?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TBulkActualizeAttributesCOMMANDParams = {
	/**
	 * Aktualizovat včetně samotné sady atributů
	 */
	ActualizeAttributeSet?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}