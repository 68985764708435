import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TAttendanceWorkShiftTypeDM from './TAttendanceWorkShiftTypeDM'
import TTextDM from './TTextDM'
import PrCalTypDay from './PrCalTypDay'
import PrCalTypHours from './PrCalTypHours'

/**
 * Položky - dny
 */
export default class TCodeCalendarItemDayDM extends Data {
	public static className = 'TCodeCalendarItemDayDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = false

	/**
	 * Datum směny
	 */
	WorkshiftDate?: Date

	/**
	 * Směna
	 */
	WorkshiftRID?: number

	/**
	 * Interval
	 */
	IntervalRID?: number

	/**
	 * RID definice
	 */
	DefinitionRID?: number

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * Čas přerušení
	 */
	BreakTIme?: number

	/**
	 * Celkový čas
	 */
	TotalTime?: number

	/**
	 * Datum do
	 */
	DateTo?: Date

	/**
	 * Datum od
	 */
	DateFrom?: Date

	/**
	 * S.hodn.3
	 */
	StringValue3?: string

	/**
	 * S.hodn.2
	 */
	StringValue2?: string

	/**
	 * Č.hodn.L
	 */
	LongValue?: number

	/**
	 * Čas celk.
	 */
	TimeTotal?: number

	/**
	 * Č.hodn.N
	 */
	ShortValue?: number

	/**
	 * Popis
	 */
	Description?: string

	/**
	 * Čas do
	 */
	TimeTo?: number

	/**
	 * Čas od
	 */
	TimeFrom?: number

	/**
	 * Datum
	 */
	Date?: Date

	/**
	 * Kalendář
	 */
	CodeCalendarId?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Akceptovat změny v definici
	 */
	AcceptChangeInDefBitCalc?: number

	/**
	 * Čas od
	 */
	TimeFromCalc?: Date

	/**
	 * Čas do
	 */
	TimeToCalc?: Date

	/**
	 * Typ směny modelu pracovní doby
	 */
	@Type(() => TAttendanceWorkShiftTypeDM)
	AttendanceWorkShiftTypeRID?: TAttendanceWorkShiftTypeDM

	/**
	 * Typ směny
	 */
	@Type(() => TTextDM)
	WorkShiftTypeId?: TTextDM

	/**
	 * Typ položky
	 */
	@Type(() => TTextDM)
	ItemTypeId?: TTextDM

	/**
	 * Priorita
	 */
	@Type(() => TTextDM)
	PriorityId?: TTextDM

	/**
	 * Typ dne
	 */
	@Type(() => PrCalTypDay)
	DayTypeId?: PrCalTypDay

	/**
	 * Typ č.ús.
	 */
	@Type(() => PrCalTypHours)
	CalendarRecordTypeId?: PrCalTypHours

	/**
	 * Typ zdroje
	 */
	@Type(() => TTextDM)
	SourceTypeIdCalc?: TTextDM

	/**
	 * Den v týdnu
	 */
	@Type(() => TTextDM)
	DayInWeekCalc?: TTextDM
}