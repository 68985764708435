import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TK2UserLookupDM from './TK2UserLookupDM'
import TParameterSetItemDM from './TParameterSetItemDM'
import TParameterSetFormulaDM from './TParameterSetFormulaDM'
import TBatchTypeItemDM from './TBatchTypeItemDM'

/**
 * Zařazení
 */
export default class TParameterSetDM extends Data {
	public static className = 'TParameterSetDM'
	public static primaryKey = 'ParameterSetId'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.ParameterSetId!
	}

	public static isHeader = true

	/**
	 * Časové razítko
	 */
	TimeStamp?: string

	/**
	 * Typ
	 */
	TypeId?: number

	/**
	 * Změněno
	 */
	ChangedOn?: Date

	/**
	 * Popis
	 */
	Description?: string

	/**
	 * Název
	 */
	Name?: string

	/**
	 * ID zař.
	 */
	ParameterSetId?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Blokováno
	 */
	BlockedRecordBitCalc?: number

	/**
	 * Aktuální typ šarže
	 */
	ActualBatchTypeIdCalc?: number

	/**
	 * Podpora VRM
	 */
	VRMEnabledBitCalc?: number

	/**
	 * Vzorec pro koef.
	 */
	VRMCoefficientFormSetIdCalc?: number

	/**
	 * Změnil
	 */
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM

	/**
	 * Parametry zařazení
	 */
	@Type(() => TParameterSetItemDM)
	ParameterSetItemChild?: TParameterSetItemDM[]

	/**
	 * Vzorce pro vícerozměrné množství
	 */
	@Type(() => TParameterSetFormulaDM)
	FormulaChild?: TParameterSetFormulaDM[]

	/**
	 * Parametry typu šarže
	 */
	@Type(() => TBatchTypeItemDM)
	BatchTypeItemChild?: TBatchTypeItemDM[]

	/**
	 * Výběr typu
	 */
	public TypeIdChangeCOMMAND(parameters: TTypeIdChangeCOMMANDParams) {
		return this.executeCommand('TypeIdChangeCOMMAND', parameters)
	}

	/**
	 * Změna skupiny práv
	 */
	public ChangeGroupRightCOMMAND() {
		return this.executeCommand('ChangeGroupRightCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public BulkChangeGroupRightCOMMAND() {
		return this.executeCommand('BulkChangeGroupRightCOMMAND')
	}

	/**
	 * Aktualizace počítaných polí
	 */
	public BulkUpdateCalcFieldsCOMMAND() {
		return this.executeCommand('BulkUpdateCalcFieldsCOMMAND')
	}

	/**
	 * Kontrola legislativy
	 */
	public SwitchOffLegislationValidationCOMMAND() {
		return this.executeCommand('SwitchOffLegislationValidationCOMMAND')
	}
}

export type TTypeIdChangeCOMMANDParams = {
	/**
	 * Typ
	 */
	TypeId?: number
	/**
	 * MessageText
	 */
	MessageText?: string
}