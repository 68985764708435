import Data from 'react-k2-api/Api/Data'
import { Type } from 'class-transformer'
import TRegisteredDataModuleDM from './TRegisteredDataModuleDM'
import TTextDM from './TTextDM'

/**
 * Deník
 */
export default class TPartnerJournalDM extends Data {
	public static className = 'TPartnerJournalDM'
	public static systemFields = [
		'RecordID',
	]

	public static isHeader = false

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * undefined
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * Rozhodné datum
	 */
	DecisiveDate?: Date

	/**
	 * Číslo položky
	 */
	ItemNumber?: number

	/**
	 * Položka
	 */
	ChildType?: number

	/**
	 * Číslo hlavičky
	 */
	HeaderRID?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Záznam
	 */
	IdentificationCalc?: string

	/**
	 * Popis
	 */
	DescriptionCalc?: string

	/**
	 * Částka
	 */
	AmountCalc?: number

	/**
	 * Právo
	 */
	RecordPermissionCalc?: string

	/**
	 * Modul
	 */
	@Type(() => TRegisteredDataModuleDM)
	HeaderTypeId?: TRegisteredDataModuleDM

	/**
	 * P/S
	 */
	@Type(() => TTextDM)
	ConfirmedOrCanceledIdCalc?: TTextDM
}