import Data from 'react-k2-api/Api/Data'

/**
 * Použití role v uložených objektech
 */
export default class TUserRoleObjectRowDM extends Data {
	public static className = 'TUserRoleObjectRowDM'
	public static systemFields = [
		'TimeStamp',
		'RecordID',
	]

	public static isHeader = false

	/**
	 * Časové razítko
	 */
	TimeStamp?: string

	/**
	 * Options
	 */
	Flags?: number

	/**
	 * Mandant
	 */
	Client?: string

	/**
	 * Uživatel
	 */
	UserId?: number

	/**
	 * Level
	 */
	Level?: number

	/**
	 * ObjectID
	 */
	ObjectId?: string

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * undefined
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Identifikátor objektu
	 */
	ObjectStorageIdCalc?: string
}