import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TExternalBatchDM from './TExternalBatchDM'
import TScheduledServiceOperationDM from './TScheduledServiceOperationDM'
import TServicedDeviceDM from './TServicedDeviceDM'
import TCode6DocumentDM from './TCode6DocumentDM'
import TCode5DocumentDM from './TCode5DocumentDM'
import TCode4DocumentDM from './TCode4DocumentDM'
import TCode3DocumentDM from './TCode3DocumentDM'
import TAccountingJournalDM from './TAccountingJournalDM'
import TProductionOrderDM from './TProductionOrderDM'
import TPeriodDM from './TPeriodDM'
import TBookDM from './TBookDM'
import TArticleCodeDocumentDM from './TArticleCodeDocumentDM'
import TCostCentreCollectionDocumentDM from './TCostCentreCollectionDocumentDM'
import TDeviceDocumentDM from './TDeviceDocumentDM'
import THandlingUnitArticleDM from './THandlingUnitArticleDM'
import TContractCodeDocumentDM from './TContractCodeDocumentDM'
import DocStatus from './DocStatus'
import Workshift from './Workshift'
import TTextDM from './TTextDM'
import PlanType from './PlanType'
import TRequestedBatchParameterHeaderDM from './TRequestedBatchParameterHeaderDM'
import TCalculationModelDM from './TCalculationModelDM'
import Priority from './Priority'
import TCode2DocumentDM from './TCode2DocumentDM'
import TCode1DocumentDM from './TCode1DocumentDM'
import TRoutingVariantDM from './TRoutingVariantDM'
import TTradingPartnerDM from './TTradingPartnerDM'
import TOfficerDocumentDM from './TOfficerDocumentDM'
import GoodsName from './GoodsName'
import TLocationArticleDM from './TLocationArticleDM'
import TMatchingSymbolDM from './TMatchingSymbolDM'
import TBatchDM from './TBatchDM'
import TMeasureUnitDM from './TMeasureUnitDM'
import TK2UserLookupDM from './TK2UserLookupDM'
import TArticleDM from './TArticleDM'
import TWarehouseDM from './TWarehouseDM'
import TSalesOrderDM from './TSalesOrderDM'
import TSerialNumberItemDM from './TSerialNumberItemDM'
import TParameterSetDM from './TParameterSetDM'
import TSalesItemBookDM from './TSalesItemBookDM'
import TDeliveryPlanDM from './TDeliveryPlanDM'
import TDeliveryOrderDM from './TDeliveryOrderDM'
import TDeliveryPlanItemBookDM from './TDeliveryPlanItemBookDM'
import TMarketingActivityTypeDM from './TMarketingActivityTypeDM'
import TWorkflowProcessDM from './TWorkflowProcessDM'
import TActivityDM from './TActivityDM'
import TCommentDM from './TCommentDM'
import TJobDM from './TJobDM'
import TTransferNoteDM from './TTransferNoteDM'
import TWarehouseTransferDM from './TWarehouseTransferDM'
import TJobCardScheduleDM from './TJobCardScheduleDM'
import TJobCardItemDM from './TJobCardItemDM'
import TJobCardOperationDM from './TJobCardOperationDM'
import TJobCardResourceDM from './TJobCardResourceDM'
import TJobCardBindDM from './TJobCardBindDM'
import TJobCardSubordinateDocumentDM from './TJobCardSubordinateDocumentDM'
import TAccountingFinanceJobCardItemDM from './TAccountingFinanceJobCardItemDM'
import TAttachedRequestedBatchParameterDM from './TAttachedRequestedBatchParameterDM'
import TAttachedBatchParameterValueDM from './TAttachedBatchParameterValueDM'
import TProductCostingDM from './TProductCostingDM'
import TJobCardVariantScheduleDM from './TJobCardVariantScheduleDM'
import TExternalDocumentItemDM from './TExternalDocumentItemDM'
import TWorkflowProcessAsItemDM from './TWorkflowProcessAsItemDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TDocumentAccountingJournalItemDM from './TDocumentAccountingJournalItemDM'
import TAttachedDocumentDM from './TAttachedDocumentDM'
import TOldNoteDM from './TOldNoteDM'

/**
 * Průvodky
 */
export default class TJobCardDM extends Data {
	public static className = 'TJobCardDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = true

	/**
	 * Firma
	 */
	BookId_CompanyId?: number

	/**
	 * Skup. právo
	 */
	BookId_RightGroupId?: number

	/**
	 * Určeno k provázání
	 */
	IntendedForBinding?: string

	/**
	 * Neevidovat skutečné množství
	 */
	NotRecordActualQuantity?: string

	/**
	 * Storno
	 */
	IsCanceledRecord?: string

	/**
	 * Zaplanováno bez pokrytí
	 */
	IsScheduledWithoutCoverage?: string

	/**
	 * Odpotvrzen bez evidence
	 */
	IsUnconfirmedWithoutStockRec?: string

	/**
	 * Likvidace nul. částek
	 */
	PostZeroAmount?: string

	/**
	 * Následující dávka
	 */
	IsFollowUpLot?: string

	/**
	 * Ignoruj ceny příjmu
	 */
	IgnoreReceiptPrice?: string

	/**
	 * Vydáno
	 */
	IsAllItemsPreparedForRelease?: string

	/**
	 * Výdej
	 */
	IsRelease?: string

	/**
	 * Potvrzena skupina
	 */
	IsSomeOperationConfirmed?: string

	/**
	 * Korektní zaúčtování
	 */
	IsCorrectPosting?: string

	/**
	 * Korektní likvidace
	 */
	IsCorrectAssignment?: string

	/**
	 * Pohyb položek
	 */
	IsAllItemsReleased?: string

	/**
	 * SN neúplná
	 */
	IsIncompleteSerialNumbers?: string

	/**
	 * Rezervováno
	 */
	IsAllReserved?: string

	/**
	 * Rezervace
	 */
	IsReservation?: string

	/**
	 * Neúplný TP
	 */
	IsIncompleteRouting?: string

	/**
	 * Výrobek
	 */
	IsProduct?: string

	/**
	 * Koř. prův. výr. přík.
	 */
	IsRootToPO?: string

	/**
	 * Číslo
	 */
	Number?: number

	/**
	 * Plán. datum do - posl.
	 */
	LastPlannedDateTo?: Date

	/**
	 * Plán. datum od - posl.
	 */
	LastPlannedDateFrom?: Date

	/**
	 * IsQtyRemTime
	 */
	IsTimeByQuantity?: string

	/**
	 * Realizace
	 */
	IsExecution?: string

	/**
	 * Fixace
	 */
	IsFixated?: string

	/**
	 * Rozvrženo
	 */
	IsManuallyAdjustedSchedule?: string

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * Časové razítko
	 */
	TimeStamp?: string

	/**
	 * Množství TP
	 */
	RoutingQuantity?: number

	/**
	 * Skut. datum do
	 */
	ActualDateTo?: Date

	/**
	 * Skut. datum od
	 */
	ActualDateFrom?: Date

	/**
	 * Nákl. cena / MJ
	 */
	UnitCostPrice?: number

	/**
	 * Plán. pořadí
	 */
	SchedulingSequenceNumber?: number

	/**
	 * Výkres
	 */
	Drawing?: string

	/**
	 * Zvýš. množství
	 */
	IncreasedQuantity?: number

	/**
	 * Plán. cena / MJ
	 */
	PlannedUnitPrice?: number

	/**
	 * Skut. cena / MJ
	 */
	UnitPrice?: number

	/**
	 * Kontace
	 */
	PostingKeyId?: number

	/**
	 * Plán. datum do
	 */
	PlannedProductionDateTo?: Date

	/**
	 * Plán. datum od
	 */
	PlannedProductionDateFrom?: Date

	/**
	 * Vytvořeno
	 */
	CreatedOn?: Date

	/**
	 * Skut. množství
	 */
	Quantity?: number

	/**
	 * Plán. množství
	 */
	PlannedQuantity?: number

	/**
	 * Právo na záznam
	 */
	RightGroupId?: number

	/**
	 * Počet likvid. pol.
	 */
	AccountingItemsCount?: number

	/**
	 * Počet pol.
	 */
	ItemCount?: number

	/**
	 * Likvidoval
	 */
	PostedById?: number

	/**
	 * Datum likvidace
	 */
	PostedOn?: Date

	/**
	 * Změněno
	 */
	ChangedOn?: Date

	/**
	 * Čas potvrzení
	 */
	ConfirmationTime?: Date

	/**
	 * Datum potvrzení
	 */
	ConfirmedOn?: Date

	/**
	 * Popis
	 */
	Description?: string

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	BookmarkImgCalc?: string

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Číslo
	 */
	DocumentNumberCalc?: number

	/**
	 * SQLSequenceOrderCalc
	 */
	SQLSequenceOrderCalc?: number

	/**
	 * Datum pro "_ver" pole
	 */
	VersionedFieldDateCalc?: Date

	/**
	 * V
	 */
	SNReceiptReleaseFilterImgCalc?: string

	/**
	 * Fi_C_Zbo
	 */
	FilterArticleIdCalc?: number

	/**
	 * Fi_Zap
	 */
	IsFilterOnCalc?: string

	/**
	 * Fi_ZapSar
	 */
	FilterBatchOnBitCalc?: number

	/**
	 * P
	 */
	ConfirmedImgCalc?: number

	/**
	 * Množ.plán.
	 */
	PlannedQuantityCalc?: number

	/**
	 * Množ.skut.
	 */
	ActualQuantityUMCalc?: number

	/**
	 * Cena celk. sk.
	 */
	ActualTotalPriceCalc?: number

	/**
	 * Dnů pl.
	 */
	PlannedProductionDaysCalc?: number

	/**
	 * Dnů sk.
	 */
	ActualProductionDaysCalc?: number

	/**
	 * Množ.zvýš.
	 */
	IncreasedQuantityUMCalc?: number

	/**
	 * Cena celk. pl.
	 */
	PlannedTotalPriceCalc?: number

	/**
	 * Skut.množ.-r.č.
	 */
	ActualQuantityRNCalc?: number

	/**
	 * Popis
	 */
	DescriptionCalc?: string

	/**
	 * Cena sk.pol.
	 */
	ActualItemTotalPriceCalc?: number

	/**
	 * Cena pl.pol.
	 */
	PlannedItemTotalPriceCalc?: number

	/**
	 * Cena celk. nákl.
	 */
	TotalCostPriceCalc?: number

	/**
	 * Pl. datum od
	 */
	PlannedProductionDateFromCalc?: Date

	/**
	 * Pl. čas od
	 */
	PlannedTimeFromCalc?: Date

	/**
	 * Pl. datum do
	 */
	PlannedProductionDateToCalc?: Date

	/**
	 * Pl. čas do
	 */
	PlannedTimeToCalc?: Date

	/**
	 * BatchTypeIdCalc
	 */
	BatchTypeIdCalc?: number

	/**
	 * Kořenová průvodka
	 */
	RootJobCardRIDCalc?: number

	/**
	 * Filtr pol. zap.
	 */
	FilterItemsBitCalc?: number

	/**
	 * undefined
	 */
	SerialNumberProductFilterCalc?: string

	/**
	 * BatchRatioCalc
	 */
	BatchRatioCalc?: number

	/**
	 * BlTestPlannedActualDateCalc
	 */
	BlTestPlannedActualDateCalc?: Date

	/**
	 * BlTestActualDateCalc
	 */
	BlTestActualDateCalc?: Date

	/**
	 * BlTestPlannedConfirmedDateCalc
	 */
	BlTestPlannedConfirmedDateCalc?: Date

	/**
	 * BlTestActualConfirmedDateCalc
	 */
	BlTestActualConfirmedDateCalc?: Date

	/**
	 * Plán. čas
	 */
	PlannedTimeCalc?: number

	/**
	 * Skut. čas
	 */
	ActualDurationCalc?: number

	/**
	 * Strávený čas (%)
	 */
	SpentTimePercentageCalc?: number

	/**
	 * Plán.hodn.
	 */
	PlannedValueCalc?: number

	/**
	 * Skut.hodn
	 */
	ActualValueCalc?: number

	/**
	 * Plnění (%)
	 */
	PercentageCompleteCalc?: number

	/**
	 * Vyrobeno
	 */
	ProducedQuantityCalc?: number

	/**
	 * Časové razítko vykrytí
	 */
	CoverageTimeStampCalc?: Date

	/**
	 * Zbývající množství
	 */
	RemainingQuantityCalc?: number

	/**
	 * undefined
	 */
	ShowAllItemCalc?: string

	/**
	 * OperationTextCalc
	 */
	OperationTextCalc?: string

	/**
	 * Termín od
	 */
	PlannedProductionDateFromParamCalc?: Date

	/**
	 * Termín do
	 */
	PlannedProductionDateToParamCalc?: Date

	/**
	 * undefined
	 */
	DeliveryOrderItemRIDCalc?: number

	/**
	 * undefined
	 */
	DeliveryOrderTimeCalc?: Date

	/**
	 * undefined
	 */
	TextHeaderMemoCalc?: string

	/**
	 * undefined
	 */
	TextFooterMemoCalc?: string

	/**
	 * Poslední komentář
	 */
	LastCommentParamCalc?: string

	/**
	 * Částka dokladu
	 */
	DocumentAmountCalc?: number

	/**
	 * Saldo
	 */
	AccountBalanceCalc?: number

	/**
	 * Účet
	 */
	DocumentAccountCalc?: string

	/**
	 * Z
	 */
	PostedCalc?: string

	/**
	 * Datum zaúčtování
	 */
	PostedOnCalc?: Date

	/**
	 * Rozdíl v zaúčtování
	 */
	AccountingDocDifferenceCalc?: number

	/**
	 * undefined
	 */
	OwnAddressIdCalc?: number

	/**
	 * undefined
	 */
	OwnAddressNameCalc?: string

	/**
	 * Doklad
	 */
	DocumentIdentificationCalc?: string

	/**
	 * ZR
	 */
	PostedManuallyCalc?: string

	/**
	 * Korektní zaúčtování
	 */
	CorrectPostingBitCalc?: string

	/**
	 * EAN kód
	 */
	BarcodeCalc?: string

	/**
	 * Likvidace nul. částek
	 */
	PostZeroAmountBitCalc?: string

	/**
	 * undefined
	 */
	IsReversedExchangeRateViewCalc?: string

	/**
	 * undefined
	 */
	AccAssignmentPostingViewCalc?: string

	/**
	 * Právo na záznam
	 */
	RightGroupIdCalc?: number

	/**
	 * Externí šarže
	 */
	@Type(() => TExternalBatchDM)
	ExternalBatchId?: TExternalBatchDM

	/**
	 * Servisní úkon
	 */
	@Type(() => TScheduledServiceOperationDM)
	ServiceOperationRID?: TScheduledServiceOperationDM

	/**
	 * Servisované zařízení
	 */
	@Type(() => TServicedDeviceDM)
	ServicedDeviceRID?: TServicedDeviceDM

	/**
	 * Kód 6
	 */
	@Type(() => TCode6DocumentDM)
	Code6Id?: TCode6DocumentDM

	/**
	 * Kód 5
	 */
	@Type(() => TCode5DocumentDM)
	Code5Id?: TCode5DocumentDM

	/**
	 * Kód 4
	 */
	@Type(() => TCode4DocumentDM)
	Code4Id?: TCode4DocumentDM

	/**
	 * Kód 3
	 */
	@Type(() => TCode3DocumentDM)
	Code3Id?: TCode3DocumentDM

	/**
	 * Účetní doklad
	 */
	@Type(() => TAccountingJournalDM)
	AccountingDocumentRID?: TAccountingJournalDM

	/**
	 * Výrobní příkaz
	 */
	@Type(() => TProductionOrderDM)
	ProductionOrderRID?: TProductionOrderDM

	/**
	 * Období
	 */
	@Type(() => TPeriodDM)
	BusinessYearId?: TPeriodDM

	/**
	 * Kniha
	 */
	@Type(() => TBookDM)
	BookId?: TBookDM

	/**
	 * Kód zboží
	 */
	@Type(() => TArticleCodeDocumentDM)
	ArticleCodeId?: TArticleCodeDocumentDM

	/**
	 * Středisko
	 */
	@Type(() => TCostCentreCollectionDocumentDM)
	CostCentreId?: TCostCentreCollectionDocumentDM

	/**
	 * Prostředek
	 */
	@Type(() => TDeviceDocumentDM)
	DeviceRID?: TDeviceDocumentDM

	/**
	 * Manipul. j.
	 */
	@Type(() => THandlingUnitArticleDM)
	HandlingUnitRID?: THandlingUnitArticleDM

	/**
	 * Kód zakázky
	 */
	@Type(() => TContractCodeDocumentDM)
	ContractCodeRID?: TContractCodeDocumentDM

	/**
	 * Stav
	 */
	@Type(() => DocStatus)
	StatusId?: DocStatus

	/**
	 * Směna
	 */
	@Type(() => Workshift)
	WorkshiftId?: Workshift

	/**
	 * Typ průvodky
	 */
	@Type(() => TTextDM)
	JobCardTypeId?: TTextDM

	/**
	 * Typ plánu
	 */
	@Type(() => PlanType)
	PlanTypeId?: PlanType

	/**
	 * Pož. vlastnosti
	 */
	@Type(() => TRequestedBatchParameterHeaderDM)
	RequestedBatchParametersId?: TRequestedBatchParameterHeaderDM

	/**
	 * KV nákladový
	 */
	@Type(() => TCalculationModelDM)
	CostPriceCalculationModelId?: TCalculationModelDM

	/**
	 * KV skladový
	 */
	@Type(() => TCalculationModelDM)
	StockPriceCalculationModelId?: TCalculationModelDM

	/**
	 * KV plánovaný
	 */
	@Type(() => TCalculationModelDM)
	PlannedPriceCalculationModelId?: TCalculationModelDM

	/**
	 * Priorita
	 */
	@Type(() => Priority)
	PriorityId?: Priority

	/**
	 * Kód 2
	 */
	@Type(() => TCode2DocumentDM)
	Code2Id?: TCode2DocumentDM

	/**
	 * Kód 1
	 */
	@Type(() => TCode1DocumentDM)
	Code1Id?: TCode1DocumentDM

	/**
	 * Varianta
	 */
	@Type(() => TRoutingVariantDM)
	RoutingVariantId?: TRoutingVariantDM

	/**
	 * Kooperant
	 */
	@Type(() => TTradingPartnerDM)
	CooperatorId?: TTradingPartnerDM

	/**
	 * Provedl
	 */
	@Type(() => TOfficerDocumentDM)
	WorkerId?: TOfficerDocumentDM

	/**
	 * Název
	 */
	@Type(() => GoodsName)
	ArticleNameId?: GoodsName

	/**
	 * Číslo umístění
	 */
	@Type(() => TLocationArticleDM)
	LocationId?: TLocationArticleDM

	/**
	 * Pár.symbol
	 */
	@Type(() => TMatchingSymbolDM)
	MatchingSymbolId?: TMatchingSymbolDM

	/**
	 * Číslo šarže
	 */
	@Type(() => TBatchDM)
	BatchId?: TBatchDM

	/**
	 * Skladová jednotka
	 */
	@Type(() => TMeasureUnitDM)
	MeasureUnitId?: TMeasureUnitDM

	/**
	 * Potvrdil
	 */
	@Type(() => TK2UserLookupDM)
	ConfirmedById?: TK2UserLookupDM

	/**
	 * Vytvořil
	 */
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM

	/**
	 * Změnil
	 */
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM

	/**
	 * Číslo zboží
	 */
	@Type(() => TArticleDM)
	ArticleId?: TArticleDM

	/**
	 * Číslo skladu
	 */
	@Type(() => TWarehouseDM)
	WarehouseId?: TWarehouseDM

	/**
	 * Období
	 */
	@Type(() => TPeriodDM)
	BusinessYearIdCalc?: TPeriodDM

	/**
	 * CFFi_PlMi
	 */
	@Type(() => TTextDM)
	SNRecRelFilterStateCalc?: TTextDM

	/**
	 * Fi_Zaruky
	 */
	@Type(() => TBatchDM)
	FilterBatchIdCalc?: TBatchDM

	/**
	 * R
	 */
	@Type(() => TTextDM)
	ReservedBitCalc?: TTextDM

	/**
	 * V
	 */
	@Type(() => TTextDM)
	ReleasedBitCalc?: TTextDM

	/**
	 * Číslo Z
	 */
	@Type(() => TSalesOrderDM)
	SalesOrderRIDCalc?: TSalesOrderDM

	/**
	 * O
	 */
	@Type(() => TTextDM)
	OrderedBitCalc?: TTextDM

	/**
	 * P
	 */
	@Type(() => TTextDM)
	ConfirmedOrCanceledIdCalc?: TTextDM

	/**
	 * N
	 */
	@Type(() => TTextDM)
	SerialNumberInconsistencyBitCalc?: TTextDM

	/**
	 * Neshoda kal.
	 */
	@Type(() => TTextDM)
	InconsistentCalItemBitCalc?: TTextDM

	/**
	 *  
	 */
	@Type(() => TTextDM)
	CoveredBitCalc?: TTextDM

	/**
	 * undefined
	 */
	@Type(() => TSerialNumberItemDM)
	SerialNumberProductFilterRIDCalc?: TSerialNumberItemDM

	/**
	 * ParameterSetIdCalc
	 */
	@Type(() => TParameterSetDM)
	ParameterSetIdCalc?: TParameterSetDM

	/**
	 *  
	 */
	@Type(() => TTextDM)
	CoveredByStockBitCalc?: TTextDM

	/**
	 * Kód zboží
	 */
	@Type(() => TArticleCodeDocumentDM)
	ArticleCodeIdCalc?: TArticleCodeDocumentDM

	/**
	 * Nejstarší položka zakázky
	 */
	@Type(() => TSalesItemBookDM)
	SalesOrderOldestItemRIDCalc?: TSalesItemBookDM

	/**
	 * undefined
	 */
	@Type(() => TTextDM)
	CoveredExtendedCalc?: TTextDM

	/**
	 * undefined
	 */
	@Type(() => TDeliveryPlanDM)
	DeliveryPlanRIDCalc?: TDeliveryPlanDM

	/**
	 * undefined
	 */
	@Type(() => TDeliveryOrderDM)
	DeliveryOrderRIDCalc?: TDeliveryOrderDM

	/**
	 * undefined
	 */
	@Type(() => TDeliveryPlanItemBookDM)
	DeliveryPlanItemRIDCalc?: TDeliveryPlanItemBookDM

	/**
	 * D
	 */
	@Type(() => TTextDM)
	DocumentExistsCalc?: TTextDM

	/**
	 * undefined
	 */
	@Type(() => TMarketingActivityTypeDM)
	ActivityTypeRIDCalc?: TMarketingActivityTypeDM

	/**
	 * SP
	 */
	@Type(() => TTextDM)
	ProcessStateIdCalc?: TTextDM

	/**
	 * Aktuální proces
	 */
	@Type(() => TWorkflowProcessDM)
	CurrentProcessIdCalc?: TWorkflowProcessDM

	/**
	 * undefined
	 */
	@Type(() => TActivityDM)
	ActivityIdCalc?: TActivityDM

	/**
	 * Zaúčtoval
	 */
	@Type(() => TK2UserLookupDM)
	PostedByIdCalc?: TK2UserLookupDM

	/**
	 * undefined
	 */
	@Type(() => TTradingPartnerDM)
	OwnTradingPartnerIdCalc?: TTradingPartnerDM

	/**
	 * Období
	 */
	@Type(() => TPeriodDM)
	CurrentBusinessYearIdCalc?: TPeriodDM

	/**
	 * Sklad
	 */
	@Type(() => TWarehouseDM)
	CurrentWarehouseIdCalc?: TWarehouseDM

	/**
	 * Komentáře
	 */
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]

	/**
	 * Úkoly
	 */
	@Type(() => TJobDM)
	JobChild?: TJobDM[]

	/**
	 * undefined
	 */
	@Type(() => TTransferNoteDM)
	TransferNoteChild?: TTransferNoteDM[]

	/**
	 * undefined
	 */
	@Type(() => TWarehouseTransferDM)
	WarehouseTransferChild?: TWarehouseTransferDM[]

	/**
	 * undefined
	 */
	@Type(() => TJobCardScheduleDM)
	JobCardScheduleChild?: TJobCardScheduleDM[]

	/**
	 * undefined
	 */
	@Type(() => TJobCardItemDM)
	JobCardItemChild?: TJobCardItemDM[]

	/**
	 * undefined
	 */
	@Type(() => TJobCardOperationDM)
	JobCardOperationChild?: TJobCardOperationDM[]

	/**
	 * undefined
	 */
	@Type(() => TJobCardResourceDM)
	JobCardResourceChild?: TJobCardResourceDM[]

	/**
	 * undefined
	 */
	@Type(() => TJobCardBindDM)
	JobCardBindChild?: TJobCardBindDM[]

	/**
	 * undefined
	 */
	@Type(() => TJobCardSubordinateDocumentDM)
	JobCardSubordinateDocChild?: TJobCardSubordinateDocumentDM[]

	/**
	 * undefined
	 */
	@Type(() => TSerialNumberItemDM)
	SuperiorSerialNumberChild?: TSerialNumberItemDM[]

	/**
	 * undefined
	 */
	@Type(() => TAccountingFinanceJobCardItemDM)
	AccFinanceJobCardItemChild?: TAccountingFinanceJobCardItemDM[]

	/**
	 * undefined
	 */
	@Type(() => TAttachedRequestedBatchParameterDM)
	RequestedBatchParameterChild?: TAttachedRequestedBatchParameterDM[]

	/**
	 * undefined
	 */
	@Type(() => TAttachedBatchParameterValueDM)
	BatchParameterChild?: TAttachedBatchParameterValueDM[]

	/**
	 * undefined
	 */
	@Type(() => TProductCostingDM)
	ProductCostingChild?: TProductCostingDM[]

	/**
	 * undefined
	 */
	@Type(() => TJobCardOperationDM)
	JCOperationReadOnlyChild?: TJobCardOperationDM[]

	/**
	 * undefined
	 */
	@Type(() => TJobCardVariantScheduleDM)
	VariantScheduleChild?: TJobCardVariantScheduleDM[]

	/**
	 * Seznam aktivit
	 */
	@Type(() => TActivityDM)
	ActivityChild?: TActivityDM[]

	/**
	 * Dokumenty
	 */
	@Type(() => TExternalDocumentItemDM)
	ExternalDocumentItemChild?: TExternalDocumentItemDM[]

	/**
	 * Procesy
	 */
	@Type(() => TWorkflowProcessAsItemDM)
	WorkflowProcessChild?: TWorkflowProcessAsItemDM[]

	/**
	 * Text hlavičky
	 */
	@Type(() => TLanguageMemoTranslationDM)
	TextHeaderChild?: TLanguageMemoTranslationDM[]

	/**
	 * Text patičky
	 */
	@Type(() => TLanguageMemoTranslationDM)
	TextFooterChild?: TLanguageMemoTranslationDM[]

	/**
	 * undefined
	 */
	@Type(() => TDocumentAccountingJournalItemDM)
	DocumentAccJournalItemChild?: TDocumentAccountingJournalItemDM[]

	/**
	 * Svázané doklady
	 */
	@Type(() => TAttachedDocumentDM)
	AttachedDocumentOldChild?: TAttachedDocumentDM[]

	/**
	 * Poznámky
	 */
	@Type(() => TOldNoteDM)
	DeprecatedNoteOldChild?: TOldNoteDM[]

	/**
	 * Zrušení zaplánování
	 */
	public BulkCancelScheduleCOMMAND() {
		return this.executeCommand('BulkCancelScheduleCOMMAND')
	}

	/**
	 * Zrušení rozvržení
	 */
	public BulkCancelManuallyAdjustedScheduleCOMMAND() {
		return this.executeCommand('BulkCancelManuallyAdjustedScheduleCOMMAND')
	}

	/**
	 * Kopie TP
	 */
	public BulkCopyTPCOMMAND() {
		return this.executeCommand('BulkCopyTPCOMMAND')
	}

	/**
	 * Přepočet plánovacích průvodek
	 */
	public BulkRecalcPlanJobCardCOMMAND(parameters: TBulkRecalcPlanJobCardCOMMANDParams) {
		return this.executeCommand('BulkRecalcPlanJobCardCOMMAND', parameters)
	}

	/**
	 * Přepočet pokrytí
	 */
	public BulkCoverageRecalculationCOMMAND(parameters: TBulkCoverageRecalculationCOMMANDParams) {
		return this.executeCommand('BulkCoverageRecalculationCOMMAND', parameters)
	}

	/**
	 * Plán ->
	 */
	public PlanFromCOMMAND() {
		return this.executeCommand('PlanFromCOMMAND')
	}

	/**
	 * Plán <-
	 */
	public PlanToCOMMAND() {
		return this.executeCommand('PlanToCOMMAND')
	}

	/**
	 * Korekční průvodka
	 */
	public CorrectionJobCardCOMMAND() {
		return this.executeCommand('CorrectionJobCardCOMMAND')
	}

	/**
	 * Potvrzení bez kontroly
	 */
	public BulkConfirmWithoutCheckCOMMAND() {
		return this.executeCommand('BulkConfirmWithoutCheckCOMMAND')
	}

	/**
	 * Odpotvrzení bez kontroly
	 */
	public BulkUnConfirmWithoutCheckCOMMAND() {
		return this.executeCommand('BulkUnConfirmWithoutCheckCOMMAND')
	}

	/**
	 * Možnosti průvodek
	 */
	public JobCardSettingsCOMMAND(parameters: TJobCardSettingsCOMMANDParams) {
		return this.executeCommand('JobCardSettingsCOMMAND', parameters)
	}

	/**
	 * Hromadná změna položek
	 */
	public BulkChangeItemsJCCOMMAND(parameters: TBulkChangeItemsJCCOMMANDParams) {
		return this.executeCommand('BulkChangeItemsJCCOMMAND', parameters)
	}

	/**
	 * Zobrazit vybranou operaci
	 */
	public ShowItemsByOperationCOMMAND() {
		return this.executeCommand('ShowItemsByOperationCOMMAND')
	}

	/**
	 * Zobrazit korekční položky
	 */
	public ShowCorrectionItemsCOMMAND() {
		return this.executeCommand('ShowCorrectionItemsCOMMAND')
	}

	/**
	 * Zobrazit TP výrobku
	 */
	public ProductRoutingVariantCOMMAND() {
		return this.executeCommand('ProductRoutingVariantCOMMAND')
	}

	/**
	 * Kontejner zboží
	 */
	public AddGoodsToContainerCOMMAND(parameters: TAddGoodsToContainerCOMMANDParams) {
		return this.executeCommand('AddGoodsToContainerCOMMAND', parameters)
	}

	/**
	 * Částečné potvrzení
	 */
	public PartialConfirmCOMMAND() {
		return this.executeCommand('PartialConfirmCOMMAND')
	}

	/**
	 * Částečné odpotvrzení
	 */
	public PartialUnconfirmCOMMAND() {
		return this.executeCommand('PartialUnconfirmCOMMAND')
	}

	/**
	 * Částečné potvrzení
	 */
	public BulkPartialConfirmCOMMAND() {
		return this.executeCommand('BulkPartialConfirmCOMMAND')
	}

	/**
	 * Částečné odpotvrzení
	 */
	public BulkPartialUnconfirmCOMMAND() {
		return this.executeCommand('BulkPartialUnconfirmCOMMAND')
	}

	/**
	 * Přecenění k rozvahovému dni
	 */
	public BulkRevaluationAtBalanceDayCOMMAND() {
		return this.executeCommand('BulkRevaluationAtBalanceDayCOMMAND')
	}

	/**
	 * Storno přecenění k rozvahovému dni
	 */
	public BulkCancelRevaluationCOMMAND() {
		return this.executeCommand('BulkCancelRevaluationCOMMAND')
	}

	/**
	 * Změna DUZP
	 */
	public InvoiceDateChangeCOMMAND(parameters: TInvoiceDateChangeCOMMANDParams) {
		return this.executeCommand('InvoiceDateChangeCOMMAND', parameters)
	}

	/**
	 * Zaúčtování s likvidací
	 */
	public AccountAssignmentPostingCOMMAND(parameters: TAccountAssignmentPostingCOMMANDParams) {
		return this.executeCommand('AccountAssignmentPostingCOMMAND', parameters)
	}

	/**
	 * Hromadné zaúčtování s likvidací
	 */
	public BulkAccountAssignmentPostingCOMMAND(parameters: TBulkAccountAssignmentPostingCOMMANDParams) {
		return this.executeCommand('BulkAccountAssignmentPostingCOMMAND', parameters)
	}

	/**
	 * Likvidace
	 */
	public AccountAssignmentCOMMAND() {
		return this.executeCommand('AccountAssignmentCOMMAND')
	}

	/**
	 * Zapnout rychlý filtr
	 */
	public FastFilterSwitchCOMMAND() {
		return this.executeCommand('FastFilterSwitchCOMMAND')
	}

	/**
	 * Povolit editaci
	 */
	public EditModeIDNoCOMMAND() {
		return this.executeCommand('EditModeIDNoCOMMAND')
	}

	/**
	 * Povolit editaci
	 */
	public EditModeVATRegisterNoCOMMAND() {
		return this.executeCommand('EditModeVATRegisterNoCOMMAND')
	}

	/**
	 * Povolit editaci
	 */
	public EditModeTaxNoCOMMAND() {
		return this.executeCommand('EditModeTaxNoCOMMAND')
	}

	/**
	 * Změna kontace
	 */
	public BulkChangePostingKeyCOMMAND(parameters: TBulkChangePostingKeyCOMMANDParams) {
		return this.executeCommand('BulkChangePostingKeyCOMMAND', parameters)
	}

	/**
	 * Aktualizace Plánovaného data zaplacení dle Prodlení Dod./Odb.
	 */
	public BulkUpdatePlannedDateOfPaymentByAvgDelayCOMMAND() {
		return this.executeCommand('BulkUpdatePlannedDateOfPaymentByAvgDelayCOMMAND')
	}

	/**
	 * Zobrazovat data od období: %s
	 */
	public ApplyLowerLimitForData() {
		return this.executeCommand('ApplyLowerLimitForData')
	}

	/**
	 * Potvrzení
	 */
	public BulkConfirmCOMMAND() {
		return this.executeCommand('BulkConfirmCOMMAND')
	}

	/**
	 * Odpotvrzení
	 */
	public BulkUnconfirmCOMMAND() {
		return this.executeCommand('BulkUnconfirmCOMMAND')
	}

	/**
	 * Změna data potvrzení
	 */
	public BulkChangeConfirmationDateCOMMAND(parameters: TBulkChangeConfirmationDateCOMMANDParams) {
		return this.executeCommand('BulkChangeConfirmationDateCOMMAND', parameters)
	}

	/**
	 * Storno
	 */
	public BulkCancelCOMMAND() {
		return this.executeCommand('BulkCancelCOMMAND')
	}

	/**
	 * Odstorno
	 */
	public BulkUnCancelCOMMAND() {
		return this.executeCommand('BulkUnCancelCOMMAND')
	}

	/**
	 * Potvrzení
	 */
	public ConfirmCOMMAND() {
		return this.executeCommand('ConfirmCOMMAND')
	}

	/**
	 * Odpotvrzení
	 */
	public UnconfirmCOMMAND() {
		return this.executeCommand('UnconfirmCOMMAND')
	}

	/**
	 * Změna data potvrzení
	 */
	public ChangeConfirmationDateCOMMAND(parameters: TChangeConfirmationDateCOMMANDParams) {
		return this.executeCommand('ChangeConfirmationDateCOMMAND', parameters)
	}

	/**
	 * Storno/Odstorno
	 */
	public CancelCOMMAND() {
		return this.executeCommand('CancelCOMMAND')
	}

	/**
	 * Zrušení stavu
	 */
	public ClearRecordStatusCOMMAND() {
		return this.executeCommand('ClearRecordStatusCOMMAND')
	}

	/**
	 * Změna stavu
	 */
	public ChangeRecordStatusCOMMAND() {
		return this.executeCommand('ChangeRecordStatusCOMMAND')
	}

	/**
	 * 
	 */
	public ChangeRecordStatusSWSCOMMAND(parameters: TChangeRecordStatusSWSCOMMANDParams) {
		return this.executeCommand('ChangeRecordStatusSWSCOMMAND', parameters)
	}

	/**
	 * Zrušení stavu
	 */
	public BulkClearRecordStatusCOMMAND() {
		return this.executeCommand('BulkClearRecordStatusCOMMAND')
	}

	/**
	 * Změna stavu
	 */
	public BulkChangeRecordStatusCOMMAND() {
		return this.executeCommand('BulkChangeRecordStatusCOMMAND')
	}

	/**
	 * Změna atributů
	 */
	public BulkChangeAttributesCOMMAND(parameters: TBulkChangeAttributesCOMMANDParams) {
		return this.executeCommand('BulkChangeAttributesCOMMAND', parameters)
	}

	/**
	 * Aktualizace sady atributů
	 */
	public BulkActualizeAttributesCOMMAND(parameters: TBulkActualizeAttributesCOMMANDParams) {
		return this.executeCommand('BulkActualizeAttributesCOMMAND', parameters)
	}

	/**
	 * Aktualizace sady atributů
	 */
	public ActualizeAttributesCOMMAND() {
		return this.executeCommand('ActualizeAttributesCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public ChangeGroupRightCOMMAND() {
		return this.executeCommand('ChangeGroupRightCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public BulkChangeGroupRightCOMMAND() {
		return this.executeCommand('BulkChangeGroupRightCOMMAND')
	}

	/**
	 * Aktualizace počítaných polí
	 */
	public BulkUpdateCalcFieldsCOMMAND() {
		return this.executeCommand('BulkUpdateCalcFieldsCOMMAND')
	}

	/**
	 * Kontrola legislativy
	 */
	public SwitchOffLegislationValidationCOMMAND() {
		return this.executeCommand('SwitchOffLegislationValidationCOMMAND')
	}
}

export type TBulkRecalcPlanJobCardCOMMANDParams = {
	/**
	 * Kompletace odváděcích průvodek
	 */
	Assembling?: boolean
	/**
	 * Včetně potvrzených odváděcích průvodek
	 */
	IncludeConfirmed?: boolean
	/**
	 * Samostatné odvádění přípravy
	 */
	SetupTimesCompletition?: boolean
	/**
	 * Nepřepočítat zaplánování
	 */
	DoNotRecalculateSchedule?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TBulkCoverageRecalculationCOMMANDParams = {
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TJobCardSettingsCOMMANDParams = {
	/**
	 * Zobrazit zkratku ve stromu
	 */
	AbbrInTree?: boolean
	/**
	 * Pro zboží zobrazit Zkratku 2
	 */
	AbbrInTree2?: boolean
	/**
	 * Zobrazit název ve stromu
	 */
	NameInTree?: boolean
	/**
	 * Zobrazit text operace
	 */
	OpeText?: boolean
	/**
	 * Skrýt strom
	 */
	HideTree?: boolean
	/**
	 * Skrýt zdroje
	 */
	HideRes?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TBulkChangeItemsJCCOMMANDParams = {
	/**
	 * Hlavičkový modul
	 */
	HeaderType?: number
	/**
	 * Položkový modul
	 */
	ItemsType?: number
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TAddGoodsToContainerCOMMANDParams = {
	/**
	 * Přidat záznamy do kontejneru
	 */
	AddToExistingFilter?: boolean
	/**
	 * Vytvořit kontejner obsahující pouze tyto záznamy
	 */
	CreateNewFilter?: boolean
	/**
	 * Kontejner vytvořit podle vybraného dokladu
	 */
	OnlyActualDocument?: boolean
	/**
	 * Kontejner vytvořit podle označených položek vybraného dokladu
	 */
	OnlyActualDocumentSelectedItems?: boolean
	/**
	 * Kontejner vytvořit podle všech označených dokladů
	 */
	AllSelectedDocuments?: boolean
	/**
	 * Kontejner vytvořit podle všech dokladů v kontejneru/filtru
	 */
	AllDocumentsInFilter?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TInvoiceDateChangeCOMMANDParams = {
	/**
	 * VATDate
	 */
	VATDate?: Date
}

export type TAccountAssignmentPostingCOMMANDParams = {
	/**
	 * Zaúčtuje
	 */
	ACCPosting?: number
	/**
	 * Kniha
	 */
	ACCBook?: number
	/**
	 * Období
	 */
	ACCBussinessYear?: number
	/**
	 * Datum zaúčtování
	 */
	ACCPostingDate?: Date
	/**
	 * Datum od
	 */
	ACCDateFrom?: Date
	/**
	 * Datum do
	 */
	ACCDateTo?: Date
	/**
	 * Zachovat ručně změněné záznamy
	 */
	ACCHandChange?: boolean
	/**
	 * Likvidace bez částky
	 */
	ACCNulAmount?: boolean
	/**
	 * Povolit zaúčtovat doklady s jiným obdobím
	 */
	ACCOtherBusinessYear?: boolean
	/**
	 * Zaúčtovat nevyrovnané saldo
	 */
	ACCBalance?: boolean
}

export type TBulkAccountAssignmentPostingCOMMANDParams = {
	/**
	 * Zaúčtuje
	 */
	ACCPosting?: number
	/**
	 * Kniha
	 */
	ACCBook?: number
	/**
	 * Období
	 */
	ACCBussinessYear?: number
	/**
	 * Datum zaúčtování
	 */
	ACCPostingDate?: Date
	/**
	 * Datum od
	 */
	ACCDateFrom?: Date
	/**
	 * Datum do
	 */
	ACCDateTo?: Date
	/**
	 * Zachovat ručně změněné záznamy
	 */
	ACCHandChange?: boolean
	/**
	 * Likvidace bez částky
	 */
	ACCNulAmount?: boolean
	/**
	 * Povolit zaúčtovat doklady s jiným obdobím
	 */
	ACCOtherBusinessYear?: boolean
	/**
	 * Zaúčtovat nevyrovnané saldo
	 */
	ACCBalance?: boolean
}

export type TBulkChangePostingKeyCOMMANDParams = {
	/**
	 * Kontace
	 */
	Header_PostingKeyX?: boolean
	/**
	 * Header_PostingKey
	 */
	Header_PostingKey?: number
	/**
	 * Účet
	 */
	Header_AccountX?: boolean
	/**
	 * Header_Account
	 */
	Header_Account?: number
	/**
	 * Účet D
	 */
	Header_CreditAcX?: boolean
	/**
	 * Header_CreditAc
	 */
	Header_CreditAc?: number
	/**
	 * Účet MD
	 */
	Header_DebitAcX?: boolean
	/**
	 * Header_DebitAc
	 */
	Header_DebitAc?: number
	/**
	 * Parovací symbol
	 */
	Header_MatchSymbolX?: boolean
	/**
	 * Header_MatchSymbol
	 */
	Header_MatchSymbol?: number
	/**
	 * Středisko
	 */
	Header_CostCentreX?: boolean
	/**
	 * Header_CostCentre
	 */
	Header_CostCentre?: number
	/**
	 * Ze třediska
	 */
	Header_FromCostCentreX?: boolean
	/**
	 * Header_FromCostCentre
	 */
	Header_FromCostCentre?: number
	/**
	 * Na středisko
	 */
	Header_ToCostCentreX?: boolean
	/**
	 * Header_ToCostCentre
	 */
	Header_ToCostCentre?: number
	/**
	 * Kód zakázky
	 */
	Header_ContractCodeX?: boolean
	/**
	 * Header_ContractCode
	 */
	Header_ContractCode?: number
	/**
	 * Z kódu zakázky
	 */
	Header_FromContractCodeX?: boolean
	/**
	 * Header_FromContractCode
	 */
	Header_FromContractCode?: number
	/**
	 * Na kód zakázky
	 */
	Header_ToContractCodeX?: boolean
	/**
	 * Header_ToContractCode
	 */
	Header_ToContractCode?: number
	/**
	 * Prostředek
	 */
	Header_DeviceX?: boolean
	/**
	 * Header_Device
	 */
	Header_Device?: number
	/**
	 * Z prostředku
	 */
	Header_FromDeviceX?: boolean
	/**
	 * Header_FromDevice
	 */
	Header_FromDevice?: number
	/**
	 * Na prostředek
	 */
	Header_ToDeviceX?: boolean
	/**
	 * Header_ToDevice
	 */
	Header_ToDevice?: number
	/**
	 * Referent
	 */
	Header_OfficerX?: boolean
	/**
	 * Header_Officer
	 */
	Header_Officer?: number
	/**
	 * Z referenta
	 */
	Header_FromOfficerX?: boolean
	/**
	 * Header_FromOfficer
	 */
	Header_FromOfficer?: number
	/**
	 * Na referenta
	 */
	Header_ToOfficerX?: boolean
	/**
	 * Header_ToOfficer
	 */
	Header_ToOfficer?: number
	/**
	 * Kód zboží
	 */
	Header_ArticleCodeX?: boolean
	/**
	 * Header_ArticleCode
	 */
	Header_ArticleCode?: number
	/**
	 * Kód 1
	 */
	Header_Code1X?: boolean
	/**
	 * Header_Code1
	 */
	Header_Code1?: number
	/**
	 * Z kódu 1
	 */
	Header_FromCode1X?: boolean
	/**
	 * Header_FromCode1
	 */
	Header_FromCode1?: number
	/**
	 * Na kód 1
	 */
	Header_ToCode1X?: boolean
	/**
	 * Header_ToCode1
	 */
	Header_ToCode1?: number
	/**
	 * Kód 2
	 */
	Header_Code2X?: boolean
	/**
	 * Header_Code2
	 */
	Header_Code2?: number
	/**
	 * Z kódu 2
	 */
	Header_FromCode2X?: boolean
	/**
	 * Header_FromCode2
	 */
	Header_FromCode2?: number
	/**
	 * Na kód 2
	 */
	Header_ToCode2X?: boolean
	/**
	 * Header_ToCode2
	 */
	Header_ToCode2?: number
	/**
	 * Kód 3
	 */
	Header_Code3X?: boolean
	/**
	 * Header_Code3
	 */
	Header_Code3?: number
	/**
	 * Z kódu 3
	 */
	Header_FromCode3X?: boolean
	/**
	 * Header_FromCode3
	 */
	Header_FromCode3?: number
	/**
	 * Na kód 3
	 */
	Header_ToCode3X?: boolean
	/**
	 * Header_ToCode3
	 */
	Header_ToCode3?: number
	/**
	 * Kód 4
	 */
	Header_Code4X?: boolean
	/**
	 * Header_Code4
	 */
	Header_Code4?: number
	/**
	 * Z kódu 4
	 */
	Header_FromCode4X?: boolean
	/**
	 * Header_FromCode4
	 */
	Header_FromCode4?: number
	/**
	 * Na kód 4
	 */
	Header_ToCode4X?: boolean
	/**
	 * Header_ToCode4
	 */
	Header_ToCode4?: number
	/**
	 * Kód 5
	 */
	Header_Code5X?: boolean
	/**
	 * Header_Code5
	 */
	Header_Code5?: number
	/**
	 * Z kódu 5
	 */
	Header_FromCode5X?: boolean
	/**
	 * Header_FromCode5
	 */
	Header_FromCode5?: number
	/**
	 * Na kód 5
	 */
	Header_ToCode5X?: boolean
	/**
	 * Header_ToCode5
	 */
	Header_ToCode5?: number
	/**
	 * Kód 6
	 */
	Header_Code6X?: boolean
	/**
	 * Header_Code6
	 */
	Header_Code6?: number
	/**
	 * Z kódu 6
	 */
	Header_FromCode6X?: boolean
	/**
	 * Header_FromCode6
	 */
	Header_FromCode6?: number
	/**
	 * Na kód 6
	 */
	Header_ToCode6X?: boolean
	/**
	 * Header_ToCode6
	 */
	Header_ToCode6?: number
	/**
	 * Ignorovat cenu příjmu
	 */
	Header_IgnorePriceIncomX?: boolean
	/**
	 * Header_IgnorePriceIncom
	 */
	Header_IgnorePriceIncom?: number
	/**
	 * Header_ShowIgnorePriceIncom
	 */
	Header_ShowIgnorePriceIncom?: boolean
	/**
	 * Kalk.vz.plán.
	 */
	Header_PlannedPriceCMX?: boolean
	/**
	 * Header_PlannedPriceCM
	 */
	Header_PlannedPriceCM?: number
	/**
	 * Kalk.vz.sklad.
	 */
	Header_StockPriceCMX?: boolean
	/**
	 * Header_StockPriceCM
	 */
	Header_StockPriceCM?: number
	/**
	 * Kalk.vz.nákl.
	 */
	Header_CostPriceCMX?: boolean
	/**
	 * Header_CostPriceCM
	 */
	Header_CostPriceCM?: number
	/**
	 * Kontace faktury
	 */
	Item_PostingKeyIX?: boolean
	/**
	 * Item_PostingKeyI
	 */
	Item_PostingKeyI?: number
	/**
	 * Kontace skladového dokladu
	 */
	Item_PostingKeySX?: boolean
	/**
	 * Item_PostingKeyS
	 */
	Item_PostingKeyS?: number
	/**
	 * Kontace rezervačního listu
	 */
	Item_PostingKeyRX?: boolean
	/**
	 * Item_PostingKeyR
	 */
	Item_PostingKeyR?: number
	/**
	 * Kód zakázky
	 */
	Item_ContractCodeX?: boolean
	/**
	 * Item_ContractCode
	 */
	Item_ContractCode?: number
	/**
	 * Kód zakázky 2
	 */
	Item_ContractCode2X?: boolean
	/**
	 * Item_ContractCode2
	 */
	Item_ContractCode2?: number
	/**
	 * Středisko
	 */
	Item_CostCentreX?: boolean
	/**
	 * Item_CostCentre
	 */
	Item_CostCentre?: number
	/**
	 * Středisko 2
	 */
	Item_CostCentre2X?: boolean
	/**
	 * Item_CostCentre2
	 */
	Item_CostCentre2?: number
	/**
	 * Dosadit z karty zboží
	 */
	Item_CostCentreG?: boolean
	/**
	 * Kód zboží
	 */
	Item_ArticleCodeX?: boolean
	/**
	 * Item_ArticleCode
	 */
	Item_ArticleCode?: number
	/**
	 * Dosadit z karty zboží
	 */
	Item_ArticleCodeG?: boolean
	/**
	 * Kód 1
	 */
	Item_Code1X?: boolean
	/**
	 * Item_Code1
	 */
	Item_Code1?: number
	/**
	 * Kód 2
	 */
	Item_Code2X?: boolean
	/**
	 * Item_Code2
	 */
	Item_Code2?: number
	/**
	 * Kód 3
	 */
	Item_Code3X?: boolean
	/**
	 * Item_Code3
	 */
	Item_Code3?: number
	/**
	 * Kód 4
	 */
	Item_Code4X?: boolean
	/**
	 * Item_Code4
	 */
	Item_Code4?: number
	/**
	 * Kód 5
	 */
	Item_Code5X?: boolean
	/**
	 * Item_Code5
	 */
	Item_Code5?: number
	/**
	 * Kód 6
	 */
	Item_Code6X?: boolean
	/**
	 * Item_Code6
	 */
	Item_Code6?: number
	/**
	 * Referent
	 */
	Item_OfficerX?: boolean
	/**
	 * Item_Officer
	 */
	Item_Officer?: number
	/**
	 * Prostředek
	 */
	Item_DeviceX?: boolean
	/**
	 * Item_Device
	 */
	Item_Device?: number
	/**
	 * Parovací symbol
	 */
	Item_MatchSymbolX?: boolean
	/**
	 * Item_MatchSymbol
	 */
	Item_MatchSymbol?: number
	/**
	 * Účet
	 */
	Item_AccountX?: boolean
	/**
	 * Item_Account
	 */
	Item_Account?: number
	/**
	 * Kontace
	 */
	Item_PostingKeyX?: boolean
	/**
	 * Item_PostingKey
	 */
	Item_PostingKey?: number
	/**
	 * Účet D
	 */
	Item_CreditAcX?: boolean
	/**
	 * Item_CreditAc
	 */
	Item_CreditAc?: number
	/**
	 * Účet MD
	 */
	Item_DebitAcX?: boolean
	/**
	 * Item_DebitAc
	 */
	Item_DebitAc?: number
	/**
	 * Podle kontejneru zboží
	 */
	GoodsContainer?: boolean
	/**
	 * S odmazáním z kontejneru
	 */
	DeleteFromContainer?: boolean
	/**
	 * Pouze u prázdných
	 */
	OnlyEmpty?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TBulkChangeConfirmationDateCOMMANDParams = {
	/**
	 * Datum potvrzení
	 */
	Confirmation1Date?: Date
	/**
	 * Hodina potvrzení
	 */
	Confirmation1Time?: Date
	/**
	 * Datum 2. potvrzení
	 */
	Confirmation2Date?: Date
	/**
	 * Hodina 2. potvrzení
	 */
	Confirmation2Time?: Date
	/**
	 * Kontrolovat záporné stavy
	 */
	CheckNegativeState?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TChangeConfirmationDateCOMMANDParams = {
	/**
	 * Datum potvrzení
	 */
	Confirmation1Date?: Date
	/**
	 * Hodina potvrzení
	 */
	Confirmation1Time?: Date
	/**
	 * Datum 2. potvrzení
	 */
	Confirmation2Date?: Date
	/**
	 * Hodina 2. potvrzení
	 */
	Confirmation2Time?: Date
	/**
	 * Kontrolovat záporné stavy
	 */
	CheckNegativeState?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TChangeRecordStatusSWSCOMMANDParams = {
	/**
	 * StatusId
	 */
	StatusId?: number
}

export type TBulkChangeAttributesCOMMANDParams = {
	/**
	 * Smazat atributy
	 */
	DeleteAttribute?: boolean
	/**
	 * Smazat všechny volné atributy
	 */
	DeleteFreeAttribute?: boolean
	/**
	 * Nastavit hodnoty atributů
	 */
	SetValues?: boolean
	/**
	 * Přidat nepřipojené atributy
	 */
	AddFreeAttributes?: boolean
	/**
	 * FreeAttributeEnabled
	 */
	FreeAttributeEnabled?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TBulkActualizeAttributesCOMMANDParams = {
	/**
	 * Aktualizovat včetně samotné sady atributů
	 */
	ActualizeAttributeSet?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}