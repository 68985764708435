import Data from 'react-k2-api/Api/Data'
import { Type } from 'class-transformer'
import TTextDM from './TTextDM'

/**
 * Saldokonto - vývoj
 */
export default class TOpenItemListTrendDM extends Data {
	public static className = 'TOpenItemListTrendDM'
	public static systemFields = [
	]

	public static isHeader = true

	/**
	 * undefined
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	TableId?: number

	/**
	 * undefined
	 */
	AmountBefore?: number

	/**
	 * undefined
	 */
	AmountAfter?: number

	/**
	 * undefined
	 */
	DocumentIdent?: string

	/**
	 * undefined
	 */
	RID?: number

	/**
	 * undefined
	 */
	Date?: Date

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * SQLSequenceOrderCalc
	 */
	SQLSequenceOrderCalc?: number

	/**
	 * undefined
	 */
	@Type(() => TTextDM)
	TypeId?: TTextDM

	/**
	 * Rozšířit zobrazení
	 */
	public AddFourToIntervalCOMMANDS() {
		return this.executeCommand('AddFourToIntervalCOMMANDS')
	}

	/**
	 * Omezit zobrazení
	 */
	public RemoveFourFromIntervalCOMMANDS() {
		return this.executeCommand('RemoveFourFromIntervalCOMMANDS')
	}

	/**
	 * Zobrazení v týdnech
	 */
	public IntervalInWeeksCOMMANDS() {
		return this.executeCommand('IntervalInWeeksCOMMANDS')
	}

	/**
	 * Změna skupiny práv
	 */
	public ChangeGroupRightCOMMAND() {
		return this.executeCommand('ChangeGroupRightCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public BulkChangeGroupRightCOMMAND() {
		return this.executeCommand('BulkChangeGroupRightCOMMAND')
	}

	/**
	 * Aktualizace počítaných polí
	 */
	public BulkUpdateCalcFieldsCOMMAND() {
		return this.executeCommand('BulkUpdateCalcFieldsCOMMAND')
	}

	/**
	 * Kontrola legislativy
	 */
	public SwitchOffLegislationValidationCOMMAND() {
		return this.executeCommand('SwitchOffLegislationValidationCOMMAND')
	}
}