import Data from 'react-k2-api/Api/Data'
import { Type } from 'class-transformer'
import TJobCardFieldDM from './TJobCardFieldDM'

/**
 * Další pole průvodky
 */
export default class TJobCardConfigFieldDM extends Data {
	public static className = 'TJobCardConfigFieldDM'
	public static systemFields = [
		'RecordID',
	]

	public static isHeader = false

	/**
	 * undefined
	 */
	Value?: string

	/**
	 * undefined
	 */
	HeaderId?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * undefined
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * undefined
	 */
	ValueCalc?: string

	/**
	 * undefined
	 */
	@Type(() => TJobCardFieldDM)
	FieldId?: TJobCardFieldDM
}