import { Item, HandleItemDelete, HandleItemSave } from "components/Item"
import TSalesItemDM from "react-k2-api/Data/TSalesItemDM"

export type ItemListProps = {
    items: TSalesItemDM[]
    handleSave: HandleItemSave
    handleDelete: HandleItemDelete
}

export const ItemList = ({ items, handleSave, handleDelete }: ItemListProps) => {
    return (
        <tbody>
            {items && items.length ? items.map((item: TSalesItemDM) => {
                return <Item key={item.RID} item={item} handleSave={handleSave} handleDelete={handleDelete} />
            }) : (<tr>
                <td>Objednávka neobsahuje žádné položky.</td>
            </tr>)}
        </tbody>
    )
}