import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TIntrastatTariffDutyDM from './TIntrastatTariffDutyDM'
import TTaxTypeDM from './TTaxTypeDM'
import TVATRateDM from './TVATRateDM'
import TK2UserLookupDM from './TK2UserLookupDM'
import TLegislationDm from './TLegislationDm'

/**
 * Zboží- pole dle legislativy
 */
export default class TLegislationLinkArticleDM extends Data {
	public static className = 'TLegislationLinkArticleDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = false

	/**
	 * Valid
	 */
	IsValid?: string

	/**
	 * Změněno
	 */
	ChangedOn?: Date

	/**
	 * RecID
	 */
	RecordNumber?: number

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Datum pro "_ver" pole
	 */
	VersionFieldDateCalc?: Date

	/**
	 * Celní sazebník
	 */
	@Type(() => TIntrastatTariffDutyDM)
	CustomsTariffId?: TIntrastatTariffDutyDM

	/**
	 * Typ daně PDP prodej
	 */
	@Type(() => TTaxTypeDM)
	SaleTaxTypeRCSId?: TTaxTypeDM

	/**
	 * Typ daně PDP nákup
	 */
	@Type(() => TTaxTypeDM)
	PurchaseTaxTypeRCSId?: TTaxTypeDM

	/**
	 * Typ daně - prodej
	 */
	@Type(() => TTaxTypeDM)
	SaleTaxTypeId?: TTaxTypeDM

	/**
	 * Typ daně - nákup
	 */
	@Type(() => TTaxTypeDM)
	PurchaseTaxTypeId?: TTaxTypeDM

	/**
	 * DPH
	 */
	@Type(() => TVATRateDM)
	VATRateId?: TVATRateDM

	/**
	 * Změnil
	 */
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM

	/**
	 * Legislativa
	 */
	@Type(() => TLegislationDm)
	LegislationId?: TLegislationDm

	/**
	 * DPH_ver
	 */
	@Type(() => TVATRateDM)
	VATRateId_ver?: TVATRateDM

	/**
	 * Celní sazebník_ver
	 */
	@Type(() => TIntrastatTariffDutyDM)
	CustomsTariffId_ver?: TIntrastatTariffDutyDM
}