import Data from 'react-k2-api/Api/Data'
import { Type } from 'class-transformer'
import TJobCardItemDM from './TJobCardItemDM'

/**
 * Nadřízené doklady
 */
export default class TJobCardBindDM extends Data {
	public static className = 'TJobCardBindDM'
	public static systemFields = [
		'RecordID',
	]

	public static isHeader = false

	/**
	 * Číslo podř.
	 */
	HeaderRID2?: number

	/**
	 * Číslo nadř.
	 */
	HeaderRID1?: number

	/**
	 * Ident. nadř.
	 */
	SuperiorBindId?: number

	/**
	 * Typ
	 */
	SuperiorDocumentType?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * undefined
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Ident. podř.
	 */
	@Type(() => TJobCardItemDM)
	SubordinateJobCardItemRID?: TJobCardItemDM
}