import Data from 'react-k2-api/Api/Data'
import { Type } from 'class-transformer'
import TTradingPartnerDM from './TTradingPartnerDM'
import TPriceListDM from './TPriceListDM'

/**
 * Ceníky - seznam odběratelů
 */
export default class TPriceListTradingPartnerDM extends Data {
	public static className = 'TPriceListTradingPartnerDM'
	public static systemFields = [
		'RecordID',
	]

	public static isHeader = false

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * Zákazník
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Vztah
	 */
	RelationTypeCalc?: number

	/**
	 * Zákazník
	 */
	@Type(() => TTradingPartnerDM)
	TradingPartnerId?: TTradingPartnerDM

	/**
	 * Ceník
	 */
	@Type(() => TPriceListDM)
	PriceListRID?: TPriceListDM
}