import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TChangeCodeHINDM from './TChangeCodeHINDM'
import TTextDM from './TTextDM'
import TK2UserLookupDM from './TK2UserLookupDM'
import Personal from './Personal'
import OJCSSZ from './OJCSSZ'
import TPartnerDM from './TPartnerDM'
import TCountryDM from './TCountryDM'
import TTownPartDM from './TTownPartDM'
import TAddressDM from './TAddressDM'
import TPrDeductionDM from './TPrDeductionDM'
import ZpusDoprClov from './ZpusDoprClov'
import DegreeName from './DegreeName'
import MaritalStatus from './MaritalStatus'
import DuvodZmPS from './DuvodZmPS'
import DruhDuchPD1 from './DruhDuchPD1'
import DruhDuchPD2 from './DruhDuchPD2'
import TContactPersonDM from './TContactPersonDM'
import TPrEmploymentSimpleDM from './TPrEmploymentSimpleDM'
import EmpDepart from './EmpDepart'
import TaxNoType from './TaxNoType'
import TBookDM from './TBookDM'
import TPrEmploymentDM from './TPrEmploymentDM'
import TPrPeriodDM from './TPrPeriodDM'
import TMarketingActivityTypeDM from './TMarketingActivityTypeDM'
import TWorkflowProcessDM from './TWorkflowProcessDM'
import TTownDM from './TTownDM'
import TCostCentreCollectionDocumentDM from './TCostCentreCollectionDocumentDM'
import TCommentDM from './TCommentDM'
import TLanguageSkillDM from './TLanguageSkillDM'
import TEducationDM from './TEducationDM'
import TEmployeeClassificationHistoryDM from './TEmployeeClassificationHistoryDM'
import TPreviousEmploymentDM from './TPreviousEmploymentDM'
import TFamilyDM from './TFamilyDM'
import TQualificationEmployedPersonDM from './TQualificationEmployedPersonDM'
import TPrLiabilityDM from './TPrLiabilityDM'
import TPrEmployeeDocumentDM from './TPrEmployeeDocumentDM'
import TPrPersonDocumentDM from './TPrPersonDocumentDM'
import TPrDeductionAllDM from './TPrDeductionAllDM'
import TPrDefinitionComponentDM from './TPrDefinitionComponentDM'
import TWorkerEventAsItemDM from './TWorkerEventAsItemDM'
import TWorkerProtectionEquipmentAsItemDM from './TWorkerProtectionEquipmentAsItemDM'
import TElectronicSubmissionPersonDM from './TElectronicSubmissionPersonDM'
import TExternalDocumentItemDM from './TExternalDocumentItemDM'
import TWorkflowProcessAsItemDM from './TWorkflowProcessAsItemDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TCompanyLinkEmployedPersonDM from './TCompanyLinkEmployedPersonDM'
import TAttachedDocumentDM from './TAttachedDocumentDM'
import TOldNoteDM from './TOldNoteDM'

/**
 * Personální údaje
 */
export default class TEmployedPersonDM extends Data {
	public static className = 'TEmployedPersonDM'
	public static primaryKey = 'Id'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.Id!
	}

	public static isHeader = true

	/**
	 * Datum kódu změny
	 */
	HISChangeCodeDate?: Date

	/**
	 * Přihlášen ZP
	 */
	IsHealthInsuranceRegistered?: string

	/**
	 * Osoba pracující v noci
	 */
	IsNightWorkingPerson?: string

	/**
	 * Předchozí příjmení
	 */
	LastSurname?: string

	/**
	 * Stravenková karta
	 */
	LuncheonVoucherNumber?: string

	/**
	 * Vytvořeno
	 */
	CreatedOn?: Date

	/**
	 * XMLFieldScript
	 */
	XMLFieldScript?: string

	/**
	 * Vydal
	 */
	IdentityCardAuthority?: string

	/**
	 * Vydání OP
	 */
	IdentityCardIssuedOn?: Date

	/**
	 * Platnost OP
	 */
	IdentityCardExpiryDate?: Date

	/**
	 * ISIR datum
	 */
	RatingIsirDate?: Date

	/**
	 * ISIR hodnocení
	 */
	RatingIsirValue?: string

	/**
	 * DIČ
	 */
	VATRegNumber?: string

	/**
	 * Číslo zdrav. pojištění
	 */
	HealthInsurranceNumber?: string

	/**
	 * Počet vychovaných dětí
	 */
	BroughtUpChildrenCount?: number

	/**
	 * Důch. sp. do
	 */
	RetirementSavingDateTo?: Date

	/**
	 * Důch. sp. od
	 */
	RetirementSavingDateFrom?: Date

	/**
	 * Důch. sp. - č. účastníka
	 */
	RetirementSavingMemberNumber?: string

	/**
	 * Č. rozhodnutí důch.
	 */
	PensionDecisionNumber?: string

	/**
	 * Email
	 */
	Email?: string

	/**
	 * Invalidita do
	 */
	DisabilityDateTo?: Date

	/**
	 * Invalidita od
	 */
	DisabilityDateFrom?: Date

	/**
	 * XMLFields
	 */
	XMLField?: string

	/**
	 * Časové razítko
	 */
	TimeStamp?: string

	/**
	 * Heslo
	 */
	EmailPassword?: string

	/**
	 * Titul za
	 */
	DegreeAfterName?: string

	/**
	 * NewFields
	 */
	NewFields?: string

	/**
	 * ZZZZ
	 */
	AdditionalInfo?: string

	/**
	 * Plátce důchodu
	 */
	PensionPayer?: string

	/**
	 * DZPS
	 */
	WorkingAbility?: string

	/**
	 * Telefon domů 2
	 */
	TemporaryPhone?: string

	/**
	 * Telefon domů
	 */
	PermanentPhone?: string

	/**
	 * Počet vyživovaných osob
	 */
	DependentChildrenCount?: number

	/**
	 * Datum vzniku důchodu
	 */
	PensionDate?: Date

	/**
	 * Druh důch.
	 */
	PensionTypeId?: string

	/**
	 * Právo na záznam
	 */
	RightGroupId?: number

	/**
	 * Změněno
	 */
	ChangedOn?: Date

	/**
	 * Datum narození
	 */
	BirthDate?: Date

	/**
	 * Rodné příjmení
	 */
	BirthName?: string

	/**
	 * Titul
	 */
	Degree?: string

	/**
	 * Příjmení
	 */
	Surname?: string

	/**
	 * CisP
	 */
	Id?: number

	/**
	 * Řidičský průkaz
	 */
	DriversLicence?: string

	/**
	 * Číslo pasu
	 */
	PassportNumber?: string

	/**
	 * Číslo OP
	 */
	IdentityCardNumber?: string

	/**
	 * Datum změny rod. stavu
	 */
	MaritalStatusDate?: Date

	/**
	 * Telefon 2
	 */
	Phone2?: string

	/**
	 * Telefon
	 */
	Phone1?: string

	/**
	 * Rodné číslo
	 */
	BirthIdNumber?: string

	/**
	 * Jméno
	 */
	FirstName?: string

	/**
	 * Osobní číslo
	 */
	EmployeePersonalNumber?: string

	/**
	 * EX_Login
	 */
	EX_Login?: string

	/**
	 * EX_Zarazeni
	 */
	EX_Zarazeni?: string

	/**
	 * EX_LFS_USERNAME
	 */
	EX_LFS_USERNAME?: string

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * SQLSequenceOrderCalc
	 */
	SQLSequenceOrderCalc?: number

	/**
	 * Datum pro "_ver" pole
	 */
	VersionedFieldDateCalc?: Date

	/**
	 * Datum pro "_ver" pole
	 */
	VersionFieldDateCalc?: Date

	/**
	 * RČ2
	 */
	BirthIdNumberPart2Calc?: string

	/**
	 * RČ1
	 */
	BirthIdNumberPart1Calc?: string

	/**
	 * Jméno
	 */
	ForenameSurnameCalc?: string

	/**
	 * Jméno
	 */
	ForenameSurnameTitleCalc?: string

	/**
	 * Jméno
	 */
	FullNameCalc?: string

	/**
	 * Jméno
	 */
	FullNameWithTitleCalc?: string

	/**
	 * Rodné číslo
	 */
	BirthIdNumberCalc?: string

	/**
	 * Pobírán důchod
	 */
	PensionDrawedBitCalc?: number

	/**
	 * Daňový nerezident
	 */
	TaxNonResidentBitCalc?: number

	/**
	 * Nekontrolovat RČ
	 */
	NoCheckBirthIdNumberBitCalc?: number

	/**
	 * Zneplatněno
	 */
	InvalidRecordBitCalc?: number

	/**
	 * Účastník DS
	 */
	PensionSavingMemberBitCalc?: number

	/**
	 * Daňový ráj
	 */
	TaxHavenBitCalc?: number

	/**
	 * Agenturní pracovník
	 */
	AgencyWorkerBitCalc?: number

	/**
	 * Duplicitní RČ
	 */
	DuplicateBirthIdNumberBitCalc?: number

	/**
	 * Zpracování omezeno
	 */
	LimitedProcessingBitCalc?: number

	/**
	 * Úplný výpis položek
	 */
	IsItemCompleteCalc?: string

	/**
	 * Číslo soc. pojištění
	 */
	SocialSecurityNumberCalc?: string

	/**
	 * Číslo soc. poj.
	 */
	SocialInsuranceNumberCalc?: string

	/**
	 * Číslo zdr. poj.
	 */
	HealthInsuranceNumberCalc?: string

	/**
	 * ISIR
	 */
	IsirRank16ImgCalc?: string

	/**
	 * ISIR
	 */
	IsirRank32ImgCalc?: string

	/**
	 * Nárok na starob. důch.
	 */
	PensionClaimDateCalc?: Date

	/**
	 * Vydavatel pasu
	 */
	PassportAuthorityValueCalc?: string

	/**
	 * Platnost pasu
	 */
	PassportValidityCalc?: Date

	/**
	 * Cizinec podle (§)
	 */
	ForeignerTypeCalc?: string

	/**
	 * Podání informační karty - nástup
	 */
	InformationCardDateCalc?: Date

	/**
	 * Pracovní povolení
	 */
	WorkPermitNumberCalc?: string

	/**
	 * Vydání pracovního povolení
	 */
	WorkPermitDateCalc?: Date

	/**
	 * Vypršení pracovního povolení
	 */
	WorkPermitExpirationDateCalc?: Date

	/**
	 * Modrá karta
	 */
	BlueCardNumberCalc?: string

	/**
	 * Vydání modré karty
	 */
	BlueCardDateCalc?: Date

	/**
	 * Vypršení modré karty
	 */
	BlueCardExpiryDateCalc?: Date

	/**
	 * Zaměstnanecká karta
	 */
	EmployeeCardNumberCalc?: string

	/**
	 * Vydání zaměstnanecké karty
	 */
	EmployeeCardIssueDateCalc?: Date

	/**
	 * Vypršení zaměstnanecké karty
	 */
	EmployeeCardExpirationDateCalc?: Date

	/**
	 * Podání informační karty - ukončení
	 */
	InformationCardDateEndCalc?: Date

	/**
	 * Datum úmrtí
	 */
	DeathDateCalc?: Date

	/**
	 * Rodné číslo
	 */
	BirthIdNumberWithSlashCalc?: string

	/**
	 * Osobní číslo
	 */
	PersonalNumberCalc?: number

	/**
	 * Platnost změn k
	 */
	FieldHistoryDateCalc?: Date

	/**
	 * Období uzavření výpočtu
	 */
	ConfirmedPeriodIdCalc?: number

	/**
	 * Datum uzavření výpočtu
	 */
	ConfirmedOnCalc?: Date

	/**
	 * Věk
	 */
	AgeCalc?: number

	/**
	 * První obrázek
	 */
	FirstPictureFileNameCalc?: string

	/**
	 * Číslo cestovního dokladu
	 */
	PassportNumberCalc?: string

	/**
	 * Vydavatel cestovního dokladu
	 */
	PassportAuthorityCalc?: string

	/**
	 * undefined
	 */
	TextHeaderMemoCalc?: string

	/**
	 * undefined
	 */
	TextFooterMemoCalc?: string

	/**
	 * Poslední komentář
	 */
	LastCommentParamCalc?: string

	/**
	 * Trvalý pobyt_ver
	 */
	PermanentAddressId_ver?: number

	/**
	 * Přechodný pobyt_ver
	 */
	TemporaryAddressId_ver?: number

	/**
	 * Kontaktní adresa_ver
	 */
	ContactAddressId_ver?: number

	/**
	 * Plátce důchodu_ver
	 */
	PensionPayerAddressId_ver?: number

	/**
	 * Ulice (Trvalý pobyt)
	 */
	PermanentAddressIdStreetCalc?: string

	/**
	 * Číslo orientační (Trvalý pobyt)
	 */
	PermanentAddressIdOrientationNumberCalc?: string

	/**
	 * Číslo popisné (Trvalý pobyt)
	 */
	PermanentAddressIdHouseNumberCalc?: string

	/**
	 * Název (Trvalý pobyt)
	 */
	PermanentAddressIdNameCalc?: string

	/**
	 * Změněno (Trvalý pobyt)
	 */
	PermanentAddressIdChangedOnCalc?: Date

	/**
	 * PSČ (Trvalý pobyt)
	 */
	PermanentAddressIdTownCodeCalc?: string

	/**
	 * Souřadnice (Trvalý pobyt)
	 */
	PermanentAddressIdCoordinatesCalc?: string

	/**
	 * Ulice (Přechodný pobyt)
	 */
	TemporaryAddressIdStreetCalc?: string

	/**
	 * Číslo orientační (Přechodný pobyt)
	 */
	TemporaryAddressIdOrientationNumberCalc?: string

	/**
	 * Číslo popisné (Přechodný pobyt)
	 */
	TemporaryAddressIdHouseNumberCalc?: string

	/**
	 * Název (Přechodný pobyt)
	 */
	TemporaryAddressIdNameCalc?: string

	/**
	 * Změněno (Přechodný pobyt)
	 */
	TemporaryAddressIdChangedOnCalc?: Date

	/**
	 * PSČ (Přechodný pobyt)
	 */
	TemporaryAddressIdTownCodeCalc?: string

	/**
	 * Souřadnice (Přechodný pobyt)
	 */
	TemporaryAddressIdCoordinatesCalc?: string

	/**
	 * Ulice (Kontaktní adresa)
	 */
	ContactAddressIdStreetCalc?: string

	/**
	 * Číslo orientační (Kontaktní adresa)
	 */
	ContactAddressIdOrientationNumberCalc?: string

	/**
	 * Číslo popisné (Kontaktní adresa)
	 */
	ContactAddressIdHouseNumberCalc?: string

	/**
	 * Název (Kontaktní adresa)
	 */
	ContactAddressIdNameCalc?: string

	/**
	 * Změněno (Kontaktní adresa)
	 */
	ContactAddressIdChangedOnCalc?: Date

	/**
	 * PSČ (Kontaktní adresa)
	 */
	ContactAddressIdTownCodeCalc?: string

	/**
	 * Souřadnice (Kontaktní adresa)
	 */
	ContactAddressIdCoordinatesCalc?: string

	/**
	 * Ulice (Plátce důchodu)
	 */
	PensionPayerAddressIdStreetCalc?: string

	/**
	 * Číslo orientační (Plátce důchodu)
	 */
	PensionPayerAddressIdOrientationNumberCalc?: string

	/**
	 * Číslo popisné (Plátce důchodu)
	 */
	PensionPayerAddressIdHouseNumberCalc?: string

	/**
	 * Název (Plátce důchodu)
	 */
	PensionPayerAddressIdNameCalc?: string

	/**
	 * Změněno (Plátce důchodu)
	 */
	PensionPayerAddressIdChangedOnCalc?: Date

	/**
	 * PSČ (Plátce důchodu)
	 */
	PensionPayerAddressIdTownCodeCalc?: string

	/**
	 * Souřadnice (Plátce důchodu)
	 */
	PensionPayerAddressIdCoordinatesCalc?: string

	/**
	 * Počet vyživovaných osob_ver
	 */
	DependentChildrenCount_ver?: number

	/**
	 * Právo na záznam
	 */
	RightGroupIdCalc?: number

	/**
	 * Kód změny
	 */
	@Type(() => TChangeCodeHINDM)
	LastHISChangeCode?: TChangeCodeHINDM

	/**
	 * Rodný jazyk
	 */
	@Type(() => TTextDM)
	NativeLanguageId?: TTextDM

	/**
	 * Vytvořil
	 */
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM

	/**
	 * Typ
	 */
	@Type(() => Personal)
	TypeId?: Personal

	/**
	 * Org. j. OSSZ
	 */
	@Type(() => OJCSSZ)
	OrganizationUnitPWDsId?: OJCSSZ

	/**
	 * Pohlaví
	 */
	@Type(() => TTextDM)
	SexId?: TTextDM

	/**
	 * Důch. sp. - partner
	 */
	@Type(() => TPartnerDM)
	RetirementSavingPartnerId?: TPartnerDM

	/**
	 * Sleva na invaliditu
	 */
	@Type(() => TTextDM)
	DisabilityId?: TTextDM

	/**
	 * Stát rezidenta
	 */
	@Type(() => TCountryDM)
	ResidentCountryId?: TCountryDM

	/**
	 * Státní příslušnost
	 */
	@Type(() => TCountryDM)
	NationalityCountryId?: TCountryDM

	/**
	 * Stát narození
	 */
	@Type(() => TCountryDM)
	BirthCountryId?: TCountryDM

	/**
	 * Místo narození
	 */
	@Type(() => TTownPartDM)
	BirthTownPartId?: TTownPartDM

	/**
	 * Plátce důchodu
	 */
	@Type(() => TAddressDM)
	PensionPayerAddressId?: TAddressDM

	/**
	 * Kontaktní adresa
	 */
	@Type(() => TAddressDM)
	ContactAddressId?: TAddressDM

	/**
	 * Přechodný pobyt
	 */
	@Type(() => TAddressDM)
	TemporaryAddressId?: TAddressDM

	/**
	 * Trvalý pobyt
	 */
	@Type(() => TAddressDM)
	PermanentAddressId?: TAddressDM

	/**
	 * Změnil
	 */
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM

	/**
	 * Zdravotní pojišťovna
	 */
	@Type(() => TPrDeductionDM)
	HealthInsuranceId?: TPrDeductionDM

	/**
	 * Způsob dopravy
	 */
	@Type(() => ZpusDoprClov)
	TransportMode?: ZpusDoprClov

	/**
	 * Vzdělání
	 */
	@Type(() => DegreeName)
	EducationLevel?: DegreeName

	/**
	 * Rodinný stav
	 */
	@Type(() => MaritalStatus)
	MaritalStatus?: MaritalStatus

	/**
	 * Z
	 */
	@Type(() => TTextDM)
	InvalidRecordImgCalc?: TTextDM

	/**
	 * Změněná prac. schopnost
	 */
	@Type(() => DuvodZmPS)
	WorkingAbility1Calc?: DuvodZmPS

	/**
	 * Druh důchodu
	 */
	@Type(() => DruhDuchPD1)
	PensionType1Calc?: DruhDuchPD1

	/**
	 * Druh důchodu doplň.
	 */
	@Type(() => DruhDuchPD2)
	PensionType2Calc?: DruhDuchPD2

	/**
	 * Kontaktní osoba
	 */
	@Type(() => TContactPersonDM)
	ContactPersonIdCalc?: TContactPersonDM

	/**
	 * Uživatel
	 */
	@Type(() => TK2UserLookupDM)
	K2UserIdCalc?: TK2UserLookupDM

	/**
	 * Hlavní p.p.
	 */
	@Type(() => TPrEmploymentSimpleDM)
	MainEmploymentIdCalc?: TPrEmploymentSimpleDM

	/**
	 * Státní příslušnost rodinného příslušníka
	 */
	@Type(() => TCountryDM)
	DependantCountryIdCalc?: TCountryDM

	/**
	 * Pobočka ÚP
	 */
	@Type(() => EmpDepart)
	EDBranchIdCalc?: EmpDepart

	/**
	 * Typ daňové identifikace
	 */
	@Type(() => TaxNoType)
	TaxNumberTypeIdCalc?: TaxNoType

	/**
	 * Stát, který vydal pas
	 */
	@Type(() => TCountryDM)
	PassportCountryIdCalc?: TCountryDM

	/**
	 * Stát, který vydal OP
	 */
	@Type(() => TCountryDM)
	TaxDocumentBitCalc?: TCountryDM

	/**
	 * Stát, který vydal ŘP
	 */
	@Type(() => TCountryDM)
	DriverLicenceCountryIdCalc?: TCountryDM

	/**
	 * P
	 */
	@Type(() => TTextDM)
	EmploymentValidityDateCalc?: TTextDM

	/**
	 * Kniha mzdových dokladů
	 */
	@Type(() => TBookDM)
	PrDocumentBookIdCalc?: TBookDM

	/**
	 * Hlavní pracovní vztah
	 */
	@Type(() => TPrEmploymentDM)
	MainPrEmploymentIdCalc?: TPrEmploymentDM

	/**
	 * Platnost
	 */
	@Type(() => TTextDM)
	EmploymentValidityBitCalc?: TTextDM

	/**
	 * Nastavené období
	 */
	@Type(() => TPrPeriodDM)
	UserPeriodIdCalc?: TPrPeriodDM

	/**
	 * Stupeň invalidity
	 */
	@Type(() => TTextDM)
	DisabilityLevelIdCalc?: TTextDM

	/**
	 * Zpracované období
	 */
	@Type(() => TPrPeriodDM)
	ProcessedPeriodIdCalc?: TPrPeriodDM

	/**
	 * D
	 */
	@Type(() => TTextDM)
	DocumentExistsCalc?: TTextDM

	/**
	 * undefined
	 */
	@Type(() => TMarketingActivityTypeDM)
	ActivityTypeRIDCalc?: TMarketingActivityTypeDM

	/**
	 * SP
	 */
	@Type(() => TTextDM)
	ProcessStateIdCalc?: TTextDM

	/**
	 * Aktuální proces
	 */
	@Type(() => TWorkflowProcessDM)
	CurrentProcessIdCalc?: TWorkflowProcessDM

	/**
	 * Stát (Trvalý pobyt)
	 */
	@Type(() => TCountryDM)
	PermanentAddressIdCountryIdCalc?: TCountryDM

	/**
	 * Část obce (Trvalý pobyt)
	 */
	@Type(() => TTownPartDM)
	PermanentAddressIdTownPartIdCalc?: TTownPartDM

	/**
	 * Číslo (Trvalý pobyt)
	 */
	@Type(() => TTownDM)
	PermanentAddressIdTownIdCalc?: TTownDM

	/**
	 * Stát (Přechodný pobyt)
	 */
	@Type(() => TCountryDM)
	TemporaryAddressIdCountryIdCalc?: TCountryDM

	/**
	 * Část obce (Přechodný pobyt)
	 */
	@Type(() => TTownPartDM)
	TemporaryAddressIdTownPartIdCalc?: TTownPartDM

	/**
	 * Číslo (Přechodný pobyt)
	 */
	@Type(() => TTownDM)
	TemporaryAddressIdTownIdCalc?: TTownDM

	/**
	 * Stát (Kontaktní adresa)
	 */
	@Type(() => TCountryDM)
	ContactAddressIdCountryIdCalc?: TCountryDM

	/**
	 * Část obce (Kontaktní adresa)
	 */
	@Type(() => TTownPartDM)
	ContactAddressIdTownPartIdCalc?: TTownPartDM

	/**
	 * Číslo (Kontaktní adresa)
	 */
	@Type(() => TTownDM)
	ContactAddressIdTownIdCalc?: TTownDM

	/**
	 * Stát (Plátce důchodu)
	 */
	@Type(() => TCountryDM)
	PensionPayerAddressIdCountryIdCalc?: TCountryDM

	/**
	 * Část obce (Plátce důchodu)
	 */
	@Type(() => TTownPartDM)
	PensionPayerAddressIdTownPartIdCalc?: TTownPartDM

	/**
	 * Číslo (Plátce důchodu)
	 */
	@Type(() => TTownDM)
	PensionPayerAddressIdTownIdCalc?: TTownDM

	/**
	 * Středisko kmenové
	 */
	@Type(() => TCostCentreCollectionDocumentDM)
	CostCentreIdCalc?: TCostCentreCollectionDocumentDM

	/**
	 * Zdravotní pojišťovna_ver
	 */
	@Type(() => TPrDeductionDM)
	HealthInsuranceId_ver?: TPrDeductionDM

	/**
	 * Stupeň invalidity_ver
	 */
	@Type(() => TTextDM)
	DisabilityLevelIdCalc_ver?: TTextDM

	/**
	 * Komentáře
	 */
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]

	/**
	 * Jazyky
	 */
	@Type(() => TLanguageSkillDM)
	LanguageChild?: TLanguageSkillDM[]

	/**
	 * Vzdělání
	 */
	@Type(() => TEducationDM)
	EducationChild?: TEducationDM[]

	/**
	 * Historie zařazení
	 */
	@Type(() => TEmployeeClassificationHistoryDM)
	PersonalHistoryChild?: TEmployeeClassificationHistoryDM[]

	/**
	 * Předchozí zaměstnání
	 */
	@Type(() => TPreviousEmploymentDM)
	PreviousEmploymentChild?: TPreviousEmploymentDM[]

	/**
	 * Rodinní příslušníci
	 */
	@Type(() => TFamilyDM)
	FamilyMemberChild?: TFamilyDM[]

	/**
	 * Kvalifikace
	 */
	@Type(() => TQualificationEmployedPersonDM)
	TrainingChild?: TQualificationEmployedPersonDM[]

	/**
	 * Pracovní vztahy
	 */
	@Type(() => TPrEmploymentDM)
	PrEmploymentChild?: TPrEmploymentDM[]

	/**
	 * Závazky z mezd
	 */
	@Type(() => TPrLiabilityDM)
	PrLiabilityChild?: TPrLiabilityDM[]

	/**
	 * Mzdové doklady
	 */
	@Type(() => TPrEmployeeDocumentDM)
	PrBookDocumentChild?: TPrEmployeeDocumentDM[]

	/**
	 * Mzdové doklady za pracovníka
	 */
	@Type(() => TPrPersonDocumentDM)
	PrPersonDocumentChild?: TPrPersonDocumentDM[]

	/**
	 * Srážky z mezd
	 */
	@Type(() => TPrDeductionAllDM)
	DeductionChild?: TPrDeductionAllDM[]

	/**
	 * Trvalé mzdové složky - za pracovníka, úplný výpis položek
	 */
	@Type(() => TPrDefinitionComponentDM)
	PrComPermanEmpCompleteChild?: TPrDefinitionComponentDM[]

	/**
	 * Termíny pracovníků
	 */
	@Type(() => TWorkerEventAsItemDM)
	WorkerEventChild?: TWorkerEventAsItemDM[]

	/**
	 * Osobní ochranné pracovní prostředky
	 */
	@Type(() => TWorkerProtectionEquipmentAsItemDM)
	WorkerProtectiveEquipmentChild?: TWorkerProtectionEquipmentAsItemDM[]

	/**
	 * undefined
	 */
	@Type(() => TElectronicSubmissionPersonDM)
	ElectronicSubmissionPersonChild?: TElectronicSubmissionPersonDM[]

	/**
	 * Dokumenty
	 */
	@Type(() => TExternalDocumentItemDM)
	ExternalDocumentItemChild?: TExternalDocumentItemDM[]

	/**
	 * Procesy
	 */
	@Type(() => TWorkflowProcessAsItemDM)
	WorkflowProcessChild?: TWorkflowProcessAsItemDM[]

	/**
	 * Text hlavičky
	 */
	@Type(() => TLanguageMemoTranslationDM)
	TextHeaderChild?: TLanguageMemoTranslationDM[]

	/**
	 * Text patičky
	 */
	@Type(() => TLanguageMemoTranslationDM)
	TextFooterChild?: TLanguageMemoTranslationDM[]

	/**
	 * undefined
	 */
	@Type(() => TCompanyLinkEmployedPersonDM)
	CompanyChild?: TCompanyLinkEmployedPersonDM[]

	/**
	 * Svázané doklady
	 */
	@Type(() => TAttachedDocumentDM)
	AttachedDocumentOldChild?: TAttachedDocumentDM[]

	/**
	 * Poznámky
	 */
	@Type(() => TOldNoteDM)
	DeprecatedNoteOldChild?: TOldNoteDM[]

	/**
	 * Smazat záznam
	 */
	public DeletePersCOMMAND() {
		return this.executeCommand('DeletePersCOMMAND')
	}

	/**
	 * Doplnění osobního čísla o znaky zleva
	 */
	public UpdatePersonalNumberCOMMAND(parameters: TUpdatePersonalNumberCOMMANDParams) {
		return this.executeCommand('UpdatePersonalNumberCOMMAND', parameters)
	}

	/**
	 * Vytvoření / aktualizace referenta
	 */
	public BulkCreatePersonalOfficerCOMMAND() {
		return this.executeCommand('BulkCreatePersonalOfficerCOMMAND')
	}

	/**
	 * Vytvoření / aktualizace referenta
	 */
	public CreatePersonalOfficerCOMMAND() {
		return this.executeCommand('CreatePersonalOfficerCOMMAND')
	}

	/**
	 * ISIR
	 */
	public BulkIsirRatingCOMMAND() {
		return this.executeCommand('BulkIsirRatingCOMMAND')
	}

	/**
	 * Nové heslo pro výplatní lístek
	 */
	public PayslipPasswordCOMMAND(parameters: TPayslipPasswordCOMMANDParams) {
		return this.executeCommand('PayslipPasswordCOMMAND', parameters)
	}

	/**
	 * Zneplatnění
	 */
	public BulkInvalidationCOMMAND() {
		return this.executeCommand('BulkInvalidationCOMMAND')
	}

	/**
	 * Zplatnění
	 */
	public BulkUnInvalidationCOMMAND() {
		return this.executeCommand('BulkUnInvalidationCOMMAND')
	}

	/**
	 * Zneplatnění/Zplatnění
	 */
	public InvalidationCOMMAND() {
		return this.executeCommand('InvalidationCOMMAND')
	}

	/**
	 * Potvrzení
	 */
	public BulkConfirmCOMMAND() {
		return this.executeCommand('BulkConfirmCOMMAND')
	}

	/**
	 * Odpotvrzení
	 */
	public BulkUnconfirmCOMMAND() {
		return this.executeCommand('BulkUnconfirmCOMMAND')
	}

	/**
	 * Změna data potvrzení
	 */
	public BulkChangeConfirmationDateCOMMAND(parameters: TBulkChangeConfirmationDateCOMMANDParams) {
		return this.executeCommand('BulkChangeConfirmationDateCOMMAND', parameters)
	}

	/**
	 * Storno
	 */
	public BulkCancelCOMMAND() {
		return this.executeCommand('BulkCancelCOMMAND')
	}

	/**
	 * Odstorno
	 */
	public BulkUnCancelCOMMAND() {
		return this.executeCommand('BulkUnCancelCOMMAND')
	}

	/**
	 * Potvrzení
	 */
	public ConfirmCOMMAND() {
		return this.executeCommand('ConfirmCOMMAND')
	}

	/**
	 * Odpotvrzení
	 */
	public UnconfirmCOMMAND() {
		return this.executeCommand('UnconfirmCOMMAND')
	}

	/**
	 * Změna data potvrzení
	 */
	public ChangeConfirmationDateCOMMAND(parameters: TChangeConfirmationDateCOMMANDParams) {
		return this.executeCommand('ChangeConfirmationDateCOMMAND', parameters)
	}

	/**
	 * Storno/Odstorno
	 */
	public CancelCOMMAND() {
		return this.executeCommand('CancelCOMMAND')
	}

	/**
	 * Zrušení stavu
	 */
	public ClearRecordStatusCOMMAND() {
		return this.executeCommand('ClearRecordStatusCOMMAND')
	}

	/**
	 * Změna stavu
	 */
	public ChangeRecordStatusCOMMAND() {
		return this.executeCommand('ChangeRecordStatusCOMMAND')
	}

	/**
	 * 
	 */
	public ChangeRecordStatusSWSCOMMAND(parameters: TChangeRecordStatusSWSCOMMANDParams) {
		return this.executeCommand('ChangeRecordStatusSWSCOMMAND', parameters)
	}

	/**
	 * Zrušení stavu
	 */
	public BulkClearRecordStatusCOMMAND() {
		return this.executeCommand('BulkClearRecordStatusCOMMAND')
	}

	/**
	 * Změna stavu
	 */
	public BulkChangeRecordStatusCOMMAND() {
		return this.executeCommand('BulkChangeRecordStatusCOMMAND')
	}

	/**
	 * Změna atributů
	 */
	public BulkChangeAttributesCOMMAND(parameters: TBulkChangeAttributesCOMMANDParams) {
		return this.executeCommand('BulkChangeAttributesCOMMAND', parameters)
	}

	/**
	 * Aktualizace sady atributů
	 */
	public BulkActualizeAttributesCOMMAND(parameters: TBulkActualizeAttributesCOMMANDParams) {
		return this.executeCommand('BulkActualizeAttributesCOMMAND', parameters)
	}

	/**
	 * Aktualizace sady atributů
	 */
	public ActualizeAttributesCOMMAND() {
		return this.executeCommand('ActualizeAttributesCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public ChangeGroupRightCOMMAND() {
		return this.executeCommand('ChangeGroupRightCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public BulkChangeGroupRightCOMMAND() {
		return this.executeCommand('BulkChangeGroupRightCOMMAND')
	}

	/**
	 * Aktualizace počítaných polí
	 */
	public BulkUpdateCalcFieldsCOMMAND() {
		return this.executeCommand('BulkUpdateCalcFieldsCOMMAND')
	}

	/**
	 * Kontrola legislativy
	 */
	public SwitchOffLegislationValidationCOMMAND() {
		return this.executeCommand('SwitchOffLegislationValidationCOMMAND')
	}
}

export type TUpdatePersonalNumberCOMMANDParams = {
	/**
	 * Počet znaků
	 */
	CharactersCount?: number
	/**
	 * Znak
	 */
	Character?: string
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TPayslipPasswordCOMMANDParams = {
	/**
	 * Heslo
	 */
	Password?: string
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TBulkChangeConfirmationDateCOMMANDParams = {
	/**
	 * Datum potvrzení
	 */
	Confirmation1Date?: Date
	/**
	 * Hodina potvrzení
	 */
	Confirmation1Time?: Date
	/**
	 * Datum 2. potvrzení
	 */
	Confirmation2Date?: Date
	/**
	 * Hodina 2. potvrzení
	 */
	Confirmation2Time?: Date
	/**
	 * Kontrolovat záporné stavy
	 */
	CheckNegativeState?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TChangeConfirmationDateCOMMANDParams = {
	/**
	 * Datum potvrzení
	 */
	Confirmation1Date?: Date
	/**
	 * Hodina potvrzení
	 */
	Confirmation1Time?: Date
	/**
	 * Datum 2. potvrzení
	 */
	Confirmation2Date?: Date
	/**
	 * Hodina 2. potvrzení
	 */
	Confirmation2Time?: Date
	/**
	 * Kontrolovat záporné stavy
	 */
	CheckNegativeState?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TChangeRecordStatusSWSCOMMANDParams = {
	/**
	 * StatusId
	 */
	StatusId?: number
}

export type TBulkChangeAttributesCOMMANDParams = {
	/**
	 * Smazat atributy
	 */
	DeleteAttribute?: boolean
	/**
	 * Smazat všechny volné atributy
	 */
	DeleteFreeAttribute?: boolean
	/**
	 * Nastavit hodnoty atributů
	 */
	SetValues?: boolean
	/**
	 * Přidat nepřipojené atributy
	 */
	AddFreeAttributes?: boolean
	/**
	 * FreeAttributeEnabled
	 */
	FreeAttributeEnabled?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TBulkActualizeAttributesCOMMANDParams = {
	/**
	 * Aktualizovat včetně samotné sady atributů
	 */
	ActualizeAttributeSet?: boolean
	/**
	 * MessageText
	 */
	MessageText?: string
}