import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TResourceDM from './TResourceDM'

/**
 * TSubordinateResourceDM
 */
export default class TSubordinateResourceDM extends Data {
	public static className = 'TSubordinateResourceDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'ItemRIDCalc',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = false

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * Dodatek
	 */
	SupplementId?: number

	/**
	 * C_Zbo
	 */
	ArticleId?: number

	/**
	 * Proc
	 */
	Percentage?: number

	/**
	 * Kniha2
	 */
	Book2?: string

	/**
	 * Ci1
	 */
	DocumentRID1?: number

	/**
	 * Kniha1
	 */
	Book1?: string

	/**
	 * Typ
	 */
	RecordTypeId?: number

	/**
	 * undefined
	 */
	RecordStateImgCalc?: string

	/**
	 * ItemRIDCalc
	 */
	ItemRIDCalc?: number

	/**
	 * RecordModeCalc
	 */
	RecordModeCalc?: number

	/**
	 * Ci2
	 */
	@Type(() => TResourceDM)
	DocumentRID2?: TResourceDM
}