import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TTextDM from './TTextDM'
import TAttendanceWorkShiftTypeDM from './TAttendanceWorkShiftTypeDM'
import TPrCalendarDM from './TPrCalendarDM'
import TAttendanceScheduleDM from './TAttendanceScheduleDM'
import TK2UserLookupDM from './TK2UserLookupDM'
import TPrEmploymentDM from './TPrEmploymentDM'
import TContactPersonDM from './TContactPersonDM'
import TAttendanceMovementSubTypeDM from './TAttendanceMovementSubTypeDM'
import TCommentDM from './TCommentDM'
import TAttendanceProcessedMovementDM from './TAttendanceProcessedMovementDM'
import TAttendanceWorkTimeReportItemAccountDM from './TAttendanceWorkTimeReportItemAccountDM'
import TAttendanceProcessedIntervalDM from './TAttendanceProcessedIntervalDM'
import TAttendanceAuxiliaryIntervalDM from './TAttendanceAuxiliaryIntervalDM'
import TAttendanceTaskWorkDM from './TAttendanceTaskWorkDM'
import TAttendanceProcessedCalendarItemDM from './TAttendanceProcessedCalendarItemDM'
import TExternalDocumentItemDM from './TExternalDocumentItemDM'
import TLinkedDocumentDM from './TLinkedDocumentDM'

/**
 * Dny pracovního listu
 */
export default class TAttendanceWorkTimeReportItemDM extends Data {
	public static className = 'TAttendanceWorkTimeReportItemDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = true

	/**
	 * Ručně editováno
	 */
	IsUserEdited?: string

	/**
	 * Poznámka
	 */
	Note?: string

	/**
	 * Po konci
	 */
	AfterEnd?: number

	/**
	 * Před začátkem
	 */
	BeforeStart?: number

	/**
	 * Brzký odchod
	 */
	IsEarlyDeparture?: string

	/**
	 * Pozdní příchod
	 */
	IsLateArrival?: string

	/**
	 * Pozdní odchod
	 */
	IsLateDeparture?: string

	/**
	 * Brzký příchod
	 */
	IsEarlyArrival?: string

	/**
	 * Fond pracovní doby
	 */
	WorkFundDays?: number

	/**
	 * Svátek
	 */
	IsHoliday?: string

	/**
	 * Saldo
	 */
	Balance?: number

	/**
	 * Absence celkem
	 */
	Absence?: number

	/**
	 * Přestávky celkem
	 */
	BreakTime?: number

	/**
	 * Odpracováno celkem
	 */
	WorkedOut?: number

	/**
	 * Fond pracovní doby
	 */
	WorkFund?: number

	/**
	 * Přesčasová směna
	 */
	IsOvertimeShift?: string

	/**
	 * Stornováno
	 */
	IsCanceledRecord?: string

	/**
	 * Datum potvrzení
	 */
	ConfirmedOn?: Date

	/**
	 * Kniha
	 */
	BookId?: number

	/**
	 * Datum
	 */
	Date?: Date

	/**
	 * Pracovní list
	 */
	WorkTimeReportRID?: number

	/**
	 * Skupina práv
	 */
	RightGroupId?: number

	/**
	 * Časové razítko
	 */
	TimeStamp?: string

	/**
	 * Změněno
	 */
	ChangedOn?: Date

	/**
	 * Vytvořeno
	 */
	CreatedOn?: Date

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Den
	 */
	DayCalc?: number

	/**
	 * Kumulované saldo
	 */
	CumulatedBalanceCalc?: number

	/**
	 * Zpracované průchody
	 */
	AttendanceMovementsDescriptionCalc?: string

	/**
	 * Popis absencí
	 */
	AbsenceDescriptionCalc?: string

	/**
	 * Minimální od
	 */
	PlanMinStartTimeCalc?: Date

	/**
	 * Minimální do
	 */
	PlanMinEndTimeCalc?: Date

	/**
	 * Smluvní od
	 */
	PlanContractStartTimeCalc?: Date

	/**
	 * Smluvní do
	 */
	PlanContractEndTimeCalc?: Date

	/**
	 * Maximální od
	 */
	PlanMaxStartTimeCalc?: Date

	/**
	 * Maximální do
	 */
	PlanMaxEndTimeCalc?: Date

	/**
	 * Zkratka dne
	 */
	AbbrCalc?: string

	/**
	 * Poslední komentář
	 */
	LastCommentParamCalc?: string

	/**
	 * Odpracováno
	 */
	WorkedOutCalc?: number

	/**
	 * Absence
	 */
	AbsenceCalc?: number

	/**
	 * Saldo
	 */
	BalanceCalc?: number

	/**
	 * Přestávky
	 */
	BreakTimeCalc?: number

	/**
	 * Odpracováno v pracovní směně
	 */
	WorkTimeHoursCountCalc?: number

	/**
	 * Odpracovnáno dnů
	 */
	WorkTimeDaysCountCalc?: number

	/**
	 * Návštěva lékaře
	 */
	DoctorVisitHoursCountCalc?: number

	/**
	 * Dovolená
	 */
	LeaveHoursCountCalc?: number

	/**
	 * Náhradní volno za přesčas
	 */
	OverTimeCompensTimeOffCountCalc?: number

	/**
	 * Náhradní volno za svátek
	 */
	HolidayCompensTimeOffCountCalc?: number

	/**
	 * Práce přesčas
	 */
	WorkOverTimeHoursCountCalc?: number

	/**
	 * Pohotovost
	 */
	ReadinessHoursCountCalc?: number

	/**
	 * Příplatek za přesčas
	 */
	OverTimeExcessFareHoursCountCalc?: number

	/**
	 * Příplatek za noční
	 */
	NightWorkExcessFareHoursCountCalc?: number

	/**
	 * Příplatek za odpolední
	 */
	AfternoonWorkExcessFareHoursCountCalc?: number

	/**
	 * Příplatek za so+ne
	 */
	WeekendWorkExcessFareHoursCountCalc?: number

	/**
	 * Příplatek za svátek
	 */
	HolidayExcessFareHoursCountCalc?: number

	/**
	 * Nárok na stravenky
	 */
	MealTicketClaimCalc?: number

	/**
	 * Přestávka na jídlo a oddech
	 */
	BreakLunchTimeCalc?: number

	/**
	 * Služební cesta
	 */
	OfficialJourneyCalc?: number

	/**
	 * Pracovní neschopnost
	 */
	IllnessCalc?: number

	/**
	 * Ošetřování člena rodiny
	 */
	NursingCalc?: number

	/**
	 * Bezpečnostní přestávka
	 */
	SafetyBreakCalc?: number

	/**
	 * Neomluvená absence
	 */
	UnexcusedAbsenceHoursCountCalc?: number

	/**
	 * Sick days
	 */
	SickDaysHoursCountCalc?: number

	/**
	 * Školení
	 */
	TrainingHoursCountCalc?: number

	/**
	 * Povětrnostní vlivy
	 */
	WeatherConditionsHoursCountCalc?: number

	/**
	 * Přerušení práce
	 */
	WorkInterruptionHoursCountCalc?: number

	/**
	 * Prostoje
	 */
	DowntimeHoursCountCalc?: number

	/**
	 * Překážka zaměstnavatele
	 */
	EmployerObstacleHoursCountCalc?: number

	/**
	 * Svatba
	 */
	WeddingHoursCountCalc?: number

	/**
	 * Odběr krve
	 */
	BloodCollectionHoursCountCalc?: number

	/**
	 * Pohřeb
	 */
	FuneralHoursCountCalc?: number

	/**
	 * Úkolová mzda
	 */
	TaskSalaryHoursCountCalc?: number

	/**
	 * Úkolová mzda
	 */
	TaskSalaryAmountCalc?: number

	/**
	 * Prémie
	 */
	BonusAmountCalc?: number

	/**
	 * První příchod
	 */
	FirstArrivalCalc?: Date

	/**
	 * Poslední odchod
	 */
	LastDepartureCalc?: Date

	/**
	 * Nepřítomnost bez náhrady mzdy
	 */
	NoCompensationAbsenceHoursCountCalc?: number

	/**
	 * Neplacené volno
	 */
	UnpaidVacationHoursCountCalc?: number

	/**
	 * Homeoffice
	 */
	HomeOfficeHoursCountCalc?: number

	/**
	 * Placený svátek
	 */
	PaidHolidayHoursCountCalc?: number

	/**
	 * Započteno odpracováno ve svátek
	 */
	HolidayWorkHoursCountCalc?: number

	/**
	 * Náhradní volno ze salda
	 */
	BalanceCompensTimeOffCountCalc?: number

	/**
	 * Skupina práv
	 */
	RightGroupIdCalc?: number

	/**
	 * Typ chyby
	 */
	@Type(() => TTextDM)
	ErrorTypeId?: TTextDM

	/**
	 * Typ směny v modelu pracovní doby
	 */
	@Type(() => TAttendanceWorkShiftTypeDM)
	AttendanceWorkShiftTypeRID?: TAttendanceWorkShiftTypeDM

	/**
	 * Model pracovní doby
	 */
	@Type(() => TPrCalendarDM)
	WHModelId?: TPrCalendarDM

	/**
	 * Harmonogram směn
	 */
	@Type(() => TAttendanceScheduleDM)
	AttendanceScheduleRID?: TAttendanceScheduleDM

	/**
	 * Stornoval
	 */
	@Type(() => TK2UserLookupDM)
	CanceledById?: TK2UserLookupDM

	/**
	 * Potvrdil
	 */
	@Type(() => TK2UserLookupDM)
	ConfirmedById?: TK2UserLookupDM

	/**
	 * Pracovní vztah
	 */
	@Type(() => TPrEmploymentDM)
	PrEmploymentId?: TPrEmploymentDM

	/**
	 * Kontaktní osoba
	 */
	@Type(() => TContactPersonDM)
	ContactPersonId?: TContactPersonDM

	/**
	 * Změnil
	 */
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM

	/**
	 * Vytvořil
	 */
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM

	/**
	 * Typ odchodu předchozího dne
	 */
	@Type(() => TAttendanceMovementSubTypeDM)
	LastDepartureTypeIdCalc?: TAttendanceMovementSubTypeDM

	/**
	 * P
	 */
	@Type(() => TTextDM)
	ConfirmedOrCanceledIdCalc?: TTextDM

	/**
	 * Komentáře
	 */
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]

	/**
	 * undefined
	 */
	@Type(() => TAttendanceScheduleDM)
	AttendanceScheduleChild?: TAttendanceScheduleDM[]

	/**
	 * undefined
	 */
	@Type(() => TAttendanceProcessedMovementDM)
	AttendanceProcessedMovementChild?: TAttendanceProcessedMovementDM[]

	/**
	 * undefined
	 */
	@Type(() => TAttendanceWorkTimeReportItemAccountDM)
	AttendanceWorkTimeReportItemAccountsChild?: TAttendanceWorkTimeReportItemAccountDM[]

	/**
	 * Zpracované intervaly
	 */
	@Type(() => TAttendanceProcessedIntervalDM)
	AttendanceProcessedIntervalChild?: TAttendanceProcessedIntervalDM[]

	/**
	 * undefined
	 */
	@Type(() => TAttendanceAuxiliaryIntervalDM)
	AttendanceAuxiliaryIntervalChild?: TAttendanceAuxiliaryIntervalDM[]

	/**
	 * undefined
	 */
	@Type(() => TAttendanceTaskWorkDM)
	AttendanceTaskWorkChild?: TAttendanceTaskWorkDM[]

	/**
	 * undefined
	 */
	@Type(() => TAttendanceProcessedCalendarItemDM)
	AttendanceProcessedCalendarItemChild?: TAttendanceProcessedCalendarItemDM[]

	/**
	 * Dokumenty
	 */
	@Type(() => TExternalDocumentItemDM)
	ExternalDocumentItemChild?: TExternalDocumentItemDM[]

	/**
	 * Doklady
	 */
	@Type(() => TLinkedDocumentDM)
	AttachedDocument?: TLinkedDocumentDM[]

	/**
	 * Storno položky
	 */
	public BulkCancelItemCOMMAND() {
		return this.executeCommand('BulkCancelItemCOMMAND')
	}

	/**
	 * Odstorno položky
	 */
	public BulkUnCancelItemCOMMAND() {
		return this.executeCommand('BulkUnCancelItemCOMMAND')
	}

	/**
	 * První potvrzení položky
	 */
	public BulkFirstConfirmItemCOMMAND() {
		return this.executeCommand('BulkFirstConfirmItemCOMMAND')
	}

	/**
	 * Potvrzení položky
	 */
	public BulkConfirmItemCOMMAND() {
		return this.executeCommand('BulkConfirmItemCOMMAND')
	}

	/**
	 * Odpotvrzení položky
	 */
	public BulkUnConfirmItemCOMMAND() {
		return this.executeCommand('BulkUnConfirmItemCOMMAND')
	}

	/**
	 * Storno položky
	 */
	public CancelItemCOMMAND() {
		return this.executeCommand('CancelItemCOMMAND')
	}

	/**
	 * Odstorno položky
	 */
	public UnCancelItemCOMMAND() {
		return this.executeCommand('UnCancelItemCOMMAND')
	}

	/**
	 * První potvrzení položky
	 */
	public FirstConfirmItemCOMMAND() {
		return this.executeCommand('FirstConfirmItemCOMMAND')
	}

	/**
	 * Potvrzení položky
	 */
	public ConfirmItemCOMMAND() {
		return this.executeCommand('ConfirmItemCOMMAND')
	}

	/**
	 * Odpotvrzení položky
	 */
	public UnConfirmItemCOMMAND() {
		return this.executeCommand('UnConfirmItemCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public ChangeGroupRightCOMMAND() {
		return this.executeCommand('ChangeGroupRightCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public BulkChangeGroupRightCOMMAND() {
		return this.executeCommand('BulkChangeGroupRightCOMMAND')
	}

	/**
	 * Aktualizace počítaných polí
	 */
	public BulkUpdateCalcFieldsCOMMAND() {
		return this.executeCommand('BulkUpdateCalcFieldsCOMMAND')
	}

	/**
	 * Kontrola legislativy
	 */
	public SwitchOffLegislationValidationCOMMAND() {
		return this.executeCommand('SwitchOffLegislationValidationCOMMAND')
	}
}