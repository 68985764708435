import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TRNCourse from './TRNCourse'
import TRNTyp from './TRNTyp'
import TPeriodicityDM from './TPeriodicityDM'
import TK2UserLookupDM from './TK2UserLookupDM'
import TEmployedPersonDM from './TEmployedPersonDM'
import TCommentDM from './TCommentDM'
import TExternalDocumentItemDM from './TExternalDocumentItemDM'
import TCompanyLinkDM from './TCompanyLinkDM'

/**
 * Termíny pracovníků
 */
export default class TWorkerEventAsItemDM extends Data {
	public static className = 'TWorkerEventAsItemDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = false

	/**
	 * Absolvováno
	 */
	CompletionDate?: Date

	/**
	 * Popis
	 */
	Description?: string

	/**
	 * Platnost do
	 */
	ValidTo?: Date

	/**
	 * Změněno
	 */
	ChangedOn?: Date

	/**
	 * Vytvořeno
	 */
	CreatedOn?: Date

	/**
	 * Časové razítko
	 */
	TimeStamp?: string

	/**
	 * Právo na záznam
	 */
	RightGroupid?: number

	/**
	 * Datum
	 */
	Date?: Date

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Poslední komentář
	 */
	LastCommentParamCalc?: string

	/**
	 * Právo na záznam
	 */
	RightGroupIdCalc?: number

	/**
	 * Kurz
	 */
	@Type(() => TRNCourse)
	CourseId?: TRNCourse

	/**
	 * Typ
	 */
	@Type(() => TRNTyp)
	TypeId?: TRNTyp

	/**
	 * Periodicita
	 */
	@Type(() => TPeriodicityDM)
	PeriodicityRID?: TPeriodicityDM

	/**
	 * Změnil
	 */
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM

	/**
	 * Vytvořil
	 */
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM

	/**
	 * Pracovník
	 */
	@Type(() => TEmployedPersonDM)
	WorkerId?: TEmployedPersonDM

	/**
	 * Komentáře
	 */
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]

	/**
	 * Dokumenty
	 */
	@Type(() => TExternalDocumentItemDM)
	ExternalDocumentItemChild?: TExternalDocumentItemDM[]

	/**
	 * undefined
	 */
	@Type(() => TCompanyLinkDM)
	CompanyChild?: TCompanyLinkDM[]
}