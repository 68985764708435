import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TTextDM from './TTextDM'
import TPriceListDM from './TPriceListDM'

/**
 * Průběh výpočtu ceny
 */
export default class TPriceListCalculationDM extends Data {
	public static className = 'TPriceListCalculationDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = false

	/**
	 * Poslední ceník
	 */
	IsLastPriceList?: string

	/**
	 * Zaokrouhlení
	 */
	Rounding?: number

	/**
	 * Pořadí
	 */
	SequenceNumber?: number

	/**
	 * Zdroj
	 */
	StepSourceRID?: number

	/**
	 * %
	 */
	IsPercentage?: string

	/**
	 * Sleva
	 */
	Reduction?: number

	/**
	 * Cena
	 */
	PriceNet?: number

	/**
	 * Identifikátor položky
	 */
	ItemRID?: number

	/**
	 * Typ položky
	 */
	ItemTypeId?: number

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * undefined
	 */
	StepSourceCalc?: string

	/**
	 * Krok
	 */
	@Type(() => TTextDM)
	StepTypeId?: TTextDM

	/**
	 * Ceník
	 */
	@Type(() => TPriceListDM)
	PriceListRIDCalc?: TPriceListDM
}