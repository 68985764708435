import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TBankOldDM from './TBankOldDM'
import TK2UserLookupDM from './TK2UserLookupDM'
import TLegislationDm from './TLegislationDm'

/**
 * Knihy
 */
export default class TCachedBookDM extends Data {
	public static className = 'TCachedBookDM'
	public static primaryKey = 'ID'
	public static systemFields = [
	]

	public getPrimaryKey(): PrimaryKey {
		return this.ID!
	}

	public static isHeader = true

	/**
	 * Modul knihy
	 */
	BookModule?: number

	/**
	 * Číslo
	 */
	ID?: number

	/**
	 * Zkratka
	 */
	Abbr?: string

	/**
	 * Firma
	 */
	CompanyId?: number

	/**
	 * Popis
	 */
	Description?: string

	/**
	 * Jazykový popis
	 */
	LocalizedDescription?: string

	/**
	 * Skupina pro právo
	 */
	RightGroup?: number

	/**
	 * Změněno
	 */
	DLCH?: Date

	/**
	 * CollectionItem
	 */
	CollectionItem?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * Skupina pro právo
	 */
	@Type(() => TBankOldDM)
	RightGroupStr?: TBankOldDM

	/**
	 * Změnil
	 */
	@Type(() => TK2UserLookupDM)
	DLCH_Who?: TK2UserLookupDM

	/**
	 * Legislativa
	 */
	@Type(() => TLegislationDm)
	LegislationId?: TLegislationDm
}