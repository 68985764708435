import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TWarehouseDM from './TWarehouseDM'

/**
 * Prodeje forecast
 */
export default class TCustomTArticleDMProdeje_forecast extends Data {
	public static className = 'TCustomTArticleDMProdeje_forecast'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = false

	/**
	 * Master
	 */
	Master?: number

	/**
	 * Na týdnů
	 */
	Na_tydnu?: number

	/**
	 * Návrh forecast
	 */
	Navrh_forecast?: number

	/**
	 * Do data
	 */
	Do_data?: Date

	/**
	 * Od data
	 */
	Od_data?: Date

	/**
	 * Prodáno suma
	 */
	Prodano_suma?: number

	/**
	 * Byl pohyb - počet zákazníků
	 */
	EX_L_Byl_pohyb_baz?: number

	/**
	 * Byl pohyb - počet výdejek
	 */
	EX_L_Byl_pohyb?: number

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * RecordMod
	 */
	RecordMod?: number

	/**
	 * RecordES
	 */
	RecordES?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * RecordState
	 */
	RecordState?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Sklad
	 */
	@Type(() => TWarehouseDM)
	Sklad?: TWarehouseDM
}