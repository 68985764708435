import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TAdressKeyDM from './TAdressKeyDM'
import TTradingPartnerDM from './TTradingPartnerDM'
import TTextDM from './TTextDM'
import AlternativeUnit from './AlternativeUnit'
import TArticleDM from './TArticleDM'

/**
 * Zboží
 */
export default class TEkoKomPackagingUsageDM extends Data {
	public static className = 'TEkoKomPackagingUsageDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = false

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * Propojení modulů
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * Popis
	 */
	Weight?: number

	/**
	 * Propojení modulů
	 */
	RID?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Konec
	 */
	@Type(() => TAdressKeyDM)
	CustomerKeyRID?: TAdressKeyDM

	/**
	 * Začátek
	 */
	@Type(() => TTradingPartnerDM)
	TradingPartnerId?: TTradingPartnerDM

	/**
	 * Lektor
	 */
	@Type(() => TTextDM)
	ArticlePackTypeId?: TTextDM

	/**
	 * Pobočka
	 */
	@Type(() => AlternativeUnit)
	MeasureUnitId?: AlternativeUnit

	/**
	 * Místo
	 */
	@Type(() => TArticleDM)
	ArticleId?: TArticleDM
}