import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TExternalBatchDM from './TExternalBatchDM'
import TScheduledServiceOperationDM from './TScheduledServiceOperationDM'
import TServicedDeviceDM from './TServicedDeviceDM'
import TCode6DocumentDM from './TCode6DocumentDM'
import TCode5DocumentDM from './TCode5DocumentDM'
import TCode4DocumentDM from './TCode4DocumentDM'
import TCode3DocumentDM from './TCode3DocumentDM'
import TCodeTypeDM from './TCodeTypeDM'
import TMatchingSymbolDM from './TMatchingSymbolDM'
import TCostCentreCollectionDocumentDM from './TCostCentreCollectionDocumentDM'
import TDeviceDocumentDM from './TDeviceDocumentDM'
import THandlingUnitArticleDM from './THandlingUnitArticleDM'
import TBookDM from './TBookDM'
import TTradingPartnerDM from './TTradingPartnerDM'
import TTaxTypeDM from './TTaxTypeDM'
import TContractCodeDocumentDM from './TContractCodeDocumentDM'
import TNomenclatureDM from './TNomenclatureDM'
import TAccountingChartDM from './TAccountingChartDM'
import TRoutingVariantDM from './TRoutingVariantDM'
import PlanType from './PlanType'
import TWarehouseDM from './TWarehouseDM'
import TCode2DocumentDM from './TCode2DocumentDM'
import TCode1DocumentDM from './TCode1DocumentDM'
import TOfficerDocumentDM from './TOfficerDocumentDM'
import TRequestedBatchParameterHeaderDM from './TRequestedBatchParameterHeaderDM'
import TLocationArticleDM from './TLocationArticleDM'
import TCountryDM from './TCountryDM'
import TArticleCodeDocumentDM from './TArticleCodeDocumentDM'
import TVATRateDM from './TVATRateDM'
import TMeasureUnitDM from './TMeasureUnitDM'
import TBatchDM from './TBatchDM'
import GoodsName from './GoodsName'
import TArticleDM from './TArticleDM'
import TSalesOrderDM from './TSalesOrderDM'
import TCurrencyDM from './TCurrencyDM'
import TTextDM from './TTextDM'
import TPriceGroupDM from './TPriceGroupDM'
import TParameterSetDM from './TParameterSetDM'
import KindAccounting from './KindAccounting'
import JCDObchod from './JCDObchod'
import JCDP20_a1 from './JCDP20_a1'
import SpecialMove from './SpecialMove'
import TransportKind from './TransportKind'
import TIntrastatTariffDutyDM from './TIntrastatTariffDutyDM'
import TPriceListDM from './TPriceListDM'
import TSupplierPriceDM from './TSupplierPriceDM'
import TLoyaltyProgramDM from './TLoyaltyProgramDM'
import TCommentDM from './TCommentDM'
import TItemNoteDM from './TItemNoteDM'
import TSerialNumberItemDM from './TSerialNumberItemDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TAssetLinkDM from './TAssetLinkDM'
import TAttachedBatchParameterValueDM from './TAttachedBatchParameterValueDM'
import TAttachedRequestedBatchParameterDM from './TAttachedRequestedBatchParameterDM'
import TAssetConnectedDocumentDM from './TAssetConnectedDocumentDM'
import TCustomTPurchaseItemDMGrid_Vybrani_ceny from './TCustomTPurchaseItemDMGrid_Vybrani_ceny'

/**
 * Položky nákupu
 */
export default class TPurchaseItemDM extends Data {
	public static className = 'TPurchaseItemDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = false

	/**
	 * Původní cena fakturovaná
	 */
	InvoiceOriginalUnitPrice?: number

	/**
	 * Původní cena plánovaná
	 */
	PlannedOriginalUnitPrice?: number

	/**
	 * 1. potvrzení dodání
	 */
	FirstDelyConfirmationDate?: Date

	/**
	 * Objednání
	 */
	OrderingDate?: Date

	/**
	 * Hlavní položka
	 */
	MainItemRID?: number

	/**
	 * Časové razítko
	 */
	TimeStamp?: string

	/**
	 * Sleva F
	 */
	InvoiceDiscount?: number

	/**
	 * Sleva (&g)
	 */
	PlannedDiscount?: number

	/**
	 * Servisní list
	 */
	ServiceNoteRID?: number

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * Příjemka
	 */
	ReceiptCardRID?: number

	/**
	 * Potvrzení dodání
	 */
	DeliveryConfirmationRID?: number

	/**
	 * Faktura přijatá
	 */
	InvoiceInRID?: number

	/**
	 * Objednávka vydaná
	 */
	PurchaseOrderRID?: number

	/**
	 * XmlData
	 */
	XmlData?: string

	/**
	 * Záruka
	 */
	Warranty?: number

	/**
	 * Datum přechodu hranice
	 */
	BorderCrossingDate?: Date

	/**
	 * Pl. vedlejší náklady
	 */
	PlannedIncidentalCost?: number

	/**
	 * Čistá skladová cena
	 */
	CostFreeStockPrice?: number

	/**
	 * Spotřební daň
	 */
	ExciseTaxId?: number

	/**
	 * Pož. termín
	 */
	DesiredDeliveryDate?: Date

	/**
	 * Datum potvrzení dodání
	 */
	DeliveryDate?: Date

	/**
	 * UnitCost
	 */
	UnitCostPrice?: number

	/**
	 * # položky
	 */
	OriginalNoReceiptCard?: number

	/**
	 * # položky
	 */
	OriginalNoPurchaseOrder?: number

	/**
	 * # položky
	 */
	OriginalNoInvoiceIn?: number

	/**
	 * # položky
	 */
	OriginalNoDeliveryConfirmation?: number

	/**
	 * PolP
	 */
	NoteId?: number

	/**
	 * Ce_JePl
	 */
	PlannedUnitPrice?: number

	/**
	 * Kontace P
	 */
	ReceiptCardPostingKeyId?: number

	/**
	 * Kontace F
	 */
	InvoicePostingKeyId?: number

	/**
	 * V_Nakl
	 */
	IncidentalCost?: number

	/**
	 * SkC
	 */
	StockPrice?: number

	/**
	 * Ce_Jedn
	 */
	InvoiceUnitPrice?: number

	/**
	 * Množství
	 */
	Quantity?: number

	/**
	 * Prijem
	 */
	Tag?: number

	/**
	 * DatP
	 */
	ConfirmedOn?: Date

	/**
	 * C_Pol
	 */
	ItemNumber?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * EX_konv_Mnoz
	 */
	EX_konv_Mnoz?: number

	/**
	 * Ve výrobě
	 */
	EX_Vyroba?: number

	/**
	 * EX_ObjAut
	 */
	EX_ObjAut?: string

	/**
	 * EX_Pomocne_CrossN
	 */
	EX_Pomocne_CrossN?: string

	/**
	 * EX_KoefNavys
	 */
	EX_KoefNavys?: number

	/**
	 * EX_Steel_CelKurz
	 */
	EX_Steel_CelKurz?: number

	/**
	 * EX_Ne_KO
	 */
	EX_Ne_KO?: string

	/**
	 * EX_Palet
	 */
	EX_Palet?: string

	/**
	 * Pův. pot.množ
	 */
	EX_Puv_popt?: number

	/**
	 * EX_Cena_zW
	 */
	EX_Cena_zW?: number

	/**
	 * EX_Cena_nab
	 */
	EX_Cena_nab?: number

	/**
	 * EX_Imp_PW
	 */
	EX_Imp_PW?: number

	/**
	 * Objednat2
	 */
	EX_Objednat2?: number

	/**
	 * Cena PW
	 */
	EX_Cena_PW?: number

	/**
	 * Poptat PW
	 */
	EX_Poptat_PW?: number

	/**
	 * Objednano
	 */
	EX_Objednano?: number

	/**
	 * Objednat
	 */
	EX_Objednat?: number

	/**
	 * undefined
	 */
	IsCached?: string

	/**
	 * undefined
	 */
	ExchangeRate?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Kurz hl.
	 */
	ExchangeRateCalc?: number

	/**
	 * Kurz fa.
	 */
	InvoiceExchangeRateCalc?: number

	/**
	 * &Množství
	 */
	QuantityUMCalc?: number

	/**
	 * Koef.dph
	 */
	VATCoefficientCalc?: number

	/**
	 * DPH
	 */
	InvoicePriceVATCalc?: number

	/**
	 * Netto pl./MJ
	 */
	PlannedUnitPriceNetCalc?: number

	/**
	 * Netto fa./MJ
	 */
	InvoiceUnitPriceNetCalc?: number

	/**
	 * Skl.cena/MJ
	 */
	StockUnitPriceCalc?: number

	/**
	 * V.nákl./MJ
	 */
	UnitIncidentalCostCalc?: number

	/**
	 * Netto pl.
	 */
	PlannedTotalPriceNetCalc?: number

	/**
	 * Netto fa.
	 */
	InvoiceTotalPriceNetCalc?: number

	/**
	 * Skl.cena
	 */
	StockTotalPriceCalc?: number

	/**
	 * V.nákl.
	 */
	TotalIncidentalCostCalc?: number

	/**
	 * Plán. &cena netto / MJ
	 */
	PlannedUnitPriceNetCCalc?: number

	/**
	 * Faktur. &cena netto / MJ
	 */
	InvoiceUnitPriceNetCCalc?: number

	/**
	 * Skladová &cena / MJ
	 */
	StockUnitPriceCCalc?: number

	/**
	 * Vedlejší náklady / MJ
	 */
	UnitIncidentalCostCCalc?: number

	/**
	 * Plán. cena netto celkem
	 */
	PlannedTotalPriceNetCCalc?: number

	/**
	 * Faktur. cena netto celkem
	 */
	InvoiceTotalPriceNetCCalc?: number

	/**
	 * Skladová cena celkem
	 */
	StockTotalPriceCCalc?: number

	/**
	 * Vedlejší náklady
	 */
	TotalIncidentalCostCCalc?: number

	/**
	 * Brutto pl./MJ
	 */
	PlannedUnitPriceGrossCalc?: number

	/**
	 * Brutto fa./MJ
	 */
	InvoiceUnitPriceGrossCalc?: number

	/**
	 * Brutto pl.
	 */
	PlannedTotalPriceGrossCalc?: number

	/**
	 * Brutto fa.
	 */
	InvoiceTotalPriceGrossCalc?: number

	/**
	 * Plán. &cena brutto / MJ
	 */
	PlannedUnitPriceGrossCCalc?: number

	/**
	 * Faktur. &cena brutto / MJ
	 */
	InvoiceUnitPriceGrossCCalc?: number

	/**
	 * Plán. cena brutto celkem
	 */
	PlannedTotalPriceGrossCCalc?: number

	/**
	 * Brutto fa.
	 */
	InvoiceTotalPriceGrossCCalc?: number

	/**
	 * Název
	 */
	NameCalc?: string

	/**
	 * Marže pl./MJ
	 */
	PlannedMarginCalc?: number

	/**
	 * Marže fa./MJ
	 */
	InvoiceMarginCalc?: number

	/**
	 * Marže pl./MJ
	 */
	PlannedMarginCCalc?: number

	/**
	 * Marže fa./MJ
	 */
	InvoiceMarginCCalc?: number

	/**
	 * Marže pl. %
	 */
	PlannedMarginPctCalc?: number

	/**
	 * Marže fa. %
	 */
	InvoiceMarginPctCalc?: number

	/**
	 * Množství
	 */
	PositiveQuantityCalc?: number

	/**
	 * Název celý
	 */
	FullNameCalc?: string

	/**
	 * Množství
	 */
	ActualQuantityRNCalc?: number

	/**
	 * Částka SD
	 */
	ExciseTaxAmountCalc?: number

	/**
	 * Faktur. cena netto / set
	 */
	SetInvPriceCalc?: number

	/**
	 * Faktur. cena netto / set / MJ
	 */
	SetInvUnitPriceCalc?: number

	/**
	 * Faktur. cena netto / set / MJ
	 */
	SetInvUnitPriceCCalc?: number

	/**
	 * Faktur. cena netto
	 */
	SetInvTotalPriceCalc?: number

	/**
	 * Faktur. cena netto
	 */
	SetInvTotalPriceCCalc?: number

	/**
	 * Faktur. cena brutto / set
	 */
	SetInvPriceGrossCalc?: number

	/**
	 * Faktur. cena brutto / set / MJ
	 */
	SetInvUnitPriceGrossCalc?: number

	/**
	 * Faktur. cena brutto / set / MJ
	 */
	SetInvUnitPriceGrossCCalc?: number

	/**
	 * Faktur. cena brutto
	 */
	SetInvTotalPriceGrossCalc?: number

	/**
	 * Faktur. cena brutto
	 */
	SetInvTotalPriceGrossCCalc?: number

	/**
	 * Plán. cena netto / set
	 */
	SetPlPriceCalc?: number

	/**
	 * Plán. cena netto / set / MJ
	 */
	SetPlUnitPriceCalc?: number

	/**
	 * Plán. cena netto / set / MJ
	 */
	SetPlUnitPriceCCalc?: number

	/**
	 * Plán. cena netto
	 */
	SetPlTotalPriceCalc?: number

	/**
	 * Plán. cena netto
	 */
	SetPlTotalPriceCCalc?: number

	/**
	 * Plán. cena brutto / set
	 */
	SetPlPriceGrossCalc?: number

	/**
	 * Plán. cena brutto / set / MJ
	 */
	SetPlUnitPriceGrossCalc?: number

	/**
	 * Plán. cena brutto / set / MJ
	 */
	SetPlUnitPriceGrossCCalc?: number

	/**
	 * Plán. cena brutto
	 */
	SetPlTotalPriceGrossCalc?: number

	/**
	 * Plán. cena brutto
	 */
	SetPlTotalPriceGrossCCalc?: number

	/**
	 * Položka setu zboží
	 */
	IsFromSetCalc?: number

	/**
	 * Čárový kód
	 */
	BarcodeCalc?: string

	/**
	 * Netto pl./ZMJ
	 */
	PlannedBaseUnitPriceCalc?: number

	/**
	 * Brutto pl./ZMJ
	 */
	PlannedBaseUnitPriceGrossCalc?: number

	/**
	 * Brutto pl./ZMJ
	 */
	PlannedBaseUnitPriceCCalc?: number

	/**
	 * Brutto pl./ZMJ
	 */
	PlannedBaseUnitPriceGrossCCalc?: number

	/**
	 * H
	 */
	MainItemCalc?: number

	/**
	 * Kurz sk.
	 */
	StockDocumentExchangeRateCalc?: number

	/**
	 * Netto pl./MJ bez slevy
	 */
	PlannedOriginalUnitPriceNetCalc?: number

	/**
	 * Netto fa./MJ bez slevy
	 */
	InvoiceOriginalUnitPriceNetCalc?: number

	/**
	 * Netto pl. bez slevy
	 */
	PlannedOriginalTotalPriceNetCalc?: number

	/**
	 * Netto fa. bez slevy
	 */
	InvoiceOriginalTotalPriceNetCalc?: number

	/**
	 * Plánovaná M / MJ
	 */
	PlannedOriginalUnitPriceNetCCalc?: number

	/**
	 * Fakturovaná M / MJ
	 */
	InvoiceOriginalUnitPriceNetCCalc?: number

	/**
	 * Plánovaná M
	 */
	PlannedOriginalTotalPriceNetCCalc?: number

	/**
	 * Fakturovaná M
	 */
	InvoiceOriginalTotalPriceNetCCalc?: number

	/**
	 * Brutto pl./MJ bez slevy
	 */
	PlannedOriginalUnitPriceGrossCalc?: number

	/**
	 * Brutto fa./MJ bez slevy
	 */
	InvoiceOriginalUnitPriceGrossCalc?: number

	/**
	 * Brutto pl. bez slevy
	 */
	PlannedOriginalTotalPriceGrossCalc?: number

	/**
	 * Brutto fa. bez slevy
	 */
	InvoiceOriginalTotalPriceGrossCalc?: number

	/**
	 * Plánovaná M / MJ
	 */
	PlannedOriginalUnitPriceGrossCCalc?: number

	/**
	 * Fakturovaná M / MJ
	 */
	InvoiceOriginalUnitPriceGrossCCalc?: number

	/**
	 * Plánovaná M
	 */
	PlannedOriginalTotalPriceGrossCCalc?: number

	/**
	 * Fakturovaná M
	 */
	InvoiceOriginalTotalPriceGrossCCalc?: number

	/**
	 * Dodání - datum
	 */
	DeliveryDateCalc?: Date

	/**
	 * Čas
	 */
	DeliveryTimeCalc?: Date

	/**
	 * Sleva v %
	 */
	DiscountInPercentageBitCalc?: number

	/**
	 * BatchParameterRatioCalc
	 */
	BatchParameterRatioCalc?: number

	/**
	 * Požad. - datum
	 */
	DesiredDeliveryDateCalc?: Date

	/**
	 * Čas
	 */
	DesiredDeliveryTimeCalc?: Date

	/**
	 * BatchTypeIdCalc
	 */
	BatchTypeIdCalc?: number

	/**
	 * Total weight
	 */
	TotalWeightCalc?: number

	/**
	 * undefined
	 */
	CostFreeStockPriceCalc?: number

	/**
	 * Skl. cena čistá/jedn.
	 */
	CostFreeUnitStockPriceCalc?: number

	/**
	 * Skl. cena čistá/celk.
	 */
	CostFreeTotalStockPriceCalc?: number

	/**
	 * Skladová cena / MJ
	 */
	CostFreeUnitStockPriceCCalc?: number

	/**
	 * Skladová cena celkem
	 */
	CostFreeTotalStockPriceCCalc?: number

	/**
	 * Dodavatelský kód
	 */
	SupplierCodeCalc?: string

	/**
	 * PlannedVATCoefficientCalc
	 */
	PlannedVATCoefficientCalc?: number

	/**
	 * InvoiceVATCoefficientCalc
	 */
	InvoiceVATCoefficientCalc?: number

	/**
	 * Vykryto do
	 */
	CoverageUntilDateCalc?: Date

	/**
	 * Dny do spotřeby
	 */
	DayToConsumptionCountCalc?: number

	/**
	 * Nákl. celk.
	 */
	TotalCostPriceCalc?: number

	/**
	 * DateForVersionCalc
	 */
	DateForVersionCalc?: Date

	/**
	 * DateFromInvoiceCalc
	 */
	DateFromInvoiceCalc?: Date

	/**
	 * Sleva (&g)
	 */
	PlannedDiscountCalc?: number

	/**
	 * Sleva F
	 */
	InvoiceDiscountCalc?: number

	/**
	 * Total weight netto
	 */
	TotalWeightNetCalc?: number

	/**
	 * WarehouseForBatchIdCalc
	 */
	WarehouseForBatchIdCalc?: number

	/**
	 * WarehouseForContractCodeIdCalc
	 */
	WarehouseForContractCodeIdCalc?: number

	/**
	 * Sleva v %
	 */
	PlDiscountPercentageBitCalc?: number

	/**
	 * Pl.v.nákl./MJ
	 */
	PlannedUnitIncidentalCostCalc?: number

	/**
	 * Pl.v.nákl.
	 */
	PlannedTotalIncidentalCostCalc?: number

	/**
	 * Plán. vedlejší náklady / MJ
	 */
	PlannedUnitIncidentalCostCCalc?: number

	/**
	 * Plán. vedlejší náklady
	 */
	PlannedTotalIncidentalCostCCalc?: number

	/**
	 * ExistsPlIncidentalCostsBitCalc
	 */
	ExistsPlIncidentalCostsBitCalc?: number

	/**
	 * undefined
	 */
	HealthHandicapIdCalc?: number

	/**
	 * PDP
	 */
	RCSModeBitCalc?: number

	/**
	 * 1. Produktová skupina
	 */
	PriceGroup1BitCalc?: number

	/**
	 * 2. Produktová skupina
	 */
	PriceGroup2BitCalc?: number

	/**
	 * Ručně změněná cena
	 */
	ManuallyChangedPriceBitCalc?: number

	/**
	 * Sleva se vztahuje jen na fakturovanou cenu
	 */
	DiscountOnlyInvoicePriceBitCalc?: number

	/**
	 * Položka rozpadlá při DoplnSarzeVydeju
	 */
	DuplicateItemBitCalc?: number

	/**
	 * Použit vzorec pro opačnou transakci (výdej/příjem)
	 */
	InverseTransformFormulaBitCalc?: number

	/**
	 * Vedlejší náklad
	 */
	IncidentalCostBitCalc?: number

	/**
	 * Nulové skutečné vedlejší náklady
	 */
	ZeroActualIncidentalCostBitCalc?: number

	/**
	 * Nezahrnovat do Intrastatu
	 */
	NotIncludeToIntrastatBitCalc?: number

	/**
	 * Položka z faktury má dobropis
	 */
	InvoiceItemHasCreditNoteBitCalc?: number

	/**
	 * Ignorovat cenu příjmu
	 */
	IgnoreReceiptPriceCalc?: number

	/**
	 * Hmotnost netto (kg)
	 */
	WeightNetCalc?: number

	/**
	 * Fakturovaná hodnota
	 */
	InvoicedPriceCCalc?: number

	/**
	 * Text
	 */
	ItemTextCalc?: string

	/**
	 * undefined
	 */
	WarehouseZoneRIDCalc?: number

	/**
	 * Typ slevy pl.
	 */
	DiscountTypePlanSuffixCalc?: string

	/**
	 * Typ slevy fa.
	 */
	DiscountTypeInvoiceSuffixCalc?: string

	/**
	 * Faktura přijatá
	 */
	InvoiceIdentificationCalc?: string

	/**
	 * Datum úč. př. fa.
	 */
	InvoiceSuffixCalc?: string

	/**
	 * Potvrzení dodání
	 */
	DeliveryConfirmationIdentificationCalc?: string

	/**
	 * undefined
	 */
	DeliveryNoteSuffixCalc?: string

	/**
	 * Příjemka
	 */
	StockDocumentIdentificationCalc?: string

	/**
	 * Sklad a dat. potvrz. skl. dokladu
	 */
	StockDocumentSuffixCalc?: string

	/**
	 * ExciseTaxDateCalc
	 */
	ExciseTaxDateCalc?: Date

	/**
	 * Datum pro EKO-KOM
	 */
	EkokomDateCalc?: Date

	/**
	 * Total capacity
	 */
	TotalCapacityCalc?: number

	/**
	 * Objednání
	 */
	OrderingDateCalc?: Date

	/**
	 * Čas
	 */
	OrderingTimeCalc?: Date

	/**
	 * undefined
	 */
	FirstDeliveryConfirmDateCalc?: Date

	/**
	 * undefined
	 */
	FirstDeliveryConfirmTimeCalc?: Date

	/**
	 * Dispozice z dodavatelské ceny
	 */
	SupplierPriceAvailableCalc?: number

	/**
	 * Objem celkem
	 */
	TotalVolumePARAMCalc?: number

	/**
	 * Cena ve věrnostním programu
	 */
	LoyaltyProgramPriceCalc?: number

	/**
	 * Skupina ve věrnostním programu
	 */
	LoyaltyProgramGroupIdCalc?: number

	/**
	 * Sleva Z (brutto)
	 */
	PlannedDiscountGrossCalc?: number

	/**
	 * Sleva F (brutto)
	 */
	InvoiceDiscountGrossCalc?: number

	/**
	 * undefined
	 */
	SerialNumberCountAsStringCalc?: string

	/**
	 * 1.Alt.j
	 */
	CF_AltJ1?: string

	/**
	 * Poměr 1.AJ
	 */
	CF_AltJ1Pom?: number

	/**
	 * CF_PolNak_Cross
	 */
	CF_PolNak_Cross?: number

	/**
	 * Koment I1
	 */
	CF_PolNak_commentI1?: string

	/**
	 * Koment I2
	 */
	CF_PolNak_commentI2?: string

	/**
	 * Externí šarže
	 */
	@Type(() => TExternalBatchDM)
	ExternalBatchId?: TExternalBatchDM

	/**
	 * Servisní úkon
	 */
	@Type(() => TScheduledServiceOperationDM)
	ServiceOperationRID?: TScheduledServiceOperationDM

	/**
	 * Servisované zařízení
	 */
	@Type(() => TServicedDeviceDM)
	ServicedDeviceRID?: TServicedDeviceDM

	/**
	 * Kód 6
	 */
	@Type(() => TCode6DocumentDM)
	Code6Id?: TCode6DocumentDM

	/**
	 * Kód 5
	 */
	@Type(() => TCode5DocumentDM)
	Code5Id?: TCode5DocumentDM

	/**
	 * Kód 4
	 */
	@Type(() => TCode4DocumentDM)
	Code4Id?: TCode4DocumentDM

	/**
	 * Kód 3
	 */
	@Type(() => TCode3DocumentDM)
	Code3Id?: TCode3DocumentDM

	/**
	 * undefined
	 */
	@Type(() => TCodeTypeDM)
	MobileTerminalStatusId?: TCodeTypeDM

	/**
	 * Párovací symbol
	 */
	@Type(() => TMatchingSymbolDM)
	MatchingSymbolId?: TMatchingSymbolDM

	/**
	 * Středisko
	 */
	@Type(() => TCostCentreCollectionDocumentDM)
	CostCentreId?: TCostCentreCollectionDocumentDM

	/**
	 * Prostředek
	 */
	@Type(() => TDeviceDocumentDM)
	DeviceRID?: TDeviceDocumentDM

	/**
	 * Manipul. j.
	 */
	@Type(() => THandlingUnitArticleDM)
	HandlingUnitRID?: THandlingUnitArticleDM

	/**
	 * Kniha
	 */
	@Type(() => TBookDM)
	MainDocumentBookId?: TBookDM

	/**
	 * Zákazník
	 */
	@Type(() => TTradingPartnerDM)
	MainDocumentTradingPartnerId?: TTradingPartnerDM

	/**
	 * Typ daně
	 */
	@Type(() => TTaxTypeDM)
	TaxTypeId?: TTaxTypeDM

	/**
	 * Kód zakázky
	 */
	@Type(() => TContractCodeDocumentDM)
	ContractCodeRID?: TContractCodeDocumentDM

	/**
	 * Nomenklatura
	 */
	@Type(() => TNomenclatureDM)
	NomenclatureId?: TNomenclatureDM

	/**
	 * Účet
	 */
	@Type(() => TAccountingChartDM)
	AccountId?: TAccountingChartDM

	/**
	 * Varianta TP
	 */
	@Type(() => TRoutingVariantDM)
	RoutingVariantId?: TRoutingVariantDM

	/**
	 * Typ plánu
	 */
	@Type(() => PlanType)
	PlanTypeId?: PlanType

	/**
	 * Plán. sklad
	 */
	@Type(() => TWarehouseDM)
	PlannedWarehouseId?: TWarehouseDM

	/**
	 * Kód 2
	 */
	@Type(() => TCode2DocumentDM)
	Code2Id?: TCode2DocumentDM

	/**
	 * Kód 1
	 */
	@Type(() => TCode1DocumentDM)
	Code1Id?: TCode1DocumentDM

	/**
	 * Referent
	 */
	@Type(() => TOfficerDocumentDM)
	OfficerId?: TOfficerDocumentDM

	/**
	 * Pož. parametry
	 */
	@Type(() => TRequestedBatchParameterHeaderDM)
	RequestedBatchParametersId?: TRequestedBatchParameterHeaderDM

	/**
	 * Umístění
	 */
	@Type(() => TLocationArticleDM)
	LocationId?: TLocationArticleDM

	/**
	 * Stát pův.
	 */
	@Type(() => TCountryDM)
	OriginCountryId?: TCountryDM

	/**
	 * Kód zboží
	 */
	@Type(() => TArticleCodeDocumentDM)
	ArticleCodeId?: TArticleCodeDocumentDM

	/**
	 * Daň
	 */
	@Type(() => TVATRateDM)
	VATRateId?: TVATRateDM

	/**
	 * Jednotka
	 */
	@Type(() => TMeasureUnitDM)
	MeasureUnitId?: TMeasureUnitDM

	/**
	 * Sklad
	 */
	@Type(() => TWarehouseDM)
	WarehouseId?: TWarehouseDM

	/**
	 * Šarže
	 */
	@Type(() => TBatchDM)
	BatchId?: TBatchDM

	/**
	 * Název
	 */
	@Type(() => GoodsName)
	ArticleNameId?: GoodsName

	/**
	 * Zboží
	 */
	@Type(() => TArticleDM)
	ArticleId?: TArticleDM

	/**
	 * Nabídka NZ
	 */
	@Type(() => TSalesOrderDM)
	EX_RID_NZ?: TSalesOrderDM

	/**
	 * Měna nadř. d.
	 */
	@Type(() => TCurrencyDM)
	HeaderCurrencyCalc?: TCurrencyDM

	/**
	 * Měna fa.
	 */
	@Type(() => TCurrencyDM)
	InvoiceCurrencyCalc?: TCurrencyDM

	/**
	 * Skl.
	 */
	@Type(() => TWarehouseDM)
	WarehouseIdCalc?: TWarehouseDM

	/**
	 * D
	 */
	@Type(() => TTextDM)
	QuantitySignBitCalc?: TTextDM

	/**
	 * Měna sk.
	 */
	@Type(() => TCurrencyDM)
	StockCurrencyCalc?: TCurrencyDM

	/**
	 * undefined
	 */
	@Type(() => TCostCentreCollectionDocumentDM)
	BindCostCentreIdCalc?: TCostCentreCollectionDocumentDM

	/**
	 * undefined
	 */
	@Type(() => TContractCodeDocumentDM)
	BindContractCodeRIDCalc?: TContractCodeDocumentDM

	/**
	 * undefined
	 */
	@Type(() => TCode1DocumentDM)
	BindCode1IdCalc?: TCode1DocumentDM

	/**
	 * undefined
	 */
	@Type(() => TCode2DocumentDM)
	BindCode2IdCalc?: TCode2DocumentDM

	/**
	 * undefined
	 */
	@Type(() => TOfficerDocumentDM)
	BindOfficerIdCalc?: TOfficerDocumentDM

	/**
	 * undefined
	 */
	@Type(() => TPriceGroupDM)
	BindPriceGroupIdCalc?: TPriceGroupDM

	/**
	 * undefined
	 */
	@Type(() => TTradingPartnerDM)
	BindTradingPartnerIdCalc?: TTradingPartnerDM

	/**
	 * N
	 */
	@Type(() => TTextDM)
	SNIncorrectCountBitCalc?: TTextDM

	/**
	 * Dobropis. položka
	 */
	@Type(() => TTextDM)
	ExistsCreditNoteBitCalc?: TTextDM

	/**
	 * WarehouseForIdCalc
	 */
	@Type(() => TWarehouseDM)
	WarehouseForIdCalc?: TWarehouseDM

	/**
	 * ParameterSetIdCalc
	 */
	@Type(() => TParameterSetDM)
	ParameterSetIdCalc?: TParameterSetDM

	/**
	 * Druh účtování
	 */
	@Type(() => KindAccounting)
	PostingKindIdCalc?: KindAccounting

	/**
	 * Transakce
	 */
	@Type(() => JCDObchod)
	TransactionIdCalc?: JCDObchod

	/**
	 * Dodací podmínky
	 */
	@Type(() => JCDP20_a1)
	DeliveryConditionIdCalc?: JCDP20_a1

	/**
	 * Zvláštní pohyb
	 */
	@Type(() => SpecialMove)
	SpecialMoveIdCalc?: SpecialMove

	/**
	 * Druh dopravy
	 */
	@Type(() => TransportKind)
	TransportTypeIdCalc?: TransportKind

	/**
	 * Stát odeslání
	 */
	@Type(() => TCountryDM)
	CountryIdCalc?: TCountryDM

	/**
	 * Platný typ daně
	 */
	@Type(() => TTaxTypeDM)
	TaxTypeIdCalc?: TTaxTypeDM

	/**
	 * undefined
	 */
	@Type(() => TDeviceDocumentDM)
	BindDeviceRIDCalc?: TDeviceDocumentDM

	/**
	 * D
	 */
	@Type(() => TTextDM)
	DeliveryConfirmationStateIdCalc?: TTextDM

	/**
	 * F
	 */
	@Type(() => TTextDM)
	InvoiceInStateIdCalc?: TTextDM

	/**
	 * P
	 */
	@Type(() => TTextDM)
	ReceiptCardStateIdCalc?: TTextDM

	/**
	 * Celní sazebník
	 */
	@Type(() => TIntrastatTariffDutyDM)
	CustomsTariffIdCalc?: TIntrastatTariffDutyDM

	/**
	 * Zboží
	 */
	@Type(() => TArticleDM)
	ArticleByIdInputIdCalc?: TArticleDM

	/**
	 * Zboží
	 */
	@Type(() => TArticleDM)
	ArticleByAbbr2InputIdCalc?: TArticleDM

	/**
	 * Zboží
	 */
	@Type(() => TArticleDM)
	ArticleByNameInputIdCalc?: TArticleDM

	/**
	 * undefined
	 */
	@Type(() => TPriceListDM)
	PriceListRIDCalc?: TPriceListDM

	/**
	 * undefined
	 */
	@Type(() => TCode3DocumentDM)
	BindCode3IdCalc?: TCode3DocumentDM

	/**
	 * undefined
	 */
	@Type(() => TCode4DocumentDM)
	BindCode4IdCalc?: TCode4DocumentDM

	/**
	 * undefined
	 */
	@Type(() => TCode5DocumentDM)
	BindCode5IdCalc?: TCode5DocumentDM

	/**
	 * undefined
	 */
	@Type(() => TCode6DocumentDM)
	BindCode6IdCalc?: TCode6DocumentDM

	/**
	 * RID z dodavatelské ceny
	 */
	@Type(() => TSupplierPriceDM)
	SupplierPriceRIDCalc?: TSupplierPriceDM

	/**
	 * V předstihu
	 */
	@Type(() => TTextDM)
	DeliveryReliability1IdCalc?: TTextDM

	/**
	 * V termínu
	 */
	@Type(() => TTextDM)
	DeliveryReliability2IdCalc?: TTextDM

	/**
	 * V ohrožení
	 */
	@Type(() => TTextDM)
	DeliveryReliability3IdCalc?: TTextDM

	/**
	 * V prodlení
	 */
	@Type(() => TTextDM)
	DeliveryReliability4IdCalc?: TTextDM

	/**
	 * V minulosti
	 */
	@Type(() => TTextDM)
	DeliveryReliability5IdCalc?: TTextDM

	/**
	 * Neurčeno
	 */
	@Type(() => TTextDM)
	DeliveryReliability6IdCalc?: TTextDM

	/**
	 * Spolehlivost dodávek
	 */
	@Type(() => TTextDM)
	DeliveryReliabilityIdCalc?: TTextDM

	/**
	 * RID Věrnostní programy
	 */
	@Type(() => TLoyaltyProgramDM)
	LoyaltyProgramRIDCalc?: TLoyaltyProgramDM

	/**
	 * Komentáře
	 */
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]

	/**
	 * Poznámky
	 */
	@Type(() => TItemNoteDM)
	ItemNotesChild?: TItemNoteDM[]

	/**
	 * Sériová čísla
	 */
	@Type(() => TSerialNumberItemDM)
	SerialNumberItemChild?: TSerialNumberItemDM[]

	/**
	 * Texty
	 */
	@Type(() => TLanguageMemoTranslationDM)
	ItemTextChild?: TLanguageMemoTranslationDM[]

	/**
	 * undefined
	 */
	@Type(() => TAssetLinkDM)
	AssetLinkChild?: TAssetLinkDM[]

	/**
	 * undefined
	 */
	@Type(() => TAttachedBatchParameterValueDM)
	BatchParameterChild?: TAttachedBatchParameterValueDM[]

	/**
	 * undefined
	 */
	@Type(() => TAttachedRequestedBatchParameterDM)
	RequestedBatchParameterChild?: TAttachedRequestedBatchParameterDM[]

	/**
	 * undefined
	 */
	@Type(() => TAssetConnectedDocumentDM)
	AssetConnectedDocumentChild?: TAssetConnectedDocumentDM[]

	/**
	 * Grid Vybrani  ceny
	 */
	@Type(() => TCustomTPurchaseItemDMGrid_Vybrani_ceny)
	Grid_Vybrani_ceny?: TCustomTPurchaseItemDMGrid_Vybrani_ceny[]
}