import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TK2UserLookupDM from './TK2UserLookupDM'
import KnowledgeName from './KnowledgeName'
import LanguageName from './LanguageName'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'

/**
 * Jazyky
 */
export default class TMarketingLanguageDM extends Data {
	public static className = 'TMarketingLanguageDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = false

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * AttachDM
	 */
	AttachDataModuleNumber?: number

	/**
	 * AttachRowNo
	 */
	AttachRowNumber?: number

	/**
	 * AttachId
	 */
	AttachId?: number

	/**
	 * AttachBook
	 */
	AttachBook?: string

	/**
	 * AttachType
	 */
	AttachTypeId?: number

	/**
	 * Hodnota
	 */
	Value?: number

	/**
	 * Komentář
	 */
	Comment?: string

	/**
	 * Vznik
	 */
	CreatedOn?: Date

	/**
	 * Změněno
	 */
	ChangedOn?: Date

	/**
	 * Datum
	 */
	Date?: Date

	/**
	 * Pořadí
	 */
	SequenceNumber?: number

	/**
	 * Kont. os.
	 */
	ContactPersonId?: number

	/**
	 * Typ
	 */
	TypeId?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Vytvořil
	 */
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM

	/**
	 * Změnil
	 */
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM

	/**
	 * Text
	 */
	@Type(() => KnowledgeName)
	Text?: KnowledgeName

	/**
	 * Jazyk
	 */
	@Type(() => LanguageName)
	LanguageId?: LanguageName

	/**
	 * undefined
	 */
	@Type(() => TLanguageMemoTranslationDM)
	CommentChild?: TLanguageMemoTranslationDM[]
}