import Data from 'react-k2-api/Api/Data'
import { Type } from 'class-transformer'
import TWorkflowPriorityDM from './TWorkflowPriorityDM'
import TTextDM from './TTextDM'
import TContactPersonDM from './TContactPersonDM'

/**
 * Historie kroků
 */
export default class TWorkflowWorkStepInProcessDM extends Data {
	public static className = 'TWorkflowWorkStepInProcessDM'
	public static systemFields = [
		'RecordID',
	]

	public static isHeader = false

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * undefined
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * undefined
	 */
	IsInvalidRecord?: string

	/**
	 * Zástupce
	 */
	OrgStrRID?: number

	/**
	 * Splnit do
	 */
	DeadlineDate?: Date

	/**
	 * Ukončeno
	 */
	CompletedDate?: Date

	/**
	 * Číslo kroku postupu
	 */
	DefaultWorkflowProcedureStepId?: number

	/**
	 * Popis kroku
	 */
	StepDescription?: string

	/**
	 * Produkt číslo
	 */
	ProductNumber?: number

	/**
	 * Produkt modul
	 */
	ProductDataModuleNumber?: number

	/**
	 * undefined
	 */
	Comment?: string

	/**
	 * Podřízený proces
	 */
	SubordinateProcessId?: number

	/**
	 * Verze kroku
	 */
	WorkStepVersion?: number

	/**
	 * Pořadí
	 */
	SequenceNumber?: number

	/**
	 * Zástup skupina
	 */
	RoleId?: number

	/**
	 * Číslo kroku
	 */
	WorkStepRID?: number

	/**
	 * Text
	 */
	RecordType?: number

	/**
	 * DateSort
	 */
	SortDate?: string

	/**
	 * H
	 */
	Header?: number

	/**
	 * Krok
	 */
	StepName?: string

	/**
	 * Číslo popisu
	 */
	MemoRID?: number

	/**
	 * Posláno
	 */
	Date?: Date

	/**
	 * Krok uživatele
	 */
	WorkStepSolverRID?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * TO
	 */
	IsFulfillExpiredCalc?: string

	/**
	 * Priorita
	 */
	@Type(() => TWorkflowPriorityDM)
	PriorityId?: TWorkflowPriorityDM

	/**
	 * Typ
	 */
	@Type(() => TTextDM)
	StepTypeId?: TTextDM

	/**
	 * Stav
	 */
	@Type(() => TTextDM)
	StatusId?: TTextDM

	/**
	 * Vlastník
	 */
	@Type(() => TContactPersonDM)
	OwnerUserId?: TContactPersonDM

	/**
	 * Poslal
	 */
	@Type(() => TContactPersonDM)
	SentUserId?: TContactPersonDM
}