import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TAddressDM from './TAddressDM'
import TContactPersonDM from './TContactPersonDM'
import TTradingPartnerDM from './TTradingPartnerDM'
import TK2UserLookupDM from './TK2UserLookupDM'
import TDeviceDocumentDM from './TDeviceDocumentDM'
import TOfficerDocumentDM from './TOfficerDocumentDM'
import TArticleCodeDocumentDM from './TArticleCodeDocumentDM'
import TContractCodeDocumentDM from './TContractCodeDocumentDM'
import TCode6DocumentDM from './TCode6DocumentDM'
import TCode5DocumentDM from './TCode5DocumentDM'
import TCode4DocumentDM from './TCode4DocumentDM'
import TCode3DocumentDM from './TCode3DocumentDM'
import TCode2DocumentDM from './TCode2DocumentDM'
import TCode1DocumentDM from './TCode1DocumentDM'
import TCostCentreCollectionDocumentDM from './TCostCentreCollectionDocumentDM'
import TermOfDelivery from './TermOfDelivery'
import TTextDM from './TTextDM'
import TDeliveryPlanDM from './TDeliveryPlanDM'
import TDeliveryNoteDM from './TDeliveryNoteDM'
import TDeliveryConfirmationDM from './TDeliveryConfirmationDM'
import TInvoiceInDM from './TInvoiceInDM'
import TInvoiceOutDM from './TInvoiceOutDM'
import TPurchaseOrderDM from './TPurchaseOrderDM'
import TReceiptCardDM from './TReceiptCardDM'
import TTransferNoteDM from './TTransferNoteDM'
import TReservingCardDM from './TReservingCardDM'
import TReleaseNoteDM from './TReleaseNoteDM'
import TSalesOrderDM from './TSalesOrderDM'
import TCountryDM from './TCountryDM'
import TTownPartDM from './TTownPartDM'
import TTownDM from './TTownDM'
import TDeliveryMeasureUnitDM from './TDeliveryMeasureUnitDM'

/**
 * Položky rozvozu
 */
export default class TDeliveryPlanItemBookDM extends Data {
	public static className = 'TDeliveryPlanItemBookDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = true

	/**
	 * Pořadí vykládky
	 */
	UnloadingSequenceNumber?: number

	/**
	 * Pořadí nakládky
	 */
	LoadingSequenceNumber?: number

	/**
	 * Objem
	 */
	Volume?: number

	/**
	 * Potvrzení
	 */
	ConfirmedOn?: Date

	/**
	 * Potvrdil
	 */
	ConfirmedById?: number

	/**
	 * Hmotnost
	 */
	Weight?: number

	/**
	 * Sklad vykládka
	 */
	WarehouseToId?: number

	/**
	 * Sklad nakládka
	 */
	WarehouseFromId?: number

	/**
	 * Balení
	 */
	PackageId?: string

	/**
	 * Region
	 */
	RegionId?: number

	/**
	 * Email vykládka
	 */
	EmailTo?: string

	/**
	 * Email nakládka
	 */
	EmailFrom?: string

	/**
	 * Tel. vykládka
	 */
	PhoneTo?: string

	/**
	 * Tel. nakládka
	 */
	PhoneFrom?: string

	/**
	 * Poznámka
	 */
	Note?: string

	/**
	 * Vytvořeno
	 */
	CreatedOn?: Date

	/**
	 * Popis
	 */
	Description?: string

	/**
	 * Typ rozvozu
	 */
	HeaderTypeId?: number

	/**
	 * Stornováno
	 */
	IsCanceledRecord?: string

	/**
	 * Termín dodání
	 */
	DeliveryTime?: Date

	/**
	 * Změněno
	 */
	ChangedOn?: Date

	/**
	 * Doklad
	 */
	OriginalDocumentRID?: number

	/**
	 * Pořadí
	 */
	SequenceNumber?: number

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * undefined
	 */
	DocumentIdentificationCalc?: string

	/**
	 * undefined
	 */
	DeliveryTimeDateCalc?: Date

	/**
	 * undefined
	 */
	DeliveryTimeTimeCalc?: Date

	/**
	 * undefined
	 */
	DeliveryOrderRIDCalc?: number

	/**
	 * undefined
	 */
	TotalVolumePARAMCalc?: number

	/**
	 * Ulice (Adresa nakládky)
	 */
	AddressFromIdStreetCalc?: string

	/**
	 * Číslo orientační (Adresa nakládky)
	 */
	AddressFromIdOrientationNumberCalc?: string

	/**
	 * Číslo popisné (Adresa nakládky)
	 */
	AddressFromIdHouseNumberCalc?: string

	/**
	 * Název (Adresa nakládky)
	 */
	AddressFromIdNameCalc?: string

	/**
	 * PSČ (Adresa nakládky)
	 */
	AddressFromIdTownCodeCalc?: string

	/**
	 * Souřadnice (Adresa nakládky)
	 */
	AddressFromIdCoordinatesCalc?: string

	/**
	 * Ulice (Adresa vykládky)
	 */
	AddressToIdStreetCalc?: string

	/**
	 * Číslo orientační (Adresa vykládky)
	 */
	AddressToIdOrientationNumberCalc?: string

	/**
	 * Číslo popisné (Adresa vykládky)
	 */
	AddressToIdHouseNumberCalc?: string

	/**
	 * Název (Adresa vykládky)
	 */
	AddressToIdNameCalc?: string

	/**
	 * PSČ (Adresa vykládky)
	 */
	AddressToIdTownCodeCalc?: string

	/**
	 * Souřadnice (Adresa vykládky)
	 */
	AddressToIdCoordinatesCalc?: string

	/**
	 * Adresa vykládky
	 */
	@Type(() => TAddressDM)
	AddressToId?: TAddressDM

	/**
	 * Adresa nakládky
	 */
	@Type(() => TAddressDM)
	AddressFromId?: TAddressDM

	/**
	 * Kontaktní osoba vykládka
	 */
	@Type(() => TContactPersonDM)
	ContactPersonToId?: TContactPersonDM

	/**
	 * Kontaktní osoba vykládka
	 */
	@Type(() => TContactPersonDM)
	ContactPersonFromId?: TContactPersonDM

	/**
	 * Zákazník vykládka
	 */
	@Type(() => TTradingPartnerDM)
	TradingPartnerToId?: TTradingPartnerDM

	/**
	 * Zákazník nakládka
	 */
	@Type(() => TTradingPartnerDM)
	TradingPartnerFromId?: TTradingPartnerDM

	/**
	 * Vytvořil
	 */
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM

	/**
	 * Prostředek
	 */
	@Type(() => TDeviceDocumentDM)
	DeviceRID?: TDeviceDocumentDM

	/**
	 * Referent
	 */
	@Type(() => TOfficerDocumentDM)
	OfficerId?: TOfficerDocumentDM

	/**
	 * Kód zboží
	 */
	@Type(() => TArticleCodeDocumentDM)
	ArticleCodeId?: TArticleCodeDocumentDM

	/**
	 * Kód zakázky
	 */
	@Type(() => TContractCodeDocumentDM)
	ContractCodeRID?: TContractCodeDocumentDM

	/**
	 * Kód 6
	 */
	@Type(() => TCode6DocumentDM)
	Code6Id?: TCode6DocumentDM

	/**
	 * Kód 5
	 */
	@Type(() => TCode5DocumentDM)
	Code5Id?: TCode5DocumentDM

	/**
	 * Kód 4
	 */
	@Type(() => TCode4DocumentDM)
	Code4Id?: TCode4DocumentDM

	/**
	 * Kód 3
	 */
	@Type(() => TCode3DocumentDM)
	Code3Id?: TCode3DocumentDM

	/**
	 * Kód 2
	 */
	@Type(() => TCode2DocumentDM)
	Code2Id?: TCode2DocumentDM

	/**
	 * Kód 1
	 */
	@Type(() => TCode1DocumentDM)
	Code1Id?: TCode1DocumentDM

	/**
	 * Středisko
	 */
	@Type(() => TCostCentreCollectionDocumentDM)
	CostCentreId?: TCostCentreCollectionDocumentDM

	/**
	 * Termín dodání slovy
	 */
	@Type(() => TermOfDelivery)
	DeliveryTimeText?: TermOfDelivery

	/**
	 * Změnil
	 */
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM

	/**
	 * Typ dokladu
	 */
	@Type(() => TTextDM)
	OriginalDocumentTypeId?: TTextDM

	/**
	 * Rozvoz
	 */
	@Type(() => TDeliveryPlanDM)
	HeaderRID?: TDeliveryPlanDM

	/**
	 * undefined
	 */
	@Type(() => TTradingPartnerDM)
	DocumentTradingPartnerIdCalc?: TTradingPartnerDM

	/**
	 * undefined
	 */
	@Type(() => TTradingPartnerDM)
	DocumentDeliveryTradingPartnerIdCalc?: TTradingPartnerDM

	/**
	 * Dodaci list
	 */
	@Type(() => TDeliveryNoteDM)
	DeliveryNoteRIDCalc?: TDeliveryNoteDM

	/**
	 * Potvrzení dodání
	 */
	@Type(() => TDeliveryConfirmationDM)
	DeliveryConfirmationRIDCalc?: TDeliveryConfirmationDM

	/**
	 * Faktura přijatá
	 */
	@Type(() => TInvoiceInDM)
	InvoiceInRIDCalc?: TInvoiceInDM

	/**
	 * Faktura vydaná
	 */
	@Type(() => TInvoiceOutDM)
	InvoiceOutRIDCalc?: TInvoiceOutDM

	/**
	 * Objednávka
	 */
	@Type(() => TPurchaseOrderDM)
	PurchaseOrderRIDCalc?: TPurchaseOrderDM

	/**
	 * Příjemka
	 */
	@Type(() => TReceiptCardDM)
	ReceiptCardRIDCalc?: TReceiptCardDM

	/**
	 * Převodka
	 */
	@Type(() => TTransferNoteDM)
	TransferNoteRIDCalc?: TTransferNoteDM

	/**
	 * Rezervační list
	 */
	@Type(() => TReservingCardDM)
	ReservingCardRIDCalc?: TReservingCardDM

	/**
	 * Výdejka
	 */
	@Type(() => TReleaseNoteDM)
	ReleaseNoteRIDCalc?: TReleaseNoteDM

	/**
	 * Zakázka
	 */
	@Type(() => TSalesOrderDM)
	SalesOrderRIDCalc?: TSalesOrderDM

	/**
	 * Stát (Adresa nakládky)
	 */
	@Type(() => TCountryDM)
	AddressFromIdCountryIdCalc?: TCountryDM

	/**
	 * Část obce (Adresa nakládky)
	 */
	@Type(() => TTownPartDM)
	AddressFromIdTownPartIdCalc?: TTownPartDM

	/**
	 * Číslo (Adresa nakládky)
	 */
	@Type(() => TTownDM)
	AddressFromIdTownIdCalc?: TTownDM

	/**
	 * Stát (Adresa vykládky)
	 */
	@Type(() => TCountryDM)
	AddressToIdCountryIdCalc?: TCountryDM

	/**
	 * Část obce (Adresa vykládky)
	 */
	@Type(() => TTownPartDM)
	AddressToIdTownPartIdCalc?: TTownPartDM

	/**
	 * Číslo (Adresa vykládky)
	 */
	@Type(() => TTownDM)
	AddressToIdTownIdCalc?: TTownDM

	/**
	 * undefined
	 */
	@Type(() => TDeliveryMeasureUnitDM)
	MeasureUnitChild?: TDeliveryMeasureUnitDM[]

	/**
	 * Změna skupiny práv
	 */
	public ChangeGroupRightCOMMAND() {
		return this.executeCommand('ChangeGroupRightCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public BulkChangeGroupRightCOMMAND() {
		return this.executeCommand('BulkChangeGroupRightCOMMAND')
	}

	/**
	 * Aktualizace počítaných polí
	 */
	public BulkUpdateCalcFieldsCOMMAND() {
		return this.executeCommand('BulkUpdateCalcFieldsCOMMAND')
	}

	/**
	 * Kontrola legislativy
	 */
	public SwitchOffLegislationValidationCOMMAND() {
		return this.executeCommand('SwitchOffLegislationValidationCOMMAND')
	}
}