import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TK2UserLookupDM from './TK2UserLookupDM'
import TCurrencyDM from './TCurrencyDM'
import TBanksDM from './TBanksDM'
import TCompanyLinkBankAccountDM from './TCompanyLinkBankAccountDM'

/**
 * Bankovní účty
 */
export default class TBankAccountTradingPartnerDM extends Data {
	public static className = 'TBankAccountTradingPartnerDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = false

	/**
	 * Zneplatněno
	 */
	IsInvalidRecord?: string

	/**
	 * Zahraniční
	 */
	IsForeign?: string

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * Spec. symb.
	 */
	SpecificSymbol?: string

	/**
	 * Plat. titul
	 */
	ChargeCode?: string

	/**
	 * Foreign
	 */
	ExtendedInformation?: string

	/**
	 * Změněno
	 */
	ChangedOn?: Date

	/**
	 * Kód poplatku
	 */
	PaymentItem?: string

	/**
	 * IBAN
	 */
	IBAN?: string

	/**
	 * Číslo účtu
	 */
	AccountNumber?: string

	/**
	 * Pořadí
	 */
	SequenceNumber?: number

	/**
	 * Číslo
	 */
	Id?: number

	/**
	 * Tabulka
	 */
	TableId?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * IBAN
	 */
	IBANCalc?: string

	/**
	 * RU
	 */
	VATPaymentsAccountRegCalc?: number

	/**
	 * Dat.ověř.
	 */
	CreditCheckVerifiedOnCalc?: Date

	/**
	 * Registrace od
	 */
	CreditCheckRegDateFromCalc?: Date

	/**
	 * Registrace do
	 */
	CreditCheckRegDateToCalc?: Date

	/**
	 * R
	 */
	AccountRegForVATPaymentImgCalc?: string

	/**
	 * SWIFT
	 */
	AccountSwiftCalc?: string

	/**
	 * Řádek 1
	 */
	BankAddress1Calc?: string

	/**
	 * Řádek 2
	 */
	BankAddress2Calc?: string

	/**
	 * Řádek 3
	 */
	BankAddress3Calc?: string

	/**
	 * Řádek 4
	 */
	BankAddress4Calc?: string

	/**
	 * ISO
	 */
	BankCountryCodeCalc?: string

	/**
	 * Název firmy
	 */
	RecipientAddress1Calc?: string

	/**
	 * Ulice
	 */
	RecipientAddress2Calc?: string

	/**
	 * Obec
	 */
	RecipientAddress3Calc?: string

	/**
	 * P4
	 */
	RecipientAddress4Calc?: string

	/**
	 * Kontaktní osoba
	 */
	ContactPersonCalc?: string

	/**
	 * undefined
	 */
	BankCodeNumberCalc?: string

	/**
	 * Výchozí
	 */
	IsDefaultAccountCalc?: string

	/**
	 * Změnil
	 */
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM

	/**
	 * Vystavil
	 */
	@Type(() => TK2UserLookupDM)
	IssuedById?: TK2UserLookupDM

	/**
	 * Měna
	 */
	@Type(() => TCurrencyDM)
	Currency?: TCurrencyDM

	/**
	 * Banka
	 */
	@Type(() => TBanksDM)
	BankId?: TBanksDM

	/**
	 * undefined
	 */
	@Type(() => TCompanyLinkBankAccountDM)
	CompanyChild?: TCompanyLinkBankAccountDM[]
}