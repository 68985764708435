import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TTaxTypeDM from './TTaxTypeDM'
import TK2UserLookupDM from './TK2UserLookupDM'
import TLegislationDm from './TLegislationDm'

/**
 * Dodavatelé - odběratelé- pole dle legislativy
 */
export default class TLegislationLinkTradingPartnerDM extends Data {
	public static className = 'TLegislationLinkTradingPartnerDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = false

	/**
	 * Zákonný úrok pro prodej
	 */
	IsStatutoryInterestSale?: string

	/**
	 * Zákonný úrok pro nákup
	 */
	IsStatutoryInterestPurchase?: string

	/**
	 * DPH na dokladech prodeje
	 */
	IsVATPayerSale?: string

	/**
	 * DPH na dokladech nákupu
	 */
	IsVATPayerPurchase?: string

	/**
	 * Valid
	 */
	IsValid?: string

	/**
	 * Změněno
	 */
	ChangedOn?: Date

	/**
	 * RecID
	 */
	RecordNumber?: number

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Typ daně - prodej
	 */
	@Type(() => TTaxTypeDM)
	SaleTaxTypeId?: TTaxTypeDM

	/**
	 * Typ daně - nákup
	 */
	@Type(() => TTaxTypeDM)
	PurchaseTaxTypeId?: TTaxTypeDM

	/**
	 * Změnil
	 */
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM

	/**
	 * Legislativa
	 */
	@Type(() => TLegislationDm)
	LegislationId?: TLegislationDm
}