import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TTextDM from './TTextDM'
import TK2UserLookupDM from './TK2UserLookupDM'
import ParameterUnit from './ParameterUnit'
import TParameterSetAsItemDM from './TParameterSetAsItemDM'
import TParameterGroupAsItemDM from './TParameterGroupAsItemDM'
import TParameterPossibleValueDM from './TParameterPossibleValueDM'
import TLanguageMemoTranslationChildDM from './TLanguageMemoTranslationChildDM'

/**
 * Parametry
 */
export default class TParameterDM extends Data {
	public static className = 'TParameterDM'
	public static primaryKey = 'ParameterId'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.ParameterId!
	}

	public static isHeader = true

	/**
	 * Stránka
	 */
	TextPageRID?: number

	/**
	 * Název e-shop
	 */
	Title?: string

	/**
	 * BetterValue
	 */
	BetterValue?: number

	/**
	 * Časové razítko
	 */
	TimeStamp?: string

	/**
	 * Změněno
	 */
	ChangedOn?: Date

	/**
	 * ID e-shop
	 */
	EShopId?: string

	/**
	 * Klíčové slovo
	 */
	KeyWord?: string

	/**
	 * Využití
	 */
	Usage?: string

	/**
	 * Formát
	 */
	Picture?: string

	/**
	 * Popis
	 */
	Description?: string

	/**
	 * Název
	 */
	Name?: string

	/**
	 * ID par.
	 */
	ParameterId?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Blokováno
	 */
	BlockedRecordBitCalc?: number

	/**
	 * Povinný údaj
	 */
	RequiredBitCalc?: number

	/**
	 * Hodnoty z číselníku
	 */
	FromListCalc?: number

	/**
	 * Možný výčet hodnot
	 */
	PossibleValuesEnumerationBitCalc?: number

	/**
	 * Kontrolovaná vazba
	 */
	ControlledReferenceCalc?: number

	/**
	 * Hodnota definovaná na šarži nebo požadovaných parametrech
	 */
	ValueFromBatchIdCalc?: number

	/**
	 * Exportovat na web
	 */
	WebExportBitCalc?: number

	/**
	 * Export OLAP
	 */
	OlapExportIdCalc?: number

	/**
	 * Filtr pro web
	 */
	WebFilterBitCalc?: number

	/**
	 * Implicitní požadovaný parametr
	 */
	ImplicitRequiredParameterIdCalc?: number

	/**
	 * Nepřenášet požadovaný parametr do šarže
	 */
	NotTranReqParamToBatchBitCalc?: number

	/**
	 * Zachovat požadovaný parametr při rozdělení položky
	 */
	KeepOnSplitIdCalc?: number

	/**
	 * Název
	 */
	TranslatedNameCalc?: string

	/**
	 * Popis
	 */
	TranslatedDescriptionCalc?: string

	/**
	 * OLAP
	 */
	@Type(() => TTextDM)
	OLAPPosition?: TTextDM

	/**
	 * Změnil
	 */
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM

	/**
	 * Typ Záz.
	 */
	@Type(() => TTextDM)
	ValueTypeId?: TTextDM

	/**
	 * Jednotka
	 */
	@Type(() => ParameterUnit)
	UnitId?: ParameterUnit

	/**
	 * TranslationLanguageIdCalc
	 */
	@Type(() => TTextDM)
	TranslationLanguageIdCalc?: TTextDM

	/**
	 * Zařazení
	 */
	@Type(() => TParameterSetAsItemDM)
	ParameterSetChild?: TParameterSetAsItemDM[]

	/**
	 * Skupiny parametrů
	 */
	@Type(() => TParameterGroupAsItemDM)
	ParameterGroupChild?: TParameterGroupAsItemDM[]

	/**
	 * Hodnoty parametru
	 */
	@Type(() => TParameterPossibleValueDM)
	PossibleValueChild?: TParameterPossibleValueDM[]

	/**
	 * undefined
	 */
	@Type(() => TLanguageMemoTranslationChildDM)
	TranslatedFieldValuesChild?: TLanguageMemoTranslationChildDM[]

	/**
	 * Změna skupiny práv
	 */
	public ChangeGroupRightCOMMAND() {
		return this.executeCommand('ChangeGroupRightCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public BulkChangeGroupRightCOMMAND() {
		return this.executeCommand('BulkChangeGroupRightCOMMAND')
	}

	/**
	 * Aktualizace počítaných polí
	 */
	public BulkUpdateCalcFieldsCOMMAND() {
		return this.executeCommand('BulkUpdateCalcFieldsCOMMAND')
	}

	/**
	 * Kontrola legislativy
	 */
	public SwitchOffLegislationValidationCOMMAND() {
		return this.executeCommand('SwitchOffLegislationValidationCOMMAND')
	}
}