import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TLocationDM from './TLocationDM'
import TWarehouseDM from './TWarehouseDM'
import TSpecialKanban from './TSpecialKanban'

/**
 * Kanban
 */
export default class TSpecialTSalesItemDMKanbanItems extends Data {
	public static className = 'TSpecialTSalesItemDMKanbanItems'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = false

	/**
	 * Master
	 */
	Master?: number

	/**
	 * Pořadí
	 */
	Ordering?: number

	/**
	 * Původní box
	 */
	BoxOriginal?: string

	/**
	 * Box
	 */
	Box?: string

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * RecordMod
	 */
	RecordMod?: number

	/**
	 * RecordES
	 */
	RecordES?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * RecordState
	 */
	RecordState?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Na umístění
	 */
	@Type(() => TLocationDM)
	ToLocationId?: TLocationDM

	/**
	 * Z umístění
	 */
	@Type(() => TLocationDM)
	FromLocationId?: TLocationDM

	/**
	 * Na sklad
	 */
	@Type(() => TWarehouseDM)
	ToWarehouseId?: TWarehouseDM

	/**
	 * Kanban
	 */
	@Type(() => TSpecialKanban)
	KanbanCalc?: TSpecialKanban
}