import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TK2UserLookupDM from './TK2UserLookupDM'
import TAddressDM from './TAddressDM'
import EmploymentName from './EmploymentName'
import TEmployedPersonDM from './TEmployedPersonDM'
import TTownDM from './TTownDM'
import TTownPartDM from './TTownPartDM'
import TCountryDM from './TCountryDM'
import TCommentDM from './TCommentDM'
import TSickLeaveDM from './TSickLeaveDM'
import TExternalDocumentItemDM from './TExternalDocumentItemDM'
import TCompanyLinkDM from './TCompanyLinkDM'

/**
 * Předchozí zaměstnání
 */
export default class TPreviousEmploymentDM extends Data {
	public static className = 'TPreviousEmploymentDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = true

	/**
	 * Skupina práv
	 */
	RightGroupId?: number

	/**
	 * Časové razítko
	 */
	TimeStamp?: string

	/**
	 * Změněno
	 */
	ChangedOn?: Date

	/**
	 * Vytvořeno
	 */
	CreatedOn?: Date

	/**
	 * XMLField
	 */
	XMLField?: string

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * Solidární daň
	 */
	TaxSolid?: number

	/**
	 * Daňový bonus
	 */
	TaxBonus?: number

	/**
	 * Pořadí
	 */
	SequenceNumber?: number

	/**
	 * Záloha na daň
	 */
	TaxAdvance?: number

	/**
	 * Pojistné
	 */
	Insurance?: number

	/**
	 * Úhrn příjmů
	 */
	Income?: number

	/**
	 * Organizace
	 */
	EmployerName?: string

	/**
	 * Datum od
	 */
	DateFrom?: Date

	/**
	 * Datum do
	 */
	DateTo?: Date

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * Souřadnice (Adresa)
	 */
	AddressIdCoordinates?: string

	/**
	 * PSČ (Adresa)
	 */
	AddressIdTownCode?: string

	/**
	 * undefined
	 */
	AddressIdChanged?: number

	/**
	 * Název (Adresa)
	 */
	AddressIdName?: string

	/**
	 * Číslo popisné (Adresa)
	 */
	AddressIdHouseNumber?: string

	/**
	 * Číslo orientační (Adresa)
	 */
	AddressIdOrientationNumber?: string

	/**
	 * Ulice (Adresa)
	 */
	AddressIdStreet?: string

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Poslední komentář
	 */
	LastCommentParamCalc?: string

	/**
	 * Skupina práv
	 */
	RightGroupIdCalc?: number

	/**
	 * Změnil
	 */
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM

	/**
	 * Vytvořil
	 */
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM

	/**
	 * Adresa
	 */
	@Type(() => TAddressDM)
	AddressId?: TAddressDM

	/**
	 * Funkce
	 */
	@Type(() => EmploymentName)
	EmployeePosition?: EmploymentName

	/**
	 * Pracovník
	 */
	@Type(() => TEmployedPersonDM)
	EmployedPersonId?: TEmployedPersonDM

	/**
	 * Číslo (Adresa)
	 */
	@Type(() => TTownDM)
	AddressIdTownId?: TTownDM

	/**
	 * Část obce (Adresa)
	 */
	@Type(() => TTownPartDM)
	AddressIdTownPartId?: TTownPartDM

	/**
	 * Stát (Adresa)
	 */
	@Type(() => TCountryDM)
	AddressIdCountryId?: TCountryDM

	/**
	 * Komentáře
	 */
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]

	/**
	 * undefined
	 */
	@Type(() => TSickLeaveDM)
	PrevEmplSickLeaveHistChild?: TSickLeaveDM[]

	/**
	 * Dokumenty
	 */
	@Type(() => TExternalDocumentItemDM)
	ExternalDocumentItemChild?: TExternalDocumentItemDM[]

	/**
	 * undefined
	 */
	@Type(() => TCompanyLinkDM)
	CompanyChild?: TCompanyLinkDM[]

	/**
	 * Změna skupiny práv
	 */
	public ChangeGroupRightCOMMAND() {
		return this.executeCommand('ChangeGroupRightCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public BulkChangeGroupRightCOMMAND() {
		return this.executeCommand('BulkChangeGroupRightCOMMAND')
	}

	/**
	 * Aktualizace počítaných polí
	 */
	public BulkUpdateCalcFieldsCOMMAND() {
		return this.executeCommand('BulkUpdateCalcFieldsCOMMAND')
	}

	/**
	 * Kontrola legislativy
	 */
	public SwitchOffLegislationValidationCOMMAND() {
		return this.executeCommand('SwitchOffLegislationValidationCOMMAND')
	}
}