import Data from 'react-k2-api/Api/Data'

/**
 * Použití role v doplňkových právech
 */
export default class TUserRoleRightExtensionDM extends Data {
	public static className = 'TUserRoleRightExtensionDM'
	public static systemFields = [
		'RecordID',
	]

	public static isHeader = false

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * undefined
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * Zkratka skladu
	 */
	WarehouseAbbr?: string

	/**
	 * Číslo skladu
	 */
	WarehouseId?: number

	/**
	 * Zkratka knihy
	 */
	BookAbbr?: string

	/**
	 * Číslo knihy
	 */
	BookId?: number

	/**
	 * Akce na dokladu
	 */
	DocumentAction?: string

	/**
	 * Modul, doklad
	 */
	SectionModule?: string

	/**
	 * Uživatel, kniha, sklad
	 */
	SectionSection?: string

	/**
	 * Identifikace sekce
	 */
	Section?: number

	/**
	 * Mandant
	 */
	Client?: string

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date
}