import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'

/**
 * Grid Informace
 */
export default class TCustomTArticleDMGrid_Informace extends Data {
	public static className = 'TCustomTArticleDMGrid_Informace'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = false

	/**
	 * Master
	 */
	Master?: number

	/**
	 * Inf Kc
	 */
	InfKc?: number

	/**
	 * InfEUR
	 */
	InfEUR?: number

	/**
	 * Informace
	 */
	Informace?: string

	/**
	 * Poradi
	 */
	Poradi?: number

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * RecordMod
	 */
	RecordMod?: number

	/**
	 * RecordES
	 */
	RecordES?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * RecordState
	 */
	RecordState?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date
}