import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TTextDM from './TTextDM'
import TAttendanceMovementSubTypeDM from './TAttendanceMovementSubTypeDM'
import TAttendanceMovementDM from './TAttendanceMovementDM'
import TK2UserLookupDM from './TK2UserLookupDM'

/**
 * Zpracované průchody
 */
export default class TAttendanceProcessedMovementDM extends Data {
	public static className = 'TAttendanceProcessedMovementDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = true

	/**
	 * Datum a čas
	 */
	MovementDate?: Date

	/**
	 * Kniha
	 */
	BookId?: number

	/**
	 * Den pracovního listu
	 */
	WorkTimeReportItemRID?: number

	/**
	 * Pracovní list
	 */
	WorkTimeReportRID?: number

	/**
	 * Skupina práv
	 */
	RightGroupId?: number

	/**
	 * Časové razítko
	 */
	TimeStamp?: string

	/**
	 * Změněno
	 */
	ChangedOn?: Date

	/**
	 * Vytvořeno
	 */
	CreatedOn?: Date

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Popis
	 */
	DescriptionCalc?: string

	/**
	 * Zaokrouhleno
	 */
	RoundedMovementDateCalc?: Date

	/**
	 * Skupina práv
	 */
	RightGroupIdCalc?: number

	/**
	 * Původ
	 */
	@Type(() => TTextDM)
	OriginTypeId?: TTextDM

	/**
	 * Typ průchodu
	 */
	@Type(() => TAttendanceMovementSubTypeDM)
	MovementSubTypeRID?: TAttendanceMovementSubTypeDM

	/**
	 * Druh průchodu
	 */
	@Type(() => TTextDM)
	MovementTypeId?: TTextDM

	/**
	 * Průchod
	 */
	@Type(() => TAttendanceMovementDM)
	MovementRID?: TAttendanceMovementDM

	/**
	 * Změnil
	 */
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM

	/**
	 * Vytvořil
	 */
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM

	/**
	 * Změna skupiny práv
	 */
	public ChangeGroupRightCOMMAND() {
		return this.executeCommand('ChangeGroupRightCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public BulkChangeGroupRightCOMMAND() {
		return this.executeCommand('BulkChangeGroupRightCOMMAND')
	}

	/**
	 * Aktualizace počítaných polí
	 */
	public BulkUpdateCalcFieldsCOMMAND() {
		return this.executeCommand('BulkUpdateCalcFieldsCOMMAND')
	}

	/**
	 * Kontrola legislativy
	 */
	public SwitchOffLegislationValidationCOMMAND() {
		return this.executeCommand('SwitchOffLegislationValidationCOMMAND')
	}
}