import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TPrEmploymentDM from './TPrEmploymentDM'
import TEmployedPersonDM from './TEmployedPersonDM'
import TElectronicSubmissionDM from './TElectronicSubmissionDM'

/**
 * Osoby v elektronickém podání
 */
export default class TElectronicSubmissionPersonDM extends Data {
	public static className = 'TElectronicSubmissionPersonDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = false

	/**
	 * Kniha
	 */
	BookId?: number

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Pracovní vztah
	 */
	@Type(() => TPrEmploymentDM)
	PrEmploymentId?: TPrEmploymentDM

	/**
	 * Pracovník
	 */
	@Type(() => TEmployedPersonDM)
	EmployedPersonId?: TEmployedPersonDM

	/**
	 * Elektronické podání
	 */
	@Type(() => TElectronicSubmissionDM)
	ElectronicSubmissionRID?: TElectronicSubmissionDM
}