import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TRequestedBatchParameterDM from './TRequestedBatchParameterDM'

/**
 * Požadované parametry šarží
 */
export default class TRequestedBatchParameterHeaderDM extends Data {
	public static className = 'TRequestedBatchParameterHeaderDM'
	public static primaryKey = 'Id'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.Id!
	}

	public static isHeader = true

	/**
	 * Časové razítko
	 */
	TimeStamp?: string

	/**
	 * Typ šarže
	 */
	BatchTypeId?: number

	/**
	 * Odkaz na zboží
	 */
	ArticleId?: number

	/**
	 * Popis
	 */
	Description?: string

	/**
	 * Zkratka
	 */
	Abbr?: string

	/**
	 * Číslo
	 */
	Id?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Aktuální typ šarže
	 */
	BatchTypeIdCalc?: number

	/**
	 * undefined
	 */
	ParameterSetIdCalc?: number

	/**
	 * Aktivní
	 */
	IsActiveCalc?: string

	/**
	 * undefined
	 */
	@Type(() => TRequestedBatchParameterDM)
	RequestedBatchParameterChild?: TRequestedBatchParameterDM[]

	/**
	 * Výběr typu
	 */
	public TypeIdChangeCOMMAND(parameters: TTypeIdChangeCOMMANDParams) {
		return this.executeCommand('TypeIdChangeCOMMAND', parameters)
	}

	/**
	 * Změna skupiny práv
	 */
	public ChangeGroupRightCOMMAND() {
		return this.executeCommand('ChangeGroupRightCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public BulkChangeGroupRightCOMMAND() {
		return this.executeCommand('BulkChangeGroupRightCOMMAND')
	}

	/**
	 * Aktualizace počítaných polí
	 */
	public BulkUpdateCalcFieldsCOMMAND() {
		return this.executeCommand('BulkUpdateCalcFieldsCOMMAND')
	}

	/**
	 * Kontrola legislativy
	 */
	public SwitchOffLegislationValidationCOMMAND() {
		return this.executeCommand('SwitchOffLegislationValidationCOMMAND')
	}
}

export type TTypeIdChangeCOMMANDParams = {
	/**
	 * Typ
	 */
	TypeId?: number
	/**
	 * MessageText
	 */
	MessageText?: string
}