import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TArticleDM from './TArticleDM'

/**
 * Napočítané statistiky
 */
export default class TEshopCalculatedStatisticsDM extends Data {
	public static className = 'TEshopCalculatedStatisticsDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = false

	/**
	 * Časové razítko
	 */
	TimeStamp?: string

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * Novinka - priorita
	 */
	NewsPriority?: number

	/**
	 * Hodnocení
	 */
	Rating?: number

	/**
	 * Priorita návštěvností
	 */
	VisitPriority?: number

	/**
	 * Počet návštěv
	 */
	VisitCount?: number

	/**
	 * Priorita prodaného množství
	 */
	SoldPriority?: number

	/**
	 * Množství prodáno
	 */
	SoldQuantity?: number

	/**
	 * E-shop
	 */
	ShopRID?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * undefined
	 */
	AssignedCalc?: number

	/**
	 * Zboží
	 */
	@Type(() => TArticleDM)
	ArticleId?: TArticleDM
}