import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TK2UserLookupDM from './TK2UserLookupDM'
import TTextDM from './TTextDM'
import TPrPeriodDM from './TPrPeriodDM'
import TBookDM from './TBookDM'
import TPrDeductionDM from './TPrDeductionDM'
import TPrConstantGroupItemDM from './TPrConstantGroupItemDM'
import TPrConstantValueItemDM from './TPrConstantValueItemDM'

/**
 * Mzdové konstanty
 */
export default class TPrConstantDM extends Data {
	public static className = 'TPrConstantDM'
	public static primaryKey = 'ConstantId'
	public static systemFields = [
		'TimeStamp',
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.ConstantId!
	}

	public static isHeader = true

	/**
	 * Skupina práv
	 */
	RightGroupId?: number

	/**
	 * Časové razítko
	 */
	TimeStamp?: string

	/**
	 * Změněno
	 */
	ChangedOn?: Date

	/**
	 * Vytvořeno
	 */
	CreatedOn?: Date

	/**
	 * Komentář
	 */
	Comment?: string

	/**
	 * Na knihy
	 */
	IsForBooks?: string

	/**
	 * Firma
	 */
	CompanyId?: number

	/**
	 * Standardní číslo
	 */
	StandardId?: number

	/**
	 * Popis
	 */
	Description?: string

	/**
	 * Zkratka
	 */
	Abbr?: string

	/**
	 * Číslo
	 */
	ConstantId?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Hodnota
	 */
	ConstantValueStringCalc?: string

	/**
	 * Zobrazení
	 */
	ValuePictureCalc?: string

	/**
	 * Hodnota
	 */
	ValueCalc?: string

	/**
	 * Doba změny hodnoty
	 */
	ChangedOnCalc?: Date

	/**
	 * Hodnota
	 */
	ActualTextCalc?: string

	/**
	 * Zobrazení
	 */
	ActualPictureCalc?: string

	/**
	 * Upozornit při importu
	 */
	ImportWarningIdCalc?: number

	/**
	 * Blokovat import
	 */
	BlockImportIdCalc?: number

	/**
	 * Neplatná konstanta
	 */
	InvalidConstantBitCalc?: number

	/**
	 * Povolit zařazení do skupin
	 */
	GroupAllowedBitCalc?: number

	/**
	 * Hodnota konstanty
	 */
	ConstantValueDateCalc?: Date

	/**
	 * Hodnota konstanty
	 */
	ConstantValueCurrencyCalc?: number

	/**
	 * Hodnota konstanty
	 */
	ConstantValueIntegerCalc?: number

	/**
	 * Hodnota konstanty
	 */
	ConstantValueRealCalc?: number

	/**
	 * Hodnota konstanty
	 */
	ConstantValueTextCalc?: string

	/**
	 * Hodnota konstanty
	 */
	ConstantValueTimeCalc?: Date

	/**
	 * Hodnota konstanty
	 */
	ConstantValueComponentIdCalc?: number

	/**
	 * Hodnota konstanty
	 */
	ConstantValueCalendarIdCalc?: number

	/**
	 * Skupina práv
	 */
	RightGroupIdCalc?: number

	/**
	 * Změnil
	 */
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM

	/**
	 * Vytvořil
	 */
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM

	/**
	 * Typ pro import
	 */
	@Type(() => TTextDM)
	ImportTypeId?: TTextDM

	/**
	 * Typ
	 */
	@Type(() => TTextDM)
	TypeId?: TTextDM

	/**
	 * Typ hodnoty
	 */
	@Type(() => TTextDM)
	ValueTypeIdCalc?: TTextDM

	/**
	 * Jednotka
	 */
	@Type(() => TTextDM)
	ValueMeasureUnitIdCalc?: TTextDM

	/**
	 * Hodnotu změnil
	 */
	@Type(() => TK2UserLookupDM)
	ChangedByIdCalc?: TK2UserLookupDM

	/**
	 * Nastavené období
	 */
	@Type(() => TPrPeriodDM)
	PeriodIdCalc?: TPrPeriodDM

	/**
	 * Platnost hodnoty do
	 */
	@Type(() => TPrPeriodDM)
	ConstantValidPeridodFromIdCalc?: TPrPeriodDM

	/**
	 * Kniha platnosti hodnoty
	 */
	@Type(() => TBookDM)
	BookIdCalc?: TBookDM

	/**
	 * Kniha mzdových dokladů
	 */
	@Type(() => TBookDM)
	PrDocumentBookIdCalc?: TBookDM

	/**
	 * Zpracované období
	 */
	@Type(() => TPrPeriodDM)
	ProcessedPeriodIdCalc?: TPrPeriodDM

	/**
	 * Nastavené období
	 */
	@Type(() => TPrPeriodDM)
	UserPeriodIdCalc?: TPrPeriodDM

	/**
	 * Změna mimo období zpracování
	 */
	@Type(() => TTextDM)
	EditTypeIdCalc?: TTextDM

	/**
	 * Omezení mzdové konstanty
	 */
	@Type(() => TTextDM)
	PrComponentRestrictionIdCalc?: TTextDM

	/**
	 * Změna v nastaveném období
	 */
	@Type(() => TTextDM)
	ChangedValueInPeriodBitCalc?: TTextDM

	/**
	 * Hodnota konstanty
	 */
	@Type(() => TTextDM)
	ConstantValueBooleanIdCalc?: TTextDM

	/**
	 * Hodnota konstanty
	 */
	@Type(() => TPrDeductionDM)
	ConstantValueBankDetailIdCalc?: TPrDeductionDM

	/**
	 * undefined
	 */
	@Type(() => TPrConstantGroupItemDM)
	PrConstantGroupItemChild?: TPrConstantGroupItemDM[]

	/**
	 * Položky - hodnoty v obdobích
	 */
	@Type(() => TPrConstantValueItemDM)
	PrConstantItemChild?: TPrConstantValueItemDM[]

	/**
	 * Období zpracování mezd
	 */
	public PrPeriodChangeCOMMAND(parameters: TPrPeriodChangeCOMMANDParams) {
		return this.executeCommand('PrPeriodChangeCOMMAND', parameters)
	}

	/**
	 * Export mzdových konstant
	 */
	public BulkPayrollConstantExportCOMMAND() {
		return this.executeCommand('BulkPayrollConstantExportCOMMAND')
	}

	/**
	 * Import ze souboru XML
	 */
	public ImportXMLCOMMAND(parameters: TImportXMLCOMMANDParams) {
		return this.executeCommand('ImportXMLCOMMAND', parameters)
	}

	/**
	 * Změna skupiny práv
	 */
	public ChangeGroupRightCOMMAND() {
		return this.executeCommand('ChangeGroupRightCOMMAND')
	}

	/**
	 * Změna skupiny práv
	 */
	public BulkChangeGroupRightCOMMAND() {
		return this.executeCommand('BulkChangeGroupRightCOMMAND')
	}

	/**
	 * Aktualizace počítaných polí
	 */
	public BulkUpdateCalcFieldsCOMMAND() {
		return this.executeCommand('BulkUpdateCalcFieldsCOMMAND')
	}

	/**
	 * Kontrola legislativy
	 */
	public SwitchOffLegislationValidationCOMMAND() {
		return this.executeCommand('SwitchOffLegislationValidationCOMMAND')
	}
}

export type TPrPeriodChangeCOMMANDParams = {
	/**
	 * Nastavené období
	 */
	IdPeriod?: number
	/**
	 * MessageText
	 */
	MessageText?: string
}

export type TImportXMLCOMMANDParams = {
	/**
	 * Typ akce importu
	 */
	XMLAction?: number
	/**
	 * Typ2 akce importu
	 */
	XMLAction2?: number
	/**
	 * Importní soubor
	 */
	FileName?: string
	/**
	 * ValueA
	 */
	ValueA?: boolean
	/**
	 * ValueB
	 */
	ValueB?: boolean
	/**
	 * VisibleA
	 */
	VisibleA?: boolean
	/**
	 * VisibleB
	 */
	VisibleB?: boolean
	/**
	 * CaptionA
	 */
	CaptionA?: string
	/**
	 * CaptionB
	 */
	CaptionB?: string
	/**
	 * MessageText
	 */
	MessageText?: string
}