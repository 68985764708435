import Data from 'react-k2-api/Api/Data'
import { Type } from 'class-transformer'
import TJobCardDM from './TJobCardDM'
import TMeasureUnitDM from './TMeasureUnitDM'
import TArticleDM from './TArticleDM'

/**
 * Podřízené doklady
 */
export default class TJobCardSubordinateDocumentDM extends Data {
	public static className = 'TJobCardSubordinateDocumentDM'
	public static systemFields = [
		'RecordID',
	]

	public static isHeader = false

	/**
	 * Ident. podř.
	 */
	SubordinateJobCardItemRID?: number

	/**
	 * Číslo nadř.
	 */
	HeaderRID1?: number

	/**
	 * Ident. nadř.
	 */
	SuperiorBindId?: number

	/**
	 * Typ
	 */
	SuperiorDocumentType?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * undefined
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * undefined
	 */
	Final?: number

	/**
	 * undefined
	 */
	BatchAbbr?: string

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Vl. množ. plán.
	 */
	OwnPlannedQuantityCalc?: number

	/**
	 * Vl. množ. skut.
	 */
	OwnActualQuantityCalc?: number

	/**
	 * Vl. množ. plán.
	 */
	OwnPlannedQuantityUMCalc?: number

	/**
	 * Vl. množ. skut.
	 */
	OwnActualQuantityUMCalc?: number

	/**
	 * Číslo podř.
	 */
	@Type(() => TJobCardDM)
	HeaderRID2?: TJobCardDM

	/**
	 * Skl. j.
	 */
	@Type(() => TMeasureUnitDM)
	MeasureUnitIdCalc?: TMeasureUnitDM

	/**
	 * Č. zboží
	 */
	@Type(() => TArticleDM)
	ArticleIdCalc?: TArticleDM
}