import Data from 'react-k2-api/Api/Data'
import PrimaryKey from 'react-k2-api/Api/PrimaryKey'
import { Type } from 'class-transformer'
import TArticleDM from './TArticleDM'
import TProductKeyDM from './TProductKeyDM'

/**
 * Položky produktových klíčů
 */
export default class TProductKeyItemDM extends Data {
	public static className = 'TProductKeyItemDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	public static isHeader = false

	/**
	 * Pořadí
	 */
	SequenceOrder?: number

	/**
	 * Hodnotový DM
	 */
	MainDataModuleNumber?: number

	/**
	 * Číslo
	 */
	RID?: number

	/**
	 * undefined
	 */
	RecordMod?: number

	/**
	 * undefined
	 */
	RecordES?: number

	/**
	 * Číslo
	 */
	RecordID?: number

	/**
	 * undefined
	 */
	RecordState?: number

	/**
	 * s
	 */
	SelectionImgCalc?: string

	/**
	 * LegislationContext
	 */
	LegislationContextCalc?: number

	/**
	 * Datum
	 */
	CurrentDateCalc?: Date

	/**
	 * Čas
	 */
	CurrentTimeCalc?: Date

	/**
	 * Hodnota
	 */
	@Type(() => TArticleDM)
	MainValue?: TArticleDM

	/**
	 * Klíč
	 */
	@Type(() => TProductKeyDM)
	SortingKeyRID?: TProductKeyDM
}